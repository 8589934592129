import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "580px",
  maxHeight: "590px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const RequestOrderCancelForm = ({ classes }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    operationInfo,
    getCancelledOrdersLogDataAPi,
    setIsOpen,
    fetchListingDataFromApi,
  } = useContext(SupportDataContext);
  const [currentOrdersStatus, setCurrentOrdersStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);


  console.log("currentOrdersStatus", currentOrdersStatus);

  const getCurrentOrdersStatusDataAPi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ProjId = operationInfo?.project_id || 0
    const orderId = operationInfo?.Order_Id || 0

    try {
      const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/get_current_order_status/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&project_id=${ProjId}&order_id=${orderId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCurrentOrdersStatus(res?.data?.results?.Current_Order_Status_Details_List);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCurrentOrdersStatusDataAPi();
  }, []);

  const orderCancelledPostAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");


    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/support_request_order_cancellation/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      factory_id: Number(factory_id),
      user_name: user_name,

      order_id: operationInfo?.Order_Id,
      project_id: operationInfo?.project_id,
      cancellation_remarks: values?.remarks,
    };
    axios.put(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      handleQuotationPromise(data);
    });
  };

  const handleQuotationPromise = (data) => {
    if (data.data.data.message) {
      setMessage(data.data.data.message);
      setSeverity(500);
    }
    if (data.data.data.msg) {
      setMessage(data.data.data.msg);
      setSeverity(500);
    }
    if (data.data.data.detail) {
      setMessage(data.data.data.detail);
      setSeverity(500);
    } else {
      setMessage(data.data.data.msg);
      setSeverity(data.data.data.code);
    }
    setIsOpen(true);
    fetchListingDataFromApi();
    getCancelledOrdersLogDataAPi();
    handleModaleClose();
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        remarks: "",
      }}
      validate={(values) => {
        let errors = {};
        if (values.remarks === "") {
          errors.remarks = "required";
        }
      }}
      onSubmit={async (data) => {
        setLoading(true);
          orderCancelledPostAPI(data);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      handleModaleClose();
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "500px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          Order Cancellation
                        </span>
                      </div>
                      <br />
                       {/* { errorMessage && (
                        <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="error" onClose={() => { }}>
                          Already Requested for Cancellation!
                        </Alert>
                      </Stack>
                      )
                      } */}
                     
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                            <InputText
                              label="Order No"
                              fieldName="Order_No"
                              fieldValue={operationInfo?.Order_No}
                              disableField={true}
                            />
                            <InputText
                              label="Order Name"
                              fieldName="Order_Name"
                              fieldValue={operationInfo?.Order_Name}
                              disableField={true}
                            />
                            <InputText
                              label="Company Name"
                              fieldName="Company_Name"
                              fieldValue={operationInfo?.Company_Name}
                              disableField={true}
                            />
                            <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                <span style={{ fontSize: "12px" }}>Cancellation Remarks</span>
                                <span style={{ color: "red", fontSize: "12px" }}>*</span>
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                <textarea
                                  name="remarks"
                                  value={values.remarks}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    setFieldValue(`remarks`, value);
                                  }}
                                  required
                                  style={{ height: "130px", width: "100%" }}
                                />
                              </Col>
                            </Form.Group>
                            <span style={{ color: "red" }}>
                              {errors.remarks &&
                                touched.remarks &&
                                errors.remarks}
                            </span>

                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                      onClick={()=>{
                        getCurrentOrdersStatusDataAPi();
                      }}
                      // disabled={
                      //   (
                      //     currentOrdersStatus[0]?.Accounts_Status === "rejected"
                      //     && currentOrdersStatus[0]?.Order_Cancellation_Status !== "Approved by Accounts"
                      //     && currentOrdersStatus[0]?.Order_Cancellation_Status !== "Requested by Support"
                      //   )
                      //     ||
                      //     (
                      //       currentOrdersStatus[0]?.Accounts_Status === "accepted"
                      //       && currentOrdersStatus[0]?.Order_Status !== "Completed"
                      //       && currentOrdersStatus[0]?.Order_Request_To_Complete_Status !== "Requested"
                      //       && currentOrdersStatus[0]?.Total_Requisition === currentOrdersStatus[0]?.TotRequisition_Completed
                      //       && currentOrdersStatus[0]?.Order_Cancellation_Status !== "Approved by Accounts"
                      //       && currentOrdersStatus[0]?.Order_Cancellation_Status !== "Requested by Support"
                      //     )
                      //     ? false
                      //     :
                      //     true
                      // }
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        handleModaleClose();
                        // handleHide()
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(RequestOrderCancelForm);
