import React from "react";
import { IconButton, Tooltip } from "@mui/material";
const CustomIconButton = ({
  width,
  key,
  title,
  icon,
  onClick,
  disabled,
  className,
}) => {
  return (
    <>
      <Tooltip title={title}>
        <span>
          <IconButton
            key={key}
            // title={title}
            size="small"
            style={{
              border: "1px solid #eb6e12",
              borderRadius: "5px",
              fontSize: "12px",
              marginRight: "6px",
              color: "#fff",
              background: "#eb6e12",
              height: "30px ",
              marginTop: "3px",
              width: width ? width : "30px ",
            }}
            className={className}
            onClick={onClick}
            disabled={disabled}
          >
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default CustomIconButton;
