import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./customTextarea.css";

const CustomTextArea = ({
  label,
  fieldName,
  fieldHandleChange,
  fieldValue,
  errorField,
  disableField,
  requiredField,
  height,
  errors,
  onPaste
}) => (
  <>
    <Form.Group as={Row} className="mb-1" controlId={fieldName}>
      <Form.Label column sm="5" className="fc-textarea-label">
        {label} {requiredField && <sup style={{ color: "red" }}>*</sup>}
      </Form.Label>
      <Col sm="7" style={{ padding: "0 12px 0 0" }}>
        <div className="fc-textarea-container" style={errorField}>
          <textarea
            className="fc-textarea-style"
            placeholder={label}
            name={fieldName}
            value={fieldValue}
            onChange={fieldHandleChange}
            disabled={disableField ? "disabled" : ""}
            onPaste={onPaste}
            style={{ height: height ? height : "" }}
          />
        </div>
      </Col>
    </Form.Group>
  </>
);

export default CustomTextArea;
