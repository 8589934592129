import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext, DataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import { VpnLock } from "@material-ui/icons";
import { addDays } from "date-fns";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    padding: "25px",
    height: "auto",
    paddingTop: "0px",
    minHeight: "550px",
    maxHeight: "550px",
    overflow: 'auto',
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        maxHeight: "760px",
    },
};

const getStyles = (errors, fieldName, height) => {

    if (height && !getIn(errors, fieldName)) {
        return { height: height }
    }

    if (getIn(errors, fieldName) && height) {
        return {
            border: "1px solid red",
            height: height
        };
    } else if (getIn(errors, fieldName)) {
        return {
            border: "1px solid red"
        };
    }
};

const AddReplyCommentForm = ({ classes }) => {

    const {
        handleModaleClose,
        setLoading,
        loading,
        setMessage,
        setSeverity,
        editModalPopper,
        setIsOpen,
        operationInfo,
        salesInfo,
        rowData,
        getComplaintListDataApi,
        getCommentListDataApi
    } = useContext(SupportDataContext);

    const [enableReinitialize, setEnablReinitializei] = useState(false);
    const [disabledId, setDisabledId] = useState([]);
    const fileInput = React.createRef();
    const [complaintId, setComplaintId] = useState(0);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className, }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#d11616",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#d11616",
        },

    }));

    const statusOptions = [
        { value: "Valid & Resolved", label: "Valid & Resolved" },
        { value: "Void", label: "Void" }
    ]


    const addCommentPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const user_name = localStorage.getItem("Employee_First_Name");
        const factory_id = localStorage.getItem("factory_id");



        const url = `https://flyingcolour.online/fcapp/typist_app/add_order_comment/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };

        const formData = new FormData();
        formData.append("factory_id ", Number(factory_id));
        formData.append("emp_id", Number(emp_id));
        formData.append("user_type", user_type);
        formData.append("order_no", operationInfo?.Order_No);
        formData.append("comment_details", values.comment);


        axios.post(url, formData, { headers: headers }).then((data) => {
            setLoading(false);

            if (data?.data?.message) {
                setMessage(`${data?.data?.message}`);
                setSeverity(500)
            }
            else {
                setMessage(data?.data?.data?.msg);
                setSeverity(data?.data?.data?.code)
            }
            setIsOpen(true);
            handleModaleClose();
            getCommentListDataApi()
        })
    };
    const replyCommentPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const user_name = localStorage.getItem("Employee_First_Name");
        const factory_id = localStorage.getItem("factory_id");

        const url = `https://flyingcolour.online/fcapp/typist_app/add_order_comment_reply/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };

        const formData = new FormData();
        formData.append("factory_id ", Number(factory_id));
        formData.append("emp_id", Number(emp_id));
        formData.append("user_type", user_type);
        formData.append("user_name", user_name);
        formData.append("order_no", operationInfo?.Order_No);
        formData.append("comment_id", rowData.Comment_Id);
        formData.append("reply_details", values.comment);


        axios.post(url, formData, { headers: headers }).then((data) => {
            setLoading(false);

            if (data?.data?.message) {
                setMessage(`${data?.data?.message}`);
                setSeverity(500)
            }
            else {
                setMessage(data?.data?.data?.msg);
                setSeverity(data?.data?.data?.code)
            }
            setIsOpen(true);
            handleModaleClose();
            getCommentListDataApi()
        })
    }

    useEffect(() => {
        //getPartnerDataApi();
    }, [])

    return (
        <>
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={{
                    comment: '',

                }}
                onSubmit={async (data) => {
                    setLoading(true)
                    editModalPopper ? replyCommentPostAPI(data) : addCommentPostAPI(data)
                    // changeComplaintStatusPostAPI(data)
                }}

                validate={(values) => {

                    let errors = {};

                    if (values.comment === "") {
                        errors.comment = "Required!!";
                    }

                    return errors;
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched,
                }) => {
                    return (
                        <form onSubmit={(e) => { e.preventDefault() }}>
                            <Box sx={style}>
                                <div>
                                    <div style={{ height: "36px" }}>
                                        <IconButton
                                            style={{ float: "right" }}
                                            className={classes.quotationModalHeaderIconCancle}
                                            onClick={() => {
                                                handleModaleClose();
                                            }}
                                        >
                                            <CloseIcon
                                                className={classes.quotationModalHeaderIconCancleIcon}
                                            />
                                        </IconButton>
                                    </div>
                                    <div style={{ height: "480px", overflowX: "hidden", padding: "0" }}>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    marginBottom: "-30px",
                                                    paddingBottom: "10px",
                                                    marginTop: "-45px",
                                                    zIndex: 10
                                                }}
                                            >
                                                <br />
                                                <div className={classes.quotationModalHeadingContainer}>
                                                    <span className={classes.quotationModalHeader}>
                                                        {editModalPopper ? `Reply on Comment` : "Add Comments"}
                                                    </span>
                                                </div>
                                                <br />
                                                <div className={classes.quotationModalFlexDivContaner}>
                                                    <Grid container>
                                                        <Grid item xs={12} md={12} style={{ paddingRight: 5 }}>

                                                            <Form.Group as={Row} className="mb-1">
                                                                <Form.Label column sm="5" className="fc-textarea-label">
                                                                    <span style={{ fontSize: "12px" }}> {editModalPopper ? "Reply Message" : "Comment"}</span>
                                                                    <span style={{ color: "red", fontSize: "12px" }}>*</span>
                                                                </Form.Label>
                                                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                                                    <textarea
                                                                        name="comment"
                                                                        value={values.comment}
                                                                        onChange={(e) => {
                                                                            e.preventDefault();
                                                                            const { value } = e.target;
                                                                            setFieldValue(
                                                                                `comment`,
                                                                                value
                                                                            );
                                                                        }}
                                                                        required
                                                                        style={{ height: "200px", width: "100%" }}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </Grid>

                                                    </Grid>

                                                </div>
                                                <br />
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <br />
                                        <div className={classes.quotationButtonDivModal}>
                                            <BootstrapTooltip arrow={true}

                                                disableFocusListener
                                                title={errors.comment === undefined
                                                    ? ""
                                                    :
                                                    `${errors.comment !== undefined ? `${errors.comment},` : ""}
                                                    `
                                                }
                                            >

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    size="small"
                                                    style={{ backgroundColor: "#eb6e12" }}
                                                    onClick={handleSubmit}
                                                >
                                                    {loading ? "Loading...." : "Submit"}
                                                </Button>
                                            </BootstrapTooltip>
                                            <Button
                                                onClick={() => {
                                                    handleModaleClose();
                                                    // handleHide()
                                                }}
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    color: "#eb6e12",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default withStyles(ModalPopperFormStyles)(AddReplyCommentForm);
