import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "620px",
  maxHeight: "620px",
  overflow: 'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if (height && !getIn(errors, fieldName)) {
    return { height: height }
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const AddWorkerForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    getRequistionListingDataFromApi,
    getViewCompanyFamilyApi,
    viewCompanyFamilyData,
    salesInfo,
    operationInfo,
    getViewCompanyWorkerApi, viewCompanyWorkerData
  } = useContext(SupportDataContext);

  const [passportExpiry, setPassportExpiry] = useState(
    editModalPopper ? new Date(new Date(moment(salesInfo?.Employee_Passport_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      moment().toDate())
  const [visaIssue, setVisaIssue] = useState(
    editModalPopper ? new Date(new Date(moment(salesInfo?.Employee_Visa_Issue_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      moment().toDate())
  const [visaExpiry, setVisaExpiry] = useState(
    editModalPopper ? new Date(new Date(moment(salesInfo?.Employee_Visa_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      moment().toDate())
      const [cardExpiry, setCardExpiry] = useState(
        editModalPopper ? new Date(new Date(moment(salesInfo?.Employee_Labour_Card_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
          moment().toDate())

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className, }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },

  }));

  const CountryList = viewCompanyWorkerData?.Nationality_List?.map((item) => {
    const obj = { value: item.name, label: item.name };
    return obj;
  });
  const CardStatusOptions = viewCompanyWorkerData?.Card_Status_List?.map((item) => {
    const obj = { value: item.Status_Id, label: item.Status_Name };
    return obj;
  });

  const LabourCardStatusOptions  = viewCompanyWorkerData?.Labour_Card_Status?.map((item) => {
    const obj = {  value: item.Status_Id, label: item.Status_Name };
    return obj;
  });
  const LabourCardTypeOptions = viewCompanyWorkerData?.Labour_Card_Type?.map((item) => {
    const obj = {  value: item.Name, label: item.Name };
    return obj;
  });

 
  

  const addFamilyInfoPostAPI = async (values) => {
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const factory_id = localStorage.getItem("factory_id");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/get_worker_details/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    const formData = new FormData();
    formData.append("factory_id", factory_id);
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("company_info_id", Number(operationInfo?.Id));
    formData.append("company_id", Number(operationInfo?.Company_Id));
    formData.append("employment_emp_name", values?.employment_emp_name);
    formData.append("employment_emp_email", values?.employment_emp_email);
    formData.append("employment_emp_mob", values?.employment_emp_mob);
    formData.append("employment_designation", values?.employment_designation);
    formData.append("basic_salary", values?.basic_salary);
    formData.append("sal_transportation", values?.sal_transportation);
    formData.append("sal_accomodation", values?.sal_accomodation);
    formData.append("sal_overtime", values?.sal_overtime);
    formData.append("employment_labour_card", values?.employment_labour_card);
    formData.append("employment_labour_expiry_date", moment(values?.employment_labour_expiry_date).format("YYYY-MM-DD"));
    formData.append("employment_visa_issue_date", moment(values?.employment_visa_issue_date).format("YYYY-MM-DD"));
    formData.append("employment_visa_expiry_date", moment(values?.employment_visa_expiry_date).format("YYYY-MM-DD"));
    formData.append("employment_passport", values?.employment_passport);
    formData.append("employment_passport_expiry_date", moment(values?.employment_passport_expiry_date).format("YYYY-MM-DD"));
    formData.append("employment_nationality", values?.employment_nationality);
    formData.append("employment_card_type", values?.employment_card_type);
    formData.append("employment_labour_card_status", values?.employment_labour_card_status);
    formData.append("card_status_id", Number(values?.card_status_id));


    if (editModalPopper) {

      formData.append("worker_id", Number(salesInfo?.Worker_Id));

      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        //handleQuotationPromise(data);
        getViewCompanyWorkerApi();
      })
    }
    else {
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        getViewCompanyWorkerApi();
      })
    }
  }

  useEffect(() => {
    setLoading(true);
    getViewCompanyWorkerApi();
  }, []);


  return (
    <>
      <Formik
        initialValues={{
          employment_emp_name: editModalPopper ? salesInfo?.Employee_Name : "",
          employment_emp_email: editModalPopper ? salesInfo?.Employee_Email : "",
          employment_emp_mob: editModalPopper ? salesInfo?.Employee_Mobile : "",
          employment_designation: editModalPopper ? salesInfo?.Employee_Designation : "",
          basic_salary: editModalPopper ? salesInfo?.Basic_Salary: "",
          sal_transportation: editModalPopper ? salesInfo?.Sal_Transportation: "",
          sal_accomodation: editModalPopper ? salesInfo?.Sal_Accomodation: "",
          sal_overtime: editModalPopper ? salesInfo?.Sal_Overtime: "",
          employment_labour_card: editModalPopper ? salesInfo?.Employee_Labour_Card : "",
          employment_labour_expiry_date: editModalPopper ? cardExpiry : "",
          employment_visa_issue_date: editModalPopper ? visaIssue : "",
          employment_visa_expiry_date: editModalPopper ? visaExpiry : "",
          employment_passport: editModalPopper ? salesInfo?.Employee_Passport : "",
          employment_passport_expiry_date: editModalPopper ? passportExpiry : "",
          employment_nationality: editModalPopper ? salesInfo?.Employee_Nationality : "",
          employment_card_type: editModalPopper ? salesInfo?.Employee_Card_Type : "",
          employment_labour_card_status: editModalPopper ? salesInfo?.Employee_Labour_Card_Status_Id : "",
          card_status_id: editModalPopper ? salesInfo?.CardStatus_Id : "",

        }}
        onSubmit={async (data) => {
          setLoading(true)
          addFamilyInfoPostAPI(data);
          handleModaleClose();
        }}
        validate={(values) => {
          let errors = {};

          if (values.employment_emp_name === "") {
            errors.employment_emp_name = "Name is required";
          }
          if (values.employment_emp_email === "") {
            errors.employment_emp_email = "Email is required";
          }
          if (values.employment_emp_mob === "") {
            errors.employment_emp_mob = "Mobile No is required";
          }
          if (values.employment_designation === "") {
            errors.employment_designation = "Designation is required";
          }
          if (values.basic_salary === "") {
            errors.basic_salary = "Basic salary is required";
          }
          if (values.sal_transportation === "") {
            errors.sal_transportation = "Salary transportation is required";
          }
          if (values.sal_accomodation === "") {
            errors.sal_accomodation = "Salary accomodation is required";
          }
          if (values.sal_overtime === "") {
            errors.sal_overtime = "Salary overtime is required";
          }
          if (values.employment_labour_card === "") {
            errors.employment_labour_card = "Employment Labour card is required";
          }
          if (values.card_status_id === "") {
            errors.card_status_id = "Card status is required";
          }

          if (values.employment_labour_expiry_date === "") {
            errors.employment_labour_expiry_date = "Labour card expiry is required";
          }
          if (values.employment_visa_issue_date === "") {
            errors.employment_visa_issue_date = "Visa issue date is required";
          }
          if (values.employment_visa_expiry_date === "") {
            errors.employment_visa_expiry_date = "Visa expiry date is required";
          }
          if (values.employment_passport === "") {
            errors.employment_passport = "Passport No is required";
          }
          if (values.employment_passport_expiry_date === "") {
            errors.employment_passport_expiry_date = "Passport expiry date is required";
          }
          if (values.employment_nationality === "") {
            errors.employment_nationality = "Nationality is required";
          }
          if (values.employment_card_type === "") {
            errors.employment_card_type = "Card type is required";
          }
          if (values.employment_labour_card_status === "") {
            errors.employment_labour_card_status = "Labour card status is required";
          }
          if (values.card_status_id === "") {
            errors.card_status_id = "Card status is required";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div style={{ height: "550px", overflowX: "hidden", padding: "0" }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            {editModalPopper ? "Edit Work Information" : "Add Worker Information"}{``}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>

                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="Employee Name"
                                fieldName="employment_emp_name"
                                fieldValue={values?.employment_emp_name}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                   setFieldValue(`employment_emp_name`, value);
                                }}
                              //  disableField={true}
                              // disabled={true}
                              // classvalue={"textBold"}
                              //  errorField={getStyles(errors)}
                               requiredField={true}
                              />
                              <InputText
                                label="Employee Email"
                                fieldName="employment_emp_email"
                                fieldValue={values?.employment_emp_email}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`employment_emp_email`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                              <InputText
                                label="Employee Mobile"
                                fieldName="employment_emp_mob"
                                fieldValue={values?.employment_emp_mob}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`employment_emp_mob`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />

                              <InputText
                                label="Employment Designation"
                                fieldName="employment_designation"
                                fieldValue={values?.employment_designation}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`employment_designation`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                                <InputText
                                label="Basic Salary"
                                fieldName="basic_salary"
                                fieldValue={values?.basic_salary}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`basic_salary`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />   
                              <InputText
                                label="Salary Transportation"
                                fieldName="sal_transportation"
                                fieldValue={values?.sal_transportation}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`sal_transportation`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />   
                             
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                               <InputText
                                label="Salary Accomodation"
                                fieldName="sal_accomodation"
                                fieldValue={values?.sal_accomodation}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`sal_accomodation`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />  
                               <InputText
                                label="Salary Overtime"
                                fieldName="sal_overtime"
                                fieldValue={values?.sal_overtime}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`sal_overtime`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              /> 
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="Labour Card No."
                                fieldName="employment_labour_card"
                                fieldValue={values?.employment_labour_card}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`employment_labour_card`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Labour Card Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={cardExpiry}
                                      onChange={(date) => {
                                        setCardExpiry(date);
                                        setFieldValue("employment_labour_expiry_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Labour Card Expiry Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Visa Issuey Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={visaIssue}
                                      onChange={(date) => {
                                        setVisaIssue(date);
                                        setFieldValue("employment_visa_issue_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Issuey Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Visa Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={visaExpiry}
                                      onChange={(date) => {
                                        setVisaExpiry(date);
                                        setFieldValue("employment_visa_expiry_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Expiry Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                            <InputText
                                label="Passport No."
                                fieldName="employment_passport"
                                fieldValue={values?.employment_passport}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`employment_passport`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Passport Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={passportExpiry}
                                      onChange={(date) => {
                                        setPassportExpiry(date);
                                        setFieldValue("employment_passport_expiry_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Passport Expiry Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <CustomDropdown
                                label="Nationality "
                                fieldOptions={CountryList}
                                fieldValue={values?.employment_nationality}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`employment_nationality`, value.value);
                                  
                                }}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Card Type  "
                                fieldOptions={LabourCardTypeOptions}
                                fieldValue={values?.employment_card_type}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`employment_card_type`, value.value);
                                  
                                }}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <CustomDropdown
                                label="Labour Card Status  "
                                fieldOptions={LabourCardStatusOptions}
                                fieldValue={values?.employment_labour_card_status}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`employment_labour_card_status`, value.value);
                                  
                                }}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <CustomDropdown
                                label="Card Status"
                                fieldOptions={CardStatusOptions}
                                fieldValue={values?.card_status_id}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`card_status_id`, value.value);
                                  
                                }}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal} >
                               <BootstrapTooltip arrow={true}
                        disableFocusListener
                        title={
                            errors.employment_emp_name === undefined
                            && errors.employment_emp_email === undefined
                            && errors.employment_emp_mob === undefined
                            && errors.employment_designation === undefined
                            && errors.basic_salary === undefined
                            && errors.sal_transportation === undefined
                            && errors.sal_accomodation === undefined
                            && errors.sal_overtime === undefined
                            && errors.employment_labour_card === undefined
                            && errors.employment_labour_expiry_date === undefined
                            && errors.employment_visa_issue_date === undefined
                            && errors.employment_visa_expiry_date === undefined
                            && errors.employment_passport === undefined
                            && errors.employment_passport_expiry_date === undefined
                            && errors.employment_nationality === undefined
                            && errors.employment_card_type === undefined
                            && errors.employment_labour_card_status === undefined
                            && errors.card_status_id === undefined
                            ? "" :
                            `
                          ${errors.employment_emp_name !== undefined ? `${errors.employment_emp_name},` : ""}
                          ${errors.employment_emp_email !== undefined ? `${errors.employment_emp_email},` : ""}
                          ${errors.employment_emp_mob !== undefined ? `${errors.employment_emp_mob},` : ""}
                          ${errors.employment_designation !== undefined ? `${errors.employment_designation},` : ""}
                          ${errors.basic_salary !== undefined ? `${errors.basic_salary},` : ""}
                          ${errors.sal_transportation !== undefined ? `${errors.sal_transportation},` : ""}
                          ${errors.sal_accomodation !== undefined ? `${errors.sal_accomodation},` : ""}
                          ${errors.sal_overtime !== undefined ? `${errors.sal_overtime},` : ""}
                          ${errors.employment_labour_card !== undefined ? `${errors.employment_labour_card},` : ""}
                          ${errors.employment_labour_expiry_date !== undefined ? `${errors.employment_labour_expiry_date},` : ""}
                          ${errors.employment_visa_issue_date !== undefined ? `${errors.employment_visa_issue_date},` : ""}
                          ${errors.employment_visa_expiry_date !== undefined ? `${errors.employment_visa_expiry_date},` : ""}
                          ${errors.employment_passport !== undefined ? `${errors.employment_passport},` : ""}
                          ${errors.employment_passport_expiry_date !== undefined ? `${errors.employment_passport_expiry_date},` : ""}
                          ${errors.employment_nationality !== undefined ? `${errors.employment_nationality},` : ""}
                          ${errors.employment_card_type !== undefined ? `${errors.employment_card_type},` : ""}
                          ${errors.employment_labour_card_status !== undefined ? `${errors.employment_labour_card_status},` : ""}
                          ${errors.card_status_id !== undefined ? `${errors.card_status_id},` : ""}
                      `
                        }
                      >

                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{ backgroundColor: "#eb6e12" }}
                          onClick={handleSubmit}
                        >
                          {"Save"}
                        </Button>
                      </BootstrapTooltip>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>

                </div>
              </Box>

            </form>
          );
        }}
      </Formik>

    </>
  );
};

export default withStyles(ModalPopperFormStyles)(AddWorkerForm);
