import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow: 'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if (height && !getIn(errors, fieldName)) {
    return { height: height }
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const AddFamilyForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    getRequistionListingDataFromApi,
    getViewCompanyFamilyApi,
    viewCompanyFamilyData,
    salesInfo,
    operationInfo
  } = useContext(SupportDataContext);

  const [passportExpiry, setPassportExpiry] = useState(
    editModalPopper ? new Date(new Date(moment(salesInfo?.Investor_Passport_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      moment().toDate())
  const [visaIssue, setVisaIssue] = useState(
    editModalPopper ? new Date(new Date(moment(salesInfo?.Investor_Visa_Issue_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      moment().toDate())
  const [visaExpiry, setVisaExpiry] = useState(
    editModalPopper ? new Date(new Date(moment(salesInfo?.Investor_Visa_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      moment().toDate())

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className, }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },

  }));

  const CountryList = viewCompanyFamilyData?.Nationality_List?.map((item) => {
    const obj = { value: item.name, label: item.name };
    return obj;
  });
  const CardStatusOptions = viewCompanyFamilyData?.Card_Status_List?.map((item) => {
    const obj = { value: item.Status_Id, label: item.Status_Name };
    return obj;
  });

 
  

  const addFamilyInfoPostAPI = async (values) => {
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const factory_id = localStorage.getItem("factory_id");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/get_family_details/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      factory_id: Number(factory_id),
      company_info_id: Number(operationInfo?.Id),
      company_id: Number(operationInfo?.Company_Id),
      investor_client_name: values.investor_name,
      investor_email: values.investor_email,
      investor_mob: values.investor_mobile,
      investor_nationality: values.nationality,
      investor_passport_expiry_date: values.investor_passport_expiry_date,
      investor_visa_issue_date: values.investor_visa_issue_date,
      investor_visa_expiry_date: values.investor_visa_expiry_date,
      family_relation: values.family_relation,
      card_status_id: values.card_status_id,
    }

    const formData = new FormData();
    formData.append("factory_id", factory_id);
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("company_info_id", Number(operationInfo?.Id));
    formData.append("company_id", Number(operationInfo?.Company_Id));
    formData.append("investor_client_name", values?.investor_name);
    formData.append("investor_email", values?.investor_email);
    formData.append("investor_mob", values?.investor_mobile);
    formData.append("investor_nationality", values?.nationality);
    formData.append("investor_passport_expiry_date", moment(values?.investor_passport_expiry_date).format("YYYY-MM-DD"));
    formData.append("investor_visa_issue_date", moment(values?.investor_visa_issue_date).format("YYYY-MM-DD"));
    formData.append("investor_visa_expiry_date", moment(values?.investor_visa_expiry_date).format("YYYY-MM-DD"));
    formData.append("family_relation", values?.family_relation);
    formData.append("card_status_id", Number(values?.card_status_id));


    if (editModalPopper) {

      formData.append("family_id", Number(salesInfo?.Family_Id));

      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        //handleQuotationPromise(data);
        getViewCompanyFamilyApi();
      })
    }
    else {
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        getViewCompanyFamilyApi();
      })
    }
  }

  useEffect(() => {

    setLoading(true);
    getViewCompanyFamilyApi();

  }, [])

  return (
    <>
      <Formik
        initialValues={{
          investor_name: editModalPopper ? salesInfo?.Investor_Client_Name : "",
          investor_email: editModalPopper ? salesInfo?.Investor_Email : "",
          investor_mobile: editModalPopper ? salesInfo?.Investor_Mobile : "",
          nationality: editModalPopper ? salesInfo?.Investor_Nationality : "",
          investor_passport_expiry_date: editModalPopper ? passportExpiry : "",
          investor_visa_expiry_date: editModalPopper ? visaExpiry : "",
          investor_visa_issue_date: editModalPopper ? visaIssue : "",
          family_relation: editModalPopper ? salesInfo?.Investor_Relation : "",
          card_status_id: editModalPopper ? salesInfo?.Card_Status_Id : "",
        }}
        onSubmit={async (data) => {
          setLoading(true)
          addFamilyInfoPostAPI(data);
          handleModaleClose();
        }}
        validate={(values) => {
         

          let errors = {};

          if (values.investor_name === "") {
            errors.investor_name = "Name is required";
          }
          if (values.investor_email === "") {
            errors.investor_email = "Email is required";
          }
          if (values.investor_mobile === "") {
            errors.investor_mobile = "Mobile No is required";
          }
          if (values.nationality === "") {
            errors.nationality = "Nationality is required";
          }
          if (values.investor_passport_expiry_date === "") {
            errors.investor_passport_expiry_date = "Passport expiry date is required";
          }
          if (values.investor_visa_expiry_date === "") {
            errors.investor_visa_expiry_date = "Visa expiry date is required";
          }

          if (values.investor_visa_issue_date === "") {
            errors.investor_visa_issue_date = "Visa issue date is required";
          }
          if (values.family_relation === "") {
            errors.family_relation = "Family relation is required";
          }
          if (values.card_status_id === "") {
            errors.card_status_id = "Card status is required";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div style={{ height: "450px", overflowX: "hidden", padding: "0" }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            {editModalPopper ? "Edit Family Information" : "Add Family Information"}{``}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>

                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="Company Name"
                                fieldName="Company_Name"
                                fieldValue={operationInfo?.Company_Name}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  // setFieldValue(`Company_Name`, value);
                                }}
                                disableField={true}
                              // disabled={true}
                              // classvalue={"textBold"}
                              //  errorField={getStyles(errors)}
                              // requiredField={true}
                              />
                              <InputText
                                label="Investor Name"
                                fieldName="investor_name"
                                fieldValue={values?.investor_name}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_name`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                              <InputText
                                label="Email"
                                fieldName="investor_email"
                                fieldValue={values?.investor_email}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_email`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />

                              <InputText
                                label="Mobile"
                                fieldName="investor_mobile"
                                fieldValue={values?.investor_mobile}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_mobile`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                              <CustomDropdown
                                label="Nationality"
                                fieldOptions={CountryList}
                                fieldValue={values?.nationality}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`nationality`, value.value);
                                  
                                }}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Passport Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={passportExpiry}
                                      onChange={(date) => {
                                        setPassportExpiry(date);
                                        setFieldValue(
                                          "investor_passport_expiry_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Passport Expiry Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                  Visa Issue Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={visaIssue}
                                      onChange={(date) => {
                                        setVisaIssue(date);
                                        setFieldValue(
                                          "investor_visa_issue_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Isssue Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                  Visa Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={visaExpiry}
                                      onChange={(date) => {
                                        setVisaExpiry(date);
                                        setFieldValue("investor_visa_expiry_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Expiry Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                              <InputText
                                label="Family Relation"
                                fieldName="family_relation"
                                fieldValue={values?.family_relation}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`family_relation`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                              <CustomDropdown
                                label="Card Status"
                                fieldOptions={CardStatusOptions}
                                fieldValue={values?.card_status_id}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`card_status_id`, value.value);
                                  
                                }}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>


                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal} >

                      <BootstrapTooltip arrow={true}
                        disableFocusListener
                        title={
                          errors.investor_name === undefined
                            && errors.investor_email === undefined
                            && errors.investor_mobile === undefined
                            && errors.nationality === undefined
                            && errors.investor_passport_expiry_date === undefined
                            && errors.investor_visa_expiry_date === undefined
                            && errors.investor_visa_issue_date === undefined
                            && errors.family_relation === undefined
                            && errors.card_status_id === undefined
                            ? "" :
                            `
                          ${errors.investor_name !== undefined ? `${errors.investor_name},` : ""}
                          ${errors.investor_email !== undefined ? `${errors.investor_email},` : ""}
                          ${errors.investor_mobile !== undefined ? `${errors.investor_mobile},` : ""}
                          ${errors.nationality !== undefined ? `${errors.nationality},` : ""}
                          ${errors.investor_passport_expiry_date !== undefined ? `${errors.investor_passport_expiry_date},` : ""}
                          ${errors.investor_visa_expiry_date !== undefined ? `${errors.investor_visa_expiry_date},` : ""}
                          ${errors.investor_visa_issue_date !== undefined ? `${errors.investor_visa_issue_date},` : ""}
                          ${errors.family_relation !== undefined ? `${errors.family_relation},` : ""}
                          ${errors.card_status_id !== undefined ? `${errors.card_status_id},` : ""}
                      `
                        }
                      >

                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{ backgroundColor: "#eb6e12" }}
                          onClick={handleSubmit}
                        >
                          {"Save"}
                        </Button>
                      </BootstrapTooltip>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>

                </div>
              </Box>

            </form>
          );
        }}
      </Formik>

    </>
  );
};

export default withStyles(ModalPopperFormStyles)(AddFamilyForm);
