import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "580px",
  maxHeight: "600px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const OrderEscalationForm = ({ classes, setOpenModal }) => {
  const {
    setLoading,
    loading,
    setMessage,
    setSeverity,
    operationInfo,
    getOverDueOrdersDataApi,
    setIsOpen,
    overDueOrdersData,
    handleModaleClose,
    editModalPopper,
    getOrderEscalationData,
    rowData

  } = useContext(SupportDataContext);
  const [editData, setEditData] = useState(null);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className, }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#d11616",
    },
}));

  console.log("operationInfo", operationInfo);

  const priorityListOptions = editData?.Priority_List?.map((item) => {
    const options = {value: item, label: item }
    return options;
  });
  const employeeListOptions = editData?.Employee_List?.map((item) => {
    const options = {value: item?.EmpId, label: item?.Emp_Name }
    return options;
  });

  const getEditDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const escalation_id  = operationInfo?.escalation_id  || 0

    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/support_api/OrderEscalation/?emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo.Order_No}&escalation_id=${escalation_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setEditData(res?.data?.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const orderEscalationPostApi = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/support_api/OrderEscalation/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      order_no: operationInfo?.Order_No,
      order_id: operationInfo?.Order_Id,
      priority: values?.priority,
      complain_emp_id: values.complain_emp_id,
      complain_emp_name: values.complain_emp_name,
      remarks: values?.remarks,
    };
    if (editModalPopper) {
      body = {
        ...body,
        escalation_id: rowData?.escalation_id,
      }
      axios.put(url, body, { headers: headers }).then((data) => {
          setLoading(false);
          handleQuotationPromise(data);
      })
  } else {
      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    }
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getOrderEscalationData();
    handleModaleClose();
  };

  useEffect(() => {
    getEditDataApi();
}, [])


  return (
    <Formik
      //enableReinitialize
      initialValues={{
        priority: editModalPopper ? rowData.priority : '',
        complain_emp_id: editModalPopper ? rowData.complain_emp_id : '',
        complain_emp_name: editModalPopper ? rowData.complain_emp_name : '',
        remarks: editModalPopper ? rowData.support_remarks : '',
      }}
      validate={(values) => {
        let errors = {};
        console.log("values", values)
        if (values.priority === "") {
          errors.priority = "Priority is required";
        }
        if (values.complain_emp_id === "") {
          errors.complain_emp_id = "Employee is required";
        }
        return errors;
      }}
      onSubmit={(data) => {
        setLoading(true);
        orderEscalationPostApi(data)
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      handleModaleClose();
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "540px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                         {editModalPopper ? "Edit Order Escalation" : "Add Order Escalation"}
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                          <InputText
                         label="Order No"
                         fieldName="Order_No"
                         fieldValue={operationInfo.Order_No}
                         fieldHandleChange={(e) => {
                             const { value } = e.target;
                             setFieldValue(`Order_No`, value);
                         }}
                        //  errorField={getStyles(errors, values?.Order_No)}
                        //  requiredField={true}
                        disableField={true}
                     />
                      <InputText
                         label="Company Name"
                         fieldName="Company_Name"
                         fieldValue={operationInfo.Company_Name}
                         fieldHandleChange={(e) => {
                             const { value } = e.target;
                             setFieldValue(`Company_Name`, value);
                         }}
                         disableField={true}
                     />
                     <InputText
                         label="Support Name"
                         fieldName="support_name"
                         fieldValue={operationInfo.Support_Emp_Name}
                         fieldHandleChange={(e) => {
                             const { value } = e.target;
                             setFieldValue(`support_name`, value);
                         }}
                         disableField={true}
                     />
                    <CustomDropdown
                      label="Employee Name"
                      fieldOptions={employeeListOptions}
                      fieldValue={values?.complain_emp_id}
                      fieldHandleChange={(value) => {
                        setFieldValue(`complain_emp_id`, value.value);
                        setFieldValue(`complain_emp_name`, value.label);
                      }}
                      requiredField={true}
                    />
                    <CustomDropdown
                      label="Priority"
                      fieldOptions={priorityListOptions}
                      fieldValue={values?.priority}
                      fieldHandleChange={(value) => {
                        setFieldValue(`priority`, value.value);
                      }}
                      requiredField={true}
                    />
                      <CustomTextArea
                          label="Remarks"
                          fieldName="remarks"
                          fieldValue={values.remarks}
                          fieldHandleChange={(e) => {
                              e.preventDefault();
                              const { value } = e.target;
                              setFieldValue(`remarks`,value );
                          }}
                          rows="6"
                        //   errorField={getStyles(errors, values?.remarks)}
                        //   requiredField={true}
                      />
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                  <BootstrapTooltip arrow={true}
                        disableFocusListener
                        title={
                            errors.priority === undefined &&
                            errors.complain_emp_id === undefined 
                            ? ""
                            :
                            `
                            ${errors.priority !== undefined ? `${errors.priority},` : ""}
                             ${errors.complain_emp_id !== undefined ? `${errors.complain_emp_id},` : ""}
                           
                            `
                           }
                            >
                     <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                </BootstrapTooltip>
                    
                    <Button
                      onClick={() => {
                        handleModaleClose();
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(OrderEscalationForm);
