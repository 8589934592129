import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import { DataContext, SupportDataContext } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import ModalPopper from "../Modals/ModelPopper";
  import { Button } from "react-bootstrap";
  import Table from 'react-bootstrap/Table';
  import ApprovalIcon from '@mui/icons-material/Approval';
  import GradingIcon from '@mui/icons-material/Grading';
  import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
  import { Grid } from "@material-ui/core";
  import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
  import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
  import PreviewIcon from '@mui/icons-material/Preview';
  import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
  import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
  import { useFormik, Formik } from "formik";
  import LoaderClock from "../../../lodar/LoaderClock";
  import {
    Modal,
    Box,
  } from '@mui/material'
  import { css } from "@emotion/react"
  import ClockLoader from 'react-spinners/ClockLoader';
  import CommentIcon from '@mui/icons-material/Comment';
  import CustomDateRangePicker from "../../../../reusableComponents/CustomDatePicker/CustomDateRangePicker";
  import Col from "react-bootstrap/Col";
  import Form from "react-bootstrap/Form";
  import Row from "react-bootstrap/Row";
  
  
  
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const ClientReviewOnOrderReport = ({
    apiData,
    upperIcons,
    lowerIcons,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    fitlerBadge
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setEditModalPopper,
      handleModalOpen,
      setAcceptAccountArray,
      getPropertyFormDataApi,
      message, severity,
      isOpen, setIsOpen,
      getOrderDropDownApi,
      getOrderDropDownForCompany,
      setIsCompleteOrderBtnDisable,
      setSalesInfo, loading,
      getSupportOrdersDataApi, SupportOrdersData,
      getClientReviewOnOrderDataApi, clientReviewOnOrderData,
      
    } = useContext(SupportDataContext);
  
    const {
      start_Date, end_Date,
      setStart_Date, setEnd_Date
    } = useContext(DataContext);
  
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const todayDate = moment().format("YYYY-MM-DD");
    const [reqFromDate, setReqFromDate] = useState("");
    const [reqToDate, setReqToDate] = useState("");
    const [supportId, setSupportId] = useState(0);
    const [resetDate, setResetDate] = useState(false);

    
    const employeeOptions = clientReviewOnOrderData?.Support_Emp_List?.map((item) => {
      const obj = { value: item.EmpId, label: item.Emp_Name };
      return obj;
    });
  
    useEffect(() => {
      getClientReviewOnOrderDataApi();
    }, [resetDate]);
  

    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue.split(" ");
        if (dateAsString == null) {
          return -1;
        }
        if (dateAsString == "") {
          return -1;
        }
        const dateParts = dateAsString[0].split("-");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
    const paramsExpendableRows = (params) => {
      return {
        getDetailRowData: function (params) {
          params.successCallback(params.data.callRecords);
        },
      };
    };
  
   const dateFilterParams = {
      inRangeInclusive: true,
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        if (dateAsString == null) {
          return 0;
        }
        const dateParts = dateAsString.slice(0, 10).split("-");
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
  
    const columnDefs = [
      {
        headerName: "Sr.",
        field: "Serial_Number",
        //cellRenderer: "agGroupCellRenderer",
        //checkboxSelection: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 75,
        pinned: "left",
        filter: false,
      },
      {
        headerName: "Order No",
        field: "Order_No",
        filter: "agTextColumnFilter",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
        width: "auto",
        pinned: "left",
      },

      {
        headerName: "Order Name",
        field: "Order_Name",
        filter: "agTextColumnFilter",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
        width: 250,
      },
      {
        headerName: "Authority",
        field: "Authority",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
      },
      {
        headerName: "Uploaded Docs",
        field: "Upload_Image",
        cellStyle: {textAlign: "center", borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
      cellRenderer: (row) => {
        return (
          <>
            <Button
              style={{
                background: "#eb6e12",
                padding: ".4rem",
                textDecorationColor: "white",
                cursor: "pointer",
                border: "none",
              }}
              onClick={() => window.open(row.data.Upload_Image)}
            >
              View
            </Button>
          </>
        );
      },
    },
      {
        headerName: "Remarks",
        field: "Remarks",
        filter: "agTextColumnFilter",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        minWidth: 370,
        flex: 1,
      },
      {
        headerName: "Reviewed Date",
        field: "Created_Date",
        filterParams: dateFilterParams,
        filter: "agDateColumnFilter",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
        cellRenderer: (params) => {
          return (
            <>
              {params?.data?.Created_Date === "" || params?.data?.Created_Date === "NA" ? "" :
                moment(params?.data?.Created_Date).format("DD-MM-YYYY HH:mm:ss a")
              }
            </>
          )
        }
      },
      {
        headerName: "Reviewed By",
        field: "Created_By",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
      },
      {
        headerName: "Reviewed By Role",
        field: "Created_By_Role",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
      },
    
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
  
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue.split(" ");
              if (dateAsString == null) {
                return 0;
              }
              const dateParts = dateAsString[0].split("-");
              const day = Number(dateParts[2]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
          },
        },
      };
    }, []);
  
  
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const filters = [
      // {
      //   id: 1,
      //   title: "Default",
      //   value: null,
      //   key: null,
      // },
     
    ];
  
  
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const handleFilters = (title, value, key) => {
  
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.onFilterChanged();
      if (key === null) {
        gridRef.current.api.setFilterModel(null);
      } else if (key === "Enquiry_Assigned_Date") {
        gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
          type: "contains",
          dateFrom: value,
          dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
      } else if (key === "}") {
      } else {
        gridRef.current.api
          .getFilterInstance(key.toString())
          .setModel({ values: [value.toString()] });
        gridRef.current.api.onFilterChanged();
      }
  
      setDefaultFilterValue(title);
    };
  
  
    const getTotalLength = (value, key) => {
      if (apiData === null || (key === null && value === null)) {
        return;
      } else if (key === null) {
        return apiData.length;
      } else if (key === "Enquiry_Assigned_Date") {
        return apiData.filter((val) => val[key].substring(0, 10) === value)
          .length;
      } else {
        return apiData.filter((val) => val[key] === value).length;
      }
    };
  
    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    const handleSearch = (searchName) => {
      handleFilters(searchName, searchName, "Inquiry_Id")
    }
  
    const override = css`
                      display: block;
                      margin: 0 auto;
                      border-color: '#eb6e12';
                      box-shadow: inset '0px 0px 0px 7px #eb6e12';
                      margin-top: 10%;
                      `;
    return (
  
      <div className="listing-data-grid" style={{ height: "500px" }}>
        <>
        <div style={{ display: "flex", marginLeft: "10px", marginTop: "50px" }}>
      <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-35px",
              }}
            >
              <Grid container>
              <Grid item sm={12} md={3} style={{ paddingRight: "1rem", }}>
              <CustomDropdown
                    label="RO Name"
                    fieldOptions={employeeOptions}
                    fieldValue={supportId}
                    fieldHandleChange={(value) => {
                      setSupportId(value.value);
                    }}
                  />
                  </Grid>
                  <Grid item sm={12} md={4} style={{ paddingRight: "0rem", }}>
                   <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
                    <Form.Label
                      column
                      sm="4"
                      style={{ fontWeight: "700", fontSize: 12 }}
                    >
                    Select Reviewed Date:
                    </Form.Label>
                    <Col sm="8" style={{ padding: "0 12px 0 0" }}>
                      <div style={{ marginTop: "0px", }}>
                        <CustomDateRangePicker />
                        </div>
                        </Col>
                       
                  </Form.Group>
                        </Grid>
                  <Grid item sm={12} md={4} >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            getClientReviewOnOrderDataApi(supportId, start_Date, end_Date);
                          }}
                          style={{
                            backgroundColor: "#eb6e12",
                            padding: "4px",
                            color: "white",
                            height: "fit-content",
                          }}
                          disabled={
                            start_Date === "" || start_Date === undefined
                            || end_Date === "" || end_Date === undefined
                          }
                        >
                          Filter
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setStart_Date();
                            setEnd_Date();
                            setResetDate(true);
                          }}
                          style={{
                            backgroundColor: "#eb6e12",
                            padding: "4px",
                            color: "white",
                            height: "fit-content",
                            marginLeft: "20px"
                          }}
                          disabled={
                            start_Date === "" || start_Date === undefined
                            || end_Date === "" || end_Date === undefined
                          }
                        >
                          Reset
                        </Button>
                        </Grid>
                      
                      <div style={{ marginTop: "0px", width: "100px" }}>

                      </div>
                    
                </Grid>
                
            </form>
          )}
        </Formik>
      </div>
          <CommonPageSelect
            search
            onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
            searchValue={searchValue}
            onPageSizeChanged={onPageSizeChanged}
            handleGotoPage={handleGotoPage}
            fromRows={fromRows}
            toRows={toRows}
            length={SupportOrdersData?.length}
          />
           
          <div
            style={{ height: "100%", width: "100%" }}
            className="ag-theme-alpine"
          >
            {loading ? (
              <>
                <div >
                  <ClockLoader
                    css={override}
                    size={50}
                    color={"#eb6e12"}
                    loading={loading} />
                </div>
              </>
            ) : (
              <AgGridReact
                suppressExcelExport={true}
                suppressCsvExport={true}
                ref={gridRef}
                rowData={clientReviewOnOrderData?.Order_Review_Report_List}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                masterDetail={true}
                //detailCellRenderer={renderExpandableRow}
                columnTypes={columnTypes}
                animateRows={true}
                // onSelectionChanged={onRowSelected}
                components={components}
                detailCellRendererParams={paramsExpendableRows}
                rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
                rowHeight={90}
                pagination={true}
                detailRowAutoHeight={true}
                onPaginationChanged={onPaginationChanged}
                overlayLoadingTemplate={"Please Wait Loading Data..."}
                overlayNoRowsTemplate={"No Data...."}
                enableCellChangeFlash={true}
                
              ></AgGridReact>
            )}
          </div>
          <ModalPopper />
         
        </>
  
  
      </div>
    );
  };
  
  export default ClientReviewOnOrderReport;
  