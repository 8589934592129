import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import PendingRequistionListing from "./PendingRequistionListing";
import { VpnLock } from "@material-ui/icons";
import CircularProgress from "@mui/material/CircularProgress";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding:"25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow:'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if(height && !getIn(errors, fieldName) ){
    return {height:height}
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height:height
    };
  } else if(getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const RequistionEditModalForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    requistionListing,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getRequistionListingDataFromApi
  } = useContext(SupportDataContext);
  const factory_id = localStorage.getItem("factory_id");
  const [mainTask, setMainTask] = useState(null);
  const [zohoBalance, setZohoBalance] = useState(null);
  const [pendingReqListing, setPendingReqListing] = useState(null);
  const [enableReinitialize, setEnablReinitializei] = useState(false);
  const [autoReqId, setAutoReqId] = useState();
  const zohoBalanceRef = useRef(null);
  const fileInput = React.createRef();

  const sow_type_dropdown = requistionListing?.SOW_List?.map((item) => {
    const obj = { task_name: item.main_task_name, value: item.main_sow_task_name, label: item.main_sow_task_name, id:item?.main_task_id, days:item?.main_task_days,  task_sequence: item?.main_task_sequence  };
    return obj;
  });
  
  const work_type_options = requistionListing?.Work_Type_List?.map((item) => {
    const obj = { value: item.Value, label: item.Work_Type_Name };
    return obj;
  });

  const typist_name_options = requistionListing?.Typiest_List?.map((item) => {
    const obj = { value: item.Emp_Id, label: item.Emp_Name, id:item?.Emp_Id, type: item?.Emp_Type  };
    return obj;
  });


 const requistionPostAPI = async(values) => {
  const user_type = localStorage.getItem('user_type');
  const emp_id = localStorage.getItem("emp_id");
  const __token = localStorage.getItem("token");
  const user_name = localStorage.getItem("Employee_First_Name");
  const url = `https://flyingcolour.online/fcapp/support_api/supportaddrequisition/`;
  const headers = {
    Authorization: `Token ${__token}`,
    "Content-Type": "application/json",
  };

  let autoReqNo = [];
  let reqAmount = [];
  let clientPreferDate = [];
  let reqWorkType = [];
  let reqRemarks = [];
  let typistName = [];
  let taskName = [];
  let taskDays = [];
  let taskSeq = [];
  let reqStartDate = [];
  let reqEndDate = [];
  let files = [];
  let typistId= [];
  let selfAssi = [];
  let reqDays = [];
  autoReqNo.push(values?.requistionId);

  values?.mainTaskArray?.map((value, i) => {
    
    reqAmount.push(value?.amount);
    reqWorkType.push(value?.typeofwork);
    typistName.push(value?.typistName);
    taskName.push(value?.taskName);
    taskDays.push(value?.taskDays);
    taskSeq.push(value?.taskSequence)
    reqStartDate.push(value?.fromDate);
    reqEndDate.push(value?.toDate);
    reqRemarks.push(value?.remarks);
    reqDays.push(value.reqDays);

    if(value?.files){
      files.push(value?.uploadDoc);
    }

    if(value?.typeofwork === "2") {
      selfAssi.push(value?.selfAssigned);
    } else {
      selfAssi.push("No");
    }

    if(value?.typeofwork === "1") {
      clientPreferDate.push(value?.clientDate_time);
    } else {
      clientPreferDate.push("No");
    }

    if(i !== 0) {
      autoReqNo.push(value?.requistionId);
    }

    if(value?.selfAssigned === "Yes") {
      typistId.push(emp_id)
    } else {
      typistId.push(value?.typistName)
    }

  })


  let body = {
    factory_id: Number(factory_id),
    order_no:operationInfo?.order_id,
    emp_id: Number(emp_id),
    user_type:user_type,
    user_name:user_name,
    auto_requisition_no: autoReqNo.toString(),
    main_task_id: Number(mainTask?.id),
    main_task_days: Number(mainTask?.days),
    main_sow_task_name:mainTask?.value,
    zoho_balance_on_requisition_amount:zohoBalanceRef.current,
    requisition_amount:reqAmount?.toString(),
    req_company_id: Number(operationInfo?.req_company_id),
    client_prefer_datetime_location:clientPreferDate.toString(),
    requisition_work_type:reqWorkType?.toString(),
    remarks:reqRemarks?.toString(),
    typist_emp_id:typistId.toString(),
    task_name:taskName?.toString(),
    task_days:taskDays?.toString(),
    task_sequence:taskSeq.toString(),
    requisition_start_date:reqStartDate.toString(),
    requisition_end_date:reqEndDate?.toString(),
    requisition_balance_approval_file:files.toString(),
    is_self_assign_by_ro:selfAssi?.toString(),
    requisition_no_days: reqDays.toString(),
    update_req_id: viewRequistion?.requisition_id
  }
  if(mainTask?.days === "1"){
    body = {
      ...body,
      requisition_type: values?.requistionType
    }
  }

  if(values?.requistionType === "Urgent"){
    body = {
      ...body,
      urgent_date: values?.requistionDate
    }
  }
    axios.put(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      if(data?.data?.message)
      {
        setMessage(`Not Updated: ${data?.data?.message}`);
        setSeverity(500)
      }
      else{
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code)
      }        
      setIsOpen(true);  
      //handleQuotationPromise(data);
    })
 }

 const zohoBalanceAPI = async() => {
  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const emp_id = localStorage.getItem("emp_id");

  //setLoading(true);
  try {
    const url = `https://flyingcolour.online/fcapp/support_api/zohobalancedetailsview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.req_company_id}`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const res = await axios.get(url, { headers });
    zohoBalanceRef.current = res?.data?.results?.availableZoho_Balance ;
    setEnablReinitializei(true)
    let value = '';
    if(res?.data?.results?.availableZoho_Balance > 0){
      value = `${res?.data?.results?.availableZoho_Balance}  - POSITIVE`
      setZohoBalance(value);
    } else if(res?.data?.results?.availableZoho_Balance < 0) {
      value = `${res?.data?.results?.availableZoho_Balance}  - NEGATIVE`
      setZohoBalance(value);
    } else {
      value = `Company doesn't exist on  Zoho`
      setZohoBalance(value);
    }
    setTimeout(() => {
      setEnablReinitializei(false);
      setLoading(false)
    }, 500)
    return value;
   
    //setLoading(false);
  } catch (err) {
    console.error(err);
  }
 }
 
 useEffect(() => {
  // const mainTaskFind = sow_type_dropdown?.find((val) => val.id === viewRequistion?.req_task_to_pro);
  //   setMainTask(mainTaskFind)
    zohoBalanceAPI();
    // PendingRequistionListingAPi();
    setLoading(true);
    getRequistionListingDataFromApi();
    
  }, [])

 const handleSameAsTask = (e, setFieldValue, values) => {
  e.preventDefault();
  setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskName`, mainTask?.task_name);
  setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskDays`,mainTask?.days);
  setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskSequence`,mainTask?.task_sequence);
 }
const StartDate = moment(viewRequistion?.startdate).format("DD-MM-YYYY");
const EndDate = moment(viewRequistion?.enddate).format("DD-MM-YYYY");
const mainTaskFind = sow_type_dropdown?.find((val) => val?.id === viewRequistion?.req_task_to_pro);
  return (
    <>
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={{
        orderId: operationInfo?.order_id,
        companyName:  viewRequistion?.company_name,
        requistionId:  viewRequistion?.requisition_auto_id,
        requistionDateTime:moment(new Date()).format("DD-MM-YYYY hh:mm:ss a"),
        zohoExpenses:zohoBalance,
        accountPendingReqAmount:viewRequistion?.req_amt,
        sow_mainTask: mainTaskFind?.value,
        requistionType: viewRequistion?.Requisition_Type,
        requistionDate: viewRequistion?.urgent_date,
        mainTaskArray: [{
          taskName: viewRequistion?.subTaskName,
          taskDays: viewRequistion?.main_task_days,
          taskSequence: viewRequistion?.sub_task_sequance,
          typeofwork: Number(viewRequistion?.requisition_work_type),
          amount: viewRequistion?.req_amt ,
          fromDate: moment(viewRequistion?.startdate).format("DD-MM-YYYY hh:mm:ss"),
          toDate: moment(viewRequistion?.enddate).format("DD-MM-YYYY hh:mm:ss"),
          typistName: Number(viewRequistion?.req_typist_id),
          clientDate_time: viewRequistion?.Client_Preffer_Datetime_Location,
          selfAssigned: viewRequistion?.is_self_assign_by_ro,
          remarks: viewRequistion?.remarks,
          reqDays:viewRequistion?.main_task_days,
          uploadDoc: viewRequistion?.requisition_balance_approval_file,
          requistionId: ""
        }]
      }}
      onSubmit={async (data) => {
        setLoading(true)
        requistionPostAPI(data);
        handleModaleClose();
      }}
      validate={(values) => {
        let errors = {};

        if (!values.sow_mainTask) {
          errors.sow_mainTask = "required";
        }

        values?.mainTaskArray?.forEach((val) => {
          if (val.taskName === "") {
            errors.taskName = "Required!!";
          }
          if (val.taskDays === "") {
            errors.taskDays = "Required!!";
          }
          if (val.taskSequence === "") {
            errors.taskSequence = "Required!!";
          }
          if (val.typeofwork === "") {
            errors.typeofwork = "Required!!";
          }
          if (val.amount === "") {
            errors.amount = "Required!!";
          }
          // if (val.fromDate === "") {
          //   errors.fromDate = "Required!!";
          // }
          // if (val.toDate === "") {
          //   errors.toDate = "Required!!";
          // }
        })
      
        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={(e) => {e.preventDefault()}}>
            <Box sx={style}>
            <div>
                <div style={{ height: "36px" }}>
              <IconButton
                style={{ float: "right" }}
                className={classes.quotationModalHeaderIconCancle}
                onClick={() => {
                  handleModaleClose();
                }}
              >
                <CloseIcon
                  className={classes.quotationModalHeaderIconCancleIcon}
                />
              </IconButton>
              </div> 
              <div style={{ height: "600px", overflowX: "hidden",padding: "0" }}>
              <Grid container spacing={2} columns={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "-30px",
                    paddingBottom: "10px",
                    marginTop: "-45px",
                    zIndex:10
                  }}
                >
                  <br />
                  <div className={classes.quotationModalHeadingContainer}>
                    <span className={classes.quotationModalHeader}>
                      Edit Requistion
                    </span>
                  </div>
                  <br />
                  <div className={classes.quotationModalFlexDivContaner}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.quotationInnerGridRight}
                      >
                        <InputText
                            label="Order Id"
                            fieldName="Requistion_Id"
                            fieldValue={values?.orderId}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                      <InputText
                            label="ZOHO Govt. Expense of Client (AED)"
                            fieldName="Requistion_Id"
                            fieldValue={values?.zohoExpenses}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />

                         <InputText
                            label="Requistion Id"
                            fieldName="Requistion_Id"
                            fieldValue={values?.requistionId}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                             <InputText
                            label="Start Date"
                            fieldName="Requistion_Id"
                            fieldValue={StartDate}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                      
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.quotationInnerGridRight}
                      >
                          <InputText
                            label="Company Name"
                            fieldName="Requistion_Id"
                            fieldValue={values?.companyName}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />

                        <InputText
                            label="Acc. Pending Req. Amount(AED)"
                            fieldName="Lnquiry_Caption"
                            fieldValue={values?.accountPendingReqAmount}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />

                        <InputText
                            label="Requistion Date & Time"
                            fieldName="Requistion_Id"
                            fieldValue={values.requistionDateTime}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                           <InputText
                            label="End Date"
                            fieldName="Requistion_Id"
                            fieldValue={EndDate}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />                      
                           
                    </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} className={classes.quotationInnerGridRight}>
                        <Form.Group as={Row} className="mb-1">
                         <Form.Label column sm="2" style={{padding:'0 10px'}} className="fc-textarea-label">
                               Main Task Name <span style={{color:'red'}}>*</span>
                           </Form.Label>
                           { sow_type_dropdown === undefined ?
                           <div
                           style={{
                             width: "100%",
                             height: "30px",
                             display: "flex",
                             justifyContent: "center",
                             alignContent: "center",
                           }}
                         >
                           <CircularProgress color="warning" />
                         </div>
                           :
                           <Col sm="10" style={{padding:'0 12px 0 0'}} >
                                <div style={{display:'flex'}}>
                                  <div className="fc-dropdown-container" style={{width:'75%', marginLeft:'5%'}}>
                                  <CustomSelect
                                      name={`sow_mainTask`}
                                      value={
                                        values.sow_mainTask
                                      }
                                      onChange={(
                                        value
                                      ) => {
                                     
                                        setFieldValue(
                                          `sow_mainTask`,
                                          value.value
                                         
                                        );
                                        setMainTask(value)
                                      }}
                                      options={
                                        sow_type_dropdown
                                      }
                                    />

                                  </div>
                                                        
                                     <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    style={{ backgroundColor: "#eb6e12", marginLeft:'10px' }}
                                    onClick={(e) => handleSameAsTask(e, setFieldValue, values)}
                                    >
                                  Same as Main Task
                                    </Button>
                                   </div>
                                 </Col>
                                 }
                            </Form.Group>

                            { mainTask?.days === "1" && (
                               <Form.Group as={Row} className="mb-1">  
                               <Form.Label column sm="2" className="fc-textarea-label">
                              Requistion Type:
                               </Form.Label>
                               <Col sm="10" style={{padding:'0 12px 0 0'}} >
                               <div
                                 //style={getStyles(errors, item?.Generation_Date)}
                                 style={{width:'75%', marginLeft:'5%'}}
                                >
                                   <RadioGroup
                                    row
                                    aria-labelledby="requistionType"
                                    name="controlled-radio-buttons-group"
                                    value={values.requistionType}
                                    onChange={(e) =>{
                                      setFieldValue(`requistionType`, e.target.value);
                                      if(e.target.value === "Urgent") {
                                        setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskDays`,1);
                                      }
                                    }}
                                  >
                                    <FormControlLabel
                                 control={<Radio />}
                                 label={"Normal"}
                                 value={"Normal"}
                                 labelPlacement="end"
                              
                               />
                                <FormControlLabel
                                 control={<Radio />}
                                 label={"Urgent"}
                                 value={"Urgent"}
                                 labelPlacement="end"
                               
                               />
                               </RadioGroup>
                                         </div>
                               </Col>
                       </Form.Group>
                            )}

                            {(mainTask?.days === "1" && values?.requistionType === "Urgent") && (
                                <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"lead-generation-date"}
                              >
                                <Form.Label
                                  column
                                  sm="2"
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                  }}
                                >
                                Requistion Date <sup style={{color:'red'}}>*</sup>
                                </Form.Label>
                                <Col
                                  sm="10"
                                  style={{ padding: "0 12px 0 0" }}
                                >
                                  <div
                                  //style={getStyles(errors, item?.Generation_Date)}
                                  >
                                    <div
                                      style={{
                                        border: "0.1px solid #d3d3d3",
                                        width:'75%', marginLeft:'5%'
                                      }}
                                    >
                                      <DatePicker
                                        //disabled={fieldsEditingMain}
                                        selected={values.requistionDate}
                                        minDate={moment().toDate()}
                                        onChange={(date) => {
                                          setFieldValue(
                                            `requistionDate`,
                                            date
                                          );
                                   
                                        }}
                                        fixedHeight
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Select Date"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Form.Group>
                            )}
                        <div className={classes.modalFlexDivContaner}>
                        <Grid container xs={12} sm={12} className={classes.innerGridContainer}>
                          <FieldArray
                            name="mainTaskArray"
                            render={(arrayHelpers) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  //marginRight: "1rem",
                                  justifyContent: "space-between",
                                  marginBottom: "1rem",
                                  width: "100%",
                                }}
                              >
                                {values.mainTaskArray.length > 0 &&
                                  values.mainTaskArray.map((item, i) => {
                                    return (
                                      <div
                                        key={i}
                                        style={
                                          i !==
                                            values.mainTaskArray.length
                                            ? {
                                              border: "1px solid #eb6e12",
                                              padding: "5px",
                                              width: "100%",
                                              paddingRight:"20px",
                                              paddingLeft:"10px"
                                            }
                                            : {
                                              border: "none",
                                              padding: "5px",
                                              marginTop: "2px",
                                              width: "100%",
                                              paddingRight:"20px"
                                            }
                                        }
                                      >
                                      
                                        {(values.mainTaskArray.length > 1 && i !== 0) && (
                                           <InputText
                                           label="Requistion Id"
                                           fieldName="requistionId"
                                           fieldValue={values?.requistionId}
                                           fieldHandleChange={(event) =>{}
                                           }
                                           disableField={true}
                                           disabled={true}
                                           classvalue={"textBold"}
                                         />
                                        )}

                                        <Form.Group as={Row} className="mb-1">
                                                    <Form.Label column sm="2" className="fc-textarea-label">
                                                        Task Name
                                                    </Form.Label>
                                                    <Col sm="10" style={{padding:'0 12px 0 0'}} >
                                                    <div style={{display:'flex'}}>
                                                    <div className="fc-input-container" style={{marginRight:5, width:'49vw', marginLeft:'5%'}}>
                                                        <input
                                                            placeholder="Name"
                                                            name="taskName"
                                                            value={item?.taskName}
                                                            onChange={(e) => {
                                                              setFieldValue(`mainTaskArray[${i}].taskName`, e.target.value);
                                                            }}
                                                            className="fc-input-style"
                                                            style={getStyles(
                                                            errors,
                                                            values?.mainDropdownServices?.[i]
                                                              ?.taskName
                                                          )}
                                                        />
                                                    </div>
                                                    <div className="fc-input-container"  style={{marginRight:5}}>
                                                        <input
                                                            placeholder="Days"
                                                            name="taskDays"
                                                            value={item.taskDays}
                                                            onChange={(e) => {
                                                              setFieldValue(`mainTaskArray[${i}].taskDays`, e.target.value);
                                                            }}
                                                            className="fc-input-style"
                                                            disabled={values?.requistionType === "Urgent" ? true : false}
                                                            style={getStyles(
                                                              errors,
                                                              values?.mainDropdownServices?.[i]
                                                                ?.taskDays
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="fc-input-container">
                                                        <input
                                                            placeholder="Sequence"
                                                            name="taskSequence"
                                                            value={item.taskSequence}
                                                            onChange={(e) => {
                                                              setFieldValue(`mainTaskArray[${i}].taskSequence`, e.target.value);
                                                            }}
                                                            className="fc-input-style"
                                                            style={getStyles(
                                                              errors,
                                                              values?.mainDropdownServices?.[i]
                                                                ?.taskSequence
                                                            )}
                                                        />
                                                    </div>
                                                    </div>
                                                    </Col>
                                                    
                                        </Form.Group>  

                          <Grid container>                                             
                          <Grid item xs={12} md={6} style={{paddingRight:5}}>
                          <CustomDropdown
                            label="Work Type"
                            fieldOptions={work_type_options}
                            fieldValue={Number(item?.typeofwork)}
                            fieldHandleChange={(value) => {
                              setFieldValue(`mainTaskArray.[${i}].typeofwork`, value.value);
                            }}
                            errorField={getStyles(errors, values?.mainTaskArray[i]?.typeofwork)}
                            requiredField={true}
                          />

                        <InputText
                            label="Amount (AED)"
                            fieldName={item.amount}
                            fieldValue={item.amount}
                            fieldHandleChange={(event) =>{
                              setFieldValue(`mainTaskArray.[${i}].amount`, event.target.value);
                            }
                            }
                            fieldType={"number"}
                            errorField={getStyles(errors, values.amount)}
                            requiredField={true}
                          />

                    {zohoBalanceRef.current < item.amount && (
                      <Form.Group as={Row} className="mb-1">  
                      <Form.Label column sm="5" className="fc-textarea-label">
                       Do you have management approval/transfer receipt? <span style={{color:'red'}}>*</span>
                      </Form.Label>
                      <Col sm="7" style={{padding:'0 12px 0 0'}} >

                         <div className={classes.businessCardFormGroupModal}>
                        <div
                          className={classes.quotationFormGroupFlexDivModalVT}
                        >


                          <div
                            className={classes.quotationSelectContainerModal}
                          >
                            <div
                              className={
                                classes.businessCardDemoDataCheckModalUploadFile
                              }
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(event) => {
                         
                                  setFieldValue(
                                    `mainTaskArray.[${i}].uploadDoc`,
                                    event.currentTarget.files[0]
                                  );
                                }}
                                // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                ref={fileInput}
                              />
                              <button
                                className={
                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                }
                                type="button"
                                onClick={() => fileInput.current.click()}
                              >
                                <PermMediaIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Add Media
                              </button>

                              <small
                                className={
                                  classes.businessCardDemoDataCheckModalUploadFileText
                                }
                              >
                                {item.uploadDoc
                                  ?item?.uploadDoc?.name ||
                                  item?.uploadDoc?.split("/").pop()
                                  : "No file chosen"}
                              </small>
                            </div>
                       
                          </div>
                        </div>
                      </div>
                      {values.uploadDocument
                                                  ?.length !== 0 &&
                                                  item.uploadDoc !== "" &&
                                                  typeof item.uploadDoc ===
                                                  "object" ? (
                                                  [
                                                    "png",
                                                    "jpg",
                                                    "JPEG",
                                                    "jpeg",
                                                  ].includes(
                                                    item.uploadDoc?.name
                                                      ?.split(".")
                                                      .pop()
                                                  ) ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".2rem",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          fileInput.current[
                                                            i
                                                          ]?.current.click();
                                                        }}
                                                        style={{
                                                          width: "98%",
                                                          height: "100px",
                                                        }}
                                                        src={URL.createObjectURL(
                                                          item.uploadDoc
                                                        )}
                                                        alt={"image"}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".5rem",
                                                        marginTop: ".5rem",
                                                        alignItems: "center",
                                                        width: "50px",
                                                        marginRight: "2rem",
                                                      }}
                                                    >
                                                      {item.uploadDoc?.name
                                                        ?.split(".")
                                                        .pop() === "docx" ? (
                                                        <>
                                                          <RiFileWord2Fill
                                                            style={{
                                                              color: "#2b579a",
                                                              fontSize: "36px",
                                                              marginRight:
                                                                ".4rem",
                                                            }}
                                                          />
                                                        </>
                                                      ) : item.uploadDoc?.name
                                                        ?.split(".")
                                                        .pop() === "pdf" ? (
                                                        <BsFilePdfFill
                                                          style={{
                                                            color: "#ff0000",
                                                            fontSize: "36px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      ) : (
                                                        <CloudUploadIcon
                                                          style={{
                                                            color: "green",
                                                            fontSize: "32px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  )
                                                ) : values.uploadDocument
                                                  ?.length !== 0 &&
                                                  item.uploadDoc !== "" &&
                                                  typeof item.uploadDoc ===
                                                  "string" ? (
                                                  [
                                                    "png",
                                                    "jpg",
                                                    "JPEG",
                                                    "jpeg",
                                                  ].includes(
                                                    item.uploadDoc
                                                      ?.split(".")
                                                      .pop()
                                                  ) ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".2rem",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          fileInput.current[
                                                            i
                                                          ]?.current.click();
                                                        }}
                                                        style={{
                                                          width: "98%",
                                                          height: "100px",
                                                        }}
                                                        src={item.uploadDoc}
                                                        alt={"image"}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".5rem",
                                                        marginTop: ".5rem",
                                                        alignItems: "center",
                                                        width: "50px",
                                                        marginRight: "2rem",
                                                      }}
                                                    >
                                                      {item.uploadDoc
                                                        ?.split(".")
                                                        .pop() === "docx" ? (
                                                        <>
                                                          <RiFileWord2Fill
                                                            style={{
                                                              color: "#2b579a",
                                                              fontSize: "36px",
                                                              marginRight:
                                                                ".4rem",
                                                            }}
                                                          />
                                                        </>
                                                      ) : item.uploadDoc
                                                        ?.split(".")
                                                        .pop() === "pdf" ? (
                                                        <BsFilePdfFill
                                                          style={{
                                                            color: "#ff0000",
                                                            fontSize: "36px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      ) : (
                                                        <CloudUploadIcon
                                                          style={{
                                                            color: "green",
                                                            fontSize: "32px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  )
                                                ) : null}
                      </Col>
              </Form.Group>
                    )}

                    {item?.typeofwork === 1 && (
                            <Form.Group as={Row} className="mb-1">  
                        <Form.Label column sm="5" className="fc-textarea-label">
                        Self Assigned?
                        </Form.Label>
                        <Col sm="7" style={{padding:'0 12px 0 0'}} >
                        <div
                          //style={getStyles(errors, item?.Generation_Date)}
                         >
                            <RadioGroup
                                    row
                                    aria-labelledby="requistionType"
                                    name="controlled-radio-buttons-group"
                                    value={item.selfAssigned}
                                    onChange={(e) =>{
                                      setFieldValue(`mainTaskArray.[${i}].selfAssigned`, e.target.value);
                                    }}
                                  >
                             <FormControlLabel
                          control={<Radio />}
                          label={"Yes"}
                          value={"Yes"}
                          labelPlacement="end"
                        
                        />
                         <FormControlLabel
                          control={<Radio />}
                          label={"No"}
                          value={"No"}
                          labelPlacement="end"
                       
                        />
                        </RadioGroup>        </div>
                        </Col>
                </Form.Group>
                          )}

                          {item?.typeofwork === 2 && (
                            <Form.Group as={Row} className="mb-1">  
                        <Form.Label column sm="5" className="fc-textarea-label">
                          Does client has preferred date time & locations?
                        </Form.Label>
                        <Col sm="7" style={{padding:'0 12px 0 0'}} >
                        <div
                          //style={getStyles(errors, item?.Generation_Date)}
                         >
                            <RadioGroup
                                    row
                                    aria-labelledby="requistionType"
                                    name="controlled-radio-buttons-group"
                                    value={item.clientDate_time}
                                    onChange={(e) =>{
                                      setFieldValue(`mainTaskArray.[${i}].clientDate_time`, e.target.value);
                                    }}
                                  >
                             <FormControlLabel
                          control={<Radio />}
                          label={"Yes"}
                          value={"Yes"}
                          labelPlacement="end"
                        />

                    <FormControlLabel
                          control={<Radio />}
                          label={"No"}
                          value={"No"}
                          labelPlacement="end"
                        />
                        </RadioGroup>
                                  </div>
                        </Col>
                </Form.Group>
                          )}

                          </Grid>
                          <Grid item xs={12} md={6}>
                            {item?.typeofwork === 2 ? (
                              <Form.Group as={Row} className="mb-1">  
                              <Form.Label column sm="5" className="fc-textarea-label">
                                 PRO Date & Time
                              </Form.Label>
                              <Col sm="7" style={{padding:'0 12px 0 0'}} >
                              <div
                                                  style={getStyles(errors, item?.fromDate)}
                                                  >
                                                    <div
                                                      style={{
                                                        border: "0.1px solid #d3d3d3",
                                                      }}
                                                    >
                                                      <CustomDatePicker custom={true} fieldHandleChange={(value, picker) => {
                                                       const differenceDays = moment(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")).diff(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), "days");
                                                         setFieldValue(
                                                          `mainTaskArray[${i}].fromDate`,
                                                          moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                                                        );
                                                        setFieldValue(
                                                          `mainTaskArray[${i}].toDate`,
                                                          moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")
                                                        );
                                                        setFieldValue(
                                                          `mainTaskArray[${i}].reqDays`,
                                                          differenceDays
                                                        );
                                                      }} />
                                                    </div>
                                                  </div>
                              </Col>
                          </Form.Group>
                            ) : item?.typeofwork === 1 ? (
                              <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"lead-generation-date"}
                            >
                              <Form.Label
                                column
                                sm="5"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                }}
                              >
                               Office Work Date & Time
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{ padding: "0 12px 0 0" }}
                              >
                                <div
                                style={getStyles(errors, item?.fromDate)}
                                >
                                  <div
                                    style={{
                                      border: "0.1px solid #d3d3d3",
                                    }}
                                  >
                                    <CustomDatePicker custom={true} fieldHandleChange={(value, picker) => {
                                      const differenceDays = moment(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")).diff(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), "days");
                                       setFieldValue(
                                        `mainTaskArray[${i}].fromDate`,
                                        moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                                      );
                                      setFieldValue(
                                        `mainTaskArray[${i}].toDate`,
                                        moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")
                                      );
                                      
                                      setFieldValue(
                                        `mainTaskArray[${i}].reqDays`,
                                        differenceDays
                                      );
                                    }} />
                                  </div>
                                </div>
                              </Col>
        </Form.Group>
                            ) : null}
                          

                   


                    {item?.selfAssigned !== "Yes" && (
                      <CustomDropdown
                            label="Typist Name"
                            fieldOptions={typist_name_options}
                            fieldValue={item?.typistName}
                            fieldHandleChange={(value) => {
                              setFieldValue(`mainTaskArray[${i}].typistName`,value.value);
                            }}
                            errorField={getStyles(errors, item?.typistName)}
                            requiredField={true}
                          />
                    )}
                    

                    <CustomTextArea
                                label="Remarks"
                                fieldName="remarks"
                                fieldValue={item.remarks}
                                fieldHandleChange={(e) => {
                                  e.preventDefault();
                                  const { value } = e.target;
                                 setFieldValue(
                                    `mainTaskArray[${i}].remarks`,
                                    value
                                  );
                                }}
                               />
                          </Grid>

                         </Grid>
                                  </div>
                             );
                         })}
                              </div>
                            )}
                          />
                        </Grid>
                        
                      </div>
                      
                      

                        </Grid>
                    </Grid>
                  </div>
                  <br />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.quotationButtonDivModal}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#eb6e12" }}
                  onClick={handleSubmit}
                >
                  {loading ? "Loading...." : "Submit"}
                </Button>
                <Button
                  onClick={() => {
                    handleModaleClose();
                    // handleHide()
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              </div>
              {/* <PendingRequistionListing pendingReqListing={pendingReqListing} /> */}
              </div>
            </Box>
           
          </form>
        );
      }}
    </Formik>

     
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(RequistionEditModalForm);
