import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import {
  DataContext,
  SupportDataContext,
} from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import {
  getTotalLength,
  handleDefaultFilters,
  handleFilters,
} from "../../../../utils/Filters";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RejectedOrderListing = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  getRejectedOrdersDataApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    handleModalOpen,
    setEditModalPopper,
    setViewFollowUp,
    handleChangeTab
  } = useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);

  useEffect(() => {
    getRejectedOrdersDataApi(true);
    handleListingSavedSearch();
  }, []);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);


 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString.slice(0, 10).split("-");
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const columnDefs = [
    {
      headerName: "Sr.",
      field: "Serial_Number",
      //cellRenderer: "agGroupCellRenderer",
      //checkboxSelection: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
      width: 75,
      pinned: "left",
      filter: false,
    },
    // {
    //   headerName: "Actions",
    //   field: "",
    //   filter: false,
    //   // checkboxSelection: true,
    //   cellRenderer: actionBtnRenderer,
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
    //   minWidth: 220,
    //   pinned: "left",
    // },
    {
      headerName: "Order No",
      field: "Order_No",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 140,
      pinned: "left",
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
    },
    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Order Sub Type",
      field: "order_sub_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 230,

    },
    {
      headerName: "Overdue Order",
      field: "Overdue_Order",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Order Approval Completion Date",
      field: "Complete_Order_Status_Date_By_Admin",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Complete_Order_Status_Date_By_Admin === "" || params?.data?.Complete_Order_Status_Date_By_Admin === "NA" ? "" :
              moment(params?.data?.Complete_Order_Status_Date_By_Admin).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Old / New Order",
      field: "old_or_new_order",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            <>
              {params?.data?.old_or_new_order === 1 ? "New" : params?.data?.old_or_new_order === 0 ? "Old" : ""}<br />

            </>
          </>
        )
      }
    },
    {
      headerName: "Project Name",
      field: "project_name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 210,
    },
    {
      headerName: "Order Sequence / No of Order",
      field: "order_sequence",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
            <>
              <span style={{ fontWeight: "bold" }}>Order Sequence:</span> {params?.data?.order_sequence}<br />
              <span style={{ fontWeight: "bold" }}>No. of Order:</span> {params?.data?.no_of_orders}
            </>
          </>
        )
      }
    },
    // {
    //   headerName: "Point of Contact",
    //   field: "Point_of_Contact",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
    //   width: 300,
    // },
    {
      headerName: "Order Creation Date",
      field: "Order_Created_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Created_Date === "" || params?.data?.Order_Created_Date === "NA" ? "" :
              moment(params?.data?.Order_Created_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Support Request Completion Date",
      field: "Support_Req_Completion_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Support_Req_Completion_Date === "" || params?.data?.Support_Req_Completion_Date === "NA" ? "" :
              moment(params?.data?.Support_Req_Completion_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Support Assign Date",
      field: "Support_Assign_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Support_Assign_Date === "" || params?.data?.Support_Assign_Date === "NA" ? "" :
              moment(params?.data?.Support_Assign_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Start_Date === "" || params?.data?.Order_Start_Date === "NA" ? "" :
              moment(params?.data?.Order_Start_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Expected_Complition_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Expected_Complition_Date === "" || params?.data?.Order_Expected_Complition_Date === "NA" ? "" :
              moment(params?.data?.Order_Expected_Complition_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Followup",
      field: "Last_Followups",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 270,
      cellRenderer: (params) => {
        params = params?.data?.Last_Followups;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
    },
    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "No Data" ? (
              <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
            ) : params?.data?.Freeze_Approval}
          </>
        )
      }
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Work in Progress",
      field: "Work_in_Progress",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Work_in_Progress;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "State",
      field: "State",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },

    {
      headerName: "Trade Names",
      field: "Trade_Names",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >

              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(
                        apiData,
                        val.Search_Name,
                        valueKey
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.length}
      />
        <span style={{color: "red", fontSize: "13px", marginLeft: "10px"}}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>

      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
          groupDisplayType="groupRows"
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowGroupHidesColumns={true}
          rowGroupPanelShow="always"
          autoGroupColumnDef={
            {
              headerName: "Company Name",
              minWidth: 300,
              cellStyle: { border: "1px solid #fff", borderBottom: "2px solid #fff", fontWeight: "bold" },
              flex: 1,
              // suppresRowCount: true,
              cellRendererParams: {

                // suppressCount: true,
              }
            }
          }
        ></AgGridReact>
      </div>
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RejectedOrderListing;

