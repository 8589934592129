import React, { useState, useContext, useEffect } from "react";
import { Box, Tab, Badge } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { withStyles } from "@material-ui/core/styles";
import TabStyles from "../styles/TabStyles";
import ModalTable from "../Modals/ModelTable";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import BackImage from "../BackImage";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { DataContext,SupportDataContext } from "../../../../provider/context";
import AdminListingTabBottom from "./AdminListingTabBottom";
import TodayOrderAlert from "../Alerts/TodayOrderAlert";
import OrderCCAlerts from "../Alerts/OrderCCAlerts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    border: "1px solid #FF7518",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const AdminListingTabTop = React.memo((props) => {
  const [openForm] = useState(false);

  const { classes } = props;

  const {
    modalTable,
    value,
    handleChangeTab,
    getAdminTodayAlertData,
    adminTodayAlertData,
  } = useContext(SupportDataContext);

  React.useEffect(() => {    
    getAdminTodayAlertData(callDueDate());    
  }, [])

 
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const callDueDate = () => {
    const currentDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const date = currentDate.split("/");
    const dueDate = `${date[2]}-${date[1]}-${date[0]}`;
    return dueDate;
  };
  return (
    <div className={classes.listingTabContainerDiv}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext
          value={value}
          onChange={(event, value) => handleChangeTab(value)}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(event, value) => handleChangeTab(value)}
              aria-label="lab API tabs example"
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
              className={classes.tabListListing}
            >
              <Tab
                className={classes.tabStyleListing}
                classes={{
                  selected: classes.selected,
                }}
                label="Listing Details"
                {...a11yProps(0)}
              />
               <Tab
                className={classes.tabStyleListing}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={adminTodayAlertData?.length} showZero max={adminTodayAlertData?.length}>
                     <LightTooltip title="Today Order Alerts" >
                      <span>Today Order Alerts &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
               
                {...a11yProps(1)}
              />
              {/* <Tab
                className={classes.tabStyleListing}
                classes={{
                  selected: classes.selected,
                }}
                label="CC Alerts"
                {...a11yProps(2)}
              /> */}
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <TabPanel value={value} index={0}>
     
          <>
            {!modalTable ? (
              <div className={classes.TabLowerContainerDiv}>
                <>
                <BackImage />
                </>
              </div>
            ) : (
              <ModalTable />
            )}
          </>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <TodayOrderAlert date={callDueDate()} height="550px" pagination={true} />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <div>
          <OrderCCAlerts/>
        </div>
      </TabPanel> */}
      
      <br /> <br />
      <AdminListingTabBottom />
    </div>
  );
});

export default withStyles(TabStyles)(AdminListingTabTop);
