import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";


const FreezeLogTable = ({ classes }) => {
  const { operationInfo,freezeLogData, getFreezeLogTableData } = useContext(SupportDataContext);
  useEffect(() => {
    getFreezeLogTableData();
  }, [operationInfo.Order_Id]);
  const gridRef = useRef();
  

 

  const [columnDefs] = useState([
    {
      headerName: "S #",
      field: "Serial_Number",
      filter: "agTextColumnFilter",
      width: 60,
      maxWidth: 90,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      cellRenderer: (params) => {
        return params?.rowIndex +1
      }
    },
    {
      headerName: "Freeze Requested By",
      field: "Freeze_By",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
    },
    {
      headerName: "Freeze / Unfreeze Status",
      field: "Freeze_Status",
      filter: "agTextColumnFilter",
      flex:1,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Freeze/Unfreeze Reason",
      field: "Freeze_Reason",
      filter: "agTextColumnFilter",
      flex:1,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Freeze Requested Date",
      field: "Freeze_Date",
      flex:1
    },
    {
      headerName: "Freeze Approval Date",
      field: "Freeze_Approval_Date",
      flex:1
    },
    {
      headerName: "Freeze Approval By",
      field: "Freeze_Approval_By",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
    },
    {
      headerName: "Freeze Approval Status",
      field: "Freeze_Approval_Status",
      filter: "agTextColumnFilter",
      flex:1,
      cellStyle: { border: "1px solid #fff", textAlign: "center"},
    },
   
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 400,
      editable: false,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <TableContainer component={Paper}>
            <div
            className="ag-theme-alpine"
            style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
          >
            <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
              ref={gridRef}
              rowData={freezeLogData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowHeight={()=>90}
              rowStyle={{
                background: "#DEDEDE",
                color: "#000000DE",
                borderBottom: "1px solid white",
              }}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
      </TableContainer>
      <Error  />
    </div>
  );
};

export default withStyles(modalTableStyles)(FreezeLogTable);
