import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import "../../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../ModelPopper";
import axios from "axios";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../../utils/Filters";
import { FaEye } from "react-icons/fa";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChangeOrderSequenceLogTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        handleModalTableOpen,
        setEditModalPopper,
        setButtonText,
        setOperationInfo,
        operationInfo,
        setSelected, setLoading,
        handleModalOpen,
        message, setMessage,
        setSeverity, handlePromise,
        isOpen, setIsOpen, severity,
        getProjectWiseOrdersLogDataAPi, projectWiseLogOrders

    } = useContext(SupportDataContext);

    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);


    useEffect(() => {
        setTimeout(() => {
            getProjectWiseOrdersLogDataAPi()
        }, 2000)
    }, []);
    
console.log(projectWiseLogOrders);

    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);

   

   const dateFilterParams = {
    inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
                return 0;
            }
            const dateParts = dateAsString.slice(0, 10).split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const columnDefs = [
        {
            headerName: "Sr.#",
            field: "Serial_Number",
            // cellRenderer: "agGroupCellRenderer",
            // checkboxSelection: true,
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
            minWidth: 90,
            pinned: "left",
            filter: false
        },
        {
            headerName: "Old Order Sequence",
            field: "Old_Order_Sequence",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 170,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {params?.data?.Old_Order_Sequence === 0 ? "Not Applicable" : params?.data?.Old_Order_Sequence}
        
                    </>
                  </>
                )
              }
        },
        {
            headerName: "Old Order Connected / Not",
            field: "Old_Order_Connected_Not",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 170,
        },
        {
            headerName: "Old Order Start With Sequence",
            field: "Old_Order_Start_With",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 170,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {params?.data?.Old_Order_Start_With === 0 && params?.data?.Old_Order_Connected_Not === "Connected"
                      ? "Not Applicable" : params?.data?.Old_Order_Start_With}
        
                    </>
                  </>
                )
              }
        },
        {
            headerName: "Old Order Account Acceptance Status",
            field: "Old_Order_Account_Acceptance_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 190,
        },
        {
            headerName: "New Order Sequence",
            field: "New_Order_Sequence",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 160,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {params?.data?.New_Order_Sequence === 0 ? "Not Applicable" : params?.data?.New_Order_Sequence}
        
                    </>
                  </>
                )
              }
        },
        {
            headerName: "New Order Connected / Not",
            field: "New_Order_Connected_Not",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "New Order Start With Sequence",
            field: "New_Order_Start_With",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 160,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {params?.data?.New_Order_Start_With}
        
                    </>
                  </>
                )
              }
           
        },
        {
            headerName: "New Order Account Acceptance Status",
            field: "New_Order_Account_Acceptance_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Updated Date",
            field: "Updated_Date",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {moment(params?.data?.Updated_Date).format("DD-MM-YYYY hh:mm:ss a")}
        
                    </>
                  </>
                )
              }
        },
        {
            headerName: "Updated By",
            field: "Updated_By",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Updated By User Type",
            field: "Updated_By_User_Type",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            // filter: true,
            // enable floating filters by default
            // floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            //flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };



    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    useEffect(() => {
        const parsedSavedValue = JSON.parse(savedValue);
        if (parsedSavedValue !== null) {
            let values = Object.keys(parsedSavedValue);
            values.map((val, i) => {
                if (parsedSavedValue[val].filterType === "text") {
                    gridRef.current.api.getFilterInstance(val).setModel({
                        type: "contains",
                        filter: parsedSavedValue[val].filter,
                    });
                } else {
                    gridRef.current.api
                        .getFilterInstance(val)
                        .setModel({ values: parsedSavedValue[val].values });
                }
            });
            gridRef.current.api.onFilterChanged();
        }
    }, [savedValue]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

   
    return (
     <div style={containerStyle}>
     
      <div
        className="ag-theme-alpine"
        style={{ height: "370px", maxHeight: "370px", overflow: "auto" }}
      >
                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={projectWiseLogOrders}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    domLayout={'autoHeight'}
                    rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
                    pagination={true}
                    enableCellChangeFlash={true}

                ></AgGridReact>
            </div>
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ChangeOrderSequenceLogTable;
