import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext, DataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";

import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import { VpnLock } from "@material-ui/icons";
import { addDays } from "date-fns";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import NewInputText from "../../../../../reusableComponents/InputText/NewInputText";
import NewCustomTextArea from "../../../../../reusableComponents/CustomTextarea/NewCustomTextArea";
import LoaderClock from "../../../../lodar/LoaderClock";



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    padding: "25px",
    height: "auto",
    paddingTop: "0px",
    minHeight: "650px",
    maxHeight: "650px",
    overflow: 'auto',
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        maxHeight: "760px",
    },
};

const getStyles = (errors, fieldName, height) => {

    if (height && !getIn(errors, fieldName)) {
        return { height: height }
    }

    if (getIn(errors, fieldName) && height) {
        return {
            border: "1px solid red",
            height: height
        };
    } else if (getIn(errors, fieldName)) {
        return {
            border: "1px solid red"
        };
    }
};

const PCOAchievementForm = ({ classes }) => {

    const {
        handleModaleClose,
        setLoading,
        loading,
        setMessage,
        setSeverity,
        editModalPopper,
        setIsOpen,
        operationInfo,
        salesInfo,
        rowData,
        getPCOTargetMastertDataApi,
        targetMasterData,
        onAddTarget,
        getActualDesignationPerformanceTargetDataApi,
        performanceTargetData,
        setButtonText,
        empAchievementData
    } = useContext(SupportDataContext);

    const [enableReinitialize, setEnablReinitializei] = useState(false);
    const [getAlloted_Target_Marks, setGetAlloted_Target_Marks] = useState(rowData?.targetDetails_List);
    const [targetMonth, setTargetMonth] = useState("");
    const [targetYear, setTargetYear] = useState("");

    const fileInput = React.createRef();

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className, }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#d11616",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#d11616",
        },
    }));

    // Function to check if targetMonth and targetYear are found in the array
function isTargetMonthYearPresent(array, targetMonth, targetYear) {
  for (const item of array) {
      if (item.Target_Month === targetMonth && item.Target_Year === targetYear) {
          return true;
      }
  }
  return false;
}

// Check if March 2024 is present
const isPresent = isTargetMonthYearPresent(performanceTargetData, targetMonth, targetYear);
console.log(isPresent); // Output: true

    console.log("targetMasterData", targetMasterData);
    const targetDetailsData = rowData?.targetDetails_List?.map((item) => {
        const obj = {
            update_id: item?.Update_Id,
            description: item?.Target_Name,
            Alloted_Mark: item?.Alloted_Mark,
            target: item?.Target_Value,
            achievement: item?.Achieve_Mark,
            Achieve_Target: item?.Achieve_Target,
            comments: item?.Comments,
            Target_Id: item?.Target_Id,
            Target_Type: item?.Target_Type,
            Target_Type_Id: item?.Target_Type
        }
        return obj;
    });
console.log("targetDetailsData", targetDetailsData)
console.log("empAchievementData", empAchievementData)

// Assuming there is only one object in the metrics array
const metric = empAchievementData?.[0];

const updatedDescriptionsTargets = targetDetailsData?.map(item => {
    // Check the description of the current item and update the target value accordingly
    switch (item?.description) {
       
         case "Closure of the works within standard":
            return { ...item, achievement: metric?.Current_Month_Total_Request_To_Complete_Order_PCO };
         case "Number of orders in the RO's module where no action has been.":
             return { ...item, achievement: metric?.Order_No_Alerts_Requisition_PCO };
        //  case "Client Complaints":
        //      return { ...item, achievement: metric?.Total_Client_Complaint };
        //      case "Orders with no Future dates Alerts":
        //         return { ...item, achievement: metric?.Order_No_Future_Alert };
          
        //      case "Rejected Orders ( Freezing and unfreezing the works regularly as per client response and closed orders )":
        //         return { ...item, achievement: metric?.Total_Rejected_Order };
        //      case "Closure Orders ( underprocess orders have to close 10% of orders on a daily baisis)":
        //         return { ...item, achievement: metric?.Total_Assigned_Order_Closed, target: metric?.Total_Assigned_Order_10_Percent };
             

        // Add cases for other descriptions if needed
        default:
            return item; // Return the item unchanged if it doesn't match the cases
    }
});

console.log(updatedDescriptionsTargets);

// Calculate Achieve_Target for each item
const newData = updatedDescriptionsTargets?.map(item => {
    // Calculate Achieve_Target only if all necessary fields have values
    if (item.Alloted_Mark !== "" && item.achievement !== "") {
        const marksObtained = (item.Alloted_Mark / item.target) * item.achievement;
        if(item.achievement > item.Alloted_Mark )
        {
            return {
                ...item,
                Achieve_Target: item.Alloted_Mark // Rounding to 2 decimal places
            };
        }
        else
        {
        return {
            ...item,
            Achieve_Target: item?.achievement // Rounding to 2 decimal places
        };
    }
    } else {
        // If any of the required fields are missing, return the item as it is
        return item;
    }
});

console.log("newData", newData);

const editTargetDetailsData = editModalPopper && rowData?.targetDetails_List?.map((item) => {
    const obj = {
        update_id: item?.Update_Id,
        description: item?.Target_Name,
        Alloted_Mark: item?.Alloted_Mark,
        target: item?.Target_Value,
        achievement: '',
        Achieve_Target: '',
        comments: '',
        Target_Id: item?.Target_Id,
        Target_Type: item?.Target_Type,
        Target_Type_Id: item?.Target_Type
    }
    return obj;
});
console.log("editTargetDetailsData", editTargetDetailsData)

    const monthOptions = [
        {value: "January", label: "January"},
        {value: "February", label: "February"},
        {value: "March", label: "March"},
        {value: "April", label: "April"},
        {value: "May", label: "May"},
        {value: "June", label: "June"},
        {value: "July", label: "July"},
        {value: "August", label: "August"},
        {value: "September", label: "September"},
        {value: "October", label: "October"},
        {value: "November", label: "November"},
        {value: "December", label: "December"},
    ];
    const yearOptions = [
        {value: "2024", label: "2024"},
        {value: "2025", label: "2025"},
    ];
   
    const targetPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const user_name = localStorage.getItem("Employee_First_Name");
        const factory_id = localStorage.getItem("factory_id");

        const url = `https://flyingcolour.online/fcapp/docprocessing_api/update_pco_target_achievement/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };
        const editTargetDetails = values.mainTaskArray?.map((item) => {
          if (item.Achieve_Target === null || isNaN(item.Achieve_Target)) {
            item.Achieve_Target = 0;
        }
            const obj = {
                update_id: item?.update_id,
                alloted_mark: item?.Alloted_Mark,
                target_value: item?.target,
                achieve_mark: item?.achievement,
                achieve_target: 0,
                comments: item?.comments, 
            }
            return obj;
        });
      
      console.log(editTargetDetails);
        let updatedDetails = editTargetDetails.map(obj => {
          let newObj = {};
          Object.keys(obj).forEach(key => {
            // Convert everything to string
            newObj[key] = String(obj[key]);
          });
          return newObj;
        });
        updatedDetails.forEach(item => {
          if (item.achieve_target === "NaN" || item.achieve_target === null) {
              item.achieve_target = 0;
          }
      });
      console.log("updatedDetails", updatedDetails);
        const formData = new FormData();
        formData.append("factory_id", factory_id);
        formData.append("emp_id", emp_id);
        formData.append("user_type", user_type);
        formData.append("user_name", user_name);
        formData.append("target_month", values?.target_month);
        formData.append("target_year", values?.target_year);
        formData.append("target_emp_id", operationInfo?.EmpId);
        formData.append("target_emp_designation", operationInfo?.User_Type);
        formData.append("target_achievement_details", JSON.stringify(updatedDetails));


        if (editModalPopper) {

            axios.put(url, formData, { headers: headers }).then((data) => {
                setLoading(false);

                if (data?.data?.message) {
                    setMessage(`${data?.data?.message}`);
                    setSeverity(500)
                }
                else {
                    setMessage(data?.data?.data?.msg);
                    setSeverity(data?.data?.data?.code)
                }
                setIsOpen(true);
                getActualDesignationPerformanceTargetDataApi();
                setButtonText("Add PCO Target");
                handleModaleClose();

            })
        } else {
            axios.put(url, formData, { headers: headers }).then((data) => {
                setLoading(false);

                if (data?.data?.message) {
                    setMessage(`${data?.data?.message}`);
                    setSeverity(500)
                }
                else {
                    setMessage(data?.data?.data?.msg);
                    setSeverity(data?.data?.data?.code)
                }
                setIsOpen(true);
                getActualDesignationPerformanceTargetDataApi();
                setButtonText("Add PCO Target");
                handleModaleClose();


            })
        }
    }

    useEffect(() => {
      getPCOTargetMastertDataApi();
    }, []);

  const totalAlloted = getAlloted_Target_Marks?.map((val) => Number(val?.Alloted_Mark));

    const totalAllotedMark = totalAlloted?.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
      const totalObtained = getAlloted_Target_Marks?.map((val) =>  Number(val?.Achieve_Mark) || Number(val?.achievement));
      // Calculate total of target_value
      for (let i = 0; i < totalObtained?.length; i++) {
        if (totalObtained[i] === null || isNaN(totalObtained[i])) {
          totalObtained[i] = 0;
        }
    }
      const totalObtainedMarks = totalObtained?.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
     
      console.log("rowData", rowData);
      console.log("totalObtained", totalObtained);
      console.log(`Total Target Value: ${totalObtainedMarks}`);

 return (
<>
<Formik
   enableReinitialize={enableReinitialize}
   initialValues={{
       target_year:  rowData.Target_Year,
       target_month:  rowData.Target_Month,
       // mainTaskArray:
       //     // editModalPopper ? editMainTaskArrayValue() :
       //     [{
       //         description: editModalPopper ? salesInfo.description : '',
       //         Alloted_Mark: editModalPopper ? salesInfo.Alloted_Mark : '',
       //         target: editModalPopper ? salesInfo.target : '',
       //         achievement: editModalPopper ? salesInfo.achievement : '',
       //         Achieve_Target: editModalPopper ? salesInfo.Achieve_Target : '',
       //         comments: editModalPopper ? salesInfo.comments : '',
       //     }],
       mainTaskArray:  newData !== undefined && newData?.length !== 0
           ? newData
           :
           [{
            description: '',
            Alloted_Mark: '',
            target: '',
            achievement: '',
            Achieve_Target: '',
            comments: '',
            
           }],
   }}
   onSubmit={async (data) => {
       setLoading(true)
       targetPostAPI(data)
   }}

   validate={(values) => {
       setGetAlloted_Target_Marks(values?.mainTaskArray)
       console.log("mainTaskArray" ,values?.mainTaskArray)
       let errors = {};
           if (values.target_month === "") {
               errors.target_month = "Target Month is Required!!";
           }
           if (values.target_year === "") {
               errors.target_year = "Target Year is Required!!";
           }
       values?.mainTaskArray?.forEach((val) => {

           if (val.target === "") {
               errors.target = "Target Value is Required!!";
           }
           if (val.Alloted_Mark === "") {
               errors.Alloted_Mark = " Alloted Marks is Required!!";
           }
            if (val.achievement === "" || val.achievement < 0) {
               errors.achievement = "Achievement Value is Required!!";
           }
       })
       return errors;
   }}>
  {({
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
  }) => {
      return (
        loading ? (
            <LoaderClock loading={loading}/>
          )
            :
          <form onSubmit={(e) => { e.preventDefault() }}>
           <Box sx={style}>
               <div>
                   <div style={{ height: "36px" }}>
                       <IconButton
                           style={{ float: "right" }}
                           className={classes.quotationModalHeaderIconCancle}
                           onClick={() => {
                            setButtonText("Add PCO Target");
                               handleModaleClose();
                           }}
                       >
                           <CloseIcon
                               className={classes.quotationModalHeaderIconCancleIcon}
                           />
                       </IconButton>
                   </div>
                   <div style={{ height: "610px", overflowX: "hidden", padding: "0" }}>
                       <Grid container spacing={2} columns={12}>
                           <Grid
                               item
                               xs={12}
                               style={{
                                   marginBottom: "-30px",
                                   paddingBottom: "10px",
                                   marginTop: "-45px",
                                   zIndex: 10
                               }}
                           >
                               <br />
                               <div className={classes.quotationModalHeadingContainer}>
                                   <span className={classes.quotationModalHeader}>
                                       {`PCO Achievement Form`}
                                   </span>
                               </div>
                               <br />
                      <br/>
                      <div style={{marginTop: "0px"}} className={classes.quotationModalHeadingContainer}>
                                   <span className={classes.quotationModalHeader}>
                                       {"PCO Target Achievement Details"}
                                   </span>
                               </div>
                      <br/>
                         <div className={classes.quotationModalFlexDivContaner}>
                            
                                   <Grid container>
                                       <Grid
                                           item
                                           xs={12}
                                           md={3}
                                           className={classes.quotationInnerGridRight}
                                       >
                                           <InputText
                                               label="Employee Name"
                                               fieldName="Requistion_Id"
                                               fieldValue={operationInfo?.Emp_Name}
                                               fieldHandleChange={(event) => { }
                                                   //handleChangeInput(item?.id, event)
                                               }
                                               disableField={true}
                                               disabled={true}
                                               classvalue={"textBold"}
                                           />
                                           </Grid>
                                           <Grid
                                           item
                                           xs={12}
                                           md={3}
                                           className={classes.quotationInnerGridRight}
                                       >
                                           <InputText
                                               label="Actual Designation"
                                               fieldName="Employee_Actual_Designation"
                                               fieldValue={operationInfo?.Employee_Actual_Designation}
                                               fieldHandleChange={(event) => { }
                                                   //handleChangeInput(item?.id, event)
                                               }
                                               disableField={true}
                                               disabled={true}
                                               classvalue={"textBold"}
                                           />
                                       </Grid>
                                       <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.quotationInnerGridRight}
                      >
                        <CustomDropdown                                 
                            label="Target Month"
                            fieldOptions={monthOptions}
                            fieldValue={values?.target_month}
                            fieldHandleChange={(value) => {
                                setFieldValue(`target_month`, value.value);
                                setTargetMonth(value.value);
                            }}
                            // errorField={getStyles(errors, values?.target_month)}
                            requiredField={true}
                            isDisabled={true}
                        />
                        </Grid>
                     <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.quotationInnerGridRight}
                      >
                         <CustomDropdown                                 
                         label="Target Year"
                          fieldOptions={yearOptions}
                          fieldValue={values?.target_year}
                          fieldHandleChange={(value) => {
                              setFieldValue(`target_year`, value.value);
                              setTargetYear(value.value)
                          }}
                        //   errorField={getStyles(errors, values?.target_year)}
                          requiredField={true}
                          isDisabled={true}
                          />
                      </Grid>
                      </Grid>

                      <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.quotationInnerGridRight}
                        >
                      <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"selectsow"}
                          >
                            <Col sm="12">
                              {/* Scope of Work Component Has to  be */}
                              {values?.mainTaskArray?.length > 0 && (
                                <div
                                  className="fc-dropdown-container"
                                  style={{ padding: "10px" }}
                                >
                                  <Box
                                    component={Grid}
                                    display={{ xs: "none", md: "flex" }}
                                  >
                                    <Grid container columns={12}>
                                      <Grid item xs={12} sm={12} md={6}>
                                        <p style={{fontWeight: "bold", fontSize: "16px"}}>Description</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p style={{fontWeight: "bold", fontSize: "16px"}}>Target<span style={{color: "red"}}>*</span> </p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p style={{fontWeight: "bold", fontSize: "16px"}}>Allocated Marks<span style={{color: "red"}}>*</span></p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p style={{fontWeight: "bold", fontSize: "16px"}}>Target Achieved<span style={{color: "red"}}>*</span> </p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={3}>
                                        <p style={{fontWeight: "bold", fontSize: "16px"}}>Comments</p>
                                      </Grid>
                                     
                                    </Grid>
                                  </Box>

                                  <>
                                    {values?.mainTaskArray?.map((val, i) => {
                                      return (
                                        <Grid container columns={12}>
                                          <Grid item xs={12} sm={12} md={6}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Description
                                              </p>
                                            </Box>

                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                             
                                             <span>{i+1}</span>
                                              <div
                                                className="fc-input-container"
                                                style={{
                                                  marginRight: 3,
                                                  marginLeft: 3,
                                                  width: "100%",
                                                }}
                                              >
                                                <input
                                                  name={"description"}
                                                  value={val?.description}
                                                  onChange={(e) => {
                                                    // setFieldValue(`mainTaskArray.${[i]}.description`, e.target.value);
                                                    e.preventDefault();
                                                  }}
                                                  type={"text"}
                                                  className={`fc-input-style`}
                                                  style={{fontWeight: "bold"}}
                                                //   disabled={true}
                                                />
                                              </div>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Target<span style={{color: "red"}}>*</span>
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"target"}
                                                  value={val?.target}
                                                  onChange={(e) => {
                                                    const {value} = e?.target;
                                                    if(value > 100 || value < 0)
                                                    {
                                                        setFieldValue(`mainTaskArray.[${i}].target`, "");
                                                    }
                                                    else
                                                    {
                                                        setFieldValue(`mainTaskArray.[${i}].target`, value);
                                                    }
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                  style={{fontWeight: "bold"}}
                                                  disabled={true}
                                                />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Alloted Marks<span style={{color: "red"}}>*</span>
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"Alloted_Mark"}
                                                  value={val?.Alloted_Mark}
                                                  onChange={(e) => {
                                                    const {value} = e?.target;
                                                     console.log(typeof(value))
                                                     if(value > 100 || value <= 0)
                                                     {
                                                         setFieldValue(`mainTaskArray.[${i}].Alloted_Mark`, "");
                                                     }
                                                     else
                                                     {
                                                         setFieldValue(`mainTaskArray.[${i}].Alloted_Mark`, value);
                                                     }
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                  style={{fontWeight: "bold"}}
                                                  disabled={true}
                                                />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Target Achieved<span style={{color: "red"}}>*</span>
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"achievement"}
                                                  value={val?.achievement}
                                                  onChange={(e) => {
                                                  const {value} = e?.target;
                                                  if(value < 0 || value > val?.Alloted_Mark)
                                                  {
                                                    setFieldValue(`mainTaskArray.[${i}].achievement`, "");
                                                  }
                                                  else{
                                                    setFieldValue(`mainTaskArray.[${i}].achievement`, value);
                                                  }
                                                 
                                                  // const obtaineMarks = val?.Alloted_Mark/val?.target;
                                                  // const obtaineMarks2  = obtaineMarks * value;
                                                  // if(obtaineMarks2 > val?.Alloted_Mark)
                                                  // {
                                                  //   setFieldValue(`mainTaskArray.[${i}].Achieve_Target`, val?.Alloted_Mark);
                                                  // }
                                                  // else{
                                                  //   setFieldValue(`mainTaskArray.[${i}].Achieve_Target`, obtaineMarks2.toFixed(2));
                                                  // }
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                  style={{fontWeight: "bold"}}
                                                  // disabled={
                                                  //   val?.Target_Id === 120
                                                  //   || val?.Target_Id === 124
                                                  //   ? true : false
                                                  // }
                                                />
                                            </div>
                                          </Grid>
                                          {/* <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Obtained Marks<span style={{color: "red"}}>*</span>
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"Achieve_Target"}
                                                  value={val?.Achieve_Target}
                                                  onChange={(e) => {
                                                    const {value} = e?.target;
                                                     console.log(typeof(value))
                                                     if(value > 100 || value <= 0)
                                                     {
                                                         setFieldValue(`mainTaskArray.[${i}].Achieve_Target`, "");
                                                     }
                                                     else
                                                     {
                                                         setFieldValue(`mainTaskArray.[${i}].Achieve_Target`, value);
                                                     }
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                  style={{fontWeight: "bold"}}
                                                  disabled={true}
                                                />
                                            </div>
                                          </Grid>                                          */}
                                          <Grid item xs={12} sm={12} md={3}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Comments
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"comments"}
                                                  value={val?.comments}
                                                  onChange={(e) => {
                                                    const {value} = e?.target;                                                     
                                                    setFieldValue(`mainTaskArray.[${i}].comments`, value);
                                                  }}
                                                  type={"text"}
                                                  className={`fc-input-style`}
                                                />
                                            </div>
                                          </Grid> 
                                        </Grid>
                                      );
                                    })}
                                  </>
                                </div>
                              )}
                            </Col>
                       </Form.Group>   
                       <Grid container columns={12}>
                                          <Grid item xs={12} sm={12} md={5}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                             
                                            </Box>

                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3, marginLeft: "12px",fontWeight: "bold" }}
                                            >
                                          <input
                                          placeholder="Total"
                                              name={"Total"}
                                              value={"Total Achieved Marks"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={{fontWeight: "bold"}}
                                              // disabled={true}
                                            />
                                            </div>
                                            <div
                                              // className="fc-dropdown-container"
                                              // style={{ marginRight: 3, marginLeft: "12px", height: "250px", width: "932px", fontWeight: "bold"  }}
                                            >
                                            </div>
                                            
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={3}>
                                       
                                       <div
                                           className="fc-dropdown-container"
                                           style={{ marginRight: 3 }}
                                         >
                                         <input
                                           placeholder=""
                                               name={""}
                                               value={""}
                                               onChange={(e) => {
                                                 e.preventDefault()
                                               }}
                                               type={"number"}
                                               className={`fc-input-style`}
                                               // disabled={true}
                                             />
                                         </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Poor"
                                              name={"Poor"}
                                              value={"Poor"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                               totalObtainedMarks <= 40
                                                 ? {
                                                   border: "1px solid #eb6e12",
                                                   color: "white",
                                                   background: "#eb6e12",
                                                   textAlign: "center"
                                                 }
                                                 : {
                                                   border: "none",
                                                   background: "none",
                                                   textAlign: "center"
                                                 } }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Week"
                                              name={"Week"}
                                              value={"Week"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                               totalObtainedMarks > 40 && totalObtainedMarks <= 60
                                                 ? {
                                                   border: "1px solid #eb6e12",
                                                   color: "white",
                                                   background: "#eb6e12",
                                                   textAlign: "center"
                                                 }
                                                 : {
                                                   border: "none",
                                                   background: "none",
                                                   textAlign: "center"
                                                 } }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Moderate"
                                              name={"Moderate"}
                                              value={"Moderate"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                               totalObtainedMarks > 60 && totalObtainedMarks <= 80
                                                 ? {
                                                   border: "1px solid #eb6e12",
                                                   color: "white",
                                                   background: "#eb6e12",
                                                   textAlign: "center"
                                                 }
                                                 : {
                                                   border: "none",
                                                   background: "none",
                                                   textAlign: "center"
                                                 } }                                             
                                                 // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Good"
                                              name={"Good"}
                                              value={"Good"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                               totalObtainedMarks > 80 && totalObtainedMarks <= 90
                                                 ? {
                                                   border: "1px solid #eb6e12",
                                                   color: "white",
                                                   background: "#eb6e12",
                                                   textAlign: "center"
                                                 }
                                                 : {
                                                   border: "none",
                                                   background: "none",
                                                   textAlign: "center"
                                                 } }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Excellent"
                                              name={"Excellent"}
                                              value={"Excellent"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                               totalObtainedMarks > 90 && totalObtainedMarks <= 100
                                                 ? {
                                                   border: "1px solid #eb6e12",
                                                   color: "white",
                                                   background: "#eb6e12",
                                                   textAlign: "center"
                                                 }
                                                 : {
                                                   border: "none",
                                                   background: "none",
                                                   textAlign: "center"
                                                 } }
                                              // disabled={true}
                                            />
                                        </div>
                                      </Grid>
                                           
                                      <Grid item xs={12} sm={12} md={1}>
                                       
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Obtained Marks Total"
                                              name={"Obtained Marks Total"}
                                              value={totalObtainedMarks}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"number"}
                                              className={`fc-input-style`}
                                              disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Obtained Marks Total"
                                              name={"Obtained Marks Total"}
                                              value={"0-40"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                                totalObtainedMarks <= 40
                                                  ? {
                                                    border: "1px solid #eb6e12",
                                                    color: "white",
                                                    background: "#eb6e12"
                                                  }
                                                  : {
                                                    border: "none",
                                                    background: "none"
                                                  }
                                              }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Obtained Marks Total"
                                              name={"Obtained Marks Total"}
                                              value={"41-61"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                                totalObtainedMarks > 40 && totalObtainedMarks <= 60
                                                  ? {
                                                    border: "1px solid #eb6e12",
                                                    color: "white",
                                                    background: "#eb6e12"
                                                  }
                                                  : {
                                                    border: "none",
                                                    background: "none"
                                                  } }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Obtained Marks Total"
                                              name={"Obtained Marks Total"}
                                              value={"61-80"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                                totalObtainedMarks > 60 && totalObtainedMarks <= 80
                                                  ? {
                                                    border: "1px solid #eb6e12",
                                                    color: "white",
                                                    background: "#eb6e12"
                                                  }
                                                  : {
                                                    border: "none",
                                                    background: "none"
                                                  } }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Obtained Marks Total"
                                              name={"Obtained Marks Total"}
                                              value={"81-90"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                                totalObtainedMarks > 80 && totalObtainedMarks <= 90
                                                  ? {
                                                    border: "1px solid #eb6e12",
                                                    color: "white",
                                                    background: "#eb6e12"
                                                  }
                                                  : {
                                                    border: "none",
                                                    background: "none"
                                                  } }
                                              // disabled={true}
                                            />
                                        </div>
                                        <div
                                          className="fc-dropdown-container"
                                          style={{ marginRight: 3 }}
                                        >
                                         <input
                                          placeholder="Obtained Marks Total"
                                              name={"Obtained Marks Total"}
                                              value={"91-100"}
                                              onChange={(e) => {
                                                e.preventDefault()
                                              }}
                                              type={"text"}
                                              className={`fc-input-style`}
                                              style={
                                                totalObtainedMarks > 90 && totalObtainedMarks <= 100
                                                  ? {
                                                    border: "1px solid #eb6e12",
                                                    color: "white",
                                                    background: "#eb6e12"
                                                  }
                                                  : {
                                                    border: "none",
                                                    background: "none"
                                                  } }
                                              // disabled={true}
                                            />
                                        </div>
                                      </Grid>
                                         
                                        </Grid>
                       </Grid>
                </div>
                <br />
                </Grid>
                </Grid>
              <br />
              <br />
              <div className={classes.quotationButtonDivModal}>
                  <BootstrapTooltip arrow={true}

                      disableFocusListener
                      title={errors.target_month === undefined && errors.target_year === undefined 
                        && errors.target === undefined && errors.Alloted_Mark === undefined
                        && errors.achievement === undefined && errors.Achieve_Target === undefined
                          ? ""
                          :
                          `${errors.target_month !== undefined ? `${errors.target_month},` : ""}
                          ${errors.target_year !== undefined ? `${errors.target_year},` : ""}
                           ${errors.target !== undefined ? `${errors.target},` : ""}
                           ${errors.Alloted_Mark !== undefined ? `${errors.Alloted_Mark},` : ""}
                           ${errors.achievement !== undefined ? `${errors.achievement},` : ""}
                           ${errors.Achieve_Target !== undefined ? `${errors.Achieve_Target},` : ""}
                           `
                      }
                  >

                      <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{ backgroundColor: "#eb6e12" }}
                          onClick={handleSubmit}
                          disabled={totalAllotedMark > 100 
                            || totalObtainedMarks > 100 
                            || (isPresent && !editModalPopper) ? true : false}
                      >
                          {loading ? "Loading...." : "Submit"}
                      </Button>
                  </BootstrapTooltip>
                  <Button
                      onClick={() => {
                        setButtonText("Add PCO Target");
                          handleModaleClose();
                          // handleHide()
                      }}
                      variant="contained"
                      size="small"
                      style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                      }}
                  >
                      Cancel
                  </Button>
                  <br/>
                  {
                  totalAllotedMark > 100 || totalObtainedMarks > 100 ? 
                  <>
                  <span style={{color: "red",fontSize: "16px"}}>Total Alloted Marks or Total Target Value should not be greater than 100!</span>
                  </>
                   : isPresent && !editModalPopper ? 
                   <span style={{color: "red",fontSize: "16px"}}>Target for selected Month & Year is already added!</span>
                   :
                   ""
                 }
              </div>
          </div>
      </div>
  </Box>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default withStyles(ModalPopperFormStyles)(PCOAchievementForm);
