import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton, Checkbox, Modal } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import BusinessIcon from "@mui/icons-material/Business";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import moment from "moment";
import { addDays } from "date-fns";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { getLocalData } from "../../../../../services/services";
import LoaderClock from "../../../../lodar/LoaderClock";
import CountriesCode from "./CountriesCode";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import NewInputText from "../../../../../reusableComponents/InputText/NewInputText";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "610px",
  paddingTop: "0px",
  minHeight: "620px",
  maxHeight: "650px",
  overflow: "auto",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};


const getStyles = (errors, fieldName) => {
  if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "500",
  paddingTop: "0px",
  minHeight: "500px",
  maxHeight: "520px",
  overflow: 'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
// const styleBox = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 450,
//   bgcolor: "background.paper",
//   border: "2px solid #eb6e12",
//   outline: "none",
//   boxShadow: 24,
//   p: 4,
//   textAlign: "center",
//   padding: "25px 32px 20px 32px",
// };

const AddEditLead = ({ classes }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    operationInfo,
    editModalPopper,
    getAddNewLeadViewListingApi,
    setIsOpen
  } = useContext(SupportDataContext);

  
  const [generationDateData, setGenerationDateData] = useState(moment().toDate());
  const [matchingEmail, setMatchingEmail] = useState("");
  const [contactNumber, setContactNumber] = useState();
  const [typeOfCompany, setTypeOfCompany] = useState(editModalPopper ? operationInfo?.Type_of_Company : "");
  const [groupCompanyId, setGroupCompanyId] = useState(editModalPopper ? operationInfo?.groupCompanyId: 0);
  const [addNewLeadInquiryDropDown, setAddNewLeadInquiryDropDown] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [clientEmail, setClientEmail] = useState(operationInfo?.Client_Email_id);
  const [clientMobileCode, setClientMobileCode] = useState(operationInfo?.Client_Mobile_No_Code);
  const [clientMobileNo, setClientMobileNo] = useState(operationInfo?.Client_Mobile_No);
  const [responseData, setResponseData] = useState();
  const [openModalContect, setOpenModalContect] = useState(false);
  const [disabledCountryCode, setDisabledCountryCode] = useState(false);
  const [disabledFields, setDisabledFields] = useState(false);
  const [mobileCode, setMobileCode] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);




  useEffect(() => {
      getAddNewLeadListingDropdownApi(typeOfCompany);
  }, [groupCompanyId, typeOfCompany]);

  // useEffect(() => {
  //   if (editModalPopper) {
  //     getAddNewLeadListingDropdownApi(operationInfo?.Type_of_Company);
  //   } else {
  //     getAddNewLeadListingDropdownApi();
  //   }
  // }, []);

  const extractedData = CountriesCode[0];
  const primaryAndSecondary = Object.entries(extractedData).map(([key, value]) => ({
    primary: value.primary,
    secondary: value.secondary
  }));
  const countryCode = primaryAndSecondary?.map((item) => {
    const obj = { value: item.secondary, label: item.secondary };
    return obj;
  });


  const getAddNewLeadListingDropdownApi = async (
    typeOfCompany,
    email,
    number
  ) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inq_id = operationInfo?.Inquiry_Id?.replace("FC", "");
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&group_company_id=${groupCompanyId}`;
      if (editModalPopper && typeOfCompany) {
        url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&group_company_id=${groupCompanyId}&type_of_company_formation_type=${typeOfCompany}&inquiry_id=${inq_id}`;
      } else if (typeOfCompany) {
        url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&group_company_id=${groupCompanyId}&type_of_company_formation_type=${typeOfCompany}`;
      }
      if (email) {
        url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&group_company_id=${groupCompanyId}&type_of_company_formation_type=${typeOfCompany}&client_email=${email}`;
      }
      if (number) {
        url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&group_company_id=${groupCompanyId}&type_of_company_formation_type=${typeOfCompany}&client_mobile=${number}`;
      }
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setAddNewLeadInquiryDropDown(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const addLeadPostOrPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    // AddInquiryInDocprocessing
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    const contactNumberCode = values?.contact_number_code?.replace("+", "");

    let body = {
      emp_id: emp_id,
      user_type: user_type,
      groupCompanyId: Number(values?.group_company_id),
      lead_type: values?.lead_type,      
      leadCaption: values?.Lead_Caption,
      description: values?.description,
      typeOfCompanyName: values?.type_of_company,
      typeOfActivityName: values?.type_of_activity,
      generationDate: moment(values?.generationDateData).format("YYYY-MM-DD"),
      currentCountryId: values?.currentCountry,
      clientEmailId: values?.matchingEmail,
      clientFirstName: values?.client_first_name,
      clientLastName: values?.client_last_name,
      clientGender: values?.gender,
      clientContactNumber: values?.contact_number,
      clientNumberCode: contactNumberCode === undefined || contactNumberCode === null ? "" : contactNumberCode,
      clientAlternateContactNumber: values?.Client_Alternate_Mobile,
      homeCountryName: values?.homeCountry,
      clientCity: values?.client_city,
      assignToConsultantId: values?.assign_to_consultant,
      // mailToClient: values?.mail_to_salesperson_client,
      // smsToClient: values?.sms_to_salesperson_client,
      mailToClient: 1,
      smsToClient: 1,
    };
    if (editModalPopper) {
      body = {
        ...body,
        inquiry_id: operationInfo?.Inquiry_Id.replace("FC", ""),
      };
      axios.put(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleNewLeadPromise(data);
      });
    } else {
      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleNewLeadPromise(data);
      });
    }
  };

  const handleNewLeadPromise = (data) => {
    if (data.data.data.message) {
      setMessage(data.data.data.message);
      setSeverity(500);
    }
    if (data.data.data.msg) {
      setMessage(data.data.data.msg);
      setSeverity(500);
    }
    if (data.data.data.detail) {
      setMessage(data.data.data.detail);
      setSeverity(500);
    } else {
      setMessage(data.data.data.msg);
      setSeverity(data.data.data.code);
    }
    setIsOpen(true);
    getAddNewLeadViewListingApi();
    handleModaleClose();
  };

  const checkMobileAndEmailPostAPI = async (values) => {
    const factory_id = localStorage.getItem('factory_id');
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const Inq_Id = editModalPopper && operationInfo?.Inquiry_Id?.replace("FC", "")

    
    const url = `https://flyingcolour.online/fcapp/receivable_manager_api/check_client_email_mobile_exist/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let visitorMobileCode = clientMobileCode?.replace("+", "");

    let formData = new FormData();
    formData.append("factory_id", factory_id);
    formData.append("emp_id", emp_id);
    formData.append("user_type", user_type);
    formData.append("personal_mobile_c_code", Number(visitorMobileCode));
    formData.append("personal_mobile_no", Number(clientMobileNo));
    formData.append("personal_email", clientEmail);
    formData.append("current_social_lead_id", Number(Inq_Id));


    axios.post(url, formData, { headers: headers }).then((data) => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      setLoading(false);
      setResponseData(data?.data?.data);
      if (!editModalPopper && clientEmail && clientEmail?.match(regex)
        && data?.data?.data?.msg === "Client already exist using Same Email.") {
        setOpenModal(true);
      }
      if (!editModalPopper && clientMobileNo && clientMobileNo?.length >= 9
        && data?.data?.data?.msg === "Client already exist using Same Mobile No.") {
        setOpenModalContect(true);
      }
    })
  };

  console.log("responseData", responseData)

  const handleFindContactOrEmail = async (email, number, setFieldValue) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    try {
      let url;

      if (email) {
        url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&client_email=${email}`;
      }
      if (number) {
        url = `https://flyingcolour.online/fcapp/docprocessing_api/AddInquiryInDocprocessing/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&client_mobile=${number}`;
      }
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      if (res?.data?.Data?.client_email_exist_or_not_list !== "Email doesn't exist" && email) {
        setFieldValue("matchingEmail", "");
        setMatchingEmail("");
        setOpenModal(true);
      }
      if (
       res?.data?.Data?.client_mobile_exist_or_not_list !== "client mobile doesn't exist" && number
      ) {
        setFieldValue("contact_number", "");
        setContactNumber("");
        setOpenModal(true);
      }
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const assignConsultantDropdown =
    addNewLeadInquiryDropDown?.lead_sales_person?.map((item, i) => {
      const obj = { value: item?.EmpId, label: item?.Emp_Name };
      return obj;
    });
  const countryDropdown = addNewLeadInquiryDropDown?.country?.map((item, i) => {
    const obj = { value: item?.Country_Id, label: item?.country_name };
    return obj;
  });
  const typeOfCompanyDropdown = addNewLeadInquiryDropDown?.type_of_company?.map(
    (item, i) => {
      const obj = {
        id: item?.Formation_type_id,
        value: item?.Formation_type,
        label: item?.Formation_type,
      };
      return obj;
    }
  );
  const leadCaptionDropdown = addNewLeadInquiryDropDown?.lead_caption?.map(
    (item, i) => {
      const obj = {
        value: item?.lead_caption_name,
        label: item?.lead_caption_name,
      };
      return obj;
    }
  );
  const groupCompanyDropdown =
    addNewLeadInquiryDropDown?.group_company_list?.map((item, i) => {
      const obj = { value: item?.Id, label: item?.Name };
      return obj;
    });
    const lead_type_Option = addNewLeadInquiryDropDown?.Lead_Type2?.map((item, i) => {
      const obj = { value: item?.Value, label: item?.Name };
      return obj;
    });
  const typeOfActivityDropdown =
    addNewLeadInquiryDropDown?.type_of_activity?.map((item, i) => {
      const obj = {
        id: item?.Type_Of_Activity_Id,
        value: item?.Type_Of_Activity_Name,
        label: item?.Type_Of_Activity_Name,
      };
      return obj;
    });

  const findCurrCountryId = () => {
    const value = addNewLeadInquiryDropDown?.country?.find(
      (val) =>
        val.country_name ===
        addNewLeadInquiryDropDown?.inquiry_details?.[0]?.Nationality
    );
    return value?.Country_Id;
  };

  const findTypeOfActivityId = () => {
    const value = addNewLeadInquiryDropDown?.type_of_activity?.find(
      (val) =>
        val.Type_Of_Activity_Name ===
        addNewLeadInquiryDropDown?.inquiry_details?.[0]?.typeOfActivityName
    );
    return value?.id;
  };

  const findTypeOfCompanyId = () => {
    const value = addNewLeadInquiryDropDown?.type_of_company?.find(
      (val) =>
        val.Formation_type ===
        addNewLeadInquiryDropDown?.inquiry_details?.[0]?.Type_of_Company
    );
    return value?.id;
  };

  const handleGroupName = () => {
    const value = addNewLeadInquiryDropDown?.group_company_list?.find(
      (val) =>
        val.Id ===
        addNewLeadInquiryDropDown?.inquiry_details?.[0]?.groupCompanyId
    );
    return value?.Name;
  };

  console.log("operationInfo", operationInfo);

  const getLastPart = () => {
    const lName = operationInfo?.Client_Full_Name?.replace(/[^a-zA-Z0-9 ]/g, "");
    const parts = lName?.trim().split(" ");
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return "";
  };
  const getFirstPart = () => {
    const fName = operationInfo?.Client_Full_Name?.replace(/[^a-zA-Z0-9 ]/g, "");
    const parts = fName.split(" ");
    if (parts.length > 1) {
      parts.pop();
      return parts.join(" ");
    }
    return operationInfo?.Client_Full_Name;
  };

  // function formatPhoneNumber() {
  //   if (operationInfo?.Client_Mobile_No?.startsWith("+")) {
  //     return operationInfo?.Client_Mobile_No?.slice(1); // Remove the '+' character
  //   }
  //   return operationInfo?.Client_Mobile_No; // Return the phone number unchanged
  // }

  const parts = editModalPopper && operationInfo?.Client_Mobile_No.split("-");

// const client_Mobile_Code = "+" + parts[0];  // "92"
// const client_Mobile_No = parts[1];   // "23443445"
  const client_Mobile_Code = "+" + operationInfo?.Client_Mobile_No?.split("-")[0];
  const client_Mobile_No = operationInfo?.Client_Mobile_No?.split("-")[1];
  console.log("client_Mobile_Code", client_Mobile_Code)
  console.log("client_Mobile_No", client_Mobile_No)

  return (
    <Formik
      //enableReinitialize={true}
      initialValues={{
        group_company_name: editModalPopper ? operationInfo?.Group_Company_Name : "",
        group_company_id: editModalPopper ? operationInfo?.groupCompanyId : "",
        lead_type: editModalPopper ? operationInfo?.Lead_Type : "",
        Lead_Caption: editModalPopper ? operationInfo?.Lead_Caption : "",
        description: editModalPopper ? operationInfo?.Description : "",
        type_of_company: editModalPopper ? operationInfo?.Type_of_Company : "",
        type_of_company_id: editModalPopper ? findTypeOfCompanyId() : "",
        type_of_activity_id: editModalPopper ? findTypeOfActivityId() : "",
        type_of_activity: editModalPopper ? operationInfo?.Type_of_Activity : "",
        generationDateData: editModalPopper ? operationInfo?.Generation_Date :  moment().toDate(),
        currentCountry: editModalPopper ? operationInfo?.Current_Country_id : "",
        matchingEmail: editModalPopper ? operationInfo?.Client_Email_id : "",
        client_first_name: editModalPopper ?  getFirstPart() : "",
        client_last_name: editModalPopper ? getLastPart() : "",
        gender: editModalPopper ? operationInfo?.Gender : "",
        contact_number: editModalPopper ? client_Mobile_No : "",
        contact_number_code: editModalPopper ? client_Mobile_Code : "",
        Client_Alternate_Mobile: editModalPopper ? operationInfo?.alternative_contact_number : "",
        homeCountry: editModalPopper ? Number(operationInfo?.Home_Country) : "",
        client_city: editModalPopper ? operationInfo?.City : "",
        assign_to_consultant: editModalPopper ? operationInfo?.Consultant_Id : "",
        mail_to_salesperson_client: 0,
        sms_to_salesperson_client: 0,
      }}
      onSubmit={async (data) => {
        setLoading(true);
        addLeadPostOrPUTAPI(data);
      }}
      validate={(values) => {
        console.log("values", values)
        let errors = {};
        if (!values?.group_company_id) {
          errors.group_company_id = "Required";
        }
        if (!values?.lead_type) {
          errors.lead_type = "Required";
        }
        if (!values?.Lead_Caption) {
          errors.Lead_Caption = "Required";
        }
        if (!values?.description) {
          errors.description = "Required";
        }
        if (!values?.type_of_company) {
          errors.type_of_company = "Required";
        }
        if (values?.type_of_company !== "PRO Services") {
          if (!values?.type_of_activity) {
            errors.type_of_activity = "Type of Activity Required";
          }
        }
        if (!values?.generationDateData) {
          errors.generationDateData = "Required";
        }
        if (!values?.currentCountry) {
          errors.currentCountry = "Required";
        }
        if (!values?.client_first_name) {
          errors.client_first_name = "Required";
        }
        if (!values?.client_last_name) {
          errors.client_last_name = "Required";
        }
        if (!values?.gender) {
          errors.gender = "Required";
        }
        if (!values?.matchingEmail) {
          errors.matchingEmail = "Required";
        }
        if (!disabledCountryCode && !editModalPopper &&
          (values.contact_number_code === "" 
            || values.contact_number_code === null 
            || values.contact_number_code === undefined)
           ) {
          errors.contact_number_code = "Contact Country Code is Required!";
        }
        if (
          (responseData?.msg === undefined || responseData?.msg === "Client Email/Mobile No. Available") && 
          values.contact_number?.length < 9
        ) {
          errors.contact_number = " Minimum 9 digits are required for Contact No";
        }
        if (!values?.contact_number) {
          errors.contact_number = "Required";
        }
        if (!values?.homeCountry) {
          errors.homeCountry = "Required";
        }
        if (!values?.client_city) {
          errors.client_city = "Required";
        }
        if (!editModalPopper && !values?.assign_to_consultant) {
          errors.assign_to_consultant = " Required";
        }

        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          loading ? (
            <LoaderClock loading={loading} />
          ) : (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      handleModaleClose();
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          {editModalPopper
                            ? `Edit Lead (${addNewLeadInquiryDropDown?.inquiry_details?.[0]?.Inquiry_Id})`
                            : "Add New Lead"}
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={editModalPopper ? 6 : 4}
                            className={classes.quotationInnerGridRight}
                            style={{ borderRight: "1px solid #d3d3d3" }}
                          >
                            <div
                              className={
                                classes.listingEditUpperHeadingListingForm
                              }
                              style={{ color: "#EB6E12", fontWeight: "700" }}
                            >
                              <BusinessIcon
                                className={classes.listingEditListingFormIcons}
                              />
                              &nbsp; Add Inquiry
                            </div>

                            {editModalPopper ? (
                              <>
                                <InputText
                                  label="Group Company Name"
                                  fieldName="Group Company Name"
                                  fieldValue={values?.group_company_name}
                                  disableField={true}
                                />

                                <InputText
                                  label="Lead Caption"
                                  fieldName="Lead Caption"
                                  fieldValue={values?.Lead_Caption}
                                  disableField={true}
                                />
                                 <InputText
                                  label="Lead Type"
                                  fieldName="Lead Type"
                                  fieldValue={values?.lead_type}
                                  disableField={true}
                                />
                              </>
                            ) : (
                              <>
                                <CustomDropdown
                                  label="Group Company Name"
                                  fieldOptions={groupCompanyDropdown}
                                  fieldValue={values?.group_company_id}
                                  fieldHandleChange={(value) => {
                                    setFieldValue("group_company_name", value.label);
                                    setFieldValue("group_company_id", value.value);
                                    setGroupCompanyId(value.value);
                                    setFieldValue(`lead_type`, 
                                      value.value === 1 ? "enq" : value.value === 17 ? "vt" : ""
                                    );
                                  }}
                                  errorField={getStyles(errors,"group_company_name" )}
                                  isDisabled={editModalPopper ? true : false}
                                  requiredField={true}
                                />
                                 <span style={{ color: "red" }}>
                                    {errors.group_company_id &&
                                      touched.group_company_id &&
                                      errors.group_company_id}
                                  </span>
                                 <CustomDropdown
                                    label="Lead Type"
                                    fieldOptions={lead_type_Option}
                                    fieldValue={values?.lead_type}
                                    fieldHandleChange={(value) => {
                                      setFieldValue(`lead_type`, value.value);
                                    }}
                                    isDisabled={groupCompanyId === 1 || groupCompanyId === 17}
                                    requiredField={true}
                                  />
                                  <span style={{ color: "red" }}>
                                    {errors.lead_type &&
                                      touched.lead_type &&
                                      errors.lead_type}
                                  </span>
                                <CustomDropdown
                                  label="Lead Caption"
                                  fieldOptions={leadCaptionDropdown}
                                  fieldValue={values?.Lead_Caption}
                                  fieldHandleChange={(value) => {
                                    setFieldValue("Lead_Caption", value.value);
                                  }}
                                  requiredField={true}
                                  isDisabled={editModalPopper ? true : false}
                                  errorField={getStyles(errors, "Lead_Caption")}
                                />
                              </>
                            )}
                             <span style={{ color: "red" }}>
                                    {errors.Lead_Caption &&
                                      touched.Lead_Caption &&
                                      errors.Lead_Caption}
                                  </span>
                            <CustomTextArea
                              label="Description"
                              fieldName="description"
                              fieldValue={values.description}
                              fieldHandleChange={(e) => {
                                //  handleChangeInput(item?.id, event)
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue("description", value);
                              }}
                              errorField={getStyles(errors, "description")}
                              requiredField={true}
                            />
                             <span style={{ color: "red" }}>
                                    {errors.description &&
                                      touched.description &&
                                      errors.description}
                                  </span>
                            <CustomDropdown
                              label="Type of Company"
                              fieldOptions={typeOfCompanyDropdown}
                              fieldValue={values?.type_of_company}
                              fieldHandleChange={(value) => {
                                setFieldValue("type_of_company", value.label);
                                setFieldValue(
                                  "type_of_company_id",
                                  value.value
                                );
                                setTypeOfCompany(value.label);
                              }}
                              errorField={getStyles(errors, "type_of_company")}
                              requiredField={true}
                            />
                             <span style={{ color: "red" }}>
                                    {errors.type_of_company &&
                                      touched.type_of_company &&
                                      errors.type_of_company}
                                  </span>
                            {typeOfCompany !== "PRO Services" && (
                              <CustomDropdown
                                label="Type of Activity"
                                fieldOptions={typeOfActivityDropdown}
                                fieldValue={values?.type_of_activity}
                                fieldHandleChange={(value) => {
                                  setFieldValue(
                                    "type_of_activity",
                                    value.label
                                  );
                                  setFieldValue(
                                    "type_of_activity_id",
                                    value.value
                                  );
                                }}
                                errorField={getStyles(
                                  errors,
                                  "type_of_activity"
                                )}
                                requiredField={true}
                              />
                            )}
                             <span style={{ color: "red" }}>
                                    {errors.type_of_activity &&
                                      touched.type_of_activity &&
                                      errors.type_of_activity}
                                  </span>

                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"lead-generation-date"}
                            >
                              <Form.Label
                                column
                                sm="5"
                                style={{ fontSize: "12px", fontWeight: "700" }}
                              >
                                Lead Generation Date{" "}
                                <sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={getStyles(
                                    errors,
                                    "generationDateData"
                                  )}
                                >
                                  <div
                                    style={{ border: "0.1px solid #d3d3d3", width:"100%" }}
                                  >
                                    <DatePicker
                                      selected={generationDateData}
                                      onChange={(date) => {
                                        setGenerationDateData(date);
                                        setFieldValue(
                                          "generationDateData",
                                          date
                                        );
                                      }}
                                      style={{width:"100%"}}
                                      timeInputLabel="Time:"
                                      dateFormat="dd-MM-yyyy h:mm aa"
                                      timeFormat="p"
                                      timeIntervals={15}
                                      maxDate={addDays(new Date(), 0)}
                                      minDate={addDays(new Date(), -7)}
                                      placeholderText="Lead Generation Date"
                                      disabled={editModalPopper ? true : false}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                            <span style={{ color: "red" }}>
                                    {errors.generationDateData &&
                                      touched.generationDateData &&
                                      errors.generationDateData}
                                  </span>
                            <CustomDropdown
                              label="Current Country"
                              fieldOptions={countryDropdown}
                              fieldValue={values?.currentCountry}
                              fieldHandleChange={(value) => {
                                setFieldValue("currentCountry", value.value);
                              }}
                              errorField={getStyles(errors, "currentCountry")}
                              requiredField={true}
                            />
                          </Grid>
                          <span style={{ color: "red" }}>
                                    {errors.currentCountry &&
                                      touched.currentCountry &&
                                      errors.currentCountry}
                                  </span>
                          <Grid
                            item
                            xs={12}
                            md={editModalPopper ? 6 : 4}
                            className={classes.quotationInnerGridRight}
                            style={{ borderRight: "1px solid #d3d3d3" }}
                          >
                            <div
                              className={
                                classes.listingEditUpperHeadingListingForm
                              }
                              style={{ color: "#EB6E12", fontWeight: "700" }}
                            >
                              <AccountCircleIcon
                                className={classes.listingEditListingFormIcons}
                              />
                              &nbsp; Client Information
                            </div>

                            <InputText
                              label="First Name"
                              fieldName="Client_First_Name"
                              fieldValue={values?.client_first_name}
                              fieldHandleChange={(e) => {
                                // handleChangeInput(item?.id, event)
                                e.preventDefault();
                                const { value } = e.target;
                                const re = /^[A-Za-z]+$/;
                                if (value === "" || re.test(value)) {
                                  setFieldValue("client_first_name", value);
                                }
                              }}
                              requiredField={true}
                              errorField={getStyles(
                                errors,
                                "client_first_name"
                              )}
                              disableField={
                                editModalPopper ? true :
                                responseData?.msg ===
                                  "Client already exist using Same Mobile No." ||
                                responseData?.msg ===
                                  "Client already exist using Same Email."
                                  ? true
                                  : false
                              }
                            />
                             <span style={{ color: "red" }}>
                                    {errors.client_first_name &&
                                      touched.client_first_name &&
                                      errors.client_first_name}
                                  </span>
                            <InputText
                              label="Last Name"
                              fieldName="Client_Last_Name"
                              fieldValue={values?.client_last_name}
                              fieldHandleChange={(e) => {
                                // handleChangeInput(item?.id, event)
                                e.preventDefault();
                                const { value } = e.target;
                                const re = /^[A-Za-z]+$/;
                                if (value === "" || re.test(value)) {
                                  setFieldValue("client_last_name", value);
                                }
                              }}
                              requiredField={true}
                              errorField={getStyles(errors, "client_last_name")}
                              disableField={
                                editModalPopper ? true :
                                responseData?.msg ===
                                  "Client already exist using Same Mobile No." ||
                                responseData?.msg ===
                                  "Client already exist using Same Email."
                                  ? true
                                  : false
                              }
                            />
                             <span style={{ color: "red" }}>
                                    {errors.client_last_name &&
                                      touched.client_last_name &&
                                      errors.client_last_name}
                                  </span>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="gender"
                              values={values.gender}
                              onChange={handleChange}
                              style={getStyles(errors, "gender")}
                            >
                              <Form.Label
                                column
                                sm="5"
                                style={{ fontSize: "12px", fontWeight: "700" }}
                              >
                                Gender <sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Col sm="7">
                                <div style={{ display: "flex" }}>
                                  <Form.Check
                                    type="radio"
                                    label={"Male"}
                                    checked={values.gender === "Male"}
                                    name="gender"
                                    value="Male"
                                    disabled={editModalPopper ? true : false}
                                  />

                                  <Form.Check
                                    type="radio"
                                    label={"Female"}
                                    value="Female"
                                    name="gender"
                                    checked={values.gender === "Female"}
                                    style={{ marginLeft: "1rem" }}
                                    disabled={editModalPopper ? true : false}
                                  />
                                </div>
                              </Col>
                            </Form.Group>
                            <span style={{ color: "red" }}>
                                    {errors.gender &&
                                      touched.gender &&
                                      errors.gender}
                                  </span>
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"email"}
                            >
                              <Form.Label
                                column
                                sm="5"
                                style={{ fontSize: "12px", fontWeight: "700" }}
                              >
                                Email: <sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div style={{ marginBottom: "5px" }}>
                                  <div
                                    style={getStyles(errors, "matchingEmail")}
                                  >
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "0.1px solid #d3d3d3",
                                        padding: "4.6px 10px",
                                      }}
                                      placeholder="Email"
                                      type="email"
                                      name="matchingEmail"
                                      value={values?.matchingEmail}
                                      onChange={(e) => {
                                        // handleChangeInput(item?.id, event)
                                        e.preventDefault();
                                        const { value } = e.target;
                                        setMatchingEmail(value);
                                        setFieldValue("matchingEmail", value);
                                        setClientEmail(value);
                                      }}
                                      onBlur={(e) => {
                                        console.log("myEmail", myEmail)
                                        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                                            const myEmail = clientEmail && clientEmail.match(regex);
                                            const myMobileNo = clientMobileNo && clientMobileNo?.length >= 9;
                                            if(myEmail)
                                            {
                                              checkMobileAndEmailPostAPI();
                                            }
                                            if(
                                              (responseData?.Exist_Email_Details_Inquiry?.length === 0
                                              || responseData?.Exist_Mobile_Details_Inquiry?.length === 0
                                              )
                                              &&
                                              (responseData?.Exist_Email_Details_Socialplatform?.[0]?.Visitor_Mobile?.includes("+")
                                              || responseData?.Exist_Mobile_Details_Socialplatform?.[0]?.Visitor_Mobile?.includes("+")
                                              )
                                            )
                                            {
                                              setDisabledCountryCode(true);
                                            }
                                        // handleFindContactOrEmail(
                                        //   matchingEmail,
                                        //   false,
                                        //   setFieldValue
                                        // );
                                      }}
                                      disabled={
                                        editModalPopper ? true :
                                        responseData?.msg ===
                                          "Client already exist using Same Mobile No." ||
                                        responseData?.msg ===
                                          "Client already exist using Same Email."
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                            <span style={{ color: "red" }}>
                                    {errors.matchingEmail &&
                                      touched.matchingEmail &&
                                      errors.matchingEmail}
                                  </span>
                            {disabledFields && responseData?.msg === "Client already exist using Same Email." ?
                                    <span style={{ color: "black" }}>
                                      Client already exist using Same Email.
                                    </span>
                                    : ""}
                            <Form.Group as={Row} className="mb-1">
                                    <Form.Label column sm="5" className="fc-textarea-label">
                                   Contact No. <span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    {
                                    (!disabledCountryCode || (editModalPopper && operationInfo?.Visitor_Phone_C_Code !== "")
                                    ) &&
                                      <Col sm="3" style={{ padding: "0 12px 0 0" }} >
                                      <div style={{ border: "0.1px solid #d3d3d3" }}  >
                                        <CustomSelect
                                          options={countryCode}
                                          value={values?.contact_number_code}
                                          onChange={(value) => {
                                            setFieldValue(`contact_number_code`, value.value);
                                            setMobileCode(value.value);
                                            setClientMobileCode(value.value);
                                          }}
                                        // requiredField={true}
                                        // isDisabled={editModalPopper && tabValue === 2 ? true : false}
                                        isDisabled={
                                          editModalPopper ? true :
                                          disabledFields &&
                                          (responseData?.msg === "Client already exist using Same Mobile No."
                                          || responseData?.msg === "Client already exist using Same Email." 
                                          ) 
                                           ? true : false
                                        }
                                        />
                                      </div>
                                    </Col>
                                    }
                                    <Col sm={disabledCountryCode ? "7" : "4"} style={{ padding: "0 12px 0 0" }} >
                                      <NewInputText
                                        placeholder="Contact No"
                                        fieldName="contact_number"
                                        fieldValue={values?.contact_number}
                                        fieldHandleChange={(value) => {
                                          value.preventDefault();
                                          let inputValue = value.target.value;
                                          const regex = /^[1-9][0-9]*$/;
                                          if (inputValue === '' || regex.test(inputValue)) {
                                            setFieldValue(`contact_number`, inputValue);
                                          }
                                          setMobileNo(inputValue);
                                          setClientMobileNo(inputValue);
                                        }}
                                        // onKeyUp={handleKeyUp}
                                        // onBlur={handleKeyUp}
                                        onBlur={(e) => {
                                            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                                            const myEmail = clientEmail && clientEmail.match(regex);
                                            const myMobileNo = clientMobileNo && clientMobileNo?.length >= 9;
                                            if(myMobileNo)
                                            {
                                              checkMobileAndEmailPostAPI();
                                            }
                                            if(
                                              (responseData?.Exist_Email_Details_Inquiry?.length === 0
                                              || responseData?.Exist_Mobile_Details_Inquiry?.length === 0
                                              )
                                              &&
                                              (responseData?.Exist_Email_Details_Socialplatform?.[0]?.Visitor_Mobile?.includes("+")
                                              || responseData?.Exist_Mobile_Details_Socialplatform?.[0]?.Visitor_Mobile?.includes("+")
                                              )
                                            )
                                            {
                                              setDisabledCountryCode(true);
                                            }
                                          // handleFindContactOrEmail(
                                          //   false,
                                          //   contactNumber,
                                          //   setFieldValue
                                          // );
                                        }}
                                        requiredField={true}
                                        fieldType={"text"}
                                        disableField={
                                          editModalPopper ? true :
                                          disabledFields &&
                                         (responseData?.msg === "Client already exist using Same Mobile No."
                                            || responseData?.msg === "Client already exist using Same Email."
                                          )
                                            ? true : false
                                        }
                                      />
                                    </Col>
                                  </Form.Group>
                                  <span style={{ color: "red" }}>
                                    {errors.contact_number_code &&
                                      touched.contact_number_code &&
                                      errors.contact_number_code}
                                  </span>
                                  <br/>
                                  <span style={{ color: "red" }}>
                                    {errors.contact_number &&
                                      touched.contact_number &&
                                      errors.contact_number}
                                  </span>
                                  {disabledFields && responseData?.msg === "Client already exist using Same Mobile No." ?
                                    <span style={{ color: "black" }}>
                                      Client already exist using Same Mobile No
                                    </span>
                                    : ""}
                            {/* <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"contact-number"}
                            >
                              <Form.Label
                                column
                                sm="5"
                                style={{ fontSize: "12px", fontWeight: "700" }}
                              >
                                Contact Number{" "}
                                <sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div style={{ marginBottom: "5px" }}>
                                  <div className={classes.selectContainer}>
                                    <div
                                      style={getStyles(errors, "contactNumber")}
                                    >
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "0.1px solid #d3d3d3",
                                          padding: "4.6px 10px",
                                        }}
                                        placeholder="Contact Number"
                                        name="contact_number"
                                        value={values?.contact_number}
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const { value } = e.target;
                                          const regex = /^[1-9][0-9]*$/;
                                          if (
                                            e.target.value === "" ||
                                            regex.test(value)
                                          ) {
                                            setContactNumber(value);
                                            setFieldValue(
                                              "contact_number",
                                              value
                                            );
                                          }
                                        }}
                                        onBlur={(e) => {
                                          checkMobileAndEmailPostAPI()
                                          // handleFindContactOrEmail(
                                          //   false,
                                          //   contactNumber,
                                          //   setFieldValue
                                          // );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Form.Group> */}

                            <InputText
                              label="Alternate Contact Number"
                              fieldName="Client_Alternate_Mobile"
                              fieldValue={values?.Client_Alternate_Mobile}
                              fieldHandleChange={(e) => {
                                //  handleChangeInput(item?.id, event)
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue("Client_Alternate_Mobile", value);
                              }}
                            />

                            <CustomDropdown
                              label="Country"
                              fieldOptions={countryDropdown}
                              fieldValue={values?.homeCountry}
                              fieldHandleChange={(value) => {
                                setFieldValue("homeCountry", value.value);
                              }}
                              isDisabled={editModalPopper ? true : false}
                            />
                            <span style={{ color: "red" }}>
                                    {errors.homeCountry &&
                                      touched.homeCountry &&
                                      errors.homeCountry}
                                  </span>
                            <InputText
                              label="City"
                              fieldName="client_city"
                              fieldValue={values?.client_city}
                              fieldHandleChange={(e) => {
                                //  handleChangeInput(item?.id, event)
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue("client_city", value);
                              }}
                              disableField={editModalPopper ? true : false}
                            />
                            <span style={{ color: "red" }}>
                                    {errors.client_city &&
                                      touched.client_city &&
                                      errors.client_city}
                                  </span>
                          </Grid>
                          {!editModalPopper && (
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.quotationInnerGridRight}
                            style={{ borderRight: "1px solid #d3d3d3" }}
                          >
                                <div
                                  className={
                                    classes.listingEditUpperHeadingListingForm
                                  }
                                  style={{
                                    color: "#EB6E12",
                                    fontWeight: "700",
                                  }}
                                >
                                  <AssignmentIcon
                                    className={
                                      classes.listingEditListingFormIcons
                                    }
                                  />
                                  &nbsp; Lead Assignment
                                </div>

                                <CustomDropdown
                                  label="Assign to Consultant"
                                  fieldOptions={assignConsultantDropdown}
                                  fieldValue={values?.assign_to_consultant}
                                  fieldHandleChange={(value) => {
                                    setFieldValue(
                                      "assign_to_consultant",
                                      value.value
                                    );
                                  }}
                                  errorField={getStyles(
                                    errors,
                                    "assign_to_consultant"
                                  )}
                                  requiredField={true}
                                />
                                 <span style={{ color: "red" }}>
                                    {errors.assign_to_consultant &&
                                      touched.assign_to_consultant &&
                                      errors.assign_to_consultant}
                                  </span>
                                {/* <Form.Group
                                  as={Row}
                                  className="mb-1"
                                  controlId={"checkb"}
                                >
                                  <Form.Label column sm="5"></Form.Label>
                                  <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                    <br />
                                    <div style={{ marginBottom: "8px" }}>
                                      <span
                                        className={
                                          classes.listingeditModalPopperCheckBoxDataSpan
                                        }
                                      >
                                        <Checkbox
                                          size="small"
                                          style={{
                                            padding: "0px",
                                            color: "#eb6e12",
                                          }}
                                          value={
                                            values?.mail_to_salesperson_client
                                          }
                                          type="checkbox"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setFieldValue(
                                                "mail_to_salesperson_client",
                                                1
                                              );
                                            } else
                                              setFieldValue(
                                                "mail_to_salesperson_client",
                                                0
                                              );
                                          }}
                                        />
                                        <span className={classes.textSpan}>
                                          Mail to Salesperson and Client
                                        </span>
                                      </span>
                                    </div>
                                    <br />
                                    <div style={{ marginBottom: "8px" }}>
                                      <span
                                        className={
                                          classes.listingeditModalPopperCheckBoxDataSpan1
                                        }
                                      >
                                        <Checkbox
                                          size="small"
                                          style={{
                                            padding: "0px",
                                            color: "#eb6e12",
                                          }}
                                          value={
                                            values?.sms_to_salesperson_client
                                          }
                                          type="checkbox"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setFieldValue(
                                                "sms_to_salesperson_client",
                                                1
                                              );
                                            } else
                                              setFieldValue(
                                                "sms_to_salesperson_client",
                                                0
                                              );
                                          }}
                                        />
                                        <span className={classes.textSpan}>
                                          SMS to Salesperson and Client
                                        </span>
                                      </span>
                                    </div>
                                  </Col>
                                </Form.Group> */}
                              </Grid>
                            )}
                          
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        handleModaleClose();
                        // handleHide()
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    </div>
                  </div>
                </div>
              </Box>
              <Modal open={openModalContect}>
                <Box sx={styleBox}>
                  <div style={{ height: "500px", overflowX: "hidden", padding: "0", }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                        }}
                      >
                        <div className={classes.headingContainer}>
                          <span className={classes.modalHeader}>
                            {" "}
                            {`Client Duplication`}
                          </span>
                        </div>
                        <div
                          style={{ marginBottom: "10px", marginTop: "0px" }}
                          className={classes.followUpFormModalFlexDivContaner}
                        >
                          {responseData?.Exist_Mobile_Details_Inquiry?.length > 0 &&
                            <>
                              <p style={{ fontSize: "15px", marginTop: "15px", textAlign: "center" }}>
                                The Following Contact No Already Exists on Qualified Lead! <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                                  {responseData?.Exist_Mobile_Details_Inquiry?.[0].Client_Mobile}
                                </span>
                              </p>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex", }}
                                  className={classes.followUpFormInnerGridLeft}
                                >
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Sr No.</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Inquiry Id</span>
                                  </Grid>
                                 
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Source</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Type</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Mobile</span>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Email</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Generation Date</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Advisor Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Advisor Assign Date</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.lastCellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Conversion Status</span>
                                  </Grid>

                                </Grid>

                                {
                                  responseData?.Exist_Mobile_Details_Inquiry?.length === 0 ?
                                    <span style={{ marginLeft: "50%", fontSize: "14px", fontWeight: "bold" }}>No Data</span>
                                    :
                                    responseData?.Exist_Mobile_Details_Inquiry?.map((item, i) => {
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                          >
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {i + 1}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              FC{item?.Lead_Id}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Source}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Type}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {`${item?.Client_First_Name} ${item?.Client_Last_Name}`}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Client_Mobile}
                                            </Grid>
                                            <Grid
                                              sx={{ overflowY: "scroll", maxHeight: "250px" }}
                                              xs={2}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Client_Email}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Generation_Date === "N/A" || item?.Lead_Generation_Date === "" ? "N/A" :
                                                moment(item?.Lead_Generation_Date).format("DD-MM-YYYY HH:mm:ss")
                                              }
                                            </Grid>
                                            <Grid
                                              sx={{ overflowY: "scroll", maxHeight: "250px" }}
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Consultant_Name}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Assign_Date === "N/A" || item?.Lead_Assign_Date === "" ? "N/A" :
                                                moment(item?.Lead_Assign_Date).format("DD-MM-YYYY HH:mm:ss")
                                              }
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.lastCellData}
                                            >
                                              {item?.Conversation_Status}
                                            </Grid>
                                          </Grid>
                                        </>
                                      )

                                    })
                                }
                              </Grid>
                            </>
                          }
                          {
                            responseData?.Exist_Mobile_Details_Socialplatform?.length > 0 &&
                            <>
                              <p style={{ fontSize: "15px", marginTop: "15px", textAlign: "center" }}>
                                The Following Contact No Already Exists on Pre Qualify Lead! <span style={{ fontWeight: "bold", fontSize: "15px", }}>
                                  {responseData?.Exist_Mobile_Details_Socialplatform?.length > 0 && responseData?.Exist_Mobile_Details_Socialplatform?.[0]?.Visitor_Mobile}
                                </span>
                              </p>

                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex", }}
                                  className={classes.followUpFormInnerGridLeft}
                                >
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Sr No.</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>PQL Id</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Source</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Type</span>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Mobile</span>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Email</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Generation Date</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>LQO Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.lastCellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>PQL Status</span>
                                  </Grid>
                                </Grid>

                                {
                                  responseData?.Exist_Mobile_Details_Socialplatform?.length === 0 ?
                                    <span style={{ marginLeft: "50%", fontSize: "14px", fontWeight: "bold" }}>No Data</span>
                                    :
                                    responseData?.Exist_Mobile_Details_Socialplatform?.map((item, i) => {
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                          >
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {i + 1}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Source}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Id}
                                            </Grid>
                                            <Grid
                                              xs={2}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {`${item?.Visitor_Name}`}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Visitor_Mobile}
                                            </Grid>
                                            <Grid
                                              sx={{ overflowY: "scroll", maxHeight: "250px" }}
                                              xs={2}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Visitor_Email}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Generation_Date === "N/A" || item?.Lead_Generation_Date === "" ? "N/A" :
                                                moment(item?.Lead_Generation_Date).format("DD-MM-YYYY HH:mm:ss")
                                              }
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.LQO_Name}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.lastCellData}
                                            >
                                              {item?.PQL_Status}
                                            </Grid>
                                          </Grid>
                                        </>
                                      )

                                    })
                                }
                              </Grid>
                            </>
                          }
                        </div>
                      </Grid>
                    </Grid>

                  </div>
                  <div className={classes.BorderSpacingLowerListing}></div>
                  <br />
                  <div
                    className={classes.popperText}
                    style={{ alignItems: "center" }}
                  >
                    <Button
                                    onClick={() => {
                                      const fName = `${responseData?.Exist_Mobile_Details_Inquiry?.[0]?.Client_First_Name}`;
                                      const lName = `${responseData?.Exist_Mobile_Details_Inquiry?.[0]?.Client_Last_Name}`;
                                      const fName2 = `${responseData?.Exist_Mobile_Details_Socialplatform?.[0]?.Visitor_Name}`;
                                      const mobileCode = responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Mobile?.includes('-') ?
                                      "+" + responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Mobile?.split("-")[0] : ""
                                      const mobileNo =
                                        responseData?.Exist_Mobile_Details_Inquiry?.[0]?.Client_Mobile?.split(
                                          "-"
                                        )[1];
                                        const mobileNo2 =
                                        responseData?.Exist_Mobile_Details_Inquiry?.[0]?.Client_Mobile;
                                      const mobileCode2 =
                                        responseData
                                          ?.Exist_Mobile_Details_Socialplatform?.[0]
                                          ?.Visitor_Mobile_Code;
                                      const mobileNo3 =
                                        responseData?.Exist_Mobile_Details_Socialplatform?.[0]?.Visitor_Mobile?.replace(
                                          "+",
                                          ""
                                        );
                                        const mobileNo4 =
                                        responseData?.Exist_Mobile_Details_Socialplatform?.[0]?.Visitor_Mobile;
                                      const email =
                                        responseData
                                          ?.Exist_Mobile_Details_Socialplatform?.[0]
                                          ?.Visitor_Email;
                                      const email2 =
                                        responseData
                                          ?.Exist_Mobile_Details_Inquiry?.[0]
                                          ?.Client_Email;
                                      const gender =
                                        responseData
                                          ?.Exist_Mobile_Details_Inquiry?.[0]
                                          ?.Client_Gender;
                                      const gender2 =
                                        responseData
                                          ?.Exist_Mobile_Details_Socialplatform?.[0]
                                          ?.Client_Gender;
                                      const Fparts = fName2.split(" ");
                                      Fparts.pop();
                                      const newfName2 = Fparts.join(" ");
                                      const first_name =
                                        responseData
                                          ?.Exist_Mobile_Details_Inquiry
                                          ?.length === 0
                                          ? newfName2
                                          : fName;
                                      const parts = fName2.split(" ");
                                      const lastName = parts[parts.length - 1];
                                      const last_name =
                                        responseData
                                          ?.Exist_Mobile_Details_Inquiry
                                          ?.length === 0
                                          ? lastName
                                          : lName;
                                      setAnchorEl(null);
                                      setOpenModalContect(false);
                                      setDisabledFields(true);
                                      // setClientMobileNo("")
                                      setFieldValue(`client_first_name`, first_name);
                                      setFieldValue(`client_last_name`, last_name);
                                      setFieldValue(
                                        `contact_number_code`,
                                        mobileCode || mobileCode2
                                      );
                                      setFieldValue(
                                        `contact_number`,
                                        mobileNo || mobileNo2 || mobileNo3 || mobileNo4
                                      );
                                      setFieldValue(
                                        `matchingEmail`,
                                        email || email2
                                      );
                                      setFieldValue(
                                        `gender`,
                                        gender || gender2
                                      );
                                    }}
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: "#eb6e12",
                                      color: "#fff",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    OK
                                  </Button>

                  </div>
                </Box>
              </Modal>
              <Modal open={openModal}>
                <Box sx={styleBox}>
                  <div style={{ height: "500px", overflowX: "hidden", padding: "0", }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                        }}
                      >
                        <div className={classes.headingContainer}>
                          <span className={classes.modalHeader}>
                            {" "}
                            {`Client Duplication`}
                          </span>
                        </div>
                        <div
                          style={{ marginBottom: "10px", marginTop: "0px" }}
                          className={classes.followUpFormModalFlexDivContaner}
                        >
                          {responseData?.Exist_Email_Details_Inquiry?.length > 0 &&
                            <>
                              <p style={{ fontSize: "15px", marginTop: "15px", textAlign: "center" }}>
                                The Following Email Already Exists on Qualified Lead! <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                                  {responseData?.Exist_Email_Details_Inquiry?.[0].Client_Email}
                                </span>
                              </p>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex", }}
                                  className={classes.followUpFormInnerGridLeft}
                                >
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Sr No.</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Inquiry Id</span>
                                  </Grid>
                                 
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Source</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Type</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Mobile</span>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Email</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Generation Date</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Advisor Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Advisor Assign Date</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.lastCellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Conversion Status</span>
                                  </Grid>

                                </Grid>

                                {
                                  responseData?.Exist_Email_Details_Inquiry?.length === 0 ?
                                    <span style={{ marginLeft: "50%", fontSize: "14px", fontWeight: "bold" }}>No Data</span>
                                    :
                                    responseData?.Exist_Email_Details_Inquiry?.map((item, i) => {
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                          >
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {i + 1}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              FC{item?.Lead_Id}
                                            </Grid>
                                           
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Source}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Type}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {`${item?.Client_First_Name} ${item?.Client_Last_Name}`}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Client_Mobile}
                                            </Grid>
                                            <Grid
                                              sx={{ overflowY: "scroll", maxHeight: "250px" }}
                                              xs={2}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Client_Email}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Generation_Date === "N/A" || item?.Lead_Generation_Date === "" ? "N/A" :
                                                moment(item?.Lead_Generation_Date).format("DD-MM-YYYY HH:mm:ss")
                                              }
                                            </Grid>
                                            <Grid
                                              sx={{ overflowY: "scroll", maxHeight: "250px" }}
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Consultant_Name}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Assign_Date === "N/A" || item?.Lead_Assign_Date === "" ? "N/A" :
                                                moment(item?.Lead_Assign_Date).format("DD-MM-YYYY HH:mm:ss")
                                              }
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.lastCellData}
                                            >
                                              {item?.Conversation_Status}
                                            </Grid>
                                          </Grid>
                                        </>
                                      )

                                    })
                                }
                              </Grid>
                            </>
                          }
                          {
                            responseData?.Exist_Email_Details_Socialplatform?.length > 0 &&
                            <>
                              <p style={{ fontSize: "15px", marginTop: "15px", textAlign: "center" }}>
                                The Following Email Already Exists on Pre Qualify Lead! <span style={{ fontWeight: "bold", fontSize: "15px", }}>
                                  {responseData?.Exist_Email_Details_Socialplatform?.length > 0 && responseData?.Exist_Email_Details_Socialplatform?.[0]?.Visitor_Email}
                                </span>
                              </p>

                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex", }}
                                  className={classes.followUpFormInnerGridLeft}
                                >
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Sr No.</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>PQL Id</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Source</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lead Type</span>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Mobile</span>
                                  </Grid>
                                  <Grid
                                    xs={2}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Client Email</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Generation Date</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.cellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>LQO Name</span>
                                  </Grid>
                                  <Grid
                                    xs={1}
                                    style={{ fontWeight: "600", height: "auto", borderTop: "1px solid #c3c3c3" }}
                                    className={classes.lastCellData}
                                  >
                                    <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>PQL Status</span>
                                  </Grid>

                                </Grid>

                                {
                                  responseData?.Exist_Email_Details_Socialplatform?.length === 0 ?
                                    <span style={{ marginLeft: "50%", fontSize: "14px", fontWeight: "bold" }}>No Data</span>
                                    :
                                    responseData?.Exist_Email_Details_Socialplatform?.map((item, i) => {
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                          >
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {i + 1}
                                            </Grid>

                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Id}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Source}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Type}
                                            </Grid>
                                            <Grid
                                              xs={2}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {`${item?.Visitor_Name}`}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Visitor_Mobile}
                                            </Grid>
                                            <Grid
                                              sx={{ overflowY: "scroll", maxHeight: "250px" }}
                                              xs={2}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Visitor_Email}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.Lead_Generation_Date === "N/A" || item?.Lead_Generation_Date === "" ? "N/A" :
                                                moment(item?.Lead_Generation_Date).format("DD-MM-YYYY HH:mm:ss")
                                              }
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.cellData}
                                            >
                                              {item?.LQO_Name}
                                            </Grid>
                                            <Grid
                                              xs={1}
                                              style={{ height: "auto" }}
                                              className={classes.lastCellData}
                                            >
                                              {item?.PQL_Status}
                                            </Grid>
                                          </Grid>
                                        </>
                                      )

                                    })
                                }
                              </Grid>
                            </>
                          }
                        </div>
                      </Grid>
                    </Grid>

                  </div>
                  <div
                    className={classes.popperText}
                    style={{ alignItems: "center" }}
                  >
                   <Button
                                    onClick={() => {
                                      const fName = `${responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_First_Name}`;
                                      const lName = `${responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Last_Name}`;
                                      const fName2 = `${responseData?.Exist_Email_Details_Socialplatform?.[0]?.Visitor_Name}`;
                                      const mobileCode = responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Mobile?.includes('-') ?
                                        "+" +
                                        responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Mobile?.split(
                                          "-"
                                        )[0] : ""
                                      const mobileNo =
                                        responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Mobile?.split(
                                          "-"
                                        )[1];
                                        const mobileNo4 =
                                        responseData?.Exist_Email_Details_Inquiry?.[0]?.Client_Mobile;
                                      const mobileCode2 =
                                        responseData
                                          ?.Exist_Email_Details_Socialplatform?.[0]
                                          ?.Visitor_Mobile_Code;
                                      const mobileNo2 =
                                        responseData?.Exist_Email_Details_Socialplatform?.[0]?.Visitor_Mobile?.replace(
                                          "+",
                                          ""
                                        );
                                        const mobileNo3 =
                                        responseData?.Exist_Email_Details_Socialplatform?.[0]?.Visitor_Mobile;
                                      const email =
                                        responseData
                                          ?.Exist_Email_Details_Socialplatform?.[0]
                                          ?.Visitor_Email;
                                      const email2 =
                                        responseData
                                          ?.Exist_Email_Details_Inquiry?.[0]
                                          ?.Client_Email;
                                      const gender =
                                        responseData
                                          ?.Exist_Email_Details_Inquiry?.[0]
                                          ?.Client_Gender;
                                      const gender2 =
                                        responseData
                                          ?.Exist_Email_Details_Socialplatform?.[0]
                                          ?.Client_Gender;
                                      const Fparts = fName2.split(" ");
                                      Fparts.pop();
                                      const newfName2 = Fparts.join(" ");
                                      console.log("newfName2", newfName2);
                                      const first_name =
                                        responseData
                                          ?.Exist_Email_Details_Inquiry
                                          ?.length === 0
                                          ? newfName2
                                          : fName;
                                      console.log("first_name", first_name);
                                      const parts = fName2.split(" ");
                                      const lastName = parts[parts.length - 1];
                                      const last_name =
                                        responseData
                                          ?.Exist_Email_Details_Inquiry
                                          ?.length === 0
                                          ? lastName
                                          : lName;

                                      setAnchorEl(null);
                                      setOpenModal(false);
                                      setDisabledFields(true);
                                      // setClientMobileNo("")
                                      setFieldValue(`client_first_name`, first_name);
                                      setFieldValue(`client_last_name`, last_name);
                                      setFieldValue(
                                        `contact_number_code`,
                                        mobileCode || mobileCode2
                                      ); 
                                      setFieldValue(
                                        `contact_number`,
                                        mobileNo || mobileNo2 || mobileNo3 || mobileNo4
                                      );
                                      setFieldValue(
                                        `matchingEmail`,
                                        email || email2
                                      );
                                      setFieldValue(
                                        `gender`,
                                        gender || gender2
                                      );
                                    }}
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: "#eb6e12",
                                      color: "#fff",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    OK
                                  </Button>
                  </div>
                </Box>
              </Modal>
              {/* <Modal open={openModal}>
              <Box sx={styleBox}>
                <p>
                  Email/Contact Number already exists.Please Change the
                  Email/Contact Number
                </p>
                <div className={classes.quotationButtonDivModal}>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                      // handleHide()
                    }}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#fff",
                      color: "#eb6e12",
                      marginLeft: "10px",
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Box> 
            </Modal> */}
            </form>
          )
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(AddEditLead);
