import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../Styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from 'react-bootstrap/Alert';
import { FaStopCircle } from "react-icons/fa";



const ScheduleTrainingLogTable = ({ classes }) => {
  const {
    message,
    setButtonText,
    setOperationInfo,
    handleModalOpen,
    operationInfo,
    getScheduleTrainingLogDataApi, scheduleTrainingLogData
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  
  useEffect(() => {
    getScheduleTrainingLogDataApi();
  }, []);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const customActionBtnRenderer = (params) => {
    return (
      <>
        <CustomIconButton
          onClick={() => {
            handleModalOpen();
            setButtonText("Freeze Order Approval");
            // scrollToTop()
            //ScrollTop();
            //setOperationInfo(params.data);
          }}
          title={"Freeze Order Approval"}
          icon={<FaStopCircle style={{ margin: "0", fontSize: "25px" }} />}
          className="btnActionAlignment"
        />
      </>
    )
  }

  const [columnDefs] = useState([
    {
      headerName: "Sr.",
      field: "Serial_Number",
      width: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    
    {
      headerName: "Status",
      field: "status",
      filter: true,
      minWidth: 220,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Status By",
      field: "status_by",
      filter: "agTextColumnFilter",
      minWidth: 200,
      //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Status Date",
      field: "status_date",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      minWidth: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.status_date === "" ? "" :
          moment(params?.data?.status_date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Status By Role",
      field: "status_by_role",
      filter: "agTextColumnFilter",
      minWidth: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Remarks",
      field: "remarks",
      filter: "agTextColumnFilter",
      minWidth: 250,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
    },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 150,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
      flex: 1,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>

      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={scheduleTrainingLogData}
            columnDefs={columnDefs}
            components={components}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default withStyles(modalTableStyles)(ScheduleTrainingLogTable);
