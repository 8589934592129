import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import {
    FaFileUpload,
    FaFileMedical,
    FaEye,
    FaSync,
    FaTimes,
    FaHandshake,
    FaIdBadge,
    FaPlus,
    FaReply,
  } from "react-icons/fa";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import { DataContext, SupportDataContext } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import ModalPopper from "../Modals/ModelPopper";
  import { Button } from "react-bootstrap";
  import Table from "react-bootstrap/Table";
  import ApprovalIcon from "@mui/icons-material/Approval";
  import GradingIcon from "@mui/icons-material/Grading";
  import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
  import { Grid } from "@material-ui/core";
  import EditIcon from "@mui/icons-material/Edit";
  import { getTotalLength } from "../../../../utils/Filters";
  import PreviewIcon from '@mui/icons-material/Preview';
  import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
  import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
  
  
  
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const OperationEscalationTable = ({
    apiData,
    upperIcons,
    lowerIcons,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setEditModalPopper,
      handleModalOpen,
      message,
      severity,
      isOpen,
      setIsOpen,
      getOrderEscalationListData,
      orderEscalationData,
      setRowData,
      isCompleteOrderBtnDisable
    } = useContext(SupportDataContext);
  
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    // const [isOpen, setIsOpen] = useState(false);
    // const [message, setMessage] = useState();
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    // const [filterOrders, setFilterOrders] = useState();
    const [complaintId, setComplaintId] = useState(0);
  
  
    useEffect(() => {
      getOrderEscalationListData();
      handleListingSavedSearch();
    }, []);

  console.log(orderEscalationData)
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);   
    
    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue.split(" ");
        if (dateAsString == null) {
          return -1;
        }
        if (dateAsString == "") {
          return -1;
        }
        const dateParts = dateAsString[0].split("-");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
   
    const [columnDefs] = useState([
      {
        headerName: "S #",
        field: "Serial_Number",
        filter: false,
        width: 60,
        maxWidth: 90,
        pinned: "left",
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
      {
        headerName: "Actions",
        field: "Inquiry_Id",
        filter: false,
        // checkboxSelection: true,
        cellRenderer:
            (params) => {
                return (
                    <>
               {params?.data?.status === "Created" || params?.data?.status === "Under Investigation" ? (
              <CustomIconButton
                onClick={() => {
                  setButtonText("Change Order Escalation Status")
                  setOperationInfo(params.data);
                  // setEditForm(true)
                 handleModalOpen();
                }}
                title={"Change Escalation Status"}
                icon={<CheckCircleOutlineIcon />}
                className="btnActionAlignment"
                disabled={ params.data?.Order_Cancellation_Status === "Approved by Accounts"
                  || params.data?.Order_Cancellation_Status === "Requested by Support" ? true : false}
              />
              ): null}
                    </>
                );
            },
        cellStyle: { border: "1px solid #fff", overflowY: 'auto' },
        width: 120,
        pinned: "left",
    },
    {
      headerName: "Order No",
      field: "order_no",
      filter: "agTextColumnFilter",
      width: 200,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Company Name",
      field: "company",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
      pinned: "left",
    },
      {
        headerName: "Priority",
        field: "priority",
        filter: true,
        width: 140,
        pinned: "left",
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
      {
        headerName: "Status",
        field: "status",
        filter: true,
        width: 130,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
        pinned: "left",
      },
      {
        headerName: "Support Status date",
        field: "support_status_date",
        filter: "agDateColumnFilter",
        width: 230,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
        filterParams: filterParams,
        cellRenderer: (params) => {
           return (
             <>
             {params.data.support_status_date === "" || params.data.support_status_date === null ? "" :
               moment(params?.data?.support_status_date).format("DD-MM-YYYY")}</>
           );
         },
      },
      {
        headerName: "Support Remarks",
        field: "support_remarks",
        filter: "agTextColumnFilter",
        width: 250,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },
      {
        headerName: "Support Name",
        field: "support_name",
        filter: "agTextColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },
      {
        headerName: "Employee Name",
        field: "complain_emp_name",
        filter: "agTextColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },
      {
          headerName: "Change Status By",
          field: "status_by",
          filter: "agTextColumnFilter",
          width: 200,
          maxWidth: 230,
          cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
      {
        headerName: "Change Status Date",
        field: "status_date",
        filter: "agDateColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
        filterParams: filterParams,
        cellRenderer: (params) => {
          return (
            <>
            {params.data.status_date === "" || params.data.status_date === null ? "" :
              moment(params?.data?.status_date).format("DD-MM-YYYY hh:mm:ss")}</>
          );
        },
    },
      {
        headerName: "Change Status Remarks",
        field: "status_remarks",
        filter: "agTextColumnFilter",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 250,
      },
     
      {
        headerName: "Updated By",
        field: "updated_by",
        filter: "agTextColumnFilter",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
        width: 200,
      },
      {
        headerName: "Updated Date",
        field: "update_date",
        filter: "agDateColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
        filterParams: filterParams,
        cellRenderer: (params) => {
          return (
            <>
            {params.data.update_date === "" || params.data.update_date === null ? "" :
              moment(params?.data?.update_date).format("DD-MM-YYYY hh:mm:ss")}</>
          );
        },
    },
    ]);
  

    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue.split(" ");
              if (dateAsString == null) {
                return 0;
              }
              const dateParts = dateAsString[0].split("-");
              const day = Number(dateParts[2]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
          },
        },
      };
    }, []);
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const filters = [
      {
        id: 1,
        title: "Default",
        value: null,
        key: null,
      },
      {
        id: 1,
        title: "Created",
        value: "Created",
        key: "status",
      },
      {
        id: 2,
        title: "Under Investigation",
        value: "Under Investigation",
        key: "status",
      },
      {
        id: 3,
        title: "Valid and Resolved",
        value: "Valid and Resolved",
        key: "status",
      },
      {
        id: 4,
        title: "Void",
        value: "Void",
        key: "status",
      },
  
    ];
    
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const handleFilters = (title, value, key) => {
     
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.onFilterChanged();
      if (key === null) {
        gridRef.current.api.setFilterModel(null);
      } else if (key === "Enquiry_Assigned_Date") {
        gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
          type: "contains",
          dateFrom: value,
          dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
      } else if (key === "}") {
      } else {
        gridRef.current.api
          .getFilterInstance(key.toString())
          .setModel({ values: [value.toString()] });
        gridRef.current.api.onFilterChanged();
      }
  
      setDefaultFilterValue(title);
    };
  
    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    const handleSearch = (searchName) => {
      handleFilters(searchName, searchName, "Inquiry_Id");
    };
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
  
        {fitlerBadge ? (
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                marginTop: "30px",
                paddingBottom: "15px",
              }}
            >
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={orderEscalationData?.Escalation_List}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                {orderEscalationData?.Escalation_List?.length > 0 &&
                  filters.map((val, i) => {
                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(orderEscalationData?.Escalation_List, val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: "Times New Roman",
                        }}
                        onClick={() =>
                          handleFilters(val.title, val.value, val.key)
                        }
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLength(orderEscalationData?.Escalation_List, val.Search_Name, valueKey)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
  
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(
                            val.Search_Name,
                            val.Search_Name,
                            valueKey
                          );
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>
          </>
        ) : null}
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={orderEscalationData?.Escalation_List?.length}
        />
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={orderEscalationData?.Escalation_List}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            // onSelectionChanged={onRowSelected}
            components={components}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            rowHeight={90}
            pagination={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <ModalPopper />
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default OperationEscalationTable;
  