import { Button } from "@mui/material";
import React from "react";
import Error from "../pages/listing/listingTable/ListingExpandableTable/modal/Error";
export class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // Display fallback UI
    this.setState({ hasError: true });
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>

        <h1><b>500 Error</b></h1>
        <br></br>
        <h1>Something went wrong here.</h1>
        <Button 
        onClick={()=>window.location.reload(false)}
        >Go Back</Button>
      </div>;
    }

    return this.props.children;
  }
}