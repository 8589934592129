const ModalPopperFormStyles = () => ({
    quotationFormGroupFlexDivModal: { 
      display: "flex",
      marginBottom: "3px",
    },
    quotationFormGroupFlexDivModalVT: {
      display: "flex",
      marginBottom: "3px",
      width: "100%",
    },
    quotationFormGroupFlexDivModalMulti: {
      display: "grid",
      marginBottom: "3px",
      width: "100%",
    },
    quotationFormGroupFlexDivModalNew: {
      marginBottom: "3px",
    },
    quotationFormGroupFlexDivModalRichText: {
      marginBottom: "-10px",
    },
    quotationGridItemContainer: {
      paddingTop: "20px",
      marginBottom: "-30px",
    },
    quotationLabelFormInputModal: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationLabelFormInputModalVT: {
      width: "25%",
      fontWeight: "600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationLabelFormInputModalCheckboxLable: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationLabelFormInputModalCheckboxLablePackageProposal: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationLabelFormInputModalCheckboxLableShareHolder: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationLabelFormInputModalChanges: {
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationLabelFormInputModalCheckBox: {
      width: "50%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    quotationSelectContainerModal: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
    },
    quotationSelectContainerModalVT: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      width: "100%",
    },
    quotationSelectContainerModal1: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      width: "100%",
    },
    quotationSelectContainerModalGridCss: {
      fontSize: "12px",
      float: "left",
      marginRight: "2px",
    },
  
    quotationSelectContainerModalCheckbox: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      alignItems: "center",
      display: "flex",
    },
    quotationSelectContainerModalCheckboxMain: {
      fontSize: "12px",
      // float: 'right',
      marginLeft: "-9.3px",
      alignItems: "center",
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        // marginLeft: '-15px',
      },
    },
    quotationSelectContainerModalCheckboxSahre: {
      fontSize: "10px",
      float: "right",
      marginRight: "2px",
      alignItems: "center",
      display: "flex",
    },
    quotationSelectContainerModalCheckbox1: {
      fontSize: "12px",
      float: "right",
      marginRight: "27px",
      alignItems: "center",
      marginLeft: "auto",
    },
    quotationDemoDataCheckModal: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "right",
    },
    quotationDemoDataCheckModalCustomSelect: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "left",
      width:"95%"
    },
    quotationDemoDataCheckModal1: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "right",
      width: "100%",
    },
    quotationDemoDataCheckModalLongWidthField: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "right",
      width: "252px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "400px",
      },
    },
    quotationDemoDataCheckModalLongWidthField1: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      // float: 'right',
      width: "252px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "400px",
      },
    },
    quotationDemoDataCheckModalLongWidthFieldActivity: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      // float: 'right',
      width: "252px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "452px",
      },
    },
    quotationDemoDataCheckModalLongWidthFieldActivityVT: {
      // border: "0.1px solid #d3d3d3",
      display: "grid",
      // float: 'right',
      width: "auto",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "452px",
      },
    },
    quotationDemoDataCheckModalLongWidthFieldTextArea: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "right",
      width: "252px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "452px",
      },
    },
    quotationListingFormInputMainModal: {
      // minWidth: "131px",
      // maxWidth: "131px",
      width:"100%",
      border: "none",
      float: "inherit",
      padding: "4px 10px",
      fontFamily: " Times New Roman ",
      // "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      //   minWidth: "250px",
      //   maxWidth: "250px",
      // },
    },
    quotationListingFormInputMainModalForFreezone: {
      minWidth: "183px",
      maxWidth: "183px",
      border: "none",
      float: "inherit",
      padding: "4px 10px",
      fontFamily: " Times New Roman ",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        minWidth: "277px",
        maxWidth: "277px",
      },
    },
    quotationListingFormInputMainModalForFreezoneService: {
      minWidth: "154px",
      maxWidth: "154px",
      border: "none",
      float: "inherit",
      padding: "4px 10px",
      fontFamily: " Times New Roman ",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        minWidth: "235px",
        maxWidth: "235px",
      },
    },
    quotationListingFormInputMainModalActivity: {
      minWidth: "250px",
      maxWidth: "250px",
      border: "none",
      float: "inherit",
      padding: "4px 10px",
      fontFamily: " Times New Roman ",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        minWidth: "396px",
        maxWidth: "400px",
      },
    },
    quotationListingFormInputMainModalActivity1: {
      minWidth: "250px",
      maxWidth: "250px",
      border: "none",
      float: "inherit",
      padding: "4px 10px",
      fontFamily: " Times New Roman ",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        minWidth: "448px",
        maxWidth: "450px",
      },
    },
    quotationListingFormInputMainModalActivity2: {
      padding: "4px 10px",
      width:"95%"
    },
    quotationListingFormInputMainModalGovtFee: {
      // minWidth: "131.3px",
      // maxWidth: "132px",
      width:"100%",
      border: "none",
      float: "inherit",
      padding: "4.5px 10px",
      fontFamily: " Times New Roman ",
      // "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      //   minWidth: "198px",
      //   maxWidth: "198px",
      // },
    },
    quotationListingFormInputMainModalDate: {
      minWidth: "131px",
      maxWidth: "131px",
      border: "none",
      float: "inherit",
      padding: "2.5px 4px",
      fontFamily: " Times New Roman ",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        minWidth: "250px",
        maxWidth: "250px",
      },
    },
    quotationButtonDivModal: {
      textAlign: "center",
      marginBottom: "-10px",
      marginRight: "-16px",
    },
    quotationModalHeader: {
      color: "#fff",
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "5px",
      textTransform: "capitalize",
    },
    quotationModalHeader1: {
      color: "red",
      fontSize: "16px",
      // fontWeight: 'bold',
      marginLeft: "5px",
      textTransform: "capitalize",
    },
    quotationModalHeaderIcon: {
      color: "#eb6e12",
      fontSize: "16px !important",
      marginTop: "5px",
      cursor: "pointer",
    },
    quotationModalHeaderIconCancleIcon: {
      color: "#eb6e12",
      fontSize: "20px !important",
      marginTop: "5px",
      cursor: "pointer",
    },
    quotationModalHeaderIconCancle: {
      float: "right",
      marginRight: "-25px !important",
      marginTop: "-3px !important",
    },
    quotationModalHeaderIcon1: {
      color: "#fff",
      fontSize: "16px !important",
      marginTop: "5px",
    },
    quotationModalHeaderIcon11: {
      color: "#eb6e12",
      fontSize: "16px !important",
      marginTop: "26px",
    },
    RichTextEditerDiv: {
      marginTop: "5px",
    },
    quotationModalHeaderIconClose: {
      color: "#eb6e12",
      marginBottom: "4.5px",
    },
    quotationModalHeadingContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginTop: "30px ",
      borderTop: "1px solid #eb6e12",
      borderBottom: "1px solid #eb6e12",
      backgroundColor: "#eb6e12",
    },
    quotationModalHeadingContainer1: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px ",
      // borderTop: '1px solid #eb6e12',
      // borderBottom: '1px solid #eb6e12',
      // backgroundColor: '#eb6e12'
    },
    quotationCancleIconDiv: {
      marginLeft: "auto",
    },
    quotationModalFormTextArea: {
      minWidth: "131px",
      maxWidth: "131px",
      float: "inherit",
      border: "none",
      fontFamily: " Times New Roman ",
    },
    RichTextEditerDiv: {
      marginTop: "5px",
    },
  
    quotationSelectContainerGender: {
      fontSize: "12px",
      // float: 'right',
      display: "flex",
      marginTop: " 7px",
      // marginRight: '33%',
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        // marginLeft: '-5px',
      },
    },
    quotationRadioContainer: {
      display: "flex",
    },
    quotationGendarLabel: {
      marginTop: "-1px",
      padding: "0px 4px",
    },
    FCserviceHeading: {
      float: "right",
      marginRight: "10%",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#eb6e12",
      marginTop: "3px",
    },
    quotationCancleButtonModel: {
      marginTop: " -15px",
      marginRight: "-25px",
      position: "relative",
      left: "43%",
      "@media screen and (min-width: 1600px) and (max-width: 3000px)": {
        left: "45%",
      },
    },
    quotationModalFlexDivContaner: {
      display: "-webkit-box",
      flexFlow: "row-reverse wrap",
    },
    quotationBorderSpacingUpperModale: {
      width: "100%",
      borderTop: "1px solid #d3d3d3 ",
      marginBottom: "10px",
    },
    quotationBorderSpacingLowerListing: {
      width: "100%",
      borderBottom: "5px solid #eb6e12 ",
    },
    quotationGapSpan: {
      fontSize: "20px",
      marginLeft: "-3px",
      marginTop: "-3px",
      marginRight: "3px",
    },
    quotationInnerGridRight: {
      padding: "0px 10px",
      //borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
    },
    quotationInnerGridRightFirst: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "8px",
      paddingLeft: "9px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
    },
    quotationInnerGridLeft: {
      padding: "0px 10px",
      paddingTop: "3px",
    },
    quotationInnerGrid: {
      padding: "0px 10px",
    },
    quotationInnerGridContainer: {
      marginBottom: "10px",
      padding: "5px 0",
    },
    quotationAddRemoveBtnContainer: {
      marginLeft: "auto",
      marginRight: "-20px",
      marginTop: "-62px",
    },
  
    checkboxFlexContainer: {
      display: "flex",
      width: "100%",
      marginLeft: "-9px",
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        // marginLeft: '-14px',
      },
    },
    businessCardDemoDataCheckModalUploadFileBtn: {
      padding: '6px 20px',
      backgroundColor: '#eb6e12',
      border: '1px solid #d3d3d3',
      color: '#fff',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight:"5px"
  },
  businessCardDemoDataCheckModalUploadFileText: {
      maxWidth: '170px',
      minWidth: '55px',
      overflow: 'hidden',
      cursor: 'pointer',
      maxHeight: '26px',
      '@media screen and (min-width: 1600px) and (max-width: 3000px)': {
          maxWidth: '177px',
          minWidth: '177px',
      }
  },
  businessCardDemoDataCheckModalUploadLabel: {
      width: '50%',
      fontWeight: ' 600!important',
      marginTop: '6px',
      marginBottom: 0,
      fontSize: '13px',
      position: 'relative',
      top: '-1px',
  },
    quotationChangeAmountBtn: {
      background: " #eb6e12",
      color: " #fff",
      border: " none",
      padding: " 2px 10px",
      marginTop: " 4px",
      borderRadius: " 3px",
      height: "24px",
    },
    quotationChangeAmountBtnTop: {
      // width: '67%',
      width: "28%",
      height: "24px",
      background: " #eb6e12",
      color: " #fff",
      border: " none",
      padding: " 2px 10px",
      marginTop: " 2px",
      borderRadius: " 3px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "17.8%",
      },
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        width: "26%",
      },
    },
    quotationChangeInput: {
      height: " 24px",
      // marginTop: '-0.5px',
      width: "50%",
      marginRight: " 1px",
      marginLeft: " 4px",
      padding: " 12.5px",
      border: " 1px solid #d3d3d3",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "75%",
      },
    },
    quotationChangeInputOfcRent: {
      height: " 24px",
      // marginTop: '-0.5px',
      width: " 56px",
      marginRight: " 1px",
      marginLeft: " 10px",
      padding: " 12.5px",
      border: " 1px solid #d3d3d3",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "174px",
        marginLeft: "0px",
      },
    },
    requierdFieldStart: {
      color: "red",
    },
    requierdFieldMsg: {
      color: "red",
      fontSize:"12px",
    },
    chaneAmountInput: {
      // width: 'auto',
      justifyContent: "end",
      width: "38.5%",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "32.5%",
      },
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        width: "36.5%",
      },
    },
    check1: { paddingRight: "0", display: "flex", marginRight: "2px" },
    Check2: {
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        marginLeft: "-16px !important",
      },
    },
    quotationSelectContainerModalLicence: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      paddingRight: "169px",
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        paddingRight: "153px",
      },
    },
    quotationFormGroupModalJurisidiction: {
      paddingRight: "111px",
      "@media screen and (min-width: 1220px) and (max-width: 1300px)": {
        paddingRight: "104px",
      },
    },
    shareholderLabelFormInputModal: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "13px",
      position: "relative",
      top: "-1px",
    },
  });
  export default ModalPopperFormStyles;
  