import React, { useState, useContext, useEffect } from "react";
import { Box, Tab, Badge } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { withStyles } from "@material-ui/core/styles";
import TabStyles from "../styles/TabStyles";
import axios from "axios";
import moment from "moment";
import {FcAcceptDatabase, FcDeleteDatabase, FcCancel} from "react-icons/fc";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaAdn,
  FaStop,
  FaStopCircle,
  FaCheckDouble, FaReply
} from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Lead from "../../../../commonComp/assets/lead.png";
import { BsChatLeftTextFill } from "react-icons/bs";
import ArticleIcon from "@mui/icons-material/Article";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BlockIcon from '@mui/icons-material/Block';
import HistoryIcon from "@mui/icons-material/History";
import PanToolIcon from '@mui/icons-material/PanTool';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { DataContext, SupportDataContext } from "../../../../provider/context";
import { getLocalData } from "../../../../services/services";
import  listingtableStyles from "../styles/ListingtableStyles";
import "../styles/ListingTable.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MyAdminOrderListingTable from "./MyAdminOrderListing";
import OrderCCAlerts from "../Alerts/OrderCCAlerts";
import ProRequisitionListTable from "./ProRequisitionListTable";
import TypistRequisitionListTable from "./TypistRequisitionListTable";
import ComplaintListingTable from "../../Admin/Modals/Complaint/ComplaintListingTable";
import MainLandWorkFlowTable from "./MainLandWorkFlowTable";
import CompletedOrderListTable from "./CompletedOrderListTable";
import ReAssignOrderListTable from "./ReAssignOrderListTable";
import FreezoneWorkFlowTable from "./FreezoneWorkflowTable";
import OffshoreWorkFlowTable from "./OffshoreWorkflowTable";
import PROWorkflowTable from "./PROWorkflowTable";
import NOFListingTable from "./NOFListingTable";
import ViewPartnerListTable from "./ViewPartnerListTable";
import ViewSponsorTable from "./ViewSponsorTable";
import ViewShareListTable from "./ViewShareListTable";
import TypistTaskListTable from "./TypistTaskListTable";
import ProTaskListTable from "./ProTaskListTable";
import CompanyInfo from "./CompanyInfo";
import BulkReassignProjectListing from "./BulkReassignProjectListing";
import ProjectListTable from "./ProjectListTable";
import OldNOFListingTable from "./OldNOFListingTable";
import RequestedCompletionOrders from "./RequestedCompletionOrders";
import ViewCompanyDetails from "./ViewCompanyDetails";
import OrdersWithNoReqListing from "./OrdersWithNoReqListing";
import OrdersOverdueReqListing from "./OrdersOverdueReqListing";
import OrdersNoLiveReqListing from "./OrdersNoLiveReqListing";
import EscalationsListing from "./EscalationsListing";
import FeedbackDetailTable from "../Modals/FeedbackListing/FeedbackDetailTable";
import FreezedOrderNoFutureAlertListing from "./FreezedOrderNoFutureAlertListing";
import PartnerListTable from "./PartnerList";
import RejectedOrderListing from "./RejectedOrderListing";
import InformationPool from "./InformationPool";
import TargetListingTable from "./TargetListing";
import OperationEscalationTable from "./OperationEscalationTable";
import SupportOrderReportTable from "./SupportOrderReportTable";
import ScheduleTrainingTable from "../../Support/Listing/ScheduleTrainingTable";
import TrainingCourseTable from "../../Support/Listing/TrainingCourseTable";
import SupportOrderStatus from "./SupportOrderStatus";
import ReviewOnOrderReportTable from "./ReviewOnOrderReportTable";
import ClientReviewOnOrderReport from "./ClientReviewOnOrderReport";
import CancelledOrdersTable from "./CancelledOrdersTable";
import AddNewLeadViewTable from "./AddNewLeadViewTable";
import SubLeadListingTable from "./SubLeadListingTable";



const AdminListingTabBottom = (props) => {
  const [value, setValue] = useState("1");
  const {
    supportBadges,
    setStart_Date, setEnd_Date,
    cmPartnerData,
    iPapiData,
    filterData,
    InformationPoolListingApi
  } = useContext(DataContext)
  const { classes } = props;
  
  const{
  
    getAdminOrderListgDataApi,
      adminOrderListgData,
    rowData,
    getProRequestionListApi,
    proRequestionList,
    getTypistRequestionListApi,
    typistRequestionList,
    reqFromDate, reqToDate,
    getAdminCompalintListDataApi,adminCompalintListData,
    getMinlandWorkFollowDataFromApi,mainlandWorkFollowData,
    getAdminCompletedOrderListgDataApi,adminCompletedOrderListgData, 
    freezoneWorkFollowData, offshoreWorkFollowData,
    proServicesWorkFollowData,
    nofData,
    getViewAdminParnterDataAPI, viewAdminParnterData,
    getShareListApi, shareData,
    projectList,
    getProjectListDataApi,
    getOldNOFDataApi, oldNofData,
    adminRequestedCompletedOrderListgData,viewCompanyData, noOrdersReqListData,
    complaintListData,
    getFreezesOrderNoFutureAlertsListingDataApi, 
    freezesOrderNoFutureAlertLisitng,
    adminOrdersWithoutFutureAlertData,
    getAdminOrdersWithoutFutureAlertDataApi,
    adminRejectedOrdersData,
    getRejectedOrdersDataApi,
    targetAchievementData,
    trainingCourseData,
    scheduleTrainingData,
    orderEscalationData,
    reviewOnOrderData,
    cancelledOrderData,
    getAddNewLeadViewListingApi, 
    fetchSubLeadListDataFromApi,
    addNewLeadListing, 
    subLeadListData
   
   } = useContext(SupportDataContext);
   const emp_id = getLocalData("emp_id");
   const __token = getLocalData("token");
   const userTypeInfo = localStorage.getItem("userType");
   const factory_id = localStorage.getItem("factory_id");  
   const [amlPolicy, setAmlPolicy] = useState(null);

   const fullName = `${localStorage.getItem( "Employee_First_Name" )} ${localStorage.getItem("Employee_Last_Name")}`;
   const filteredSchedules = scheduleTrainingData?.Training_Schedule_List?.filter(schedule => {
     return schedule?.trainee_detail?.some(trainee => trainee?.Trainee_Name === fullName);
   });
   const completedNotifiedCourses = filteredSchedules?.filter(course => 
     course?.status === "Notified"
   );
   const approvedCourses = trainingCourseData?.filter(course => course?.status === "Approved");

   const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
      border: "1px solid #FF7518",
    },
  }));
  const ScrollTop = () => {
    window.scrollTo({
      top: -40,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {    
   // getProRequestionListApi(reqFromDate, reqToDate);
    //getTypistRequestionListApi(reqFromDate, reqToDate)
    //getAdminCompalintListDataApi();
    //getMinlandWorkFollowDataFromApi();
    // getTypistRequestionListApi();
   // getViewAdminParnterDataAPI();
   // getShareListApi();
  }, []);
 
  const fetchAmlPolicy = async () => {
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/amlpolicy/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setAmlPolicy(res.data.results?.AML_policy_list[0]);
    } catch (err) {
      
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue === "34") {
      fetchAmlPolicy();
    }
    setValue(newValue);
    setStart_Date();
    setEnd_Date();
  };
  const nofListData = nofData?.New_Order_Form_List_On_Company?.concat(nofData?.New_Order_Form_List_On_Inquiry) || null;
  const myInquryIconsArrUpper = [
    // {
    //   icon: <IoDocument style={{ margin: "0", fontSize: "17px" }} />,
    //   popper: "Initial Approval Order",
    //   btnText: "Initial Approval Order",
    // },
    // {
    //   icon: (
    //     <FaStopCircle className={classes.iconImg} style={{ margin: "0" }} />
    //   ),
    //   popper: "Freeze Order Approval",
    //   btnText: "Freeze Order Approval",
    // },
    {
      icon: <FaEye style={{ margin: "0" }} />,
      popper: "View Order Detail",
      btnText: "View Order Detail",
    },
    {
      icon: <FaSync className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Add/View Follow-up",
      btnText: "Add/View Follow-up",
    },
    {
      icon: <ReceiptLongIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Add/View Requisition",
      btnText: "Add/View Requisition",
    },
   
  ];
  const myInquryIconsArrLower = [
   
    // {
    //   icon: <FaCheckDouble style={{ margin: "0", fontSize: "17px" }} />,
    //   popper: "Completion Order Approval",
    //   btnText: "Completion Order Approval",
    // },
    {
      icon: <HowToRegIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Edit/View Complaint",
      btnText: "Edit/View Complaint",
    },
    {
      icon: <ArticleIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "View Order Log",
      btnText: "View Order Log",
    },
   
  ];
  const tableUpperButtonArr = [
    {
      buttonName: "Save Search",
    },
    {
      buttonName: `SSS`,
      icon: "",
    },
  ];
  return (
    <div className={classes.listingTableContainer}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              style={{display:'flex', flexWrap:'wrap'}}
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
              className={classes.tabListListing}
            >
            <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={adminOrderListgData?.length} showZero max={adminOrderListgData?.length}>
                     <LightTooltip title="My All Orders List" >
                      <span style={{marginRight: "28px"}}>Orders List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="1"
              />    
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Project_List_Count} showZero max={supportBadges?.Project_List_Count}>
                     <LightTooltip title="Project Creation" >
                      <span style={{marginRight: "28px"}}>Project List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="20"
              />
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={targetAchievementData?.length} showZero max={targetAchievementData?.length}>
                    <LightTooltip title="Employee Target/Achievement List" >
                      <span style={{marginRight: "20px"}}>Target/Achievement</span>
                    </LightTooltip>
                  </Badge>
                }

                value="35"
              />
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Cancellation_Requested_Order} showZero max={supportBadges?.Cancellation_Requested_Order}>
                     <LightTooltip title="Requested & Cancelled Orders List" >
                      <span style={{marginRight: "28px"}}>Requested & Cancelled Orders</span>
                    </LightTooltip>
                  </Badge>
                }
                value="44"
              /> 
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={nofListData?.length} showZero max={nofListData?.length}>
                     <LightTooltip title="NOF" >
                      <span style={{marginRight: "28px"}}>NOF</span>
                    </LightTooltip>
                  </Badge>
                }
                value="12"
              /> 
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" showZero>
                     <LightTooltip title="Re-assign Orders in Bulk" >
                      <span style={{marginRight: "8px"}}>Reassign Old Orders</span>
                    </LightTooltip>
                  </Badge>
                }
                value="8"
              />
               {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={cmPartnerData?.length} showZero max={cmPartnerData?.length}>
                    <LightTooltip title="Partner List" >
                      <span style={{marginRight: "20px"}}>Partner List</span>
                    </LightTooltip>
                  </Badge>
                }

                value="31"
              />      */}
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={rowData?.length} showZero max={rowData?.length}>
                     <LightTooltip title="CC Orders Alerts List" >
                      <span style={{marginRight: "28px"}}>CC Alerts</span>
                    </LightTooltip>
                  </Badge>
                }
                value="3"
              />     
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={proRequestionList?.length} showZero max={proRequestionList?.length}>
                     <LightTooltip title="View PRO'S Requisition List" >
                      <span style={{marginRight: "28px"}}> PRO'S Requisition List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="4"
              />          
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={typistRequestionList?.length} showZero max={typistRequestionList?.length}>
                     <LightTooltip title="View Typist Requisition List" >
                      <span style={{marginRight: "28px"}}>Typist Requisition List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="5"
              />   
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} 
                  badgeContent={viewAdminParnterData?.results?.Individual_Corporate_Compliance_List?.length} 
                  showZero max={viewAdminParnterData?.results?.Individual_Corporate_Compliance_List?.length}>
                     <LightTooltip title="View Partner Detail List" >
                      <span style={{marginRight: "28px"}}>View Partner</span>
                    </LightTooltip>
                  </Badge>
                }
                value="13"
              /> 
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={shareData?.length} showZero max={shareData?.length}>
                     <LightTooltip title="View Share Detail List" >
                      <span style={{marginRight: "28px"}}>View Share</span>
                    </LightTooltip>
                  </Badge>
                }
                value="14"
              />      
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}}>
                     <LightTooltip title="View Sponsor Detail List" >
                      <span style={{marginRight: "28px"}}>View Sponsor</span>
                    </LightTooltip>
                  </Badge>
                }
                value="15"
              />    
               
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={adminCompalintListData?.length} showZero max={adminCompalintListData?.length}>
                     <LightTooltip title="Compliant List Added by Admin to RO" >
                      <span style={{marginRight: "28px"}}>Complaint List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="6"
              />    
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Complaint_Count} showZero max={supportBadges?.Complaint_Count}>
                     <LightTooltip title="Sales / ECS Escalations to Operation List" >
                      <span style={{marginRight: "28px"}}>Sales/ECS Escalations</span>
                    </LightTooltip>
                  </Badge>
                }
                value="27"
              />    
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.internal_esca_count_created} showZero max={supportBadges?.internal_esca_count_created}>
                     <LightTooltip title="Internal Operations Escalations List" >
                      <span style={{marginRight: "28px"}}>Internal Operations Escalations</span>
                    </LightTooltip>
                  </Badge>
                }
                value="37"
              />       
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.View_Lead} showZero max={supportBadges?.View_Lead}>
                    <LightTooltip title="View New Lead of RO" >
                      <span style={{marginRight: "20px"}}>View New Lead</span>
                    </LightTooltip>
                  </Badge>
                }

                value="45"
              />
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Sub_Lead_Count} showZero max={supportBadges?.Sub_Lead_Count}>
                    <LightTooltip title="View Sub Lead of RO" >
                      <span style={{marginRight: "20px"}}>Sub Lead </span>
                    </LightTooltip>
                  </Badge>
                }
                value="46"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.nof_feedbacK_count_created} showZero max={supportBadges?.nof_feedbacK_count_created}>
                     <LightTooltip title="NOF Feedback List" >
                      <span style={{marginRight: "28px"}}>NOF Feedback</span>
                    </LightTooltip>
                  </Badge>
                }
                value="28"
              /> 
               {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={mainlandWorkFollowData?.length} showZero max={mainlandWorkFollowData?.length}>
                     <LightTooltip title="Mainland Workflow List" >
                      <span>Mainland Workflow &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="7"
              />  
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={freezoneWorkFollowData?.length} showZero max={freezoneWorkFollowData?.length}>
                     <LightTooltip title="Freezone Workflow List" >
                      <span>Freezone Workflow &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="9"
              />   
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={offshoreWorkFollowData?.length} showZero max={offshoreWorkFollowData?.length}>
                     <LightTooltip title="Offshore Workflow List" >
                      <span>Offshore Workflow &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="10"
              />   
                <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={proServicesWorkFollowData?.length} showZero max={proServicesWorkFollowData?.length}>
                     <LightTooltip title="Pro Services Workflow List" >
                      <span>PRO Services Workflow &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="11"
              />   */}
               
                <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} >
                    <LightTooltip title="Old NOF Orders List" >
                      <span style={{marginRight: "28px"}}> Old NOF</span>
                    </LightTooltip>
                  </Badge>
                }
                value="21"
              />
                 <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}}>
                     <LightTooltip title="PRO Task List" >
                      <span style={{marginRight: "28px"}}>PRO Task List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="16"
              />  
                 <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}}>
                     <LightTooltip title="Typist Task List" >
                      <span style={{marginRight: "28px"}}>Typist Task List</span>
                    </LightTooltip>
                  </Badge>
                }
                value="17"
              />   
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}}>
                     <LightTooltip title="Company Information" >
                      <span style={{marginRight: "28px"}}>Comp. Info</span>
                    </LightTooltip>
                  </Badge>
                }
                value="18"
              />    
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" > 
                     <LightTooltip title="Bulk Reassign Project" >
                      <span style={{marginRight: "8px"}}>Bulk Reassign Project</span>
                    </LightTooltip>
                  </Badge>
                }
                value="19"
              />     
                {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={adminCompletedOrderListgData?.length} showZero max={adminCompletedOrderListgData?.length}>
                     <LightTooltip title="All Completed Orders List" >
                      <span style={{marginRight: "28px"}}>Completed Orders</span>
                    </LightTooltip>
                  </Badge>
                }
                value="2"
              />    
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={adminRequestedCompletedOrderListgData?.length} showZero max={adminRequestedCompletedOrderListgData?.length}>
                     <LightTooltip title="Requested Completion Orders List" >
                      <span style={{marginRight: "28px"}}>Requested Completion Orders</span>
                    </LightTooltip>
                  </Badge>
                }
                value="22"
              />    */}
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Partner_Count} showZero max={supportBadges?.Partner_Count} >
                    <LightTooltip title="View Company Details " >
                      <span  style={{marginRight: "20px"}}>View Company</span>
                    </LightTooltip>
                  </Badge>
                }
                value="23"
              /> 
              {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Order_With_No_Requisition_Count} showZero max={supportBadges?.Order_With_No_Requisition_Count}>
                    <LightTooltip title="Orders without Requisition" >
                      <span  style={{marginRight: "20px"}}>Orders without Requisition</span>
                    </LightTooltip>
                  </Badge>
                }
                value="24"
              />

              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Order_With_Overdue_Requisition_Count}  showZero max={supportBadges?.Order_With_Overdue_Requisition_Count}>
                    <LightTooltip title="Orders Overdue Requisition" >
                      <span  style={{marginRight: "20px"}}>Orders Overdue Requisition</span>
                    </LightTooltip>
                  </Badge>
                }
                value="25"
              /> */}

              {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Order_With_No_Live_Requisition_Count}  showZero max={supportBadges?.Order_With_No_Live_Requisition_Count}>
                    <LightTooltip title="Orders no live Requisition" >
                      <span  style={{marginRight: "20px"}}>Orders no live Requisition</span>
                    </LightTooltip>
                  </Badge>
                }
                value="26"
              /> */}
                {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Freeze_Order_With_No_Future_Alert} showZero max={supportBadges?.Freeze_Order_With_No_Future_Alert}>
                    <LightTooltip title="Freezed Order With No Future Alert" >
                      <span style={{marginRight: "20px"}}>Freezed Order With No Future Alert</span>
                    </LightTooltip>
                  </Badge>
                }
                value="29"
              /> */}
                {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}} badgeContent={supportBadges?.Freeze_Order_With_No_Future_Alert} showZero max={supportBadges?.Freeze_Order_With_No_Future_Alert}>
                    <LightTooltip title="Orders without Future Alerts" >
                      <span style={{marginRight: "20px"}}>Orders without Future Alerts</span>
                    </LightTooltip>
                  </Badge>
                }

                value="30"
              /> */}

              {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}}>
                    <LightTooltip title="Rejected Orders" >
                      <span style={{marginRight: "20px"}}>Rejected Orders</span>
                    </LightTooltip>
                  </Badge>
                }

                value="32"
              /> */}
               {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}}>
                    <LightTooltip title="All Orders Report List" >
                      <span style={{marginRight: "20px"}}>All Orders Report</span>
                    </LightTooltip>
                  </Badge>
                }
                value="38"
              />
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}}>
                    <LightTooltip title="Review on Orders Report List" >
                      <span style={{marginRight: "20px"}}>Review on Orders Report</span>
                    </LightTooltip>
                  </Badge>
                }
                value="42"
              /> */}
               {/* <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}}>
                    <LightTooltip title="Support Order Status List" >
                      <span style={{marginRight: "20px"}}>Support Order Status</span>
                    </LightTooltip>
                  </Badge>
                }
                value="41"
              /> */}
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{ marginRight: "5px" }} badgeContent={supportBadges?.Training_Course_Count} showZero max={supportBadges?.Training_Course_Count}>
                    <LightTooltip title="Training Course List" >
                      <span style={{ marginRight: "20px" }}>Training Course</span>
                    </LightTooltip>
                  </Badge>
                }
                value="39"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{ marginRight: "5px" }} badgeContent={supportBadges?.Scheduled_Training_Count} showZero max={supportBadges?.Scheduled_Training_Count}>
                    <LightTooltip title="Schedule Training Course List" >
                      <span style={{ marginRight: "20px" }}>Schedule Training </span>
                    </LightTooltip>
                  </Badge>
                }
                value="40"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "5px"}}>
                    <LightTooltip title="Info. Pool" >
                      <span style={{marginRight: "20px"}}>Info. Pool</span>
                    </LightTooltip>
                  </Badge>
                }

                value="33"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={0}>
                    AML Policy
                  </Badge>
                }
                value="34"
              />
               <FormControl sx={{ minWidth: 140, borderColor: '#eb6e12', backgroundColor: "white" }}>
              {/* <Badge color="warning" showZero 
              badgeContent={
                value === "22" ? supportBadges?.Requested_Completed_Order 
                : value === "2" ? adminCompletedOrderListgData?.length
                : value === "24" ? supportBadges?.Order_With_No_Requisition_Count
                : value === "25" ? supportBadges?.Order_With_Overdue_Requisition_Count
                : value === "26" ? supportBadges?.Order_With_No_Live_Requisition_Count
                // : value === "29" ? supportBadges?.Freeze_Order_With_No_Future_Alert
                // : value === "30" ? supportBadges?.Freeze_Order_With_No_Future_Alert
                // : value === "32" ? supportBadges?.Order_With_Overdue_Requisition_Count

                 : 0
              } 
              max={99999}> 
              </Badge> */}
              { value==='2' || value==='22' || value==='24' || value==='25' || value==='26' || value==='29' || value==='30' || value==='32' ?
                  <InputLabel id="demo-simple-select-standard-label" 
                sx={{ color: '#505050', fontSize: `17px`, fontFamily: "Times New Roman", fontWeight: "bold" }}>
                  Orders
                  </InputLabel>
                  :
                  <InputLabel id="demo-simple-select-standard-label" 
                  sx={{ color: '#505050', fontSize: `11px`, fontFamily: "Times New Roman" }}>
                    ORDERS
                    </InputLabel>
                  }
                <Select
                style={{fontSize:"14px", borderColor: "#eb6e12", fontFamily: "Times New Roman"}}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                value={value==='2' || value==='22' || value==='24' || value==='25' || value==='26' || value==='29' || value==='30' || value==='32' ? value : ""}
                  onChange={(val) => {
                    setValue(val.target.value);
                   }}
                  label={"ORDERS"}
                >
                  <MenuItem value="1">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"2"} name="Completed Orders" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={adminCompletedOrderListgData?.length } showZero max={adminCompletedOrderListgData?.length }>
                      <span style={{marginRight: "8px"}}>Completed Orders</span>
                  </Badge>
                  </MenuItem>
                  <MenuItem value={"22"} name="Requested Completion Orders" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Requested_Completed_Order } showZero max={supportBadges?.Requested_Completed_Order }>
                      <span style={{marginRight: "8px"}}>Requested Completion Orders</span>
                  </Badge>
                  </MenuItem>
                  <MenuItem value={"24"} name="Orders Without Requisition" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Order_With_No_Requisition_Count } showZero max={supportBadges?.Order_With_No_Requisition_Count }>
                      <span style={{marginRight: "8px"}}>Orders Without Requisition</span>
                  </Badge>
                  </MenuItem>
                  <MenuItem value={"25"} name="All Orders Report" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Order_With_Overdue_Requisition_Count } showZero max={supportBadges?.Order_With_Overdue_Requisition_Count }>
                      <span style={{marginRight: "8px"}}>Orders Overdue Requisition</span>
                  </Badge>
                  </MenuItem>
                  <MenuItem value={"26"} name="Orders no live Requisition" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={supportBadges?.Order_With_No_Live_Requisition_Count } showZero max={supportBadges?.Order_With_No_Live_Requisition_Count }>
                      <span style={{marginRight: "8px"}}>Orders no Live Requisition</span>
                  </Badge>
                  </MenuItem>
                  <MenuItem value={"29"} name="Freezed Order With No Future Alert" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>Freezed Order With No Future Alert</MenuItem>
                  <MenuItem value={"30"} name="Orders without Future Alerts" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>Orders Without Future Alerts</MenuItem>
                  <MenuItem value={"32"} name="Rejected Orders" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>Rejected Orders</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 140, borderColor: '#eb6e12', backgroundColor: "white" }}>
              {/* <Badge color="warning" showZero badgeContent={value === "28" ? clientPortfolioData?.length : value === "12" ? empVisaExpData?.length : 0} 
              max={clientPortfolioData?.length}> 
              </Badge> */}
              { value==='38' || value==='41' || value==='42' || value==='43' ?
                  <InputLabel id="demo-simple-select-standard-label" 
                sx={{ color: '#505050', fontSize: `17px`, fontFamily: "Times New Roman", fontWeight: "bold" }}>
                  Reports
                  </InputLabel>
                  :
                  <InputLabel id="demo-simple-select-standard-label" 
                  sx={{ color: '#505050', fontSize: `11px`, fontFamily: "Times New Roman" }}>
                    REPORTS
                    </InputLabel>
                  }
                <Select
                style={{fontSize:"14px", borderColor: "#eb6e12", fontFamily: "Times New Roman"}}
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                value={value==='38' || value==='41' || value==='42' || value==='43' ? value : ""}
                  onChange={(val) => {
                    setValue(val.target.value);
                   }}
                  label={"REPORTS"}
                >
                  <MenuItem value="1">
                    <em>Select</em>
                  </MenuItem>
                  
                  <MenuItem value={"38"} name="All Orders Report" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>All Orders</MenuItem>
                  <MenuItem value={"41"} name="Support Order Status Report" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>Support Order Status</MenuItem>
                  <MenuItem value={"42"} name="Review on Orders Report" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>Review on Orders By Admin</MenuItem>
                  <MenuItem value={"43"} name="Client Review on Orders Report" style={{ color: '#808080', fontSize: "14px", fontFamily: "Times New Roman" }}>Client Review on Orders By RO</MenuItem>

                </Select>
              </FormControl>
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <div className={classes.TabLowerContainerDiv}>
        {
        value === "1" ? (
         <MyAdminOrderListingTable
         upperIcons={myInquryIconsArrUpper}
         lowerIcons={myInquryIconsArrLower}
         apiData={adminOrderListgData}
         tableUpperButtonArr={tableUpperButtonArr}
         tabValue={value}
         fetchListingDataFromApi={getAdminOrderListgDataApi}
         ScrollTop={ScrollTop}
         fitlerBadge={true}
        />
       ) :
       value === "2" ? (
         <CompletedOrderListTable
         upperIcons={myInquryIconsArrUpper}
         lowerIcons={myInquryIconsArrLower}
         apiData={adminCompletedOrderListgData}
         tableUpperButtonArr={tableUpperButtonArr}
         tabValue={value}
         //fetchListingDataFromApi={fetchListingDataFromApi}
         ScrollTop={ScrollTop}
         fitlerBadge={true}
         />
        ) :
        value === "3" ? (
          <OrderCCAlerts
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={rowData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={fetchListingDataFromApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : 
         value === "4" ? (
          <ProRequisitionListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={proRequestionList}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getProRequestionListApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) :  
         value === "5" ? (
          <TypistRequisitionListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={typistRequestionList}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getTypistRequestionListApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) :
         value === "6" ? (
          <ComplaintListingTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={typistRequestionList}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         )  :
         value === "7" ? (
          <MainLandWorkFlowTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={mainlandWorkFollowData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) :
         value === "8" ? (
          <ReAssignOrderListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={adminOrderListgData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) :  value === "9" ? (
          <FreezoneWorkFlowTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={freezoneWorkFollowData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         )  :  value === "10" ? (
          <OffshoreWorkFlowTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={offshoreWorkFollowData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         )  :  value === "11" ? (
          <PROWorkflowTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={proServicesWorkFollowData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) :  value === "12" ? (
          <NOFListingTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={nofListData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          //fetchListingDataFromApi={getAdminCompalintListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : value === "13" ? (
          <ViewPartnerListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={viewAdminParnterData?.results?.Individual_Corporate_Compliance_List}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          fetchListingDataFromApi={getViewAdminParnterDataAPI}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : value === "14" ? (
          <ViewShareListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={shareData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          fetchListingDataFromApi={getShareListApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         )
          : value === "15" ? (
          <ViewSponsorTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={viewAdminParnterData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
         // fetchListingDataFromApi={getViewAdminParnterDataAPI}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         )   : value === "16" ? (
          <ProTaskListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
         // fetchListingDataFromApi={getViewAdminParnterDataAPI}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         )   : value === "17" ? (
          <TypistTaskListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
         // fetchListingDataFromApi={getViewAdminParnterDataAPI}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : value === "18" ? (
          <CompanyInfo
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={viewAdminParnterData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
         // fetchListingDataFromApi={getViewAdminParnterDataAPI}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : value === "19" ? (
          <BulkReassignProjectListing
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
         // fetchListingDataFromApi={getViewAdminParnterDataAPI}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : value === "20" ? (
          <ProjectListTable
          upperIcons={myInquryIconsArrUpper}
          lowerIcon={myInquryIconsArrLower}
          apiData={projectList}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          fetchListingDataFromApi={getProjectListDataApi}
          ScrollTop={ScrollTop}
          fitlerBadge={true}
          />
         ) : value === "21" ? (
          <OldNOFListingTable
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={oldNofData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            // fetchListingDataFromApi={getOldNOFDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
         ) : value === "22" ? (
          <RequestedCompletionOrders
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={adminRequestedCompletedOrderListgData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            // fetchListingDataFromApi={getOldNOFDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "23" ? (
          <ViewCompanyDetails
            upperIcons={myInquryIconsArrUpper}
            lowerIcon={myInquryIconsArrLower}
            apiData={viewCompanyData?.Company_List}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "24" ? (
          <OrdersWithNoReqListing
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={noOrdersReqListData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          /> 
        ): value === "25" ? (
          <OrdersOverdueReqListing
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={noOrdersReqListData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          /> 
        ) : value === "26" ? (
          <OrdersNoLiveReqListing
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={noOrdersReqListData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          /> 
        ) 
        : value === "27" ? (
          <EscalationsListing
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={complaintListData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          /> 
        ) : value === "28" ? (
          <FeedbackDetailTable
            upperIcons={myInquryIconsArrUpper}
            lowerIcons={myInquryIconsArrLower}
            apiData={complaintListData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          /> 
        ): value === "29" ? (
          <FreezedOrderNoFutureAlertListing
            apiData={freezesOrderNoFutureAlertLisitng}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            getFreezesOrderNoFutureAlertsListingDataApi={getFreezesOrderNoFutureAlertsListingDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "30" ? (
          <FreezedOrderNoFutureAlertListing
            apiData={adminOrdersWithoutFutureAlertData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            getFreezesOrderNoFutureAlertsListingDataApi={getAdminOrdersWithoutFutureAlertDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "31" ? (
          <PartnerListTable
            apiData={cmPartnerData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        )  : value === "32" ? (
          <RejectedOrderListing
            apiData={adminRejectedOrdersData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            getRejectedOrdersDataApi={getRejectedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "33" ? (
          <InformationPool
          rowData={filterData}
          iPapiData={iPapiData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          InformationPoolListingApi={InformationPoolListingApi}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          />
        ) : value === "34" ? (
          <div className="aml-policy-container">
            <p className="aml-policy-header">AML Policy</p>
            <div
              dangerouslySetInnerHTML={{ __html: amlPolicy?.Policy_Details }}
            />
          </div>
        ) : value === "35" ? (
          <TargetListingTable
          rowData={targetAchievementData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          // InformationPoolListingApi={InformationPoolListingApi}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
        ) : value === "37" ? (
          <OperationEscalationTable
          rowData={orderEscalationData?.Escalation_List}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
        ) : value === "38" ? (
          <SupportOrderReportTable
          rowData={orderEscalationData?.Escalation_List}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
        ) : value === "39" ? (
          <TrainingCourseTable
            rowData={trainingCourseData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            ScrollTop={ScrollTop}
            heightTable="100%"
            height="500px"
            fitlerBadge={true}
          />
        ) :
          value === "40" ? (
            <ScheduleTrainingTable
              rowData={scheduleTrainingData?.Training_Schedule_List}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              ScrollTop={ScrollTop}
              heightTable="100%"
              height="500px"
              fitlerBadge={true}
            />
          ) :
          value === "41" ? (
            <SupportOrderStatus
              rowData={scheduleTrainingData?.Training_Schedule_List}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              ScrollTop={ScrollTop}
              heightTable="100%"
              height="500px"
              fitlerBadge={true}
            />
          ) :
          value === "42" ? (
            <ReviewOnOrderReportTable
              rowData={reviewOnOrderData?.Review_List}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              ScrollTop={ScrollTop}
              heightTable="100%"
              height="500px"
              fitlerBadge={true}
            />
          ) : value === "43" ? (
            <ClientReviewOnOrderReport
              rowData={reviewOnOrderData?.Review_List}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              ScrollTop={ScrollTop}
              heightTable="100%"
              height="500px"
              fitlerBadge={true}
            />
          ) : value === "44" ? (
            <CancelledOrdersTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcons={myInquryIconsArrLower}
              apiData={cancelledOrderData?.Support_Order_List}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              ScrollTop={ScrollTop}
              heightTable="100%"
              height="500px"
              fitlerBadge={true}
            />
          ) : value === "45" ? (
            <AddNewLeadViewTable
              apiData={addNewLeadListing}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              getAddNewLeadViewListingApi={getAddNewLeadViewListingApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
          ) : value === "46" ? (
            <SubLeadListingTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcon={myInquryIconsArrLower}
              apiData={subLeadListData}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              fetchSubLeadListDataFromApi={fetchSubLeadListDataFromApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
          ) 
         :
       null
        }
        
      </div>
    </div>
  );
};

export default withStyles(listingtableStyles)(AdminListingTabBottom);
