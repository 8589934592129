import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../Styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from 'react-bootstrap/Alert';
import {
  FaStopCircle,

} from "react-icons/fa";
import { Button } from "react-bootstrap";




const CourseDocsTable = ({ classes }) => {
  const {
    message,
    setButtonText,
    setOperationInfo,
    handleModalOpen,
    operationInfo,
    getCompleteOrderListApi,
    completeOrderList,
    getOrderLogDataApi,
    orderLogData,
    getTrainingCourseData, trainingCourseData

  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  useEffect(() => {
    getTrainingCourseData();
  }, []);

  console.log("operationInfo", operationInfo);

  const documentItems = (params) => {
    let data = params?.data?.document;

    return data ? (
      <>
        <Button
          style={{
            marginLeft: ".4rem",
            textDecorationColor: "#eb6e12",
            background: "none",
            cursor: "pointer",
            border: "none",
            padding: "6px",
          }}
          onClick={() => window.open(data)}
        >
          View
        </Button>

      </>
    ) : null;
  };

  const [columnDefs] = useState([
    {
      headerName: "Sr.",
      field: "Serial_Number",
      width: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Course Title",
      field: `${operationInfo?.course_title}`,
      filter: "agTextColumnFilter",
      width: 220,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      cellRenderer: (params) => {
        return (
          <>
            {operationInfo?.course_title}
          </>
        )
      },
    },
    {
      headerName: "Document Id",
      field: "training_course_document_id",
      filter: "agTextColumnFilter",
      maxWidth: 160,
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
    },
    {
      headerName: "Document Type",
      field: "document_type",
      filter: "agTextColumnFilter",
      width: 230,
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
    },
    {
      headerName: "View Document",
      field: "action",
      filter: false,
      width: 200,
      cellStyle: { border: "1px solid #fff", paddingTop: 0 },
      cellRenderer: documentItems,
    },
    {
      headerName: "Uploaded By",
      field: `${operationInfo?.created_by}`,
      filter: "agTextColumnFilter",
      width: 220,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      cellRenderer: (params) => {
        return (
          <>
            {operationInfo?.created_by}
          </>
        )
      },
    },
    {
      headerName: "Uploaded Date",
      field: "Created_Date",
      filter: true,
      minWidth: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      cellRenderer: (params) => {
        return (
          <>
            {operationInfo?.created_date === "" || operationInfo?.created_date === null ? "" :
              moment(operationInfo?.created_date).format("DD-MM-YYYY hh:mm:ss")}
          </>
        )
      },
    },


  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 200,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
      flex: 1,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>

      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >


          {/* <Alert show={show} variant="success" sx={{ width: "100%" }} onClose={() => setShow(false)} dismissible>
                 {message} 
               </Alert> */}

          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={operationInfo?.Document}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default withStyles(modalTableStyles)(CourseDocsTable);
