import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import modalPopperStyles from "../styles/ModalPopperStyles";
import { Modal } from "@mui/material";
import Error from "../../../listing/listingTable/ListingExpandableTable/modal/Error";
import LoaderClock from "../../../lodar/LoaderClock";
import { SupportDataContext } from "../../../../provider/context";
import ApprovedOrderModelForm from "./ApproveOrder/ApproveOrderModelForm";
import ApprovedFreezeOrderModelForm from "./ApproveOrder/ApproveFreezeOrderModelForm";
import ComplaintModalForm from "./Complaint/ComplaintModalForm";
import ConfirmationDialogue from "./Complaint/ConfirmationDialogue";
import MainlandForm from "./Mainland/MainlandForm";
import ViewMainlandForm from "./Mainland/ViewMainlandForm";
import FreezoneForm from "./Freezone/FreezoneForm";
import ViewFreezoneForm from "./Freezone/ViewFreezoneForm";
import OffshoreForm from "./Offshore/OffshoreForm";
import ViewOffshoreForm from "./Offshore/ViewOffshoreForm";
import PROServicesForm from "./PROServices/PROServicesForm";
import ViewPROServicesForm from "./PROServices/ViewPROServicesForm";
import AddAdminCommentForm from "../Alerts/AddAdminCommentForm"
import AddOperationOrderApproval from "../Alerts/AddOperationOrderApproval";
import AddNOFOrderForm from "../Alerts/AddNOFOrderForm";
import FreezeOrderApprovalModelForm from "./Completion_FreezeCompletedOrders/FreezeOrderApprovalModelForm";
import CompletionOrderApprovalModelForm from "./Completion_FreezeCompletedOrders/CompletionOrderApprovalModelForm";
import AddFamilyForm from "./ViewCompanyFamily/AddFamilyForm";
import AddWorkerForm from "./ViewCompanyWorker/AddWorkerForm";
import EditCompanyInfoModalForm from "./EditCompanyInfoModalForm";
import ViewOrderFormForCompany from "./viewOrderFormForCompany";
import ViewOrderForm from "./viewOrderForm";
import ViewProjectDescription from "./ProjectList/ViewProjectDescription";
import ViewOrderOnProject from "./ProjectList/ViewOrderOnProject";
import ChangesEscalationStatusForm from "./Complaint/ChangesEscalationStatusForm";
import AddReplyCommentForm from "./AddViewComment/AddReplyCommentForm";
import ChangesFeedbackStatusForm from "./FeedbackListing/ChangesFeedbackStatusForm";
import FollowUpModalForm from "./Followup/FollowUpModalForm";
import TargetForm from "./TargetAchievement/TargetForm";
import AchievementForm from "./TargetAchievement/AchievementForm";
import ViewTargetAchievement from "./TargetAchievement/ViewTargetAchievement";
import OperationEscalationStatusForm from "./OperationEscalation/OperationEscalationStatusForm";
import ReviewOnOrderForm from "./ReviewOnOrder/ReviewOnOrderForm";
import PCOTargetForm from "./TargetAchievement/PCOTargetForm";
import PCOAchievementForm from "./TargetAchievement/PCOAchievementForm";
import ViewPcoTargetAchievement from "./TargetAchievement/ViewPcoTargetAchievement";
import AdminTargetForm from "./TargetAchievement/AdminTargetForm";
import AdminAchievementForm from "./TargetAchievement/AdminAchievementForm";
import ViewAdminTargetAchievement from "./TargetAchievement/ViewAdminTargetAchievement";
import ApprovalOrderCancellationForm from "./ApprovalOrderCancellation/ApprovalOrderCancellationForm";


const ModalPopper = () => {
  const { modalOpen, buttonText, error } = useContext(SupportDataContext);

  return (
    <>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {buttonText === "Completion Order Approval" ? (
          <ApprovedOrderModelForm />
        ) : buttonText === "Freeze Order Approval" ? (
          <ApprovedFreezeOrderModelForm />
        ) : buttonText === "Add/Edit Complaint" ? (
          <ComplaintModalForm />
        ) : buttonText === "Edit/View Complaint" ? (
          <ComplaintModalForm />
        ) : buttonText === "Resolve Complaint" ? (
          <ConfirmationDialogue />
        ) : buttonText === "Add/Edit Mainland" ? (
          <MainlandForm />
        ) : buttonText === "View Mainland Form" ? (
          <ViewMainlandForm />
        ) : buttonText === "Add/Edit Freezone" ? (
          <FreezoneForm />
        ) : buttonText === "View Freezone Form" ? (
          <ViewFreezoneForm />
        ) : buttonText === "Add/Edit Offshore" ? (
          <OffshoreForm />
        ) : buttonText === "View Offshore Form" ? (
          <ViewOffshoreForm />
        ) : buttonText === "Add/Edit PRO Services" ? (
          <PROServicesForm />
        ) : buttonText === "View PRO Services Form" ? (
          <ViewPROServicesForm />
        ) : buttonText === "Add Comment" ? (
          <AddAdminCommentForm />
         ) : buttonText === "Add Operation Order Approval" ? (
          <AddOperationOrderApproval />
         ) : buttonText === "Add NOF Order Form" ? (
          <AddNOFOrderForm />
         ) : buttonText === "Freeze Completed_Order Approval" ? (
          <FreezeOrderApprovalModelForm />
         ) : buttonText === "Completion Completed_Order Approval" ? (
          <CompletionOrderApprovalModelForm />
         ) : buttonText === "View / Add Family" ? (
          <AddFamilyForm />
         ) : buttonText === "Add Worker" ? (
          <AddWorkerForm />
         ) : buttonText === "Edit Company Info" ? (
          <EditCompanyInfoModalForm />
         ) : buttonText === "View NOF Company" ? (
          <ViewOrderFormForCompany />
         )
         : buttonText === "View NOF Inquiry" ? (
          <ViewOrderForm />
         ) 
         : buttonText === "View Project Description" ? (
          <ViewProjectDescription />
        ) : buttonText === "View Order On Project" ? (
          <ViewOrderOnProject/>
         ) : buttonText === "Change Escalation/Complaint Status" ? (
          <ChangesEscalationStatusForm/> 
         ) : buttonText === "Add Comments" ? (
          <AddReplyCommentForm/>
         ) : buttonText === "Change Feedback Status" ? (
          <ChangesFeedbackStatusForm/>
         ): buttonText === "Add / View Follow-up" ? (
          <FollowUpModalForm />
        ) : buttonText === "Add Target" ? (
          <TargetForm />
        ) : buttonText === "Add Achievement" ? (
          <AchievementForm />
        ) : buttonText === "View Achievement" ? (
          <ViewTargetAchievement />
        ) : buttonText === "Add PCO Target" ? (
          <PCOTargetForm />
        ) : buttonText === "Add PCO Achievement" ? (
          <PCOAchievementForm />
        ) : buttonText === "View PCO Achievement" ? (
          <ViewPcoTargetAchievement />
        ) : buttonText === "Add Admin Assistant Target" ? (
          <AdminTargetForm />
        ) : buttonText === "Add Admin Assistant Achievement" ? (
          <AdminAchievementForm />
        ) : buttonText === "View Admin Assistant Achievement" ? (
          <ViewAdminTargetAchievement />
        )
         : buttonText === "Change Order Escalation Status" ? (
          <OperationEscalationStatusForm />
        ) : buttonText === "Review On Order" ? (
          <ReviewOnOrderForm />
        ) : buttonText === "Approved / Reject Order Cacnellation" ? (
          <ApprovalOrderCancellationForm />
        )
         : buttonText === "error" ? (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        ) : (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        )}
      </Modal>
    </>
  );
};

export default withStyles(modalPopperStyles)(ModalPopper);
