import React, { useContext, useState} from "react";
import "./customDatePicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangeIcon from '@mui/icons-material/DateRange';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DataContext } from "../../provider/context";
import CloseIcon from '@mui/icons-material/Close';
import format from 'date-fns/format'


const DateRangePickerComp = (props) => {
    const { 
        start_Date, setStart_Date, end_Date, setEnd_Date
     } = useContext(DataContext);
        const [open, setOpen] = useState(false)
        const range = {
            "Today": [moment(), moment()],
            "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
                moment()
                    .subtract(1, "month")
                    .startOf("month"),
                moment()
                    .subtract(1, "month")
                    .endOf("month")
            ],
            "Last Year": [
                moment()
                    .subtract(1, "year")
                    .startOf("year"),
                moment()
                    .subtract(1, "year")
                    .endOf("year")
            ]
        };
  const handleEventAddApply = (event, picker) => {
    // alert("123")
    setStart_Date(moment(picker.startDate).format().slice(0, 10));
    setEnd_Date(moment(picker.endDate).format().slice(0, 10));
    if(props.custom) {
        props.fieldHandleChange(event, picker)
    }
    setOpen(true)
};
  const handleDeSelectDate = () => {
    setStart_Date();
    setEnd_Date()
    // props?.fieldHandleChange(null)
}
  return (
    <div className="datePicker">
        <DateRangePicker
            ranges={range}
            showDropdowns = {true}
            // onEvent={handleEvent}
            // onCallback={handleCallback}
            onApply={handleEventAddApply}
        >
             <button className='datePicker-display-date-btn'>
                    <DateRangeIcon style={{ width: '18px', marginRight: '4px' }} />
                    {props?.showDate ? (
                          <span className='date-heading-main'> Select Date</span>
                    ) : (
                        <span className='date-heading-main'>  {`${start_Date === undefined ? "" : moment(start_Date).format("DD-MM-YYYY")} - ${end_Date === undefined ? "" : moment(end_Date).format("DD-MM-YYYY")}`}</span>
                    )}
                    <KeyboardArrowDownIcon style={{ width: '18px', float: 'right' }} />
                    {!props?.showDate && (<CloseIcon  style={{ width: '18px', float: 'right' }} onClick={handleDeSelectDate} />)}
                   
                </button>
        </DateRangePicker>
    </div>
);
}

export default DateRangePickerComp