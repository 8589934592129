import * as React from "react";
import { TextField } from "@mui/material";
export default function CommonPageSelect({
  search,
  onSearch,
  searchValue,
  onPageSizeChanged,
  handleGotoPage,
  fromRows,
  toRows,
  length,
  marginTop,
  isNoRecords
}) {
  return (
    <>
      {search ? (
        <div className="listing-data-grid">
          <div
            style={{
              marginTop: marginTop ? marginTop:"0px",
              height: "30px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <p>Search: &nbsp;&nbsp;</p>
            <TextField
              id="standard-basic"
              onChange={onSearch}
              className="search-filter-listing-grid"
              value={searchValue}
              InputProps={{
                style: {
                  border: "1px solid #eb6e12",
                  fontSize: "12px",
                  width: "100%",
                  fontFamily: " Times New Roman !important",
                  marginLeft: "auto  !important",
                  marginRight: "10px !important",
                  position: "relative",
                  float: "right",
                  top: "-10px",
                  backgroundColor: "#fff ",
                  color: "#fff !important ",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#eb6e12",
                  fontWeight: "bold",
                  fontFamily: "Times New Roman ",
                  marginTop: "-11px",
                  padding: "0",
                },
                shrink: false,
              }}
            />
          </div>
        </div>
      ) : null}

      {!isNoRecords && (
        <div
        className="tot-count"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          margin: "0 10px",
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <select
            onChange={onPageSizeChanged}
            id="page-size"
            style={{
              borderRadius: "12%",
              background: "no-repeat",
            }}
          >
            <option value="10">10</option>
            <option value="50" >
              50
            </option>
            <option value="100" selected={true}>100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="2000">2000</option>
          </select>
          <p style={{ margin: "0" }}> &nbsp; records per page</p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: "0" }}>
            Showing {fromRows} to {toRows} of {length} entries &nbsp;
          </p>
          <p style={{ margin: 0, display: "flex", alignItems: "center" }}>
            Go to: &nbsp;
          </p>
          <input
            id="go-to-page"
            type="text"
            onChange={handleGotoPage}
            style={{ width: "55px",  marginRight: "-9px" }}
          />
        </div>
      </div>
      )}

      
    </>
  );
}
