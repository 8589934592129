import './tableData.scss'



export const columns = [

    { headerName: 'S.No.', field: 'id', headerAlign: 'center', align: 'center', width: 70 },
    { headerName: 'ENQ#', field: 'enq', headerAlign: 'center', align: 'center', width: 70 },
    { headerName: 'Meeting Date And Time', field: 'meetingDateAndTime', headerAlign: 'center', align: 'center', width: 180, type: 'date' },
    {
        headerName: 'Client And Company Name', field: 'clientAndCompanyName', headerAlign: 'center', align: 'center', width: 190,
        type: 'singleSelect',
    },
    {
        headerName: 'Consultant Name', field: 'consultantName', headerAlign: 'center', align: 'center', flex: 1
    },
    {
        headerName: 'Location', field: 'location', headerAlign: 'center', align: 'center', flex: 1
    },
    {
        headerName: 'Meeting Purpose', field: 'meetingPurpose', headerAlign: 'center', align: 'center', flex: 1
    },
    { headerName: 'Counter', field: 'counter', headerAlign: 'center', align: 'center', },
    { headerName: 'Remarks', field: 'remarks', headerAlign: 'center', align: 'center', flex: 1 },
    { headerName: 'Creation Date', field: 'creationDate', headerAlign: 'center', align: 'center', flex: 1 },

];
export const columnsHeader = {

    // id: 'S.no',
    // m: 'M',
    // e: 'E',
    // s: 'S',
    Inquiry_Id: 'ENQ#',
    Lead_Caption: 'Lead Caption',
    Enquiry_Source: ' Enquiry Source',
    Client_Full_Name: 'Client Name',
    Client_Email_id: 'Client Email',
    Client_Mobile_No: 'Client Mobile No',
    // clientReview: 'Client Review',

    Enquiry_Date: 'Lead Date',
    Enquiry_Assigned_Date: 'Assigned Date',
    Enquiry_Status: 'Reception  Status',
    Conversation_Status: 'Conversation Status',
    Consultant_Name: 'Consultant Name',
    Created_By: 'Created By',
}

export const expendData = {
    deposit: 'Deposit', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',

}
export const expendData1 = {
    Lead_Received_Through: 'Lead Received Through', Campaign_Name: 'Campaign Name', Client_Gender: 'Client Gender', Client_Address: 'Client Address', Client_City: 'Client City', Client_Occupation: 'Client Occupation', Client_DOB: "Client DOB", Client_Existing_Company_Address: "Client Existing Company Address", Nationality: "Nationality", Client_Existing_Website: "Client Existing Website", Client_Passport_No: "Client Passport No", Client_Skype_id: "Client Skype id", Type_of_Company: "Type of Company", Lead_Type: "Lead Type"

}

export const originalRows = [

    {
        id: '1', enq: '21', referral: '1', clientName: 'mamta ', clientEmail: 'mamta@gmail.com', clientReview: 'werwer', lastFollowUp: 'brazil', leadCaption: '1Bed', leadDate: '3/01/21', reassingDate: '2/01/22', leadStatus: 'done', converSationStatus: 'donestatuslisting',
        deposit: 'Deposit', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',

    },
    {
        id: '2', enq: '112', referral: '2', clientName: 'Ali', clientEmail: 'alie@gmail.com', lastFollowUp: 'dubai', leadCaption: '2Bed', leadDate: '2/02/21', reassingDate: '2/08/`12', leadStatus: 'working', converSationStatus: 'converSationStatus ', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '3', enq: '323', referral: '3', clientName: 'Amaan', clientEmail: 'Amaan@gmail.com', lastFollowUp: 'Pakistan', leadCaption: '3Bed', leadDate: '11/01/21', reassingDate: '2/03/20', leadStatus: 'done', converSationStatus: 'AmaanConver', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '4', enq: '567', referral: '4', clientName: 'Tanveer', clientEmail: 'tanveer@gmail.com', lastFollowUp: 'UAE', leadCaption: '4Bed', leadDate: '30/04/21', reassingDate: '31/01/12', leadStatus: 'woeking', converSationStatus: 'tanveerconver', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '5', enq: '43', referral: '15', clientName: 'Taniya', clientEmail: 'Taniya@gmail.com', lastFollowUp: 'India', leadCaption: '1Bed', leadDate: '22/01/21', reassingDate: '12/01/20', leadStatus: 'done', converSationStatus: '3343sdd4', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '6', enq: '211', referral: '61', clientName: 'Arif', clientEmail: 'Arif@gmail.com', lastFollowUp: 'Canada', leadCaption: '8Bed', leadDate: '5/01/21', reassingDate: '9/01/22', leadStatus: 'done', converSationStatus: 'Status33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '7', enq: '555', referral: '122', clientName: 'Ajay', clientEmail: 'aj@gmail.com', lastFollowUp: 'india', leadCaption: '1Bed', leadDate: '3/01/21', reassingDate: '2/01/22', leadStatus: 'done', converSationStatus: '33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '8', enq: '655', referral: '44', clientName: 'Fardeen', clientEmail: 'm.fd@gmail.in', lastFollowUp: 'UAE', leadCaption: '1Bed', leadDate: '8/01/21', reassingDate: '6/07/22', leadStatus: 'done', converSationStatus: 'data33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '9', enq: '987', referral: '14', clientName: 'Sana', clientEmail: 'sana@gmail.com', lastFollowUp: 'Afganistan', leadCaption: '8Bed', leadDate: '7/2/21', reassingDate: '2/01/22', leadStatus: 'done', converSationStatus: '33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '10', enq: '765', referral: '2', clientName: 'Fatima', clientEmail: 'fatima@gmail.com', lastFollowUp: 'UK', leadCaption: '6Bed', leadDate: '3/01/21', reassingDate: '14/01/12', leadStatus: 'working', converSationStatus: 'Done33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '11', enq: '1112', referral: '12', clientName: 'Tamnna', clientEmail: 'alia@gmail.com', lastFollowUp: 'brazil', leadCaption: '1Bed', leadDate: '3/01/21', reassingDate: '2/01/22', leadStatus: 'done', converSationStatus: '33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '12', enq: '112', referral: '2', clientName: 'Ali', clientEmail: 'alie@gmail.com', lastFollowUp: 'dubai', leadCaption: '2Bed', leadDate: '2/02/21', reassingDate: '2/08/`12', leadStatus: 'working', converSationStatus: 'converSationStatus ', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '13', enq: '323', referral: '3', clientName: 'Amaan', clientEmail: 'Amaan@gmail.com', lastFollowUp: 'Pakistan', leadCaption: '3Bed', leadDate: '11/01/21', reassingDate: '2/03/20', leadStatus: 'done', converSationStatus: 'AmaanConver', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '14', enq: '567', referral: '4', clientName: 'Tanveer', clientEmail: 'tanveer@gmail.com', lastFollowUp: 'UAE', leadCaption: '4Bed', leadDate: '30/04/21', reassingDate: '31/01/12', leadStatus: 'woeking', converSationStatus: 'tanveerconver', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '15', enq: '43', referral: '15', clientName: 'Taniya', clientEmail: 'Taniya@gmail.com', lastFollowUp: 'India', leadCaption: '1Bed', leadDate: '22/01/21', reassingDate: '12/01/20', leadStatus: 'done', converSationStatus: '3343sdd4', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '16', enq: '211', referral: '61', clientName: 'Arif', clientEmail: 'Arif@gmail.com', lastFollowUp: 'Canada', leadCaption: '8Bed', leadDate: '5/01/21', reassingDate: '9/01/22', leadStatus: 'done', converSationStatus: 'Status33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '17', enq: '555', referral: '122', clientName: 'Ajay', clientEmail: 'aj@gmail.com', lastFollowUp: 'india', leadCaption: '1Bed', leadDate: '3/01/21', reassingDate: '2/01/22', leadStatus: 'done', converSationStatus: '33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '18', enq: '655', referral: '44', clientName: 'Fardeen', clientEmail: 'm.fd@gmail.in', lastFollowUp: 'UAE', leadCaption: '1Bed', leadDate: '8/01/21', reassingDate: '6/07/22', leadStatus: 'done', converSationStatus: 'data33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '19', enq: '987', referral: '14', clientName: 'Sana', clientEmail: 'sana@gmail.com', lastFollowUp: 'Afganistan', leadCaption: '8Bed', leadDate: '7/2/21', reassingDate: '2/01/22', leadStatus: 'done', converSationStatus: '33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },
    {
        id: '20', enq: '765', referral: '2', clientName: 'Fatima', clientEmail: 'fatima@gmail.com', lastFollowUp: 'UK', leadCaption: '6Bed', leadDate: '3/01/21', reassingDate: '14/01/12', leadStatus: 'working', converSationStatus: 'Done33434', deposit: '12221', pricePer: '2312', listed: '12/14/2021', updated: '12/14/2021', location: 'sdasds', DeveloperUnit: 'yes', TransactionNo: '31231', PermitNo: 'suboo', keyLocation: 'Dubai',
        LastPublishedOn: '211s03', CreatedBy: 'mamta', updatedBy: 'Admin', TitleAR: 'client', Renteduntil: 'datatablecell', PfVerified: 'Yes', PfPublished: 'Nonon', PfListingType: 'fddd', Parking: 'one', ManagedUnit: 'Yes', ExpirationDate: '12/04/2022', keyword: 'data', leadingPageURL: 'www.sale.com', generationDate: '1/12/2022', city: 'Delhi', contactNumber: '2322323',
    },

]
export const excitingClientColumn = [
    // { headerName: '' },
    { headerName: 'S.NO.' },
    { headerName: 'C.ID.' },
    { headerName: 'Company  Name' },
    // { headerName: 'Company Category' },
    // { headerName: 'Company Type/Transaction' },
    { headerName: 'State' },
    { headerName: 'Formation Type' },
    { headerName: 'Inquiry No.' },
    { headerName: 'Camp Info.' },
    // { headerName: 'Client Manager/ Sales Person' },
    { headerName: 'Last Follow-up' },
    // { headerName: 'Licence Issue/Expiry Date' },
    { headerName: 'Camp Status' },
    { headerName: 'Contacted By' },
    // { headerName: 'SponSor Name' },
    { headerName: 'Ctrated Date' },
    { headerName: 'Joing Date' },
    { headerName: 'Action' },
    // { headerName: 'Last Modified By/Date' },

]
export const excitingClientRow = [
    {
        id: '1', companyId: '22', comapnyName: 'flipkart', companyCategory: 'sell', state: 'U.k', formationType: 'xqw', inquiryNumber: '121', campInfo: 'sasa',
        lastFollowUp: 'sdsdds', campStatus: 'asasas', conntactedBy: '1/01/21', createdBy: '12/2/21', joingDate: '22/1/21', companyTypeTransaction: 'asa',
        clientManagerSalesPerson: 'Adil', licenceIssueExpiryDate: '1/01/21', sponsorName: 'amit', lastModifiedByDate: '22/1/21',
    },
    {
        id: '2', companyId: '22', comapnyName: '2223', companyCategory: 'sell', state: 'U.k', formationType: 'xqw', inquiryNumber: '121', campInfo: 'sasa',
        lastFollowUp: 'sdsdds', campStatus: 'asasas', conntactedBy: '1/01/21', createdBy: '12/2/21', joingDate: '22/1/21'
    },
    {
        id: '3', companyId: '22', comapnyName: 'amazon', companyCategory: 'sell', state: 'U.k', formationType: 'xqw', inquiryNumber: '121', campInfo: 'sasa',
        lastFollowUp: 'sdsdds', campStatus: 'asasas', conntactedBy: '1/01/21', createdBy: '12/2/21', joingDate: '22/1/21'
    },
    {
        id: '4', companyId: '22', comapnyName: 'amazon', companyCategory: 'sell', state: 'U.k', formationType: 'xqw', inquiryNumber: '121', campInfo: 'sasa',
        lastFollowUp: 'sdsdds', campStatus: 'asasas', conntactedBy: '1/01/21', createdBy: '12/2/21', joingDate: '22/1/21'
    },
]


export const reassignTableHeaderArrUpdate = {
    sno: 'S.no.',
    leadCaotion: 'Lead Caption',
    capantyActivity: 'Inquiry Id',
    clientName: 'Client Name',
    email: 'Email',
    salesPerson: 'Sales Person',
    leadDate: 'Lead Date',
    reassingDate: 'Reassing Date',
    keywords: 'Keywords',
    leadStatus: 'Lead Status',
    leadConversationStatus: 'Lead Conversation Status',
}
export const reassignTableRowArr = [
    {
        sno: 'S.no.',
        leadCaotion: 'Lead Caption',
        capantyActivity: 'Inquiry Id',
        clientName: 'Client Name',
        email: 'Email',
        salesPerson: 'Sales Person',
        leadDate: 'Lead Date',
        reassingDate: 'Reassing Date',
        keywords: 'Keywords',
        leadStatus: 'Lead Status',
        leadConversationStatus: 'Lead Conversation Status',
    },
    {
        sno: 'S.no.',
        leadCaotion: 'Lead Caption',
        capantyActivity: 'Inquiry Id',
        clientName: 'saif',
        email: 'Email',
        salesPerson: 'Sales Person',
        leadDate: 'Lead Date',
        reassingDate: 'Reassing Date',
        keywords: 'Keywords',
        leadStatus: 'Lead Status',
        leadConversationStatus: 'Lead Conversation Status',
    },

]


export const clientTableHeaderArr = {
    sno: 'S.no.',
    Client_Name: "Name",
    Client_Email: "Email",
    Client_Mobile: "Mobile",
    Joining_Date: "Joining Date",
    Sales_Person: "Sales Person",
}

export const commonRowClientData = [
    {
        sno: '1',
        name: ' Mamta',
        emailId: 'mamta.gmail.in',
        mobile: '+9122312',
        joiningDate: '12/3/21',
        salesPerson: 'Sales Person',
    },
    {
        sno: '2',
        name: ' ali',
        emailId: 'ali.gmail.in',
        mobile: '+9122312',
        joiningDate: '12/3/21',
        salesPerson: 'Sales Person',
    }
]

export const commonRowMettingProjectionData = [
    {
        id: '1',
        enq: '323',
        meetingDateAndTime: '12/10/21, 09:00AM',
        clientAndCompanyName: 'amazon',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '11/10/21',
        consultan: 'consultan',
    },
    {
        id: '2',
        enq: '123',
        meetingDateAndTime: '1/10/21',
        clientAndCompanyName: 'Flipkart',
        consultantName: 'Saif Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/7/21',
        consultan: 'consultan',
    },
    {
        id: '3',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '4',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '5',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '6',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '7',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '8',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '9',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '10',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
    {
        id: '11',
        enq: '343',
        meetingDateAndTime: '22/10/21',
        clientAndCompanyName: 'LeansKart',
        consultantName: 'Ali',
        location: 'Location',
        meetingPurpose: 'Metting Purpose',
        counter: 'Counter',
        remarks: 'Remarks',
        creationDate: '1/12/21',
        consultan: 'consultan',
    },
]
//  for radio btn styling
export const radioStyle = {
    color: "#f9a668",
    "& svg": {
      width: 16,
      height: 16,
    },
    "&.Mui-checked": {
      color: "#eb6e12",
    },
  };
  export  const labelStyle = {
    ".MuiFormControlLabel-label":
      {
        fontSize:
          "12px !important",
        fontFamily:
          "Times New Roman !important",
        fontWeight:
          "600 !important",
      },
  };