const richTextBoxStyles = (theme) => ({
    RichTextEditerContainer: {
        height: '170px ',
        maxHeight: '200px',
        fontFamily: ' Times New Roman !important',
        border: '1px solid #d3d3d3',
        marginLeft: '9px'
    },
    richTextBoxTopBarIcon: {

        fontSize: '20px !important'
    },
    RichTextEditerDiv: {
        marginTop: '-10px'
    },
})
export default richTextBoxStyles