import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./customTextarea.css";

const NewCustomTextArea = ({
  placeholder,
  fieldName,
  fieldHandleChange,
  fieldValue,
  errorField,
  disableField,
  requiredField,
  height,
  errors,
  onPaste
}) => (
  <>
        <div className="fc-textarea-container" style={errorField}>
          <textarea
            className="fc-textarea-style"
            placeholder={placeholder}
            name={fieldName}
            value={fieldValue}
            onChange={fieldHandleChange}
            disabled={disableField ? "disabled" : ""}
            onPaste={onPaste}
            style={{ height: height ? height : "" }}
          />
        </div>
  </>
);

export default NewCustomTextArea;
