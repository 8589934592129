import React, { useState, useContext, useEffect } from "react";
import { Box, Tab, Badge } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { withStyles } from "@material-ui/core/styles";
import TabStyles from "../styles/TabStyles";
import axios from "axios";
import moment from "moment";
import { FcAcceptDatabase, FcDeleteDatabase, FcCancel } from "react-icons/fc";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaAdn,
  FaStop,
  FaStopCircle,
  FaCheckDouble, FaReply
} from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Lead from "../../../../commonComp/assets/lead.png";
import { BsChatLeftTextFill } from "react-icons/bs";
import ArticleIcon from "@mui/icons-material/Article";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { DataContext, SupportDataContext } from "../../../../provider/context";
import { getLocalData } from "../../../../services/services";
import listingtableStyles from "../styles/ListingtableStyles";
import "../styles/ListingTable.css";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MyProjectDesignerOrderListing from "./MyProjectDesignerOrderListing";
import TemplateCreationListingTable from "./TemplateCreationListingTable";
import NOFListingTable from "./NOFListingTable";
import ProjectListTable from "./ProjectListTable";
import OldNOFListingTable from "./OldNOFListingTable";
import ViewCompanyDetails from "./ViewCompanyDetails";
import ReAssignOrderListTable from "./ReAssignOrderListTable";
import ScheduleTrainingTable from "../../Support/Listing/ScheduleTrainingTable";
import TrainingCourseTable from "../../Support/Listing/TrainingCourseTable";
import TypistRequisitionListing from "./TypistRequisitionListing";
import ProRequisitionListTable from "./ProRequisitionListTable";
import GeneralScheduleListingTable from "./GeneralScheduleListingTable";
import RoOrderListTable from "./RoOrderListTable";




const ProjectDesignerListingTabBottom = (props) => {
  const [value, setValue] = useState("1");
  const { nofData, pdBadges } = useContext(DataContext);
  const { classes } = props;

  const {
    getAdminOrderListgDataApi,
    adminOrderListgData,
    templateCreationList,
    projectList,
    getProjectListDataApi,
    getOldNOFDataApi, oldNofData,
    scheduleTrainingData,
    trainingCourseData,
    typistRequestionList,
    proRequestionList,
    generalScheduleData,
    getGeneralScheduleDataApi,
    
  } = useContext(SupportDataContext);

  const emp_id = getLocalData("emp_id");
  const __token = getLocalData("token");
  const userTypeInfo = localStorage.getItem("userType");
  const factory_id = localStorage.getItem("factory_id");  
  const [amlPolicy, setAmlPolicy] = useState(null);

  const fullName = `${localStorage.getItem( "Employee_First_Name" )} ${localStorage.getItem("Employee_Last_Name")}`;
  const filteredSchedules = scheduleTrainingData?.Training_Schedule_List?.filter(schedule => {
    return schedule?.trainee_detail?.some(trainee => trainee?.Trainee_Name === fullName);
  });
  const completedNotifiedCourses = filteredSchedules?.filter(course => 
    course?.status === "Notified"
  );
  const approvedCourses = trainingCourseData?.filter(course => course?.status === "Approved");
  const nofApiData = nofData?.New_Order_Form_List_On_Company?.concat(nofData?.New_Order_Form_List_On_Inquiry);



  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
      border: "1px solid #FF7518",
    },
  }));
  const ScrollTop = () => {
    window.scrollTo({
      top: -40,
      left: 0,
      behavior: "smooth",
    });
  };
  const fetchAmlPolicy = async () => {
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/amlpolicy/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setAmlPolicy(res.data.results?.AML_policy_list[0]);
    } catch (err) {
      
    }
  };
  const handleChange = (event, newValue) => {
    if (newValue === "6") {
      fetchAmlPolicy();
    }
    setValue(newValue);
  };

  const myInquryIconsArrUpper = [
    // {
    //   icon: <IoDocument style={{ margin: "0", fontSize: "17px" }} />,
    //   popper: "Initial Approval Order",
    //   btnText: "Initial Approval Order",
    // },
    {
      icon: (
        <FaStopCircle className={classes.iconImg} style={{ margin: "0" }} />
      ),
      popper: "Freeze Order Approval",
      btnText: "Freeze Order Approval",
    },
    {
      icon: <FaCheckDouble style={{ margin: "0", fontSize: "17px" }} />,
      popper: "Completion Order Approval",
      btnText: "Completion Order Approval",
    },
    {
      icon: <FaEye style={{ margin: "0" }} />,
      popper: "View Order Detail",
      btnText: "View Order Detail",
    },

  ];
  const myInquryIconsArrLower = [
    {
      icon: (
        <ReceiptLongIcon className={classes.iconImg} style={{ margin: "0" }} />
      ),
      popper: "Add/View Requisition",
      btnText: "Add/View Requisition",
    },
    {
      icon: <FaSync className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Add/View Follow-up",
      btnText: "Add/View Follow-up",
    },
    {
      icon: <NotListedLocationIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Edit/View Complaint",
      btnText: "Edit/View Complaint",
    },
    {
      icon: <ArticleIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "View Order Log",
      btnText: "View Order Log",
    },


  ];
  const tableUpperButtonArr = [
    {
      buttonName: "Save Search",
    },
    {
      buttonName: `SSS`,
      icon: "",
    },
  ];
  return (
    <div className={classes.listingTableContainer}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              style={{ display: 'flex', flexWrap: 'wrap' }}
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
              className={classes.tabListListing}
            >


              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={pdBadges?.NOF_Order_Count} showZero max={pdBadges?.NOF_Order_Count}>
                    <LightTooltip title="My All Orders List" >
                      <span>NOF & Orders &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="1"
              />

              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={pdBadges?.Template_Creation_Count} showZero max={pdBadges?.Template_Creation_Count}>
                    <LightTooltip title="Template Creation" >
                      <span>Template Creation &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="2"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={pdBadges?.Projects_Count} showZero max={pdBadges?.Projects_Count}>
                     <LightTooltip title="Project Creation" >
                      <span>Project List &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </LightTooltip>
                  </Badge>
                }
                value="3"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "10px"}} badgeContent={oldNofData?.length} showZero max={oldNofData?.length}>
                    <LightTooltip title="Old NOF Orders List" >
                      <span style={{marginRight: "10px"}}> Old NOF</span>
                    </LightTooltip>
                  </Badge>
                }
                value="4"
              />
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" >
                    <LightTooltip title="Re Assign Orders To Typist" >
                      <span>Re-Assign Orders</span>
                    </LightTooltip>
                  </Badge>
                }
                value="7"
              />  
                <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" >
                    <LightTooltip title="RO Orders List" >
                      <span>RO Orders</span>
                    </LightTooltip>
                  </Badge>
                }
                value="13"
              /> 
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" >
                    <LightTooltip title="View Company Details " >
                      <span>View Company</span>
                    </LightTooltip>
                  </Badge>
                }
                value="5"
              />  
               <Tab
              className={classes.tabStyleListingOthers}
              classes={{
                selected: classes.selected,
              }}
              icon={
                <Badge color="warning" style={{marginRight: "5px"}} badgeContent={pdBadges?.Training_Course_Count} showZero max={pdBadges?.Training_Course_Count}>
                  <LightTooltip title="Training Course List" >
                    <span style={{marginRight: "14px"}}>Training Course</span>
                  </LightTooltip>
                </Badge>
              }
              value="8"
            />
             <Tab
              className={classes.tabStyleListingOthers}
              classes={{
                selected: classes.selected,
              }}
              icon={
                <Badge color="warning" style={{marginRight: "5px"}} badgeContent={pdBadges?.Scheduled_Training_Count} showZero max={pdBadges?.Scheduled_Training_Count}>
                  <LightTooltip title="Scheduled Training Course List" >
                    <span style={{marginRight: "14px"}}>Scheduled Training </span>
                  </LightTooltip>
                </Badge>
              }
              value="9"
            />
             <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{ marginRight: "6px" }} badgeContent={pdBadges?.Typist_Requisition_Count} showZero max={pdBadges?.Typist_Requisition_Count} >
                    <LightTooltip title="View Typist Requisition List">
                      <span style={{ marginRight: "15px" }}>Typist Requisition</span>
                    </LightTooltip>
                  </Badge>
                }
                value="10"
              />
                <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "6px"}} badgeContent={pdBadges?.PRO_Requisition_Count} showZero max={pdBadges?.PRO_Requisition_Count}>
                     <LightTooltip title="View PRO'S Requisition List" >
                      <span style={{marginRight: "20px"}}>PRO'S Requisition</span>
                    </LightTooltip>
                  </Badge>
                }
                value="11"
              />   
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{marginRight: "6px"}} badgeContent={pdBadges?.PRO_General_Schedule_Count} showZero max={pdBadges?.PRO_General_Schedule_Count}>
                     <LightTooltip title="View PRO'S General Schedule" >
                      <span style={{marginRight: "20px"}}> PRO General Schedule</span>
                    </LightTooltip>
                  </Badge>
                }
                value="12"
              />   
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" badgeContent={0}>
                    AML Policy
                  </Badge>
                }
                value="6"
              />

            </TabList>
          </Box>
        </TabContext>
      </Box>
      <div className={classes.TabLowerContainerDiv}>
        {
          value === "1" ? (
            <NOFListingTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcon={myInquryIconsArrLower}
              apiData={nofApiData}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              //fetchListingDataFromApi={getAdminCompalintListDataApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
          ) : value === "2" ? (
            <TemplateCreationListingTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcons={myInquryIconsArrLower}
              apiData={adminOrderListgData}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              fetchListingDataFromApi={getAdminOrderListgDataApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
          ) : value === "3" ? (
            <ProjectListTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcons={myInquryIconsArrLower}
              apiData={projectList}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              fetchListingDataFromApi={getProjectListDataApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
          ) : value === "4" ? (
            <OldNOFListingTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcons={myInquryIconsArrLower}
              apiData={oldNofData}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              fetchListingDataFromApi={getOldNOFDataApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
          ) : value === "5" ? (
            <ViewCompanyDetails
              upperIcons={myInquryIconsArrUpper}
              lowerIcons={myInquryIconsArrLower}
              // apiData={oldNofData}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              fetchListingDataFromApi={getOldNOFDataApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
           ) 
           : value === "7" ? (
            <ReAssignOrderListTable
              upperIcons={myInquryIconsArrUpper}
              lowerIcons={myInquryIconsArrLower}
              // apiData={oldNofData}
              tableUpperButtonArr={tableUpperButtonArr}
              tabValue={value}
              // fetchListingDataFromApi={getOldNOFDataApi}
              ScrollTop={ScrollTop}
              fitlerBadge={true}
            />
           ) : value === "8" ? (
            <TrainingCourseTable
            rowData={trainingCourseData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            ScrollTop={ScrollTop}
            heightTable="100%"
            height="500px"
            fitlerBadge={true}
            />
          ) :
          value === "9" ? (
          <ScheduleTrainingTable
          rowData={scheduleTrainingData?.Training_Schedule_List}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
          ) :
          value === "10" ? (
          <TypistRequisitionListing
          rowData={typistRequestionList}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
          ) :
          value === "11" ? (
          <ProRequisitionListTable
          rowData={proRequestionList}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
          ) :
          value === "12" ? (
          <GeneralScheduleListingTable
          rowData={generalScheduleData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          fetchListingDataFromApi={getGeneralScheduleDataApi}
          />
          ) 
          : value === "13" ? (
            <RoOrderListTable
            rowData={generalScheduleData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            ScrollTop={ScrollTop}
            heightTable="100%"
            height="500px"
            fitlerBadge={true}
            fetchListingDataFromApi={getGeneralScheduleDataApi}
              
            />
            ) 
            : value === "6" ? (
            <div className="aml-policy-container">
              <p className="aml-policy-header">AML Policy</p>
              <div
                dangerouslySetInnerHTML={{ __html: amlPolicy?.Policy_Details }}
              />
            </div>
          )
            :
            null
        }

      </div>
    </div>
  );
};

export default withStyles(listingtableStyles)(ProjectDesignerListingTabBottom);
