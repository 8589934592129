import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import LoaderClock from "../../../../lodar/LoaderClock";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../Styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";


const PendingRequistionListing = ({ classes, pendingReqListing }) => {
  const {
    loading,
    handleModalOpen,
    setViewRequistion,
    operationInfo,
    setEditModalPopper

  } = useContext(SupportDataContext);
  const gridRef = useRef();

  const [columnDefs] = useState([
    {
      headerName: "S#",
      field: "Serial_Number",
      filter: "agTextColumnFilter",
      width: 70,
      maxWidth: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Requistion Id",
      field: "Requisition_Auto_Id",
      filter: "agTextColumnFilter",
      width: 170,
      maxWidth: 200,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Task Name",
      field: "mainTaskName",
      filter: "agTextColumnFilter",
      width: 230,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },

    {
      headerName: "Work Type",
      field: "work_type_name",
      //filter: "agDateColumnFilter",
      width: 150,
      maxWidth: 230,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Request By",
      field: "Requested_By",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Amount",
      field: "Requisition_Amount",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
    },
    {
      headerName: "Account Status / Date",
      field: "accountsStatus",
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: { border: "1px solid #fff", overflowY: "auto", height: "90px" },
      cellRenderer: (params) => {
        return (
          <>
            <>
              {params?.data?.accountsStatus}<br />
              {params?.data?.Account_Status_Date}
            </>
          </>
        )
      }
    },
    {
      headerName: "Requistion Date",
      field: "requisition_date",
      filter: "agTextColumnFilter",
      width: 200,
      maxWidth: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      cellRenderer: (params) => {
        return <>{params?.data?.requisition_date === "" ? "" :
          moment(params?.data?.requisition_date).format("DD-MM-YYYY hh:mm:ss a")}</>;
      },
    },
    {
      headerName: "Requisition Start Date",
      field: "startdate",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff" },
      width: 200,
      cellRenderer: (params) => {
        return <>{params?.data?.startdate === "" ? "" :
          moment(params?.data?.startdate).format("DD-MM-YYYY hh:mm:ss a")}</>;
      },
    },
    {
      headerName: "Requisition End Date",
      field: "enddate",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff" },
      width: 200,
      cellRenderer: (params) => {
        return <>{params?.data?.enddate === "" ? "" :
          moment(params?.data?.enddate).format("DD-MM-YYYY hh:mm:ss a")}</>;
      },
    },
    {
      headerName: "Self Assigned",
      field: "is_self_assign_by_ro",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
    },
    {
      headerName: "PRO Head Status / Date",
      field: "proHeadStatus",
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: { border: "1px solid #fff", overflowY: "auto", height: "90px" },
      cellRenderer: (params) => {
        return (
          <>
            <>
              {params?.data?.proHeadStatus}<br />
              {params?.data?.pro_head_status_date}
            </>
          </>
        )
      }
    },
    {
      headerName: "PRO Name",
      field: "Pro_Name",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto", height: "90px" },
    },
    {
      headerName: "Support Status / Date",
      field: "support_status",
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: { border: "1px solid #fff", overflowY: "auto", height: "90px" },
      cellRenderer: (params) => {
        return (
          <>
            <>
              {params?.data?.support_status}<br />
              {params?.data?.support_status_date}
            </>
          </>
        )
      }
    },

    {
      headerName: "Sub Task Days",
      field: "sub_task_days",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
    },
    {
      headerName: "Sub Task Sequence",
      field: "sub_task_sequance",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
    },

    {
      headerName: "Order No.",
      field: "Order_No",
      filter: "agTextColumnFilter",
      width: 220,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto", height: "90px" },
    },


  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      editable: false,
      // filter: "agTextColumnFilter",
      // floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <LoaderClock loading={loading} />
      <TableContainer component={Paper}>
        <h5 style={{ color: 'black', width: '100%', background: '#F5F5F5', margin: 0 }}>PENDING REQUISTION</h5>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={pendingReqListing?.Requisition_List}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
    </div>
  );
};

export default withStyles(modalTableStyles)(PendingRequistionListing);
