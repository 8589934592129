import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaPlus,
  FaReply,
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ApprovalIcon from "@mui/icons-material/Approval";
import GradingIcon from "@mui/icons-material/Grading";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { getTotalLength } from "../../../../utils/Filters";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TemplateCreationListingTable = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    setAcceptAccountArray,
    getPropertyFormDataApi,
    message,
    severity,
    isOpen,
    setIsOpen,
    // getTemplateCreationListgDataApi,
    // templateCreationList
    getTemplateCreationListgDataApi,
    templateCreationList,
    filterOrders,
    setFilterOrders,
    setTemplateOperationInfo
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  // const [filterOrders, setFilterOrders] = useState();

  useEffect(() => {
    getTemplateCreationListgDataApi();
    handleListingSavedSearch();
  }, []);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <CustomIconButton
          onClick={() => {
            setOperationInfo(params.data);
            handleModalTableOpen("View Template Creation", params.data);
            setButtonText("View Template Creation");
            setEditModalPopper(false);
            ScrollTop();
          }}
          title={"View Template Creation"}
          icon={<FaEye />}
          className="btnActionAlignment"
        />
        <CustomIconButton
          onClick={() => {
            setOperationInfo(params.data);
            setTemplateOperationInfo(params?.data)
            handleModalTableOpen("Edit Template Creation", params.data);
            setButtonText("Edit Template Creation");
            setEditModalPopper(true);
            ScrollTop();
          }}
          title={"Edit Template Creation"}
          icon={<EditIcon />}
          className="btnActionAlignment"
        />
        <CustomIconButton
          width={"auto"}
          title={data?.template_category === "Order Sub Template" ? "Add Description" : "Add SOW"}
          icon={
            <>
              {data?.template_category === "Order Sub Template" ? "Add Description" : "Add SOW"} 
            </>
          }
          onClick={() => {
            setOperationInfo(params.data);
            setTemplateOperationInfo(params?.data)
            handleModalTableOpen("SOW Template Listing", params.data);
            setButtonText("SOW Template Listing");
            setEditModalPopper(false);
            ScrollTop();
          }}
        />
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Sr.",
      field: "Serial_Number",
      //cellRenderer: "agGroupCellRenderer",
      //checkboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        textAlign: "center",
      },
      width: 75,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Actions",
      field: "",
      filter: false,
      // checkboxSelection: true,
      cellRenderer: actionBtnRenderer,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      minWidth: 220,
    },
    {
      headerName: "Template Name",
      field: "Service_Name",
      filter: 'agTextColumnFilter',
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Template Category",
      field: "template_category",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Template Remarks",
      field: "Service_Details",
       filter: 'agTextColumnFilter',
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "active_status_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Created By",
      field: "Created_By",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Update By",
      field: "Updated_By",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Updated Date",
      field: "Updated_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    {
      id: 2,
      title: "Order Template",
      value: "Order Template",
      key: "template_category",
    },
    {
      id: 3,
      title: "Order Sub Template",
      value: "Order Sub Template",
      key: "template_category",
    },
  ];

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleFilters = (title, value, key) => {
   
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id");
  };

  return (
    <div className="listing-data-grid" style={{ height: "600px" }}>
      <Grid container>
        <Grid item sm={12} md={8} xs={12} style={{ paddingRight: "1rem" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#eb6e12",
              fontFamily: "Times New Roman !important",
              color: "#fff",
              width: "200px",
              height: "37px",
              margin: "10px 0 0 10px",
            }}
            onClick={() => {
              handleModalTableOpen("Add Template Creation");
              setButtonText("Add Template Creation");
              setEditModalPopper(false);
              ScrollTop();
            }}
          >
            <FaPlus style={{ marginRight: "10px" }} />
            Create New Template
          </Button>
        </Grid>
      </Grid>

      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={templateCreationList?.Template_Service_Name_List}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {templateCreationList?.Template_Service_Name_List?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(templateCreationList?.Template_Service_Name_List, val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleFilters(val.title, val.value, val.key)
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(templateCreationList?.Template_Service_Name_List,val.Search_Name, valueKey)}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",

                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          val.Search_Name,
                          val.Search_Name,
                          valueKey
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={templateCreationList?.Template_Service_Name_List?.length}
      />
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
          ref={gridRef}
          rowData={templateCreationList?.Template_Service_Name_List}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          // onSelectionChanged={onRowSelected}
          components={components}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TemplateCreationListingTable;
