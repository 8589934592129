import React, { useState, useContext, useEffect } from "react";
import { Box, Tab, Badge } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { withStyles } from "@material-ui/core/styles";
import TabStyles from "../Styles/TabStyles";
import axios from "axios";
import moment from "moment";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaUserFriends,
} from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Lead from "../../../../commonComp/assets/lead.png";
import { BsChatLeftTextFill } from "react-icons/bs";
import ArticleIcon from "@mui/icons-material/Article";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HistoryIcon from "@mui/icons-material/History";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import { getLocalData } from "../../../../services/services";
import listingtableStyles from "../Styles/ListingtableStyles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PRORequistionListing from "./PRORequistionListing";
import GeneralTaskManagerListingTable from "./GeneralTaskManagerListingTable";
import GeneralScheduleListingTable from "./GeneralScheduleListingTable";
import PROTaskListingTable from "./PROTaskListingTable";
import TypistTaskListingTable from "./TypistTaskListingTable";
import NOFListingTable from "./NOFListing";
import NOFListingonCompanyTable from "./NOFListingonCompany";
import InformationPool from "../../Support/Listing/InformationPool";
import PROInternalRequistion from "./PROInternalRequistion";
import ViewCompanyDetails from "./ViewCompanyDetails";
import ScheduleTrainingTable from "../../Support/Listing/ScheduleTrainingTable";
import TrainingCourseTable from "../../Support/Listing/TrainingCourseTable";



const PROHeadListingTapBottom = (props) => {
  const [value, setValue] = useState("1");
  const {
    getProHeadRequistionDataApi,
    proHeadRequistionData,
    getGeneralTaskManagerDataDataApi,
    generalTaskManagerData,
    getGeneralScheduleDataApi,
    generalScheduleData,
    typistTaskList,
    proTaskList,
    proInternalData,
    fetchPROInternalRequistionListData,
    viewCompanyData,
    scheduleTrainingData,
    trainingCourseData
  } = useContext(SupportDataContext);
  const {
    supportBadges,
    setStart_Date,
    setEnd_Date,
    iPapiData,
    filterData,
    InformationPoolListingApi,
  } = useContext(DataContext);
  const { classes } = props;
  const __token = getLocalData("token");
  const [nOFListData, setNOFListingData] = useState(null);

  const fullName = `${localStorage.getItem( "Employee_First_Name" )} ${localStorage.getItem("Employee_Last_Name")}`;
   const filteredSchedules = scheduleTrainingData?.Training_Schedule_List?.filter(schedule => {
     return schedule?.trainee_detail?.some(trainee => trainee?.Trainee_Name === fullName);
   });
   const completedNotifiedCourses = filteredSchedules?.filter(course => 
     course?.status === "Notified"
   );
   const approvedCourses = trainingCourseData?.filter(course => course?.status === "Approved");

  const NofLitingApi = async (fromDate, toDate) => {
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/adminneworderformlist/${
        fromDate === undefined ? "" : `?from_date=${fromDate}`
      }${toDate === undefined ? "" : `&to_date=${toDate}`}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setNOFListingData(res.data.Data);
    } catch (err) {
      
    }
  };

  const ScrollTop = () => {
    window.scrollTo({
      top: -40,
      left: 0,
      behavior: "smooth",
    });
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 13,
      border: "1px solid #FF7518",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStart_Date();
    setEnd_Date();
  };

  const IconsForNewPartnerCompliance = [
    {
      icon: (
        <FaUserFriends className={classes.iconImg} style={{ margin: "0" }} />
      ),
      popper: "View Shareholder",
      btnText: "View Shareholder/Partner",
    },
    {
      icon: <FaSync className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Change Company Compliance Status",
      btnText: "Change Company Compliance Status",
    },
  ];

  const myInquryIconsArrUpper = [
    {
      icon: <FaEye style={{ margin: "0" }} />,
      popper: "View Order",
      btnText: "View Order",
    },
    {
      icon: <FaSync className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Add / View Follow-up",
      btnText: "Add / View Follow-up",
    },
    {
      icon: <HistoryIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Add / View Comments",
      btnText: "Add / View Comments",
    },
    // {
    //   icon: (
    //     <ReceiptLongIcon className={classes.iconImg} style={{ margin: "0" }} />
    //   ),
    //   popper: "Add / View Requestion",
    //   btnText: "Add / View Requestion",
    // },
    {
      icon: (
        <FaFileUpload className={classes.iconImg} style={{ margin: "0" }} />
      ),
      popper: "Request Freez Order",
      btnText: "Request Freez Order",
    },
  ];
  const myInquryIconsArrLower = [
    {
      icon: <ArticleIcon className={classes.iconImg} style={{ margin: "0" }} />,
      popper: "Order Log",
      btnText: "Order Log",
    },
    // {
    //   icon: <IoDocument style={{ margin: "0", fontSize: "17px" }} />,
    //   popper: "Add / View SOW Requisition",
    //   btnText: "Add / View SOW Requisition",
    // },
    // {
    //   icon: <FaFileMedical style={{ margin: "0", fontSize: "17px" }} />,
    //   popper: "Add Request Order Completion",
    //   btnText: "Add Request Order Completion",
    // },
  ];
  const tableUpperButtonArr = [
    {
      buttonName: "Save Search",
    },
    {
      buttonName: `SSS`,
      icon: "",
    },
  ];
  return (
    <div className={classes.listingTableContainer}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
              className={classes.tabListListing}
            >
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge
                    color="warning"
                    style={{ marginRight: "5px" }}
                    badgeContent={supportBadges?.PRO_Requisition}
                    showZero
                    max={supportBadges?.PRO_Requisition}
                  >
                    <span style={{ marginRight: "20px" }}>PRO Requistion </span>
                  </Badge>
                }
                value="1"
              />

              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{ marginRight: "5px" }}>
                    <span style={{ marginRight: "20px" }}>
                      General Task Master{" "}
                    </span>
                  </Badge>
                }
                value="2"
              />

              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge
                    color="warning"
                    style={{ marginRight: "5px" }}
                    badgeContent={
                      supportBadges?.NotStarted_Underprocess_Overdue_General_Schedule
                    }
                    showZero
                    max={
                      supportBadges?.NotStarted_Underprocess_Overdue_General_Schedule
                    }
                  >
                    <span style={{ marginRight: "20px" }}>
                      General Schedule
                    </span>
                  </Badge>
                }
                value="3"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge
                    color="warning"
                    style={{ marginRight: "10px" }}
                    badgeContent={proTaskList?.length}
                    showZero
                  >
                    <LightTooltip title="Pro Task List">
                      <span style={{ marginRight: "25px" }}>Pro Task</span>
                    </LightTooltip>
                  </Badge>
                }
                value="4"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge
                    color="warning"
                    style={{ marginRight: "10px" }}
                    badgeContent={typistTaskList?.length}
                    showZero
                  >
                    <LightTooltip title="Typist Task List">
                      <span style={{ marginRight: "25px" }}>Typist Task</span>
                    </LightTooltip>
                  </Badge>
                }
                value="5"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge
                    color="warning"
                    badgeContent={
                      nOFListData?.New_Order_Form_List_On_Inquiry?.length
                    }
                    showZero
                    max={nOFListData?.New_Order_Form_List_On_Inquiry?.length}
                  >
                    <LightTooltip title="Approved NOF on Inquiry">
                      <span style={{ marginRight: "10px" }}>
                        Approved NOF on Inquiry &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </LightTooltip>
                  </Badge>
                }
                value="6"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge
                    color="warning"
                    badgeContent={
                      nOFListData?.New_Order_Form_List_On_Company?.length
                    }
                    showZero
                    max={nOFListData?.New_Order_Form_List_On_Company?.length}
                  >
                    <LightTooltip title="Approved NOF on Company ">
                      <span style={{ marginRight: "10px" }}>
                        Approved NOF on Company &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </LightTooltip>
                  </Badge>
                }
                value="7"
              />
              <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{ marginRight: "5px" }}>
                    <LightTooltip title="Info. Pool">
                      <span style={{ marginRight: "20px" }}>Info. Pool</span>
                    </LightTooltip>
                  </Badge>
                }
                value="8"
              />
                    <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning" style={{ marginRight: "5px" }}>
                    <LightTooltip title="PRO Internal Requisition">
                      <span style={{ marginRight: "20px" }}>PRO Internal Requisition</span>
                    </LightTooltip>
                  </Badge>
                }
                value="9"
              />
               <Tab
                className={classes.tabStyleListingOthers}
                classes={{
                  selected: classes.selected,
                }}
                icon={
                  <Badge color="warning"  style={{marginRight: "10px"}} >
                     <LightTooltip title="View Company Details">
                      <span style={{marginRight: "25px"}}>View Company</span>
                    </LightTooltip>
                  </Badge>
                }
                value="10"
              />  
               <Tab
              className={classes.tabStyleListingOthers}
              classes={{
                selected: classes.selected,
              }}
              icon={
                <Badge color="warning" style={{marginRight: "5px"}} badgeContent={approvedCourses?.length} showZero max={approvedCourses?.length}>
                  <LightTooltip title="Training Course List" >
                    <span style={{marginRight: "20px"}}>Training Course</span>
                  </LightTooltip>
                </Badge>
              }
              value="11"
            />
             <Tab
              className={classes.tabStyleListingOthers}
              classes={{
                selected: classes.selected,
              }}
              icon={
                <Badge color="warning" style={{marginRight: "5px"}} badgeContent={completedNotifiedCourses?.length} showZero max={completedNotifiedCourses?.length}>
                  <LightTooltip title="Schedule Training Course List" >
                    <span style={{marginRight: "20px"}}>Schedule Training </span>
                  </LightTooltip>
                </Badge>
              }
              value="12"
            />
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <div className={classes.TabLowerContainerDiv}>
        {value === "1" ? (
          <PRORequistionListing
            upperIcons={myInquryIconsArrUpper}
            lowerIcon={myInquryIconsArrLower}
            apiData={proHeadRequistionData?.Requisition_List}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            fetchListingDataFromApi={getProHeadRequistionDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "2" ? (
          <GeneralTaskManagerListingTable
            upperIcons={myInquryIconsArrUpper}
            lowerIcon={myInquryIconsArrLower}
            apiData={generalTaskManagerData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            fetchListingDataFromApi={getGeneralTaskManagerDataDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "3" ? (
          <GeneralScheduleListingTable
            upperIcons={myInquryIconsArrUpper}
            lowerIcon={myInquryIconsArrLower}
            apiData={generalScheduleData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            fetchListingDataFromApi={getGeneralScheduleDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "4" ? (
          <PROTaskListingTable
            apiData={proTaskList}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchProTaskListFromApi={fetchProTaskListFromApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "5" ? (
          <TypistTaskListingTable
            apiData={typistTaskList}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchTypistTaskListFromApi={fetchTypistTaskListFromApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "6" ? (
          <NOFListingTable
            upperIcons={IconsForNewPartnerCompliance}
            //lowerIcon={myInquryIconsArrLower2}
            apiData={nOFListData?.New_Order_Form_List_On_Inquiry || null}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            fetchListingDataFromApi={NofLitingApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "7" ? (
          <NOFListingonCompanyTable
            upperIcons={IconsForNewPartnerCompliance}
            //lowerIcon={myInquryIconsArrLower2}
            apiData={nOFListData?.New_Order_Form_List_On_Company || null}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            fetchListingDataFromApi={NofLitingApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
        ) : value === "8" ? (
          <InformationPool
            rowData={filterData}
            iPapiData={iPapiData}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            InformationPoolListingApi={InformationPoolListingApi}
            ScrollTop={ScrollTop}
            heightTable="100%"
            height="500px"
          />
        ) : value === "9" ? (
          <PROInternalRequistion
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            ScrollTop={ScrollTop}
            apiData={proInternalData}
            fetchListingDataFromApi={fetchPROInternalRequistionListData}
            fitlerBadge={true}
          />
        ) : value === "10" ? (
          <ViewCompanyDetails
            upperIcons={myInquryIconsArrUpper}
            lowerIcon={myInquryIconsArrLower}
            apiData={viewCompanyData?.Company_List}
            tableUpperButtonArr={tableUpperButtonArr}
            tabValue={value}
            //fetchListingDataFromApi={getCompletedOrdersDataApi}
            ScrollTop={ScrollTop}
            fitlerBadge={true}
          />
         ) : value === "11" ? (
          <TrainingCourseTable
          rowData={trainingCourseData}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
          ):
          value === "12" ? (
          <ScheduleTrainingTable
          rowData={scheduleTrainingData?.Training_Schedule_List}
          tableUpperButtonArr={tableUpperButtonArr}
          tabValue={value}
          ScrollTop={ScrollTop}
          heightTable="100%"
          height="500px"
          fitlerBadge={true}
          />
          )
        : null}
      </div>
    </div>
  );
};

export default withStyles(listingtableStyles)(PROHeadListingTapBottom);
