export const apiUrl = 'https://flyingcolour.online/fcapp/api'
export const setLocalData = (name, data) => localStorage.setItem(name, data)
export const getLocalData = data => localStorage.getItem(data)
export const getSessionData = data => localStorage.getItem(data)

export const headers = (__token) => {
  return {
    'Authorization': `Token ${__token}`,
    'Content-Type': 'application/json'
  }
}

