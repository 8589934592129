import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import ModalPopperFormStyles from "../Styles/ModalPopperFormStyles";
import { Modal } from "@mui/material";
import Error from "../../../listing/listingTable/ListingExpandableTable/modal/Error";
import LoaderClock from "../../../lodar/LoaderClock";
import {  SupportDataContext } from "../../../../provider/context";
import FollowUpModalForm from "./Followup/FollowUpModalForm";
import CommentModalForm from "./Comments/CommentModalForm";
import RequestFreezeOrderModalForm from "./FreezeLog/RequestFreezeOrderModalForm";
import RequestOrderCompletedModalForm from "./RequestOrderCompleted/RequestOrderCompletedForm";
import UnfreezeRequestOrderModalForm from "./FreezeLog/UnFreezeRequestOrder"
import RescheduleModalForm from "./Followup/RescheduleModalForm";
import RequistionModalForm from "./Requistion/RequistionModalForm";
import SubLeadForm_Support from "./SubLead/SubLeadForm_Support";
import AddEditLead from "./NewLeadInquiry/AddEditLead";
import RequistionEditModalForm from "./Requistion/RequistionEditModalForm";
import AddRequisitionModelForm from "./ViewOrderSOW/AddRequisitionModelForm";
import ViewProjectDescription from "./ProjectList/ViewProjectDescription";
import ViewOrderOnProject from "./ProjectList/ViewOrderOnProject";
import WorkDoneForm from "./ViewOrderSOW/WorkDoneForm";
import ReIssueRequisitionForm from "./ViewOrderSOW/ReIssueRequisitionForm";
import AddReplyCommentForm from "./AddViewComment/AddReplyCommentForm";
import AddNOFFeedbackForm from "./AddViewNOFFeedback/AddNOFFeedbackForm";
import ChangeDoneStatusForm from "./Followup/ChangeDoneStatusForm";
import ClientReviewForm from "./ClientReview/ClientReviewForm";
import OverdueReasonForm from "./OverdueReason/OverdueReasonForm";
import OrderEscalationForm from "./OrderEscalation/OrderEscalationForm";
import ViewCourseDetail from "../../OperationIRT/Modals/TrainingCourse/ViewCourseDetail";
import RequestOrderCancelForm from "./RequestOrderCancelation/RequestOrderCancelForm";


const ModalPopper = () => {
  const {
    modalOpen,
    buttonText,
    error,
    operationInfo
  } = useContext(SupportDataContext);
  return (
    <>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {buttonText === "Add / View Follow-up" ? (
          <FollowUpModalForm />
        ) : buttonText === "Add / View Comments" ? (
          <CommentModalForm />
        ) : buttonText === "Request Freez Order" ? (
          <RequestFreezeOrderModalForm />
        ) : buttonText === "Add Request Order Completion" ? (
          <RequestOrderCompletedModalForm />
        ) : buttonText === "Request Order Cancellation" ? (
          <RequestOrderCancelForm />
        ) : buttonText === "Request Unfreeze Order" ? (
          <UnfreezeRequestOrderModalForm />
        ) : buttonText === "Add Reschedule" ? (
          <RescheduleModalForm />
        )  : buttonText === "Change Status Done" ? (
          <ChangeDoneStatusForm />
        ) : buttonText === "Add / View Requestion" ? (
          <RequistionModalForm />
        ) : buttonText === "Add Requisition" ? (
          <AddRequisitionModelForm />
        ) : buttonText === "Edit Requestion" ? (
          <RequistionEditModalForm />
        ) : buttonText === "Add Sub Lead" ? (
          <SubLeadForm_Support />
        ) : buttonText === "Add/Edit Lead" ? (
          <AddEditLead />
        ) : buttonText === "View Project Description" ? (
          <ViewProjectDescription />
        ) : buttonText === "View Order On Project" ? (
          <ViewOrderOnProject/>
         ) : buttonText === "Work Don Requisition" || buttonText === "Work Don Old Requisition"? (
          <WorkDoneForm/>
         ) : buttonText === "Re-Issue Requisition" || buttonText ===  "Re-Issue Old Requisition"? (
          <ReIssueRequisitionForm/>
         ) : buttonText === "Add Comments" ? (
          <AddReplyCommentForm/>
         ) : buttonText ===  "Add NOF Feedback" ? (
          <AddNOFFeedbackForm/>
         ): buttonText === "Add Client Review" ? (
          <ClientReviewForm getInfo={operationInfo} />
        ) : buttonText === "Add Overdue Order Reason" ? (
          <OverdueReasonForm />
        ) : buttonText === "Add Order Escalation" ? (
          <OrderEscalationForm />
        ) : buttonText === "View Course Detail" ? (
          <ViewCourseDetail />
        ) 
        : buttonText === "error" ? (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        ) : (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        )}
      </Modal>
    </>
  );
};


export default withStyles(ModalPopperFormStyles)(ModalPopper);
