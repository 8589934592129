import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../../../ReceptionListing/ListingDataGrid.css";
import moment from "moment";
import axios from "axios";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { Button } from "react-bootstrap";
import ModalPopper from "../Modals/ModelPopper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useFormik, Formik } from "formik";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
// import ExpandCompaniesListingTab from "../../listingTab/expandCompaniesListingTab";
import { CustomSelect } from "../../../listing/listingForm/ListingForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../commonComp/customInput/CustomInput";
import Badge from "@mui/material/Badge";
import { getTotalLength, handleDefaultFilters } from "../../../../utils/Filters";
import {
  FaFileUpload, FaFileMedical, FaEye, FaSync, FaTimes, FaUserDoctor
} from "react-icons/fa";
import { MdFamilyRestroom, MdOutlineFamilyRestroom } from "react-icons/md";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EditIcon from '@mui/icons-material/Edit';
import CustomIconButton from "../../../../reusableComponents/IconButton";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import { Grid } from "@material-ui/core";
import Select from 'react-select';







const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CompanyInfo = ({
   apiData, 
   tableUpperButtonArr, 
   tabValue,
   ScrollTop,
   fitlerBadge
  }) => {
  const {
    setSelected,
    setEditSaveButton,
    setFieldsEditing,
    setFieldsEditingMain,
    setEditApiData,
    // CompanyList,
    // ServiceList,
    FetchcompanyInfoData1,
  } = useContext(DataContext);
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    getInquiryPiAPI, inquiryPiData,
   
    isOpen, setIsOpen,
    message, setMessage

} = useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const todayDate = moment().format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
const [ServiceList, setServiceList] = useState([]);
const [companyId, getCompanyId] = useState("");
  const [serviceId, getServiceId] = useState("");


  const formik = useFormik({
    initialValues: {
      company: "",
      service: "",
      startDate: "",
      endDate: "",
    },
  });
  // const [startsDate, setstartsDate] = useState("");
  // const [endsDate, setendsDate] = useState("");

  // const handleEvent = (event, picker) => {
  //   setstartsDate(moment(picker.startDate).format("YYYY-MM-DD"));
  //   setendsDate(moment(picker.endDate).format("YYYY-MM-DD"));
  // };
  // const handleCallback = (start, end, label) => {
  
  // };

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  useEffect(() => {
    FetchcompanyInfoData(); 
  }, []);

  const Company_Options = CompanyList?.map((item) => {
      const obj = { value: item.Company_Id, label: item.Company_Name };
      return obj;
    });

  const Service_Options = ServiceList?.map((item) => {
      const obj = { value: item, label: item };
      return obj;
    });

  // const renderExpandableRow = (value) => {
  //   return <ExpandCompaniesListingTab data={value.data} />;
  // };
  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
        <>
            <div>
                
                <CustomIconButton
                    title="View / Add Family"
                    // disabled={
                    //     params.data?.Payment_Status !== "Partial Paid" ? true : false
                    // }
                    icon={
                        <Diversity3Icon
                            onClick={() => {
                              handleModalTableOpen("View / Add Family", params.data);
                               ScrollTop();
                              setOperationInfo(params.data);
                              setButtonText("View / Add Family");
                            }}
                        />
                    }
                />
                <CustomIconButton
                    title="View / Add Worker"
                    // disabled={
                    //     params.data?.Payment_Status !== "Partial Paid" ? true : false
                    // }
                    icon={
                        <EngineeringIcon
                            onClick={() => {
                              handleModalTableOpen("Add Worker", params.data);
                               ScrollTop();
                              setOperationInfo(params.data);
                              setButtonText("Add Worker");
                            }}
                        />
                    }
                />           

              {/* <CustomIconButton
                    title="Edit Company Info"
                    // disabled={
                    //     params.data?.Payment_Status !== "Partial Paid" ? true : false
                    // }
                    icon={
                        <EditIcon
                            onClick={() => {
                              handleModalOpen();
                              // ScrollTop();
                              setOperationInfo(params.data);
                              setButtonText("Edit Company Info");
                            }}
                        />
                    }
                />          */}

           
            </div>
        </>
    );
};
  const columnDefs = [
    {
      headerName: "S #",
      field: "Serial_Number",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        borderLeft: "1px solid #fff",
        textAlign: "center",
      },
      minWidth: 40,
      pinned: "left",
    },
    // {
    //   headerName: "Action",
    //   field: "",
    //   filter: false,
    //   // checkboxSelection: true,
    //   cellRenderer: actionBtnRenderer,
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
    //   minWidth: 150,
    //   pinned: "left",
    // },
    {
      headerName: "Company ID",
      field: "Company_Id",
      filter: "agSetColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
      width: 140,
      pinned: "left",
    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
      width: 200,
      pinned: "left",
      // cellRenderer: (params) => {
      //   return(<>{params?.data?.Company_Id} / {params?.data?.Company_Name}</>)
      // }
    },
    {
      headerName: "Cmp Immigration Card Details",
      field: "Immigration_issue_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      cellRenderer: (params) => {
        return (
          params?.data?.Immigration_issue_date === "" ? "" :
          <>
            Issue Date:{" "}
            {moment(params?.data?.Immigration_issue_date).format("DD-MM-YYYY")}
            <br />
            Expiry Date:{" "}
            {params?.data?.Immigration_Expiry_date}
            <br />
          </>
        );
      },
      filter: "agSetColumnFilter",
      width: 300,
    },
    {
      headerName: "Cmp Labour Card Details",
      field: "Employment_Labour_Card",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      cellRenderer: (params) => {
        return  (
          params?.data?.Employment_Labour_Card === "" ? "" :
          <>
            Card: {params?.data?.Employment_Labour_Card}
            <br />
            Expiry Date:{" "}
            {moment(params?.data?.Employment_Labour_Card_Expiry).format(
              "DD-MM-YYYY"
            )}
            <br />
            Type: {params?.data?.Employment_Labour_Card_Type}
          </>
        );
      },
      filter: "agSetColumnFilter",
      width: 300,
    },
    {
      headerName: "Investor Visa Details",
      field: "Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      cellRenderer: (params) => {
        const Passport_Expiry_Date = !params.data.Investor_Passport_Expiry_Date
          ? ""
          : moment(params.data.Investor_Passport_Expiry_Date)
              .format("DD-MM-YYYY")
              .slice(0, 10);
        const Investor_Visa_Expiry_Date = !params.data.Investor_Visa_Expiry_Date
          ? ""
          : moment(params.data.Investor_Visa_Expiry_Date)
              .format("DD-MM-YYYY")
              .slice(0, 10);
        return params?.data?.Status ? (
          <>
            Status:- {params?.data?.Status}
            <br />
            Client Name: {params?.data?.Investor_Client_Name}
            <br />
            Email Id: {params?.data?.Investor_Email_Id}
            <br />
            Mob No.: {params?.data?.Investor_Mobile}
            <br />
            Nationality: {params?.data?.Investor_Nationality}
            <br />
            Passport No.: {params?.data?.Investor_Passport}
            <br />
            Passport Expiry Date: {params.data.Investor_Passport_Expiry_Date}
            <br />
            Visa Expiry: {params.data.Investor_Visa_Expiry_Date}
            <br />
            Share: {params?.data?.Investor_Number_Of_Shares}
          </>
        ) : (
          <></>
        );
      },
      width: 300,
    },
    {
      headerName: "Employment Visa Details",
      field: "Employment_Emp_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 250,
      cellRenderer: (params) => {
        return params?.data?.Employment_Emp_Name ? (
          <>
            Name: {params?.data?.Employment_Emp_Name}
            <br />
            Mob No.: {params?.data?.Employment_Emp_Mobile}
            <br />
            Email Id: {params?.data?.Employment_Emp_Email}
            <br />
            Basic Salary: {params?.data?.Employment_Emp_Basic_Salary}
            <br />
            Accomodation: {params?.data?.Employment_Emp_Accomodation}
            <br />
            Designation: {params?.data?.Employment_Emp_Designation}
          </>
        ) : (
          <></>
        );
      },
    },
    {
      headerName: "PO Box Details",
      field: "PO_Box_Number",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
        cellRenderer: (params) => {
          const PO_Box_Expiry_Date = !params.data.PO_Box_Expiry_Date
            ? ""
            : moment(params.data.PO_Box_Expiry_Date)
                .format("DD-MM-YYYY")
                .slice(0, 10);
          return params?.data?.PO_Box_Number ? (
            <>
              Number: {params?.data?.PO_Box_Number}
              <br />
              Expiry Date: {PO_Box_Expiry_Date}
              <br />
            </>
          ) : (
            <></>
          );
      },
      width: 150,
    },
    {
      headerName: "Trade Mark Details",
      field: "Trade_Mark_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
         overflowY: "auto",
        },
        cellRenderer: (params) => {
          const Trade_Mark_Expiry_Date = !params.data.Trade_Mark_Expiry_Date
            ? ""
            : moment(params.data.Trade_Mark_Expiry_Date)
                .format("DD-MM-YYYY")
                .slice(0, 10);
          return params?.data?.Trade_Mark_Name ? (
            <>
              Name: {params?.data?.Trade_Mark_Name}
              <br />
              Number: {params?.data?.Trade_Mark_Number}
              <br />
              Expiry Date: {Trade_Mark_Expiry_Date}
              <br />
            </>
          ) : (
            <></>
          );
        },
     
      width: 200,
    },

    {
      headerName: "Point of Contact",
      field: "Point_Of_Contact_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto"
      },
        cellRenderer: (params) => {
          // const Point_Of_Contact_Passport_Expiry_date = !params.data
          //   .Point_Of_Contact_Passport_Expiry_date
          //   ? ""
          //   : moment(params.data.Point_Of_Contact_Passport_Expiry_date)
          //       .format("DD-MM-YYYY")
          //       .slice(0, 10);
          // const Point_Of_Contact_Passport_Number = !params.data
          //   .Point_Of_Contact_Passport_Number
          //   ? ""
          //   : moment(params.data.Point_Of_Contact_Passport_Number)
          //       .format("DD-MM-YYYY")
          //       .slice(0, 10);
          return (
            params?.data?.Point_Of_Contact_Name === "" ? "" :
            <>
              Name: {params?.data?.Point_Of_Contact_Name}
              <br />
              Mob No.: {params?.data?.Point_Of_Contact_Mobile}
              <br />
              Email Id: {params?.data?.Point_Of_Contact_Email}
              <br />
              Nationality: {params?.data?.Point_Of_Contact_Nationality}
              <br />
              Passport No.: {params?.data?.Point_Of_Contact_Passport_Number}
              <br />
              Passport Expiry: {params?.data?.Point_Of_Contact_Passport_Expiry_date}
              <br />
              Primary Contact: {params?.data?.Point_Of_Contact_Primary}
              <br />
            </>
          );
        },
      width: 250,
    },
    {
      headerName: "E-Channel",
      field: "EChanel_Issue_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
         overflowY: "auto",
      },
      cellRenderer: (params) => {
        const EChanel_Issue_Date = !params.data.EChanel_Issue_Date
          ? ""
          : moment(params.data.EChanel_Issue_Date)
              .format("DD-MM-YYYY")
              .slice(0, 10);
        const EChanel_Expiry_Date = !params.data.EChanel_Expiry_Date
          ? ""
          : moment(params.data.EChanel_Expiry_Date)
              .format("DD-MM-YYYY")
              .slice(0, 10);
        return params?.data?.EChanel_Issue_Date ? (
          <>
            Issue Date: {params.data.EChanel_Issue_Date}
            <br />
            Expiry Date: {params.data.EChanel_Expiry_Date}
          </>
        ) : (
          <></>
        );
      },
      width: 150,
    },
    {
      headerName: "RSA Token",
      field: "RSA_Token_Issue_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
         overflowY: "auto",
      },
        cellRenderer: (params) => {
          const RSA_Token_Issue_Date = !params.data.RSA_Token_Issue_Date
            ? ""
            : moment(params.data.RSA_Token_Issue_Date)
                .format("DD-MM-YYYY")
                .slice(0, 10);
          const RSA_Token_Expiry_Date = !params.data.RSA_Token_Expiry_Date
            ? ""
            : moment(params.data.RSA_Token_Expiry_Date)
                .format("DD-MM-YYYY")
                .slice(0, 10);
          return params?.data?.RSA_Token_Issue_Date ? (
            <>
              Issue Date: {params.data.RSA_Token_Issue_Date}
              <br />
              Expiry Date: {params.data.RSA_Token_Expiry_Date}
            </>
          ) : (
            <></>
          );
        },
    
      width: 150,
    },
    {
      headerName: "User Name & Password",
      field: "Username",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
        cellRenderer: (params) => {
          return params?.data?.Username ? (
            <>
              Name: {params?.data?.Username}
              <br />
              Password: {params?.data?.Password}
              <br />
            </>
          ) : (
            <></>
          );
        },
     
      width: 220,
    },
    {
      headerName: "Company Stamp",
      field: "company_Stamp",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 230,
    },
    {
      headerName: "RERA Card Expiry Date",
      field: "RERA_Card_Expiry_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
    },
    // {
    //   headerName: "Created By",
    //   field: "Created_By",
    //   cellStyle: {
    //     borderBottom: "1px solid #fff",
    //     borderRight: "1px solid #fff",
    //   },
    //   width: 150,
    // },
    // {
    //   headerName: "Created Date",
    //   field: "Created_Date",
    //   cellStyle: {
    //     borderBottom: "1px solid #fff",
    //     borderRight: "1px solid #fff",
    //   },
    //   width: 150,
    // },
  ];

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      editable: false,
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;

    if (rowData) {
      setSelected((selected) => [...selected, rowData]);
      setEditSaveButton(false);
      setFieldsEditing(false);
      setFieldsEditingMain(true);
      setEditApiData([]);
    } else {
      setSelected((selected) => selected.filter((e) => e !== rowData));
    }
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };

  const [companyInfoData, setcompanyInfoData] = useState([]);
  const FetchcompanyInfoData = async (data) => {
    gridRef?.current?.api?.showLoadingOverlay();
    const serviceId = data?.service ? data?.service : "";
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let userTypeInf = localStorage.getItem("userType");
      let factory_id = localStorage.getItem("factory_id");

      const url = `https://flyingcolour.online/fcapp/spm_api/companyinfodetails/?factory_id=${factory_id}&emp_id=${empId}&user_type=${userTypeInf}&company_id=${data?.company === undefined ? 0 :data?.company}&service=${serviceId === "" ? "" : serviceId}&service_from_date=${data?.startDate === "" || data?.startDate === undefined ? "" : data?.startDate}&service_to_date=${data?.endDate === "" || data?.endDate===undefined ? "" : data?.endDate}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setcompanyInfoData(res?.data?.results);
      setCompanyList(res?.data?.results?.All_Company_List);
      setServiceList(res?.data?.results?.Service_List);
      gridRef?.current?.api?.hideOverlay();
    } catch (err) {
      gridRef?.current?.api?.hideOverlay();
    }
  };

  console.log("companyInfoData", companyInfoData?.Data_List)


  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

   const filters = [
      {
        id: 0,
        title: "Default",
        value: null,
        key: null,
      }
    ];

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>

<div className="listing-filters" style={{ margin: "1rem" }}>

</div>

      <div className="listing-filters" style={{ margin: "1rem",  marginTop: "50px" }}>
<Formik
        enableReinitialize
        initialValues={{
          company: "",
          service: "",
          startDate: "",
          endDate: "",
        }}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={async (data) => {
          // FetchcompanyInfoData(data);
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors, touched }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
             // justifyContent: "center",
              width: "100%",
              marginTop: "-35px",
            }}
          >
             <Grid container>
             <Grid item sm={12} md={6} style={{ paddingRight: "1rem", }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Select From Date
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setFieldValue(
                      "startDate",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  
                  dateFormat="dd-MM-yyyy"
                  
                 // maxDate={addDays(new Date(), 0)}
                  // minDate={addDays(new Date(), -7)}
                  placeholderText="From Date"
                />
                 
                </div>
              </Col>
            </Form.Group>
            </Grid>
            <Grid item sm={12} md={6} style={{ paddingRight: "1rem", }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Select To Date
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setFieldValue(
                      "endDate",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  
                  dateFormat="dd-MM-yyyy"
                 
                 // maxDate={addDays(new Date(), 0)}
                  // minDate={addDays(new Date(), -7)}
                  placeholderText="To Date"
                />
                 
                </div>
              </Col>
            </Form.Group>
            </Grid>
            </Grid>
            <Grid container>
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Company
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <CustomSelect
                    id="company"
                    value={values.company}
                    onChange={(value) => {
                      setFieldValue("company", value.value);
                      getCompanyId(value?.value);
                    }}
                    options={Company_Options}
                  />
                 </div>
              </Col>
            </Form.Group>
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Service
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <CustomSelect
                    id="service"
                    value={values.service}
                    onChange={(value) => {
                      setFieldValue("service", value.value);
                      getServiceId(value?.value);
                    }}
                    options={Service_Options}
                  />
                 </div>
              </Col>
            </Form.Group>
            </Grid>
     
            {
            values.company || values.service
            || values.service || values.service
             ? (
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#eb6e12",
                  padding: "0 5px",
                  color: "white",
                  height: "fit-content",
                }}
                onClick={()=>{
                  FetchcompanyInfoData(values);
                }}
              >
                Filter
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                disabled
                style={{
                  backgroundColor: "#eb6e12",
                  padding: "0 5px",
                  color: "white",
                  height: "fit-content",
                }}
              >
                Filter
              </Button>
            )}
            </Grid>
          </form>
        )}
      </Formik>
      </div>
      <div style={{ display: "flex", marginLeft: "10px", marginTop: "10px" }}>

        <LeadsFilterAdvancedMain
          tableUpperButtonArr={tableUpperButtonArr}
          apiData={companyInfoData?.Data_List}
          gridRef={gridRef}
          tabValue={tabValue}
          setSavedValue={setSavedValue}
          savedValue={savedValue}
          defaultFilterValue={defaultFilterValue}
        />

         <span
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              color: "#008000",
              marginLeft: "15px",
            }}
          >
            Filters:
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              flexWrap: "wrap",
              color: "#008000",
              marginLeft: "15px",
            }}
          >
            {companyInfoData?.Data_List?.length > 0 &&
              filters.map((val, i) => {
                return (
                  <Badge
                    key={i}
                    badgeContent={getTotalLength(companyInfoData?.Data_List, val.value, val.key)}
                    color="warning"
                    max={9999999}
                    showZero
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                      color: "#fff",
                      marginLeft: "15px",
                      background: "#EB6E12",
                      borderRadius: "20px",
                      padding: "5px 10px",
                      marginBottom: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDefaultFilters(gridRef,setDefaultFilterValue, val.title, val.value, val.key)}
                  >
                    <span>{val.title}</span>
                  </Badge>
                );
              })}
            {savedSearchList?.length > 0 &&
              savedSearchList?.map((val, i) => {
                return (
                  <Badge
                    key={i}
                    color="warning"
                    max={9999999}
                    showZero
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                      color: "#fff",
                      marginLeft: "15px",
                      background: "#EB6E12",
                      borderRadius: "20px",
                      padding: "5px 10px",
                      // marginBottom: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSavedValue(val.Search_Filters);
                      setDefaultFilterValue(val.Search_Name);
                    }}
                  >
                    <span>{val.Search_Name}</span>
                  </Badge>
                );
              })}
          </div>
      </div>
      <br />
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={companyInfoData?.Data_List?.length}
      />
      
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          ref={gridRef}
          rowData={companyInfoData?.Data_List}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
         // detailCellRenderer={renderExpandableRow}
          animateRows={true}
          onSelectionChanged={onRowSelected}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 190}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <ModalPopper />

      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CompanyInfo;
