import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload, FaFileMedical, FaEye, FaSync, FaTimes, FaHandshake, FaIdBadge, FaAdn, FaStopCircle, FaCheckDouble, FaReply
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ApprovalIcon from '@mui/icons-material/Approval';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GradingIcon from '@mui/icons-material/Grading';
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PreviewIcon from '@mui/icons-material/Preview';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { useNavigate } from "react-router-dom";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { getTotalLength, getTotalLengthForSavedFilters } from "../../../../utils/Filters";
import { handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
import CommentIcon from '@mui/icons-material/Comment';
import GavelIcon from "@mui/icons-material/Gavel";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';





const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyAdminOrderListingTable = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop, fitlerBadge
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSalesInfo,
    handleModalOpen,
    message, severity,
    isOpen, setIsOpen,
    // getAdminOrderListgDataApi,
    // adminOrderListgData
    getAdminOrderListgDataApi, adminOrderListgData,
    getOrderDropDownApi, orderDropDown,
    getOrderDropDownForCompany,
    setIsCompleteOrderBtnDisable, setEditModalPopper
  } = useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAdminOrderListgDataApi();
    handleListingSavedSearch();
  }, []);

  console.log("adminOrderListgData", adminOrderListgData)
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };


  const renderExpandableRow = (value) => {
    const row = value?.data;
    return (
      <Table striped bordered hover style={{ width: "96%", marginTop: "5px", borderBottom: "5px", border: "1px solid #fff", fontSize: "12px" }}>
        <thead style={{ border: "1px solid #fff" }}>
          <tr>
            <th>Created By</th>
            <th>Decline Date</th>
            <th>Enquiry Type</th>

            <th>Client Gender</th>
            <th>Converted Date</th>
            <th>Decline Reason</th>
          </tr>
        </thead>

        <tbody style={{ wordWrap: "break-word" }}>
          <tr>
            <td>{row.Created_By}</td>
            <td>{row.Decline_Date}</td>
            <td>{row.enquiry_type}</td>

            <td>{row.Client_Gender}</td>
            <td>{row.Converted_Date}</td>
            <td>{row.Decline_Reason}</td>
          </tr>

        </tbody>
        <thead style={{ border: "1px solid #fff" }}>
          <tr>
            <th>Decline Status</th>
            <th>Client Country Name</th>
            <th>Inquiry Source</th>
            <th>Type of Activity</th>
            <th>Conversation Status</th>
            <th>Enquiry Assigned Date</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{row.Decline_Status}</td>
            <td>{row.Client_Country_Name}</td>
            <td>{row.inquiry_source}</td>
            <td>{row.Type_OF_Activity}</td>
            <td>{row.Conversation_Status}</td>
            <td>{row.Enquiry_Assigned_Date}</td>

          </tr>
        </tbody>
        <thead style={{ border: "1px solid #fff" }}>
          <tr>
            <th>Property Enquiry Type</th>
            <th>Enquiry Referral Source</th>
            <th>Enquiry Re Assigned Date</th>
            <th>Enquiry Referral Source Id</th>
            <th>Alternative Contact Number</th>
            <th>Enquiry Referral Source Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{row.property_enquiry_type}</td>
            <td>{row.Enquiry_Referral_Source}</td>
            <td>{row.Enquiry_Re_Assigned_Date}</td>
            <td>{row.Enquiry_Referral_Source_Id}</td>
            <td>{row.alternative_contact_number}</td>
            <td>{row.Enquiry_Referral_Source_Name}</td>
          </tr>
        </tbody>

        <thead style={{ borderTop: "1px solid #fff", borderRight: "1px solid #fff", borderLeft: "1px solid #fff" }}>
          <tr>
            <th>Campaign Name</th>
            <th>Lead Received Through</th>
            <th>Property Enquiry Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{row.Campaign_Name}</td>
            <td>{row.Lead_Received_Through}</td>
            <td>{row.property_enquiry_type}</td>
          </tr>
        </tbody>

      </Table>
    );
  };
  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };

  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <div>
          {
            params?.data?.Order_Status === "Admin Approval Pending" ?
              <CustomIconButton

                onClick={() => {
                  //handleModalOpen();
                  setButtonText("Initial Order Approval");
                  // scrollToTop()
                  ScrollTop();
                  setOperationInfo(params.data);
                  setIsCompleteOrderBtnDisable(false);

                }}
                title={"Initial Order Approval"}
                icon={<GradingIcon style={{ margin: "0", fontSize: "25px" }} />}
                className="btnActionAlignment"
              />
              :
              <></>
          }
           { 
          params?.data?.old_or_new_order === 0 &&(
            <>
            <CustomIconButton
              title="Freeze Order Approval"
              width={"auto"}
              icon={
                <>
                  <FaStopCircle style={{ margin: "0", fontSize: "19px" }}/>
                </>
              }
              onClick={() => {
                  handleModalTableOpen("Freeze Order Approval", params?.data)
                  setButtonText("Freeze Order Approval");
                  ScrollTop();
                  setOperationInfo(params?.data);
                  if(
                    params.data?.Order_Cancellation_Status === "Approved by Accounts"
                    || params.data?.Order_Cancellation_Status === "Requested by Support"
                  )
                  {
                    setIsCompleteOrderBtnDisable(true);
                  }
                  else
                  {
                    setIsCompleteOrderBtnDisable(false);
                  }   

              }}
              disabled={
                params?.data?.Order_Status === "Admin Approval Pending" ?
                  true : false 
              }
            />
             </>
            )}

          {upperIcons &&
            upperIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    disabled={
                      params?.data?.Order_Status === "Admin Approval Pending" ? true : false
                    }
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params?.data);
                      setButtonText(item.btnText);
                      // scrollToTop()
                      ScrollTop();
                      setOperationInfo(params?.data);
                      if(
                        params.data?.Order_Cancellation_Status === "Approved by Accounts"
                          || params.data?.Order_Cancellation_Status === "Requested by Support"
                      )
                      {
                        setIsCompleteOrderBtnDisable(true);
                      }
                      else
                      {
                        setIsCompleteOrderBtnDisable(false);
                      }   
                    }}
                    title={item.btnText}
                    size="small"
                    style={{
                      border: "1px solid #eb6e12",
                      borderRadius: "5px",
                      fontSize: "12px",
                      marginRight: "6px",
                      color: "#fff",
                      background: "#eb6e12",
                      height: "30px",
                      marginTop: "3px",
                      width: "30px",
                    }}
                    className="btnActionAlignment"
                    icon={item.icon}
                  />
                </>
              );
            })}
          {lowerIcons &&
            lowerIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params?.data);
                      setButtonText(item.btnText);
                      ScrollTop();
                      setOperationInfo(params?.data);
                      if(
                        params.data?.Order_Cancellation_Status === "Approved by Accounts"
                          || params.data?.Order_Cancellation_Status === "Requested by Support"
                      )
                      {
                        setIsCompleteOrderBtnDisable(true);
                      }
                      else
                      {
                        setIsCompleteOrderBtnDisable(false);
                      }   
                    }}
                    title={item.btnText}
                    icon={item.icon}
                    className="btnActionAlignment"
                  />
                </>
              );
            })}
            <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Add Comments", params?.data);
              setButtonText("Add Comments");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params?.data);
              setEditModalPopper(false)
              if( params.data?.Order_Cancellation_Status === "Approved by Accounts"
                   || params.data?.Order_Cancellation_Status === "Requested by Support"
                      )
                      {
                        setIsCompleteOrderBtnDisable(true);
                      }
                      else
                      {
                        setIsCompleteOrderBtnDisable(false);
                      }   
            }}
            title={"Add / View Comments"}
            icon={
              <>
                < CommentIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Request Freez Order", params.data);
              setButtonText("Request Freez Order");
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(true);
            }}
            title={"View Freeze / Unfreeze Reason & Log"}
            icon={<FaFileUpload style={{ margin: "0", fontSize: "17px" }} />}
            className="btnActionAlignment"
          />
          <CustomIconButton
          title={"Review On Order"}
          icon={< AssignmentTurnedInIcon />}
          className="btnActionAlignment"
            onClick={() => {
              handleModalTableOpen("Review On Order", params?.data);
              setButtonText("Review On Order");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params?.data);
              setEditModalPopper(false)
              if(
                  params.data?.Order_Cancellation_Status === "Approved by Accounts"
                  || params.data?.Order_Cancellation_Status === "Requested by Support"
                      )
                      {
                        setIsCompleteOrderBtnDisable(true);
                      }
                      else
                      {
                        setIsCompleteOrderBtnDisable(false);
                      }   
            }}
          />
           <CustomIconButton
            onClick={() => {
              handleModalOpen("View Project Description", params.data);
              setButtonText("View Project Description");
              setEditModalPopper(true);
              setOperationInfo(params.data);
            }}
            title={"Project Description"}
            icon={<VisibilityIcon />}
            className="btnActionAlignment"
          />
            { 
          params?.data?.old_or_new_order === 1 &&(
            <>
            <CustomIconButton
              title="View NOF"
              icon={
                <>
                  <PreviewIcon />
                </>
              }
              onClick={() => {
                if (params?.data?.from_inquiry_or_company === "Company") {
                  setOperationInfo(params?.data);
                  setSalesInfo(params?.data);
                 getOrderDropDownForCompany(params?.data);
                  setButtonText("View NOF Company");
                  // setModalValue("viewforcompany");
                  // setOpenModal(true);
                  handleModalOpen();
                } else {
                  setButtonText("View NOF Inquiry");
                  // setModalValue("viewforinquiry");
                  setSalesInfo(params?.data);
                 getOrderDropDownApi(params?.data);
                 setOperationInfo(params?.data);
                  // setOpenModal(true);
                  handleModalOpen();
                }
              }}
            />

            <CustomIconButton
              title="Dowload NOF"
              icon={
                <>
                  <SimCardDownloadIcon />
                </>
              }
              onClick={() => {
               window.open(params?.data?.NOF_Download_Path, "_blank");
              }}
            />
            </>
           ) }
           <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params?.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params?.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"View Company Document"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Revised NOF Listing", params?.data);
              setButtonText("Revised NOF Listing");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params?.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"Revised NOF Listing"}
            icon={
              <>
                < DriveFileRenameOutlineIcon />
              </>}
            className="btnActionAlignment"
           disabled={data?.Revised_NOF_Exist === "Yes" ? false : true}
          />
           {params?.data?.Order_Status === "Overdue" && (
            <CustomIconButton
              onClick={() => {
                handleModalTableOpen("Overdue Order Reason", params?.data);
                setButtonText("Overdue Order Reason");
                ScrollTop();
                setOperationInfo(params?.data);
                if(
                  params.data?.Order_Cancellation_Status === "Approved by Accounts"
                    || params.data?.Order_Cancellation_Status === "Requested by Support"
                )
                {
                  setIsCompleteOrderBtnDisable(true);
                }
                else
                {
                  setIsCompleteOrderBtnDisable(false);
                }   
              }}
              title={"Overdue Order Reason"}
              icon={
                <>
                  <GavelIcon />
                </>
              }
              className="btnActionAlignment"
            />
          )}
        </div>
      </>
    );
  };
 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate <= filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate >= filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const columnDefs = [
   
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", textAlign: "left", padding: "0", },
      width: 70,
      filter: false,
      pinned: "left",
      cellRenderer: (params) => {
        return(
          <>
          {params?.data?.Revised_NOF_Exist === "Yes" ? <div  style={{
              background: 'blue',
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color:'#fff'
            }}>{params?.data?.Serial_Number}</div> : <div  style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>{params?.data?.Serial_Number}</div>}
           </>
        )
      }
    },
   
    {
      headerName: "Actions",
      field: "",
      filter: false,
      // checkboxSelection: true,
      cellRenderer: actionBtnRenderer,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff",  overflowY: 'auto' },
      minWidth: 300,
      pinned: "left",
    },
    // {
    //   headerName: "Inquiry / Company",
    //   field: "from_inquiry_or_company",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
    //   width: 170,
    //   pinned: "left",
    // },
    {
      headerName: "Order No",
      field: "Order_No",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 180,
      pinned: "left",
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
      // pinned: "left",
    },
    {
      headerName: "Order Sub Type",
      field: "order_sub_type",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      // hide: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", overflowY: 'auto' },
      width: 230,

    },
   
    {
      headerName: "Company Joining Date",
      field: "Company_Joining_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 170,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Company_Joining_Date === "" || params?.data?.Company_Joining_Date === "NA" ? "" :
          moment(params?.data?.Company_Joining_Date).format("DD-MM-YYYY")
          }
          </>
        )
      }
    },
    {
      headerName: "Old / New Order",
      field: "old_or_new_order",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
              <>
                 {params?.data?.old_or_new_order === 1 ? "New" : params?.data?.old_or_new_order === 0 ? "Old" : ""}<br />
               
              </>
          </>
        )
      }
    },
    {
      headerName: "Project Name",
      field: "project_name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", overflowY: 'auto' },
      width: 210,
    },
    {
      headerName: "Order Sequence / No of Order",
      field: "order_sequence",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
              <>
                <span style={{fontWeight: "bold"}}>Order Sequence:</span> {params?.data?.order_sequence}<br />
                <span style={{fontWeight: "bold"}}>No. of Order:</span> {params?.data?.no_of_orders}
              </>
          </>
        )
      }
    },
    {
      headerName: "Latest Overdue Order Reason",
      field: "latest_overdue_order_reason",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", overflowY: 'auto' },
      width: 300,
    },
    {
      headerName: "Order Creation Date",
      field: "Order_Created_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Order_Created_Date === "" || params?.data?.Order_Created_Date === "NA" ? "" :
          moment(params?.data?.Order_Created_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Support Assign Date",
      field: "Support_Assign_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Support_Assign_Date === "" || params?.data?.Support_Assign_Date === "NA" ? "" :
          moment(params?.data?.Support_Assign_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Order_Start_Date === "" || params?.data?.Order_Start_Date === "NA" ? "" :
          moment(params?.data?.Order_Start_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Expected_Complition_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Order_Expected_Complition_Date === "" || params?.data?.Order_Expected_Complition_Date === "NA" ? "" :
          moment(params?.data?.Order_Expected_Complition_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },

    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Followup",
      field: "Last_Followups",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", overflowY: 'auto' },
      width: 270,
      cellRenderer: (params) => {
        params = params?.data?.Last_Followups;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 200,
    },
    {
      headerName: "CM Name",
      field: "Client_Manager_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 200,
      cellRenderer: (params)=>{
       const cmName = params?.data?.Client_Manager_Name.replace(/\s*\(.*?\)/, ""); 
        return(
          <>
          {cmName }
          </>
        )
      }
    },
    {
      headerName: "Advisor Name",
      field: "Consutant_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 200,
      cellRenderer: (params)=>{
        const advisorName = params?.data?.Consutant_Name.replace(/\s*\(.*?\)/, ""); 
        return(
          <>
          {advisorName }
          </>
        )
      }
    },
    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "NA" ? (
              <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
            ) : params?.data?.Freeze_Approval}
          </>
        )
      }
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Complete Order Status Date",
      field: "Complete_Order_Status_Date_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 230,
    },
    {
      headerName: "Complete Order Status Remarks By Admin",
      field: "Complete_Order_Status_Remarks_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 230,
    },
    {
      headerName: "Order Completion Instruction For Admin",
      field: "Order_Completion_Instruction_For_Admin",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", overflowY: 'auto' },
      width: 300,
      cellRenderer: (params) => {
        return(
          <>
            {params?.data?.Order_Completion_Instruction_For_Admin?.map((val) => {
              return(
                <>{val}</>
              )
            })}
          </>
        )
      }
    },
    // {
    //   headerName: "Work in Progress",
    //   field: "Work_in_Progress",
    //   cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", overflowY: 'auto' },
    //   width: 150,
    //   cellRenderer: (params) => {
    //     params = params?.data?.Work_in_Progress;
    //     return (
    //       <p
    //         style={{
    //           padding: "0 5px",
    //           margin: "0 2rem 1rem 0",
    //         }}
    //         dangerouslySetInnerHTML={{ __html: params }}
    //       />
    //     );
    //   },
    // },
    {
      headerName: "State",
      field: "State",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Fz / Offshore / Foundation Name",
      field: "Fz_Offshore_Foundation_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 180,
    },
    {
      headerName: "Group Activity Name",
      field: "Group_Activity_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 180,
    },
    {
      headerName: "Company Age (in Year)",
      field: "Company_Age",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 160,
    },
    {
      headerName: "Revised NOF",
      field: "Revised_NOF_Exist",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
     hide: true
    },
    {
      headerName: "Comments On Order",
      field: "Admin_comment_on_order",
      cellStyle: { borderBottom: "1px solid #fff", border: "1px solid #fff", },
      width: 150,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);



  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    {
      id: 2,
      title: "UnderProcess",
      value: "Underprocess",
      key: "Order_Status",
    },
    {
      id: 3,
      title: "Accounts Rejected",
      value: "Accounts Rejected",
      key: "Order_Status",
    },
    {
      id: 4,
      title: "Freeze Approval Pending",
      value: "Freeze Approval Pending",
      key: "Order_Status",
    },
    {
      id: 5,
      title: "Freezed",
      value: "Order Freezed",
      key: "Order_Status",
    },
    // {
    //   id: 6,
    //   title: "Completion Approval Pending",
    //   value: "Completion Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Admin Approval Pending",
    //   value: "Admin Approval Pending",
    //   key: "Order_Status",
    // },
    {
      id: 7,
      title: "Pending From Accounts",
      value: "Pending From Accounts",
      key: "Order_Status",
    },
    {
      id: 7,
      title: "Overdue",
      value: "Overdue",
      key: "Order_Status",
    },
    {
      id: 8,
      title: "Revised NOF",
      value: "Yes",
      key: "Revised_NOF_Exist",
    },
    {
      id: 9,
      title: "Orders With Comments",
      value: "Yes",
      key: "Admin_comment_on_order",
    },
    {
      id: 10,
      title: "Orders Without Comments",
      value: "No",
      key: "Admin_comment_on_order",
    },
  ];

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  
  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if(data?.detail === "Invalid Token!") {
          localStorage.clear();
          navigate("/login")
          sessionStorage.clear();
          window.location.reload();
        }
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id")
  }

  return (
    <div className="listing-data-grid" style={{ height: "650px" }}>
      {/* <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
         
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
          <CustomDropdown
              label="Select Status to View List:"
              fieldOptions={StatusOptions}
              fieldValue={filterOrders}
              fieldHandleChange={(value) => {
                setFilterOrders(value.value);
                // fetchListingDataFromApi();
              }}
            />
          </Grid>
        
        </Grid>
      </div> */}
      {
        fitlerBadge ? (
          <>
            <div style={{ display: "flex", marginLeft: "10px", marginTop: "30px", paddingBottom: "15px" }}>
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={apiData}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                {apiData?.length > 0 &&
                  filters.map((val, i) => {

                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(apiData, val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: 'Times New Roman'
                        }}
                        onClick={() => handleDefaultFilters(gridRef, setDefaultFilterValue, val.title, val.value, val.key)}
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const keyValue = val.Search_Filter?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")?.split(",")[0].replace("[", "").replace("]", "")
                    const keyss = val.Search_Filter.replace(/(.*?)=(.*?)(?:\|\||$)/g)
                    const getNewKey = keyss?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")
                    const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                    const data = JSON.parse(keyss);
                    let myValuesArr = [];
                    let myKeysArr = [];
                    // Iterate over keys and get values
                    for (const key in data) {
                      if (data.hasOwnProperty(key)) {
                        const values = data[key].values;
                        myValuesArr.push(values)
                        myKeysArr.push(key)
                      }
                    }
                    const valuesArray = [].concat(...myValuesArr);
                    let resultArray = ""
                    if (getNewKe2) {
                      const resultString = getNewKe2[1];
                      resultArray = resultString.split(',');
                    }
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLengthForSavedFilters(apiData, myValuesArr, myKeysArr)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(gridRef, setDefaultFilterValue, val.Search_Name, keyValue?.trim(), val.Search_Filter);
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                          }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>

          </>

        ) : null
      }
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.length}
      />
      <span style={{color: "red", fontSize: "13px", marginLeft: "10px"}}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={adminOrderListgData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          detailCellRenderer={renderExpandableRow}
          columnTypes={columnTypes}
          animateRows={true}
          // onSelectionChanged={onRowSelected}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
          overlayLoadingTemplate={"Please Wait Loading Data..."}
          overlayNoRowsTemplate={"No Data...."}
          // groupDefaultExpanded = {1}
          groupDisplayType="groupRows"
          // groupHideOpenParents={true}
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowGroupHidesColumns={true}
          rowGroupPanelShow="always"
          localeText = "local"
          // groupRowRendererParams={
          //  {
          //   innerRenderer: p => 
          //     ""
          //      + p.value 
          //      +""
          //      + '----(Company Name)'
            
          //  }
          // }
          autoGroupColumnDef= {
           { 
            headerName: "Company Name",
            minWidth: 300,
            cellStyle: { border: "1px solid #fff", borderBottom: "2px solid #fff", fontWeight: "bold"},
            flex: 1,
            // suppresRowCount: true,
            cellRendererParams: {
             
              // suppressCount: true,
            }
          }
          }
        ></AgGridReact>
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} >
        <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyAdminOrderListingTable;
