import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import "../../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../ModelPopper";
import axios from "axios";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../../utils/Filters";
import { FaEye } from "react-icons/fa";
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewOrderOnProjectListTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        handleModalTableOpen,
        setEditModalPopper,
        setButtonText,
        setOperationInfo,
        operationInfo,
        setSelected, setLoading,
        handleModalOpen,
        message, setMessage,
        setSeverity, handlePromise,
        isOpen, setIsOpen, severity,
        getNOFDataListingApi, nofDataListing,
        getTemplateDataAPI,
        getProjectListDropdownapi, projectListDropdown,

    } = useContext(SupportDataContext);

    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);

    useEffect(() => {
        getProjectListDropdownapi();
        handleListingSavedSearch();
    }, []);

    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
                return -1;
            }
            if (dateAsString == "") {
                return -1;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const documentItems = (params) => {
        let data = params?.data.documents;
        return data ? (
            <>
                <Button
                    style={{
                        marginLeft: ".4rem",
                        textDecorationColor: "#eb6e12",
                        background: "none",
                        cursor: "pointer",
                        border: "none",
                        padding: "6px",
                    }}
                    onClick={() => window.open(data)}
                >
                    View Document
                </Button>
            </>
        ) : null;
    };

    const dateFilterParams = {
        inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
                return 0;
            }
            const dateParts = dateAsString.slice(0, 10).split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const columnDefs = [
        {
            headerName: "Order Sequence",
            field: "order_sequence",
            // cellRenderer: "agGroupCellRenderer",
            // checkboxSelection: true,
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
            minWidth: 130,
            pinned: "left",
            filter: false
        },
        {
            headerName: "Actions",
            field: "Inquiry_Id",
            filter: false,
            // checkboxSelection: true,
            cellRenderer:
                (params) => {
                    return (
                        <>
                            <CustomIconButton
                                title="View SOW"
                                icon={
                                    <FaEye
                                        onClick={() => {
                                            handleModalOpen();
                                            setButtonText("View Order On Project");
                                            //  ScrollTop();
                                            setEditModalPopper(true);
                                            setOperationInfo(params.data);
                                            //  getAdminCompalinFormDataApi(params.data)
                                        }}
                                    />
                                }
                            />
                            <CustomIconButton
                            width={"auto"}
                                title="Revised NOF"
                                icon={
                                    <>
                                    {"Revised NOF"}
                                    </> }
                                onClick={() => {
                                    getTemplateDataAPI();
                                    setTimeout(() => {
                                        setOperationInfo(params.data);
                                        setButtonText("Revised NOF Listing");
                                    }, 1000)
                                }}
                                disabled={params.data?.Order_Cancellation_Status === "Approved by Accounts"
                                    || params.data?.Order_Cancellation_Status === "Requested by Support"}
                            />
                            {/* <CustomIconButton
                title="Edit"
                icon={
                  <EditIcon
                    onClick={() => {
                      getTemplateDataAPI();
                      setTimeout(() => {
                        setEditModalPopper(true);
                        setOperationInfo(params.data);
                        setButtonText("Edit NOF Order Form");
                      }, 1000)
                  
                    //  getAdminCompalinFormDataApi(params.data)
                    }}
                  />
                }
                disabled={params?.data?.Accounts_Status === "Approval Pending" || params?.data?.Accounts_Status === "rejected" ? false : true}
              />
        <CustomIconButton
                title="Update SOW"
                icon={
                  <DriveFileRenameOutlineIcon
                    onClick={() => {
                      getTemplateDataAPI();
                      setTimeout(() => {
                        setEditModalPopper(true);
                        setOperationInfo(params.data);
                        setButtonText("Update NOF SOW");
                      }, 1000)
                  
                    //  getAdminCompalinFormDataApi(params.data)
                    }}
                  />
                }
               // disabled={params?.data?.Accounts_Status === "accepted" ? false : true}
              /> */}
                        </>
                    );
                },
            cellStyle: { border: "1px solid #fff", overflowY: 'auto' },
            minWidth: 170,
            pinned: "left",
        },
        {
            headerName: "Order No",
            field: "Order_No",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 190,
            pinned: "left",
        },
        {
            headerName: "Order Name",
            field: "Order_Name",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 350,
        },
        {
            headerName: "Company Name",
            field: "Company_Name",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 250,
            pinned: "left",
        },
        {
            headerName: "State",
            field: "State",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 130,
        },
        {
            headerName: "Formation Type",
            field: "Formation",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 150,
            cellRenderer: (params) => {
                params = params?.data?.Formation;
                return (
                    <p
                        style={{
                            padding: "0 5px",
                            margin: "0 2rem 1rem 0",
                        }}
                        dangerouslySetInnerHTML={{ __html: params }}
                    />
                );
            },
        },
        {
            headerName: "Order Type",
            field: "Type_Of_Order",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Order Created Date",
            field: "Order_Created_Date",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Order Sub Type",
            field: "order_sub_type",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Order Status",
            field: "Order_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Account Approval Status",
            field: "Accounts_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
            minWidth: 200,
        },
        {
            headerName: "Connected or Not",
            field: "order_connected_or_not",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 170,
        },
        {
            headerName: "Typist Name",
            field: "Typist_Name",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Support Name",
            field: "Support_Emp_Name",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Order Template",
            field: "order_template",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Tag Name",
            field: "Tag_Names",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
            cellRenderer: (params) => {
                params = params?.data?.Tag_Names;
                return (
                    <p
                        style={{
                            padding: "0 5px",
                            margin: "0 2rem 1rem 0",
                        }}
                        dangerouslySetInnerHTML={{ __html: params }}
                    />
                );
            },
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            filter: true,
            // enable floating filters by default
            floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            //flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };



    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    useEffect(() => {
        const parsedSavedValue = JSON.parse(savedValue);
        if (parsedSavedValue !== null) {
            let values = Object.keys(parsedSavedValue);
            values.map((val, i) => {
                if (parsedSavedValue[val].filterType === "text") {
                    gridRef.current.api.getFilterInstance(val).setModel({
                        type: "contains",
                        filter: parsedSavedValue[val].filter,
                    });
                } else {
                    gridRef.current.api
                        .getFilterInstance(val)
                        .setModel({ values: parsedSavedValue[val].values });
                }
            });
            gridRef.current.api.onFilterChanged();
        }
    }, [savedValue]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

    const handleListingSavedSearch = async () => {
        const __token = localStorage.getItem("token");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        await fetch(
            `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${__token}`,
                    "Content-Type": "application/json",
                },
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSavedSearchList(data.results?.Response);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className="listing-data-grid" style={{ height: "500px" }}>

            <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >

                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={projectListDropdown}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    columnTypes={columnTypes}
                    animateRows={true}
                    components={components}
                    onSelectionChanged={onRowSelected}
                    rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
                    getRowHeight={() => 90}
                    pagination={true}
                    detailRowAutoHeight={true}
                    onPaginationChanged={onPaginationChanged}
                ></AgGridReact>
            </div>
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ViewOrderOnProjectListTable;
