export const handleDefaultFilters = (gridRef, setDefaultFilterValue, title, value, key) => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    }  else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date")?.setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if(typeof value === 'object') {
      gridRef.current.api
      .getFilterInstance(key.toString())
      .setModel({ values: [...value] });
    gridRef.current.api.onFilterChanged();
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())?.setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  }

  export const handleFilters = (gridRef, setDefaultFilterValue,title, value, key) => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      const filterKey = JSON.parse(key);
      Object.keys(filterKey)?.map((val) => {
        gridRef.current.api
        .getFilterInstance(val)
        .setModel({ values: filterKey[val].values });
     
      })
      gridRef.current.api.onFilterChanged();
    }
    setDefaultFilterValue(title);
  };

  export const getTotalLength = (apiData,value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return apiData?.length;
    } else if (key === null) {
      return apiData?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else if(typeof value === 'object') {
        let count = [];
        value?.map((val) => {
          let values = apiData?.filter((vals) => vals[key] === val);
          count.push(...values) 
        });

        return count.length;
    }  else {
      return apiData?.filter((val) => val[key] == value).length;
    }
  };
  export const getTotalLengthForSavedFilters = (apiData, value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return apiData?.length;
    } else if (key === null) {
      return apiData?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      const filteredData = apiData?.filter(function (item) {
        if(key?.length > 1)
        {
        return key.every((key, index) => value[index]?.includes(item[key]))
          // return key.every((key, index) => item[key] === value[index]);
        }
        else{
          return key.every((key, index) => value[index]?.includes(item[key]))
        }
        // return value.includes(item?.[key]);
      });
      return filteredData?.length
    }
  }; 
 

  export const getTotalLengthFilter = (apiData,value, key) => {
    const filterKey = JSON.parse(key);
    if (apiData === null || (key === null && value === null)) {
      return;
    } else if (key === null) {
      return apiData?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      let count = [];
      for (const [key, value] of Object.entries(filterKey)) {
        value?.values?.map((value) => {
          let values = apiData?.filter((vals) => vals[key] == value);
          count.push(...values)
        })
      }

      let uniqueArray = count?.filter(function(item, pos) {
        return count.indexOf(item) == pos;
    })
      return uniqueArray?.length;
    }
  };