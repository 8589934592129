const SubleadTableStyles = () => ({
  subleadFormGroupFlexDivModal: {
    display: "flex",
    marginBottom: "6px",
    width: "100%",
  },
  requierdFieldMsg: {
    color: "red",
    marginBottom: "-2px",
  },
  subleadModalHeaderIconCancle: {
    float: "right",
    marginRight: "-25px !important",
    marginTop: "-3px !important",
  },
  subleadLabelFormInputModal: {
    width: "50%",
    fontWeight: " 600!important",
    marginTop: "6px",
    marginBottom: 0,
    fontSize: "13px",
    position: "relative",
    top: "-1px",
  },
  subleadSelectContainerModal: {
    fontSize: "12px",
    float: "right",
    marginRight: "2px",
    width: "50%",
  },

  subleadDemoDataCheckModal: {
    border: "0.1px solid #d3d3d3",
    display: "grid",
    float: "right",
    width: "100%",
  },
  subleadListingFormInputMainModal: {
    border: "none",
    float: "inherit",
    padding: "4.5px 10px",
    fontFamily: " Times New Roman ",
  },

  subleadButtonDivModal: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "-10px",
    marginRight: "-16px",
  },
  subleadModalHeader: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "5px",
    textTransform: "capitalize",
  },
  subleadModalHeadingContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px ",
    borderTop: "1px solid #eb6e12",
    borderBottom: "1px solid #eb6e12",
    backgroundColor: "#eb6e12",
  },
  subleadModalFlexDivContaner: {
    display: "-webkit-box",
    flexFlow: "row-reverse wrap",
  },
  subleadInnerGridLeft: {
    padding: "0px 10px",
    paddingTop: "3px",
  },
});
export default SubleadTableStyles;
