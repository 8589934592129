import bgPic from "../../../../commonComp/assets/burjKhalifaListingImg3.jpg";
import bgPic1920 from "../../../../commonComp/assets/burjKhalifaListingImg4.jpg";


const TabStyles = (theme) => ({
  listingTabContainerDiv: {
    padding: "0 15px",
  },
  indicator: {
    backgroundColor: "#eb6e12 !important",
    top: "0px",
  },
  tabStyleListing: {
    backgroundColor: "#fff !important",
    border: "1px solid #e3e3e3 !important",
    fontSize: "11px !important",
    fontWeight: "bold !important",
    fontFamily: "Times New Roman !important",
  },
  tabStyleListing1: {
    backgroundColor: "#fff !important",
    border: "1px solid #e3e3e3 !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
    width: "210px !important",
    fontFamily: "Times New Roman !important",
  },
  tabStyleListingOthers: {
    backgroundColor: "#fff !important",
    borderRight: "1px solid #e3e3e3 !important",
    borderTop: "1px solid #e3e3e3 !important",
    borderBottom: "1px solid #e3e3e3 !important",
    fontSize: "11px !important",
    fontWeight: "bold !important",
  },
  selected: {
    color: "#eb6e12  !important",
    borderBottom: "1px solid #fff !important",
  },
  selected1: {
    backgroundColor: "rgb(235, 110, 18) !important",
    color: "white !important",
    borderBottom: "1px solid #fff !important",
  },
  TabLowerContainerDiv: {
    border: "1px solid #e3e3e3 !important",
    zIndex: "1",
    marginTop: "-2px",
    backgroundColor: "#fff",
  },
  ButtonDivListingTab: {
    padding: "10px !important",
  },
  NewButton: {
    backgroundColor: "#eb6e12 !important",
    fontFamily: "Times New Roman !important",
  },

  BorderSpacingUpper: {
    width: "100%",
    borderTop: "5px solid #eb6e12 ",
  },
  BorderSpacingLower: {
    width: "100%",
    borderBottom: "5px solid #eb6e12 ",
  },
  backImg: {
    backgroundImage: `url(${bgPic})`,
    minHeight: "408px",
    "@media screen and (min-width: 1450px) and (max-width: 3000px)": {
      backgroundImage: `url(${bgPic1920})`,
    },
  },
  ImgHeadingContainer: {
    opacity: "2",
    width: "50%",
    transition: "all .2s ease-in",
    fontSize: "15px",
    padding: "25px",
    backgroundColor: "rgba(255, 255, 255, .85)",
    position: "absolute",
    top: "68%",
    left: "25%",
    textAlign: "center",
    transform: " translate(-50 %, -50 %)",
    "@media screen and (min-width: 1450px) and (max-width: 3000px)": {
      top: "40%",
    },
  },
  ImgHeading: {
    marginBottom: "15px",
  },
  tabListListing: {
    display:'flex',
    flexWrap:'wrap',
    "&:hover": {
      color: "red !important",
    },
  },
});
export default TabStyles;
