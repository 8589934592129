import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import { FaEye, FaPlus } from "react-icons/fa";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
  import {
    DataContext,
    SupportDataContext,
  } from "../../../../../provider/context";
  import "../../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import EditIcon from "@mui/icons-material/Edit";
  import { getLocalData } from "../../../../../services/services";
  import axios from "axios";
  import { Grid } from "@material-ui/core";
  import { Button } from "@mui/material";
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const AddScopeOfWorkOrderSubTemplateListing = ({
    apiData,
    upperIcons,
    lowerIcons,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setEditModalPopper,
      message,
      severity,
      isOpen,
      setIsOpen,
      operationInfo,
      setFilterOrders,
      handleModalTableClose,
      getSowListingDataApi,
      sowList,
      templateOperationInfo
    } = useContext(SupportDataContext);
  
    const gridRef = useRef();
   
  
    useEffect(() => {
      getSowListingDataApi();
    }, [ templateOperationInfo?.Service_Id ]);
  
  
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    const actionBtnRenderer = (params) => {
      return (
        <>
          <CustomIconButton
            onClick={() => {
              setOperationInfo(params?.data);
              setEditModalPopper(true);
              handleModalTableOpen("Add SOW Order Sub Template Form", params.data);
              setButtonText("Add SOW Order Sub Template Form");
            }}
            title={"Edit SOW Template Form"}
            icon={<EditIcon />}
            className="btnActionAlignment"
          />
        </>
      );
    };
  
    const columnDefs = [
      {
        headerName: "Sr.",
        field: "Serial_Number",
        //cellRenderer: "agGroupCellRenderer",
        //checkboxSelection: true,
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          textAlign: "center",
        },
        width: 75,
        pinned: "left",
        filter: false,
      },
      {
        headerName: "Actions",
        field: "",
        filter: false,
        // checkboxSelection: true,
        cellRenderer: actionBtnRenderer,
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        minWidth: 140,
      },
      {
        headerName: "Description",
        field: "Task_Details",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        minWidth: 170,
        flex: 1,
        cellRenderer: (params) => {
          return(
            <div dangerouslySetInnerHTML={{
              __html: params?.data?.Task_Details,
            }}  />
          )
        }
      },
      {
        headerName: "Remarks",
        field: "remarks",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        minWidth: 170,
        flex: 1,
      },
       {
        headerName: "Status",
        field: "active_status_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue.split(" ");
              if (dateAsString == null) {
                return 0;
              }
              const dateParts = dateAsString[0].split("-");
              const day = Number(dateParts[2]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
          },
        },
      };
    }, []);
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
        <Grid container>
          <Grid item sm={12} md={8} xs={12} style={{ paddingRight: "1rem" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#eb6e12",
              fontFamily: "Times New Roman !important",
              color: "#fff",
              width: "380px",
              minHeight: "37px",
              margin: "10px 0 0 10px",
            }}
           
          >
           {templateOperationInfo?.Service_Name }
          </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#eb6e12",
                fontFamily: "Times New Roman !important",
                color: "#fff",
                width: "260px",
                height: "37px",
                margin: "10px 0 0 10px",
              }}
              onClick={() => {
                handleModalTableOpen("Add SOW Order Sub Template Form");
                setButtonText("Add SOW Order Sub Template Form");
                setEditModalPopper(false);
                
              }}
            >
              <FaPlus style={{ marginRight: "10px" }} />
              Create New Description
            </Button>
            <Button
              onClick={() => {
                handleModalTableClose();
                // handleHide()
              }}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#fff",
                color: "#eb6e12",
                margin: "10px 0 0 10px",
                height: "37px",
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={apiData?.length}
        />
        <div
          style={{ height: "400px", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
            ref={gridRef}
            rowData={sowList?.Service_Task_List}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            // onSelectionChanged={onRowSelected}
            components={components}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            rowHeight={90}
            pagination={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default AddScopeOfWorkOrderSubTemplateListing;
  