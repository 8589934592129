import React, { useContext } from "react";
import { withStyles } from "@material-ui/core";
import previewFormStyles from "../../Preview/previewStyle";
import { Box, Grid, IconButton } from "@mui/material";
import { DataContext, SupportDataContext } from "../../../../../provider/context"
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/styles.css";
import moment from "moment";
const ViewOfGroupCompanyYes = ({ classes, setOpenModal, isSupportModule }) => {
    const {
        salesInfo,
        getOrderDropDownForCompany,
        orderDropDown,
        businessInfo,
        setOrderDropDown,
        setEditView, setEditCard
    } = useContext(DataContext);
    const {
        handleModaleClose,
    } = useContext(SupportDataContext);
   
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        bgcolor: "#fff",
        border: "2px solid #eb6e12",
        boxShadow: 10,
        p: 4,
        height: "auto",
        paddingTop: "0px",
        maxHeight: "590px",
        overflowY: "scroll",
    };
   

    return (
        <>
            {
            orderDropDown?.Order_Details?.length >= 0 ?
                <div className={classes.divModal}>
                  
                    <Box sx={style}>
                    <div
                  style={{ height: "36px", boxShadow: "0 3px 6px #0000001c" }}
                >
                        <IconButton
                            style={{ backgroundColor: "#fffffff0", float: "right" }}
                            className={classes.headerIconCancle}
                            onClick={() => {
                                    handleModaleClose();
                                    setOrderDropDown([]);
                               }
                            }
                        >
                            <CloseIcon
                                className={classes.headerIcon}
                                style={{ marginTop: "0px", marginBottom: "-40px", fontSize: "25px" }}
                            />
                        </IconButton>
                   </div>
                        <Grid container spacing={2} columns={12}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginBottom: "-30px",
                                    paddingBottom: "10px",
                                }}
                            >
                                <br />
                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"View of New Order Form (NOF)"}
                                    </span>
                                </div>
                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"New Order Form Detail"}{" "}
                                    </span>
                                </div>
                                <div
                                    style={{ marginBottom: "10px" }}
                                    className={classes.followUpFormModalFlexDivContaner}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        > 
                                       <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            NOF Id
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.lastCellData}
                                        >
                                            {salesInfo?.new_order_form_id || businessInfo?.new_order_form_id}
                                        </Grid>
                                        <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Order Name 
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                               {businessInfo?.order_title}
                                            </Grid>
                                       
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        > 
                                       <Grid
                                            xs={3}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            Company Name
                                        </Grid>
                                        <Grid
                                                xs={9}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                               {orderDropDown?.Company_detail[0]?.Company_Name}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                State 
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                                {businessInfo?.state_name}

                                            </Grid>

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                              Formation Type
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                               {businessInfo?.formation_type}
                                            </Grid>
                            
                                        </Grid>
                                       
                                    </Grid>
                                </div>
                            
                         
                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"Scope of Work"}
                                    </span>
                                </div>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex" }}
                                  className={classes.followUpFormInnerGridLeft}
                                                    >
                                  <Grid xs={6}
                                      style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                      className={classes.cellData}>
                                      Scope of Work
                                  </Grid>
                                  <Grid xs={3}
                                      style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                      className={classes.cellData}>
                                      Quantity
                                  </Grid>
                                  <Grid xs={4}
                                      style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                      className={classes.lastCellData}>
                                      Description
                                  </Grid>
                                  <Grid xs={4}
                                      style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                      className={classes.cellData}>
                                      Expiry
                                  </Grid>
                                  <Grid xs={4}
                                      style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                      className={classes.lastCellData}>
                                      Priority
                                  </Grid>

                              </Grid> 
                                {orderDropDown?.Order_Details[0]?.length !== 0 && (
                                    <>
                                        {orderDropDown?.Order_Details[0]?.hr_scope_of_work_details?.map((i) => {
                                            return (
                                                <>
                                                  <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid xs={6}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW === "" || i?.SOW === undefined ? "No Data" : i?.SOW}
                                                        </Grid>
                                                       
                                                        <Grid xs={3}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW_Quantity}
                                                        </Grid>
                                                       
                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW_Description}
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {moment(i.Expiry).format("DD-MM-YYYY")}
                                                        </Grid>
                                                       
                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.Priority}
                                                        </Grid>
                                                    </Grid> 
                                           
                                                </>
                                            )
                                        })}

                                    </>
                                )
                                }

                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"Upload Document Detail"}{" "}
                                    </span>
                                </div>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >
                                    <Grid xs={4}
                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                        className={classes.cellData}>
                                        Upload Type
                                    </Grid>
                                    <Grid xs={4}
                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                        className={classes.cellData}>
                                        Document Particular
                                    </Grid>
                                    <Grid xs={4}
                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                        className={classes.lastCellData}>
                                        Document/File
                                    </Grid>
                                </Grid>
                                {
                                    orderDropDown?.Order_Details[0]?.upload_doc_details.map((i) => {
                                        const docType = orderDropDown.Document_Type.find(
                                            (n) => n.Id === parseInt(i?.Document_Type)
                                        );
                                 
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid xs={4}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    {docType?.Doc_Name === "" || docType?.Doc_Name === undefined ? "No Data" : docType?.Doc_Name}
                                                </Grid>
                                                <Grid xs={4}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    {i.Particular === "" || i.Particular === undefined ? "No Data" :  i.Particular}
                                                </Grid>
                                                <Grid xs={4}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                        {
                                                        i.uploadDoc === "" || i.uploadDoc === undefined ? "No Data" :
                                                    <button className="btn-view" onClick={() => window.open(i.uploadDoc)}>View</button>
                                                        }
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* Missing document  */}
                                {
                                    orderDropDown?.Order_Details[0]?.missing_documents.length !== 0 && (
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    {"Missing Document Detail"}{" "}
                                                </span>
                                            </div>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid xs={6}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    Upload Type
                                                </Grid>
                                                <Grid xs={6}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                    Document Particular
                                                </Grid>
                                            </Grid>
                                            {
                                                orderDropDown?.Order_Details[0]?.missing_documents.map((i) => {
                                                    const docType = orderDropDown.Document_Type.find(
                                                        (n) => n.Id === parseInt(i.Document_Type)
                                                    );
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid xs={6}
                                                                style={{ height: "auto", textAlign: "center" }}
                                                                className={classes.cellData}>
                                                                {docType?.Doc_Name === "" || docType?.Doc_Name === undefined ? "No Data" : docType?.Doc_Name}
                                                            </Grid>
                                                            <Grid xs={6}
                                                                style={{ height: "auto", textAlign: "center" }}
                                                                className={classes.lastCellData}>
                                                                {i.Particular === "" || i.Particular === undefined ? "No Data" : i.Particular}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                
                                

                                <br />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                : null}
        </>
    );
};

export default withStyles(previewFormStyles)(ViewOfGroupCompanyYes);