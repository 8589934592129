import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaAdn,
  FaStop,
  FaStopCircle,
  FaCheckDouble, FaReply
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ApprovalIcon from '@mui/icons-material/Approval';
import GradingIcon from '@mui/icons-material/Grading';
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PreviewIcon from '@mui/icons-material/Preview';
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import { useFormik, Formik } from "formik";
import LoaderClock from "../../../lodar/LoaderClock";
import {
  Modal,
  Box,
} from '@mui/material'
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader';
import CommentIcon from '@mui/icons-material/Comment';
import CustomDateRangePicker from "../../../../reusableComponents/CustomDatePicker/CustomDateRangePicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';




const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RoOrderListTable = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  fitlerBadge
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    setAcceptAccountArray,
    getPropertyFormDataApi,
    message, severity,
    isOpen, setIsOpen,
    getOrderDropDownApi,
    getOrderDropDownForCompany,
    getAdminCompletedOrderListgDataApi, 
    adminCompletedOrderListgData,
    setIsCompleteOrderBtnDisable,
    setSalesInfo, loading,
    getPdOrderListDataApi, pdOrderListData
  } = useContext(SupportDataContext);

  const {
    start_Date, 
    end_Date
  } = useContext(DataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const todayDate = moment().format("YYYY-MM-DD");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [supportName, setSupportName] = useState("");



  const dateOptions = [
    { value: "Creation_Date", label: "Creation Date" },
    { value: "Approval_Date", label: "Approval Date" },
    { value: "Request_Completion_Date", label: "Request Completion Date" },
  ]

  const typist_name_options = pdOrderListData?.Support_Emp_List?.map((item) => {
    const obj = { value: item.EmpId, label: item.Emp_Name, id: item?.EmpId, type: item?.UserType };
    return obj;
});

  useEffect(() => {
    handleListingSavedSearch();
  }, []);

  useEffect(() => {
    getPdOrderListDataApi(supportName, start_Date, end_Date);
  }, []);
console.log("pdOrderListData", pdOrderListData);

  const ScrollTop = () => {
    window.scrollTo({
      top: -40,
      left: 0,
      behavior: "smooth",
    });
  };
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };


  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <div>
          {/* {
            params.data.Order_Status === "Admin Approval Pending" ?
              <CustomIconButton

                onClick={() => {
                  //handleModalOpen();
                  setButtonText("Initial Order Approval");
                  // scrollToTop()
                  ScrollTop();
                  setOperationInfo(params.data);
                  setIsCompleteOrderBtnDisable(true);

                }}
                title={"Initial Order Approval"}
                icon={<GradingIcon style={{ margin: "0", fontSize: "25px" }} />}
                className="btnActionAlignment"
              />
              :
              <></>
          } */}
          {/* {
            params?.data?.old_or_new_order === 0 && (
              <>
                <CustomIconButton
                  title="Freeze Order Approval"
                  width={"auto"}
                  icon={
                    <>
                      <FaStopCircle style={{ margin: "0", fontSize: "19px" }} />
                    </>
                  }
                  onClick={() => {
                    handleModalTableOpen("Freeze Order Approval", params.data)
                    setButtonText("Freeze Order Approval");
                    ScrollTop();
                    setOperationInfo(params.data);
                    setIsCompleteOrderBtnDisable(true);

                  }}
                  disabled={
                    params.data.Order_Status === "Admin Approval Pending" ?
                      true : false
                  }
                />
              </>
            )} */}

          {upperIcons &&
            upperIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    disabled={
                      params.data.Order_Status === "Admin Approval Pending" ?
                        item.btnText === "Completion Order Approval" ? true : false : false
                    }
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params.data);
                      setButtonText(item.btnText);
                      // scrollToTop()
                      ScrollTop();
                      setOperationInfo(params.data);
                      setIsCompleteOrderBtnDisable(true);
                    }}
                    title={item.btnText}
                    size="small"
                    style={{
                      border: "1px solid #eb6e12",
                      borderRadius: "5px",
                      fontSize: "12px",
                      marginRight: "6px",
                      color: "#fff",
                      background: "#eb6e12",
                      height: "30px",
                      marginTop: "3px",
                      width: "30px",
                    }}
                    className="btnActionAlignment"
                    icon={item.icon}
                  />
                </>
              );
            })}
          {lowerIcons &&
            lowerIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params.data);
                      setButtonText(item.btnText);
                      ScrollTop();
                      setOperationInfo(params.data);
                      setIsCompleteOrderBtnDisable(true);
                    }}
                    title={item.btnText}
                    icon={item.icon}
                    className="btnActionAlignment"
                  />
                </>
              );
            })}
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Add Comments", params.data);
              setButtonText("Add Comments");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setEditModalPopper(false)
              setIsCompleteOrderBtnDisable(true);
            }}
            title={"Add / View Comments"}
            icon={
              <>
                < CommentIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
          {
            params?.data?.old_or_new_order === 1 && (
              <>
                <CustomIconButton
                  title="View NOF"
                  width={"auto"}
                  icon={
                    <>
                      <PreviewIcon />
                    </>
                  }
                  onClick={() => {
                    if (params?.data?.from_inquiry_or_company === "Company") {
                      setOperationInfo(params.data);
                      setSalesInfo(params?.data);
                      getOrderDropDownForCompany(params.data);
                      setButtonText("View NOF Company");
                      // setModalValue("viewforcompany");
                      // setOpenModal(true);
                      handleModalOpen();
                    } else {
                      setButtonText("View NOF Inquiry");
                      // setModalValue("viewforinquiry");
                      setSalesInfo(params?.data);
                      getOrderDropDownApi(params.data);
                      setOperationInfo(params.data);
                      // setOpenModal(true);
                      handleModalOpen();
                    }
                  }}
                />

                <CustomIconButton
                  title="Dowload NOF"
                  width={"auto"}
                  icon={
                    <>
                      <SimCardDownloadIcon />
                    </>
                  }
                  onClick={() => {
                    window.open(params?.data?.NOF_Download_Path, "_blank");
                  }}
                />

              </>
            )}
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(true);
            }}
            title={"View Company Document"}
            width={"auto"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
           <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Revised NOF Listing", params?.data);
              setButtonText("Revised NOF Listing");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params?.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"Revised NOF Listing"}
            icon={
              <>
                < DriveFileRenameOutlineIcon />
              </>}
            className="btnActionAlignment"
           disabled={data?.Revised_NOF_Exist === "Yes" ? false : true}
          />
        </div>
      </>
    );
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const onlyDate = cellValue.split(" ");
      const dateParts = onlyDate[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      // Now that both parameters are Date objects, we can compare
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };
  var filterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };
  

  const columnDefs = [
    {
        headerName: "Sr. No.",
        valueGetter: "node.rowIndex + 1",
        // cellRenderer: "agGroupCellRenderer",
        // checkboxSelection: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 100,
        pinned: "left",
        filter: false
    },
    {
        headerName: "Order No",
        field: "Order_No",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
        width: 190,
        pinned: "left",
    },
    {
        headerName: "Order Name",
        field: "Order_Name",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 350,
    },
    {
        headerName: "Company Name",
        field: "Company_Name",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
        width: 250,
        pinned: "left",
    },
    {
        headerName: "State",
        field: "State",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 130,
    },
    {
        headerName: "Formation Type",
        field: "Formation",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 150,
        cellRenderer: (params) => {
            params = params?.data?.Formation;
            return (
                <p
                    style={{
                        padding: "0 5px",
                        margin: "0 2rem 1rem 0",
                    }}
                    dangerouslySetInnerHTML={{ __html: params }}
                />
            );
        },
    },
    {
        headerName: "Order Type",
        field: "Type_Of_Order",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Order Created Date",
        field: "Order_Created_Date",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Order Sub Type",
        field: "order_sub_type",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Order Status",
        field: "Order_Status",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Account Approval Status",
        field: "Accounts_Status",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
        minWidth: 200,
    },
    {
        headerName: "Connected or Not",
        field: "order_connected_or_not",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 170,
    },
    {
        headerName: "Typist Name",
        field: "Typist_Name",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Support Name",
        field: "Support_Emp_Name",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Order Template",
        field: "order_template",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
    },
    {
        headerName: "Tag Name",
        field: "Tag_Names",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
        width: 200,
        cellRenderer: (params) => {
            params = params?.data?.Tag_Names;
            return (
                <p
                    style={{
                        padding: "0 5px",
                        margin: "0 2rem 1rem 0",
                    }}
                    dangerouslySetInnerHTML={{ __html: params }}
                />
            );
        },
    },
];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,

    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);



  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    // {
    //   id: 1,
    //   title: "Default",
    //   value: null,
    //   key: null,
    // },
    {
      id: 4,
      title: "On-Time Clousre",
      value: "Yes",
      key: "Closure_On_Time",
    },
    {
      id: 5,
      title: "Overdue Clousre",
      value: "No",
      key: "Closure_On_Time",
    },
    {
      id: 2,
      title: "Completed In First Attempt",
      value: "Yes",
      key: "Order_completed_in_first_attempt",
    },
    {
      id: 3,
      title: "Not Completed In First Attempt",
      value: "No",
      key: "Order_completed_in_first_attempt",
    },
    // {
    //   id: 4,
    //   title: "Freeze Approval Pending",
    //   value: "Freeze Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 5,
    //   title: "Freezed",
    //   value: "Order Freezed",
    //   key: "Order_Status",
    // },
    // {
    //   id: 6,
    //   title: "Completion Approval Pending",
    //   value: "Completion Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Admin Approval Pending",
    //   value: "Admin Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Pending From Accounts",
    //   value: "Pending From Accounts",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Overdue",
    //   value: "Overdue",
    //   key: "Order_Status",
    // },
  ];


  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleFilters = (title, value, key) => {

    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };


  const getTotalLength = (value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return;
    } else if (key === null) {
      return apiData.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      return apiData.filter((val) => val[key] === value).length;
    }
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id")
  }

  const override = css`
                  display: block;
                  margin: 0 auto;
                  border-color: '#eb6e12';
                  box-shadow: inset '0px 0px 0px 7px #eb6e12';
                  margin-top: 10%;
                  `;
  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
 <div className="listing-filters"  style={{ padding: "1rem", background: "#fff", marginTop: "50px" }}>
    <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-35px",
              }}
            >
            <Grid container>
             <Grid item sm={12} md={3} style={{ paddingRight: "1rem", }}>
             <CustomDropdown
                  label="Select RO Name:"
                  fieldOptions={typist_name_options}
                  fieldValue={supportName}
                  fieldHandleChange={(value) => {
                    setSupportName(value.value);
                  }}
                />
            </Grid>
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
              Order Creation Date:
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
              <div style={{marginTop: "0px"}}>
             
                  <CustomDateRangePicker />
                  </div>
              </Col>
            </Form.Group>
            </Grid>
            <Button
                variant="contained"
                size="small"
                onClick={()=>{
                  getPdOrderListDataApi(supportName, start_Date, end_Date);

                }}
                style={{
                  backgroundColor: "#eb6e12",
                  padding: "4px",
                  color: "white",
                  height: "fit-content",
                }}
                disabled={
                   supportName === "" || supportName === undefined
                  || start_Date === "" || start_Date === undefined
                  || end_Date === "" || end_Date === undefined
                }
              >
                Filter
              </Button>
            </Grid>

           

            </form>
          )}
        </Formik>
        </div>
      <>
       
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={pdOrderListData?.Support_Order_List?.length}
        />
       
        <span style={{color: "red", fontSize: "13px", marginLeft: "10px"}}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>

        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
            <AgGridReact
              suppressExcelExport={true}
              suppressCsvExport={true}
              ref={gridRef}
              rowData={pdOrderListData?.Support_Order_List}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              masterDetail={true}
              //detailCellRenderer={renderExpandableRow}
              columnTypes={columnTypes}
              animateRows={true}
              // onSelectionChanged={onRowSelected}
              components={components}
              detailCellRendererParams={paramsExpendableRows}
              rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
              rowHeight={90}
              pagination={true}
              detailRowAutoHeight={true}
              onPaginationChanged={onPaginationChanged}
              overlayLoadingTemplate={"Please Wait Loading Data..."}
              overlayNoRowsTemplate={"No Data...."}
              enableCellChangeFlash={true}
              groupDisplayType="groupRows"
              // groupHideOpenParents={true}
              suppressDragLeaveHidesColumns={true}
              suppressMakeColumnVisibleAfterUnGroup={true}
              suppressRowGroupHidesColumns={true}
              rowGroupPanelShow="always"
             
            ></AgGridReact>
          )}
        </div>
        <ModalPopper />
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} >
          <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </>


    </div>
  );
};

export default RoOrderListTable;
