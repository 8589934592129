import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import "../../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../ModelPopper";
import axios from "axios";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../../utils/Filters";
import { FaEye } from "react-icons/fa";
import ChangeOrderSequenceLogTable from "./ChangeOrderSequenceLogTable";
import SubjectIcon from '@mui/icons-material/Subject';
import LoaderClock from "../../../../lodar/LoaderClock";




const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChangeOrderSequenceTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        handleModalTableOpen,
        setEditModalPopper,
        setButtonText,
        setOperationInfo,
        operationInfo,
        setSelected, setLoading,
        loading,
        handleModalOpen,
        message, setMessage,
        setSeverity, handlePromise,
        isOpen, setIsOpen, severity,
        getProjectWiseOrdersDataAPi, projectWiseOrders,
        getTemplateDataAPI,
        setRowData
    } = useContext(SupportDataContext);

    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);

    useEffect(() => {
        getProjectWiseOrdersDataAPi();
    }, [operationInfo]);
    
console.log(projectWiseOrders);

    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
                return -1;
            }
            if (dateAsString == "") {
                return -1;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const renderExpandableRow = (value) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                marginLeft: "4px",
                width: "99%",
                height: "301px",
                minHeight: "40px",
                overflowX: "scroll",
                overflowY: "scroll",
                border: "1px solid white",
              }}
            >
             <ChangeOrderSequenceLogTable  />
            </div>
          </>
        );
      };

    

    const columnDefs = [
        {
            headerName: "Sr.#",
            field: "Serial_Number",
            // cellRenderer: "agGroupCellRenderer",
            // checkboxSelection: true,
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
            minWidth: 90,
            pinned: "left",
            filter: false
        },
        {
            headerName: "Actions",
            field: "Inquiry_Id",
            filter: false,
            // checkboxSelection: true,
            cellRenderer:
                (params) => {
                return (
                 <>
                    {/* <CustomIconButton
                         title="Change NOF Sequence"
                         icon={
                         <EditIcon
                             onClick={() => {
                            getTemplateDataAPI();
                             setTimeout(() => {
                                setEditModalPopper(true);
                                setOperationInfo(params.data);
                                 setButtonText("Edit Order Sequence");
                             }, 2000)
                             }}
                         />
                         }
                     disabled={
                            params?.data?.Accounts_Status?.toLowerCase() !== "accepted" 
                            && params?.data?.Order_Request_To_Complete_Status?.toLowerCase() === "not requested" 
                            && params?.data?.Order_Initiate_Status?.toLowerCase() === "no" ? false : true
                        }
                     /> */}
                      <CustomIconButton
                         title="View NOF Sequence Log"
                         icon={
                         <SubjectIcon
                         onClick={() => {
                            setRowData(params.data);
                            if (params.node.expanded === false) {
                              renderExpandableRow(params, "orderSequence");
                              params.node.setExpanded(true);
                            } else {
                              params.node.setExpanded(false);
                            }
                          }}
                        //  onClick={() => {
                        //     if (params.node.expanded === false) {
                        //       if (params.data.handleview === 1) {
                        //         setRowData(params.data);
                        //       } else {
                        //         params.node.setExpanded(true);
                        //         setRowData(params.data);
                        //       }
                        //     } else {
                        //       params.node.setExpanded(false);
                        //     }
                        //     //setButtonText("View Detail");
                        //     setRowData(params.data);
                        //     //setEditForm(true);
                        //     //handleModalOpen();
                        //   }}
                         />
                         }
                     
                     />
               </>
              );
         },
            cellStyle: { border: "1px solid #fff", overflowY: 'auto' },
            minWidth: 120,
            pinned: "left",
        },
        {
            headerName: "Order No",
            field: "Order_No",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 210,
            pinned: "left",
        },
        {
            headerName: "Order Name",
            field: "Order_Name",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Accounts Status",
            field: "Accounts_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 170,
        },
        {
            headerName: "Account Initilize Status",
            field: "Order_Initiate_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 170,
        },
        {
            headerName: "Connected or Not",
            field: "order_connected_or_not",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 170,
        },
        {
            headerName: "Order Sequence",
            field: "order_sequence",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 160,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {params?.data?.order_sequence === 0 ? "Not Applicable" : params?.data?.order_sequence}
        
                    </>
                  </>
                )
              }
        },
        {
            headerName: "Order Start With Sequence",
            field: "Order_StartsWith",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 160,
            cellRenderer: (params) => {
                return (
                  <>
                    <>
                      {params?.data?.Order_StartsWith === 0 && params?.data?.order_connected_or_not === "Connected"
                       ? "Not Applicable" : params?.data?.Order_StartsWith}
        
                    </>
                  </>
                )
              }
        },
        {
            headerName: "Request to Complete Status",
            field: "Order_Request_To_Complete_Status",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Support Name",
            field: "Support_Emp_Name",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
        },
        {
            headerName: "Order Created Date",
            field: "Order_Creation_Date",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            width: 200,
            // cellRenderer: (params) => {
            //     return (
            //       <>
            //         <>
            //           {moment(params?.data?.Order_Creation_Date).format("DD-MM-YYYY HH:mm:ss a")}
        
            //         </>
            //       </>
            //     )
            //   }
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            filter: true,
            // enable floating filters by default
            floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            //flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };



    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    useEffect(() => {
        const parsedSavedValue = JSON.parse(savedValue);
        if (parsedSavedValue !== null) {
            let values = Object.keys(parsedSavedValue);
            values.map((val, i) => {
                if (parsedSavedValue[val].filterType === "text") {
                    gridRef.current.api.getFilterInstance(val).setModel({
                        type: "contains",
                        filter: parsedSavedValue[val].filter,
                    });
                } else {
                    gridRef.current.api
                        .getFilterInstance(val)
                        .setModel({ values: parsedSavedValue[val].values });
                }
            });
            gridRef.current.api.onFilterChanged();
        }
    }, [savedValue]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

    const handleListingSavedSearch = async () => {
        const __token = localStorage.getItem("token");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        await fetch(
            `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${__token}`,
                    "Content-Type": "application/json",
                },
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSavedSearchList(data.results?.Response);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className="listing-data-grid" style={{ height: "590px" }}>
            <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={projectWiseOrders?.Project_Order_List}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    columnTypes={columnTypes}
                    masterDetail={true}
                    animateRows={true}
                    components={components}
                    onSelectionChanged={onRowSelected}
                    rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
                    getRowHeight={() => 90}
                    pagination={true}
                    detailRowAutoHeight={true}
                    onPaginationChanged={onPaginationChanged}
                    detailCellRenderer={renderExpandableRow}

                ></AgGridReact>
            </div>
           
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ChangeOrderSequenceTable;
