import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import LoaderClock from "../../../../lodar/LoaderClock";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";


const RequisitionLogTable = ({ classes }) => {
  const {
    requistionListing,
    loading,
    handleModalOpen,
    setViewRequistion,
    operationInfo,
    getRequistionListingDataFromApi,
    setEditModalPopper,

    requisitionLogData,
    getRequisitionLogListDataApi

  } = useContext(SupportDataContext);
  useEffect(() => {
    getRequistionListingDataFromApi();
    getRequisitionLogListDataApi();
  }, [operationInfo.Order_Id]);
  const gridRef = useRef();
  const [columnDefs] = useState([
    // {
    //   headerName: "S #",
    //   field: "Serial_Number",
    //   filter: "agTextColumnFilter",
    //   width: 60,
    //   maxWidth: 90,
    //   pinned: "left",
    //   cellStyle: { border: "1px solid #fff", textAlign: "center" },
    // },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   filter: false,
    //   width: 100,
    //   maxWidth: 100,
    //   cellStyle: {
    //     border: "1px solid #fff",
    //     paddingTop: 0,
    //     textAlign: "center",
    //   },
    //   cellRenderer: (params) => {
    //     return (
    //       <CustomIconButton
    //         title="edit"
    //         disabled={
    //           params.data.Is_Alert_Done === "Yes" ? true : false
    //         }
    //         icon={
    //           <EditIcon
    //             onClick={() => {
    //               handleModalOpen();
    //               setEditModalPopper(true);
    //               setViewRequistion(params.data);
    //             }}
    //           />
    //         }
    //       />
    //     );
    //   },
    //   pinned: "left",
    // },
    
    {
        headerName: "Log Id",
        field: "Log_Id",
        filter: "agTextColumnFilter",
        width: 150,
        maxWidth: 150,
        pinned: "left",
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
        headerName: "Requisition No",
        field: "Requisition_No",
        filter: "agTextColumnFilter",
        width: 200,
        maxWidth: 200,
        pinned: "left",
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
    {
      headerName: "Requested By",
      field: "Requisition_Requested_By",
      filter: "agTextColumnFilter",
      width: 160,
      maxWidth: 200,
    //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Requisition Date",
      field: "createdDate",
      filter: "agDateColumnFilter",
      width: 150,
      maxWidth: 230,
    //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Status",
      field: "Status",
      filter: "agTextColumnFilter",
      width: 150,
      maxWidth: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Remarks",
      field: "Remarks",
      filter: "agTextColumnFilter",
    //   flex:1,
      width: 250,
      maxWidth: 280,
      cellStyle: { border: "1px solid #fff", textAlign: "center"},
    },
    {
      headerName: "Task Amount",
      field: "Task_Amount",
    //   filter: "agDateColumnFilter",
      width: 150,
      maxWidth: 160,
    },
    {
      headerName: "Assigned By",
      field: "Assigned_By",
      filter: "agTextColumnFilter",
      width: 170,
      maxWidth: 180,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
        headerName: "Assigned To",
        field: "Assigned_To",
        filter: "agTextColumnFilter",
        width: 170,
        maxWidth: 180,
        cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 400,
      editable: false,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <LoaderClock loading={loading} />
      <TableContainer component={Paper}>
            <div
            className="ag-theme-alpine"
            style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
          >
            <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
              ref={gridRef}
              rowData={requisitionLogData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowHeight={()=>90}
              rowStyle={{
                background: "#DEDEDE",
                color: "#000000DE",
                borderBottom: "1px solid white",
              }}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
      </TableContainer>
      <Error  />
    </div>
  );
};

export default withStyles(modalTableStyles)(RequisitionLogTable);
