import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, Modal, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import AddScopeOfWorkForm from "../TemplateCreation/AddScopeOfWorkForm";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import FinalizeNOFOrder from "./FinalizeNOFOrder";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddOrEditProjectList from "../ProjectList/AddOrEditProjectList";
import CustomUploadFile from "../../../../../reusableComponents/CustomUploadFile/CustomUploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "350px",
  maxHeight: "350px",

  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddNOFOrderForm = ({ classes }) => {
  const {
    setButtonText,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getNOFDataListingApi,
    getTemplateCreationListgDataApi,
    templateCreationList,
    templateList,
    getNOFDataApi,
    setOperationInfo,
  } = useContext(SupportDataContext);

  var today = new Date();
  const currentDate = moment(today).format("YYYY-MM-DD");
  const [formationTypeValue, setFormationTypeValue] = useState(null);
  const [orderIdValue, setOrderIdValue] = useState(null);
  const [stateIdValue, setStateIdValue] = useState(null);
  const [licenseTypeValue, setLicenseTypeValue] = useState(null);
  const [activityTypeValue, setActivityTypeValue] = useState(null);
  const [freezoneValue, setFreezoneValue] = useState(null);
  const [offshoreValue, setOffshoreValue] = useState(null);
  const [legalTypeValue, setLegalTypeValue] = useState(null);
  const [proFormationTypeValue, setPROFormationTypeValue] = useState(null);
  const [dedfzoffTypeValue, setDedFzOffTypeValue] = useState(null);
  const [proServicesName, setProServicesName] = useState(null);
  const [sowList, setSowList] = useState([]);
  const [sowSubTemplateList, setSowSubTemplateList] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectetOrderTemplate, setSelectedOrderTemplate] = useState([]);
  const [selectetOrderSubTemplate, setSelectedOrderSubTemplate] = useState([]);
  const [isDefaultValueChecked, setIsDefaultValueChecked] = useState(false);
  const [
    isDefaultValueCheckedSubTemplate,
    setIsDefaultValueCheckedSubTemplate,
  ] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isSaveAndContinue, setIsSaveAndContinue] = useState(true);
  const [isProjectDisabled, setIsProjectDisable] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showProjectForm, setShowProjectForm] = useState(true);

  const addOperationNOFOrderFormPOSTPUTApi = async (values, projectDetails) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": 'multipart/form-data',
    };
    const formData = new FormData();
    let allOrderDetails = values?.mainTaskArray?.map((val, index) => {
      const templateValues = val?.selectedTemplate?.map((value) => {
        return value?.label;
      });
      const uploadDocDetails = val?.uploadDocument?.map((value, i) => {
        formData.append(`order_${val?.order_sequence}_upload_docs_${i}`, value.uploadDoc)
        return {
          Document_Type: value?.documentType,
          Particular: value?.particular
        }
      });

      let obj = {
        order_id: val?.orderId || "",
        order_name: val?.orderName || "",
        type_of_order: val?.orderType || "",
        order_sub_type: val?.orderSubType || "",
        state_id: val?.state || "",
        formation_type: val?.formationType || "",
        legal_type: val?.legalType || "",
        activity_type: val?.activityType || "",
        license_type_id: val?.licenseType || "",
        formation_name_id: val?.freezoneName || "",
        formation_name_offshore: val?.offShoreName || "",
        sub_formation_type: val?.proServices || "",
        formation_name_pro: val?.dedFzOffshore || "",
        pro_services_name: val?.proServiceName || "",
        support_emp_id: values?.supportId || "",
        typist_emp_id: val?.typistId,
        scope_of_work: JSON.stringify(val?.scopeOfWork) || [],
        order_connected_or_not: val?.orderConnected || "",
        order_finalize: val?.order_finalize || "",
        order_sequence: val?.order_sequence,
        order_template: JSON.stringify(templateValues),
        order_start_with: val?.order_start_with,
        upload_doc_details: JSON.stringify(uploadDocDetails),
        number_of_upload_docs: val?.uploadDocument?.length
      };

      return obj;
    });


    formData.append('emp_id', Number(emp_id));
    formData.append('user_type', user_type);
    formData.append('company_id', Number(values?.companyId));
    formData.append('project_id', Number(projectDetails?.project_id));
    formData.append('project_name', projectDetails?.project_name);
    formData.append('inquiry_or_company_order_id', operationInfo?.new_order_form_id);
    formData.append('company_inquiry_flag', operationInfo?.inquiry_id ? 1 : 2);
    formData.append('all_order_details', JSON.stringify(allOrderDetails));
    formData.append('group_company', operationInfo?.Group_company);


    if (operationInfo?.inquiry_id) {
      formData.append('inquiry_id', operationInfo?.inquiry_id);
    }

    axios.post(url, formData, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500);
      } else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code);
        setOperationInfo(projectDetails);
        setLoading(false);
        setIsOpen(true);
        getNOFDataListingApi(projectDetails?.project_id);
        getNOFDataApi();
        setButtonText("NOF Order Form Table");
      }
    });
  };

  const [orderFormCompanyDropdown, setOrderFormCompanyDropdown] = useState([]);
  const getOrderFormonCompanyData = async (
    orderType,
    stateId,
    formationId,
    licenseType,
    activityType,
    freezoneName,
    offShoreName,
    legalType,
    proFormationTYpe,
    dedFzOffShoreName,
    proServiceName,
    orderId
  ) => {
    setOpenLoading(true);
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/?company_inquiry_flag=${operationInfo?.inquiry_id ? 1 : 2
        }`;
      if (orderId) {
        url = `${url}&order_id=${operationInfo?.Order_Id}`;
      }
      if (orderType) {
        url = `${url}&order_type=${orderType}`;
      }
      if (stateId) {
        url = `${url}&state_id=${stateId}`;
      }
      if (formationId) {
        url = `${url}&formation_type_id=${formationId}`;
      }
      if (
        (formationId === 1 && licenseType) ||
        (formationId === 2 && licenseType)
      ) {
        url = `${url}&license_type=${licenseType}`;
      }
      if (
        (formationId === 1 && activityType) ||
        (formationId === 2 && activityType) ||
        (formationId === 3 && activityType)
      ) {
        url = `${url}&activity_type=${activityType}`;
      }
      if (formationId === 2 && freezoneName) {
        url = `${url}&freezone_name=${freezoneName}`;
      }
      if (formationId === 3 && legalType) {
        url = `${url}&legal_type=${legalType}`;
      }
      if (formationId === 3 && offShoreName) {
        url = `${url}&offshore_name=${offShoreName}`;
      }
      if (formationId === 4 && proFormationTYpe) {
        url = `${url}&pro_formation_type=${proFormationTYpe}`;
      }
      if (formationId === 4 && dedFzOffShoreName) {
        url = `${url}&ded_fz_offshore_name=${dedFzOffShoreName}`;
      }
      if (formationId === 4 && proServiceName) {
        url = `${url}&pro_services_name=${proServiceName}`;
      }

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderFormCompanyDropdown(res?.data?.Data);
      setOrderId(res?.data?.Data?.Order_Id);
      setInitialLoad(false);
      setOpenLoading(false);
      if (editModalPopper && initialLoad) {
        setOrderIdValue(res?.data?.Data?.Order_Detail?.[0]?.Type_Of_Order);
        setStateIdValue(res?.data?.Data?.Order_Detail?.[0]?.state_id);
        setFormationTypeValue(
          Number(res?.data?.Data?.Order_Detail?.[0]?.ftype)
        );
        setLicenseTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.license_type_id
        );
        setActivityTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.activity_type_id
        );
        setFreezoneValue(res?.data?.Data?.Order_Detail?.[0]?.freezone_name_id);
        setOffshoreValue(
          Number(res?.data?.Data?.Order_Detail?.[0]?.offshore_name_id)
        );
        setLegalTypeValue(
          Number(res?.data?.Data?.Order_Detail?.[0]?.legal_type_id)
        );
        setPROFormationTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.formation_name_pro
        );
        setDedFzOffTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.formation_name_offshore
        );
        setProServicesName(
          res?.data?.Data?.Order_Detail?.[0]?.pro_services_name
        );
        selectedTemplateValue(res?.data?.Data);
        selectedSubtemplateValue(res?.data?.Data);
      }

      //setLoading(false);
    } catch (err) {
      setOpenLoading(false);
      console.error(err);
    }
  };

  const states =
    orderFormCompanyDropdown.Master_State !== undefined
      ? orderFormCompanyDropdown?.Master_State.map((i) => ({
        id: i.StateIds,
        value: i.StateIds,
        label: `${i.StateName}`,
      }))
      : [];

  const companyList =
    orderFormCompanyDropdown.Company_List !== undefined
      ? orderFormCompanyDropdown?.Company_List.map((i) => ({
        id: i.Company_Id,
        value: i.Company_Id,
        label: `${i.Company_Name}`,
      }))
      : [];

  const orderTypeDropdown =
    orderFormCompanyDropdown.Order_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Type.map((i) => ({
        id: i,
        value: i,
        label: `${i}`,
      }))
      : [];

  const formationType =
    orderFormCompanyDropdown.Master_type_of_company !== undefined
      ? orderFormCompanyDropdown?.Master_type_of_company.map((i) => ({
        id: i.Formation_type_id,
        value: i.Formation_type_id,
        label: `${i.Formation_type}`,
      }))
      : [];

  const licenseTypes =
    orderFormCompanyDropdown.License_Type !== undefined
      ? orderFormCompanyDropdown?.License_Type.map((i) => ({
        id: i.Ids,
        value: i.Ids,
        label: `${i.Name}`,
      }))
      : [];
  const legalTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Legal_Type !== undefined
      ? orderFormCompanyDropdown?.Legal_Type.map((i) => ({
        id: i.Ids,
        value: i.Ids,
        label: `${i.Name}`,
      }))
      : [];
  const activityGroupTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.Activity_Group !== undefined
      ? orderFormCompanyDropdown?.Activity_Group.map((i) => ({
        id: i.Ids,
        value: i.Ids,
        label: `${i.Name}`,
      }))
      : [];
  const freezoneTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.Freezone_Name !== undefined
      ? orderFormCompanyDropdown?.Freezone_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];
  const offshoreTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.Offshore_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];
  const proFormationTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.PRO_Formation_Type !== undefined
      ? orderFormCompanyDropdown?.PRO_Formation_Type.map((i) => ({
        value: i,
        label: `${i}`,
      }))
      : [];
  const ded_Fz_OfforeTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.DED_FZ_Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.DED_FZ_Offshore_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];
  const proServiceNames =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.PRO_Service_Name !== undefined
      ? orderFormCompanyDropdown?.PRO_Service_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];

  const orderSubTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Order_Sub_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Sub_Type.map((i) => ({
        id: i.Serial_Number,
        value: `${i.order_sub_type}`,
        label: `${i.order_sub_type}`,
      }))
      : [];

  const tagNames =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.Tag_Name_List !== undefined
      ? orderFormCompanyDropdown?.Tag_Name_List.map((i) => ({
        id: i.Tag_Id,
        value: i.Tag_Id,
        label: `${i.Tag_Name}`,
      }))
      : [];

  const typistList =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Typist_List !== undefined
      ? orderFormCompanyDropdown?.Typist_List.map((i) => ({
        id: i.EmpId,
        value: i.EmpId,
        label: `${i.Emp_Name}`,
      }))
      : [];
  const documentTypeList =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Document_Type !== undefined
      ? orderFormCompanyDropdown?.Document_Type.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Doc_Name}`,
      }))
      : [];

  const templateDropdown = [];
  templateList?.Service_Template_List?.map((i) => {
    let tasksList = [...i?.Task_List]?.reverse();
    if (i?.SOW_Exist === "Yes") {
      templateDropdown.push({
        id: i.Service_Id,
        value: `${i.Service_Id}`,
        label: `${i.Service_Name}`,
        details: i?.Service_Details,
        taskList: tasksList,
      });
    }
  });

  const templateListDropdown = templateDropdown || [];

  const templateSubOrderListDropdown =
    templateList?.Service_Sub_Template_List?.length !== 0 &&
      templateList?.Service_Sub_Template_List !== undefined
      ? templateList?.Service_Sub_Template_List?.map((i) => ({
        id: i.Service_Id,
        value: `${i.Service_Id}`,
        label: `${i.Service_Name}`,
        details: i?.Service_Details,
        taskList: i?.Task_List,
      }))
      : [];
  const projectList =
    templateCreationList?.length !== 0 &&
      templateCreationList?.Project_List !== undefined
      ? templateCreationList?.Project_List.map((i) => ({
        id: i.project_id,
        value: i.project_id,
        label: `${i.project_name} (${i?.company_name})`,
        supportId: i.support_id,
        supportName: i?.support_name,
        companyName: i?.company_name,
        companyId: i?.company_id,
        project_name: i?.project_name,
        subOrderTemplateName: i?.scope_work_template_name_for_sub_template,
        taskList: i?.scope_of_work_for_sub_template,
      }))
      : [];

  const taskSectionDropdown = templateCreationList?.Single_Multi_Work_Type?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const taskTypeDropdown = templateCreationList?.Task_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Task_Type_Name, label: item.Task_Type_Name };
      return obj;
    }
  );

  const taskCategoryDropdown = templateCreationList?.Task_Category_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const workTypeDropdown = templateCreationList?.Work_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const govtDeptDropdown = templateCreationList?.Govt_Dept_List?.map(
    (item, i) => {
      const obj = { value: item.Govt_Dept_Name, label: item.Govt_Dept_Name };
      return obj;
    }
  );

  useEffect(() => {
    getTemplateCreationListgDataApi();
  }, []);

  useEffect(() => {
    if (editModalPopper) {
      setIsDefaultValueChecked(true);
      setIsDefaultValueCheckedSubTemplate(true);
      getOrderFormonCompanyData(
        orderIdValue,
        stateIdValue,
        formationTypeValue,
        licenseTypeValue,
        activityTypeValue,
        freezoneValue,
        offshoreValue,
        legalTypeValue,
        proFormationTypeValue,
        dedfzoffTypeValue,
        proServicesName,
        true
      );
    } else {
      getOrderFormonCompanyData(
        orderIdValue,
        stateIdValue,
        formationTypeValue,
        licenseTypeValue,
        activityTypeValue,
        freezoneValue,
        offshoreValue,
        legalTypeValue,
        proFormationTypeValue,
        dedfzoffTypeValue,
        proServicesName,
        false
      );
    }
  }, [
    orderIdValue,
    formationTypeValue,
    stateIdValue,
    licenseTypeValue,
    activityTypeValue,
    freezoneValue,
    offshoreValue,
    legalTypeValue,
    proFormationTypeValue,
    dedfzoffTypeValue,
    proServicesName,
  ]);

  const removeDuplicateObjects = (array, property) => {
    const uniqueArray = [];

    array.forEach((object) => {
      const existingObject = uniqueArray.find(
        (item) => item[property] === object[property]
      );

      if (!existingObject) {
        uniqueArray.push(object);
      }
    });

    return uniqueArray;
  };

  const selectedTemplateValue = (data) => {
    setInitialLoad(true);
    let templateList = [];
    let finalTaskList = [];
    data?.Order_Detail?.[0]?.scope_of_work?.forEach((item) => {
      let findValue = templateListDropdown?.find(
        (val) => Number(val.id) === Number(item.Template_Id)
      );
      let findExistValue = templateList.find(
        (val) => Number(val.id) === Number(item.Template_Id)
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Template_Service_Name: item?.Name,
          Task_Type_Name: item?.Task_Type,
          Task_Category: item?.Task_Category,
          Nos_Task_Days: item?.Days,
          Task_Sequence: Number(item?.Sequance),
          Task_Amount: item?.Amount,
          Govt_Dept_Name: item?.Govt_Department,
          Special_Instruction: item?.Special_Instruction,
          Single_Multi_Task_Type: item?.Task_Section,
          Task_Details: item?.Task_Detail,
          Work_Type: item?.Work_Type,
          Tag_Id: item?.Tag_Id,
          Tag_Name: item?.Tag_Name,
          Template_Id: item?.Template_Id,
          Template_Name: item?.Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
        };

        finalTaskList.unshift(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          templateList.unshift(findValue);
        }
      }
    });

    // let filteredValue = removeDuplicateObjects(templateList, "Service_Task_Id");
    setSowList(data?.Order_Detail?.[0]?.scope_of_work);
    setSelectedOrderTemplate(templateList);
    setInitialLoad(false);
  };

  const selectedSubtemplateValue = (data) => {
    setInitialLoad(true);
    let selecSubTemplates = [];
    let finalTaskList = [];
    data?.Order_Detail?.[0]?.scope_of_work_for_sub_template?.forEach((item) => {
      let findValue = templateSubOrderListDropdown?.find(
        (val) => val.label === item.Sub_Template_Name
      );
      let findExistValue = selecSubTemplates.find(
        (val) => val.label === item.Sub_Template_Name
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Task_Details: item?.Description,
          Sub_Template_Id: item?.Sub_Template_Id,
          Sub_Template_Name: item?.Sub_Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
        };
        finalTaskList.unshift(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          selecSubTemplates.unshift(findValue);
        }
      }
    });

    //let filteredValue = removeDuplicateObjects(selecSubTemplates, "Service_Task_Id");

    setSowSubTemplateList(
      data?.Order_Detail?.[0]?.scope_of_work_for_sub_template || []
    );
    setSelectedOrderSubTemplate(selecSubTemplates);
    setInitialLoad(false);
  };
  const [orderNOFListDropdown, setOrderNOFListDropdown] = useState([]);
  const handleSaveAndContine = (arrayHelpers, mainTaskArray) => {
    let orderConc =
      mainTaskArray[mainTaskArray?.length - 1]?.orderConnected ===
        "Not Connected"
        ? "Not Connected"
        : "Connected";
    let connectedOrders = mainTaskArray?.filter((item) => item?.orderConnected === "Connected");

    let mainTaskArrayDropdown = connectedOrders?.map((val) => {
      return {
        id: val?.order_sequence,
        value: val?.order_sequence,
        label: val?.orderName,
      };
    });
    setOrderNOFListDropdown(mainTaskArrayDropdown);
    arrayHelpers.push({
      orderId: orderFormCompanyDropdown?.Order_Id,
      orderName: "",
      orderType: "",
      orderSubType: "",
      state: "",
      formationType: "",
      proServices: "",
      dedFzOffshore: "",
      proServiceName: "",
      freezoneName: "",
      offShoreName: "",
      legalType: "",
      licenseType: "",
      activityType: "",
      selectedTemplate: "",
      selectedTemplateId: "",
      scopeOfWork: [],
      typistId: "",
      orderConnected: orderConc,
      order_finalize: "",
      order_sequence: mainTaskArray?.length + 1,
      order_start_with: orderConc === "Connected" ? 0 : "",
      uploadFile: "No",
      uploadDocument: [
        {
          documentType: "",
          particular: "",
          uploadDoc: "",
        },
      ],
    });
    // setOpenLoading(true);
    setIsSaveAndContinue(true);
    setIsProjectDisable(true);
    setIsDefaultValueChecked(false);
    setIsDefaultValueCheckedSubTemplate(false);
    setCurrentIndex((prev) => prev + 1);
    setOrderIdValue(null);
    setStateIdValue(null);
    setLicenseTypeValue(null);
    setActivityTypeValue(null);
    setFreezoneValue(null);
    setOffshoreValue(null);
    setLegalTypeValue(null);
    setPROFormationTypeValue(null);
    setDedFzOffTypeValue(null);
    setProServicesName(null);
    setSowList([]);
    setOrderId(null);
    setSelectedOrderTemplate([]);
  };

  const handleOrderTemplateChecked = (scopeOfWork, val) => {
    let findValue = scopeOfWork?.find(
      (value) => value.Service_Task_Id === val.Service_Task_Id
    );
    if (findValue) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveProjectValue = (
    values,
    setFieldValue,
    sowSubTemplateList
  ) => {
    setSowSubTemplateList(sowSubTemplateList);
    setShowProjectForm(false);
    setFieldValue("companyName", values?.companyLabel);
    setFieldValue("companyId", values?.companyName);
    setFieldValue("supportName", values?.supportLabel);
    setFieldValue("supportId", values?.supportName);
    setFieldValue("selectedSubTemplate", values?.selectedSubTemplate);
    setFieldValue("selectedSubTemplateId", values?.selectedSubTemplate);
    setFieldValue("subOrderTemplateName", values?.selectedSubTemplate);
    setFieldValue("scopeOfWorkSubTemplate", values?.scopeOfWorkSubTemplate);
  };

  const addProjectPOSTPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const user_name = localStorage.getItem("Employee_First_Name");
    // https://flyingcolour.online/fcapp/compliance/checkverificationonpartner/
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectmaster/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let selectedSubTemplate = values?.selectedSubTemplate?.map((val) => {
      return val.label;
    });

    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);
    formData.append("company_id", values?.companyId);
    formData.append("company_name", values?.companyName);
    formData.append("support_id", values?.supportId);
    formData.append("active_inactive", "Active");
    formData.append("order_sub_template", JSON.stringify(selectedSubTemplate));
    formData.append(
      "scope_of_work_for_sub_template",
      JSON.stringify(sowSubTemplateList)
    );
    formData.append("company_inquiry_flag", operationInfo?.inquiry_id ? 1 : 2);
    formData.append(
      "inquiry_or_company_order_id",
      operationInfo?.new_order_form_id
    );

    axios.post(url, formData, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.data?.project_id !== 0) {
        addOperationNOFOrderFormPOSTPUTApi(values, data?.data?.data);
      }
    });
  };

  const isSequenceExist = (val, array, Service_Task_Id) => {
    let value = array.some(obj => {
      if (!(obj.Service_Task_Id === Service_Task_Id)) {
        return obj.Sequance === val
      }
    });
    return value;
  }

  return (
    <>
      <Formik
        enableReinitialize={initialLoad}
        initialValues={{
          companyName: operationInfo?.company_name,
          companyId: operationInfo?.company_id,
          projectId: "",
          projectName: "",
          supportName: "",
          selectedSubTemplate: "",
          selectedSubTemplateId: "",
          supportId: "",
          subOrderTemplateName: "",
          scopeOfWorkSubTemplate: [],
          mainTaskArray: [
            {
              orderId: orderFormCompanyDropdown?.Order_Id,
              orderName: "",
              orderType: "",
              orderSubType: "",
              state: "",
              formationType: "",
              proServices: "",
              dedFzOffshore: "",
              proServiceName: "",
              freezoneName: "",
              offShoreName: "",
              legalType: "",
              licenseType: "",
              activityType: "",
              selectedTemplate: "",
              selectedTemplateId: "",
              scopeOfWork: [],
              typistId: "",
              orderConnected: "Connected",
              order_finalize: "",
              order_sequence: 1,
              order_start_with: 0,
              uploadFile: "No",
              uploadDocument: [],
            },
          ],
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addOperationNOFOrderFormPOSTPUTApi(data);
        }}
        validate={(values) => {
          let errors = {};
          console.log("mainTaskArray", values?.mainTaskArray)

          if (!values.companyName) {
            errors.companyName = "required";
          }

          if (!values.supportId) {
            errors.supportId = "required";
          }
          values?.mainTaskArray?.forEach((val) => {
            if (!val.orderName) {
              errors.orderName = "required";
            }
            if (!val.selectedTemplate) {
              errors.selectedTemplate = "required";
            }
            if (!val.orderType) {
              errors.orderType = "required";
            }
            if (!val.orderSubType) {
              errors.orderSubType = "required";
            }
            if (!val.state) {
              errors.state = "required";
            }
            if (!val.formationType) {
              errors.formationType = "required";
            }
            //Mainland
            if (val.formationType === 1) {
              if (!val.activityType) {
                errors.activityType = "required";
              }
              if (!val.licenseType) {
                errors.licenseType = "required";
              }
            }
            //Freezone
            if (val.formationType === 2) {
              if (!val.freezoneName) {
                errors.freezoneName = "required";
              }
              if (!val.activityType) {
                errors.activityType = "required";
              }
              if (!val.licenseType) {
                errors.licenseType = "required";
              }
            }
            //Offshore
            if (val.formationType === 3) {
              if (!val.offShoreName) {
                errors.offShoreName = "required";
              }
              if (!val.activityType) {
                errors.activityType = "required";
              }
              if (!val.legalType) {
                errors.legalType = "required";
              }
            }
            //PRO Services
            if (val.formationType === 4) {
              if (!val.proServices) {
                errors.proServices = "required";
              }
              if (!val.dedFzOffshore) {
                errors.dedFzOffshore = "required";
              }
              if (!val.proServiceName) {
                errors.proServiceName = "required";
              }
            }

            if (val?.scopeOfWork?.length <= 0) {
              errors.scopeOfWork = "Required";
            }

            // val?.selectedTemplate?.forEach((val) => {
            //   val?.taskList?.forEach((item) => {
            //     if(item?.Nos_Task_Days < 1 ){
            //       errors.Nos_Task_Days = "Required";
            //     }
            //   })
            // })
            if (
              val?.orderConnected === "Not Connected" &&
              val?.order_start_with <= 0
            ) {
              errors.order_start_with = "Required";
            }
            if (val.typistId === "" || val.typistId < 0) {
              errors.typistId = "required";
            }
          });
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >

              {showProjectForm ? (
                <AddOrEditProjectList
                  showAddOrder={true}
                  handleSaveProjectValue={handleSaveProjectValue}
                  nofSetFieldValue={setFieldValue}
                  nofValues={values}
                />
              ) : (
                <FieldArray
                  name="mainTaskArray"
                  render={(arrayHelpers) => (
                    <>
                      {values.mainTaskArray?.length > 0 &&
                        values.mainTaskArray?.map((item, i) => {
                          if (i === currentIndex) {
                            return (
                              <>
                                <div>
                                  <>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      size="small"
                                      style={{ backgroundColor: "#eb6e12" }}
                                      onClick={() => {
                                        if (currentIndex === 0) {
                                          return setShowProjectForm(true);
                                        }
                                        setOpenLoading(true);
                                        let mainTaskValue =
                                          values?.mainTaskArray[
                                          currentIndex - 1
                                          ];
                                        getOrderFormonCompanyData(
                                          mainTaskValue.orderType,
                                          mainTaskValue.state,
                                          mainTaskValue.formationType,
                                          mainTaskValue.licenseType,
                                          mainTaskValue.activityType,
                                          mainTaskValue.freezoneName,
                                          mainTaskValue.offShoreName,
                                          mainTaskValue.legalType,
                                          mainTaskValue.proServices,
                                          mainTaskValue.dedFzOffshore,
                                          mainTaskValue.proServiceName,
                                          false
                                        );
                                        setCurrentIndex((prev) => prev - 1);
                                      }}
                                    >
                                      Back
                                    </Button>

                                    <Button
                                      type="submit"
                                      variant="contained"
                                      size="small"
                                      style={{
                                        backgroundColor: "#eb6e12",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        setShowProjectForm(false);
                                        if (
                                          values?.mainTaskArray.length ===
                                          currentIndex + 1
                                        ) {
                                          setFieldValue(
                                            `mainTaskArray.[${i}].order_finalize`,
                                            1
                                          );
                                          handleSaveAndContine(
                                            arrayHelpers,
                                            values?.mainTaskArray
                                          );
                                        } else {
                                          let mainTaskValue =
                                            values?.mainTaskArray[
                                            currentIndex + 1
                                            ];
                                          getOrderFormonCompanyData(
                                            mainTaskValue.orderType,
                                            mainTaskValue.state,
                                            mainTaskValue.formationType,
                                            mainTaskValue.licenseType,
                                            mainTaskValue.activityType,
                                            mainTaskValue.freezoneName,
                                            mainTaskValue.offShoreName,
                                            mainTaskValue.legalType,
                                            mainTaskValue.proServices,
                                            mainTaskValue.dedFzOffshore,
                                            mainTaskValue.proServiceName,
                                            false
                                          );
                                          setCurrentIndex((prev) => prev + 1);
                                        }
                                      }}
                                      disabled={
                                        item?.orderName.length > 0 &&
                                          Object.keys(errors).length === 0
                                          ? false
                                          : true
                                      }
                                    >
                                      Save & continue with another order
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setFieldValue(
                                          `mainTaskArray.[${i}].order_finalize`,
                                          2
                                        );
                                        if (
                                          values?.mainTaskArray.length !==
                                          currentIndex + 1
                                        ) {
                                          values?.mainTaskArray?.forEach(
                                            (val, removeIndex) => {
                                              if (removeIndex > i) {
                                                arrayHelpers.remove(
                                                  removeIndex
                                                );
                                              }
                                            }
                                          );
                                        }
                                        setIsSaveAndContinue(false);
                                        setOpenModal(true);
                                      }}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        backgroundColor: "#f9f9f9",
                                        color: "#eb6e12",
                                        marginLeft: "10px",
                                      }}
                                    // disabled={
                                    //   item?.orderName.length > 0 &&
                                    //   Object.keys(errors).length === 0
                                    //     ? false
                                    //     : true
                                    // }
                                    >
                                      Finalize Project
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setButtonText("NOF Order Form Table");
                                      }}
                                      variant="contained"
                                      size="small"
                                      style={{
                                        backgroundColor: "#fff",
                                        color: "#eb6e12",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                </div>
                                <br />

                                <div
                                  style={{
                                    height: "450px",
                                    overflowX: "hidden",
                                    padding: "0",
                                  }}
                                >
                                  <Grid container spacing={2} columns={12}>
                                    <Grid item xs={12}>
                                      <div
                                        className={
                                          classes.quotationModalFlexDivContaner
                                        }
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className={
                                              classes.quotationInnerGridRight
                                            }
                                          >
                                            <InputText
                                              label="Order Name"
                                              fieldName="orderName"
                                              fieldValue={item?.orderName}
                                              fieldHandleChange={(event) =>
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].orderName`,
                                                  event.target.value
                                                )
                                              }
                                              requiredField={true}
                                              errorField={getStyles(
                                                errors,
                                                item?.orderName
                                              )}
                                              maxLength={230}
                                            />
                                            <CustomDropdown
                                              label="Order Type"
                                              fieldOptions={orderTypeDropdown}
                                              fieldValue={item?.orderType}
                                              fieldHandleChange={(value) => {
                                                //setleadCaptionData(value.value);
                                                setOrderIdValue(value.value);
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].orderType`,
                                                  value.value
                                                );
                                              }}
                                              errorField={getStyles(
                                                errors,
                                                item?.orderType
                                              )}
                                              requiredField={true}
                                            />

                                            <CustomDropdown
                                              label="Order Sub Type"
                                              fieldOptions={orderSubTypes}
                                              fieldValue={item?.orderSubType}
                                              fieldHandleChange={(value) => {
                                                //setleadCaptionData(value.value);
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].orderSubType`,
                                                  value.value
                                                );
                                              }}
                                              errorField={getStyles(
                                                errors,
                                                item?.orderSubType
                                              )}
                                              requiredField={true}
                                            />
                                            <CustomDropdown
                                              label="State"
                                              fieldOptions={states}
                                              fieldValue={item?.state}
                                              fieldHandleChange={(value) => {
                                                //setleadCaptionData(value.value);
                                                setStateIdValue(value.value);
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].state`,
                                                  value.value
                                                );
                                              }}
                                              errorField={getStyles(
                                                errors,
                                                item?.state
                                              )}
                                              requiredField={true}
                                            />
                                            <CustomDropdown
                                              label="Formation Type"
                                              fieldOptions={formationType}
                                              fieldValue={item?.formationType}
                                              fieldHandleChange={(value) => {
                                                setFormationTypeValue(
                                                  value.value
                                                );
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].formationType`,
                                                  value.value
                                                );
                                              }}
                                              errorField={getStyles(
                                                errors,
                                                item?.formationType
                                              )}
                                              requiredField={true}
                                            />

                                            {item?.formationType === 3 && (
                                              <>
                                                <CustomDropdown
                                                  label="Offshore Name"
                                                  fieldOptions={offshoreTypes}
                                                  fieldValue={
                                                    item?.offShoreName
                                                  }
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setOffshoreValue(
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].offShoreName`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.offShoreName
                                                  )}
                                                  requiredField={true}
                                                />
                                                <CustomDropdown
                                                  label="Legal Type"
                                                  fieldOptions={legalTypes}
                                                  fieldValue={item?.legalType}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setLegalTypeValue(
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].legalType`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.legalType
                                                  )}
                                                  requiredField={true}
                                                />
                                              </>
                                            )}

                                            {item?.formationType === 2 && (
                                              <>
                                                <CustomDropdown
                                                  label="Freezone Name"
                                                  fieldOptions={freezoneTypes}
                                                  fieldValue={
                                                    item?.freezoneName
                                                  }
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFreezoneValue(
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].freezoneName`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.freezoneName
                                                  )}
                                                  requiredField={true}
                                                />
                                              </>
                                            )}

                                            {item?.formationType === 4 && (
                                              <>
                                                <CustomDropdown
                                                  label="Select Formation Type for PRO Services"
                                                  fieldOptions={
                                                    proFormationTypes
                                                  }
                                                  fieldValue={item?.proServices}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setPROFormationTypeValue(
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].proServices`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.proServices
                                                  )}
                                                  requiredField={true}
                                                />
                                                <CustomDropdown
                                                  label="Select DED/FZ/Offshore Name"
                                                  fieldOptions={
                                                    ded_Fz_OfforeTypes
                                                  }
                                                  fieldValue={
                                                    item?.dedFzOffshore
                                                  }
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setDedFzOffTypeValue(
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].dedFzOffshore`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.dedFzOffshore
                                                  )}
                                                  requiredField={true}
                                                />
                                                <CustomDropdown
                                                  label="Pro Service Name"
                                                  fieldOptions={proServiceNames}
                                                  fieldValue={
                                                    item?.proServiceName
                                                  }
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setProServicesName(
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].proServiceName`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.proServiceName
                                                  )}
                                                  requiredField={true}
                                                />
                                              </>
                                            )}
                                            {!(
                                              item?.formationType === 3 ||
                                              item?.formationType === 4
                                            ) && (
                                                <>
                                                  <CustomDropdown
                                                    label="License Type"
                                                    fieldOptions={licenseTypes}
                                                    fieldValue={item?.licenseType}
                                                    fieldHandleChange={(
                                                      value
                                                    ) => {
                                                      setLicenseTypeValue(
                                                        value.value
                                                      );
                                                      setFieldValue(
                                                        `mainTaskArray.[${i}].licenseType`,
                                                        value.value
                                                      );
                                                    }}
                                                    errorField={getStyles(
                                                      errors,
                                                      item?.licenseType
                                                    )}
                                                    requiredField={true}
                                                  />
                                                </>
                                              )}
                                            {item?.formationType !== 4 && (
                                              <CustomDropdown
                                                label="Activity Type"
                                                fieldOptions={
                                                  activityGroupTypes
                                                }
                                                fieldValue={item?.activityType}
                                                fieldHandleChange={(value) => {
                                                  setActivityTypeValue(
                                                    value.value
                                                  );
                                                  setFieldValue(
                                                    `mainTaskArray.[${i}].activityType`,
                                                    value.value
                                                  );
                                                }}
                                                errorField={getStyles(
                                                  errors,
                                                  item?.activityType
                                                )}
                                                requiredField={true}
                                              />
                                            )}
                                            <CustomDropdown
                                              label="Typist"
                                              fieldOptions={typistList}
                                              fieldValue={item?.typistId}
                                              fieldHandleChange={(value) => {
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].typistId`,
                                                  value.value
                                                );
                                              }}
                                              errorField={getStyles(
                                                errors,
                                                item?.typistId
                                              )}
                                              requiredField={true}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className={
                                              classes.quotationInnerGridRight
                                            }
                                          >
                                            <InputText
                                              label="Order Sequence"
                                              fieldName="supportName"
                                              fieldValue={item?.order_sequence}
                                              disableField={true}
                                              disabled={true}
                                            />

                                            <InputText
                                              label="Company Name"
                                              fieldName="companyName"
                                              fieldValue={values?.companyName}
                                              disabled={true}
                                              disableField={true}
                                              requiredField={true}
                                              errorField={getStyles(
                                                errors,
                                                values?.companyName
                                              )}
                                            />

                                            <InputText
                                              label="Order Id"
                                              fieldName="orderId"
                                              fieldValue={item?.orderId}
                                              disableField={true}
                                              disabled={true}
                                            />
                                            <InputText
                                              label="Support"
                                              fieldName="supportName"
                                              fieldValue={values?.supportName}
                                              disableField={true}
                                              disabled={true}
                                            />
                                            <CustomMultiSelectDropdown
                                              label="Sub Order Template"
                                              fieldOptions={
                                                templateSubOrderListDropdown
                                              }
                                              fieldValue={
                                                values?.subOrderTemplateName
                                              }
                                              isDisabled={true}
                                              requiredField={true}
                                            />

                                            <Form.Group
                                              as={Row}
                                              className="my-1"
                                              controlId={"gender"}
                                            >
                                              <Form.Label column sm="5">
                                                Connected / Not Connected
                                              </Form.Label>
                                              <Col
                                                sm="7"
                                                style={{
                                                  padding: "0 12px 0 0",
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.listingEditListingEditFormGroup
                                                  }
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div>
                                                    <fieldset
                                                      onChange={(event) => {
                                                        setFieldValue(
                                                          `mainTaskArray.[${i}].orderConnected`,
                                                          event.target.value
                                                        );
                                                      }}
                                                      disabled={
                                                        i === 0
                                                          ? true
                                                          : values
                                                            ?.mainTaskArray[
                                                            values
                                                              ?.mainTaskArray
                                                              ?.length - 2
                                                          ]
                                                            ?.orderConnected ===
                                                            "Not Connected"
                                                            ? true
                                                            : false
                                                      }
                                                    >
                                                      <input
                                                        type="radio"
                                                        checked={
                                                          item?.orderConnected ===
                                                          "Connected"
                                                        }
                                                        name="orderConnected"
                                                        value="Connected"
                                                      />
                                                      <span
                                                        className={
                                                          classes.genderRadioButtonLabele
                                                        }
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        Connected
                                                      </span>
                                                      <input
                                                        type="radio"
                                                        checked={
                                                          item?.orderConnected ===
                                                          "Not Connected"
                                                        }
                                                        name="orderConnected"
                                                        value="Not Connected"
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      />
                                                      <span
                                                        className={
                                                          classes.genderRadioButtonLabele
                                                        }
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        Not Connected
                                                      </span>
                                                    </fieldset>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Form.Group>

                                            {item?.orderConnected ===
                                              "Not Connected" && (
                                                <CustomDropdown
                                                  label="Select Order Start With"
                                                  fieldOptions={
                                                    orderNOFListDropdown
                                                  }
                                                  fieldValue={
                                                    item?.order_start_with
                                                  }
                                                  fieldHandleChange={(value) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].order_start_with`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    item?.order_start_with
                                                  )}
                                                  requiredField={true}
                                                />
                                              )}

                                            <CustomMultiSelectDropdown
                                              label="Select Order Template"
                                              fieldOptions={
                                                templateListDropdown
                                              }
                                              fieldValue={
                                                item?.selectedTemplateId
                                              }
                                              fieldHandleChange={(value) => {
                                                if (value?.length === 0) {
                                                  setFieldValue(
                                                    `mainTaskArray.[${i}].scopeOfWork`,
                                                    []
                                                  );
                                                } else {
                                                  let idsInArray = value?.map((obj) => obj.id);
                                                  let sow = values?.mainTaskArray?.[i]?.scopeOfWork;
                                                  const filteredArray = sow?.filter((obj) => idsInArray?.includes(obj.Id))
                                                  setFieldValue(
                                                    `mainTaskArray.[${i}].scopeOfWork`,
                                                    filteredArray
                                                  );
                                                }
                                                setIsDefaultValueChecked(false);
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].selectedTemplate`,
                                                  value
                                                );
                                                setFieldValue(
                                                  `mainTaskArray.[${i}].selectedTemplateId`,
                                                  value
                                                );
                                              }}
                                              errorField={getStyles(
                                                errors,
                                                item?.selectedTemplate
                                              )}
                                              requiredField={true}
                                            />
                                            <Form.Group
                                              as={Row}
                                              className="my-1"
                                              controlId={"gender"}
                                            >
                                              <Form.Label column sm="5">
                                                Upload File <sup style={{ color: 'red' }}>*</sup>
                                              </Form.Label>
                                              <Col
                                                sm="7"
                                                style={{
                                                  padding: "0 12px 0 0",
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.listingEditListingEditFormGroup
                                                  }
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div>
                                                    <fieldset
                                                      onChange={(event) => {
                                                        if (event.target.value === "No") {
                                                          setFieldValue(
                                                            `mainTaskArray.[${i}].uploadDocument`,
                                                            []
                                                          );
                                                        } else {
                                                          setFieldValue(
                                                            `mainTaskArray.[${i}].uploadDocument`,
                                                            [{
                                                              documentType: "",
                                                              particular: "",
                                                              uploadDoc: "",
                                                            },]
                                                          );
                                                        }
                                                        setFieldValue(
                                                          `mainTaskArray.[${i}].uploadFile`,
                                                          event.target.value
                                                        );
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        checked={
                                                          item?.uploadFile ===
                                                          "Yes"
                                                        }
                                                        name="Yes"
                                                        value="Yes"
                                                      />
                                                      <span
                                                        className={
                                                          classes.genderRadioButtonLabele
                                                        }
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        Yes
                                                      </span>
                                                      <input
                                                        type="radio"
                                                        checked={
                                                          item?.uploadFile ===
                                                          "No"
                                                        }
                                                        name="uploadFile"
                                                        value="No"
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      />
                                                      <span
                                                        className={
                                                          classes.genderRadioButtonLabele
                                                        }
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        No
                                                      </span>
                                                    </fieldset>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Form.Group>
                                            <br />
                                          </Grid>
                                        </Grid>
                                        <br />


                                        {item?.uploadFile === "Yes" && (
                                          <>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              className={
                                                classes.quotationInnerGridRight
                                              }
                                            >
                                              <Form.Group
                                                as={Row}
                                                className="mb-1"
                                                controlId={"sow"}
                                              >
                                                <Form.Label
                                                  column
                                                  sm="12"
                                                  className="fc-dropdown-label"
                                                >
                                                  Upload Document
                                                  <sup style={{ color: "red" }}>
                                                    *
                                                  </sup>
                                                </Form.Label>
                                              </Form.Group>

                                              <Form.Group
                                                as={Row}
                                                className="mb-1"
                                                controlId={"selectsow"}
                                              >
                                                <Col sm="12">
                                                  <div
                                                    className="fc-dropdown-container"
                                                    style={{ padding: "10px" }}
                                                  >
                                                    <Box
                                                      component={Grid}
                                                      display={{
                                                        md: "flex",
                                                      }}
                                                    >
                                                      <FieldArray
                                                        name={`mainTaskArray.[${i}].uploadDocument`}
                                                        render={(arrayHelpers) => (
                                                          <Grid
                                                            container
                                                            columns={12}>
                                                            {item?.uploadDocument
                                                              ?.length > 0 &&
                                                              item?.uploadDocument?.map(
                                                                (
                                                                  uploc,
                                                                  uploadIndex
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={4}
                                                                        style={{ paddingRight: 5 }}
                                                                      >
                                                                        <CustomDropdown
                                                                          label="Document Type"
                                                                          fieldOptions={
                                                                            documentTypeList
                                                                          }
                                                                          fieldValue={
                                                                            uploc?.documentType
                                                                          }
                                                                          fieldHandleChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].uploadDocument.[${uploadIndex}].documentType`,
                                                                              value.value
                                                                            );
                                                                          }}
                                                                        />
                                                                      </Grid>

                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={3}
                                                                        style={{ paddingRight: 5 }}
                                                                      >
                                                                        <InputText
                                                                          label="Particular"
                                                                          fieldName="orderName"
                                                                          fieldValue={
                                                                            uploc?.particular
                                                                          }
                                                                          fieldHandleChange={(
                                                                            event
                                                                          ) =>
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].uploadDocument.[${uploadIndex}].particular`,
                                                                              event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        />
                                                                      </Grid>

                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={4}
                                                                        style={{ paddingRight: 5 }}
                                                                      >
                                                                        <CustomUploadFile
                                                                          label="Upload Documents"
                                                                          uploadDoc={
                                                                            uploc?.uploadDoc
                                                                          }
                                                                          uploadType="single"
                                                                          fieldHandleChange={(
                                                                            e
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].uploadDocument.[${uploadIndex}].uploadDoc`,
                                                                              e.currentTarget
                                                                                .files?.[0],

                                                                            );
                                                                          }}
                                                                          fieldDeleteChange={(
                                                                            index
                                                                          ) => {

                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].uploadDocument.[${uploadIndex}].uploadDoc`,
                                                                              ""
                                                                            );
                                                                          }}
                                                                        />
                                                                      </Grid>
                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={1}
                                                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                      >
                                                                        <div
                                                                          style={{
                                                                            width:
                                                                              "2px",
                                                                            display:
                                                                              "flex",
                                                                            flexDirection:
                                                                              "column",
                                                                            justifyContent:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <IconButton
                                                                            onClick={() =>
                                                                              arrayHelpers?.push({
                                                                                documentType: "",
                                                                                particular: "",
                                                                                uploadDoc: "",
                                                                              },)
                                                                            }
                                                                            style={{
                                                                              paddingBottom: 0,
                                                                              paddingTop: 0,
                                                                            }}
                                                                          >
                                                                            <AddCircleIcon
                                                                              style={{
                                                                                fontSize:
                                                                                  "14px !important",
                                                                              }}

                                                                            />
                                                                          </IconButton>
                                                                          {item
                                                                            ?.uploadDocument
                                                                            ?.length >
                                                                            1 &&
                                                                            uploadIndex ===
                                                                            item
                                                                              ?.uploadDocument
                                                                              .length -
                                                                            1 ? (
                                                                            <IconButton
                                                                              onClick={() =>
                                                                                arrayHelpers?.remove(
                                                                                  uploadIndex
                                                                                )
                                                                              }
                                                                              style={{
                                                                                paddingBottom: 0,
                                                                                paddingTop: 0,
                                                                              }}
                                                                            >
                                                                              <RemoveCircleOutlineIcon
                                                                                style={{
                                                                                  fontSize:
                                                                                    "14px !important",
                                                                                }}
                                                                                className={
                                                                                  classes.modalHeaderIcon
                                                                                }
                                                                              />
                                                                            </IconButton>
                                                                          ) : null}
                                                                        </div>
                                                                      </Grid>
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                          </Grid>
                                                        )}
                                                      />
                                                    </Box>
                                                  </div>
                                                </Col>
                                              </Form.Group>
                                            </Grid>
                                          </>
                                        )}


                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          className={
                                            classes.quotationInnerGridRight
                                          }
                                        >
                                          <Form.Group
                                            as={Row}
                                            className="mb-1"
                                            controlId={"sow"}
                                          >
                                            <Form.Label
                                              column
                                              sm="12"
                                              className="fc-dropdown-label"
                                            >
                                              Select Order Template Scope Of
                                              Work
                                              <sup style={{ color: "red" }}>
                                                *
                                              </sup>
                                            </Form.Label>
                                          </Form.Group>

                                          <Form.Group
                                            as={Row}
                                            className="mb-1"
                                            controlId={"selectsow"}
                                          >
                                            <Col sm="12">
                                              {/* Scope of Work Component Has to  be */}
                                              {item?.selectedTemplate?.length >
                                                0 && (
                                                  <div
                                                    className="fc-dropdown-container"
                                                    style={{ padding: "10px" }}
                                                  >
                                                    <Box
                                                      component={Grid}
                                                      display={{
                                                        xs: "none",
                                                        md: "flex",
                                                      }}
                                                    >
                                                      <Grid
                                                        container
                                                        columns={12}
                                                      >
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Task Name</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Task detail</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Work type</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Amount</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Days</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Sequence</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>
                                                            Special Instruction
                                                          </p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Task section</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Task type</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Task category</p>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Govt. Dept</p>
                                                        </Grid>

                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={12}
                                                          md={1}
                                                        >
                                                          <p>Tag Name</p>
                                                        </Grid>
                                                      </Grid>
                                                    </Box>
                                                    {item?.selectedTemplate?.map(
                                                      (value, index) => {
                                                        return (
                                                          <>
                                                            {value?.taskList?.map(
                                                              (
                                                                val,
                                                                taskListIndex
                                                              ) => {
                                                                return (
                                                                  <Grid
                                                                    container
                                                                    columns={12}
                                                                  >
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Task
                                                                          Name
                                                                        </p>
                                                                      </Box>

                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          alignItems:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <Form.Check
                                                                          type="checkbox"
                                                                          name="scopeOfWork"
                                                                          value={
                                                                            val?.id
                                                                          }
                                                                          checked={handleOrderTemplateChecked(
                                                                            item?.scopeOfWork,
                                                                            val
                                                                          )}
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            const currVal =
                                                                              item.scopeOfWork;
                                                                            if (
                                                                              e
                                                                                .target
                                                                                .checked
                                                                            ) {
                                                                              let obj =
                                                                              {
                                                                                Id: value.id,
                                                                                Name: val.Service_Task_Name,
                                                                                Task_Type:
                                                                                  val.Task_Type_Name,
                                                                                Task_Category:
                                                                                  val.Task_Category,
                                                                                Days: val?.Nos_Task_Days,
                                                                                Sequance:
                                                                                  Number(val?.Task_Sequence),
                                                                                Amount:
                                                                                  val?.Task_Amount,
                                                                                Govt_Department:
                                                                                  val.Govt_Dept_Name,
                                                                                Special_Instruction:
                                                                                  val.Special_Instruction,
                                                                                Task_Section:
                                                                                  val?.Single_Multi_Task_Type,
                                                                                Task_Detail:
                                                                                  val.Task_Details,
                                                                                Work_Type:
                                                                                  val.Work_Type,
                                                                                Tag_Id:
                                                                                  Number(
                                                                                    val.Tag_Id
                                                                                  ),
                                                                                Tag_Name:
                                                                                  val.Tag_Name,
                                                                                Template_Name:
                                                                                  value.label,
                                                                                Template_Id:
                                                                                  value.id,
                                                                                Service_Task_Id:
                                                                                  val?.Service_Task_Id,
                                                                              };
                                                                              currVal.push(
                                                                                obj
                                                                              );
                                                                            } else {
                                                                              let isValueExist =
                                                                                currVal.findIndex(
                                                                                  (
                                                                                    doc
                                                                                  ) =>
                                                                                    doc.Service_Task_Id ===
                                                                                    val.Service_Task_Id
                                                                                );
                                                                              currVal.splice(
                                                                                isValueExist,
                                                                                1
                                                                              );
                                                                            }
                                                                            setSowList(
                                                                              currVal
                                                                            );
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork`,
                                                                              currVal
                                                                            );
                                                                          }}
                                                                        />

                                                                        <div
                                                                          className="fc-input-container"
                                                                          style={{
                                                                            marginRight: 3,
                                                                            marginLeft: 3,
                                                                            width:
                                                                              "100%",
                                                                          }}
                                                                        >
                                                                          <input
                                                                            name={
                                                                              "Task Name"
                                                                            }
                                                                            value={
                                                                              val?.Service_Task_Name
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) => {
                                                                              setFieldValue(
                                                                                `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                  index,
                                                                                ]}.taskList.${[
                                                                                  taskListIndex,
                                                                                ]}.Service_Task_Name`,
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              );
                                                                              let fina =
                                                                                [
                                                                                  ...sowList,
                                                                                ];
                                                                              let sowIndex =
                                                                                fina?.findIndex(
                                                                                  (
                                                                                    item
                                                                                  ) =>
                                                                                    item.Service_Task_Id ===
                                                                                    val.Service_Task_Id
                                                                                );
                                                                              setFieldValue(
                                                                                `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                  sowIndex,
                                                                                ]}.Name`,
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              );
                                                                              fina[
                                                                                sowIndex
                                                                              ] =
                                                                              {
                                                                                ...fina[
                                                                                sowIndex
                                                                                ],
                                                                                Name: e
                                                                                  .target
                                                                                  .value,
                                                                              };
                                                                              setSowList(
                                                                                fina
                                                                              );
                                                                            }}
                                                                            type={
                                                                              "text"
                                                                            }
                                                                            disabled={true}
                                                                            className={`fc-input-style`}
                                                                          />
                                                                        </div>
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Task
                                                                          detail
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-dropdown-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <input
                                                                          name={
                                                                            "Task Details"
                                                                          }
                                                                          value={
                                                                            val?.Task_Details
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Task_Details`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Task_Detail:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Task_Detail`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          type={
                                                                            "text"
                                                                          }
                                                                          disabled={true}
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Work
                                                                          Type
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-dropdown-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <CustomSelect
                                                                          name={
                                                                            "Work Type"
                                                                          }
                                                                          value={
                                                                            val?.Work_Type
                                                                          }
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Work_Type`,
                                                                              value.value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Work_Type:
                                                                                value.value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Work_Type`,
                                                                              value.value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          options={
                                                                            workTypeDropdown
                                                                          }
                                                                          isDisabled={values?.mainTaskArray?.[i]?.selectedTemplateId?.[
                                                                            index
                                                                          ]?.taskList?.[
                                                                            taskListIndex
                                                                          ]?.Work_Type === "PRO Work" ? true : false}
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Amount
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-input-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <input
                                                                          name={
                                                                            "Task Amount"
                                                                          }
                                                                          value={
                                                                            val?.Task_Amount
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            if (e.target.value < 0) { return }
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Task_Amount`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Amount:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Amount`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          type={
                                                                            "number"
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Days
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-input-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <input
                                                                          name={"Days"}
                                                                          value={val?.Nos_Task_Days}
                                                                          onChange={(e) => {
                                                                            // Only allow integers (no decimals)
                                                                            const value = e.target.value;
                                                                            if (value === '' || /^[0-9]\d*$/.test(value)) {
                                                                              setFieldValue(
                                                                                `mainTaskArray.[${i}].selectedTemplate.${[index]}.taskList.${[taskListIndex]}.Nos_Task_Days`,
                                                                                value
                                                                              );
                                                                              let fina = [...sowList];
                                                                              let sowIndex = fina?.findIndex(
                                                                                (item) => item.Service_Task_Id === val.Service_Task_Id
                                                                              );
                                                                              fina[sowIndex] = {
                                                                                ...fina[sowIndex],
                                                                                Days: parseInt(value || '0'),
                                                                              };
                                                                              setFieldValue(
                                                                                `mainTaskArray.[${i}].scopeOfWork.${[sowIndex]}.Days`,
                                                                                value
                                                                              );
                                                                              setSowList(fina);
                                                                            }
                                                                          }}
                                                                          type={"text"}
                                                                          // Add this to prevent decimal input via browser UI
                                                                          onKeyDown={(e) => {
                                                                            if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                                                                              e.preventDefault();
                                                                            }
                                                                          }}
                                                                          step="1"
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                      {val?.Nos_Task_Days < 1 && (
                                                                        <span style={{ color: "red" }}>
                                                                          Required!
                                                                        </span>
                                                                      )}
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Sequence
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-input-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <input
                                                                          name={
                                                                            "Sequence"
                                                                          }
                                                                          value={
                                                                            val?.Task_Sequence
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            if (e.target.value === "" || (/^[1-9][0-9]*$/.test(e.target.value))) {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Task_Sequence`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Sequance:
                                                                                parseInt(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                ),
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Sequance`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }
                                                                          }}
                                                                          type={
                                                                            "text"
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                      {isSequenceExist(val?.Task_Sequence, sowList, val?.Service_Task_Id) && (
                                                                        <span style={{ color: 'red', fontSize: 12 }}>
                                                                          Sequence already exists!
                                                                        </span>
                                                                      )}
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Special
                                                                          Instruction
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-input-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <input
                                                                          name={
                                                                            "Special Instrcution"
                                                                          }
                                                                          value={
                                                                            val?.Special_Instruction
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Special_Instruction`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Special_Instruction:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Special_Instruction`,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          type={
                                                                            "text"
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Task
                                                                          section
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-dropdown-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <CustomSelect
                                                                          name={
                                                                            "Task section"
                                                                          }
                                                                          value={
                                                                            val?.Single_Multi_Task_Type
                                                                          }
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Single_Multi_Task_Type`,
                                                                              value.value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Task_Section:
                                                                                value.value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Task_Section`,
                                                                              value.value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          options={
                                                                            taskSectionDropdown
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Task
                                                                          type
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-dropdown-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <CustomSelect
                                                                          name={
                                                                            "Task type"
                                                                          }
                                                                          value={
                                                                            val?.Task_Type_Name
                                                                          }
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Task_Type_Name`,
                                                                              value.value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Task_Type:
                                                                                value.value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Task_Type`,
                                                                              value.value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          options={
                                                                            taskTypeDropdown
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Task
                                                                          category
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-dropdown-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <CustomSelect
                                                                          name={
                                                                            "Task category"
                                                                          }
                                                                          value={
                                                                            val?.Task_Category
                                                                          }
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Task_Category`,
                                                                              value.value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Task_Category:
                                                                                value.value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Task_Category`,
                                                                              value.value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          options={
                                                                            taskCategoryDropdown
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>

                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Govt.
                                                                          Dept
                                                                        </p>
                                                                      </Box>
                                                                      <div
                                                                        className="fc-dropdown-container"
                                                                        style={{
                                                                          marginRight: 3,
                                                                        }}
                                                                      >
                                                                        <CustomSelect
                                                                          name={
                                                                            "Govt. Dept"
                                                                          }
                                                                          value={String(
                                                                            val?.Govt_Dept_Name
                                                                          )}
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Govt_Dept_Name`,
                                                                              value.value
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Govt_Department:
                                                                                value.value,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Govt_Department`,
                                                                              value.value
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          options={
                                                                            govtDeptDropdown
                                                                          }
                                                                          className={`fc-input-style`}
                                                                        />
                                                                      </div>
                                                                    </Grid>

                                                                    <Grid
                                                                      item
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={1}
                                                                    >
                                                                      <Box
                                                                        component={
                                                                          Grid
                                                                        }
                                                                        display={{
                                                                          md: "none",
                                                                        }}
                                                                      >
                                                                        <p
                                                                          style={{
                                                                            margin: 0,
                                                                            marginBottom: 3,
                                                                          }}
                                                                        >
                                                                          Tag Name
                                                                        </p>
                                                                      </Box>
                                                                      <div className="fc-dropdown-container">
                                                                        <CustomSelect
                                                                          value={Number(
                                                                            val?.Tag_Id
                                                                          )}
                                                                          onChange={(
                                                                            value
                                                                          ) => {
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Tag_Id`,
                                                                              Number(
                                                                                value.value
                                                                              )
                                                                            );
                                                                            setFieldValue(
                                                                              `selectedTemplate.${[
                                                                                index,
                                                                              ]}.taskList.${[
                                                                                taskListIndex,
                                                                              ]}.Tag_Name`,
                                                                              value.label
                                                                            );
                                                                            let fina =
                                                                              [
                                                                                ...sowList,
                                                                              ];
                                                                            let sowIndex =
                                                                              fina?.findIndex(
                                                                                (
                                                                                  item
                                                                                ) =>
                                                                                  item.Service_Task_Id ===
                                                                                  val.Service_Task_Id
                                                                              );
                                                                            fina[
                                                                              sowIndex
                                                                            ] = {
                                                                              ...fina[
                                                                              sowIndex
                                                                              ],
                                                                              Tag_Id:
                                                                                Number(
                                                                                  value.value
                                                                                ),
                                                                              Tag_Name:
                                                                                value.label,
                                                                            };
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Tag_Name`,
                                                                              value.label
                                                                            );
                                                                            setFieldValue(
                                                                              `mainTaskArray.[${i}].scopeOfWork.${[
                                                                                sowIndex,
                                                                              ]}.Tag_Id`,
                                                                              Number(
                                                                                value.value
                                                                              )
                                                                            );
                                                                            setSowList(
                                                                              fina
                                                                            );
                                                                          }}
                                                                          options={
                                                                            tagNames
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </Grid>
                                                                  </Grid>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                )}
                                            </Col>
                                          </Form.Group>
                                          <span style={{ color: "red" }}>
                                            {errors?.mainTaskArray?.[i]
                                              ?.scopeOfWork &&
                                              touched?.mainTaskArray?.[i]
                                                ?.scopeOfWork &&
                                              errors?.mainTaskArray?.[i]
                                                ?.scopeOfWork}
                                          </span>
                                        </Grid>
                                      </div>
                                      <br />
                                    </Grid>
                                  </Grid>
                                  <br />
                                  <br />
                                </div>
                              </>
                            );
                          }
                        })}
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={openLoading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      <Modal
                        open={openModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <FinalizeNOFOrder
                          msg={"Are you sure?"}
                          onSubmit={() =>
                            addProjectPOSTPUTAPI(values)
                          }
                          setOpenModal={setOpenModal}
                          setIsSaveAndContinue={setIsSaveAndContinue}
                          errors={errors}
                          companyName={values?.companyName}
                        />
                      </Modal>
                    </>
                  )}
                />
              )}
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(AddNOFOrderForm);
