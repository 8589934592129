import * as React from "react";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles/projectDesignerTopBar.css";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { getLocalData } from "../../../services/services";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from "moment";
import CustomCalender from "../../../reusableComponents/CustomCalender/CustomCalender";
import DatePicker from '../../../pages/Dashboard/DatePicker';
import { DataContext } from "../../../provider/context";
import { useNavigate } from "react-router-dom";


export default function Notify({ checked, fetchNotification }) {

  const __token = localStorage.getItem("token")
  const user_type = getLocalData('user_type')
  const emp_id = getLocalData('emp_id')
  const factory_id = getLocalData('factory_id')
  const [isShowAllNotification, setIssShowAllNotification] = React.useState(false);
  const [notificationAllDataList, setNotificationAllDataList] = React.useState(false);
  const { fromDate, toDate, setError, setErrorAlert } = React.useContext(DataContext);
  const [isInitialRef, setInitialRef] = React.useState(false);
  const navigate = useNavigate();
  const gridRef = React.useRef();

  const notify_data = notificationAllDataList?.Notification_On_Inquiry?.concat(notificationAllDataList?.Notification_On_Company) || null;

  const handleNotificationDate = () => {
    gridRef?.current?.api?.getFilterInstance('Inquiry_Status_Date').setModel({
      type: 'inRange',
      dateFrom: fromDate,
      dateTo: toDate
    })
    gridRef?.current?.api.onFilterChanged();
  }


  React.useEffect(() => {
    if (isInitialRef) {
      handleNotificationDate()
    }
    setInitialRef(true);
  }, [fromDate, toDate])

  const handleChange = async (item) => {
    let data = {
      emp_id: Number(emp_id),
      user_type: user_type,
      factory_id: Number(factory_id),
      cmp_type: item.inquiry_company,
      new_order_form_id: item.new_order_form_id,
      read_all: Number(0),
    }
    await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/notificationon_nof/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login")
        }
      });
    // setChecked((prev) => [...prev.filter((i) => i !== item)]);
  };
  const handleChange2 = async (item) => {
    let data = {
      emp_id: Number(emp_id),
      user_type: user_type,
      factory_id: Number(factory_id),
      cmp_type: item.inquiry_company,
      new_order_form_id: item.new_order_form_id,
      read_all: Number(0),
    }
    await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/notificationon_nof/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification();
        handleViewAllNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login")
        }
      });
    // setChecked((prev) => [...prev.filter((i) => i !== item)]);
  };
  const components = React.useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);
  const NotificationBox = ({ title, Msg, onClose, date }) => {
    return (
      <div className="tBody">
        <div className="toast-header">
          <h5 class="headerTxt"></h5>
          <IconButton className="closeBtn" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </IconButton>
        </div>
        {/* <div className="toast-body" style={{ marginBottom: '0.5rem' }}>
          <span style={{fontWeight: "bold"}}>Date & Time :</span> {date}
        </div> */}
        <div className="toast-body">
        New NOF: <span style={{fontWeight: "bold"}}>{Msg?.new_order_form_id}</span> Approved <span style={{fontWeight: "bold"}}>({moment(Msg?.Approval_Date_Time).format("DD-MM-YYYY hh:mm:ss a")})</span> on <span>{Msg?.inquiry_company}</span>: <span style={{fontWeight: "bold"}}>{Msg?.inquiry_company === "Inquiry"? `FC${Msg?.inquiry_id}` 
        : Msg?.company_id}</span> / <span style={{fontWeight: "bold"}}>{Msg?.company_name}</span>.
         
        </div>
        <Button className="toast-button" onClick={() =>  handleChange(Msg)}>Read</Button>
      </div>
    );
  };

  const handleViewAllNotification = async () => {
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/notificationon_nof/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}`
      const headers = {
        'Authorization': `Token ${__token}`,
        'Content-Type': 'application/json',
      }
      const res = await axios.get(url, { headers })
      if (
        res?.data?.Data === undefined
      ) {
        return setErrorAlert(true), setError(`ERROR ${res?.data?.code}-${res?.data?.msg}${" "}${"Error is from sales person notification"}`);
      } else if (res?.data?.detail !== undefined && res?.data?.Data === undefined) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res?.data?.Data}${" "}${"Error is from sales person notification"}`);
      } else {
        return setNotificationAllDataList(res?.data?.Data), setIssShowAllNotification(true);
      }
    }
    catch (error) {
      if (error?.response?.status === 401) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login")
        }
        return setErrorAlert(true), setError(`${error.response?.statusText} ${error.response?.status}-${error.response?.data?.detail}${" "}${"Please Login Again!!"}`)
      } else if (!error?.response) {
        return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

      }
    }
  }

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const onlyDate = cellValue.split(' ');
      const dateParts = onlyDate[0].split('-');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      // Now that both parameters are Date objects, we can compare
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true
  }

  const columnTypes = React.useMemo(() => {
    return {
      numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
      medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
      nonEditableColumn: { editable: false }
    };
  }, []);

  const handleReadAllNotification = async () => {
    let data = {
      emp_id: Number(emp_id),
      user_type: user_type,
      factory_id: Number(factory_id),
      cmp_type: "",
      new_order_form_id: 1,
      read_all: 1,
    }
    await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/notificationon_nof/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification();
        handleViewAllNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login")
        }
        console.error("Error:", error);

      });
  }


  const columnDefs = [
    {
      headerName: 'Sr.#',
      field: 'Serial_Number',
      filter: false,
      width: 90,
      cellStyle: { border: '1px solid #fff' }
    },
    {
      headerName: 'Inquiry / Company',
      field: 'inquiry_company',
      cellStyle: { border: '1px solid #fff' },
      flex: 1,
      floatingFilter: false,
    },
    {
      headerName: 'Inquiry / Company Id',
      field: 'Notification_Title',
      cellStyle: { border: '1px solid #fff', overflowY: 'auto' },
      flex: 1,
      floatingFilter: false,
      cellRenderer: (params) => {
        return (
    <>
    { params?.data?.inquiry_company === "Company"  ? params?.data?.company_id : `FC${params?.data?.inquiry_id}` } 
    </>   
        )
        }
    },
    {
      headerName: "Company Name",
      field: 'company_name',
      cellStyle: { border: '1px solid #fff' },
      filter: 'agTextColumnFilter',
     // filterParams: dateFilterParams,
      flex: 1
    },
    {
      headerName: 'NOF Id',
      field: 'new_order_form_id',
      cellStyle: { border: '1px solid #fff' },
      filter: false,
     // filterParams: dateFilterParams,
      flex: 1
    },
    // {
    //   headerName: 'Remarks',
    //   field: 'remarks',
    //   cellStyle: { border: '1px solid #fff' },
    //   flex: 1
    // },
    {
      headerName: 'Approval Date',
      field: 'Approval_Date_Time',
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { border: '1px solid #fff' },
      flex: 1,
      cellRenderer: (params) => {
        return (
    <>
    {moment(params?.data?.Approval_Date_Time).format("DD-MM-YYYY hh:mm:ss a")} 
    </>   
        )
        }
    },
    {
      headerName: 'Read / UnRead',
      field: 'Inquiry_Status_Date',
      floatingFilter: false,
      cellRenderer: (params) => {
        return (
          <>
            {
               params?.data?.Read_Flag === "0"  ?  (
                <div className="" style={{ display: 'flex', justifyContent: "center" }}>
                  <Button className="toast-button" onClick={() => handleChange2(params?.data)}>Read</Button>
                </div>
                ) : null
            } 
          </>
        )
      },
      cellStyle: { border: '1px solid #fff' },
      flex: 1
    },

  ]

  const defaultColDef = React.useMemo(() => {
    return {
      width: 100,
      editable: false,
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
    };
  }, []);


  return (
    <>
      <Dropdown.Menu>
        <div className="notifyHead">
          <h5 className="headTxt">RO Notifications</h5>
        </div>
        <div className="boy" style={{ maxHeight: "500px", overflowY: 'scroll' }}>
          {checked?.length !== 0 ? (
            (checked || []).map((i) => (
              <>
               {i.Read_Flag === "0" &&(
                <Collapse in={checked} key={i}>
                  <NotificationBox 
                    title={i.operation_admin_approval_status}
                    Msg={i}
                    onClose={() => {
                      handleChange(i);
                    }}
                    date={i.Approval_Date}
                  />
                </Collapse>
                )}
              </>
            ))
          ) : (
            <div className="noNotification">
              No Notifications <NotificationsIcon />
            </div>
          )}
        </div>
        <div className="" style={{ borderTop: "1px solid red", display: 'flex', justifyContent: "space-between" }}>
          <Button className="toast-button" onClick={() => handleViewAllNotification()}>View All</Button>

          <Button className="toast-button" onClick={() => handleReadAllNotification()}>Read All</Button>
        </div>
      </Dropdown.Menu>

      <Modal
        show={isShowAllNotification}
        onHide={() => setIssShowAllNotification(false)}
        animation={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header style={{ background: "#eb6e12", color: "white" }} closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "600px" }}>
          <div style={{ marginLeft: "10px", display: 'flex', justifyContent: 'space-between' }}>
            {/* <DatePicker /> */} <h1></h1>
            <Button className="toast-button" onClick={() => handleReadAllNotification()}>Read All</Button>
          </div>
          <div style={{ height: "93%", width: "100%" }} className="ag-theme-alpine">
            <AgGridReact
              suppressExcelExport={true}
              suppressCsvExport={true}
              ref={gridRef}
              rowData={notify_data}
              columnDefs={columnDefs}
              columnTypes={columnTypes}
              defaultColDef={defaultColDef}
              animateRows={true}
              components={components}
              rowStyle={{ background: '#DEDEDE', color: '#000000DE' }}
              pagination={true}
              getRowHeight={() => 90}
            ></AgGridReact>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}
