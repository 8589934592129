import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./style.css";
import Badge from "@mui/material/Badge";
import { DataContext, SupportDataContext } from "../../provider/context";
import CustomCalender from "../CustomCalender/CustomCalender";
import {
  getTotalLength,
  handleDefaultFilters,
  handleFilters,
} from "../../utils/Filters";
import LeadsFilterAdvancedMain from "../../pages/ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import CommonPageSelect from "../../commonComp/commonPageSelect";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomPaginationTable = ({
  apiData,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
  columnDefs,
  filters,
  isCommonPageSelect,
  setCurrentPageIndex,
  currentPageIndex,
  totalCount,
  gridRef
}) => {
  const {
    setSelected,
    setSalesInfo,
    setIsOpen,
    isOpen,
    message,
    setIsCompleteOrderBtnDisable,
  } = useContext(SupportDataContext);
 
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);

  useEffect(() => {
    handleListingSavedSearch();
  }, []);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);


  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
     setCurrentPageIndex(Number(value))
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  const handleNextPage = () => {
    gridRef.current.api.showLoadingOverlay();
    setCurrentPageIndex(currentPageIndex + 50)
  }

  const handlePreviousPage  = () => {
    gridRef.current.api.showLoadingOverlay();
    setCurrentPageIndex(currentPageIndex - 50)
  }

  return (
    <>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {apiData?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(apiData, val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleDefaultFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.title,
                          val.value,
                          val.key
                        )
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(
                        apiData,
                        val.Search_Name,
                        valueKey
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}

      { isCommonPageSelect && (
         <CommonPageSelect
         search
         onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
         searchValue={searchValue}
         onPageSizeChanged={onPageSizeChanged}
         handleGotoPage={handleGotoPage}
         fromRows={fromRows}
         toRows={toRows}
         length={apiData?.length}
       />
      )         
      }
     
      <div
        style={{ height: "500px", maxHeight: "550px", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          suppressPaginationPanel={true}
        ></AgGridReact>
      </div>
      <div className="custom-footer-pagination">
          <div className="pagination-footer-labels">
            {
              currentPageIndex > 0 && (
                <p onClick={handlePreviousPage}><AiOutlineLeft style={{ margin: "0 10px" }} />Previous </p>
              )
            }      
            <p>{currentPageIndex + 1} to {currentPageIndex + 50} of {totalCount}</p>
            {currentPageIndex < totalCount && (
                <p onClick={handleNextPage}>Next <AiOutlineRight style={{ margin: "0 10px" }} /></p>
            )}
           
          </div>
        </div>
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomPaginationTable;
