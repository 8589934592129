import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import {
  SupportDataContext,
  DataContext,
} from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from "@mui/material/Radio";
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from "@mui/material/RadioGroup";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import { VpnLock } from "@material-ui/icons";
import { addDays } from "date-fns";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CustomUploadFile from "../../../../../reusableComponents/CustomUploadFile/CustomUploadFile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "560px",
  maxHeight: "560px",
  overflow: "auto",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};

const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddRevisedNOFForm = ({ classes }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    operationInfo,
    salesInfo,
    rowData,
    approvedCPSubleadData,
    nofOtherData,
    getRevisedProjectListapi,
    setButtonText
  } = useContext(SupportDataContext);

  const [enableReinitialize, setEnablReinitializei] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [disabledId, setDisabledId] = useState([]);
  const fileInput = React.createRef();

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },
  }));
  const changesStatusOptions = nofOtherData?.Status_Details_List?.map(
    (item) => {
      const options = { value: item?.Name, label: item?.Name };
      return options;
    }
  );

  const revisedNOFPOSTPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const user_name = localStorage.getItem("Employee_First_Name");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/RevisedNOF/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);
    formData.append("upload_docs", values?.uploadDoc);
    formData.append(
      "order_id",
      Number(operationInfo?.Order_Id || operationInfo?.order_id)
    );
    formData.append("order_no", operationInfo?.Order_No || operationInfo?.order_no);
    formData.append("project_id", Number(operationInfo?.project_id));
    formData.append(
      "project_name",
      operationInfo?.project_name
    );
    formData.append("remarks", values?.comments);

    if(editModalPopper) {
        formData.append(
            "update_id",
            operationInfo?.update_id
          );
        axios.put(url, formData, { headers: headers }).then((data) => {
            setLoading(false);
           
            if (data?.data?.message) {
              setMessage(`${data?.data?.message}`);
              setSeverity(500);
            } else {
              setMessage(data?.data?.data?.msg);
              setSeverity(data?.data?.data?.code);
            }
            setButtonText("Revised NOF Listing");
            setIsOpen(true);
            handleModaleClose();
            getRevisedProjectListapi(operationInfo?.Order_No || operationInfo?.order_no);
          });
    } else {
        axios.post(url, formData, { headers: headers }).then((data) => {
            setLoading(false);
           
            if (data?.data?.message) {
              setMessage(`${data?.data?.message}`);
              setSeverity(500);
            } else {
              setMessage(data?.data?.data?.msg);
              setSeverity(data?.data?.data?.code);
            }
            setButtonText("Revised NOF Listing");
            setIsOpen(true);
            handleModaleClose();
            getRevisedProjectListapi(operationInfo?.Order_No || operationInfo?.order_no);
          });
    } 
  };

  return (
    <>
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={{
          uploadDoc: editModalPopper ? operationInfo?.Upload_Document : "",
          comments: editModalPopper ? operationInfo?.remarks : "",
        }}
        onSubmit={async (data) => {
          setLoading(true);
          revisedNOFPOSTPUTAPI(data);
        }}
        validate={(values) => {
          let errors = {};
          if (values.uploadDoc === "") {
            errors.uploadDoc = "required";
          }
          if (values.comments === "") {
            errors.comments = "required";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        setButtonText("Revised NOF Listing");
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      height: "480px",
                      overflowX: "hidden",
                      padding: "0",
                    }}
                  >
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10,
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            {editModalPopper
                              ? `Edit Revised NOF`
                              : `Add Revised NOF`}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.quotationInnerGridRight}
                            >
                              <CustomUploadFile
                                label="Upload Document"
                                uploadDoc={values?.uploadDoc}
                                uploadType="single"
                                fieldHandleChange={(e) => {
                                  setFieldValue(
                                    `uploadDoc`,
                                    e.currentTarget.files?.[0]
                                  );
                                }}
                                requiredField={true}
                              />
                              <span style={{ color: "red" }}>
                                    {errors.uploadDoc &&
                                      touched.uploadDoc &&
                                      errors.uploadDoc}
                                  </span>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.quotationInnerGridRight}
                            >
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label
                                  column
                                  sm="5"
                                  className="fc-textarea-label"
                                >
                                  Remarks <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                  <div
                                    className={
                                      classes.paymentReminderSelectContainerModal
                                    }
                                  >
                                    <textarea
                                      style={{ width: "100%" }}
                                      name="comments"
                                      value={values.comments}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        setFieldValue(
                                          "comments",
                                          e.target.value
                                        );
                                      }}
                                      rows="5"
                                      cols="50"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                              <span style={{ color: "red" }}>
                                    {errors.comments &&
                                      touched.comments &&
                                      errors.comments}
                                  </span>
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal}>
                      <BootstrapTooltip
                        arrow={true}
                        disableFocusListener
                        title={
                          errors.order_status === undefined &&
                          errors.uploadDoc === undefined
                            ? ""
                            : `${
                                errors.order_status !== undefined
                                  ? `${errors.order_status},`
                                  : ""
                              }
                  ${
                    errors.Nationality !== undefined
                      ? `${errors.Nationality},`
                      : ""
                  }`
                        }
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{ backgroundColor: "#eb6e12" }}
                          onClick={handleSubmit}
                        >
                          {loading ? "Loading...." : "Submit"}
                        </Button>
                      </BootstrapTooltip>
                      <Button
                        onClick={() => {
                            setButtonText("Revised NOF Listing");
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(AddRevisedNOFForm);
