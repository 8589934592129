import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import "../../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../ModelPopper";
import axios from "axios";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../../utils/Filters";
import ReplyIcon from '@mui/icons-material/Reply';
import ViewReplyDetailsTable from "./ViewReplyDetailsTable";
import {
    FaEye,
} from "react-icons/fa";




const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewCommentTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        setSalesInfo,
        setEditModalPopper,
        setButtonText,
        setSelected, setLoading,
        handleModalOpen,
        message, setMessage,
        isOpen, setIsOpen, severity,
        getCommentListDataApi, commentListData, setRowData,
        isCompleteOrderBtnDisable
    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);

    useEffect(() => {
        getCommentListDataApi();
        handleListingSavedSearch();
    }, []);

    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
                return -1;
            }
            if (dateAsString == "") {
                return -1;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const renderExpandableRow = (value) => {
        setSalesInfo(value?.data);
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        marginLeft: "4px",
                        width: "99%",
                        height: "301px",
                        minHeight: "40px",
                        overflowX: "scroll",
                        overflowY: "scroll",
                        border: "1px solid white",
                    }}
                >
                    {value.data.handleview === 0 ? (
                        <div style={{ width: "100%" }}>
                            <ViewReplyDetailsTable />
                        </div>
                    ) : value.data.Status === "converted" &&
                        value.data.handleview === 1 ? (
                        <div> No View</div>
                    ) : (
                        <ViewReplyDetailsTable />
                        // <EcFollowUpTable values={value?.data} height="240px" subLead />
                    )}
                </div>
            </>
        );
    };
    const detailCellRenderer = useMemo(() => {
        return renderExpandableRow;
    }, []);

   const dateFilterParams = {
    inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
                return 0;
            }
            const dateParts = dateAsString.slice(0, 10).split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const columnDefs = [
        {
            headerName: "S #",
            field: "Serial_Number",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
            minWidth: 40,
            pinned: "left",
            filter: false
        },
        {
            headerName: "Actions",
            field: "Inquiry_Id",
            filter: false,
            // checkboxSelection: true,
            cellRenderer:
                (params) => {
                    return (
                        <>
                            <CustomIconButton
                                title="Reply"
                                disabled={
                                    isCompleteOrderBtnDisable
                                }
                                icon={
                                    <ReplyIcon
                                        onClick={() => {
                                            handleModalOpen();
                                            setEditModalPopper(true);
                                            setRowData(params.data);
                                            setButtonText("Add Comments");
                                            //  getAdminCompalinFormDataApi(params.data)
                                        }}
                                    />
                                }
                            />
                            <CustomIconButton
                                title="View Old Reply"
                                icon={<FaEye />}
                                onClick={() => {
                                    if (params.node.expanded === false) {
                                        if (params.data.handleview === 1) {
                                            setRowData(params.data);
                                            //setfirst("order");
                                            // params.node.setExpanded(true);
                                        } else {
                                            params.node.setExpanded(true);
                                            setRowData(params.data);
                                        }
                                    } else {
                                        params.node.setExpanded(false);
                                    }
                                    //setButtonText("View Detail");
                                    setRowData(params.data);

                                    //setEditForm(true);
                                    //handleModalOpen();
                                }}
                            // disabled={
                            //   salesInfo?.compliance_status === "Approved" || salesInfo?.compliance_status === "Rejected " ? true : false
                            // }
                            />
                        </>
                    );
                },
            cellStyle: { border: "1px solid #fff", overflowY: 'auto' },
            minWidth: 110,
            pinned: "left",
        },
        // {
        //   headerName: "Complaint Id",
        //   field: "complaint_id",
        //   filter: false,
        //   // checkboxSelection: true,
        //  // cellRenderer: actionBtnRenderer,
        //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
        //   minWidth: 130,
        //   pinned: "left",
        // },
        {
            headerName: "Order Id",
            field: "Order_Id",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 150,
            pinned: "left",
        },
        {
            headerName: "Main Comment",
            field: "Comment_Details",
            filter: "agTextColumnFilter",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 400,
            flex: 1,
            // cellRenderer: (params) => {
            //     params = params?.data?.Comment_Details;
            //     return (
            //         <p
            //             style={{
            //                 padding: "0 5px",
            //                 margin: "0 2rem 1rem 0",
            //             }}
            //             dangerouslySetInnerHTML={{ __html: params }}
            //         />
            //     );
            // },
        },
        {
            headerName: "Comment Date",
            field: "Comment_Datetime",
            filter: "agDateColumnFilter",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 200,
            filterParams: dateFilterParams,
            // cellRenderer: (params) => {
            //     return <>{moment(params?.data?.Comment_Datetime).format("DD-MM-YYYY hh:mm:ss")}</>;
            // },
        },
        {
            headerName: "Commented By",
            field: "Commented_By",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            flex: 1,
        },
        {
            headerName: "Latest Reply",
            field: "Commented_By",
            filter: "agTextColumnFilter",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 350,
            flex: 1,
            cellRenderer: (params) => {
                return (
                    params?.data?.Reply_List?.length === 0 ? "" : params?.data?.Reply_List?.[0].Reply_Details
                    )
            },
        },
        {
            headerName: "Reply Date",
            field: "Reply_Datetime",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            flex: 1,
            cellRenderer: (params) => {
                return <>{
                    params?.data?.Reply_List?.length === 0 ? "" :
                    moment(params?.data?.Reply_List?.[0].Reply_Datetime).format("DD-MM-YYYY hh:mm:ss")
                    }</>;
            },
        },
        {
            headerName: "Reply By",
            field: "Reply_By",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            flex: 1,
            cellRenderer: (params) => {
                return (
                    params?.data?.Reply_List?.length === 0 ? "" : params?.data?.Reply_List?.[0].Reply_By
                    )
            },
        },
        {
            headerName: "Commented By",
            field: "Commented_By_Role",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            flex: 1,
        },
       
    ];

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            filter: true,
            // enable floating filters by default
            floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            //flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };


    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    const filters = [
        {
            id: 1,
            title: "Default",
            value: null,
            key: null,
        },
    ];

    useEffect(() => {
        const parsedSavedValue = JSON.parse(savedValue);
        if (parsedSavedValue !== null) {
            let values = Object.keys(parsedSavedValue);
            values.map((val, i) => {
                if (parsedSavedValue[val].filterType === "text") {
                    gridRef.current.api.getFilterInstance(val).setModel({
                        type: "contains",
                        filter: parsedSavedValue[val].filter,
                    });
                } else {
                    gridRef.current.api
                        .getFilterInstance(val)
                        .setModel({ values: parsedSavedValue[val].values });
                }
            });
            gridRef.current.api.onFilterChanged();
        }
    }, [savedValue]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

    const handleListingSavedSearch = async () => {
        const __token = localStorage.getItem("token");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        await fetch(
            `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${__token}`,
                    "Content-Type": "application/json",
                },
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSavedSearchList(data.results?.Response);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className="listing-data-grid" style={{ height: "650px" }}>

            <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={commentListData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowStyle={{
                        background: "#DEDEDE",
                        color: "#000000DE",
                        borderBottom: "1px solid white",
                    }}
                    components={components}
                    masterDetail={true}
                    animateRows={true}
                    getRowHeight={() => 90}
                    pagination={true}
                    detailRowAutoHeight={true}
                    //onPaginationChanged={onPaginationChanged}
                    detailCellRenderer={detailCellRenderer}
                ></AgGridReact>

            </div>
            <ModalPopper />
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ViewCommentTable;
