import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import previewFormStyles from "../../Preview/previewStyle";
import { SupportDataContext } from "../../../../../provider/context";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/styles.css";
import { CKRictTextEditer } from "../../../../listing/listingForm/RichTextEditer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ViewProjectDescription = ({ classes }) => {
  const { operationInfo, handleModaleClose } = useContext(SupportDataContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    p: 4,
    height: "auto",
    paddingTop: "0px",
    maxHeight: "590px",
    overflowY: "scroll",
  };
  const [projectDesc, setProjectDesc] = useState("");
  const [openLoading, setOpenLoading] = useState(false);

  useEffect(() => {
    handleSubOrderTemplate();
  }, [operationInfo]);

  const handleSubOrderTemplate = () => {
    let projectDescriptionArray = operationInfo?.scope_of_work_for_sub_template;
    let projectDescription = projectDescriptionArray?.map((val) => {
      return val?.Description;
    });
    setProjectDesc(projectDescription?.toString());
  };

  return (
    <>
      <div className={classes.divModal}>
        <Box sx={style}>
          <IconButton
            style={{ backgroundColor: "#fffffff0" }}
            className={classes.headerIconCancle}
            onClick={() => {
              handleModaleClose();
            }}
          >
            <CloseIcon
              className={classes.headerIcon}
              style={{
                marginTop: "0px",
                marginBottom: "-45px",
                fontSize: "25px",
              }}
            />
          </IconButton>

          <Grid container spacing={2} columns={12}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "-30px",
                paddingBottom: "10px",
              }}
            >
              <br />
              <div className={classes.headingContainer}>
                <span className={classes.modalHeader}>
                  {"View Project Description"}
                </span>
              </div>
              {projectDesc?.length > 0 ? (
                <Grid
                  container
                  spacing={2}
                  columns={12}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12} md={12}>
                    <div
                      className="fc-input-container"
                      style={{ marginRight: 3 }}
                    >
                      <CKRictTextEditer
                        disabledField={true}
                        data={projectDesc}
                        height="auto"
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 10000,
                  }}
                  open={openLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default withStyles(previewFormStyles)(ViewProjectDescription);
