import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CustomSelect } from "../../pages/listing/listingForm/ListingForm";
import "./customDropdown.css";

const CustomDropdown = ({
  label,
  fieldValue,
  fieldHandleChange,
  isDisabled,
  fieldOptions,
  errorField,
  requiredField,
  isClearable
}) => (
  <>
    <Form.Group as={Row} className="mb-1" controlId={label}>
      <Form.Label column sm="5" className="fc-dropdown-label">
        {label} {requiredField && <sup style={{color:'red'}}>*</sup> }
      </Form.Label>
      <Col sm="7" style={{ padding: "0 12px 0 0" }}>
        <div className="fc-dropdown-container" style={errorField}>
          <CustomSelect
            value={fieldValue}
            onChange={fieldHandleChange}
            options={fieldOptions}
            isDisabled={isDisabled}
            isClearable={isClearable}
          />
        </div>
      </Col>
    </Form.Group>
  </>
);

export default CustomDropdown;
