import React from 'react'
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

    const CustomInput = React.forwardRef((props, ref) => {
        return (
          <>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              onClick={props.onClick}
            >
              <label
                style={{ width: "60%", paddingLeft: "10px" }}
                onClick={props.onClick}
                ref={ref}
              >
                {props.value || props.placeholder}
              </label>
              <CalendarTodayIcon
                style={{ paddingRight: "10px" }}
                onClick={props.onClick}
              />
            </div>
          </>
        );
      });

export default CustomInput