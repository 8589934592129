import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect, CustomMultiSelect, CustomSelected } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import { CKRictTextEditer } from "../../../../listing/listingForm/RichTextEditer";

import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import { CollectionsOutlined, VpnLock } from "@material-ui/icons";
import { Container } from "react-bootstrap";
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import AddIcon from "@mui/icons-material/Add";
import { addDays } from "date-fns";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow: 'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },

};

const getStyles = (errors, fieldName, height) => {

  if (height && !getIn(errors, fieldName)) {
    return { height: height }
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const options = [

  "Title Deed",
  "Passport Copy",
  "Pictures",
  "NOC from the owner for Marketing"
];

const ComplaintModalForm = ({ classes, getInfo }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    setIsOpen,
    editModalPopper,
    getAdminCompalintListDataApi,
    getAdminCompalinFormDataApi, adminCompalinFormData,
    getCompanyIdAdminComplaintFormData, setCompanyIdAdminComplaintFormData,
    operationInfo,
  } = useContext(SupportDataContext);

  const fileInput = React.createRef();
  const [enableReinitialize, setEnablReinitializei] = useState(false);
  const [getSupportId, setGetSupportId] = useState([
    operationInfo?.support_id,
  ]);
  const [companyName, setCompanyName] = useState(
    operationInfo?.company_name,
  );
  const [supportName, setSupportName] = useState(
    operationInfo?.support_coordinator_name,
  );
  const [orderName, setOrderName] = useState(
    operationInfo?.order_name,
  );
  const [orderNo, setOrderNo] = useState(operationInfo?.order_id);
 
  const [complaint_date, setComplaint_date] = useState(
    editModalPopper ? moment(operationInfo?.complaint_date).toDate() :
    moment().toDate()
  );
  const CompanyList = adminCompalinFormData?.company_list?.map((item) => {
    const obj = { value: item.Company_Id, label: item.Company_Name };
    return obj;
  });

  const CompanyOrderList = adminCompalinFormData?.company_order_list?.map((item) => {
    const obj = { 
       value: item.Order_No,
       label: item.Order_Name, 
       Order_No: item?.Order_No,
       Support_Name: item?.Support_Name,
       Support_Id: item?.Support_Id,

       };
    return obj;
  });

  const EmployeeList = adminCompalinFormData?.employee_list?.map((item) => {
    const obj = { value: item.EmpId, label: item.Emp_Name };
    return obj;
  });
  // const EmployeeList2 = [{ value: getSupportName?.Support_Id, label: getSupportName?.Support_Name }];
  

  const addComplainttPostAPI = async (values) => {
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    //https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/
    const url = `https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const date = moment(values.complaint_date).format("YYYY-MM-DD hh:mm:ss")
    let formData = new FormData();
    formData.append("emp_id", emp_id);
    formData.append("user_type", user_type);
    formData.append("company_id", values.company_id);
    formData.append("company_name", companyName);
    formData.append("order_no", values.order_id);
    formData.append("order_name", orderName);
    formData.append("support_id", values.support_id);
    formData.append("support_name", supportName);
    formData.append("complaint_date", date);
    formData.append("complaint_details", values.complaint_details);
    formData.append("upload_document", values.uploadDoc);

    if (editModalPopper) {
      formData.append("complaint_id", operationInfo?.complaint_id);

      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);

        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        handlePromise(data);
      })
    } else {
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        handlePromise(data);
      })
    }

  }

  useEffect(() => {
    if (editModalPopper) {
      setCompanyIdAdminComplaintFormData(operationInfo?.company_id)
      // setOrderName(operationInfo?.order_name);
    }
    getAdminCompalinFormDataApi();
  }, [getCompanyIdAdminComplaintFormData, operationInfo, companyName])

  useEffect(() => {
   
      setOrderName(operationInfo?.order_name);
    
    
  }, [])

  const handlePromise = (data) => {
    // getAddDeveloperFormDataApi();
    handleModaleClose();
    getAdminCompalintListDataApi();
  }
  return (
    <>
      <Formik
        //enableReinitialize={enableReinitialize}
        initialValues={{
          company_id: editModalPopper ? operationInfo?.company_id : "",
          company_name: editModalPopper ? operationInfo?.company_name : "",
          order_name: editModalPopper ? operationInfo?.order_name : "",
          order_id: editModalPopper ? operationInfo?.order_id : "",
          support_name:  supportName,
          support_id: getSupportId,
          complaint_date: editModalPopper ? moment(operationInfo?.complaint_date).format("YYYY-MM-DD") : complaint_date,
          uploadDoc: editModalPopper ? operationInfo?.documents : "",
          complaint_details: editModalPopper ? operationInfo?.complaint_details : "",
        }}

        onSubmit={async (data) => {
          setLoading(true)
          //  editModalPopper ? updatePropertyPutAPI(data) : 
          addComplainttPostAPI(data);
          // getDeveoperPropertyListTableData();
        }}

        validate={(values) => {
          let errors = {};
          
          // if (!values.complaint_date) {
          //   errors.complaint_date = "Required!";
          // }
          if (!values.order_id) {
            errors.order_id = "Required!";
          }
          if (!values.company_id) {
            errors.company_id = "Required!";
          }
          if (!values.support_id) {
            errors.support_id = "Required!";
          }
          if (!values.complaint_details) {
            errors.complaint_details = "Required!";
          }
          if (!values.uploadDoc) {
            errors.uploadDoc = "Required!";
          }

          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div style={{ height: "480px", overflowX: "hidden", padding: "0" }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            {editModalPopper ? "Edit Complaint Form" : "Add Complaint Form"}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              className={classes.quotationInnerGridRight}
                            ><br />
                              <Container className="content" style={{ marginLeft: "10px" }}>

                                <div className="row">
                                  <div className="col-sm-12">
                             
                                    <div className="row mb-1">
                                    {
                                editModalPopper ?
                                 <div class="form-group col-md-6">
                                 <label style={{ fontSize: "12px", fontWeight: "bold" }}>Company Name<span style={{ color: "red" }}>*</span>:</label>
                                 <input type="text" value={operationInfo?.company_name} name="order_no" class="form-control" placeholder="Order No" disabled
                                   style={{
                                     width: "362px",
                                     fontSize: "12px", height: "25px"
                                   }}
                                   onChange={(e) => {
                                     const { value } =
                                       e.target;
                                       setFieldValue("order_no", value)
                                   }
                                   }
                                 />   
                               </div>
                               :
                                      <div class="form-group col-md-6">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}> Select Company <span style={{ color: "red" }}>*</span>:</label>
                                        <div className="fc-dropdown-container" style={{ width: '362px', }}>
                                          <CustomSelect
                                            name={`company_id`}
                                            value={
                                              values.company_id
                                            }
                                            onChange={(
                                              value
                                            ) => {
                                              setFieldValue(
                                                `company_id`,
                                                value.value
                                              );
                                              setCompanyName(value.label)
                                              setCompanyIdAdminComplaintFormData(value.value)
                                              
                                            }}
                                            options={
                                              CompanyList
                                            }
                                            disabled={
                                              editModalPopper ? true: false
                                            }
                                          />
                                        </div>
                                        {/* <span style={{ color: "red" }}>
                                          {errors.company_id}
                                        </span> */}
                                      </div>
                                        }
                               {
                                editModalPopper ?
                                 <div class="form-group col-md-6">
                                 <label style={{ fontSize: "12px", fontWeight: "bold" }}>Order Name<span style={{ color: "red" }}>*</span>:</label>
                                 <input 
                                 type="text" 
                                 value={operationInfo?.order_name} 
                                 name="order_no" class="form-control" 
                                 placeholder="Order No" 
                                 disabled
                                   style={{
                                     width: "362px",
                                     fontSize: "12px", height: "25px"
                                   }}
                                   onChange={(e) => {
                                     const { value } =
                                       e.target;
                                       setFieldValue("order_no", value)
                                   }
                                   }
                                 />   
                               </div>
                               :
                                      <div class="form-group col-md-6">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>Select Order<span style={{ color: "red" }}>*</span>: </label>
                                        <div className="fc-dropdown-container" style={{ width: '362px', }}>
                                          <CustomSelect
                                            name={`order_id`}
                                            value={
                                              values.order_id
                                            }
                                            onChange={(
                                              value
                                            ) => {
                                              setFieldValue(
                                                `order_id`,
                                                value.value
                                              );
                                              setOrderName(value.label)
                                              setOrderNo(value.Order_No)
                                              setGetSupportId(value?.Support_Id) 
                                              setSupportName(value?.Support_Name)
                                                                                         }
                                            }
                                            options={
                                              CompanyOrderList
                                            }
                                          />
                                        </div>
                                        {/* <span style={{ color: "red" }}>
                                          {errors.order_id}
                                        </span> */}
                                      </div>
                                      }

                                    </div>
       
                                    <div className="row mb-1">
                                      <div class="form-group col-md-6">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>Support Name <span style={{ color: "red" }}>*</span>: </label>

                                        <input type="text" value={supportName} name="order_no" class="form-control" placeholder="Support Name" disabled
                                   style={{
                                     width: "362px",
                                     fontSize: "12px", height: "25px"
                                   }}
                                   onChange={(e) => {
                                     const { value } =
                                       e.target;
                                       setFieldValue("order_no", value)
                                   }
                                   }
                                 />   
                                        {/* <div className="fc-dropdown-container" style={{ width: '366px', }}>
                                          <CustomSelect
                                            name={`support_id`}
                                            value={
                                              values.support_id
                                            }
                                            onChange={(
                                              value
                                            ) => {
                                              setFieldValue(
                                                `support_id`,
                                                value.value
                                              );
                                              setSupportName(value.label)
                                             
                                            }}
                                            options={
                                              EmployeeList2
                                            }
                                          />
                                        </div> */}
                                        {/* <span style={{ color: "red" }}>
                                          {errors.support_id}
                                        </span> */}
                                      </div>
                                      <div class="form-group col-md-6">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>Order No:</label>
                                        <input type="text" value={orderNo} name="order_no" class="form-control" placeholder="Order No" disabled
                                          style={{
                                            width: "362px",
                                            fontSize: "12px", height: "25px"
                                          }}
                                          onChange={(e) => {
                                            const { value } =
                                              e.target;
                                              setFieldValue("order_no", value)
                                          }
                                          }
                                        />
                                        
                                      </div>
                                    </div>
                                    <div className="row mb-1">
                                      <div class="form-group col-md-6">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>Complaint Date<span style={{ color: "red" }}>*</span>:</label>
                                       
                                        <div className="fc-dropdown-container" style={{ width: '366px', }}>
                                        <div
                                  style={getStyles(
                                    errors,
                                    "complaint_date"
                                  )}
                                >
                                  <div
                                    style={{  }}
                                  >
                                    <DatePicker
                                      selected={complaint_date}
                                      onChange={(date) => {
                                        setComplaint_date(date);
                                        setFieldValue(
                                          "complaint_date",
                                          date
                                        );
                                      }}
                                      
                                      dateFormat="dd-MM-yyyy h:mm aa"
                                     
                                      maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Complaint Date"
                                    />
                                  </div>
                                </div>
                                </div>

                                        {/* <div
                                          className={
                                            classes.paymentReminderSelectContainerModal
                                          }
                                        >
                                          <input type='date' name="complaint_date" value={values.complaint_date}
                                            min={new Date().toISOString().split('T')[0]}
                                            style={{ width: '360px', fontSize: "12px", height: "25px", }}
                                            onChange={(e) => {
                                              setFieldValue("complaint_date", e.target.value)
                                            }
                                            }
                                          />
                                        </div> */}
                                        {/* <span style={{ color: "red" }}>
                                          {errors.complaint_date}
                                        </span> */}
                                      </div>

                                      <div class="form-group col-md-4">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>Upload Docoment <span style={{ color: "red" }}>*</span>: </label>
                                        <div className={classes.businessCardFormGroupFlexDivModal}>

                                          <div
                                            className={classes.businessCardSelectContainerModal}
                                          >
                                            <div
                                              className={
                                                classes.businessCardDemoDataCheckModalUploadFile
                                              }
                                            >
                                              <input
                                                required
                                                type="file"
                                                // name = {"uploadDoc"}
                                                // value={operationInfo?.documents}
                                                multiple
                                                style={{ display: "none", }}
                                                onChange={(e) => {
                                                  // handleChangeInput(e, i)
                                                  // setFileUpload(e.target.files[0])
                                                  setFieldValue(
                                                    `uploadDoc`,
                                                    e.currentTarget.files[0]
                                                  );
                                                }}
                                                accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                ref={fileInput}
                                              />
                                              <button style={{
                                                padding: '6px 20px',
                                                backgroundColor: '#eb6e12',
                                                border: '1px solid #d3d3d3',
                                                color: '#fff',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                marginRight: "5px"
                                              }}
                                                className={
                                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                                }
                                                type="button"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  fileInput.current.click()
                                                }}
                                              >
                                                <PermMediaIcon
                                                  style={{ marginRight: "10px" }}
                                                />
                                                Add Media
                                              </button>

                                              <small
                                                className={
                                                  classes.businessCardDemoDataCheckModalUploadFileText
                                                }
                                              >
                                                {values.uploadDoc
                                                  ? values.uploadDoc.name ||
                                                  values.uploadDoc.split("/").pop()
                                                  : "No file choose"}
                                              </small>{" "}
                                              <small className={classes.uploadFileRemovet} onClick={() => {
                                                setFieldValue(
                                                  "uploadDoc",
                                                  ""
                                                )
                                              }}>

                                                {/* <span style={{ color: 'red' }}> x </span> */}
                                              </small>
                                            </div>

                                          </div>

                                        </div>
                                      </div>

                                    </div>
                                    <div className="row mb-1">
                                      <div class="form-group col-md-11">
                                        <label style={{ fontSize: "12px", fontWeight: "bold" }}>Remarks:</label>

                                        <div
                                          className={
                                            classes.RichTextEditerDiv
                                          }
                                          style={{ width: '795px', }}
                                        >

                                          <CKRictTextEditer
                                            height={"72px"}

                                            data={values.complaint_details}
                                            handleDataChange={(
                                              event,
                                              editor
                                            ) => {
                                              const data = editor.getData();
                                              setFieldValue(
                                                "complaint_details",
                                                data
                                              );

                                            }}
                                            handleBlur={handleBlur}
                                          />
                                        </div>

                                        {/* <textarea id="w3review" name="other_remarks" rows="1" cols="50" value={values.other_remarks}
                                          onChange={(e) => {
                                            setFieldValue("other_remarks", e.target.value)
                                          }
                                          }
                                        >
                                        </textarea> */}
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </Container>
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>

                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: "#eb6e12" }}
                        onClick={handleSubmit}
                      >
                        {loading ? "Loading...." : editModalPopper ? "Save Changes" : "Submit"}
                      </Button>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>

            </form>
          );
        }}
      </Formik>


    </>
  );
};

export default withStyles(ModalPopperFormStyles)(ComplaintModalForm);
