import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import previewFormStyles from "../../Preview/previewStyle";
import { SupportDataContext } from "../../../../../provider/context";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/styles.css";

const ViewOrderOnProject = ({ classes }) => {
  const { operationInfo, handleModaleClose } = useContext(SupportDataContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    p: 4,
    height: "auto",
    paddingTop: "0px",
    maxHeight: "590px",
    overflowY: "scroll",
  };

  // useEffect(() => {
  //   getProjectListDropdownapi();
  // }, [operationInfo]);

  return (
    <>
      <Formik>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <div className={classes.divModal}>
              <Box sx={style}>
                <IconButton
                  style={{ backgroundColor: "#fffffff0" }}
                  className={classes.headerIconCancle}
                  onClick={() => {
                    handleModaleClose();
                  }}
                >
                  <CloseIcon
                    className={classes.headerIcon}
                    style={{
                      marginTop: "0px",
                      marginBottom: "-45px",
                      fontSize: "25px",
                    }}
                  />
                </IconButton>

                <Grid container spacing={2} columns={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                    }}
                  >
                    <br />
                    <div className={classes.headingContainer}>
                      <span className={classes.modalHeader}>
                        {`View Order on Project (Company Name: ${operationInfo?.Company_Name})`} 
                      </span>
                    </div>

                    <div className={classes.headingContainer}>
                      <span className={classes.modalHeader}>
                        {"Scope of Work"}
                      </span>
                    </div>

                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex" }}
                        className={classes.followUpFormInnerGridLeft}
                      >
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.cellData}
                        >
                          Name
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.cellData}
                        >
                          Task Type
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Section
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Category
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Detail
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Work Type
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Days
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Amount
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Sequence
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Govt. Dept Name
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Spl. Instruction
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Tag Name
                        </Grid>
                      </Grid>

                      {operationInfo?.scope_of_work_array?.map(
                        (i) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                className={classes.followUpFormInnerGridLeft}
                              >
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.cellData}
                                >
                                  {i?.Name}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.cellData}
                                >
                                  {i?.Task_Type}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Section}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Category}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Detail}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Work_Type}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Days}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Amount}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Sequance}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Govt_Department}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Special_Instruction}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: i?.Tag_Name,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          );
                        }
                      )}
                    </>
                    <div className={classes.headingContainer} style={{height: "10px"}}>
                      <span className={classes.modalHeader}>
                        {""}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(previewFormStyles)(ViewOrderOnProject);
