const listingFormStyles = (theme) => ({
  listingFormConater: {
    overflow: "hidden!important",

    paddingLeft: " 10px!important",
    // paddingRight: '10px!important',
  },
  listFormLeftConater: {
    padding: "9px",
    height: "513px",
  },
  upperHeadingListingForm: {
    marginBottom: "8px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#eb6e12",
    display: "flex",
  },
  leftFormDetails: {
    paddingBottom: "10px!important",
    overflowY: "auto",
    padding: "0 10px",
    // marginTop: '-10px',
    height: "341px",
    marginTop: "-35px",
  },
  leftFormDetailsAssinment: {
    paddingBottom: "10px!important",
    overflowY: "auto",
    padding: "0 10px",
    marginTop: "-35px",
    minHeight: "500px",
    height: "auto",
  },
  formGroup: {
    marginRight: "-10px",
    marginLeft: " -10px",
    marginBottom: "-10px",
    display: "flow-root",
    alignItems: "center",
  },
  labelForm: {
    fontWeight: " 600!important",
    paddingLeft: "5px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    marginBottom: 0,
    fontSize: "12px",
    position: "relative",
    top: "2.5px",
  },
  labelFormGender: {
    fontWeight: " 600!important",
    paddingLeft: "5px",
    paddingRight: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    marginBottom: 3,
    fontSize: "12px",
    position: "relative",
    top: "2px",
  },
  modalDatacontainer: {
    fontSize: "12px",
    marginLeft: "auto",
    // float: 'right',
    marginRight: "2px",
  },
  selectContainer: {
    fontSize: "12px",
    float: "right",
    marginRight: "2px",
  },
  selectContainerMain: {
    fontSize: "12px",
    marginLeft: "auto",
    marginRight: "2px",
    width: "20vw",
  },
  selectContainerGender: {
    fontSize: "12px",
    float: "right",
    marginRight: "38px",
    marginTop: "9px",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      marginRight: "156px",
      marginTop: "8px",
    },
  },
  selectContainer2: {
    fontSize: "12px",
    float: "right",
    marginTop: "2px",
    marginRight: "2px",
  },
  listingSelectContainer: {
    fontFamily: " Times New Roman ",
    border: "1px solid #d3d3d3",
    padding: "2px 10px",
    marginRight: "2px",
  },
  spanText: {
    color: "#eb6e12",
    marginRight: " 85px",
  },
  listingFormInput: {
    minWidth: "131px",
    maxWidth: "131px",
    border: "1px solid #d3d3d3",
    float: "inherit",
    padding: "3.5px 10px",
    fontFamily: " Times New Roman ",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      minWidth: "250px",
      maxWidth: "250px",
    },
  },
  listingFormInputMain: {
    minWidth: "131px",
    maxWidth: "131px",

    border: "none",
    float: "inherit",
    padding: "3.5px 10px",
    fontFamily: " Times New Roman ",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      minWidth: "250px",
      maxWidth: "250px",
    },
  },
  listingFormTextArea: {
    minWidth: "131px",
    maxWidth: "131px",

    float: "inherit",
    border: "none",

    fontFamily: " Times New Roman ",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      minWidth: "250px",
      maxWidth: "250px",
    },
  },
  listingFormInputDate: {
    minWidth: "131px",
    maxWidth: "131px",
    border: "none",

    float: "inherit",
    padding: "2px 5.7px",
    fontFamily: " Times New Roman ",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      minWidth: "250px",
      maxWidth: "250px",
    },
  },
  listingFormIcons: {
    fontSize: "20px !important",
    marginBottom: "-4px",
    color: "#eb6e12",
    fontWeight: "bold",
    cursor: "pointer",
  },
  listFormLeftConaterBottom: {
    padding: "10px",
  },
  leftFormDetailsBottom: {
    height: "120px!important",
    paddingBottom: "10px!important",
    overflowY: "auto",
    padding: "0 10px",
    marginTop: "-10px",
  },
  selectwithbtn: {
    display: "grid",
    width: "50%",
    /* margin-left: -109px; */
  },
  addNewBtnBottom: {
    float: "right",
    marginRight: "15px",
    fontSize: "11px",
    padding: "2px",
    cursor: "pointer",
    backgroundColor: "#fff !important",
    border: "none",
    color: "#eb6e12",
    fontFamily: " Times New Roman ",
  },
  listingFormInput1: {
    width: "15% !important",
    border: "1px solid #d3d3d3",
    float: "inherit",
    padding: "2px 10px",
    fontFamily: " Times New Roman ",
    marginRight: "4px",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      minWidth: "250px",
      maxWidth: "250px",
    },
  },
  selectContainer1: {
    fontSize: "12px",
    float: "right",
    display: "contents",
    marginRight: "10px",
  },
  inputGroupLableDiv: {
    position: "relative",
    display: "initial",
    borderCollapse: "separate",
  },
  tablecellAddOn: {
    padding: "4px 2px 4px 2px",
    display: "table-cell !important",
    color: "#999",
    fontSize: "12px",
    borderColor: "#e5e5e5",
    background: "#e5e5e5",
    fontWeight: 400,
    lineHeight: 1,
    textAlign: "center",
    backgroundColor: "#eee",
    border: "1px solid #ccc",
  },
  datalistInputDropDown: {
    float: "right",
    width: "90%",
    fontFamily: " Times New Roman ",
    border: "1px solid #d3d3d3",
    padding: "5px 10px",
  },
  datalistDropDown: {
    width: "10% !important",
    backgroundColor: "red !important",
  },
  datalistDropDownOptions: {
    fontFamily: " Times New Roman !important",
    width: "50px",
    backgroundColor: "red !important",
  },

  helpIcon: {
    fontSize: "13px !important",
    position: "relative",
    top: "-3px",
  },
  RichTextEditerDiv: {
    marginTop: "5px",
  },
  saveButton: {
    backgroundColor: "#eb6e12 !important",
    fontFamily: "Times New Roman !important",
  },
  CancleButtonButton: {
    backgroundColor: "#fff !important",
    fontFamily: "Times New Roman !important",
    color: "#eb6e12 !important",
    marginLeft: "10px !important",
  },
  ButtonDivListingTabListing: {
    padding: "10px !important",
  },
  BorderSpacingUpperListing: {
    width: "100%",
    borderTop: "5px solid #eb6e12 ",
  },
  BorderSpacingLowerListing: {
    width: "100%",
    borderBottom: "5px solid #eb6e12 ",
  },
  formGroupFlexDiv: {
    display: "flex",
    marginBottom: "-5px",
  },
  modelFlexDiv: {
    display: "flex",
    // marginBottom: '-5px'
  },
  labelFormInput: {
    width: "64%",
    fontWeight: " 600!important",
    paddingLeft: "5px",
    paddingRight: "9px",

    marginTop: "6px",
    marginBottom: 0,
    fontSize: "12px",
    position: "relative",
    top: "-1px",
  },
  modalLable: {
    fontWeight: " 600!important",
    textAlign: "left",
    fontSize: "12px",
  },
  formCheckBoxDiv: {
    marginLeft: "auto",
    float: "right",
    display: "flex",
    marginTop: "-3px",
  },
  formCheckBoxDataSpan: {
    fontSize: "9px",
    display: "flow-root",
    marginRight: "-3px",
    marginTop: "-12px",
    marginBottom: "-15px",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      marginRight: "115px",
    },
  },
  formCheckBoxDataSpan1: {
    fontSize: "9px",
    display: "flow-root",
    marginRight: "-4px",
    marginTop: "-7px",

    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      marginRight: "114px",
    },
  },
  textSpan: {
    position: "relative",
    top: "1.5px",
  },
  demoDataCheck: {
    border: "0.1px solid #d3d3d3",
  },
  demoDataCheckTextArea: {
    border: "0.1px solid #d3d3d3",
    display: "grid",
    marginBottom: "2px",
  },
  selectDropdpenReact: {
    border: "none !important",
  },
  listingEditListingEditFormGroup: {
    marginBottom: "-13px",
  },
  popperText: {
    display: "flex",
    justifyContent: "center",
    marginTop: "6px",
  },

  listingFormValidationMsg: {
    fontSize: "11px",
    float: " right",
    color: " red",
    marginTop: " 9px",
    marginBottom: " -6px",
    // marginRight: '12px',
  },
  listingFormValidationMsgGeneration_date: {
    fontSize: "11px",
    float: " right",
    color: " red",
    marginTop: " -2px",
    // marginBottom: ' -10px',
    marginRight: "-8px",
  },
  listingFormValidationMsg_date_of_birth: {
    fontSize: "11px",
    float: " right",
    color: " red",
    marginTop: " -1px",
    // marginBottom: ' -10px',
    marginRight: "-8px",
  },
  radioButtonYes: {
    marginTop: "-2.5px",
    marginRight: " 10px",
    marginLeft: " 2px",
  },
  radioButtonNo: {
    marginTop: "-2.5px",
    marginLeft: " 2.5px",
  },
  dateChangeEditer: {
    width: 131,
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      marginRight: "250px",
    },
  },
});
export default listingFormStyles;
