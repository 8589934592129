import React, { useState } from "react";
import { DataContext } from "./context";

import { columns, originalRows } from "../utils/tableData";
import BuildIcon from "@mui/icons-material/Build";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import moment from "moment";
import axios from "axios";
import { getLocalData, headers } from "../services/services";

const dommyData = [
  {
    agreementName: "product",
    comapnyName: "flipkart",
    firstPartylName: "jhon",
    generationDate: "01/15/2022",
    leadCaption: "sale",
    leadSource: "website",
    legalType: "website",
    state: "India",
    typeOfActivity: "saling",
    action: [
      <BuildIcon className="dataIcons" />,
      <CheckCircleIcon className="dataIcons" />,
      <RemoveRedEyeIcon className="dataIcons" />,
      <AcUnitIcon className="dataIcons1" />,
    ],
    typeOfCompany: "e-commerce",
  },
  {
    agreementName: "product1",
    comapnyName: "amazon",
    firstPartylName: "jhon",
    generationDate: "01/15/2022",
    leadCaption: "dat",
    leadSource: "website",
    legalType: "website",
    state: "India",
    typeOfActivity: "saling",
    action: [
      <BuildIcon className="dataIcons" />,
      <CheckCircleIcon className="dataIcons" />,
      <RemoveRedEyeIcon className="dataIcons" />,
      <AcUnitIcon className="dataIcons1" />,
    ],
    typeOfCompany: "e-commerce",
  },
  {
    agreementName: "product",
    comapnyName: "mytra",
    firstPartylName: "jhon",
    generationDate: "01/15/2022",
    leadCaption: "sale",
    leadSource: "website",
    legalType: "website",
    state: "India",
    typeOfActivity: "saling",
    action: [
      <BuildIcon className="dataIcons" />,
      <CheckCircleIcon className="dataIcons" />,
      <RemoveRedEyeIcon className="dataIcons" />,
      <AcUnitIcon className="dataIcons1" />,
    ],
    typeOfCompany: "e-commerce",
  },
  {
    agreementName: "",
    comapnyName: "",
    firstPartylName: "",
    generationDate: "",
    leadCaption: "",
    leadSource: "",
    legalType: "",
    state: "",
    typeOfActivity: "",
    action: [
      <BuildIcon className="dataIcons" />,
      <CheckCircleIcon className="dataIcons" />,
      <RemoveRedEyeIcon className="dataIcons" />,
      <AcUnitIcon className="dataIcons1" />,
    ],
    typeOfCompany: "",
  },
];

let todayFrom = moment().format().slice(0, 10);
let todayTo = moment().format().slice(0, 10);
const DataProvider = ({ children }) => {
  const [fromDate, setFromDate] = useState(todayFrom);
  const [toDate, setToDate] = useState(todayTo);
  const [start_Date, setStart_Date] = useState();
  const [end_Date, setEnd_Date] = useState();
  const [dataArr, setDataArr] = useState(dommyData);
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [buttonTextNew, setButtonTextNew] = useState("");
  const [masterState, setMasterState] = useState("");
  const [businessInfo, setBusinessInfo] = useState("");
  const [leadCaption, setLeadCaption] = useState("");

  const [followUp, setFollowUp] = useState([]);
  const [serviceId, setServiceId] = useState();
  const [dataApi, setDataApi] = useState([]);
  const [modalTable, setModalTable] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);

  const [selected, setSelected] = useState([]);
  const [dashboardToken, setDashBoardToken] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [formApiMsg, setFormApiMsg] = useState("");
  const [formApiMsgError, setFormApiMsgError] = useState("");
  const [clientRowData, setClientRowData] = useState([]);
  const [editSaveButton, setEditSaveButton] = useState(false);
  const [fieldsEditing, setFieldsEditing] = useState(false);
  const [fieldsEditingMain, setFieldsEditingMain] = useState(true);
  const [factoryId, setFactoryId] = useState("");
  const [clickObjData, setClickObjData] = useState("");
  const [subleadData, setSubleadData] = useState("");
  const [inquiryDropdown, setInquiryDropdown] = useState(null);
  const [filterData2, setFilterData2] = useState(null);
  const [sharedLeadData, setSharedLeadData] = useState([]);
  const [clientReviewArr, setClientReviewArr] = useState([
    {
      authority: "zcz",
      created: "03/2/2022",
      inquiryId: "233",
      remarks: "zxczx",
      uploadImg: "",
    },
    {
      authority: "zcz",
      created: "03/2/2022",
      inquiryId: "233",
      remarks: "zxczx",
      uploadImg: "",
    },
  ]);
  const [provisionalInvoiceArr, setProvisionalInvoiceArr] = useState([]);
  const [viewProvisonalInvoiceArr, setViewProvisionalInvoiceArr] = useState([]);
  const [editProvisonalInvoice, setEditProvisionalInvoice] = useState(false);
  const [viewQuotationVT, setViewQuotationVT] = useState([]);
  const [editQuotationVT, setEditQuotationVT] = useState(false);
  const [editOrderFormVT, setEditOrderFormVT] = useState(false);
  const [companyFlag, setCompanyFlag] = useState(0);
  const [signedSaArr, setSignedSaArr] = useState([
    {
      createdDate: "03/2/2022",
      inquiryId: "321",
      remarks: "remarks",
      uploadDoc: "",
    },
    {
      createdDate: "03/2/2022",
      inquiryId: "123",
      remarks: "remarks",
      uploadDoc: "file",
    },
  ]);
  const [paymentReminderArr, setPaymentReminderArr] = useState([]);
  const [subLeadParams, setSubLeadParams] = useState(null);

  const [quotationTableArr, setQuotationTableArr] = useState([]);
  const [quotationTableVT, setQuotationTableVT] = useState([]);
  const [clientmanagerquotationTableArr, setClientmanagerquotationTableArr] =
    useState([]);

  const [authToken, setAuthToken] = useState("");
  const emp_id = localStorage.getItem("emp_id");
  const __token = getLocalData("token");
  const factory_id = getLocalData("factory_id");
  const userTypeInfo = localStorage.getItem("userType");
  const salesUserTypeFind = userTypeInfo && userTypeInfo.includes("SALES");
  const userTYpeInfoCheck = salesUserTypeFind ? "SALES_PERSON" : "RECEPTIONIST";
  const [addInquiryBulkList, setAddInquiryBulkList] = useState([]);
  const [supportBadges, setSupportBadges] = useState([]);
  const [pdBadges, setPDBadges] = useState([]);


  const businessCardListingAPI = async (row) => {
    let rowInquiryId = row.Inquiry_Id.replace("FC", "");
    await fetch(
      `https://flyingcolour.online/fcapp/api/businesscardlisting/?cardids=4&enquiryids=${rowInquiryId}&factory_id=${factory_id}&no_of_record=2&limit=1`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFollowUp(data ?.results ?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const assignReassignLogAPI = async (value) => {
    let rowInquiryId = salesInfo.Inquiry_Id ?.replace("FC", "");
    setLoading(true);
    await fetch(
      value ?.Company_ID
        ? `https://flyingcolour.online/fcapp/spm_api/companyassignreassignloglisting/?company_id=${value ?.Company_ID}`
        : `https://flyingcolour.online/fcapp/api/inquiryassignreassignloglisting/?enquiryids=${rowInquiryId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (value ?.Company_ID !== undefined) {
          setPaymentReminderArr(data ?.results ?.Assign_Reassign_Log_List);
          setLoading(false);
        } else {
          setPaymentReminderArr(data ?.results ?.Response);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [listingParams, setListingParams] = useState({});
  const [apiData, setApiData] = useState(null);
  const [tokenData, setTokenData] = useState(false);
  const [authTokenData, setAuthTokenData] = useState(false);
  const [editApiData, setEditApiData] = useState([]);
  const [expirToken, setExpirToken] = useState(false);
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredErrorMsg, setExpiredErrorMsg] = useState(false);

  const [detail, setDetail] = useState([]);
  const [detail1, setDetail1] = useState([]);
  const [clientDetail, setClientDetail] = useState([]);
  const [declineList, setDeclineList] = useState(null);
  const [editCard, setEditCard] = useState(false);
  const [editPropsal, setEditPropsal] = useState(false);
  const [quotationEditModal, setQuotationEditModal] = useState(false);
  const [quotationEditModal1, setQuotationEditModal1] = useState(false);
  const [quotationEditArr, setQuotationEditArr] = useState([]);
  const [disableField, setDisableField] = useState(true);
  const [salesInfo, setSalesInfo] = useState([]);
  const [salesInfo2, setSalesInfo2] = useState();

  const [message, setMessage] = useState("");
  const [apiResponse, setResponse] = useState("");
  const [severity, setSeverity] = useState("");
  const [isShedule, setIsShedule] = useState("");

  const [enqAlerts, setEnqAlerts] = useState([]);
  const [todayEnqAlerts, setTodayEnqAlerts] = useState([]);
  const [meetingView, setMeetingView] = useState([]);
  const [saData, setSAData] = useState([]);
  const [inqWOFutureAlert, setInqWOFutureAlert] = useState([]);
  const [companyWOFutureAlert, setcompanyWOFutureAlert] = useState([]);
  const [shareholderData, setshareholderData] = useState([]);
  const [shareholderData1, setshareholderData1] = useState([]);
  const [subleadservicesList, getSubleadservicesList] = useState([]);
  const [companyhistoryData, setCompanyHistoryData] = useState([]);
  const [companyportfolioData, setCompanyPortfolioData] = useState([]);
  const [companyportfolioData1, setCompanyPortfolioData1] = useState([]);
  const [portfolioservicesList, getportfolioservicesList] = useState([]);
  const [portfolioservicesList1, getportfolioservicesList1] = useState([]);
  const [ReasonforUnsubscribeList, getReasonforUnsubscribeList] = useState([]);
  const [statusList, getStatusList] = useState([]);
  const [statusList1, getStatusList1] = useState([]);
  const [PIRow, setPIRow] = useState([]);
  const [error, setError] = useState();
  const [errorAlert, setErrorAlert] = useState(false);
  const [editView, setEditView] = useState();
  const [alertECsForOtherCompanyCMData, setAlertECsForOtherCompanyCMData] = useState([]);
  const [parentCompanyData, setParentCompanyData] = useState();
  const [getParamsData, setParamsData] = useState();
  const [existingPartnerList, setExistingPartnerList] = useState([]);
  const [tabChange, setTabChange] = useState();
  const [countBadges, setCountBadges] = useState([]);
  const [tabValue, setTabValue] = useState("");
  const [isDeclinedLeadDisabledBtn, setIsDeclinedLeadDisabledBtn] = useState('');
  const [decline_date_from, setDeclineDateFrom] = useState();
  const [decline_date_to, setDeclineDateTo] = useState();
  const [lead_assign_date_from, setLeadAssignDateFrom] = useState();
  const [lead_assign_date_to, setLeadAssignDateTo] = useState();
  const [alertTabValue, setAlertTabValue] = useState("");
  const [sharedData, setSharedData] = useState([]);









  let listingResponse = JSON.parse(localStorage.getItem("listing_api_res"));
  // const add_new_data = JSON.parse(localStorage.getItem('add_new_data'))
  let employeeFirstName = localStorage.getItem("Employee_First_Name");
  let employeeLastName = localStorage.getItem("Employee_Last_Name");
  const [subLeadInfo, setSubLeadInfo] = useState([]);
  const handleModalTableOpen = (item, row) => {
    if (item === "Add Business Card") {
      businessCardListingAPI(row);
    } else if (item === "Inquiry Assign/Reassign Log") {
      assignReassignLogAPI(row);
    } else if (item === "Add Sub Lead") {
      setSalesInfo(row);
      getSubleadList();
    } else if (item === "Add Shareholder") {
      getShareholderList(row);
    } else if (item === "Company History") {
      getCompanyHistoryList(row);
    } else if (item === "Company Portfolio") {
      getCompanyPortfolioList(row);
    } else if (item === "Add View Reply") {
      fetchOutsourceServiceReplyListingData(row);
    }

    setModalTable(true);
  };

  const handleModalTableClose = () => {
    setModalTable(false);
    setExistingPartnerList([])
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModaleClose = () => {
    setModalOpen(false);
  };

  const fetchListingDataFromApi = async () => {
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      const url = `https://flyingcolour.online/fcapp/api/inquiry/?emp_id=${empId}&factory_id=${factory_ids}&user_type=${userTypeInf}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      if (
        res ?.data ?.results === undefined
      ) {
        return setErrorAlert(true), setError(`ERROR ${res.data.code}-${res.data.msg}${" "}${"Error is from My Inquiry"}`);
      } else if (res ?.data ?.detail !== undefined) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res.data.msg}${" "}${"Error is from My Inquiry"}`);
      } else {
        setApiData(res ?.data ?.results);
        setInquiryDropdown(res ?.data);
        // const idb = window?.indexedDB;
        // const dbPromise = idb.open("inquiry-db", 2);

        // dbPromise.onsuccess = () => {
        //   const db = dbPromise.result;
        //   const tx = db.transaction('listing_api_res', 'readwrite');
        //   const data = tx.objectStore('listing_api_res');
        //   res?.data?.results?.forEach((item) => {
        //     data.put(item);
        //   })
        //     tx.oncomplete = () => {
        //       db.close();
        //     }


        //     tx.onerror = () => {
        //       db.close();

        //     }
        // }

      }
    } catch (error) {

      if (error ?.response ?.status === 401) {
        return setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
      }
      else if (error ?.response) {
        return setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${"Please try again!!"}`)
      }
      else if (!error ?.response) {
        return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
      }
    }
  };
  const [otherCompanies, setOtherCompany] = useState([]);

  const fetchOtherCompanyECListingData = async () => {
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      const url = `https://flyingcolour.online/fcapp/spm_api/cm_companylist/?emp_id=${empId}&factory_id=${factory_ids}&user_type=${userTypeInf}&company_flag=3`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOtherCompany(res ?.data ?.results ?.CM_Company_List);
    } catch (err) {

    }
  };
  const [CompanyList, setCompanyList] = useState("");
  const [ServiceList, setServiceList] = useState("");
  const FetchcompanyInfoData1 = async (data) => {
    try {
      let empId = localStorage.getItem("emp_id");
      let factory_ids = localStorage.getItem("factory_id");
      let _token = localStorage.getItem("token");
      let userTypeInf = localStorage.getItem("userType");
      const url = `https://flyingcolour.online/fcapp/spm_api/companyinfodetails/?factory_id=${factory_ids}&emp_id=${empId}&company_id=0&user_type=${userTypeInf}&service=`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCompanyList(res ?.data ?.results ?.Company_List);
      setServiceList(res ?.data ?.results ?.Service_List);
    } catch (err) {

    }
  };
  const fetchSharedLeadListingData = async () => {
    setLoad(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      const url = `https://flyingcolour.online/fcapp/spm_api/sharedinquirylist/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setLoad(false);
      setSharedLeadData(res.data.results.Shared_Inquiry_List);
      setFilterData2(res.data.results.Shared_Inquiry_List);
    } catch (err) {
      setLoad(false);

    }
  };

  const [RichTextVTData, setRichTextVTData] = useState("");
  const fetchRichTextVTData = async (data) => {
    setLoad(true);
    try {
      let _token = localStorage.getItem("token");
      const url = `https://flyingcolour.online/fcapp/spm_api/servicewisevattaxdetailslist/?service_id=${data}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };

      const res = await axios.get(url, { headers });
      setRichTextVTData(res ?.data ?.results);
    } catch (err) {
      setLoad(false);

    }
  };

  const [MasterVatTaxServiceData, setMasterVatTaxServiceData] = useState("");
  const fetchMasterVatTaxServiceData = async () => {
    setLoad(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      const url = `https://flyingcolour.online/fcapp/spm_api/vattaxserviceslist/?service_id=0`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };

      const res = await axios.get(url, { headers });
      // setLoad(false);
      setMasterVatTaxServiceData(
        res ?.data ?.results ?.Master_Vat_Tax_Services_List
      );
      // setFilterData2(res.data.results.Shared_Inquiry_List);
    } catch (err) {
      setLoad(false);

    }
  };



  const responseOn = async (data) => {
    const eqId = businessInfo.Enquiry_Id
      ? businessInfo.Enquiry_Id ?.replace("FC", "")
        : businessInfo.Inquiry_Id ?.replace("FC", "");

    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/businesscardview/?enquiryids=${eqId}&factory_id=${factory_id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setDetail(data.results.Business_Card_Listing_list);
      })
      .catch((error) => {
        console.error("Error from business card:", error);
      });
  };

  const getShareholderList = async (row) => {
    const enqid = row !== undefined
      ? row.Inquiry_Id ?.replace("FC", "")
        : salesInfo.Inquiry_Id.replace("FC", "");
    const client_id = row !== undefined ? row.Client_Id : salesInfo.Client_Id;
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/shareholderinquiryview/?inquiryid=${enqid}&factory_id=${factory_id}&emp_id=${emp_id}&client_id=${client_id}&user_type=${userTypeInfo}&shareHolderId=0&flags=1`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        const mapping = data.results.ShareHolderDetails.map((i) => ({
          Created_Date: i.Created_Date,
          InquiryId: i.InquiryId,
          Serial_Number: i.Serial_Number,
          Share_Holder_Name: i.Share_Holder_Name,
          Share_Holder_Info_Email: i.Share_Holder_Info_Email,
          Share_Holder_Info_Gender: i.Share_Holder_Info_Gender,
          Share_Holder_Info_Mobile: i.Share_Holder_Info_Mobile,
          Share_Holder_Info_Nationality: i.Share_Holder_Info_Nationality,
          Share_Holder_Info_Passport: i.Share_Holder_Info_Passport,
          Share_Holder_for_Service_Agreement:
            i.Share_Holder_for_Service_Agreement,
          Share_Holder_Info_Residing_In: i.Share_Holder_Info_Residing_In,
          Remarks: i.Remarks,
          shareHolderId: i.shareHolderId,
        }));
        const mapping1 = data.results.Nationality_Residing_In_List.map((i) => ({
          Share_Holder_Info_Nationality: i.country_name,
        }));
        setshareholderData(mapping);
        setshareholderData1(mapping1);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from shareholderData alert:", error);
      });
  };

  const getSubleadList = async (row, data) => {
    let Company_Id =
      data ?.Company_ID !== undefined
        ? data ?.Company_ID
          : salesInfo ?.Company_ID || salesInfo ?.Company_Id || salesInfo ?.inquiry_id || businessInfo ?.Company_ID;
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/subleadview/?sub_lead_id=0&emp_id=${emp_id}&factory_id=${factory_id}&company_id=${Company_Id}&emp_id=${emp_id}&user_type=${userTypeInfo}${row === 1 ? `&flag=${row}` : ""
      }`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        const mappingServices = data ?.results ?.Dropdown_Service.map((i) => ({
          Name: i.Name,
          Id: i.Id,
        }));
        getSubleadservicesList(mappingServices);
        setSubleadData(data ?.results ?.Sub_Lead_list);

      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from Sublead services alert:", error);
      });
  };

  const [crmBlocked, setcrmBlocked] = useState("");
  const crmBlockonCompanyHistoryList = async (
    rowToken,
    rowUserType,
    rowempId,
    rowFactory
  ) => {
    setLoader(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/api/crmblockoncompanyhistory/?factory_id=${rowFactory ? rowFactory : factory_ids
      }&emp_id=${rowempId ? rowempId : empId}&user_type=${rowUserType ? rowUserType : userTypeInf
      }`,
      {
        method: "GET",
        headers: headers(rowToken ? rowToken : _token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setcrmBlocked(data ?.data);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
        }
        else {
          setLoader(false);
          console.error("Error from CRM block Company History Listing: ", error);
        }
      });
  };
  const paymentreminderListAPI = async () => {
    const eqId = salesInfo.Inquiry_Id ?.replace("FC", "");
    setLoading(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquirypaymentreminderList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&inquiry_id=${eqId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPaymentReminderArr(data ?.results ?.Data);
        setLoading(false);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoader(false);
          console.error("Error : ", error);
        }
      });
  };


  const getCompanyHistoryList = async (row) => {
    let Company_IDd = "";
    salesInfo.length === 0
      ? (Company_IDd = row.Company_ID)
      : (Company_IDd = salesInfo.Company_ID);
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyhistorylistview/?company_id=${Company_IDd}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setCompanyHistoryData(data ?.results ?.Company_History_List);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoader(false);
          console.error("Error : ", error);
        }
      });
  };
  const [companyhistoryDropdown, setCompanyhistoryDropdown] = useState(null);
  const getCompanyHistoryDropdownApi = async (row) => {
    let Company_IDd = "";
    salesInfo.length === 0
      ? (Company_IDd = row.Company_ID)
      : (Company_IDd = salesInfo.Company_ID);
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyhistorylistview/?company_id=${Company_IDd}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setCompanyhistoryDropdown(data ?.results);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoader(false);
          console.error("Error : ", error);
        }
      });
  };

  const getCompanyPortfolioList = async (row) => {
    let Company_IDd = "";
    salesInfo.length === 0
      ? (Company_IDd = row.Company_ID)
      : (Company_IDd = salesInfo.Company_ID);
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyportfoliolistview/?company_id=${Company_IDd}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.data !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`), setLoader(false)
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`), setLoader(false);
        } else {
          const mappingServices = data ?.results ?.Service_Dropdown.map((i) => ({
            Name: i.Name,
            Id: i.Id,
          }))
          const mappingServicess = data ?.results ?.Service_Dropdown.map((i) => ({
            Name: i.Name,
            Id: i.Id,
          }));
          const mappingRFU = data ?.results ?.Reason_for_unsubscribe.map((i) => ({
            DeclineIds: i.DeclineIds,
            DeclineReason: i.DeclineReason,
          }));
          return setLoader(false), setCompanyPortfolioData(data ?.results ?.Company_Portfolio_List), getportfolioservicesList(mappingServices),
            getReasonforUnsubscribeList(mappingRFU), getportfolioservicesList1(mappingServicess);
        }

      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoader(false);
          console.error("Error from Company Portfolio View: ", error);
        }
      });
  };
  const getProposal = async (data) => {
    let emp_id = localStorage.getItem("emp_id");
    let factory_id = localStorage.getItem("factory_id");
    let userTypeInfo = localStorage.getItem("userType");
    const eqId = businessInfo.Enquiry_Id
      ? businessInfo.Enquiry_Id ?.replace("FC", "")
        : businessInfo.Inquiry_Id ?.replace("FC", "");

    setLoading(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/uploadquotationview/?enquiryids=${eqId}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setDetail(data.Upload_Quotation_List);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoading(false);
          console.error("Error from Upload Propsal: ", error);
        }
      });
  };

  const ClientManagergetProposal = async (data) => {
    const eqId = businessInfo.Company_ID;
    setLoading(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/uploadquotationview/?enquiryids=${eqId}&quotation_type=Company`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setDetail1(data.Upload_Quotation_List);
      })
      .catch((error) => {
        console.error("Error from Upload Propsal:", error);
      });
  };

  const clientResponse = async (value) => {
    const is_company = salesInfo ?.hasOwnProperty("Inquiry_Id") ? 0 : 1;
    const enq = salesInfo ?.hasOwnProperty("Inquiry_Id")
      ? salesInfo ?.Inquiry_Id ?.replace("FC", "")
        : salesInfo ?.Inquiry_No ?.replace("FC", "");
    const eqId =
      value ?.Inquiry_No !== undefined
        ? value ?.Inquiry_No ?.replace("FC", "")
          : enq;

    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/clientreview/?enquiryids=${eqId}&factory_id=${factory_id}&is_company=${is_company}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.results === undefined ||
          data.data !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`), setLoader(false);

        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`), setLoader(false);
        } else {
          return setLoader(false), setClientDetail(data.results);
        }

      })
      .catch((error) => {
        if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
        } else { console.error("Error from client Review:", error); }
      });
  };
  const clientReviewReport = async (value) => {
    const is_company = salesInfo ?.hasOwnProperty("Inquiry_Id") ? 0 : 1;
    const enq = salesInfo ?.hasOwnProperty("Inquiry_Id")
      ? salesInfo ?.Inquiry_Id ?.replace("FC", "")
        : salesInfo ?.Inquiry_No ?.replace("FC", "");
    const eqId =
      value ?.Inquiry_No !== undefined
        ? value ?.Inquiry_No ?.replace("FC", "")
          : enq;

    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/clientreview/?enquiryids=${0}&factory_id=${factory_id}&is_company=${is_company}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.results === undefined ||
          data.data !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`), setLoader(false);

        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`), setLoader(false);
        } else {
          return setLoader(false), setClientDetail(data.results);
        }

      })
      .catch((error) => {
        if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
        } else { console.error("Error from client Review:", error); }
      });
  };

  const requestDeclineList = async (value) => {
    const cmpIdSales = salesInfo ?.Company_ID;
    const cmpId =
      value ?.Inquiry_No !== undefined ? value ?.Company_ID : cmpIdSales;

    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/declinecompanylist/?company_id=${cmpId === undefined ? salesInfo ?.Company_ID : cmpId}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data ?.results === undefined ||
            data ?.data !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${data ?.data ?.code}-${data ?.data ?.msg}`), setLoader(false);

        } else if (data ?.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data ?.data ?.msg}`), setLoader(false);
        } else {
          setLoader(false);
          setDeclineList(data ?.results);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoader(false);
          console.error("Error from Decline: ", error);
        }
      });
  };

  const getSA = async () => {
    const eqId = salesInfo.Inquiry_Id ?.replace("FC", "") || salesInfo ?.Inquiry_No ?.replace("FC", "");
    setLoader(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/signedserviceagreementview/?enquiryids=${eqId}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setDetail(data.results.signed_service_agreement_list);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoader(false);
          console.error("Error from SA: ", error);
        }
      });
  };

  const [followUpList, setFollowupList] = useState(null);
  const [resheduler, setResheduler] = useState(false);
  const getResheduleData = async (dataItem) => {
    // flag = 1 for dropdown value in add form
    // flag = 2  for auto populate and dropdown value in edit form
    // flag = 3 for followup listing
    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/reschedulefollowuponcompanyview/?company_id=${dataItem.companyId}&sub_lead_id=${dataItem.subLead}&emp_id=${emp_id}&user_type=${userTypeInfo}&followup_id=${dataItem.followupId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFollowUp(data);
        setLoad(false);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoad(false);
          console.error("Error from Reshedule data: ", error);
        }
      });
  };

  const getECFollowUpView = async (dataItem) => {
    // flag = 1 for dropdown value in add form
    // flag = 2  for auto populate and dropdown value in edit form
    // flag = 3 for followup listing
    const subId = dataItem ?.subLead === null ? "0" : dataItem ?.subLead;
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/followuponcompanyView/?flag=${dataItem ?.flag}&company_id=${salesInfo ?.Company_ID || salesInfo ?.Company_Id}&sub_lead_id=${subId}&emp_id=${emp_id}&user_type=${userTypeInfo}&followup_id=${dataItem ?.followupId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFollowupList(data ?.followup_list);
        setLoader(false);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoader(false);
          console.error("Error from EC Followup: ", error);
        }
      });
  };

  const getEcOtherFollowUpApi = async () => {
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryNo = salesInfo ?.Inquiry_No ?.replace("FC", "");

    setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/followup_api/cmp_inquiry_followup_view/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${salesInfo ?.Company_ID}&company_inquiry_id=${inquiryNo}&followup_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setFollowupList(res ?.data ?.followup_list);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const getECFollowUpEdit = async (dataItem) => {
    // flag = 1 for dropdown value in add form
    // flag = 2  for auto populate and dropdown value in edit form
    // flag = 3 for followup listing
    setLoad(true);
    // setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/followuponcompanyView/?flag=${dataItem.flag}&company_id=${dataItem.companyId || dataItem.Company_Id}&sub_lead_id=${dataItem.subLead}&emp_id=${emp_id}&user_type=${userTypeInfo}&followup_id=${dataItem.followupId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFollowUp(data);
        setLoad(false);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoad(false);
          console.error("Error from followup: ", error);
        }
      });
  };
  const getFollowUpView = async (data) => {
    console.log("data", data)
    let eqId;
    eqId = data !== undefined 
      ? data?.Inquiry_Id?.replace("FC", "") || data?.Company_Id
        : salesInfo ?.Inquiry_Id ?.replace("FC", "") || salesInfo ?.Inquiry_id || businessInfo ?.Inquiry_id;
    const enqType = todayEnqAlerts ?.enquiry_type || salesInfo ?.enquiry_type || businessInfo ?.enquiry_type
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/followup_view/?enquiryids=${eqId !== undefined ? eqId : data ?.Inquiry_id || data ?.Company_Id || salesInfo ?.Inquiry_id}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&enquiry_type=${enqType}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`), setLoader(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`), setLoader(false);
        } else {
          return setFollowUp(data), setLoader(false)
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoader(false);
          console.error("Error from Follow up: ", error);
        }
      });
  };
  const getEnQAlert = async (data) => {
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/alertinquiryview/?emp_id=${emp_id}&&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setEnqAlerts(data);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from today alert:", error);
      });
  };
  const momDownloadView = async (data) => {
    const Inquiry_Id = data ?.Company_Id || data.Inquiry_Id || data.Inquiry_id || salesInfo.Inquiry_Id.replace("FC", "");
    const enq =
      Inquiry_Id ?.length === 7 ? Inquiry_Id.substring(0, 2) : Inquiry_Id;
    setLoad(true);
    await fetch(
      `http://flyingcolour.online/fcapp/followup_api/momdownloadview/?inquiryId=${enq}&factoryId=${factory_id}&followup_id=${data.followup_id || data.Followup_Id}&emp_id=${emp_id}&&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`);
        } else {
          return setMeetingView(data);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from mom view alert:", error);
      });
  };
  const momTaxDownloadView = async (data) => {
    const compId = data ?.Company_Id;

    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/momdownloadview_company/?company_Id=${compId}&factoryId=${factory_id}&followup_id=${data.followup_id || data.Followup_Id}&emp_id=${emp_id}&&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`);
        } else {
          return setMeetingView(data);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from mom view alert:", error);
      });
  };
  const [saTableData, setSaTableData] = useState([]);
  const getServiceAgreement = async (data) => {
    // for getting dropdownData
    const enq = businessInfo ?.Inquiry_Id ?.replace("FC", "") || businessInfo ?.Inquiry_No ?.replace("FC", "");
    setLoad(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");

    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/serviceagreementview/?inquiryid=${enq}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}${data === undefined ? "" : `&serviceAgreementId=${data.SA_ID}`}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else {
          return setSAData(data), setLoad(false);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement Alert: ", error);
        }
      });
  };
  const getServiceAgreementForCompany = async (data) => {
    // for getting dropdownData

    const companyId = businessInfo ?.Company_ID || data ?.InquiryId;
    const SA_Id = data ?.SA_ID ? data ?.SA_ID : null;
    setLoad(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      SA_Id != null ?
        `https://flyingcolour.online/fcapp/company/serviceagreementforcompany/?companyId=${companyId}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&serviceAgreementId=${SA_Id}`
        :
        `https://flyingcolour.online/fcapp/company/serviceagreementforcompany/?companyId=${companyId}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else {
          return setSAData(data), setLoad(false);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement for Company Alert: ", error);
        }
      });
  };
  const getServiceAgreementVT = async (data) => {
    let enq = businessInfo.Inquiry_Id ?.replace("FC", "") || businessInfo.Inquiry_No ?.replace("FC", "");
    if (enq === undefined) {
      enq = data ?.inquiry_id;
    }
    setLoad(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    const inq_id = data ?.inquiry_id


    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/serviceagreementtaxview/?inquiryid=${inq_id || enq}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}${data === undefined ? "" : `&serviceAgreementId=${data.SA_ID}`}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else {
          return setSAData(data), setLoad(false);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement Alert: ", error);
        }
      });


  };

  const getServiceAgreementVTListing = async () => {
    setLoad(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/serviceagreementtaxview/?inquiryid=${businessInfo ?.Inquiry_Id ?.replace("FC", "")}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}`,
      {
        method: "GET",
        headers: {
          "Authorization": `Token ${_token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else {
          return setSAData(data), setLoad(false);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement Alert: ", error);
        }
      });
  };
  const [saPTermsData, setSAPTermsData] = useState([]);
  const getpaymentTermsVT = async (data) => {

    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/getsatermspayment/`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else {
          return setSAPTermsData(data.results), setLoad(false);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement Alert: ", error);
        }
      });
  };
  const [sCData, setSCData] = useState([]);
  const getServiceChargesVT = async () => {

    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/vattaxserviceslist/?service_id=0`,
      // &serviceAgreementId=0
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`);
        } else {
          return setSCData(data.results);
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement Alert: ", error);
        }
      });
  };
  const [vatDetail, setVatDetail] = useState([]);

  const getServiceVTDetail = async (input) => {
    const arr = []
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/${input.value.id === undefined ? `allservicewisevattaxsadetailslist/?service_id=0` : `servicewisevattaxsadetailslist/?service_id=${input.value.id}`}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`);
        } else {
          if (input.edit === true) {
            const filter_Array = [];
            input ?.service ?.forEach((element) => {
              const filter_Data = data.results.Vat_Tax_Services_Wise_SOW_Obligation_Preamble_List ?.filter((i) => i.Id === parseInt(element));
              filter_Array.push(filter_Data[0])
            });
            return filter_Array.length !== 0 && setVatDetail(filter_Array);
          }
          else {
            if (input ?.service.length === 1 && vatDetail.find(i => i.Service_Name === `<li>${input ?.value ?.value}</li><br>`) === undefined) {
              return setVatDetail([data.results]);
            } else if (input ?.service.length === vatDetail.length) {
              const valIndex = vatDetail.findIndex(
                a => [`<li>${input.service[input.index].ServiceCharges}</li><br>`, `<li>${input.service[input.index].ServiceCharges}</li>`].includes(a.Service_Name));
              if (valIndex !== -1) {
                vatDetail.splice(valIndex, 1)
              }
              return setVatDetail([...vatDetail, data.results]);
            }
            else if (vatDetail.find(i => i.Service_Name === `<li>${input ?.value ?.value}</li><br>`) === undefined) {
              return setVatDetail([...vatDetail, data.results]);
            }
          }
        }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

        }
        else {
          setLoad(false);
          console.error("Error from Service Agreement Alert: ", error);
        }
      });
  };
  const getSAEdit = async (data) => {
    const enq = data ?.Inquiry_Id
      ? data ?.Inquiry_Id ?.replace("FC", "") || data ?.Inquiry_No ?.replace("FC", "")
        : data ?.InquiryId || data ?.Inquiry_No ?.replace("FC", "");
    const inq_id = data ?.inquiry_id;
    const InqId = salesInfo ?.Inquiry_Id ?.replace("FC", "");
    const SA_Id = data ?.sa_ID ? data ?.sa_ID : data ?.SA_ID;
    setLoad(true);
    handleModalOpen();
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      businessInfo.enquiry_type === "vt" ?
        `https://flyingcolour.online/fcapp/spm_api/serviceagreementtaxview/?inquiryid=${enq || inq_id || InqId}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}${data === undefined ? "" : `&serviceAgreementId=${data ?.SA_ID}`}`
        : businessInfo.enquiry_type === "enq" ?
          `https://flyingcolour.online/fcapp/spm_api/serviceagreementview/?inquiryid=${enq || inq_id || InqId}&factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}${data === undefined ? "" : `&serviceAgreementId=${SA_Id}`}`
          : "",
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement View"}`), setLoad(false);
        } else {
          return setSAData(data), setLoad(false);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from SA Edit:", error);
      });
  };

  const getSHEdit = async (data) => {
    const shareHolderId = data ?.shareHolderId;
    const enq = data ?.InquiryId;
    setLoad(true);
    handleModalOpen();
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/shareholderinquiryview/?inquiryid=${enq}&factory_id=${factory_id}&emp_id=${emp_id}&client_id=21021&user_type=${userTypeInfo}&shareHolderId=${shareHolderId}&flags=2`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // if (data.results.ShareHolderDetails.length !== 0) {
        //   return (
        setLoad(false);
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`);
        }
        else {
          return setSHData(data.results.ShareHolderDetails);
        }

      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from SH Edit:", error);
      });
  };

  const getSLEdit = async (data) => {
    const sub_lead_id = data ?.Sub_Lead_Id;
    const company_id = businessInfo ?.Company_ID;
    setServiceId(data ?.Service);
    setLoader(true);
    handleModalOpen();
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/subleadview/?sub_lead_id=${sub_lead_id}&emp_id=${emp_id}&factory_id=${factory_id}&company_id=${company_id}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setSLData(data ?.results ?.Sub_Lead_list);
      })
      .catch((error) => {
        // setLoad(false);
        console.error("Error from SL Edit:", error);
      });
  };

  const getPREdit = async (data) => {
    const payment_id = data.payment_id;
    const inquiry_id = data.inquiry_id;
    setLoader(true);
    handleModalOpen();
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquirypaymentreminderform/?payment_id=${payment_id}&inquiry_id=${inquiry_id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results.data.length !== 0) {
          return setPRData(data ?.results ?.data), setLoader(false);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from PR Edit:", error);
      });
  };

  const [prDone, setprDone] = useState("");
  const getpaymentreminderDone = async (data) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${__token}`);
    const formData = new FormData();
    formData.append("emp_id", emp_id);
    formData.append("payment_id", data.payment_id);
    formData.append("is_done", 1);
    formData.append("inquiry_id", data.inquiry_id);
    formData.append("user_type", userTypeInfo);
    formData.append("payment_date", data.payment_date);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquirypaymentreminderform/`,
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
        },
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.msg) {
          setMessage(data.msg);
          setSeverity(500);
        } else {
          setMessage(data.data.msg);
          setSeverity(data.data.code);
        }
        // setprDone(data?.results?.data);
        paymentreminderListAPI();
      })
      .catch((error) => {
        // setLoad(false);
        console.error("Error from PR Done:", error);
      });
  };

  const getCHEdit = async (data) => {
    setCHData([])
    const Company_History_Id = data ?.Company_History_Id;
    setLoader(true);
    handleModalOpen();
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyhistorylistview/?company_history_id=${Company_History_Id}&company_id=${data.Company_Id}&factory_id=1&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setCHData(data ?.results ?.Company_History_List);
      })
      .catch((error) => {
        // setLoad(false);
        console.error("Error from SL Edit:", error);
      });
  };

  const [osData, setOSData] = useState("");
  const getOSEdit = async (data) => {
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&status=0&out_source_id=${data ?.Id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setOSData(data ?.results ?.Assigned_Inquirty_Outservice_List);
      })
      .catch((error) => {
        // setLoad(false);
        console.error("Error from OS Edit:", error);
      });
  };

  const [osrData, setOSRData] = useState("");
  const getOSREdit = async (data) => {
    setLoader(true);
    handleModalOpen();
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceservicereplyList/?inquiry_outsource_id=${data ?.Ios_Id}&emp_id=${emp_id}&user_type=${userTypeInfo}&reply_id=${data ?.Reply_Id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setOSRData(data ?.results ?.Assigned_Inquirty_Outservice_Reply_List);
      })
      .catch((error) => {
        // setLoad(false);
        console.error("Error from OSR Edit:", error);
      });
  };

  const provisionalInvoiceTableGetApi = async (data) => {
    const enq =
      businessInfo.Inquiry_Id ?.replace("FC", "") || businessInfo.Company_ID;
    const user_type = getLocalData("user_type");

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/provisionalinvoicelist/?factory_id=${factory_id}&&emp_id=${emp_id}&&user_type=${user_type}&&inquiry_id=${enq}&&company_flag=${companyFlag}`;

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoading(true);
      const res = await axios.get(url, { headers: headers });
      setProvisionalInvoiceArr(res ?.data ?.results ?.provisional_invoice_list);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const provisionalInvoiceEditGetApi = async (data) => {
    const enq = data ?.inquiry_id || businessInfo ?.Company_ID;
    const user_type = getLocalData("user_type");
    let url = `https://flyingcolour.online/fcapp/spm_api/provisionalinvioceview/?factory_id=${factory_id}&&emp_id=${emp_id}&&user_type=${user_type}&&inquiry_id=${enq}&&company_flag=${companyFlag}&&provisional_invoice_id=${data ?.provisional_invoice_id}&&provisional_invoice_cost_id=${data ?.provisional_invoice_cost_id}&&flags=3`;

    try {
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoading(true);

      const res = await axios.get(url, { headers: headers });
      if (res ?.data ?.results ?.ProvisionalDetails !== undefined) {
        setLoading(false);
        setViewProvisionalInvoiceArr(res ?.data ?.results);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCPServicesList = async () => {
    const Company_ID = salesInfo.Company_ID;
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyportfoliolistview/?company_id=${Company_ID}&emp_id=${emp_id}&user_type=${userTypeInfo}&service=FISP`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        const mappingServices = data ?.results ?.Service_Dropdown.map((i) => ({
          Name: i.Name,
          Id: i.Id,
        }));
        setcpServiceData(mappingServices);
        const mappingStatusList = data ?.results ?.Status_List.map((i) => ({
          i: i,
        }));
        getStatusList1(mappingStatusList);

        const index = data ?.results ?.Status_List.indexOf(
          "Partially Unsubscribe"
        );
        const index1 = data ?.results ?.Status_List.indexOf("Unsubscribed");
        if (index > -1) {
          data ?.results ?.Status_List.splice(index, 1);
        }
        if (index1 > -1) {
          data ?.results ?.Status_List.splice(index1, 1);
        }
        getStatusList(data ?.results ?.Status_List);
      })
      .catch((error) => {
        console.error("Error from CP Edit:", error);
      });
  };

  const getCPServicesList1 = async () => {
    const Company_ID = salesInfo.Company_ID;
    setLoader(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyportfoliolistview/?company_id=${Company_ID}&emp_id=${emp_id}&user_type=${userTypeInfo}&service=FOSP`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        const mappingServices = data ?.results ?.Service_Dropdown.map((i) => ({
          Name: i.Name,
          Id: i.Id,
        }));
        setcpServiceData1(mappingServices);
      })
      .catch((error) => {
        console.error("Error from CP Edit:", error);
      });
  };

  const getCPEdit = async (data) => {
    const Company_Id = data.Company_Id;
    const Company_Portfolio_Id = data ?.Company_Portfolio_Id;
    setLoader(true);
    handleModalOpen();
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/companyportfoliolistview/?company_id=${Company_Id}&company_portfolio_id=${Company_Portfolio_Id}&emp_id=${emp_id}&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {

        setCPData(data ?.results ?.Company_Portfolio_List);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error from CP Edit:", error);
      });
  };

  // for table showing data
  const getSAList = async (data) => {
    const enq = businessInfo.Inquiry_Id ?.replace("FC", "") || businessInfo.Inquiry_No ?.replace("FC", "");

    const appURL = window.location.href;
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = getLocalData("emp_id");
    const companyId = businessInfo.Company_ID;
    const factory_id = localStorage.getItem("factory_id");

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/serviceagreementlistview/?inquiryId=${enq}&factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSaTableData(res ?.data.results.service_agreement_list)
      // setViewCompanyFamilyData(res?.data?.results);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  // const getSAList = async (data) => {
  //   const enq = businessInfo.Inquiry_Id?.replace("FC", "") || businessInfo.Inquiry_No?.replace("FC", "") ;
  //   setLoad(true);
  //   await fetch(
  //     `https://flyingcolour.online/fcapp/spm_api/serviceagreementlistview/?inquiryId=${enq}&factoryId=${factory_id}&empId=${emp_id}&userType=${userTypeInfo}`,
  //     {
  //       method: "GET",
  //       headers: headers(__token),
  //       mode: "cors",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setLoad(false);
  //       if (
  //         data?.results?.service_agreement_list === undefined ||
  //         data.data !== undefined
  //       ) {
  //         return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement List"}`), setLoad(false);
  //       } else if (data.detail !== undefined) {
  //         return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement List"}`), setLoad(false);
  //       } else if (data?.results?.service_agreement_list?.length !== 0) {
  //         return setSaTableData(data.results.service_agreement_list);
  //       }

  //     })
  //     .catch((error) => {
  //       setLoad(false);
  //       console.error("Error from Sa Table alert:", error);
  //     });
  // };

  const [state_Id, setState_Id] = useState("");
  const [formationId, setFormationId] = useState("");
  const [legalTypeId, setLegalTypeId] = useState(0);
  const [activityLegal, setActivityLegal] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const getLegalActivity = async (data) => {
    const enq = data ?.inquiryId;
    data.formationId !== undefined && data.legalTypeId === null || data.legalTypeId === undefined
      ? setIsLoad(true)
      : setIsLoad(false);
    data.legalTypeId !== null
      ? setLoader(true)
      : setLoader(false);
    await fetch(
      `http://flyingcolour.online/fcapp/spm_api/getlegalandactivitytypelist/?inquiryId=${enq}&stateId=${data.stateId}&formationTypeId=${data.formationId}&factory_id=${factory_id}&legaltype=${data.legalTypeId}&forWhomFlag=1&enquiry_type=${data ?.enquiry_type}&comp_flag=1`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setActivityLegal(data);
        setIsLoad(false);
        setLoader(false);
      })
      .catch((error) => {
        setIsLoad(false);
        console.error("Error from Legal Activity alert:", error);
      });
  };
  const [open, setOpen] = React.useState(false);
  const [quotationApiMsg, setQuotationApiMsg] = useState("");
  const [quotationApiMsg2, setQuotationApiMsg2] = useState("")

  const handleClickSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function countMonth(str) {
    if (str <= 9) {
      return `0${str}`;
    } else return str;
  }
  const [rowData, setRowData] = useState([]);
  const callDueDate = () => {
    const currentDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const date = currentDate.split("/");
    const dueDate = `${date[2]}-${date[1]}-${date[0]}`;
    return dueDate;
  };
  const getTodayAlert = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/alertinquiryview/?emp_id=${empId}&&user_type=${userTypeInf}&&due_date=${date}&&factory_id=${factory_ids}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);

        setRowData(data);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };
  const [alertData, setAlertData] = useState([]);
  const [pendingAlertData, setPendingAlertData] = useState();
  const getTodayECsAlert = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/alertcompanyview/?emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}&sub_lead_id=0`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setAlertData(data.Alert_Details_Inquiry);
        setPendingAlertData(data ?.Pending_Alert)
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const getTodayECsAlertOnCMSH = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_id = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");

    await fetch(
      `https://flyingcolour.online/fcapp/client_manager_sh/company_prospect_alerts_list/?factory_id=${factory_id}&emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setAlertData(data ?.Alert_Details_Inquiry);
        setPendingAlertData(data ?.Pending_Alert)
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const [alertDataForOtherCompany, setAlertDataForOtherCompany] = useState(null);
  const [pendingAlertDataForOtherCompany, setPendingAlertDataForOtherCompany] = useState();
  const getTodayECsAlertforOtherCompany = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    // https://flyingcolour.online/fcapp/followup_api/alertothercompanyview/?emp_id=314&user_type=CLIENT%20MANAGER&due_date=2023-01-28
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/alertothercompanyview/?emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setAlertDataForOtherCompany(data.Alert_Details_Inquiry);
        setPendingAlertDataForOtherCompany(data ?.Pending_Alert)
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const getInquiryWithoutFutureAlertTable = (date) => {
    setInqWOFutureAlert(null);
    fetch(
      `https://flyingcolour.online/fcapp/spm_api/nofuturealertinquiryview/?emp_id=${emp_id}&&user_type=${userTypeInfo}&&due_date=${date}&&factory_id=${factory_id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);

        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`), setLoader(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`), setLoader(false);
        }
        else {
          return setInqWOFutureAlert(data.Alert_Details_Inquiry);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const getCompanyWithoutFutureAlertTable = (date) => {
    fetch(
      `https://flyingcolour.online/fcapp/spm_api/companywithnofuturealertlist/?emp_id=${emp_id}&user_type=${userTypeInfo}&factory_id=${factory_id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        const mapping = data.Alert_Details_Inquiry.map((i) => ({
          Sno: i.Serial_Number,
          Inquiry_Id: `FC${i.Inquiry_id}`,
          Due_Date: i.Due_Date,
          leadCaption: i.Lead_Caption,
          Client_Name: i.Client_Name,
          ClientEmail: `${i.Client_Details.split("<br/>")[0]}`,
          ClientNum: `${i.Client_Details.split("<br/>").pop()}`,
          Sale_Person: i.Sale_Person,
          Zone: i.Zone,
          revenue_id: i.Revenue_Id,
          followup_id: i.Followup_Id,
          projection:
            i.Revenue_Month !== 0
              ? `${moment(countMonth(i.Revenue_Month)).format("MMM")}`
              : "No Projecton",
          revenue_type:
            i.Revenue_Type.split(",")[0] !== 0
              ? `${i.Revenue_Type.split(",")[0]}:${i.Revenue_Amount.split(",")[0]
              },${i.Revenue_Type.split(",")[1]}:${i.Revenue_Amount.split(",")[1]
              },${i.Revenue_Type.split(",")[2]}:${i.Revenue_Amount.split(",")[2]
              }`
              : "null",
          followup: i.Followup,
          Followup_Type: i.Followup_Type,
          Followup_Status_And_Rejected: `${i.Followup_Status} ${i.Approved_Rejected_Date}`,
          Followup_Status: i.Followup_Status,
          Is_Alert_Done: i.Is_Alert_Done,
          leadConversation: i.Inquiry_Status,
          Approved_Rejected_Date: i.Approved_Rejected_Date,
          Alert_Done_Flag: i.Alert_Done_Flag,
          Download_Flag: i.Download_Flag,
          action: "sd",
        }));
        setcompanyWOFutureAlert(mapping);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from Company without Future Alert : ", error);
      });
  };

  // for listing tab in sales
  const [value, setValue] = useState(0);
  const handleChangeTab = (newValue) => {
    setValue(newValue);
    setAlertTabValue(newValue);
  };
  const [values, setValues] = useState(0);
  const handleChangeTabs = (newValue) => {
    setValues(newValue);
  };
  const [ProposalReportData, setProposalReportData] = useState(null);
  const quotationTableGetApi = async (prEnq) => {
    const enq = businessInfo.Inquiry_Id?.replace("FC", "");
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/quotationlistview/?factory_id=${factory_ids}&&emp_id=${empId}&&user_type=${userTypeInf}&&enquiry_id=${prEnq !== 0 ? enq : 0}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      setLoading(true);
      const res = await axios.get(url, { headers: headers });
      setLoading(false)
      if (
        res.data?.quotation_list !== undefined
      ) {
        return prEnq !== 0 ? setQuotationTableArr(res?.data?.quotation_list) : setProposalReportData(res?.data?.quotation_list);
      } else if (res.data.data?.detail !== undefined && res.data?.quotation_list === undefined && res.data.message === undefined) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res.data.data.msg}`);
      }
      else if (res.data.message !== undefined && res.data.data?.detail === undefined && res.data?.quotation_list === undefined) {
        return setErrorAlert(true), setError(`ERROR Missing-${res.data.message}`)
      }
      else {
        return setErrorAlert(true), setError(`ERROR ${res.data.data.code}-${res.data.data.msg}`)
      }
    } catch (err) {
      console.error(err);
    }
  };

  const quotationReportApi = async (start_Date, end_Date) => {
    const enq = businessInfo.Inquiry_Id ?.replace("FC", "");
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    const toDayDate = new Date();
    const from_Date = start_Date === undefined ? "" : start_Date;
    const to_Date = end_Date === undefined ? "" : end_Date;
    setLoading(true);

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/quotationlistReportview/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&inquiry_id=${0}&from_date=${from_Date}&to_date=${to_Date}&quotation_type=Inquiry`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers: headers });
      setProposalReportData(res?.data?.quotation_Report_list)
      setLoading(false)
    //   if (
    //     res.data ?.quotation_list !== undefined
    //   ) {
    //     return prEnq !== 0 ? setQuotationTableArr(res ?.data ?.quotation_Report_list) : setProposalReportData(res ?.data ?.quotation_list);
    //   } else if (res.data.data ?.detail !== undefined && res.data ?.quotation_list === undefined && res.data.message === undefined) {
    //     return setErrorAlert(true), setError(`ERROR ${"401"}-${res.data.data.msg}`);
    //   }
    //   else if (res.data.message !== undefined && res.data.data ?.detail === undefined && res.data ?.quotation_list === undefined) {
    //     return setErrorAlert(true), setError(`ERROR Missing-${res.data.message}`)
    //   }
    //   else {
    //     return setErrorAlert(true), setError(`ERROR ${res.data.data.code}-${res.data.data.msg}`)
    //   }
    } catch (err) {
      console.error(err);
    }
  };


  const quotationTableVTGetApi = async () => {
    const enq = businessInfo.Inquiry_Id ?.replace("FC", "") || businessInfo.Inquiry_No ?.replace("FC", "");
    const user_type = getLocalData("user_type");
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/quotationtaxList/?factory_id=${factory_id}&emp_id=${emp_id}&inquiry_id=${enq}&quotation_id=0`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      setLoading(true);
      const res = await axios.get(url, { headers: headers });
      if (
        res.data ?.results ?.Vat_Tax_Quotation_List !== undefined
      ) {
        return setQuotationTableVT(res ?.data ?.results ?.Vat_Tax_Quotation_List), setLoading(false);
      } else if (res.data.data ?.detail !== undefined && res ?.data ?.results ?.Vat_Tax_Quotation_List === undefined && res.data.message === undefined) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res.data.data.msg}`), setLoading(false);
      }
      else if (res.data.message !== undefined && res.data.data ?.detail === undefined && res.data ?.results ?.Vat_Tax_Quotation_List === undefined) {
        return setErrorAlert(true), setError(`ERROR Missing-${res.data.message}`), setLoading(false);
      }
      else {
        return setErrorAlert(true), setError(`ERROR ${res.data.data.code}-${res.data.data.msg}`), setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getVatTaxQuotationData = async () => {
    const ComId = businessInfo.Company_ID
    const user_type = getLocalData("user_type");
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_tax/quotationtaxList/?factory_id=${factory_id}&emp_id=${emp_id}&company_id=${ComId}&quotation_id=0`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      setLoading(true);
      const res = await axios.get(url, { headers: headers });
      if (
        res.data ?.results ?.Vat_Tax_Quotation_List !== undefined
      ) {
        return setQuotationTableVT(res ?.data ?.results), setLoading(false);
      } else if (res.data.data ?.detail !== undefined && res ?.data ?.results ?.Vat_Tax_Quotation_List === undefined && res.data.message === undefined) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res.data.data.msg}`), setLoading(false);
      }
      else if (res.data.message !== undefined && res.data.data ?.detail === undefined && res.data ?.results ?.Vat_Tax_Quotation_List === undefined) {
        return setErrorAlert(true), setError(`ERROR Missing-${res.data.message}`), setLoading(false);
      }
      else {
        return setErrorAlert(true), setError(`ERROR ${res.data.data.code}-${res.data.data.msg}`), setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const ClientManagerquotationTableGetApi = async (data) => {
    const enq = businessInfo.Company_ID;
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/quotationlistview/?factory_id=${factory_ids}&user_type=${userTypeInf}&enquiry_id=${enq}&emp_id=${empId}&quotation_type=Company`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      setLoading(true);
      const res = await axios.get(url, { headers: headers });
      if (
        res.data ?.quotation_list !== undefined
      ) {
        return setClientmanagerquotationTableArr(res ?.data ?.quotation_list), setLoading(false);
      } else if (res.data.data ?.detail !== undefined && res.data ?.quotation_list === undefined && res.data.message === undefined) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res.data.data.msg}`), setLoading(false);
      }
      else if (res.data.message !== undefined && res.data.data ?.detail === undefined && res.data ?.quotation_list === undefined) {
        return setErrorAlert(true), setError(`ERROR Missing-${res.data.message}`), setLoading(false);
      }
      else {
        return setErrorAlert(true), setError(`ERROR ${res.data.data.code}-${res.data.data.msg}`), setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [stateId, setStateId] = useState("");
  const [masterFormationType, setMasterFormationType] = useState("");
  const [masterLegalType, setMasterLegalType] = useState("");
  const [masterMainActivity, setMasterMainActivity] = useState("");
  const [masterMainActivityLicence, setMasterMainActivityLicence] =
    useState("");
  const [remarkData, setRemarkData] = useState("");
  const [instantLicence, setInstantLicence] = useState("");
  const [quotationData, setQuotationData] = useState({});
  const [packageProposal, setPackageProposal] = useState(false);
  const [activityHide, setActivityHide] = useState(false);
  const [shData, setSHData] = useState("");
  const [slData, setSLData] = useState("");
  const [chData, setCHData] = useState("");
  const [cpData, setCPData] = useState([]);
  const [prData, setPRData] = useState("");
  const [cpServiceData, setcpServiceData] = useState([]);
  const [cpServiceData1, setcpServiceData1] = useState([]);
  const [SLServiceData, setSLServiceData] = useState("");
  const [first, setfirst] = useState("");

  const [iPapiData, setiPapiData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const InformationPoolListingApi = async () => {
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      const url = `https://flyingcolour.online/fcapp/spm_api/informationpoolList/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&status=approved`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setiPapiData(res ?.data ?.results ?.Information_Pool_list);
      setFilterData(res ?.data ?.results ?.Information_Pool_list);
    } catch (err) {

    }
  };
  const [parentCompany, setParentCompany] = useState([]);
  const ParentCompanyListApi = async (data) => {
    setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/parentcompanylisting/?company_id=${data.Company_ID}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setParentCompany(res ?.data ?.results ?.Data);
      setLoader(false);
    } catch (err) {

    }
  };

  const [outsourcedata, setOutsourcedata] = useState("");
  const fetchOutsourceServiceListingData = async () => {
    setLoad(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&status=0`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${_token}`,
          "Content-Type": "application/json",
        },
        moda: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOutsourcedata(data ?.results ?.Assigned_Inquirty_Outservice_List);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [outsourcereplydata, setOutsourcereplydata] = useState("");
  const fetchOutsourceServiceReplyListingData = async (data) => {
    const inquiry_outsource_id = data ?.Id ? data ?.Id : businessInfo ?.Id;
    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceservicereplyList/?inquiry_outsource_id=${inquiry_outsource_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&reply_id=0`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        moda: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOutsourcereplydata(
          data ?.results ?.Assigned_Inquirty_Outservice_Reply_List
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [momReport, setMomReport] = useState([]);
  const MomReportApi = async (data) => {
    setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/companymomlisting/?company_id=${data.Company_ID}&&emp_id=${emp_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setMomReport(res ?.data ?.results ?.Data);
      setLoader(false);
    } catch (err) {

    }
  };

  const [InquirymomReport, setInquirymomReport] = useState([]);
  const InquiryMomReportApi = async (data) => {
    setLoader(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");

      const url = `https://flyingcolour.online/fcapp/spm_api/inquirymomlisting/?factory_id=${factory_ids}&emp_id=${empId}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setInquirymomReport(res ?.data ?.results ?.Data);
      setLoader(false);
    } catch (err) {

    }
  };
  const [TodosList, setTodosList] = useState([]);
  const TodoListAPI = async (e) => {
    await fetch(
      `https://flyingcolour.online/fcapp/api/todolisting/?factory_id=${factory_id}&emp_id=${emp_id}&status_value=All&no_of_record=10&limit=0`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setTodosList(data ?.results ?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [SAReports, setSAReports] = useState([]);
  // const SAReportApi = async (data) => {
  //   setLoader(true);
  //   try {
  //     let empId = localStorage.getItem("emp_id");
  //     let _token = localStorage.getItem("token");
  //     let factory_ids = localStorage.getItem("factory_id");
  //     let userTypeInf = localStorage.getItem("userType");
  //     const url = `https://flyingcolour.online/fcapp/spm_api/serviceagreementlistview/?inquiryId=0&factoryId=${factory_ids}&empId=${empId}&userType=${userTypeInf}`;
  //     const headers = {
  //       Authorization: `Token ${_token}`,
  //       "Content-Type": "application/json",
  //     };
  //     const res = await axios.get(url, { headers });
  //     setSAReports(res?.data?.results?.service_agreement_list);
  //     setLoader(false);
  //   } catch (err) {

  //   }
  // };

  const SAReportApi = async (start_Date, end_Date) => {

    setLoader(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      const toDayDate = new Date()
      // const to_Date = moment(toDayDate).format("YYYY-MM-DD");
      // const from_Date = "2023-01-01";
      const from_Date = start_Date === undefined ? "" : start_Date;
      const to_Date = end_Date === undefined ? "" : end_Date;


      const url = `https://flyingcolour.online/fcapp/spm_api/serviceagreementReportview/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&inquiry_id=0&&from_date=${from_Date}&to_date=${to_Date}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSAReports(res?.data?.results?.service_agreement_Report);
      setLoader(false);
    } catch (err) {

    }
  };


  const [PIReports, setPIReports] = useState([]);
  const PIReportApi = async (data) => {
    setLoader(true);
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/provisionalinvoicelist/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&inquiry_id=0&company_flag=0`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPIReports(res ?.data ?.results ?.provisional_invoice_list);
      setLoader(false);
    } catch (err) {

    }
  };

  const [compDetail, setCompDetail] = useState([]);
  const getCompanyDetailApi = async (data) => {
    setLoader(true);
    const url = `https://flyingcolour.online/fcapp/spm_api/getcompanydetails/?company_id=${data.Company_ID}`;
    await fetch(url, {
      method: "GET",
      headers: headers(__token),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setCompDetail(data ?.results);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from company detail:", error);
      });
  };
  const [orderData, setOrderData] = useState([]);
  const getOrderListApi = async (data) => {
    let enq = data ?.Inquiry_Id ?.replace("FC", "") || data.Inquiry_No ?.replace("FC", "") || data.inquiry_id || businessInfo ?.Inquiry_No ?.replace("FC", "");
    setLoader(true);
    const url = `https://flyingcolour.online/fcapp/neha_test_api/orderformlist/?inquiry_id=${enq}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&company_flag=${(userTypeInfo === "CLIENT_MANAGER" || userTypeInfo === "SALES_PERSON") ? `0` : `1`}&client_id=${data.Client_Id || salesInfo ?.Client_Id || salesInfo ?.Company_ID}`;

    await fetch(url, {
      method: "GET",
      headers: headers(__token),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderData(data ?.results ?.Order_Form_List);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from Order Form:", error);
      });
  };
  const getOrderListForCompany = async (data) => {

    setLoader(true);
    const url = `https://flyingcolour.online/fcapp/neha_test_api/orderformlist/?company_id=${businessInfo.inquiry_id || businessInfo ?.Company_ID || salesInfo ?.Company_ID}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&company_flag=1&sub_lead_id=${salesInfo ?.Sub_Lead_Id || salesInfo ?.sub_lead_id}`;

    await fetch(url, {
      method: "GET",
      headers: headers(__token),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderData(data ?.results ?.Order_Form_List);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from Order Form:", error);
      });
  };
  const [orderDataVT, setOrderDataVT] = useState(null);
  const getOrderVTListApi = async (data) => {
    let enq = data.Inquiry_Id ?.replace("FC", "") || data.Inquiry_No ?.replace("FC", "");
    const compID = data.Company_ID || data.Company_Id;


    setLoader(true);
    let url;
    if (userTypeInfo === "CLIENT_MANAGER_TAX") {
      url = `https://flyingcolour.online/fcapp/neha_test_api/orderformlistforvat/?${salesInfo ?.Inquiry_Id ? `&inquiry_id=${enq}` : `company_id=${data.Company_ID || data.Company_Id || data ?.inquiry_id || 0}`}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}${salesInfo ?.Inquiry_Id ? `&company_flag=0` : `&company_flag=1`}${salesInfo ?.Inquiry_Id ? `&client_id=${data.Client_Id}` : `&sub_lead_id=${salesInfo ?.Sub_Lead_Id || 0}`}`;
    }
    else {
      url = `https://flyingcolour.online/fcapp/neha_test_api/orderformlistforvat/?inquiry_id=${userTypeInfo === "CLIENT_MANAGER_TAX" ? data.Company_ID || data.Company_Id : enq}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}${userTypeInfo === "CLIENT_MANAGER_TAX" ? `&company_flag=1` : `&client_id=${data.Client_Id}`}`;
    }
    await fetch(url, {
      method: "GET",
      headers: headers(__token),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderDataVT(data ?.results ?.Order_Form_List);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error from Order Form:", error);
      });
  };

  const [orderDropDown, setOrderDropDown] = useState([]);
  const [loadData, setLoadData] = useState([]);

  const getOrderDropDownApi = async (data) => {
    data.stateId === undefined ? setLoad(true) : setLoad(false);
    (data.formationId !== undefined && data.licenseId === undefined) ||
      data.offshoreId === undefined
      ? setLoader(true)
      : setLoader(false);
    data.formationId !== 2 ? setLoadData(true) : setLoadData(false);

    data.licenseId !== undefined ||
      (data.offshoreId !== undefined && data.legalTypeId === undefined)
      ? setIsLoad(true)
      : setIsLoad(false);


    let enq =
      data.inquiry_id !== undefined
        ? typeof data ?.inquiry_id === "string" ? data ?.inquiry_id ?.replace("FC", "") : data ?.inquiry_id
          : typeof data.Inquiry_Id === "string" ? data.Inquiry_Id ?.replace("FC", "") : data.Inquiry_Id;
    const orderFormId = data ?.inquiry_or_company_order_id || data ?.new_order_form_id

    setLoading(true);
    // for company inquiry_id is company id
    let url = "";
    {
      userTypeInfo === "Support" || userTypeInfo === "Project_Designer" ?
        url = `https://flyingcolour.online/fcapp/company/NOFViewOnInquiry/?orderFormId=${orderFormId}`
        :
        url = `https://flyingcolour.online/fcapp/company/orderformview/?inquiry_id=${enq !== undefined ? enq : typeof data ?.Inquiry_No === "string" ? data ?.Inquiry_No ?.replace("FC", "") : data ?.Inquiry_No}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&flag=${data.Order_Id !== undefined ? 2 : 1
        }&orderFormId=${userTypeInfo === "Support" ? data ?.inquiry_or_company_order_id : data.Order_Id !== undefined ? data.Order_Id : 0
      }&for_Inquiry_Company=${userTypeInfo === "CLIENT_MANAGER" || userTypeInfo === "SALES_PERSON" || userTypeInfo === "Project_Designer" || userTypeInfo === "Admin" ? `1` : `2`}${data.stateId !== undefined ? `&state_id=${data.stateId}` : ""
        }${data.formationId !== undefined
          ? `&formation_type_id=${data.formationId}`
          : ""
        }${data.freezoneId !== undefined ? `&freezone_name=${data.freezoneId}` : ""}
    ${data.ded_Fz_Offshore !== undefined
          ? `&ded_fz_offshore_name=${data.ded_Fz_Offshore}`
          : ""
        }${data.proServiceId !== undefined
          ? `&pro_formation_type=${data.proServiceId ?.replace("/%20/g", "")}`
          : ""
        }
    ${data.offshoreId !== undefined ? `&offshore_name=${data.offshoreId}` : ""
        }${data.licenseId !== undefined ? `&license_type=${data.licenseId}` : ""}${data.legalTypeId !== undefined ? `&legal_type=${data.legalTypeId}` : ""
        }`;
    }
    await fetch(url, {
      method: "GET",
      headers: headers(__token),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Order Form View"}`), setEditCard(false), handleModaleClose(), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Order Form View"}`), setEditCard(false), handleModaleClose(), setLoad(false);
        } else {
          return (
            setOrderDropDown(data),
            setLoading(false),
            setLoad(false),
            setLoader(false),
            setIsLoad(false)
          );
        }


        // }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
        }
        else if (error ?.response) {

          return setLoad(false), setErrorAlert(true), setError(`${error}-${"Try again!!"}`)
        }
      });
  };
  const getOrderDropDownForCompany = async (data) => {

    data.stateId === undefined ? setLoad(true) : setLoad(false);
    (data.formationId !== undefined && data.licenseId === undefined) ||
      data.offshoreId === undefined
      ? setLoader(true)
      : setLoader(false);
    data.formationId !== 2 ? setLoadData(true) : setLoadData(false);

    data.licenseId !== undefined ||
      (data.offshoreId !== undefined && data.legalTypeId === undefined)
      ? setIsLoad(true)
      : setIsLoad(false);
    let enq =
      data.Inquiry_No !== undefined
        ? typeof data ?.Inquiry_No === "string" ? data ?.Inquiry_No ?.replace("FC", "") : data ?.Inquiry_No
          : typeof data.Inquiry_No === "string" ? data.Inquiry_No ?.replace("FC", "") : data.Inquiry_No;

    // for company we need company id
    // here data.inquiry_id is also company id 
    setLoading(true);
    const url =
      `https://flyingcolour.online/fcapp/company/orderformviewoncompany/?${`company_id=${salesInfo ?.company_id || data ?.Company_Id || data ?.company_id || salesInfo ?.Company_ID}`}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${userTypeInfo}&flag=${data.Order_Id !== undefined ? 2 : userTypeInfo === "Project_Designer" ? 2 : 1
      }&orderFormId=${userTypeInfo === "Support" ? data ?.inquiry_or_company_order_id : userTypeInfo === "Project_Designer" && data ?.new_order_form_id !== undefined ? data ?.new_order_form_id :
        data.Order_Id !== undefined ? data.Order_Id : 0
      }${data.stateId !== undefined ? `&state_id=${data.stateId}` : ""
      }${data.formationId !== undefined
        ? `&formation_type_id=${data.formationId}`
        : ""
      }${data.freezoneId !== undefined ? `&freezone_name=${data.freezoneId}` : ""}
    ${data.ded_Fz_Offshore !== undefined
        ? `&ded_fz_offshore_name=${data.ded_Fz_Offshore}`
        : ""
      }${data.proServiceId !== undefined
        ? `&pro_formation_type=${data.proServiceId}`
        : ""
      }`;
    await fetch(url, {
      method: "GET",
      headers: headers(__token),
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Order Form View on Company"}`), setEditCard(false), handleModaleClose(), setLoad(false);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Order Form View on Company"}`), setEditCard(false), handleModaleClose(), setLoad(false);
        } else {
          return (
            setOrderDropDown(data),
            setLoading(false),
            setLoad(false),
            setLoader(false),
            setIsLoad(false)
          );
        }


        // }
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        }
        else if (!error ?.response) {
          return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
        }
        else if (error ?.response) {

          return setLoad(false), setErrorAlert(true), setError(`${error}-${"Try again!!"}`)
        }
      });
  };
  const [meetingProjection, setMeetingProjection] = useState(null);
  const meetingProjectionData = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/api/meeting_projection/?start_date=${reqFromDate}&end_date=${reqToDate}&factory_id=${factory_id}&&user_type=${user_type}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false)
        setMeetingProjection(data ?.data ?.meeting_projection);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoad(false);
          console.error("Error from Meeting Projection: ", error);
        }
      });
  };
  const [revenueProjection, setRevenueProjection] = useState(null);
  const revenueProjectionData = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    setLoad(true)
    await fetch(
      `https://flyingcolour.online/fcapp/spm_api/revenueprojection/?inquiry_id=0&revenue_projection_date_from=${reqFromDate}&revenue_projection_date_to=${reqToDate}&factory_id=${factory_id}&&user_type=${user_type}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false)
        setRevenueProjection(data ?.results ?.Revenue_Projection_List);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoader(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoader(false);
          console.error("Error from Revenu Projection: ", error);
        }
      });
  };
  const [clientData, setClientData] = useState(null);

  const getSAListForCompany = async () => {
    const appURL = window.location.href;
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = getLocalData("emp_id");
    const companyId = businessInfo.Company_ID;
    const factory_id = localStorage.getItem("factory_id");

    try {
      const url = `https://flyingcolour.online/fcapp/neha_test_api/serviceagreementlistoncompany/?companyId=${companyId}&factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSaTableData(res ?.data.results.service_agreement_list)
      // setViewCompanyFamilyData(res?.data?.results);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  // const getSAListForCompany = async () => {
  //   const companyId = businessInfo.Company_ID;
  //   setLoad(true);
  //   await fetch(
  //     `https://flyingcolour.online/fcapp/neha_test_api/serviceagreementlistoncompany/?companyId=${companyId}&factoryId=${factory_id}&empId=${emp_id}&userType=${userTypeInfo}`,
  //     {
  //       method: "GET",
  //       headers: headers(__token),
  //       mode: "cors",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setLoad(false);
  //       if (
  //         data?.results?.service_agreement_list === undefined ||
  //         data.data !== undefined
  //       ) {
  //         return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}${" "}${"Error is from Service Agreement List"}`), setLoad(false);
  //       } else if (data.detail !== undefined) {
  //         return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}${" "}${"Error is from Service Agreement List"}`), setLoad(false);
  //       } else if (data?.results?.service_agreement_list?.length !== 0) {
  //         return setSaTableData(data.results.service_agreement_list);
  //       }

  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 401) {
  //         return setLoad(false), setErrorAlert(true), setError(`${error.response?.statusText} ${error.response?.status}-${error.response?.data?.detail}${" "}${"Please Login Again!!"}`)
  //       }
  //       else if (!error?.response) {
  //         return setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
  //       }
  //       else if (error?.response) {
  //         return  setLoad(false),setErrorAlert(true), setError(`${error}-${"Try again!!"}`)
  //       }
  //     });

  // };
  const getPointToBeContact = async (data) => {
    const companyId = data ?.companyId;
    await fetch(
      `https://flyingcolour.online/fcapp/company/serviceagreementforcompany/?companyId=${companyId}&pointToBeContactId=${data.id}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setClientData(data.pointToBeContactList[0]);
      })
      .catch((error) => {
        setIsLoad(false);
        console.error("Error from Legal Activity alert:", error);
      });
  };

  const [followUpReport, setFollowUpReport] = useState(null);

  const FollowUpReportApi = async (reqFromDate, reqToDate) => {
    setLoading(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");

      const url = `https://flyingcolour.online/fcapp/followup_api/followup_report_view/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${user_type}&followup_type_name=&fromDate=${reqFromDate}&toDate=${reqToDate}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setFollowUpReport(res ?.data ?.followup_list);
      setLoading(false);
    } catch (err) {

    }
  };

  const [eChannelExpiry, setEChannelExpiry] = useState(null);

  const EChannelExpiryApi = async (data) => {
    setLoader(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");
      //https://flyingcolour.online/fcapp/company/ClientInfoReraCardOnCompany/?factoryId=1&empId=314&userType=CLIENT%20MANAGER
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoReraCardOnCompany/?factoryId=${factory_id}&empId=${empId}&userType=${user_type}&companyId=0`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setEChannelExpiry(res ?.data ?.results ?.Data_list);
      setLoader(false);
    } catch (err) {

    }
  };

  const [userAndPassExpiry, setUserAndPassExpiry] = useState(null);
  const UsernameAndPasswordExpiryApi = async (data) => {
    setLoader(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");

      //https://flyingcolour.online/fcapp/company/ClientInfoUsernamePasswordOnCompany/?factoryId=1&empId=314&userType=CLIENT%20MANAGER
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoUsernamePasswordOnCompany/?factoryId=${factory_id}&empId=${empId}&userType=${user_type}&companyId=0`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setUserAndPassExpiry(res ?.data ?.results ?.Data_list);
      setLoader(false);
    } catch (err) {

    }
  };

  const [rSATokenPassExpiry, setRSATokenPassExpiry] = useState([]);
  const RSATokenPassExpiryApi = async (data) => {
    setLoader(true);
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");

      //https://flyingcolour.online/fcapp/company/ClientInfoRSATokenOnCompany/?factoryId=1&empId=314&userType=CLIENT%20MANAGER

      const url = `https://flyingcolour.online/fcapp/company/ClientInfoRSATokenOnCompany/?factoryId=${factory_id}&empId=${empId}&userType=${user_type}&companyId=0`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setRSATokenPassExpiry(res ?.data ?.results ?.Data_list);
      setLoader(false);
    } catch (err) {

    }
  };

  const [contPassExpData, setContPassExpData] = useState([]);

  const getContactPassportExpiryApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {

      const url = `https://flyingcolour.online/fcapp/company/ClientInfoContactPassportOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setContPassExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [empLabourExpData, setEmpLabourExpData] = useState([]);
  const getEmpLabourExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoEmploymentLabourOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setEmpLabourExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [empVisaExpData, setEmpVisaExpData] = useState([]);
  const getEmpVisaExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoEmploymentVisaOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setEmpVisaExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [imgCardExpData, setImgCardExpData] = useState([]);
  const getImgCardExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoImmigrationCardOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setImgCardExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };
  const [invtVisaExpData, setInvtVisaExpData] = useState([]);

  const getInvtVisaExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoInvestorVisaOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setInvtVisaExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [pOBoxExpData, setPOBoxExpData] = useState([]);

  const getPOBoxExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoPOBoxOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setPOBoxExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [invtPassExpData, setInvtPassExpData] = useState([]);
  const getInvtPassExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoInvestorPassportOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setInvtPassExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [eChannelCardExpData, setEChannelCardExpData] = useState([]);
  const getEChannelCardExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoEchannelCardOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setEChannelCardExpData(res.data.results.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [tradeMarkExpData, setTradeMarkExpData] = useState([]);
  const getTradeMarkExpApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    // https://flyingcolour.online/fcapp/company/ClientInfoTradeMarkOnCompany/?factoryId=1&empId=314&userType=CLIENT%20MANAGER

    try {
      const url = `https://flyingcolour.online/fcapp/company/ClientInfoTradeMarkOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setTradeMarkExpData(res ?.data ?.results ?.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };
  const [totalLicenseExpiredData, setTotalLicenseExpiredData] = useState([]);
  const getTotalLicenseExpiredDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    // https://flyingcolour.online/fcapp/company/TotalExpiredLicenseOnCompany/?factoryId=1&empId=314&userType=CLIENT_MANAGER&companyId=0

    try {
      const url = `https://flyingcolour.online/fcapp/company/TotalExpiredLicenseOnCompany/?factoryId=${factory_id}&empId=${emp_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setTotalLicenseExpiredData(res ?.data ?.results ?.Data_list);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [todayInquiryOutsourceServiceData, setTodayInquiryOutsourceServiceData] = useState(null);
  const getTodayInquiryOutsourceServiceDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    // https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=1&emp_id=314&user_type=CLIENT MANAGER&status=0&today_outsource=1

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&status=0&today_outsource=1`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setTodayInquiryOutsourceServiceData(res ?.data ?.results ?.Assigned_Inquirty_Outservice_List);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [todayOngoingOutsourceServiceData, setTodayOngoingOutsourceServiceData] = useState(null);
  const getTodayOngoingOutsourceServiceDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    // https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=1&emp_id=314&user_type=CLIENT MANAGER&status=2

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&status=2`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setTodayOngoingOutsourceServiceData(res ?.data ?.results ?.Assigned_Inquirty_Outservice_List);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [totalAssignedOutsourceServiceData, setTotalAssignedOutsourceServiceData] = useState(null);
  const getTotalAssignedOutsourceServiceDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");

    // https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=1&emp_id=314&user_type=CLIENT MANAGER&status=0

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/inquiryoutsourceserviceList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&status=0`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };

      setLoader(true);

      const res = await axios.get(url, { headers });

      setLoader(false);

      setTotalAssignedOutsourceServiceData(res ?.data ?.results ?.Assigned_Inquirty_Outservice_List);
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  const [complianceFormData, setComplianceFormData] = useState([]);
  const getComplianceFormDataApi = async (shareId) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryId = businessInfo ?.Inquiry_Id ?.replace('FC', '')
    // setLoader(true);
    //  https://flyingcolour.online/fcapp/compliance/requestforcompliancefrominquiryupdated/?inquiry_id=88287&emp_id=687&user_type=Compliance&factory_id=1
    try {
      const url = `https://flyingcolour.online/fcapp/compliance/requestforcompliancefrominquiryupdated/?inquiry_id=${inquiryId}&emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setComplianceFormData(res ?.data ?.results);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [declinedCompaniesData, setDeclinedCompaniesData] = useState([]);
  const getDeclinedCompaniesDataApi = async (filterOrders) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryId = businessInfo ?.Inquiry_Id ?.replace('FC', '')
    // setLoader(true);

    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_decline_company_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDeclinedCompaniesData(res ?.data ?.results ?.Decline_Company_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [assignedCompaniesData, setAssignedCompaniesData] = useState([]);
  const getAssignedCompaniesDataApi = async (filterOrders) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryId = businessInfo ?.Inquiry_Id ?.replace('FC', '')
    // setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/cm_companylist_sp_flag/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setAssignedCompaniesData(res ?.data ?.results ?.CM_Company_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [subLeadsData, setSubLeadsData] = useState(null);
  const getSubLeadsDataApi = async (value) => {
    setSubLeadsData(null);
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/company/totalsubleadview/?emp_id=${emp_id}&user_type=${user_type}&today_flag=${value}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSubLeadsData(res ?.data ?.results ?.Sub_Lead_list);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [todaySubLeadsData, setTodaySubLeadsData] = useState(null);
  const getTodaySubLeadsDataApi = async () => {
    setSubLeadsData(null);
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/company/totalsubleadview/?emp_id=${emp_id}&user_type=${user_type}&today_flag=${1}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTodaySubLeadsData(res ?.data ?.results ?.Sub_Lead_list);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [totalSubLeadsData, setTotalSubLeadsData] = useState(null);
  const getTotalSubLeadsDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const companyType = data === undefined || data ?.tax_other_company === "" ? "" : data ?.tax_other_company;
    const grpCompany = data === undefined || data ?.group_company === "" ? "" : data ?.group_company;
    const from_Date = data === undefined || data ?.fromDate === "" ? "" : data ?.fromDate;
    const to_Date = data === undefined || data ?.toDate === "" ? "" : data ?.toDate;


    setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/company/totalsubleadview/?emp_id=${emp_id}&user_type=${user_type}&today_flag=${0}&from_date=${from_Date}&to_date=${to_Date}&company_type=${companyType}&&group_by=${grpCompany}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTotalSubLeadsData(res ?.data ?.results ?.Sub_Lead_list);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [clientPortfolioData, setClientPortfolioData] = useState(null);
  const getClientPortfolioDataApi = async (value) => {
    setSubLeadsData(null);
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    // setLoader(true);
    // https://flyingcolour.online/fcapp/spm_api/getallcompanyportfoliolistview/?emp_id=314&user_type=client_manager
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/getallcompanyportfoliolistview/?emp_id=${emp_id}&user_type=${user_type}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setClientPortfolioData(res ?.data ?.results ?.All_Company_Portfolio_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };


  const [revenueProjectionCompanyData, setRevenueProjectionCompanyData] = useState(null);
  const getRevenueProjectionCompanyDataApi = async (reqFromDate, reqToDate) => {
    setSubLeadsData(null);
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    // setLoader(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/revenueprojection/?inquiry_company_flag=1&emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&inquiry_id=0&revenue_projection_date_from=${reqFromDate}&revenue_projection_date_to=${reqToDate}`
        ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setRevenueProjectionCompanyData(res ?.data ?.results ?.Revenue_Projection_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [meetingProjectionCompany, setMeetingProjectionCompany] = useState(null);
  const meetingProjectionCompanyDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/followup_api/meetingprojectionviewoncompany/?start_date=${reqFromDate}&end_date=${reqToDate}&factory_id=${factory_id}&&user_type=${user_type}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false)
        setMeetingProjectionCompany(data ?.data ?.meeting_projection);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoad(false);
          console.error("Error from Meeting Projection: ", error);
        }
      });
  };
  const meetingProjectionSHCompanyDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/client_manager_sh/sh_meeting_projection_list/?start_date=${reqFromDate}&end_date=${reqToDate}&factory_id=${factory_id}&&user_type=${user_type}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false)
        setMeetingProjectionCompany(data ?.data ?.meeting_projection);
      })
      .catch((error) => {
        if (error ?.response ?.status === 401) {
          return setLoad(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
        } else {
          setLoad(false);
          console.error("Error from Meeting Projection: ", error);
        }
      });
  };

  const [serviceAgreementReportData, setServiceAgreementReportData] = useState(null);
  const getServiceAgreementReportDataApi = async (value) => {
    setSubLeadsData(null);
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    // setLoader(true);
    //  https://flyingcolour.online/fcapp/company/serviceagreementlistoncompany/?companyId=0&empId=314&userType=CLIENT%20MANAGER&factoryId=1
    try {
      const url = `https://flyingcolour.online/fcapp/company/serviceagreementlistoncompany/?companyId=0&empId=${emp_id}&factoryId=${factory_id}&userType=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setServiceAgreementReportData(res ?.data ?.results ?.service_agreement_list);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [orderReportData, setOrdertReportData] = useState(null);
  const getOrderReportDataApi = async (value) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    // setLoader(true);
   
    try {
      const url = ` https://flyingcolour.online/fcapp/neha_test_api/orderformlist/?company_id=0&emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&company_flag=1`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrdertReportData(res ?.data ?.results ?.Order_Form_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const getAllCompanyOrderReportDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const startDate = start_Date === undefined ? "" : start_Date;
    const endDate = end_Date === undefined ? "" : end_Date;

    // setLoader(true);
    try {
      const url = ` https://flyingcolour.online/fcapp/neha_test_api/orderformlist/?company_id=0&emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&company_flag=1&from_date=${startDate}&to_date=${endDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrdertReportData(res ?.data ?.results ?.Order_Form_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [quotationReportData, setQuotationReportData] = useState(null);
  // const getQuotationReportDataApi = async (value) => {
  //   const __token = localStorage.getItem("token");
  //   const user_type = getLocalData("user_type");
  //   const emp_id = localStorage.getItem("emp_id");
  //   const factory_id = localStorage.getItem("factory_id");
  //   // setLoader(true);
  //   // https://flyingcolour.online/fcapp/spm_api/quotationlistview/?factory_id=1&enquiry_id=0&emp_id=314&user_type=CLIENT
  //   try {
  //     const url = `https://flyingcolour.online/fcapp/spm_api/quotationlistview/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&enquiry_id=0&quotation_type=Company`;
  //     const headers = {
  //       Authorization: `Token ${__token}`,
  //       "Content-Type": "application/json",
  //     };
  //     const res = await axios.get(url, { headers });
  //     setQuotationReportData(res?.data?.quotation_list);
  //     //setLoading(false);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  const getQuotationReportDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const toDayDate = new Date();
    const from_Date = start_Date === undefined ? "" : start_Date;
    const to_Date = end_Date === undefined ? "" : end_Date;
    setLoader(true);

    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/quotationlistReportview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&inquiry_id=${0}&from_date=${from_Date}&to_date=${to_Date}&quotation_type=Company`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setQuotationReportData(res ?.data ?.quotation_Report_list);
      setLoader(false);
    } catch (err) {
      console.error(err);
    }
  };



  const [followUpReportData, setFollowUpReportData] = useState(null);
  const to_Date = moment(new Date()).format("YYYY-MM-DD")
  const from_Date = moment(new Date(new Date().getTime() - (60 * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD")
  const [repFromDate, setRepFromDate] = useState(from_Date);
  const [repToDate, setRepToDate] = useState(to_Date);

  const getFollowUpReportDataApi = async (value) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    // let to_date = new Date();
    // // let toDate = moment(new Date()).format("YYYY-MM-DD")

    // let date = new Date();
    // let from_date = new Date(date.getTime() - (60 * 24 * 60 * 60 * 1000));
    // // let fromDate = moment(new Date(date.getTime() - (60 * 24 * 60 * 60 * 1000))).format("YYYY-MM-DD")

    // setLoader(true);
    //  https://flyingcolour.online/fcapp/followup_api/viewfollowuponcompany/
    try {
      const url = ` https://flyingcolour.online/fcapp/followup_api/viewfollowuponcompany/?emp_id=${emp_id}&user_type=${user_type}&from_date=${repFromDate}&to_date=${repToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setFollowUpReportData(res ?.data ?.followup_list);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [cmMOMReportData, setCM_MOM_ReportData] = useState(null);
  const getClientManagerMOMReportApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true)
    try {
      const url = ` https://flyingcolour.online/fcapp/followup_api/mom_report_on_company_cm_cmtax/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&from_date=${reqFromDate}&to_date=${reqToDate}&company_id=0&followup_type=`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCM_MOM_ReportData(res ?.data ?.MOM_Report_List);
      setLoading(false)
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  };
  const [followUpTaxReportData, setFollowUpTaxReportData] = useState(null);
  const getTaxFollowUpReportDataApi = async (followupType, companyType, repToDate2, repFromDate2) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    try {
      const url = ` https://flyingcolour.online/fcapp/followup_api/viewfollowuponcompany/?emp_id=${emp_id}&user_type=${user_type}&from_date=${repFromDate}&to_date=${repToDate}&followup_type=${followupType === undefined ? "" : followupType}&company_type=${companyType === undefined ? "" : companyType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setFollowUpTaxReportData(res ?.data);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Client Data Controller APIs--------------------------------------------------------------------------------------------------------------------------------------------

  const [clientDataControllertListData, setClientDataControllertListData] = useState([]);
  const getClientDataControllertListDataApi = async (value) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    // setLoader(true);
    // https://flyingcolour.online/fcapp/support_api/get_clientdata_companylist/?factory_id=1&emp_id=639&user_type=Client_Data&limit_from=0&how_many_record=200
    try {
      const url = `https://flyingcolour.online/fcapp/support_api/get_clientdata_companylist/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&limit_from=0&how_many_record=100`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setClientDataControllertListData(res ?.data ?.results ?.Company_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  let findPartnerId = complianceFormData ?.data ?.map((item) => {
    const options = item.partner_id;
    return options;
  });
  const [getPartnerName, setPartnerName] = useState("");
  const getExistingPartnerListApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const inquiryId = businessInfo ?.Inquiry_Id.replace('FC', '');
    const factory_id = localStorage.getItem("factory_id");

    // https://flyingcolour.online/fcapp/docprocessing_api/existingshareholder/?emp_id=11&user_type=admin&shareholder_name=test
    try {
      const url =
        `https://flyingcolour.online/fcapp/docprocessing_api/existingshareholder/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&shareholder_name=${getPartnerName}&inquiry_id=${inquiryId}&partner_ids=${findPartnerId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setExistingPartnerList(res ?.data ?.Data);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [declineInqLeadData, setDeclineInqLeadData] = useState(null);
  const getDeclineInqLeadDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    try {
      const url =
        `https://flyingcolour.online/fcapp/api/decline_inquiry/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDeclineInqLeadData(res ?.data ?.results);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [bulkReassignProjectData, setBulkReassignProject] = useState(null);
  const getBulkReassignProjectApi = async (supportId) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    try {
      const url =
        `https://flyingcolour.online/fcapp/docprocessing_api/projectbulkreassigntosupport/?emp_id=${emp_id}&user_type=${user_type}&support_id=${supportId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setBulkReassignProject(res ?.data ?.Data);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [complaintListData, setComplaintListData] = useState(null);
  const getComplaintListDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      const url =
        `https://flyingcolour.online/fcapp/typist_app/get_complaint_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&complaint_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setComplaintListData(res ?.data ?.results ?.Complaint_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  // const [complainFormData, setComplainFormData] = useState(null);
  // const getComplainFormDataApi = async (companyId) => {
  //   const __token = localStorage.getItem("token");
  //   const user_type = localStorage.getItem('user_type');
  //   const emp_id = localStorage.getItem("emp_id");
  //   const factory_id = localStorage.getItem("factory_id");
  //   setLoading(true);
  //   try {
  //     const url =
  //       `https://flyingcolour.online/fcapp/typist_app/get_complaint_type_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&complaint_type=${1}&company_id=${companyId}`;
  //     const headers = {
  //       Authorization: `Token ${__token}`,
  //       "Content-Type": "application/json",
  //     };
  //     const res = await axios.get(url, { headers });
  //     setComplainFormData(res?.data?.results);
  //     setLoading(false);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  // const [complainFormData_CM, setComplainFormData_CM] = useState(null);
  // const getComplainFormData_CMApi = async (companyId, complaintType) => {
  //   const __token = localStorage.getItem("token");
  //   const user_type = localStorage.getItem('user_type');
  //   const emp_id = localStorage.getItem("emp_id");
  //   const factory_id = localStorage.getItem("factory_id");
  //   setLoading(true);
  //   try {
  //     const url =
  //       `https://flyingcolour.online/fcapp/typist_app/get_complaint_type_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&complaint_type=${complaintType}&company_id=${companyId}`;
  //     const headers = {
  //       Authorization: `Token ${__token}`,
  //       "Content-Type": "application/json",
  //     };
  //     const res = await axios.get(url, { headers });
  //     setComplainFormData_CM(res?.data?.results);
  //     setLoading(false);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const [receivableProjHistoryData, setReceivableProjHistoryData] = useState(null);
  const getReceivableProjHistoryDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const projectionMonth = data === "" || data ?.Projection_Month === undefined ? "" : data ?.Projection_Month;
    const projectionYear = data === "" || data ?.Projection_Year === undefined ? "" : data ?.Projection_Year;
    const paymentMonth = data === "" || data ?.Paid_Month === undefined ? "" : data ?.Paid_Month;
    const paymentYear = data === "" || data ?.Paid_Year === undefined ? "" : data ?.Paid_Year;
    const projectionStatus = data === "" || data ?.Projection_Status === undefined ? "" : data ?.Projection_Status;

    setLoading(true);
    try {
      const url =
        `https://flyingcolour.online/fcapp/receivable_manager_api/get_receivable_projection_report_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&projection_month=${projectionMonth}&projection_year=${projectionYear}&payment_month=${paymentMonth}&payment_year=${paymentYear}&projection_status=${projectionStatus}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReceivableProjHistoryData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [receivableProjectionReportCountData, setReceivableProjectionReportCountData] = useState(null);
  const getReceivableProjectionReportCountDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const searchYear = data ?.year === "" || data ?.year === undefined ? "" : data ?.year;
    const searchMonth = data ?.month === "" || data ?.month === undefined ? "" : data ?.month;


    setLoading(true);
    try {
      const url =
        `https://flyingcolour.online/fcapp/receivable_manager_api/get_receivable_projection_report_count_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&parent_company_id=${user_type === "CLIENT_MANAGER" || user_type === "CLIENT_MANAGER_SH" ? factory_id : 17}&client_manager_id=${emp_id}&search_year=${searchYear}&search_month=${searchMonth}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReceivableProjectionReportCountData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [taxCompanyData, setTaxCompanyData] = useState(null);
  const getCmTaxCompanyListingData = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/cm_tax_companylist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_flag=4`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      sessionStorage.setItem(
        "listing_companies_res",
        JSON.stringify(res ?.data ?.results ?.CM_Tax_Company_List)
      );
      setTaxCompanyData(res ?.data ?.results ?.CM_Tax_Company_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };
  const [cmTaxOtherCompanyData, setCmTaxOtherCompanyData] = useState(null);
  const getCmTaxOtherCompanyDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/cm_tax_companylist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_flag=5`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCmTaxOtherCompanyData(res ?.data ?.results ?.CM_Tax_Company_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };
  const [cmPartnerData, setCmPartnerData] = useState(null);
  const getCmPartnerDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/companypartnerlist/?emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCmPartnerData(res ?.data.results.Data_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [partnerCompanySharesData, setPartnerCompanySharesData] = useState(null);
  const getPartnerCompanySharesDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const partnerId = businessInfo ?.Partner_Id;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/compliance/get_partner_wise_cmp_share/?emp_id=${emp_id}&user_type=${user_type}&partner_id=${partnerId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPartnerCompanySharesData(res ?.data.results ?.Company_Share_Details);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [cmSHListingData, setCmSHListingData] = useState(null);
  const getCmSHListingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const partnerId = businessInfo ?.Partner_Id;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/cm_sh_companylist/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_flag=6`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCmSHListingData(res ?.data.results ?.CM_SH_Company_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const getCmSHOtherCompanyListingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const partnerId = businessInfo ?.Partner_Id;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/cm_sh_companylist/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_flag=7`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCmTaxOtherCompanyData(res ?.data.results ?.CM_SH_Company_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [prospectCompanyData, setProspectCompanyData] = useState(null);
  const getProespectCompanyDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ?
      businessInfo ?.Company_Id || businessInfo ?.Company_ID : data ?.Inquiry_Id ?.replace("FC", "") || businessInfo ?.Inquiry_Id ?.replace("FC", "");
    const prospectId = data === undefined || data.Prospect_Id === undefined ? 0 : data.Prospect_Id
    const prospectType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;


    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_wise_prospect_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${compId === undefined ? 0 : compId}&prospect_id=${prospectId}&prospect_type=${prospectType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectCompanyData(res ?.data.results ?.Company_Wise_Prospect_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };
  const [ProspectFollowupData, setProspectFullowUpData] = useState(null);
  const getProspectFollowUpDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || data ?.Company_Id || businessInfo ?.Company_ID || 0;
    const prospectId = salesInfo === undefined || salesInfo.Prospect_Id === undefined || salesInfo.Prospect_Id === "" ? 0 : salesInfo.Prospect_Id
    const flag = salesInfo.Prospect_Id === undefined || salesInfo.Prospect_Id === "" ? 2 : 1;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/prospect_wise_followup_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${compId}&prospect_id=${prospectId}&followup_flag=${flag}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectFullowUpData(res ?.data.results);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };
  const [othetrFollowupData, setOthetrFullowUpData] = useState(null);
  const getOtherFollowUpDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || data ?.Company_Id || businessInfo ?.Company_ID || 0;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/others_emp_followup_company_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${compId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOthetrFullowUpData(res ?.data);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [prospectViewingData, setProspectViewingData] = useState(null);
  const getProspectViewingDataDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || businessInfo ?.Company_ID;
    const prospectId = salesInfo === undefined || salesInfo.Prospect_Id === undefined ? 0 : salesInfo.Prospect_Id
    const viewingType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;


    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_viewing_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${viewingType === 1 && compId !== undefined ? compId : 0}&prospect_id=${viewingType === 1 ? prospectId : 0}&viewing_type=${viewingType}&inquiry_id=${viewingType === 1 ? 0 : businessInfo ?.Inquiry_Id ?.replace("FC", "")}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectViewingData(res ?.data.results);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [prospectNOFData, setProspectNOFData] = useState(null);
  const getProspectNOFDataDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || businessInfo ?.Company_ID;
    const prospectId = salesInfo === undefined || salesInfo.Prospect_Id === undefined ? 0 : salesInfo.Prospect_Id
    const nofType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_wise_nof_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${nofType === 1 ? compId : 0}&prospect_id=${nofType === 1 ? prospectId : 0}&nof_type=${nofType}&inquiry_id=${nofType === 1 ? 0 : businessInfo ?.Inquiry_Id ?.replace("FC", "")}&client_id=${nofType === 1 ? 0 : businessInfo ?.Client_Id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectNOFData(res ?.data.results);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [nOFShareholderData, setNOFShareholderData] = useState(null);
  const getNOFShareholderDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || businessInfo ?.Company_ID;
    const nofType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_nof_partner_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&nof_type=${nofType}&company_id=${nofType === 1 ? compId : 0}&inquiry_id=${nofType === 1 ? 0 : businessInfo ?.Inquiry_Id ?.replace("FC", "")}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setNOFShareholderData(res ?.data.results ?.partner_list);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.log(err);
    }
  };

  function findAreaIds() {
    const vart = [];
    getParamsData ?.Interest_Area_Ids ?.split(",").forEach((el) => {
      vart ?.push({ value: Number(el), label: el });
    });
    const mapArray = vart.map((i) => i);
    return mapArray;
  }

  const [prospectAreaIds, setProspectAreaIds] = useState(
    editForm ?
      findAreaIds()
      : []
  );
  function findPropertyTypeIds() {
    const vart = [];
    getParamsData ?.Interest_Property_Type_Ids ?.split(",").forEach((el) => {
      vart ?.push({ value: Number(el), label: el });
    });
    const mapArray = vart.map((i) => i);
    return mapArray;
  }
  const [prospectPropertyTypeIds, setProspectPropertyTypeIds] = useState(
    editForm ?
      findPropertyTypeIds()
      : []);
  const [prospectStateIds, setProspectStateIds] = useState(editForm ? Number(getParamsData ?.State_Id) : 0);
  const getAreaIds = prospectAreaIds ?.map((item) => {
    const obj = item ?.value;
    return obj;
  });
  const getPropertyTypeIds = prospectPropertyTypeIds ?.map((item) => {
    const obj = item ?.value;
    return obj;
  });
  const [dropdownData, setDropdownData] = useState();
  const getDropdownDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = businessInfo ?.Company_Id || businessInfo ?.Company_ID;
    const areaId = getAreaIds;
    const propertyIds = getPropertyTypeIds;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/prospect_company_dependency/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&state_id=${prospectStateIds}&area_id=${areaId}&property_type_id=${propertyIds}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDropdownData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };


  const [viewingAreaIds, setViewingAreaIds] = useState(editForm ? Number(getParamsData ?.Area_Ids) : "");
  function findPropertyTypeIds() {
    const vart = [];
    getParamsData ?.Interest_Property_Type_Ids ?.split(",").forEach((el) => {
      vart ?.push({ value: Number(el), label: el });
    });
    const mapArray = vart.map((i) => i);
    return mapArray;
  }
  const [viewingPropertyTypeIds, setViewingPropertyTypeIds] = useState(
    editForm ?
      findPropertyTypeIds()
      : []);
  const [ViewingStateIds, setViewingStateIds] = useState(editForm ? Number(getParamsData ?.State_Id) : 0);
  const getViewingPropertyTypeIds = viewingPropertyTypeIds ?.map((item) => {
    const obj = item ?.value;
    return obj;
  });
  const getViewingDropdownDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = businessInfo ?.Company_Id || businessInfo ?.Company_ID;
    const propertyIds = getViewingPropertyTypeIds;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_viewing_dependency/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&state_id=${ViewingStateIds}&area_id=${viewingAreaIds}&property_type_id=${propertyIds}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDropdownData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [nofDropdownData, setNOFDropdownData] = useState(null);
  const [stateIds, setStateIds] = useState(editForm ? Number(getParamsData ?.Emirates_Id) : 0);
  const [areaIds, setAreaIds] = useState(editForm ? getParamsData ?.Area_Id : "");
  const [propertyTypeIds, setPropertyTypeIds] = useState(editForm ? getParamsData ?.Property_Type_Id : "");

  const getNOFDropdownDataaApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || businessInfo ?.Company_ID;
    const prospectId = salesInfo === undefined || salesInfo.Prospect_Id === undefined ? 0 : salesInfo.Prospect_Id
    const nofType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_nof_dependency/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&state_id=${stateIds}&area_id=${areaIds}&property_type_id=${propertyTypeIds}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setNOFDropdownData(res ?.data.results);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [companyNoFutureAlertsData, setCompanyNoFutureAlertsData] = useState(null);
  const getCompanyWithNoFutureAlertsDataaApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = salesInfo ?.Company_Id || businessInfo ?.Company_ID;
    const prospectId = salesInfo === undefined || salesInfo.Prospect_Id === undefined ? 0 : salesInfo.Prospect_Id
    const nofType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_noFuture_alerts_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCompanyNoFutureAlertsData(res ?.data.results ?.Company_No_Future_Alerts_List);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const momCMSHDownloadView = async (data) => {
    const compId = data ?.Company_Id || salesInfo ?.Company_Id;

    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/client_manager_sh/company_prospect_momdownload_view/?company_id=${compId}&factory_id=${factory_id}&followup_id=${data.followup_id || data.Followup_Id}&emp_id=${emp_id}&&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`);
        } else {
          return setMeetingView(data);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from mom view alert:", error);
      });
  };

  const [prospectQuotationData, setProspectQuotationData] = useState(null);
  const [propertyType, setPropertyType] = useState(editForm ? getParamsData ?.Property_Type : "");
  const getProspectQuotationDataDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? salesInfo ?.Company_Id || businessInfo ?.Company_ID : 0;
    const prospectId = salesInfo === undefined || salesInfo.Prospect_Id === undefined ? 0 : salesInfo.Prospect_Id
    const quotFlagType = user_type === "CLIENT_MANAGER_SH" && (tabChange === undefined || tabChange === 0) ? 1 : 2;
    const quotationId = salesInfo === undefined || salesInfo.quotation_id === undefined ? 0 : salesInfo.quotation_id;

    setLoad(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_prospect_quotation_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${quotFlagType === 1 ? compId : 0}&prospect_id=${prospectId}&quotation_flag_type=${quotFlagType}&inquiry_id=${quotFlagType === 2 ? businessInfo ?.Inquiry_Id.replace("FC", "") : 0}&quotation_id=${quotationId}&property_type=${propertyType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectQuotationData(res ?.data.results);
      setLoad(false);
    } catch (err) {
      setLoad(false);

    }
  };

  const [prospectReportData, setProspectReportData] = useState(null);
  const getProspectReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/prospect_report_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&from_date=${reqFromDate}&&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectReportData(res ?.data.results ?.Prospect_Report_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [viewingReportData, setViewingReportData] = useState(null);
  const getViewingReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_viewing_report_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&from_date=${reqFromDate}&&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setViewingReportData(res ?.data.results ?.Company_Inquiry_Viewing_Report_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [nOFReportData, setNOFReportData] = useState(null);
  const getNOFReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/nof_report_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&from_date=${reqFromDate}&&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setNOFReportData(res ?.data.results ?.NOF_Report_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [quotation_CMSH_ReportData, setQuotation_CMSH_ReportData] = useState(null);
  const getQuotation_CMSH_ReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/quotation_report_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${0}&from_date=${reqFromDate}&&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setQuotation_CMSH_ReportData(res ?.data.results ?.Quotation_Report_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [mOM_CMSH_ReportData, setMOM_CMSH_ReportData] = useState(null);
  const getMOM_CMSH_ReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/mom_report_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${0}&from_date=${reqFromDate}&&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setMOM_CMSH_ReportData(res ?.data ?.MOM_Report_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [prospectMainListingData, setProspectMainListingData] = useState(null);
  const getProspectMainListingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const prospectType = userTypeInfo === "SALES_PERSON" || tabChange === 1 ? 2 : 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/prospect_all_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&prospect_type=${prospectType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProspectMainListingData(res ?.data ?.results ?.Prospect_All_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };
  const [viewingMainListingData, setViewingMainListingData] = useState(null);
  const getViewingMainListingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const viewingType = userTypeInfo === "SALES_PERSON" || tabChange === 1 ? 2 : 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_sh/company_inquiry_viewing_all_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&viewing_type=${viewingType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setViewingMainListingData(res ?.data ?.results ?.Company_Inquiry_Viewing_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };
  const [cmTaxCompanySideData, setCmTaxCompanySideData] = useState(null);
  const getCmTaxCompanySideDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_tax/mom_companySide_title_descr_List/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCmTaxCompanySideData(res ?.data ?.results ?.MOM_Company_Side_Title_Descr_Master);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [preCPLisitnData, setPreCPLisitnData] = useState(null);
  const getPreCPLisitnDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = data ?.referral_partner_id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/pre_cp_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=1&referral_partner_id=${referralPartnerId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPreCPLisitnData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [preCPQualifiedLisitnData, setPreCPQualifiedLisitnData] = useState(null);
  const getPreQualifiedCPLisitnDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = data ?.Referral_Partner_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/pre_cp_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=0&referral_partner_id=${referralPartnerId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPreCPQualifiedLisitnData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [cpFollowUpData, setCpFollowUpData] = useState(null);
  const getCpFollowUpDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = businessInfo ?.Referral_Partner_Id || data ?.Referral_Partner_Id || 0;
    const cpType = tabValue === 2 ? 1 : 0;
    const followupId = businessInfo ?.Followup_Id || data ?.Followup_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_followup_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=${cpType}&referral_partner_id=${referralPartnerId}&followup_id=${followupId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpFollowUpData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };
  const [cpDocumentData, setCpDocumentData] = useState(null);
  const getCpDocumentDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = businessInfo ?.Referral_Partner_Id || 0;
    const cpType = tabValue === 2 ? 1 : 0;
    const docId = businessInfo ?.document_id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_document_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=${cpType}&referral_partner_id=${referralPartnerId}&document_id=${docId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpDocumentData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [cpAlertData, setCPAlertData] = useState(null);
  const getTodayCPAlertApi = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");

    await fetch(
      `https://flyingcolour.online/fcapp/cp_coordinator/cp_alerts_list/?emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}&factory_id=${factory_ids}&cp_type=${1}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setCPAlertData(data.Alert_Details_Inquiry);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };
  const [teamCPAlertData, setTeamCPAlertData] = useState(null);
  const getTodayTeamCPAlertApi = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    const todayNewDate = date === undefined ? moment(new Date()).format("YYYY-DD-MM") : date;
    await fetch(
      `https://flyingcolour.online/fcapp/cp_coordinator/team_cp_alerts_list/?emp_id=${empId}&user_type=${userTypeInf}&due_date=${todayNewDate}&factory_id=${factory_ids}&cp_type=${1}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setTeamCPAlertData(data.Alert_Details_Inquiry);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const getTodayRefFcAlertApi = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");

    await fetch(
      `https://flyingcolour.online/fcapp/cp_coordinator/referred_by_fc_alerts_list/?emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}&&factory_id=${factory_ids}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setCPAlertData(data.Alert_Details_Inquiry);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const [momReportData, setMomReportData] = useState(null);
  const getMomReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const cpType = tabValue === 2 ? 1 : 1;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_mom_report_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=${cpType}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setMomReportData(res ?.data ?.MOM_Report_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const momCPDownloadView = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = businessInfo ?.Referral_Partner_Id || data ?.Referral_CP_Id || 0;
    const cpType = tabValue === 2 ? 1 : 0;
    const FollowupId = data ?.Followup_Id || 0;

    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/cp_coordinator/cp_momdownload_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=${cpType}&referral_partner_id=${referralPartnerId}&followup_id=${FollowupId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`);
        } else {
          return setMeetingView(data);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from mom view alert:", error);
      });
  };

  const [cpReferralAgreementData, setCpReferralAgreementData] = useState(null);
  const getCpReferralAgreementDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = businessInfo ?.Referral_Partner_Id || data ?.Referral_CP_Id || 0;
    const cpType = tabValue === 2 ? 1 : 0;
    const refAgrId = getParamsData ?.referral_agreemnet_id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_referral_agreemnet_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=${cpType}&referral_partner_id=${referralPartnerId}&referral_agreemnet_id=${refAgrId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpReferralAgreementData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [referredByFCData, setReferredByFCData] = useState(null);
  const getReferredByFCDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referralPartnerId = businessInfo ?.Referral_Partner_Id || data ?.Referral_CP_Id || 0;
    const cpType = tabValue === 2 ? 1 : 0;
    const refByFc = getParamsData ?.referred_by_fc_id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/get_referred_by_fc_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&cp_type=${cpType}&referral_partner_id=${referralPartnerId}&referred_by_fc_id=${refByFc}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReferredByFCData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };
  const [cpAgreementFollowUpData, setCpAgreementFollowUpData] = useState(null);
  const getCpAgreementFollowUpDataApi = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const referredByFcId = businessInfo ?.Referred_ByFC_Id || data ?.Referred_ByFC_Id || 0;
    const cpType = tabValue === 2 ? 1 : 0;
    const followupId = 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/referred_by_fc_followup_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referred_by_fc_id=${referredByFcId}&followup_id=${followupId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpAgreementFollowUpData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const momReferredByFcDownloadView = async (data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoad(true);
    const referredByFcId = businessInfo ?.Referred_ByFC_Id || 0;
    const FollowupId = data ?.Followup_Id || 0;
    await fetch(
      `https://flyingcolour.online/fcapp/cp_coordinator/referred_by_fc_momdownload_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referred_by_fc_id=${referredByFcId}&followup_id=${FollowupId}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        if (
          data.data !== undefined
        ) {
          return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`);
        } else if (data.detail !== undefined) {
          return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`);
        } else {
          return setMeetingView(data);
        }
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from mom view alert:", error);
      });
  };

  const [cpDeclineData, setCpDeclineData] = useState(null);
  const getCpDeclineDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const decId = 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/decline_cp_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&decline_id=${decId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpDeclineData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };

  const [cpClientReviewData, setCpClientReviewData] = useState(null);
  const getCpClientReviewDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = businessInfo ?.Referral_Partner_Id;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_client_review/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&client_review_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpClientReviewData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };
  const [cpMarketingData, setCpMarketingData] = useState(null);
  const getCpMarketingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = businessInfo ?.Referral_Partner_Id;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_marketing_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&marketing_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpMarketingData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);

    }
  };
  const [cpPOCData, setCpPOCData] = useState(null);
  const getCpPOCDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = businessInfo ?.Referral_Partner_Id;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_point_to_contact_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&poc_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpPOCData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [cpPaymentReqData, setCpPaymentReqData] = useState(null);
  const getCpPaymentReqDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = businessInfo ?.Referral_Partner_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_referral_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpPaymentReqData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [cpInquiryData, setCpInquiryData] = useState(null);
  const getCpInquiryDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = businessInfo ?.Referral_Partner_Id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_inquiry_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpInquiryData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getCpPaymentReqReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_referral_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpPaymentReqData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getCpCoordinatorPaymentReqReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = emp_id;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_referral_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpPaymentReqData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getReferredByFcCpPaymentReqReportDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = emp_id;
    setLoading(true);
    try {
      const url = user_type === "COMMERCIAL_PARTNER_COORDINATOR" ?
        `https://flyingcolour.online/fcapp/cp_coordinator/referred_byfc_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${emp_id}&referred_byfc_payment_req_id=0`
        :
        `https://flyingcolour.online/fcapp/cp_coordinator/referred_byfc_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${0}&referred_byfc_payment_req_id=0`
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpPaymentReqData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getCpInquiryReportDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const refPartId = 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_inquiry_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${refPartId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpInquiryData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getCpInquiryCMDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const CompanyId = businessInfo ?.Company_ID;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_inquiry_list_by_cm/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${CompanyId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpInquiryData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [cpCompanyData, setCpCompanyData] = useState(null);
  const getCpCompanyDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const companyId = businessInfo ?.Company_Id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_company_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${companyId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpCompanyData(res ?.data ?.results ?.CP_Company_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [cpSubleadData, setCpSubleadData] = useState(null);
  const getCpSubleadDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const companyId = businessInfo ?.Company_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_paymentApprovedSublead_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${companyId}&from_date=${""}&to_date=${""}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpSubleadData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [cpOutsourceServiceData, setCpOutsourceServiceData] = useState(null);
  const getCpOutsourceServiceDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const companyId = businessInfo ?.Company_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_inquiryoutsourceserviceList/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&out_source_id=${companyId}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpOutsourceServiceData(res ?.data ?.results ?.CP_Inquirty_Outsoirce_Service_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [cpReplyData, setCpReplyData] = useState(null);
  const getCpReplyDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryOutsourceId = businessInfo ?.Id || 0;
    const replyId = getParamsData ?.Reply_Id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_inquiryoutsourceservices_reply_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_outsource_id=${inquiryOutsourceId}&reply_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpReplyData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [paymentRequisitionData, setPaymentRequisitionData] = useState(null);
  const getPaymentRequisitionDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const RefParId = businessInfo ?.Referral_Partner_Id || 0;
    const replyId = getParamsData ?.Reply_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/referred_byfc_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${RefParId}&referred_byfc_payment_req_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPaymentRequisitionData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [teamCPData, setTeamCPData] = useState(null);
  const getTeamCPDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryOutsourceId = businessInfo ?.Id || 0;
    const replyId = getParamsData ?.Reply_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/team_cp_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${0}&cp_type=${1}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTeamCPData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [teamQualifiedCPData, setTeamQualifiedCPData] = useState(null);
  const getTeamQualifiedCPDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryOutsourceId = businessInfo ?.Id || 0;
    const replyId = getParamsData ?.Reply_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/team_cp_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_partner_id=${0}&cp_type=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTeamQualifiedCPData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [cpInqPaymentData, setCpInqPaymentData] = useState(null);
  const getCpInqPaymentDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inquiryOutsourceId = businessInfo ?.Id || 0;
    const cpId = businessInfo ?.CP_Ids || 0;
    const inqId = businessInfo ?.Inquiry_Id || getParamsData ?.Inquiry_Id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/cp_inquiry_referral_payment_requisition_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&referral_cp_id=${cpId}&inquiry_id=${inqId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCpInqPaymentData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [secondaryPeformanceData, setSecondaryPeformanceData] = useState(null);
  const getSecondaryPeformanceDataApi = async (reqFromDate, reqToDate, sourceName) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const inqId = businessInfo ?.Inquiry_Id || getParamsData ?.Inquiry_Id || 0;
    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/cp_coordinator/get_secondary_performance_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&secondary_performance_id=${0}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSecondaryPeformanceData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [companyHistoryReportData, setCompanyHistoryReportData] = useState(null);
  const getCompanyHistoryReportDataApi = async (data, checkedItems, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const priority = data ?.priority || "";
    const date_from = data ?.dateFrom || "";
    const date_to = data ?.dateTo || "";
    const searchQuestions = data ?.searchName ?.map((o) => o ?.value);
    const searchNames = searchQuestions ?.toString() || "";
    const latest_record = checkedItems !== undefined && checkedItems.includes("Latest Record") ? 1 : 0


    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/CompanyHistorySearch/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&priority=${priority}&history_date_from=${date_from}&history_date_to=${date_to}&search_for=${searchNames}&latest_record=${latest_record}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCompanyHistoryReportData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [discountInServiceChargesData, setDiscountInServiceChargesData] = useState(null);
  const getDiscountInServiceChargesDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = businessInfo ?.Company_ID || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_tax/cmp_discount_service_charge/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${compId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDiscountInServiceChargesData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getAllDiscountInServiceChargesDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = businessInfo ?.Company_ID || 0;
    const startDate = start_Date === undefined ? "" : start_Date;
    const endDate = end_Date === undefined ? "" : end_Date;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_tax/cmp_discount_service_charge_report/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${0}&from_date=${startDate}&to_date=${endDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDiscountInServiceChargesData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [internalIncomData, setInternalIncomData] = useState(null);
  const getInternalIncomDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const compId = businessInfo ?.Company_ID || 0;
    const startDate = start_Date === undefined ? "" : start_Date;
    const endDate = end_Date === undefined ? "" : end_Date;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/client_manager_tax/get_company_internal_income_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${compId}&from_date=${startDate}&to_date=${endDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setInternalIncomData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [preQualifyLeadsData, setPreQualifyLeadsData] = useState(null);
  const getPreQualifyLeadsDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const startDate = start_Date === undefined ? "" : start_Date;
    const endDate = end_Date === undefined ? "" : end_Date;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_socialplatform_lead_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&from_date=${startDate}&to_date=${endDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPreQualifyLeadsData(res ?.data ?.results ?.Get_SocialPlatform_Lead_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [reQualifyLeadsData, setReQualifyLeadsData] = useState(null);
  const getReQualifyLeadsDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const startDate = start_Date === undefined ? "" : start_Date;
    const endDate = end_Date === undefined ? "" : end_Date;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_Requalification_Inquiry_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReQualifyLeadsData(res ?.data ?.results ?.Get_ReQualification_Inquiry_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [preQualifyFollowupData, setPreQualifyFollowupData] = useState(null);
  const getPreQualifyFollowupDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const leadType = businessInfo ?.QualifyDisqualify_Status === "No" ? 1 : businessInfo ?.QualifyDisqualify_Status === "Yes" ? 2 : 1;
    const socialLeadId = salesInfo ?.Social_Lead_Ids || salesInfo ?.social_lead_id || salesInfo ?.PreQualified_Lead_Id || 0;
    const Inq_Id = businessInfo ?.Inquiry_Id !== undefined && businessInfo ?.Inquiry_Id ?.replace("FC", "") || 0;


    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/pre_qualified_leads_followup_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&lead_type=${1}&social_lead_id=${socialLeadId}&followup_id=${0}&inquiry_id=${Number(Inq_Id)}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPreQualifyFollowupData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [preQualifyAlertData, setPreQualifyAlertData] = useState([]);
  const getPreQualifyLeadTodayAlert = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    await fetch(
      `https://flyingcolour.online/fcapp/receivable_manager_api/pre_qualified_lead_alerts_list/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}&social_lead_id=${0}&lead_type=${1}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setPreQualifyAlertData(data);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };

  const [reQualifyAlertData, setReQualifyAlertData] = useState(null);
  const getReQualifyLeadTodayAlertApi = async (date) => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");

    setLoad(true);
    await fetch(
      `https://flyingcolour.online/fcapp/receivable_manager_api/re_qualified_lead_alerts_list/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}&social_lead_id=${0}&lead_type=${1}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);
        setReQualifyAlertData(data);
      })
      .catch((error) => {
        setLoad(false);
        console.error("Error from today alert:", error);
      });
  };
  const [qualifyDisqualifyLogData, setQualifyDisqualifyLogData] = useState([]);
  const getQualifyDisqualifyLogDataAPi = async () => {
    let empId = localStorage.getItem("emp_id");
    let _token = localStorage.getItem("token");
    let factory_ids = localStorage.getItem("factory_id");
    let userTypeInf = localStorage.getItem("userType");
    setLoading(true);
    await fetch(
      `https://flyingcolour.online/fcapp/receivable_manager_api/get_SocialLead_qualify_disqualify_log_list/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${userTypeInf}&social_lead_id=${businessInfo ?.Social_Lead_Ids}`,
      {
        method: "GET",
        headers: headers(_token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setQualifyDisqualifyLogData(data ?.results);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error from today alert:", error);
      });
  };
  const [callNotConnectedLogData, setCallNotConnectedLogData] = useState([]);
  const getCallNotConnectedLogDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const socialLeadId = businessInfo ?.Social_Lead_Ids || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_SocialLead_callNotConnected_invalidNo_log_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&social_lead_id=${socialLeadId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCallNotConnectedLogData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getRequalifyCallNotConnectedLogDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const InqId = businessInfo ?.Inquiry_Id.replace("FC", "") || 0;
    const socialLeadId = businessInfo ?.social_lead_id || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_ReQualifyInquiry_callNotConnectedStatus_log_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_id=${Number(InqId)}&social_lead_id=${socialLeadId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCallNotConnectedLogData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const [callNotConnectedSPLogData, setCallNotConnectedSPLogData] = useState([]);
  const getCallNotConnectedSPLogDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const Inq_Id = businessInfo ?.Inquiry_Id.replace("FC", "") || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_Inquiry_callNotConnectedStatus_log_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_id=${Number(Inq_Id)}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCallNotConnectedSPLogData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [preQualificationQuestionsData, setPreQualificationQuestionsData] = useState([]);
  const preQualificationQuestionsDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const socialLeadId = businessInfo ?.Social_Lead_Ids || businessInfo ?.social_lead_id || 0;
    const Inq_Id = businessInfo ?.Inquiry_Id !== undefined && businessInfo ?.Inquiry_Id ?.replace("FC", "") || 0;
    const leadType = businessInfo ?.enquiry_type || ""

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/PreQualificationQuestions/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&social_lead_id=${socialLeadId}&inquiry_id=${Number(Inq_Id)}&lead_type=${leadType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPreQualificationQuestionsData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const reQualificationQuestionsDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const socialLeadId = businessInfo ?.Social_Lead_Ids || businessInfo ?.social_lead_id || 0;
    const Inq_Id = businessInfo ?.Inquiry_Id !== undefined && businessInfo ?.Inquiry_Id ?.replace("FC", "") || 0;
    const grpCompanyId = businessInfo ?.Group_Company_Id !== undefined && businessInfo ?.Group_Company_Id || 0;


    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/PreQualificationQuestions/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&social_lead_id=${socialLeadId}&inquiry_id=${Number(Inq_Id)}&group_company_id=${grpCompanyId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPreQualificationQuestionsData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [declinedLeadsData, setDeclinedLeadsData] = useState(null);
  const getDeclinedLeadsDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const socialLeadId = businessInfo ?.Social_Lead_Ids || businessInfo ?.social_lead_id || 0;
    const Inq_Id = businessInfo ?.Inquiry_Id !== undefined && businessInfo ?.Inquiry_Id ?.replace("FC", "") || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_Requalification_Declined_Inquiry_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setDeclinedLeadsData(res ?.data ?.results ?.Get_ReQualification_Declined_Inquiry_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [spDeclinedLeadsData, setSpDeclinedLeadsData] = useState(null);
  const getSpDeclinedLeadsDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const socialLeadId = businessInfo ?.Social_Lead_Ids || businessInfo ?.social_lead_id || 0;
    const Inq_Id = businessInfo ?.Inquiry_Id !== undefined && businessInfo ?.Inquiry_Id ?.replace("FC", "") || 0;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_SPSL_Declined_Inquiry_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_id=${0}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSpDeclinedLeadsData(res ?.data ?.results.Get_SPSL_Declined_Inquiry_List);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [spNOFData, setSpNofData] = useState(null);
  const getSpNofDataApi = async (start_Date, end_Date) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const startDate = start_Date === undefined ? "" : start_Date;
    const endDate = end_Date === undefined ? "" : end_Date;

    setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_SP_NOF_Report/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&inquiry_id=${0}&date_from=${startDate}&date_to=${endDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSpNofData(res ?.data ?.results);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };


  return (
    <DataContext.Provider
      value={{
        first,
        setfirst,
        listingParams,
        setListingParams,
        columns,
        originalRows,
        modalTable,
        handleModalTableClose,
        handleModalTableOpen,
        dataArr,
        setDataArr,
        handleModalOpen,
        handleModaleClose,
        modalOpen,
        buttonText,
        setButtonText,
        buttonTextNew, setButtonTextNew,
        businessInfo,
        setBusinessInfo,
        quotationTableArr,
        setQuotationTableArr,
        quotationTableVT,
        setQuotationTableVT,
        clientmanagerquotationTableArr,
        setClientmanagerquotationTableArr,
        dataApi,
        setDataApi,
        signedSaArr,
        ReasonforUnsubscribeList,
        getReasonforUnsubscribeList,
        statusList,
        getStatusList,
        statusList1,
        getStatusList1,
        setSignedSaArr,

        paymentReminderArr,
        setPaymentReminderArr,

        provisionalInvoiceArr,
        setProvisionalInvoiceArr,

        clientReviewArr,
        setClientReviewArr,

        selected,
        setSelected,

        editForm,
        setEditForm,

        dashboardToken,
        setDashBoardToken,

        fromDate,
        setFromDate,
        toDate,
        setToDate,

        authToken,
        setAuthToken,

        loading,
        fetchListingDataFromApi,
        setLoading,
        openForm,
        TodosList,
        setTodosList,
        setOpenForm,

        formApiMsg,
        fetchOutsourceServiceListingData,
        setOutsourcedata,
        outsourcedata,
        fetchOutsourceServiceReplyListingData,
        outsourcereplydata,
        setOutsourcereplydata,
        setFormApiMsg,

        clientRowData,
        setClientRowData,
        fetchSharedLeadListingData,
        formApiMsgError,
        sharedLeadData,
        setInquiryDropdown,
        setFormApiMsgError,
        editSaveButton,
        setEditSaveButton,
        fieldsEditing,
        setFieldsEditing,
        fieldsEditingMain,
        setFieldsEditingMain,
        factoryId,
        setFactoryId,
        tokenData,
        FetchcompanyInfoData1,
        setTokenData,

        authTokenData,
        setAuthTokenData,
        editApiData,
        setEditApiData,

        expirToken,
        setExpirToken,

        apiData,
        setApiData,
        detail1,
        setDetail1,
        detail,
        setDetail,
        editCard,
        setEditCard,
        editPropsal,
        setEditPropsal,
        responseOn,
        message,
        severity,
        setMessage,
        setSeverity,
        apiResponse,
        setResponse,
        setSharedLeadData,
        sharedLeadData,
        setServiceId,
        serviceId,
        open,
        setOpen,
        handleClickSnack,
        handleCloseSnack,
        getProposal,
        ClientManagergetProposal,
        salesInfo,
        setSalesInfo,
        salesInfo2, setSalesInfo2,
        leadCaption,
        setLeadCaption,
        listingResponse,
        // add_new_data,
        expiredMsg,
        setExpiredMsg,

        expiredErrorMsg,
        setExpiredErrorMsg,

        userTypeInfo,
        clientResponse,
        TodoListAPI,
        getSA,
        userTYpeInfoCheck,
        clientDetail,
        setClientDetail,
        assignReassignLogAPI,
        getFollowUpView,
        followUp,
        setFollowUp,
        loader,
        setLoader,
        getShareholderList,
        getSubleadList,
        getCompanyHistoryList,
        paymentreminderListAPI,
        crmBlockonCompanyHistoryList,
        getCompanyPortfolioList,
        enqAlerts,
        setEnqAlerts,
        momDownloadView,
        getEnQAlert,
        todayEnqAlerts,
        setTodayEnqAlerts,
        setMasterState,
        masterState,
        meetingView,
        setMeetingView,
        setCompanyList,
        setServiceList,
        CompanyList,
        ServiceList,
        load,
        setLoad,
        addInquiryBulkList,
        setAddInquiryBulkList,
        isShedule,
        setIsShedule,
        getTodayAlert,
        rowData,
        setRowData,
        callDueDate,
        value,
        setValue,
        handleChangeTab,
        handleChangeTabs,
        getServiceAgreement,
        getServiceAgreementVT,
        shData,
        setSHData,
        slData,
        setSLData,
        chData,
        setCHData,
        prData,
        setPRData,
        cpServiceData,
        setcpServiceData,
        cpServiceData1,
        setcpServiceData1,
        cpData,
        setCPData,
        saData,
        SLServiceData,
        setSLServiceData,
        setSAData,
        saTableData,
        setSaTableData,
        shareholderData,
        setshareholderData,
        shareholderData1,
        setshareholderData1,
        subleadservicesList,
        getSubleadservicesList,
        subleadData,
        setSubleadData,
        crmBlocked,
        setcrmBlocked,
        companyhistoryData,
        setCompanyHistoryData,
        companyportfolioData,
        setCompanyPortfolioData,
        companyportfolioData1,
        setCompanyPortfolioData1,
        portfolioservicesList,
        getportfolioservicesList,
        portfolioservicesList1,
        getportfolioservicesList1,
        stateId,
        setStateId,
        state_Id,
        setState_Id,
        formationId,
        setFormationId,
        legalTypeId,
        setLegalTypeId,
        activityLegal,
        setActivityLegal,
        getLegalActivity,
        employeeFirstName,
        employeeLastName,
        clickObjData,
        setClickObjData,
        quotationTableGetApi,
        ClientManagerquotationTableGetApi,
        quotationReportApi,
        provisionalInvoiceTableGetApi,
        quotationApiMsg,
        setQuotationApiMsg,
        quotationApiMsg2,
        setQuotationApiMsg2,
        quotationEditModal,
        quotationEditModal1,
        setQuotationEditModal,
        setQuotationEditModal1,
        quotationEditArr,
        setQuotationEditArr,
        disableField,
        setDisableField,
        inqWOFutureAlert,
        companyWOFutureAlert,
        getInquiryWithoutFutureAlertTable,
        getCompanyWithoutFutureAlertTable,
        values,
        setValues,
        masterFormationType,
        setMasterFormationType,
        masterLegalType,
        setMasterLegalType,
        masterMainActivity,
        setMasterMainActivity,
        masterMainActivityLicence,
        setMasterMainActivityLicence,
        remarkData,
        setRemarkData,
        instantLicence,
        setInstantLicence,
        quotationData,
        setQuotationData,
        packageProposal,
        setPackageProposal,
        getSAList,
        setMasterVatTaxServiceData,
        MasterVatTaxServiceData,
        fetchMasterVatTaxServiceData,
        getSAEdit,
        isLoad,
        getSHEdit,
        getSLEdit,
        getCHEdit,
        getOSEdit,
        getOSREdit,
        osrData,
        setOSRData,
        osData,
        setOSData,
        getpaymentreminderDone,
        prDone,
        setprDone,
        getPREdit,
        getCPEdit,
        getCPServicesList,
        getCPServicesList1,
        activityHide,
        setActivityHide,
        error,
        setError,
        getECFollowUpView,
        followUpList,
        setFollowupList,
        getECFollowUpEdit,
        resheduler,
        setResheduler,
        getResheduleData,
        alertData,
        setAlertData,
        getTodayECsAlert,
        viewProvisonalInvoiceArr,
        provisionalInvoiceEditGetApi,
        setViewProvisionalInvoiceArr,
        editProvisonalInvoice,
        setEditProvisionalInvoice,
        setPIRow,
        PIRow,
        companyFlag,
        setCompanyFlag,
        iPapiData,
        setiPapiData,
        InformationPoolListingApi,
        filterData,
        setFilterData,
        quotationTableVTGetApi,
        parentCompany,
        setParentCompany,
        ParentCompanyListApi,
        momReport,
        setMomReport,
        InquirymomReport,
        setInquirymomReport,
        SAReports,
        setRichTextVTData,
        fetchRichTextVTData,
        RichTextVTData,
        setSAReports,
        PIReports,
        setPIReports,
        MomReportApi,
        InquiryMomReportApi,
        SAReportApi,
        PIReportApi,
        compDetail,
        setCompDetail,
        getCompanyDetailApi,
        orderData,
        setOrderData,
        getOrderListApi,
        orderDropDown,
        setOrderDropDown,
        getOrderDropDownApi,
        loadData,
        otherCompanies,
        setOtherCompany,
        fetchOtherCompanyECListingData,
        requestDeclineList,
        declineList,
        meetingProjection,
        setMeetingProjection,
        meetingProjectionData,
        revenueProjection,
        setRevenueProjection,
        revenueProjectionData,
        errorAlert, setErrorAlert,
        ProposalReportData,
        setEditQuotationVT,
        editQuotationVT,
        setViewQuotationVT,
        viewQuotationVT,
        getOrderVTListApi,
        orderDataVT,
        sCData,
        setSCData,
        getServiceChargesVT,
        vatDetail,
        setVatDetail,
        getServiceVTDetail,
        setEditOrderFormVT,
        editOrderFormVT,
        saPTermsData,
        setSAPTermsData,
        getpaymentTermsVT,
        editView, setEditView,
        getServiceAgreementForCompany,
        clientData, setClientData,
        getPointToBeContact,
        getSAListForCompany,
        getOrderDropDownForCompany,
        getOrderListForCompany,
        getServiceAgreementVTListing, subLeadInfo, setSubLeadInfo,
        subLeadParams, setSubLeadParams,
        FollowUpReportApi, followUpReport,
        EChannelExpiryApi, eChannelExpiry,
        UsernameAndPasswordExpiryApi, userAndPassExpiry,
        RSATokenPassExpiryApi, rSATokenPassExpiry,
        getContactPassportExpiryApi, contPassExpData,
        getEmpLabourExpApi, empLabourExpData,
        getEmpVisaExpApi, empVisaExpData,
        getImgCardExpApi, imgCardExpData,
        getInvtVisaExpApi, invtVisaExpData,
        getInvtPassExpApi, invtPassExpData,
        getPOBoxExpApi, pOBoxExpData,
        getEChannelCardExpApi, eChannelCardExpData,
        getTradeMarkExpApi, tradeMarkExpData,
        getTodayInquiryOutsourceServiceDataApi, todayInquiryOutsourceServiceData,
        getTodayOngoingOutsourceServiceDataApi, todayOngoingOutsourceServiceData,
        getTotalLicenseExpiredDataApi, totalLicenseExpiredData,
        getTotalAssignedOutsourceServiceDataApi, totalAssignedOutsourceServiceData,
        getTodayECsAlertforOtherCompany, alertDataForOtherCompany,
        alertECsForOtherCompanyCMData, setAlertECsForOtherCompanyCMData,
        parentCompanyData, setParentCompanyData,
        getComplianceFormDataApi, complianceFormData,
        getParamsData, setParamsData,
        getDeclinedCompaniesDataApi, declinedCompaniesData,
        getAssignedCompaniesDataApi, assignedCompaniesData,
        getSubLeadsDataApi, subLeadsData,
        getRevenueProjectionCompanyDataApi, revenueProjectionCompanyData,
        meetingProjectionCompanyDataApi, meetingProjectionCompany,
        getClientPortfolioDataApi, clientPortfolioData,
        getServiceAgreementReportDataApi, serviceAgreementReportData,
        getOrderReportDataApi, orderReportData,
        getAllCompanyOrderReportDataApi,
        getQuotationReportDataApi, quotationReportData,
        getFollowUpReportDataApi, followUpReportData,
        setRepFromDate, setRepToDate, repToDate, repFromDate,
        getClientDataControllertListDataApi, clientDataControllertListData, setInqWOFutureAlert,
        existingPartnerList, setExistingPartnerList,
        getExistingPartnerListApi, getPartnerName, setPartnerName,
        pendingAlertDataForOtherCompany, pendingAlertData,
        setSupportBadges, supportBadges,
        getDeclineInqLeadDataApi, declineInqLeadData,
        bulkReassignProjectData, getBulkReassignProjectApi,
        start_Date, setStart_Date, end_Date, setEnd_Date,
        getComplaintListDataApi, complaintListData,
        // getComplainFormDataApi, complainFormData,
        // getComplainFormData_CMApi, complainFormData_CM, 
        inquiryDropdown,
        getReceivableProjHistoryDataApi, receivableProjHistoryData,
        getReceivableProjectionReportCountDataApi, receivableProjectionReportCountData,
        getCmTaxCompanyListingData, taxCompanyData,
        getCmTaxOtherCompanyDataApi, cmTaxOtherCompanyData, getTaxFollowUpReportDataApi, followUpTaxReportData,
        getCmPartnerDataApi, cmPartnerData, getPartnerCompanySharesDataApi, partnerCompanySharesData,
        getTodaySubLeadsDataApi, todaySubLeadsData, getTotalSubLeadsDataApi, totalSubLeadsData,
        momTaxDownloadView, getVatTaxQuotationData, getCmSHListingDataApi, cmSHListingData,
        getCmSHOtherCompanyListingDataApi, getProespectCompanyDataApi, prospectCompanyData,
        getProspectFollowUpDataApi, ProspectFollowupData, getProspectViewingDataDataApi, prospectViewingData,
        getOtherFollowUpDataApi, othetrFollowupData,
        tabChange, setTabChange, getProspectNOFDataDataApi, prospectNOFData,
        getNOFDropdownDataaApi, nofDropdownData,
        areaIds, setAreaIds, stateIds, setStateIds, propertyTypeIds, setPropertyTypeIds,
        getCompanyWithNoFutureAlertsDataaApi, companyNoFutureAlertsData,
        getTodayECsAlertOnCMSH, momCMSHDownloadView,
        getProspectQuotationDataDataApi, prospectQuotationData, propertyType, setPropertyType,
        prospectAreaIds, setProspectAreaIds, prospectPropertyTypeIds, setProspectPropertyTypeIds,
        prospectStateIds, setProspectStateIds, getDropdownDataApi, dropdownData,
        viewingAreaIds, setViewingAreaIds, viewingPropertyTypeIds, setViewingPropertyTypeIds,
        ViewingStateIds, setViewingStateIds, getViewingDropdownDataApi,
        countBadges, setCountBadges,
        getProspectReportDataApi, prospectReportData, getViewingReportDataApi, viewingReportData,
        getNOFReportDataApi, nOFReportData, getQuotation_CMSH_ReportDataApi, quotation_CMSH_ReportData,
        getMOM_CMSH_ReportDataApi, mOM_CMSH_ReportData,
        getProspectMainListingDataApi, prospectMainListingData, getViewingMainListingDataApi, viewingMainListingData,
        getClientManagerMOMReportApi, cmMOMReportData, tabValue, setTabValue,
        meetingProjectionSHCompanyDataApi,
        isDeclinedLeadDisabledBtn, setIsDeclinedLeadDisabledBtn,
        getNOFShareholderDataApi, nOFShareholderData,
        getCmTaxCompanySideDataApi, cmTaxCompanySideData,
        getPreCPLisitnDataApi, preCPLisitnData,
        getPreQualifiedCPLisitnDataApi, preCPQualifiedLisitnData,
        getCpFollowUpDataApi, cpFollowUpData, getCpDocumentDataApi, cpDocumentData,
        getTodayCPAlertApi, getMomReportDataApi, momReportData,
        momCPDownloadView, cpAlertData,
        getCpReferralAgreementDataApi, cpReferralAgreementData,
        getReferredByFCDataApi, referredByFCData,
        getCpAgreementFollowUpDataApi, cpAgreementFollowUpData,
        momReferredByFcDownloadView, getTodayRefFcAlertApi,
        getCpDeclineDataApi, cpDeclineData,
        getCpClientReviewDataApi, cpClientReviewData,
        getCpMarketingDataApi, cpMarketingData,
        getCpPOCDataApi, cpPOCData, getCpPaymentReqDataApi, cpPaymentReqData,
        getCpInquiryDataApi, cpInquiryData, getCpInquiryReportDataApi,
        getCpPaymentReqReportDataApi, getCpCompanyDataApi, cpCompanyData,
        getCpSubleadDataApi, cpSubleadData,
        getCpOutsourceServiceDataApi, cpOutsourceServiceData,
        getCpReplyDataApi, cpReplyData,
        getPaymentRequisitionDataApi, paymentRequisitionData,
        getTeamCPDataApi, teamCPData,
        getTeamQualifiedCPDataApi, teamQualifiedCPData,
        getTodayTeamCPAlertApi, teamCPAlertData,
        getCpCoordinatorPaymentReqReportDataApi,
        getReferredByFcCpPaymentReqReportDataApi,
        getCpInqPaymentDataApi, cpInqPaymentData,
        getSecondaryPeformanceDataApi, secondaryPeformanceData,
        getCpInquiryCMDataApi,
        pdBadges, setPDBadges,
        decline_date_from, setDeclineDateFrom,
        decline_date_to, setDeclineDateTo,
        lead_assign_date_from, setLeadAssignDateFrom,
        lead_assign_date_to, setLeadAssignDateTo,
        clientReviewReport, getEcOtherFollowUpApi,
        getCompanyHistoryDropdownApi, companyhistoryDropdown,
        getCompanyHistoryReportDataApi, companyHistoryReportData,
        getDiscountInServiceChargesDataApi, discountInServiceChargesData,
        getAllDiscountInServiceChargesDataApi,
        getPreQualifyLeadsDataApi, preQualifyLeadsData,
        getPreQualifyFollowupDataApi, preQualifyFollowupData,
        getPreQualifyLeadTodayAlert, preQualifyAlertData,
        getInternalIncomDataApi, internalIncomData,
        getQualifyDisqualifyLogDataAPi, qualifyDisqualifyLogData,
        getCallNotConnectedLogDataApi,
        getRequalifyCallNotConnectedLogDataApi, callNotConnectedLogData,
        preQualificationQuestionsDataApi, reQualificationQuestionsDataApi,
        preQualificationQuestionsData,
        getCallNotConnectedSPLogDataApi, callNotConnectedSPLogData,
        getReQualifyLeadsDataApi, reQualifyLeadsData,
        alertTabValue,
        getReQualifyLeadTodayAlertApi, reQualifyAlertData,
        getDeclinedLeadsDataApi, declinedLeadsData,
        getSpNofDataApi, spNOFData,
        getSpDeclinedLeadsDataApi, spDeclinedLeadsData,
        sharedData, setSharedData

      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataProvider;
