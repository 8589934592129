import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload, FaFileMedical, FaEye, FaSync, FaTimes, FaHandshake, FaIdBadge, FaAdn, FaSortAmountDown, FaCheckDouble, FaReply
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ApprovalIcon from '@mui/icons-material/Approval';
import GradingIcon from '@mui/icons-material/Grading';
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import axios from "axios";
import Select from 'react-select';
import { CustomSelect, CustomMultiSelect } from "../../../listing/listingForm/ListingForm";
import { Modal } from "@mui/material";
import SubmitModal from "../Modals/SubmitModal";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader'
import { useFormik, Formik } from "formik";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';





const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReAssignOrderListTable = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop, fitlerBadge
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    setAcceptAccountArray,
    getPropertyFormDataApi,
    message, severity, setSeverity,
    setMessage, loading, setLoading,
    isOpen, setIsOpen,
   
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);
  const [reloadComponent, setReloadComponent] = useState(false);
  const [typistDetails, setTypistDetails] = useState({});
  const [disabledId, setDisabledId] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [getTypistId, setTypistId] = useState(0);
  const [assignFrom, setAssignFrom] = useState("");
  const [assignTo, setAssignTo] = useState("");


  // let getSupportId = filterSupportId?.map((value) =>
  // value?.value.toString(),
  // );
  //   let opt;
  //   for (let i = 0; i < getSupportId.length; i++) {

  //      opt = getSupportId[i];
  // }
  useEffect(() => {
    handleListingSavedSearch();
    getBulkOrdersApi();
  }, []);

  const EmployeeList = filterOrders?.employee_list?.map((item) => {
    const obj = { value: item.EmpId, label: item.Emp_Name };
    return obj;
  });

  const newEmployeeList = EmployeeList?.map(option => {
    if (option?.value === disabledId) {
      return { ...option, isDisabled: true };
    }
    return option;
  });
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };

  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      checkboxSelection: true,
      // headerCheckboxSelection: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
      minWidth: 120,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Order No",
      field: "Order_No",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 180,
      pinned: "left",
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      pinned: "left",
    },
    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Order Sub Type",
      field: "order_sub_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 250,

    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 250,
    },
    {
      headerName: "Order Creation Date",
      field: "Order_Created_Date",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Created_Date === "" || params?.data?.Order_Created_Date === "NA" ? "" :
              moment(params?.data?.Order_Created_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 160,
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Expected_Complition_Date",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 190,
    },
    {
      headerName: "Project Name",
      field: "Project_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 320,
    },
    {
      headerName: "Project Status",
      field: "Project_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 190,
    },
    {
      headerName: "Project Creation Date",
      field: "Project_Created_Date ",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Project_Created_Date === "" || params?.data?.Project_Created_Date === "NA" ? "" :
              moment(params?.data?.Project_Created_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 150,
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 210,
    },
    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 150,
    },
    {
      headerName: "Support Assign Date",
      field: "Support_Assign_Date",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 160,
    },
    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 180,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "NA" ? (
              <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
            ) : params?.data?.Freeze_Approval}
          </>
        )
      }
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 150,
    },
    {
      headerName: "Consutant Name",
      field: "Consutant_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 250,
    },
    {
      headerName: "State",
      field: "State",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 180,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Revised NOF",
      field: "Revised_NOF_Exist",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 150,
      hide: true
    },
   
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);
  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);



  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    //   {
    //     id: 1,
    //     title: "Default",
    //     value: null,
    //     key: null,
    //   },
    //   {
    //     id: 2,
    //     title: "UnderProcess",
    //     value: "Underprocess",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 3,
    //     title: "Accounts Rejected",
    //     value: "Accounts Rejected",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 4,
    //     title: "Freeze Approval Pending",
    //     value: "Freeze Approval Pending",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 5,
    //     title: "Freezed",
    //     value: "Order Freezed",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 6,
    //     title: "Completion Approval Pending",
    //     value: "Completion Approval Pending",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 7,
    //     title: "Admin Approval Pending",
    //     value: "Admin Approval Pending",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 7,
    //     title: "Pending From Accounts",
    //     value: "Pending From Accounts",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 7,
    //     title: "Overdue",
    //     value: "Overdue",
    //     key: "Order_Status",
    //   },
  ];


  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleFilters = (title, value, key) => {

    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };

  const getTotalLength = (value, key) => {
    if (filterOrders?.order_list === null || (key === null && value === null)) {
      return;
    } else if (key === null) {
      return filterOrders?.order_list?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return filterOrders?.order_list?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      return filterOrders?.order_list?.filter((val) => val[key] === value).length;
    }
  };

  const handleAssignBulkOrdersApi = async (setModalLoading) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/OrderBulkReassignToTypist/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    let orderIdArray = [];
    rowSelected?.map((value) => {
      orderIdArray.push(value?.Order_No);
    })

    let body = {
      assign_from: assignFrom,
      assign_to: assignTo,
      order_id_array: orderIdArray,
      emp_id: Number(emp_id),
      user_type: user_type,
    }
    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      setModalLoading(false);
      setOpenModal(false);
      setFilterOrders([]);
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500)
      }
      else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code)
      }
      setRowSelected('');
        // setAssignFrom();
        // setAssignTo();
        setTypistDetails({});
        setTypistId(0);
        getBulkOrdersApi();
      setIsOpen(true);
      // handlePromise(data);
    })
  }

  const getBulkOrdersApi = async () => {
    gridRef?.current?.api?.showLoadingOverlay();
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/OrderBulkReassignToTypist/?typist_id=${getTypistId}&emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setRowSelected('');
      setFilterOrders(res?.data?.Data);
      gridRef?.current?.api?.hideOverlay();
    } catch (err) {
      console.error(err);
      gridRef?.current?.api?.hideOverlay();
    }

  }

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id")
  }
  const rowSelectionType = "multiple"
  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedRows();
    setRowSelected(rowData);
  };

  const handleAssignSubmit = (setModalLoading) => {
    handleAssignBulkOrdersApi(setModalLoading)
  }
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#eb6e12';
  box-shadow: inset '0px 0px 0px 7px #eb6e12';
  margin-top: 10%;
  `;
 
  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      <div className="listing-filters" style={{ padding: "1rem", background: "#fff", marginTop: "10px" }}>
        {/* {rowSelected == undefined || rowSelected == '' ? */}
        {/* <span style={{ color: "#eb6e12" }}>Please filter and select Order to Re-assign</span>
          : */}
           <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                // justifyContent: "center",
                width: "100%",
                // marginTop: "-35px",
              }}
            >

              <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
              <Form.Group as={Row} className="mb-1">
                        <Col sm="12" style={{ padding: '0 12px 0 0' }} >
                          <div className="fc-dropdown-container" >
                            <CustomSelect
                             placeholder="Select Typist Name To Filter Orders"
                              options={newEmployeeList}
                              value={assignFrom}
                              onChange={(value) => {
                                setDisabledId(value.value);
                                setTypistId(value.value);
                                setAssignFrom(value.value);
                                setTypistDetails({
                                  ...typistDetails,
                                  assignFrom: value.label
                                })
                                setFieldValue(`assign_from_name`, value.value);
                              }}
                            // isClearable={true}
                            />
                          </div>
                        </Col>
                      </Form.Group>
              </Grid>
              <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
              <Form.Group as={Row} className="mb-1">
                        <Col sm="12" style={{ padding: '0 12px 0 0' }} >
                          <div className="fc-dropdown-container" >
                            <CustomSelect
                            placeholder="Select Typist Name To Re-assign Orders"
                              options={newEmployeeList}
                              value={assignTo}
                              onChange={(value) => {
                                setDisabledId(value.value);
                                setAssignTo(value.value);
                                setTypistDetails({
                                  ...typistDetails,
                                  assignTo: value.label
                                })
                                setFieldValue(`assign_to_name`, value.value);
                              }}
                            // isClearable={true}
                            // isOptionDisabled={(option) =>
                            //   option.value.toString() == filterSupportId?.toString()
                            //   // option.value.toString() ==  getSupportId.toString()   
                            // }
                            />
                          </div>
                        </Col>
                      </Form.Group>
              </Grid>
              <Grid item sm={12} md={2} style={{ paddingRight: "0rem", }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#eb6e12",
                fontFamily: "Times New Roman !important",
                color: "#fff",
                width: "120px",
                height: '37px',
                marginTop: '20x',
                marginRight: "5px"
              }}
              onClick={() => {
                getBulkOrdersApi();
              }}
              disabled={
                assignFrom == undefined || assignFrom == '' ? true : false
              }
            >
              Search Orders
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#eb6e12",
                fontFamily: "Times New Roman !important",
                color: "#fff",
                width: "120px",
                height: '37px',
                marginTop: '20x'
              }}
              onClick={() => {
                setOpenModal(true);
              }}
              disabled={
                rowSelected == undefined || rowSelected == '' ? true 
                : assignFrom == undefined || assignFrom == '' ? true 
                : assignTo == undefined || assignTo == '' ? true 
                : false
              }
            >
              Assign
            </Button>
          </Grid>
            </form>
          )}
        </Formik>

       
      </div>
      {
        fitlerBadge ? (
          <>
            <div style={{ display: "flex", marginLeft: "10px", marginTop: "-15px", paddingBottom: "15px" }}>
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={filterOrders?.order_list}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                {filterOrders?.order_list?.length > 0 &&
                  filters.map((val, i) => {

                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: 'Times New Roman'
                        }}
                        onClick={() => handleFilters(val.title, val.value, val.key)}
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLength(val.Search_Name, valueKey)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(val.Search_Name, val.Search_Name, valueKey);
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>

          </>

        ) : null
      }
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={filterOrders?.order_list?.length}
      />
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={filterOrders?.order_list}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          //detailCellRenderer={renderExpandableRow}
          columnTypes={columnTypes}
          animateRows={true}
          onSelectionChanged={onRowSelected}
          rowSelection={rowSelectionType}
          // rowMultiSelectWithClick= {true}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}

        ></AgGridReact>
         
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} >
        <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <SubmitModal 
          msg={"Are you sure, want to re-assign?"} 
          typistDetails={typistDetails} 
          rowSelected={rowSelected} 
          setOpenModal={setOpenModal} 
          handleSubmit={handleAssignSubmit} 
          reCallApi={getBulkOrdersApi}
          setReloadComponent={setReloadComponent}
          setAssignFrom={setAssignFrom}
          setAssignTo={setAssignTo}
          />
        </>
      </Modal>
    </div>
  );
};

export default ReAssignOrderListTable;
