import React, { useContext, lazy, Suspense, useEffect, useState } from "react";
import { createGenerateClassName } from "@material-ui/core/styles";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Authentication from "./pages/authentication/login";
import PrivateRoute from "./PrivateRoute";
import { DataContext } from "./provider/context";
import "./app.css";
import CircularProgress from "@mui/material/CircularProgress";
import AdminListing from "./pages/Operations/Admin/AdminListing";
import ProjectDesignerListing from "./pages/Operations/ProjectDesigner/ProjectDesignerListing";
import PROHeadListing from "./pages/Operations/PROHead/PROHeadListing";
import DocumentControllerListing from "./pages/Operations/DocumentController/DocumentControllerListing";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const CompanyHistoryListing = lazy(() =>
  import("./pages/listing/CompanyHistoryListing")
);
const SalesPersonListing = lazy(() => import("./module/salesPerson"));
const SalesPersonAgentListing = lazy(() => import("./module/SalesPersonAgent"));

const ClientManagerListing = lazy(() => import("./module/clientManager"));
const ClientManagerTaxListing = lazy(() =>
  import("./module/clientManager-Tax")
);
const ClientManagerSHListing = lazy(() => import("./module/clientManager-SH"));
const ReassignListing = lazy(() =>
  import("./pages/reassign-listing/reassign-listing")
);
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const CPDashboard = lazy(() => import("./pages/CPDashboard/CPDashboard"));
const CPListing = lazy(() => import("./pages/CPListing/CPListing"));
const SupportListing = lazy(() =>
  import("./pages/Operations/Support/SupportListing")
);
const AccountListing = lazy(() =>
  import("./pages/Operations/Accounts/AccountListing")
);
const RealEstateListing = lazy(() =>
  import("./pages/RealEstateInventory/RealEstate/RealEstateListing")
);
const ComplianceListing = lazy(() =>
  import("./pages/Operations/Compliance/ComplianceListing")
);
const ClinetDataControler = lazy(() =>
  import("./pages/ClientData/ClinetDataControlerListing")
);
const ReceivableManagerListing = lazy(() =>
  import(
    "./pages/Operations/Accounts/ReceivableManager/ReceivableManagerListing"
  )
);
const TypistListing = lazy(() =>
  import("./pages/Operations/Typist/TypistListing")
);
// const SalesDashboard = lazy(() =>
//   import("./pages/Dashboard/sales_dashboard/SalesDashboard")
// );

const CommercialPartnerListing = lazy(() =>
  import("./module/CommercialPartner")
);
const CPCoordinatorListing = lazy(() =>
  import("./module/CPCoordinator")
);
const QualityAnalystListing = lazy(() =>
  import("./module/QualityAnalyst")
);
const PreQualifiedListing = lazy(() =>
import("./module/PreQualifiedLead/index")
);
const OperationIRTListing = lazy(() =>
  import("./pages/Operations/OperationIRT/OperationIRTListing")
);

const AIChatBot = lazy(() =>
  import("./pages/AIChatbot/AIChatBot/AIChatBot")
); 

const idb = window.indexedDB;

const createCollectionInIndexedDb = () => {
  if (!idb) {
    return console.log("This browser doesn't support IndexedDB");
  }

  const request = idb.open("inquiry-db", 2);

  request.onupgradeneeded = (event) => {
    const db = request.result;

    if (!db.objectStoreNames.contains("listing_api_res")) {
      db.createObjectStore("listing_api_res", {
        keyPath: "Serial_Number",
      });
    }

    request.onsuccess = () => {
      console.log("Databvase Opened Successfully!");
    };
  };
};

function App() {
  const { userTypeInfo, error } = useContext(DataContext);

  const { state } = useLocation();
  const __token = localStorage.getItem("token") || state?.token;
  const navigate = useNavigate();
  const [isCountryExist, setIsCountryExist] = useState(true);
  const [isActiveTab, setIsActiveTab] = useState(false);
  const BROADCAST_CHANNEL_NAME = 'tab-url';
  useEffect(() => {
    createCollectionInIndexedDb();
   // fetchUserCountry()
  }, []);

// useEffect(() => {
//   if ('BroadcastChannel' in window) {
//     console.log('Broadcast Channel API is supported.');
//   } else {
//     console.log('Broadcast Channel API is not supported.');
//   }
//     // Get the current URL
//     const currentUrl = window.location.href;

//     // Create a Broadcast Channel
//     const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

//     // Send the current URL to other tabs
//     broadcastChannel.postMessage({ url: currentUrl });

//     // Listen for messages from other tabs
//     broadcastChannel.onmessage = event => {
//       const { url } = event.data;
      
//       if (url === currentUrl) {
//         console.log('Same URL is open in multiple tabs.');
//         setIsActiveTab(true);
//       } else {
//         console.log('Different URLs are open in multiple tabs.');
//         setIsActiveTab(false);
//       }
//     };

//     // Close the broadcast channel when the component unmounts
//     return () => {
//       broadcastChannel.close();
//     };
// }, []);

// const handleReloadTab = () => {
//   window.location.reload();
//   const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
//   broadcastChannel.postMessage({ url: window.location.href });
// };

  useEffect(() => {
    if (__token && userTypeInfo === "CLIENT_MANAGER") {
      if (
        [
          "/app/bizsetup-reception-listing",
          "/app/bizsetup-sales-company-history",
          "/app/reception-dashboard",
          "/app/bizsetup-reception-listing",
          "/clientmanager-tax-listing",
          "/bizsetup-support-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-clientmanager-listing");
      }
    } else if (__token && userTypeInfo === "CLIENT_MANAGER_TAX") {
      if (
        [
          "/app/bizsetup-reception-listing",
          "/app/bizsetup-sales-company-history",
          "/app/reception-dashboard",
          "/app/bizsetup-reception-listing",
          "/bizsetup-clientmanager-listing",
          "/bizsetup-support-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/clientmanager-tax-listing");
      }
    } else if (__token && userTypeInfo === "CLIENT_MANAGER_SH") {
      if (
        [
          "/app/clientmanager-sh-listing",
          "/app/clientmanager-sh-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/clientmanager-sh-listing");
      }
    } else if (__token && userTypeInfo === "SALES_PERSON") {
      if (
        [
          "/app/bizsetup-reception-listing",
          "/app/reception-dashboard",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
          "/bizsetup-support-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-sales-listing");
      }
    } else if (__token && userTypeInfo === "SALES_PERSON_AGENT") {
      if (
        [
          "/bizsetup-sales-agent-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-sales-agent-listing");
      }
    } else if (__token && userTypeInfo === "RECEPTIONIST") {
      if (
        [
          "/app/bizsetup-sales-listing",
          "/app/bizsetup-sales-company-history",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
          "/bizsetup-support-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/reception-dashboard");
      }
    } else if (__token && userTypeInfo === "Support") {
      if (
        [
          "/app/bizsetup-sales-listing",
          "/app/bizsetup-sales-company-history",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-support-listing");
      }
    } else if (__token && userTypeInfo === "Accountant") {
      if (
        [
          "/app/bizsetup-accountants-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-accountants-listing");
      }
    } else if (__token && userTypeInfo === "REAL_ESTATE_INVENTORY") {
      if (
        [
          "/app/bizsetup-realestate-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-realestate-listing");
      }
    } else if (__token && userTypeInfo === "Admin") {
      if (
        [
          "/app/bizsetup-admin-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-admin-listing");
      }
    } else if (__token && userTypeInfo === "COMPLIANCE_OFFICER") {
      if (
        [
          "/app/bizsetup-compliance-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-compliance-listing");
      }
    } else if (__token && userTypeInfo === "CLIENT_DATA_CONTROLLER") {
      if (
        [
          "/app/bizsetup-clinet-data-controler-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-clinet-data-controler-listing");
      }
    } else if (__token && userTypeInfo === "Project_Designer") {
      if (
        [
          "/app/bizsetup-project-designer-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-project-designer-listing");
      }
    } else if (__token && userTypeInfo === "Receivable_Manager") {
      if (
        [
          "/app/bizsetup-receivable-manager-listing",
          "/app/bizsetup-receivable-manager-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-receivable-manager-listing");
      }
    } else if (__token && userTypeInfo === "Typist") {
      if (
        [
          "/app/bizsetup-typist-listing",
          "/app/bizsetup-clientmanager-listing",
          "/clientmanager-tax-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-typist-listing");
      }
    } else if (__token && userTypeInfo === "PRO_Head") {
      if (
        [
          "/app/bizsetup-prohead-listing",
          "/app/bizsetup-prohead-listing",
        ].includes(window.location.pathname)
      ) {
        return navigate("/bizsetup-prohead-listing");
      }
    } else if (__token && userTypeInfo === "DOCUMENT_CONTROLLER") {
      if (
        ["/app/bizsetup-document-controller-listing"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/bizsetup-document-controller-listing");
      }
    }
    else if (__token && userTypeInfo === "COMMERCIAL_PARTNER_TEAM_LEADER") {
      if (
        ["/app/bizsetup-commercial-partner-team-leader-listing"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/bizsetup-commercial-partner-team-leader-listing");
      }
    }
    else if (__token && userTypeInfo === "COMMERCIAL_PARTNER_COORDINATOR") {
      if (
        ["/app/bizsetup-commercial-partner-coordinator-listing"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/bizsetup-commercial-partner-coordinator-listing");
      }
    }
    else if (__token && userTypeInfo === "Quality_Analyst") {
      if (
        ["/app/bizsetup-quality-analyst-listing"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/bizsetup-quality-analyst-listing");
      }
    }
    else if (__token && userTypeInfo === "PrequalifyLeads") {
      if (
        ["/app/bizsetup-pre-qualify-listing"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/bizsetup-pre-qualify-listing");
      }
    }
    else if (__token && userTypeInfo === "Operation_IRT") {
      if (
        ["/app/bizsetup-operation-irt-listing"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/bizsetup-operation-irt-listing");
      }
    }
    else if (__token && userTypeInfo === "AIchatboat") {
      if (
        ["/AI-Chatbot"].includes(
          window.location.pathname
        )
      ) {
        return navigate("/AI-Chatbot");
      }
    }
  }, [__token, userTypeInfo, error]);
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  const loadingStyles = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    color: "#eb6e12",
    fontWeight: "700",
    fontSize: "18px",
  };

  createGenerateClassName({
    disableGlobal: true,
  });
  return (
    <div className="App"> 
       <Suspense
       fallback={
         <Box>
           <div style={loadingStyles}>
             <div>
               <CircularProgress
                 size={28}
                 sx={{
                   color: "#eb6e12",
                 }}
               />
             </div>
             <div> Loading...</div>
           </div>
         </Box>
       }
     >
       <Routes>
         <Route
           path="/bizsetup-reception-listing"
           element={
             <PrivateRoute user={__token}>
               <ReassignListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/reception-dashboard"
           element={
             <PrivateRoute user={__token}>
               <Dashboard />
             </PrivateRoute>
           }
         />

         <Route
           path="/bizsetup-sales-listing"
           element={
             <PrivateRoute user={__token}>
               <SalesPersonListing />
             </PrivateRoute>
           }
         />
          <Route
           path="/bizsetup-sales-agent-listing"
           element={
             <PrivateRoute user={__token}>
               <SalesPersonAgentListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-sales-company-history"
           // /
           element={
             <PrivateRoute user={__token}>
               <CompanyHistoryListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-clientmanager-listing"
           element={
             <PrivateRoute user={__token}>
               <ClientManagerListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/clientmanager-tax-listing"
           element={
             <PrivateRoute user={__token}>
               <ClientManagerTaxListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/clientmanager-sh-listing"
           element={
             <PrivateRoute user={__token}>
               <ClientManagerSHListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-support-listing"
           element={
             <PrivateRoute user={__token}>
               <SupportListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-accountants-listing"
           element={
             <PrivateRoute user={__token}>
               <AccountListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-realestate-listing"
           element={
             <PrivateRoute user={__token}>
               <RealEstateListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-admin-listing"
           element={
             <PrivateRoute user={__token}>
               <AdminListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-project-designer-listing"
           element={
             <PrivateRoute user={__token}>
               <ProjectDesignerListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-receivable-manager-listing"
           element={
             <PrivateRoute user={__token}>
               <ReceivableManagerListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-compliance-listing"
           element={
             <PrivateRoute user={__token}>
               <ComplianceListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-clinet-data-controler-listing"
           element={
             <PrivateRoute user={__token}>
               <ClinetDataControler />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-typist-listing"
           element={
             <PrivateRoute user={__token}>
               <TypistListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-prohead-listing"
           element={
             <PrivateRoute user={__token}>
               <PROHeadListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/bizsetup-document-controller-listing"
           element={
             <PrivateRoute user={__token}>
               <DocumentControllerListing />
             </PrivateRoute>
           }
         />
         <Route
            path="/bizsetup-commercial-partner-team-leader-listing"
            element={
              <PrivateRoute user={__token}>
                <CommercialPartnerListing />
              </PrivateRoute>
            }
          />
           <Route
            path="/bizsetup-commercial-partner-coordinator-listing"
            element={
              <PrivateRoute user={__token}>
                <CPCoordinatorListing />
              </PrivateRoute>
            }
          /> 
            <Route
            path="/bizsetup-quality-analyst-listing"
            element={
              <PrivateRoute user={__token}>
                <QualityAnalystListing />
              </PrivateRoute>
            }
          />
           <Route
            path="/bizsetup-pre-qualify-listing"
            element={
              <PrivateRoute user={__token}>
                <PreQualifiedListing />
              </PrivateRoute>
            }
          />
          
           <Route
           path="/bizsetup-operation-irt-listing"
           element={
             <PrivateRoute user={__token}>
               <OperationIRTListing />
             </PrivateRoute>
           }
         />
         <Route
           path="/AI-Chatbot"
           element={
             <PrivateRoute user={__token}>
               <AIChatBot />
             </PrivateRoute>
           }
         />
         <Route
           path="/cpdashboard"
           element={
             <PrivateRoute user={__token}>
               <CPDashboard />
             </PrivateRoute>
           }
         />
         <Route
           path="/cplisting"
           element={
             <PrivateRoute user={__token}>
               <CPListing />
             </PrivateRoute>
           }
         />
         <Route
           exact
           path="/login"
           element={
             __token && userTypeInfo === "RECEPTIONIST" ? (
               <Navigate to="/reception-dashboard" replace />
             ) : __token && userTypeInfo === "SALES_PERSON" ? (
               <Navigate to="/bizsetup-sales-listing" replace />
             ) : __token && userTypeInfo === "SALES_PERSON_AGENT" ? (
              <Navigate to="/bizsetup-sales-agent-listing" replace />
             ) : __token && userTypeInfo === "CLIENT_MANAGER" ? (
               <Navigate to="/bizsetup-clientmanager-listing" replace />
             ) : __token && userTypeInfo === "CLIENT_MANAGER_TAX" ? (
               <Navigate to="/clientmanager-tax-listing" replace />
             ) : __token && userTypeInfo === "CLIENT_MANAGER_SH" ? (
               <Navigate to="/clientmanager-sh-listing" replace />
             ) : __token && userTypeInfo === "Support" ? (
               <Navigate to="/bizsetup-support-listing" replace />
             ) : __token && userTypeInfo === "Accountant" ? (
               <Navigate to="/bizsetup-accountants-listing" replace />
             ) : __token && userTypeInfo === "REAL_ESTATE_INVENTORY" ? (
               <Navigate to="/bizsetup-realestate-listing" replace />
             ) : __token && userTypeInfo === "Admin" ? (
               <Navigate to="/bizsetup-admin-listing" replace />
             ) : __token && userTypeInfo === "COMPLIANCE_OFFICER" ? (
               <Navigate to="/bizsetup-compliance-listing" replace />
             ) : __token && userTypeInfo === "CLIENT DATA CONTROLLER" ? (
               <Navigate
                 to="/bizsetup-clinet-data-controler-listing"
                 replace
               />
             ) : __token && userTypeInfo === "Project_Designer" ? (
               <Navigate to="/bizsetup-project-designer-listing" replace />
             ) : __token && userTypeInfo === "Receivable_Manager" ? (
               <Navigate to="/bizsetup-receivable-manager-listing" replace />
             ) : __token && userTypeInfo === "Typist" ? (
               <Navigate to="/bizsetup-typist-listing" replace />
             ) : __token && userTypeInfo === "PRO_Head" ? (
               <Navigate to="/bizsetup-prohead-listing" replace />
             ) : __token && userTypeInfo === "DOCUMENT_CONTROLLER" ? (
               <Navigate to="/bizsetup-document-controller-listing" replace />
             ) : __token && userTypeInfo === "COMMERCIAL_PARTNER_TEAM_LEADER" ? (
              <Navigate to="/bizsetup-commercial-partner-team-leader-listing" replace />
             ) : __token && userTypeInfo === "Operation_IRT" ? (
              <Navigate to="/bizsetup-operation-irt-listing" replace />
             ) : __token && userTypeInfo === "COMMERCIAL_PARTNER_COORDINATOR" ? (
              <Navigate to="/bizsetup-commercial-partner-coordinator-listing" replace />
             ) : __token && userTypeInfo === "Quality_Analyst" ? (
              <Navigate to="/bizsetup-quality-analyst-listing" replace />
             ) : __token && userTypeInfo === "PrequalifyLeads" ? (
              <Navigate to="/bizsetup-pre-qualify-listing" replace />
             ) : __token && userTypeInfo === "AIchatboat" ? (
              <Navigate to="/AI-Chatbot" replace />
             )
             : (
               <Authentication />
             )
           }
         />
         <Route
           path="*"
           element={
             __token && userTypeInfo === "RECEPTIONIST" ? (
               <Navigate to="/reception-dashboard" replace />
             ) : __token && userTypeInfo === "SALES_PERSON" ? (
               <Navigate to="/bizsetup-sales-listing" replace />
             ) : __token && userTypeInfo === "SALES_PERSON_AGENT" ? (
              <Navigate to="/bizsetup-sales-agent-listing" replace />
             ) : __token && userTypeInfo === "CLIENT_MANAGER" ? (
               <Navigate to="/bizsetup-clientmanager-listing" replace />
             ) : __token && userTypeInfo === "CLIENT_MANAGER_TAX" ? (
               <Navigate to="/clientmanager-tax-listing" replace />
             ) : __token && userTypeInfo === "CLIENT_MANAGER_SH" ? (
               <Navigate to="/clientmanager-sh-listing" replace />
             ) : __token && userTypeInfo === "Support" ? (
               <Navigate to="/bizsetup-support-listing" replace />
             ) : __token && userTypeInfo === "Accountant" ? (
               <Navigate to="/bizsetup-accountants-listing" replace />
             ) : __token && userTypeInfo === "REAL_ESTATE_INVENTORY" ? (
               <Navigate to="/bizsetup-realestate-listing" replace />
             ) : __token && userTypeInfo === "Admin" ? (
               <Navigate to="/bizsetup-admin-listing" replace />
             ) : __token && userTypeInfo === "COMPLIANCE_OFFICER" ? (
               <Navigate to="/bizsetup-compliance-listing" replace />
             ) : __token && userTypeInfo === "CLIENT DATA CONTROLLER" ? (
               <Navigate to="/bizsetup-clinet-data-controler-listing" replace />
             ) : __token && userTypeInfo === "Project_Designer" ? (
               <Navigate to="/bizsetup-project-designer-listing" replace />
             ) : __token && userTypeInfo === "Receivable_Manager" ? (
               <Navigate to="/bizsetup-receivable-manager-listing" replace />
             ) : __token && userTypeInfo === "Typist" ? (
               <Navigate to="/bizsetup-typist-listing" replace />
             ) : __token && userTypeInfo === "PRO_Head" ? (
               <Navigate to="/bizsetup-prohead-listing" replace />
             ) : __token && userTypeInfo === "DOCUMENT_CONTROLLER" ? (
               <Navigate to="/bizsetup-document-controller-listing" replace />
             ) : __token && userTypeInfo === "COMMERCIAL_PARTNER_TEAM_LEADER" ? (
              <Navigate to="/bizsetup-commercial-partner-team-leader-listing" replace />
             ) : __token && userTypeInfo === "Operation_IRT" ? (
              <Navigate to="/bizsetup-operation-irt-listing" replace />
             ) : __token && userTypeInfo === "COMMERCIAL_PARTNER_COORDINATOR" ? (
              <Navigate to="/bizsetup-commercial-partner-coordinator-listing" replace />
             ) : __token && userTypeInfo === "Quality_Analyst" ? (
              <Navigate to="/bizsetup-quality-analyst-listing" replace />
             ) : __token && userTypeInfo === "PrequalifyLeads" ? (
              <Navigate to="/bizsetup-pre-qualify-listing" replace />
             ) : __token && userTypeInfo === "AIchatboat" ? (
              <Navigate to="/AI-Chatbot" replace />
             )
              : (
               <NotFoundPage />
             )
           }
         />
       </Routes>
     </Suspense>
    
     {/* <Modal
      show={isActiveTab}
      backdrop="static"
      keyboard={false}
      backdropClassName="modal-backdrop-window"
      centered
    >
      <Modal.Body>
      Website is already open in another window. Click "Use Here" to use here.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary"  onClick={handleReloadTab}> Use here</Button>
      </Modal.Footer>
    </Modal> */}
    </div>
  );
}

export default App;
