import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";


import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../../Admin/Modals/ModelPopper";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from "@mui/icons-material/History";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { Grid } from "@material-ui/core";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader'
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";




const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProRequisitionListTable = ({
  apiData,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop, fitlerBadge, classes
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    loading,
    setSelected,
    setIsOpen, isOpen,
    message,
    handleModalOpen,
    getProRequestionListApi, proRequestionList,

  } = useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  //const [isOpen, setIsOpen] = useState(false);
  //const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const todayDate = moment().format("YYYY-MM-DD");
  const [reqFromDate, setReqFromDate] = useState("");
  const [reqToDate, setReqToDate] = useState("");
  const [myRows, setRow] = useState("");


  
  useEffect(() => {
    getProRequestionListApi(reqFromDate, reqToDate);
  }, [reqToDate])
  useEffect(() => {
    handleListingSavedSearch();
  }, [])

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const dateFilterParams = {
    inRangeInclusive: true,
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        if (dateAsString == null) {
          return 0;
        }
        const dateParts = dateAsString.slice(0, 10).split("-");
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <div>
         
          <CustomIconButton
            title="Requestion Log"
            disabled={
              params.data?.Status === "converted" ? true : false
            }
            icon={
              <HistoryIcon
                onClick={() => {
                  handleModalTableOpen("Requestion Log", params.data);
                  setButtonText("Requestion Log");
                  // scrollToTop()
                  ScrollTop();
                  setOperationInfo(params.data);
                }}
              />
            }
          /> 
         <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
            }}
            title={"View Company Document"}
            width={"auto"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          />
        </div>
      </>
    );
  };

   const columnDefs = [
    {
      headerName: "S #",
      field: "Serial_Number",
      cellRenderer: "agGroupCellRenderer",
      //checkboxSelection: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
      width: 110,
      pinned: "left",
      filter: false
    },
   
      {
        headerName: "Actions",
        field: "Inquiry_Id",
        filter: false,
        // checkboxSelection: true,
        cellRenderer: actionBtnRenderer,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center"},
        minWidth: 130,
        pinned: "left",
       
      },
    {
      headerName: "Req Id",
      field: "requisition_no",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Requisition Date",
      field: "requisition_date_time",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
            {moment(params.data?.requisition_date_time).format("DD-MM-YYYY HH:mm:ss a")}
          </>
        );
      },
    },
    {
      headerName: "Work Type",
      field: "work_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Order No",
      field: "order_id",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      // pinned: "left",
    },
    {
      headerName: "Order Name",
      field: "order_name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 270,
      // pinned: "left",
    },
    {
      headerName: "Company Name",
      field: "company_name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Company Email",
      field: "company_email",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },

    {
      headerName: "Typist Name",
      field: "Typist_name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 180,
    },
    {
      headerName: "Typist Status",
      field: "Typist_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
    },
    {
      headerName: "Main Task Name",
      field: "main_task_name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Task Name",
      field: "task_name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Req. Amount",
      field: "req_amount",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "By Pass Accounts",
      field: "byPassAccount",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
    },
    {
      headerName: "Self Assigned",
      field: "is_self_assign_by_ro",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 160,
    },
    {
      headerName: "PRO Name",
      field: "Pro_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
    },
    {
      headerName: "Appoint Date & Time ",
      field: "Appointment_Start_Date",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
      cellRenderer: (params) => {
        return (
          <>
            {moment(params.data?.order_created_date).format("DD-MM-YYYY")}
          </>
        );
      },
    },
    {
      headerName: "Location",
      field: "req_location",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 250,
    },
    
    {
      headerName: "Account Status",
      field: "Account_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "PRO Head Status",
      field: "pro_head_status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "PRO Status",
      field: "PRO_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
    },
    {
      headerName: "Request By",
      field: "Requested_By",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "PI Remarks",
      field: "pi_remarks",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150
    },
    {
      headerName: "Client Details",
      field: "Client_Detail",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
      width: 250,
      cellRenderer: (params) => {
        const myEmail = `mailto:${params?.data?.client_detail?.[0]?.email_id}`;
         const myWhatsApp = `https://api.whatsapp.com/send?phone=${params?.data?.client_detail?.[0]?.contact_no}`;
        return (
          <>
            <p style={{ margin: 0 }}><span>Name:</span>{params?.data?.client_detail?.[0]?.client_name}</p>
            <p style={{ margin: 0 }}><span>Email:</span><div>
            <button
            style={{
              backgroundColor: "#E5E4E2",
              border: "none",
              color: " #0000FF",
              textDecoration: "none",
              width: "100%",
              fontSize: "14px"
            }}
            onClick={() => window.open(myEmail, "_blank")}
          >
          {params?.data?.client_detail?.[0]?.email_id}
          </button>
          </div>
            </p>
            <p style={{ margin: 0 }}><span>Mobile:</span><div>
            <button
            style={{
              backgroundColor: "#E5E4E2",
              border: "none",
              color: " #0000FF",
              textDecoration: "none",
              width: "100%",
              fontSize: "14px"
            }}
            onClick={() => window.open(myWhatsApp, "_blank")}
          >
          {params?.data?.client_detail?.[0]?.contact_no}
          </button>
          </div>
            
            </p>
          </>
        )
      }
    },
    //   {
    //     headerName: "No of overdue Days",
    //     field: "totOverDueDays",
    //     cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
    //     width: 150,
    //   },
    {
      headerName: "Requisition Remarks",
      field: "remarks",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 300,
    },
    {
      headerName: "Typist Done Remarks",
      field: "req_typist_done_comment",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 300,
    },
  ];
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Group",
      field: "company_name",
      minWidth: 250,
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey)
  }

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#eb6e12';
  box-shadow: inset '0px 0px 0px 7px #eb6e12';
  margin-top: 10%;
  `;

   
  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "600", fontSize: 12 }}
              >
                Requisition Date:
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <DateRangePickerComp
                    custom={true}
                    fieldHandleChange={(value, picker) => {
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      //    getProRequestionListApi(reqFromDate, reqToDate);
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>
        </Grid>
      </div>
      <span style={{color: "red", marginLeft: "10px"}}>In Default, all requisitions which are not completed are showing here.</span>

      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={proRequestionList?.length}
      />
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
 {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={proRequestionList}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          //onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
          )}
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProRequisitionListTable;