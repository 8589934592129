import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../listing/listingForm/ListingForm";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";
import CustomUploadFile from "../../../../../reusableComponents/CustomUploadFile/CustomUploadFile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const PROInternalRequisitionForm = ({ classes, setOpenModal, isEdit }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    followUp,
    setMessage,
    setSeverity,
    operationInfo,
    fetchPROInternalRequistionListData,
    setIsOpen,
  } = useContext(SupportDataContext);
  const {
    start_Date, end_Date
    
  } = useContext(DataContext);

  const [proInternalDropdown, setPROInternalDropdown] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [proName, setProName] = useState(isEdit ? operationInfo?.pro_name : "");

  const [editCompanyVal, setEditCompanyVal] = useState([]);
  const getPROInternalDropdownDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    try {
      let url = `https://flyingcolour.online/fcapp/pro_head/PROInternalRequisitionForm/`;

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
    
      setPROInternalDropdown(res?.data?.Data);
      if(isEdit){
        handleCompanyValue(operationInfo?.company_id, res?.data?.Data?.Company_List)
      }
      // setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const proNameListDropDown = proInternalDropdown?.PRO_List?.map((i) => {
    return {
      id: i.EmpId,
      value: i.EmpId,
      label: `${i.Emp_Name}`,
    };
  });

  const requisitionTypeDropdown = proInternalDropdown?.Requisition_Type?.map(
    (item) => {
      const obj = { value: item, label: item };
      return obj;
    }
  );

  const companyListDropdown = proInternalDropdown?.Company_List?.map((item) => {
    const obj = { value: item.Company_Id, label: item.Company_Name };
    return obj;
  });

  useEffect(() => {
    getPROInternalDropdownDataApi();
  }, []);

  const proInternalRequisitionPOSTAPI = async (values) => {
    setLoading(true);
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/pro_head/PROInternalRequisitionForm/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    const companyIds = values?.companyName?.map((val) => val.value);

    const formData = new FormData();
    formData.append("emp_id", emp_id);
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);
    formData.append("requisition_type", values?.requisitionType);
    formData.append("task_description", values?.taskDescription);
    formData.append("pro_name_id", values?.proId);
    formData.append("amount", values?.amount);
    formData.append("company_id", JSON.stringify(companyIds));
    formData.append("upload_doc", values?.uploadDoc)

    if (isEdit) {
      formData.append("requisition_id", operationInfo?.requisition_id);
      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    } else {
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    }
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    fetchPROInternalRequistionListData(null, start_Date, end_Date);
    setOpenModal(false);
  };

  const handleCompanyValue = (data, list) => {
    setInitialLoad(true)
    const finalVale = [];
     data?.forEach((item) => {
      const isExist = list?.find((val) => Number(val.Company_Id) === Number(item));
      if(isExist) finalVale?.push({
        value: isExist.Company_Id, label: isExist.Company_Name 
      });
    })
    
    setEditCompanyVal(finalVale)
    setInitialLoad(false);
    
  }

  return (
    <Formik
      enableReinitialize={initialLoad}
      initialValues={{
        proName: isEdit ? operationInfo?.pro_name  :"",
        proId:  isEdit ? operationInfo?.pro_id.toString() : "",
        taskDescription:  isEdit ? operationInfo?.task_description : "",
        requisitionType:  isEdit ? operationInfo?.requisition_type : "",
        companyName:  isEdit ?  editCompanyVal : [],
        amount:  isEdit ? operationInfo?.req_amount : 0,
        uploadDoc: isEdit ? operationInfo?.uploaded_docs : ''
      }}
      validate={(values) => {
        let errors = {};
        console.log("values", values);
        if (!values.requisitionType) {
          errors.requisitionType = "required";
        }

        if (values.companyName?.length <= 0) {
          errors.companyName = "required";
        }

        if (!values.proId) {
          errors.proId = "required";
        }

        if (!values.taskDescription) {
          errors.taskDescription = "required";
        }

        return errors;
      }}
      onSubmit={(data) => {
        proInternalRequisitionPOSTAPI(data);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "450px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                         {isEdit ? "Edit " : "Add "} PRO Internal Requisition
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                            <CustomDropdown
                              label="Requisition Type"
                              fieldOptions={requisitionTypeDropdown}
                              fieldValue={values?.requisitionType}
                              fieldHandleChange={(value) => {
                                setFieldValue(`requisitionType`, value.value);
                              }}
                              requiredField={true}
                              errorField={getStyles(
                                errors,
                                values?.requisitionType
                              )}
                            />

                            <CustomMultiSelectDropdown
                              label="Company Name"
                              fieldOptions={companyListDropdown}
                              fieldValue={values?.companyName}
                              fieldHandleChange={(value) => {
                                setFieldValue(`companyName`, value);
                              }}
                              requiredField={true}
                              errorField={getStyles(
                                errors,
                                values?.companyName
                              )}
                            />

<InputText
                                label="Amount"
                                fieldName="amount"
                                fieldType={"number"}
                                fieldValue={values?.amount}
                                fieldHandleChange={(event) => {
                                  if(event.target.value >= 0){
                                    setFieldValue("amount", event.target.value)
                                  }
                                }
                                 
                                }
                              />

                            <CustomDropdown
                              label="PRO Name"
                              fieldOptions={proNameListDropDown}
                              fieldValue={values?.proId}
                              fieldHandleChange={(value) => {
                                setFieldValue(`proId`, value.value);
                                setProName(value.label)
                              }}
                              requiredField={true}
                              errorField={getStyles(errors, values?.proId)}
                            />

                            <CustomTextArea
                              label="Task Description"
                              fieldName="taskDescription"
                              fieldValue={values?.taskDescription}
                              fieldHandleChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue("taskDescription", value);
                              }}
                              requiredField={true}
                              errorField={getStyles(
                                errors,
                                values?.taskDescription
                              )}
                            />

<CustomUploadFile
                                                                      label="Upload Documents"
                                                                      uploadDoc={
                                                                        values?.uploadDoc
                                                                      }
                                                                      uploadType="single"
                                                                      fieldHandleChange={(
                                                                        e
                                                                      ) => {
                                                                        setFieldValue(
                                                                          `uploadDoc`,
                                                                              e.currentTarget
                                                                              .files?.[0],
                                                                          
                                                                        );
                                                                      }}
                                                                      fieldDeleteChange={(
                                                                        index
                                                                      ) => {
                                                                        
                                                                        setFieldValue(
                                                                          `uploadDoc`,
                                                                          ""
                                                                        );
                                                                      }}
                                                                    />
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(PROInternalRequisitionForm);
