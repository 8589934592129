import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../listing/listingForm/ListingForm";
import InputText from "../../../../../reusableComponents/InputText/InputText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const GeneralTaskNameForm = ({ classes, setOpenModal, isEdit }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    followUp,
    setMessage,
    setSeverity,
    operationInfo,
    getGeneralTaskManagerDataDataApi,
    setIsOpen,
    generalTaskManagerData,
  } = useContext(SupportDataContext);
  const [isNameExist, setIsNameExist] = useState(false);

  const LocationList = generalTaskManagerData?.Active_Inactive?.map((item) => {
    const obj = { value: `${item.Id}`, label: item.Name };
    return obj;
  });

  const addGeneralTaskNamePOSTPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/pro_head/GeneralTaskManager/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      task_name: values?.generalTaskName,
    };

    if (isEdit) {
      body = {
        ...body,
        general_task_id: operationInfo?.task_id,
        active_status: values?.status
      };
      axios.put(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    } else {
      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    }
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getGeneralTaskManagerDataDataApi();
    setOpenModal(false);
  };

  const checkDuplicateTaskName = async (values, setFieldValue) => {
    const __token = localStorage.getItem("token");
    // setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/pro_head/CheckDuplicateGeneralTask/?task_name=${values?.generalTaskName}`;

      if(isEdit) {
        url = url + `&general_task_id=${operationInfo?.task_id}`
      }

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      if (res?.data?.message === "Duplicate Task name.") {
        setFieldValue("generalTaskName", "");
        setIsNameExist(true);
      } else {
        setIsNameExist(false);
        addGeneralTaskNamePOSTPUTAPI(values)
        return;
      }
      // setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        generalTaskName: isEdit ? operationInfo?.task_name : "",
        status: isEdit ? String(operationInfo?.active_status) : "",
      }}
      validate={(values) => {
        let errors = {};

        if (!values.generalTaskName) {
          errors.generalTaskName = "required";
        }

        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "400px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          Add General Task Name
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                            <InputText
                              label="General Task Name"
                              fieldName="generalTaskName"
                              fieldValue={values?.generalTaskName}
                              errorField={getStyles(
                                errors,
                                values?.generalTaskName
                              )}
                              requiredField={true}
                              fieldHandleChange={(e) => {
                                setFieldValue(
                                  "generalTaskName",
                                  e.target.value
                                );
                              }}
                              
                            />
                            {isNameExist && (
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label
                                  column
                                  sm="5"
                                  className="fc-textarea-label"
                                ></Form.Label>
                                <Col
                                  sm="7"
                                  style={{
                                    padding: "0 12px 0 0",
                                    display: "flex",
                                  }}
                                >
                                  <div style={{ color: "red", fontSize: 12 }}>
                                    General Task name already exists!
                                  </div>
                                </Col>
                              </Form.Group>
                            )}

                            {isEdit && (
                              <CustomDropdown
                                label="Status"
                                fieldOptions={LocationList}
                                fieldValue={values?.status}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`status`, value.value);
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                      onClick={() => {
                        checkDuplicateTaskName(
                            values,
                            setFieldValue
                          )
                      }}
                      disabled={values?.generalTaskName?.length > 0 ? false : true}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(GeneralTaskNameForm);
