import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import AddScopeOfWorkForm from "../TemplateCreation/AddScopeOfWorkForm";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import isEqual from "lodash/isEqual";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "350px",
  maxHeight: "350px",

  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const UpdateNOFSOW = ({ classes }) => {
  const {
    setButtonText,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getNOFDataListingApi,
    getTemplateCreationListgDataApi,
    templateCreationList,
    templateList,
  } = useContext(SupportDataContext);

  const [sowList, setSowList] = useState([]);
  const [initialSOWListData, setInitialSOWList] = useState([])
  const [sowSubTemplateList, setSowSubTemplateList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectetOrderTemplate, setSelectedOrderTemplate] = useState([]);
  const [initialSelectedTemplate, setInitialSelectedTemplate] = useState([]);
  const [selectetOrderSubTemplate, setSelectedOrderSubTemplate] = useState([]);
  const [isDefaultValueChecked, setIsDefaultValueChecked] = useState(false);
  const [
    isDefaultValueCheckedSubTemplate,
    setIsDefaultValueCheckedSubTemplate,
  ] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(true);

  const taskSectionDropdown = templateCreationList?.Single_Multi_Work_Type?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const taskTypeDropdown = templateCreationList?.Task_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Task_Type_Name, label: item.Task_Type_Name };
      return obj;
    }
  );

  const taskCategoryDropdown = templateCreationList?.Task_Category_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const workTypeDropdown = templateCreationList?.Work_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const govtDeptDropdown = templateCreationList?.Govt_Dept_List?.map(
    (item, i) => {
      const obj = { value: item.Govt_Dept_Name, label: item.Govt_Dept_Name };
      return obj;
    }
  );

  const hasDuplicate = (array, property) => {
    const seen = new Set();
    for (const item of array) {
      if (seen.has(item[property])) {
        return true; // Found a duplicate
      }
      seen.add(item[property]);
    }
    return false; // No duplicates found
  }

  const hasSequanceDuplicate = (array, property) => {
    const seen = new Set();
    for (const item of array) {
      if (seen.has(Number(item[property]))) {
        return true; // Found a duplicate
      }
      seen.add(Number(item[property]));
    }
    return false; // No duplicates found
  }

  const updateNOSOWPUTApi = async (values) => {
    setOpenLoading(true);
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/updatesowonorder/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    let updateSOw = sowList;

    let selectedSubTemplate = values?.selectedSubTemplate?.map((val) => {
      return val.label;
    });

    let selectedTemplate = values?.selectedTemplate?.map((val) => {
      return val.label;
    });
    let updatedSelectedScopeOfWork = [];
    values?.selectedScopeOfWork?.forEach((val) => {
      const { checked, ...rest } = val;
      if (checked !== false) updatedSelectedScopeOfWork?.push(rest);
    })
    if (updatedSelectedScopeOfWork?.length < 1 && operationInfo?.Accounts_Status === "accepted") {
      setMessage(`Selected Scope of work cannot be empty`);
      setSeverity(500);
      setIsOpen(true);
      setLoading(false);
      setOpenLoading(false);
      return;
    }
    if (operationInfo?.Accounts_Status === "accepted") {
      updateSOw = [...updateSOw, ...updatedSelectedScopeOfWork];
      selectedTemplate = [...selectedTemplate, ...values?.selectedTemplatesAccepted,]
    }
    const hasDuplicates = hasDuplicate(updateSOw, "Service_Task_Id");
    const hasDuplicateSequance = hasSequanceDuplicate(updateSOw, "Sequance");
    console.log("Chekcing the duplicates", hasDuplicateSequance, updateSOw);
    if (hasDuplicateSequance) {
      setMessage(`Sequance cannot be same for multiple SOW`);
      setSeverity(500);
      setIsOpen(true);
      setLoading(false);
      setOpenLoading(false);
      return;
    }
    let initialSOWLIst = orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work


    if (hasDuplicates) {
      setMessage(`Duplicate Scope of work found!. Please try again later!`);
      setSeverity(500);
      setIsOpen(true);
      setLoading(false);
      setOpenLoading(false);
      setButtonText("NOF Order Form Table");
      return;
    }

    if (!isEqual(updateSOw, initialSOWLIst)) {
      let todayDate = moment().format("YYYY-MM-DD");
      updateSOw?.forEach((ele, i) => {
        let findValue = initialSOWLIst?.find((val) => Number(val?.Service_Task_Id) === Number(ele?.Service_Task_Id));
        if (!isEqual(findValue, ele)) {
          updateSOw[i] = {
            ...ele,
            last_update_by_sow: emp_id,
            last_update_date_sow: todayDate
          }
        }
      });
    }


    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      update_id: orderFormCompanyDropdown?.Order_Detail?.[0]?.Order_Id,
      scope_of_work: JSON.stringify(updateSOw),
      scope_of_work_for_sub_template: JSON.stringify(sowSubTemplateList),
      order_template: JSON.stringify(selectedTemplate),
      order_sub_template: JSON.stringify(selectedSubTemplate),
    };

    axios.put(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500);
      } else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code);
      }
      setIsOpen(true);
      // handlePromise(data);
      setOpenLoading(false);
      getNOFDataListingApi();
      setButtonText("NOF Order Form Table");
    });
  };

  const [orderFormCompanyDropdown, setOrderFormCompanyDropdown] = useState([]);
  const getOrderFormonCompanyData = async (orderId) => {
    setOpenLoading(true);
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/?company_inquiry_flag=${operationInfo?.inquiry_id ? 1 : 2
        }`;
      if (orderId) {
        url = `${url}&order_id=${operationInfo?.Order_Id}`;
      }

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderFormCompanyDropdown(res?.data?.Data);
      setInitialLoad(false);
      setOpenLoading(false);
      if (editModalPopper && initialLoad) {
        selectedTemplateValue(res?.data?.Data);
        selectedSubtemplateValue(res?.data?.Data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const tagNames =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Tag_Name_List !== undefined
      ? orderFormCompanyDropdown?.Tag_Name_List.map((i) => ({
        id: i.Tag_Id,
        value: i.Tag_Id,
        label: `${i.Tag_Name}`,
      }))
      : [];

  const templateListDropdown =
    templateList?.Service_Template_List?.length !== 0 &&
      templateList?.Service_Template_List !== undefined
      ? templateList?.Service_Template_List?.map((i) => ({
        id: i.Service_Id,
        value: `${i.Service_Id}`,
        label: `${i.Service_Name}`,
        details: i?.Service_Details,
        taskList: [...i?.Task_List]?.sort(
          (a, b) => a.Task_Sequence - b.Task_Sequence
        ),
      }))
      : [];

  const templateSubOrderListDropdown =
    templateList?.Service_Sub_Template_List?.length !== 0 &&
      templateList?.Service_Sub_Template_List !== undefined
      ? templateList?.Service_Sub_Template_List?.map((i) => ({
        id: i.Service_Id,
        value: `${i.Service_Id}`,
        label: `${i.Service_Name}`,
        details: i?.Service_Details,
        taskList: i?.Task_List,
      }))
      : [];

  useEffect(() => {
    getTemplateCreationListgDataApi();
  }, []);

  useEffect(() => {
    if (editModalPopper) {
      setIsDefaultValueChecked(true);
      setIsDefaultValueCheckedSubTemplate(true);
      getOrderFormonCompanyData(true);
    }
  }, []);


  const selectedTemplateValue = (data) => {
    setInitialLoad(true);
    let templateList = [];
    let finalTaskList = [];
    data?.Order_Detail?.[0]?.scope_of_work?.forEach((item) => {
      let findValue = templateListDropdown?.find(
        (val) => Number(val.id) === Number(item.Template_Id)
      );
      let findExistValue = templateList.find(
        (val) => Number(val.id) === Number(item.Template_Id)
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Service_Task_Name: item?.Name,
          Task_Type_Name: item?.Task_Type,
          Task_Category: item?.Task_Category,
          Nos_Task_Days: item?.Days,
          Task_Sequence: Number(item?.Sequance),
          Task_Amount: Number(item?.Amount),
          Govt_Dept_Name: item?.Govt_Department,
          Special_Instruction: item?.Special_Instruction,
          Single_Multi_Task_Type: item?.Task_Section,
          Task_Details: item?.Task_Detail,
          Work_Type: item?.Work_Type,
          Tag_Id: item?.Tag_Id,
          // Tag_Name: item?.Tag_Name,
          Template_Id: item?.Template_Id,
          Template_Name: item?.Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
          Requisition_Created: item?.Requisition_Created,
          Requisition_No: item?.Requisition_No,
        };

        finalTaskList.push(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          templateList.push(findValue);
        }
      }
    });

    // let filteredValue = removeDuplicateObjects(templateList, "Service_Task_Id");
    if (operationInfo?.Accounts_Status !== "accepted") {
      setSowList(data?.Order_Detail?.[0]?.scope_of_work);
    }
    setInitialSOWList([...data?.Order_Detail?.[0]?.scope_of_work])
    setSelectedOrderTemplate(templateList);
    setInitialSelectedTemplate(templateList);
    setInitialLoad(false);
  };

  const selectedSubtemplateValue = (data) => {
    setInitialLoad(true);
    let selecSubTemplates = [];
    let finalTaskList = [];
    data?.Order_Detail?.[0]?.scope_of_work_for_sub_template?.forEach((item) => {
      let findValue = templateSubOrderListDropdown?.find(
        (val) => val.label === item.Sub_Template_Name
      );
      let findExistValue = selecSubTemplates.find(
        (val) => val.label === item.Sub_Template_Name
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Task_Details: item?.Description,
          Sub_Template_Id: item?.Sub_Template_Id,
          Sub_Template_Name: item?.Sub_Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
        };
        finalTaskList.push(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          selecSubTemplates.push(findValue);
        }
      }
    });

    //let filteredValue = removeDuplicateObjects(selecSubTemplates, "Service_Task_Id");

    setSowSubTemplateList(
      data?.Order_Detail?.[0]?.scope_of_work_for_sub_template
    );
    setSelectedOrderSubTemplate(selecSubTemplates);
    setInitialLoad(false);
  };

  const handleOrderTemplateChecked = (scopeOfWork, val) => {
    let findValue = scopeOfWork?.find(
      (value) => value.Service_Task_Id === val.Service_Task_Id
    );
    if (findValue) {
      return true;
    } else {
      return false;
    }
  };

  const isCheckboxDisabled = (Service_Task_Id) => {
    if (operationInfo?.Accounts_Status === "accepted") {
      let isValueFind;
      orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work?.forEach((template) => {
        let findValue = orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work?.find(
          (val) => Number(val.Service_Task_Id) === Service_Task_Id
        );
        if (findValue) {
          return (isValueFind = true);
        } else {
          return (isValueFind = false);
        }
      });
      return isValueFind;
    } else {
      return false;
    }
  };

  const isSequenceExist = (val, array, Service_Task_Id) => {
    let value = array.some(obj => {
      if (!(obj.Service_Task_Id === Service_Task_Id)) {
        return Number(obj.Sequance) === Number(val)
      }
    });
    return value;
  }

  const handleBtnDisable = (data) => {
    let initialDa = [...initialSOWListData]

    if (isEqual(initialDa, data)) {
      setIsShowBtn(true)
    } else {
      setIsShowBtn(false)
    }
  }
  const handleKeyDown = (e) => {
    // Prevent "-" and "e" keypresses
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={initialLoad}
        initialValues={{
          selectedOrderTemplates: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.order_template
            : [],
          selectedTemplate:
            editModalPopper && operationInfo?.Accounts_Status === "accepted"
              ? []
              : editModalPopper
                ? selectetOrderTemplate
                : "",
          selectedTemplateId:
            editModalPopper && operationInfo?.Accounts_Status === "accepted"
              ? []
              : editModalPopper
                ? selectetOrderTemplate
                : "",
          selectedSubTemplate:
            editModalPopper && operationInfo?.Accounts_Status === "accepted"
              ? []
              : editModalPopper
                ? selectetOrderSubTemplate
                : "",
          selectedSubTemplateId:
            editModalPopper && operationInfo?.Accounts_Status === "accepted"
              ? []
              : editModalPopper
                ? selectetOrderSubTemplate
                : "",
          selectedTemplatesAccepted: editModalPopper && operationInfo?.Accounts_Status === "accepted"
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.order_template
            : editModalPopper
              ? []
              : "",
          selectedScopeOfWork: editModalPopper && operationInfo?.Accounts_Status === "accepted"
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work
            : editModalPopper
              ? []
              : "",
          scopeOfWork: editModalPopper && operationInfo?.Accounts_Status === "accepted"
            ? [] : editModalPopper
              ? orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work
              : [],
          scopeOfWorkSubTemplate: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]
              ?.scope_of_work_for_sub_template
            : [],
        }}
        onSubmit={async (data) => {
          setLoading(true);
          updateNOSOWPUTApi(data);
        }}
        validate={(values) => {
          let errors = {};
          console.log("selectedScopeOfWork", values?.selectedScopeOfWork)

          console.log("scopeOfWork", values?.scopeOfWork)
          console.log("selectedTemplate", values?.selectedTemplate)
          console.log("scopeOfWorkSubTemplate", values?.scopeOfWorkSubTemplate)


          if (operationInfo?.Accounts_Status !== "accepted" && values?.selectedTemplate?.length < 1) {
            errors.selectedTemplateId = "Required";
          }
          if (operationInfo?.Accounts_Status !== "accepted" && values?.scopeOfWork?.length < 1) {
            errors.scopeOfWork = "Required";
          }


          operationInfo?.Accounts_Status !== "accepted" && values?.selectedTemplate?.forEach((val) => {
            val?.taskList?.forEach((item) => {
              if (item?.Nos_Task_Days < 1) {
                errors.Nos_Task_Days = "Required";
              }
            });
          });
          // values?.scopeOfWork?.forEach((val) => {
          //   if(val?.Days < 1) {
          //     errors.Days = "Required";
          //   }
          // })

          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >

              <div>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#eb6e12" }}
                  onClick={handleSubmit}
                  disabled={isShowBtn}
                >
                  {"Submit"}
                </Button>
                <Button
                  onClick={() => {
                    setButtonText("NOF Order Form Table");
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <br />
              <div
                style={{
                  height: "450px",
                  overflowX: "hidden",
                  padding: "0",
                }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={12}>
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid container>
                        {operationInfo?.Accounts_Status === "accepted" && (
                          <Grid xs={12} md={4}>
                            <InputText
                              label="Selected Order Templates"
                              fieldName="Selected Order Templates"
                              fieldValue={values?.selectedOrderTemplates?.toString()}
                              disableField={true}
                              requiredField={true}
                            />
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={classes.quotationInnerGridRight}
                        >
                          <CustomMultiSelectDropdown
                            label="Select Order Template"
                            fieldOptions={templateListDropdown}
                            fieldValue={values?.selectedTemplateId}
                            fieldHandleChange={(value) => {
                              if (
                                operationInfo?.Accounts_Status === "accepted" &&
                                editModalPopper
                              ) {
                                setIsDefaultValueChecked(false);
                                setFieldValue("selectedTemplate", value);
                                setFieldValue("selectedTemplateId", value);
                                setSelectedOrderTemplate(value);
                              } else if (editModalPopper) {
                                if (value.length === 0) {

                                  setSowList([]);
                                  setFieldValue("scopeOfWork", []);
                                  setFieldValue("selectedTemplate", value);
                                  setFieldValue("selectedTemplateId", value);
                                  handleBtnDisable([]);
                                } else {
                                  let currVal = [];
                                  value?.map((taskArray) => {
                                    let findArray = templateListDropdown?.find(
                                      (val) => taskArray.label !== val.label
                                    );
                                    let findValue =
                                      templateList?.Service_Template_List?.find(
                                        (val) =>
                                          taskArray.label !== val.Service_Name
                                      );
                                    if (findValue) {
                                      findArray.taskList = findValue.Task_List;
                                    }
                                    taskArray?.taskList?.forEach(
                                      (taskListVal) => {
                                        let findValue = sowList?.find(
                                          (val) =>
                                            Number(val?.Service_Task_Id) ===
                                            Number(taskListVal.Service_Task_Id)
                                        );
                                        if (findValue) {
                                          currVal?.push(findValue);
                                        }
                                      }
                                    );
                                    setSowList(currVal);
                                    setFieldValue("scopeOfWork", currVal);
                                    setFieldValue("selectedTemplate", value);
                                    setFieldValue("selectedTemplateId", value);
                                    handleBtnDisable(currVal);
                                  });
                                }

                              } else {
                                setIsDefaultValueChecked(false);
                                setFieldValue("selectedTemplate", value);
                                setFieldValue("selectedTemplateId", value);
                              }
                            }}
                            requiredField={true}
                          />
                          {operationInfo?.Accounts_Status !== "accepted" && values?.selectedTemplate.length < 1 && (
                            <span style={{ color: 'red' }}>Required!</span>
                          )}
                        </Grid>
                      </Grid>
                      <br />
                      {operationInfo?.Accounts_Status === "accepted" && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.quotationInnerGridRight}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"sow"}
                          >
                            <Form.Label
                              column
                              sm="12"
                              className="fc-dropdown-label"
                            >
                              Selected Order Template Scope Of Work
                              <sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"selectsow"}
                          >
                            <Col sm="12">
                              {/* Scope of Work Component Has to  be */}
                              {values?.selectedScopeOfWork?.length > 0 && (
                                <div
                                  className="fc-dropdown-container"
                                  style={{ padding: "10px" }}
                                >
                                  <Box
                                    component={Grid}
                                    display={{ xs: "none", md: "flex" }}
                                  >
                                    <Grid container columns={12}>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Task Name</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Task Type</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Task Category</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Task Section</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Task Detail</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Work Type</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Days</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Sequence</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Amount</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Govt. Dept</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Special Instruction</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={1}>
                                        <p>Req. No</p>
                                      </Grid>
                                    </Grid>
                                  </Box>

                                  <>
                                    {values?.selectedScopeOfWork?.map((val, i) => {
                                      return (
                                        <Grid container columns={12}>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Task Name
                                              </p>
                                            </Box>

                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                name="selectedScopeOfWork"
                                                value={val?.id}
                                                checked={(val?.checked === undefined || val?.checked === true) ? true : false}
                                                onClick={(e) => {

                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[i]}.checked`,
                                                    e.target.checked
                                                  );
                                                  let fina = [...values?.selectedScopeOfWork]
                                                  fina[i] = {
                                                    ...fina[i],
                                                    checked: e.target.checked
                                                  }
                                                  handleBtnDisable(fina);
                                                }}
                                                disabled={
                                                  (val?.Requisition_Created ===
                                                    "Account Accepted" || val?.Requisition_Created ===
                                                    "Yes")
                                                    ? true
                                                    : false
                                                }
                                              />

                                              <div
                                                className="fc-input-container"
                                                style={{
                                                  marginRight: 3,
                                                  marginLeft: 3,
                                                  width: "100%",
                                                }}
                                              >
                                                <Tooltip title={val?.Name}>
                                                  <input
                                                    name={"Task Name"}
                                                    value={val?.Name}
                                                    onChange={(e) => {
                                                      e.preventDefault();
                                                      // setFieldValue(
                                                      //   `selectedScopeOfWork.${[i]}.Name`,
                                                      //   e.target.value
                                                      // );
                                                      // let fina = [...values?.selectedScopeOfWork]
                                                      // fina[i] = {
                                                      //   ...fina[i],
                                                      //   Name: e.target.value
                                                      // }
                                                      // handleBtnDisable(fina);
                                                    }}
                                                    type={"text"}
                                                    className={`fc-input-style`}
                                                  // disabled={true}
                                                  />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Task Type
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <CustomSelect
                                                name={"Task type"}
                                                value={val?.Task_Type}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[
                                                      i,
                                                    ]}.Task_Type`,
                                                    value.value
                                                  );
                                                  let fina = [...values?.selectedScopeOfWork]
                                                  fina[i] = {
                                                    ...fina[i],
                                                    Task_Type: value.value
                                                  }
                                                  handleBtnDisable(fina);

                                                }}
                                                options={taskTypeDropdown}
                                                className={`fc-input-style`}
                                                isDisabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Task Category
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <CustomSelect
                                                name={"Task category"}
                                                value={val?.Task_Category}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[
                                                      i,
                                                    ]}.Task_Category`,
                                                    value.value
                                                  );
                                                  let fina = [...values?.selectedScopeOfWork]
                                                  fina[i] = {
                                                    ...fina[i],
                                                    Task_Category: value.value
                                                  }
                                                  handleBtnDisable(fina);
                                                }}
                                                options={taskCategoryDropdown}
                                                className={`fc-input-style`}
                                                isDisabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Task Section
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <CustomSelect
                                                name={"Task section"}
                                                value={val?.Task_Section}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[
                                                      i,
                                                    ]}.Task_Section`,
                                                    value.value
                                                  );
                                                  let fina = [...values?.selectedScopeOfWork]
                                                  fina[i] = {
                                                    ...fina[i],
                                                    Task_Section: value.value
                                                  }
                                                  handleBtnDisable(fina);
                                                }}
                                                options={taskSectionDropdown}
                                                className={`fc-input-style`}
                                                isDisabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Task Detail
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-input-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <Tooltip title={val?.Task_Detail}>
                                                <input
                                                  name={"Task Details"}
                                                  value={val?.Task_Detail}
                                                  onChange={(e) => {
                                                    e.preventDefault();
                                                    // setFieldValue(
                                                    //   `selectedScopeOfWork.${[
                                                    //     i,
                                                    //   ]}.Task_Detail`,
                                                    //   e.target.value
                                                    // );
                                                    // let fina = [...values?.selectedScopeOfWork]
                                                    // fina[i] = {
                                                    //   ...fina[i],
                                                    //   Task_Detail: e.target.value
                                                    // }
                                                    // handleBtnDisable(fina);
                                                  }}
                                                  type={"text"}
                                                  className={`fc-input-style`}
                                                // disabled={true}
                                                />
                                              </Tooltip>
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Work Type
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <CustomSelect
                                                name={"Work Type"}
                                                value={val?.Work_Type}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[
                                                      i,
                                                    ]}.Work_Type`,
                                                    value.value
                                                  );
                                                  let fina = [...values?.selectedScopeOfWork]
                                                  fina[i] = {
                                                    ...fina[i],
                                                    Work_Type: value.value
                                                  }
                                                  handleBtnDisable(fina);
                                                }}
                                                isDisabled={
                                                  (val?.Requisition_Created ===
                                                    "Account Accepted" || val?.Requisition_Created ===
                                                    "Yes" || (orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work?.[i]?.Work_Type === "PRO Work"))
                                                    ? true
                                                    : false
                                                }
                                                options={workTypeDropdown}
                                                className={`fc-input-style`}
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Days
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-input-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <input
                                                name={"Days"}
                                                value={val?.Days}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  if (value === '' || /^[0-9]\d*$/.test(value)) {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[i]}.Days`,
                                                    e.target.value
                                                  );
                                                  let fina = [...sowList];
                                                  let sowIndex =
                                                    fina?.findIndex(
                                                      (item) =>
                                                        item.Service_Task_Id ===
                                                        val.Service_Task_Id
                                                    );
                                                  fina[sowIndex] = {
                                                    ...fina[sowIndex],
                                                    Days: parseInt(
                                                      e.target.value
                                                    ),
                                                  };
                                                  let final = [...values?.selectedScopeOfWork]
                                                  final[i] = {
                                                    ...final[i],
                                                    Days: parseInt(
                                                      e.target.value
                                                    ),
                                                  }
                                                  handleBtnDisable(final);
                                                  setSowList(fina);
                                                }
                                                }}
                                                type={"text"}
                                                disabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                                className={`fc-input-style`}
                                              />
                                            </div>
                                            {val?.Nos_Task_Days < 1 && (
                                              <span style={{ color: "red" }}>
                                                Required!
                                              </span>
                                            )}
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Sequence
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-input-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                              name={"Sequence"}
                                              value={val?.Sequance}
                                              onChange={(e) => {
                                                // Only allow empty string or positive integers with no decimal point
                                                if (e.target.value === "" || (/^[1-9][0-9]*$/.test(e.target.value))) {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[i]}.Sequance`,
                                                    e.target.value
                                                  );
                                                  
                                                  let final = [...values?.selectedScopeOfWork];
                                                  final[i] = {
                                                    ...final[i],
                                                    Sequance: parseInt(e.target.value) || 0,
                                                  };
                                                  handleBtnDisable(final);
                                                }
                                              }}
                                              onKeyDown={(e) => {
                                                // Prevent decimal point (period) key
                                                if (e.key === '.') {
                                                  e.preventDefault();
                                                }
                                                // Call the original handleKeyDown function
                                                handleKeyDown(e);
                                              }}
                                              type={"text"}
                                              disabled={
                                                val?.Requisition_Created === "Account Accepted"
                                                  ? true
                                                  : false
                                              }
                                              className={`fc-input-style`}
                                            />
                                            </div>
                                            {isSequenceExist(val?.Sequance, [...values?.selectedScopeOfWork, ...sowList], val?.Service_Task_Id) && (
                                              <span style={{ color: 'red', fontSize: 12 }}>
                                                Sequence already exists!
                                              </span>
                                            )}
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Amount
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-input-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <input
                                                name={"Task Amount"}
                                                value={val?.Amount}
                                                onChange={(e) => {
                                                  if (e.target.value < 0) { return }
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[i]}.Amount`,
                                                    Number(e.target.value)
                                                  );
                                                  let final = [...values?.selectedScopeOfWork]
                                                  final[i] = {
                                                    ...final[i],
                                                    Amount: parseInt(
                                                      e.target.value
                                                    ),
                                                  }
                                                  handleBtnDisable(final);
                                                }}
                                                disabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                                type={"number"}
                                                className={`fc-input-style`}
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Govt. Dept
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <CustomSelect
                                                name={"Govt. Dept"}
                                                value={String(
                                                  val?.Govt_Department
                                                )}
                                                onChange={(value) => {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[
                                                      i,
                                                    ]}.Govt_Department`,
                                                    value.value
                                                  );
                                                  let final = [...values?.selectedScopeOfWork]
                                                  final[i] = {
                                                    ...final[i],
                                                    Govt_Department: value.value
                                                  }
                                                  handleBtnDisable(final);
                                                }}
                                                isDisabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                                options={govtDeptDropdown}
                                                className={`fc-input-style`}
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Special Instruction
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-input-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <input
                                                name={"Special Instrcution"}
                                                value={val?.Special_Instruction}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `selectedScopeOfWork.${[
                                                      i,
                                                    ]}.Special_Instruction`,
                                                    e.target.value
                                                  );
                                                  let final = [...values?.selectedScopeOfWork]
                                                  final[i] = {
                                                    ...final[i],
                                                    Special_Instruction: e.target.value
                                                  }
                                                  handleBtnDisable(final);
                                                }}
                                                type={"text"}
                                                disabled={
                                                  val?.Requisition_Created ===
                                                    "Account Accepted"
                                                    ? true
                                                    : false
                                                }
                                                className={`fc-input-style`}
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Req. No
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-input-container"
                                              style={{ marginRight: 3 }}
                                            >
                                              <input
                                                name={"requisition_no"}
                                                value={val?.Requisition_No}
                                                onChange={(e) => {
                                                  e.preventDefault();
                                                }}
                                                type={"text"}
                                                // disabled={true}
                                                className={`fc-input-style`}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </>
                                </div>
                              )}
                            </Col>

                          </Form.Group>

                        </Grid>
                      )}

                      <br />

                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.quotationInnerGridRight}
                      >
                        <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                          <Form.Label
                            column
                            sm="12"
                            className="fc-dropdown-label"
                          >
                            Select Order Template Scope Of Work
                            <sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-1"
                          controlId={"selectsow"}
                        >
                          <Col sm="12">
                            {/* Scope of Work Component Has to  be */}
                            {values?.selectedTemplate?.length > 0 && (
                              <div
                                className="fc-dropdown-container"
                                style={{ padding: "10px" }}
                              >
                                <Box
                                  component={Grid}
                                  display={{ xs: "none", md: "flex" }}
                                >
                                  <Grid container columns={12}>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task Name</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task Type</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task Category</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task Section</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task Detail</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Work Type</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Days</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Sequence</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Amount</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Govt. Dept</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Special Instruction</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Req. No</p>
                                    </Grid>
                                  </Grid>
                                </Box>
                                {values?.selectedTemplate?.map(
                                  (value, index) => {
                                    return (
                                      <>
                                        {value?.taskList?.map((val, i) => {
                                          return (
                                            <Grid container columns={12}>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task Name
                                                  </p>
                                                </Box>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Form.Check
                                                    type="checkbox"
                                                    name="scopeOfWork"
                                                    value={val?.id}
                                                    checked={handleOrderTemplateChecked(
                                                      values?.scopeOfWork,
                                                      val
                                                    )}
                                                    disabled={isCheckboxDisabled(
                                                      val?.Service_Task_Id
                                                    )}
                                                    onClick={(e) => {

                                                      const currVal =
                                                        values.scopeOfWork;
                                                      if (e.target.checked) {
                                                        let obj = {
                                                          Id: value.id,
                                                          Name: val.Service_Task_Name,
                                                          Task_Type:
                                                            val.Task_Type_Name,
                                                          Task_Category:
                                                            val.Task_Category,
                                                          Days: val?.Nos_Task_Days,
                                                          Sequance:
                                                            Number(val?.Task_Sequence),
                                                          Amount:
                                                            Number(val?.Task_Amount),
                                                          Govt_Department:
                                                            val.Govt_Dept_Name,
                                                          Special_Instruction:
                                                            val.Special_Instruction,
                                                          Task_Section:
                                                            val?.Single_Multi_Task_Type,
                                                          Task_Detail:
                                                            val.Task_Details,
                                                          Work_Type:
                                                            val.Work_Type,
                                                          Tag_Id: Number(
                                                            val.Tag_Id
                                                          ),
                                                          // Tag_Name:
                                                          //   val.Tag_Name,
                                                          Template_Name:
                                                            value.label,
                                                          Template_Id: value.id,
                                                          Service_Task_Id:
                                                            val?.Service_Task_Id,
                                                          Requisition_No: val?.Requisition_No,
                                                        };
                                                        currVal.push(obj);
                                                      } else {
                                                        let isValueExist =
                                                          currVal.findIndex(
                                                            (doc) =>
                                                              doc.Service_Task_Id ===
                                                              val.Service_Task_Id
                                                          );
                                                        currVal.splice(
                                                          isValueExist,
                                                          1
                                                        );
                                                      }
                                                      handleBtnDisable(currVal);
                                                      setSowList(currVal);
                                                      setFieldValue(
                                                        "scopeOfWork",
                                                        currVal
                                                      );

                                                    }}
                                                  />

                                                  <div
                                                    className="fc-input-container"
                                                    style={{
                                                      marginRight: 3,
                                                      marginLeft: 3,
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <Tooltip title={val?.Service_Task_Name}>
                                                      <input
                                                        name={"Task Name"}
                                                        value={
                                                          val?.Service_Task_Name
                                                        }
                                                        onChange={(e) => {
                                                          // setFieldValue(
                                                          //   `selectedTemplate.${[
                                                          //     index,
                                                          //   ]}.taskList.${[
                                                          //     i,
                                                          //   ]}.Service_Task_Name`,
                                                          //   e.target.value
                                                          // );
                                                          // let fina = [...sowList];
                                                          // let sowIndex =
                                                          //   fina?.findIndex(
                                                          //     (item) =>
                                                          //       item.Service_Task_Id ===
                                                          //       val.Service_Task_Id
                                                          //   );
                                                          // fina[sowIndex] = {
                                                          //   ...fina[sowIndex],
                                                          //   Name: e.target.value,
                                                          // };
                                                          // handleBtnDisable(fina);
                                                          // setSowList(fina);

                                                        }}
                                                        type={"text"}
                                                        className={`fc-input-style`}
                                                      // disabled={true}
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task Type
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Task type"}
                                                    value={val?.Task_Type_Name}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Type_Name`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Type: value.value,
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}
                                                    options={taskTypeDropdown}
                                                    className={`fc-input-style`}
                                                    isDisabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task Category
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Task category"}
                                                    value={val?.Task_Category}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Category`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Category:
                                                          value.value,
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}
                                                    options={
                                                      taskCategoryDropdown
                                                    }
                                                    className={`fc-input-style`}
                                                    isDisabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task Section
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Task section"}
                                                    value={
                                                      val?.Single_Multi_Task_Type
                                                    }
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Single_Multi_Task_Type`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Section:
                                                          value.value,
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}
                                                    options={
                                                      taskSectionDropdown
                                                    }
                                                    className={`fc-input-style`}
                                                    isDisabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task Detail
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <Tooltip title={val?.Task_Details}>
                                                    <input
                                                      name={"Task Details"}
                                                      value={val?.Task_Details}
                                                      onChange={(e) => {
                                                        e.preventDefault();
                                                        // setFieldValue(
                                                        //   `selectedTemplate.${[
                                                        //     index,
                                                        //   ]}.taskList.${[
                                                        //     i,
                                                        //   ]}.Task_Details`,
                                                        //   e.target.value
                                                        // );
                                                        // let fina = [...sowList];
                                                        // let sowIndex =
                                                        //   fina?.findIndex(
                                                        //     (item) =>
                                                        //       item.Service_Task_Id ===
                                                        //       val.Service_Task_Id
                                                        //   );
                                                        // fina[sowIndex] = {
                                                        //   ...fina[sowIndex],
                                                        //   Task_Detail:
                                                        //     e.target.value,
                                                        // };
                                                        // setSowList(fina);
                                                        //  handleBtnDisable(fina);
                                                      }}
                                                      type={"text"}
                                                      className={`fc-input-style`}
                                                    // disabled={true}
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Work Type
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Work Type"}
                                                    value={val?.Work_Type}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Work_Type`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Work_Type: value.value,
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}

                                                    isDisabled={
                                                      (selectetOrderTemplate?.[index]?.taskList?.[i]?.Work_Type === "PRO Work") ? true : false
                                                    }
                                                    options={workTypeDropdown}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Days
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Days"}
                                                    value={val?.Nos_Task_Days}
                                                    onChange={(e) => {
                                                      const value = e.target.value;
                                                      if (value === '' || /^[0-9]\d*$/.test(value)) {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Nos_Task_Days`,
                                                        value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Days: parseInt(
                                                          value
                                                        ),
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }
                                                    }}
                                                    type={"text"}
                                                    disabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                                {val?.Nos_Task_Days < 1 && (
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Required!
                                                  </span>
                                                )}
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Sequence
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Sequence"}
                                                    value={val?.Task_Sequence}
                                                    onChange={(e) => {
                                                      if (e.target.value === "" || (/^[1-9][0-9]*$/.test(e.target.value))) {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Sequence`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Sequance: parseInt(
                                                          e.target.value
                                                        ),
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }
                                                    }}
                                                    type={"text"}
                                                    disabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                                {isSequenceExist(val?.Task_Sequence, [...values?.selectedScopeOfWork, ...sowList], val?.Service_Task_Id) && (
                                                  <span style={{ color: 'red', fontSize: 12 }}>
                                                    Sequence already exists!
                                                  </span>
                                                )}
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Amount
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Task Amount"}
                                                    value={val?.Task_Amount}
                                                    onChange={(e) => {
                                                      if (e.target.value < 0) { return }
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Amount`,
                                                        Number(e.target.value)
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Amount: Number(e.target.value),
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}
                                                    disabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                    type={"text"}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Govt. Dept
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Govt. Dept"}
                                                    value={String(
                                                      val?.Govt_Dept_Name
                                                    )}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Govt_Dept_Name`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Govt_Department:
                                                          value.value,
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}
                                                    isDisabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                    options={govtDeptDropdown}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Special Instruction
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Special Instrcution"}
                                                    value={
                                                      val?.Special_Instruction
                                                    }
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Special_Instruction`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Special_Instruction:
                                                          e.target.value,
                                                      };
                                                      setSowList(fina);
                                                      handleBtnDisable(fina);
                                                    }}
                                                    type={"text"}
                                                    disabled={
                                                      val?.Requisition_Created ===
                                                        "Account Accepted"
                                                        ? true
                                                        : false
                                                    }
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Req. No
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"requisition_no"}
                                                    value={val?.Requisition_No}
                                                    onChange={(e) => {
                                                      e.preventDefault();
                                                    }}
                                                    type={"text"}
                                                    // disabled={true}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                            </Grid>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </Col>
                          {operationInfo?.Accounts_Status !== "accepted" && values?.scopeOfWork?.length < 1 && (
                            <span style={{ color: 'red', fontSize: 12 }}>
                              Required
                            </span>
                          )}
                        </Form.Group>
                      </Grid>
                    </div>
                    <br />
                  </Grid>
                </Grid>
                <br />
                <br />
              </div>
            </form>
          );
        }}
      </Formik>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(UpdateNOFSOW);
