import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow: "auto",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};

const ViewPROServicesForm = ({ classes, getInfo }) => {
  const { handleModaleClose, operationInfo } = useContext(SupportDataContext);

  return (
    <>
      <Box sx={style}>
        <div>
          <div style={{ height: "36px" }}>
            <IconButton
              style={{ float: "right" }}
              className={classes.quotationModalHeaderIconCancle}
              onClick={() => {
                handleModaleClose();
              }}
            >
              <CloseIcon
                className={classes.quotationModalHeaderIconCancleIcon}
              />
            </IconButton>
          </div>
          <div style={{ height: "480px", overflowX: "hidden", padding: "0" }}>
            <Grid container spacing={2} columns={12}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: "-30px",
                  paddingBottom: "10px",
                  marginTop: "-45px",
                  zIndex: 10,
                }}
              >
                <br />
                <div className={classes.quotationModalHeadingContainer}>
                  <span className={classes.quotationModalHeader}>
                    View Offshore Scope Of Work
                  </span>
                </div>
                <br />
                <div className={classes.quotationModalFlexDivContaner}>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <InputText
                        label="State"
                        fieldName="State"
                        fieldValue={operationInfo?.state_name}
                        disableField={true}
                        disabled={true}
                      />
                      <InputText
                        label="Formation Type"
                        fieldName="formation_Type"
                        fieldValue={operationInfo?.formation_type_name}
                        disableField={true}
                        disabled={true}
                      />
                      <InputText
                        label="Formation Type for PRO Services"
                        fieldName="formation_Type"
                        fieldValue={
                          operationInfo?.formation_type_for_pro_service
                        }
                        disableField={true}
                        disabled={true}
                      />
                      <InputText
                        label="DED/FZ/Offshore Name"
                        fieldName="formation_Type"
                        fieldValue={operationInfo?.ded_fz_offshore_name}
                        disableField={true}
                        disabled={true}
                      />
                      <InputText
                        label="PRO Services Name"
                        fieldName="Legal Type"
                        fieldValue={operationInfo?.pro_service_name}
                        disableField={true}
                        disabled={true}
                      />
              
                      <Form.Group
                        as={Row}
                        className="mb-1"
                        controlId={"status"}
                      >
                        <Form.Label
                          column
                          sm="5"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          Workflows
                        </Form.Label>
                        <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                          <Grid container>
                            <Grid item xs={12} md={5}>
                              <input
                                style={{
                                  width: "98%",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "4.6px 10px",
                                }}
                                placeholder="Name"
                                type="text"
                                name="Name"
                                value={operationInfo?.workflow_name}
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <input
                                style={{
                                  width: "98%",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "4.6px 10px",
                                }}
                                placeholder="Days"
                                type="number"
                                name="days"
                                value={operationInfo?.workflow_days}
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <input
                                style={{
                                  width: "98%",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "4.6px 10px",
                                }}
                                placeholder="Sequence"
                                type="number"
                                name="sequence"
                                value={operationInfo?.workflow_sequence}
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>
                          </Grid>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-1"
                        controlId={"status"}
                      >
                        <Form.Label
                          column
                          sm="5"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          Status
                        </Form.Label>
                        <Col
                          sm="7"
                          style={{
                            padding: "0 12px 0 0",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {operationInfo?.status === 1 ? "Active" : "Inactive"}
                        </Col>
                      </Form.Group>
                    </Grid>
                  </Grid>
                </div>
                <br />
              </Grid>
            </Grid>

            <br />
            <br />
            <div className={classes.quotationButtonDivModal}>
              <Button
                onClick={() => {
                  handleModaleClose();
                  // handleHide()
                }}
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#fff",
                  color: "#eb6e12",
                  marginLeft: "10px",
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(ViewPROServicesForm);
