import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const OverdueReasonForm = ({ classes, setOpenModal }) => {
  const {
    setLoading,
    loading,
    setMessage,
    setSeverity,
    operationInfo,
    getOverDueOrdersDataApi,
    setIsOpen,
    overDueOrdersData,
    handleModaleClose
  } = useContext(SupportDataContext);

  const reasonList = overDueOrdersData?.reason_list?.map(
    (item) => {
        return {
            value: item.Id, label: item.Name
        }
    }
  );

  const overdueReasonPOSTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/OverdueOrderReason/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      company_id: operationInfo?.Company_Id,
      order_id: operationInfo?.Order_Id ,
      support_emp_id: Number(operationInfo?.Support_Emp_Id),
      reason_id: values?.reasonId ,
      reason_name: values?.reasonName,
      delay_reason: values?.delay_reason,
    };

      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getOverDueOrdersDataApi(operationInfo?.order_id);
    setOpenModal(false);
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        reasonName: "",
        reasonId: "",
        delay_reason: "",
      }}
      validate={(values) => {
        let errors = {};

        if (!values.reasonName) {
          errors.reasonName = "required";
        }

        if (!values.reasonId) {
            errors.reasonId = "required";
          }

        return errors;
      }}
      onSubmit={(data) => {
        overdueReasonPOSTAPI(data)
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      handleModaleClose();
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "400px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                         Add Overdue Reason
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                              <CustomDropdown
                                label="Reason"
                                fieldOptions={reasonList}
                                fieldValue={values?.reasonId}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`reasonId`, value.value);
                                  setFieldValue(`reasonName`, value.label);
                                }}
                                requiredField={true}
                              />
                         
                          <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Remarks
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <textarea
                                 name="delay_reason"
                                 value={values.delay_reason}
                                 onChange={(e) => {
                                   e.preventDefault();
                                   const { value } = e.target;
                                   setFieldValue(`delay_reason`, value);
                                 }}
                                 required
                                 style={{ height: "120px", width: "100%" }}
                               />
                             </Col>
                           </Form.Group>
                           </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        handleModaleClose();
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(OverdueReasonForm);
