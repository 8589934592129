import React, { useContext } from "react";
import { withStyles } from "@material-ui/core";
import previewFormStyles from "../Preview/previewStyle";
import { Box, Grid, IconButton } from "@mui/material";
import { DataContext, SupportDataContext } from "../../../../provider/context"
import CloseIcon from "@mui/icons-material/Close";
import "../styles/styles.css";
import moment from "moment";
const ViewOrderFormForCompany = ({ classes, setOpenModal, isSupportModule }) => {
    const {
        handleModaleClose,
        salesInfo,
        getOrderDropDownForCompany,
        orderDropDown,
        operationInfo,
        setOrderDropDown,
        setEditView, setEditCard
    } = useContext(SupportDataContext);
    const editLicenseType = () => {
        const data1 = {
            Order_Id: operationInfo.Order_Id,
            Company_Id: salesInfo?.Company_Id || operationInfo.inquiry_id,
            stateId: orderDropDown?.Order_Details?.[0]?.state_id,
            formationId: orderDropDown?.Order_Details?.[0]?.formation_type,
            freezoneId: orderDropDown?.Order_Details?.[0]?.freezone_name === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.freezone_name,
            licenseId: orderDropDown?.Order_Details?.[0]?.license_type === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.license_type,
            offshoreId: orderDropDown?.Order_Details?.[0]?.offshore_name === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.offshore_name,
            legalTypeId: orderDropDown?.Order_Details?.[0]?.legal_type === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.legal_type,
            ded_Fz_Offshore: orderDropDown?.Order_Details?.[0]?.pro_ded_fz_offshore_name === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.pro_ded_fz_offshore_name,
            proServiceId: orderDropDown?.Order_Details?.[0]?.pro_formation_type === null ? undefined : orderDropDown?.Order_Details?.[0]?.pro_formation_type,
        };
        return getOrderDropDownForCompany(data1);
    }
    React.useEffect(() => {
        // if(!isSupportModule)  editLicenseType();
    }, []);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        bgcolor: "#fff",
        border: "2px solid #eb6e12",
        boxShadow: 10,
        p: 4,
        height: "590",
        paddingTop: "0px",
        maxHeight: "590px",
        overflowY: "scroll",
    };
    
    const state = orderDropDown?.Master_State?.find(
        (n) => n.StateIds === parseInt(orderDropDown?.Order_Details?.[0]?.state_id)
    );
    // const formationType = orderDropDown?.Master_type_of_company.find(
    //     (n) => n.Formation_type_id === parseInt(orderDropDown?.Order_Details?.[0]?.formation_type)
    // );
    // const licenseType = orderDropDown?.License_Type.find(
    //     (n) => n.Ids === parseInt(orderDropDown?.Order_Details?.[0]?.license_type)
    // );
    // const legalType = orderDropDown?.Legal_Type.find(
    //     (n) => n.Ids === parseInt(orderDropDown?.Order_Details?.[0]?.legal_type)
    // );
    // const activityGroupType = orderDropDown?.Activity_Group.find(
    //     (n) => n.Ids === parseInt(orderDropDown?.Order_Details?.[0]?.main_activity)
    // );
    // const freezoneType = orderDropDown?.Freezone_Name.find(
    //     (n) => n.Id === parseInt(orderDropDown?.Order_Details?.[0]?.freezone_name)
    // );
    // const offshoreType = orderDropDown?.Offshore_Name.find(
    //     (n) => n.Id === parseInt(orderDropDown?.Order_Details?.[0]?.offshore_name)
    // );
    // const proFormationType = orderDropDown?.PRO_Formation_Type.find(
    //     (n) => n === parseInt(orderDropDown?.Order_Details?.[0]?.pro_formation_type)
    // );
    // const ded_Fz_OfforeType = orderDropDown?.DED_FZ_Offshore_Name.find(
    //     (n) => n.Id === parseInt(orderDropDown?.Order_Details?.[0]?.pro_ded_fz_offshore_name)
    // );
    // const cpMember = orderDropDown?.CP_Coordinator.find(
    //     (n) => n.EmpId === parseInt(orderDropDown?.Order_Details?.[0]?.office_third_party_cp_coordinate)
    // );

    const corporateData = orderDropDown?.ShareHolderDetails?.filter(x =>
        x.Shareholder_Corporate === "Corporate"
        // x.DOB === ""
        // && x.nationality === ""
        // && x.Partner_Name === ""
    );

    const shareHolderData = orderDropDown?.ShareHolderDetails?.filter(x =>
        x.Shareholder_Corporate === "Shareholder" 
        // x.DOB !== ""
        // || x.nationality !== ""
        // || x.Partner_Name !== ""
    );
    const scopeOfWorkForEmployee = orderDropDown?.length !== 0 
    &&
    orderDropDown?.Order_Details[0]?.scope_of_work_details_pro?.filter(x => 
        x.SOW === "Employment Visa" 
       ||  x.SOW === "Employment Visa Renewal"
         );

    const scopeOfWorkDetails = orderDropDown?.length !== 0 
    &&
    orderDropDown?.Order_Details[0]?.scope_of_work_details_pro?.filter(x => 
        x.SOW_Charges !== "" 
       ||  x.SOW_Quantity !== ""
         );

    return (
        <>
            {
            orderDropDown?.Order_Details?.length >= 0 ?
                <div className={classes.divModal}>
                  
                    <Box sx={style}>
                    <div
                  style={{ height: "36px", boxShadow: "0 3px 6px #0000001c" }}
                >
                        <IconButton
                            style={{ backgroundColor: "#fffffff0", float: "right" }}
                            className={classes.headerIconCancle}
                            onClick={() => {
                                handleModaleClose();
                              }}
                        >
                            <CloseIcon
                                className={classes.headerIcon}
                                style={{ marginTop: "0px", marginBottom: "-40px", fontSize: "25px" }}
                            />
                        </IconButton>
                   </div>
                        <Grid container spacing={2} columns={12}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginBottom: "-30px",
                                    paddingBottom: "10px",
                                }}
                            >
                                <br />
                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"View of New Order Form (NOF)"}
                                    </span>
                                </div>
                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"New Order Form Detail"}{" "}
                                    </span>
                                </div>
                                <div
                                    style={{ marginBottom: "10px" }}
                                    className={classes.followUpFormModalFlexDivContaner}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        > 
                                       <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            Company Id
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.lastCellData}
                                        >
                                            {operationInfo?.company_id || operationInfo?.inquiry_id || operationInfo?.Company_Id}
                                        </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Order Priority
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {orderDropDown?.Order_Details[0]?.order_priority}

                                            </Grid>
                                          
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                        <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                State
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                                {state?.StateName !== undefined
                                                    ? state?.StateName
                                                    : "undefined"}

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Type of company
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {orderDropDown?.Order_Details[0]?.Formation_type !== undefined
                                                    ? orderDropDown?.Order_Details[0]?.Formation_type
                                                    : "undefined"}
                                            </Grid>
                                           


                                        </Grid>
                                       
                                        
                                        {
                                            orderDropDown?.Order_Details[0]?.formation_type === 4 && (<Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    PRO Service Formation
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    {orderDropDown?.Order_Details[0]?.pro_formation_type}
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    DED/FZ/Offhsore
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    {orderDropDown?.Order_Details[0]?.pro_ded_fz_offshore_name}
                                                </Grid>
                                            </Grid>)
                                        }

                                    </Grid>
                                </div>
                                {/* <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"Company Information"}{" "}
                                    </span>
                                </div>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: "flex" }}
                                        className={classes.followUpFormInnerGridLeft}
                                    >


                                        <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            Company Id
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.lastCellData}
                                        >
                                            {salesInfo?.company_id || businessInfo?.inquiry_id}
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            Company Name
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            {salesInfo?.company_name}
                                        </Grid>
                                    </Grid>



                                </Grid> */}
                                {
                                    orderDropDown?.Order_Details[0]?.formation_type === 4 && (
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    License Activities Details
                                                </span>
                                            </div>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid
                                                    xs={6}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    Activity Name
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    Code
                                                </Grid>
                                            </Grid>

                                            {orderDropDown?.Order_Details[0]?.license_activities_details?.map((i) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid
                                                                xs={6}
                                                                style={{ height: "auto" }}
                                                                className={classes.cellData}
                                                            >
                                                                {i.Name}
                                                            </Grid>

                                                            <Grid
                                                                xs={6}
                                                                style={{ height: "auto" }}
                                                                className={classes.lastCellData}
                                                            >
                                                                {i.Code}
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                            }

                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid
                                                    xs={12}
                                                    style={{ height: "auto", textAlign: "center", display: "flex", flexDirection: "column", padding: "0px", }}
                                                    className={classes.lastCellData}
                                                >
                                                    {orderDropDown?.Order_Details[0]?.proposed_company_name?.split(",").map((i, index) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                    xs={12}
                                                                    style={{
                                                                        height: "auto", textAlign: "left", borderLeft: "none",
                                                                        borderBottom: orderDropDown?.Order_Details[0]?.proposed_company_name?.length > 1 &&
                                                                            index ===
                                                                            orderDropDown?.Order_Details[0]?.proposed_company_name?.length - 1 ? "none" : "1px solid #d3d3d3"
                                                                    }}
                                                                    className={classes.cellData}>
                                                                    {i}
                                                                </Grid>

                                                            </>
                                                        )
                                                    })

                                                    }
                                                </Grid>


                                            </Grid>
                                        </>
                                    )
                                }


                                {
                                    orderDropDown?.Order_Details[0]?.pro_formation_type === "Mainland"
                                        ? (
                                            <>
                                                <div className={classes.headingContainer}>
                                                    <span className={classes.modalHeader}>
                                                        {" "}
                                                        {"UAE National / Service Agent"}{" "}
                                                    </span>
                                                </div>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid
                                                            xs={4}
                                                            style={{ fontWeight: "600", height: "auto" }}
                                                            className={classes.cellData}
                                                        >
                                                            UAE National
                                                        </Grid>
                                                        <Grid
                                                            xs={4}
                                                            style={{ height: "auto" }}
                                                            className={classes.cellData}
                                                        >
                                                            {orderDropDown?.Order_Details[0]?.uae_national}
                                                        </Grid>
                                                        {orderDropDown?.Order_Details[0]?.uae_national === "Required" ? (
                                                            <>
                                                                <Grid
                                                                    xs={6}
                                                                    style={{ fontWeight: "600", height: "auto" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    Arranged By
                                                                </Grid>
                                                                <Grid
                                                                    xs={6}
                                                                    style={{ height: "auto" }}
                                                                    className={classes.lastCellData}
                                                                >
                                                                    {orderDropDown?.Order_Details[0]?.arranged_by}
                                                                </Grid>
                                                            </>

                                                        ) : (
                                                            <Grid
                                                                xs={12}
                                                                style={{ fontWeight: "600", height: "auto", color: "#eb6e12", textAlign: "center" }}
                                                                className={classes.lastCellData}>
                                                                No UAE national will require for this license as Shareholder or Service agent
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    {
                                                        orderDropDown?.Order_Details[0]?.uae_national === "Required" && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid
                                                                    xs={3}
                                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    Position in license:
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex", }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={4} style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ fontWeight: "600", height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            Position as
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            {orderDropDown?.Order_Details[0]?.position_in_license_position_as}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={4} style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ fontWeight: "600", height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            Name of the UAE national
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            {orderDropDown?.Order_Details[0]?.position_in_license_name}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={4} style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ fontWeight: "600", height: "auto" }}
                                                                            className={classes.lastCellData}
                                                                        >
                                                                            Share
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ height: "auto" }}
                                                                            className={classes.lastCellData}
                                                                        >
                                                                            {orderDropDown?.Order_Details[0]?.position_in_license_share}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>)}
                                                </Grid>
                                            </>
                                        ) : null
                                }


                                {/* {
                                    orderDropDown?.Order_Details[0]?.point_of_contact.find((i) => i === "Third Party") && (
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    Point of Contact{" "}
                                                </span>
                                            </div>
                                            <div
                                                style={{ marginBottom: "10px" }}
                                                className={classes.followUpFormModalFlexDivContaner}
                                            >
                                                <table
                                                    style={{ border: "1px solid #c3c3c3", borderTop: "none" }}
                                                    className={classes.followUpFormInnerGridLeft}
                                                >
                                                    <tr className={classes.followUpFormInnerGridLeft}>
                                                        <th
                                                            style={{ textAlign: "center" }}
                                                            className={classes.cellDataHead}
                                                        >
                                                            Name
                                                        </th>
                                                        <th
                                                            style={{ textAlign: "center" }}
                                                            className={classes.cellDataHead}
                                                        >
                                                            Relation
                                                        </th>
                                                        <th
                                                            style={{ textAlign: "center" }}
                                                            className={classes.cellDataHead}
                                                        >
                                                            Email
                                                        </th>
                                                        <th
                                                            style={{ textAlign: "center" }}
                                                            className={classes.cellDataHead}
                                                        >
                                                            Primary Number
                                                        </th>
                                                        <th
                                                            style={{ textAlign: "center" }}
                                                            className={classes.cellDataHead}
                                                        >
                                                            Alternate Number
                                                        </th>
                                                        <th
                                                            style={{ textAlign: "center" }}
                                                            className={classes.cellDataHead}
                                                        >
                                                            POA Holder
                                                        </th>
                                                        {
                                                            orderDropDown?.Order_Details[0]?.person_detail.find((item) => item.POA_Holder === "Yes") && (
                                                                <th
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.cellDataHead}
                                                                >
                                                                    POA Holder Name
                                                                </th>
                                                            )
                                                        }

                                                    </tr>
                                                    {
                                                        orderDropDown?.Order_Details[0]?.person_detail.map((i) => {
                                                            return (
                                                                <tr>

                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {i.Name}
                                                                    </td>
                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {i.Email}
                                                                    </td>
                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {i.Relation}
                                                                    </td>
                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {i.Primary_Phone_No}
                                                                    </td>
                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {i.Alternate_Number}
                                                                    </td>
                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {i.POA_Holder}
                                                                    </td>
                                                                    {i.POA_Holder === "No" ? null : (
                                                                        <td
                                                                            style={{ textAlign: "center" }}
                                                                            className={classes.lastCellData}
                                                                        >
                                                                            {i.POA_Holder_Name}
                                                                        </td>
                                                                    )}

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </table>
                                            </div>
                                        </>
                                    )
                                } */}
                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"Shareholder Detail"}{" "}
                                    </span>
                                </div>


                                {
                                    <>
                                        {
                                            shareHolderData?.map((i) => {

                                                return (
                                                    <>
                                                        {/* <Grid xs={6}
                                                             style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                              Corporate / Shareholder
                                                         </Grid>
                                                         <Grid xs={6}
                                                             style={{ height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                             {
                                                             i.nationality !== "" || i.DOB !== "" || i.gender !== "" ? "Shareholder" : "Corporate"
                                                             }
                                                         </Grid> */}

                                                        {
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >

                                                                <Grid xs={3}
                                                                    style={{ height: "auto", textAlign: "center" }}
                                                                    className={classes.cellData}>
                                                                    <b>Name of Shareholder:</b>  <span style={{ color: "#eb6e12" }}>{i.Partner_Name}</span>
                                                                </Grid>
                                                                <Grid xs={9}
                                                                    style={{ height: "auto", }}
                                                                    className={classes.lastCellData}>
                                                                    <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                        <li style={{ width: "100%" }}> <b>Total Shares:</b> {i.Total_Share}%</li>
                                                                        <li style={{ width: "100%" }}><b>Role_In_License:</b> {i.Role_In_License}</li>

                                                                    </ul>
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                        <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.DOB}</li>
                                                                        <li style={{ width: "100%" }}> <b>Gender:</b> {i.gender}</li>

                                                                    </ul>
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >

                                                                        <li style={{ width: "100%" }}> <b>Nationality:</b> {i.nationality}</li>
                                                                    </ul>
                                                                   
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >

                                                                        <li style={{ width: "100%" }}> <b>Visited UAE:</b> {i.visited_UAE}</li>
                                                                        <li style={{ width: "100%" }}> <b>Visa Status:</b> {i.visa_status}</li>

                                                                    </ul>
                                                                  
                                                                    <ul>

                                                                        {/* <li style={{ width: "100%" }}> <b>Email Id:</b> {i.Email_Id}</li>
                                                                    <li style={{ width: "100%" }}> <b>Mobile:</b> {i.Mobile}</li> */}

                                                                        {
                                                                            i.investor_visa === "Yes" && (
                                                                                <>
                                                                                    <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                                        {/* <li> <b>Position:</b> {i.Position}</li> */}
                                                                                        <li>
                                                                                            <b>Visa Application:</b> {i.visa_application}
                                                                                        </li>
                                                                                        <li>
                                                                                            <b>Security approval:</b> {i.security_approval}
                                                                                        </li>
                                                                                        <li> <b>Degree Certificate: </b>{i.degree_certificate}</li>
                                                                                        <li> <b> Salary bifurcation:</b> {i.salary_bifurcation}</li>
                                                                                        {i.Salary_Bifurcation === "Salary Detail" && (
                                                                                            <ul>
                                                                                                <li> <b> Basic:</b> {i.salary_basic}</li>
                                                                                                <li> <b>Accommodation:</b> {i.salary_accommodation}</li>
                                                                                                <li> <b> Transportation:</b> {i.salary_transportation}</li>
                                                                                                <li> <b> Total Salary:</b> {i.total_salary}</li>
                                                                                            </ul>
                                                                                        )}

                                                                                    </ul>

                                                                                </>
                                                                            )
                                                                        }


                                                                        <li> <b>Represent by any POA holder:</b> {i.represent_by_any_POA_holder}</li>
                                                                        {
                                                                            i.represent_by_any_POA_holder === "Yes" && (
                                                                                <ul><li> <b>Name of POA:</b> {i.name_of_POA}</li></ul>
                                                                            )
                                                                        }
                                                                        <li> <b>Can Be Contact :</b> {i.can_be_contact}</li>
                                                                        {
                                                                            i.can_be_contact === "Yes" && (
                                                                                <ul>
                                                                                    <li> <b>Primary Number:</b>{i.Mobile}</li>
                                                                                    <li> <b> Email :</b> {i.Email_Id}</li>
                                                                                    <li> <b>Alternate Number:</b> {i.alternate_number}</li>
                                                                                </ul>
                                                                            )}


                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                            
                                                           
                                                        }
                                                    </>
                                                )
                                            }

                                            )
                                        }

                                    </>

                                }

                           

                                {
                                    <>
                                        {corporateData?.length !== undefined && corporateData?.length !== 0 &&
                                            corporateData?.map((i) => {

                                                return (
                                                    <>
                                                        {/* <Grid xs={6}
                                                             style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                              Corporate / Shareholder
                                                         </Grid>
                                                         <Grid xs={6}
                                                             style={{ height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                             {
                                                             i.nationality !== "" || i.DOB !== "" || i.gender !== "" ? "Shareholder" : "Corporate"
                                                             }
                                                         </Grid> */}

                                                        {


                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid xs={3}
                                                                    style={{ height: "auto", textAlign: "center" }}
                                                                    className={classes.cellData}>
                                                                    <b>Name of Company:</b>  <span style={{ color: "#eb6e12" }}>{i.corporate_company_name}</span>
                                                                </Grid>
                                                                <Grid xs={9}
                                                                    style={{ height: "auto", }}
                                                                    className={classes.lastCellData}>
                                                                    <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                        <li style={{ width: "100%" }}> <b>Corporate Company Type:</b> {i.corporate_company_type}</li>
                                                                        <li style={{ width: "100%" }}><b>Corporate Incorporation Country:</b> {i.corporate_incorporation_country}</li>

                                                                    </ul>
                                                                    <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                        <li style={{ width: "100%" }}> <b>Total Shares:</b> {i.Total_Share}%</li>
                                                                        <li style={{ width: "100%" }}><b>Role_In_License:</b> {i.Role_In_License}</li>

                                                                    </ul>
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                        <li style={{ width: "100%" }}> <b>Corporate License Number:</b> {i.corporate_license_number}</li>
                                                                        <li style={{ width: "100%" }}> <b>Name of_POA:</b> {i.name_of_POA_for_corporate}</li>

                                                                    </ul>
                                                                    <ul>

                                                                        {/* <li style={{ width: "100%" }}> <b>Email Id:</b> {i.Email_Id}</li>
                                                                <li style={{ width: "100%" }}> <b>Mobile:</b> {i.Mobile}</li> */}

                                                                        {
                                                                            i.document_of_parent_company_attested === "Yes" && (
                                                                                <>
                                                                                    <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                                        {/* <li> <b>Position:</b> {i.Position}</li> */}
                                                                                        <li>
                                                                                            <b>Need FC Assistance:</b> {i.need_fc_assistance}
                                                                                        </li>
                                                                                        <li>
                                                                                            <b>CP Cordinator Name:</b> {i.cp_cordinator_name}
                                                                                        </li>



                                                                                    </ul>

                                                                                </>
                                                                            )
                                                                        }





                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                )
                                            }

                                            )
                                        }

                                    </>

                                }



                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"Scope of Work (with time frame)"}{" "}
                                    </span>
                                </div>
                                {orderDropDown?.Order_Details[0]?.length !== 0 && (
                                    <>

                                        {scopeOfWorkDetails?.map((i) => {
                                            return (
                                                <>
                                                 <Grid
                                                    item
                                                    xs={12}
                                                    style={{ display: "flex" }}
                                                    className={classes.followUpFormInnerGridLeft}
                                                >
                                                    <Grid xs={3}
                                                        style={{ height: "auto", textAlign: "left" }}
                                                        className={classes.cellData}>
                                                        <b>SOW:</b>  <span style={{ color: "#eb6e12" }}>{
                                                           i.SOW
                                                        }</span>
                                                    </Grid>
                                                    <Grid xs={9}
                                                        style={{ height: "auto", }}
                                                        className={classes.lastCellData}>
                                                         
                                                        <ul style={{ display: "flex", width: "100%", margin: "0px" }}>

                                                            <li style={{ width: "100%" }}><b>Quantity:</b> {i.SOW_Quantity}</li>
                                                            <li style={{ width: "100%" }}> <b>Charges:</b> {i.SOW_Charges}</li>
                                                        </ul>
                                                        <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                            <li style={{ width: "100%" }}> <b>Total Cost:</b> {i.SOW_Total_Cost}</li>

                                                            <li style={{ width: "100%" }}> <b>Time Frame:</b> {i.SOW_Time_Frame}</li>
                                                        </ul>
                                                        <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                            <li style={{ width: "100%" }}> <b>Description:</b> {i.SOW_Description}</li>
                                                        </ul>
                                                      
                                                    </Grid>
                                                </Grid>
                                                    {/* <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.cellData}>
                                                            Scope Of Work
                                                        </Grid>

                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.cellData}>
                                                            {i?.SOW}
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.cellData}>
                                                            QTY
                                                        </Grid>

                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.cellData}>
                                                            {i?.SOW_Quantity}
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            Charges
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW_Charges}
                                                        </Grid>

                                                    </Grid> */}
                                                    {/* <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            Total Cost
                                                        </Grid>

                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW_Total_Cost}
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            Time Frame
                                                        </Grid>

                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW_Time_Frame}
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            Description
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i?.SOW_Description}
                                                        </Grid>
                                                    </Grid> */}
                                                </>
                                            )
                                        })}

                                    </>
                                )
                                }

                                {
                                orderDropDown?.Order_Details[0]?.SOW_employee_details?.length !== undefined
                                    && orderDropDown?.Order_Details[0]?.SOW_employee_details?.length !== 0
                                    &&
                                    orderDropDown?.Order_Details[0]?.SOW_employee_details?.map((i) => {

                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ display: "flex" }}
                                                    className={classes.followUpFormInnerGridLeft}
                                                >
                                                    <Grid xs={3}
                                                        style={{ height: "auto", textAlign: "left" }}
                                                        className={classes.cellData}>
                                                        <b>SOW:</b>  <span style={{ color: "#eb6e12" }}>{
                                                            "Employment Visa"
                                                        }</span>
                                                    </Grid>
                                                    <Grid xs={9}
                                                        style={{ height: "auto", }}
                                                        className={classes.lastCellData}>
                                                          <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                            <li style={{ width: "100%" }}><b>Name:</b> {i.Name}</li>
                                                            </ul>
                                                        <ul style={{ display: "flex", width: "100%", margin: "0px" }}>

                                                            <li style={{ width: "100%" }}><b>Visa Application:</b> {i.Visa_Application}</li>
                                                            <li style={{ width: "100%" }}> <b> Visited Status:</b> {i.Visa_Status}</li>
                                                        </ul>
                                                        <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                            <li style={{ width: "100%" }}> <b>Gender:</b> {i.Gender}</li>

                                                            <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                        </ul>
                                                        <ul>


                                                            <li style={{ width: "100%" }}> <b>Designation:</b> {i.Designation}</li>
                                                            {
                                                                i.Designation === "Confirm" && (
                                                                    <>
                                                                        <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                            <li> <b>Designation Name:</b> {i.Designation_Name}</li>

                                                                        </ul>

                                                                    </>
                                                                )
                                                            }
                                                            <>
                                                                <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>


                                                                    <li>
                                                                        <b>Security approval:</b> {i.Security_Approval}
                                                                    </li>
                                                                    <li> <b>Degree Certificate: </b>{i.Degree_Certificate}</li>
                                                                    <li> <b> Salary bifurcation:</b> {i.Salary_Bifurcation}</li>
                                                                    {i.Salary_Bifurcation === "Salary Detail" && (
                                                                        <ul>
                                                                            <li> <b> Basic:</b> {i.Salary_Bifurcation_Basic}</li>
                                                                            <li> <b>Accommodation:</b> {i.Salary_Bifurcation_Accommodation}</li>
                                                                            <li> <b> Transportation:</b> {i.Salary_Bifurcation_Transportation}</li>
                                                                            <li> <b> Total Salary:</b> {i.Salary_Bifurcation_Total_Salary}</li>
                                                                        </ul>
                                                                    )}

                                                                </ul>

                                                            </>




                                                        </ul>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }

                                    )
                                    
                                }
                                {
                                    
                                     orderDropDown?.Order_Details[0]?.SOW_employee_visa_renewal_details?.length !== undefined
                                     && orderDropDown?.Order_Details[0]?.SOW_employee_visa_renewal_details?.length !== 0
                                     &&
                                     orderDropDown?.Order_Details[0]?.SOW_employee_visa_renewal_details?.map((i) => {
 
                                         return (
                                             <>
                                                 <Grid
                                                     item
                                                     xs={12}
                                                     style={{ display: "flex" }}
                                                     className={classes.followUpFormInnerGridLeft}
                                                 >
                                                     <Grid xs={3}
                                                         style={{ height: "auto", textAlign: "left" }}
                                                         className={classes.cellData}>
                                                         <b>SOW:</b>  <span style={{ color: "#eb6e12" }}>{
                                                            "Employment Visa Renewal"
                                                         }</span>
                                                     </Grid>
                                                     <Grid xs={9}
                                                         style={{ height: "auto", }}
                                                         className={classes.lastCellData}>
                                                             <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                            <li style={{ width: "100%" }}><b>Name:</b> {i.Name}</li>
                                                            </ul>
                                                         <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                             <li style={{ width: "100%" }}><b>Visa Application:</b> {i.Visa_Application}</li>
                                                             <li style={{ width: "100%" }}> <b> Visited Status:</b> {i.Visa_Status}</li>
                                                         </ul>
                                                         <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                             <li style={{ width: "100%" }}> <b>Gender:</b> {i.Gender}</li>
 
                                                             <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                         </ul>
                                                         <ul>
 
 
                                                             <li style={{ width: "100%" }}> <b>Designation:</b> {i.Designation}</li>
                                                             {
                                                                 i.Designation === "Confirm" && (
                                                                     <>
                                                                         <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                             <li> <b>Designation Name:</b> {i.Designation_Name}</li>
 
                                                                         </ul>
 
                                                                     </>
                                                                 )
                                                             }
                                                             <>
                                                                 <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
 
 
                                                                     <li>
                                                                         <b>Security approval:</b> {i.Security_Approval}
                                                                     </li>
                                                                     <li> <b>Degree Certificate: </b>{i.Degree_Certificate}</li>
                                                                     <li> <b> Salary bifurcation:</b> {i.Salary_Bifurcation}</li>
                                                                     {i.Salary_Bifurcation === "Salary Detail" && (
                                                                         <ul>
                                                                             <li> <b> Basic:</b> {i.Salary_Bifurcation_Basic}</li>
                                                                             <li> <b>Accommodation:</b> {i.Salary_Bifurcation_Accommodation}</li>
                                                                             <li> <b> Transportation:</b> {i.Salary_Bifurcation_Transportation}</li>
                                                                             <li> <b> Total Salary:</b> {i.Salary_Bifurcation_Total_Salary}</li>
                                                                         </ul>
                                                                     )}
 
                                                                 </ul>
 
                                                             </>
 
 
 
 
                                                         </ul>
                                                     </Grid>
                                                 </Grid>
                                             </>
                                         )
                                     }
 
                                     )
                                }





                                {
                                    orderDropDown?.Order_Details[0]?.pro_formation_type === "Mainland" && (
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    {"Office Detail"}{" "}
                                                </span>
                                            </div>
                                            <Grid container>

                                                {
                                                    ["Flyingcolour Sharing Office", "Third Party"].includes(orderDropDown?.Order_Details[0]?.office) ? (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex", }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        Office
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details[0]?.office}
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    orderDropDown?.Order_Details[0]?.office === "Flyingcolour Sharing Office" && (
                                                                        <>
                                                                            <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                                <Grid
                                                                                    xs={12}
                                                                                    style={{ fontWeight: "600", height: "auto" }}
                                                                                    className={classes.cellData}
                                                                                >
                                                                                    Office Number
                                                                                </Grid>
                                                                                <Grid
                                                                                    xs={12}
                                                                                    style={{ height: "auto" }}
                                                                                    className={classes.cellData}
                                                                                >
                                                                                    {orderDropDown?.Order_Details[0]?.office_number}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                                <Grid
                                                                                    xs={12}
                                                                                    style={{ fontWeight: "600", height: "auto" }}
                                                                                    className={classes.cellData}
                                                                                >
                                                                                    MOU
                                                                                </Grid>
                                                                                <Grid
                                                                                    xs={12}
                                                                                    style={{ height: "auto" }}
                                                                                    className={classes.cellData}
                                                                                >
                                                                                    {orderDropDown?.Order_Details[0]?.office_mou}
                                                                                </Grid>
                                                                            </Grid>

                                                                        </>
                                                                    )}
                                                                {
                                                                    orderDropDown?.Order_Details[0]?.office === "Third Party" && (
                                                                        <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                            <Grid
                                                                                xs={12}
                                                                                style={{ fontWeight: "600", height: "auto" }}
                                                                                className={classes.cellData}
                                                                            >
                                                                                Name of CP Member
                                                                            </Grid>
                                                                            <Grid
                                                                                xs={12}
                                                                                style={{ height: "auto" }}
                                                                                className={classes.cellData}
                                                                            >
                                                                                {/* {cpMember?.Emp_First_Name !== undefined ? `${cpMember?.Emp_First_Name} ${cpMember?.Emp_Last_Name}` : "undefined"} */}
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                                }
                                                                <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        Remarks
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details[0]?.remarks}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex", }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ fontWeight: "600", height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            Office
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            {orderDropDown?.Order_Details[0]?.office}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ fontWeight: "600", height: "auto" }}
                                                                            className={classes.lastCellData}
                                                                        >
                                                                            Remarks
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ height: "auto" }}
                                                                            className={classes.lastCellData}
                                                                        >
                                                                            {orderDropDown?.Order_Details[0]?.remarks}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                }

                                            </Grid>
                                        </>
                                    )
                                }
                                {
                                   
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    {"Payment Description"}{" "}
                                                </span>
                                            </div>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid xs={3}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    Govt. Received Amount
                                                </Grid>
                                                <Grid xs={2}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    {orderDropDown?.Order_Details[0]?.paymentDescriptionGovtAmount}
                                                </Grid>
                                                <Grid xs={4}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    FC SC Received Amount
                                                </Grid>
                                                <Grid xs={3}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    {orderDropDown?.Order_Details[0]?.paymentDescriptionFCAmount}
                                                </Grid>
                                                <Grid xs={3}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    Payment terms
                                                </Grid>
                                                <Grid xs={3}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                    {orderDropDown?.Order_Details[0]?.paymentDescriptionPaymentTerms}
                                                </Grid>
                                            </Grid>
                                        </>
                                  
                                }


                                <div className={classes.headingContainer}>
                                    <span className={classes.modalHeader}>
                                        {" "}
                                        {"Upload Document Detail"}{" "}
                                    </span>
                                </div>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >
                                    <Grid xs={4}
                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                        className={classes.cellData}>
                                        Upload Type
                                    </Grid>
                                    <Grid xs={4}
                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                        className={classes.cellData}>
                                        Document Particular
                                    </Grid>
                                    <Grid xs={4}
                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                        className={classes.lastCellData}>
                                        Document/File
                                    </Grid>
                                </Grid>
                                {
                                    orderDropDown?.Order_Details[0]?.upload_doc_details.map((i) => {
                                        const docType = orderDropDown.Document_Type.find(
                                            (n) => n.Id === parseInt(i?.Document_Type)
                                        );
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid xs={4}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    {docType?.Doc_Name}
                                                </Grid>
                                                <Grid xs={4}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    {i.Particular}
                                                </Grid>
                                                <Grid xs={4}
                                                    style={{ height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                    <button className="btn-view" onClick={() => window.open(i.uploadDoc)}>View</button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* Missing document  */}
                                {
                                    orderDropDown?.Order_Details[0]?.missing_documents.length !== 0 && (
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    {"Missing Document Detail"}{" "}
                                                </span>
                                            </div>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid xs={6}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.cellData}>
                                                    Upload Type
                                                </Grid>
                                                <Grid xs={6}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                    Document Particular
                                                </Grid>
                                            </Grid>
                                            {
                                                orderDropDown?.Order_Details[0]?.missing_documents.map((i) => {
                                                    const docType = orderDropDown.Document_Type.find(
                                                        (n) => n.Id === parseInt(i.Document_Type)
                                                    );
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid xs={6}
                                                                style={{ height: "auto", textAlign: "center" }}
                                                                className={classes.cellData}>
                                                                {docType?.Doc_Name}
                                                            </Grid>
                                                            <Grid xs={6}
                                                                style={{ height: "auto", textAlign: "center" }}
                                                                className={classes.lastCellData}>
                                                                {i.Particular}
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                
                                {
                                   
                                   <>
                                       <div className={classes.headingContainer}>
                                           <span className={classes.modalHeader}>
                                               {" "}
                                               {"General Remarks"}{" "}
                                           </span>
                                       </div>
                                       <Grid
                                           item
                                           xs={12}
                                           style={{ display: "flex" }}
                                           className={classes.followUpFormInnerGridLeft}
                                       >
                                           <Grid xs={2}
                                               style={{ fontWeight: "600", height: "auto", textAlign: "left" }}
                                               className={classes.cellData}>
                                               Remarks
                                           </Grid>
                                           <Grid xs={10}
                                               style={{ height: "auto", textAlign: "left" }}
                                               className={classes.cellData}>
                                               { <p
                                                dangerouslySetInnerHTML={{ __html: orderDropDown?.Order_Details[0]?.general_remarks }}
                                               ></p>
                                               
                                               }
                                           </Grid>
                                          
                                       </Grid>
                                   </>
                             
                           }

                                <br />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                : null}
        </>
    );
};

export default withStyles(previewFormStyles)(ViewOrderFormForCompany);