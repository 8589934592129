import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext, DataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import { VpnLock } from "@material-ui/icons";
import { addDays } from "date-fns";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    padding: "25px",
    height: "auto",
    paddingTop: "0px",
    minHeight: "560px",
    maxHeight: "560px",
    overflow: 'auto',
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        maxHeight: "760px",
    },
};

const getStyles = (errors, fieldName, height) => {

    if (height && !getIn(errors, fieldName)) {
        return { height: height }
    }

    if (getIn(errors, fieldName) && height) {
        return {
            border: "1px solid red",
            height: height
        };
    } else if (getIn(errors, fieldName)) {
        return {
            border: "1px solid red"
        };
    }
};

const PDNOFApprovalForm = ({ classes }) => {
  
    const {
        handleModaleClose,
        setLoading,
        loading,
        setMessage,
        setSeverity,
        editModalPopper,
        setIsOpen,
        operationInfo,
        salesInfo,
        rowData,
        approvedCPSubleadData,
        nofOtherData, getNOFDataApi
      } = useContext(SupportDataContext);
    
    const [enableReinitialize, setEnablReinitializei] = useState(false);
    const [partnerData, setPartnerData] = useState([]);
    const [disabledId, setDisabledId] = useState([]);
    const fileInput = React.createRef();

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className, }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#d11616",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#d11616",
        },
    }));
    const changesStatusOptions = nofOtherData?.Status_Details_List?.map((item) => {
        const options = { value: item?.Value, label: item?.Name }
        return options;
    }
    );
  
    const changeOrderStatusPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const factory_id = localStorage.getItem("factory_id");
        const user_name = localStorage.getItem("Employee_First_Name");

        const url = `https://flyingcolour.online/fcapp/docprocessing_api/update_nof_approval_status/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };
        const formData = new FormData();
        formData.append("emp_id", Number(emp_id));
        formData.append("user_type", user_type);
        formData.append("user_name", user_name);
        formData.append("order_status", values?.order_status);
        formData.append("order_type", operationInfo?.inquiry_company === "Company" ? 1 : 0); 
        formData.append("new_order_form_id", Number(operationInfo?.new_order_form_id));
        formData.append("comments", values?.comments);

         axios.put(url, formData, { headers: headers }).then((data) => {
                setLoading(false);
               
                if (data?.data?.message) {
                    setMessage(`${data?.data?.message}`);
                    setSeverity(500)
                }
                else {
                    setMessage(data?.data?.data?.msg);
                    setSeverity(data?.data?.data?.code)
                }
                 setIsOpen(true); 
                handleModaleClose();
                getNOFDataApi();
            })
    };

    useEffect(() => {
       // getPartnerDataApi();
    }, [])

return (
 <>
 <Formik
   enableReinitialize={enableReinitialize}
     initialValues={{
        order_status: "",
        comments: "",
         }}
         onSubmit={async (data) => {
             setLoading(true)
             changeOrderStatusPostAPI(data) 
         }}
         
         validate={(values) => {
             let errors = {};
                 if (values.order_status === "") {
                     errors.order_status = "Status is Required!!";
                 }
                
                //  if (val.Remarks === "") {
                //      errors.Remarks = "Required!!";
                //  }
           
             return errors;
         }}
        >
      {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          }) => {
     return (
         <form onSubmit={(e) => { e.preventDefault() }}>
             <Box sx={style}>
                 <div>
                     <div style={{ height: "36px" }}>
                         <IconButton
                             style={{ float: "right" }}
                             className={classes.quotationModalHeaderIconCancle}
                             onClick={() => {
                                 handleModaleClose();
                             }}
                         >
                             <CloseIcon
                                 className={classes.quotationModalHeaderIconCancleIcon}
                             />
                         </IconButton>
                     </div>
                     <div style={{ height: "480px", overflowX: "hidden", padding: "0" }}>
                         <Grid container spacing={2} columns={12}>
                             <Grid
                                 item
                                 xs={12}
                                 style={{
                                     marginBottom: "0px",
                                     paddingBottom: "10px",
                                     marginTop: "-45px",
                                     zIndex: 10
                                 }}
                             >
                                 <br />
                                 <div className={classes.quotationModalHeadingContainer}>
                                     <span className={classes.quotationModalHeader}>
                                         {editModalPopper ? `Edit Project Designer NOF Approval Status`
                                             : `Project Designer NOF Approval Status`}
                                     </span>
                                 </div>
                                 <br />
                                 <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                     
                     <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.quotationInnerGridRight}
                      >
                        <CustomDropdown                                 
                                      label="Order Status"
                                      fieldOptions={changesStatusOptions}
                                      fieldValue={values?.order_status}
                                      fieldHandleChange={(value) => {
                                          setFieldValue(`order_status`, value.value);
                                         // setDisabledId(value?.value)
                                      }}
                                    //   isOptionDisabled={option => option.show === false}
                                   // isOptionDisabled={(option) => option.isdisabled}
                                      errorField={getStyles(errors, values?.order_status)}
                                      requiredField={true}
                                  />
                          </Grid>
                    
                          <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.quotationInnerGridRight}
                      >
                         <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                Comments
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                <div className={classes.paymentReminderSelectContainerModal} >
                                  <textarea
                                    style={{ width: "100%" }}
                                    name="comments"
                                    value={values.comments}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      setFieldValue("comments", e.target.value)
                                    }}
                                    rows="5" cols="50"
                                  />
                                </div>
                              </Col>
                            </Form.Group>                         
                         </Grid>

                    </Grid>
 
         </div>
         <br />
       </Grid>
 </Grid>
     <br />
     <br />
     <div className={classes.quotationButtonDivModal}>
         <BootstrapTooltip arrow={true}

             disableFocusListener
             title={errors.order_status === undefined 
                 && errors.uploadDoc === undefined
                 ? ""
                 :
                 `${errors.order_status !== undefined ? `${errors.order_status},` : ""}
                  ${errors.Nationality !== undefined ? `${errors.Nationality},` : ""}`
                     }
                 >

             <Button
                 type="submit"
                 variant="contained"
                 size="small"
                 style={{ backgroundColor: "#eb6e12" }}
                 onClick={handleSubmit}
             >
                 {loading ? "Loading...." : "Submit"}
             </Button>
         </BootstrapTooltip>
         <Button
             onClick={() => {
                 handleModaleClose();
                 // handleHide()
             }}
             variant="contained"
             size="small"
             style={{
                 backgroundColor: "#fff",
                 color: "#eb6e12",
                 marginLeft: "10px",
             }}
         >
             Cancel
         </Button>
      </div>
     
     </div>
    
      </div>
 </Box>
  </form>
   );
   }}
    </Formik>
 </>
    );
};

export default withStyles(ModalPopperFormStyles)(PDNOFApprovalForm);
