import React, { useContext } from "react";
import { withStyles } from "@material-ui/core";
import previewFormStyles from "../Preview/previewStyle";
import { Box, Grid, IconButton } from "@mui/material";
import { DataContext, SupportDataContext } from "../../../../provider/context"
import CloseIcon from "@mui/icons-material/Close";
import "../Styles/styles.css";
import moment from "moment";
const ViewOrderForm = ({ classes, setOpenModal, isSupportModule }) => {
    const {
        handleModaleClose,
        salesInfo,
        getOrderDropDownApi,
        orderDropDown,
        businessInfo,
        opearationInfo,
        setOrderDropDown,
        setEditView,setEditCard
    } = useContext(SupportDataContext);
    const editLicenseType = () => {
        const data1 = {
            Order_Id: opearationInfo.Order_Id,
            Inquiry_Id: salesInfo.Inquiry_Id.replace("FC", ""),
            stateId: orderDropDown?.Order_Details?.[0]?.state_id,
            formationId: orderDropDown?.Order_Details?.[0]?.formation_type,
            freezoneId: orderDropDown?.Order_Details?.[0]?.freezone_name === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.freezone_name,
            licenseId: orderDropDown?.Order_Details?.[0]?.license_type === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.license_type,
            offshoreId: orderDropDown?.Order_Details?.[0]?.offshore_name === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.offshore_name,
            legalTypeId: orderDropDown?.Order_Details?.[0]?.legal_type === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.legal_type,
            ded_Fz_Offshore: orderDropDown?.Order_Details?.[0]?.pro_ded_fz_offshore_name === 0 ? undefined : orderDropDown?.Order_Details?.[0]?.pro_ded_fz_offshore_name,
            proServiceId: orderDropDown?.Order_Details?.[0]?.pro_formation_type === null ? undefined : orderDropDown?.Order_Details?.[0]?.pro_formation_type,
        };
        return getOrderDropDownApi(data1);
    }
    React.useEffect(() => {
        // if(!isSupportModule)  editLicenseType();
    }, []);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "85%",
        bgcolor: "#fff",
        border: "2px solid #eb6e12",
        boxShadow: 10,
        p: 4,
        height: "590",
        paddingTop: "0px",
        maxHeight: "590px",
        overflowY: "scroll",
    };

    const state = orderDropDown?.Master_State?.find(
        (n) => n.StateIds === parseInt(orderDropDown?.Order_Details?.[0]?.state_id)
    );
    const formationType = orderDropDown?.Master_type_of_company?.find(
        (n) => n.Formation_type_id === parseInt(orderDropDown?.Order_Details?.[0]?.formation_type)
    );
    const licenseType = orderDropDown?.License_Type?.find(
        (n) => n.Ids === parseInt(orderDropDown?.Order_Details?.[0]?.license_type)
    );
    const legalType = orderDropDown?.Legal_Type?.find(
        (n) => n.Ids === parseInt(orderDropDown?.Order_Details?.[0]?.legal_type)
    );
    const activityGroupType = orderDropDown?.Activity_Group?.find(
        (n) => n.Ids === parseInt(orderDropDown?.Order_Details?.[0]?.main_activity)
    );
    const freezoneType = orderDropDown?.Freezone_Name?.find(
        (n) => n.Id === parseInt(orderDropDown?.Order_Details?.[0]?.freezone_name)
    );
    const offshoreType = orderDropDown?.Offshore_Name?.find(
        (n) => n.Id === parseInt(orderDropDown?.Order_Details?.[0]?.offshore_name)
    );
    const proFormationType = orderDropDown?.PRO_Formation_Type?.find(
        (n) => n === parseInt(orderDropDown?.Order_Details?.[0]?.pro_formation_type)
    );
    const ded_Fz_OfforeType = orderDropDown?.DED_FZ_Offshore_Name?.find(
        (n) => n.Id === parseInt(orderDropDown?.Order_Details?.[0]?.pro_ded_fz_offshore_name)
    );
    const cpMember = orderDropDown?.CP_Coordinator?.find(
        (n) => n.EmpId === parseInt(orderDropDown?.Order_Details?.[0]?.office_third_party_cp_coordinate)
    );

    const additionalRoleArr = [];
    salesInfo?.additional_role !== null && 
    salesInfo?.additional_role !== "" && salesInfo?.additional_role?.split(",").forEach((el) => {
        additionalRoleArr?.push(el);
    });
    const corporateData = orderDropDown?.ShareHolderDetails?.filter(x =>
        x.Shareholder_Corporate === "Corporate" 
        // x.DOB === ""
        // && x.nationality === ""
        // && x.Partner_Name === ""
    );

    const shareHolderData = orderDropDown?.ShareHolderDetails?.filter(x =>
        x.Shareholder_Corporate === "Shareholder" 
        // x.DOB !== ""
        // || x.nationality !== ""
        // || x.Partner_Name !== ""
    );

    return (
        <>
            <div className={classes.divModal}>
                {/* <div
                    className={classes.closeModal}
                >
                    <IconButton
                        style={{ backgroundColor: "#fffffff0" }}
                        className={classes.headerIconCancle}
                        onClick={() => {
                            setEditCard(false)
                            setEditView("");
                            handleModaleClose();
                            setOrderDropDown([]);
                        }}
                    >
                        <CloseIcon
                            className={classes.headerIcon}
                            style={{ marginTop: "0px", fontSize: "25px" }}
                        />
                    </IconButton>
                </div> */}
                <Box sx={style}>
                
                    <IconButton
                        style={{ backgroundColor: "#fffffff0" }}
                        className={classes.headerIconCancle}
                        onClick={() => {
                            setOpenModal(false);
                          }}
                    >
                        <CloseIcon
                            className={classes.headerIcon}
                            style={{ marginTop: "0px", marginBottom: "-45px", fontSize: "25px" }}
                        />
                    </IconButton>
               
                    <Grid container spacing={2} columns={12}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                marginBottom: "-30px",
                                paddingBottom: "10px",
                            }}
                        >
                            <br />
                            <div className={classes.headingContainer}>
                                <span className={classes.modalHeader}>
                                    {" "}
                                    {"View of Order Form"}
                                </span>
                            </div>
                            <div className={classes.headingContainer}>
                                <span className={classes.modalHeader}>
                                    {" "}
                                    {"Order Form Detail"}{" "}
                                </span>
                            </div>
                            <div
                                style={{ marginBottom: "10px" }}
                                className={classes.followUpFormModalFlexDivContaner}
                            >
                                <Grid container>
                                <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                   
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Inquiry Id
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {salesInfo.Inquiry_Id || orderDropDown?.Order_Details?.[0]?.inquiry_id}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                       Created Date
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {orderDropDown?.Order_Details?.[0]?.order_date}
                                    </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: "flex" }}
                                        className={classes.followUpFormInnerGridLeft}
                                    >
                                        <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            Order Priority
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            {orderDropDown?.Order_Details?.[0]?.order_priority}

                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            State
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.lastCellData}
                                        >
                                            {state?.StateName !== undefined
                                                ? state?.StateName
                                                : "undefined"}

                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ display: "flex" }}
                                        className={classes.followUpFormInnerGridLeft}
                                    >

                                        <Grid
                                            xs={4}
                                            style={{ fontWeight: "600", height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            Type of company
                                        </Grid>
                                        <Grid
                                            xs={4}
                                            style={{ height: "auto" }}
                                            className={classes.cellData}
                                        >
                                            {formationType?.Formation_type !== undefined
                                                ? formationType?.Formation_type
                                                : "undefined"}
                                        </Grid>
                                        {orderDropDown?.Order_Details?.[0]?.formation_type === 2 ? (
                                            <>
                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    Freezone Name
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    {freezoneType?.Name !== undefined
                                                        ? freezoneType?.Name
                                                        : "undefined"}
                                                </Grid>
                                            </>
                                        ) : (<>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Legal Type
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                                {legalType?.Name !== undefined ? legalType?.Name : "undefined"}
                                            </Grid>
                                        </>
                                        )}


                                    </Grid>
                                    {
                                        orderDropDown?.Order_Details?.[0]?.formation_type === 2 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >

                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    License Type
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    {licenseType?.Name !== undefined ? licenseType?.Name : "undefined"}
                                                </Grid>

                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    Activity Group
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    {activityGroupType?.Name}
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    {
                                        orderDropDown?.Order_Details?.[0]?.formation_type === 3 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    Offshore Name
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    {offshoreType?.Name !== undefined ? offshoreType?.Name : "undefined"}
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    Activity Group
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    {activityGroupType?.Name}
                                                </Grid>
                                            </Grid>
                                        )}
                                    {
                                        orderDropDown?.Order_Details?.[0]?.formation_type === 1 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ display: "flex" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >

                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    License Type
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    {licenseType?.Name !== undefined ? licenseType?.Name : "undefined"}
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ fontWeight: "600", height: "auto" }}
                                                    className={classes.cellData}
                                                >
                                                    Activity Group
                                                </Grid>
                                                <Grid
                                                    xs={4}
                                                    style={{ height: "auto" }}
                                                    className={classes.lastCellData}
                                                >
                                                    {activityGroupType?.Name}
                                                </Grid>
                                            </Grid>)}
                                    {/* pro service */}
                                    {
                                        orderDropDown?.Order_Details?.[0]?.formation_type === 4 && (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                PRO Service
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {orderDropDown?.Order_Details?.[0]?.pro_formation_type}
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                DED/FZ/Offhsore
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                                {ded_Fz_OfforeType?.Name}
                                            </Grid>
                                        </Grid>)
                                    }
                                   
                                </Grid>
                                <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                    <Grid
                                        xs={6}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Share capital
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {orderDropDown?.Order_Details?.[0]?.share_capital}
                                    </Grid>
                                   
                                    </Grid>
                            </div>
                            {/* <div className={classes.headingContainer}>
                                <span className={classes.modalHeader}>
                                    {" "}
                                    {"Client Information"}{" "}
                                </span>
                            </div>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >

                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Share capital
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {orderDropDown?.Order_Details?.[0]?.share_capital}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Inquiry Id
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {salesInfo.Inquiry_Id}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        First Name
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.First_Name}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Last Name
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.Last_Name}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Email
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.Email}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Phone No
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.Contact_Number}
                                    </Grid>{" "}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Date of Birth
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {moment(orderDropDown?.Client_Information[0]?.clientDOB).format(
                                            "DD-MM-YYYY"
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        City
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.City}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >

                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Passport No
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.Passport_No}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Po Box No
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.PO_Box}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex" }}
                                    className={classes.followUpFormInnerGridLeft}
                                >
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Overseas Contact
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        {orderDropDown?.Client_Information[0]?.Overseas_Contact}
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ fontWeight: "600", height: "auto" }}
                                        className={classes.cellData}
                                    >
                                        Consultant Name
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        style={{ height: "auto" }}
                                        className={classes.lastCellData}
                                    >
                                        {orderDropDown?.Lead_Information[0]?.Consultant_Name}
                                    </Grid>

                                </Grid>

                                <Grid
                                    xs={3}
                                    style={{ fontWeight: "600", height: "auto" }}
                                    className={classes.cellData}
                                >
                                    Address
                                </Grid>
                                <Grid
                                    xs={3}
                                    style={{ height: "auto" }}
                                    className={classes.lastCellData}
                                >
                                    {orderDropDown?.Client_Information[0]?.Address}
                                </Grid>


                            </Grid> */}
                            {
                                orderDropDown?.Order_Details?.[0]?.formation_type !== 4 && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                License Activities Details
                                            </span>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={6}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Activity Name
                                            </Grid>
                                            <Grid
                                                xs={6}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                                Code
                                            </Grid>
                                        </Grid>

                                        {orderDropDown?.Order_Details?.[0]?.license_activities_details?.map((i) => {
                                            return (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid
                                                            xs={6}
                                                            style={{ height: "auto" }}
                                                            className={classes.cellData}
                                                        >
                                                            {i.Name}
                                                        </Grid>

                                                        <Grid
                                                            xs={6}
                                                            style={{ height: "auto" }}
                                                            className={classes.lastCellData}
                                                        >
                                                            {i.Code}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        })
                                        }
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                Proposed Company
                                            </span>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={12}
                                                style={{ height: "auto", textAlign: "center", display: "flex", flexDirection: "column", padding: "0px", }}
                                                className={classes.lastCellData}
                                            >
                                                {orderDropDown?.Order_Details?.[0]?.proposed_company_name?.split(",")?.map((i, index) => {
                                                    return (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                style={{
                                                                    height: "auto", textAlign: "left", borderLeft: "none",
                                                                    borderBottom: orderDropDown?.Order_Details?.[0]?.proposed_company_name?.length > 1 &&
                                                                        index ===
                                                                        orderDropDown?.Order_Details?.[0]?.proposed_company_name?.length - 1 ? "none" : "1px solid #d3d3d3"
                                                                }}
                                                                className={classes.cellData}>
                                                                {i}
                                                            </Grid>

                                                        </>
                                                    )
                                                })

                                                }
                                            </Grid>


                                        </Grid>
                                    </>
                                )
                            }

                            {
                                orderDropDown?.Order_Details?.[0]?.formation_type === 2 && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                {"Package Detail"}{" "}
                                            </span>
                                        </div>
                                        <Grid
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={12}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {orderDropDown?.Order_Details?.[0]?.package}
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }
                            {
                                [2, 3, 4].includes(orderDropDown?.Order_Details?.[0]?.formation_type)
                                    ? null : (
                                        <>
                                            <div className={classes.headingContainer}>
                                                <span className={classes.modalHeader}>
                                                    {" "}
                                                    {"UAE National / Service Agent"}{" "}
                                                </span>
                                            </div>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ display: "flex" }}
                                                    className={classes.followUpFormInnerGridLeft}
                                                >
                                                    <Grid
                                                        xs={4}
                                                        style={{ fontWeight: "600", height: "auto" }}
                                                        className={classes.cellData}
                                                    >
                                                        UAE National
                                                    </Grid>
                                                    <Grid
                                                        xs={4}
                                                        style={{ height: "auto" }}
                                                        className={classes.cellData}
                                                    >
                                                        {orderDropDown?.Order_Details?.[0]?.uae_national}
                                                    </Grid>
                                                    {orderDropDown?.Order_Details?.[0]?.uae_national === "Required" ? (
                                                        <>
                                                            <Grid
                                                                xs={6}
                                                                style={{ fontWeight: "600", height: "auto" }}
                                                                className={classes.cellData}
                                                            >
                                                                Arranged By
                                                            </Grid>
                                                            <Grid
                                                                xs={6}
                                                                style={{ height: "auto" }}
                                                                className={classes.lastCellData}
                                                            >
                                                                {orderDropDown?.Order_Details?.[0]?.arranged_by}
                                                            </Grid>
                                                        </>

                                                    ) : (
                                                        <Grid
                                                            xs={12}
                                                            style={{ fontWeight: "600", height: "auto", color: "#eb6e12", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            No UAE national will require for this license as Shareholder or Service agent
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                {
                                                    orderDropDown?.Order_Details?.[0]?.uae_national === "Required" && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid
                                                                xs={3}
                                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                                className={classes.cellData}
                                                            >
                                                                Position in license:
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex", }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid xs={4} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        Position as
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details?.[0]?.position_in_license_position_as}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid xs={4} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        Name of the UAE national
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details?.[0]?.position_in_license_name}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid xs={4} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        Share
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details?.[0]?.position_in_license_share}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>)}
                                            </Grid>
                                        </>
                                    )
                            }


                            {
                                orderDropDown?.Order_Details?.[0]?.point_of_contact?.find((i) => i === "Third Party") && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                Point of Contact{" "}
                                            </span>
                                        </div>
                                        <div
                                            style={{ marginBottom: "10px" }}
                                            className={classes.followUpFormModalFlexDivContaner}
                                        >
                                            <table
                                                style={{ border: "1px solid #c3c3c3", borderTop: "none" }}
                                                className={classes.followUpFormInnerGridLeft}
                                            >
                                                <tr className={classes.followUpFormInnerGridLeft}>
                                                    <th
                                                        style={{ textAlign: "center" }}
                                                        className={classes.cellDataHead}
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        style={{ textAlign: "center" }}
                                                        className={classes.cellDataHead}
                                                    >
                                                        Relation
                                                    </th>
                                                    <th
                                                        style={{ textAlign: "center" }}
                                                        className={classes.cellDataHead}
                                                    >
                                                        Email
                                                    </th>
                                                    <th
                                                        style={{ textAlign: "center" }}
                                                        className={classes.cellDataHead}
                                                    >
                                                        Primary Number
                                                    </th>
                                                    <th
                                                        style={{ textAlign: "center" }}
                                                        className={classes.cellDataHead}
                                                    >
                                                        Alternate Number
                                                    </th>
                                                    <th
                                                        style={{ textAlign: "center" }}
                                                        className={classes.cellDataHead}
                                                    >
                                                        POA Holder
                                                    </th>
                                                    {
                                                        orderDropDown?.Order_Details?.[0]?.person_detail?.find((item) => item.POA_Holder === "Yes") && (
                                                            <th
                                                                style={{ textAlign: "center" }}
                                                                className={classes.cellDataHead}
                                                            >
                                                                POA Holder Name
                                                            </th>
                                                        )
                                                    }

                                                </tr>
                                                {
                                                    orderDropDown?.Order_Details?.[0]?.person_detail?.map((i) => {
                                                        return (
                                                            <tr>

                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    {i.Name}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    {i.Email}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    {i.Relation}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.lastCellData}
                                                                >
                                                                    {i.Primary_Phone_No}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.lastCellData}
                                                                >
                                                                    {i.Alternate_Number}
                                                                </td>
                                                                <td
                                                                    style={{ textAlign: "center" }}
                                                                    className={classes.lastCellData}
                                                                >
                                                                    {i.POA_Holder}
                                                                </td>
                                                                {i.POA_Holder === "No" ? null : (
                                                                    <td
                                                                        style={{ textAlign: "center" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {i.POA_Holder_Name}
                                                                    </td>
                                                                )}

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    </>
                                )
                            }
                            <div className={classes.headingContainer}>
                                <span className={classes.modalHeader}>
                                    {" "}
                                    {"Shareholder Detail"}{" "}
                                </span>
                            </div>
                            {/* <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                className={classes.followUpFormInnerGridLeft}
                            >
                                <Grid xs={12}
                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                    className={classes.cellData}>
                                    Type of Shareholder
                                </Grid>
                                <Grid xs={6}
                                    style={{ height: "auto", textAlign: "center" }}
                                    className={classes.cellData}>
                                    {orderDropDown?.Order_Details?.[0]?.shareholder}
                                </Grid>
                                {["Individual", "Both"].includes(orderDropDown?.Order_Details?.[0]?.shareholder) && (
                                    <>
                                        <Grid xs={3}
                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                            className={classes.cellData}>
                                            No of Shareholder
                                        </Grid>
                                        <Grid xs={3}
                                            style={{ height: "auto", textAlign: "center" }}
                                            className={classes.lastCellData}>
                                            {orderDropDown?.Order_Details?.[0]?.no_of_shareholder}
                                        </Grid>
                                    </>
                                )}
                                 {["Corporate", "Both"].includes(orderDropDown?.Order_Details?.[0]?.shareholder) && (
                                    <>
                                        <Grid xs={3}
                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                            className={classes.cellData}>
                                            No of Corporate
                                        </Grid>
                                        <Grid xs={3}
                                            style={{ height: "auto", textAlign: "center" }}
                                            className={classes.lastCellData}>
                                            {orderDropDown?.Order_Details?.[0]?.number_of_shareholder_corporate}
                                        </Grid>
                                    </>
                                )}
                            </Grid> */}
                            {/* {
                                ["Corporate", "Both"].includes(orderDropDown?.Order_Details?.[0]?.shareholder) && (
                                    <>
                                    <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                               Company Name
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                               Company Type
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Share Percentage
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Documents of parent company Attested
                                            </Grid>
                                                <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Needs FC assistance
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Name of CP coordinator
                                            </Grid>
                                                <Grid xs={4}
                                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                    POA Holder Name
                                                </Grid>
                                    </Grid>
                                    {
                                    orderDropDown?.Order_Details?.[0]?.corporate_shareholder_details.map((i)=>{
                                        const fCMember = orderDropDown?.CP_Coordinator?.find(
                                            (n) => n.EmpId === parseInt(i.fc_assistance_cp_cordinator)
                                        );
                                        return(
                                            <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                              {i.company_name}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                               {i.local_forein_company}
                                            </Grid>
                                            
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {i.sharePercentage}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {i.parent_company_atested}
                                            </Grid>
                                                <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                               {i.fc_assistance}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{  height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                     {fCMember?.Emp_First_Name !== undefined ? `${fCMember?.Emp_First_Name} ${fCMember?.Emp_Last_Name}` : ""}
                                            </Grid>
                                                <Grid xs={4}
                                                    style={{  height: "auto", textAlign: "center" }}
                                                    className={classes.lastCellData}>
                                                  {i.corporate_poa_holder_name}
                                                </Grid>
                                            
                                    </Grid>   
                                        )
                                    })
                                }
                                    </>
                                )
                            } */}
                            {
                                    <>
                                        {
                                            shareHolderData?.map((i) => {

                                                return (
                                                    <>
                                                        {/* <Grid xs={6}
                                                             style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                              Corporate / Shareholder
                                                         </Grid>
                                                         <Grid xs={6}
                                                             style={{ height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                             {
                                                             i.nationality !== "" || i.DOB !== "" || i.gender !== "" ? "Shareholder" : "Corporate"
                                                             }
                                                         </Grid> */}

                                                        {
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >

                                                                <Grid xs={3}
                                                                    style={{ height: "auto", textAlign: "center" }}
                                                                    className={classes.cellData}>
                                                                    <b>Name of Shareholder:</b>  <span style={{ color: "#eb6e12" }}>{i.Partner_Name}</span>
                                                                </Grid>
                                                                <Grid xs={9}
                                                                    style={{ height: "auto", }}
                                                                    className={classes.lastCellData}>
                                                                    <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                        <li style={{ width: "100%" }}> <b>Total Shares:</b> {i.Total_Share}%</li>
                                                                        <li style={{ width: "100%" }}><b>Role In License:</b> {i.Role_In_License}</li>
                                                                        </ul>
                                                                        <ul>
                                                                        {
                                                                             i.Role_In_License === "Share Holder & Manager"
                                                                             || i.Role_In_License === "Share Holder Only" ?
                                                                              (
                                                                             <li><b>Additional Role:</b></li>
                                                                             ) : null
                                                                             
                                                                        }
                                                                         
                                                                        { additionalRoleArr !== null && 
                                                                       additionalRoleArr !== "" &&
                                                                       additionalRoleArr?.map((el, i) => {
                                                                    
                                                                           return(
                                                                                <ul>
                                                                                    <li style={{ marginLeft: "30px" }}><b> </b> {el}</li>
                                                                                </ul>
                                                                           )
                                                                        })
                                                                            }
                                                                            </ul>

                                                                   
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                        <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.DOB}</li>
                                                                        <li style={{ width: "100%" }}> <b>Gender:</b> {i.gender}</li>

                                                                    </ul>
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >

                                                                        <li style={{ width: "100%" }}> <b>Nationality:</b> {i.nationality}</li>
                                                                    </ul>
                                                                   
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >

                                                                        <li style={{ width: "100%" }}> <b>Visited UAE:</b> {i.visited_UAE}</li>
                                                                        <li style={{ width: "100%" }}> <b>Visa Status:</b> {i.visa_status}</li>

                                                                    </ul>
                                                                  
                                                                    <ul>

                                                                        {/* <li style={{ width: "100%" }}> <b>Email Id:</b> {i.Email_Id}</li>
                                                                    <li style={{ width: "100%" }}> <b>Mobile:</b> {i.Mobile}</li> */}

                                                                        {
                                                                            i.investor_visa === "Yes" && (
                                                                                <>
                                                                                    <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                                        {/* <li> <b>Position:</b> {i.Position}</li> */}
                                                                                        <li>
                                                                                            <b>Visa Application:</b> {i.visa_application}
                                                                                        </li>
                                                                                        <li>
                                                                                            <b>Security approval:</b> {i.security_approval}
                                                                                        </li>
                                                                                        <li> <b>Degree Certificate: </b>{i.degree_certificate}</li>
                                                                                        <li> <b> Salary bifurcation:</b> {i.salary_bifurcation}</li>
                                                                                        {i.Salary_Bifurcation === "Salary Detail" && (
                                                                                            <ul>
                                                                                                <li> <b> Basic:</b> {i.salary_basic}</li>
                                                                                                <li> <b>Accommodation:</b> {i.salary_accommodation}</li>
                                                                                                <li> <b> Transportation:</b> {i.salary_transportation}</li>
                                                                                                <li> <b> Total Salary:</b> {i.total_salary}</li>
                                                                                            </ul>
                                                                                        )}

                                                                                    </ul>

                                                                                </>
                                                                            )
                                                                        }


                                                                        <li> <b>Represent by any POA holder:</b> {i.represent_by_any_POA_holder}</li>
                                                                        {
                                                                            i.represent_by_any_POA_holder === "Yes" && (
                                                                                <ul><li> <b>Name of POA:</b> {i.name_of_POA}</li></ul>
                                                                            )
                                                                        }
                                                                        <li> <b>Can Be Contact :</b> {i.can_be_contact}</li>
                                                                        {
                                                                            i.can_be_contact === "Yes" && (
                                                                                <ul>
                                                                                    <li> <b>Primary Number:</b>{i.Mobile}</li>
                                                                                    <li> <b> Email :</b> {i.Email_Id}</li>
                                                                                    <li> <b>Alternate Number:</b> {i.alternate_number}</li>
                                                                                </ul>
                                                                            )}


                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                            
                                                           
                                                        }
                                                    </>
                                                )
                                            }

                                            )
                                        }

                                    </>

                                }

                           

                                {
                                    <>
                                        {corporateData?.length !== undefined && corporateData?.length !== 0 &&
                                            orderDropDown?.corporateData?.map((i) => {

                                                return (
                                                    <>
                                                        {/* <Grid xs={6}
                                                             style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                              Corporate / Shareholder
                                                         </Grid>
                                                         <Grid xs={6}
                                                             style={{ height: "auto", textAlign: "center" }}
                                                             className={classes.cellData}>
                                                             {
                                                             i.nationality !== "" || i.DOB !== "" || i.gender !== "" ? "Shareholder" : "Corporate"
                                                             }
                                                         </Grid> */}

                                                        {


                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid xs={3}
                                                                    style={{ height: "auto", textAlign: "center" }}
                                                                    className={classes.cellData}>
                                                                    <b>Name of Company:</b>  <span style={{ color: "#eb6e12" }}>{i.corporate_company_name}</span>
                                                                </Grid>
                                                                <Grid xs={9}
                                                                    style={{ height: "auto", }}
                                                                    className={classes.lastCellData}>
                                                                    <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                        <li style={{ width: "100%" }}> <b>Corporate Company Type:</b> {i.corporate_company_type}</li>
                                                                        <li style={{ width: "100%" }}><b>Corporate Incorporation Country:</b> {i.corporate_incorporation_country}</li>

                                                                    </ul>
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                        <li style={{ width: "100%" }}> <b>Corporate License Number:</b> {i.corporate_license_number}</li>
                                                                        <li style={{ width: "100%" }}> <b>Name of_POA:</b> {i.name_of_POA_for_corporate}</li>
                                                                        <li style={{ width: "100%" }}> <b>Nationality:</b> {i.nationality}</li>

                                                                    </ul>
                                                                    <ul>

                                                                        {/* <li style={{ width: "100%" }}> <b>Email Id:</b> {i.Email_Id}</li>
                                                                <li style={{ width: "100%" }}> <b>Mobile:</b> {i.Mobile}</li> */}

                                                                        {
                                                                            i.document_of_parent_company_attested === "Yes" && (
                                                                                <>
                                                                                    <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                                        {/* <li> <b>Position:</b> {i.Position}</li> */}
                                                                                        <li>
                                                                                            <b>Need FC Assistance:</b> {i.need_fc_assistance}
                                                                                        </li>
                                                                                        <li>
                                                                                            <b>CP Cordinator Name:</b> {i.cp_cordinator_name}
                                                                                        </li>



                                                                                    </ul>

                                                                                </>
                                                                            )
                                                                        }





                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                )
                                            }

                                            )
                                        }

                                    </>

                                }


                            {/* {
                                orderDropDown?.Order_Details?.[0]?.formation_type === 3 && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                {"Nominee Detail"}{" "}
                                            </span>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Nominee Type
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center", display: "flex", flexDirection: "column", }}
                                                className={classes.cellData}>
                                                {
                                                    orderDropDown?.Order_Details?.[0]?.nominee_detail_value.map((i, index) => {
                                                        return (
                                                            <Grid xs={12}
                                                                style={{
                                                                    height: "auto", textAlign: "center", padding: "0px", borderLeft: "none",
                                                                    borderBottom: orderDropDown?.Order_Details?.[0]?.nominee_detail_value?.length > 1 &&
                                                                        index ===
                                                                        orderDropDown?.Order_Details?.[0]?.nominee_detail_value.length - 1 ? "none" : "1px solid #d3d3d3"
                                                                }}
                                                                className={classes.cellData}>
                                                                {i}
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            {orderDropDown?.Order_Details?.[0]?.nominee_no_of_director !== 0 && (
                                                <>
                                                    <Grid xs={4}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        No of Director
                                                    </Grid>
                                                    <Grid xs={4}
                                                        style={{ height: "auto", textAlign: "center" }}
                                                        className={classes.lastCellData}>
                                                        {orderDropDown?.Order_Details?.[0]?.nominee_no_of_director}
                                                    </Grid>
                                                </>
                                            )}
                                            {
                                                orderDropDown?.Order_Details?.[0]?.nominee_no_of_manager !== 0 && (
                                                    <>
                                                        <Grid xs={4}
                                                            style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                            className={classes.cellData}>
                                                            No of Manager
                                                        </Grid>
                                                        <Grid xs={4}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {orderDropDown?.Order_Details?.[0]?.nominee_no_of_manager}
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            {
                                                orderDropDown?.Order_Details?.[0]?.nominee_no_of_secretary !== 0 && (<>
                                                    <Grid xs={4}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        No of Secretary
                                                    </Grid>
                                                    <Grid xs={4}
                                                        style={{ height: "auto", textAlign: "center" }}
                                                        className={classes.lastCellData}>
                                                        {orderDropDown?.Order_Details?.[0]?.nominee_no_of_secretary}
                                                    </Grid>
                                                </>)
                                            }
                                        </Grid>
                                        {orderDropDown?.Order_Details?.[0]?.nominee_no_of_director !== 0 && (
                                            <>
                                                <div className={classes.headingContainer} style={{ backgroundColor: "#fdaa61" }}>
                                                    <span className={classes.modalHeader}>
                                                        {" "}
                                                        {"Shareholder Detail"}{" "}
                                                    </span>
                                                </div>
                                                {
                                                    orderDropDown?.Order_Details?.[0]?.nominee_director_details.map((i) => {
                                                        const nation = orderDropDown?.Company_Residing_In?.find(
                                                            (n) => n.Country_Id === parseInt(i.Nationality)
                                                        )
                                                        return (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex" }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        <b>Name of Shareholder:</b>  <span style={{ color: "#eb6e12" }}>{i.Name}</span>
                                                                    </Grid>
                                                                    <Grid xs={9}
                                                                        style={{ height: "auto", }}
                                                                        className={classes.lastCellData}>
                                                                        <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                            
                                                                            <li style={{ width: "100%" }}><b>Availability:</b> {i.Availability}</li>
                                                                            <li style={{ width: "100%" }}> <b> Visited Status:</b> {i.Visa_Status}</li>
                                                                            <li style={{ width: "100%" }}> <b>Visited UAE:</b> {i.Visited_UAE}</li>
                                                                       
                                                                        </ul>
                                                                        <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                            <li style={{ width: "100%" }}> <b>Gender:</b> {i.Gender}</li>
                                                                            <li style={{ width: "100%" }}> <b>Nationality:</b> {nation.country_name}</li>
                                                                            <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                                        </ul>
                                                                        <ul>


                                                                            <li> <b>Represent by any POA holder:</b> {i.POA_Holder}</li>
                                                                            {
                                                                                i.POA_Holder === "Yes" && (
                                                                                    <ul><li> <b>Name of POA:</b> {i.poa_holder_name}</li></ul>
                                                                                )
                                                                            }
                                                                            <li> <b>Can Be Contact :</b> {i.can_be_contact}</li>
                                                                            {
                                                                                i.can_be_contact === "Yes" && (
                                                                                    <ul>
                                                                                        <li> <b>Primary Number:</b>{i.can_be_contact_primary_no}</li>
                                                                                        <li> <b> Email :</b> {i.can_be_contact_alternate_email}</li>
                                                                                        <li> <b>Alternate Number:</b> {i.can_be_contact_alternate_no}</li>
                                                                                    </ul>
                                                                                )}
                                                                        </ul>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    }
                                                    )
                                                }
                                            </>

                                        )
                                        }
                                        {orderDropDown?.Order_Details?.[0]?.nominee_no_of_manager !== 0 && (
                                            <>
                                                <div className={classes.headingContainer} style={{ backgroundColor: "#fdaa61" }}>
                                                    <span className={classes.modalHeader}>
                                                        {" "}
                                                        {"Manager / Director Detail"}{" "}
                                                    </span>
                                                </div>
                                                {orderDropDown?.Order_Details?.[0]?.nominee_manager_details.map((i) => {
                                                    const nation = orderDropDown?.Company_Residing_In?.find(
                                                        (n) => n.Country_Id === parseInt(i.Nationality)
                                                    )
                                                    return (
                                                        <>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex" }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid xs={3}
                                                                    style={{ height: "auto", textAlign: "center" }}
                                                                    className={classes.cellData}>
                                                                    <b>Name of Manager / Director:</b>  <span style={{ color: "#eb6e12" }}>{i.Name}</span>
                                                                </Grid>
                                                                <Grid xs={9}
                                                                    style={{ height: "auto", }}
                                                                    className={classes.lastCellData}>
                                                                    <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                        
                                                                        <li style={{ width: "100%" }}><b>Availability:</b> {i.Availability}</li>
                                                                        <li style={{ width: "100%" }}> <b> Visited Status:</b> {i.Visa_Status}</li>
                                                                        <li style={{ width: "100%" }}> <b>Visited UAE:</b> {i.Visited_UAE}</li>
                                                                   
                                                                    </ul>
                                                                    <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                        <li style={{ width: "100%" }}> <b>Gender:</b> {i.Gender}</li>
                                                                        <li style={{ width: "100%" }}> <b>Nationality:</b> {nation.country_name}</li>
                                                                        <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                                    </ul>
                                                                    <ul>


                                                                        <li> <b>Represent by any POA holder:</b> {i.POA_Holder}</li>
                                                                        {
                                                                            i.POA_Holder === "Yes" && (
                                                                                <ul><li> <b>Name of POA:</b> {i.poa_holder_name}</li></ul>
                                                                            )
                                                                        }
                                                                        <li> <b>Can Be Contact :</b> {i.can_be_contact}</li>
                                                                        {
                                                                            i.can_be_contact === "Yes" && (
                                                                                <ul>
                                                                                    <li> <b>Primary Number:</b>{i.can_be_contact_primary_no}</li>
                                                                                    <li> <b> Email :</b> {i.can_be_contact_alternate_email}</li>
                                                                                    <li> <b>Alternate Number:</b> {i.can_be_contact_alternate_no}</li>
                                                                                </ul>
                                                                            )}
                                                                    </ul>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                }
                                                )}
                                            </>
                                        )
                                        }
                                        {orderDropDown?.Order_Details?.[0]?.nominee_no_of_secretary !== 0 && (
                                            <>
                                                <div className={classes.headingContainer} style={{ backgroundColor: "#fdaa61" }}>
                                                    <span className={classes.modalHeader}>
                                                        {" "}
                                                        {"Secretary Detail"}{" "}
                                                    </span>
                                                </div>
                                                {
                                                    orderDropDown?.Order_Details?.[0]?.nominee_secretary_details.map((i) => {
                                                        const nation = orderDropDown?.Company_Residing_In?.find(
                                                            (n) => n.Country_Id === parseInt(i.Nationality)
                                                        )
                                                        return (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex" }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        <b>Name of Secretary:</b>  <span style={{ color: "#eb6e12" }}>{i.Name}</span>
                                                                    </Grid>
                                                                    <Grid xs={9}
                                                                        style={{ height: "auto", }}
                                                                        className={classes.lastCellData}>
                                                                        <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                            
                                                                            <li style={{ width: "100%" }}><b>Availability:</b> {i.Availability}</li>
                                                                            <li style={{ width: "100%" }}> <b> Visited Status:</b> {i.Visa_Status}</li>
                                                                            <li style={{ width: "100%" }}> <b>Visited UAE:</b> {i.Visited_UAE}</li>
                                                                        </ul>
                                                                        <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                            <li style={{ width: "100%" }}> <b>Gender:</b> {i.Gender}</li>
                                                                            <li style={{ width: "100%" }}> <b>Nationality:</b> {nation.country_name}</li>
                                                                            <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                                        </ul>
                                                                        <ul>

                                                                           

                                                                            <li> <b>Represent by any POA holder:</b> {i.POA_Holder}</li>
                                                                            {
                                                                                i.POA_Holder === "Yes" && (
                                                                                    <ul><li> <b>Name of POA:</b> {i.poa_holder_name}</li></ul>
                                                                                )
                                                                            }
                                                                            <li> <b>Can Be Contact :</b> {i.can_be_contact}</li>
                                                                            {
                                                                                i.can_be_contact === "Yes" && (
                                                                                    <ul>
                                                                                        <li> <b>Primary Number:</b>{i.can_be_contact_primary_no}</li>
                                                                                        <li> <b> Email :</b> {i.can_be_contact_alternate_email}</li>
                                                                                        <li> <b>Alternate Number:</b> {i.can_be_contact_alternate_no}</li>
                                                                                    </ul>
                                                                                )}
                                                                        </ul>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    }
                                                    )
                                                }
                                            </>
                                        )
                                        }
                                    </>
                                )
                            }

                            {
                                (orderDropDown?.Order_Details?.[0]?.formation_type !== 3) && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                {"Manager Details"}{" "}
                                            </span>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                No of Manager
                                            </Grid>
                                            <Grid xs={9}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                {orderDropDown?.Order_Details?.[0]?.no_of_manager}
                                            </Grid>

                                        </Grid>
                                        {
                                            orderDropDown?.Order_Details?.[0]?.manager_details.map((i) => {
                                                const nation = orderDropDown?.Company_Residing_In?.find(
                                                    (n) => n.Country_Id === parseInt(i.Nationality)
                                                )
                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid xs={3}
                                                                style={{ height: "auto", textAlign: "center" }}
                                                                className={classes.cellData}>
                                                                <b>Name of Manager:</b>  <span style={{ color: "#eb6e12" }}>{i.Name}</span>
                                                            </Grid>
                                                            <Grid xs={9}
                                                                style={{ height: "auto", }}
                                                                className={classes.lastCellData}>
                                                                <ul style={{ display: "flex", width: "100%", margin: "0px" }}>
                                                                    <li style={{ width: "100%" }}> <b>Percentage:</b> {i.Percentage}%</li>
                                                                    <li style={{ width: "100%" }}><b>Availability:</b> {i.Availability}</li>
                                                                    <li style={{ width: "100%" }}> <b> Visited Status:</b> {i.Visa_Status}</li>
                                                                </ul>
                                                                <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                    <li style={{ width: "100%" }}> <b>Gender:</b> {i.Gender}</li>
                                                                    <li style={{ width: "100%" }}> <b>Nationality:</b> {nation.country_name}</li>
                                                                    <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                                </ul>
                                                                <ul>

                                                                    <li style={{ width: "100%" }}> <b>Visited UAE:</b> {i.Visited_UAE}</li>
                                                                    <li style={{ width: "100%" }}> <b>Investor Visa:</b> {i.Investor_Visa}</li>

                                                                    {
                                                                        i.Investor_Visa === "Yes" && (
                                                                            <>
                                                                                <ul style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                                    <li> <b>Position:</b> {i.Position}</li>
                                                                                    <li>
                                                                                        <b>Visa Application:</b> {i.Visa_Application}
                                                                                    </li>
                                                                                    <li>
                                                                                        <b>Security approval:</b> {i.Security_Approval}
                                                                                    </li>
                                                                                    <li> <b>Degree Certificate: </b>{i.Degree_Certificate}</li>
                                                                                    <li> <b> Salary bifurcation:</b> {i.Salary_Bifurcation}</li>
                                                                                    {i.Salary_Bifurcation === "Salary Detail" && (
                                                                                        <ul>
                                                                                            <li> <b> Basic:</b> {i.Salary_Bifurcation_Basic}</li>
                                                                                            <li> <b>Accommodation:</b> {i.Salary_Bifurcation_Accommodation}</li>
                                                                                            <li> <b> Transportation:</b> {i.Salary_Bifurcation_Transportation}</li>
                                                                                            <li> <b> Total Salary:</b> {i.Salary_Bifurcation_Total_Salary}</li>
                                                                                        </ul>
                                                                                    )}

                                                                                </ul>

                                                                            </>
                                                                        )
                                                                    }
                                                                    <li> <b>Represent by any POA holder:</b> {i.POA_Holder}</li>
                                                                    {
                                                                        i.POA_Holder === "Yes" && (
                                                                            <ul><li> <b>Name of POA:</b> {i.poa_holder_name}</li></ul>
                                                                        )
                                                                    }
                                                                    <li> <b>Can Be Contact :</b> {i.can_be_contact}</li>
                                                                    {
                                                                        i.can_be_contact === "Yes" && (
                                                                            <ul>
                                                                                <li> <b>Primary Number:</b>{i.can_be_contact_primary_no}</li>
                                                                                <li> <b> Email :</b> {i.can_be_contact_alternate_email}</li>
                                                                                <li> <b>Alternate Number:</b> {i.can_be_contact_alternate_no}</li>
                                                                            </ul>
                                                                        )}


                                                                </ul>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            )
                                        }
                                    </>
                                )
                            } */}
                            <div className={classes.headingContainer}>
                                <span className={classes.modalHeader}>
                                    {" "}
                                    {"Scope of Work (with time frame)"}{" "}
                                </span>
                            </div>
                            {
                               orderDropDown?.Order_Details?.[0]?.formation_type === 4 && (
                                <>
                                 <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Scope Of Work
                                            </Grid>
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                QTY
                                            </Grid>
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                Time Frame
                                            </Grid>
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                Description
                                            </Grid>
                                        </Grid>

                                    {orderDropDown?.Order_Details?.[0]?.scope_of_work_details_pro?.map((i) => {
                                        return(
                                            <>
                                            <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex" }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Quantity}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.lastCellData}>
                                                                        {i?.SOW_Time_Frame}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.lastCellData}>
                                                                        {i?.SOW_Description}
                                                                    </Grid>
                                                                </Grid>
                                            </>
                                        )
                                    })}

                                </>
                               ) 
                            }

                            {
                                orderDropDown?.Order_Details?.[0]?.formation_type === 1 && (
                                    <>
                                    {
                                         orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.length === 1
                                         && orderDropDown?.Order_Details?.[0]?.scope_of_work_details[0]?.SOW === "Employee Visa" ?
                                         null
                                         :(
                                            <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Scope Of Work
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Quantity
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                Description
                                            </Grid>
                                        </Grid>
                                         )
                                    }
                                       
                                        {
                                            orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.map((i) => {

                                                return (
                                                    <>
                                                        {
                                                            ["License", "Employee Visa"].includes(i.SOW) ? null : (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex" }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={4}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW}
                                                                    </Grid>
                                                                    <Grid xs={4}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Quantity}
                                                                    </Grid>
                                                                    <Grid xs={4}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.lastCellData}>
                                                                        {i?.SOW_Description}
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        }

                                                    </>

                                                )
                                            })
                                        }
                                        {
                                            orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.find((i) => i.SOW === "License") && (<>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ display: "flex", backgroundColor: "#fdaa61" }}
                                                    className={classes.followUpFormInnerGridLeft}
                                                >
                                                    <Grid xs={3}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Scope Of Work
                                                    </Grid>
                                                    <Grid xs={3}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Quantity
                                                    </Grid>
                                                    <Grid xs={3}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Issuing Authority
                                                    </Grid>
                                                    <Grid xs={3}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Additional approval
                                                    </Grid>
                                                    {
                                                        orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.find((i) => i.License_Additional_Approval === "Yes") && (
                                                            <Grid xs={3}
                                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                                className={classes.lastCellData}>
                                                                Name Of Approval
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                                {
                                                    orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.map((i) => {

                                                        return (
                                                            <>
                                                                {
                                                                    i.SOW === "License" && (
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            style={{ display: "flex" }}
                                                                            className={classes.followUpFormInnerGridLeft}
                                                                        >
                                                                            <Grid xs={3}
                                                                                style={{ height: "auto", textAlign: "center" }}
                                                                                className={classes.cellData}>
                                                                                {i?.SOW}
                                                                            </Grid>
                                                                            <Grid xs={3}
                                                                                style={{ height: "auto", textAlign: "center" }}
                                                                                className={classes.cellData}>
                                                                                {i?.SOW_Quantity}
                                                                            </Grid>
                                                                            <Grid xs={3}
                                                                                style={{ height: "auto", textAlign: "center" }}
                                                                                className={classes.cellData}>
                                                                                {i.License_Issuing_Authority}
                                                                            </Grid>
                                                                            <Grid xs={3}
                                                                                style={{ height: "auto", textAlign: "center" }}
                                                                                className={classes.cellData}>
                                                                                {i.License_Additional_Approval}
                                                                            </Grid>
                                                                            {
                                                                                i.License_Additional_Approval === "Yes" && (
                                                                                    <Grid xs={3}
                                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                                        className={classes.lastCellData}>
                                                                                        {

                                                                                            i.License_Additional_Approval_Name?.map((i) => {
                                                                                                return (
                                                                                                    <li>{i}</li>
                                                                                                )
                                                                                            })

                                                                                        }
                                                                                    </Grid>
                                                                                )
                                                                            }

                                                                        </Grid>
                                                                    )
                                                                }

                                                            </>

                                                        )
                                                    })
                                                }

                                            </>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                orderDropDown?.Order_Details?.[0]?.formation_type === 2 && (
                                    <>
                                    {
                                        orderDropDown?.Order_Details?.[0]?.scope_of_work_details_fz?.length === 1
                                        && orderDropDown?.Order_Details?.[0]?.scope_of_work_details_fz[0]?.SOW === "Employee Visa" ?
                                        null
                                        :(
                                            <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Scope Of Work
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Quantity
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Cost
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Time Frame
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                Description
                                            </Grid>
                                        </Grid>
                                        )
                                    }
                                       
                                        {
                                            orderDropDown?.Order_Details?.[0]?.scope_of_work_details_fz?.map((i) => {

                                                return (
                                                    <>
                                                        {
                                                            i.SOW === "Employee Visa" ? null :
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex" }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Quantity}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Cost}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Time_Frame}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.lastCellData}>
                                                                        {i?.SOW_Description}
                                                                    </Grid>
                                                                </Grid>
                                                        }

                                                    </>

                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                            {
                                orderDropDown?.Order_Details?.[0]?.formation_type === 3 && (
                                    <>
                                        {orderDropDown?.Order_Details?.[0]?.scope_of_work_details_offshore?.length === 1
                                            && orderDropDown?.Order_Details?.[0]?.scope_of_work_details_offshore[0]?.SOW === "Employee Visa" ?
                                            null
                                            : (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ display: "flex" }}
                                                    className={classes.followUpFormInnerGridLeft}
                                                >
                                                    <Grid xs={4}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Scope Of Work
                                                    </Grid>
                                                    <Grid xs={4}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Quantity
                                                    </Grid>
                                                    <Grid xs={4}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.cellData}>
                                                        Time Frame
                                                    </Grid>
                                                    <Grid xs={4}
                                                        style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                        className={classes.lastCellData}>
                                                        Description
                                                    </Grid>
                                                </Grid>
                                            )}

                                        {
                                            orderDropDown?.Order_Details?.[0]?.scope_of_work_details_offshore?.map((i) => {

                                                return (
                                                    <>
                                                        {
                                                            i.SOW === "Employee Visa" ? null :
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    style={{ display: "flex" }}
                                                                    className={classes.followUpFormInnerGridLeft}
                                                                >
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Quantity}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.cellData}>
                                                                        {i?.SOW_Time_Frame}
                                                                    </Grid>
                                                                    <Grid xs={3}
                                                                        style={{ height: "auto", textAlign: "center" }}
                                                                        className={classes.lastCellData}>
                                                                        {i?.SOW_Description}
                                                                    </Grid>
                                                                </Grid>
                                                        }

                                                    </>

                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                            {
                                orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.find((i) => i.SOW === "Employee Visa") && (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex", backgroundColor: "#fdaa61" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Scope of Work
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {orderDropDown?.Order_Details?.[0]?.scope_of_work_details?.find((i) => i.SOW === "Employee Visa") ? "Employee Visa" : "undefined"}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                No of Employee
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {orderDropDown?.Order_Details?.[0]?.number_of_employee}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                No of Known Employee
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                {orderDropDown?.Order_Details?.[0]?.number_of_known_employee
                                                }
                                            </Grid>

                                        </Grid>
                                        {
                                            orderDropDown?.Order_Details?.[0]?.SOW_employee_details?.map((i) => {

                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid xs={3}
                                                                style={{ height: "auto", textAlign: "center" }}
                                                                className={classes.cellData}>
                                                                <b>Name of Employee:</b>  <span style={{ color: "#eb6e12" }}>{i.Name}</span>
                                                            </Grid>
                                                            <Grid xs={9}
                                                                style={{ height: "auto", }}
                                                                className={classes.lastCellData}>
                                                                <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                    <li style={{ width: "100%" }}> <b> Visa Status:</b> {i.Visa_Status}</li>
                                                                    <li style={{ width: "100%" }}> <b>Degree Certificate: </b>{i.Degree_Certificate}</li>

                                                                    <li style={{ width: "100%" }}><b>Visa Application:</b> {i.Visa_Application} </li>
                                                                </ul>
                                                                <ul style={{ display: "flex", justifyContent: "initial", width: "100%", margin: "0px" }} >
                                                                    <li style={{ width: "100%" }}> <b>Date of Birth:</b> {i.Birth_Date}</li>
                                                                    <li style={{ width: "100%" }}><b>Security approval:</b> {i.Security_Approval}
                                                                    </li>
                                                                    {
                                                                        i.Designation !== "Confirm" && (
                                                                            <li style={{ width: "100%" }}><b>Designation:</b> {i.Security_Approval}</li>

                                                                        )}

                                                                </ul>
                                                                {
                                                                    i.Designation === "Confirm" && (
                                                                        <>
                                                                            <ul>
                                                                                <li style={{ width: "100%" }}><b>Designation:</b> {i.Security_Approval}
                                                                                </li>
                                                                                {
                                                                                    i.Designation === "Confirm" && (
                                                                                        <>
                                                                                            <ul>
                                                                                                <li> <b> Designation Name:</b> {i.Designation_Confirm}</li>
                                                                                            </ul>
                                                                                        </>
                                                                                    )
                                                                                }

                                                                            </ul>
                                                                        </>
                                                                    )
                                                                }

                                                                <ul style={{ display: "flex", width: "100%", margin: "0px" }} >
                                                                    <li> <b> Salary bifurcation:</b> {i.Salary_Bifurcation}</li>
                                                                    {i.Salary_Bifurcation === "Salary Detail" && (
                                                                        <ul>
                                                                            <li> <b> Basic:</b> {i.Salary_Bifurcation_Basic}</li>
                                                                            <li> <b>Accommodation:</b> {i.Salary_Bifurcation_Accommodation}</li>
                                                                            <li> <b> Transportation:</b> {i.Salary_Bifurcation_Transportation}</li>
                                                                            <li> <b> Total Salary:</b> {i.Salary_Bifurcation_Total_Salary}</li>
                                                                        </ul>)}
                                                                </ul>

                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            }
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                ![2, 3, 4].includes(orderDropDown?.Order_Details?.[0]?.formation_type) && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                {"Office Detail"}{" "}
                                            </span>
                                        </div>
                                        <Grid container>

                                            {
                                                ["Flyingcolour Sharing Office", "Third Party"].includes(orderDropDown?.Order_Details?.[0]?.office) ? (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex", }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                <Grid
                                                                    xs={12}
                                                                    style={{ fontWeight: "600", height: "auto" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    Office
                                                                </Grid>
                                                                <Grid
                                                                    xs={12}
                                                                    style={{ height: "auto" }}
                                                                    className={classes.cellData}
                                                                >
                                                                    {orderDropDown?.Order_Details?.[0]?.office}
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                orderDropDown?.Order_Details?.[0]?.office === "Flyingcolour Sharing Office" && (
                                                                    <>
                                                                        <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                            <Grid
                                                                                xs={12}
                                                                                style={{ fontWeight: "600", height: "auto" }}
                                                                                className={classes.cellData}
                                                                            >
                                                                                Office Number
                                                                            </Grid>
                                                                            <Grid
                                                                                xs={12}
                                                                                style={{ height: "auto" }}
                                                                                className={classes.cellData}
                                                                            >
                                                                                {orderDropDown?.Order_Details?.[0]?.office_number}
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                            <Grid
                                                                                xs={12}
                                                                                style={{ fontWeight: "600", height: "auto" }}
                                                                                className={classes.cellData}
                                                                            >
                                                                                MOU
                                                                            </Grid>
                                                                            <Grid
                                                                                xs={12}
                                                                                style={{ height: "auto" }}
                                                                                className={classes.cellData}
                                                                            >
                                                                                {orderDropDown?.Order_Details?.[0]?.office_mou}
                                                                            </Grid>
                                                                        </Grid>

                                                                    </>
                                                                )}
                                                            {
                                                                orderDropDown?.Order_Details?.[0]?.office === "Third Party" && (
                                                                    <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ fontWeight: "600", height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            Name of CP Member
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={12}
                                                                            style={{ height: "auto" }}
                                                                            className={classes.cellData}
                                                                        >
                                                                            {cpMember?.Emp_First_Name !== undefined ? `${cpMember?.Emp_First_Name} ${cpMember?.Emp_Last_Name}` : "undefined"}
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            }
                                                            <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                <Grid
                                                                    xs={12}
                                                                    style={{ fontWeight: "600", height: "auto" }}
                                                                    className={classes.lastCellData}
                                                                >
                                                                    Remarks
                                                                </Grid>
                                                                <Grid
                                                                    xs={12}
                                                                    style={{ height: "auto" }}
                                                                    className={classes.lastCellData}
                                                                >
                                                                    {orderDropDown?.Order_Details?.[0]?.remarks}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ display: "flex" }}
                                                            className={classes.followUpFormInnerGridLeft}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                style={{ display: "flex", }}
                                                                className={classes.followUpFormInnerGridLeft}
                                                            >
                                                                <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        Office
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.cellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details?.[0]?.office}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid xs={6} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ fontWeight: "600", height: "auto" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        Remarks
                                                                    </Grid>
                                                                    <Grid
                                                                        xs={12}
                                                                        style={{ height: "auto" }}
                                                                        className={classes.lastCellData}
                                                                    >
                                                                        {orderDropDown?.Order_Details?.[0]?.remarks}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                        </Grid>
                                    </>
                                )
                            }
                            {
                                ![3, 4].includes(orderDropDown?.Order_Details?.[0]?.formation_type) && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                {"Payment Description"}{" "}
                                            </span>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Govt. Received Amount
                                            </Grid>
                                            <Grid xs={2}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {orderDropDown?.Order_Details?.[0]?.paymentDescriptionGovtAmount}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                FC SC Received Amount
                                            </Grid>
                                            <Grid xs={3}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {orderDropDown?.Order_Details?.[0]?.paymentDescriptionFCAmount}
                                            </Grid>
                                            <Grid xs={3}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Payment terms
                                            </Grid>
                                            <Grid xs={3}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                {orderDropDown?.Order_Details?.[0]?.paymentDescriptionPaymentTerms}
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }


                            <div className={classes.headingContainer}>
                                <span className={classes.modalHeader}>
                                    {" "}
                                    {"Upload Document Detail"}{" "}
                                </span>
                            </div>
                            <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                className={classes.followUpFormInnerGridLeft}
                            >
                                <Grid xs={4}
                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                    className={classes.cellData}>
                                    Upload Type
                                </Grid>
                                <Grid xs={4}
                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                    className={classes.cellData}>
                                    Document Particular
                                </Grid>
                                <Grid xs={4}
                                    style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                    className={classes.lastCellData}>
                                    Document/File
                                </Grid>
                            </Grid>
                            {
                                orderDropDown?.Order_Details?.[0]?.upload_doc_details?.map((i) => {
                                    const docType = orderDropDown.Document_Type?.find(
                                        (n) => n.Id === parseInt(i?.Document_Type)
                                    );
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {docType?.Doc_Name}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                {i.Particular}
                                            </Grid>
                                            <Grid xs={4}
                                                style={{ height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                <button className="btn-view" onClick={() => window.open(i.uploadDoc)}>View</button>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                            {/* Missing document  */}
                            {
                                orderDropDown?.Order_Details?.[0]?.missing_documents.length !== 0 && (
                                    <>
                                        <div className={classes.headingContainer}>
                                            <span className={classes.modalHeader}>
                                                {" "}
                                                {"Missing Document Detail"}{" "}
                                            </span>
                                        </div>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid xs={6}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.cellData}>
                                                Upload Type
                                            </Grid>
                                            <Grid xs={6}
                                                style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                                                className={classes.lastCellData}>
                                                Document Particular
                                            </Grid>
                                        </Grid>
                                        {
                                            orderDropDown?.Order_Details?.[0]?.missing_documents?.map((i) => {
                                                const docType = orderDropDown.Document_Type?.find(
                                                    (n) => n.Id === parseInt(i.Document_Type)
                                                );
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: "flex" }}
                                                        className={classes.followUpFormInnerGridLeft}
                                                    >
                                                        <Grid xs={6}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.cellData}>
                                                            {docType?.Doc_Name}
                                                        </Grid>
                                                        <Grid xs={6}
                                                            style={{ height: "auto", textAlign: "center" }}
                                                            className={classes.lastCellData}>
                                                            {i.Particular}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                            <br />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
};

export default withStyles(previewFormStyles)(ViewOrderForm);