
import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import { FaEye, FaPlus } from "react-icons/fa";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
  import {
    DataContext,
    SupportDataContext,
  } from "../../../../../provider/context";
  import "../../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import EditIcon from "@mui/icons-material/Edit";
  import { getLocalData } from "../../../../../services/services";
  import axios from "axios";
  import { Grid } from "@material-ui/core";
  import { Button } from "@mui/material";
import AddScopeOfWorkOrderTemplateListing from "./AddScopeOfWorkOrderTemplateListing";
import AddScopeOfWorkOrderSubTemplateListing from "./AddScopeOfWorkOrderSubTemplateListing";
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const AddScopeOfWork = ({
    apiData,
    upperIcons,
    lowerIcons,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    fitlerBadge,
  }) => {
    const {
      operationInfo,
    } = useContext(SupportDataContext);
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
        {
           operationInfo?.template_category === "Order Sub Template" ? (<AddScopeOfWorkOrderSubTemplateListing />) : (
            <AddScopeOfWorkOrderTemplateListing />
           ) 
        }
      </div>
    );
  };
  
  export default AddScopeOfWork;
  