import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import modalPopperStyles from "../styles/ModalPopperStyles";
import { Modal } from "@mui/material";
import Error from "../../../listing/listingTable/ListingExpandableTable/modal/Error";
import LoaderClock from "../../../lodar/LoaderClock";
import { SupportDataContext } from "../../../../provider/context";
import PDNOFApprovalForm from "./PDNOFApproval/PDNOFApprovalForm";
import ViewOrderOnProject from "./ProjectList/ViewOrderOnProject";
import ViewProjectDescription from "./ProjectList/ViewProjectDescription";
import ViewOrderNOF from "./NOF/ViewOrderNOF";
import ViewOfGroupCompanyYes from "./NOF/ViewOfGroupCompanyYes";
import AddRevisedNOFForm from "./ProjectList/AddRevisedNOFForm";
import ChangeOrderSequenceForm from "./NOF/ChangeOrderSequenceForm";


const ModalPopper = () => {
  const { modalOpen, buttonText, error } = useContext(SupportDataContext);

  return (
    <>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {buttonText === "PD NOF Approval" ? (
          <PDNOFApprovalForm/>
         ) 
         : buttonText === "View Order On Project" ? (
          <ViewOrderOnProject/>
         ) : buttonText === "View Order On NOF" ? (
          <ViewOrderNOF/>
         ) : buttonText === "View for Group Company" ? (
          <ViewOfGroupCompanyYes/>
         )
         : buttonText === "error" ? (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        ) : buttonText === "View Project Description" ? (
          <ViewProjectDescription />
        ) : buttonText === "Add Revised NOF Form" ? (
          <AddRevisedNOFForm />
        ) 
        : (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        )}
      </Modal>
    </>
  );
};

export default withStyles(modalPopperStyles)(ModalPopper);
