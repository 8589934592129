import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import { FaEye, FaPlus } from "react-icons/fa";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
  import {
    DataContext,
    SupportDataContext,
  } from "../../../../../provider/context";
  import "../../Styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import EditIcon from "@mui/icons-material/Edit";
  import { getLocalData } from "../../../../../services/services";
  import axios from "axios";
  import { Grid } from "@material-ui/core";
  import { Button } from "@mui/material";
import moment from "moment";
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const RefundedListing = ({
    apiData,
    upperIcons,
    lowerIcons,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setEditModalPopper,
      message,
      severity,
      isOpen,
      setIsOpen,
      operationInfo,
      setFilterOrders,
      handleModalTableClose,
    } = useContext(SupportDataContext);
    const [modalValue, setModalValue] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const gridRef = useRef();
  
    useEffect(() => {
      getRefundedListDataApi(operationInfo?.requisition_no, operationInfo?.order_id);
    }, [operationInfo?.requisition_no]);
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    const [refundedListData, setRefundedListData] = useState(null);
    const getRefundedListDataApi = async (requisition_no, order_id) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      try {
        const url = `https://flyingcolour.online/fcapp/pro_head/RequisitionRefundedDetail/?user_type=${user_type}&emp_id=${emp_id}&requisition_no=${
            requisition_no
        }&factory_id=${factory_id}&order_no=${order_id}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setRefundedListData(res?.data?.results?.Refunded_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
  
    const columnDefs = [
      {
        headerName: "S#",
        field: "Serial_Number",
        // cellRenderer: "agGroupCellRenderer",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          textAlign: "left",
        },
        width: 70,
        pinned: "left",
        filter: false,
        cellRenderer: (params) => {
          return (
            <p>{params?.rowIndex + 1}</p>
          );
        },
      },
      {
        headerName: "Company Name",
        field: "company_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 250,
      },
   
      {
        headerName: "Order Id",
        field: "order_id",
        filter: "agTextColumnFilter",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 180,
      },
      {
        headerName: "Requisition No",
        field: "requisition_auto_id",
        filter: "agTextColumnFilter",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 160,
      },
      {
        headerName: "Requisition Date",
        field: "requisition_date",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 250,
        cellRenderer: (params) => {
          return (
              <>
                  {moment(params?.data?.requisition_date).format("DD-MM-YYYY H:mm:ss")}
              </>
          );
      },
      },
      {
        headerName: "Refund Req Status",
        field: "refund_req_status",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 250,
      },
      {
        headerName: "Refund Req Date",
        field: "refund_req_date",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 150,
        cellRenderer: (params) => {
          return (
              <>
                  {moment(params?.data?.refund_req_date).format("DD-MM-YYYY hh:mm:ss")}
              </>
          );
      },
      },
    
      {
        headerName: "Refund Req Remarks",
        field: "refund_req_remarks",
        filter: "agTextColumnFilter",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        minWidth: 300,
        flex: 1,
        cellRenderer: (params) => {
          return (
            <div
              style={{
                padding: "0 5px",
                margin: "0 2rem 1rem 0",
              }}
              dangerouslySetInnerHTML={{ __html: params?.data?.refund_req_remarks }}
            />
          );
        },
      },
      {
        headerName: "Refunded By",
        field: "refunded_by",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 170,
      },
      {
        headerName: "Refunded by User Type",
        field: "refunded_by_user_type",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 200,
      },
 
    
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue.split(" ");
              if (dateAsString == null) {
                return 0;
              }
              const dateParts = dateAsString[0].split("-");
              const day = Number(dateParts[2]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
          },
        },
      };
    }, []);
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
        <Grid container>
          <Grid item sm={12} md={8} xs={12} style={{ paddingRight: "1rem" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#eb6e12",
                fontFamily: "Times New Roman !important",
                color: "#fff",
                minHeight: "37px",
                margin: "10px 0 0 10px",
              }}
        
            >
              Refunded List
            </Button>
  
            <Button
              onClick={() => {
                handleModalTableClose();
                // handleHide()
              }}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#fff",
                color: "#eb6e12",
                margin: "10px 0 0 10px",
                height: "37px",
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={apiData?.length}
        />
        <div
          style={{ height: "400px", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={refundedListData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            // onSelectionChanged={onRowSelected}
            components={components}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            rowHeight={90}
            pagination={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
  
        </Snackbar>
      </div>
    );
  };
  
  export default RefundedListing;
  