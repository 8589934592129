import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import {DataContext, SupportDataContext} from "../../../../provider/context";
  import "../Styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import {
    handleDefaultFilters,
    handleFilters,
    getTotalLength,
    getTotalLengthForSavedFilters,
  } from "../../../../utils/Filters";
  import {FaAdn, FaEye} from "react-icons/fa";
  import MenuBookIcon from '@mui/icons-material/MenuBook';
  import EditIcon from '@mui/icons-material/Edit';
  import ModalPopper from "../Modals/ModalPopper";
  import { Button } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  import { css } from "@emotion/react"
  import AlarmIcon from '@mui/icons-material/Alarm';
  import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
  import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
  import PreviewIcon from '@mui/icons-material/Preview';
  import FolderSharedIcon from '@mui/icons-material/FolderShared';

  



  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const ScheduleTrainingTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    getFreezesOrderNoFutureAlertsListingDataApi,
    ScrollTop,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setSelected,
      handleModalOpen,
      message, severity,
      isOpen, setIsOpen,
      setEditModalPopper,
      setViewFollowUp,
      handleChangeTab,
      getScheduleTrainingDataApi, scheduleTrainingData,
    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
  
    const fullName = `${localStorage.getItem( "Employee_First_Name" )} ${localStorage.getItem("Employee_Last_Name")}`;

    useEffect(() => {
      handleListingSavedSearch();
      getScheduleTrainingDataApi();
    }, []);

    console.log("fullName", fullName)

    const filteredSchedules = scheduleTrainingData?.Training_Schedule_List?.filter(schedule => {
      return schedule?.trainee_detail?.some(trainee => trainee?.Trainee_Name === fullName);
    });
    const completedNotifiedCourses = filteredSchedules?.filter(course => 
      course?.status === "Notified" || course?.status === "Completed"
    );
    console.log("completedNotifiedCourses", completedNotifiedCourses);


    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue.split(" ");
        if (dateAsString == null) {
          return -1;
        }
        if (dateAsString == "") {
          return -1;
        }
        const dateParts = dateAsString[0].split("-");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };

    const actionBtn = (value) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >        
            <CustomIconButton
            width={"auto"}
            title={"View Training Attendees"}
            icon={<FolderSharedIcon style={{fontSize: "25px", width: "18px"}}/>}
            onClick={() => {
              setButtonText("View Training Attendees");
              handleModalTableOpen("View Training Attendees", value.data);
              setOperationInfo(value.data);
              ScrollTop();
              }}
            />
            <CustomIconButton
            width={"auto"}
            title={"View Training Status Log"}
            icon={<PreviewIcon style={{fontSize: "18px", width: "18px"}}/>}
            onClick={() => {
              setButtonText("View Training Status Log");
              handleModalTableOpen("View Training Status Log", value.data);
              setOperationInfo(value.data);
              ScrollTop();
              }}
            />
          </div>
        </>
      );
    };
  
    const columnDefs = [
        {
          headerName: "S #",
          field: "Serial_Number",
          valueGetter: "node.rowIndex + 1",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
          minWidth: 70,
          maxWidth: 80,
          pinned: "left",
          filter:false
        },
        {
          headerName: "Action",
          field: "action",
          filter: false,
          minWidth: 140,
          pinned: "left",
          cellStyle: { border: "1px solid #fff", paddingTop: 0 },
          cellRenderer: actionBtn,
        },
        {
          headerName: "Course Title",
          field: "course_title",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 210,
          pinned: "left",
          cellRenderer: (params) => {
            const myData = params?.data.course_title;
          return (
            <>
            {
                myData.map((item) => {
                   return <p>{item}</p>
                })
            }
            </>
          );
        },
        },
        {
          headerName: "Course Level",
          field: "course_level",
          filter: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff",  overflowY:'auto'},
          width: 160,
          pinned: "left",
        },
        {
          headerName: "Training Date",
          field: "schedule_dateTime",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
          filter: 'agDateColumnFilter',
          filterParams: filterParams,
          cellRenderer: (params) => {
            return (
              <>
                {params?.data?.schedule_dateTime === "" || params?.data?.schedule_dateTime === null ? "" :
                moment(params?.data?.schedule_dateTime).format("DD-MM-YYYY hh:mm:ss")}
              </>
            );
          },
          width: 200,
        },
        {
          headerName: "Training Duration",
          field: "training_duration",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY:'auto'},
          width: 170,
        },
        {
          headerName: "Training Type",
          field: "meeting_type",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY:'auto'},
          width: 170,
      },

      {
          headerName: "Training Place",
          field: "training_place",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY:'auto'},
          width: 200,
          cellRenderer: (params) => {
            return (
              <>
                {params?.data?.training_place}
              </>
            );
          },
          width: 200,
        },
        {
            headerName: "Trainer Name",
            field: "trainer_name",
            filter: 'agTextColumnFilter',
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
            width: 260,
        },
        {
          headerName: "Status",
          field: "status",
          filter: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
          width: 160,
       },
    {
      headerName: "Training Remarks",
      field: "remarks",
      filter: 'agTextColumnFilter',
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
      width: 260,
    },
    {
        headerName: "Created By ",
        field: "created_by",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
        width: 200,
        filter: true,
    },
    {
        headerName: "Created Date",
        field: "created_date",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
        filter: 'agDateColumnFilter',
        filterParams: filterParams,
        cellRenderer: (params) => {
          return (
            <>
              {params?.data?.created_date === "" || params?.data?.created_date === null ? "" :
              moment(params?.data?.created_date).format("DD-MM-YYYY hh:mm:ss")}
            </>
          );
        },
        width: 200,
      },
        {
          headerName: "Created By Role",
          field: "created_by_role",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 180,
        },
        {
            headerName: "Updated By ",
            field: "updated_by",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
            width: 200,
            filter: true,
        },
        {
            headerName: "Updated Date",
            field: "updated_date",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto"},
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            cellRenderer: (params) => {
              return (
                <>
                  {params?.data?.updated_date === "" || params?.data?.updated_date === null ? "" :
                  moment(params?.data?.updated_date).format("DD-MM-YYYY hh:mm:ss")}
                </>
              );
            },
            width: 200,
          },
       
        {
          headerName: "Updated By Role",
          field: "updated_by_role",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 180,
        },
      ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = cellValue.split("/");
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      };
    }, []);
  
    const onRowSelected = (event) => {
      const rowData = event.api.getSelectedNodes()[0]?.data;
      setSelected([rowData]);
    };
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    const handleSearch = (searchName, value, searchKey) => {
      handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
    };
  
    const filters = [
      {
        id: 1,
        title: "Default",
        value: null,
        key: null,
      },
      {
        id: 2,
        title: "Upcoming",
        value: "Notified",
        key: "status",
      },
      {
        id: 3,
        title: "Completed",
        value: "Completed",
        key: "status",
      },
     
     
    ];

    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
        {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={completedNotifiedCourses}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {completedNotifiedCourses?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(completedNotifiedCourses, val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleDefaultFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.title,
                          val.value,
                          val.key
                        )
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const keyss = val.Search_Filter.replace(
                    /(.*?)=(.*?)(?:\|\||$)/g
                  );
                  const getNewKey = keyss
                    ?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "");
                  const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                  const data = JSON.parse(keyss);
                  let myValuesArr = [];
                  let myKeysArr = [];
                  // Iterate over keys and get values
                  for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                      const values = data[key].values;
                      myValuesArr.push(values);
                      myKeysArr.push(key);
                    }
                  }
                  const valuesArray = [].concat(...myValuesArr);
                  let resultArray = "";
                  if (getNewKe2) {
                    const resultString = getNewKe2[1];
                    resultArray = resultString.split(",");
                  }
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLengthForSavedFilters(
                        completedNotifiedCourses,
                        myValuesArr,
                        myKeysArr
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={completedNotifiedCourses?.length}
        />
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={completedNotifiedCourses}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 90}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <ModalPopper/>
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} >
        <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      </div>
    );
  };
  
  export default ScheduleTrainingTable;
  