import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../Styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModalPopper";
import { handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
import PreviewIcon from "@mui/icons-material/Preview";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AcceptRequistion from "../Modals/PRORequistionModals/AcceptRequistion";
import RejectRequistion from "../Modals/PRORequistionModals/RejectRequistion";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Grid } from "@material-ui/core";
import ExecutedByForm from "../Modals/PRORequistionModals/ExecutedByForm";
import ReAssignTaskForm from "../Modals/PRORequistionModals/ReAssignTaskForm";
import ExtendedPROHead from "../Modals/PRORequistionModals/ExtendedPROHead";
import WorkDoneForm from "../Modals/PRORequistionModals/WorkDoneForm";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PRORequistionListing = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    setSalesInfo,
    setIsOpen,
    isOpen,
    message,
    severity,
    setIsCompleteOrderBtnDisable
  } = useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [reqFromDate, setReqFromDate] = useState("");
  const [reqToDate, setReqToDate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    handleListingSavedSearch();
  }, []);

  useEffect(() => {
    fetchListingDataFromApi(reqFromDate, reqToDate, gridRef);
  }, [reqFromDate, reqToDate]);
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const actionBtnRenderer = (params) => {
    return (
      <>
        {(Number(params?.data?.req_amount) === 0 && params?.data?.PROHead_Status === "Approved" && params?.data?.PRO_Status === "Overdue")
        || (Number(params?.data?.req_amount) === 0 && params?.data?.PROHead_Status === "Approved" && params?.data?.PRO_Status === "Underprocess")
        || (Number(params?.data?.req_amount) > 0 && params?.data?.PROHead_Status === "Approved" &&  params?.data?.Account_Status === "Approved" && params?.data?.PRO_Status === "Overdue") 
        || (Number(params?.data?.req_amount) > 0 && params?.data?.PROHead_Status === "Approved" &&  params?.data?.Account_Status === "Approved" && params?.data?.PRO_Status === "Underprocess")
        ? (
          <>
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue("Executed By");
                setOpenModal(true);
              }}
              title={"Executed By"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Executed By</>}
            />
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue("Re-Assign Task");
                setOpenModal(true);
              }}
              title={"Re-Assign Task"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Re-Assign Task</>}
            />
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("PRO Work Done")
                setIsCompleteOrderBtnDisable(false)
              
              }}
              title={"Work Done"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Work Done</>}
            />
                   <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Logs")
                setIsCompleteOrderBtnDisable(false);
              }}
              title={"Logs"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Logs</>}
            />
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("Requistion Extended by PRO Head")
                setIsCompleteOrderBtnDisable(false);
              }}
              title={"Requistion Extended by PRO Head"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Requisition Extended by PRO Head</>}
            />
               <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Document")
              }}
              title={"View Document"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>View Document</>}
            />
          
    
          </>
        ) : (Number(params?.data?.req_amount) === 0 && params?.data?.PROHead_Status === "Rejected")
        || (Number(params?.data?.req_amount) > 0 && params?.data?.PROHead_Status === "Approved" &&  params?.data?.Account_Status === "Rejected") ? (
          <>
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue("accept");
                setOpenModal(true);
              }}
              title={"Re-Accept"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Re-Accept</>}
            />
    <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Logs")
                setIsCompleteOrderBtnDisable(false);
              }}
              title={"Logs"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Logs</>}
            />
<CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Document")
              }}
              title={"View Document"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>View Document</>}
            />
          </>
        ) : (Number(params?.data?.req_amount) === 0 && params?.data?.PROHead_Status === "Approved" &&  params?.data?.Account_Status === "Approved" && params?.data?.PRO_Status === "Completed") 
        || (Number(params?.data?.req_amount) > 0 && params?.data?.PROHead_Status === "Approved" && params?.data?.PRO_Status === "Completed")
            
        ? (
          <>
          <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("PRO Work Done")
                setIsCompleteOrderBtnDisable(true)
              
              }}
              title={"Work Done"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Work Done</>}
            />
          <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Logs")
                setIsCompleteOrderBtnDisable(false);
              }}
              title={"Logs"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Logs</>}
            />
<CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Document")
                setIsCompleteOrderBtnDisable(false);
              }}
              title={"View Document"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>View Document</>}
            />
          </>
        ) : params?.data?.PROHead_Status === "Pending" ? (
          <>
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue("accept");
                setOpenModal(true);
              }}
              title={"Accept"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Accept</>}
            />
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue("reject");
                setOpenModal(true);
              }}
              title={"Reject"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Reject</>}
            />
          </>
        ) :  (
          <>
          <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Logs")
                setIsCompleteOrderBtnDisable(false);
              }}
              title={"Logs"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>Logs</>}
            />
<CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                handleModalTableOpen();
                setButtonText("View Document")
              }}
              title={"View Document"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>View Document</>}
            />
          </>
        )}
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        textAlign: "left",
      },
      width: 70,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Actions",
      field: "Inquiry_Id",
      filter: false,
      // checkboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 370,
      pinned: "left",
      cellRenderer: actionBtnRenderer,
    },
    {
      headerName: "Company Name",
      field: "company_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 210,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Order No",
      field: "order_id",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Order Name",
      field: "order_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 250,
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Requistion No",
      field: "requisition_no",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 160,
    },
    // {
    //   headerName: "Requistion Id",
    //   field: "requisition_id",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
    //   width: 150,
    // },
    {
      headerName: "Requistion Date",
      field: "requisition_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Work Type",
      field: "work_type",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Req Amount",
      field: "req_amount",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "PRO Head Status",
      field: "PROHead_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Account Status",
      field: "Account_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "PRO Status",
      field: "PRO_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Task Name",
      field: "task_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 250,
    },
    {
      headerName: "PRO Name",
      field: "Pro_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Appointment Start Date",
      field: "Appointment_Start_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Appointment End Date",
      field: "Appointment_End_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Req Location",
      field: "req_location",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Main Task Days",
      field: "main_task_days",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Task Days",
      field: "task_days",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Typist Name",
      field: "Typist_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Typist Status",
      field: "Typist_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Self Assigned",
      field: "is_self_assign_by_ro",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Task Sequence",
      field: "task_sequance",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Order Creation Date",
      field: "order_created_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 150,
    },

    {
      headerName: "Job Priority",
      field: "job_priority",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Remarks",
      field: "remarks",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Completed Comment",
      field: "completed_comment",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Executed By",
      field: "executed_by",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    {
      id: 2,
      title: "Approved",
      value: "Approved",
      key: "PROHead_Status",
    },
    {
      id: 3,
      title: "Reject",
      value: "Rejected",
      key: "PROHead_Status",
    },
    {
      id: 4,
      title: "Pending",
      value: "Pending",
      key: "PROHead_Status",
    },
    {
      id: 4,
      title: "Overdue",
      value: "Pending",
      key: "PRO_Status",
    },
    {
      id: 4,
      title: "Underprocess",
      value: "Underprocess",
      key: "PRO_Status",
    },
    {
      id: 5,
      title: "Completed",
      value: "Completed",
      key: "PRO_Status",
    },
    {
      id: 6,
      title: "Refunded",
      value: "Refunded",
      key: "PRO_Status",
    },
    {
      id: 7,
      title: "Not Assign",
      value: "Not Assign",
      key: "PRO_Status",
    },

  ];

  const getTotalLength = (apiData, value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return apiData?.length;
    } else if (key === null) {
      return apiData?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      return apiData?.filter((val) => val[key] == value).length;
    }
  };

  const getTotalLengthForSavedFilters = (apiData, value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return apiData?.length;
    } else if (key === null) {
      return apiData?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      const filteredData = apiData.filter(function (item) {
        return value.includes(item[key]);
      });
      return filteredData?.length;
    }
  };

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.detail === "Invalid Token!") {
          localStorage.clear();
          navigate("/login");
          sessionStorage.clear();
          window.location.reload();
        }
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {apiData?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(apiData, val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleDefaultFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.title,
                          val.value,
                          val.key
                        )
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const keyss = val.Search_Filter.replace(
                    /(.*?)=(.*?)(?:\|\||$)/g
                  );
                  const getNewKey = keyss
                    ?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "");
                  const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                  let resultArray = "";
                  if (getNewKe2) {
                    const resultString = getNewKe2[1];
                    resultArray = resultString.split(",");
                  }
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLengthForSavedFilters(
                        apiData,
                        resultArray,
                        valueKey
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
          <div className="listing-filters" style={{ position:'relative', top: "5.9rem" }}>
        <Grid container>
        <Grid item sm={12} md={4}></Grid>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "600", fontSize: 12 }}
              >
                Requisition Date:
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <CustomDatePicker
                    custom={true}
                    fieldHandleChange={(value, picker) => {
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      //    getProRequestionListApi(reqFromDate, reqToDate);
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>
          <Grid item sm={12} md={4}></Grid>
        </Grid>
      </div>
      <p style={{fontSize: 14, color:'red',marginLeft: 10, marginBottom: 0, paddingTop: 5}}>Note: Default data which are showing is from today and yesterday's data.</p>
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.length}
      />
  
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modalValue === "accept" ? (
            <AcceptRequistion setOpenModal={setOpenModal} />
          ) : modalValue === "reject" ? (
            <RejectRequistion setOpenModal={setOpenModal} />
          ) : modalValue === "Executed By" ? (
            <ExecutedByForm setOpenModal={setOpenModal} />
          ) : modalValue === "Re-Assign Task" ? (
            <ReAssignTaskForm setOpenModal={setOpenModal} />
          ) : null}
        </>
      </Modal>
    </div>
  );
};

export default PRORequistionListing;
