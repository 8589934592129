import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import ModalPopperFormStyles from "../Styles/ModalPopperFormStyles";
import { Modal } from "@mui/material";
import Error from "../../../listing/listingTable/ListingExpandableTable/modal/Error";
import LoaderClock from "../../../lodar/LoaderClock";
import {  SupportDataContext } from "../../../../provider/context";


const ModalPopper = () => {
  const {
    modalOpen,
    buttonText,
    error,
  } = useContext(SupportDataContext);
  return (
    <>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {buttonText === "Add / View Follow-up" ? (
          "Test"
        )
        : buttonText === "error" ? (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        ) : (
          <Error msg={"Error 500"} subMsg={error} btn="error" />
        )}
      </Modal>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(ModalPopper);
