import flatpickr from 'flatpickr';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
  } from 'react';
  
  export default forwardRef((props, ref) => {
    const [date, setDate] = useState(null);
    const [picker, setPicker] = useState(null);
    const refFlatPickr = useRef(null);
    const refInput = useRef(null);
  
    const dateRef = useRef(null);

    const onDateChanged = (selectedDates) => {
      const newDate = selectedDates[0];
      setDate(newDate);
      dateRef.current = newDate;
      props.onDateChanged();
    };
  
    useEffect(() => {
      setPicker(
        flatpickr(refFlatPickr.current, {
          onChange: onDateChanged,
          dateFormat: 'd/m/Y',
          wrap: true,
        })
      );
    }, []);
  
    useEffect(() => {
      if (picker) {
        picker?.calendarContainer?.classList?.add('ag-custom-component-popup');
      }
    }, [picker]);
  
    useEffect(() => {
      if (picker) {
        picker.setDate(date);
      }
    }, [date, picker]);
  
    useImperativeHandle(ref, () => ({
      getDate() {
        return dateRef.current;
      },
  
      setDate(date) {
        dateRef.current = date;
        setDate(date);
      },
  
      setInputPlaceholder(placeholder) {
        if (refInput.current) {
          refInput.current.setAttribute('placeholder', placeholder);
        }
      },
  
      setInputAriaLabel(label) {
        if (refInput.current) {
          refInput.current.setAttribute('aria-label', label);
        }
      },
    }));
  
    return (
      <div
        className="ag-input-wrapper custom-date-filter"
        role="presentation"
        ref={refFlatPickr}
      >
        <input type="text" ref={refInput} data-input style={{ width: '100%' }} />
        <a class="input-button" title="clear" data-clear>
          <i class="fa fa-times"></i>
        </a>
      </div>
    );
  });