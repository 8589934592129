import React, { useState, useContext, useEffect } from "react";
import SubleadFormStyles from "./SubLeadFormStyles";
import { withStyles } from "@material-ui/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { getLocalData } from "../../../../../services/services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
};

const SubLeadForm_Support = ({ classes, getInfo }) => {
 
  const {
   
    handleModaleClose,
    setMessage,
    setSeverity,
    setIsOpen,
    editModalPopper,
    setEditModalPopper,
    getSubleadList,
    salesInfo,
    businessInfo,
    subleadservicesList,
    fetchSubLeadListDataFromApi,
  } = useContext(SupportDataContext);

  useEffect(() => {
    getSubleadList();
  }, []);


  const [loading, setLoading] = useState(false);
  const services_Options = subleadservicesList?.Data?.lead_caption_list?.map((item, i) => {
    const obj = {
      value: item?.lead_caption_id,
      label: item?.lead_caption_name,
    };
    return obj;
  });
  const company_Options = subleadservicesList?.Data?.company_list?.map((item, i) => {
    const obj = {
      value: item?.company_id,
      label: item?.company_name,
    };
    return obj;
  });
 
  const __token = localStorage.getItem("token");
  const emp_id = getLocalData("emp_id");
  const userType = localStorage.getItem("userType");

  const onEditSLForm = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${__token}`);
    const formData = new FormData();
    formData.append("sub_lead_id", data?.sub_lead_id);
    formData.append("company_id",data.company_id);
    formData.append("service_id", data.service_id);
    formData.append("service_detail", data.service_details);
    formData.append("emp_id", 617);
    formData.append("user_type", userType);

    setLoading(true);
    let result = await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/SubLeadView/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
        },
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.msg) {
          setMessage(data.data.msg);
          setSeverity(500);
        

        }
        else if (data.message) {
          setMessage(data.message);
          setSeverity(500);
         

        }
        else {
          setMessage(data.data.msg);
          setSeverity(data.data.code);
        

        }
        setIsOpen(true); 
       // handleClickSnack();
        // getSubleadList();
      //  setEditModalPopper(false);
      //   setEditView("");
      })
      .catch((error) => {
        console.error("Error from shareholder edit:", error);
      });
    handleModaleClose();
  };

  const onSaveData = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${__token}`);
    const formData = new FormData();
    formData.append("company_id", data.company_id);
    formData.append("service_id", data?.service_id);
    formData.append("service_detail", data?.service_details);
    formData.append("emp_id", emp_id);
    formData.append("user_type", userType);

    setLoading(true);
    let result = await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/SubLeadView/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${__token}`,
        },
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.msg) {
          setMessage(data.msg);
          setSeverity(500);
         

          // setEditCard(true);
        }  else if (data.message) {
          setMessage(data.message);
          setSeverity(500);
        

        }
         else {
          setMessage(data.data.msg);
          setSeverity(data.data.code);
        }
        setIsOpen(true); 
        //handleClickSnack();
        // getSLEdit();
        // getSubleadList();
        // setEditView("");
      })
      .catch((error) => {
        console.error("Error from shareholder save:", error);
      });
    handleModaleClose();
  };
  // setEditCard(editModalPopper && slData.length > 0 ? true : false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        sub_lead_id: editModalPopper ? salesInfo.Sub_Lead_Id : "",
        service_id: editModalPopper ? salesInfo.Service : "",
       // emp_id: editModalPopper ? getInfo.emp_id : "",
        // Company_Name: editModalPopper ? salesInfo?.Company_Name || businessInfo?.Company_Name || subLeadInfo?.Company_Name : salesInfo?.Company_Name || businessInfo?.Company_Name || subLeadInfo?.Company_Name,
        //service: editModalPopper ? salesInfo?.Service || businessInfo?.Service || subLeadInfo?.Service : "",
        //userType: editModalPopper ? getInfo.userType : "",
        company_id: editModalPopper? salesInfo.Company_Id: "",
        service_details: editModalPopper ? salesInfo?.Service_Details : "",
      }}
      validate={(values) => {
        const errors = {};
        if (!values.company_id) {
          errors.company_id = "company id is Required!!";
        }
        if (!values.service_id) {
          errors.service_id = "Service is Required!!";
        }
        if (!values.service_details) {
          errors.service_details = "Service details is Required!!";
        }
        return errors;
      }}
      onSubmit={async (data) => {
        editModalPopper ? onEditSLForm(data) : onSaveData(data);
        fetchSubLeadListDataFromApi();
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <IconButton
              className={classes.subleadModalHeaderIconCancle}
              onClick={() => {
                //setEditView("");
                setEditModalPopper(false);
                handleModaleClose();
                
              }}
            >
              <CloseIcon className={classes.shareholderModalHeaderIcon} />
            </IconButton>
            <Grid container spacing={2} columns={12}>
              {editModalPopper ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "0px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                    }}
                  >
                    <br />

                    <div className={classes.subleadModalHeadingContainer}>
                      <span className={classes.subleadModalHeader}>
                        {" "}
                        Edit Sub Lead Information{" "}
                      </span>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "0px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                    }}
                  >
                    <br />

                    <div className={classes.subleadModalHeadingContainer}>
                      <span className={classes.subleadModalHeader}>
                        {" "}
                      Add Sub Lead Information{" "}
                      </span>
                    </div>
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: "-30px",
                  paddingBottom: "10px",
                  marginTop: "-45px",
                }}
              >
                <br />
                <br />
                <div className={classes.subleadModalFlexDivContaner}>
                  <Grid container>
                    <Grid item xs={12} className={classes.subleadInnerGridLeft}>
                     { editModalPopper ?
                     <div className={classes.subleadFormGroupFlexDivModal}>
                        <label className={classes.subleadLabelFormInputModal}>
                          <span>Company Name</span>
                        </label>

                        <div className={classes.subleadSelectContainerModal}>
                          <div className={classes.subleadDemoDataCheckModal}>
                            <input
                              className={
                                classes.subleadListingFormInputMainModal
                              }
                              placeholder="Enter Company Name"
                              name="Company_Name"
                              disabled
                              value={salesInfo?.Company_Name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      :
                      <div className={classes.subleadFormGroupFlexDivModal}>
                        <label className={classes.subleadLabelFormInputModal}>
                          <span>Company Name:</span>
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>

                        <div className={classes.subleadSelectContainerModal}>
                          <div className={classes.subleadDemoDataCheckModal}>
                            <CustomSelect
                              options={company_Options}
                              name="company_id"
                              value={values.company_id}
                              onChange={(value) =>
                               { 
                                setFieldValue("company_id", value.value)
                              }
                              }
                              isDisabled={
                                editModalPopper ? true : false
                              }
                            />
                          </div>
                          {errors.company_id && touched.company_id && (
                            <div className={classes.requierdFieldMsg}>
                              {errors.company_id}
                            </div>
                          )}
                        </div>
                      </div>
}
                      <div className={classes.subleadFormGroupFlexDivModal}>
                        <label className={classes.subleadLabelFormInputModal}>
                          <span>Services:</span>
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>

                        <div className={classes.subleadSelectContainerModal}>
                          <div className={classes.subleadDemoDataCheckModal}>
                            <CustomSelect
                              options={services_Options}
                              name="service_id"
                              value={values.service_id}
                              onChange={(value) =>
                              {  
                                setFieldValue("service_id", value.value)
                              }
                              }
                            />
                          </div>
                          {errors.service_id && touched.service_id && (
                            <div className={classes.requierdFieldMsg}>
                              {errors.service_id}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={classes.subleadFormGroupFlexDivModal}>
                        <label className={classes.subleadLabelFormInputModal}>
                          <span>Services Detail:</span> <span style={{ color: "red" }}>*</span>{" "}
                        </label>

                        <div className={classes.subleadSelectContainerModal}>
                          <div className={classes.subleadDemoDataCheckModal}>
                            <textarea
                              className={
                                classes.subleadListingFormInputMainModal
                              }
                              name="service_details"
                              value={values.service_details}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.service_details && touched.service_details && (
                            <div className={classes.requierdFieldMsg}>
                              {errors.service_details}
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <br />
              </Grid>
            </Grid>
            <div className={classes.subleadButtonDivModal}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{ backgroundColor: "#eb6e12" }}
              >
                {loading ? "Loading...." : editModalPopper ? "Submit" : "Save"}
              </Button>
              <Button
                onClick={() => {
                  //setEditView("");
                  handleModaleClose();
                  setEditModalPopper(false);
                }}
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#fff",
                  color: "#eb6e12",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(SubleadFormStyles)(SubLeadForm_Support);
