import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, Modal, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import AddScopeOfWorkForm from "../TemplateCreation/AddScopeOfWorkForm";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import FinalizeNOFOrder from "./FinalizeNOFOrder";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomUploadFile from "../../../../../reusableComponents/CustomUploadFile/CustomUploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "350px",
  maxHeight: "350px",

  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const EditNOFOrderForm = ({ classes }) => {
  const {
    setButtonText,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getNOFDataListingApi,
    getTemplateCreationListgDataApi,
    templateCreationList,
    templateList,
  } = useContext(SupportDataContext);

  var today = new Date();
  const currentDate = moment(today).format("YYYY-MM-DD");
  const [formationTypeValue, setFormationTypeValue] = useState(null);
  const [orderIdValue, setOrderIdValue] = useState(null);
  const [stateIdValue, setStateIdValue] = useState(null);
  const [licenseTypeValue, setLicenseTypeValue] = useState(null);
  const [activityTypeValue, setActivityTypeValue] = useState(null);
  const [freezoneValue, setFreezoneValue] = useState(null);
  const [offshoreValue, setOffshoreValue] = useState(null);
  const [legalTypeValue, setLegalTypeValue] = useState(null);
  const [proFormationTypeValue, setPROFormationTypeValue] = useState(null);
  const [dedfzoffTypeValue, setDedFzOffTypeValue] = useState(null);
  const [proServicesName, setProServicesName] = useState(null);
  const [sowList, setSowList] = useState([]);
  const [sowSubTemplateList, setSowSubTemplateList] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectetOrderTemplate, setSelectedOrderTemplate] = useState([]);
  const [selectetOrderSubTemplate, setSelectedOrderSubTemplate] = useState([]);
  const [isDefaultValueChecked, setIsDefaultValueChecked] = useState(false);
  const [
    isDefaultValueCheckedSubTemplate,
    setIsDefaultValueCheckedSubTemplate,
  ] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isSaveAndContinue, setIsSaveAndContinue] = useState(true);
  const [isProjectDisabled, setIsProjectDisable] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [dropdownLoad, setDropdownLoad] = useState(true);
  const [orderIdEditValue, setOrderIdEditValue] = useState('');
  const [callAPI, setDontCallApi] = useState(true);
  const [lastUpdate, setLastUpdate] = useState("No");

  const hasDuplicate = (array, property) => {
    const seen = new Set();
    for (const item of array) {
      if (seen.has(item[property])) {
        return true; // Found a duplicate
      }
      seen.add(item[property]);
    }
    return false; // No duplicates found
  }

  const addOperationNOFOrderFormPOSTPUTApi = async (values, setFieldValue) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const formData = new FormData();
    let selectedTemplate = values?.selectedTemplate?.map((val) => {
      return val.label;
    });
    const uploadDoc = values?.uploadDocument?.map((val, i) => {
      formData.append(`upload_docs_${i}`, val.uploadDoc);
      return {
        Document_Type: val?.documentType,
        Particular: val?.particular
      }
    })
    const hasDuplicates = hasDuplicate(sowList, "Service_Task_Id");
    
    if(hasDuplicates) {
      setMessage(`Duplicate Scope of work found!. Please try again later!`);
      setSeverity(500);
      setIsOpen(true);
      setLoading(false);
      setButtonText("NOF Order Form Table");
      return;
    }
    formData.append("number_of_upload_docs", values?.uploadDocument?.length);
    formData.append("upload_doc_details", JSON.stringify(uploadDoc))
    formData.append('emp_id', Number(emp_id));
    formData.append('user_type', user_type);
    formData.append('company_id', Number(values?.companyName));
    formData.append('order_id', orderIdEditValue);
    formData.append('order_name', values?.orderName);
    formData.append('type_of_order', values?.orderType);
    formData.append('order_sub_type', values?.orderSubType);
    formData.append('support_emp_id', Number(values?.supportId));
    formData.append('typist_emp_id', Number(values?.typistId));
    // formData.append('scope_of_work', JSON.stringify(sowList));
    formData.append('inquiry_or_company_order_id', operationInfo?.new_order_form_id);
    formData.append('company_inquiry_flag', operationInfo?.inquiry_id ? 1 : 2);
    formData.append('state_id', Number(values?.state));
    formData.append('formation_type', Number(values?.formationType));
    // formData.append('order_template', JSON.stringify(selectedTemplate));
    formData.append('project_id', Number(values?.projectId));
    formData.append('project_name', values?.projectName);
    formData.append('order_connected_or_not', values?.orderConnected);
    formData.append("last_update_order", lastUpdate)


    if (values.formationType === 1) {
      formData.append('license_type_id', Number(values?.licenseType));
      formData.append('activity_type', Number(values?.activityType));
    }

    if (values.formationType === 2) {
       formData.append('formation_name_id', values?.freezoneName);
      formData.append('activity_type', Number(values?.activityType));
      formData.append('license_type_id', Number(values?.licenseType));
    }

    if (values.formationType === 3) {
      formData.append('formation_name_offshore', values?.offShoreName);
      formData.append('activity_type', Number(values?.activityType));
      formData.append('legal_type', Number(values?.legalType));
    }

    if (values.formationType === 4) {
       formData.append('sub_formation_type', values?.proServices);
       formData.append('formation_name_pro', values?.dedFzOffshore);
       formData.append('pro_services_name', values?.proServiceName);
    }

    if (operationInfo?.inquiry_id) {
      formData.append('inquiry_id', operationInfo?.inquiry_id);
    }
    if (editModalPopper) {
      formData.append('update_id', orderFormCompanyDropdown?.Order_Detail?.[0]?.Order_Id);
      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        // handlePromise(data);
        getNOFDataListingApi();
        setButtonText("NOF Order Form Table");
      });
    }
  };

  const [orderFormCompanyDropdown, setOrderFormCompanyDropdown] = useState([]);
  const getOrderFormonCompanyData = async (
    orderType,
    stateId,
    formationId,
    licenseType,
    activityType,
    freezoneName,
    offShoreName,
    legalType,
    proFormationTYpe,
    dedFzOffShoreName,
    proServiceName,
  ) => {
    setOpenLoading(true)
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/?company_inquiry_flag=${
        operationInfo?.inquiry_id ? 1 : 2
      }&order_id=${operationInfo?.Order_Id}`;
     
      if (operationInfo?.Type_Of_Order) {
        url = `${url}&order_type=${orderType}`;
      }
      if (operationInfo?.state_id) {
        url = `${url}&state_id=${stateId}`;
      }
      if (operationInfo?.formation_type) {
        url = `${url}&formation_type_id=${Number(formationId)}`;
      }
      if (
        (Number(formationId) === 1 && licenseType) ||
        (Number(formationId) === 2 && licenseType)
      ) {
        url = `${url}&license_type=${licenseType}`;
      }
      if (
        (Number(formationId) === 1 && activityType) ||
        (Number(formationId) === 2 && activityType) ||
        (Number(formationId) === 3 && activityType)
      ) {
        url = `${url}&activity_type=${activityType}`;
      }
      if (Number(formationId) === 2 && freezoneName) {
        url = `${url}&freezone_name=${freezoneName}`;
      }
      if (Number(formationId) === 3 && legalType) {
        url = `${url}&legal_type=${legalType}`;
      }
      if (Number(formationId) === 3 && offShoreName) {
        url = `${url}&offshore_name=${offShoreName}`;
      }
   
      if (Number(formationId) === 4 && proFormationTYpe) {
        url = `${url}&pro_formation_type=${proFormationTYpe}`;
      }
      if (Number(formationId) === 4 && dedFzOffShoreName) {
        url = `${url}&ded_fz_offshore_name=${dedFzOffShoreName}`;
      }
      if (Number(formationId) === 4 && proServiceName) {
        url = `${url}&pro_services_name=${proServiceName}`;
      }

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderFormCompanyDropdown(res?.data?.Data);
      setOrderIdEditValue(res?.data?.Data?.Order_Id);
    

      setInitialLoad(false);
      setOpenLoading(false);
      setDropdownLoad(false)
      if (editModalPopper && initialLoad) {
        selectedTemplateValue(res?.data?.Data);
        selectedSubtemplateValue(res?.data?.Data);
      }
      if(dropdownLoad) {
        setDontCallApi(false);
        setFormationTypeValue(res?.data?.Data?.Order_Detail?.[0]?.ftype)
        setOrderIdValue(res?.data?.Data?.Order_Detail?.[0]?.Type_Of_Order)
        setStateIdValue(res?.data?.Data?.Order_Detail?.[0]?.state_id);
        setLicenseTypeValue(res?.data?.Data?.Order_Detail?.[0]?.license_type_id);
        setActivityTypeValue(res?.data?.Data?.Order_Detail?.[0]?.activity_type_id);
        setFreezoneValue(res?.data?.Data?.Order_Detail?.[0]?.freezone_name_id);
        setOffshoreValue(res?.data?.Data?.Order_Detail?.[0]?.offshore_name_id);
        setLegalTypeValue(res?.data?.Data?.Order_Detail?.[0]?.legal_type_id);
        setPROFormationTypeValue(res?.data?.Data?.Order_Detail?.[0]?.sub_formation_type);
        setDedFzOffTypeValue(res?.data?.Data?.Order_Detail?.[0]?.formation_name_pro);
        setProServicesName(res?.data?.Data?.Order_Detail?.[0]?.pro_services_name);
      }

      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const states =
    orderFormCompanyDropdown.Master_State !== undefined
      ? orderFormCompanyDropdown?.Master_State.map((i) => ({
          id: i.StateIds,
          value: i.StateIds,
          label: `${i.StateName}`,
        }))
      : [];

  const companyList =
    orderFormCompanyDropdown.Company_List !== undefined
      ? orderFormCompanyDropdown?.Company_List.map((i) => ({
          id: i.Company_Id,
          value: i.Company_Id,
          label: `${i.Company_Name}`,
        }))
      : [];

  const orderTypeDropdown =
    orderFormCompanyDropdown.Order_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Type.map((i) => ({
          id: i,
          value: i,
          label: `${i}`,
        }))
      : [];

  const formationType =
    orderFormCompanyDropdown.Master_type_of_company !== undefined
      ? orderFormCompanyDropdown?.Master_type_of_company.map((i) => ({
          id: i.Formation_type_id,
          value: i.Formation_type_id,
          label: `${i.Formation_type}`,
        }))
      : [];

  const licenseTypes =
    orderFormCompanyDropdown.License_Type !== undefined
      ? orderFormCompanyDropdown?.License_Type.map((i) => ({
          id: i.Ids,
          value: i.Ids,
          label: `${i.Name}`,
        }))
      : [];
  const legalTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Legal_Type !== undefined
      ? orderFormCompanyDropdown?.Legal_Type.map((i) => ({
          id: i.Ids,
          value: i.Ids,
          label: `${i.Name}`,
        }))
      : [];
  const activityGroupTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.Activity_Group !== undefined
      ? orderFormCompanyDropdown?.Activity_Group.map((i) => ({
          id: i.Ids,
          value: i.Ids,
          label: `${i.Name}`,
        }))
      : [];
  const freezoneTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.Freezone_Name !== undefined
      ? orderFormCompanyDropdown?.Freezone_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];
  const offshoreTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.Offshore_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];
  const proFormationTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.PRO_Formation_Type !== undefined
      ? orderFormCompanyDropdown?.PRO_Formation_Type.map((i) => ({
          value: i,
          label: `${i}`,
        }))
      : [];
  const ded_Fz_OfforeTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.DED_FZ_Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.DED_FZ_Offshore_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];
  const proServiceNames =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.PRO_Service_Name !== undefined
      ? orderFormCompanyDropdown?.PRO_Service_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];

  const orderSubTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Order_Sub_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Sub_Type.map((i) => ({
          id: i.Serial_Number,
          value: `${i.order_sub_type}`,
          label: `${i.order_sub_type}`,
        }))
      : [];

  const tagNames =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.Tag_Name_List !== undefined
      ? orderFormCompanyDropdown?.Tag_Name_List.map((i) => ({
          id: i.Tag_Id,
          value: i.Tag_Id,
          label: `${i.Tag_Name}`,
        }))
      : [];

  const typistList =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Typist_List !== undefined
      ? orderFormCompanyDropdown?.Typist_List.map((i) => ({
          id: i.EmpId,
          value: i.EmpId,
          label: `${i.Emp_Name}`,
        }))
      : [];

      const documentTypeList =
      orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Document_Type !== undefined
        ? orderFormCompanyDropdown?.Document_Type.map((i) => ({
            id: i.Id,
            value: i.Id,
            label: `${i.Doc_Name}`,
          }))
        : [];

  const templateListDropdown =
    templateList?.Service_Template_List?.length !== 0 &&
    templateList?.Service_Template_List !== undefined
      ? templateList?.Service_Template_List?.map((i) => ({
          id: i.Service_Id,
          value: `${i.Service_Id}`,
          label: `${i.Service_Name}`,
          details: i?.Service_Details,
          taskList: [...i?.Task_List]?.sort((a,b) => a.Task_Sequence - b.Task_Sequence),
        }))
      : [];

  const templateSubOrderListDropdown =
    templateList?.Service_Sub_Template_List?.length !== 0 &&
    templateList?.Service_Sub_Template_List !== undefined
      ? templateList?.Service_Sub_Template_List?.map((i) => ({
          id: i.Service_Id,
          value: `${i.Service_Id}`,
          label: `${i.Service_Name}`,
          details: i?.Service_Details,
          taskList: i?.Task_List,
        }))
      : [];
  const projectList =
  templateCreationList?.length !== 0 &&
    templateCreationList?.Project_List !== undefined
      ? templateCreationList?.Project_List.map((i) => ({
          id: i.project_id,
          value: i.project_id,
          label: `${i.project_name}`,
          supportId: i.support_id,
          supportName: i?.support_name,
          companyName: i?.company_name,
          subOrderTemplateName: i?.scope_work_template_name_for_sub_template,
          taskList: i?.scope_of_work_for_sub_template,
        }))
      : [];

  const taskSectionDropdown = templateCreationList?.Single_Multi_Work_Type?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const taskTypeDropdown = templateCreationList?.Task_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Task_Type_Name, label: item.Task_Type_Name };
      return obj;
    }
  );

  const taskCategoryDropdown = templateCreationList?.Task_Category_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const workTypeDropdown = templateCreationList?.Work_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const govtDeptDropdown = templateCreationList?.Govt_Dept_List?.map(
    (item, i) => {
      const obj = { value: item.Govt_Dept_Name, label: item.Govt_Dept_Name };
      return obj;
    }
  );

  useEffect(() => {
    getTemplateCreationListgDataApi();
  }, []);

  useEffect(() => {
    if (editModalPopper && callAPI && !initialLoad) {
      setIsDefaultValueChecked(true);
      getOrderFormonCompanyData(
        orderIdValue,
        stateIdValue,
        formationTypeValue,
        licenseTypeValue,
        activityTypeValue,
        freezoneValue,
        offshoreValue,
        legalTypeValue,
        proFormationTypeValue,
        dedfzoffTypeValue,
        proServicesName,
      );
    }
  }, [
    orderIdValue,
    formationTypeValue,
    stateIdValue,
    licenseTypeValue,
    activityTypeValue,
    freezoneValue,
    offshoreValue,
    legalTypeValue,
    proFormationTypeValue,
    dedfzoffTypeValue,
    proServicesName,
  ]);

  const removeDuplicateObjects = (array, property) => {
    const uniqueArray = [];

    array.forEach((object) => {
      const existingObject = uniqueArray.find(
        (item) => item[property] === object[property]
      );

      if (!existingObject) {
        uniqueArray.push(object);
      }
    });

    return uniqueArray;
  };

  useEffect(() => {
      getOrderFormonCompanyData(
        operationInfo?.Type_Of_Order,
        operationInfo?.state_id,
        Number(operationInfo?.formation_type),
        operationInfo?.license_type_id,
        operationInfo?.activity_type_id,
        operationInfo?.freezone_name_id,
        Number(operationInfo?.offshore_name_id),
        Number(operationInfo?.legal_type),
        operationInfo?.formation_type_for_pro_services,
        operationInfo?.ded_fz_offshore_name_id,
        operationInfo?.pro_services_name_id,
      );
  }, [])

  const selectedTemplateValue = (data) => {
    setInitialLoad(true);
    let templateList = [];
    let finalTaskList = [];
    data?.Order_Detail?.[0]?.scope_of_work?.forEach((item) => {
      let findValue = templateListDropdown?.find(
        (val) => Number(val.id) === Number(item.Template_Id)
      );
      let findExistValue = templateList.find(
        (val) => Number(val.id) === Number(item.Template_Id)
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Service_Task_Name: item?.Name,
          Task_Type_Name: item?.Task_Type,
          Task_Category: item?.Task_Category,
          Nos_Task_Days: item?.Days,
          Task_Sequence: item?.Sequance,
          Task_Amount: item?.Amount,
          Govt_Dept_Name: item?.Govt_Department,
          Special_Instruction: item?.Special_Instruction,
          Single_Multi_Task_Type: item?.Task_Section,
          Task_Details: item?.Task_Detail,
          Work_Type: item?.Work_Type,
          Tag_Id: item?.Tag_Id,
          Tag_Name: item?.Tag_Name,
          Template_Id: item?.Template_Id,
          Template_Name: item?.Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
        };

        finalTaskList.push(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          templateList.push(findValue);
        }
      }
    });

    // let filteredValue = removeDuplicateObjects(templateList, "Service_Task_Id");
    setSowList(data?.Order_Detail?.[0]?.scope_of_work);
    setSelectedOrderTemplate(templateList);
    setInitialLoad(false);
  };

  const selectedSubtemplateValue = (data) => {
    setInitialLoad(true);
    let selecSubTemplates = [];
    let finalTaskList = [];
    data?.Order_Detail?.[0]?.scope_of_work_for_sub_template?.forEach((item) => {
      let findValue = templateSubOrderListDropdown?.find(
        (val) => val.label === item.Sub_Template_Name
      );
      let findExistValue = selecSubTemplates.find(
        (val) => val.label === item.Sub_Template_Name
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Task_Details: item?.Description,
          Sub_Template_Id: item?.Sub_Template_Id,
          Sub_Template_Name: item?.Sub_Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
        };
        finalTaskList.push(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          selecSubTemplates.push(findValue);
        }
      }
    });

    //let filteredValue = removeDuplicateObjects(selecSubTemplates, "Service_Task_Id");

    setSowSubTemplateList(
      data?.Order_Detail?.[0]?.scope_of_work_for_sub_template
    );
    setSelectedOrderSubTemplate(selecSubTemplates);
    setInitialLoad(false);
  };

    const handleOrderTemplateChecked = (scopeOfWork, val) => {
    let findValue = scopeOfWork?.find(
      (value) => value.Service_Task_Id === val.Service_Task_Id
    );
    if (findValue) {
      return true;
    } else {
      return false;
    }
  };

  const handleUploadDocument = () => {
    let finalValue = orderFormCompanyDropdown?.Order_Detail?.[0]
    ?.upload_doc_details?.map((val) => {
      return {
        documentType: val?.Document_Type,
        particular: val?.Particular,
        uploadDoc: val?.uploadDoc
      }
    })
    return finalValue
  }

  const isSequenceExist = (val, array, Service_Task_Id) => {
    let value =  array.some(obj => {
      if(!(obj.Service_Task_Id === Service_Task_Id)) {
        return obj.Sequance === val
      }
    }); 
    return value;
  }

  return (
    <>
      <Formik
        enableReinitialize={initialLoad}
        initialValues={{
          orderId: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.Order_No
            : orderFormCompanyDropdown?.Order_Id,
          companyName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.Company_Id
            : "",
          orderName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.Order_Name
            : "",
          orderType: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.Type_Of_Order
            : "",
          orderSubType: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.order_sub_type
            : "",
          state: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.state_id
            : "",
          formationType: editModalPopper
            ? Number(orderFormCompanyDropdown?.Order_Detail?.[0]?.ftype)
            : "",
          proServices: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.sub_formation_type
            : "",
          dedFzOffshore: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]
                ?.formation_name_pro
            : "",
          proServiceName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.pro_services_name
            : "",
          freezoneName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.freezone_name_id
            : "",
          offShoreName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.offshore_name_id
            : "",
          legalType: editModalPopper
            ? Number(orderFormCompanyDropdown?.Order_Detail?.[0]?.legal_type_id)
            : "",
          licenseType: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.license_type_id
            : "",
          activityType: editModalPopper
            ? Number(
                orderFormCompanyDropdown?.Order_Detail?.[0]?.activity_type_id
              )
            : "",
          selectedTemplate: editModalPopper ? selectetOrderTemplate : "",
          selectedTemplateId: editModalPopper ? selectetOrderTemplate : "",
          selectedSubTemplate: editModalPopper ? selectetOrderSubTemplate : "",
          selectedSubTemplateId: editModalPopper
            ? selectetOrderSubTemplate
            : "",
          scopeOfWork: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.scope_of_work
            : [],
          scopeOfWorkSubTemplate: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]
                ?.scope_of_work_for_sub_template
            : [],
          supportId: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.Support_Emp_Id
            : "",
          typistId: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.typist_id
            : "",
          projectId: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.project_id
            : "",
          projectName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.project_name
            : "",
          supportName: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]?.Support_Emp_Name
            : "",
          orderConnected: editModalPopper
            ? orderFormCompanyDropdown?.Order_Detail?.[0]
                ?.order_connected_or_not
            : "Connected",
          subOrderTemplateName: '',
          uploadFile: editModalPopper
          ? orderFormCompanyDropdown?.Order_Detail?.[0]
              ?.upload_doc_details?.length > 0 ? "Yes" : "No"
          : "No",
          uploadDocument: editModalPopper
          ? handleUploadDocument()
          : []
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addOperationNOFOrderFormPOSTPUTApi(data);
        }}
        validate={(values) => {
          let errors = {};
          if (!values.companyName) {
            errors.companyName = "required";
          }
          if (!values.orderName) {
            errors.orderName = "required";
          }
          if (!values.selectedTemplate) {
            errors.selectedTemplate = "required";
          }
          if (!values.orderType) {
            errors.orderType = "required";
          }
          if (!values.orderSubType) {
            errors.orderSubType = "required";
          }
          if (!values.state) {
            errors.state = "required";
          }
          if (!values.formationType) {
            errors.formationType = "required";
          }
          //Mainland
          if (values.formationType === 1) {
            if (!values.activityType) {
              errors.activityType = "required";
            }
            if (!values.licenseType) {
              errors.licenseType = "required";
            }
          }
          //Freezone
          if (values.formationType === 2) {
            if (!values.freezoneName) {
              errors.freezoneName = "required";
            }
            if (!values.activityType) {
              errors.activityType = "required";
            }
            if (!values.licenseType) {
              errors.licenseType = "required";
            }
          }
          //Offshore
          if (values.formationType === 3) {
            if (!values.offShoreName) {
              errors.offShoreName = "required";
            }
            if (!values.activityType) {
              errors.activityType = "required";
            }
            if (!values.legalType) {
              errors.legalType = "required";
            }
          }
          //PRO Services
          if (values.formationType === 4) {
            if (!values.proServices) {
              errors.proServices = "required";
            }
            if (!values.dedFzOffshore) {
              errors.dedFzOffshore = "required";
            }
            if (!values.proServiceName) {
              errors.proServiceName = "required";
            }
          }

          if (!values.supportId) {
            errors.supportId = "required";
          }
          if (values.typistId < 0) {
            errors.typistId = "required";
          }
          if (!values.projectId) {
            errors.projectId = "required";
          }

          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              <div>
                {editModalPopper ? (
                  <>
                  
                      <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={lastUpdate === "No" ? {
                        cursor:'not-allowed'
                      } : {backgroundColor: "#eb6e12"} }
                      onClick={handleSubmit}
                      disabled={lastUpdate === "Yes" ? false : true}
                    >
                      {"Submit"}
                    </Button>
                   
                   
                    <Button
                      onClick={() => {
                        setButtonText("NOF Order Form Table");
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                      onClick={() => {
                        setOpenLoading(true);
                        setIsSaveAndContinue(true);
                        addOperationNOFOrderFormPOSTPUTApi(
                          values,
                          setFieldValue
                        );
                      }}
                      disabled={
                        values?.orderName.length > 0 &&
                        Object.keys(errors).length === 0
                          ? false
                          : true
                      }
                    >
                      Save & continue with another order
                    </Button>
                    <Button
                      onClick={() => {
                        setIsSaveAndContinue(false);
                        setOpenModal(true);
                      }}
                      variant="contained"
                      size="small"
                      style={
                        values?.orderName.length > 0 &&
                        Object.keys(errors).length === 0
                          ? {
                              backgroundColor: "#eb6e12",
                              color: "#fff",
                              marginLeft: "10px",
                            }
                          : {
                              backgroundColor: "#f9f9f9",
                              color: "#eb6e12",
                              marginLeft: "10px",
                            }
                      }
                      disabled={
                        values?.orderName.length > 0 &&
                        Object.keys(errors).length === 0
                          ? false
                          : true
                      }
                    >
                      Finalize Order
                    </Button>
                    <Button
                      onClick={() => {
                        setButtonText("NOF Order Form Table");
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
              <br />

              <div
                style={{
                  height: "450px",
                  overflowX: "hidden",
                  padding: "0",
                }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={12}>
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={classes.quotationInnerGridRight}
                        >
                          {isProjectDisabled || editModalPopper ? (
                            <InputText
                              label="Select Project"
                              fieldName="projectName"
                              fieldValue={values?.projectName}
                              disableField={true}
                              disabled={true}
                            />
                          ) : (
                            <CustomDropdown
                              label="Select Project"
                              fieldOptions={projectList}
                              fieldValue={values?.projectId}
                              fieldHandleChange={(value) => {
                             
                                setFieldValue("projectName", value.label);
                                setFieldValue("projectId", value.value);
                                setFieldValue("supportId", value.supportId);
                                setFieldValue("supportName", value.supportName);
                                setFieldValue("companyName", value.companyName);
                                setIsDefaultValueCheckedSubTemplate(false);
                                setFieldValue("selectedSubTemplate", [value]);
                                setFieldValue("selectedSubTemplateId", [value]);
                                setFieldValue("subOrderTemplateName", value?.subOrderTemplateName)
                              }}
                              errorField={getStyles(errors, values?.projectId)}
                              requiredField={true}
                            />
                          )}

                          {/* <CustomDropdown
                            label="Company Name"
                            fieldOptions={companyList}
                            fieldValue={values?.companyName}
                            fieldHandleChange={(value) => {
                              //setleadCaptionData(value.value);
                              setFieldValue("companyName", value.value);
                            }}
                            errorField={getStyles(errors, values?.companyName)}
                            requiredField={true}
                          /> */}

                          <InputText
                            label="Order Name"
                            fieldName="orderName"
                            fieldValue={values?.orderName}
                            fieldHandleChange={(event) => {
                              setFieldValue("orderName", event.target.value);
                              setLastUpdate("Yes");
                            }
                              
                            }
                            requiredField={true}
                            errorField={getStyles(errors, values?.orderName)}
                          />
                          <CustomDropdown
                            label="Order Type"
                            fieldOptions={orderTypeDropdown}
                            fieldValue={values?.orderType}
                            fieldHandleChange={(value) => {
                              //setleadCaptionData(value.value);
                              setOrderIdValue(value.value);
                              setFieldValue("orderType", value.value);
                              setDontCallApi(true);
                              setLastUpdate("Yes");
                            }}
                            errorField={getStyles(errors, values?.orderType)}
                            requiredField={true}
                          />

                          <CustomDropdown
                            label="Order Sub Type"
                            fieldOptions={orderSubTypes}
                            fieldValue={values?.orderSubType}
                            fieldHandleChange={(value) => {
                              //setleadCaptionData(value.value);
                              setFieldValue("orderSubType", value.value);
                               setDontCallApi(true);
                               setLastUpdate("Yes");
                            }}
                            errorField={getStyles(errors, values?.orderSubType)}
                            requiredField={true}
                          />
                          <CustomDropdown
                            label="State"
                            fieldOptions={states}
                            fieldValue={values?.state}
                            fieldHandleChange={(value) => {
                              //setleadCaptionData(value.value);
                              setStateIdValue(value.value);
                              setFieldValue("state", value.value);
                              setDontCallApi(true);
                              setLastUpdate("Yes");
                            }}
                            errorField={getStyles(errors, values?.state)}
                            requiredField={true}
                          />
                          <CustomDropdown
                            label="Formation Type"
                            fieldOptions={formationType}
                            fieldValue={values?.formationType}
                            fieldHandleChange={(value) => {
                              setFormationTypeValue(value.value);
                              setFieldValue("formationType", value.value);
                              setDontCallApi(true);
                              setLastUpdate("Yes");
                            }}
                            errorField={getStyles(
                              errors,
                              values?.formationType
                            )}
                            requiredField={true}
                          />

                          { values?.formationType === 3 && (
                            <>
                              <CustomDropdown
                                label="Offshore Name"
                                fieldOptions={offshoreTypes}
                                fieldValue={values?.offShoreName}
                                fieldHandleChange={(value) => {
                                  setOffshoreValue(value.value);
                                  setFieldValue("offShoreName", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.offShoreName
                                )}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Legal Type"
                                fieldOptions={legalTypes}
                                fieldValue={values?.legalType}
                                fieldHandleChange={(value) => {
                                  setLegalTypeValue(value.value);
                                  setFieldValue("legalType", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.legalType
                                )}
                                requiredField={true}
                              />
                            </>
                          )}

                          { values?.formationType === 2 && (
                            <>
                              <CustomDropdown
                                label="Freezone Name"
                                fieldOptions={freezoneTypes}
                                fieldValue={values?.freezoneName}
                                fieldHandleChange={(value) => {
                                  setFreezoneValue(value.value);
                                  setFieldValue("freezoneName", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.freezoneName
                                )}
                                requiredField={true}
                              />
                            </>
                          )}

                          { values?.formationType === 4 && (
                            <>
                              <CustomDropdown
                                label="Select Formation Type for PRO Services"
                                fieldOptions={proFormationTypes}
                                fieldValue={values?.proServices}
                                fieldHandleChange={(value) => {
                                  setPROFormationTypeValue(value.value);
                                  setFieldValue("proServices", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.proServices
                                )}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Select DED/FZ/Offshore Name"
                                fieldOptions={ded_Fz_OfforeTypes}
                                fieldValue={values?.dedFzOffshore}
                                fieldHandleChange={(value) => {
                                  setDedFzOffTypeValue(value.value);
                                  setFieldValue("dedFzOffshore", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.dedFzOffshore
                                )}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Pro Service Name"
                                fieldOptions={proServiceNames}
                                fieldValue={values?.proServiceName}
                                fieldHandleChange={(value) => {
                                  setProServicesName(value.value);
                                  setFieldValue("proServiceName", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.proServiceName
                                )}
                                requiredField={true}
                              />
                            </>
                          )}
                          {!(
                             values?.formationType === 3 ||  values?.formationType === 4
                          ) && (
                            <>
                              <CustomDropdown
                                label="License Type"
                                fieldOptions={licenseTypes}
                                fieldValue={values?.licenseType}
                                fieldHandleChange={(value) => {
                                  setLicenseTypeValue(value.value);
                                  setFieldValue("licenseType", value.value);
                                  setDontCallApi(true);
                                  setLastUpdate("Yes");
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.licenseType
                                )}
                                requiredField={true}
                              />
                            </>
                          )}
                          { values?.formationType !== 4 && (
                            <CustomDropdown
                              label="Activity Type"
                              fieldOptions={activityGroupTypes}
                              fieldValue={values?.activityType}
                              fieldHandleChange={(value) => {
                                setActivityTypeValue(value.value);
                                setFieldValue("activityType", value.value);
                                setDontCallApi(true);
                                setLastUpdate("Yes");
                              }}
                              errorField={getStyles(
                                errors,
                                values?.activityType
                              )}
                              requiredField={true}
                            />
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={classes.quotationInnerGridRight}
                        >
                            <InputText
                            label="Company Name"
                            fieldName="orderName"
                            fieldValue={operationInfo?.Company_Name}
                            fieldHandleChange={(event) =>
                              setFieldValue("orderName", event.target.value)
                            }
                            disabled={true}
                            disableField={true}
                            requiredField={true}
                            errorField={getStyles(errors, values?.companyName)}
                          />

                          <InputText
                            label="Order Id"
                            fieldName="orderId"
                            fieldValue={values?.orderId}
                            disableField={true}
                            disabled={true}
                          />
                          <InputText
                            label="Support"
                            fieldName="supportName"
                            fieldValue={values?.supportName}
                            disableField={true}
                            disabled={true}
                          />

                          <CustomDropdown
                            label="Typist"
                            fieldOptions={typistList}
                            fieldValue={values?.typistId}
                            fieldHandleChange={(value) => {
                              setFieldValue("typistId", value.value);
                              setDontCallApi(true);
                              setLastUpdate("Yes");
                            }}
                            errorField={getStyles(errors, values?.typistId)}
                            requiredField={true}
                          />

                          <Form.Group
                            as={Row}
                            className="my-1"
                            controlId={"gender"}
                          >
                            <Form.Label column sm="5">
                              Connected / Not Connected
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                className={
                                  classes.listingEditListingEditFormGroup
                                }
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <fieldset
                                    onChange={(event) =>
                                      setFieldValue(
                                        "orderConnected",
                                        event.target.value
                                      )
                                      
                                    }
                                    disabled={editModalPopper ? true : false}
                                  >
                                    <input
                                      type="radio"
                                      checked={
                                        values?.orderConnected === "Connected"
                                      }
                                      name="orderConnected"
                                      value="Connected"
                                    />
                                    <span
                                      className={
                                        classes.genderRadioButtonLabele
                                      }
                                      style={{ marginLeft: "5px" }}
                                    >
                                      Connected
                                    </span>
                                    <input
                                      type="radio"
                                      checked={
                                        values?.orderConnected ===
                                        "Not Connected"
                                      }
                                      name="orderConnected"
                                      value="Not Connected"
                                      style={{ marginLeft: "5px" }}
                                    />
                                    <span
                                      className={
                                        classes.genderRadioButtonLabele
                                      }
                                      style={{ marginLeft: "5px" }}
                                    >
                                      Not Connected
                                    </span>
                                  </fieldset>
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <CustomMultiSelectDropdown
                            label="Select Order Template"
                            fieldOptions={templateListDropdown}
                            fieldValue={values?.selectedTemplateId}
                            isDisabled={true}
                            errorField={getStyles(
                              errors,
                              values?.selectedTemplate
                            )}
                            requiredField={true}
                          />

<Form.Group
                                              as={Row}
                                              className="my-1"
                                              controlId={"gender"}
                                            >
                                              <Form.Label column sm="5">
                                                Upload File  <sup style={{color:'red'}}>*</sup>
                                              </Form.Label>
                                              <Col
                                                sm="7"
                                                style={{
                                                  padding: "0 12px 0 0",
                                                }}
                                              >
                                                <div
                                                  className={
                                                    classes.listingEditListingEditFormGroup
                                                  }
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <div>
                                                    <fieldset
                                                      onChange={(event) => {
                                                        setLastUpdate("Yes");
                                                        if(event.target.value === "No") {
                                                          setFieldValue(
                                                            `uploadDocument`,
                                                            []
                                                          );
                                                        } else {
                                                          setFieldValue(
                                                            `uploadDocument`,
                                                            [{
                                                              documentType: "",
                                                              particular: "",
                                                              uploadDoc: "",
                                                            },]
                                                          );
                                                        }
                                                        setFieldValue(
                                                          `uploadFile`,
                                                          event.target.value
                                                        );
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        checked={
                                                          values?.uploadFile ===
                                                          "Yes"
                                                        }
                                                        name="Yes"
                                                        value="Yes"
                                                      />
                                                      <span
                                                        className={
                                                          classes.genderRadioButtonLabele
                                                        }
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        Yes
                                                      </span>
                                                      <input
                                                        type="radio"
                                                        checked={
                                                          values?.uploadFile ===
                                                          "No"
                                                        }
                                                        name="uploadFile"
                                                        value="No"
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      />
                                                      <span
                                                        className={
                                                          classes.genderRadioButtonLabele
                                                        }
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                       No
                                                      </span>
                                                    </fieldset>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Form.Group>
                                            <br />
                        </Grid>
                      </Grid>
                      <br />

                      {values?.uploadFile === "Yes" && (
                                    <>
                                    <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          className={
                                            classes.quotationInnerGridRight
                                          }
                                        >
                                          <Form.Group
                                            as={Row}
                                            className="mb-1"
                                            controlId={"sow"}
                                          >
                                            <Form.Label
                                              column
                                              sm="12"
                                              className="fc-dropdown-label"
                                            >
                                              Upload Document
                                              <sup style={{ color: "red" }}>
                                                *
                                              </sup>
                                            </Form.Label>
                                          </Form.Group>

                                          <Form.Group
                                            as={Row}
                                            className="mb-1"
                                            controlId={"selectsow"}
                                          >
                                            <Col sm="12">
                                              <div
                                                className="fc-dropdown-container"
                                                style={{ padding: "10px" }}
                                              >
                                                <Box
                                                  component={Grid}
                                                  display={{
                                                    md: "flex",
                                                  }}
                                                >
                                                  <FieldArray
                                                    name={`uploadDocument`}
                                                    render={(arrayHelpers) => (
                                                      <Grid
                                                      container
                                                      columns={12}>
                                                        {values?.uploadDocument
                                                          ?.length > 0 &&
                                                          values?.uploadDocument?.map(
                                                            (
                                                              uploc,
                                                              uploadIndex
                                                            ) => {
                                                              return (
                                                                <>
                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={4}
                                                                    style={{paddingRight: 5}}
                                                                  >
                                                                    <CustomDropdown
                                                                      label="Document Type"
                                                                      fieldOptions={
                                                                        documentTypeList
                                                                      }
                                                                      fieldValue={
                                                                        uploc?.documentType
                                                                      }
                                                                      fieldHandleChange={(
                                                                        value
                                                                      ) => {
                                                                        setFieldValue(
                                                                          `uploadDocument.[${uploadIndex}].documentType`,
                                                                          value.value
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Grid>

                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={3}
                                                                    style={{paddingRight: 5}}
                                                                  >
                                                                    <InputText
                                                                      label="Particular"
                                                                      fieldName="orderName"
                                                                      fieldValue={
                                                                        uploc?.particular
                                                                      }
                                                                      fieldHandleChange={(
                                                                        event
                                                                      ) =>
                                                                        setFieldValue(
                                                                          `uploadDocument.[${uploadIndex}].particular`,
                                                                          event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                    />
                                                                  </Grid>

                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={4}
                                                                      style={{paddingRight: 5}}
                                                                  >
                                                                    <CustomUploadFile
                                                                      label="Upload Documents"
                                                                      uploadDoc={
                                                                        uploc?.uploadDoc
                                                                      }
                                                                      uploadType="single"
                                                                      fieldHandleChange={(
                                                                        e
                                                                      ) => {
                                                                      
                                                                        setFieldValue(
                                                                          `uploadDocument.[${uploadIndex}].uploadDoc`,
                                                                            e
                                                                              .currentTarget
                                                                              .files?.[0]
                                                                        );
                                                                      }}
                                                                      fieldDeleteChange={(
                                                                        index
                                                                      ) => {
                                                                        
                                                                        setFieldValue(
                                                                          `uploadDocument.[${uploadIndex}].uploadDoc`,
                                                                          ""
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={1}
                                                                    style={{display:'flex', alignItems:'center', justifyContent:'center'}}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        width:
                                                                          "2px",
                                                                        display:
                                                                          "flex",
                                                                        flexDirection:
                                                                          "column",
                                                                        justifyContent:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      <IconButton
                                                                        onClick={() =>
                                                                          arrayHelpers?.push({
                                                                            documentType: "",
                                                                            particular: "",
                                                                            uploadDoc: "",
                                                                          },)
                                                                        }
                                                                        style={{
                                                                          paddingBottom: 0,
                                                                          paddingTop: 0,
                                                                        }}
                                                                      >
                                                                        <AddCircleIcon
                                                                          style={{
                                                                            fontSize:
                                                                              "14px !important",
                                                                          }}
                                                                        
                                                                        />
                                                                      </IconButton>
                                                                      {values
                                                                        ?.uploadDocument
                                                                        ?.length >
                                                                        1 &&
                                                                      uploadIndex ===
                                                                        values
                                                                          ?.uploadDocument
                                                                          .length -
                                                                          1 ? (
                                                                        <IconButton
                                                                          onClick={() =>
                                                                            arrayHelpers?.remove(
                                                                              uploadIndex
                                                                            )
                                                                          }
                                                                          style={{
                                                                            paddingBottom: 0,
                                                                            paddingTop: 0,
                                                                          }}
                                                                        >
                                                                          <RemoveCircleOutlineIcon
                                                                            style={{
                                                                              fontSize:
                                                                                "14px !important",
                                                                            }}
                                                                            className={
                                                                              classes.modalHeaderIcon
                                                                            }
                                                                          />
                                                                        </IconButton>
                                                                      ) : null}
                                                                    </div>
                                                                  </Grid>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                      </Grid>
                                                    )}
                                                  />
                                                </Box>
                                              </div>
                                            </Col>
                                          </Form.Group>
                                        </Grid>
                                    </>
                                  )}
                                        

                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.quotationInnerGridRight}
                      >
                        <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                          <Form.Label
                            column
                            sm="12"
                            className="fc-dropdown-label"
                          >
                            Select Order Template Scope Of Work
                            <sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-1"
                          controlId={"selectsow"}
                        >
                          <Col sm="12">
                            {/* Scope of Work Component Has to  be */}
                            {values?.selectedTemplate?.length > 0 && (
                              <div
                                className="fc-dropdown-container"
                                style={{ padding: "10px" }}
                              >
                                <Box
                                  component={Grid}
                                  display={{ xs: "none", md: "flex" }}
                                >
                                  <Grid container columns={12}>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task Name</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task type</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task category</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task section</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Task detail</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Work type</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Days</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Sequence</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Amount</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Govt. Dept</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Special Instruction</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={1}>
                                      <p>Tag Name</p>
                                    </Grid>
                                  </Grid>
                                </Box>
                                {values?.selectedTemplate?.map(
                                  (value, index) => {
                                    return (
                                      <>
                                        {value?.taskList?.map((val, i) => {
                                          return (
                                            <Grid container columns={12}>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task Name
                                                  </p>
                                                </Box>

                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Form.Check
                                                    type="checkbox"
                                                    name="scopeOfWork"
                                                    value={val?.id}
                                                    checked={
                                                      handleOrderTemplateChecked(
                                                        values?.scopeOfWork,
                                                        val
                                                      )
                                                    }
                                                    onClick={(e) => {
                                                 
                                                      const currVal =
                                                        values.scopeOfWork || [];
                                                      if (e.target.checked) {
                                                        let obj = {
                                                          Id: value.id,
                                                          Name: val.Service_Task_Name,
                                                          Task_Type:
                                                            val.Task_Type_Name,
                                                          Task_Category:
                                                            val.Task_Category,
                                                          Days: val?.Nos_Task_Days,
                                                          Sequance:
                                                            val?.Task_Sequence,
                                                          Amount:
                                                            val?.Task_Amount,
                                                          Govt_Department:
                                                            val.Govt_Dept_Name,
                                                          Special_Instruction:
                                                            val.Special_Instruction,
                                                          Task_Section:
                                                            val?.Single_Multi_Task_Type,
                                                          Task_Detail:
                                                            val.Task_Details,
                                                          Work_Type:
                                                            val.Work_Type,
                                                          Tag_Id: Number(
                                                            val.Tag_Id
                                                          ),
                                                          Tag_Name:
                                                            val.Tag_Name,
                                                          Template_Name:
                                                            value.label,
                                                          Template_Id: value.id,
                                                          Service_Task_Id:
                                                            val?.Service_Task_Id,
                                                        };
                                                        currVal.push(obj);
                                                      } else {
                                                        let isValueExist =
                                                          currVal.findIndex(
                                                            (doc) =>
                                                              doc.Service_Task_Id ===
                                                              val.Service_Task_Id
                                                          );
                                                        currVal.splice(
                                                          isValueExist,
                                                          1
                                                        );
                                                      }
                                                      setSowList(currVal);
                                                      setFieldValue(
                                                        "scopeOfWork",
                                                        currVal
                                                      );
                                                    }}
                                                    disabled={true}
                                                  />

                                                  <div
                                                    className="fc-input-container"
                                                    style={{
                                                      marginRight: 3,
                                                      marginLeft: 3,
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <input
                                                      name={"Task Name"}
                                                      value={
                                                        val?.Service_Task_Name
                                                      }
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          `selectedTemplate.${[
                                                            index,
                                                          ]}.taskList.${[
                                                            i,
                                                          ]}.Service_Task_Name`,
                                                          e.target.value
                                                        );
                                                        let fina = [...sowList];
                                                        let sowIndex =
                                                          fina?.findIndex(
                                                            (item) =>
                                                              item.Service_Task_Id ===
                                                              val.Service_Task_Id
                                                          );
                                                        fina[sowIndex] = {
                                                          ...fina[sowIndex],
                                                          Name: e.target.value,
                                                        };
                                                        setSowList(fina);
                                                      }}
                                                      disabled={true}
                                                      type={"text"}
                                                      className={`fc-input-style`}
                                                    />
                                                  </div>
                                                </div>
                                              </Grid>

                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task type
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Task type"}
                                                    value={val?.Task_Type_Name}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Type_Name`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Type:
                                                        value.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                     isDisabled={true}
                                                    options={taskTypeDropdown}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task category
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Task category"}
                                                    value={val?.Task_Category}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Category`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Category:
                                                        value.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                     isDisabled={true}
                                                    options={taskCategoryDropdown}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task section
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Task section"}
                                                    value={
                                                      val?.Single_Multi_Task_Type
                                                    }
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Single_Multi_Task_Type`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Section:
                                                        value.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    isDisabled={true}
                                                    options={taskSectionDropdown}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Task detail
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Task Details"}
                                                    value={val?.Task_Details}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Details`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Task_Detail:
                                                          e.target.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    type={"text"}
                                                    disabled={true}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Work Type
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Work Type"}
                                                    value={val?.Work_Type}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Work_Type`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Work_Type:
                                                        value.value
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    options={workTypeDropdown}
                                                    isDisabled={true}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Days
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Days"}
                                                    value={val?.Nos_Task_Days}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Nos_Task_Days`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Days: parseInt(
                                                          e.target.value
                                                        ),
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    disabled={true}
                                                    type={"number"}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                                {val?.Nos_Task_Days < 1 && (
                                                    <span style={{ color: "red" }}>
                                                        Required!
                                                  </span>
                                                )}
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Sequence
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Sequence"}
                                                    value={val?.Task_Sequence}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Sequence`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Sequance: parseInt(
                                                          e.target.value
                                                        ),
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                     disabled={true}
                                                    type={"text"}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                                {isSequenceExist(val?.Task_Sequence, sowList, val?.Service_Task_Id) && (
                                                  <span style={{color:'red', fontSize: 12}}>
                                                    Sequence already exists!
                                                  </span>
                                                )}
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Amount
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Task Amount"}
                                                    value={val?.Task_Amount}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Amount`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Amount: e.target.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                     disabled={true}
                                                    type={"text"}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Govt. Dept
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-dropdown-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <CustomSelect
                                                    name={"Govt. Dept"}
                                                    value={String(val?.Govt_Dept_Name)}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Govt_Dept_Name`,
                                                        value.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Govt_Department:
                                                          value.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    isDisabled={true}
                                                    options={govtDeptDropdown}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Special Instruction
                                                  </p>
                                                </Box>
                                                <div
                                                  className="fc-input-container"
                                                  style={{ marginRight: 3 }}
                                                >
                                                  <input
                                                    name={"Special Instrcution"}
                                                    value={
                                                      val?.Special_Instruction
                                                    }
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Special_Instruction`,
                                                        e.target.value
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Special_Instruction:
                                                          e.target.value,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    disabled={true}
                                                    type={"text"}
                                                    className={`fc-input-style`}
                                                  />
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} sm={12} md={1}>
                                                <Box
                                                  component={Grid}
                                                  display={{ md: "none" }}
                                                >
                                                  <p
                                                    style={{
                                                      margin: 0,
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    Tag Name
                                                  </p>
                                                </Box>
                                                <div className="fc-dropdown-container">
                                                  <CustomSelect
                                                    value={Number(val?.Tag_Id)}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Tag_Id`,
                                                        Number(value.value)
                                                      );
                                                      setFieldValue(
                                                        `selectedTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Tag_Name`,
                                                        value.label
                                                      );
                                                      let fina = [...sowList];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Tag_Id: Number(
                                                          value.value
                                                        ),
                                                        Tag_Name: value.label,
                                                      };
                                                      setSowList(fina);
                                                    }}
                                                    isDisabled={true}
                                                    options={tagNames}
                                                  />
                                                </div>
                                              </Grid>
                                            </Grid>
                                          );
                                        })}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                        <span style={{ color: "red" }}>
                          {errors?.scopeOfWork &&
                            touched?.scopeOfWork &&
                            errors?.scopeOfWork}
                        </span>
                      
                       
                      </Grid>
                    </div>
                    <br />
                  </Grid>
                </Grid>
                <br />
                <br />
              </div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <FinalizeNOFOrder
                  msg={"Are you sure?"}
                  onSubmit={handleSubmit}
                  setOpenModal={setOpenModal}
                  setIsSaveAndContinue={setIsSaveAndContinue}
                />
              </Modal>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(EditNOFOrderForm);
