import React, { useContext, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import previewFormStyles from "../../Preview/previewStyle";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { SupportDataContext } from "../../../../../provider/context";
import CloseIcon from "@mui/icons-material/Close";
import LoaderClock from "../../../../lodar/LoaderClock";
import ReactHtmlParser from "react-html-parser";
import "../../../../listing/listingTab/TodayAlert/styles.css";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getLocalData } from "../../../../../services/services";
import axios from "axios";

const ViewCourseDetail = ({ classes }) => {
  const {
    handleModaleClose,
    operationInfo,
  } = useContext(SupportDataContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    p: 4,
    height: "auto",
    paddingTop: "0px",
    minHeight: "650px",
    maxHeight: "650px",
    overflowY: "scroll",
  };
  const [myData, setMyData] = useState();

  console.log("operationInfo", operationInfo)
  console.log(operationInfo)
  const documentArray = [];
  const scopeOfWorkArray = []

  return (
    <>
      {


        <div className={classes.divModal}>

          <Box sx={style}>

            <IconButton
              style={{ backgroundColor: "#fffffff0" }}
              className={classes.headerIconCancle}
              onClick={() => {
                // setButtonText("Add Quotation / Proposal");
                // setEditView("");
                handleModaleClose();
                // setSAData([]);
                // // getServiceAgreement();
                // // getServiceAgreementVT();
                // setEditForm(false);
              }}
            >
              <CloseIcon
                className={classes.headerIcon}
                style={{ marginTop: "0px", fontSize: "25px" }}
              />
            </IconButton>

            <Grid container spacing={2} columns={12}>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "-30px",
                  marginBottom: "-30px",
                  paddingBottom: "10px",
                }}
              >
                <div className={classes.headingContainer}>
                  <span className={classes.modalHeader}>
                    {" "}
                    {"View Course"}{" "}
                  </span>
                </div>
                <br/>
                <div className={classes.headingContainer}>
                  <span className={classes.modalHeader}>
                    {" "}
                    {"View Course Details"}{" "}
                  </span>
                </div>
                <div
                  style={{ marginBottom: "10px" }}
                  className={classes.followUpFormModalFlexDivContaner}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex" }}
                      className={classes.followUpFormInnerGridLeft}
                    >
                      <Grid
                        xs={3}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        Course Title
                      </Grid>
                      <Grid
                        xs={9}
                        style={{ height: "auto" }}
                        className={classes.lastCellData}
                      >
                        {operationInfo?.course_title?.toString()}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex" }}
                      className={classes.followUpFormInnerGridLeft}
                    >
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        Course Level
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.cellData}
                      >
                        {operationInfo?.course_level}
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        Course Created By & Role
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.lastCellData}
                      >
                        {operationInfo.created_by} / {operationInfo?.created_by_role}

                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex" }}
                      className={classes.followUpFormInnerGridLeft}
                    >
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        Created Date
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.cellData}
                      >
                        {moment(operationInfo.created_date).format("DD-MM-YYYY hh:mm:ss")}

                      </Grid>
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        Status
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.lastCellData}
                      >
                        {
                          <span style={{}}>{operationInfo?.status}</span>
                        }

                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex" }}
                      className={classes.followUpFormInnerGridLeft}
                    >
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        State
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.cellData}
                      >
                        {operationInfo.state}

                      </Grid>
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                        Formation Type
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.lastCellData}
                      >
                        {
                          <span style={{}}>{operationInfo?.formation_type}</span>
                        }

                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex" }}
                      className={classes.followUpFormInnerGridLeft}
                    >
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                     { operationInfo?.formation_type === "Foundation" ? "Foundation Name"
                     : operationInfo?.formation_type === "Freezone" ? "Freezone Name"
                     : operationInfo?.formation_type === "Offshore" ? "Offshore Name" : "Formation Type Name"
                     }
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.cellData}
                      >
                        {operationInfo?.formation_type === "Foundation" ? operationInfo.foundation_name :
                        operationInfo?.formation_type === "Freezone" ? operationInfo.fz_name
                        : operationInfo?.formation_type === "Offshore" ? operationInfo.offshore_name
                        :
                        "N/A"}

                      </Grid>
                      <Grid
                        xs={4}
                        style={{ fontWeight: "600", height: "auto" }}
                        className={classes.cellData}
                      >
                       Description
                      </Grid>
                      <Grid
                        xs={4}
                        style={{ height: "auto" }}
                        className={classes.lastCellData}
                      >
                        {ReactHtmlParser(operationInfo?.description)}

                      </Grid>
                    </Grid>
                    {/* <Grid
                    item
                    xs={12}
                    style={{ display: "flex" }}
                    className={classes.followUpFormInnerGridLeft}
                  >
                    <Grid
                      xs={3}
                      style={{ height: "auto", fontWeight: "600", }}
                      className={classes.lastCellData}
                    >
                      Description
                    </Grid>
                    <Grid
                      xs={9}
                      style={{ height: "auto" }}
                      className={classes.lastCellData}
                    >
                    
                    </Grid>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex" }}
                    className={classes.followUpFormInnerGridLeft}
                  >
                    <Grid
                      xs={3}
                      style={{ height: "auto", fontWeight: "600", }}
                      className={classes.lastCellData}
                    >
                      Video Link
                    </Grid>
                    <Grid
                      xs={9}
                      style={{ height: "auto" }}
                      className={classes.lastCellData}
                    >
                      {operationInfo?.VDO_link?.map((item, i) => {
                        return (
                          <>
                            <button className="btn-view" onClick={() => window.open(item, '_blank')}>{i+1}-View Link</button>
                            {", "}
                          </>
                        )
                      })}
                    </Grid>
                  </Grid>
                  </Grid>

                </div>
               
               
                <div className={classes.headingContainer}>
                  <span className={classes.modalHeader}>
                    {" "}
                    {"View Documents Details"}{" "}
                  </span>
                </div>
                <div
                  style={{ marginBottom: "10px" }}
                  className={classes.followUpFormModalFlexDivContaner}
                >
                   <Grid container>
                     <Grid
                         item
                         xs={12}
                         style={{ display: "flex" }}
                         className={classes.followUpFormInnerGridLeft}
                     >
                      
                         <Grid xs={6}
                             style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                             className={classes.cellData}>
                             Document Type
                         </Grid>
                         <Grid xs={6}
                             style={{ fontWeight: "600", height: "auto", textAlign: "center" }}
                             className={classes.lastCellData}>
                             Document File
                         </Grid>
                     </Grid>
                      {
                       operationInfo?.Document?.map((ele) => {
                              return (
                                  <Grid
                                      item
                                      xs={12}
                                      style={{ display: "flex" }}
                                      className={classes.followUpFormInnerGridLeft}
                                  >
                                      <Grid xs={6}
                                          style={{ height: "auto", textAlign: "center" }}
                                          className={classes.cellData}>
                                          {ele?.document_type}
                                      </Grid>
                                     
                                      <Grid xs={6}
                                          style={{ height: "auto", textAlign: "center" }}
                                          className={classes.lastCellData}>
                                          <button className="btn-view" onClick={() => window.open(ele?.document)}>View Doc</button>
                                      </Grid>
                                  </Grid>
                              )
                          })
                      }
                </Grid>
                </div>


                <br />
              </Grid>
            </Grid>
          </Box>
        </div>


      }

    </>
  );
};

export default withStyles(previewFormStyles)(ViewCourseDetail);
