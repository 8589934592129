import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaAdn,
  FaStop,
  FaStopCircle,
  FaCheckDouble, FaReply
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ApprovalIcon from '@mui/icons-material/Approval';
import GradingIcon from '@mui/icons-material/Grading';
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PreviewIcon from '@mui/icons-material/Preview';
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import { useFormik, Formik } from "formik";
import LoaderClock from "../../../lodar/LoaderClock";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader';
import CommentIcon from '@mui/icons-material/Comment';
import { getTotalLength, getTotalLengthForSavedFilters } from "../../../../utils/Filters";
import { handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';







const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequestedCompletionOrders = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  fitlerBadge
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    message, severity,
    isOpen, setIsOpen,
    getAdminRequestedCompletedOrderListgDataApi, adminRequestedCompletedOrderListgData,
    getOrderDropDownApi,
    getOrderDropDownForCompany,
    setIsCompleteOrderBtnDisable,
    setSalesInfo, loading
  } = useContext(SupportDataContext);

  const {
    setStart_Date
   } = useContext(DataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const todayDate = moment().format("YYYY-MM-DD");
  const [reqFromDate, setReqFromDate] = useState("");
  const [reqToDate, setReqToDate] = useState("");

  useEffect(() => {
    handleListingSavedSearch();
  }, []);


  useEffect(() => {
    getAdminRequestedCompletedOrderListgDataApi(reqFromDate, reqToDate);
  }, [reqToDate]);

  const ScrollTop = () => {
    window.scrollTo({
      top: -40,
      left: 0,
      behavior: "smooth",
    });
  };

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };

  const customActionBtnRenderer = (params) => {

    return (
     
        <div >
          <>
            <CustomIconButton
              onClick={() => {
                handleModalTableOpen("Completion Order Approval", params.data);
                setButtonText("Completion Order Approval");
                ScrollTop();
                setOperationInfo(params.data);
              }}
              disabled={
                params.data.Status==="Completed" ? true:false
              }
              title={"Completion Order Approval"}
              icon={<FaCheckDouble style={{ margin: "0", fontSize: "30px" }} />}
              className="btnActionAlignment"
            />
         <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Add Comments", params.data);
              setButtonText("Add Comments");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setEditModalPopper(false)
              setIsCompleteOrderBtnDisable(true);
            }}
            title={"Add / View Comments"}
            icon={
              <>
                < CommentIcon />
              </>}
            className="btnActionAlignment"
          />
        {
            params?.data?.old_or_new_order === 1 && (
              <>
                <CustomIconButton
                  title="View NOF"
                  width={"auto"}
                  icon={
                    <>
                      <PreviewIcon />
                    </>
                  }
                  onClick={() => {
                    if (params?.data?.from_inquiry_or_company === "Company") {
                      setOperationInfo(params.data);
                      setSalesInfo(params?.data);
                      getOrderDropDownForCompany(params.data);
                      setButtonText("View NOF Company");
                      // setModalValue("viewforcompany");
                      // setOpenModal(true);
                      handleModalOpen();
                    } else {
                      setButtonText("View NOF Inquiry");
                      // setModalValue("viewforinquiry");
                      setSalesInfo(params?.data);
                      getOrderDropDownApi(params.data);
                      setOperationInfo(params.data);
                      // setOpenModal(true);
                      handleModalOpen();
                    }
                  }}
                />

                <CustomIconButton
                  title="Dowload NOF"
                  width={"auto"}
                  icon={
                    <>
                      <SimCardDownloadIcon />
                    </>
                  }
                  onClick={() => {
                    window.open(params?.data?.NOF_Download_Path, "_blank");
                  }}
                />

              </>
            )}
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(true);
            }}
            title={"View Company Document"}
            width={"auto"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
      </>
      </div>
    )
  }

  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <div>
        <CustomIconButton
              onClick={() => {
                handleModalTableOpen("Completion Order Approval", params.data);
                setButtonText("Completion Order Approval");
                ScrollTop();
                setOperationInfo(params.data);
              }}
              disabled={
                params.data.Status==="Completed" ? true:false
              }
              title={"Completion Order Approval"}
              icon={<FaCheckDouble style={{ margin: "0", fontSize: "30px" }} />}
              className="btnActionAlignment"
            />
          {/* {
            params?.data?.old_or_new_order === 0 && (
              <>
                <CustomIconButton
                  title="Freeze Order Approval"
                  width={"auto"}
                  icon={
                    <>
                      <FaStopCircle style={{ margin: "0", fontSize: "19px" }} />
                    </>
                  }
                  onClick={() => {
                    handleModalTableOpen("Freeze Order Approval", params.data)
                    setButtonText("Freeze Order Approval");
                    ScrollTop();
                    setOperationInfo(params.data);
                    setIsCompleteOrderBtnDisable(true);

                  }}
                  disabled={
                    params.data.Order_Status === "Admin Approval Pending" ?
                      true : false
                  }
                />
              </>
            )} */}

          {upperIcons &&
            upperIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    disabled={
                      params.data.Order_Status === "Admin Approval Pending" ?
                        item.btnText === "Completion Order Approval" ? true : false : false
                    }
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params.data);
                      setButtonText(item.btnText);
                      // scrollToTop()
                      ScrollTop();
                      setOperationInfo(params.data);
                      setIsCompleteOrderBtnDisable(true);
                    }}
                    title={item.btnText}
                    size="small"
                    style={{
                      border: "1px solid #eb6e12",
                      borderRadius: "5px",
                      fontSize: "12px",
                      marginRight: "6px",
                      color: "#fff",
                      background: "#eb6e12",
                      height: "30px",
                      marginTop: "3px",
                      width: "30px",
                    }}
                    className="btnActionAlignment"
                    icon={item.icon}
                  />
                </>
              );
            })}
          {lowerIcons &&
            lowerIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params.data);
                      setButtonText(item.btnText);
                      ScrollTop();
                      setOperationInfo(params.data);
                      setIsCompleteOrderBtnDisable(true);
                    }}
                    title={item.btnText}
                    icon={item.icon}
                    className="btnActionAlignment"
                  />
                </>
              );
            })}
          {
            params?.data?.old_or_new_order === 1 && (
              <>
                <CustomIconButton
                  title="View NOF"
                  width={"auto"}
                  icon={
                    <>
                      <PreviewIcon />
                    </>
                  }
                  onClick={() => {
                    if (params?.data?.from_inquiry_or_company === "Company") {
                      setOperationInfo(params.data);
                      setSalesInfo(params?.data);
                      getOrderDropDownForCompany(params.data);
                      setButtonText("View NOF Company");
                      // setModalValue("viewforcompany");
                      // setOpenModal(true);
                      handleModalOpen();
                    } else {
                      setButtonText("View NOF Inquiry");
                      // setModalValue("viewforinquiry");
                      setSalesInfo(params?.data);
                      getOrderDropDownApi(params.data);
                      setOperationInfo(params.data);
                      // setOpenModal(true);
                      handleModalOpen();
                    }
                  }}
                />

                <CustomIconButton
                  title="Dowload NOF"
                  width={"auto"}
                  icon={
                    <>
                      <SimCardDownloadIcon />
                    </>
                  }
                  onClick={() => {
                    window.open(params?.data?.NOF_Download_Path, "_blank");
                  }}
                />

              </>
            )}
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(true);
            }}
            title={"View Company Document"}
            width={"auto"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
           <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Revised NOF Listing", params?.data);
              setButtonText("Revised NOF Listing");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params?.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"Revised NOF Listing"}
            icon={
              <>
                < DriveFileRenameOutlineIcon />
              </>}
            className="btnActionAlignment"
           disabled={data?.Revised_NOF_Exist === "Yes" ? false : true}
          />
        </div>
      </>
    );
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString.slice(0, 10).split("-");
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };


  const columnDefs = [
    {
      headerName: "Sr.",
      field: "Serial_Number",
      //cellRenderer: "agGroupCellRenderer",
      //checkboxSelection: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
      width: 75,
      pinned: "left",
      filter: false
    },
    {
      headerName: "Actions",
      field: "",
      filter: false,
      // checkboxSelection: true,
      cellRenderer: actionBtnRenderer,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      minWidth: 250,
      pinned: "left",
    },
    {
      headerName: "Order No",
      field: "Order_No",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: "auto",
      pinned: "left",
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
    },
    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Order Sub Type",
      field: "order_sub_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 230,

    },
   
    {
      headerName: "Requested Completion Date",
      field: "Support_Req_Completion_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",      
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 210,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Support_Req_Completion_Date === "" || params?.data?.Support_Req_Completion_Date === "NA" ? "" :
          moment(params?.data?.Support_Req_Completion_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Closure On Time",
      field: "Closure_On_Time",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 170,
    },
    {
      headerName: "Old / New Order",
      field: "old_or_new_order",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
              <>
                 {params?.data?.old_or_new_order === 1 ? "New" : params?.data?.old_or_new_order === 0 ? "Old" : ""}<br />
               
              </>
          </>
        )
      }
    },
    {
      headerName: "Project Name",
      field: "project_name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 210,
    },
    {
      headerName: "Order Sequence / No of Order",
      field: "order_sequence",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
              <>
                <span style={{fontWeight: "bold"}}>Order Sequence:</span> {params?.data?.order_sequence}<br />
                <span style={{fontWeight: "bold"}}>No. of Order:</span> {params?.data?.no_of_orders}
              </>
          </>
        )
      }
    },
    // {
    //   headerName: "Point of Contact",
    //   field: "Point_of_Contact",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
    //   width: 300,
    // },
    {
      headerName: "Order Creation Date",
      field: "Order_Created_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Order_Created_Date === "" || params?.data?.Order_Created_Date === "NA" ? "" :
          moment(params?.data?.Order_Created_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Support Assign Date",
      field: "Support_Assign_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Support_Assign_Date === "" || params?.data?.Support_Assign_Date === "NA" ? "" :
          moment(params?.data?.Support_Assign_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Order_Start_Date === "" || params?.data?.Order_Start_Date === "NA" ? "" :
          moment(params?.data?.Order_Start_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Expected_Complition_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Order_Expected_Complition_Date === "" || params?.data?.Order_Expected_Complition_Date === "NA" ? "" :
          moment(params?.data?.Order_Expected_Complition_Date).format("DD-MM-YYYY hh:mm:ss a")
          }
          </>
        )
      }
    },
   
    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 180,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Followup",
      field: "Last_Followups",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 270,
      cellRenderer: (params) => {
        params = params?.data?.Last_Followups;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
    },
    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "No Data" ? (
              <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
            ) : params?.data?.Freeze_Approval}
          </>
        )
      }
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Work in Progress",
      field: "Work_in_Progress",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Work_in_Progress;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "State",
      field: "State",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },

    {
      headerName: "Trade Names",
      field: "Trade_Names",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);



  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    {
      id: 4,
      title: "On-Time Clousre",
      value: "Yes",
      key: "Closure_On_Time",
    },
    {
      id: 5,
      title: "Overdue Clousre",
      value: "No",
      key: "Closure_On_Time",
    },
    // {
    //   id: 2,
    //   title: "UnderProcess",
    //   value: "Underprocess",
    //   key: "Order_Status",
    // },
    // {
    //   id: 3,
    //   title: "Accounts Rejected",
    //   value: "Accounts Rejected",
    //   key: "Order_Status",
    // },
    // {
    //   id: 4,
    //   title: "Freeze Approval Pending",
    //   value: "Freeze Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 5,
    //   title: "Freezed",
    //   value: "Order Freezed",
    //   key: "Order_Status",
    // },
    // {
    //   id: 6,
    //   title: "Completion Approval Pending",
    //   value: "Completion Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Admin Approval Pending",
    //   value: "Admin Approval Pending",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Pending From Accounts",
    //   value: "Pending From Accounts",
    //   key: "Order_Status",
    // },
    // {
    //   id: 7,
    //   title: "Overdue",
    //   value: "Overdue",
    //   key: "Order_Status",
    // },
  ];


  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };



  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id")
  }
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#eb6e12';
  box-shadow: inset '0px 0px 0px 7px #eb6e12';
  margin-top: 10%;
  `;
  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      {
        fitlerBadge ? (
          <>
            <div style={{ display: "flex", marginLeft: "10px", marginTop: "30px", paddingBottom: "15px" }}>
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={adminRequestedCompletedOrderListgData}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                {adminRequestedCompletedOrderListgData?.length > 0 &&
                  filters.map((val, i) => {

                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(adminRequestedCompletedOrderListgData, val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: 'Times New Roman'
                        }}
                        onClick={() => handleDefaultFilters(gridRef, setDefaultFilterValue, val.title, val.value, val.key)}
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const keyValue = val.Search_Filter?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")?.split(",")[0].replace("[", "").replace("]", "")
                    const keyss = val.Search_Filter.replace(/(.*?)=(.*?)(?:\|\||$)/g)
                    const getNewKey = keyss?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")
                    const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                    const data = JSON.parse(keyss);
                    let myValuesArr = [];
                    let myKeysArr = [];
                    // Iterate over keys and get values
                    for (const key in data) {
                      if (data.hasOwnProperty(key)) {
                        const values = data[key].values;
                        myValuesArr.push(values)
                        myKeysArr.push(key)
                      }
                    }
                    const valuesArray = [].concat(...myValuesArr);
                    let resultArray = ""
                    if (getNewKe2) {
                      const resultString = getNewKe2[1];
                      resultArray = resultString.split(',');
                    }
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLengthForSavedFilters(adminRequestedCompletedOrderListgData, myValuesArr, myKeysArr)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(gridRef, setDefaultFilterValue, val.Search_Name, keyValue?.trim(), val.Search_Filter);
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>

          </>

        ) : null
      }
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={adminRequestedCompletedOrderListgData?.length}
      />
       <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-35px",
              }}
            >
              <div style={{
                display: "flex",
                marginBottom: "10px",
                width: "37%"
              }}>
                <label htmlFor="company" className="labelFormInputModal" >
                  <span>
                  Select Requested Completion Date:
                    
                  </span>
                </label>
  
                <div className="selectContainerModal" style={{ width: "300px" }}>
                 
                  <DateRangePickerComp
                        custom={true}
                        fieldHandleChange={(value, picker) => {
                          setReqFromDate(
                            moment(picker.startDate).format("YYYY-MM-DD")
                          );
                          setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                          setStart_Date(moment(picker.startDate).format("YYYY-MM-DD"))
                        }}
                      />
                 
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  
            </form>
          )}
        </Formik>
        <span style={{color: "red", fontSize: "13px", marginLeft: "10px"}}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>

      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
         {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={loading ? null : adminRequestedCompletedOrderListgData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          //detailCellRenderer={renderExpandableRow}
          columnTypes={columnTypes}
          animateRows={true}
          // onSelectionChanged={onRowSelected}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
          overlayLoadingTemplate={"Please Wait Loading Data..."}
          overlayNoRowsTemplate={"No Data...."}
          groupDisplayType="groupRows"
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowGroupHidesColumns={true}
          rowGroupPanelShow="always"
          autoGroupColumnDef= {
            { 
             headerName: "Company Name",
             minWidth: 300,
             cellStyle: { border: "1px solid #fff", borderBottom: "2px solid #fff", fontWeight: "bold"},
             flex: 1,
             // suppresRowCount: true,
             cellRendererParams: {
              
               // suppressCount: true,
             }
           }
           }
        ></AgGridReact>
         )} 
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} >
        <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
   
    </div>
  );
};

export default RequestedCompletionOrders;
