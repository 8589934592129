import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from 'react-bootstrap/Alert';
import {
  FaStopCircle,
 
} from "react-icons/fa";



const FreezeOrderLogTable = ({ classes }) => {
  const {
    message,
    getFreezeOrderListApi,
    freezeOrderList,
    setButtonText,
    setOperationInfo,
    handleModalOpen,
    operationInfo,
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  useEffect(() => {
    getFreezeOrderListApi();
  }, []);

  const customActionBtnRenderer = (params) => {
    return (
      <>
        <CustomIconButton
          onClick={() => {
            handleModalOpen();
            setButtonText("Freeze Order Approval");
            // scrollToTop()
            //ScrollTop();
            //setOperationInfo(params.data);
          }}
          disabled={
            params.data.freeze_unfreeze==="Freezed" ? true:false
          }
          title={"Freeze Order Approval"}
          icon={<FaStopCircle style={{ margin: "0", fontSize: "25px" }} />}
          className="btnActionAlignment"
        />
      </>
    )
  }

  const [columnDefs] = useState([
    {
      headerName: "Sr.",
      field: "Serial_Number",
      width: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Action",
      field: "action",
      filter: false,
      width: 100,
      pinned: "left",
      cellStyle: { border: "1px solid #fff",textAlign: "center", paddingTop: 0 },
      cellRenderer: customActionBtnRenderer,
    },
    // {
    //   headerName: "Order No",
    //   field: "order_no",
    //   filter: "agTextColumnFilter",
    //   width: 140,
    //   pinned: "left",
    //   cellStyle: { border: "1px solid #fff" },
    // },
    {
      headerName: "Freeze Status",
      field: "freeze_unfreeze",
      filter: "agTextColumnFilter",
      width: 170,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      filter: "agTextColumnFilter",
      width: 170,
      pinned: "left",
      cellRenderer: (params)=>{
        return(
          <>
          {operationInfo?.Order_Status}
          </>
        )
      },
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Freezed/Unfreezed Date",
      field: "log_date",
      filter: "agTextColumnFilter",
      width: 180,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Approved/Rejected By",
      field: "approved_rejected_by",
      filter: "agTextColumnFilter",
      width: 200,
      //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Approval/Rejected Date",
      field: "approval_rejected_date",
      filter: "agDateColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
    },
    {
      headerName: "Approval/Rejected Status",
      field: "approval_rejected_status",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
    },
    {
      headerName: "Reason",
      field: "reason",
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
    },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
      flex: 1,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>

      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >


          {/* <Alert show={show} variant="success" sx={{ width: "100%" }} onClose={() => setShow(false)} dismissible>
                 {message} 
               </Alert> */}

          <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
            ref={gridRef}
            rowData={freezeOrderList}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default withStyles(modalTableStyles)(FreezeOrderLogTable);
