import React, { useContext, useState, useEffect } from "react";
import listingFormStyles from "./listingFormStyles/listingFormStyles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@mui/material";
import "./listingFormStyles/listingFormStyles.css";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Checkbox from "@mui/material/Checkbox";
import { DataContext } from "../../../provider/context";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addDays } from "date-fns";
import Modal from "@mui/material/Modal";
import { Formik, Field, getIn } from "formik";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const getStyles = (errors, fieldName) => {
  if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};
export const CustomSelectLongWidth = ({
  onChange,
  options,
  value,
  maxWidth,
  onClick,
  disableField,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FFD580",
      },
    }),
    control: () => ({
      width: 250,
      display: "flex",
      marginBottom: "-5px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: maxWidth ? maxWidth : "450px",
      },
      cursor: "pointer",
    }),
    menuList: (base) => ({
      ...base,
      marginBottom: "-10px",
      marginTop: "-7px",
      // border: '1px solid #d3d3d3',
      height: "170px",
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "6px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : null;
  };

  return (
    <>
      <Select
        key={`my_unique_select_key__${value}`}
        value={defaultValue(options, value) || ""}
        onChange={(value) => onChange(value)}
        options={options}
        // styles={getStyles(validateError, valiDateFiled)}
        className="selectDropdpenReact"
        onClick={onClick}
        styles={customStyles}
        minMenuHeight={100}
        maxMenuHeight={200}
        disabled={disableField}
      />
    </>
  );
};
export const CustomSelected = ({
  onFocus,
  ref,
  id,
  name,
  isLoading,
  onChange,
  options,
  value,
  onBlur,
  onClick,
}) => {
  const customStyles2 = {
    option: (provided, state) => ({
      ...provided,

      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "#FFD580",
      },

      // minHeight: 200,
      // maxHeight: 200,
    }),
    control: (base) => ({
      // ...base,
      width: "100%",

      display: "flex",
      marginBottom: "-5px",
      cursor: "pointer",
    }),
    menuList: (base) => ({
      ...base,
      marginTop: "-7px",
      border: "1px solid #d3d3d3",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const defaultValue = (options, value) => {
    let foundValue;
    if (typeof value === "string" && value?.length !== 0) {
      return (foundValue = options?.find((option) => option.label === value));
    } else if (typeof value === "number" && value?.length !== NaN) {
      return (foundValue = options?.find((option) => option.value === value));
    } else {
      return options ? options?.find((option) => option.value === value) : null;
    }
  };
  return (
    <>
      <Select
        ref={ref}
        id={id}
        name={name}
        isLoading={isLoading}
        // menuPlacement="auto"
        value={defaultValue(options, value)}
        onChange={(value) => onChange(value)}
        options={options}
        className="selectDropdpenReact"
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        width="100%"
        styles={customStyles2}
        minMenuHeight={100}
        maxMenuHeight={150}
        menuPlacement="auto"
      />
    </>
  );
};
export const CustomSelect = ({
  isLoading,
  onChange,
  options,
  value,
  name,
  onClick,handleBlur,isDisabled,isClearable, placeholder
}) => {
  const customStyles2 = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : state.isDisabled ? "grey" : "black",
      backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
      cursor: "pointer",
      zIndex: 9999,
      "&:hover": {
        backgroundColor: "#FFD580",
      },
    }),
    control: (base) => ({
      width: "100%",
      display: "flex",
      marginBottom: "-5px",
      cursor: "pointer",
    }),
    menuList: (base) => ({
      ...base,
      marginTop: "-7px",
      border: "1px solid #d3d3d3",
      zIndex: 9999
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.8 : 1;
      const transition = "opacity 300ms";
      const color = "black"

      return { ...provided, opacity, transition, color };
    },
  };

  const defaultValue = (options, value) => {
    return options ? options?.find((option) => option.value === value) : null;
  };
  return (
    <>
      <Select
        key={`my_unique_select_key__${value}`}
        isLoading={isLoading}
        value={defaultValue(options, value) || ""}
        onChange={(value) => onChange(value)}
        name={name}
        options={options}
        className="selectDropdpenReact"
        onClick={onClick}
        width="100%"
        styles={customStyles2}
        minMenuHeight={100}
        maxMenuHeight={250}
        menuPlacement="bottom"
        onBlur={handleBlur}
        isDisabled={isDisabled}
        isClearable={isClearable}
        placeholder={placeholder}
      />
    </>
  );
};
export const CustomMultiSelect = ({
  isLoading,
  defualtValue,
  onChange,
  options,
  value,
  disableField,
  midWidth,
  validateError,
  selectBorderColorValidation,
  isMulti,
  width,
  longWidth,
}) => {
  const defaultValue = (options, value) => {
    let data = options
      ? options.find((option, i) => {
          const data1 = value && value.length > 0 ? value[i].value : value;
          const dataCheck =
            option.value === value
              ? option.value === value
              : option.value === data1;
          return dataCheck;
        })
      : "";

    return data;
  };
  const customStyles3 = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FFD580",
      },
    }),
    control: (base) => ({
      // ...base,
      // flex: 1,
     
      width: width ? width : 131,
      display: "flex",
      marginBottom: "-5px",
      paddingBottom: "4px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        // width: '420px'
        width: longWidth ? longWidth : "250px",
      },
      cursor: "pointer",
    }),
    menuList: (base) => ({
      ...base,
      marginBottom: "-10px",
      marginTop: "-7px",
      // border: '1px solid #d3d3d3',
      height: "190px",
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "6px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    multiValueLabel: (base) => ({
      ...base,
      whiteSpace: "break-spaces",
      overflow: "hidden",
      flexWrap: 'break-spaces',
  }),
  };

  return (
    <>
      <Select
        defaultValue={defualtValue}
        value={value}
        onChange={(value) => onChange(value)}
        options={options}
        // styles={getStyles(validateError, valiDateFiled)}
        className="selectDropdpenReactMulti"
        isMulti
        styles={customStyles3}
        minMenuHeight={100}
        maxMenuHeight={150}
        isSearchable={!disableField}
        isClearable={!disableField}
        isDisabled={disableField}
        isLoading={isLoading}
        components={
          disableField
            ? {
                Menu: () => null,
                MenuList: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                MultiValueRemove: () => null,
              }
            : {}
        }
      />
    </>
  );
};
export const CustomMultiSelectLongWidth = ({
  onChange,
  options,
  value,
  defualtValue,
  valiDateFiled,
  validateError,
  selectBorderColorValidation,
  isMulti,
}) => {
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };
  const customStyles4 = {
    option: (provided, state) => ({
      ...provided,

      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#FFD580",
      },
    }),
    control: () => ({
      width: 250,
      display: "flex",
      marginBottom: "-5px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "450px",
      },
      cursor: "pointer",
    }),
    menuList: (base) => ({
      ...base,
      marginBottom: "-10px",
      marginTop: "-7px",
      border: "1px solid #d3d3d3",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  return (
    <>
      <Select
        value={defaultValue(options, value)}
        onChange={(value) => onChange(value)}
        defaultValue={defualtValue}
        options={options}
        // styles={getStyles(validateError, valiDateFiled)}
        className="selectDropdpenReact"
        isMulti
        styles={customStyles4}
        minMenuHeight={150}
        maxMenuHeight={200}
      />
    </>
  );
};

const ListingForm = ({ classes, listingApiData }) => {
  const {
    setOpenForm,
    setFormApiMsg,
    setFormApiMsgError,
    apiData,
    setApiData,
    formApiMsg,
    setExpiredMsg,
  } = useContext(DataContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [metchingEmail, setMetchingEmail] = useState("");
  const [leadNourceName, setLeadNourceName] = useState("");
  const [leadSourceName, setLeadSourceName] = useState("");
  const [apiOptionData, setApiOptionData] = useState([]);
  const [leadPageUrlValue, setLeadPageUrlvalue] = useState("");
  const [referralSourceID, setReferralSourceID] = useState("");
  const [consultantIdvalue, setConsultantIdvalue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalContect, setOpenModalContect] = useState(false);
  const [inquirIdFromEmailModal, setInquirIdFromEmailModal] = useState("");
  const [leadDateFromEmailModal, setLeadDateFromEmailModal] = useState("");
  const [consultantNameFromEmailModal, setConsultantNameFromEmailModal] =
    useState("");
  const [convesationStatusFromEmailModal, setConvesationStatusFromEmailModal] =
    useState("");
  const [autoFillFormBoolen, setAutoFillFormBoolen] = useState(false);
  const [autoFillFormWhenEmailIsExist, setAutoFillFormWhenEmailIsExist] =
    useState({
      city: "",
      Client_Full_Name: "",
      gender: "",
      // Client_DOB: new Date(),
      Client_Country: "",
      Client_Alternate_Mobile: "",
      Client_Address: "",
      Client_Passport_No: "",
      Client_Occupation: "",
      Client_Existing_Company_Name: "",
      Client_Existing_Company_Address: "",
      Client_Existing_Website: "",
      Client_Skype_id: "",
    });
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [genderData, setGenderData] = useState("");
  const [fullNameData, setFullNameData] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternetContactNumver, setAlternetContactNumver] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientPassportNo, setClientPassportNo] = useState("");
  const [clientOccupation, setClientOccupation] = useState("");
  const [clientExistingCompanyName, setClientExistingCompanyName] =
    useState("");
  const [clientExistingCompanyAddress, setClientExistingCompanyAddress] =
    useState("");
  const [clientExistingWebsite, setClientExistingWebsite] = useState("");
  const [clientSkypeid, setClientSkypeid] = useState("");
  const [clientCity, setClientCity] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientDateOfBirth, setClientDateOfBirth] = useState("");
  const [clientCountryId, setClientCountryId] = useState("");
  const [clientCountryName, setClientCountryName] = useState("");
  const [typeOfActivityDropDown, setTypeOfActivityDropDown] = useState("");
  const appURL = window.location.href;

  // const data_Inquiry_Id = listingApiData[0]?.Inquiry_Id
  //   ? listingApiData[0]?.Inquiry_Id?.substring(2)
  //   : null;
  // const parseInt_inq_data = parseInt(data_Inquiry_Id);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "2px solid #eb6e12",
    outline: "none",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    padding: "25px 32px 20px 32px",
  };

  //

  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClick = async (findEmailVal, event) => {
    const filterData = findEmailVal.toString().toLowerCase();
    const filteredEmail = listingApiData.filter((row) => {
      return (
        row.Client_Email_id &&
        row.Client_Email_id.toLowerCase().includes(filterData)
      );
    });

    if (filteredEmail[0]?.Client_Email_id === findEmailVal) {
      setAnchorEl(event.currentTarget);
      setOpenModal(true);
      handleSetStateData(filteredEmail[0]);
    } else {
      setAnchorEl(null);
      setOpenModal(false);
    }
  };
  const handleSetStateData = (filteredEmail) => {
    setMetchingEmail(filteredEmail?.Client_Email_id);
    setInquirIdFromEmailModal(filteredEmail?.Inquiry_Id);
    setLeadDateFromEmailModal(filteredEmail?.Enquiry_Date);
    setConsultantNameFromEmailModal(filteredEmail?.Consultant_Name);
    setConvesationStatusFromEmailModal(filteredEmail?.Conversation_Status);
    setGenderData(filteredEmail?.Client_Gender);
    setFullNameData(filteredEmail?.Client_Full_Name?.split(" "));
    setContactNumber(filteredEmail?.Client_Mobile_No);
    setAlternetContactNumver(filteredEmail?.Client_Alternate_Mobile);
    setClientAddress(filteredEmail?.Client_Address);
    setClientPassportNo(filteredEmail?.Client_Passport_No);
    setClientOccupation(filteredEmail?.Client_Occupation);
    setClientExistingCompanyName(filteredEmail?.Client_Existing_Company_Name);
    setClientExistingCompanyAddress(
      filteredEmail?.Client_Existing_Company_Address
    );
    setClientExistingWebsite(filteredEmail?.Client_Existing_Website);
    setClientSkypeid(filteredEmail?.Client_Skype_id);
    setClientCountryId(filteredEmail?.Client_Country_id);
    setClientCountryName(filteredEmail?.Client_Country_Name);
    setClientCity(filteredEmail?.Client_City);
    setClientDateOfBirth(
      filteredEmail?.Client_DOB === "" ||
        filteredEmail?.Client_DOB === "0000-00-00"
        ? null
        : moment(filteredEmail?.Client_DOB).toDate()
    );
    setFirstNameData(fullNameData ? fullNameData[0] : "");
    setLastNameData(fullNameData ? fullNameData[1] : "");
  };

  useEffect(() => {
    setFirstNameData(fullNameData ? fullNameData[0] : "");
    setLastNameData(fullNameData ? fullNameData[1] : "");
  }, [fullNameData]);

  const handleFindContact = (val, event) => {
    const filterData = listingApiData.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] !== null)
          return item[key]
            .toString()
            .toLowerCase()
            .includes(val.toString().toLowerCase());
      });
    });

    setInquirIdFromEmailModal(filterData[0]?.Inquiry_Id);
    setLeadDateFromEmailModal(filterData[0]?.Enquiry_Date);
    setConsultantNameFromEmailModal(filterData[0]?.Consultant_Name);
    setConvesationStatusFromEmailModal(filterData[0]?.Conversation_Status);

    if (filterData[0]?.Client_Mobile_No === val) {
      setAnchorEl2(event.currentTarget);
      setOpenModalContect(true);
      handleSetStateDataContact(filterData[0]);
    } else {
      setAnchorEl2(null);
      setOpenModalContect(false);
    }
  };

  const handleSetStateDataContact = (filteredEmail) => {
    setMetchingEmail(filteredEmail?.Client_Email_id);
    setInquirIdFromEmailModal(filteredEmail?.Inquiry_Id);
    setLeadDateFromEmailModal(filteredEmail?.Enquiry_Date);
    setConsultantNameFromEmailModal(filteredEmail?.Consultant_Name);
    setConvesationStatusFromEmailModal(filteredEmail?.Conversation_Status);
    setGenderData(filteredEmail?.Client_Gender);
    setFullNameData(filteredEmail?.Client_Full_Name?.split(" "));
    setAlternetContactNumver(filteredEmail?.Client_Alternate_Mobile);
    setClientAddress(filteredEmail?.Client_Address);
    setClientPassportNo(filteredEmail?.Client_Passport_No);
    setClientOccupation(filteredEmail?.Client_Occupation);
    setClientExistingCompanyName(filteredEmail?.Client_Existing_Company_Name);
    setClientExistingCompanyAddress(
      filteredEmail?.Client_Existing_Company_Address
    );
    setClientExistingWebsite(filteredEmail?.Client_Existing_Website);
    setClientSkypeid(filteredEmail?.Client_Skype_id);
    setClientCountryId(filteredEmail?.Client_Country_id);
    setClientCountryName(filteredEmail?.Client_Country_Name);
    setClientDateOfBirth(
      filteredEmail?.Client_DOB === "" ||
        filteredEmail?.Client_DOB === "0000-00-00"
        ? null
        : moment(filteredEmail?.Client_DOB).toDate()
    );
    setFirstNameData(fullNameData ? fullNameData[0] : "");
    setLastNameData(fullNameData ? fullNameData[1] : "");
  };

  const refUrl =
    referralSourceID === ""
      ? `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&lead_source_name=${leadSourceName?.label}`
      : `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&lead_source_name=Referral&Referral_Source_ID=${referralSourceID.value} `;
  const typeOfCompUrl = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Mainland`;
  const typeOfCompUrlFreezone = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Freezone`;
  const typeOfCompUrlOffshore = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Offshore`;
  const typeOfCompUrlAccountingAndVATServices = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Accounting & VAT Services`;
  const typeOfCompUrlRealEstate = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Real Estate`;
  const typeOfCompUrlServicedOffices = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Serviced Offices`;
  const typeOfCompUrlNotApplicable = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=Not Applicable`;
  const typeOfCompPROServicesUrl = `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&type_of_company_formation_type=PRO Services`;
  const mainUrl =
    leadSourceName === ""
      ? `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST`
      : `https://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&lead_source_name=${leadSourceName?.label}`;

  const urlChanging =
    leadNourceName?.label === "Mainland"
      ? typeOfCompUrl
      : leadNourceName?.label === "Freezone"
      ? typeOfCompUrlFreezone
      : leadNourceName?.label === "Offshore"
      ? typeOfCompUrlOffshore
      : leadNourceName?.label === "Accounting & VAT Services"
      ? typeOfCompUrlAccountingAndVATServices
      : leadNourceName?.label === "Real Estate"
      ? typeOfCompUrlRealEstate
      : leadNourceName?.label === "Serviced Offices"
      ? typeOfCompUrlServicedOffices
      : leadNourceName?.label === "Not Applicable"
      ? typeOfCompUrlNotApplicable
      : leadNourceName?.label === "PRO Services"
      ? typeOfCompPROServicesUrl
      : referralSourceID?.value
      ? refUrl
      : mainUrl;

  const __token = localStorage.getItem("token");
  const selectDropDownApi = async (e) => {
    await fetch(urlChanging, {
      method: "GET",
      headers: {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setApiOptionData(data?.Data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    selectDropDownApi();
  }, [leadNourceName, leadSourceName, referralSourceID]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;

  const conversation_status_Option = apiOptionData?.conversation_status?.map(
    (item, i) => {
      const obj = { value: i, label: item?.Conversation_Status };
      return obj;
    }
  );
  const lead_caption_Option = apiOptionData?.lead_caption?.map((item, i) => {
    const obj = {
      value: item?.lead_caption_name,
      label: item?.lead_caption_name,
    };
    return obj;
  });
  const lead_source_Option = apiOptionData?.lead_source?.map((item, i) => {
    const obj = {
      value: item?.lead_source_name,
      label: item?.lead_source_name,
    };
    return obj;
  });

  const campaign_name_Option = apiOptionData?.campaign_name?.map((item, i) => {
    const obj = { value: item?.Campaign_id, label: item?.Campaign_name };
    return obj;
  });
  const lead_record_through_Option = apiOptionData?.lead_record_through?.map(
    (item, i) => {
      const obj = {
        value: item?.Lead_Received_Through,
        label: item?.Lead_Received_Through,
      };
      return obj;
    }
  );
  const country_Option = apiOptionData?.country?.map((item, i) => {
    const obj = { value: item?.Country_Id, label: item?.country_name };
    return obj;
  });
  const type_of_company_Option = apiOptionData?.type_of_company?.map(
    (item, i) => {
      const obj = { value: item?.Formation_type, label: item?.Formation_type };
      return obj;
    }
  );
  const type_of_activity_Option = apiOptionData?.type_of_activity?.map(
    (item, i) => {
      const obj = {
        value: item?.Type_Of_Activity_Name,
        label: item?.Type_Of_Activity_Name,
      };
      return obj;
    }
  );

  const landing_Page_URLFind = (value) => {
    const filterData = value.label.toString().toLowerCase();
    const filteredLeadOageUrl = apiOptionData?.campaign_name.filter((url) => {
      return url.Campaign_name.toLowerCase().includes(filterData);
    });
    setLeadPageUrlvalue(filteredLeadOageUrl);
  };

  const Referral_Option = apiOptionData?.Referral?.map((item, i) => {
    const obj = {
      value: item?.Referral_Source_ID,
      label: `${item?.referral_source_name}`,
    };
    return obj;
  });
  const lead_sales_person_Option = apiOptionData?.lead_sales_person?.map(
    (item, i) => {
      const obj = { value: item?.Consultant_id, label: item?.Consultant_Name };
      return obj;
    }
  );
  const lead_type_Option = apiOptionData?.lead_type?.map((item, i) => {
    const obj = { value: item?.Lead_Type_Value, label: item?.Lead_Type_Name };
    return obj;
  });

  const existing_client_listingID_cp1_Option =
    apiOptionData?.Dropdown_CP_Inquiry_List_list?.map((item, i) => {
      const obj = {
        value: item?.Referral_Id,
        label: `${item?.Client_Name}(${item?.Referral_Id})`,
      };
      return obj;
    });
  const existing_client_listingID_cp2_Option =
    apiOptionData?.Dropdown_CP_Inquiry_List_list?.map((item, i) => {
      const obj = {
        value: item?.Company_Id,
        label: `${item?.Company_Name}(${item?.Company_Id})`,
      };
      return obj;
    });
  const existing_client_listingID_cp3_Option =
    apiOptionData?.Dropdown_CP_Inquiry_List_list?.map((item, i) => {
      const obj = {
        value: item?.Inquiry_Id,
        label: `${item?.Client_Name}(${item?.Inquiry_Id})`,
      };
      return obj;
    });

  const addNewFormPostApi = async (data) => {
    let dateFormatPayload = new Date(data.generation_date)
      .toISOString()
      .slice(0, 10);
    let dateFormatPayloadAssignDate =
      data.assign_date.toLocaleDateString("fr-CA");
    let dateFormatPayloadDateOfBirth =
      clientDateOfBirth === "" || clientDateOfBirth === null
        ? ""
        : clientDateOfBirth.toLocaleDateString("fr-CA");
    // let timeFormatPayload = `${new Date(data.generation_date).getHours()}:${new Date(data.generation_date).getMinutes()}:${new Date(data.generation_date).getSeconds()}`
    let timeFormatPayload = new Date(data.generation_date).toLocaleTimeString(
      "it-IT"
    );
    let timeAndDateFormate = `${dateFormatPayload} ${timeFormatPayload}`;

    const userINFO = {
      company_id: 1,
      lead_type: data?.lead_type,
      lead_caption: data.lead_caption,
      lead_source: data.lead_source,
      campaign_name: data.campaign_name,
      keyword: data.keyword,
      lead_record_through: data.lead_record_through,
      generation_date: timeAndDateFormate,
      lead_description: data.leadDescription,
      email: metchingEmail,
      first_name: firstNameData,
      last_name: lastNameData,
      gender: genderData,
      city: clientCity,
      contact_number: contactNumber,
      alternative_contact_number: alternetContactNumver,
      client_address: clientAddress,
      country: clientCountryId?.value
        ? clientCountryId?.value
        : clientCountryId,
      passport_no: clientPassportNo,
      occupation: clientOccupation,
      // "current_company": data.current_company,
      type_of_company: data.type_of_company,
      existing_company_name: clientExistingCompanyName,
      existing_company_address: clientExistingCompanyAddress,
      existing_website: clientExistingWebsite,
      skype_id: clientSkypeid,
      date_of_birth: dateFormatPayloadDateOfBirth,
      lead_sales_person: data?.lead_sales_person,
      mail_to_salesperson_client: data.mail_to_salesperson_client,
      sms_to_salesperson_client: data.sms_to_salesperson_client,
      assign_date: dateFormatPayloadAssignDate,
      consultant_id: data.consultant_id,
      client_exist: data.clientExist ? data.clientExist : "no",
      referral: data.referral,
      referral_id: data.referralId
        ? data.referralId
        : data.inquiryId
        ? data.inquiryId
        : data.existingClientCompanyId,
      existing_client_company_id: data.existingClientCompanyId,
      inquiry_Id: data.inquiryId,
      type_of_activity: data.type_of_activity,
      consultant_id: consultantIdvalue,
      landing_page_url: leadPageUrlValue[0]?.Landing_Page_URL.toString(),
    };

    await fetch(`http://flyingcolour.online/fcapp/api/add_inquiry/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: await JSON.stringify(userINFO),
    })
      .then((response) => response.json())
      .then((res) => {
        setFormApiMsg(res?.data?.msg);
        setFormApiMsgError(res?.message);
        setExpiredMsg(true);

        let dateFormatPayloadAdd = new Date(data.generation_date)
          .toISOString()
          .slice(0, 10);
        let dateFormatPayloadAssignDateAdd =
          data.assign_date.toLocaleDateString("fr-CA");
        let dateFormatPayloadDateOfBirthAdd =
          clientDateOfBirth === "" && clientDateOfBirth === null
            ? ""
            : new Date(clientDateOfBirth).toLocaleDateString("fr-CA");
        let timeFormatPayloadAdd = `${new Date(
          data.generation_date
        ).getHours()}:${new Date(data.generation_date).getMinutes()}:${new Date(
          data.generation_date
        ).getSeconds()}`;
        let timeAndDateFormateAdd = `${dateFormatPayloadAdd} ${timeFormatPayloadAdd}`;
        const userINFOAdd = {
          company_id: 1,
          Lead_Type: data?.lead_type?.label,
          Lead_Caption: data.lead_caption,
          Enquiry_Source: data.lead_source,
          Campaign_Name: data.campaign_name?.label,
          Keyword: data.keyword,
          Lead_Received_Through: data.lead_record_through,
          Generation_Date: timeAndDateFormateAdd,
          Lead_Description: data.leadDescription,
          Client_Email_id: metchingEmail,
          First_Name: firstNameData,
          Client_Full_Name: `${firstNameData} ${lastNameData}`,
          Last_Name: lastNameData,
          Client_Gender: genderData,
          Client_City: clientCity,
          Client_Mobile_No: contactNumber,
          alternative_contact_number: alternetContactNumver,
          Client_Address: clientAddress,
          Client_Country_Name: clientCountryId?.label
            ? clientCountryId?.label
            : clientCountryName,
          Client_Country_id: clientCountryId?.value
            ? clientCountryId?.value
            : clientCountryId,
          Client_Passport_No: clientPassportNo,
          Client_Occupation: clientOccupation,
          // "current_company": data.current_company,
          Type_of_Company: data.type_of_company,
          Client_Existing_Company_Name: clientExistingCompanyName,
          Client_Existing_Company_Address: clientExistingCompanyAddress,
          Client_Existing_Website: clientExistingWebsite,
          Client_Skype_id: clientSkypeid,
          Client_DOB: dateFormatPayloadDateOfBirthAdd,
          Consultant_Name: data.lead_sales_person?.label,
          Enquiry_Assigned_Date: dateFormatPayloadAssignDateAdd,
          consultant_id: data.consultant_id,
          client_exist: data.clientExist ? data.clientExist : "no",
          mail_to_salesperson_client: data.mail_to_salesperson_client,
          sms_to_salesperson_client: data.sms_to_salesperson_client,
          Enquiry_Referral_Source: data.referral,
          Enquiry_Referral_Source_Id: data.referralId
            ? `CP${data.referralId}`
            : data.inquiryId
            ? data.inquiryId
            : data.existingClientCompanyId,
          existing_client_company_id: data.existingClientCompanyId,
          // Inquiry_Id: `FC${parseInt_inq_data + 1}`,
          Type_OF_Activity: data.type_of_activity,
          Consultant_Id: consultantIdvalue,
          Landing_Page_URL: leadPageUrlValue[0]?.Landing_Page_URL.toString(),
          Conversation_Status: "Not Proceed",
          Enquiry_Status: "Assigned",
          Created_By: "kashif shah",
          Is_Expired: "Yes",
        };
        if (res?.data?.msg === "Listing added successfully.") {
          setApiData([userINFOAdd, ...apiData]);
          setOpenForm(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    setFormApiMsg(formApiMsg);
  }, [formApiMsg]);

  const options = [
    { id: "1", value: "chocolate", label: "Chocolate" },
    { id: "2", value: "strawberry", label: "Strawberry" },
    { id: "3", value: "vanilla", label: "Vanilla" },
    { id: "1", value: "chocolate", label: "Chocolate" },
    { id: "2", value: "strawberry", label: "Strawberry" },
    { id: "3", value: "vanilla", label: "Vanilla" },
    { id: "1", value: "chocolate", label: "Chocolate" },
    { id: "2", value: "strawberry", label: "Strawberry" },
    { id: "3", value: "vanilla", label: "Vanilla" },
    { id: "1", value: "chocolate", label: "Chocolate" },
    { id: "2", value: "strawberry", label: "Strawberry" },
    { id: "3", value: "vanilla", label: "Vanilla" },
  ];
  //

  // useEffect(() => {
  //     Formik?.setFieldValue("city", autoFillFormWhenEmailIsExist?.city);
  // }, [autoFillFormWhenEmailIsExist]);

  const handleRestFieds = () => {
    // setAutoFillFormWhenEmailIsExist({ city: '' })
    setMetchingEmail("");
    setFirstNameData("");
    setLastNameData("");
    setGenderData("");
    setClientCity("");
    setContactNumber("");
    setAlternetContactNumver("");
    setClientAddress("");
    setClientPassportNo("");
    setClientOccupation("");
    setClientExistingCompanyName("");
    setClientExistingCompanyAddress("");
    setClientExistingWebsite("");
    setClientSkypeid("");
    setClientDateOfBirth("");
    setClientCountryId("");
    setClientCountryName("");
  };

  return (
    <>
      <Formik
        // enableReinitialize
        initialValues={{
          lead_type: "",
          lead_caption: "",
          lead_source: "",
          campaign_name: "",
          landing_page_url: leadPageUrlValue[0]?.Landing_Page_URL,
          referral: "",
          referralId: "",
          existingClientCompanyId: "",
          inquiryId: "",
          keyword: "",
          lead_record_through: "",
          type_of_company: "",
          generation_date: "",
          currentCompany: "",
          leadDescription: "",

          first_name: firstNameData,
          last_name: "",
          email: "",
          clientExist: "",
          gender: "",
          city: autoFillFormBoolen ? autoFillFormWhenEmailIsExist?.city : "",
          // city: '',
          contact_number: autoFillFormBoolen
            ? autoFillFormWhenEmailIsExist?.Client_Mobile_No
            : "",
          alternative_contact_number: "",

          client_address: "",
          country: "",
          passport_no: "",
          occupation: "",
          existing_company_name: "",
          existing_company_address: "",
          existing_website: "",
          skype_id: "",
          date_of_birth: null,

          lead_sales_person: "",
          assign_date: "",
          mail_to_salesperson_client: false,
          sms_to_salesperson_client: false,
          type_of_activity: null,
        }}
        onSubmit={async (values) => {
          await addNewFormPostApi(values);
          // setOpenForm(false)
        }}
        validate={(values) => {
          let errors = {};

          if (!values.lead_type) {
            errors.lead_type = "";
          }
          if (!values.lead_caption) {
            errors.lead_caption = "";
          }
          if (!values.lead_source) {
            errors.lead_source = "";
          }
          if (!values.referral && values.lead_source === "Referral") {
            errors.referral = "";
          }
          if (
            !values.referralId &&
            values.lead_source === "Referral" &&
            values.referral === 1
          ) {
            errors.referralId = "";
          }

          if (!values.lead_record_through) {
            errors.lead_record_through = "";
          }
          if (!values.generation_date) {
            errors.generation_date = "";
          }

          if (!metchingEmail) {
            errors.metchingEmail = "";
          }
          if (!firstNameData) {
            errors.firstNameData = "";
          }
          if (!lastNameData) {
            errors.lastNameData = "";
          }

          if (!clientCountryId) {
            errors.clientCountryId = "";
          }
          if (!contactNumber) {
            errors.contactNumber = "";
          }

          if (!values.type_of_company) {
            errors.type_of_company = "";
          }
          if (
            !values.type_of_activity &&
            values.type_of_company !== "PRO Services"
          ) {
            errors.type_of_activity = "";
          }

          if (!values.lead_sales_person) {
            errors.lead_sales_person = "";
          }
          if (!values.assign_date) {
            errors.assign_date = "";
          }

          if (!values.leadDescription) {
            errors.leadDescription = "";
          }

          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <div className={classes.ButtonDivListingTabListing}>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    type="submit"
                  >
                    <span style={{ marginTop: "2px" }}>Save</span>
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.CancleButtonButton}
                    onClick={() => setOpenForm(false)}
                  >
                    <span style={{ marginTop: "2px" }}>Cancel</span>
                  </Button>
                </div>

                <div className={classes.ImgDivListingListing}>
                  <div className={classes.BorderSpacingUpperListing}></div>

                  <div className={classes.listingFormConater}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          borderRight: "1px solid #d3d3d3",
                          paddingTop: "20px",
                          marginBottom: "-30px",
                          paddingLeft: "3px",
                        }}
                      >
                        <div style={{padding: "9px",height:'513px'}}>
                          <span className={classes.upperHeadingListingForm}>
                            <BusinessIcon
                              className={classes.listingFormIcons}
                            />
                            Lead Information
                          </span>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Lead Type
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div
                                  style={getStyles(errors, values.lead_type)}
                                >
                                  <CustomSelect
                                    options={lead_type_Option}
                                    // options={options}
                                    value={values.lead_type}
                                    onChange={(value) =>
                                      setFieldValue("lead_type", value.value)
                                    }
                                    valiDateFiled={values.lead_type}
                                    validateError={errors}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Lead Caption
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div
                                  style={getStyles(errors, values.lead_caption)}
                                >
                                  <CustomSelect
                                    options={lead_caption_Option}
                                    value={values.lead_caption}
                                    onChange={(value) =>
                                      setFieldValue("lead_caption", value.value)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Lead Source
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div
                                  style={getStyles(errors, values.lead_source)}
                                >
                                  <CustomSelect
                                    options={lead_source_Option}
                                    value={values.lead_source}
                                    onChange={(value) => {
                                      setLeadNourceName(value);
                                      setLeadSourceName(value);
                                      setReferralSourceID("");
                                      setFieldValue("lead_source", value.value);

                                      if (values.lead_source !== "Referral") {
                                        setFieldValue("referral", "");
                                        setFieldValue("referralId", "");
                                        setFieldValue(
                                          "existingClientCompanyId",
                                          ""
                                        );
                                        setFieldValue("inquiryId", "");
                                      } else {
                                        setFieldValue("referral", "");
                                        setFieldValue("referralId", "");
                                        setFieldValue(
                                          "existingClientCompanyId",
                                          ""
                                        );
                                        setFieldValue("inquiryId", "");
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          {values.lead_source === "Referral" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"lead-generation-date"}
                            >
                              <Form.Label column sm="5">
                                Referral
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(errors, values.referral)}
                                  >
                                    <CustomSelect
                                      options={Referral_Option}
                                      value={values.referral}
                                      onChange={(value) => {
                                        setReferralSourceID(value);
                                        setFieldValue("referral", value.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.referral === 1 &&
                            values.lead_source === "Referral" && (
                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"lead-generation-date"}
                              >
                                <Form.Label column sm="5">
                                  Referral Id
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                      border: "0.1px solid #d3d3d3",
                                      padding: "1px 0px",
                                    }}
                                  >
                                    <div
                                      style={getStyles(
                                        errors,
                                        values.referralId
                                      )}
                                    >
                                      <CustomSelect
                                        options={
                                          existing_client_listingID_cp1_Option
                                        }
                                        value={values.referralId}
                                        menuPortalTargetAata={document.body}
                                        onChange={(value) => {
                                          setFieldValue(
                                            "referralId",
                                            value.value
                                          );
                                          setFieldValue(
                                            "existingClientCompanyId",
                                            ""
                                          );
                                          setFieldValue("inquiryId", "");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Form.Group>
                            )}

                          {values.referral === 2 &&
                            values.lead_source === "Referral" && (
                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"lead-generation-date"}
                              >
                                <Form.Label column sm="5">
                                  Existing Client Company Id
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                      border: "0.1px solid #d3d3d3",
                                      padding: "1px 0px",
                                    }}
                                  >
                                    <div>
                                      <CustomSelect
                                        options={
                                          existing_client_listingID_cp2_Option
                                        }
                                        value={values.existingClientCompanyId}
                                        onChange={(value) => {
                                          setFieldValue(
                                            "existingClientCompanyId",
                                            value.value
                                          );
                                          setFieldValue("inquiryId", "");
                                          setFieldValue("referralId", "");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Form.Group>
                            )}

                          {values.referral === 2 &&
                            values.lead_source === "Referral" && (
                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"lead-generation-date"}
                              >
                                <Form.Label column sm="5">
                                  Inquiry Id
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                  <div
                                    style={{
                                      marginBottom: "5px",
                                      border: "0.1px solid #d3d3d3",
                                      padding: "1px 0px",
                                    }}
                                  >
                                    <div>
                                      <CustomSelect
                                        options={
                                          existing_client_listingID_cp3_Option
                                        }
                                        value={values.inquiryId}
                                        onChange={(value) => {
                                          setFieldValue(
                                            "inquiryId",
                                            value.value
                                          );
                                          setFieldValue(
                                            "existingClientCompanyId",
                                            ""
                                          );
                                          setFieldValue("referralId", "");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Form.Group>
                            )}

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Campaign Name
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div>
                                  <CustomSelect
                                    options={campaign_name_Option}
                                    value={values.campaign_name}
                                    onChange={(value) => {
                                      landing_Page_URLFind(value);
                                      setFieldValue("campaign_name", value.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Landing Page URL
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    disabled
                                    placeholder="Landing Page URL"
                                    name="landing_page_url"
                                    value={
                                      leadPageUrlValue[0]?.Landing_Page_URL
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Keyword
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="Keyword"
                                    name="keyword"
                                    value={values.keyword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Lead Record Through
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div
                                  style={getStyles(
                                    errors,
                                    values.lead_record_through
                                  )}
                                >
                                  <CustomSelect
                                    options={lead_record_through_Option}
                                    value={values.lead_record_through}
                                    onChange={(value) =>
                                      setFieldValue(
                                        "lead_record_through",
                                        value.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Lead Generation Date
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={getStyles(
                                  errors,
                                  values.generation_date
                                )}
                              >
                                <div style={{ border: "0.1px solid #d3d3d3" }}>
                                  <DatePicker
                                    selected={values.generation_date}
                                    onChange={(date) => {
                                      setFieldValue("generation_date", date);
                                    }}
                                    timeInputLabel="Time:"
                                    // timeInputLabel="Time:"
                                    dateFormat="dd-MM-yyyy h:mm:ss aa"
                                    // showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={15}
                                    showTimeSelect
                                    maxDate={addDays(new Date(), 0)}
                                    minDate={addDays(new Date(), -7)}
                                    placeholderText="Lead Generation Date"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          paddingTop: "20px",
                          marginBottom: "-30px",
                          paddingLeft: "0px",
                        }}
                      >
                        <div style={{padding: "9px",height:'513px'}}>
                          <span className={classes.upperHeadingListingForm}>
                            <AccountCircleIcon
                              className={classes.listingFormIcons}
                            />{" "}
                            Client Information
                          </span>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Email
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div style={getStyles(errors, metchingEmail)}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    aria-describedby={id}
                                    className={classes.listingFormInputMain}
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    value={metchingEmail}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      setMetchingEmail(value);
                                    }}
                                    onBlur={(e) =>
                                      handleClick(metchingEmail, e)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>
                          <Modal open={openModal}>
                            <Box sx={styleBox}>
                              <p>
                                Email already exists. Still want to continue
                                with same email?
                              </p>

                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Enq#
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {inquirIdFromEmailModal}
                                </div>
                              </div>
                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Lead Date
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {leadDateFromEmailModal
                                    ? leadDateFromEmailModal.slice(0, 10)
                                    : ""}
                                </div>
                              </div>
                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Consultant Name
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {consultantNameFromEmailModal}
                                </div>
                              </div>
                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Convesation Status
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {convesationStatusFromEmailModal}
                                </div>
                              </div>
                              <div className={classes.popperText}>
                                <Field
                                  type="radio"
                                  name="clientExist"
                                  value="yes"
                                  onClick={() => {
                                    setAutoFillFormBoolen(true);
                                    setAnchorEl(null);
                                    setOpenModal(false);
                                  }}
                                />
                                <label className={classes.radioButtonYes}>
                                  Yes
                                </label>
                                <Field
                                  type="radio"
                                  name="clientExist"
                                  value="no"
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setOpenModal(false);
                                    setFieldValue("email", "");
                                    handleRestFieds();
                                  }}
                                />{" "}
                                <label className={classes.radioButtonNo}>
                                  No
                                </label>
                              </div>
                            </Box>
                          </Modal>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"first-name"}
                          >
                            <Form.Label column sm="5">
                              First Name
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div style={getStyles(errors, firstNameData)}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="First Name"
                                    type="text"
                                    name="first_name"
                                    value={firstNameData}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      const re = /^[A-Za-z]+$/;
                                      if (value === "" || re.test(value)) {
                                        setFirstNameData(value);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Last Name
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div style={getStyles(errors, lastNameData)}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="Last Name"
                                    type="text"
                                    name="last_name"
                                    value={lastNameData}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      const re = /^[A-Za-z]+$/;
                                      if (value === "" || re.test(value)) {
                                        setLastNameData(value);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"gender"}
                          >
                            <Form.Label column sm="5">
                              Gender
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                className={
                                  classes.listingEditListingEditFormGroup
                                }
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <fieldset
                                    onChange={(event) =>
                                      setGenderData(event.target.value)
                                    }
                                  >
                                    <input
                                      type="radio"
                                      checked={genderData === "male"}
                                      name="gender"
                                      value="male"
                                    />
                                    <span
                                      className={
                                        classes.genderRadioButtonLabele
                                      }
                                      style={{ marginLeft: "5px" }}
                                    >
                                      Male
                                    </span>
                                    <input
                                      type="radio"
                                      checked={genderData === "female"}
                                      name="gender"
                                      value="female"
                                      style={{ marginLeft: "5px" }}
                                    />
                                    <span
                                      className={
                                        classes.genderRadioButtonLabele
                                      }
                                      style={{ marginLeft: "5px" }}
                                    >
                                      Female
                                    </span>
                                  </fieldset>
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Date Of Birth
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div>
                                <div style={{ border: "0.1px solid #d3d3d3" }}>
                                  <DatePicker
                                    selected={clientDateOfBirth}
                                    onChange={(date) =>
                                      setClientDateOfBirth(date)
                                    }
                                    // selected={values?.date_of_birth}
                                    // onChange={(date) => setFieldValue("date_of_birth", date)}
                                    maxDate={moment().toDate()}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Date Of Birth"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"country"}
                          >
                            <Form.Label column sm="5">
                              Country
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div style={getStyles(errors, clientCountryId)}>
                                  <CustomSelect
                                    style={getStyles(errors, clientCountryId)}
                                    options={country_Option}
                                    // value={values.country}
                                    value={clientCountryId}
                                    onChange={(value) => {
                                      setFieldValue("country", value);
                                      setClientCountryId(value.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"city"}
                          >
                            <Form.Label column sm="5">
                              City
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    className={classes.listingFormInputMain}
                                    placeholder="City"
                                    type="text"
                                    name="city"
                                    value={clientCity}
                                    // value={values?.city}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      setClientCity(value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Contact Number
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div  className={classes.demoDataCheck} style={getStyles(errors, contactNumber)}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="Contact Number"
                                    name="contact_number"
                                    // value={values.contact_number}
                                    value={contactNumber}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      const regex = /^[1-9][0-9]*$/;
                                      if (
                                        e.target.value === "" ||
                                        regex.test(value)
                                      ) {
                                        // setFieldValue("contact_number", value);
                                        setContactNumber(value);
                                      }
                                    }}
                                    onBlur={(e) => {
                                      handleFindContact(contactNumber, e);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>
                          <Modal open={openModalContect}>
                            <Box sx={styleBox}>
                              <p>
                                Contact Number already exists. Still want to
                                continue with same contact number?
                              </p>

                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Enq#
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {inquirIdFromEmailModal}
                                </div>
                              </div>
                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Lead Date
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {leadDateFromEmailModal
                                    ? leadDateFromEmailModal.slice(0, 10)
                                    : ""}
                                </div>
                              </div>
                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Consultant Name
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {consultantNameFromEmailModal}
                                </div>
                              </div>
                              <div className={classes.modelFlexDiv}>
                                <label className={classes.modalLable}>
                                  Convesation Status
                                </label>
                                <div className={classes.modalDatacontainer}>
                                  {convesationStatusFromEmailModal}
                                </div>
                              </div>

                              <div className={classes.popperText}>
                                <Field
                                  type="radio"
                                  name="clientExist"
                                  value="yes"
                                  onClick={() => {
                                    setAnchorEl2(null);
                                    setOpenModalContect(false);
                                  }}
                                />
                                <label className={classes.radioButtonYes}>
                                  Yes
                                </label>
                                <Field
                                  type="radio"
                                  name="clientExist"
                                  value="no"
                                  onClick={() => {
                                    setAnchorEl2(null);
                                    setFieldValue("contact_number", "");
                                    setOpenModalContect(false);
                                    handleRestFieds();
                                  }}
                                />{" "}
                                <label className={classes.radioButtonNo}>
                                  No
                                </label>
                              </div>
                            </Box>
                          </Modal>
                          {/* </Popper> */}
                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              Alternate Contact Number
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="Alternate Contact No."
                                    name="alternative_contact_number"
                                    //  value={values.alternative_contact_number}
                                    value={alternetContactNumver}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      const regex = /^[1-9][0-9]*$/;
                                      if (
                                        e.target.value === "" ||
                                        regex.test(value)
                                      ) {
                                        // setFieldValue("alternative_contact_number", value);
                                        setAlternetContactNumver(value);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              Client Address
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  height: "50px",
                                }}
                              >
                                <textarea
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    padding: "2px 10px",
                                  }}
                                  placeholder="Client Address"
                                  type="text"
                                  name="client_address"
                                  value={clientAddress}
                                  //   onChange={handleChange}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    setClientAddress(value);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </Col>
                          </Form.Group>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          borderLeft: "1px solid #d3d3d3",
                          paddingTop: "20px",
                          marginBottom: "-30px",
                          paddingLeft: "0px",
                        }}
                      >
                        <div style={{padding: "9px",height:'513px'}}>
                          <span className={classes.upperHeadingListingForm}>
                            {" "}
                            <InfoIcon
                              className={classes.listingFormIcons}
                            />{" "}
                            Additional Information
                          </span>
                          <br />
                          <br />

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"passport-no"}
                          >
                            <Form.Label column sm="5">
                              Passport No.
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder=" Passport No."
                                    name="passport_no"
                                    //  value={values.passport_no}
                                    value={clientPassportNo}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      const regex = /^(?!\s)[A-Za-z0-9\s]+$/;
                                      if (
                                        e.target.value === "" ||
                                        regex.test(value)
                                      ) {
                                        // setFieldValue("passport_no", value);
                                        // setAutoFillFormWhenEmailIsExist({ Client_Passport_No: value })
                                        setClientPassportNo(value);
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              Occupation
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="Occupation"
                                    type="text"
                                    name="occupation"
                                    // value={values.occupation}
                                    value={clientOccupation}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      // setAutoFillFormWhenEmailIsExist({ Client_Occupation: value })
                                      setClientOccupation(value);
                                    }}
                                    //  onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"country"}
                          >
                            <Form.Label column sm="5">
                              Type Of Company
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div
                                  style={getStyles(
                                    errors,
                                    values.type_of_company
                                  )}
                                >
                                  <CustomSelect
                                    options={type_of_company_Option}
                                    value={values.type_of_company}
                                    onChange={(value) => {
                                      setLeadNourceName(value);
                                      setFieldValue(
                                        "type_of_company",
                                        value.value
                                      );
                                      setFieldValue("type_of_activity", null);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          {values.type_of_company === "Mainland" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"country"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.type_of_company === "Freezone" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"country"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.type_of_company === "Offshore" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"country"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.type_of_company === "Not Applicable" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"country"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.type_of_company ===
                            "Accounting & VAT Services" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"country"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.type_of_company === "Real Estate" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"country"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          {values.type_of_company === "Serviced Offices" && (
                            <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"type-of-activity"}
                            >
                              <Form.Label column sm="5">
                                Type Of Activity
                              </Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                    border: "0.1px solid #d3d3d3",
                                    padding: "1px 0px",
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values.type_of_activity
                                    )}
                                  >
                                    <CustomSelect
                                      options={type_of_activity_Option}
                                      value={values.type_of_activity}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "type_of_activity",
                                          value.value
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                          )}

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              Existing Company Name
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="Company Name"
                                    type="text"
                                    name="existing_company_name"
                                    // value={values.existing_company_name}
                                    //  onChange={handleChange}
                                    value={clientExistingCompanyName}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      // setAutoFillFormWhenEmailIsExist({ Client_Existing_Company_Name: value })
                                      setClientExistingCompanyName(value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              Existing Company Address
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  height: "50px",
                                }}
                              >
                                <textarea
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    padding: "2px 10px",
                                  }}
                                  placeholder="Company Address"
                                  type="text"
                                  name="existing_company_address"
                                  //  value={values.existing_company_address} onChange={handleChange}
                                  value={clientExistingCompanyAddress}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    // setAutoFillFormWhenEmailIsExist({ Client_Existing_Company_Address: value })
                                    setClientExistingCompanyAddress(value);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              Existing website
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="existing_website"
                                    type="text"
                                    name="existing_website"
                                    //  value={values.existing_website} onChange={handleChange}
                                    // value={autoFillFormWhenEmailIsExist.Client_Existing_Website}
                                    value={clientExistingWebsite}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      // setAutoFillFormWhenEmailIsExist({ Client_Existing_Website: value })
                                      setClientExistingWebsite(value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"alternate-contact-number"}
                          >
                            <Form.Label column sm="5">
                              SkypeId
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div style={{ marginBottom: "5px" }}>
                                <div className={classes.demoDataCheck}>
                                  <input
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      padding: "4.6px 10px",
                                    }}
                                    placeholder="skypeId"
                                    type="text"
                                    name="skype_id"
                                    // value={values.skype_id} onChange={handleChange}
                                    // value={autoFillFormWhenEmailIsExist.Client_Skype_id}
                                    value={clientSkypeid}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      // setAutoFillFormWhenEmailIsExist({ Client_Skype_id: value })
                                      setClientSkypeid(value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                          borderLeft: "1px solid #d3d3d3",
                          paddingTop: "20px",
                          marginBottom: "-30px",
                          paddingLeft: "0px",
                        }}
                      >
                        <div style={{padding: "9px",height:'513px'}}>
                          <span className={classes.upperHeadingListingForm}>
                            {" "}
                            <AssignmentIcon
                              className={classes.listingFormIcons}
                            />{" "}
                            User Assignment{" "}
                          </span>
                          <br />
                          <br />
                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-sales-person"}
                          >
                            <Form.Label column sm="5">
                              Lead Sales Person
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={{
                                  marginBottom: "5px",
                                  border: "0.1px solid #d3d3d3",
                                  padding: "1px 0px",
                                }}
                              >
                                <div
                                  style={getStyles(
                                    errors,
                                    values.type_of_activity
                                  )}
                                >
                                  <CustomSelect
                                    options={lead_sales_person_Option}
                                    value={values.lead_sales_person}
                                    onChange={(value) => {
                                      setConsultantIdvalue(value?.value);
                                      setFieldValue("lead_sales_person", value.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={""}
                          >
                            <Form.Label column sm="5">
                              
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <br />
                              <div className={classes.formCheckBoxDiv}>
                                <span className={classes.formCheckBoxDataSpan}>
                                  <Checkbox
                                    size="small"
                                    style={{ padding: "0px", color: "#eb6e12" }}
                                    value={values.mail_to_salesperson_client}
                                    type="checkbox"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          "mail_to_salesperson_client",
                                          true
                                        );
                                      } else
                                        setFieldValue(
                                          "mail_to_salesperson_client",
                                          false
                                        );
                                    }}
                                  />{" "}
                                  <span className={classes.textSpan}>
                                    {" "}
                                    Mail to salesperson and client
                                  </span>
                                </span>
                              </div>
                              <br />
                              <div className={classes.formCheckBoxDiv}>
                                <span className={classes.formCheckBoxDataSpan1}>
                                  <Checkbox
                                    size="small"
                                    style={{ padding: "0px", color: "#eb6e12" }}
                                    value={values.sms_to_salesperson_client}
                                    type="checkbox"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setFieldValue(
                                          "sms_to_salesperson_client",
                                          true
                                        );
                                      } else
                                        setFieldValue(
                                          "sms_to_salesperson_client",
                                          false
                                        );
                                    }}
                                  />{" "}
                                  <span className={classes.textSpan}>
                                    {" "}
                                    SMS to salesperson and client
                                  </span>
                                </span>
                              </div>
                              <br />
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"lead-generation-date"}
                          >
                            <Form.Label column sm="5">
                              Assign Date
                            </Form.Label>
                            <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                              <div
                                style={getStyles(errors, values.assign_date)}
                              >
                                <div style={{ border: "0.1px solid #d3d3d3" }}>
                                  <DatePicker
                                    placeholderText="Assign Date"
                                    selected={values.assign_date}
                                    onChange={(date) =>
                                      setFieldValue("assign_date", date)
                                    }
                                    dateFormat="yyyy-MM-dd"
                                    maxDate={moment().toDate()}
                                    minDate={moment().toDate()}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Form.Group>

                          <div className={classes.formGroup}>
                              <label className={classes.labelForm}>
                                Lead Description
                              </label>
                              <br />
                              <div
                                style={getStyles(
                                  errors,
                                  values.leadDescription
                                )}
                                className={classes.RichTextEditerDiv}
                              >
                                <CKEditor
                                  data={`${values.leadDescription}`}
                                  editor={ClassicEditor}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("leadDescription", data);
                                  }}
                                  onReady={(editor) => {
                                    if(editor === null){}else{
                                    // You can store the "editor" and use when it is needed.
                                    editor?.editing.view.change((writer) => {
                                      writer.setStyle(
                                        "min-height",
                                        "100px",
                                        editor?.editing.view.document.getRoot()
                                      );
                                      writer.setStyle(
                                        "max-height",
                                        "120px",
                                        editor?.editing.view.document.getRoot()
                                      );
                                      writer.setStyle(
                                        " overflow-y",
                                        "scroll",
                                        editor.editing.view.document.getRoot()
                                      );
                                    });
                                   } }}
                                />
                              </div>
                            </div>
                            <br />
                        
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.BorderSpacingLowerListing}></div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(listingFormStyles)(ListingForm);
