import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton, Checkbox } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow: "auto",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const ApprovedOrderModelForm = ({ classes }) => {
  const {
    handleModaleClose,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getRequistionListingDataFromApi,
    requistionListing,
  } = useContext(SupportDataContext);

  const [checkboxValue, setCheckboxValue] = useState([
    "false",
    "false",
    "false",
  ]);

  const options = [
    { label: "Approve", value: "1" },
    { label: "Reject", value: "2" },
  ];

  const requistionPostAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/completeorderapproval/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      order_no: operationInfo?.Order_No,
      order_id: Number(operationInfo?.Order_Id),
      approve_reject_flag: Number(values.approve),
      reason: values?.reason,
      Order_Completion_Instruction_For_Admin: JSON.stringify(requistionListing?.Order_Completion_Instruction_For_Admin)
    };

    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500);
      } else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code);
      }
      setIsOpen(true);
      //handleQuotationPromise(data);
    });
  };

  useEffect(() => {
    setLoading(true);
    getRequistionListingDataFromApi();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          approve: "",
          reason: "",
        }}
        onSubmit={async (data) => {
          setLoading(true);
          requistionPostAPI(data);
          handleModaleClose();
        }}
        validate={(values) => {
          let errors = {};

          if (!values.approve) {
            errors.approve = "required";
          }
          if (!values.reason) {
            errors.reason = "required";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      height: "450px",
                      overflowX: "hidden",
                      padding: "0",
                    }}
                  >
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10,
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            Completion Order Approval/Rejection Form {``}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="Order Id"
                                fieldName="order_id"
                                fieldValue={operationInfo?.Order_Id}
                                disableField={true}
                                disabled={true}
                                classvalue={"textBold"}
                                requiredField={true}
                              />

                              <InputText
                                label="Order No"
                                fieldName="order_no"
                                fieldValue={operationInfo?.Order_No}
                                disableField={true}
                                disabled={true}
                                classvalue={"textBold"}
                                requiredField={true}
                              />

                              <CustomDropdown
                                label="Completion Order Approval/Rejection"
                                fieldOptions={options}
                                fieldValue={values?.approve}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`approve`, value.value);
                                  setCheckboxValue([
                                    "false",
                                    "false",
                                    "false",
                                  ])
                                }}
                                errorField={getStyles(errors?.approve)}
                                requiredField={true}
                              />

                              {values?.approve === "1" && (
                                <>
                                  {requistionListing?.Order_Completion_Instruction_For_Admin?.map(
                                    (val, i) => {
                                      return (
                                        <Form.Group as={Row} className="mb-1">
                                          <Form.Label
                                            column
                                            sm="5"
                                            className="fc-textarea-label"
                                          ></Form.Label>
                                          <Col
                                            sm="7"
                                            style={{ padding: "0 12px 0 0" }}
                                          >
                                            <span
                                              className={
                                                classes.listingEditFormCheckBoxDataSpan1
                                              }
                                            >
                                              <Checkbox
                                                size="small"
                                                style={{
                                                  padding: "0px",
                                                  color: "#eb6e12",
                                                }}
                                                value={checkboxValue?.[i]}
                                                type="checkbox"
                                                onChange={(e) => {

                                                  const value = [
                                                    ...checkboxValue,
                                                  ];
                                                  value?.splice(
                                                    i,
                                                    1,
                                                    e.target.checked
                                                  );
                                                  setCheckboxValue(value);
                                                }}
                                              />
                                              <span
                                                className={classes.textSpan}
                                              >
                                                {val}
                                              </span>
                                            </span>
                                          </Col>
                                        </Form.Group>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              <CustomTextArea
                                label="Reason"
                                fieldName="reason"
                                fieldValue={values.reason}
                                fieldHandleChange={(e) => {
                                  e.preventDefault();
                                  const { value } = e.target;
                                  setFieldValue(`reason`, value);
                                }}
                                errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: "#eb6e12" }}
                        onClick={handleSubmit}
                        disabled={values?.approve === "2" ? false : checkboxValue?.every((val) => val === true) ? false : true}
                      >
                        {"Submit"}
                      </Button>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(ApprovedOrderModelForm);
