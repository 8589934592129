import React, { useState, useContext } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { withStyles } from "@material-ui/core/styles";
import TabStyles from "./Styles/TabStyles";
import { DataContext } from "../../../provider/context";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DocumentControllerListingTabTop from "./Listing/DocumentControllerListingTabTop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const DocumentControllerListingHeaderTab = React.memo((props) => {
  const { classes } = props;

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value1, setValue1] = useState(0);
  const handleChangeTab1 = (newValue) => {
    setValue1(newValue);
  };
  return (
    <div className={classes.listingTabContainerDiv}>
      <Box sx={{ padding: "0px 15px", width: "100%", typography: "body1" }}>
        <TabContext
          value={value1}
          onChange={(event, value1) => handleChangeTab1(value1)}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(event, value1) => handleChangeTab1(value1)}
              aria-label="lab API tabs example"
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
              className={classes.tabListListing}
            >
              <Tab
                className={TabStyles.tabStyleListing1}
                classes={{
                  selected: classes.selected1,
                }}
                label="Document Controller"
                {...a11yProps(0)}
              />
            </TabList>
           
          </Box>
        </TabContext>
      </Box>
      <TabPanel value={value1} index={0}>
        <div>
          <DocumentControllerListingTabTop />
        </div>
      </TabPanel>
     
    </div>
  );
});

export default withStyles(TabStyles)(DocumentControllerListingHeaderTab);
