import * as React from "react";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dropdown from "react-bootstrap/Dropdown";
import "./Styles/supportTopbar.css";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "../../../services/services";
import { DataContext } from "../../../provider/context";
import CustomCalender from "../../../reusableComponents/CustomCalender/CustomCalender";
import { DatePicker } from "@material-ui/pickers";


export default function Notify({checked, fetchNotification, data}) {

  const __token = localStorage.getItem("token")
  const user_type = getLocalData('user_type')
  const emp_id = getLocalData('emp_id')
  const factory_id = getLocalData('factory_id')
  const [notificationAllDataList, setNotificationAllDataList] = React.useState(false);
  const [isShowAllNotification, setIssShowAllNotification] = React.useState(false);
  const { fromDate, toDate,setError,setErrorAlert } = React.useContext(DataContext);
  const [isInitialRef, setInitialRef] = React.useState(false);
  const navigate = useNavigate();
  const gridRef = React.useRef()

  const handleNotificationDate = () => {
    gridRef?.current?.api?.getFilterInstance('Inquiry_Status_Date').setModel({
      type:'inRange',
      dateFrom:fromDate,
      dateTo: toDate
    })
    gridRef?.current?.api.onFilterChanged();
  }


  React.useEffect(() => {
    if(isInitialRef){
      handleNotificationDate()
    }
    setInitialRef(true);
  }, [fromDate, toDate])

  const handleChange = async(item) => {
    let data ={
      emp_id: emp_id,
      user_type:user_type,
      notification_id: item.notification_id,
      read_all: 1
    }
     await fetch(
      "https://flyingcolour.online/fcapp/support_api/NotificationForSupport/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification()
      })
      .catch((error) => {
        if(error?.response?.status === 401) {
          localStorage.clear(); 
          navigate("/login")
        }
      });
   // setChecked((prev) => [...prev.filter((i) => i !== item)]);
  };

  const components = React.useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);
  const NotificationBox = ({ title, Msg, onClose, date }) => {
    return (
      <div className="tBody">
        <div className="toast-header">
          <h5 class="headerTxt" style={{color:"#eb6e12"}}>{title}</h5>
          <IconButton style={{fontSize: 12}} className="closeBtn" aria-label="Close" onClick={onClose}>
           Read
          </IconButton>
        </div>
        <div className="toast-body" style={{marginBottom:'0.5rem', fontWeight: 700}}>
          Date & Time : {moment(date).format("DD-MM-YYYY H:mm:ss")}
        </div>
        <div className="toast-body">
          {Msg}
          {/* <Button className="toast-button">View</Button> */}
        </div>
        
      </div>
    );
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const onlyDate = cellValue.split(' ');
      const dateParts = onlyDate[0].split('-');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      // Now that both parameters are Date objects, we can compare
      if(filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } 
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } 
    },
    browserDatePicker:true
  }

  const columnTypes = React.useMemo(() => {
    return {
      numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
      medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
      nonEditableColumn: { editable: false }
    };
  }, []);

  const handleReadAllNotification = async() => {
    let data ={
      emp_id: emp_id,
      user_type:user_type,
      read_all: 2
    }
   await fetch(
      "https://flyingcolour.online/fcapp/support_api/NotificationForSupport/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification()
      })
      .catch((error) => {
        if(error?.response?.status === 401) {
          localStorage.clear(); 
          navigate("/login")
        }
        console.error("Error:", error);
        
      });
  }


   const columnDefs = [
    {
      headerName:'S.NO',
      field:'Serial_Number',
      filter:false,
      width:90,
      cellStyle: {border:'1px solid #fff'}
    },
    {
      headerName:'Notification Name',
      field:'Inquiry_Status',
      cellStyle: {border:'1px solid #fff'},
      flex:1,
      floatingFilter: false,
    },
    {
      headerName:'Notification Description',
      field:'Remarks',
      cellStyle: {border:'1px solid #fff'},
      flex:1
    },
    {
      headerName:'Notification Date & Time',
      field:'Inquiry_Status_Date',
      cellRenderer:(params) => {
        return <>{moment(params?.data?.Inquiry_Status_Date).format('L')}</>
      },
      cellStyle: {border:'1px solid #fff'},
      filter: 'agDateColumnFilter',
      filterParams:dateFilterParams,
      flex:1
    },
    {
      headerName:'',
      field:'Inquiry_Status_Date',
      floatingFilter: false,
      cellRenderer:(params) => {
            return(
              <>
              {
                params?.data?.Read_Unread_Flag === 0  ?  (
                  <div className="" style={{display:'flex', justifyContent:"center"}}>
                  <Button className="toast-button" onClick={() =>  handleChange(params?.data)}>Read</Button>
                  </div>
                ) : null
              }
              </>
            )
      },
      cellStyle: {border:'1px solid #fff'},
      flex:1
    },
  
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      width: 100,
      editable: false,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      resizable: true,
      sortable:true,
    };
  }, []);

  const handleViewAllNotification = async() => {
    const userName = localStorage.getItem('userName');
   
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/Notification/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&read_unread_flag=3&user_name=${userName}`
      const headers = {
          'Authorization': `Token ${__token}`,
          'Content-Type': 'application/json',
      }
      const res = await axios.get(url, { headers })
      if (
        res?.data?.results === undefined && res?.data?.detail === undefined
      ) {
        return setErrorAlert(true), setError(`ERROR ${res?.data?.code}-${res?.data?.msg}${" "}${"Error is from sales person notification"}`);
      }else  if (res?.data?.detail !== undefined && res?.data?.results === undefined ) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res?.data?.detail}${" "}${"Error is from sales person notification"}`);
      }else { 
        return setNotificationAllDataList(res?.data?.results?.data),setIssShowAllNotification(true);
      }
  }
  catch (error) {
    if (error?.response?.status === 401) {
      if(error?.response?.status === 401) {
        localStorage.clear(); 
        navigate("/login")
      }
    return  setErrorAlert(true), setError(`${error.response?.statusText} ${error.response?.status}-${error.response?.data?.detail}${" "}${"Please Login Again!!"}`)
    }else if (!error?.response){
      return  setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)

    }
}
  }



  return (
    <>
      <Dropdown.Menu>
        <div className="notifyHead">
          <h5 className="headTxt">Notifications</h5>
        </div>
        <div className="boy" style={{maxHeight:"500px", overflowY:'scroll'}}>
          {checked?.length !== 0 ? (
            (checked || []).map((i) => (
              <>
                <Collapse in={checked} key={i}>
                  <NotificationBox
                    title={"New Order Received!"}
                    Msg={i.description}
                    onClose={() => {
                      handleChange(i);
                    }}
                  />
                </Collapse>
              </>
            ))
          ) : (
            <div className="noNotification">
              No Notifications <NotificationsIcon />
            </div>
          )}
        </div>
        <div className="" style={{borderTop:"1px solid red", display:'flex', justifyContent:"space-between"}}>
          {/* <Button className="toast-button" onClick={() => handleViewAllNotification()}>View All</Button> */}
            <div></div>
          <Button className="toast-button" onClick={() => handleReadAllNotification()}>Read All</Button>
        </div>
      </Dropdown.Menu>

      <Modal
        show={isShowAllNotification}
        onHide={() => setIssShowAllNotification(false)}
        animation={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header style={{ background: "#eb6e12", color: "white" }} closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"500px"}}>
        <div style={{marginLeft:"10px", display:'flex', justifyContent:'space-between'}}>
              <DatePicker /> 
              <Button className="toast-button" onClick={() => handleReadAllNotification()}>Read All</Button>
        </div>
        <div style={{height:"93%", width:"100%"}} className="ag-theme-alpine">
          <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
            ref={gridRef}
            rowData={notificationAllDataList}
            columnDefs={columnDefs}
            columnTypes={columnTypes}
            defaultColDef={defaultColDef}
            animateRows={true}
            components={components}
            rowStyle={{background:'#DEDEDE', color:'#000000DE'}}
            pagination={true}
            getRowHeight={() => 90}
          ></AgGridReact>
        </div>
        </Modal.Body>

      </Modal>
    </>
  );
}
