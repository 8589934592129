import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "#fff",
  boxShadow: 15,
  padding: "25px",
  borderRadius:"30px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "150px",
  maxHeight: "150px",
  
  "@media screen and (min-width: 550px) and (max-width: 600px)": {
    maxHeight: "460px",
    minHeight: "460px",
  },

};

const ComplaintModalForm = ({ classes, getInfo }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    setIsOpen,
    operationInfo,
    getAdminCompalintListDataApi,
    getCompalintOrderListDataApi,
  } = useContext(SupportDataContext);
 
  const handleResolveComplaintApi = async (data) => {
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/support_api/clientcomplaintstatusresolved/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
   
    let formData = new FormData();
    formData.append("complaint_id", operationInfo.complaint_id);
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        handlePromise(data);
      })
  }

  const handlePromise = (data) => {
    handleModaleClose();
    getAdminCompalintListDataApi();
    getCompalintOrderListDataApi();
  }
  return (
    <>
      <Formik
        //enableReinitialize={enableReinitialize}
        initialValues={{
        
        }}

        onSubmit={async (data) => {
          setLoading(true)         
          handleResolveComplaintApi(data);
        }}

        validate={(values) => {
          let errors = {};
         
          return errors;
        }}
      >
        {({
          values,
        
          handleSubmit,
         
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Box sx={style}>
                <div>
                 
                  <div style={{ height: "280px", overflowX: "hidden", padding: "0" }}>
                    <Grid container spacing={2} columns={12}>
                      
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span style={{color: "black", fontSize: "20px"}}>
                          
                          </span>
                        </div>
                        <br />
                     
                    </Grid>
                    <span style={{color: "black", fontSize: "20px", marginLeft: "20px"}}>
                           Are you sure to Resolve this Complaint!
                          </span>
                    <br />
                    <span style={{marginBottom: "20px"}}>
                           
                          </span>
                    <br />
                    <div className={classes.quotationButtonDivModal}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: "#eb6e12", marginTop: "40px" }}
                        onClick={handleSubmit}
                      >
                        {"Yes"}
                      </Button>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "50px",
                          marginTop: "40px",
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>

            </form>
          );
        }}
      </Formik>


    </>
  );
};

export default withStyles(ModalPopperFormStyles)(ComplaintModalForm);
