import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, Modal, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import AddScopeOfWorkForm from "../TemplateCreation/AddScopeOfWorkForm";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import FinalizeNOFOrder from "./FinalizeNOFOrder";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddOrEditProjectList from "../ProjectList/AddOrEditProjectList";
import CustomUploadFile from "../../../../../reusableComponents/CustomUploadFile/CustomUploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "350px",
  maxHeight: "350px",

  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};


const ChangeOrderSequenceForm = ({ classes }) => {
  const {
    setButtonText,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getNOFDataListingApi,
    getTemplateCreationListgDataApi,
    templateCreationList,
    templateList,
    getProjectWiseOrdersDataAPi,
    setOperationInfo,
    projectWiseOrders,
    handleModaleClose
  } = useContext(SupportDataContext);

  var today = new Date();
  const currentDate = moment(today).format("YYYY-MM-DD");
  const [formationTypeValue, setFormationTypeValue] = useState(null);
  const [orderIdValue, setOrderIdValue] = useState(null);
  const [stateIdValue, setStateIdValue] = useState(null);
  const [licenseTypeValue, setLicenseTypeValue] = useState(null);
  const [activityTypeValue, setActivityTypeValue] = useState(null);
  const [freezoneValue, setFreezoneValue] = useState(null);
  const [offshoreValue, setOffshoreValue] = useState(null);
  const [legalTypeValue, setLegalTypeValue] = useState(null);
  const [proFormationTypeValue, setPROFormationTypeValue] = useState(null);
  const [dedfzoffTypeValue, setDedFzOffTypeValue] = useState(null);
  const [proServicesName, setProServicesName] = useState(null);
  const [sowList, setSowList] = useState([]);
  const [sowSubTemplateList, setSowSubTemplateList] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectetOrderTemplate, setSelectedOrderTemplate] = useState([]);
  const [selectetOrderSubTemplate, setSelectedOrderSubTemplate] = useState([]);
  const [isDefaultValueChecked, setIsDefaultValueChecked] = useState(false);
  const [
    isDefaultValueCheckedSubTemplate,
    setIsDefaultValueCheckedSubTemplate,
  ] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isSaveAndContinue, setIsSaveAndContinue] = useState(true);
  const [isProjectDisabled, setIsProjectDisable] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showProjectForm, setShowProjectForm] = useState(true);
  const [showAllOrders, setShowAllOrders] = useState(false);
  const [disableConnectedBtn, setDisableConnectedBtn] = useState(false);

  

  const radioStyle = {
    color: "#f9a668",
    "& svg": {
      width: 16,
      height: 16,
    },
    "&.Mui-checked": {
      color: "#eb6e12",
    },
  }; 
  const labelStyle = {
    fontSize: "14px",
    fontFamily: "Times New Roman",
  };

  const addOperationNOFOrderFormPOSTPUTApi = async (values, projectDetails) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const user_name = localStorage.getItem("Employee_First_Name");
    // update_project_orders
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/update_project_orders/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": 'multipart/form-data',
    };

    const formData = new FormData();
    const ordersYetToStart = values?.mainTaskArray?.filter((item, i) => 
      item.AccountsStatus !== "accepted" && 
      item.OrderInitiateStatus === "No" && 
      item.OrderRequestToCompleteStatus === "Not Requested"
    );

    let allOrderDetails = ordersYetToStart?.map((val, index) => {
      const templateValues = val?.selectedTemplate?.map((value) => {
        return value?.label;
      });
      const uploadDocDetails = val?.uploadDocument?.map((value, i) => {
        formData.append(`order_${val?.order_sequence}_upload_docs_${i}`, value.uploadDoc)
        return {
          Document_Type: value?.documentType,
          Particular: value?.particular
        }
      });

      let obj = {
        order_id: val?.order_id || "",
        order_connected_or_not: val?.orderConnected || "",
        order_sequence: val?.orderConnected === "Not Connected" ? "0" : val?.order_sequence.toString(),
        order_start_with: val?.orderConnected === "Connected" ? "0" : val?.order_start_with.toString(),
      };

      return obj;
    });


    formData.append('emp_id', Number(emp_id));
    formData.append('user_type', user_type);
    formData.append('user_name', user_name);
    formData.append('project_id', operationInfo?.project_id);
    formData.append('factory_id', factory_id);

    formData.append('order_details', JSON.stringify(allOrderDetails));

    axios.put(url, formData, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.data?.message) {
        setMessage(`${data?.data?.data?.message}`);
        setSeverity(500);
        getProjectWiseOrdersDataAPi();
        setButtonText("Update Order Sequence");
      } else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code);
        setLoading(false);
        setIsOpen(true);
        getProjectWiseOrdersDataAPi();
        setButtonText("Update Order Sequence");
      }
     
    });
  };

  const [orderFormCompanyDropdown, setOrderFormCompanyDropdown] = useState([]);
  const getOrderFormonCompanyData = async (
    orderType,
    stateId,
    formationId,
    licenseType,
    activityType,
    freezoneName,
    offShoreName,
    legalType,
    proFormationTYpe,
    dedFzOffShoreName,
    proServiceName,
    orderId
  ) => {
    setOpenLoading(true);
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/?company_inquiry_flag=${operationInfo?.inquiry_id ? 1 : 2
        }`;

      if (orderId) {
        url = `${url}&order_id=${orderId}`;
      }
      if (orderType) {
        url = `${url}&order_type=${orderType}`;
      }
      if (stateId) {
        url = `${url}&state_id=${stateId}`;
      }
      if (formationId) {
        url = `${url}&formation_type_id=${formationId}`;
      }
      if (
        (formationId === 1 && licenseType) ||
        (formationId === 2 && licenseType)
      ) {
        url = `${url}&license_type=${licenseType}`;
      }
      if (
        (formationId === 1 && activityType) ||
        (formationId === 2 && activityType) ||
        (formationId === 3 && activityType)
      ) {
        url = `${url}&activity_type=${activityType}`;
      }
      if (formationId === 2 && freezoneName) {
        url = `${url}&freezone_name=${freezoneName}`;
      }
      if (formationId === 3 && legalType) {
        url = `${url}&legal_type=${legalType}`;
      }
      if (formationId === 3 && offShoreName) {
        url = `${url}&offshore_name=${offShoreName}`;
      }
      if (formationId === 4 && proFormationTYpe) {
        url = `${url}&pro_formation_type=${proFormationTYpe}`;
      }
      if (formationId === 4 && dedFzOffShoreName) {
        url = `${url}&ded_fz_offshore_name=${dedFzOffShoreName}`;
      }
      if (formationId === 4 && proServiceName) {
        url = `${url}&pro_services_name=${proServiceName}`;
      }


      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderFormCompanyDropdown(res?.data?.Data);
      // setOrderId(res?.data?.Data?.Order_Id);
      setInitialLoad(false);
      setOpenLoading(false);
      if (editModalPopper && initialLoad) {
        setOrderIdValue(res?.data?.Data?.Order_Detail?.[0]?.Type_Of_Order);
        setStateIdValue(res?.data?.Data?.Order_Detail?.[0]?.state_id);
        setFormationTypeValue(
          Number(res?.data?.Data?.Order_Detail?.[0]?.ftype)
        );
        setLicenseTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.license_type_id
        );
        setActivityTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.activity_type_id
        );
        setFreezoneValue(res?.data?.Data?.Order_Detail?.[0]?.freezone_name_id);
        setOffshoreValue(
          Number(res?.data?.Data?.Order_Detail?.[0]?.offshore_name_id)
        );
        setLegalTypeValue(
          Number(res?.data?.Data?.Order_Detail?.[0]?.legal_type_id)
        );
        setPROFormationTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.formation_name_pro
        );
        setDedFzOffTypeValue(
          res?.data?.Data?.Order_Detail?.[0]?.formation_name_offshore
        );
        setProServicesName(
          res?.data?.Data?.Order_Detail?.[0]?.pro_services_name
        );
      }


      //setLoading(false);
    } catch (err) {
      setOpenLoading(false);
      console.error(err);
    }
  };

  const states =
    orderFormCompanyDropdown.Master_State !== undefined
      ? orderFormCompanyDropdown?.Master_State.map((i) => ({
        id: i.StateIds,
        value: i.StateIds,
        label: `${i.StateName}`,
      }))
      : [];

  const companyList =
    orderFormCompanyDropdown.Company_List !== undefined
      ? orderFormCompanyDropdown?.Company_List.map((i) => ({
        id: i.Company_Id,
        value: i.Company_Id,
        label: `${i.Company_Name}`,
      }))
      : [];

  const orderTypeDropdown =
    orderFormCompanyDropdown.Order_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Type.map((i) => ({
        id: i,
        value: i,
        label: `${i}`,
      }))
      : [];

  const formationType =
    orderFormCompanyDropdown.Master_type_of_company !== undefined
      ? orderFormCompanyDropdown?.Master_type_of_company.map((i) => ({
        id: i.Formation_type_id,
        value: i.Formation_type_id,
        label: `${i.Formation_type}`,
      }))
      : [];

  const licenseTypes =
    orderFormCompanyDropdown.License_Type !== undefined
      ? orderFormCompanyDropdown?.License_Type.map((i) => ({
        id: i.Ids,
        value: i.Ids,
        label: `${i.Name}`,
      }))
      : [];
  const legalTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Legal_Type !== undefined
      ? orderFormCompanyDropdown?.Legal_Type.map((i) => ({
        id: i.Ids,
        value: i.Ids,
        label: `${i.Name}`,
      }))
      : [];
  const activityGroupTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.Activity_Group !== undefined
      ? orderFormCompanyDropdown?.Activity_Group.map((i) => ({
        id: i.Ids,
        value: i.Ids,
        label: `${i.Name}`,
      }))
      : [];
  const freezoneTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.Freezone_Name !== undefined
      ? orderFormCompanyDropdown?.Freezone_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];
  const offshoreTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.Offshore_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];
  const proFormationTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.PRO_Formation_Type !== undefined
      ? orderFormCompanyDropdown?.PRO_Formation_Type.map((i) => ({
        value: i,
        label: `${i}`,
      }))
      : [];
  const ded_Fz_OfforeTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.DED_FZ_Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.DED_FZ_Offshore_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];
  const proServiceNames =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.PRO_Service_Name !== undefined
      ? orderFormCompanyDropdown?.PRO_Service_Name.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Name}`,
      }))
      : [];

  const orderSubTypes =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Order_Sub_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Sub_Type.map((i) => ({
        id: i.Serial_Number,
        value: `${i.order_sub_type}`,
        label: `${i.order_sub_type}`,
      }))
      : [];

  const tagNames =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown?.Tag_Name_List !== undefined
      ? orderFormCompanyDropdown?.Tag_Name_List.map((i) => ({
        id: i.Tag_Id,
        value: i.Tag_Id,
        label: `${i.Tag_Name}`,
      }))
      : [];

  const typistList =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Typist_List !== undefined
      ? orderFormCompanyDropdown?.Typist_List.map((i) => ({
        id: i.EmpId,
        value: i.EmpId,
        label: `${i.Emp_Name}`,
      }))
      : [];
  const startImmidiate = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ]
  const documentTypeList =
    orderFormCompanyDropdown?.length !== 0 &&
      orderFormCompanyDropdown.Document_Type !== undefined
      ? orderFormCompanyDropdown?.Document_Type.map((i) => ({
        id: i.Id,
        value: i.Id,
        label: `${i.Doc_Name}`,
      }))
      : [];

  const templateDropdown = [];
  templateList?.Service_Template_List?.map((i) => {
    let tasksList = [...i?.Task_List]?.reverse();
    if (i?.SOW_Exist === "Yes") {
      templateDropdown.push({
        id: i.Service_Id,
        value: `${i.Service_Id}`,
        label: `${i.Service_Name}`,
        details: i?.Service_Details,
        taskList: tasksList,
      });
    }
  });

  const templateListDropdown = templateDropdown || [];

  const templateSubOrderListDropdown =
    templateList?.Service_Sub_Template_List?.length !== 0 &&
      templateList?.Service_Sub_Template_List !== undefined
      ? templateList?.Service_Sub_Template_List?.map((i) => ({
        id: i.Service_Id,
        value: `${i.Service_Id}`,
        label: `${i.Service_Name}`,
        details: i?.Service_Details,
        taskList: i?.Task_List,
      }))
      : [];
  const projectList =
    templateCreationList?.length !== 0 &&
      templateCreationList?.Project_List !== undefined
      ? templateCreationList?.Project_List.map((i) => ({
        id: i.project_id,
        value: i.project_id,
        label: `${i.project_name} (${i?.company_name})`,
        supportId: i.support_id,
        supportName: i?.support_name,
        companyName: i?.company_name,
        companyId: i?.company_id,
        project_name: i?.project_name,
        subOrderTemplateName: i?.scope_work_template_name_for_sub_template,
        taskList: i?.scope_of_work_for_sub_template,
      }))
      : [];

  const taskSectionDropdown = templateCreationList?.Single_Multi_Work_Type?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const taskTypeDropdown = templateCreationList?.Task_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Task_Type_Name, label: item.Task_Type_Name };
      return obj;
    }
  );

  const taskCategoryDropdown = templateCreationList?.Task_Category_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const workTypeDropdown = templateCreationList?.Work_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const govtDeptDropdown = templateCreationList?.Govt_Dept_List?.map(
    (item, i) => {
      const obj = { value: item.Govt_Dept_Name, label: item.Govt_Dept_Name };
      return obj;
    }
  );

  useEffect(() => {
    getTemplateCreationListgDataApi();
  }, []);

  useEffect(() => {
    setIsDefaultValueChecked(true);
    setIsDefaultValueCheckedSubTemplate(true);
    getOrderFormonCompanyData(
      orderIdValue,
      stateIdValue,
      formationTypeValue,
      licenseTypeValue,
      activityTypeValue,
      freezoneValue,
      offshoreValue,
      legalTypeValue,
      proFormationTypeValue,
      dedfzoffTypeValue,
      proServicesName,
      orderId
    );
  }, []);

  const removeDuplicateObjects = (array, property) => {
    const uniqueArray = [];

    array.forEach((object) => {
      const existingObject = uniqueArray.find(
        (item) => item[property] === object[property]
      );

      if (!existingObject) {
        uniqueArray.push(object);
      }
    });

    return uniqueArray;
  };

  

  

  const [orderNOFListDropdown, setOrderNOFListDropdown] = useState([]);

  const handleSaveAndContine = (arrayHelpers, mainTaskArray) => {
    let orderConc =
      mainTaskArray[mainTaskArray?.length - 1]?.orderConnected ===
        "Not Connected"
        ? "Not Connected"
        : "Connected";
    let connectedOrders = mainTaskArray?.filter((item, i) => item?.orderConnected === "Connected");
    
    let mainTaskArrayDropdown = connectedOrders?.map((val) => {
      return {
        id: val?.order_sequence,
        value: val?.order_sequence,
        label: val?.orderName,
      };
    });
    // setOrderNOFListDropdown(mainTaskArrayDropdown);
    arrayHelpers.push({
      orderId: orderFormCompanyDropdown?.Order_Id,
      orderName: "",
      orderType: "",
      orderSubType: "",
      state: "",
      formationType: "",
      proServices: "",
      dedFzOffshore: "",
      proServiceName: "",
      freezoneName: "",
      offShoreName: "",
      legalType: "",
      licenseType: "",
      activityType: "",
      selectedTemplate: "",
      selectedTemplateId: "",
      scopeOfWork: [],
      typistId: "",
      orderConnected: orderConc,
      order_finalize: "",
      order_sequence: mainTaskArray?.length + 1,
      order_start_with: orderConc === "Connected" ? 0 : "",
      start_immidiate: "",
      uploadFile: "No",
      uploadDocument: [
        {
          documentType: "",
          particular: "",
          uploadDoc: "",
        },
      ],
    });
    // setOpenLoading(true);
    setIsSaveAndContinue(true);
    setIsProjectDisable(true);
    setIsDefaultValueChecked(false);
    setIsDefaultValueCheckedSubTemplate(false);
    setCurrentIndex((prev) => prev + 1);
    setOrderIdValue(null);
    setStateIdValue(null);
    setLicenseTypeValue(null);
    setActivityTypeValue(null);
    setFreezoneValue(null);
    setOffshoreValue(null);
    setLegalTypeValue(null);
    setPROFormationTypeValue(null);
    setDedFzOffTypeValue(null);
    setProServicesName(null);
    setSowList([]);
    setOrderId(null);
    // setSelectedOrderTemplate([]);
  };

 
   let handleUploadDocument = projectWiseOrders?.Project_Order_List?.upload_doc_details?.length > 0 ?
    projectWiseOrders?.Project_Order_List?.upload_doc_details?.map((val) => 
       ({
        documentType: val?.Document_Type,
        particular: val?.Particular,
        uploadDoc: val?.uploadDoc
      })
    ) : [];
  
    const ordersNotCancelled = projectWiseOrders?.Project_Order_List?.filter((item, i) => 
      item?.Order_Cancellation_Status !== "Approved by Accounts"
     && item?.Order_Cancellation_Status !== "Requested by Support"
    );
console.log("projectWiseOrders", projectWiseOrders);
  const editMainTaskArray2 = ordersNotCancelled?.map((item) => ({
    order_id: item.Order_Id,
    orderId: item.Order_No,
    orderName: item.Order_Name,
    orderType: item.Type_Of_Order,
    orderSubType: item.order_sub_type,
    state: item.State,
    formationType: Number(item.formation_type),
    formationTypeName: item.Formation,
    proServices: item.formation_type_for_pro_services,
    dedFzOffshore: item.ded_fz_offshore_name_name,
    proServiceName: item.pro_services_name_name,
    freezoneName: item.freezone_name,
    offShoreName: item.offshore_name,
    legalType: item.legal_type_name,
    licenseType: item.license_type_name,
    activityType: item.activity_type_name,
    selectedTemplate: item.scope_of_work_array?.map((ele)=>({
      Template_Service_Name: ele?.Name,
      Task_Type_Name: ele?.Task_Type,
      Task_Category: ele?.Task_Category,
      Nos_Task_Days: ele?.Days,
      Task_Sequence: Number(ele?.Sequance),
      Task_Amount: ele?.Task_Amount,
      Govt_Dept_Name: ele?.Govt_Department,
      Special_Instruction: ele?.Special_Instruction,
      Single_Multi_Task_Type: ele?.Task_Section,
      Task_Details: ele?.Task_Detail,
      Work_Type: ele?.Work_Type,
      Tag_Id: ele?.Tag_Id,
      Tag_Name: ele?.Tag_Name,
      Template_Id: ele?.Template_Id,
      Template_Name: ele?.Template_Name,
      Service_Task_Id: ele?.Service_Task_Id,
  })),
    selectedTemplateId: item.order_template,
    scopeOfWork: item.scope_of_work_array,
    typistId: item.Typist_Name,
    orderConnected: item.order_connected_or_not,
    order_finalize: "",
    order_sequence: Number(item?.order_sequence),
    order_start_with: item?.Order_StartsWith.toString(),
    uploadFile: item.upload_doc_details?.length > 0 ? "Yes" : "No",
    uploadDocument: handleUploadDocument,
    AccountsStatus: item?.Accounts_Status,
    OrderRequestToCompleteStatus: item?.Order_Request_To_Complete_Status,
    OrderInitiateStatus: item?.Order_Initiate_Status,
    start_immidiate: "",
    edit_or_not: "No",
  })
  );
  console.log("editMainTaskArray2", editMainTaskArray2)
      
  useEffect(() => {
    let newObject = { id: 0, value: "0", label: "Start Immediately" };
    let connectedOrders = projectWiseOrders?.Project_Order_List?.filter((item, i) => 
      item.Accounts_Status !== "accepted" && 
      item.Order_Initiate_Status === "No" && 
      item.Order_Request_To_Complete_Status === "Not Requested" && 
      item.order_connected_or_not === "Connected"
    );
           let mainTaskArrayDropdown = connectedOrders?.map((val) => {
             return {
               id: val?.order_sequence,
               value: val?.order_sequence.toString(),
               label: val?.Order_Name,
             };
           });
          const startWithArrayDropdown = [newObject].concat(mainTaskArrayDropdown)
           setOrderNOFListDropdown(startWithArrayDropdown);
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={initialLoad}
        initialValues={{
          companyName: projectWiseOrders?.Project_Order_List?.[0]?.Company_Name,
          companyId: projectWiseOrders?.Project_Order_List?.[0]?.Company_Id,
          projectId: projectWiseOrders?.Project_Order_List?.[0]?.project_id,
          projectName: projectWiseOrders?.Project_Order_List?.[0]?.project_name,
          supportName: projectWiseOrders?.Project_Order_List?.[0]?.Support_Emp_Name,
          selectedSubTemplate: selectetOrderSubTemplate,
          selectedSubTemplateId: selectetOrderSubTemplate,
          supportId: Number(projectWiseOrders?.Project_Order_List?.[0]?.Support_Emp_Id),
          subOrderTemplateName: "",
          scopeOfWorkSubTemplate: projectWiseOrders?.Project_Order_List?.[0]?.scope_of_work_for_sub_template,
          mainTaskArray: editMainTaskArray2 !== undefined && editMainTaskArray2,
            
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addOperationNOFOrderFormPOSTPUTApi(data);
        }}
        validate={(values) => {
          let errors = {};
          console.log("mainTaskArray", values?.mainTaskArray)
          let connectedOrders = values?.mainTaskArray?.filter((item, i) => 
          item.AccountsStatus !== "accepted" && 
          item.OrderInitiateStatus === "No" && 
          item.OrderRequestToCompleteStatus === "Not Requested" && 
          item.orderConnected === "Connected"
        );
          let newObject = { id: 0, value: "0", label: "Start Immediately" };
           let mainTaskArrayDropdown = connectedOrders?.map((val) => {
             return {
               id: val?.order_sequence,
               value: val?.order_sequence.toString(),
               label: val?.orderName,
             };
           });
           const startWithArrayDropdown = [newObject].concat(mainTaskArrayDropdown)
           setOrderNOFListDropdown(startWithArrayDropdown);
           
          values?.mainTaskArray?.forEach((values) => {
            if (
              values?.orderConnected === "Not Connected" &&
              (values?.order_start_with < 0 || values?.order_start_with === "" || typeof values?.order_start_with !== "string")
            ) {
              errors.order_start_with = "Required";
            }
            if (values.typistId === "" || values.typistId === NaN || values.typistId < 0) {
              errors.typistId = "required";
            }
          });
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              <div className={classes.quotationModalHeadingContainer}>
                    <span className={classes.quotationModalHeader}>
                    { "Update Order Sequence"} on Company: {values?.companyName}
                    </span>
                  </div>  
                  <br />
              <div
                style={{
                  height: "580px",
                  overflowX: "hidden",
                  padding: "0",
                }}
              >
                <FieldArray
                  name="mainTaskArray"
                  render={(arrayHelpers) => (
                    <>
                      {values.mainTaskArray?.length > 0 &&
                        values.mainTaskArray?.map((item, i) => {
                          return (
                            <>
                              <Grid container spacing={2} columns={12}>
                                <Grid item xs={12}>
                                  <div
                                    className={
                                      classes.quotationModalFlexDivContaner
                                    }
                                  >
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        className={
                                          classes.quotationInnerGridRight
                                        }
                                      >
                                         <InputText
                                         label="Order Name"
                                          fieldName="orderName"
                                          fieldValue={item?.orderName}
                                          disableField={true}
                                          fieldHandleChange={(event) =>
                                            event.preventDefault()
                                          }
                                          // requiredField={true}
                                          // errorField={getStyles(
                                          //   errors,
                                          //   item?.orderName
                                          // )}
                                          // maxLength={230}
                                        />
                                        <InputText
                                          label="Order No"
                                          fieldName="orderId"
                                          fieldValue={item?.orderId}
                                          disableField={true}
                                          disabled={true}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        className={
                                          classes.quotationInnerGridRight
                                        }
                                      >
                                         <InputText
                                          label="Order Sequence"
                                          fieldName="supportName"
                                          fieldValue={item?.order_sequence}
                                          disableField={true}
                                          disabled={true}
                                        />
                                        <InputText
                                         label="Order Type"
                                          fieldName="orderType"
                                          fieldValue={item?.orderType}
                                          disableField={true}
                                          fieldHandleChange={(event) =>
                                            event.preventDefault()
                                          }
                                          // requiredField={true}
                                          // errorField={getStyles(
                                          //   errors,
                                          //   item?.state
                                          // )}
                                          // maxLength={230}
                                        />
                                       
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        className={
                                          classes.quotationInnerGridRight
                                        }
                                      >
                                        <Form.Group
                                          as={Row}
                                          className="my-1"
                                          controlId={"gender"}
                                        >
                                          <Form.Label column sm="5">
                                            Connected / Not Connected
                                          </Form.Label>
                                          <Col
                                            sm="7"
                                            style={{
                                              padding: "0 12px 0 0",
                                            }}
                                          >
                                            <div
                                              className={
                                                classes.listingEditListingEditFormGroup
                                              }
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                           <RadioGroup
                                          style={{
                                            display: "contents",
                                          }}
                                          row
                                          name="orderConnected"
                                          value={item?.orderConnected}
                                          onChange={(event) => {
                                          const value = event.target.value;
                                          if(i > 0 && values.mainTaskArray[i - 1]?.orderConnected === "Not Connected")
                                          {
                                            setDisableConnectedBtn(true)
                                          }
                                         
                                            setFieldValue(`mainTaskArray.[${i}].orderConnected`, event.target.value)
                                            setFieldValue(`mainTaskArray.[${i}].edit_or_not`, "Yes");
                                         
                                          if (value === "Not Connected") {
                                            for (let j = i + 1; j < values.mainTaskArray.length; j++) {
                                              setFieldValue(`mainTaskArray[${j}].orderConnected`, "Not Connected");
                                              setFieldValue(`mainTaskArray.[${i}].order_start_with`, "");
                                            }
                                          }
                                          if (value === "Connected") {
                                            for (let j = i + 1; j < values.mainTaskArray.length; j++) {
                                              setFieldValue(`mainTaskArray[${j}].orderConnected`, "Connected");
                                              // setFieldValue(`mainTaskArray.[${i}].order_start_with`, "");
                                            }
                                          }
                                          }}
                                        >
                                         
                                          <FormControlLabel
                                            value="Connected"
                                            label="Connected"
                                            control={<Radio sx={radioStyle} />}
                                            sx={labelStyle}
                                            style={{ marginTop: "2px" }}
                                            disabled={
                                              (i > 0 && values.mainTaskArray[i - 1]?.orderConnected === "Not Connected")
                                              ||
                                              (item?.AccountsStatus?.toLowerCase() === "accepted"
                                                && item?.OrderRequestToCompleteStatus?.toLowerCase() === "not requested"
                                                && item?.OrderInitiateStatus?.toLowerCase() === "yes") 
                                                 ? true:
                                                  false
                                            }
                                          />
                                          <FormControlLabel
                                            value="Not Connected"
                                            label="Not Connected"
                                            control={<Radio sx={radioStyle} />}
                                            sx={labelStyle}
                                            style={{ marginTop: "2px" }}
                                            disabled={
                                              (item?.AccountsStatus?.toLowerCase() !== "accepted"
                                                && item?.OrderRequestToCompleteStatus?.toLowerCase() === "not requested"
                                                && item?.OrderInitiateStatus?.toLowerCase() === "no" )
                                                ? false : true
                                            }
                                          />
                                        </RadioGroup>
                                            </div>
                                          </Col>
                                        </Form.Group>

                                        {item?.orderConnected ===
                                          "Not Connected" && (
                                            <>
                                              <CustomDropdown
                                                label="Select Order Start With"
                                                fieldOptions={
                                                  orderNOFListDropdown
                                                }
                                                fieldValue={
                                                  item?.order_start_with
                                                }
                                                fieldHandleChange={(value) => {
                                                  setFieldValue(`mainTaskArray.[${i}].order_start_with`, value.value.toString());
                                                  if (item?.orderConnected === "Not Connected") {                                                   
                                                      setFieldValue(`mainTaskArray.[${i}].edit_or_not`, "Yes");
                                                  }
                                                }}
                                                errorField={getStyles(
                                                  errors,
                                                  item?.order_start_with
                                                )}
                                                requiredField={true}
                                                
                                                isDisabled={
                                                  item?.AccountsStatus?.toLowerCase() !== "accepted"
                                                    && item?.OrderRequestToCompleteStatus?.toLowerCase() === "not requested"
                                                    && item?.OrderInitiateStatus?.toLowerCase() === "no" ? false : true
                                                }
                                              />
                                              {/* <CustomDropdown
                                                label="Start Immidiately"
                                                fieldOptions={startImmidiate}
                                                fieldValue={item?.start_immidiate}
                                                fieldHandleChange={(value) => {
                                                  setFieldValue(
                                                    `mainTaskArray.[${i}].start_immidiate`,
                                                    value.value
                                                  );
                                                }}
                                                // errorField={getStyles(
                                                //   errors,
                                                //   item?.typistId
                                                // )}
                                                // requiredField={true}
                                                isDisabled={
                                                  item?.AccountsStatus?.toLowerCase() !== "accepted"
                                                    && item?.OrderRequestToCompleteStatus?.toLowerCase() === "not requested"
                                                    && item?.OrderInitiateStatus?.toLowerCase() === "no" ? false : true
                                                }
                                              /> */}
                                            </>
                                          )}
                                       
                                      </Grid>
                                    </Grid>
                                  </div>
                               </Grid>

                              </Grid>
                               <div style={{
                                 alignItems: "center",
                                 display: "flex",
                                 justifyContent: "center",
                                 marginTop: "3px",
                                 borderTop: "1px solid #eb6e12",
                                 backgroundColor: "#eb6e12",
                               }}>
                             </div>  
                            </>
                          );

                        })}
                      <Backdrop
                        sx={{
                          color: "#eb6e12",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={openLoading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </>
                  )}
                  
                />
              </div>
              <br />
              { console.log("error", errors)}
             
              <div className={classes.buttonDivModal}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#eb6e12" }}
                  onClick={handleSubmit}
                  // disabled={disableConnectedBtn}
                >
                  {"Save"}
                </Button>
                <Button
                  onClick={() => {
                    setButtonText("Update Order Sequence");
                    handleModaleClose();
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
                {/* {disableConnectedBtn ? 
                <span style={{color: "red"}}>You can't choose</span>
                : ""
              } */}
              </div>
            
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(ChangeOrderSequenceForm);
