import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from 'react-bootstrap/Alert';
import {
  FaStopCircle,
 
} from "react-icons/fa";



const EscalationLogTable = ({ classes }) => {
  const {
    message,
    setButtonText,
    setOperationInfo,
    handleModalOpen,
    operationInfo,
    getEscalationLogDataApi, escalationLogData
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  useEffect(() => {
    getEscalationLogDataApi();
  }, []);
console.log(escalationLogData)
  const customActionBtnRenderer = (params) => {
    return (
      <>
        <CustomIconButton
          onClick={() => {
            handleModalOpen();
            setButtonText("Freeze Order Approval");
            // scrollToTop()
            //ScrollTop();
            //setOperationInfo(params.data);
          }}
          title={"Freeze Order Approval"}
          icon={<FaStopCircle style={{ margin: "0", fontSize: "25px" }} />}
          className="btnActionAlignment"
        />
      </>
    )
  }

  const [columnDefs] = useState([
    {
      headerName: "Sr.",
      field: "Serial_Number",
      width: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
   
      {
        headerName: "Log Id",
        field: "Log_Id",
        filter: "agTextColumnFilter",
        width: 120,
        pinned: "left",
        cellStyle: { border: "1px solid #fff" },
      },
    {
      headerName: "Complaint Status",
      field: "Complaint_Status",
      filter: true,
      width: 220,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Created By / Date",
      field: "Created_By",
      filter: "agTextColumnFilter",
      width: 210,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      cellRenderer: (params) => { 
        return(
        <>
         {params?.data?.Created_By} / { moment(params?.data?.Created_Date).format("DD-MM-YYYY")}
        </>
        )
      },
    },
    {
      headerName: "User Type",
      field: "Created_By_User_Type",
      filter: true,
      width: 160,
      //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Remarks",
      field: "Admin_Remarks",
      filter: "agTextColumnFilter",
      width: 200,
      //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
      flex: 1,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>

      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >


          {/* <Alert show={show} variant="success" sx={{ width: "100%" }} onClose={() => setShow(false)} dismissible>
                 {message} 
               </Alert> */}

          <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
            ref={gridRef}
            rowData={escalationLogData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default withStyles(modalTableStyles)(EscalationLogTable);
