const modalTableStyles = (theme) => ({
    TabLowerContainerModalTable: {
      border: "1px solid #e3e3e3 !important",
      zIndex: "1",
      marginTop: "-2px",
      backgroundColor: "#d3d3d3",
    },
    BorderSpacingUpperModaleTable: {
      width: "100%",
      // borderTop: '5px solid #eb6e12 ',
      paddingBottom: "1px",
    },
    BorderSpacingLowerModaleTable: {
      width: "100%",
      borderBottom: "5px solid #eb6e12 ",
    },
    modalTableSecondContainer: {
      maxHeight: "500px",
  
      overflowY: "scroll",
    },
    tableHeaderModalTable: {
      backgroundColor: "#eb6e12",
    },
    tableCellModal: {
      color: "#fff !important ",
      fontFamily: " Times New Roman !important",
      padding: "7px !important",
  
      fontWeight: "bold",
    },
    expandbleTableCellDisRow: {
      fontFamily: " Times New Roman !important",
      maxWidth: "20% !important",
      padding: "7px !important",
      borderRight: ` 1px solid #fff !important`,
      borderBottom: ` 1px solid #fff !important`,
      backgroundColor: "#d3d3d3",
    },
    ButtonDivListingTab: {
      padding: "10px !important",
      marginBottom: "3px",
      "@media screen and (min-width: 800px) and (max-width: 3000px)": {
        padding: "10px !important",
        display: "flex",
      },
      
    },
    NewButton: {
      backgroundColor: "#eb6e12 !important",
      fontFamily: "Times New Roman !important",
    },
    NewButton1: {
      backgroundColor: "#eb6e12 !important",
      fontFamily: "Times New Roman !important",
      fontSize: "1rem",
      lineHeight: "1.75",
      margin: "0",
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      padding: "6px 16px",
      borderRadius: "4px",
      color: "#fff",
      textDecoration: "none",
      marginRight: "5px",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
  
    tableMsgContaner: {
      width: "68.7%",
      textAlign: "center",
      marginLeft: "-11%",
      marginTop: "10px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "80%",
        marginLeft: "0px",
      },
    },
    tableMsgHeader: {
      color: "green",
    },
  });
  export default modalTableStyles;
  