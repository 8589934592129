import React, { useState, useContext } from "react";
import { Box, Tab, Badge } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { withStyles } from "@material-ui/core/styles";
import TabStyles from "../Styles/TabStyles";
import ModalTable from "../Modals/ModalTable";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import BackImage from "../BackImage";
import PROHeadListingTapBottom from "./PROHeadListingTapBottom";
import { DataContext,SupportDataContext } from "../../../../provider/context";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    border: "1px solid #FF7518",
  },
}));
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const PROHeadListingTabTop = React.memo((props) => {
  const [openForm] = useState(false);

  const { classes } = props;

  const {
    modalTable,
    value,
    selected,
    handleChangeTab,
  } = useContext(SupportDataContext);
  const {supportBadges} = useContext(DataContext)
  

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const callDueDate = () => {
    const currentDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const date = currentDate.split("/");
    const dueDate = `${date[2]}-${date[1]}-${date[0]}`;
    return dueDate;
  };
  return (
    <div className={classes.listingTabContainerDiv}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext
          value={value}
          onChange={(event, value) => handleChangeTab(value)}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(event, value) => handleChangeTab(value)}
              aria-label="lab API tabs example"
              classes={{
                flexContainer: classes.flexContainer,
                indicator: classes.indicator,
              }}
              className={classes.tabListListing}
            >
              <Tab
                className={classes.tabStyleListing}
                classes={{
                  selected: classes.selected,
                }}
                label="Listing Details"
                {...a11yProps(0)}
              />
           
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <TabPanel value={value} index={0}>
     
      { selected?.length < 1 ? (
          <>
            {!modalTable ? (
              <div className={classes.TabLowerContainerDiv}>
                <>
                <BackImage />
                </>
              </div>
            ) : (
              <ModalTable />
            )}
          </>
       ) : (
       <></>
       )}

      </TabPanel>
     
      <br /> <br />
      <PROHeadListingTapBottom />
    </div>
  );
});

export default withStyles(TabStyles)(PROHeadListingTabTop);
