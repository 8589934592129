import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import modalTableStyles from "../../styles/ModalTableStyles";
import {
  SupportDataContext,
  DataContext,
} from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddScopeOfWorkForm = ({ classes, isNOF, taskValue }) => {
  const {
    handleModalTableClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    operationInfo,
    salesInfo,
    rowData,
    templateCreationList,
    setButtonText,
    handleModalTableOpen,
    setOperationInfo,
    sowList,
    setOpen,
    open,
    handleCloseSnack,
    severity,
    message,
  } = useContext(SupportDataContext);

  const [isTaskNameExist, setIsTaskNameExist] = useState(false);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },
  }));

  const addSOWPostAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const factory_id = localStorage.getItem("factory_id");
    // https://flyingcolour.online/fcapp/compliance/checkverificationonpartner/
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/order_Template_Service_Task_view/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    const taskDetailsValue = values?.mainTaskArray?.map((val) => {
      const sowList = {
        govt_dept_id: val?.govtDeptId,
        govt_dept_name: val?.govtDeptName,
        task_type_id: val?.taskTypeId,
        task_type_name: val?.taskTypeName,
        tag_id: val?.tagId,
        // tag_name: val?.tagName,
        task_name: val?.taskName,
        task_category: val?.taskCategory,
        work_type: val?.workType,
        single_multi_task_type: val?.taskSection,
        nos_task_days: val?.noOfDays,
        task_amount: val?.amount,
        task_details: val?.taskDetails,
        special_instruction: val?.anySpecialInstruction,
        task_sequence: val?.sequence,
      };

      return sowList;
    });
    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);

    if (editModalPopper) {
      let govtDeptId = [],
        govtDeptName = [],
        taskTypeId = [],
        taskTypeName = [],
        tagId = [],
        // tagName = [],
        taskName = [],
        taskCategory = [],
        workType = [],
        taskSection = [],
        noOfDays = [],
        amount = [],
        taskDetails = [],
        anySpecialInstruction = [],
        sequence = [];
      values?.mainTaskArray?.forEach((val) => {
        govtDeptId?.push(val?.govtDeptId);
        govtDeptName?.push(val?.govtDeptName);
        taskTypeId?.push(val?.taskTypeId);
        taskTypeName?.push(val?.taskTypeName);
        tagId?.push(val?.tagId);
        // tagName?.push(val?.tagName);
        taskName?.push(val?.taskName);
        taskCategory?.push(val?.taskCategory);
        workType?.push(val?.workType);
        taskSection?.push(val?.taskSection);
        noOfDays?.push(val?.noOfDays);
        amount?.push(val?.amount);
        taskDetails?.push(val?.taskDetails);
        anySpecialInstruction?.push(val?.anySpecialInstruction);
        sequence?.push(val?.sequence);
      });

      const formData = new FormData();
      formData.append("emp_id", Number(emp_id));
      formData.append("user_type", user_type);
      formData.append("factory_id", factory_id);
      formData.append("govt_dept_id", govtDeptId?.toString());
      formData.append("govt_dept_name", govtDeptName?.toString());
      formData.append("task_type_id", taskTypeId?.toString());
      formData.append("task_type_name", taskTypeName?.toString());
      formData.append("tag_id", tagId?.toString());
      // formData.append("tag_name", tagName?.toString());
      formData.append("task_name", taskName?.toString());
      formData.append("task_category", taskCategory?.toString());
      formData.append("work_type", workType?.toString());
      formData.append("single_multi_task_type", taskSection?.toString());
      formData.append("nos_task_days", noOfDays?.toString());
      formData.append("task_amount", amount?.toString());
      formData.append("task_details", taskDetails?.toString());
      formData.append("task_sequence", sequence?.toString());
      formData.append("special_instruction", anySpecialInstruction?.toString());
      formData.append("service_task_id", operationInfo?.Service_Task_Id);
      formData.append(
        "template_service_id",
        Number(operationInfo?.Template_Service_Id)
      );
      formData.append(
        "template_service_name",
        operationInfo?.Template_Service_Name
      );
      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        setButtonText("SOW Template Listing");
      });
    } else {
      formData.append("task_list_details", JSON.stringify(taskDetailsValue));
      formData.append("template_service_id", operationInfo?.Service_Id);
      formData.append("template_service_name", operationInfo?.Service_Name);
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        setButtonText("SOW Template Listing");
      });
    }
  };

  const taskSectionDropdown = templateCreationList?.Single_Multi_Work_Type?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const taskTypeDropdown = templateCreationList?.Task_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Task_Type_Id, label: item.Task_Type_Name };
      return obj;
    }
  );

  const taskCategoryDropdown = templateCreationList?.Task_Category_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const workTypeDropdown = templateCreationList?.Work_Type_List?.map(
    (item, i) => {
      const obj = { value: item.Name, label: item.Name };
      return obj;
    }
  );

  const tagNameDropdown = templateCreationList?.Tag_Name_List?.map(
    (item, i) => {
      const obj = { value: item.Tag_Id, label: item.Tag_Name };
      return obj;
    }
  );

  const govtDeptDropdown = templateCreationList?.Govt_Dept_List?.map(
    (item, i) => {
      const obj = { value: item.Govt_Dept_Id, label: item.Govt_Dept_Name };
      return obj;
    }
  );

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleEditValue = () => {
    const finalValue = [];
    finalValue.push({
      taskName: operationInfo?.Service_Task_Name,
      sequence: operationInfo?.Task_Sequence,
      taskSection: operationInfo?.Single_Multi_Task_Type,
      taskTypeName: operationInfo?.Task_Type_Name,
      taskTypeId: Number(operationInfo?.Task_Type_Id),
      taskCategory: operationInfo?.Task_Category,
      taskDetails: operationInfo?.Task_Details,
      workType: operationInfo?.Work_Type,
      // tagName: operationInfo?.Tag_Name,
      tagId: Number(operationInfo?.Tag_Id),
      govtDeptName: operationInfo?.Govt_Dept_Name,
      govtDeptId: Number(operationInfo?.Govt_Dept_Id),
      noOfDays: operationInfo?.Nos_Task_Days,
      amount: operationInfo?.Task_Amount,
      anySpecialInstruction: operationInfo?.Special_Instruction,
      description: "",
    });

    return finalValue;
  };

  const checkIsTaskNameExist = (value, setFieldValue, i, mainTaskArray) => {
   
    if(!editModalPopper) {
      mainTaskArray.forEach((val, index) => {
        if(index === i) return; 
        
       if(val.taskName.toLowerCase() === value.toLowerCase()) {
        setFieldValue(`mainTaskArray.[${i}].taskName`, "");
        setIsTaskNameExist(i);
        return;
       }
  
      })
    }
    if( editModalPopper && operationInfo?.Service_Task_Name?.toLowerCase() === value.toLowerCase()) {
      setIsTaskNameExist(false);
      return;
    }
    
    if (
      sowList?.Service_Task_List?.some(
        (item) => item.Service_Task_Name.toLowerCase() === value.toLowerCase()
      )
    ) {
      setFieldValue(`mainTaskArray.[${i}].taskName`, "");
      setIsTaskNameExist(i);
    } else {
      setIsTaskNameExist(false);
    }
  };

  const handleNOFValue = () => {
    const finalValue = [];
    taskValue?.taskList?.forEach((val) => {
      finalValue.push({
        taskName: val?.Service_Task_Name,
        sequence: val?.Task_Sequence,
        taskSection: val?.Single_Multi_Task_Type,
        taskTypeName: val?.Task_Type_Name,
        taskTypeId: Number(val?.Task_Type_Id),
        taskCategory: val?.Task_Category,
        taskDetails: val?.Task_Details,
        workType: val?.Work_Type,
        // tagName: val?.Tag_Name,
        tagId: Number(val?.Tag_Id),
        govtDeptName: val?.Govt_Dept_Name,
        govtDeptId: Number(val?.Govt_Dept_Id),
        noOfDays: val?.Nos_Task_Days,
        amount: val?.Task_Amount,
        anySpecialInstruction: val?.Special_Instruction,
        description: "",
      });
    })
  

    return finalValue;
  };
  return (
    <>
      <Formik
        initialValues={{
          mainTaskArray: isNOF ? handleNOFValue() : editModalPopper
            ? handleEditValue()
            : [
                {
                  taskName: "",
                  sequence: "",
                  taskSection: "",
                  taskTypeName: "",
                  taskTypeId: "",
                  taskCategory: "",
                  taskDetails: "",
                  workType: "",
                  // tagName: "",
                  tagId: "",
                  govtDeptName: "",
                  govtDeptId: "",
                  noOfDays: "",
                  amount: "",
                  anySpecialInstruction: "",
                  description: "",
                },
              ],
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addSOWPostAPI(data);
        }}
        validate={(values) => {
          // setDisabledId(values?.mainTaskArray)
          let errors = {};
          values?.mainTaskArray?.forEach((val) => {
            if (val.taskName === "") {
              errors.taskName = "Required!!";
            }
            if (val.sequence === "") {
              errors.sequence = "Required!!";
            }

            if (val.taskSection === "") {
              errors.taskSection = "Required";
            }
            if (val.taskTypeName === "") {
              errors.taskTypeName = "Required!!";
            }
            if (val.taskCategory === "") {
              errors.taskCategory = "Required!!";
            }
            if (val.taskDetails === "") {
              errors.taskDetails = "Required!!";
            }
            if (val.workType === "") {
              errors.workType = "Required!!";
            }
           
            if (val.govtDeptName === "") {
              errors.govtDeptName = "Required!!";
            }
            if (val.amount === "") {
              errors.amount = "Required!!";
            }
            if (val.noOfDays === "") {
              errors.noOfDays = "Required!!";
            }
          });
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              {!isNOF && (
     <div className={classes.quotationButtonDivModal}>
     <BootstrapTooltip arrow={true} disableFocusListener>
       <Button
         type="submit"
         variant="contained"
         size="small"
         style={{ backgroundColor: "#eb6e12" }}
         onClick={() => { 
          if(values?.mainTaskArray?.[0]?.taskName.length > 0 && Object.keys(errors)?.length === 0){ 
           handleSubmit(values) 
          } else {
            setOpen(true);
            setMessage(`Missing fields in SOW. Error: ${Object.keys(errors)?.toString()}`); 
            setSeverity(500); 
          }
          }}
       >
         {loading ? "Loading...." : "Save"}
       </Button>
     </BootstrapTooltip>
     <Button
       onClick={() => {
         setButtonText("SOW Template Listing");
       }}
       variant="contained"
       size="small"
       style={{
         backgroundColor: "#fff",
         color: "#eb6e12",
         marginLeft: "10px",
       }}
     >
       Cancel
     </Button>
   </div>
              )}
         
              <div
                style={{ height: "480px", overflowX: "hidden", padding: "0" }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                      zIndex: 10,
                    }}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={12} xs={12}>
                          <FieldArray
                            name="mainTaskArray"
                            render={(arrayHelpers) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  //marginRight: "1rem",
                                  justifyContent: "space-between",
                                  marginBottom: "1rem",
                                  width: "100%",
                                }}
                              >
                                {values.mainTaskArray?.length > 0 &&
                                  values.mainTaskArray?.map((item, i) => {
                                    return (
                                      <>
                                        <Grid item xs={12} md={12}>
                                          <div
                                            key={i}
                                            style={
                                              i !== values.mainTaskArray.length
                                                ? {
                                                    border: "1px solid #eb6e12",
                                                    padding: "5px",
                                                    width: "100%",
                                                    paddingRight: "20px",
                                                    paddingLeft: "10px",
                                                  }
                                                : {
                                                    border: "none",
                                                    padding: "5px",
                                                    marginTop: "2px",
                                                    width: "100%",
                                                    paddingRight: "20px",
                                                  }
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                position: "relative",
                                                left: "20px",
                                                cursor: "pointer",
                                                color: "#eb6e12",
                                                margin: "3px 10px 10px 0",
                                              }}
                                            >
                                              { !(isNOF || editModalPopper) && (
                                                <AddCircleIcon
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      taskName: "",
                                                      sequence: "",
                                                      taskSection: "",
                                                      taskTypeName: "",
                                                      taskCategory: "",
                                                      taskDetails: "",
                                                      workType: "",
                                                      // tagName: "",
                                                      govtDeptName: "",
                                                      noOfDays: "",
                                                      amount: "",
                                                      anySpecialInstruction: "",
                                                      description: "",
                                                    });
                                                  }}
                                                  style={
                                                    values.mainTaskArray
                                                      .length > 1
                                                      ? {
                                                          marginBottom: "0px",
                                                          marginTop: "0px",
                                                        }
                                                      : {
                                                          marginBottom: "0px",
                                                          marginTop: "0px",
                                                        }
                                                  }
                                                  className="ModalHeaderIcon"
                                                />
                                              )}
                                              {!editModalPopper &&
                                              values.mainTaskArray.length >
                                                1 ? (
                                                <RemoveCircleOutlineIcon
                                                  onClick={() =>
                                                    //handleRemoveFieldsActivity(item.id)
                                                    arrayHelpers.remove(i)
                                                  }
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                  className="ModalHeaderIcon"
                                                />
                                              ) : null}
                                            </div>

                                            <Grid container>
                                              <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                style={{ paddingRight: 5 }}
                                              >
                                                <InputText
                                                  label="Task Name"
                                                  fieldName={`mainTaskArray.[${i}].taskName`}
                                                  fieldValue={item?.taskName}
                                                  fieldHandleChange={(
                                                    event
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].taskName`,
                                                      event.target.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      .taskName
                                                  )}
                                                  errors={errors?.taskName}
                                                  requiredField={true}
                                                  onBlur={() =>
                                                    checkIsTaskNameExist(
                                                      item?.taskName,
                                                      setFieldValue,
                                                      i,
                                                      values?.mainTaskArray
                                                    )
                                                  }
                                                  disableField={editModalPopper ? true : false}
                                                />
                                                {isTaskNameExist === i && (
                                                  <Form.Group
                                                    as={Row}
                                                    className="mb-1"
                                                  >
                                                    <Form.Label
                                                      column
                                                      sm="5"
                                                      className="fc-text-label"
                                                    ></Form.Label>
                                                    <Col
                                                      sm="7"
                                                      style={{
                                                        padding: "0 12px 0 0",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ color: "red" }}
                                                      >
                                                        Task Name already
                                                        exists!!!
                                                      </span>
                                                    </Col>
                                                  </Form.Group>
                                                )}

                                                <CustomDropdown
                                                  label="Task Type"
                                                  fieldOptions={
                                                    taskTypeDropdown
                                                  }
                                                  fieldValue={item?.taskTypeId}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].taskTypeName`,
                                                      value.label
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].taskTypeId`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.taskTypeName
                                                  )}
                                                  requiredField={true}
                                                />

                                                <InputText
                                                  label="Task Details"
                                                  fieldName="taskDetails"
                                                  fieldValue={item?.taskDetails}
                                                  fieldHandleChange={(
                                                    event
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].taskDetails`,
                                                      event.target.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.taskDetails
                                                  )}
                                                  disableField={editModalPopper ? true : false}
                                                  requiredField={true}
                                                />

                                                {/* <CustomDropdown
                                                  label="Tag Name"
                                                  fieldOptions={tagNameDropdown}
                                                  fieldValue={item?.tagId}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].tagName`,
                                                      value.label
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].tagId`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.tagName
                                                  )}
                                                  requiredField={true}
                                                /> */}

                                                <InputText
                                                  label="No. of Days"
                                                  fieldName="noOfDays"
                                                  fieldValue={item?.noOfDays}
                                                  fieldType="text"
                                                  fieldHandleChange={
                                                    (e) => {
                                                      const value = e.target.value;
                                                  if (value === '' || /^[0-9]\d*$/.test(value)) {
                                                      setFieldValue(
                                                        `mainTaskArray.[${i}].noOfDays`,
                                                        value
                                                      );
                                                    }
                                                  }
                                                    //handleChangeInput(item?.id, event)
                                                  }
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.noOfDays
                                                  )}
                                                  requiredField={true}
                                                  //classvalue={"textBold"}
                                                />

                                                <InputText
                                                  label="Amount (AED)"
                                                  fieldName="amount"
                                                  fieldValue={item?.amount}
                                                  fieldType="number"
                                                  fieldHandleChange={
                                                    (event) => {
                                                      if(event.target.value < 0){
                                                        return;
                                                      }
                                                      setFieldValue(
                                                        `mainTaskArray.[${i}].amount`,
                                                        event.target.value
                                                      );
                                                    }
                                                    //handleChangeInput(item?.id, event)
                                                  }
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.amount
                                                  )}
                                                  requiredField={true}
                                                  //classvalue={"textBold"}
                                                />

                                                {/* <CustomTextArea
                                                label="Description"
                                                fieldName="description"
                                                fieldValue={item.description}
                                                fieldHandleChange={(e) => {
                                                  e.preventDefault();
                                                  const { value } = e.target;
                                                  setFieldValue(
                                                    `mainTaskArray[${i}].description`,
                                                    value
                                                  );
                                                }}
                                              /> */}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                style={{ paddingLeft: 5 }}
                                              >
                                                <CustomDropdown
                                                  label="Task Section"
                                                  fieldOptions={
                                                    taskSectionDropdown
                                                  }
                                                  fieldValue={item?.taskSection}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].taskSection`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.taskSection
                                                  )}
                                                  requiredField={true}
                                                />

                                                <CustomDropdown
                                                  label="Task Category"
                                                  fieldOptions={
                                                    taskCategoryDropdown
                                                  }
                                                  fieldValue={
                                                    item?.taskCategory
                                                  }
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].taskCategory`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.taskCategory
                                                  )}
                                                  requiredField={true}
                                                />

                                                <CustomDropdown
                                                  label="Work Type"
                                                  fieldOptions={
                                                    workTypeDropdown
                                                  }
                                                  fieldValue={item?.workType}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].workType`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.workType
                                                  )}
                                                  requiredField={true}
                                                  isDisabled={editModalPopper &&  operationInfo?.Work_Type=== "PRO Work" ? true : false}
                                                />
                                                <CustomDropdown
                                                  label="Govt. Department Name"
                                                  fieldOptions={
                                                    govtDeptDropdown
                                                  }
                                                  fieldValue={item?.govtDeptId}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].govtDeptId`,
                                                      value.value
                                                    );
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].govtDeptName`,
                                                      value.label
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.govtDeptName
                                                  )}
                                                  requiredField={true}
                                                />

                                                <InputText
                                                  label="Sequence"
                                                  fieldName="sequence"
                                                  fieldValue={item?.sequence}
                                                  fieldType="text"
                                                  fieldHandleChange={(
                                                    event
                                                  ) => {
                                                    if (event.target.value === "" || (/^[1-9][0-9]*$/.test(event.target.value))) {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].sequence`,
                                                      event.target.value
                                                    );
                                                  }
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.sequence
                                                  )}
                                                  requiredField={true}
                                                  onBlur={() => {
                                                    let findValue = sowList?.Service_Task_List?.find((val) => val.Task_Sequence === item?.sequence);
                                                    if(findValue) {
                                                      setFieldValue(
                                                        `mainTaskArray.[${i}].sequence`,
                                                        ''
                                                      );
                                                      setOpen(true);
                                                      setMessage(`Sequence number already exist!`); 
                                                      setSeverity(500); 
                                                    }
                                                    
                                                  }}
                                                />

                                                <InputText
                                                  label="Any Special Instruction"
                                                  fieldName="anySpecialInstruction"
                                                  fieldValue={
                                                    item?.anySpecialInstruction
                                                  }
                                                  fieldHandleChange={
                                                    (event) => {
                                                      setFieldValue(
                                                        `mainTaskArray.[${i}].anySpecialInstruction`,
                                                        event.target.value
                                                      );
                                                    }
                                                    //handleChangeInput(item?.id, event)
                                                  }
                                                  // errorField={getStyles(
                                                  //   errors,
                                                  //   values?.mainTaskArray[i]
                                                  //     ?.anySpecialInstruction
                                                  // )}
                                                  // requiredField={true}
                                                />
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </Grid>
                                      </>
                                    );
                                  })}
                              </div>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                  </Grid>
                </Grid>
              </div>
            </form>
          );
        }}
      </Formik>
      <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          action={action}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
    </>
  );
};

export default withStyles(modalTableStyles)(AddScopeOfWorkForm);
