import React, { useState, useContext, useEffect, useRef } from "react";

const CountriesCode = [{
  
    AF: {
      primary: "Afghanistan",
      secondary: "+93",
    },
    AX: {
      primary: "Aland Islands",
      secondary: "+358",
    },
    AL: {
      primary: "Albania",
      secondary: "+355",
    },
    DZ: {
      primary: "Algeria",
      secondary: "+213",
    },
    AS: {
      primary: "AmericanSamoa",
      secondary: "+1684",
    },
    AD: {
      primary: "Andorra",
      secondary: "+376",
    },
    AO: {
      primary: "Angola",
      secondary: "+244",
    },
    AI: {
      primary: "Anguilla",
      secondary: "+1264",
    },
    AQ: {
      primary: "Antarctica",
      secondary: "+672",
    },
    AG: {
      primary: "Antigua and Barbuda",
      secondary: "+1268",
    },
    AR: {
      primary: "Argentina",
      secondary: "+54",
    },
    AM: {
      primary: "Armenia",
      secondary: "+374",
    },
    AW: {
      primary: "Aruba",
      secondary: "+297",
    },
    AU: {
      primary: "Australia",
      secondary: "+61",
    },
    AT: {
      primary: "Austria",
      secondary: "+43",
    },
    AZ: {
      primary: "Azerbaijan",
      secondary: "+994",
    },
    BS: {
      primary: "Bahamas",
      secondary: "+1242",
    },
    BH: {
      primary: "Bahrain",
      secondary: "+973",
    },
    BD: {
      primary: "Bangladesh",
      secondary: "+880",
    },
    BB: {
      primary: "Barbados",
      secondary: "+1246",
    },
    BY: {
      primary: "Belarus",
      secondary: "+375",
    },
    BE: {
      primary: "Belgium",
      secondary: "+32",
    },
    BZ: {
      primary: "Belize",
      secondary: "+501",
    },
    BJ: {
      primary: "Benin",
      secondary: "+229",
    },
    BM: {
      primary: "Bermuda",
      secondary: "+1441",
    },
    BT: {
      primary: "Bhutan",
      secondary: "+975",
    },
    BO: {
      primary: "Bolivia, Plurinational State of",
      secondary: "+591",
    },
    BA: {
      primary: "Bosnia and Herzegovina",
      secondary: "+387",
    },
    BW: {
      primary: "Botswana",
      secondary: "+267",
    },
    BR: {
      primary: "Brazil",
      secondary: "+55",
    },
    IO: {
      primary: "British Indian Ocean Territory",
      secondary: "+246",
    },
    BN: {
      primary: "Brunei Darussalam",
      secondary: "+673",
    },
    BG: {
      primary: "Bulgaria",
      secondary: "+359",
    },
    BF: {
      primary: "Burkina Faso",
      secondary: "+226",
    },
    BI: {
      primary: "Burundi",
      secondary: "+257",
    },
    KH: {
      primary: "Cambodia",
      secondary: "+855",
    },
    CM: {
      primary: "Cameroon",
      secondary: "+237",
    },
    CA: {
      primary: "Canada",
      secondary: "+1",
    },
    CV: {
      primary: "Cape Verde",
      secondary: "+238",
    },
    KY: {
      primary: "Cayman Islands",
      secondary: "+345",
    },
    CF: {
      primary: "Central African Republic",
      secondary: "+236",
    },
    TD: {
      primary: "Chad",
      secondary: "+235",
    },
    CL: {
      primary: "Chile",
      secondary: "+56",
    },
    CN: {
      primary: "China",
      secondary: "+86",
    },
    CX: {
      primary: "Christmas Island",
      secondary: "+61",
    },
    CC: {
      primary: "Cocos (Keeling) Islands",
      secondary: "+61",
    },
    CO: {
      primary: "Colombia",
      secondary: "+57",
    },
    KM: {
      primary: "Comoros",
      secondary: "+269",
    },
    CG: {
      primary: "Congo",
      secondary: "+242",
    },
    CD: {
      primary: "Congo, The Democratic Republic of the Congo",
      secondary: "+243",
    },
    CK: {
      primary: "Cook Islands",
      secondary: "+682",
    },
    CR: {
      primary: "Costa Rica",
      secondary: "+506",
    },
    CI: {
      primary: "Cote d'Ivoire",
      secondary: "+225",
    },
    HR: {
      primary: "Croatia",
      secondary: "+385",
    },
    CU: {
      primary: "Cuba",
      secondary: "+53",
    },
    CY: {
      primary: "Cyprus",
      secondary: "+357",
    },
    CZ: {
      primary: "Czech Republic",
      secondary: "+420",
    },
    DK: {
      primary: "Denmark",
      secondary: "+45",
    },
    DJ: {
      primary: "Djibouti",
      secondary: "+253",
    },
    DM: {
      primary: "Dominica",
      secondary: "+1767",
    },
    DO: {
      primary: "Dominican Republic",
      secondary: "+1849",
    },
    EC: {
      primary: "Ecuador",
      secondary: "+593",
    },
    EG: {
      primary: "Egypt",
      secondary: "+20",
    },
    SV: {
      primary: "El Salvador",
      secondary: "+503",
    },
    GQ: {
      primary: "Equatorial Guinea",
      secondary: "+240",
    },
    ER: {
      primary: "Eritrea",
      secondary: "+291",
    },
    EE: {
      primary: "Estonia",
      secondary: "+372",
    },
    ET: {
      primary: "Ethiopia",
      secondary: "+251",
    },
    FK: {
      primary: "Falkland Islands (Malvinas)",
      secondary: "+500",
    },
    FO: {
      primary: "Faroe Islands",
      secondary: "+298",
    },
    FJ: {
      primary: "Fiji",
      secondary: "+679",
    },
    FI: {
      primary: "Finland",
      secondary: "+358",
    },
    FR: {
      primary: "France",
      secondary: "+33",
    },
    GF: {
      primary: "French Guiana",
      secondary: "+594",
    },
    PF: {
      primary: "French Polynesia",
      secondary: "+689",
    },
    GA: {
      primary: "Gabon",
      secondary: "+241",
    },
    GM: {
      primary: "Gambia",
      secondary: "+220",
    },
    GE: {
      primary: "Georgia",
      secondary: "+995",
    },
    DE: {
      primary: "Germany",
      secondary: "+49",
    },
    GH: {
      primary: "Ghana",
      secondary: "+233",
    },
    GI: {
      primary: "Gibraltar",
      secondary: "+350",
    },
    GR: {
      primary: "Greece",
      secondary: "+30",
    },
    GL: {
      primary: "Greenland",
      secondary: "+299",
    },
    GD: {
      primary: "Grenada",
      secondary: "+1473",
    },
    GP: {
      primary: "Guadeloupe",
      secondary: "+590",
    },
    GU: {
      primary: "Guam",
      secondary: "+1671",
    },
    GT: {
      primary: "Guatemala",
      secondary: "+502",
    },
    GG: {
      primary: "Guernsey",
      secondary: "+44",
    },
    GN: {
      primary: "Guinea",
      secondary: "+224",
    },
    GW: {
      primary: "Guinea-Bissau",
      secondary: "+245",
    },
    GY: {
      primary: "Guyana",
      secondary: "+595",
    },
    HT: {
      primary: "Haiti",
      secondary: "+509",
    },
    VA: {
      primary: "Holy See (Vatican City State)",
      secondary: "+379",
    },
    HN: {
      primary: "Honduras",
      secondary: "+504",
    },
    HK: {
      primary: "Hong Kong",
      secondary: "+852",
    },
    HU: {
      primary: "Hungary",
      secondary: "+36",
    },
    IS: {
      primary: "Iceland",
      secondary: "+354",
    },
    IN: {
      primary: "India",
      secondary: "+91",
    },
    ID: {
      primary: "Indonesia",
      secondary: "+62",
    },
    IR: {
      primary: "Iran, Islamic Republic of Persian Gulf",
      secondary: "+98",
    },
    IQ: {
      primary: "Iraq",
      secondary: "+964",
    },
    IE: {
      primary: "Ireland",
      secondary: "+353",
    },
    IM: {
      primary: "Isle of Man",
      secondary: "+44",
    },
    IL: {
      primary: "Israel",
      secondary: "+972",
    },
    IT: {
      primary: "Italy",
      secondary: "+39",
    },
    JM: {
      primary: "Jamaica",
      secondary: "+1876",
    },
    JP: {
      primary: "Japan",
      secondary: "+81",
    },
    JE: {
      primary: "Jersey",
      secondary: "+44",
    },
    JO: {
      primary: "Jordan",
      secondary: "+962",
    },
    KZ: {
      primary: "Kazakhstan",
      secondary: "+77",
    },
    KE: {
      primary: "Kenya",
      secondary: "+254",
    },
    KI: {
      primary: "Kiribati",
      secondary: "+686",
    },
    KP: {
      primary: "Korea, Democratic People's Republic of Korea",
      secondary: "+850",
    },
    KR: {
      primary: "Korea, Republic of South Korea",
      secondary: "+82",
    },
    KW: {
      primary: "Kuwait",
      secondary: "+965",
    },
    KG: {
      primary: "Kyrgyzstan",
      secondary: "+996",
    },
    LA: {
      primary: "Laos",
      secondary: "+856",
    },
    LV: {
      primary: "Latvia",
      secondary: "+371",
    },
    LB: {
      primary: "Lebanon",
      secondary: "+961",
    },
    LS: {
      primary: "Lesotho",
      secondary: "+266",
    },
    LR: {
      primary: "Liberia",
      secondary: "+231",
    },
    LY: {
      primary: "Libyan Arab Jamahiriya",
      secondary: "+218",
    },
    LI: {
      primary: "Liechtenstein",
      secondary: "+423",
    },
    LT: {
      primary: "Lithuania",
      secondary: "+370",
    },
    LU: {
      primary: "Luxembourg",
      secondary: "+352",
    },
    MO: {
      primary: "Macao",
      secondary: "+853",
    },
    MK: {
      primary: "Macedonia",
      secondary: "+389",
    },
    MG: {
      primary: "Madagascar",
      secondary: "+261",
    },
    MW: {
      primary: "Malawi",
      secondary: "+265",
    },
    MY: {
      primary: "Malaysia",
      secondary: "+60",
    },
    MV: {
      primary: "Maldives",
      secondary: "+960",
    },
    ML: {
      primary: "Mali",
      secondary: "+223",
    },
    MT: {
      primary: "Malta",
      secondary: "+356",
    },
    MH: {
      primary: "Marshall Islands",
      secondary: "+692",
    },
    MQ: {
      primary: "Martinique",
      secondary: "+596",
    },
    MR: {
      primary: "Mauritania",
      secondary: "+222",
    },
    MU: {
      primary: "Mauritius",
      secondary: "+230",
    },
    YT: {
      primary: "Mayotte",
      secondary: "+262",
    },
    MX: {
      primary: "Mexico",
      secondary: "+52",
    },
    FM: {
      primary: "Micronesia, Federated States of Micronesia",
      secondary: "+691",
    },
    MD: {
      primary: "Moldova",
      secondary: "+373",
    },
    MC: {
      primary: "Monaco",
      secondary: "+377",
    },
    MN: {
      primary: "Mongolia",
      secondary: "+976",
    },
    ME: {
      primary: "Montenegro",
      secondary: "+382",
    },
    MS: {
      primary: "Montserrat",
      secondary: "+1664",
    },
    MA: {
      primary: "Morocco",
      secondary: "+212",
    },
    MZ: {
      primary: "Mozambique",
      secondary: "+258",
    },
    MM: {
      primary: "Myanmar",
      secondary: "+95",
    },
    NA: {
      primary: "Namibia",
      secondary: "+264",
    },
    NR: {
      primary: "Nauru",
      secondary: "+674",
    },
    NP: {
      primary: "Nepal",
      secondary: "+977",
    },
    NL: {
      primary: "Netherlands",
      secondary: "+31",
    },
    AN: {
      primary: "Netherlands Antilles",
      secondary: "+599",
    },
    NC: {
      primary: "New Caledonia",
      secondary: "+687",
    },
    NZ: {
      primary: "New Zealand",
      secondary: "+64",
    },
    NI: {
      primary: "Nicaragua",
      secondary: "+505",
    },
    NE: {
      primary: "Niger",
      secondary: "+227",
    },
    NG: {
      primary: "Nigeria",
      secondary: "+234",
    },
    NU: {
      primary: "Niue",
      secondary: "+683",
    },
    NF: {
      primary: "Norfolk Island",
      secondary: "+672",
    },
    MP: {
      primary: "Northern Mariana Islands",
      secondary: "+1670",
    },
    NO: {
      primary: "Norway",
      secondary: "+47",
    },
    OM: {
      primary: "Oman",
      secondary: "+968",
    },
    PK: {
      primary: "Pakistan",
      secondary: "+92",
    },
    PW: {
      primary: "Palau",
      secondary: "+680",
    },
    PS: {
      primary: "Palestinian Territory, Occupied",
      secondary: "+970",
    },
    PA: {
      primary: "Panama",
      secondary: "+507",
    },
    PG: {
      primary: "Papua New Guinea",
      secondary: "+675",
    },
    PY: {
      primary: "Paraguay",
      secondary: "+595",
    },
    PE: {
      primary: "Peru",
      secondary: "+51",
    },
    PH: {
      primary: "Philippines",
      secondary: "+63",
    },
    PN: {
      primary: "Pitcairn",
      secondary: "+872",
    },
    PL: {
      primary: "Poland",
      secondary: "+48",
    },
    PT: {
      primary: "Portugal",
      secondary: "+351",
    },
    PR: {
      primary: "Puerto Rico",
      secondary: "+1939",
    },
    QA: {
      primary: "Qatar",
      secondary: "+974",
    },
    RE: {
      primary: "Reunion",
      secondary: "+262",
    },
    RO: {
      primary: "Romania",
      secondary: "+40",
    },
    RU: {
      primary: "Russia",
      secondary: "+7",
    },
    RW: {
      primary: "Rwanda",
      secondary: "+250",
    },
    BL: {
      primary: "Saint Barthelemy",
      secondary: "+590",
    },
    SH: {
      primary: "Saint Helena, Ascension and Tristan Da Cunha",
      secondary: "+290",
    },
    KN: {
      primary: "Saint Kitts and Nevis",
      secondary: "+1869",
    },
    LC: {
      primary: "Saint Lucia",
      secondary: "+1758",
    },
    MF: {
      primary: "Saint Martin",
      secondary: "+590",
    },
    PM: {
      primary: "Saint Pierre and Miquelon",
      secondary: "+508",
    },
    VC: {
      primary: "Saint Vincent and the Grenadines",
      secondary: "+1784",
    },
    WS: {
      primary: "Samoa",
      secondary: "+685",
    },
    SM: {
      primary: "San Marino",
      secondary: "+378",
    },
    ST: {
      primary: "Sao Tome and Principe",
      secondary: "+239",
    },
    SA: {
      primary: "Saudi Arabia",
      secondary: "+966",
    },
    SN: {
      primary: "Senegal",
      secondary: "+221",
    },
    RS: {
      primary: "Serbia",
      secondary: "+381",
    },
    SC: {
      primary: "Seychelles",
      secondary: "+248",
    },
    SL: {
      primary: "Sierra Leone",
      secondary: "+232",
    },
    SG: {
      primary: "Singapore",
      secondary: "+65",
    },
    SK: {
      primary: "Slovakia",
      secondary: "+421",
    },
    SI: {
      primary: "Slovenia",
      secondary: "+386",
    },
    SB: {
      primary: "Solomon Islands",
      secondary: "+677",
    },
    SO: {
      primary: "Somalia",
      secondary: "+252",
    },
    ZA: {
      primary: "South Africa",
      secondary: "+27",
    },
    GS: {
      primary: "South Georgia and the South Sandwich Islands",
      secondary: "+500",
    },
    SS: {
      primary: "South Sudan",
      secondary: "+211",
    },
    ES: {
      primary: "Spain",
      secondary: "+34",
    },
    LK: {
      primary: "Sri Lanka",
      secondary: "+94",
    },
    SD: {
      primary: "Sudan",
      secondary: "+249",
    },
    SR: {
      primary: "Suriname",
      secondary: "+597",
    },
    SJ: {
      primary: "Svalbard and Jan Mayen",
      secondary: "+47",
    },
    SZ: {
      primary: "Swaziland",
      secondary: "+268",
    },
    SE: {
      primary: "Sweden",
      secondary: "+46",
    },
    CH: {
      primary: "Switzerland",
      secondary: "+41",
    },
    SY: {
      primary: "Syrian Arab Republic",
      secondary: "+963",
    },
    TW: {
      primary: "Taiwan",
      secondary: "+886",
    },
    TJ: {
      primary: "Tajikistan",
      secondary: "+992",
    },
    TZ: {
      primary: "Tanzania, United Republic of Tanzania",
      secondary: "+255",
    },
    TH: {
      primary: "Thailand",
      secondary: "+66",
    },
    TL: {
      primary: "Timor-Leste",
      secondary: "+670",
    },
    TG: {
      primary: "Togo",
      secondary: "+228",
    },
    TK: {
      primary: "Tokelau",
      secondary: "+690",
    },
    TO: {
      primary: "Tonga",
      secondary: "+676",
    },
    TT: {
      primary: "Trinidad and Tobago",
      secondary: "+1868",
    },
    TN: {
      primary: "Tunisia",
      secondary: "+216",
    },
    TR: {
      primary: "Turkey",
      secondary: "+90",
    },
    TM: {
      primary: "Turkmenistan",
      secondary: "+993",
    },
    TC: {
      primary: "Turks and Caicos Islands",
      secondary: "+1649",
    },
    TV: {
      primary: "Tuvalu",
      secondary: "+688",
    },
    UG: {
      primary: "Uganda",
      secondary: "+256",
    },
    UA: {
      primary: "Ukraine",
      secondary: "+380",
    },
    AE: {
      primary: "United Arab Emirates",
      secondary: "+971",
    },
    GB: {
      primary: "United Kingdom",
      secondary: "+44",
    },
    US: {
      primary: "United States",
      secondary: "+1",
    },
    UY: {
      primary: "Uruguay",
      secondary: "+598",
    },
    UZ: {
      primary: "Uzbekistan",
      secondary: "+998",
    },
    VU: {
      primary: "Vanuatu",
      secondary: "+678",
    },
    VE: {
      primary: "Venezuela, Bolivarian Republic of Venezuela",
      secondary: "+58",
    },
    VN: {
      primary: "Vietnam",
      secondary: "+84",
    },
    VG: {
      primary: "Virgin Islands, British",
      secondary: "+1284",
    },
    VI: {
      primary: "Virgin Islands, U.S.",
      secondary: "+1340",
    },
    WF: {
      primary: "Wallis and Futuna",
      secondary: "+681",
    },
    YE: {
      primary: "Yemen",
      secondary: "+967",
    },
    ZM: {
      primary: "Zambia",
      secondary: "+260",
    },
    ZW: {
      primary: "Zimbabwe",
      secondary: "+263",
    },
  }];


  export default CountriesCode;