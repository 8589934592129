import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import { Button } from "react-bootstrap";
  import AddIcon from "@mui/icons-material/Add";
  import EditIcon from "@mui/icons-material/Edit";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import { DataContext, SupportDataContext } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
  import Badge from "@mui/material/Badge";
  import ModalPopper from "../Modals/ModelPopper";
  import {
    getTotalLength,
    handleDefaultFilters,
    handleFilters,
  } from "../../../../utils/Filters";
  import { FaEye, FaCheckDouble } from "react-icons/fa";
  import Col from "react-bootstrap/Col";
  import Form from "react-bootstrap/Form";
  import Row from "react-bootstrap/Row";
  import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
  import { Grid, Modal } from "@material-ui/core";
  import moment from "moment";
  import ViewOrderFormForCompany from "../../../listing/listingModals/order-form-listing/viewOrderFormForCompany";
  import DownloadIcon from "@mui/icons-material/Download";
  import axios from "axios";
  import fileDownload from "js-file-download";
  import ViewOrderForm from "../../../listing/listingModals/order-form-listing/viewOrderForm";
  import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
  import { Formik } from "formik";
  import { css } from "@emotion/react"
  import ClockLoader from 'react-spinners/ClockLoader';


  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const OldNOFListingTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setEditModalPopper,
      setButtonText,
      setOperationInfo,
      setSelected,
      setLoading,
      handleModalOpen,
      message,
      setMessage,
      setSeverity,
      handlePromise,
      isOpen,
      setIsOpen,
      severity,
      loading,
      getOldNOFDataApi, oldNofData
    } = useContext(SupportDataContext);
    const {
      setBusinessInfo,
      setSalesInfo,
      getOrderDropDownForCompany,
      setErrorAlert,
      setError,
      getOrderDropDownApi,
      businessInfo, setStart_Date
    } = useContext(DataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
  
    const [modalValue, setModalValue] = useState("");
    const [openModal, setOpenModal] = useState(false);
  
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const [reqFromDate, setReqFromDate] = useState("");
    const [reqToDate, setReqToDate] = useState("");
    const todayDate = moment().format("YYYY-MM-DD");
    const [nofFromDate, setNofFromDate] = useState("");
    const [nofToDate, setNofToDate] = useState("");
  
    const api_data = oldNofData?.Older_Order_Form_List_On_Company?.concat(oldNofData?.Older_Order_Form_List_On_Inquiry) || null;

  
    useEffect(() => {
      handleListingSavedSearch();
    }, []);

    useEffect(() => {
      getOldNOFDataApi(nofFromDate, nofToDate);
    }, [nofToDate]);

    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue.split(" ");
        if (dateAsString == null) {
          return -1;
        }
        if (dateAsString == "") {
          return -1;
        }
        const dateParts = dateAsString[0].split("-");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
    const downloadBtnCompany = (value) => {
      setLoading(value?.Serial_Number);
      let empId = localStorage.getItem("emp_id");
      let factory_id = localStorage.getItem("factory_id");
      let user_type = localStorage.getItem("user_type");
      const __token = localStorage.getItem("token");
  
      axios
        .get(
          `https://flyingcolour.online/fcapp/company/orderformdownloadoncompany/?factory_id=${factory_id}&order_id=${value.Order_Id}&emp_id=${empId}&company_id=${value.company_id}&user_type=${user_type}&sub_lead_id=${value.sub_lead_id}`,
          {
            responseType: "application/pdf",
            headers: {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoading(-1);
          fileDownload(res.data, "New Order Form.pdf");
        })
        .catch((error) => {
          setLoading(-1);
          if (error?.response?.status === 401) {
            return (
              setErrorAlert(true),
              setError(
                `${error.response?.statusText} ${error.response?.status}-${
                  error.response?.data?.detail
                }${" "}${"Please Login Again!!"}`
              )
            );
          } else if (error?.response) {
            return (
              setErrorAlert(true),
              setError(
                `${error.response?.statusText} ${
                  error.response?.status
                }-${"Please try again!!"}`
              )
            );
          }
          // else if (!error?.response){
          //   return  setErrorAlert(true), setError(`${error}-${"Please Check Your Network Connectivity and try again!!"}`)
  
          // }
        });
    };
  
    const downloadBtnInquiry = (value) => {
      setLoading(value?.Serial_Number);
      let empId = localStorage.getItem("emp_id");
      let factory_id = localStorage.getItem("factory_id");
      let user_type = localStorage.getItem("user_type");
      const __token = localStorage.getItem("token");
  
      //https://flyingcolour.online/fcapp/neha_test_api/OrderFormVatDownload/?company_inquiry_flag=0&order_id=3363
  
      if (
        (value.Order_Status === "Created" &&
          businessInfo.enquiry_type === "enq") ||
        (value.Order_Status === "Pending" && businessInfo.enquiry_type === "enq")
      ) {
        axios
          .get(
            `https://flyingcolour.online/fcapp/spm_api/orderformdownload/?factory_id=${factory_id}&order_id=${value.Order_Id}&emp_id=${empId}&inquiry_id=${value.inquiry_id}&user_type=${user_type}&company_flag=1`,
            {
              responseType: "application/pdf",
              headers: {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setLoading(-1);
            fileDownload(res.data, "Order Form.pdf");
          })
          .catch((error) => {
            setLoading(-1);
            if (error?.response?.status === 401) {
              return (
                setErrorAlert(true),
                setError(
                  `${error.response?.statusText} ${error.response?.status}-${
                    error.response?.data?.detail
                  }${" "}${"Please Login Again!!"}`
                )
              );
            } else if (error?.response) {
              return (
                setErrorAlert(true),
                setError(
                  `${error.response?.statusText} ${
                    error.response?.status
                  }-${"Please try again!!"}`
                )
              );
            } else if (!error?.response) {
              return (
                setErrorAlert(true),
                setError(
                  `${error}-${"Please Check Your Network Connectivity and try again!!"}`
                )
              );
            }
          });
      }
  
      if (
        (value.Order_Status === "Created" &&
          businessInfo.enquiry_type === "vt") ||
        (value.Order_Status === "Pending" && businessInfo.enquiry_type === "vt")
      ) {
        axios
          .get(
            `https://flyingcolour.online/fcapp/neha_test_api/OrderFormVatDownload/?company_inquiry_flag=0&order_id=${value.Order_Id}`,
            {
              responseType: "application/pdf",
              headers: {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setLoading(-1);
            fileDownload(res.data, "Order Form.pdf");
          })
          .catch((error) => {
            setLoading(-1);
            if (error?.response?.status === 401) {
              return (
                setErrorAlert(true),
                setError(
                  `${error.response?.statusText} ${error.response?.status}-${
                    error.response?.data?.detail
                  }${" "}${"Please Login Again!!"}`
                )
              );
            } else if (error?.response) {
              return (
                setErrorAlert(true),
                setError(
                  `${error.response?.statusText} ${
                    error.response?.status
                  }-${"Please try again!!"}`
                )
              );
            } else if (!error?.response) {
              return (
                setErrorAlert(true),
                setError(
                  `${error}-${"Please Check Your Network Connectivity and try again!!"}`
                )
              );
            }
          });
      }
    };
  
    const columnDefs = [
      {
        headerName: "S #",
        field: "Serial_Number",
        // cellRenderer: "agGroupCellRenderer",
        // checkboxSelection: true,
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          textAlign: "center",
        },
        minWidth: 40,
        pinned: "left",
        filter: false,
      },
      {
        headerName: "Actions",
        field: "Inquiry_Id",
        filter: false,
        // checkboxSelection: true,
        cellStyle: { border: "1px solid #fff", overflowY: "auto" },
        minWidth: 170,
  
        pinned: "left",
        cellRenderer: (params) => {
          return (
            <>
              <CustomIconButton
                title="View NOF"
                width={"auto"}
                icon={
                  <>
                    <FaEye />
                    {"View NOF"}
                  </>
                }
                onClick={() => {
                  if (params?.data?.inquiry_company === "Company") {
                    setBusinessInfo(params.data);
                    setSalesInfo(params?.data);
                    getOrderDropDownForCompany(params.data);
                    setModalValue("viewforcompany");
                    setOpenModal(true);
                  } else {
                    setButtonText("Add Order Form");
                    setModalValue("viewforinquiry");
                    setSalesInfo(params?.data);
                    getOrderDropDownApi(params.data);
                    setBusinessInfo(params.data);
                    setOpenModal(true);
                  }
                }}
              />
  
              <CustomIconButton
                title="Dowload NOF"
                width={"auto"}
                icon={
                  <>
                    <DownloadIcon />
                    {"Download NOF"}
                  </>
                }
                onClick={() => {
           
                  if (params?.data?.inquiry_company === "Company") {
                    if (params?.data?.downloadFilePath !== "") {
                      window.open(params?.data?.downloadFilePath, "_blank");
                    } else {
                      downloadBtnCompany(params?.data);
                    }
                  } else {
                    if (params?.data?.downloadFilePath !== "") {
                      window.open(params?.data?.downloadFilePath, "_blank");
                    } else {
                      downloadBtnInquiry(params?.data);
                    }
                  }
                }}
              />
            </>
          );
        },
      },
      {
        headerName: "NOF Type",
        field: "inquiry_company",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
        width: 140,
        pinned: "left",
      },
      {
        headerName: "Inquiry / Company Id",
        field: "inquiry_id",
        filter: 'agTextColumnFilter',
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 140,
        pinned: "left",
        filterValueGetter: (params) => {
          return `${params?.data?.inquiry_id} ${params?.data?.company_id}`; 
        },
        cellRenderer: (params) => {
          return (
            <>
              {params?.data?.inquiry_id
                ? params?.data?.inquiry_id
                : params?.data?.company_id}
            </>
          );
        },
      },
      {
        headerName: "Client / Company Name",
        field: "company_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 200,
        filter: 'agTextColumnFilter',
        pinned: "left",
        filterValueGetter: (params) => {
          return `${params?.data?.company_name} ${params?.data?.client_first_name}`; 
        },
        cellRenderer: (params) => {
          return (
            <>
              {params?.data?.inquiry_id
                ? params?.data?.client_first_name + " " + params?.data?.client_last_name
                : params?.data?.company_name}
            </>
          );
        },
      },
      {
        headerName: "State",
        field: "state_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 140,
        pinned: "left",
      },
      {
        headerName: "Formation Type",
        field: "formation_type",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 200,
      },
  
      {
        headerName: "Legal Type Name",
        field: "legal_type_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 170,
      },
  
      {
        headerName: "Sub Lead Name",
        field: "sub_Lead_Name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 170,
      },
      {
        headerName: "NOF Date",
        field: "Order_Date",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 150,
      },
      {
        headerName: "NOF Approval Date",
        field: "Approval_Date",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto" },
        width: 150,
      },
      {
        headerName: "NOF Title",
        field: "order_title",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        filter: "agSetColumnFilter",
        width: 300,
      },
     
     
      // {
      //   headerName: "NOF Remarks",
      //   field: "remarks",
      //   cellStyle: {
      //     borderBottom: "1px solid #fff",
      //     borderRight: "1px solid #fff",
      //     overflowY: "auto",
      //   },
      //   width: 200,
      // },
      // {
      //   headerName: "Client Name",
      //   field: "remarks",
      //   cellStyle: {
      //     borderBottom: "1px solid #fff",
      //     borderRight: "1px solid #fff",
      //     overflowY: "auto",
      //   },
      //   width: 200,
      //   cellRenderer: (params) => {
      //     return (
      //       <>
      //         {params?.data?.client_first_name} {params?.data?.client_last_name}
      //       </>
      //     );
      //   },
      // },
      // {
      //   headerName: "Client Email",
      //   field: "client_email",
      //   cellStyle: {
      //     borderBottom: "1px solid #fff",
      //     borderRight: "1px solid #fff",
      //     overflowY: "auto",
      //   },
      //   width: 200,
      // },
  
      // {
      //   headerName: "Client Mobile",
      //   field: "client_mobile",
      //   cellStyle: {
      //     borderBottom: "1px solid #fff",
      //     borderRight: "1px solid #fff",
      //     overflowY: "auto",
      //   },
      //   width: 200,
      // },
      // {
      //   headerName: "Company",
      //   field: "company_id",
      //   cellStyle: {
      //     borderBottom: "1px solid #fff",
      //     borderRight: "1px solid #fff",
      //     overflowY: "auto",
      //   },
      //   width: 200,
      //   hide: true,
      // },
      // {
      //   headerName: "Inquiry",
      //   field: "inquiry_id",
      //   cellStyle: {
      //     borderBottom: "1px solid #fff",
      //     borderRight: "1px solid #fff",
      //     overflowY: "auto",
      //   },
      //   width: 200,
      //   hide: true,
      // },
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = cellValue.split("/");
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      };
    }, []);
  
    const onRowSelected = (event) => {
      const rowData = event.api.getSelectedNodes()[0]?.data;
      setSelected([rowData]);
    };
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const filters = [
      {
        id: 1,
        title: "Default",
        value: null,
        key: null,
      },
      {
        id: 2,
        title: "Company",
        value: null,
        key: "company_id",
      },
      {
        id: 3,
        title: "Inquiry",
        value: null,
        key: "inquiry_id",
      },
      
    ];
  
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    const handleSearch = (searchName, value, searchKey) => {
      handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
    };

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: '#eb6e12';
    box-shadow: inset '0px 0px 0px 7px #eb6e12';
    margin-top: 10%;
    `;

    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
        
        {fitlerBadge ? (
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                marginTop: "30px",
                paddingBottom: "35px",
              }}
            >
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={api_data}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                {api_data?.length > 0 &&
                  filters.map((val, i) => {
                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(api_data, val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: "Times New Roman",
                        }}
                        onClick={() =>
                          handleDefaultFilters(
                            gridRef,
                            setDefaultFilterValue,
                            val.title,
                            val.value,
                            val.key
                          )
                        }
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const keyValue = val.Search_Filter?.split("{")[2]
                      ?.split(":")[1]
                      ?.replaceAll('"', "")
                      ?.split(",")[0]
                      .replace("[", "")
                      .replace("]", "");
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLength(
                          api_data,
                          val.Search_Name,
                          valueKey
                        )}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(
                            gridRef,
                            setDefaultFilterValue,
                            val.Search_Name,
                            keyValue?.trim(),
                            val.Search_Filter
                          );
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>
          </>
        ) : null}
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={api_data?.length}
        />
          <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-35px",
              }}
            >
              <div className="formLeadFlexDivModal2">
                <label htmlFor="company" className="labelFormInputModal">
                  <span>
                  Select NOF Approval Date Range:
                    
                  </span>
                </label>
  
                <div className="selectContainerModal" style={{width: "250px"}}>
                 
                  <DateRangePickerComp
                        custom={true}
                        fieldHandleChange={(value, picker) => {
                          setNofFromDate(
                            moment(picker.startDate).format("YYYY-MM-DD")
                          );
                          setNofToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                          setStart_Date(moment(picker.startDate).format("YYYY-MM-DD"))
                        }}
                      />
                 
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  
            </form>
          )}
        </Formik>
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
           {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
          <AgGridReact
           suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={api_data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 90}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
          )}
        </div>
        <ModalPopper />
        <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
  
        <Modal
          open={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            {modalValue === "viewforcompany" ? (
              <ViewOrderFormForCompany setOpenModal={setOpenModal} />
            ) : modalValue === "viewforinquiry" ? (
              <ViewOrderForm setOpenModal={setOpenModal} isSupportModule={true} />
            ) : null}
          </>
        </Modal>
      </div>
    );
  };
  
  export default OldNOFListingTable;
  