import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import modalTableStyles from "../../styles/ModalTableStyles";
import {
  SupportDataContext,
  DataContext,
} from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CKRictTextEditer } from "../../../../listing/listingForm/RichTextEditer";
import "../../styles/styles.css";
import previewFormStyles from "../../Preview/previewStyle";

const ViewTemplateCreation = ({ classes }) => {
  const {
    handleModalTableClose,
    operationInfo,
    templateCreationList
  } = useContext(SupportDataContext);
  const [sowOrDesc, setSoworDesc] = useState([]);
  const [orderCategory, setOrderCategory] = useState(null)

  useEffect(() => {
    const findDescOrSow = templateCreationList?.Service_Task_List?.filter((val) => Number(val?.Template_Service_Id) === operationInfo?.Service_Id);
    if(operationInfo?.template_category === "Order Template"){
      setSoworDesc(findDescOrSow);
      setOrderCategory("Order Template")
    } else if(operationInfo?.template_category === "Order Sub Template") {
      let projectDescription = findDescOrSow?.map((val) => {
        return val?.Task_Details;
      });
      setSoworDesc(projectDescription?.toString());
      setOrderCategory("Order Sub Template")
    }
  }, [operationInfo])

  return (
    <div  style={{background:"#fff", padding:10}}>
      <div className={classes.quotationButtonDivModal}>
        <Button
          onClick={() => {
            handleModalTableClose();
            // handleHide()
          }}
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#fff",
            color: "#eb6e12",
            marginLeft: "10px",
          }}
        >
          Close
        </Button>
      </div>
      <div style={{ height: "400px", overflowX: "hidden", padding: "0" }}>
        <Grid container spacing={2} columns={12}>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              marginBottom: "-30px",
              paddingBottom: "10px",
              marginTop: "-45px",
              zIndex: 10,
            }}
          >
            <br />
            <br />
            <br />
            <br />
            <div className={classes.quotationModalFlexDivContaner}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.quotationInnerGridRight}
                >
                  <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                       <p  className="fc-dropdown-label" > Template Name <sup style={{ color: "red", }}>*</sup></p>
                  <input
                  className="fc-dropdown-label"
                   // label="Template Name"
                    name="templateName"
                    value={operationInfo?.Service_Name}
                    // requiredField={true}
                    // disableField={true}
                    disabled
                    //classvalue={"textBold"}
                    style={{height: "30px", width: "99%"}}
                  />
                   </Form.Group>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                       <p  className="fc-dropdown-label" > Template Description <sup style={{ color: "red", }}>*</sup></p>
                    <textarea
                   // label="Template Description"
                   className="fc-input-container"
                    name="templateDescription"
                    value={operationInfo?.Service_Details}
                    disabled
                    style={{height: "140px", width: "99%"}}
                  />
                      </Form.Group>
                    </Grid>
                <Grid item xs={12} md={12}>
                  {orderCategory === "Order Template" && (
                    <>
                    <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                          <Form.Label
                            column
                            sm="12"
                            className="fc-dropdown-label"
                          >
                            Order Template Scope Of Work
                            <sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                        </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId={"sow"}>

                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex" }}
                        className={classes.followUpFormInnerGridLeft}
                      >
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.cellData}
                        >
                          Name
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.cellData}
                        >
                          Task Type
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Section
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Category
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Detail
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Work Type
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Days
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Amount
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Sequence
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Govt. Dept Name
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Spl. Instruction
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Tag Name
                        </Grid>
                      </Grid>

                      {sowOrDesc?.map(
                        (i) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                className={classes.followUpFormInnerGridLeft}
                              >
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.cellData}
                                >
                                  {i?.Service_Task_Name}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.cellData}
                                >
                                  {i?.Task_Type_Name}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Single_Multi_Task_Type}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Category}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Details}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Work_Type}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Nos_Task_Days}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Amount}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Task_Sequence}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Govt_Dept_Name}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  {i?.Special_Instruction}
                                </Grid>
                                <Grid
                                  xs={1}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: i?.Tag_Name,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          );
                        }
                      )}
                    </>
                    </Form.Group>
                    </>
                  ) 
                      }
                  
                  {orderCategory === "Order Sub Template" && (
                    <>
                    <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                          <Form.Label
                            column
                            sm="12"
                            className="fc-dropdown-label"
                          >
                            Order Description
                            <sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                        </Form.Group>
                    
                    <Grid
                    container
                    spacing={2}
                    columns={12}
                    
                  >
                    <Grid item xs={12} md={12}>
                      <div
                        className="fc-input-container"
                        style={{ marginRight: 3 }}
                      >
                        <CKRictTextEditer
                          disabledField={true}
                          data={sowOrDesc}
                          height="300px"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  </>
                  )}
                </Grid>
                  <>
                 
                 
                  </>
                {/* <Grid item xs={12} md={12}>
                  <CustomTextArea
                    label="Template Description"
                    fieldName="templateDescription"
                    fieldValue={operationInfo?.Service_Details}
                    disableField={true}
                    height={"300px"}
                  />
                </Grid> */}
              </Grid>
            </div>
            <br />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(previewFormStyles)(ViewTemplateCreation);
