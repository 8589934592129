import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import PendingRequistionListing from "./PendingRequistionListing";
import { VpnLock } from "@material-ui/icons";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding:"25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow:'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if(height && !getIn(errors, fieldName) ){
    return {height:height}
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height:height
    };
  } else if(getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const RequistionModalForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    requistionListing,
    setMessage,
    setSeverity,
    editModalPopper,
    viewRequistion,
    operationInfo,
    setIsOpen,
    getRequistionListingDataFromApi
  } = useContext(SupportDataContext);
  const factory_id = localStorage.getItem("factory_id");
  const [mainTask, setMainTask] = useState(null);
  const [zohoBalance, setZohoBalance] = useState(null);
  const [zohoAccountName, setZohoAccountName] = useState(null);
  const [pendingReqListing, setPendingReqListing] = useState(null);
  const [enableReinitialize, setEnablReinitializei] = useState(false);
  const [autoReqId, setAutoReqId] = useState();
  const zohoBalanceRef = useRef(null);
  const fileInput = useRef([]);
  const [to_date, setTo_date] = useState(editModalPopper ? new Date(new Date(moment(viewRequistion?.enddate)).toString()) : '')


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className, }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#d11616",
    },
}));

  const sow_type_dropdown = requistionListing?.SOW_List?.map((item) => {
    const obj = { task_name: item.main_task_name, value: item.main_sow_task_name, label: item.main_sow_task_name, id:item?.main_task_id, days:item?.main_task_days,  task_sequence: item?.main_task_sequence  };
    return obj;
  });

  const work_type_options = requistionListing?.Work_Type_List?.map((item) => {
    const obj = { value: item.Value, label: item.Work_Type_Name };
    return obj;
  });

  const typist_name_options = requistionListing?.Typiest_List?.map((item) => {
    const obj = { value: item.Emp_Id, label: item.Emp_Name, id:item?.Emp_Id, type: item?.Emp_Type  };
    return obj;
  });
  
  const user_first_name = localStorage.getItem("Employee_First_Name");
  const user_last_name = localStorage.getItem("Employee_Last_Name");

 const requistionPostAPI = async(values) => {
  const user_type = localStorage.getItem('user_type');
  const emp_id = localStorage.getItem("emp_id");
  const __token = localStorage.getItem("token");
  const user_name = localStorage.getItem("Employee_First_Name");
  const url = `https://flyingcolour.online/fcapp/support_api/supportaddrequisition/`;
  const headers = {
    Authorization: `Token ${__token}`,
    "Content-Type": "application/json",
  };

  let autoReqNo = [];
  let reqAmount = [];
  let clientPreferDate = [];
  let reqWorkType = [];
  let reqRemarks = [];
  let typistName = [];
  let taskName = [];
  let taskDays = [];
  let taskSeq = [];
  let reqStartDate = [];
  let reqEndDate = [];
  let files = [];
  let typistId= [];
  let selfAssi = [];
  let reqDays = [];
  autoReqNo.push(values?.requistionId);

  values?.mainTaskArray?.map((value, i) => {
    
    reqAmount.push(value?.amount);
    reqWorkType.push(value?.typeofwork);
    typistName.push(value?.typistName);
    taskName.push(value?.taskName);
    taskDays.push(values?.requistionType === "Urgent" ? 1 : value?.taskDays);
    taskSeq.push(values?.requistionType === "Urgent" ? mainTask?.task_sequence: value?.taskSequence)
    reqStartDate.push(values?.requistionType === "Urgent" ? moment(values?.requistionDate).format("YYYY-MM-DD HH:mm:ss"): moment(value?.fromDate).format("YYYY-MM-DD HH:mm:ss"));
    reqEndDate.push(values?.requistionType === "Urgent" ? moment(values?.requistionDate).format("YYYY-MM-DD HH:mm:ss") : moment(value?.toDate).format("YYYY-MM-DD HH:mm:ss"));
    // selfAssi.push(value?.typeofwork === 2 ? "No" : value?.selfAssigned);
    reqRemarks.push(value?.remarks);
    reqDays.push(values?.requistionType === "Urgent" ? 1: value.reqDays);
    // clientPreferDate.push(value?.clientDate_time);

    if(value?.uploadDoc){
      files.push(value?.uploadDoc);
    }

    if(value?.typeofwork === 1) {
      selfAssi.push(value?.selfAssigned);
    } else {
      selfAssi.push("No");
    }

    if(value?.typeofwork === 2) {
      clientPreferDate.push(value?.clientDate_time);
    } else {
      clientPreferDate.push("No");
    }

    if(i !== 0) {
      autoReqNo.push(value?.requistionId);
    }

    if(value?.selfAssigned === "Yes") {
      typistId.push(0)
    } else {
      typistId.push(value?.typistName)
    }

  })
  let body = {
    // factory_id:factory_id,
    // order_no:operationInfo?.Order_No,
    emp_id:emp_id,
    user_type:user_type,
    user_name:user_name,
    auto_requisition_no:autoReqNo.toString(),
    main_task_id:mainTask?.id,
    main_task_days:mainTask?.days,
    main_sow_task_name:mainTask?.value,
    zoho_balance_on_requisition_amount:zohoBalanceRef.current,
    requisition_amount:reqAmount?.toString(),
    req_company_id:operationInfo?.Company_Id,
    client_prefer_datetime_location:clientPreferDate.toString(),
    requisition_work_type:reqWorkType?.toString(),
    remarks:reqRemarks?.toString(),
    typist_emp_id:typistId.toString(),
    task_name:taskName?.toString(),
    task_days:taskDays?.toString(),
    task_sequence:taskSeq.toString(),
    requisition_start_date:reqStartDate.toString(),
    requisition_end_date:reqEndDate?.toString(),
    requisition_balance_approval_file:files.toString(),
    is_self_assign_by_ro:selfAssi?.toString(),
    requisition_no_days:reqDays.toString()
  }
  const myRemarks = reqRemarks.map(s => `${s}`).join('` ');  
  const formData = new FormData();
  formData.append("factory_id", factory_id);
  formData.append("order_no", operationInfo?.Order_No);
  formData.append("emp_id", Number(emp_id));
  formData.append("user_type", user_type);
  formData.append("user_name", user_name);
  formData.append("auto_requisition_no", autoReqNo.toString());
  formData.append("main_task_id", mainTask?.id);
  formData.append("main_task_days", mainTask?.days);
  formData.append("main_sow_task_name", mainTask?.value);
  formData.append("zoho_balance_on_requisition_amount", zohoBalanceRef.current);
  formData.append("requisition_amount", reqAmount?.toString());
  formData.append("req_company_id", operationInfo?.Company_Id);
  formData.append("client_prefer_datetime_location", clientPreferDate.toString());
  formData.append("requisition_work_type", reqWorkType?.toString());
  formData.append("remarks", myRemarks);
  formData.append("typist_emp_id", typistId.toString());
  formData.append("task_name", taskName?.toString());
  formData.append("task_days", taskDays?.toString());
  formData.append("task_sequence", taskSeq.toString());
  formData.append("requisition_start_date", reqStartDate.toString());
  formData.append("requisition_end_date", reqEndDate?.toString());
  formData.append("is_self_assign_by_ro", selfAssi?.toString());
  formData.append("requisition_no_days", reqDays?.toString());

  // if(mainTask?.days === "1"){
  //   body = {
  //     ...body,
  //     requisition_type: values?.requistionType
  //   }
  // }

  // if(values?.requistionType === "Urgent"){
  //   body = {
  //     ...body,
  //     urgent_date: values?.requistionDate
  //   }
  // }
  // if(editModalPopper) {
  //   body ={
  //     ...body,
  //     update_req_id:viewRequistion?.requisition_id
  //   }
  if (!editModalPopper) {
    // let uploadDocs = values.mainTaskArray?.map((i, index) => {
    //   // if (listInput.length < 0) { formData.append(`upload_docs_${index}`, i.uploadDoc) }
    //   // else (
        formData.append(`requisition_balance_approval_file`, values?.mainTaskArray[0]?.uploadDoc);
  //});
}

if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "string") {
    formData.append("upload_doc_url", values?.mainTaskArray[0]?.uploadDoc);
}
if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "object") {
    formData.append("upload_doc_url", "");
}
if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "object") {
    formData.append("requisition_balance_approval_file", values?.mainTaskArray[0]?.uploadDoc);
}
if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "string") {
    formData.append("requisition_balance_approval_file", "");
}

  if(mainTask?.days === "1"){
    formData.append("requisition_type", values?.requistionType);
  }
  if(values?.requistionType === "Urgent"){
    formData.append("urgent_date", moment(values?.requistionDate).format("YYYY-MM-DD HH:mm:ss"));
  }
  if(editModalPopper) {
      formData.append("update_req_id", viewRequistion?.requisition_id);
      
    axios.put(url, formData, { headers: headers }).then((data) => {
      setLoading(false);
      handleQuotationPromise(data);
    })
  } else {
    axios.post(url, formData, { headers: headers }).then((data) => {
      setLoading(false);
      handleQuotationPromise(data);
    })
  }
 
 }

 const zohoBalanceAPI = async() => {
  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const emp_id = localStorage.getItem("emp_id");

  //setLoading(true);
  try {
    const url = `https://flyingcolour.online/fcapp/support_api/zohobalancedetailsview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.Company_Id}`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const res = await axios.get(url, { headers });
    zohoBalanceRef.current = res?.data?.results?.availableZoho_Balance ;
    setEnablReinitializei(true)
    setZohoAccountName(res?.data?.results?.zohoAccount_Name)
    let value = '';
    if(res?.data?.results?.availableZoho_Balance > 0){
      value = `${res?.data?.results?.availableZoho_Balance}`
      setZohoBalance(value);
    } else if(res?.data?.results?.availableZoho_Balance <= 0) {
      value = `${res?.data?.results?.availableZoho_Balance}`
      setZohoBalance(value);
    } else {
      value = `Company doesn't exist on  Zoho`
      setZohoBalance(value);
    }
    setTimeout(() => {
      setEnablReinitializei(false);
      setLoading(false)
    }, 500)
    return value;
   
    //setLoading(false);
  } catch (err) {
    console.error(err);
  }
 }

 const PendingRequistionListingAPi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
  
    //setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/support_api/orderwisependingrequisitionlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo?.Order_No}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setEnablReinitializei(true)
      setPendingReqListing(res?.data?.results);

      setTimeout(() => {
        setEnablReinitializei(false)
      }, 500)
     
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
 }

 const handleAutoRequistion = async(setFieldValue, i, values) => {
  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const emp_id = localStorage.getItem("emp_id");
  const factory_id = localStorage.getItem("factory_id");

  //setLoading(true);
  try {
    const url = `https://flyingcolour.online/fcapp/support_api/generaterequisitionno/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const res = await axios.get(url, { headers });
    setAutoReqId(res?.data?.results?.Random_Requisition_No?.[0]?.Auto_Requisition_No);
    setFieldValue(`mainTaskArray[${i}].requistionId`, res?.data?.results?.Random_Requisition_No?.[0]?.Auto_Requisition_No);
    
   
    //setLoading(false);
  } catch (err) {
    console.error(err);
  }
 }

 useEffect(() => {
  if(editModalPopper){
    zohoBalanceAPI();
    PendingRequistionListingAPi();
    setLoading(true);
    const mainTaskFind = sow_type_dropdown?.find((val) => val.id === viewRequistion?.req_task_to_pro);
    setMainTask(mainTaskFind)
  } else {
    zohoBalanceAPI();
    PendingRequistionListingAPi();
    handleAutoRequistion();
    setLoading(true)
  }
  }, [])

  const handleQuotationPromise = (data) => {
    
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
  }
  if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
  }
  if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
  } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
  }
  setIsOpen(true);
  handleModaleClose();
    getRequistionListingDataFromApi();
  };

 const filterWeekends = (date) => {
  return date.getDay() !== 0 ; 
};

 const handleSameAsTask = (e, setFieldValue, values) => {
  e.preventDefault();
  setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskName`, mainTask?.task_name);
  setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskDays`,mainTask?.days);
  setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskSequence`,mainTask?.task_sequence);
 }
 

 const handleDateChangeRaw = (e) => {
  e.preventDefault();
}
const editingReq =  pendingReqListing?.Requisition_List?.find((item) => item.Requisition_Auto_Id === (viewRequistion?.requisitionNos || viewRequistion?.requisition_auto_id));
const checkPendingReqAmount = editingReq === undefined ? 0 : pendingReqListing?.Pending_Requisition_Amount - editingReq?.Requisition_Amount;
const start_date = editModalPopper && new Date(new Date(moment(viewRequistion?.startdate)).toString());
const end_date = editModalPopper && new Date(new Date(moment(viewRequistion?.enddate)).toString());
const checkZohoBalance = zohoBalanceRef.current - (editModalPopper ? (checkPendingReqAmount !== 0 ? checkPendingReqAmount : pendingReqListing?.Pending_Requisition_Amount) : pendingReqListing?.Pending_Requisition_Amount);
const reqUrgentDate = editModalPopper && new Date(new Date(moment(viewRequistion?.urgent_date, "DD-MM-YYYYY")).toString());
  return (
    <>
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={{
        orderId: editModalPopper ? viewRequistion?.order_id : operationInfo.Order_No,
        companyName: editModalPopper ? viewRequistion?.company_name : operationInfo?.Company_Name,
        requistionId: editModalPopper ? viewRequistion?.requisition_auto_id : requistionListing?.Random_Requisition_No?.[0]?.Auto_Requisition_No,
        requistionDateTime: moment(new Date()).format("DD-MM-YYYY hh:mm:ss a"),
        zohoExpenses:zohoBalance,
        accountPendingReqAmount: editModalPopper ? (checkPendingReqAmount !== 0 ? checkPendingReqAmount : pendingReqListing?.Pending_Requisition_Amount) : pendingReqListing?.Pending_Requisition_Amount,
        sow_mainTask:editModalPopper ? mainTask?.value :'',
        requistionType: editModalPopper? viewRequistion?.Requisition_Type : '',
        requistionDate:editModalPopper ? reqUrgentDate :'',
        mainTaskArray: [{
          taskName:editModalPopper ? viewRequistion?.sub_task_name :'',
          taskDays:editModalPopper ? viewRequistion?.sub_task_days :'',
          taskSequence:editModalPopper ? viewRequistion?.sub_task_sequance :'',
          typeofwork:editModalPopper ? Number(viewRequistion?.requisition_work_type) :'',
          amount:editModalPopper ? viewRequistion?.req_amt :'',
          fromDate:editModalPopper ?start_date :'',
          toDate:editModalPopper ? end_date :'',
          uploadDoc: editModalPopper ? viewRequistion?.requisition_balance_approval_file : '',
          typistName:editModalPopper ? Number(viewRequistion?.req_typist_id) :'',
          clientDate_time:editModalPopper ? viewRequistion?.Client_Preffer_Datetime_Location :'',
          selfAssigned:editModalPopper ? viewRequistion?.is_self_assign_by_ro :'',
          remarks:editModalPopper ? viewRequistion?.remarks :'',
          reqDays:editModalPopper? viewRequistion?.Requisition_No_Days : '',
          requistionId:''
        }]
      }}
      onSubmit={async (data) => {
        setLoading(true)
        requistionPostAPI(data)
      }}
      validate={(values) => {
        
        let errors = {};

        if (!values.sow_mainTask) {
          errors.sow_mainTask = "required";
        }
        if (checkZohoBalance < values?.mainTaskArray[0].amount && values?.mainTaskArray[0]?.uploadDoc === "" ) {
            errors.uploadDoc = "Upload File is Required!!";
        }
        values?.mainTaskArray?.forEach((val) => {
          if (val.taskName === "") {
            errors.taskName = "Task Name is Required!!";
          }
          if(values?.requistionType !== "Urgent")
          {
          if (val.taskDays === "") {
            errors.taskDays = "Task Days are Required!!";
          }
          if (val.taskSequence === "") {
            errors.taskSequence = "Task Sequence is Required!!";
          }
          if (val.fromDate === "") {
            errors.fromDate = "From Date is Required!!";
          } 
          if (val.toDate === "") {
            errors.toDate = "To Date Required!!";
          }
        }
          if (val.typeofwork === "") {
            errors.typeofwork = "Work Typs is Required!!";
          }
          if (val.amount === "") {
            errors.amount = "Amount is Required!!";
          }
          
          if (val.typeofwork === 2 && val.clientDate_time === "") {
            errors.clientDate_time = "Client Prefer DateTime is Required!!";
          }
          if (val.typeofwork === 1 && val.selfAssigned === "") {
            errors.selfAssigned = "Self Assigned is Required!!";
          }
        })

        
        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={(e) => {e.preventDefault()}}>
            <Box sx={style}>
            <div>
                <div style={{ height: "36px" }}>
              <IconButton
                style={{ float: "right" }}
                className={classes.quotationModalHeaderIconCancle}
                onClick={() => {
                  handleModaleClose();
                }}
              >
                <CloseIcon
                  className={classes.quotationModalHeaderIconCancleIcon}
                />
              </IconButton>
              </div> 
              <div style={{ height: "600px", overflowX: "hidden",padding: "0" }}>
              <Grid container spacing={2} columns={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "-30px",
                    paddingBottom: "10px",
                    marginTop: "-45px",
                    zIndex:10
                  }}
                >
                  <br />
                  <div className={classes.quotationModalHeadingContainer}>
                    <span className={classes.quotationModalHeader}>
                     {
                      editModalPopper ? "Edit Requistion" : " Add Requistion"
                     } On Order {values?.orderId}
                    </span>
                  </div>
                  <br />
                  <div className={classes.quotationModalFlexDivContaner}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.quotationInnerGridRight}
                      >
                          <InputText
                           label="Company Id"
                           fieldName="Requistion_Id"
                           fieldValue={operationInfo?.Company_Id}
                           fieldHandleChange={(event) => { }
                               //handleChangeInput(item?.id, event)
                           }
                           disableField={true}
                           disabled={true}
                           classvalue={"textBold"}
                       />
                        <InputText
                            label="Zoho Account Name"
                            fieldName="Requistion_Id"
                            fieldValue={zohoAccountName}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                      <InputText
                            label="ZOHO Govt. Expense of Client (AED)"
                            fieldName="Requistion_Id"
                            fieldValue={values?.zohoExpenses}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />

                         <InputText
                            label="Requistion Id"
                            fieldName="Requistion_Id"
                            fieldValue={values?.requistionId}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                      
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.quotationInnerGridRight}
                      >
                          <InputText
                            label="Company Name"
                            fieldName="Requistion_Id"
                            fieldValue={values?.companyName}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />

                        <InputText
                            label="Accounts Pending Requistion Amount(AED)"
                            fieldName="Lnquiry_Caption"
                            fieldValue={values?.accountPendingReqAmount}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />

                        <InputText
                            label="Requistion Date & Time"
                            fieldName="Requistion_Id"
                            fieldValue={values.requistionDateTime}
                            fieldHandleChange={(event) =>{}
                              //handleChangeInput(item?.id, event)
                            }
                            disableField={true}
                            disabled={true}
                            classvalue={"textBold"}
                          />
                       <InputText
                             label="RO Name"
                             fieldName="Requistion_Id"
                             fieldValue={`${user_first_name} ${user_last_name}`}
                             fieldHandleChange={(event) => { }
                                 //handleChangeInput(item?.id, event)
                             }
                             disableField={true}
                             disabled={true}
                             classvalue={"textBold"}
                         />
                      
                           
                    </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12} className={classes.quotationInnerGridRight}>
                        <Form.Group as={Row} className="mb-1">
                         <Form.Label column sm="2" style={{padding:'0 10px'}} className="fc-textarea-label">
                               Main Task Name <span style={{color:'red'}}>*</span>
                           </Form.Label>
                           <Col sm="10" style={{padding:'0 12px 0 0'}} >
                                <div style={{display:'flex'}}>
                                  <div className="fc-dropdown-container" style={{width:'75%', marginLeft:'5%'}}>
                                  <CustomSelect
                                      name={`sow_mainTask`}
                                      value={
                                        values.sow_mainTask
                                      }
                                      onChange={(
                                        value
                                      ) => {
                                        setFieldValue(
                                          `sow_mainTask`,
                                          value.value
                                        );
                                        setMainTask(value)
                                        setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskSequence`, Number(value?.task_sequence));
                                        setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskDays`, Number(value?.days));
                                      }}
                                      options={
                                        sow_type_dropdown
                                      }
                                    />

                                  </div>
                                                        
                                     <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    style={{ backgroundColor: "#eb6e12", marginLeft:'10px' }}
                                    onClick={(e) => handleSameAsTask(e, setFieldValue, values)}
                                    >
                                  Same as Main Task
                                    </Button>
                                   </div>
                                 </Col>
                            </Form.Group>

                            { mainTask?.days === "1" && (
                               <Form.Group as={Row} className="mb-1">  
                               <Form.Label column sm="2" className="fc-textarea-label">
                              Requisition Type:
                               </Form.Label>
                               <Col sm="10" style={{padding:'0 12px 0 0'}} >
                               <div
                                 //style={getStyles(errors, item?.Generation_Date)}
                                 style={{width:'75%', marginLeft:'5%'}}
                                >
                                   <RadioGroup
                                    row
                                    aria-labelledby="requistionType"
                                    name="controlled-radio-buttons-group"
                                    value={values.requistionType}
                                    onChange={(e) =>{
                                      setFieldValue(`requistionType`, e.target.value);
                                      if(e.target.value === "Urgent") {
                                        setFieldValue(`mainTaskArray[${values.mainTaskArray.length-1}].taskDays`,1);
                                      }
                                    }}
                                  >
                                    <FormControlLabel
                                 control={<Radio />}
                                 label={"Single"}
                                 value={"Normal"}
                                 labelPlacement="end"
                              
                               />
                                <FormControlLabel
                                 control={<Radio />}
                                 label={"Multiple"}
                                 value={"Urgent"}
                                 labelPlacement="end"
                               
                               />
                               </RadioGroup>
                                         </div>
                               </Col>
                       </Form.Group>
                            )}

                            {(mainTask?.days === "1" && values?.requistionType === "Urgent") && (
                                <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"lead-generation-date"}
                              >
                                <Form.Label
                                  column
                                  sm="2"
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                  }}
                                >
                                Requistion Date <sup style={{color:'red'}}>*</sup>
                                </Form.Label>
                                <Col
                                  sm="10"
                                  style={{ padding: "0 12px 0 0" }}
                                >
                                  <div
                                  //style={getStyles(errors, item?.Generation_Date)}
                                  >
                                    <div
                                      style={{
                                        border: "0.1px solid #d3d3d3",
                                        width:'75%', marginLeft:'5%'
                                      }}
                                    >
                                      <DatePicker
                                        //disabled={fieldsEditingMain}
                                        selected={values.requistionDate}
                                        minDate={moment().toDate()}
                                        onChange={(date) => {
                                          setFieldValue(
                                            `requistionDate`,
                                            date
                                          );
                                   
                                        }}
                                        fixedHeight
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Select Date"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Form.Group>
                            )}
                        <div className={classes.modalFlexDivContaner}>
                        <Grid container xs={12} sm={12} className={classes.innerGridContainer}>
                          <FieldArray
                            name="mainTaskArray"
                            render={(arrayHelpers) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  //marginRight: "1rem",
                                  justifyContent: "space-between",
                                  marginBottom: "1rem",
                                  width: "100%",
                                }}
                              >
                                {values.mainTaskArray.length > 0 &&
                                  values.mainTaskArray.map((item, i) => {
                                    return (
                                      <div
                                        key={i}
                                        style={
                                          i !==
                                            values.mainTaskArray.length
                                            ? {
                                              border: "1px solid #eb6e12",
                                              padding: "5px",
                                              width: "100%",
                                              paddingRight:"20px",
                                              paddingLeft:"10px"
                                            }
                                            : {
                                              border: "none",
                                              padding: "5px",
                                              marginTop: "2px",
                                              width: "100%",
                                              paddingRight:"20px"
                                            }
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            position: "relative",
                                            left: "20px",
                                            cursor:'pointer',
                                            color:'#eb6e12'
                                          }}
                                        >
                                         {!editModalPopper && values?.requistionType === "Urgent" && <IconButton
                                                     disabled={item?.uploadDoc === "" ? true : false}
                                                     onClick={() => {
                                                      handleAutoRequistion()
                                                      if(values?.requistionType === "Urgent") {
                                                        arrayHelpers.push({
                                                          taskName:'',
                                                          taskDays:'',
                                                          taskSequence:'',
                                                          typeofwork:'',
                                                          amount:'',
                                                          fromDate:'',
                                                          toDate:'',
                                                          typistName:'',
                                                          clientDate_time:'',
                                                          selfAssigned:'',
                                                          remarks:'',
                                                          reqDays:'',
                                                          requistionId:autoReqId
                
                                                        });
                                                      } else {
                                                        arrayHelpers.push({
                                                          taskName:'',
                                                          taskDays:'',
                                                          taskSequence:'',
                                                          typeofwork:'',
                                                          amount:'',
                                                          fromDate:'',
                                                          toDate:'',
                                                          typistName:'',
                                                          clientDate_time:'',
                                                          selfAssigned:'',
                                                          remarks:'',
                                                          reqDays:'',
                                                          requistionId:autoReqId
                                                        });
                                                      }
                                                    
                                                    }}
                                                     style={{
                                                       paddingBottom: 0,
                                                       paddingTop: 0,
                                                     }}
                                                   >
                                                    <AddCircleIcon
                                                        style={
                                                          {
                                                            marginBottom: "0px",
                                                            marginTop: "12px",
                                                          }
                                                        }
                                                        className="ModalHeaderIcon"                                                     
                                                      />
                                                   </IconButton>
                                         }
                                         {!editModalPopper && values.mainTaskArray.length >
                                            1 ? (
                                                       <IconButton
                                                       onClick={() =>
                                                        arrayHelpers.remove(i)
                                                      }
                                                      style={{
                                                        paddingBottom: 0,
                                                        paddingTop: 0,
                                                      }}
                                                    >
                                                      <RemoveCircleOutlineIcon
                                                     className="ModalHeaderIcon"
                                                    />
                                                   </IconButton>
                                                    ) : null}
                                          
                                        </div>

                                        {(values.mainTaskArray.length > 1 && i !== 0) && (
                                           <InputText
                                           label="Requistion Id"
                                           fieldName="requistionId"
                                           fieldValue={item?.requistionId}
                                           fieldHandleChange={(event) =>{}
                                           }
                                           disableField={true}
                                           disabled={true}
                                           classvalue={"textBold"}
                                         />
                                        )}

                                        <Form.Group as={Row} className="mb-1">
                                                    <Form.Label column sm="2" className="fc-textarea-label">
                                                        Task Name
                                                    </Form.Label>
                                                    <Col sm="10" style={{padding:'0 12px 0 0'}} >
                                                    <div style={{display:'flex'}}>
                                                    <div className="fc-input-container" style={{marginRight:5, width:'49vw', marginLeft:'5%'}}>
                                                        <input
                                                            placeholder="Name"
                                                            name="taskName"
                                                            value={item?.taskName}
                                                            onChange={(e) => {
                                                              setFieldValue(`mainTaskArray[${i}].taskName`, e.target.value);
                                                            }}
                                                            className="fc-input-style"
                                                            style={getStyles(
                                                            errors,
                                                            values?.mainDropdownServices?.[i]
                                                              ?.taskName
                                                          )}
                                                        />
                                                    </div>
                                                    <div className="fc-input-container"  style={{marginRight:5}}>
                                                        <input
                                                            placeholder="Days"
                                                            name="taskDays"
                                                            value={values?.requistionType === "Urgent" ? 1 : item.taskDays}
                                                            onChange={(e) => {
                                                              if(values?.requistionType === "Urgent")
                                                              {
                                                                setFieldValue(`mainTaskArray[${i}].taskDays`, Number(mainTask?.days));
                                                              }
                                                              else{
                                                                setFieldValue(`mainTaskArray[${i}].taskDays`, e.target.value);
                                                              }
                                                            }}
                                                            className="fc-input-style"
                                                            disabled={true}
                                                            style={getStyles(
                                                              errors,
                                                              values?.mainDropdownServices?.[i]
                                                                ?.taskDays
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="fc-input-container">
                                                        <input
                                                            placeholder="Sequence"
                                                            name="taskSequence"
                                                            value={values?.requistionType === "Urgent" ? mainTask?.task_sequence : item.taskSequence}
                                                            onChange={(e) => {
                                                              
                                                              if(values?.requistionType === "Urgent")
                                                              {
                                                                setFieldValue(`mainTaskArray[${i}].taskSequence`, Number(mainTask?.task_sequence));
                                                              }
                                                              else{
                                                                setFieldValue(`mainTaskArray[${i}].taskSequence`, e.target.value);
                                                              }
                                                            }}
                                                            disabled={true}
                                                            className="fc-input-style"
                                                            style={getStyles(
                                                              errors,
                                                              values?.mainDropdownServices?.[i]
                                                                ?.taskSequence
                                                            )}
                                                        />
                                                    </div>
                                                    </div>
                                                    </Col>
                                        </Form.Group>  

                          <Grid container>                                             
                          <Grid item xs={12} md={6} style={{paddingRight:5}}>
                          <CustomDropdown
                            label="Work Type"
                            fieldOptions={work_type_options}
                            fieldValue={Number(item?.typeofwork)}
                            fieldHandleChange={(value) => {
                              setFieldValue(`mainTaskArray.[${i}].typeofwork`, value.value);
                            }}
                            errorField={getStyles(errors, values?.mainTaskArray[i]?.typeofwork)}
                            requiredField={true}
                          />

                        <InputText
                            label="Amount (AED)"
                            fieldName={item.amount}
                            fieldValue={item.amount}
                            fieldHandleChange={(e) =>{
                              const { value } = e.target;
                              const regex =  /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;
                              if (!value || regex.test(value.toString()))
                              setFieldValue(`mainTaskArray.[${i}].amount`, value);
                            }
                            }
                            fieldType={"number"}
                            errorField={getStyles(errors, values.amount)}
                            requiredField={true}
                          />

                    {checkZohoBalance < item.amount && (
                      <Form.Group as={Row} className="mb-1">  
                      <Form.Label column sm="5" className="fc-textarea-label">
                       Do you have management approval/transfer receipt? <span style={{color:'red'}}>*</span>
                      </Form.Label>
                      <Col sm="7" style={{padding:'0 12px 0 0'}} >

                         <div className={classes.businessCardFormGroupModal}>
                        <div
                          className={classes.quotationFormGroupFlexDivModalVT}
                        >


                          <div
                            className={classes.quotationSelectContainerModal}
                          >
                            <div
                              className={
                                classes.businessCardDemoDataCheckModalUploadFile
                              }
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(event) => {
                                  
                                  setFieldValue(
                                    `mainTaskArray.[${i}].uploadDoc`,
                                    event.currentTarget.files[0]
                                  );
                                }}
                                // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                ref={(el) => (fileInput.current[i] = el)}                              
                                />
                              <button
                                className={
                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                }
                                type="button"
                                onClick={() => fileInput.current[i].click()}
                              >
                                <PermMediaIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Add Media
                              </button>

                              <small
                                className={
                                  classes.businessCardDemoDataCheckModalUploadFileText
                                }
                              >
                                {item.uploadDoc
                                  ?item?.uploadDoc?.name ||
                                  item?.uploadDoc?.split("/").pop()
                                  : "No file chosen"}
                              </small>
                            </div>
                       
                          </div>
                        </div>
                      </div>
                      {values.uploadDocument
                                                  ?.length !== 0 &&
                                                  item.uploadDoc !== "" &&
                                                  typeof item.uploadDoc ===
                                                  "object" ? (
                                                  [
                                                    "png",
                                                    "jpg",
                                                    "JPEG",
                                                    "jpeg",
                                                  ].includes(
                                                    item.uploadDoc?.name
                                                      ?.split(".")
                                                      .pop()
                                                  ) ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".2rem",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          fileInput.current[
                                                            i
                                                          ]?.current.click();
                                                        }}
                                                        style={{
                                                          width: "98%",
                                                          height: "100px",
                                                        }}
                                                        src={URL.createObjectURL(
                                                          item.uploadDoc
                                                        )}
                                                        alt={"image"}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".5rem",
                                                        marginTop: ".5rem",
                                                        alignItems: "center",
                                                        width: "50px",
                                                        marginRight: "2rem",
                                                      }}
                                                    >
                                                      {item.uploadDoc?.name
                                                        ?.split(".")
                                                        .pop() === "docx" ? (
                                                        <>
                                                          <RiFileWord2Fill
                                                            style={{
                                                              color: "#2b579a",
                                                              fontSize: "36px",
                                                              marginRight:
                                                                ".4rem",
                                                            }}
                                                          />
                                                        </>
                                                      ) : item.uploadDoc?.name
                                                        ?.split(".")
                                                        .pop() === "pdf" ? (
                                                        <BsFilePdfFill
                                                          style={{
                                                            color: "#ff0000",
                                                            fontSize: "36px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      ) : (
                                                        <CloudUploadIcon
                                                          style={{
                                                            color: "green",
                                                            fontSize: "32px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  )
                                                ) : values.uploadDocument
                                                  ?.length !== 0 &&
                                                  item.uploadDoc !== "" &&
                                                  typeof item.uploadDoc ===
                                                  "string" ? (
                                                  [
                                                    "png",
                                                    "jpg",
                                                    "JPEG",
                                                    "jpeg",
                                                  ].includes(
                                                    item.uploadDoc
                                                      ?.split(".")
                                                      .pop()
                                                  ) ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".2rem",
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      <img
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          fileInput.current[
                                                            i
                                                          ]?.current.click();
                                                        }}
                                                        style={{
                                                          width: "98%",
                                                          height: "100px",
                                                        }}
                                                        src={item.uploadDoc}
                                                        alt={"image"}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        marginBottom: ".5rem",
                                                        marginTop: ".5rem",
                                                        alignItems: "center",
                                                        width: "50px",
                                                        marginRight: "2rem",
                                                      }}
                                                    >
                                                      {item.uploadDoc
                                                        ?.split(".")
                                                        .pop() === "docx" ? (
                                                        <>
                                                          <RiFileWord2Fill
                                                            style={{
                                                              color: "#2b579a",
                                                              fontSize: "36px",
                                                              marginRight:
                                                                ".4rem",
                                                            }}
                                                          />
                                                        </>
                                                      ) : item.uploadDoc
                                                        ?.split(".")
                                                        .pop() === "pdf" ? (
                                                        <BsFilePdfFill
                                                          style={{
                                                            color: "#ff0000",
                                                            fontSize: "36px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      ) : (
                                                        <CloudUploadIcon
                                                          style={{
                                                            color: "green",
                                                            fontSize: "32px",
                                                            marginRight:
                                                              ".4rem",
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  )
                                                ) : null}
                      </Col>
              </Form.Group>
                    )}

                    {item?.typeofwork === 1 && (
                            <Form.Group as={Row} className="mb-1">  
                        <Form.Label column sm="5" className="fc-textarea-label">
                        Self Assigned?<span style={{color: "red"}}>*</span>
                        </Form.Label>
                        <Col sm="7" style={{padding:'0 12px 0 0'}} >
                        <div
                          //style={getStyles(errors, item?.Generation_Date)}
                         >
                            <RadioGroup
                                    row
                                    aria-labelledby="requistionType"
                                    name="controlled-radio-buttons-group"
                                    value={item.selfAssigned}
                                    onChange={(e) =>{
                                      setFieldValue(`mainTaskArray.[${i}].selfAssigned`, e.target.value);
                                    }}
                                  >
                             <FormControlLabel
                          control={<Radio />}
                          label={"Yes"}
                          value={"Yes"}
                          labelPlacement="end"
                        
                        />
                         <FormControlLabel
                          control={<Radio />}
                          label={"No"}
                          value={"No"}
                          labelPlacement="end"
                       
                        />
                        </RadioGroup>        </div>
                        </Col>
                </Form.Group>
                          )}

                          {item?.typeofwork === 2 && (
                            <Form.Group as={Row} className="mb-1">  
                        <Form.Label column sm="5" className="fc-textarea-label">
                          Does client has preferred date time & locations?<span style={{color: "red"}}>*</span>
                        </Form.Label>
                        <Col sm="7" style={{padding:'0 12px 0 0'}} >
                        <div
                          //style={getStyles(errors, item?.Generation_Date)}
                         >
                            <RadioGroup
                                    row
                                    aria-labelledby="requistionType"
                                    name="controlled-radio-buttons-group"
                                    value={item.clientDate_time}
                                    onChange={(e) =>{
                                      setFieldValue(`mainTaskArray.[${i}].clientDate_time`, e.target.value);
                                    }}
                                  >
                             <FormControlLabel
                          control={<Radio />}
                          label={"Yes"}
                          value={"Yes"}
                          labelPlacement="end"
                        />

                    <FormControlLabel
                          control={<Radio />}
                          label={"No"}
                          value={"No"}
                          labelPlacement="end"
                        />
                        </RadioGroup>
                                  </div>
                        </Col>
                </Form.Group>
                          )}

                          </Grid>
                          <Grid item xs={12} md={6}>
                            {/* {item?.typeofwork === 2 ? (
                              <Form.Group as={Row} className="mb-1">  
                              <Form.Label column sm="5" className="fc-textarea-label">
                                 Pro Date & Time
                              </Form.Label>
                              <Col sm="7" style={{padding:'0 12px 0 0'}} >
                              <div
                                                  style={getStyles(errors, item?.fromDate)}
                                                  >
                                                    <div
                                                      style={{
                                                        border: "0.1px solid #d3d3d3",
                                                      }}
                                                    >
                                                      <CustomDatePicker custom={true} fieldHandleChange={(value, picker) => {
                                                       const differenceDays = moment(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")).diff(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), "days");
                                                         setFieldValue(
                                                          `mainTaskArray[${i}].fromDate`,
                                                          moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                                                        );
                                                        setFieldValue(
                                                          `mainTaskArray[${i}].toDate`,
                                                          moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")
                                                        );
                                                        setFieldValue(
                                                          `mainTaskArray[${i}].reqDays`,
                                                          differenceDays
                                                        );
                                                      }} />
                                                    </div>
                                                  </div>
                              </Col>
                          </Form.Group>
                            ) : item?.typeofwork === 1 ? (
                              <Form.Group
                              as={Row}
                              className="mb-1"
                              controlId={"lead-generation-date"}
                            >
                              <Form.Label
                                column
                                sm="5"
                                style={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                }}
                              >
                               Office Work Date & Time
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{ padding: "0 12px 0 0" }}
                              >
                                <div
                                style={getStyles(errors, item?.fromDate)}
                                >
                                  <div
                                    style={{
                                      border: "0.1px solid #d3d3d3",
                                    }}
                                  >
                                    <CustomDatePicker custom={true} fieldHandleChange={(value, picker) => {
                                      const differenceDays = moment(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")).diff(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), "days");
                                       setFieldValue(
                                        `mainTaskArray[${i}].fromDate`,
                                        moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
                                      );
                                      setFieldValue(
                                        `mainTaskArray[${i}].toDate`,
                                        moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss")
                                      );
                                      
                                      setFieldValue(
                                        `mainTaskArray[${i}].reqDays`,
                                        differenceDays
                                      );
                                    }} />
                                  </div>
                                </div>
                              </Col>
        </Form.Group>
                            ) : null} */}
                          
                          <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                                 From Date & Time<span style={{color: "red"}}>*</span>
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                                 <div style={getStyles(errors, item?.fromDate)} >
                                                     <div style={{ border: "0.1px solid #d3d3d3"}}  >
                                                   
                                                           <DatePicker
                                                           //  label="Select Start Date"
                                                            // value={item?.fromDate}
                                                             selected={values?.requistionType === "Urgent" ? values?.requistionDate: item?.fromDate}
                                                             minDate={addDays(new Date(), 0)}
                                                             maxDate={addDays(new Date(), Number(item?.taskDays)-1)}
                                                             onChange={(value) => {
                                                                // setFrom_date(value);
                                                                if (new Date(to_date) >= new Date(value)) {
                                                                  setFieldValue(`mainTaskArray[${i}].fromDate`, value)
                                                                } else {
                                                                  setFieldValue(`mainTaskArray[${i}].fromDate`, value)
                                                                  setFieldValue(`mainTaskArray[${i}].toDate`, "");
                                                                }                                                             
                                                              }
                                                             }
                                                             dateFormat="dd-MM-yyyy"
                                                             filterDate={filterWeekends}
                                                             onChangeRaw={handleDateChangeRaw}
                                                            //  renderInput={(params) => (
                                                            //     <TextField {...params} error={false} />
                                                            //   )}
                                                         />
                                                    
                                                     </div>
                                                 </div>
                                             </Col>
                                         </Form.Group>

                                         <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                                 To Date & Time<span style={{color: "red"}}>*</span>
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                                 <div style={getStyles(errors, item?.toDate)} >
                                                     <div style={{ border: "0.1px solid #d3d3d3"}}  >
                                                   
                                                         <DatePicker
                                                           //  label="Select End Date"
                                                            // value={item?.toDate}
                                                             selected={values?.requistionType === "Urgent" ? values?.requistionDate: item?.toDate}
                                                             minDate={addDays(new Date(), 0)}
                                                             maxDate={addDays(new Date(), Number(item?.taskDays)-1)}
                                                             onChange={(value) => {
                                                                 const Difference_In_Time = new Date(value).getTime() - new Date(item?.fromDate).getTime();
                                                                 const diffDays = Difference_In_Time / (1000 * 60 * 60 * 24);
                                                                setTo_date(value);
                                                                if (new Date(item?.fromDate) <= new Date(value)) {
                                                                  setFieldValue(`mainTaskArray[${i}].toDate`, value);
                                                                } else {
                                                                  setFieldValue(`mainTaskArray[${i}].toDate`, value);
                                                                  setFieldValue(`mainTaskArray[${i}].fromDate`, "")
                                                                }
                                                                 setFieldValue(
                                                                     `mainTaskArray[${i}].reqDays`,
                                                                     Math.trunc(diffDays + 1)
                                                                 );
                                                             }
                                                             }
                                                             dateFormat="dd-MM-yyyy"
                                                             filterDate={filterWeekends}
                                                             onChangeRaw={handleDateChangeRaw}
                                                            //  renderInput={(params) => (
                                                            //     <TextField {...params} error={false} />
                                                            //   )}
                                                         />
                                                          
                                                     </div>
                                                 </div>
                                             </Col>
                                         </Form.Group>
                   


                    {item?.selfAssigned !== "Yes" && (
                      <CustomDropdown
                            label="Typist Name"
                            fieldOptions={typist_name_options}
                            fieldValue={item?.typistName}
                            fieldHandleChange={(value) => {
                              setFieldValue(`mainTaskArray[${i}].typistName`,value.value);
                            }}
                            errorField={getStyles(errors, item?.typistName)}
                            requiredField={true}
                          />
                    )}
                    
                    <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                             Remarks
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                             <textarea
                                            // label="Remarks"
                                             name="remarks"
                                             value={item?.remarks}
                                             onChange={(e) => {
                                                 e.preventDefault();
                                                 const { value } = e.target;
                                                 setFieldValue(
                                                     `mainTaskArray[${i}].remarks`,
                                                     value
                                                 );
                                             }}
                                             style={{height: "100px", width: "100%"}}
                                         />
                                             </Col>
                                         </Form.Group>
                    {/* <CustomTextArea
                                label="Remarks"
                                fieldName="remarks"
                                fieldValue={item.remarks}
                                fieldHandleChange={(e) => {
                                  e.preventDefault();
                                  const { value } = e.target;
                                 setFieldValue(
                                    `mainTaskArray[${i}].remarks`,
                                    value
                                  );
                                }}
                               /> */}
                          </Grid>

                         </Grid>
                                  </div>
                             );
                         })}
                              </div>
                            )}
                          />
                        </Grid>
                        
                      </div>
                      
                      

                        </Grid>
                    </Grid>
                  </div>
                  <br />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.quotationButtonDivModal}>
              
              <BootstrapTooltip arrow={true}
              disableFocusListener
              title={errors.taskName === undefined && errors.taskDays === undefined &&
                  errors.taskSequence === undefined && errors.uploadDoc === undefined
                  && errors.fromDate === undefined && errors.toDate === undefined
                  && errors.typeofwork === undefined && errors.amount === undefined
                  && errors.selfAssigned === undefined && errors.clientDate_time === undefined
                  ? ""
                  :
                  `${errors.taskName !== undefined ? `${errors.taskName},` : ""}${errors.taskDays !== undefined ? `${errors.taskDays},` : ""}
                  ${errors.taskSequence !== undefined ? `${errors.taskSequence},` : ""}${errors.uploadDoc !== undefined ? `${errors.uploadDoc},` 
                  : ""}${errors.fromDate !== undefined ? `${errors.fromDate},` : ""}${errors.toDate !== undefined ? `${errors.toDate},`
                  : ""}${errors.typeofwork !== undefined ? `${errors.typeofwork},`: ""}${errors.amount !== undefined ? `${errors.amount},`: ""}
                  ${errors.selfAssigned !== undefined ? `${errors.selfAssigned},`: ""}${errors.clientDate_time !== undefined ? `${errors.clientDate_time},`: ""}
                  `
                  }
                  >
                 <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#eb6e12" }}
                  onClick={handleSubmit}
                  disabled={zohoBalance === "Company doesn't exist on  Zoho" ? true : false}
                >
                  {loading ? "Loading...." : "Submit"}
                </Button>
                </BootstrapTooltip>

                <Button
                  onClick={() => {
                    handleModaleClose();
                    // handleHide()
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              </div>
              <PendingRequistionListing pendingReqListing={pendingReqListing} />
              </div>
            </Box>
           
          </form>
        );
      }}
    </Formik>

     
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(RequistionModalForm);
