import React, { useContext, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, IconButton, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TiWarning } from "react-icons/ti";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import modalStyles from "../../../listing/listingTable/ListingExpandableTable/modal/modalStyles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "42%",
  maxWidth: "98vw",
  bgcolor: "#fff",
  borderRadius: "4%",
  // border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  overflowY: "scroll",
  minHeight: "350px",
  maxHeight: "550px",
  overflowY: "hidden",
};

const SubmitModal = ({ classes, msg, setOpenModal, handleSubmit, rowSelected, supportDetails }) => {
  const [loading, setLoading] = useState(false);
  const handleSubmitWithLoading = () => {
    setLoading(true);
    handleSubmit(setLoading)
  }
  return (
    <Box sx={style}>
      <IconButton
        className={classes.modalHeaderIconCancle}
        onClick={() => {
            setOpenModal(false);
        }}
      >
        <CloseIcon className={classes.modalHeaderIconCacleIcon} />
      </IconButton>
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TiWarning style={{ color: "#eb6e12", fontSize: "151px" }} />
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0px",
            paddingBottom: "1px",
            marginTop: "5px",
            fontSize: "14px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "600",
            color:'red'
          }}
        >
         <div style={{textAlign:'center'}}>Total number of order selected: {rowSelected?.length} <br /></div>
         <div style={{display:'flex'}}>
          {supportDetails?.supportName} ---------------------- {supportDetails?.assignSupportName}
         </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0px",
            paddingBottom: "1px",
            marginTop: "5px",
            fontSize: "25px",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "600",
          }}
        >
          {msg}
        </Grid>
        
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0px",
            paddingBottom: "10px",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            size="medium"
            style={{
              fontFamily: "'Poppins', sans-serif",
              marginRight: loading ? "0px" : "30px",
              color: "#fff",
              background: "#eb6e12",
              padding: "5px 30px",
            }}
            onClick={() => {
                handleSubmitWithLoading();
            }}
          >
            {loading ? (
              <CircularProgress
                size={28}
                sx={{
                  color: "#fff",
                }}
              />
            ) : (
              "Yes"
            )}
          </Button>
          {loading ? null : (
            <Button
              variant="contained"
              size="medium"
              style={{
                fontFamily: "'Poppins', sans-serif",
                color: "#eb6e12",
                background: "#fff",
                padding: "5px 30px",
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
          )}
        </Grid>
      </div>
    </Box>
  );
};
export default withStyles(modalStyles)(SubmitModal);
