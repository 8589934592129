import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { DataContext } from "../../../../provider/context";
import "../../../ReceptionListing/ListingDataGrid.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { Button } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import "../styles/table.css";
import moment from "moment";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InformationPool = ({
  iPapiData,
  tableUpperButtonArr,
  tabValue,
  upperIcons,
  fetchListingDataFromApi,
  ScrollTop,
  heightTable,
  height,
  InformationPoolListingApi
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setSelected,
    setEditSaveButton,
    setFieldsEditing,
    setFieldsEditingMain,
    setEditApiData,
    setSalesInfo,
    handleModalOpen,
    setBusinessInfo,
    getFollowUpView,
    setTodayEnqAlerts,
    setClickObjData,
    setEditForm,
  } = useContext(DataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const handleAccept = async (value) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const inquiryId = value.Inquiry_Id.toString().substring(2);
    let data = {
      inquiryId: Number(inquiryId),
      shared_emp_id: value.Shared_Emp_id,
      shared_inquiry_flag: value.Shared_Inquiry_Flag,
      emp_id: emp_id,
      user_type: user_type,
    };

    const __token = localStorage.getItem("token");
    await fetch(
      "http://flyingcolour.online/fcapp/spm_api/acceptassigninquiryview/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsOpen(true);
        setMessage(`ENQ# ${value.Inquiry_Id} Accepted Successfully.`);
        fetchListingDataFromApi();
      })
      .catch((error) => {
        setIsOpen(true);
        setMessage(`Please try again later!`);
        console.error("Error:", error);
      });
  };
  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        {data?.Conversation_Status === "Not Proceed" &&
          data?.Enquiry_Status === "Approved" ? (
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#eb6e12",
                fontFamily: "Times New Roman !important",
                color: "#fff",
                width: "80px",
              }}
              onClick={() => handleAccept(data)}
            >
              Accept
            </Button>
          </div>
        ) : (
          <div>
            {upperIcons &&
              upperIcons?.map((item, i) => {
                let rowIndex = params?.node?.rowIndex;
                return (
                  <>
                    <span
                      key={item.id}
                      onClick={() => {
                        setEditForm(false);
                        handleModalTableOpen(item.btnText, params.data);
                        setButtonText(item.btnText);
                        // scrollToTop()
                        ScrollTop();
                        setBusinessInfo(params.data);
                        setSalesInfo(params.data);
                      }}
                      title={item.btnText}
                      style={{ cursor: "pointer", marginRight: "10px" }}
                    >
                      {item.icon}
                    </span>
                  </>
                );
              })}{" "}
            <br />
          </div>
        )}
      </>
    );
  };

  const renderExpandableRow = (value) => {
    const row = value?.data;
    return (
      <table>
        <tr>
          <td colSpan={15}>
            <div style={{ display: "flex", flexWrap: "wrap", padding: "1rem" }}>
              <div>
                <p style={{ fontWeight: "600", margin: "0 2rem 0.5rem 0" }}>
                  Description
                </p>
                <p
                  className="wraptextdescription"
                  style={{ padding: "0 5px", margin: "0 2rem 1rem 0" }}
                  dangerouslySetInnerHTML={{ __html: row?.Description }}
                />
              </div>
            </div>
          </td>
        </tr>
      </table>
    );
  };

  const documentItems = (params) => {
    let data = params?.data.Document;

    return (
      <>
        {
          data?.length === 0 ?
            (<>
              {"No Document"}
            </>)
            :
            data.map((item, i) => {
              return (
                <>
                  <Button
                    style={{
                      marginLeft: ".4rem",
                      textDecorationColor: "#eb6e12",
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      padding: "6px",
                    }}
                    onClick={() => window.open(item[1])}
                  >
                    {`View Document`}
                  </Button><br/> <br/>
                </>
              )
            })
        }
      </>
    );
  };

  const detailItems = (params) => {
    let data = params?.data;
    if (data?.Freezone_name !== "") {
      return (
        <>
          <span>
            <b>Freezone Name:</b> {data.Freezone_name}
          </span>
          <br />
        </>
      );
    }
    if (data?.Offshore_Name !== "") {
      return (
        <>
          <span>
            <b>Offshore Name:</b> {data.Offshore_Name}
          </span>
          <br />
        </>
      );
    }
    if (data?.PRO_Services_Name !== "") {
      return (
        <>
          <span>
            <b>PRO Services Name:</b> {data.PRO_Services_Name}
          </span>
          <br />
        </>
      );
    }
    if (data?.[`DED/FZ/Offshore_Name`] !== "") {
      return (
        <>
          <span>
            <b>Select DED/FZ/Offshore Name:</b>
            {data?.[`DED/FZ/Offshore_Name`]}
          </span>
          <br />
        </>
      );
    }
    return (
      <>
        <br />
      </>
    );
  };

  const columnDefs1 = [
    {
      headerName: "Actions",
      field: "Activity_Type",
      cellRenderer: "agGroupCellRenderer",
      filter: false,
      // checkboxSelection: true,
      cellRendererParams: {
        innerRenderer: actionBtnRenderer,
      },
      cellStyle: { border: "1px solid #fff" },
      minWidth: 100,
      pinned: "left",
    },
    {
      headerName: "Serial No.",
      field: "Serial_Number",
      cellStyle: { border: "1px solid #fff" },
      width: 120,
      pinned: "left",
    },
    {
      headerName: "Info Pool Id",
      field: "information_pool_id",
      filter: "agSetColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      minWidth: 130,
      flex: 1,
      pinned: "left",
    },
    {
      headerName: "State",
      field: "State",
      cellStyle: { border: "1px solid #fff" },
      minWidth: 100,
      flex: 1,
      pinned: "left",
    },
    {
      headerName: "Information",
      field: "Information",
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      minWidth: 200,
      flex: 1,
      pinned: "left",
    },
    {
      headerName: "Formation Type",
      field: "Formation_Type_Name",
      cellStyle: { border: "1px solid #fff" },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Details",
      field: "Freezone_name",
      cellRenderer: "agGroupCellRenderer",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      flex: 1,
      cellRendererParams: {
        innerRenderer: detailItems,
      },
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      minWidth: 200,
    },
    {
      headerName: "Document",
      field: "Document",
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      minWidth: 200,
      flex: 1,
      cellRendererParams: {
        innerRenderer: documentItems,
      },
    },
    {
      headerName: "License Type",
      field: "License_Type",
      flex: 1,
      cellStyle: { border: "1px solid #fff" },
      minWidth: 150,
    },
    {
      headerName: "Activity Type",
      field: "Activity_Type",
      flex: 1,
      cellStyle: { border: "1px solid #fff" },
      minWidth: 150,
    },
    {
      headerName: "Approval Date",
      field: "Approval_Date",
      flex: 1,
      cellStyle: { border: "1px solid #fff" },
      filter: "agDateColumnFilter",
      minWidth: 200,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          const dateParts = dateAsString.split("-");
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2].split(" ")[0]);
          const cellDate = new Date(year, month, day);
          const cellDateFilter = new Date(
            filterLocalDateAtMidnight.getFullYear(),
            filterLocalDateAtMidnight.getMonth(),
            filterLocalDateAtMidnight.getDate()
          );
          if (cellDate < cellDateFilter) {
            return -1;
          } else if (cellDate > cellDateFilter) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      headerName: "Created By",
      field: "Created_By",
      flex: 1,
      cellStyle: { border: "1px solid #fff" },
      minWidth: 150,
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      flex: 1,
      cellStyle: { border: "1px solid #fff" },
      type: ["dateColumn", "nonEditableColumn"],
      filter: "agDateColumnFilter",
      minWidth: 200,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          const dateParts = dateAsString.split("-");
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2].split(" ")[0]);
          const cellDate = new Date(year, month, day);
          const cellDateFilter = new Date(
            filterLocalDateAtMidnight.getFullYear(),
            filterLocalDateAtMidnight.getMonth(),
            filterLocalDateAtMidnight.getDate()
          );
          if (cellDate < cellDateFilter) {
            return -1;
          } else if (cellDate > cellDateFilter) {
            return 1;
          }
          return 0;
        },
      },
    },
  ];

  const [columnDefs, setcolumnDefs] = useState(columnDefs1);
  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            var dateAsString = moment(cellValue).format("DD-MM-YYYY");
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;

    if (rowData) {
      setSelected((selected) => [...selected, rowData]);
      setEditSaveButton(false);
      setFieldsEditing(false);
      setFieldsEditingMain(true);
      setEditApiData([]);
    } else {
      setSelected((selected) => selected.filter((e) => e !== rowData));
    }
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };
  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  useEffect(() => {
    InformationPoolListingApi()
  }, [])

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  return (
    <div className="listing-data-grid" style={{ height: height }}>
      <div style={{ display: "flex", marginLeft: "10px", marginTop: "10px" }}>
        <LeadsFilterAdvancedMain
          tableUpperButtonArr={tableUpperButtonArr}
          iPapiData={iPapiData}
          gridRef={gridRef}
          tabValue={tabValue}
          setSavedValue={setSavedValue}
          savedValue={savedValue}
        />
      </div>
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={iPapiData?.length}
      />
      <div
        style={{ height: heightTable, width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
          ref={gridRef}
          rowData={iPapiData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          detailCellRenderer={renderExpandableRow}
          columnTypes={columnTypes}
          animateRows={true}
          onSelectionChanged={onRowSelected}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          enableFilter={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>

      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default InformationPool;
