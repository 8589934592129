import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles/adminTopbar.css";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "../../../services/services";
import { DataContext } from "../../../provider/context";
import CustomCalender from "../../../reusableComponents/CustomCalender/CustomCalender";
import CustomPaginationTable from "../../../reusableComponents/CustomPaginationTable/CustomPaginationTable";
import DatePicker from "../../Dashboard/DatePicker";
import InfoIcon from '@mui/icons-material/Info';
import { SupportDataContext } from "../../../provider/context";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Grid } from "@material-ui/core";
import DateRangePickerComp from "../../../reusableComponents/CustomDatePicker/DateRangePickerComp";




export default function CommentNotifications({ fetchNotification, data }) {
  const {setSelected } = useContext(SupportDataContext);
  const {
    setStart_Date
   } = useContext(DataContext);
  const __token = localStorage.getItem("token");
  const user_type = getLocalData("user_type");
  const emp_id = getLocalData("emp_id");
  const factory_id = getLocalData("factory_id");
  const [notificationAllDataList, setNotificationAllDataList] = useState([]);
  const [isShowAllNotification, setIssShowAllNotification] =
    React.useState(false);
  const { fromDate, toDate, setError, setErrorAlert } =
    React.useContext(DataContext);
  const [isInitialRef, setInitialRef] = React.useState(false);
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0);
  const gridRef = React.useRef();
  const todayDate = moment().format("YYYY-MM-DD");
  const [reqFromDate, setReqFromDate] = useState("");
  const [reqToDate, setReqToDate] = useState("");



  const handleNotificationDate = () => {
    gridRef?.current?.api?.getFilterInstance("Inquiry_Status_Date").setModel({
      type: "inRange",
      dateFrom: fromDate,
      dateTo: toDate,
    });
    gridRef?.current?.api.onFilterChanged();
  };

  // React.useEffect(() => {
  //   if (isInitialRef) {
  //     handleNotificationDate();
  //   }
  //   setInitialRef(true);
  // }, [fromDate, toDate]);

  const getCommentNotificationApi = async (reqFromDate, reqToDate) => {
    try {
      const url = `https://flyingcolour.online/fcapp/typist_app/get_notification_comment_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&from_date=${reqFromDate === undefined ? "" : reqFromDate}&to_date=${reqToDate === undefined ? "" : reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
        return setNotificationAllDataList(res?.data?.results?.Comment_List);
      
    } catch (err) {
      }
   }

  React.useEffect(() => {
    if (isShowAllNotification) {
      getCommentNotificationApi(reqFromDate, reqToDate);
    }
  }, [isShowAllNotification, reqFromDate, reqToDate]);

  const handleChange = async (item) => {
 
    let data = {
      factory_id: factory_id,
      emp_id: emp_id,
      user_type: user_type,
      comment_id : item?.Comment_Id,
      notification_type: item?.Notification_Type
    };
    await fetch(
      
      "https://flyingcolour.online/fcapp/typist_app/change_comment_notification_status/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        getCommentNotificationApi();
        fetchNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
    // setChecked((prev) => [...prev.filter((i) => i !== item)]);
  };

  const components = React.useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);
  const NotificationBox = ({ title, Msg, onClose, date }) => {
    return (
      <div className="tBody">
        <div className="toast-header">
          {/* <h5 class="headerTxt" style={{ color: "#eb6e12" }}>
            {title}
          </h5> */}
          <IconButton
            style={{ fontSize: 12 }}
            className="closeBtn"
            aria-label="Close"
            onClick={onClose}
          >
            Read
          </IconButton>
        </div>
        <div
          className="toast-body"
          style={{ marginBottom: "0.5rem", fontWeight: 700 }}
        >
          Date & Time : {moment(date).format("DD-MM-YYYY H:mm:ss")}
        </div>
        <div className="toast-body">
          {Msg}
          {/* <Button className="toast-button">View</Button> */}
        </div>
      </div>
    );
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const onlyDate = cellValue.split(" ");
      const dateParts = onlyDate[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      // Now that both parameters are Date objects, we can compare
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  const handleReadAllNotification = async () => {
    let data = {
      factory_id: factory_id,
      emp_id: emp_id,
      user_type: user_type,
    };
    await fetch(
      "https://flyingcolour.online/fcapp/typist_app/changed_all_comment_notification_status/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        getCommentNotificationApi();
        fetchNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        console.error("Error:", error);
      });
  };

  const columnDefs = [
    {
      headerName: "Sr#",
      field: "Serial_Number",
      filter: false,
      width: 70,
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Order Id",
      field: "Order_Id",
      cellStyle: { border: "1px solid #fff" },
      minWidth: 160,
      // flex: 1,
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", overflow: "auto" },
      minWidth: 230,
      flex: 1,
    },
    {
      headerName: "Comment / Reply",
      field: "Comment_Details",
      filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff",overflow: "auto"},
      minWidth: 400,
      flex: 1,
    },
    {
      headerName: "Notification Type",
      field: "Notification_Type",
      cellStyle: { border: "1px solid #fff",overflow: "auto"},
      minWidth: 160,
      flex: 1,
    },
    {
      headerName: "Comment Date & Time",
      field: "Comment_Datetime",
      cellStyle: { border: "1px solid #fff" },
      // filter: "agDateColumnFilter",
      // filterParams: dateFilterParams,
      minWidth: 170,
      // flex: 1,
      cellRenderer: (params) => {
        return (
          <>
            {moment(params.data?.Comment_Datetime).format("DD-MM-YYYY hh:mm:ss a")}
          </>
        );
      },
    },
    {
      headerName: "Commented By",
      field: "Commented_By",
      cellStyle: { border: "1px solid #fff" },
      minWidth: 160,
      // flex: 1,
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      cellStyle: { border: "1px solid #fff" },
      minWidth: 160,
      // flex: 1,
    },
    {
      headerName: "Action",
      field: "action",
      floatingFilter: false,
      cellRenderer: (params) => {
        return (
          <>
            {new Date(params?.data?.CommentDatetime) > new Date("2024-01-01 12:00:00")
            && params?.data?.Admin_Dismiss_Status === "No" ? (
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="toast-button"
                  onClick={() => {
                    handleChange(params?.data)
                  }
                }
                >
                  Dismiss
                </Button>
              </div>
            ) : null}
          </>
        );
      },
      cellStyle: { border: "1px solid #fff" },
      width: 120,
      // flex: 1,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      // flex:1,
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
};



const [fromRows, setFromRows] = useState(0);
const [toRows, setToRows] = useState(0);
const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
}, []);

const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
};
const columnTypes = useMemo(() => {
  return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
              // provide comparator function
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                  // In the example application, dates are stored as dd/mm/yyyy
                  // We create a Date object for comparison against the filter date
                  const dateParts = cellValue.split("/");
                  const day = Number(dateParts[0]);
                  const month = Number(dateParts[1]) - 1;
                  const year = Number(dateParts[2]);
                  const cellDate = new Date(year, month, day);
                  // Now that both parameters are Date objects, we can compare
                  if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                  } else if (cellDate > filterLocalDateAtMidnight) {
                      return 1;
                  } else {
                      return 0;
                  }
              },
          },
      },
  };
}, []);

  const singleComment = data?.filter((item) => item?.Admin_Dismiss_Status === "No");
  const myData = notificationAllDataList?.filter((item, index) => index !== 0);
  
  return (
    <>
        { 
        singleComment?.length === 0 ? "" :
        singleComment?.length === 1 ? (
         
           <div>
          <InfoIcon />
         
          <span style={{color: "white", paddingLeft: "10px", fontSize: "16px"}}>{singleComment?.[0]?.Notification_Type === "Comment" ? `Comment: ${singleComment?.[0]?.Comment_Details}` : `Reply of Comment: ${singleComment?.[0]?.Comment_Details},`} </span> 
          <span  style={{color: "white", fontSize: "16px"}}> By ({singleComment?.[0]?.Commented_By}) On Order:</span> <span style={{color: "white", fontSize: "16px"}}>{singleComment?.[0]?.Order_Id}.</span> 
    
          <Button
            onClick={() => {
              // setIssShowAllNotification(true);
              handleChange(singleComment?.[0]);
            }}
            style={{marginLeft: "8px", borderRadius: "15px", marginTop: "-5px"}}
          >
          <span  style={{color: "white", fontSize: "14px", textDecoration: "underline"}}>Dismiss</span>
          
          </Button>
          </div>
          
          ) : (
        
        <div>
           <InfoIcon />
           
        <span  style={{color: "white", fontSize: "16px", paddingLeft: "10px"}}>
            You may have multiple comments / reply on orders.
            </span>
          <Button
            onClick={() => {
              setIssShowAllNotification(true);
              getCommentNotificationApi();
            }}
            style={{marginLeft: "8px", borderRadius: "15px",}}
          >
             <span  style={{color: "white", fontSize: "13px", textDecoration: "underline"}}>View All</span>
            
          </Button>

          {/* <Button
            className="toast-button"
            onClick={() => handleReadAllNotification()}
          >
            Read All
          </Button> */}
        </div>
         )}
    

      <Modal
        show={isShowAllNotification}
        onHide={() =>
           {
            setIssShowAllNotification(false);
            setReqFromDate("")
            setReqToDate("")
          }
          }
        animation={true}
        size="xl"        
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#eb6e12", color: "white" }}
          closeButton
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Comments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "670px" }}>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item sm={12} md={6} style={{ paddingRight: "1rem" }}>
         <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
           <Form.Label
             column
             sm="5"
             style={{ fontWeight: "600", fontSize: 12 }}
           >
             Select Date for All Comment / Reply:
           </Form.Label>
           <Col sm="7" style={{ padding: "0 12px 0 0" }}>
             <div className="fc-dropdown-container">
             <DateRangePickerComp
                        custom={true}
                        fieldHandleChange={(value, picker) => {
                          setReqFromDate(
                            moment(picker.startDate).format("YYYY-MM-DD")
                          );
                          setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                          setStart_Date(moment(picker.startDate).format("YYYY-MM-DD"))
                        }}
                      />
             </div>
           </Col>
         </Form.Group>
       </Grid>
            <div></div>
            <Button
              className="toast-button"
              onClick={() => {
                setReqFromDate("")
                setReqToDate("")
                handleReadAllNotification()
              }
            }
            >
              Dismiss All
            </Button>
          </div>

          <div
          className="ag-theme-alpine"
          style={{ height: "616px", maxHeight: "616px", overflow: "auto",}}
        >
      <AgGridReact
       suppressExcelExport={true}
       suppressCsvExport={true}
       ref={gridRef}
       rowData={notificationAllDataList}
       columnDefs={columnDefs}
       defaultColDef={defaultColDef}
       columnTypes={columnTypes}
       animateRows={true}
       components={components}
       onSelectionChanged={onRowSelected}
       rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
       getRowHeight={() => 90}
       pagination={true}
       detailRowAutoHeight={true}
       onPaginationChanged={onPaginationChanged}
   ></AgGridReact>
    </div>

          {/* <CustomPaginationTable
            fitlerBadge={false}
            isCommonPageSelect={false}
            gridRef={gridRef}
            columnDefs={columnDefs}
          //  apiData={data}
            // totalCount={data}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
          /> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
