import React, { useContext, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import { Button, Box, Grid, IconButton} from "@mui/material";
import { Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import clientReviewFormStyles from "./clientReviewFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { getLocalData } from "../../../../../services/services";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
};

const ClientReviewForm = ({ classes, getInfo }) => {
  const {
    handleModaleClose,
    fetchClientResponseFromApi,
    editCard,
    setEditCard,
    handleClickSnack,
    setMessage,
    setSeverity,
    clientDetail,
    operationInfo
  } = useContext(SupportDataContext);

  const [loading, setLoading] = React.useState(false);
  const fileInput = React.createRef();
  const __token=getLocalData('token')
  const isCompany = operationInfo?.hasOwnProperty("Inquiry_Id") ? 0 : 1
  const isEditAuthority = editCard ? clientDetail?.authority_dropdown?.find(val => val.Authority_Name === getInfo?.Authority) : "";
  const user_type = getLocalData("user_type");
  const emp_id = getLocalData("emp_id");
  const factory_id = localStorage.getItem("factory_id");


  const onSaveData = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${__token}`);
    const formData = new FormData();

    formData.append("enquirid", 0);
    formData.append("authority", data.authority);
    formData.append("uploaded_file_name", data.uploadDoc);
    formData.append("factory_id", factory_id);
    formData.append("user_type", user_type);
    formData.append("emp_id", emp_id);
    formData.append("order_id", operationInfo?.Order_Id);
    formData.append("company_id", operationInfo?.Company_Id);
    formData.append("remarks", data.remarks);
    formData.append("is_company", 1);
    setLoading(true);
    let result = await fetch(
      "https://flyingcolour.online/fcapp/spm_api/clientreview/",
      {
        method: "POST",
        headers: {
          Authorization: `Token ${__token}`,
        },
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setMessage(data.data.msg);
        setSeverity(data.data.code);
        handleClickSnack();
        fetchClientResponseFromApi();
      })
      .catch((error) => {
        console.error("Error from business card save:", error);
      });
    handleModaleClose();
  };
  const onEditData = async (data) => {
  
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${__token}`);
    const formData = new FormData();
    formData.append("enquirid", 0);
    formData.append("authority", data.authority); // #mandatory
    formData.append("uploaded_file_name", data.uploadDoc);

    formData.append("user_type", user_type);
    formData.append("emp_id", emp_id);
    formData.append("order_id", operationInfo?.Order_Id);
    formData.append("company_id", getInfo?.Company_Id);

    formData.append("remarks", data.remarks); 
    formData.append("updateid", getInfo.Client_Review_Id); 
    formData.append("is_company", 1);
    setLoading(true);
    let result = await fetch(
      " https://flyingcolour.online/fcapp/spm_api/clientreview/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
        },
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.message) {
          setMessage(data.message);
          setSeverity(500);
        } else {
          setMessage(data.data.msg);
          setSeverity(data.data.code);
        }
        handleClickSnack();
        fetchClientResponseFromApi();
        setEditCard(false);
      })
      .catch((error) => {
        console.error("Error from business card edit:", error);
      });
    handleModaleClose();
  };
 
  return (
<>
    <Formik
      enableReinitialize
      initialValues={{
        inquiryId: operationInfo?.Company_Id,
        authority:editCard ? isEditAuthority?.Authority_Id: clientDetail.authority_dropdown === undefined && clientDetail.authority_dropdown?.length !==0 ?"":1,
        uploadDoc: editCard ? getInfo.Upload_Image:"",
        remarks:editCard ? getInfo.Remarks:"",
        created: new Date().toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "numeric",
          year: "numeric",
        }),
      }}
      validate={(values) => {
        const errors = {};
        if (!values.authority) {
          errors.authority = "Required!!";
        }
        if (!values.uploadDoc) {
          errors.uploadDoc = "Required!!";
        }
        return errors;
      }}
      onSubmit={async (data) => {
        editCard ? onEditData(data) :
         onSaveData(data);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <IconButton
              className={classes.clientReviewModalHeaderIconCancle}
              onClick={handleModaleClose}
            >
              <CloseIcon className={classes.clientReviewModalHeaderIcon} />
            </IconButton>
            <Grid container spacing={2} columns={12}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: "-30px",
                  paddingBottom: "10px",
                  marginTop: "-45px",
                }}
              >
                <br />
                <div className={classes.clientReviewModalHeadingContainer}>
                  <span className={classes.clientReviewModalHeader}>
                    {" "}
                    Client Review{" "}
                  </span>
                </div>
                <br />
                <div className={classes.clientReviewModalFlexDivContaner}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className={classes.clientReviewInnerGridLeft}
                    >
                      <div className={classes.clientReviewFormGroupModal}>
                        <div
                          className={classes.clientReviewFormGroupFlexDivModal}
                        >
                          <label
                            className={classes.clientReviewLabelFormInputModal}
                          >
                            <span>{isCompany === 0 ? "Inquiry Id#" :"Company Id#" }: </span>
                          </label>

                          <div
                            className={classes.clientReviewSelectContainerModal}
                          >
                            <div
                              className={classes.clientReviewDemoDataCheckModal}
                            >
                              <input
                                className={
                                  classes.clientReviewListingFormInputMainModal
                                }
                                placeholder="Inquiry Id#"
                                name="inquiryId"
                                value={values.inquiryId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classes.clientReviewFormGroupModal}>
                        <div
                          className={classes.clientReviewFormGroupFlexDivModal}
                        >
                          <label
                            className={classes.clientReviewLabelFormInputModal}
                          >
                            <span>Authority </span>
                          </label>

                          <div
                            className={classes.clientReviewSelectContainerModal}
                          >
                            <div
                              className={classes.clientReviewDemoDataCheckModal}
                            >
                              <select
                                className={
                                  classes.clientReviewListingFormInputMainModal
                                }
                                name="authority"
                                value={values.authority}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              >
                                {clientDetail.authority_dropdown === undefined ? (
                                  <option value="" >
                                  No option
                                </option>
                                ) :clientDetail?.authority_dropdown.map((i) => (
                                  <option value={i.Authority_Id} key={i.Authority_Id}>
                                    {i.Authority_Name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <span style={{ color: "red" }}>
                              {errors.uploadDoc &&
                                touched.uploadDoc &&
                                errors.uploadDoc}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className={classes.clientReviewFormGroupModal}>
                        <div
                          className={classes.clientReviewFormGroupFlexDivModal}
                        >
                          <label
                            className={
                              classes.clientReviewDemoDataCheckModalUploadLabel
                            }
                          >
                            <span>Upload Image: </span>
                          </label>

                          <div
                            className={classes.clientReviewSelectContainerModal}
                          >
                            <div
                              className={
                                classes.clientReviewDemoDataCheckModalUploadFile
                              }
                            >
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(event) => {
                                  setFieldValue(
                                    "uploadDoc",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                ref={fileInput}
                              />
                              <button
                                className={
                                  classes.clientReviewDemoDataCheckModalUploadFileBtn
                                }
                                type="button"
                                onClick={() => fileInput.current.click()}
                              >
                                <PermMediaIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Add Media
                              </button>

                              <small
                                className={
                                  classes.clientReviewDemoDataCheckModalUploadFileText
                                }
                              >
                                {values.uploadDoc
                                  ? values.uploadDoc.name ||
                                    values.uploadDoc.split("/").pop()
                                  : "No file chosen"}{" "}
                              </small>
                            </div>
                            <span style={{ color: "red" }}>
                              {errors.uploadDoc &&
                                touched.uploadDoc &&
                                errors.uploadDoc}
                            </span>
                          </div>
                        </div>
                        {values.uploadDoc.length !== 0 ? (
                          (
                            values.uploadDoc.name
                              ? values.uploadDoc.name.split(".").pop() ===
                                ("png" || "jpg" || "jpeg" || "JPEG")
                              : values.uploadDoc.split(".").pop() ===
                                ("png" || "jpg" || "jpeg" || "JPEG")
                          ) ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: ".2rem",
                              }}
                            >
                              <img
                                onClick={() => fileInput.current.click()}
                                style={{ width: "50%", height: "150px" }}
                                src={
                                  values.uploadDoc.name
                                    ? URL.createObjectURL(values.uploadDoc)
                                    : values.uploadDoc
                                }
                                alt={"image"}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: ".5rem",
                                marginTop: ".5rem",
                                alignItems: "center",
                              }}
                            >
                              <CloudUploadIcon
                                style={{
                                  color: "green",
                                  fontSize: "32px",
                                  marginRight: ".4rem",
                                }}
                              />
                              {values.uploadDoc.name
                                ? values.uploadDoc.name
                                : values.uploadDoc?.split("/").pop()}
                            </div>
                          )
                        ) : null}
                      </div>

                      <div className={classes.clientReviewFormGroupModal}>
                        <div
                          className={classes.clientReviewFormGroupFlexDivModal}
                        >
                          <label
                            className={classes.clientReviewLabelFormInputModal}
                          >
                            <span>Remarks: </span>
                          </label>

                          <div
                            className={classes.clientReviewSelectContainerModal}
                          >
                            <div
                              className={classes.clientReviewDemoDataCheckModal}
                            >
                              <textarea
                                className={
                                  classes.clientReviewListingFormInputMainModal
                                }
                                placeholder="remarks"
                                name="remarks"
                                value={values.remarks}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <br />
              </Grid>
            </Grid>
            <div className={classes.clientReviewButtonDivModal}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{ backgroundColor: "#eb6e12" }}
                 onClick={handleSubmit}               
              >
                {loading ? "Loading..." : "Save"}
              </Button>
              <Button
                onClick={() => {
                  handleModaleClose();
                  // handleHide()
                }}
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#fff",
                  color: "#eb6e12",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </form>
      )}
    </Formik>
    <Error  />
    </>
  );
};

export default withStyles(clientReviewFormStyles)(ClientReviewForm);
