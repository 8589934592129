import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton, Box, Grid } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from "react-bootstrap/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import FollowupTable from "../Followup/FollowupTable";
import ViewRequisitionTable from "../Requisition/ViewRequisitionTable";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    p: 4,
    height: "auto",
    paddingTop: "0px",
    minHeight: "550px",
    maxHeight: "550px",
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
      maxHeight: "760px",
    },
  };

const ViewCompanyOrdersTable = ({ classes }) => {
  const {
    message,
    setButtonText,
    setOperationInfo,
    handleModalOpen,
    operationInfo,
    getCompanyOrdersDataApi,
    companyOrdersData,
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  useEffect(() => {
    getCompanyOrdersDataApi();
  }, []);

  const columnDefs = [
    {
      headerName: "Sr.#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 80,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Action",
      field: "action",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      pinned: "left",
      cellRenderer: (params) => {
        return (
          <>
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue('viewfollowup');
                setOpenModal(true);
              }}
              title={"View Follow Up"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>View Follow Up</>}
            />
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue('viewrequistion');
                setOpenModal(true);
              }}
              title={"View Requistion"}
              width={"auto"}
              className="btnActionAlignment"
              icon={<>View Requistion</>}
            />
          </>
        );
      },
    },
    {
      headerName: "Order No",
      field: "Order_No",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 250,
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 210,
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Order Creation Date",
      field: "Order_Creation_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 150,
    },
    {
      headerName: "Support Assign Date",
      field: "Support_Assign_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Completion_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Support Name",
      field: "Support_Emp_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 170,
    },

    {
      headerName: "Point of Contact",
      field: "Point_of_Contact",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 300,
      cellRenderer: (params) => {
        params = params?.data?.Point_of_Contact;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Followup",
      field: "Last_Followups",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Followups;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Tag Name",
      field: "Tag_Names",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 250,
      cellRenderer: (params) => {
        params = params?.data?.Tag_Names;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },

    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "No Data" ? (
              <>
                {params?.data?.Freeze_Approval}
                <br />
                {params?.data?.Freeze_Approval_Date}
              </>
            ) : (
              params?.data?.Freeze_Approval
            )}
          </>
        );
      },
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    //   {
    //     headerName: "License Issue/Expiry Date",
    //     field: "License_Expiry_Date",
    //     cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
    //     type: ["dateColumn", "nonEditableColumn"],
    //     filterParams: filterParams,
    //     cellRenderer: (params) => {
    //       return params.data.License_Expiry_Date ? (
    //         <>{moment(params?.data?.License_Expiry_Date).format("DD-MM-YYYY")}</>
    //       ) : (
    //         <></>
    //       );
    //     },
    //     width: 200,
    //   },
    {
      headerName: "Work in Progress",
      field: "Work_in_Progress",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Work_in_Progress;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Sub Activities",
      field: "Sub_Activities",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "State",
      field: "State",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Advisor Name",
      field: "Advisor_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "inquiry / company",
      field: "from_inquiry_or_company",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      //filter: 'agTextColumnFilter',
    },
    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Old / New Order",
      field: "old_or_new_order",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            <>
              {params?.data?.old_or_new_order === 1
                ? "New"
                : params?.data?.old_or_new_order === 0
                ? "Old"
                : ""}
              <br />
            </>
          </>
        );
      },
    },
    {
      headerName: "Project Name",
      field: "project_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 210,
    },
    {
      headerName: "Order Sequence / No of Order",
      field: "order_sequence",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
            <>
              <span style={{ fontWeight: "bold" }}>Order Sequence:</span>{" "}
              {params?.data?.order_sequence}
              <br />
              <span style={{ fontWeight: "bold" }}>No. of Order:</span>{" "}
              {params?.data?.no_of_orders}
            </>
          </>
        );
      },
    },
    {
      headerName: "Revised NOF",
      field: "Revised_NOF_Exist",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      hide: true,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      //   floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={companyOrdersData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                 <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                        setOpenModal(false);
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div>

                </div>
                <div style={{ padding: "0px 20px 20px 20px" }}>
                <Grid container spacing={2} columns={12} style={{marginBottom:'30px'}}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10
                      }}
                    >
                      <br />
                      <div style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px ",
                        borderTop: "1px solid #eb6e12",
                        borderBottom: "1px solid #eb6e12",
                        backgroundColor: "#eb6e12",
                      }}>
                        <span style={{
                               color: "#fff",
                               fontSize: "16px",
                               fontWeight: "bold",
                               marginLeft: "5px",
                               textTransform: "capitalize",
                        }}>
                           {modalValue === "viewfollowup" ? "View Follow Up" : "View Requistion"}
                        </span>
                      </div>
                      <br />
                </Grid>
                </Grid>
                <>
                    {modalValue === "viewfollowup" ? (
                        <FollowupTable />
                    ) : modalValue === "viewrequistion" ? (
                        <ViewRequisitionTable  />
                    )  : null}
                </>
                </div>
              </div>
            </Box>
           
            </Modal>
    </div>
  );
};

export default withStyles(modalTableStyles)(ViewCompanyOrdersTable);
