import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import LoaderClock from "../../../../lodar/LoaderClock";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Repeat from '@mui/icons-material/Repeat';


const OverdueReasonListingTable = ({ classes }) => {
  const {
    overDueOrdersData,
    loading,
    operationInfo,
    getOverDueOrdersDataApi,
  } = useContext(SupportDataContext);
  useEffect(() => {
    getOverDueOrdersDataApi(operationInfo.Order_Id);
  }, [operationInfo.Order_Id]);
  const gridRef = useRef();

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        if (dateAsString == null) {
            return 0;
        }
        const dateParts = dateAsString.slice(0, 10).split("-");
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
};

  const [columnDefs] = useState([
    {
      headerName: "S #",
      field: "Serial_Number",
      filter: false,
      width: 60,
      maxWidth: 90,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Created By",
      field: "Created_By",
      filter: "agTextColumnFilter",
      width: 230,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      filter: "agDateColumnFilter",
      pinned: "left",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
      filterParams: dateFilterParams,
      cellRenderer: (params) => {
          return <>{params?.data?.Created_Date === ""  || params?.data?.Created_Date === null ? "" :
              moment(params?.data?.Created_Date).format("DD-MM-YYYY hh:mm:ss")}</>;
      },
  },
   
    {
      headerName: "Created By User Type",
      field: "created_by_user_type",
      //filter: "agDateColumnFilter",
      width: 200,
      maxWidth: 230,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Reason",
      field: "reason_name",
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex:1,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Remarks",
      field: "delay_reason",
      filter: "agTextColumnFilter",
      minWidth: 350,
      flex:1,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Assigned By",
      field: "assigned_by",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Assigned By User Type",
      field: "assigned_by_user_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      editable: false,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <LoaderClock loading={loading} />
      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={overDueOrdersData?.details}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
    </div>
  );
};

export default withStyles(modalTableStyles)(OverdueReasonListingTable);
