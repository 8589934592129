import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../../../ReceptionListing/ListingDataGrid.css";
import moment from "moment";
import axios from "axios";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import ModalPopper from "../Modals/ModelPopper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useFormik, Formik } from "formik";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
// import ExpandCompaniesListingTab from "../../listingTab/expandCompaniesListingTab";
import { CustomSelect } from "../../../listing/listingForm/ListingForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../../../commonComp/customInput/CustomInput";
import Badge from "@mui/material/Badge";
import {
  getTotalLength,
  handleDefaultFilters,
} from "../../../../utils/Filters";
import { Grid, Button, Modal } from "@material-ui/core";
import Select from "react-select";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import FinalizeReassignProject from "./FinalizeReassignProject";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BulkReassignProjectListing = ({
  apiData,
  tableUpperButtonArr,
  tabValue,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    getBulkReassignProjectApi,
    bulkReassignProjectData,
    setIsOpen,
    message,
    isOpen,
    setSelected,
    setEditSaveButton,
    setFieldsEditing,
    setEditApiData,
    setFieldsEditingMain,
    selected,
    setOpen,
    setMessage,
    setSeverity
  } = useContext(DataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const todayDate = moment().format("YYYY-MM-DD");

  const [supportId, setSupportId] = useState("");
  const [targetId, setTargetId] = useState("");
  const [targetDropdown, setTargetDropdown]=useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [rowSelected, setRowSelected] = useState();;
  const [supportDetails, setSupportDetails] = useState({});


  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  useEffect(() => {
    getBulkReassignProjectApi(0);
  }, []);

  const Support_Options = bulkReassignProjectData?.employee_list?.map((item) => {
    const obj = { value: item.EmpId, label: item.Emp_Name };
    return obj;
  });

  const columnDefs = [
    {
      headerName: "Sr.",
      field: "Serial_Number",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        textAlign: "center",
      },
      width: 100,
      filter: false,
    },
    {
      headerName: "Company Name",
      field: "company_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Sub Order Template",
      field: "scope_work_template_name_for_sub_template",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      minWidth: 210,
      flex: 1,
    },
    {
      headerName: "Project Name",
      field: "project_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Project Status",
      field: "project_status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Support Name",
      field: "support_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Active / InActive",
      field: "active_inactive",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Created By",
      field: "created_by_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Created Date",
      field: "created_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Update By",
      field: "updated_by_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
    {
      headerName: "Updated Date",
      field: "updated_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 170,
      flex: 1,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      editable: false,
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedRows();
    setRowSelected(rowData);
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const addProjectReassignPOSTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const user_name = localStorage.getItem("Employee_First_Name");
    // https://flyingcolour.online/fcapp/compliance/checkverificationonpartner/
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/projectbulkreassigntosupport/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let projectIds = rowSelected?.map((val) => {
      return val.project_id;
    });

    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);
    formData.append("project_id_array", projectIds);
    formData.append("assign_from", supportId);
    formData.append("assign_to", targetId);

    axios.post(url, formData, { headers: headers }).then((data) => {
        if (data?.data?.message) {
            setOpen(true);
            setMessage(`${data?.data?.message}`);
            setSeverity(500);
            setOpenModal(false);
          } else {
            setOpen(true);
            setMessage(data?.data?.data?.msg);
            setSeverity(data?.data?.data?.code);
            getBulkReassignProjectApi(supportId);
            setOpenModal(false);
            setRowSelected([])
          }
    });
  };

  const filters = [
    {
      id: 0,
      title: "Default",
      value: null,
      key: null,
    },
  ];

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
         <div
        className="listing-filters"
        style={{ padding: "1rem", background: "#fff" }}
      >
        <Grid container>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <CustomDropdown
              label="Source Employee Name"
              fieldOptions={Support_Options}
              fieldValue={supportId}
              fieldHandleChange={(value) => {
                let finalDropdownValue = Support_Options?.filter((val) => val.value !== value.value)
                setSupportId(value?.value);
                setTargetDropdown(finalDropdownValue)
                setSupportDetails({
                  ...supportDetails,
                  supportName: value.label
                })
              }}
              isClearable={true}
            />
          </Grid>
        
         
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
              }}
              disabled={supportId ? false : true}
              onClick={() => {
                setRowSelected([]);
                getBulkReassignProjectApi(supportId);
              }}
            >
              Search
            </Button>
            
          </Grid>
        </Grid>
      </div>
      <div
        className="listing-filters"
        style={{ padding: "1rem", background: "#fff" }}
      >
        <Grid container>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <CustomDropdown
              label="Target Employee Name"
              fieldOptions={targetDropdown}
              fieldValue={targetId}
              fieldHandleChange={(value) => {
                setTargetId(value?.value);
                setSupportDetails({
                  ...supportDetails,
                  assignSupportName: value.label
                })
              }}
              isClearable={true}
            />
          </Grid>
        
         
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
              }}
              onClick={() => {
                //getBulkReassignProjectApi(supportId);
                if(targetId && rowSelected?.length > 0){
                    setOpenModal(true);
                } else{
                    setOpen(true);
                    setMessage(`No items is selected`); 
                    setSeverity(500); 
                }
              }}
            >
              Reassign Project
            </Button>
            
          </Grid>
        </Grid>
      </div>
      <br />
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={bulkReassignProjectData?.project_list?.length}
      />

      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          ref={gridRef}
          rowData={bulkReassignProjectData?.project_list}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          // detailCellRenderer={renderExpandableRow}
          animateRows={true}
          onSelectionChanged={onRowSelected}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          rowSelection={'multiple'}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <ModalPopper />

      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Modal
                        open={openModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <FinalizeReassignProject
                          msg={"Are you sure?"}
                          supportDetails={supportDetails} 
                          rowSelected={rowSelected} 
                          onSubmit={() => {addProjectReassignPOSTAPI()}}
                          setOpenModal={setOpenModal}
                        />
                      </Modal>
    </div>
  );
};

export default BulkReassignProjectListing;
