import React, { useContext } from "react";
import { withStyles } from "@material-ui/core";
import previewFormStyles from "../../Preview/previewStyle";
import { Box, Grid, IconButton } from "@mui/material";
import { SupportDataContext, DataContext } from "../../../../../provider/context";
import { Button } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/styles.css";
import moment from "moment";
import { color, fontWeight } from "@mui/system";


const ViewOrderDetail = ({ classes }) => {
    const {
        getOrderListgDataApi,
        orderListgData,
    } = useContext(SupportDataContext);

    React.useEffect(() => {
        getOrderListgDataApi();
        //editLicenseType();
    }, []);

    const style = {
        //position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        //width: "75%",
        bgcolor: "#fff",
        border: "2px solid #eb6e12",
        // boxShadow: 10,
        // p: 4,
        // height: "auto",
        marginTop: "00px",
        // maxHeight: "590px",
        // overflowY: "scroll",
    };
    const listingEditListingFormIcons = {
        fontSize: '20px !important',
        marginBottom: '-4px',
        color: '#eb6e12',
        fontWeight: 'bold',
        // cursor: 'pointer'
    };
   

    //const findProperty = getUpdatePropertyDetailList?.filter(project => project.inquiry_id == inquiryId);
    const sow_Sequence = [];
    orderListgData[0]?.scope_work_sequence?.split(",").forEach((el) => {
        sow_Sequence?.push(el)   
    });

    const sow_Name = [];
    orderListgData[0]?.scope_work_name?.split(",").forEach((el) => {
        sow_Name?.push(el)
      
    });

    const sow_Days = [];
    orderListgData[0]?.scope_work_days?.split(",").forEach((el) => {
        sow_Days?.push(el)
     
    });

    const sow_Work = [];
    orderListgData[0]?.scope_work?.split(",").forEach((el) => {
        sow_Work?.push(el)
       
    });

    //let Document_Details = [];
    const Document_Details = orderListgData[0]?.Document_Details_List !== undefined ?
        Object?.values(orderListgData[0]?.Document_Details_List) : []
   



    return (
        // <>
        // <p>Hello</p>
        // </>
        <>
            <Box sx={style}>


                <div style={{ height: "600px", overflowX: "hidden", padding: "0", }}>

                    <Grid container spacing={2} columns={12}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                marginBottom: "-30px",
                                paddingBottom: "10px",
                            }}
                            >
                            <br />
                            <div className={classes.headingContainer} style={{textTransform: "capitalize",  marginTop: "-18px",}}>
                                <span className={classes.modalHeader} style={{textTransform: "capitalize"}}>
                                    {" "}
                                    {`Order Details for Company: ${orderListgData[0]?.Company_Name.toLowerCase()}`}
                                </span>
                            </div>
                            <div
                                style={{ marginBottom: "10px", marginTop: "0px" }}
                                className={classes.followUpFormModalFlexDivContaner}
                            >
                                {
                                    // allProperty.length === 0 ? "No Record Found Against this Inquiry" :

                                    <Grid container>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex", }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lable</span>

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Information</span>

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Lable</span>
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                <span style={{ fontSize: "14px", color: "#eb6e12", fontWeight: "bold" }}>Information</span>


                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Order No
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    <span style={{}}>{orderListgData[0]?.Order_No}</span>

                                                }

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                              Company Id
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                               {orderListgData[0]?.Company_Id}

                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Created Date
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    <span style={{}}>{orderListgData[0]?.Order_Creation_Date}</span>

                                                }

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                               Expected Completion Date
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                  orderListgData[0]?.Order_Completion_Date
                                                }

                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Start Date
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.lastCellData}
                                            >
                                                {
                                                    orderListgData[0]?.Order_Start_Date
                                                }

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Order Status
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Order_Status
                                                }
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Order Type
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Type_Of_Order
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Order Name
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    <span style={{textTransform: "capitalize"}}>{ orderListgData[0]?.Order_Name.toLowerCase()}</span>
                                                   // orderListgData[0]?.Order_Name

                                                }
                                            </Grid>


                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                State
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    `${orderListgData[0]?.State}`
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Formation
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Formation
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Fz/Offshore/Foundation Name
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    `${orderListgData[0]?.Fz_Offshore_Foundation_Name}`
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                          Group Activity Name
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Group_Activity_Name
                                                }
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                        <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                             Company Age (in Year)
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {orderListgData[0]?.Company_Age}
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Accounts Status
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Accounts_Status
                                                }
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Admin Approved Reject By

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Admin_Approved_Reject_By
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Admin Complete Accept Reject Reason
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Admin_Complete_Accept_Reject_Reason
                                                }
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Complete Order Status By Admin

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Complete_Order_Status_By_Admin
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Advisor Name
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Advisor_Name
                                                }
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Last Followups
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    <div dangerouslySetInnerHTML={{ __html: orderListgData[0]?.Last_Followups }} />
                                                    //    orderListgData[0]?.Last_Followups  
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Last Requisition
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Last_Requisition
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Freeze Approval
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Freeze_Approval
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Freeze Approval Date

                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Freeze_Approval_Date
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Freezed Order Commented By Admin
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Freezed_Order_Commented_By_Admin
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Point of Contact
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    //<div dangerouslySetInnerHTML={{ __html: orderListgData[0]?.Point_of_Contact }} />
                                                   orderListgData[0]?.Point_of_Contact
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Sub Activities
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Sub_Activities
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Support Name
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Support_Emp_Name
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Support Assign Date
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Support_Assign_Date
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Support Status
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Support_Status
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Task in Progress
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                     <div dangerouslySetInnerHTML={{ __html: orderListgData[0]?.Task_in_Progress }} />
                                                    // orderListgData[0]?.Task_in_Progress
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Work in Progress
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    <div dangerouslySetInnerHTML={{ __html: orderListgData[0]?.Work_in_Progress }} />
                                                    //orderListgData[0]?.Work_in_Progress
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{ display: "flex" }}
                                            className={classes.followUpFormInnerGridLeft}
                                        >

                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Trade Names
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Trade_Names
                                                }
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ fontWeight: "600", height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                Typist Name
                                            </Grid>
                                            <Grid
                                                xs={4}
                                                style={{ height: "auto" }}
                                                className={classes.cellData}
                                            >
                                                {
                                                    orderListgData[0]?.Typist_Name
                                                }
                                            </Grid>
                                        </Grid>

                                      <Grid
                                      item
                                      xs={12}
                                      style={{ display: "flex" }}
                                      className={classes.followUpFormInnerGridLeft}
                                      >
                                      
                                      <Grid
                                          xs={3}
                                          style={{ fontWeight: "600", height: "auto" }}
                                          className={classes.cellData}
                                      >
                                      Document Details List
                                      </Grid>
                                      <Grid
                                          xs={9}
                                          style={{ height: "auto" }}
                                          className={classes.cellData}
                                      >
                                      {orderListgData?.[0]?.upload_doc_details?.length === 0 ? "No Data" : (
                                       <>
                                       <Grid
                                         item
                                         xs={12}
                                         style={{ display: "flex" }}
                                         className={classes.followUpFormInnerGridLeft}
                                       >
                                         <Grid
                                           xs={4}
                                           style={{
                                             fontWeight: "600",
                                             height: "auto",
                                             textAlign: "center",
                                           }}
                                           className={classes.cellData}
                                         >
                                           Document Type
                                         </Grid>
                                         <Grid
                                           xs={4}
                                           style={{
                                             fontWeight: "600",
                                             height: "auto",
                                             textAlign: "center",
                                           }}
                                           className={classes.cellData}
                                         >
                                           Particular
                                         </Grid>
                                         <Grid
                                           xs={4}
                                           style={{
                                             fontWeight: "600",
                                             height: "auto",
                                             textAlign: "center",
                                           }}
                                           className={classes.lastCellData}
                                         >
                                           Documents
                                         </Grid>
                                       </Grid>
                                      
                                       {orderListgData?.[0]?.upload_doc_details?.map((i) => {
                                         return (
                                           <>
                                             <Grid
                                               item
                                               xs={12}
                                               style={{ display: "flex" }}
                                               className={classes.followUpFormInnerGridLeft}
                                             >
                                               <Grid
                                                 xs={4}
                                                 style={{
                                                   height: "auto",
                                                   textAlign: "center",
                                                 }}
                                                 className={classes.cellData}
                                               >
                                                  {
                                                   i.Document_Type_Name === undefined 
                                                   || i.Document_Type_Name === null
                                                   || i.Document_Type_Name === "" ? "No Data"
                                                   : i.Document_Type_Name 
                                                   }
                                               </Grid>
                                               <Grid
                                                 xs={4}
                                                 style={{
                                                   height: "auto",
                                                   textAlign: "center",
                                                 }}
                                                 className={classes.cellData}
                                               >
                                                  {
                                                   i.Particular === undefined 
                                                   || i.Particular === null
                                                   || i.Particular === "" ? "No Data"
                                                   : i.Particular 
                                                   }
                                               </Grid>
                                               <Grid
                                                 xs={4}
                                                 style={{
                                                   height: "auto",
                                                   textAlign: "center",
                                                 }}
                                                 className={classes.lastCellData}
                                               >
                                                  {
                                                   i.uploadDoc === undefined 
                                                   || i.uploadDoc === null
                                                   || i.uploadDoc === "" ? "No Data"
                                                   :
                                                 <Button
                                                  style={{
                                                    marginLeft: ".4rem",
                                                    textDecorationColor: "#eb6e12",
                                                    background: "none",
                                                    cursor: "pointer",
                                                    border: "none",
                                                    padding: "6px",
                                                  }}
                                                  onClick={() => window.open(i?.uploadDoc)}
                                                  >
                                                  View Document
                                                </Button>
                                                 }
                                               </Grid>
                                             </Grid>
                                           </>
                                         );
                                       })}
                                     </>
                                   )}
                                      </Grid>
                                      
                                  </Grid>
                                      
                                      
                                  <Grid
                                      item
                                      xs={12}
                                      style={{ display: "flex" }}
                                      className={classes.followUpFormInnerGridLeft}
                                                                          >
                                      
                                      <Grid
                                          xs={3}
                                          style={{ fontWeight: "600", height: "auto" }}
                                          className={classes.cellData}
                                      >
                                          Scope of Work
                                      </Grid>
                                      <Grid
                                          xs={9}
                                          style={{ height: "auto" }}
                                          className={classes.cellData}
                                      >
                                      
                                          {
                                              orderListgData?.[0]?.scope_of_work_array?.map((item, i) => {
                                                  return (
                                                      <>
                                                          <Grid
                                                              item
                                                              xs={12}
                                                              style={{ display: "flex" }}
                                                              className={classes.followUpFormInnerGridLeft}
                                                          >
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Name
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Name
                                                                  }
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Days
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Days
                                                                  }
                                                              </Grid>
                                      
                                                          </Grid>
                                      
                                                          <Grid
                                                              item
                                                              xs={12}
                                                              style={{ display: "flex" }}
                                                              className={classes.followUpFormInnerGridLeft}
                                                          >
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Sequance
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Sequance
                                                                  }
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Govt Department
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Govt_Department
                                                                  }
                                                              </Grid>
                                      
                                                          </Grid>
                                      
                                                          <Grid
                                                              item
                                                              xs={12}
                                                              style={{ display: "flex" }}
                                                              className={classes.followUpFormInnerGridLeft}
                                                          >
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Task Type
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Task_Type
                                                                  }
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Task Amount
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Task_Amount
                                                                  }
                                                              </Grid>
                                      
                                                          </Grid>
                                      
                                                          <Grid
                                                              item
                                                              xs={12}
                                                              style={{ display: "flex" }}
                                                              className={classes.followUpFormInnerGridLeft}
                                                          >
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Task Category
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Task_Category
                                                                  }
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Task Section
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Task_Section
                                                                  }
                                                              </Grid>
                                      
                                                          </Grid>
                                      
                                                          <Grid
                                                              item
                                                              xs={12}
                                                              style={{ display: "flex" }}
                                                              className={classes.followUpFormInnerGridLeft}
                                                          >
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Task Detail
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Task_Detail
                                                                  }
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Tag Name
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      <div dangerouslySetInnerHTML={{ __html: item.Tag_Name }} />
                                      
                                                                  }
                                                              </Grid>
                                      
                                                          </Grid>
                                      
                                                          <Grid
                                                              item
                                                              xs={12}
                                                              style={{ display: "flex" }}
                                                              className={classes.followUpFormInnerGridLeft}
                                                          >
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Work Type
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Work_Type
                                                                  }
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ fontWeight: "600", height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  Special Instruction
                                                              </Grid>
                                                              <Grid
                                                                  xs={3}
                                                                  style={{ height: "auto" }}
                                                                  className={classes.cellData}
                                                              >
                                                                  {
                                                                      item.Special_Instruction
                                                                  }
                                                              </Grid>
                                      
                                                          </Grid>
                                                          {
                                                              orderListgData?.[0]?.scope_of_work_array?.length > 1 ?
                                                                  <div className={classes.headingContainer}> </div>
                                                                  : null
                                                          }
                                                      </>
                                      
                                                  )
                                      
                                              }
                                      
                                              )
                                      
                                          }
                                      
                                      </Grid>
                                      
                                      
                                      
                                  </Grid>
                                      
                                        <br />

                                       
                                    </Grid>

                                }
                            </div>

                        </Grid>
                    </Grid>




                </div>

            </Box>

        </>
    );
};

export default withStyles(previewFormStyles)(ViewOrderDetail);