import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./customUploadFile.css";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";

const CustomUploadFile = ({
  label,
  uploadDoc,
  fieldHandleChange,
  requiredField,
  uploadType,
  fieldDeleteChange,
}) => {
  const fileInput = useRef(null);
  return (
    <>
      <Form.Group as={Row} className="mb-2" controlId={label}>
        <Form.Label column sm="5" className="fc-upload-label">
          {label} {requiredField && <sup style={{ color: "red" }}>*</sup>}
        </Form.Label>
        <Col sm="7" style={{ padding: 0 }}>
          <div className="upload-doc-container">
            <div className="upload-doc-container-upload-file">
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={(event) => {
                  fieldHandleChange(event);
                  //   setFieldValue(
                  //     "uploadDoc",
                  //     event.currentTarget.files[0]
                  //   );
                }}
                // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ref={fileInput}
              />
              <button
                className="upload-doc-container-upload-file-btn"
                type="button"
                onClick={() => fileInput.current.click()}
              >
                <PermMediaIcon style={{ marginRight: "10px" }} />
                Add Media
              </button>
              <small className="upload-doc-container-upload-file-text">
                {uploadType === "single" && (
                  <>
                    {uploadDoc
                      ? uploadDoc?.name || uploadDoc?.[0]?.name || uploadDoc?.split("/")?.pop() 
                      : "No file chosen"}
                  </>
                )}
                {uploadType === "multiple" && uploadDoc?.length <= 0 && (
                  <>No file chosen</>
                )}
              </small>
            </div>

            {uploadType === "multiple" && uploadDoc?.length > 0 &&
              uploadDoc?.map((value, i) => {
                return (
                  <>
                    {typeof value === "object" ? (
                      ["png", "jpg", "JPEG", "jpeg"].includes(
                        value?.name?.split(".").pop()
                      ) ? (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: ".2rem",
                            marginTop: "5px",
                          }}
                        >
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              fileInput.current[i]?.current.click();
                            }}
                            style={{
                              width: "100px",
                              height: "100px",
                              marginRight: "5px",
                            }}
                            src={URL.createObjectURL(value)}
                            alt={"upload-img"}
                          />
                          <small className="upload-doc-container-upload-file-text">
                            {value?.name}
                          </small>

                          <small
                            className="upload-file-remove"
                            onClick={() => fieldDeleteChange(i)}
                          >
                            <span className="upload-file-remove-btn">x</span>
                          </small>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: ".5rem",
                            marginTop: ".5rem",
                            alignItems: "center",
                          }}
                        >
                          {value?.name?.split(".").pop() === "docx" ? (
                            <>
                              <RiFileWord2Fill
                                style={{
                                  color: "#2b579a",
                                  fontSize: "36px",
                                  marginRight: ".4rem",
                                }}
                              />
                              <small className="upload-doc-container-upload-file-text">
                                {value?.name}
                              </small>
                              <small
                                className="upload-file-remove"
                                onClick={() => fieldDeleteChange(i)}
                              >
                                <span className="upload-file-remove-btn">
                                  x
                                </span>
                              </small>
                            </>
                          ) : value?.name?.split(".").pop() === "pdf" ? (
                            <>
                              <BsFilePdfFill
                                style={{
                                  color: "#ff0000",
                                  fontSize: "36px",
                                  marginRight: ".4rem",
                                }}
                              />
                              <small className="upload-doc-container-upload-file-text">
                                {value?.name}
                              </small>
                              <small
                                className="upload-file-remove"
                                onClick={() => fieldDeleteChange(i)}
                              >
                                <span className="upload-file-remove-btn">
                                  x
                                </span>
                              </small>
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                style={{
                                  color: "green",
                                  fontSize: "32px",
                                  marginRight: ".4rem",
                                }}
                              />
                              <small className="upload-doc-container-upload-file-text">
                                {value?.name}
                              </small>
                              <small
                                className="upload-file-remove"
                                onClick={() => fieldDeleteChange(i)}
                              >
                                <span className="upload-file-remove-btn">
                                  x
                                </span>
                              </small>
                            </>
                          )}
                        </div>
                      )
                    ) : typeof value === "string" ? (
                      ["png", "jpg", "JPEG", "jpeg"].includes(
                        value?.split(".").pop()
                      ) ? (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: ".2rem",
                            marginTop: "5px",
                          }}
                        >
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              fileInput.current[i]?.current.click();
                            }}
                            style={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={value}
                            alt={"upload-img"}
                          />
                          <small className="upload-doc-container-upload-file-text">
                            {value?.name}
                          </small>

                          <small
                            className="upload-file-remove"
                            onClick={() => fieldDeleteChange(i)}
                          >
                            <span className="upload-file-remove-btn">x</span>
                          </small>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            marginBottom: ".5rem",
                            marginTop: ".5rem",
                            alignItems: "center",
                          }}
                        >
                          {value?.split(".").pop() === "docx" ? (
                            <>
                              <RiFileWord2Fill
                                style={{
                                  color: "#2b579a",
                                  fontSize: "36px",
                                  marginRight: ".4rem",
                                }}
                              />
                              <small className="upload-doc-container-upload-file-text">
                                {value?.name}
                              </small>

                              <small
                                className="upload-file-remove"
                                onClick={() => fieldDeleteChange(i)}
                              >
                                <span className="upload-file-remove-btn">
                                  x
                                </span>
                              </small>
                            </>
                          ) : value?.split(".").pop() === "pdf" ? (
                            <>
                              <BsFilePdfFill
                                style={{
                                  color: "#ff0000",
                                  fontSize: "36px",
                                  marginRight: ".4rem",
                                }}
                              />
                              <small className="upload-doc-container-upload-file-text">
                                {value?.name}
                              </small>

                              <small
                                className="upload-file-remove"
                                onClick={() => fieldDeleteChange(i)}
                              >
                                <span className="upload-file-remove-btn">
                                  x
                                </span>
                              </small>
                            </>
                          ) : (
                            <>
                              <CloudUploadIcon
                                style={{
                                  color: "green",
                                  fontSize: "32px",
                                  marginRight: ".4rem",
                                }}
                              />
                              <small className="upload-doc-container-upload-file-text">
                                {value?.name}
                              </small>

                              <small
                                className="upload-file-remove"
                                onClick={() => fieldDeleteChange(i)}
                              >
                                <span className="upload-file-remove-btn">
                                  x
                                </span>
                              </small>
                            </>
                          )}
                        </div>
                      )
                    ) : null}
                  </>
                );
              })}
          </div>
        </Col>
      </Form.Group>
    </>
  );
};

export default CustomUploadFile;
