import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../listing/listingForm/ListingForm";
import InputText from "../../../../../reusableComponents/InputText/InputText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const ExecutedByForm = ({ classes, setOpenModal }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    followUp,
    setMessage,
    setSeverity,
    operationInfo,
    getProHeadRequistionDataApi,
    setIsOpen,
    proHeadRequistionData,
  } = useContext(SupportDataContext);

  const PRO_NameList = proHeadRequistionData?.PRO_List?.map(
    (item) => {
      const obj = { value: item.EmpId, label: item.Emp_Name };
      return obj;
    }
  );

  const executedByPOSTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/pro_head/ExecutedByOnPRORequisition/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      requisition_id: operationInfo?.requisition_id,
      requisition_no: operationInfo?.requisition_no,
      req_pro_name: values?.executedByName,
      req_pro_id: values?.executedById
    };

      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getProHeadRequistionDataApi();
    setOpenModal(false);
  };


  return (
    <Formik
      //enableReinitialize
      initialValues={{
        executedByName: "",
        executedById: ""
      }}
      validate={(values) => {
        let errors = {};

        if (!values.executedByName) {
          errors.executedByName = "required";
        }

        if (!values.executedById) {
            errors.executedById = "required";
          }

        return errors;
      }}
      onSubmit={(data) => {
        executedByPOSTAPI(data)
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "400px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          Executed By
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                          
                              <CustomDropdown
                                label="To be Executed By"
                                fieldOptions={PRO_NameList}
                                fieldValue={values?.executedById}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`executedById`, value.value);
                                  setFieldValue(`executedByName`, value.label);
                                }}
                                requiredField={true}
                              />
                        
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(ExecutedByForm);
