import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../Styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModalPopper";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { CustomSelect } from "../../../listing/listingForm/ListingForm";
import { useFormik, Formik } from "formik";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewIcon from "@mui/icons-material/Preview";
import { Modal } from "@mui/material";
import ViewOrderForm from "./ViewOrderForm";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Grid } from "@material-ui/core";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NOFListingTable = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    message,
    severity,
    isOpen,
    setIsOpen,
    getCorporateComplianceListApi,
    corporateComplianceList,
    setSearchTypeId,
    getSearchTypeId,
    setSalesInfo,
    setEditModalPopper,
    handleModalOpen,
    getOrderDropDownApi,
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [reqFromDate, setReqFromDate] = useState("");
  const [reqToDate, setReqToDate] = useState("");

  useEffect(() => {
    // getCorporateComplianceListApi();
    handleListingSavedSearch();
  }, []);

  useEffect(() => {
    fetchListingDataFromApi(reqFromDate, reqToDate);
  }, [reqFromDate, reqToDate]);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");

      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2023,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };

  const actionBtn = (params) => {
    let data = params?.data.downloadFilePath;

    return data ? (
      <>
        <CustomIconButton
          title="Download Document"
          icon={<DownloadIcon />}
          onClick={() => {
            {
              window.open(params?.data?.downloadFilePath, "_blank");
            }
          }}
        />
        <CustomIconButton
          title="View NOF"
          icon={
            <>
              <PreviewIcon />
            </>
          }
          onClick={() => {
            setModalValue("View NOF Inquiry");
            setOpenModal(true);
            setSalesInfo(params?.data);
            getOrderDropDownApi(params.data);
            setOperationInfo(params.data);
          }}
        />
      </>
    ) : null;
  };
  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      filter: false,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      width: 70,
      pinned: "left",
    },
    {
      headerName: "Action",
      field: "I_Id",
      filter: false,
      cellRenderer: actionBtn,
      cellStyle: { border: "1px solid #fff" },
      width: 120,
      pinned: "left",
    },
    // {
    //   headerName: "Order Id",
    //   field: "new_order_form_id",
    //   cellStyle: { border: "1px solid #fff" },
    //   width: 140,
    //   pinned: "left",
    // },
    {
      headerName: "Inquiry Id",
      field: "inquiry_id",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Order Title",
      field: "order_title",
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      filter: "agSetColumnFilter",
      width: 300,
      pinned: "left",
    },
    {
      headerName: "Client Name",
      field: "Partner_Name",
      cellStyle: { border: "1px solid #fff" },
      filter: "agSetColumnFilter",
      width: 200,
      cellRenderer: (params) => {
        return (
          <>
            {params.data.client_first_name} {params.data.client_last_name}
          </>
        );
      },
    },
    {
      headerName: "State",
      field: "state_name",
      cellStyle: { border: "1px solid #fff" },
      filter: "agSetColumnFilter",
      width: 170,
    },
    {
      headerName: "Formation Type",
      field: "formation_type",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
    },
    {
      headerName: "Legal Type",
      field: "legal_type_name",
      cellStyle: { border: "1px solid #fff", overflowY: "auto", height: 90 },
      //cellRenderer: lastFollowUp,
      width: 180,
    },
    {
      headerName: "Order Date",
      field: "Order_Date",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      // cellRenderer: (params) => {
      //   return <>{moment(params?.data?.Order_Date).format("DD-MM-YYYY")}</>;
      // },
      cellStyle: { border: "1px solid #fff" },
      width: 180,
    },
    {
      headerName: "Order Priority",
      field: "order_priority",
      filter: "agSetColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      width: 170,
    },
    // {
    //   headerName: "Operation Order Status",
    //   field: "operation_order_status",
    //   cellStyle: { border: "1px solid #fff" },
    //   filter: "agSetColumnFilter",
    //   width: 170,
    // },
    // {
    //   headerName: "Admin Approval Status",
    //   field: "operation_admin_approval_status",
    //   filter: "agSetColumnFilter",
    //   cellStyle: { border: "1px solid #fff" },
    //   width: 170,
    // },
    // {
    //   headerName: "Admin Approval Status Date",
    //   field: "operation_admin_approval_status_date",
    //   filter: "agSetColumnFilter",
    //   cellStyle: { border: "1px solid #fff" },
    //   width: 170,
    // },

    {
      headerName: "Client Email",
      field: "client_email",
      cellStyle: { border: "1px solid #fff" },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Client Mobile",
      field: "client_mobile",
      cellStyle: { border: "1px solid #fff" },
      width: 160,
    },
    {
      headerName: "Consultant Name",
      field: "created_by",
      cellStyle: { border: "1px solid #fff" },
      width: 170,
    },
    {
      headerName: "Remarks",
      field: "remarks",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
    };
  }, []);

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };
  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    //   {
    //     id: 2,
    //     title: "Pending",
    //     value: "Pending",
    //     key: "Worldcheck_Compliance_Status",
    //   },
    //   {
    //     id: 2,
    //     title: "Compliant",
    //     value: "Compliant",
    //     key: "Worldcheck_Compliance_Status",
    //   },
    //   {
    //     id: 2,
    //     title: "Non-Compliant",
    //     value: "Non-Compliant",
    //     key: "Worldcheck_Compliance_Status",
    //   },
    //   {
    //     id: 2,
    //     title: "FC Biz Setup",
    //     value: "FC Biz Setup",
    //     key: "Parent_Company_Name",
    //   },
    //   {
    //     id: 3,
    //     title: "FC Tax",
    //     value: "FC TAX",
    //     key: "Parent_Company_Name",
    //   },
    //   {
    //     id: 4,
    //     title: "FC Consulting",
    //     value: "FC Consulting",
    //     key: "Parent_Company_Name",
    //   },
    //   {
    //     id: 5,
    //     title: "Mainland",
    //     value: "Mainland",
    //     key: "Formation_Type",
    //   },
    //   {
    //     id: 6,
    //     title: "Free Zone",
    //     value: "Free Zone",
    //     key: "Formation_Type",
    //   },
    //   {
    //     id: 7,
    //     title: "Offshore",
    //     value: "Offshore",
    //     key: "Formation_Type",
    //   },
    //   {
    //     id: 8,
    //     title: "PRO Services",
    //     value: "PRO Services",
    //     key: "Formation_Type",
    //   },
    //   {
    //     id: 8,
    //     title: "Ac/Vat Services",
    //     value: "Accounting & VAT Services",
    //     key: "Formation_Type",
    //   },
    //   {
    //     id: 9,
    //     title: "Tax Client",
    //     value: "Tax Client",
    //     key: "Company_Status",
    //   },

    //   {
    //     id: 10,
    //     title: "Manager",
    //     value: "Manager Only",
    //     key: "Role_In_License",
    //   },
    //   {
    //     id: 11,
    //     title: "Share Holder",
    //     value: "Share Holder Only",
    //     key: "Role_In_License",
    //   },
    //   {
    //     id: 12,
    //     title: "Share Holder & Manager",
    //     value: "Share Holder & Manager",
    //     key: "Role_In_License",
    //   },
    //   {
    //     id: 13,
    //     title: "Overlap Yes",
    //     value: "Yes",
    //     key: "Is_Overlap",
    //   },
    //   {
    //     id: 14,
    //     title: "Overlap No",
    //     value: "No",
    //     key: "Is_Overlap",
    //   },
    //   {
    //     id: 15,
    //     title: "Corporate",
    //     value: "Corporate",
    //     key: "Entity_Classification",
    //   },
    //   {
    //     id: 16,
    //     title: "Individual",
    //     value: "Individual",
    //     key: "Entity_Classification",
    //   },
  ];
  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleFilters = (title, value, key) => {
   
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };

  const getTotalLength = (value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return;
    } else if (key === null) {
      return apiData.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      return apiData.filter((val) => val[key] === value).length;
    }
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id");
  };
  const SearchTypeOptions = corporateComplianceList?.Search_Type_List?.map(
    (item) => {
      const obj = { value: item.Value, label: item.Name };
      return obj;
    }
  );

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {apiData?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <>
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: "Times New Roman",
                        }}
                        onClick={() =>
                          handleFilters(val.title, val.value, val.key)
                        }
                      >
                        <span>{val.title}</span>
                      </Badge>
                    </>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(val.Search_Name, valueKey)}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          val.Search_Name,
                          val.Search_Name,
                          valueKey
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <div
        className="listing-filters"
        style={{ position: "relative", top: "4.6rem" }}
      >
        <Grid container>
          <Grid item sm={12} md={4}></Grid>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "600", fontSize: 12 }}
              >
                Requisition Date:
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <CustomDatePicker
                    custom={true}
                    fieldHandleChange={(value, picker) => {
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      //    getProRequestionListApi(reqFromDate, reqToDate);
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>
          <Grid item sm={12} md={4}></Grid>
        </Grid>
      </div>

      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.length}
      />

      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          //masterDetail={true}
          //detailCellRenderer={renderExpandableRow}
          columnTypes={columnTypes}
          animateRows={true}
          // onSelectionChanged={onRowSelected}
          //detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modalValue === "View NOF Inquiry" ? (
            <ViewOrderForm setOpenModal={setOpenModal} />
          ) : null}
        </>
      </Modal>
    </div>
  );
};

export default NOFListingTable;
