import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../provider/context";
import InputText from "../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../reusableComponents/CustomTextarea/CustomTextArea";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "650px",
  maxHeight: "650px",
  overflow: 'auto',
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if (height && !getIn(errors, fieldName)) {
    return { height: height }
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const EditCompanyInfoModalForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    getRequistionListingDataFromApi,
    getViewCompanyFamilyApi,
    viewCompanyFamilyData,
    salesInfo,
    operationInfo,
    getViewCompanyWorkerApi,
    viewCompanyWorkerData
  } = useContext(SupportDataContext);
  const fileInput = React.createRef();
const contactPassportExpiryData = operationInfo?.Point_Of_Contact_Passport_Expiry_date === "00-00-0000" ? "" : operationInfo?.Point_Of_Contact_Passport_Expiry_date;
  const [passportExpiry, setPassportExpiry] = useState(
    operationInfo?.Investor_Passport_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.Investor_Passport_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
//   const [visaIssue, setVisaIssue] = useState(
//     editModalPopper ? new Date(new Date(moment(operationInfo?.Investor_Visa_Issue_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
//       moment().toDate())
  const [visaExpiry, setVisaExpiry] = useState(
    operationInfo?.Investor_Visa_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.Investor_Visa_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")

 const [immigrationIssueDate , setImmigrationIssueDate] = useState(
  operationInfo?.Immigration_issue_date !== "" ? new Date(new Date(moment(operationInfo?.Immigration_issue_date).format("YYYY-MM-DD")).toString()) :
          "")
 const [immigrationExpiryDate , setImmigrationExpiryDate] = useState(
     operationInfo?.Immigration_Expiry_date !== "" ? new Date(new Date(moment(operationInfo?.Immigration_Expiry_date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
       "")
  const [labourIssueDate , setLabourIssueDate] = useState(
    operationInfo?.Labour_Issue_Date !== "" ? new Date(new Date(moment(operationInfo?.Labour_Issue_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
        "")

 const [emp_passportExpiry, setEmp_PassportExpiry] = useState(
 operationInfo?.Employment_Passport_Expiry !== "" ? new Date(new Date(moment(operationInfo?.Employment_Passport_Expiry, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
    "")
//  const [emp_visaIssue, setEmp_VisaIssue] = useState(
//     operationInfo?.Investor_Visa_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.Employment_Visa_Issue_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
//      "")
 const [emp_visaExpiry, setEmp_VisaExpiry] = useState(
  operationInfo?.employment_visa_expiry_date !== "" ? new Date(new Date(moment(operationInfo?.employment_visa_expiry_date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
 const [emp_cardExpiry, setEmp_CardExpiry] = useState(
    operationInfo?.Employment_Labour_Card_Expiry !== "" ? new Date(new Date(moment(operationInfo?.Employment_Labour_Card_Expiry, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
  const [tradeMartExpiryDate, setTradeMartExpiryDate] = useState(
     operationInfo?.Trade_Mark_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.Trade_Mark_Expiry_Date , "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
  const [poBoxExpiryDate, setPoBoxExpiryDate] = useState(
    operationInfo?.PO_Box_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.PO_Box_Expiry_Date , "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
     "")
  const [family_PassportExpiry, setFamily_PassportExpiry] = useState(
     operationInfo?.family_passport_expiry_date !== "" ? new Date(new Date(moment(operationInfo?.family_passport_expiry_date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "");
 const [family_visaExpiry, setFamily_VisaExpiry] = useState(
        operationInfo?.family_visa_expiry_date !== "" ? new Date(new Date(moment(operationInfo?.family_visa_expiry_date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
         "")
  const [family_cardExpiry, setFamily_CardExpiry] = useState(
     operationInfo?.family_labour_expiry_date !== "" ? new Date(new Date(moment(operationInfo?.family_labour_expiry_date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
 const [contact_PassportExpiry, setContact_PassportExpiry] = useState(
   contactPassportExpiryData !== "" ? new Date(new Date(moment(contactPassportExpiryData, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
 const [reraExpiryDate, setReraExpiryDate] = useState(
     operationInfo?.RERA_Card_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.RERA_Card_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
      "")
const [e_ChannelIssueDate, setE_ChannelIssueDate] = useState(
  operationInfo?.EChanel_Issue_Date !== "" ? new Date(new Date(moment(operationInfo?.EChanel_Issue_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
     "")
const [e_ChannelExpiryDate, setE_ChannelExpiryDate] = useState(
      operationInfo?.EChanel_Expiry_Date !== "" ? new Date(new Date(moment(operationInfo?.EChanel_Expiry_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) :
           "")



  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className, }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },

  }));

  const CountryList = viewCompanyWorkerData?.Nationality_List?.map((item) => {
    const obj = { value: item.name, label: item.name };
    return obj;
  });

  const CardStatusOptions = viewCompanyWorkerData?.Card_Status_List?.map((item) => {
    const obj = { value: item.Status_Id, label: item.Status_Name };
    return obj;
  });

  const LabourCardStatusOptions  = viewCompanyWorkerData?.Labour_Card_Status?.map((item) => {
    const obj = {  value: item.Status_Id, label: item.Status_Name };
    return obj;
  });
  const LabourCardTypeOptions = viewCompanyWorkerData?.Labour_Card_Type?.map((item) => {
    const obj = {  value: item.Name, label: item.Name };
    return obj;
  });

  const PrimaryContactOptions = [
    {value: "1", label: "Yes"},
    {value: "0", label: "No"},
  ]

  const addFamilyInfoPostAPI = async (values) => {
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const factory_id = localStorage.getItem("factory_id");
    
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/edit_company_info/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      factory_id: Number(factory_id),
      company_info_id: Number(operationInfo?.Id),
      company_id: Number(operationInfo?.Company_Id),
      investor_client_name: values.investor_name,
      investor_email: values.investor_email,
      investor_mob: values.investor_mobile,
      investor_nationality: values.nationality,
      investor_passport_expiry_date: values.investor_passport_expiry_date,
      investor_visa_issue_date: values.investor_visa_issue_date,
      investor_visa_expiry_date: values.investor_visa_expiry_date,
      family_relation: values.family_relation,
      card_status_id: values.card_status_id,
    }

    const formData = new FormData();
    formData.append("factory_id", factory_id);
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("user_name", user_name);
    formData.append("company_info_id", Number(operationInfo?.Id));
    formData.append("company_id", Number(operationInfo?.Company_Id));
    formData.append("existing_info", Number(operationInfo?.existing_info));

    // for existing Id = 1
    formData.append("immigration_issue_date", moment(values?.immigration_issue_date).format("YYYY-MM-DD HH:mm:ss")=== "Invalid date" ? "" : moment(values?.immigration_issue_date).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("immigration_expiry_date", moment(values?.immigration_expiry_date).format("YYYY-MM-DD HH:mm:ss")=== "Invalid date" ? "" : moment(values?.immigration_expiry_date).format("YYYY-MM-DD HH:mm:ss"));
    if(operationInfo?.existing_info === "1" && typeof(values?.uploadDoc1) === "string"){
      formData.append("upload_card_doc_url_name_1", values?.uploadDoc1);
     }
     if(operationInfo?.existing_info === "1" && typeof(values?.uploadDoc1) === "object"){
      formData.append("upload_card_doc_url_name_1", "");
    }
    if(operationInfo?.existing_info === "1" && typeof(values?.uploadDoc1) === "object"){
      formData.append("upload_card_doc_1", values?.uploadDoc1);
    }
    if(operationInfo?.existing_info === "1" && typeof(values?.uploadDoc1) === "string"){
      formData.append("upload_card_doc_1", "");
    }
    
    
    // for existing Id = 2
    formData.append("labour_issue_date", moment(values?.labour_issue_date).format("YYYY-MM-DD HH:mm:ss") === "Invalid date" ? "" : moment(values?.labour_issue_date).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("labour_card",  values?.labour_card);
    if(operationInfo?.existing_info === "2" && typeof(values?.uploadDoc2) === "string"){
      formData.append("upload_card_doc_url_name_2", values?.uploadDoc2);
     }
     if(operationInfo?.existing_info === "2" && typeof(values?.uploadDoc2) === "object"){
      formData.append("upload_card_doc_url_name_2", "");
    }
    if(operationInfo?.existing_info === "2" && typeof(values?.uploadDoc2) === "object"){
      formData.append("upload_card_doc_2", values?.uploadDoc2);
    }
    if(operationInfo?.existing_info === "2" && typeof(values?.uploadDoc2) === "string"){
      formData.append("upload_card_doc_2", "");
    }
   
    //for exosting Id = 3
    formData.append("investor_client_name", values?.investor_name);
    formData.append("investor_email", values?.investor_email);
    formData.append("investor_mob", values?.investor_mobile); 
    formData.append("investor_nationality", values?.nationality);
    formData.append("investor_passport_no", values?.investor_passport_no);
    formData.append("investor_passport_expiry_date", moment(values?.investor_passport_expiry_date).format("YYYY-MM-DD HH:mm:ss") === "Invalid date" ? "" : moment(values?.investor_passport_expiry_date).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("investor_visa_expiry_date", moment(values?.investor_visa_expiry_date).format("YYYY-MM-DD HH:mm:ss") === "Invalid date" ? "" : moment(values?.investor_visa_expiry_date).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("no_of_share", Number(values?.no_of_shares));

    // for existing Id = 4
    formData.append("employment_emp_name", values?.employment_emp_name);
    formData.append("employment_emp_email", values?.employment_emp_email);
    formData.append("employment_emp_mob", values?.employment_emp_mob);
    formData.append("employment_designation", values?.employment_designation);
    formData.append("basic_salary", values?.basic_salary);
    formData.append("sal_transportation", values?.sal_transportation);
    formData.append("sal_accomodation", values?.sal_accomodation);
    formData.append("sal_overtime", values?.sal_overtime);
    formData.append("employment_labour_card", values?.employment_labour_card);
    formData.append("employment_labour_expiry_date", moment(values?.employment_labour_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.employment_labour_expiry_date).format("YYYY-MM-DD"));
    formData.append("employment_visa_expiry_date", moment(values?.employment_visa_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.employment_visa_expiry_date).format("YYYY-MM-DD"));
    formData.append("employment_passport", values?.employment_labour_card);
    formData.append("employment_passport_expiry_date", moment(values?.employment_passport_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.employment_passport_expiry_date).format("YYYY-MM-DD"));
    formData.append("employment_nationality", values?.employment_nationality);
    formData.append("employment_card_type", values?.employment_card_type);
    formData.append("employment_labour_card_status", Number(values?.employment_labour_card_status));

   // for existing Id = 5
    formData.append("po_box_no", values?.po_box_no);
    formData.append("po_box_expiry_date", moment(values?.po_box_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.po_box_expiry_date).format("YYYY-MM-DD"));

    // for existing Id = 6
    formData.append("trade_mart_no", values?.trade_mart_no);
    formData.append("trade_mart_name", values?.trade_mart_name);
    formData.append("trade_mart_expiry_date", moment(values?.trade_mart_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.trade_mart_expiry_date).format("YYYY-MM-DD") );

    // for existing Id = 7
    formData.append("family_investor_visa", values?.family_investor_visa);
    formData.append("family_relation", values?.family_relation);
    formData.append("family_emp_name", values?.family_emp_name);
    formData.append("family_emp_email", values?.family_emp_email);
    formData.append("family_mob", values?.family_mob);
    formData.append("family_designation", values?.family_designation);
    formData.append("family_labour_card", values?.family_labour_card);
    formData.append("family_labour_expiry_date", moment(values?.family_labour_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.family_labour_expiry_date).format("YYYY-MM-DD"));
    formData.append("family_visa_expiry_date", moment(values?.family_visa_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.family_visa_expiry_date).format("YYYY-MM-DD"));
    formData.append("family_passport", values?.family_passport);
    formData.append("family_passport_expiry_date", moment(values?.family_passport_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.family_passport_expiry_date).format("YYYY-MM-DD"));
    formData.append("family_nationality", values?.family_nationality);
    formData.append("family_card_type", values?.family_card_type);

    // for existing Id = 8
    formData.append("contact_client_name", values?.contact_client_name);
    formData.append("contact_client_designation", values?.contact_client_designation);
    formData.append("contact_email", values?.contact_email);
    formData.append("contact_mob", values?.contact_mob);
    formData.append("contact_nationality", values?.contact_nationality);
    formData.append("contact_passport_no", values?.contact_passport_no);
    formData.append("contact_passport_expiry_date", moment(values?.contact_passport_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.contact_passport_expiry_date).format("YYYY-MM-DD"));
    formData.append("primary", values?.primary);
    formData.append("old_investor_primary", values?.old_investor_primary);
    formData.append("contact_remarks", values?.contact_remarks);
    formData.append("card_status", values?.card_status);

    // for existing Id = 12
    formData.append("e_chanel_issue_date", moment(values?.e_chanel_issue_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.e_chanel_issue_date).format("YYYY-MM-DD"));
    formData.append("e_chanel_expiry_date", moment(values?.e_chanel_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.e_chanel_expiry_date).format("YYYY-MM-DD"));
    if(operationInfo?.existing_info === "12" && typeof(values?.uploadDoc12) === "string"){
      formData.append("upload_card_doc_url_name_12", values?.uploadDoc12);
     }
     if(operationInfo?.existing_info === "12" && typeof(values?.uploadDoc12) === "string"){
      formData.append("upload_card_doc_12", "");
    }
    if(operationInfo?.existing_info === "12" && typeof(values?.uploadDoc12) === "object"){
      formData.append("upload_card_doc_12", values?.uploadDoc12);
    }
    if(operationInfo?.existing_info === "12" && typeof(values?.uploadDoc12) === "object"){
      formData.append("upload_card_doc_url_name_12", "");
    }

     // for existing Id = 13
     formData.append("rera_expiry_date", moment(values?.rera_expiry_date).format("YYYY-MM-DD") === "Invalid date" ? "" : moment(values?.rera_expiry_date).format("YYYY-MM-DD"));
     formData.append("rera_remarks", values?.rera_remarks);
     if(operationInfo?.existing_info === "13" && typeof(values?.uploadDoc13) === "string"){
       formData.append("upload_card_doc_url_name_13", values?.uploadDoc13);
      }
      if(operationInfo?.existing_info === "13" && typeof(values?.uploadDoc13) === "string"){
        formData.append("upload_card_doc_13", "");
      }
     if(operationInfo?.existing_info === "13" && typeof(values?.uploadDoc13) === "object"){
       formData.append("upload_card_doc_13", values?.uploadDoc13);
     }
     if(operationInfo?.existing_info === "13" && typeof(values?.uploadDoc13) === "object"){
      formData.append("upload_card_doc_url_name_13", "");
    }



      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500)
        }
        else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code)
        }
        setIsOpen(true);
        //handleQuotationPromise(data);
        getViewCompanyFamilyApi();
      })
    
    // else {
    //   axios.post(url, formData, { headers: headers }).then((data) => {
    //     setLoading(false);
    //     if (data?.data?.message) {
    //       setMessage(`${data?.data?.message}`);
    //       setSeverity(500)
    //     }
    //     else {
    //       setMessage(data?.data?.data?.msg);
    //       setSeverity(data?.data?.data?.code)
    //     }
    //     setIsOpen(true);
    //     getViewCompanyFamilyApi();
    //   })
    // }
  }

  useEffect(() => {

    setLoading(true);
    getViewCompanyWorkerApi();

  }, [])

  return (
    <>
      <Formik
        initialValues={{
          immigration_issue_date: immigrationIssueDate,
          immigration_expiry_date: immigrationExpiryDate,
          upload_card_doc_url_name_1: "",
          uploadDoc1: operationInfo?.Immigration_Doc_File,
  
          labour_issue_date: labourIssueDate,
          labour_card: operationInfo?.Labour_Card_Number,
          upload_card_doc_url_name_2: "",
          uploadDoc2: operationInfo?.Labour_Doc_File,

          investor_name: operationInfo?.Investor_Client_Name,
          investor_email: operationInfo?.Investor_Email_Id,
          investor_mobile: operationInfo?.Investor_Mobile,
          nationality: operationInfo?.Investor_Nationality,
          investor_passport_expiry_date: passportExpiry,
          investor_visa_expiry_date: visaExpiry,
          no_of_shares: operationInfo?.Investor_Number_Of_Shares,
          investor_passport_no: operationInfo?.Investor_Passport,

          employment_emp_name:  operationInfo?.Employment_Emp_Name,
          employment_emp_email:  operationInfo?.Employment_Emp_Email,
          employment_emp_mob:  operationInfo?.Employment_Emp_Mobile,
          employment_designation:  operationInfo?.Employment_Emp_Designation,
          basic_salary:  operationInfo?.Employment_Emp_Basic_Salary,
          sal_transportation:  operationInfo?.Employment_Emp_Transportaion,
          sal_accomodation:  operationInfo?.Employment_Emp_Accomodation,
          sal_overtime:  operationInfo?.Employment_Emp_OverTime,
          employment_labour_card:  operationInfo?.Employment_Labour_Card,
          employment_labour_expiry_date:  emp_cardExpiry,
          // employment_visa_issue_date:  emp_visaIssue,
          employment_visa_expiry_date:  emp_visaExpiry,
          employment_passport:  operationInfo?.Employment_Passport,
          employment_passport_expiry_date:  emp_passportExpiry,
          employment_nationality:  operationInfo?.Employment_Nationality,
          employment_card_type:  operationInfo?.Employment_Labour_Card_Type,
          employment_labour_card_status:  Number(operationInfo?.Employment_Labour_Card_Status_Id),

          po_box_no: operationInfo?.PO_Box_Number,
          po_box_expiry_date: poBoxExpiryDate,

          trade_mart_no: operationInfo?.Trade_Mark_Number,
          trade_mart_name: operationInfo?.Trade_Mark_Name,
          trade_mart_expiry_date: tradeMartExpiryDate,

          family_investor_visa: operationInfo?.family_investor_visa,
          family_relation: operationInfo?.family_relation,
          family_emp_name: operationInfo?.family_emp_name,
          family_emp_email: operationInfo?.family_emp_email,
          family_mob: operationInfo?.family_mob,
          family_designation: operationInfo?.family_designation,
          family_labour_card: operationInfo?.family_labour_card,
          family_labour_expiry_date: family_cardExpiry,
          family_visa_expiry_date: family_visaExpiry,
          family_passport: operationInfo?.family_passport,
          family_passport_expiry_date: family_PassportExpiry,
          family_nationality: operationInfo?.family_nationality,
          family_card_type: operationInfo?.family_card_type,

          contact_client_name: operationInfo?.Point_Of_Contact_Name,
          contact_client_designation: operationInfo?.contact_client_designation,
          contact_email: operationInfo?.Point_Of_Contact_Email,
          contact_mob: operationInfo?.Point_Of_Contact_Mobile,
          contact_nationality: operationInfo?.Point_Of_Contact_Nationality,
          contact_passport_no: operationInfo?.Point_Of_Contact_Passport_Number,
          contact_passport_expiry_date: contact_PassportExpiry,
          primary: operationInfo?.Investor_Primary_Id.toString(),
          old_investor_primary: "",
          card_status: Number(operationInfo?.Card_Status_Id),
          contact_remarks: operationInfo?.contact_remarks,

          e_chanel_issue_date: e_ChannelIssueDate,
          e_chanel_expiry_date: e_ChannelExpiryDate,
          upload_card_doc_url_name_12: "",
          uploadDoc12: operationInfo?.EChanel_Doc_File,

          rera_expiry_date: reraExpiryDate,
          rera_remarks: operationInfo?.rera_remarks,
          upload_card_doc_url_name_13: "",
          uploadDoc13: operationInfo?.RERA_Doc_File,


        }}
        onSubmit={async (data) => {
          setLoading(true)
          addFamilyInfoPostAPI(data);
          handleModaleClose();
        }}
        validate={(values) => {

          let errors = {};

          // for existing_info = 1
          if(operationInfo?.existing_info === "1")
          {
            if (values.immigration_issue_date === "") {
                errors.immigration_issue_date = "Immigration Issue date is required";
              }
              if (values.immigration_expiry_date === "") {
                errors.immigration_expiry_date = "Immigration expiry date is required";
              }
          }
          // for existing_info = 2
          if(operationInfo?.existing_info === "2")
          {
            if (values.labour_issue_date === "") {
                errors.labour_issue_date = "Labour Issue date is required";
              }
              if (values.labour_card === "") {
                errors.labour_card = "Labour card is required";
              }
          }

          // for existing_info = 3
         if(operationInfo?.existing_info === "3")
         {
            if (values.investor_name === "") {
                errors.investor_name = "Name is required";
              }
              if (values.investor_email === "") {
                errors.investor_email = "Email is required";
              }
              if (values.investor_mobile === "") {
                errors.investor_mobile = "Mobile No is required";
              }
              if (values.nationality === "") {
                errors.nationality = "Nationality is required";
              }
              if (values.investor_passport_expiry_date === "") {
                errors.investor_passport_expiry_date = "Passport expiry date is required";
              }
              if (values.investor_visa_expiry_date === "") {
                errors.investor_visa_expiry_date = "Visa expiry date is required";
              }
              if (values.investor_passport_no === "") {
                errors.investor_passport_no = "Investor passport no is required";
              }
              if (values.no_of_shares > 100 || values.no_of_shares <= 0 ) {
                errors.no_of_shares = "No of shares should be greater than 0 and less than or equal to 100";
              }
              
         }
         // for existing_info = 4
         if(operationInfo?.existing_info === "4")
         {
          if (values.employment_emp_name === "") {
            errors.employment_emp_name = "Name is required";
          }
          if (values.employment_emp_email === "") {
            errors.employment_emp_email = "Email is required";
          }
          if (values.employment_emp_mob === "") {
            errors.employment_emp_mob = "Mobile No is required";
          }
          if (values.employment_designation === "") {
            errors.employment_designation = "Designation is required";
          }
          if (values.basic_salary === "") {
            errors.basic_salary = "Basic salary is required";
          }
          if (values.sal_transportation === "") {
            errors.sal_transportation = "Salary transportation is required";
          }
          if (values.sal_accomodation === "") {
            errors.sal_accomodation = "Salary accomodation is required";
          }
         
          if (values.employment_labour_card === "") {
            errors.employment_labour_card = "Employment Labour card is required";
          }
          if (values.card_status_id === "") {
            errors.card_status_id = "Card status is required";
          }

          if (values.employment_labour_expiry_date === "") {
            errors.employment_labour_expiry_date = "Labour card expiry is required";
          }
         
          if (values.employment_visa_expiry_date === "") {
            errors.employment_visa_expiry_date = "Visa expiry date is required";
          }
          if (values.employment_passport === "") {
            errors.employment_passport = "Passport No is required";
          }
          if (values.employment_passport_expiry_date === "") {
            errors.employment_passport_expiry_date = "Passport expiry date is required";
          }
          if (values.employment_nationality === "") {
            errors.employment_nationality = "Nationality is required";
          }
          if (values.employment_card_type === "") {
            errors.employment_card_type = "Card type is required";
          }
          if (values.employment_labour_card_status === "") {
            errors.employment_labour_card_status = "Labour card status is required";
          }
         }

         // for existing_info = 5
         if(operationInfo?.existing_info === "5")
         {
          if (values.po_box_no === "") {
            errors.po_box_no = "PO Box No is required";
          }
          if (values.po_box_expiry_date === "") {
            errors.po_box_expiry_date = "PO Box expiry date is required";
          }
         }

          // for existing_info = 6
          if(operationInfo?.existing_info === "6")
          {
           if (values.trade_mart_no === "") {
             errors.trade_mart_no = "Trade mark No is required";
           }
           if (values.trade_mart_name === "") {
             errors.trade_mart_name = "Trade mark name is required";
           }
           if (values.trade_mart_expiry_date === "") {
            errors.trade_mart_expiry_date = "Trade mark expiry date is required";
          }
          }
          
           // for existing_info = 7
           if(operationInfo?.existing_info === "7")
           {
            if (values.family_investor_visa === "") {
              errors.family_investor_visa = "Family investor visa is required";
            }
            if (values.family_relation === "") {
              errors.family_relation = "Family relation is required";
            }
            if (values.family_emp_name === "") {
             errors.family_emp_name = "Family name is required";
           }
           if (values.family_passport === "") {
            errors.family_passport = "Family passport is required";
          }
          if (values.family_passport_expiry_date === "") {
           errors.family_passport_expiry_date = "Family passport expiry is required";
         }
         }

          // for existing_info = 8
          if(operationInfo?.existing_info === "8")
          {
           if (values.contact_client_name === "") {
             errors.contact_client_name = "Contact name is required";
           }
           if (values.contact_nationality === "") {
             errors.contact_nationality = "Nationality is required";
           }
          if (values.contact_passport_no === "") {
           errors.contact_passport_no = "Contact passport is required";
         }
         if (values.contact_passport_expiry_date  === "") {
          errors.contact_passport_expiry_date  = "Contact passport expiry is required";
        }
        }

        // for existing_info = 12
        if(operationInfo?.existing_info === "12")
          {
          if (values.e_chanel_issue_date === "") {
            errors.e_chanel_issue_date = "E-Channel issue date is required";
           }
          if (values.e_chanel_expiry_date === "") {
           errors.e_chanel_expiry_date = "E-Channel expiry date is required";
          }
        }
        // for existing_info = 13
        if(operationInfo?.existing_info === "13")
          {
          if (values.rera_expiry_date === "") {
            errors.rera_expiry_date = "Rera expiry is required";
           }
          if (values.rera_remarks === "") {
           errors.rera_remarks = "Rera remarks is required";
          }
        }
    
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div style={{ height: "550px", overflowX: "hidden", padding: "0" }}>
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            {editModalPopper ? "Edit Company Information" : "Edit Company Information"}{``}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>
                        { operationInfo?.existing_info === "1" &&(
                         <Grid container>
                          
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              
                               <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Immigration Issue Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={immigrationIssueDate}
                                      onChange={(date) => {
                                        setImmigrationIssueDate(date);
                                        setFieldValue("immigration_issue_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Expiry Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Immigration Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={immigrationExpiryDate}
                                      onChange={(date) => {
                                        setImmigrationExpiryDate(date);
                                        setFieldValue("immigration_expiry_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Expiry Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                            <Form.Group as={Row} className="mb-1">
                                      <Form.Label column sm="5" className="fc-textarea-label">
                                          Upload Card Docs<span style={{ color: 'red' }}>*</span>
                                      </Form.Label>
                                      <Col sm="7" style={{ padding: '0 12px 0 0' }} >

                                          <div className={classes.businessCardFormGroupModal}>
                                              <div
                                                  className={classes.quotationFormGroupFlexDivModalVT}
                                              >
                                                  <div
                                                      className={classes.quotationSelectContainerModal}
                                                  >
                                                      <div
                                                          className={
                                                              classes.businessCardDemoDataCheckModalUploadFile
                                                          }
                                                      >
                                                          <input
                                                              type="file"
                                                              style={{ display: "none" }}
                                                              onChange={(event) => {
                                                                 
                                                                  setFieldValue(
                                                                      `uploadDoc1`,
                                                                      event.currentTarget.files[0]
                                                                  );
                                                              }}
                                                              // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                              ref={fileInput}
                                                          />
                                                          <button
                                                              style={{
                                                                  padding: '6px 20px',
                                                                  backgroundColor: '#eb6e12',
                                                                  border: '1px solid #d3d3d3',
                                                                  color: '#fff',
                                                                  borderRadius: '5px',
                                                                  cursor: 'pointer',
                                                                  marginRight: "5px"
                                                              }}
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                                              }
                                                              type="button"
                                                              onClick={() => fileInput.current.click()}
                                                          >
                                                              <PermMediaIcon
                                                                  style={{ marginRight: "10px" }}
                                                              />
                                                              Add Media
                                                          </button>

                                                          <small
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileText
                                                              }
                                                          >
                                                              {values.uploadDoc1
                                                                  ? values?.uploadDoc1?.name ||
                                                                  values?.uploadDoc1?.split("/").pop()
                                                                  : "No file chosen"}
                                                          </small>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                          {values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc1 !== "" &&
                                              typeof values.uploadDoc1 ===
                                              "object" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc1?.name
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={URL.createObjectURL(
                                                              values.uploadDoc1
                                                          )}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc1?.name
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc1?.name
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc1 !== "" &&
                                              typeof values.uploadDoc1 ===
                                              "string" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc1
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={values.uploadDoc1}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc1
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc1
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : null}
                                      </Col>
                                  </Form.Group>
                            </Grid>

                          </Grid>
                         ) }
                         { operationInfo?.existing_info === "2" &&(
                         <Grid container>
                          
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              
                               <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Labour Card Issue Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={labourIssueDate}
                                      onChange={(date) => {
                                        setLabourIssueDate(date);
                                        setFieldValue("labour_issue_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Labour issue Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                               <InputText
                                label="Labour Card No."
                                fieldName="labour_card"
                                fieldValue={values?.labour_card}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`labour_card`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                            <Form.Group as={Row} className="mb-1">
                                      <Form.Label column sm="5" className="fc-textarea-label">
                                          Upload Card Docs<span style={{ color: 'red' }}>*</span>
                                      </Form.Label>
                                      <Col sm="7" style={{ padding: '0 12px 0 0' }} >

                                          <div className={classes.businessCardFormGroupModal}>
                                              <div
                                                  className={classes.quotationFormGroupFlexDivModalVT}
                                              >
                                                  <div
                                                      className={classes.quotationSelectContainerModal}
                                                  >
                                                      <div
                                                          className={
                                                              classes.businessCardDemoDataCheckModalUploadFile
                                                          }
                                                      >
                                                          <input
                                                              type="file"
                                                              style={{ display: "none" }}
                                                              onChange={(event) => {
                                                                 
                                                                  setFieldValue(
                                                                      `uploadDoc2`,
                                                                      event.currentTarget.files[0]
                                                                  );
                                                              }}
                                                              // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                              ref={fileInput}
                                                          />
                                                          <button
                                                              style={{
                                                                  padding: '6px 20px',
                                                                  backgroundColor: '#eb6e12',
                                                                  border: '1px solid #d3d3d3',
                                                                  color: '#fff',
                                                                  borderRadius: '5px',
                                                                  cursor: 'pointer',
                                                                  marginRight: "5px"
                                                              }}
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                                              }
                                                              type="button"
                                                              onClick={() => fileInput.current.click()}
                                                          >
                                                              <PermMediaIcon
                                                                  style={{ marginRight: "10px" }}
                                                              />
                                                              Add Media
                                                          </button>

                                                          <small
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileText
                                                              }
                                                          >
                                                              {values.uploadDoc2
                                                                  ? values?.uploadDoc2?.name ||
                                                                  values?.uploadDoc2?.split("/").pop()
                                                                  : "No file chosen"}
                                                          </small>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                          {values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc2 !== "" &&
                                              typeof values.uploadDoc2 ===
                                              "object" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc2?.name
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={URL.createObjectURL(
                                                              values.uploadDoc2
                                                          )}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc2?.name
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc2?.name
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc2 !== "" &&
                                              typeof values.uploadDoc2 ===
                                              "string" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc2
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={values.uploadDoc2}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc2
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc2
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : null}
                                      </Col>
                                  </Form.Group>
                            </Grid>

                          </Grid>
                         ) }
                         { operationInfo?.existing_info === "3" &&(
                         <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                            
                              <InputText
                                label="Investor Name"
                                fieldName="investor_name"
                                fieldValue={values?.investor_name}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_name`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                               <InputText
                                label="Email"
                                fieldName="investor_email"
                                fieldValue={values?.investor_email}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_email`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="Mobile"
                                fieldName="investor_mobile"
                                fieldValue={values?.investor_mobile}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_mobile`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Nationality"
                                fieldOptions={CountryList}
                                fieldValue={values?.nationality}
                                fieldHandleChange={(value) => {
                                  setFieldValue(`nationality`, value.value);
                                  
                                }}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                                <InputText
                                label="Passport No."
                                fieldName="investor_passport_no"
                                fieldValue={values?.investor_passport_no}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`investor_passport_no`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                fieldType={"text"}
                                // errorField={getStyles(errors)}
                                requiredField={true}
                              />
                             
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Passport Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={passportExpiry}
                                      onChange={(date) => {
                                        setPassportExpiry(date);
                                        setFieldValue(
                                          "investor_passport_expiry_date", date);
                                      }}

                                      dateFormat="dd-MM-yyyy"

                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Passport Expiry Date"
                                    />

                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="No. of Shares"
                                fieldName="no_of_shares"
                                fieldValue={values?.no_of_shares}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`no_of_shares`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                                fieldType={"number"}
                              />
                               <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                  Visa Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={visaExpiry}
                                      onChange={(date) => {
                                        setVisaExpiry(date);
                                        setFieldValue("investor_visa_expiry_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Visa Expiry Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                             
                            </Grid>


                          </Grid>
                         ) }
                        { operationInfo?.existing_info === "4" &&(
                         <Grid container>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <InputText
                             label="Employee Name"
                             fieldName="employment_emp_name"
                             fieldValue={values?.employment_emp_name}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                                setFieldValue(`employment_emp_name`, value);
                             }}
                           //  disableField={true}
                           // disabled={true}
                           // classvalue={"textBold"}
                           //  errorField={getStyles(errors)}
                            requiredField={true}
                           />
                           <InputText
                             label="Employee Email"
                             fieldName="employment_emp_email"
                             fieldValue={values?.employment_emp_email}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`employment_emp_email`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >

                           <InputText
                             label="Employee Mobile"
                             fieldName="employment_emp_mob"
                             fieldValue={values?.employment_emp_mob}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`employment_emp_mob`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />

                           <InputText
                             label="Employment Designation"
                             fieldName="employment_designation"
                             fieldValue={values?.employment_designation}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`employment_designation`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>

                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                             <InputText
                             label="Basic Salary"
                             fieldName="basic_salary"
                             fieldValue={values?.basic_salary}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`basic_salary`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />   
                           <InputText
                             label="Salary Transportation"
                             fieldName="sal_transportation"
                             fieldValue={values?.sal_transportation}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`sal_transportation`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />   
                          
                         </Grid>

                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                            <InputText
                             label="Salary Accomodation"
                             fieldName="sal_accomodation"
                             fieldValue={values?.sal_accomodation}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`sal_accomodation`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />  
                            <InputText
                             label="Salary Overtime"
                             fieldName="sal_overtime"
                             fieldValue={values?.sal_overtime}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`sal_overtime`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           /> 
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <InputText
                             label="Labour Card No."
                             fieldName="employment_labour_card"
                             fieldValue={values?.employment_labour_card}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`employment_labour_card`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />
                           <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Labour Card Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={emp_cardExpiry}
                                   onChange={(date) => {
                                     setEmp_CardExpiry(date);
                                     setFieldValue("employment_labour_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"

                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Labour Card Expiry Date"
                                 />

                               </div>
                             </Col>
                           </Form.Group>
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                          
                           <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Visa Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={emp_visaExpiry}
                                   onChange={(date) => {
                                     setEmp_VisaExpiry(date);
                                     setFieldValue("employment_visa_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"

                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Visa Expiry Date"
                                 />

                               </div>
                             </Col>
                           </Form.Group>
                           <InputText
                             label="Passport No."
                             fieldName="employment_passport"
                             fieldValue={values?.employment_passport}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`employment_passport`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                          <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Passport Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={emp_passportExpiry}
                                   onChange={(date) => {
                                     setEmp_PassportExpiry(date);
                                     setFieldValue("employment_passport_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"
                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Passport Expiry Date"
                                 />
                               </div>
                             </Col>
                           </Form.Group>
                           <CustomDropdown
                             label="Nationality"
                             fieldOptions={CountryList}
                             fieldValue={values?.employment_nationality}
                             fieldHandleChange={(value) => {
                               setFieldValue(`employment_nationality`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <CustomDropdown
                             label="Card Type"
                             fieldOptions={LabourCardTypeOptions}
                             fieldValue={values?.employment_card_type}
                             fieldHandleChange={(value) => {
                               setFieldValue(`employment_card_type`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                            <CustomDropdown
                             label="Labour Card Status"
                             fieldOptions={LabourCardStatusOptions}
                             fieldValue={values?.employment_labour_card_status}
                             fieldHandleChange={(value) => {
                               setFieldValue(`employment_labour_card_status`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>
                       
                       </Grid>
                         ) }
                          { operationInfo?.existing_info === "5" &&(
                         <Grid container>
                          
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="PO Box No."
                                fieldName="po_box_no"
                                fieldValue={values?.po_box_no}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`po_box_no`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                                <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                PO Box Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={poBoxExpiryDate}
                                      onChange={(date) => {
                                        setPoBoxExpiryDate(date);
                                        setFieldValue("po_box_expiry_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Labour issue Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                           

                          </Grid>
                         ) }  
                         { operationInfo?.existing_info === "6" &&(
                         <Grid container>
                          
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                                <InputText
                                label="Trade Mark No."
                                fieldName="trade_mart_no"
                                fieldValue={values?.trade_mart_no}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`trade_mart_no`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                               <InputText
                                label="Trade Mark Name"
                                fieldName="trade_mart_name"
                                fieldValue={values?.trade_mart_name}
                                fieldHandleChange={(e) => {
                                  const value = e.target.value
                                  setFieldValue(`trade_mart_name`, value);
                                }}
                                // disableField={true}
                                // disabled={true}
                                // classvalue={"textBold"}
                                //  errorField={getStyles(errors)}
                                requiredField={true}
                              />
                              
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                               <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Trade Mark Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={tradeMartExpiryDate}
                                      onChange={(date) => {
                                        setTradeMartExpiryDate(date);
                                        setFieldValue("trade_mart_expiry_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Labour issue Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                            </Grid>

                          </Grid>
                         ) }
                        { operationInfo?.existing_info === "7" &&(
                         <Grid container>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <InputText
                             label="Family Investor Visa"
                             fieldName="family_investor_visa"
                             fieldValue={values?.family_investor_visa}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                                setFieldValue(`family_investor_visa`, value);
                             }}
                           //  disableField={true}
                           // disabled={true}
                           // classvalue={"textBold"}
                           //  errorField={getStyles(errors)}
                            requiredField={true}
                           />
                           <InputText
                             label="Family Relation "
                             fieldName="family_relation"
                             fieldValue={values?.family_relation}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_relation`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >

                           <InputText
                             label="Family Emp. Name"
                             fieldName="family_emp_name"
                             fieldValue={values?.family_emp_name}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_emp_name`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />

                           <InputText
                             label="Family Emp. Email"
                             fieldName="family_emp_email"
                             fieldValue={values?.family_emp_email}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_emp_email`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>

                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                             <InputText
                             label="Family Mobile"
                             fieldName="family_mob"
                             fieldValue={values?.family_mob}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_mob`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />   
                           <InputText
                             label="Family Designation"
                             fieldName="family_designation"
                             fieldValue={values?.family_designation}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_designation`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />   
                          
                         </Grid>

                    
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <InputText
                             label="Family Card No."
                             fieldName="family_labour_card"
                             fieldValue={values?.family_labour_card}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_labour_card`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />
                           <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Family Card Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={family_cardExpiry}
                                   onChange={(date) => {
                                     setFamily_CardExpiry(date);
                                     setFieldValue("family_labour_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"

                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Family Card Expiry Date"
                                 />

                               </div>
                             </Col>
                           </Form.Group>
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Family Visa Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={family_visaExpiry}
                                   onChange={(date) => {
                                     setFamily_VisaExpiry(date);
                                     setFieldValue("family_visa_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"

                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Family Visa Expiry Date"
                                 />

                               </div>
                             </Col>
                           </Form.Group>
                           <InputText
                             label="Family Passport No."
                             fieldName="family_passport"
                             fieldValue={values?.family_passport}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`family_passport`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                             requiredField={true}
                           />
                          
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                         <CustomDropdown
                             label="Nationality"
                             fieldOptions={CountryList}
                             fieldValue={values?.family_nationality}
                             fieldHandleChange={(value) => {
                               setFieldValue(`family_nationality`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                           <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Family Passport Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={family_PassportExpiry}
                                   onChange={(date) => {
                                     setFamily_PassportExpiry(date);
                                     setFieldValue("family_passport_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"
                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Family Passport Expiry Date"
                                 />

                               </div>
                             </Col>
                           </Form.Group>
                          
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                            <CustomDropdown
                             label="Family Card Type"
                             fieldOptions={LabourCardTypeOptions}
                             fieldValue={values?.family_card_type}
                             fieldHandleChange={(value) => {
                               setFieldValue(`family_card_type`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                         </Grid>
                        
                       
                       </Grid>
                         ) }
                        { operationInfo?.existing_info === "8" &&(
                         <Grid container>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <InputText
                             label="Contact Client Name "
                             fieldName="contact_client_name"
                             fieldValue={values?.contact_client_name}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                                setFieldValue(`contact_client_name`, value);
                             }}
                           //  disableField={true}
                           // disabled={true}
                           // classvalue={"textBold"}
                           //  errorField={getStyles(errors)}
                            requiredField={true}
                           />
                           <InputText
                             label="Contact Client Designation"
                             fieldName="contact_client_designation"
                             fieldValue={values?.contact_client_designation}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`contact_client_designation`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                           //  requiredField={true}
                           />
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >

                           <InputText
                             label="Contact Email"
                             fieldName="contact_email"
                             fieldValue={values?.contact_email}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`contact_email`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //  errorField={getStyles(errors)}
                           //  requiredField={true}
                           />

                           <InputText
                             label="Contact Mobile"
                             fieldName="contact_mob"
                             fieldValue={values?.contact_mob}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`contact_mob`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //fieldType={"text"}
                             // errorField={getStyles(errors)}
                            // requiredField={true}
                           />
                         </Grid>

                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                             <CustomDropdown
                             label="Nationality"
                             fieldOptions={CountryList}
                             fieldValue={values?.contact_nationality}
                             fieldHandleChange={(value) => {
                               setFieldValue(`contact_nationality`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />
                           <InputText
                             label="Passport No"
                             fieldName="contact_passport_no"
                             fieldValue={values?.contact_passport_no}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`contact_passport_no`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           /> 
                         </Grid>

                
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                           <Form.Group as={Row} className="mb-1">
                             <Form.Label column sm="5" className="fc-textarea-label">
                             Contact Passport Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                             </Form.Label>
                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                               <div className="fc-dropdown-container">
                                 <DatePicker
                                   selected={contact_PassportExpiry}
                                   onChange={(date) => {
                                     setContact_PassportExpiry(date);
                                     setFieldValue("contact_passport_expiry_date", date);
                                   }}

                                   dateFormat="dd-MM-yyyy"
                                   // maxDate={addDays(new Date(), 0)}
                                   // minDate={addDays(new Date(), -7)}
                                   placeholderText="Contact Passport Expiry Date"
                                 />

                               </div>
                             </Col>
                           </Form.Group>
                           <CustomDropdown
                             label="Primary Contact"
                             fieldOptions={PrimaryContactOptions}
                             fieldValue={values?.primary}
                             fieldHandleChange={(value) => {
                               setFieldValue(`primary`, value.value);
                             
                             }}
                             // errorField={getStyles(errors)}
                            // requiredField={true}
                           />
                           {/* <InputText
                             label="Primary"
                             fieldName="primary"
                             fieldValue={values?.primary}
                             fieldHandleChange={(e) => {
                               const value = e.target.value
                               setFieldValue(`primary`, value);
                             }}
                             // disableField={true}
                             // disabled={true}
                             // classvalue={"textBold"}
                             //fieldType={"text"}
                             // errorField={getStyles(errors)}
                             requiredField={true}
                           />  */}
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                            <CustomDropdown
                             label="Card Status"
                             fieldOptions={CardStatusOptions}
                             fieldValue={values?.card_status}
                             fieldHandleChange={(value) => {
                               setFieldValue(`card_status`, value.value);
                               
                             }}
                             // errorField={getStyles(errors)}
                            // requiredField={true}
                           />
                         </Grid>
                         <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                         >
                          <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                Contact Remarks
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                <div className={classes.paymentReminderSelectContainerModal} >
                                  <textarea
                                    style={{ width: "100%" }}
                                    name="contact_remarks"
                                    value={values.contact_remarks}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      setFieldValue("contact_remarks", e.target.value)
                                    }}
                                    rows="3" cols="50"
                                  />
                                </div>
                              </Col>
                            </Form.Group> 
                         </Grid>
                        
                       
                       </Grid>
                         ) }
                        { operationInfo?.existing_info === "12" &&(
                         <Grid container>
                          
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              
                               <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                E-Channel Issue Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={e_ChannelIssueDate}
                                      onChange={(date) => {
                                        setE_ChannelIssueDate(date);
                                        setFieldValue("e_chanel_issue_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="E-Channel Issue Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                E-Channel Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={e_ChannelExpiryDate}
                                      onChange={(date) => {
                                        setE_ChannelExpiryDate(date);
                                        setFieldValue("e_chanel_expiry_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="E-Channel Expiry Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                            <Form.Group as={Row} className="mb-1">
                                      <Form.Label column sm="5" className="fc-textarea-label">
                                          Upload Card Docs<span style={{ color: 'red' }}>*</span>
                                      </Form.Label>
                                      <Col sm="7" style={{ padding: '0 12px 0 0' }} >

                                          <div className={classes.businessCardFormGroupModal}>
                                              <div
                                                  className={classes.quotationFormGroupFlexDivModalVT}
                                              >
                                                  <div
                                                      className={classes.quotationSelectContainerModal}
                                                  >
                                                      <div
                                                          className={
                                                              classes.businessCardDemoDataCheckModalUploadFile
                                                          }
                                                      >
                                                          <input
                                                              type="file"
                                                              style={{ display: "none" }}
                                                              onChange={(event) => {
                                                                 
                                                                  setFieldValue(
                                                                      `uploadDoc12`,
                                                                      event.currentTarget.files[0]
                                                                  );
                                                              }}
                                                              // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                              ref={fileInput}
                                                          />
                                                          <button
                                                              style={{
                                                                  padding: '6px 20px',
                                                                  backgroundColor: '#eb6e12',
                                                                  border: '1px solid #d3d3d3',
                                                                  color: '#fff',
                                                                  borderRadius: '5px',
                                                                  cursor: 'pointer',
                                                                  marginRight: "5px"
                                                              }}
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                                              }
                                                              type="button"
                                                              onClick={() => fileInput.current.click()}
                                                          >
                                                              <PermMediaIcon
                                                                  style={{ marginRight: "10px" }}
                                                              />
                                                              Add Media
                                                          </button>

                                                          <small
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileText
                                                              }
                                                          >
                                                              {values.uploadDoc12
                                                                  ? values?.uploadDoc12?.name ||
                                                                  values?.uploadDoc12?.split("/").pop()
                                                                  : "No file chosen"}
                                                          </small>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                          {values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc12 !== "" &&
                                              typeof values.uploadDoc12 ===
                                              "object" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc12?.name
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={URL.createObjectURL(
                                                              values.uploadDoc12
                                                          )}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc12?.name
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc12?.name
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc12 !== "" &&
                                              typeof values.uploadDoc12 ===
                                              "string" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc12
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={values.uploadDoc12}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc12
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc12
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : null}
                                      </Col>
                                  </Form.Group>
                            </Grid>

                          </Grid>
                         ) }
                        { operationInfo?.existing_info === "13" &&(
                         <Grid container>
                          
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              
                               <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm="5" className="fc-textarea-label">
                                Rera Card Expiry Date <span style={{color: "red", fontSize: "10px"}}>*</span>
                                </Form.Label>
                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                  <div className="fc-dropdown-container">
                                    <DatePicker
                                      selected={reraExpiryDate}
                                      onChange={(date) => {
                                        setReraExpiryDate(date);
                                        setFieldValue("rera_expiry_date", date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      // maxDate={addDays(new Date(), 0)}
                                      // minDate={addDays(new Date(), -7)}
                                      placeholderText="Rera Card Expiry Date"
                                    />
                                  </div>
                                </Col>
                              </Form.Group>
                             
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >
                              <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                Rera Remarks
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                <div className={classes.paymentReminderSelectContainerModal} >
                                  <textarea
                                    style={{ width: "100%" }}
                                    name="rera_remarks"
                                    value={values.rera_remarks}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const { value } = e.target;
                                      setFieldValue("rera_remarks", e.target.value)
                                    }}
                                    rows="3" cols="50"
                                  />
                                </div>
                              </Col>
                            </Form.Group> 
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              className={classes.quotationInnerGridRight}
                            >

                            <Form.Group as={Row} className="mb-1">
                                      <Form.Label column sm="5" className="fc-textarea-label">
                                          Upload Card Docs<span style={{ color: 'red' }}>*</span>
                                      </Form.Label>
                                      <Col sm="7" style={{ padding: '0 12px 0 0' }} >

                                          <div className={classes.businessCardFormGroupModal}>
                                              <div
                                                  className={classes.quotationFormGroupFlexDivModalVT}
                                              >
                                                  <div
                                                      className={classes.quotationSelectContainerModal}
                                                  >
                                                      <div
                                                          className={
                                                              classes.businessCardDemoDataCheckModalUploadFile
                                                          }
                                                      >
                                                          <input
                                                              type="file"
                                                              style={{ display: "none" }}
                                                              onChange={(event) => {
                                                                 
                                                                  setFieldValue(
                                                                      `uploadDoc13`,
                                                                      event.currentTarget.files[0]
                                                                  );
                                                              }}
                                                              // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                              ref={fileInput}
                                                          />
                                                          <button
                                                              style={{
                                                                  padding: '6px 20px',
                                                                  backgroundColor: '#eb6e12',
                                                                  border: '1px solid #d3d3d3',
                                                                  color: '#fff',
                                                                  borderRadius: '5px',
                                                                  cursor: 'pointer',
                                                                  marginRight: "5px"
                                                              }}
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileBtn
                                                              }
                                                              type="button"
                                                              onClick={() => fileInput.current.click()}
                                                          >
                                                              <PermMediaIcon
                                                                  style={{ marginRight: "10px" }}
                                                              />
                                                              Add Media
                                                          </button>

                                                          <small
                                                              className={
                                                                  classes.businessCardDemoDataCheckModalUploadFileText
                                                              }
                                                          >
                                                              {values.uploadDoc13
                                                                  ? values?.uploadDoc13?.name ||
                                                                  values?.uploadDoc13?.split("/").pop()
                                                                  : "No file chosen"}
                                                          </small>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                          {values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc13 !== "" &&
                                              typeof values.uploadDoc13 ===
                                              "object" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc13?.name
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={URL.createObjectURL(
                                                              values.uploadDoc13
                                                          )}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc13?.name
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc13?.name
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : values.uploadDocument
                                              ?.length !== 0 &&
                                              values.uploadDoc13 !== "" &&
                                              typeof values.uploadDoc13 ===
                                              "string" ? (
                                              [
                                                  "png",
                                                  "jpg",
                                                  "JPEG",
                                                  "jpeg",
                                              ].includes(
                                                  values.uploadDoc13
                                                      ?.split(".")
                                                      .pop()
                                              ) ? (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".2rem",
                                                          marginTop: "5px",
                                                      }}
                                                  >
                                                      <img
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              fileInput.current[
                                                                  0
                                                              ]?.current.click();
                                                          }}
                                                          style={{
                                                              width: "98%",
                                                              height: "100px",
                                                          }}
                                                          src={values.uploadDoc13}
                                                          alt={"image"}
                                                      />
                                                  </div>
                                              ) : (
                                                  <div
                                                      style={{
                                                          display: "flex",
                                                          justifyContent:
                                                              "center",
                                                          marginBottom: ".5rem",
                                                          marginTop: ".5rem",
                                                          alignItems: "center",
                                                          width: "50px",
                                                          marginRight: "2rem",
                                                      }}
                                                  >
                                                      {values.uploadDoc13
                                                          ?.split(".")
                                                          .pop() === "docx" ? (
                                                          <>
                                                              <RiFileWord2Fill
                                                                  style={{
                                                                      color: "#2b579a",
                                                                      fontSize: "36px",
                                                                      marginRight:
                                                                          ".4rem",
                                                                  }}
                                                              />
                                                          </>
                                                      ) : values.uploadDoc13
                                                          ?.split(".")
                                                          .pop() === "pdf" ? (
                                                          <BsFilePdfFill
                                                              style={{
                                                                  color: "#ff0000",
                                                                  fontSize: "36px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      ) : (
                                                          <CloudUploadIcon
                                                              style={{
                                                                  color: "green",
                                                                  fontSize: "32px",
                                                                  marginRight:
                                                                      ".4rem",
                                                              }}
                                                          />
                                                      )}
                                                  </div>
                                              )
                                          ) : null}
                                      </Col>
                                  </Form.Group>
                            </Grid>

                          </Grid>
                         ) }
                        </div>
                        <br />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal} >

                      <BootstrapTooltip arrow={true}
                        disableFocusListener
                        title={
                          errors.immigration_issue_date === undefined
                          && errors.immigration_expiry_date === undefined 

                          && errors.labour_issue_date === undefined 
                          && errors.labour_card === undefined
                          
                          && errors.investor_name === undefined
                            && errors.investor_email === undefined
                            && errors.investor_mobile === undefined
                            && errors.nationality === undefined
                            && errors.investor_passport_no === undefined
                            && errors.investor_passport_expiry_date === undefined
                            && errors.investor_visa_expiry_date === undefined
                            && errors.no_of_shares === undefined

                            && errors.employment_emp_name === undefined
                            && errors.employment_emp_email === undefined
                            && errors.employment_emp_mob === undefined
                            && errors.employment_designation === undefined
                            && errors.basic_salary === undefined
                            && errors.sal_transportation === undefined
                            && errors.sal_accomodation === undefined
                            && errors.employment_labour_card === undefined
                            && errors.employment_labour_expiry_date === undefined
                            && errors.employment_visa_expiry_date === undefined
                            && errors.employment_passport === undefined
                            && errors.employment_passport_expiry_date === undefined
                            && errors.employment_nationality === undefined
                            && errors.employment_card_type === undefined
                            && errors.employment_labour_card_status === undefined

                            && errors.po_box_no === undefined
                            && errors.po_box_expiry_date === undefined

                            && errors.trade_mart_no === undefined
                            && errors.trade_mart_name === undefined
                            && errors.trade_mart_expiry_date === undefined

                            && errors.family_investor_visa === undefined
                            && errors.family_relation === undefined
                            && errors.family_emp_name === undefined
                            && errors.family_passport === undefined
                            && errors.family_passport_expiry_date === undefined

                            && errors.contact_client_name === undefined
                            && errors.contact_nationality === undefined
                            && errors.contact_passport_no === undefined
                            && errors.contact_passport_expiry_date === undefined

                            && errors.e_chanel_issue_date === undefined
                            && errors.e_chanel_expiry_date === undefined

                            && errors.rera_expiry_date === undefined
                            && errors.rera_remarks === undefined


                            ? "" :
                            `
                          ${errors.immigration_issue_date !== undefined ? `${errors.immigration_issue_date},` : ""}
                          ${errors.immigration_expiry_date !== undefined ? `${errors.immigration_expiry_date},` : ""}

                          ${errors.labour_issue_date !== undefined ? `${errors.labour_issue_date},` : ""}
                          ${errors.labour_card !== undefined ? `${errors.labour_card},` : ""}

                          ${errors.investor_name !== undefined ? `${errors.investor_name},` : ""}
                          ${errors.investor_email !== undefined ? `${errors.investor_email},` : ""}
                          ${errors.investor_mobile !== undefined ? `${errors.investor_mobile},` : ""}
                          ${errors.nationality !== undefined ? `${errors.nationality},` : ""}
                          ${errors.investor_passport_expiry_date !== undefined ? `${errors.investor_passport_expiry_date},` : ""}
                          ${errors.investor_visa_expiry_date !== undefined ? `${errors.investor_visa_expiry_date},` : ""}
                          ${errors.investor_passport_no !== undefined ? `${errors.investor_passport_no},` : ""}
                          ${errors.no_of_shares !== undefined ? `${errors.no_of_shares},` : ""}

                          ${errors.employment_emp_name !== undefined ? `${errors.employment_emp_name},` : ""}
                          ${errors.employment_emp_email !== undefined ? `${errors.employment_emp_email},` : ""}
                          ${errors.employment_emp_mob !== undefined ? `${errors.employment_emp_mob},` : ""}
                          ${errors.employment_designation !== undefined ? `${errors.employment_designation},` : ""}
                          ${errors.basic_salary !== undefined ? `${errors.basic_salary},` : ""}
                          ${errors.sal_transportation !== undefined ? `${errors.sal_transportation},` : ""}
                          ${errors.sal_accomodation !== undefined ? `${errors.sal_accomodation},` : ""}
                          ${errors.employment_labour_card !== undefined ? `${errors.employment_labour_card},` : ""}
                          ${errors.employment_labour_expiry_date !== undefined ? `${errors.employment_labour_expiry_date},` : ""}
                          ${errors.employment_visa_expiry_date !== undefined ? `${errors.employment_visa_expiry_date},` : ""}
                          ${errors.employment_passport !== undefined ? `${errors.employment_passport},` : ""}
                          ${errors.employment_passport_expiry_date !== undefined ? `${errors.employment_passport_expiry_date},` : ""}
                          ${errors.employment_nationality !== undefined ? `${errors.employment_nationality},` : ""}
                          ${errors.employment_card_type !== undefined ? `${errors.employment_card_type},` : ""}
                          ${errors.employment_labour_card_status !== undefined ? `${errors.employment_labour_card_status},` : ""}

                          ${errors.po_box_no !== undefined ? `${errors.po_box_no},` : ""}
                          ${errors.po_box_expiry_date !== undefined ? `${errors.po_box_expiry_date},` : ""}

                          ${errors.trade_mart_no !== undefined ? `${errors.trade_mart_no},` : ""}
                          ${errors.trade_mart_name !== undefined ? `${errors.trade_mart_name},` : ""}
                          ${errors.trade_mart_expiry_date !== undefined ? `${errors.trade_mart_expiry_date},` : ""}

                          ${errors.family_investor_visa !== undefined ? `${errors.family_investor_visa},` : ""}
                          ${errors.family_relation !== undefined ? `${errors.family_relation},` : ""}
                          ${errors.family_emp_name !== undefined ? `${errors.family_emp_name},` : ""}
                          ${errors.family_passport !== undefined ? `${errors.family_passport},` : ""}
                          ${errors.family_passport_expiry_date !== undefined ? `${errors.family_passport_expiry_date},` : ""}

                          ${errors.contact_client_name !== undefined ? `${errors.contact_client_name},` : ""}
                          ${errors.contact_nationality !== undefined ? `${errors.contact_nationality},` : ""}
                          ${errors.contact_passport_no !== undefined ? `${errors.contact_passport_no},` : ""}
                          ${errors.contact_passport_expiry_date !== undefined ? `${errors.contact_passport_expiry_date},` : ""}

                          ${errors.e_chanel_issue_date !== undefined ? `${errors.e_chanel_issue_date},` : ""}
                          ${errors.e_chanel_expiry_date !== undefined ? `${errors.e_chanel_expiry_date},` : ""}

                          ${errors.rera_expiry_date !== undefined ? `${errors.rera_expiry_date},` : ""}
                          ${errors.rera_remarks !== undefined ? `${errors.rera_remarks},` : ""}

                      `
                        }
                      >

                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{ backgroundColor: "#eb6e12" }}
                          onClick={handleSubmit}
                        >
                          {"Save"}
                        </Button>
                      </BootstrapTooltip>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>

                </div>
              </Box>

            </form>
          );
        }}
      </Formik>

    </>
  );
};

export default withStyles(ModalPopperFormStyles)(EditCompanyInfoModalForm);
