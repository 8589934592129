import React, { useState, useContext, useEffect, useRef } from "react";
import { SupportDataContext } from "./context";

import moment from "moment";
import axios from "axios";
import { getLocalData, headers } from "../services/services";


const OperationProvider = ({children}) => {

    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");
    const [modalOpen, setModalOpen] = useState(false);
    const [buttonText, setButtonText] = useState("");
    const [operationInfo, setOperationInfo] = useState("");
    const [modalTable, setModalTable] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState(0);
    const [followUp, setFollowUp] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [expirToken, setExpirToken] = useState(false);
    
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [editModalPopper, setEditModalPopper] = useState(false);
    const [viewFollowup, setViewFollowUp] = useState([]);
    const [open, setOpen] = useState(false);
    const [acceptAccountArray, setAcceptAccountArray] = useState([]);
    const [orderData, setOrderData] = useState(null);
    const [commentData, setCommentData] = useState(null);
    const [viewComment, setViewComment] = useState([]);
    const [freezeLogData, setFreezeLogData] = useState(null);
    const [requestOrderCompletedData, setRequestOrderCompletedData] = useState(null);
    const [myList, setMyOrderListing] = useState(null);
    const [selected, setSelected] = useState([]);
    const [requistionListing, setRequistionListing] = useState(null);
    const [viewRequistion, setViewRequistion] = useState(null);

    const [requisitionParamsData, setrequisitionParamsData] = useState([]);
    const [orderRequisitionList, setOrderRequisitionList] = useState([]);
    const [zohoBankAccountList, setZohoBankAccountList] = useState([]);
    const [zohoBankAccountNameList, setZohoBankAccountNameList] = useState([]);
    const [zohoBalance,setZohoBalance] = useState();
    const [requisitionData, setRequisitionData] = useState([]);
    const [voucherNo, setVoucherNo] = useState();
    const [refundRequisitionParams, setRefundRequisitionParams] = useState();
    const [docTypeList, setDocTypeList] = useState([]);
    const [approvedOrderFormTableData, setApprovedOrderFormTableData] = useState([]);
    const [approvedCompanyOrderFormTableData, setApprovedCompanyOrderFormTableData] = useState([]);
    const [serviceAgreementListingTableData, setServiceAgreementListingTableData] = useState([]);
    const [subleadOrderFormListingTableData, setSubleadOrderFormListingTableData] = useState([]);
    const [companyListViewTableData, setCompanyListViewTableData] = useState([]);
    const [getPropertyFormData, setGetPropertyFormData] = useState([]);
    const [viewUpdateResale, setViewUpdateResale] = useState(null);
    const [getUpdatePropertyDetailList, setGetUpdatePropertyDetailList] = useState([]);
    const [getDeveloperPropertyList, setGetDeveloperPropertyList] = useState([]);
    const [getAddDeveloperFormData, setGetAddDeveloperFormData] = useState([]);
    const [viewDeveloper, setViewDeveloper] = useState(null);
    const [editDeveloperData, setEditDeveloperData] = useState([]);
    const [rentalPropertyListData, setRentalPropertyListData] = useState([]);
    const [rentalPropertyFormData, setRentalPropertyFormData] = useState([]);
    const [myRentalList, setmyRentalList] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [getAriaId, setGetAriaId] = useState(0);
    const [subPropertyType2, setSubPropertyType2] = useState(0);
    const [requestionProList, setRequestionPROList] = useState(null);
    const [proTaskList, setProTaskList] = useState(null);
    const [typistTaskList, setTypistTaskList] = useState(null);
    const [salesInfo, setSalesInfo] = useState([]);
    const [editForm, setEditForm] = useState(false);
    const [addNewLeadListing, setAddNewLeadListing] = useState(null);
    const [clientStatusListing, setClientStatusListing] = useState(null);
    const [orderNoFutureAlertLisitng, setOrderNoFutureAlertLisitng] = useState(null);
    const [freezesOrderNoFutureAlertLisitng, setFreezesOrderNoFutureAlertLisitng] = useState(null);
    const [rowData, setRowData] = useState();
    const [getCompanyIdAdminComplaintFormData, setCompanyIdAdminComplaintFormData] = useState(editModalPopper? operationInfo?.company_id : 0);
    const [templateOperationInfo, setTemplateOperationInfo] = useState('');
    const [isCompleteOrderBtnDisable, setIsCompleteOrderBtnDisable] = useState('');
    const [OrderSOWData, setOrderSOWData] = useState([]);
    const [tabValue, setTabValue] = useState("");
    const [start_Date, setStart_Date] = useState();
    const [end_Date, setEnd_Date] = useState();
    const [complianceBadges, setComplianceBadges] = useState([]);
    const [getParamsData, setParamsData] = useState("");





    const handleChangeTab = (newValue) => {
      setValue(newValue);
    };

    const handleModalTableOpen = (item, row) => {
      setModalTable(true);
    };

    const handleModalTableClose = () => {
       setModalTable(false);
       setRequistionListing([]);
       setOrderSOWData([])
    };
    
    const handleModalOpen = () => {
      setModalOpen(true);
    };
    
    const handleModaleClose = () => {
      setModalOpen(false);
    };
    const handleClickSnack = () => {
      setOpen(true);
    };
    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };
    const callDueDate = () => {
      const currentDate = new Date().toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const date = currentDate.split("/");
      const dueDate = `${date[2]}-${date[1]}-${date[0]}`;
      return dueDate;
    };
    const fetchListingDataFromApi = async() => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      //setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/orderlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setMyOrderListing(res?.data?.results?.Support_Order_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const getFollowUpTableAndDropdown = async (data) => {
      const id = operationInfo?.Order_No;
      await fetch(
        `https://flyingcolour.online/fcapp/support_api/orderfollowupsview/?factory_id=${factory_id}&order_no=${id}&emp_id=${emp_id}&user_type=${user_type}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (
            data.data !== undefined
          ) {
            return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`)
          } else if (data.detail !== undefined) {
            return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`)
          } else {
            return setFollowUp(data?.results)
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            return setErrorAlert(true), setError(`${error.response?.statusText} ${error.response?.status}-${error.response?.data?.detail}${" "}${"Please Login Again!!"}`)
          } else {
            console.error("Error from Follow up: ", error);
          }
        });
    };

const[alertDate, setAlertDate] = useState()
    const getOrderData = async (date) => {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      await fetch(
        `https://flyingcolour.online/fcapp/support_api/orderfollowupalertdetailsview/?emp_id=${empId}&user_type=${userTypeInf}&due_date=${date}&factory_id=${factory_ids}`,
        {
          method: "GET",
          headers: headers(_token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setOrderData(data?.results?.Order_Followup_Alerts_List);
        })
        .catch((error) => {
          console.error("Error from today alert:", error);
        });
    };

    const getCommentTableListingAPI = async() => {
      const id = operationInfo?.Order_No;
      await fetch(
        `https://flyingcolour.online/fcapp/support_api/ordercommentsview/?factory_id=${factory_id}&order_no=${id}&emp_id=${emp_id}&user_type=${user_type}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (
            data.data !== undefined
          ) {
            return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`)
          } else if (data.detail !== undefined) {
            return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`)
          } else {
            return setCommentData(data?.results?.Order_Followup_List)
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            return setErrorAlert(true), setError(`${error.response?.statusText} ${error.response?.status}-${error.response?.data?.detail}${" "}${"Please Login Again!!"}`)
          } else {
            console.error("Error from Follow up: ", error);
          }
        });
    }

    const getFreezeLogTableData = async() => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
      const id = operationInfo?.Order_Id;
      await fetch(
        `https://flyingcolour.online/fcapp/support_api/freezedorderloglistview/?factory_id=${factory_id}&order_id=${id}&emp_id=${emp_id}&user_type=${user_type}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFreezeLogData(data?.results?.Freezed_Order_Log_List)
        })
    }

    const getOrderCompletedTableData = async() => {
      setRequestOrderCompletedData(null);
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
      const id = operationInfo?.Order_Id;
      await fetch(
        `https://flyingcolour.online/fcapp/support_api/supportordercompletedloglistview/?factory_id=${factory_id}&order_id=${id}&emp_id=${emp_id}&user_type=${user_type}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setRequestOrderCompletedData(data?.results)
        })   
    }
    var neObjArr = [];
    const getBankAccountList = async() => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
  
      //setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/zohobankaccountlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
       
        //setZohoBankAccountList(res?.data.results.bankAccount_List);
        const obj = res?.data.results.bankAccount_List;
        setZohoBankAccountNameList(res?.data.results.bankAccount_List);
        neObjArr = obj.map(item => item.account_id);
        setZohoBankAccountList(neObjArr)
                // for(let i = 0; i < obj.length; i++){
        //   const newArr = obj[i].account_id;
        //   setZohoBankAccountList(newArr);
    
        //    }
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const getRequistionListingDataFromApi = async() => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const order_no = operationInfo?.Order_No || operationInfo?.order_id ;
      //setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/orderwiserequisitionlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${order_no}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setRequistionListing(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const getSettlementView = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
      const company_id = refundRequisitionParams.req_company_id;
  
      //setLoading(true);
      try {
  
        const url = `https://flyingcolour.online/fcapp/support_api/requisitionsettlementview/?factory_id=${factory_id}&user_type=${user_type}&emp_id=${emp_id}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setDocTypeList(res.data.results.Document_Type_List);
        // setZohoAccessToken(res?.data.results.access_token);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const getPropertyFormDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      
      //setLoading(true);
      //https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailForm/?flagAddEdit=1&areaId=163&propertyTypeId=2
      //{data === undefined ? "" : `&serviceAgreementId=${data.SA_ID}
      try {
        const url = editModalPopper ?  
        `https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailForm/?flagAddEdit=2&areaId=${getAriaId}&propertyTypeId=${subPropertyType2}&resaleId=${editModalPopper ? viewUpdateResale.update_id: ""}`
        :
         `https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailForm/?flagAddEdit=1&areaId=${getAriaId}&propertyTypeId=${subPropertyType2}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setGetPropertyFormData(res?.data?.results);
        
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    
    const getetUpdatePropertyDetailListTableData = async() => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
      const inq_id = operationInfo?.Inquiry_Id.replace("FC", "");

  
      //https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailList/?factory_id=1&emp_id=0&user_type=REAL ESTATE INVENTORY&property_id=0&inquiry_id=0
      
      await fetch(
        `https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&property_id=0&inquiry_id=${inq_id}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setGetUpdatePropertyDetailList(data?.data)
        })   
    }

    const getAddDeveloperFormDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
     
     // https://flyingcolour.online/fcapp/real_estate_inventory/AddDeveloperPropertyDetailForm/?flagAddEdit=1&areaId=163&propertyTypeId=2
      //setLoading(true);
          
      try {

        const url = editModalPopper ?  
        `https://flyingcolour.online/fcapp/real_estate_inventory/AddDeveloperPropertyDetailForm/?flagAddEdit=2&areaId=${getAriaId}&propertyTypeId=${subPropertyType2}&resaleId=${editModalPopper ? viewDeveloper?.developerPropertyId: ""}`
        :
         `https://flyingcolour.online/fcapp/real_estate_inventory/AddDeveloperPropertyDetailForm/?flagAddEdit=1&areaId=${getAriaId}&propertyTypeId=${subPropertyType2}`;
        
        //const url = `https://flyingcolour.online/fcapp/real_estate_inventory/AddDeveloperPropertyDetailForm/?flagAddEdit=${editModalPopper? "2": "1"}&areaId=163&propertyTypeId=2`;
        
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setGetAddDeveloperFormData(res?.data?.results);
        
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const getEditDeveloperData = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
     
     // https://flyingcolour.online/fcapp/real_estate_inventory/AddDeveloperPropertyDetailForm/?flagAddEdit=1&areaId=163&propertyTypeId=2
     // setLoading(true);
          
      try {
        const url = `https://flyingcolour.online/fcapp/real_estate_inventory/AddDeveloperPropertyDetailForm/?flagAddEdit=2&areaId=${data.area}&developerPropertyId=${data.developerPropertyId}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setEditDeveloperData(res?.data?.results);
        
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const getDeveoperPropertyListTableData = async() => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
      const id = operationInfo?.Inquiry_Id;

      //https://flyingcolour.online/fcapp/real_estate_inventory/DeveloperPropertyList/?emp_id=678&user_type=REAL%20ESTATE%20INVENTORY&factory_id=1
      
      await fetch(
        `https://flyingcolour.online/fcapp/real_estate_inventory/DeveloperPropertyList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setGetDeveloperPropertyList(data?.data)
        })   
    }
    const rentalPropertyListTableData = async() => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const factory_id = localStorage.getItem("factory_id");
      const emp_id = localStorage.getItem("emp_id");
      const Inq_id = operationInfo?.Inquiry_Id.replace("FC", "");


      // https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailForRentalList/?factory_id=1&emp_id=0&user_type=REAL%20ESTATE%20INVENTORY&property_id=0&inquiry_id=0      
      await fetch(
        `https://flyingcolour.online/fcapp/real_estate_inventory/UpdatePropertyDetailForRentalList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&property_id=0&inquiry_id=${Inq_id}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setRentalPropertyListData(data?.data)
        })   
    }
      const rentalPropertyListFormDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
     
      //setLoading(true);
          
      try {
        const url = `https://flyingcolour.online/fcapp/real_estate_inventory/UpdateRentalPropertyDetailForm/?flagAddEdit=1&areaId=163&propertyTypeId=2`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setRentalPropertyFormData(res?.data?.results);
        
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const rentalListingDataFromApi = async() => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      //setLoading(true);
      // https://flyingcolour.online/fcapp/real_estate_inventory/inquiry/?factory_id=1&emp_id=0&user_type=REAL%20ESTATE%20INVENTORY&property_enquiry_type=4&enquiry_id=0
      try {
        const url = `https://flyingcolour.online/fcapp/real_estate_inventory/inquiry/?emp_id=${emp_id}&factory_id=${1}&user_type=${user_type}&property_enquiry_type=${4}&enquiry_id=0`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
       
        setmyRentalList(res.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    } 

    const fetchRequestionProScheduleFromApi = async(reqFromDate, reqToDate) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/supportRequisitionList/?empId=${emp_id}&userType=${user_type}&loginFlag=1&fromDate=${reqFromDate}&toDate=${reqToDate}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        localStorage.setItem('req_pro_schedule', JSON.stringify(res?.data?.results?.Requisition_List))
        setRequestionPROList(res?.data?.results?.Requisition_List);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

      const fetchProTaskListFromApi = async(proId, reqFromDate, reqToDate, searchFor) => {
      const __token = localStorage.getItem("token");
  
      setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/getProTaskDetails/?Pro_Ids=${proId}&requisition_Date_From=${reqFromDate}&requisition_Date_To=${reqToDate}&search_for=${searchFor === 2 ? "Appointment_Date" : "Requisition_Date"}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setProTaskList(res?.data?.results);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const fetchTypistTaskListFromApi = async(typistId, reqFromDate, reqToDate) => {
      const __token = localStorage.getItem("token");
      setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/getTypistTaskDetails/?Typist_Ids=${typistId}&requisition_Date_From=${reqFromDate}&requisition_Date_To=${reqToDate}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setTypistTaskList(res?.data?.results);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const [subLeadListData, setSubLeadListDatat] = useState(null);
    const fetchSubLeadListDataFromApi = async(typistId, todayDate) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      //setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/SubLeadList/?created_by=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setSubLeadListDatat(res?.data?.Data?.Sub_Lead_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const [subleadservicesList, getSubleadservicesList] = useState([]);
    const getSubleadList = async (row, data) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
     // setLoader(true);
      //   `https://flyingcolour.online/fcapp/docprocessing_api/SubLeadView/?emp_id=${emp_id}&user_type=${userTypeInfo}`,
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/SubLeadView/?emp_id=110&user_type=Support&sub_lead_id=0`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        getSubleadservicesList(res?.data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [compalintListData, setCompalintListData] = useState([]);
    const getCompalintListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
     // setLoader(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/?emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setCompalintListData(res?.data?.Data?.List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
   

    const getAddNewLeadViewListingApi = async (typeOfCompany) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
    //  setLoader(true);
      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/ListInquiryInDocprocessing/?emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setAddNewLeadListing(res?.data?.Data?.Inquiry_List);
        // setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [requisitionLogData, setRequisitionLogData] = useState([]);
    const getRequisitionLogListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const req_id = operationInfo.requisition_id;
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/support_api/requisitionloglist/?factory_id=1&emp_id=58&user_type=Support&requisition_id=58243
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/requisitionloglist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&requisition_id=${req_id}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setRequisitionLogData(res?.data?.results?.Requisition_Log_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [orderListgReqData, setOrderListgReqData] = useState([]);
    const getOrderListgRequisitionDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const order_no = operationInfo.order_id || operationInfo?.Order_No;
     
     // setLoader(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/requisition_order_details/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${order_no}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setOrderListgReqData(res?.data?.results?.Requisition_Order_Details);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [orderListgData, setOrderListgData] = useState([]);
    const getOrderListgDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const order_no = operationInfo.order_id || operationInfo?.Order_No;
   
     // setLoader(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/orderdetails/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${order_no}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setOrderListgData(res?.data?.results?.Order_Details);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const getClientStatusListingDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/supportclientrelatedStatusList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        localStorage.setItem('client_status', JSON.stringify(res?.data?.Data?.List))
        setClientStatusListing(res?.data?.Data?.List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const getOrderNoFutureAlertsListingDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/orderwithnofuturealertslistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setOrderNoFutureAlertLisitng(res?.data?.results?.Support_Order_With_No_Future_Alerts_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const getFreezesOrderNoFutureAlertsListingDataApi = async (isAdmin) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/freezedorderwithnofuturealertslistview/?factory_id=${factory_id}&emp_id=${isAdmin ? 0 : emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setFreezesOrderNoFutureAlertLisitng(res?.data?.results?.Freezed_Order_With_No_Future_Alerts_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [adminTodayAlertData, setAdminTodayAlertData] = useState(null);
    const getAdminTodayAlertData = async (date) => {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");

      await fetch(
        `https://flyingcolour.online/fcapp/docprocessing_api/ccalertdetaillist/?emp_id=${emp_id}&user_type=${userTypeInf}&today_order_alert_flag=1&due_date=${date}`,
        {
          method: "GET",
          headers: headers(_token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setAdminTodayAlertData(data?.Data?.Alert_Detail_List);
        })
        .catch((error) => {
          console.error("Error from today alert:", error);
        });
    };

    const [freezeOrderList, setFreezeOrderList] = useState(null);
    const getFreezeOrderListApi = async () => {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      //  https://flyingcolour.online/fcapp/docprocessing_api/freezeorderloglist/?order_id=10642

      await fetch(
        `https://flyingcolour.online/fcapp/docprocessing_api/freezeorderloglist/?order_id=${operationInfo.Order_Id}`,
        {
          method: "GET",
          headers: headers(_token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFreezeOrderList(data?.Data?.Freeze_Order_Log_List);
        })
        .catch((error) => {
          console.error("Error from today alert:", error);
        });
    };

    const [filterOrders, setFilterOrders] = useState();
    const [completeOrderList, setCompleteOrderList] = useState(null);
    const getCompleteOrderListApi = async () => {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      //  https://flyingcolour.online/fcapp/docprocessing_api/orderloglist/

      await fetch(
        `https://flyingcolour.online/fcapp/docprocessing_api/orderloglist/?order_id=${operationInfo.Order_Id}&flag=1`,
        {
          method: "GET",
          headers: headers(_token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setCompleteOrderList(data?.Data?.Order_Log_List);
        })
        .catch((error) => {
          console.error("Error from today alert:", error);
        });
    };

    const [orderLogData, setOrderLogData] = useState(null);
    const getOrderLogDataApi = async () => {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      let userTypeInf = localStorage.getItem("userType");
      
      //  https://flyingcolour.online/fcapp/docprocessing_api/orderloglist/
      await fetch(
        `https://flyingcolour.online/fcapp/docprocessing_api/orderloglist/?order_id=${operationInfo.Order_Id}`,
        {
          method: "GET",
          headers: headers(_token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setOrderLogData(data?.Data?.Order_Log_List);
        })
        .catch((error) => {
          console.error("Error from today alert:", error);
        });
    };

    
    const [proRequestionList, setProRequestionList] = useState(null);
    const getProRequestionListApi = async(toDate, fromDate) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      setLoading(true);     
       try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/admintypistorprorequisitionlist/?emp_id=${emp_id}&user_type=${user_type}&typist_pro_list_flag=2&from_date=${toDate}&to_date=${fromDate}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setProRequestionList(res?.data?.Data?.Requisition_List);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const [typistRequestionList, setTypistRequestionList] = useState(null);
    const getTypistRequestionListApi = async(toDate, fromDate) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/admintypistorprorequisitionlist/?emp_id=${emp_id}&user_type=${user_type}&typist_pro_list_flag=1&from_date=${toDate}&to_date=${fromDate}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setTypistRequestionList(res?.data?.Data?.Requisition_List);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const getRequestionListApi = async(toDate, fromDate) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      setLoading(true);
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/admintypistorprorequisitionlist/?emp_id=${emp_id}&user_type=${user_type}&typist_pro_list_flag=0&from_date=${toDate}&to_date=${fromDate}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setTypistRequestionList(res?.data?.Data?.Requisition_List);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const [adminCompalintListData, setAdminCompalintListData] = useState([]);
    const getAdminCompalintListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
     // setLoader(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/?emp_id=${0}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setAdminCompalintListData(res?.data?.Data?.List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const [compalinOrdertListData, setCompalinOrdertListData] = useState([]);
    const getCompalintOrderListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const order_id = operationInfo?.order_id || operationInfo?.Order_No
     // setLoader(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/?emp_id=${0}&user_type=${user_type}&order_id=${order_id}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setCompalinOrdertListData(res?.data?.Data?.List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const [adminCompalinFormData, setAdminCompalinFormData] = useState([]);
    const getAdminCompalinFormDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const complaint_id = data?.complaint_id;
     // setLoader(true);
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/?user_type=${user_type}&complaint_id=9&company_id=${getCompanyIdAdminComplaintFormData}`
        ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setAdminCompalinFormData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const getAdminRequistionListingDataFromApi = async() => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const order_no = operationInfo?.Order_No || operationInfo?.order_id ;
      //setLoading(true);
      // https://flyingcolour.online/fcapp/support_api/orderwiserequisitionlistview/?factory_id=1&emp_id=58&user_type=Support&order_no=OR1666854370
      try {
        const url = `https://flyingcolour.online/fcapp/support_api/orderwiserequisitionlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${order_no}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setRequistionListing(res?.data?.results?.Requisition_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const [adminFolloUpData, setAdminFolloUpData] = useState([]);
    const getAddminFollowUpApi = async (data) => {
      const id = operationInfo?.Order_No || operationInfo?.order_no;
      await fetch(
        `https://flyingcolour.online/fcapp/support_api/orderfollowupsview/?factory_id=${factory_id}&order_no=${id}&emp_id=${0}&user_type=${user_type}`,
        {
          method: "GET",
          headers: headers(__token),
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (
            data.data !== undefined
          ) {
            return setErrorAlert(true), setError(`ERROR ${data.data.code}-${data.data.msg}`)
          } else if (data.detail !== undefined) {
            return setErrorAlert(true), setError(`ERROR ${"401"}-${data.data.msg}`)
          } else {
            return setAdminFolloUpData(data?.results)
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            return setErrorAlert(true), setError(`${error.response?.statusText} ${error.response?.status}-${error.response?.data?.detail}${" "}${"Please Login Again!!"}`)
          } else {
            console.error("Error from Follow up: ", error);
          }
        });
    };

    const [mainlandWorkFollowData, setMinlandWorkFollowData] = useState([]);
    const getMinlandWorkFollowDataFromApi = async(licenseType, stateType, workflowId) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const order_no = operationInfo?.Order_No || operationInfo?.order_id ;
      //setLoading(true);
      //https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/
      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/`;
        if(licenseType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/?license_type=${licenseType}&state_id=${stateType}`;
        }

        // if(workflowId) {
        //   url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/?license_type=${licenseType}&state_id=${stateType}&workflow_id=${workflowId}`;
        // }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setMinlandWorkFollowData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    
    const [adminOrderListgData, setAdminOrderListgData] = useState(null);
    const getAdminOrderListgDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/docprocessing_api/adminorderlist/?user_type=Admin
     try {
      const url = 
      `https://flyingcolour.online/fcapp/docprocessing_api/adminorderlist/?user_type=${user_type}&flag=0` ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setAdminOrderListgData(res?.data?.Data?.Order_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [adminCompletedOrderListgData, setAdminCompletedOrderListgData] = useState(null);
    const getAdminCompletedOrderListgDataApi = async (dateTypeName, reqFromDate, reqToDate) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const flagValue = dateTypeName === "Request_Completion_Date" ? 5 : 1
      setLoading(true);
     try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/adminorderlist/?user_type=${user_type}&emp_id=${emp_id}&flag=${flagValue}&date_type=${dateTypeName}&fromDate=${reqFromDate}&toDate=${reqToDate}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setAdminCompletedOrderListgData(res?.data?.Data?.Order_List);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const [pdOrderListData, setPdOrderListData] = useState(null);
    const getPdOrderListDataApi = async (supportName, fromDate, toDate) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      setLoading(true);
     try {
      const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/pd_get_support_order_list/?user_type=${user_type}&emp_id=${emp_id}&factory_id=${factory_id}&support_id=${supportName}&date_from=${fromDate}&date_to=${toDate}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setPdOrderListData(res?.data?.results);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [freezoneWorkFollowData, setFreezoneWorkFollowData] = useState([]);
    const getFreezoneWorkFollowDataFromApi = async(licenseType, stateType, freezoneType) => {
      const __token = localStorage.getItem("token");
      //setLoading(true);
      //https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/
      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/dcfreezoneworkflow/`;
        if(stateType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcfreezoneworkflow/?state_id=${stateType}`
        }
        if(stateType && freezoneType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcfreezoneworkflow/?freezone_name_id=${freezoneType}&state_id=${stateType}`;
        }

        if(stateType && freezoneType && licenseType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcfreezoneworkflow/?freezone_name_id=${freezoneType}&license_type=${licenseType}&state_id=${stateType}`;
        }

        // if(workflowId) {
        //   url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/?license_type=${licenseType}&state_id=${stateType}&workflow_id=${workflowId}`;
        // }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setFreezoneWorkFollowData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const [offshoreWorkFollowData, setOffshoreWorkFollowData] = useState([]);
    const getOffshoreWorkFollowDataFromApi = async(legalType, stateType, offshoreType) => {
      const __token = localStorage.getItem("token");
      //setLoading(true);
      //https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/
      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/dcoffshoreworkflow/`;
        if(stateType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcoffshoreworkflow/?state_id=${stateType}`
        }
        if(stateType && offshoreType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcoffshoreworkflow/?offshore_name_id=${offshoreType}&state_id=${stateType}`;
        }

        // if(workflowId) {
        //   url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcmainlandworkflow/?license_type=${licenseType}&state_id=${stateType}&workflow_id=${workflowId}`;
        // }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setOffshoreWorkFollowData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const [proServicesWorkFollowData, setPROServicesWorkFollowData] = useState([]);
    const getPROServicesWorkFollowDataFromApi = async(proFormation, stateType, dedType) => {
      const __token = localStorage.getItem("token");
     

      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/dcproserviceworkflow/`;
        if(stateType) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcproserviceworkflow/?state_id=${stateType}`
        }

        if(stateType && proFormation) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcproserviceworkflow/?state_id=${stateType}&pro_formation_type=${proFormation}`
        }


        if(stateType && dedType && proFormation) {
          url =  `https://flyingcolour.online/fcapp/docprocessing_api/dcproserviceworkflow/?ded_fz_offshore_name=${dedType}&pro_formation_type=${proFormation}&state_id=${stateType}`;
        }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setPROServicesWorkFollowData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    const [complianceListinggData, setComplianceListinggData] = useState(null);
    const getComplianceListinggDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/compliance/inquirylistingforcompliance/
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/inquirylistingforcompliance/?user_type=${user_type}&emp_id=${emp_id}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceListinggData(res?.data?.results?.data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [complianceFormData, setComplianceFormData] = useState(null);
    const getComplianceFormDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const inquiryId = operationInfo?.Inquiry_Id?.replace('FC', '');
      const ComId = operationInfo?.Company_Id;
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/compliance/requestforcompliancefrominquiryupdated/?factory_id=1&inquiry_id=94516&emp_id=687&user_type=COMPLIANCE_OFFICER
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/requestforcompliancefrominquiryupdated/?factory_id=${factory_id}&inquiry_id=${inquiryId}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceFormData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [complianceLogData, setComplianceLlogData] = useState([]);
    const getComplianceLogDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const inquiryId = operationInfo?.Inquiry_Id?.replace('FC', '')
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/compliance/requestforcompliancefrominquiry/?inquiry_id=88287&emp_id=33&user_type=admin
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/changecompliancestatus/?inquiry_id=${inquiryId}&emp_id=${emp_id}&user_type=${user_type}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceLlogData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [compliancePartnerLogData, setCompliancePartnerLlogData] = useState([]);
    const getCompliancePartnerLogDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const partner_id = salesInfo?.partner_id;
     // setLoader(true);
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/changecompliancestatusforpartner/?partner_id=${partner_id}&emp_id=${emp_id}&user_type=${user_type}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setCompliancePartnerLlogData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [complianceVerificationPartnerData, setComplianceVerificationPartnerData] = useState([]);
    const getComplianceVerificationPartnerDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const inquiryId = operationInfo?.Inquiry_Id?.replace('FC', '')
      const partner_id = rowData?.partner_id;
      
      // setLoader(true);
     //   https://flyingcolour.online/fcapp/compliance/checkverificationlist/?inquiry_id=86522&emp_id=687&user_type=Compliance
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/checkverificationlist/?inquiry_id=${inquiryId}&partner_id=${partner_id}&emp_id=${emp_id}&user_type=${user_type}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceVerificationPartnerData(res?.data?.results?.check_verification_details);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const [corporateComplianceList, setCorporateComplianceList] = useState(null);
    const [getSearchTypeId, setSearchTypeId] = useState(0);
    const getCorporateComplianceListApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const searchTypeId = data?.company;      
      setLoading(true);
     try {
      const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&search_type=${2}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setCorporateComplianceList(res?.data?.results);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    const [complianceDetailList, setComplianceDetailList] = useState([]);
    const getComplianceDetailListApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const Partner_Id = operationInfo?.Partner_Id;
      const Company_Id = operationInfo?.Company_Id;
      
      // setLoader(true);
     //   https://flyingcolour.online/fcapp/support_api/compliance_verification_list/?factory_id=1&emp_id=687&user_type=Compliance&company_id=4034
     try {
      const url = `https://flyingcolour.online/fcapp/support_api/compliance_verification_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${0}&partner_id=${Partner_Id}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceDetailList(res?.data?.results?.Compliance_Verification_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
 const [missingKYCComplianceList, setMissingKYCComplianceList] = useState([]);
    const getMissingKYCComplianceListApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const searchTypeId = data?.company;
      
      // setLoader(true);
     //   https://flyingcolour.online/fcapp/support_api/missing_kyc_compliance_list/?factory_id=1&emp_id=687&user_type=COMPLIANCE_OFFICER
     try {
      const url = `https://flyingcolour.online/fcapp/support_api/missing_kyc_compliance_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setMissingKYCComplianceList(res?.data?.results?.Missing_KYC_Compliance_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const [newPartnerComplianceList, setNewPartnerComplianceList] = useState(null);
    const getNewPartnerComplianceListApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const searchTypeId = data?.company;
      
      // setLoader(true);
     //   https://flyingcolour.online/fcapp/compliance/get_new_partner_compliance_request_list/?emp_id=727&user_type=COMPLIANCE_OFFICER
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/get_new_partner_compliance_request_list/?emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setNewPartnerComplianceList(res?.data?.results?.data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [newPartnerDetailList, setNewPartnerDetailList] = useState([]);
    const getNewPartnerDetailListApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const comp_id = operationInfo?.Company_Id;
      const compInqId = operationInfo?.Cmp_Inquiry_Id;      
      // setLoader(true);
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/get_new_partner_compliance_request_list_details/?company_id=${comp_id}&company_inquiry_id=${compInqId}&partner_id=${0}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setNewPartnerDetailList(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [complianceVerificationPartnerForCompanyData, setComplianceVerificationPartnerForCompanyData] = useState([]);
    const getComplianceVerificationPartnerForCompanyDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const comp_id = operationInfo?.Company_Id;
      const partner_id = rowData?.partner_id;
      
      // setLoader(true);
     //  https://flyingcolour.online/fcapp/compliance/check_company_verificationonpartner/?factory_id=1&emp_id=727&user_type=COMPLIANCE_OFFICER&company_id=4044&partner_id=0
     try {
      const url = `https://flyingcolour.online/fcapp/compliance/check_company_verificationonpartner/?company_id=${comp_id}&partner_id=${partner_id}&emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceVerificationPartnerForCompanyData(res?.data?.results?.data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    const [complianceLogOnCompanyData, setComplianceLogOnCompanyData] = useState([]);
    const getComplianceLogOnCompanyDataApi = async (filterOrders) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const comp_id = operationInfo?.Company_Id;
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/compliance/change_company_compliance_status/?emp_id=727&user_type=COMPLIANCE_OFFICER&company_id=4044
     try {
      const url = 
      `https://flyingcolour.online/fcapp/compliance/change_company_compliance_status/?company_id=${comp_id}&emp_id=${emp_id}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setComplianceLogOnCompanyData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [nofData, setNOFData] = useState(null);
    const [nofOtherData, setNOFOtherData] = useState(null);

    const getNOFDataApi = async (fromDate, toDate) => {
      const __token = localStorage.getItem("token");
      setLoading(true);
     try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/adminneworderformlist/${fromDate === undefined ? "" : `?from_date=${fromDate}`}${toDate === undefined ? "" : `&to_date=${toDate}`}`;
      // if(fromDate && toDate) {
      //   url =  `https://flyingcolour.online/fcapp/docprocessing_api/adminneworderformlist/?from_date=${fromDate}&to_date=${toDate}`;
      // }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setNOFOtherData(res?.data?.Data)
        setNOFData(res?.data?.Data);
        setLoading(false);
        // let finalVal = [];
        // Object.keys(res?.data?.Data)?.map((val) => {
        //   res?.data?.Data?.[val]?.forEach((value) => {
        //     finalVal.push(value);
        //   })
        // })
        // setNOFData(finalVal);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [nofDataListing, setNOFDataListing] = useState(null);
    const getNOFDataListingApi = async (proId) => {
      setNOFDataListing(null);
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const Pro_Id = proId ? proId : operationInfo?.project_id===null || operationInfo?.project_id===undefined ? 0 : operationInfo?.project_id;
      // setLoader(true);
     try {
      let url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectorderlist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&new_order_form_id=${operationInfo?.new_order_form_id}&project_id=${Pro_Id}`;

        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        
        setNOFDataListing(res?.data?.results?.Order_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };


    const [templateCreationList, setTemplateCreationListData] = useState([]);
    const getTemplateCreationListgDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/docprocessing_api/adminorderlist/?user_type=Admin
     try {
      const url = 
      `https://flyingcolour.online/fcapp/Operation_Order_Template/order_Template_Service_Task_view/?user_type=${user_type}&factory_id=${factory_id}&emp_id=${emp_id}&task_ids=0` ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setTemplateCreationListData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [sowList, setSOWList] = useState(null);
    const getSowListingDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      try {
        const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/order_Template_Service_Task_view/?user_type=${user_type}&factory_id=${factory_id}&emp_id=${emp_id}&template_servioce_ids=${
          operationInfo?.Service_Id || operationInfo?.Template_Service_Id
        }`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setSOWList(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [templateList, setTemplateList] = useState([]);
    const getTemplateDataAPI = async () => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      let url = `https://flyingcolour.online/fcapp/Operation_Order_Template/order_Template_Wise_Task_view/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTemplateList(res?.data?.results);
    };

    // Receivable Manager Module APIs Start from here----------------------------------------------------------------------------------------------------

    const [ReceivableCompanyData, setReceivableCompanyData] = useState(null);
    const getReceivableManagerCompaniesAPI = async () => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      let url = `https://flyingcolour.online/fcapp/receivable_manager_api/viewcompany/`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReceivableCompanyData(res?.data?.results?.Company_List);
    };

    const [ReceivableVATCompanyData, setReceivableVATCompanyData] = useState(null);
    const getReceivableVATCompaniesAPI = async () => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      // setLoading(true)
      try{
      let url = `https://flyingcolour.online/fcapp/receivable_manager_api/viewcompanytax/`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReceivableVATCompanyData(res?.data?.results?.Company_List);
      // setLoading(false)
    } catch (err) {
      console.error(err);
    }
    };

    const [ReceivableBIZCompanyData, setReceivableBIZCompanyData] = useState(null);
    const getReceivableBIZCompaniesAPI = async () => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      let url = `https://flyingcolour.online/fcapp/receivable_manager_api/viewcompanybiz-expiry-45/`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReceivableBIZCompanyData(res?.data?.results?.Company_List);
    };
    const [noExpiryCompanyData, setNoExpiryCompanyData] = useState(null);
    const getNoExpiryCompaniesAPI = async () => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      let url = `https://flyingcolour.online/fcapp/receivable_manager_api/viewcompany-noexpdate/`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setNoExpiryCompanyData(res?.data?.results?.Company_List);
    };

    const [zohoTransactionData, setZohoTransactionData] = useState([]);
    const getZohoTransactionDataAPI = async () => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");

      let url = ` https://flyingcolour.online/fcapp/support_api/zohotransactiondetailsview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.Company_Id}`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setZohoTransactionData(res?.data?.results);
    };

   
    const getOrderSOWDataAPI = async (data) => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      const orderId = operationInfo?.Order_No || data?.order_id;
      setLoading(true);

    try{
      let url = ` https://flyingcolour.online/fcapp/support_api/orderwise_sowlist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${orderId}`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderSOWData(res?.data?.results);
       setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [SOWEditData, setEditSOWData] = useState([]);
    const getEditSOWDataAPI = async (data) => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");

      let url = ` https://flyingcolour.online/fcapp/support_api/requisition_details_by_id/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo?.Order_No}&requisition_no=${data?.requisitionNos}`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setEditSOWData(res?.data?.results?.Requisition_Details);
    };

   const [viewAdminParnterData, setViewAdminParnterData] = useState(null);
    const getViewAdminParnterDataAPI = async (data) => {
      const user_type = localStorage.getItem("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const __token = localStorage.getItem("token");
      let url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&search_type=${1}&parent_company_id=${""}`;
  
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setViewAdminParnterData(res?.data);
    };

    const [shareData, setShareData] = useState(null);
    const getShareListApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/companysharelist/?partner_id=${0}&emp_id=${0}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setShareData(res?.data?.results?.Data_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
  
    }

    const [inquiryPiData, setInquiryPiData] = useState([]);
    const getInquiryPiAPI = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");

      try {
        const url = `https://flyingcolour.online/fcapp/spm_api/provisionalinvoicelist/?company_flag=${0}&factory_id=${factory_id}&emp_id=${0}&inquiry_id=${0}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setInquiryPiData(res?.data?.results?.provisional_invoice_list);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [companyPiData, setCompanyPiData] = useState([]);
    const getCompanyPiAPI = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
// https://flyingcolour.online/fcapp/spm_api/provisionalinvoicelist/?company_flag=0&factory_id=1&emp_id=0&inquiry_id=0&user_type=Accounts
      try {
        const url = `https://flyingcolour.online/fcapp/spm_api/provisionalinvoicelist/?company_flag=${1}&factory_id=${factory_id}&emp_id=${0}&inquiry_id=${0}&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setCompanyPiData(res?.data?.results?.provisional_invoice_list);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [revenueProjData, setRevenueProjData] = useState([]);
    const getRevenueProjDataApi = async(toDate, fromDate) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      //https://flyingcolour.online/fcapp/docprocessing_api/revenueprojectionlist/?factory_id=1&emp_id=0&inquiry_id=0&user_type=Accounts&revenue_projection_date_from=&revenue_projection_date_to=
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/revenueprojectionlist/?factory_id=1&emp_id=0&user_type=${user_type}&inquiry_id=0&revenue_projection_date_from=${toDate}&revenue_projection_date_to=${fromDate}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setRevenueProjData(res?.data?.results?.Revenue_Projection_List);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [approvedCPSubleadData, setApprovedCPSubleadData] = useState([]);
    const getApprovedCPSubleadApi = async() => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");

      try {
        const url = `https://flyingcolour.online/fcapp/company/approvedconvertedcpsublead/?emp_id=0&user_type=${user_type}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setApprovedCPSubleadData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [viewCompanyFamilyData, setViewCompanyFamilyData] = useState([]);
    const getViewCompanyFamilyApi = async() => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/get_family_details/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${operationInfo?.Company_Id}&company_info_id=${operationInfo?.Id}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setViewCompanyFamilyData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [viewCompanyWorkerData, setViewCompanyWorkerData] = useState([]);
    const getViewCompanyWorkerApi = async() => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      https://flyingcolour.online/fcapp/docprocessing_api/get_worker_details/?factory_id=1&emp_id=58&user_type=Support&company_id=0&company_info_id=0
      try {
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/get_worker_details/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&company_id=${operationInfo?.Company_Id}&company_info_id=${operationInfo?.Id}`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setViewCompanyWorkerData(res?.data?.results);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    const [approvedCPLeadData, setApprovedCPLeadData] = useState(null);
    const getApprovedCPLeadApi = async(fromDate,toDate, accountStatus, searchId) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");

      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/accounts_getApprovedConverted_cpLead/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&flag_type=1`;
        if(toDate || fromDate) {
          url = url + `&lead_date_from=${fromDate}&lead_date_to=${toDate}`
        }
        if(accountStatus) {
          url = url + `&account_status=${accountStatus}`
        }
        if(searchId) {
          url = url + `&search_emp_id=${searchId}`
        }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setApprovedCPLeadData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [approvedCP2LeadData, setApprovedCP2LeadData] = useState(null);
    const getApprovedCP2LeadApi = async(fromDate,toDate, accountStatus, searchId) => {
      const appURL = window.location.href;
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = getLocalData("emp_id");
      const factory_id = localStorage.getItem("factory_id");

      try {
        let url = `https://flyingcolour.online/fcapp/docprocessing_api/accounts_getApprovedConverted_cpLead/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&flag_type=2`;
        if(toDate || fromDate) {
          url = url + `&lead_date_from=${fromDate}&lead_date_to=${toDate}`
        }
        if(accountStatus) {
          url = url + `&account_status=${accountStatus}`
        }
        if(searchId) {
          url = url + `&search_emp_id=${searchId}`
        }
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
  
        setApprovedCP2LeadData(res?.data?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [projectList, setProjectListData] = useState(null);
    const getProjectListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      
     // setLoader(true);
     //  https://flyingcolour.online/fcapp/docprocessing_api/adminorderlist/?user_type=Admin
     try {
      const url = 
      `https://flyingcolour.online/fcapp/Operation_Order_Template/projectlist/?user_type=${user_type}&emp_id=${emp_id}` ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setProjectListData(res?.data?.Data?.projectList);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [vatTaxData, setVatTaxData] = useState(null);
    const getVatTaxDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      
     try {
      const url = 
      `https://flyingcolour.online/fcapp/aubid_test_api/taxorderadmindetails/` ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setVatTaxData(res?.data?.results?.Data);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [oldNofData, setOldNOFData] = useState(null);
    const getOldNOFDataApi = async (nofFromDate, nofToDate) => {
      const __token = localStorage.getItem("token");
      setLoading(true);
     try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/adminneworderformlist_olderorder/?from_date=${nofFromDate}&to_date=${nofToDate}`;
      
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        let finalVal = [];
        Object.keys(res?.data?.Data)?.map((val) => {
          res?.data?.Data?.[val]?.forEach((value) => {
            finalVal.push(value);
          })
        })
        setOldNOFData(res?.data?.Data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const [projectListDropdown, setProjectListDropdown] = useState([]);
  const getProjectListDropdownapi = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const projectId = operationInfo?.project_id;

    try {
      const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectorderlist/?user_type=${user_type}&emp_id=${emp_id}&factory_id=${factory_id}&project_id=${projectId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProjectListDropdown(res?.data?.results?.Order_List);
    } catch (err) {
      console.error(err);
    }
  };

  const [completedOrdersData, setCompletedOrdersData] = useState(null);
  const getCompletedOrdersDataApi = async () => {
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
   try {
    let url =   `https://flyingcolour.online/fcapp/support_api/completedorderlist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
    
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setCompletedOrdersData(res?.data?.results?.Order_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [orderDropDown, setOrderDropDown] = useState([]);
  const getOrderDropDownApi = async (data) => {
    const orderFormId = data?.inquiry_or_company_order_id || data?.new_order_form_id
    setLoading(true);
     try {
      let url = `https://flyingcolour.online/fcapp/company/NOFViewOnInquiry/?orderFormId=${orderFormId}`;

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
  
      setOrderDropDown(res?.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  
  const getOrderDropDownForCompany = async (data) => {
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    setLoading(true);
try {    
const url = 
    `https://flyingcolour.online/fcapp/company/orderformviewoncompany/?${`company_id=${operationInfo?.company_id || data?.Company_Id || data?.company_id || operationInfo?.Company_ID}`}&factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&flag=${data.Order_Id !== undefined ? 2 : 1
      }&orderFormId=${data?. inquiry_or_company_order_id !== undefined ? data?.inquiry_or_company_order_id : 0}`;
     
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
    
      setOrderDropDown(res?.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } 
  };

  const [revisedProjectList, setRevisedProjectList] = useState(null);
  const getRevisedProjectListapi = async (orderNo) => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const projectId = operationInfo?.project_id;

    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/RevisedNOF/?user_type=${user_type}&emp_id=${user_type === "Project_Designer" ? 0 : emp_id}&order_no=${orderNo}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
         setRevisedProjectList(res?.data?.results?.data);
        } catch (err) {
          console.error(err);
        }
      };
      
  const [adminRequestedCompletedOrderListgData, setAdminRequestedCompletedOrderListgData] = useState(null);
  const getAdminRequestedCompletedOrderListgDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true)
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/adminorderlist/?user_type=${user_type}&flag=2&emp_id=${emp_id}&fromDate=${reqFromDate}&toDate=${reqToDate}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setAdminRequestedCompletedOrderListgData(res?.data?.Data?.Order_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [followUpReport, setFollowUpReport] = useState(null);
  const FollowUpReportApi = async (reqFromDate, reqToDate) => {
    setLoading(true)
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_ids = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");

      const url = `https://flyingcolour.online/fcapp/support_api/orderfollowup_reportdetailsview/?factory_id=${factory_ids}&emp_id=${empId}&user_type=${user_type}&from_date=${reqFromDate}&to_date=${reqToDate}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setFollowUpReport(res?.data?.results?.Order_Followup_Report_List);
      setLoading(false);
    } catch (err) {
      
    }
  };

  const [typisOrdertListData, setTypisOrdertListData] = useState(null);
  const getTypistOrderListDataApi = async () => {
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");

      const url = `https://flyingcolour.online/fcapp/typist_app/typist_orderlistview/?factory_id=${factory_id}&emp_id=${empId}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTypisOrdertListData(res?.data?.results?.Support_Order_List);
    } catch (err) {
      
    }
  };
  const [typistReqListData, setTypistReqListData] = useState(null);
  const getTypistReqListDataApi = async (reqFromDate, reqToDate) => {
    setLoading(true)
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");
      const url = `https://flyingcolour.online/fcapp/typist_app/typistRequisitionList/?empId=${empId}&userType=${user_type}&loginFlag=1&fromDate=${reqFromDate}&toDate=${reqToDate}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTypistReqListData(res?.data?.results);
      setLoading(false)

    } catch (err) {
      
    }
  };

  const [viewCompanyData, setViewCompanyData] = useState(null);
  const getViewCompanyDataApi = async (companyId) => {
    setLoading(true)
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");

      const url = `https://flyingcolour.online/fcapp/typist_app/get_support_admin_companylist/?factory_id=${factory_id}&emp_id=${empId}&user_type=${user_type}&company_id=${companyId}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setViewCompanyData(res?.data?.results);
      setLoading(false)
    } catch (err) {
      
    }
  };

  const [noOrdersReqListData, setNoOrdersReqListData] = useState(null);
  const getNoOrdersReqListDataApi = async () => {
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");
      const url = `https://flyingcolour.online/fcapp/support_api/OrdersWithNoRequisition/?emp_id=${empId}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setNoOrdersReqListData(res?.data?.results?.Support_Order_List);
    } catch (err) {
      
    }
  };
  const [typistDraftListData, setTypistDraftListData] = useState(null);
  const getTypistDraftListDataApi = async () => {
    try {
      let empId = localStorage.getItem("emp_id");
      let _token = localStorage.getItem("token");
      let factory_id = localStorage.getItem("factory_id");
      const user_type = localStorage.getItem("user_type");
      const url = `https://flyingcolour.online/fcapp/typist_app/get_draft_list/?emp_id=${empId}&user_type=${user_type}&factory_id=${factory_id}&&draft_id=0`;
      const headers = {
        Authorization: `Token ${_token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTypistDraftListData(res?.data?.results);
    } catch (err) {
      
    }
  };

  const [ordersOverdueReqListData, setOrdersOverdueReqListData] = useState(null);
  const getOrdersOverdueReqListingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    try {
      const url = `https://flyingcolour.online/fcapp/support_api/OrdersWithOverdueRequisition//?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrdersOverdueReqListData(res?.data?.results?.Support_Order_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [ordersNoLiveReqListData, setOrdersNoLiveReqListData] = useState(null);
  const getOrdersNoLiveReqListingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    try {
      const url = `https://flyingcolour.online/fcapp/support_api/OrdersWithNoLiveRequisition/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrdersNoLiveReqListData(res?.data?.results?.Support_Order_List);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [selfAssignedReqData, setSelfAssignedReqData] = useState(null);
  const getSelfAssignedReqDataApi = async (reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true)
    try {
      const url = `https://flyingcolour.online/fcapp/typist_app/getSelfAssign_RequisitionList/?empId=${emp_id}&userType=${user_type}&loginFlag=1&fromDate=${reqFromDate}&toDate=${reqToDate}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSelfAssignedReqData(res?.data?.results?.Requisition_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [complaintListData, setComplaintListData] = useState(null);
    const getComplaintListDataApi = async (complaintId) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/typist_app/get_complaint_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&complaint_id=${complaintId}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setComplaintListData(res?.data?.results?.Complaint_List);
            setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [commentListData, setCommentListData] = useState(null);
    const getCommentListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const orderNo = operationInfo?.Order_No;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/typist_app/get_comment_reply_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${orderNo}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setCommentListData(res?.data?.results?.Comment_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const [feedbackListData, setFeedbackListData] = useState(null);
    const getFeedbackListDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const orderNo = operationInfo === "" ? "" : operationInfo?.Order_No;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/typist_app/get_order_feedback_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${orderNo}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setFeedbackListData(res?.data?.results?.Feedback_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const getFeedbackListDataApi2 = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const orderNo = operationInfo === "" ? "" : operationInfo?.Order_No;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/typist_app/get_order_feedback_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${""}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setFeedbackListData(res?.data?.results?.Feedback_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const [companyOrdersData, setCompanyOrdersData] = useState(null);
    const getCompanyOrdersDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/Operation_Order_Template/CompanyOrderList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${companyId}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setCompanyOrdersData(res?.data?.results?.Order_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const [receivableProjData, setReceivableProjData] = useState(null);
    const getReceivableProjDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      const formationTypeId = operationInfo === "" ? "" : operationInfo?.Formation_Type_Id;
      const tabType = tabValue === "1" || tabValue === "" ? "Biz_Setup" : tabValue === "2" ? "FC_Tax" : "";
     
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_receivable_projection_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&receivable_projection_id=${0}&company_id=${companyId}&formation_type_id=${formationTypeId}&tab_type=${tabType}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setReceivableProjData(res?.data?.results);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [proHeadRequistionData, setProHeadRequistionData] = useState(null);
    const getProHeadRequistionDataApi = async (fromDate, toDate, gridRef) => {
      gridRef?.current?.api?.showLoadingOverlay();
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      // setLoading(true);
      try {
          let url = `https://flyingcolour.online/fcapp/pro_head/PRORequisitionList/?emp_id=${0}&user_type=${user_type}`;

            if(fromDate || toDate) {
              url = url + `&from_date=${fromDate}&to_date=${toDate}`;
            }
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setProHeadRequistionData(res?.data?.Data);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [currentMonthARProjectionData, setcCurrentMonthARProjectionData] = useState(null);
    const getCurrentMonthARProjectionDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      const searchYear = data?.year === "" || data?.year === undefined ? "" : data?.year;
      const searchMonth = data?.month === "" || data?.month === undefined ? "" : data?.month;
      // setLoading(true);
      
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_current_month_receivable_projection_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&search_month=${searchMonth}&search_year=${searchYear}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setcCurrentMonthARProjectionData(res?.data?.results);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [receivableProjHistoryData, setReceivableProjHistoryData] = useState(null);
    const getReceivableProjHistoryDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const parentCompanyId = data === "" || data?.Parent_Company_Ids === undefined ? "" : data?.Parent_Company_Ids;
      const projectionMonth = data === "" || data?.Projection_Month === undefined ? "" : data?.Projection_Month;
      const projectionYear = data === "" || data?.Projection_Year === undefined ? "" : data?.Projection_Year;
      const paymentMonth = data === "" || data?.Paid_Month === undefined ? "" : data?.Paid_Month;
      const paymentYear = data === "" || data?.Paid_Year === undefined ? "" : data?.Paid_Year;
      const cmId = data === "" || data?.client_manager_id === undefined ? "" : data?.client_manager_id;
      const projectionStatus = data === "" || data?.Projection_Status === undefined ? "" : data?.Projection_Status;
      
      setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_receivable_projection_report_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&parent_company_id=${parentCompanyId}&projection_month=${projectionMonth}&projection_year=${projectionYear}&payment_month=${paymentMonth}&payment_year=${paymentYear}&client_manager_id=${cmId}&projection_status=${projectionStatus}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setReceivableProjHistoryData(res?.data?.results);
            setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [adminOrdersWithoutFutureAlertData, setAdminOrdersWithoutFutureAlertData] = useState(null);
    const getAdminOrdersWithoutFutureAlertDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");

      try {
          const url = 
          `https://flyingcolour.online/fcapp/support_api/orderwithnofuturealertslistview/?factory_id=1&emp_id=0&user_type=Admin`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setAdminOrdersWithoutFutureAlertData(res?.data?.results?.Support_Order_With_No_Future_Alerts_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [receivableProjectionReportCountData, setReceivableProjectionReportCountData] = useState(null);
    const getReceivableProjectionReportCountDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const parentCompanyId = data?.Parent_Company_Ids === "" || data?.Parent_Company_Ids === undefined ? "" : data?.Parent_Company_Ids;
      const cmId = data === "" || data?.client_manager_id === undefined ? "" : data?.client_manager_id;
      const searchYear = data?.year === "" || data?.year === undefined ? "" : data?.year;
      const searchMonth = data?.month === "" || data?.month === undefined ? "" : data?.month;

       
      setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_receivable_projection_report_count_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&parent_company_id=${parentCompanyId}&client_manager_id=${cmId}&search_year=${searchYear}&search_month=${searchMonth}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setReceivableProjectionReportCountData(res?.data?.results);
            setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
  
    const [ordersWithCommentsReplyData, setOrdersWithCommentsReplyData] = useState(null);
    const getOrdersWithCommentsReplyDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);

      try {
          const url = 
          `https://flyingcolour.online/fcapp/typist_app/support_noreply_orderlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setOrdersWithCommentsReplyData(res?.data?.results?.NO_Reply_Order_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const [missingARProjectionData, setMissingARProjectionData] = useState(null);
    const getMissingARProjectionDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);

      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_missing_receivable_companylist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setMissingARProjectionData(res?.data?.results?.Company_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    
    const [newReceivableData, setNewReceivableData] = useState(null);
    const getNewReceivableDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_new_receivable_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setNewReceivableData(res?.data?.results?.Receivable_Projection_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
       };
    const [hotReceivableData, setHotReceivableData] = useState(null);
    const getHotReceivableDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_hot_receivable_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setHotReceivableData(res?.data?.results?.Receivable_Projection_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const [oldReceivableData, setOldReceivableData] = useState(null);
    const getOldReceivableDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);
      
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_old_receivable_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setOldReceivableData(res?.data?.results?.Receivable_Projection_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };
    const [writtenOffReceivableData, setWrittenOffReceivableData] = useState(null);
    const getWrittenOffReceivableDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const companyId = operationInfo === "" ? "" : operationInfo?.Company_Id;
      // setLoading(true);
      try {
          const url = 
          `https://flyingcolour.online/fcapp/receivable_manager_api/get_writeoff_receivable_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setWrittenOffReceivableData(res?.data?.results?.Receivable_Projection_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };


    const [generalTaskManagerData, setGeneralTaskManagerData] = useState(null);
    const getGeneralTaskManagerDataDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      // setLoading(true);
      try {
          let url = `https://flyingcolour.online/fcapp/pro_head/GeneralTaskManager/?emp_id=${emp_id}&user_type=${user_type}`;

            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setGeneralTaskManagerData(res?.data?.results);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [generalScheduleData, setGeneralScheduleData] = useState(null);
    const getGeneralScheduleDataApi = async () => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      setLoading(true);
      try {
          let url = `https://flyingcolour.online/fcapp/pro_head/GeneralScheduleList/?emp_id=${emp_id}&user_type=${user_type}`;

            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setGeneralScheduleData(res?.data?.results);
            setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [rescheduleDataList, setRescheduleDataList] = useState(null);
    const getRescheduleDataListApi = async (generalScheduleId) => {
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
  
      try {
        const url = `https://flyingcolour.online/fcapp/pro_head/GeneralReschedule/?user_type=${user_type}&emp_id=${emp_id}&general_schedule_id=${
          generalScheduleId
        }`;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setRescheduleDataList(res?.data?.results?.Task_Detail);
        //setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };


    const [adminRejectedOrdersData, setAdminRejectedOrdersData] = useState(null);
    const getRejectedOrdersDataApi = async (data) => {
      const __token = localStorage.getItem("token");
      const user_type = localStorage.getItem('user_type');
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");

      try {
          const url = 
          `https://flyingcolour.online/fcapp/docprocessing_api/RejectedOrderList/?emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
              Authorization: `Token ${__token}`,
              "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setAdminRejectedOrdersData(res?.data?.results?.Order_List);
            // setLoading(false);
          } catch (err) {
            console.error(err);
          }
    };

    const [clientDetail, setClientDetail] = useState(null);
  const fetchClientResponseFromApi = async (Order_Id) => {
    const appURL = window.location.href;
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = getLocalData("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    //setLoading(true);
    try {
      const url = `https://flyingcolour.online/fcapp/spm_api/clientreview/?enquiryids=${0}&is_company=${1}&factory_id=${factory_id}&order_id=${
        Order_Id
      }`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setClientDetail(res?.data?.results);
      setTemplateOperationInfo(res?.data?.results)
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [overDueOrdersData, setOverDueOrdersData] = useState(null);
  const getOverDueOrdersDataApi = async (orderId) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    try {
        const url = 
        `https://flyingcolour.online/fcapp/docprocessing_api/OverdueOrderReason/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&single_multiple=Single&order_id=${orderId}`;
          const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          };
          const res = await axios.get(url, { headers });
          setOverDueOrdersData(res?.data?.results);
          // setLoading(false);
        } catch (err) {
          console.error(err);
        }
  };

  const [ecsShareholderData, setEcsShareholderData] = useState(null);
  const getEcsShareholderDataApi = async (orderId) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    try {
        const url = 
        `https://flyingcolour.online/fcapp/compliance/get_shareholder_company_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&shareholder_id=${operationInfo?.Partner_Id}`;
          const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          };
          const res = await axios.get(url, { headers });
          setEcsShareholderData(res?.data?.results);
          // setLoading(false);
        } catch (err) {
          console.error(err);
        }
  };

  const [proInternalData, setPROInternalData] = useState(null);
  const fetchPROInternalRequistionListData = async(proId, reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/pro_head/PROInternalRequisitionList/?from_date=${reqFromDate}&to_date=${reqToDate}&emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;

      if(proId) {
       url = url+`&Pro_Ids=${proId}`
      }
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setPROInternalData(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const [escalationLogData, setEscalationLogData] = useState(null);
  const getEscalationLogDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ComplaintId = operationInfo?.Complaint_Id
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/typist_app/get_complaint_log_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&complaint_id=${ComplaintId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setEscalationLogData(res?.data?.results?.Complaint_Log_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const [targetAchievementData, setTargetAchievementData] = useState(null);
  const getTargetAchievementDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ComplaintId = operationInfo?.Complaint_Id
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/usertype_wise_employee_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setTargetAchievementData(res?.data?.Data?.Employee_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const [onAddTarget, setOnAddTarget] = useState(false);
  const [targetMasterData, setTargetMastertData] = useState(null);
  const getTargetMastertDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ComplaintId = operationInfo?.Complaint_Id
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/support_target_name_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setTargetMastertData(res?.data?.Data?.Employee_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const getPCOTargetMastertDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ComplaintId = operationInfo?.Complaint_Id
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/pco_target_name_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setTargetMastertData(res?.data?.Data?.Target_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const getAdminTargetMastertDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ComplaintId = operationInfo?.Complaint_Id
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/admin_assistant_target_name_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

      setTargetMastertData(res?.data?.Data?.Target_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [performanceTargetData, setPerformanceTargetData] = useState(null);
  const getPerformanceTargetDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const TargetEmpId = operationInfo?.EmpId;
    const EmpUserType = operationInfo?.User_Type;

    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/get_employee_performance_target_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&target_emp_id=${TargetEmpId}&target_emp_designation=${EmpUserType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPerformanceTargetData(res?.data?.Data?.employeePerformance_Target_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const getActualDesignationPerformanceTargetDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const TargetEmpId = operationInfo?.EmpId;
    const EmpUserType = operationInfo?.Employee_Actual_Designation === "Project Closing Officer" ? operationInfo?.Employee_Actual_Designation : operationInfo?.User_Type;

    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/get_pco_performance_target_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&target_emp_id=${TargetEmpId}&target_emp_designation=${EmpUserType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPerformanceTargetData(res?.data?.Data?.employeePerformance_Target_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const getAdminPerformanceTargetDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const TargetEmpId = operationInfo?.EmpId;
    const EmpUserType = operationInfo?.Employee_Actual_Designation === "Admin Assistant" ? operationInfo?.Employee_Actual_Designation : operationInfo?.User_Type;
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/get_adminAssistant_performance_target_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&target_emp_id=${TargetEmpId}&target_emp_designation=${EmpUserType}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPerformanceTargetData(res?.data?.Data?.employeePerformance_Target_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  
  const [empAchievementData, setEmpAchievementData] = useState(null);
  const getEmpAchievementDataApi = async(data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const TargetEmpId = data?.Target_Emp_Id;
    const TargetEmpDesig = data?.Target_Emp_Designation;
    const TargetMonth = data?.Target_Month;
    const TargetYear = data?.Target_Year;

    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/target_achieved_by_support/?target_emp_id=${TargetEmpId}&target_emp_designation=${TargetEmpDesig}&target_month=${TargetMonth}&target_year=${TargetYear}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setEmpAchievementData(res?.data?.results?.Target_Achieve_Count);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getPCOAchievementDataApi = async(data) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const TargetEmpId = data?.Target_Emp_Id;
    const TargetEmpDesig = data?.Target_Emp_Designation;
    const TargetMonth = data?.Target_Month;
    const TargetYear = data?.Target_Year;
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/target_achieved_by_pco/?target_emp_id=${TargetEmpId}&target_emp_designation=${TargetEmpDesig}&target_month=${TargetMonth}&target_year=${TargetYear}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setEmpAchievementData(res?.data?.results?.Target_Achieve_Count);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const getPaymentHistoryDataApi = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const projId = rowData?.Receivable_Projection_Id;
    const CompId = operationInfo?.Company_Id;

    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/receivable_manager_api/get_receivable_projection_payment_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&receivable_projection_id=${projId}&company_id=${CompId}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setPaymentHistoryData(res?.data?.results?.Receivable_Projection_Payment_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [trainingCourseData, setTrainingCourseData] = useState(null);
  const getTrainingCourseData = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/TrainingCourseList/?emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTrainingCourseData(res?.data?.Data?.Training_Course_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [orderEscalationData, setOrderEscalationData] = useState(null);
  const getOrderEscalationData = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/support_api/OrderEscalationList/?emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo.Order_No}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderEscalationData(res?.data?.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getOrderEscalationListData = async() => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const empId = user_type === "Admin" ? 0 : emp_id
    setLoading(true);
    try {
      let url = `https://flyingcolour.online/fcapp/support_api/OrderEscalationList/?emp_id=${empId}&user_type=${user_type}&order_no=${""}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderEscalationData(res?.data?.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const [SupportOrdersData, setSupportOrdersData] = useState(null);
  const getSupportOrdersDataApi = async (supportId, reqFromDate, reqToDate) => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoading(true);
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/OrderReport/?user_type=${user_type}&emp_id=${emp_id}&support_id=${supportId}&fromDate=${reqFromDate}&toDate=${reqToDate}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setSupportOrdersData(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [scheduleTrainingData, setScheduleTrainingData] = useState(null);
  const getScheduleTrainingDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoading(true);
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/TrainingScheduleList/?user_type=${user_type}&emp_id=${emp_id}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setScheduleTrainingData(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [trainingLogData, setTrainingLogData] = useState(null);
  const getTrainingLogDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const courseId = operationInfo?.traning_course_id;
    setLoading(true);
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/TrainingCourseLogList/?user_type=${user_type}&emp_id=${emp_id}&training_course_id=${courseId}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setTrainingLogData(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [scheduleTrainingLogData, setScheduleTrainingLogData] = useState(null);
  const getScheduleTrainingLogDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const uniqueIds  = operationInfo?.unique_ids;
    setLoading(true);
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/TrainingScheduleLogList/?user_type=${user_type}&emp_id=${emp_id}&unique_ids=${uniqueIds}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setScheduleTrainingLogData(res?.data?.Data?.Training_Schedule_Log_List);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const [reviewOnOrderData, setReviewOnOrderData] = useState(null);
  const getReviewOnOrderDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const orderId  = operationInfo?.Order_Id;
    setLoading(true);
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/OperationAdminReviewOnOrder/?factory_id=${factory_id}&user_type=${user_type}&emp_id=${emp_id}&order_id=${orderId}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReviewOnOrderData(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
   const getReviewOnOrderReportDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = getLocalData("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const orderId  = operationInfo?.Order_Id;
    setLoading(true);
   try {
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/OperationAdminReviewOnOrder/?factory_id=${factory_id}&user_type=${user_type}&emp_id=${emp_id}&order_id=${0}`
    ;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setReviewOnOrderData(res?.data?.Data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  
  const [pendingData, setPendingData] = useState(null);
  const requistionListingDataAPi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");

    setLoading(true);
    try {
        const url = `https://flyingcolour.online/fcapp/support_api/orderwise_pending_sowlist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo?.Order_No || viewRequistion?.order_id}`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setPendingData(res?.data?.results);
        setLoading(false);
    } catch (err) {
        console.error(err);
    }
};
    const [projectWiseOrders, setProjectWiseOrders] = useState(null);
    const getProjectWiseOrdersDataAPi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const ProjId = operationInfo?.project_id || 0

    setLoading(true);
    try {
        const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectwise_orderlist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&project_id=${ProjId}`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setProjectWiseOrders(res?.data?.results);
        setLoading(false);
    } catch (err) {
        console.error(err);
    }
  };

  const [projectWiseLogOrders, setProjectWiseLogOrders] = useState(null);
  const getProjectWiseOrdersLogDataAPi = async () => {
  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const emp_id = localStorage.getItem("emp_id");
  const factory_id = localStorage.getItem("factory_id");
  const ProjId = operationInfo?.project_id || 0
  const orderId = rowData?.Order_Id || 0

  setLoading(true);
  try {
      const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectwise_order_log_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&project_id=${ProjId}&order_id=${orderId}`;
      const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProjectWiseLogOrders(res?.data?.results?.Project_Wise_Order_Log_List);
      setLoading(false);
  } catch (err) {
      console.error(err);
  }
};

  const [cancelledOrdersLog, setCancelledOrdersLog] = useState(null);
  const getCancelledOrdersLogDataAPi = async () => {
  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const emp_id = localStorage.getItem("emp_id");
  const factory_id = localStorage.getItem("factory_id");
  const ProjId = operationInfo?.project_id || 0
  const orderId = operationInfo?.Order_Id || 0

  try {
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/order_cancellation_log_list/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&project_id=${ProjId}&order_id=${orderId}`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const res = await axios.get(url, { headers });
    setCancelledOrdersLog(res?.data?.results?.Order_Cancellation_Log_List);
  } catch (err) {
    console.error(err);
  }
};

const [projectPendingOrderData, setProjectPendingOrderData] = useState(null);
  const getProjectPendingOrderDataApi = async () => {
  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const emp_id = localStorage.getItem("emp_id");
  const factory_id = localStorage.getItem("factory_id");
  const ProjId = operationInfo?.project_id || 0
  const orderId = operationInfo?.Order_Id || 0

  setLoading(true);
  try {
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectwise_PendingOrderList/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&project_id=${ProjId}`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    const res = await axios.get(url, { headers });
    setLoading(false);
    setProjectPendingOrderData(res?.data?.results);
  } catch (err) {
    setLoading(false);
    console.error(err);
  }
 };
 const [clientReviewOnOrderData, setClientReviewOnOrderData] = useState(null);
 const getClientReviewOnOrderDataApi = async (supportId, start_Date, end_Date) => {
   const __token = localStorage.getItem("token");
   const user_type = getLocalData("user_type");
   const emp_id = localStorage.getItem("emp_id");
   const factory_id = localStorage.getItem("factory_id");
   const suppId = supportId === undefined ? 0 : supportId;
   const startDate = start_Date === undefined ? "" : start_Date;
   const endDate = end_Date === undefined ? "" : end_Date;


   setLoading(true);
  try {
   const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/get_order_review_report/?factory_id=${factory_id}&user_type=${user_type}&emp_id=${emp_id}&support_id=${suppId}&date_from=${startDate}&date_to=${endDate}`
   ;
     const headers = {
       Authorization: `Token ${__token}`,
       "Content-Type": "application/json",
     };
     const res = await axios.get(url, { headers });
     setClientReviewOnOrderData(res?.data?.results);
     setLoading(false);
   } catch (err) {
     console.error(err);
   }
 };

 const [cancelledOrderData, setCancelledOrderData] = useState(null);
 const getCancelledOrderDataApi = async () => {
   const __token = localStorage.getItem("token");
   const user_type = getLocalData("user_type");
   const emp_id = localStorage.getItem("emp_id");
   const factory_id = localStorage.getItem("factory_id");
   

   setLoading(true);
  try {
   const url = `https://flyingcolour.online/fcapp/support_api/cancelled_orderlistview/?factory_id=${factory_id}&user_type=${user_type}&emp_id=${emp_id}`
   ;
     const headers = {
       Authorization: `Token ${__token}`,
       "Content-Type": "application/json",
     };
     const res = await axios.get(url, { headers });
     setCancelledOrderData(res?.data?.results);
     setLoading(false);
   } catch (err) {
     console.error(err);
   }
 };

  const [bsComplianceData, setBsComplianceData] = useState(null);
   const getBsComplianceDataApi = async () => {
     const __token = localStorage.getItem("token");
     const user_type = localStorage.getItem('user_type');
     const emp_id = localStorage.getItem("emp_id");
     const factory_id = localStorage.getItem("factory_id");
 
     setLoading(true);
     try {
       const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&search_type=${1}&parent_company_id=${1}`;
       const headers = {
         Authorization: `Token ${__token}`,
         "Content-Type": "application/json",
       };
       const res = await axios.get(url, { headers });
       setBsComplianceData(res?.data?.results);
       setLoading(false);
     } catch (err) {
       setLoading(false);
     }
   };

   const [fcConsultingComplianceData, setFcConsultingComplianceData] = useState(null);
   const getFcConsultingComplianceDataApi = async () => {
     const __token = localStorage.getItem("token");
     const user_type = localStorage.getItem('user_type');
     const emp_id = localStorage.getItem("emp_id");
     const factory_id = localStorage.getItem("factory_id");
 
     setLoading(true);
     try {
       const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&search_type=${1}&parent_company_id=${26}`;
       const headers = {
         Authorization: `Token ${__token}`,
         "Content-Type": "application/json",
       };
       const res = await axios.get(url, { headers });
       setFcConsultingComplianceData(res?.data?.results);
       setLoading(false);
     } catch (err) {
       setLoading(false);
     }
   };

   const [taxComplianceData, setTaxComplianceData] = useState(null);
   const getTaxComplianceDataApi = async () => {
     const __token = localStorage.getItem("token");
     const user_type = localStorage.getItem('user_type');
     const emp_id = localStorage.getItem("emp_id");
     const factory_id = localStorage.getItem("factory_id");
 
     setLoading(true);
     try {
       const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&search_type=${1}&parent_company_id=${17}`;
       const headers = {
         Authorization: `Token ${__token}`,
         "Content-Type": "application/json",
       };
       const res = await axios.get(url, { headers });
       setTaxComplianceData(res?.data?.results);
       setLoading(false);
     } catch (err) {
       setLoading(false);
     }
   };
   const [shComplianceData, setShComplianceData] = useState(null);
   const getShComplianceDataApi = async () => {
     const __token = localStorage.getItem("token");
     const user_type = localStorage.getItem('user_type');
     const emp_id = localStorage.getItem("emp_id");
     const factory_id = localStorage.getItem("factory_id");
 
     setLoading(true);
     try {
       const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&search_type=${1}&parent_company_id=${23}`;
       const headers = {
         Authorization: `Token ${__token}`,
         "Content-Type": "application/json",
       };
       const res = await axios.get(url, { headers });
       setShComplianceData(res?.data?.results);
       setLoading(false);
     } catch (err) {
       setLoading(false);
     }
   };
   const [jnjComplianceData, setJNJComplianceData] = useState(null);
   const getJNJComplianceDataApi = async () => {
     const __token = localStorage.getItem("token");
     const user_type = localStorage.getItem('user_type');
     const emp_id = localStorage.getItem("emp_id");
     const factory_id = localStorage.getItem("factory_id");
 
     setLoading(true);
     try {
       const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&search_type=${1}&parent_company_id=${27}`;
       const headers = {
         Authorization: `Token ${__token}`,
         "Content-Type": "application/json",
       };
       const res = await axios.get(url, { headers });
       setJNJComplianceData(res?.data?.results);
       setLoading(false);
     } catch (err) {
       setLoading(false);
     }
   };
   const [difcComplianceData, setDIFCComplianceData] = useState(null);
   const getDIFCComplianceDataApi = async () => {
     const __token = localStorage.getItem("token");
     const user_type = localStorage.getItem('user_type');
     const emp_id = localStorage.getItem("emp_id");
     const factory_id = localStorage.getItem("factory_id");
 
     setLoading(true);
     try {
       const url = `https://flyingcolour.online/fcapp/support_api/compliance_individual_corporate_view/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&search_type=${1}&parent_company_id=${28}`;
       const headers = {
         Authorization: `Token ${__token}`,
         "Content-Type": "application/json",
       };
       const res = await axios.get(url, { headers });
       setDIFCComplianceData(res?.data?.results);
       setLoading(false);
     } catch (err) {
       setLoading(false);
     }
   };

   const [followupList, setFollowupList] = useState(null);
   const [companyFollowUpData, setCompanyFollowUpData] = useState([]);

  const getECFollowUpView = async () => {
     setLoading(true);
     await fetch(
       `https://flyingcolour.online/fcapp/followup_api/followuponcompanyView/?flag=3&company_id=${salesInfo?.Company_Id}&sub_lead_id=${0}&emp_id=${emp_id}&user_type=${user_type}&followup_id=${0}`,
       {
         method: "GET",
         headers: headers(__token),
         mode: "cors",
       }
     )
       .then((response) => response.json())
       .then((data) => {
         setFollowupList(data ?.followup_list);
         setCompanyFollowUpData(data?.followup_list);
         setLoading(false);
       })
       .catch((error) => {
         if (error ?.response ?.status === 401) {
           return setLoading(false), setErrorAlert(true), setError(`${error.response ?.statusText} ${error.response ?.status}-${error.response ?.data ?.detail}${" "}${"Please Login Again!!"}`)
         } else {
           setLoading(false);
           console.error("Error from EC Followup: ", error);
         }
       });
   };
 

    return(
        <SupportDataContext.Provider value={{
          getParamsData, 
          setParamsData,
            modalOpen,
            setModalOpen,
            buttonText,
            setButtonText,
            operationInfo,
            setOperationInfo,
            modalTable,
            setModalTable,
            followUp,
            loading,
            setLoading,
            setFollowUp,
            errorAlert,
            setErrorAlert,
            error,
            value,
            setValue,
            setError,
            isOpen, 
            setIsOpen,
            message,
            setMessage,
            severity,
            setSeverity,
            editModalPopper,
            setEditModalPopper,
            viewFollowup,
            open,
            callDueDate,
            orderData,
            setOrderData,
            setOpen,
            commentData,
            setCommentData,
            viewComment,
            setViewComment,
            setViewFollowUp,
            setFreezeLogData,
            myList,
            setSelected,
            selected,
            requistionListing,
            setRequistionListing,
            viewRequistion,
            setViewRequistion,
            freezeLogData,
            requestOrderCompletedData,
            setRequestOrderCompletedData,
            handleModalTableOpen,
            handleModalTableClose,
            handleModalOpen,
            handleModaleClose,
            handleChangeTab,
            getFollowUpTableAndDropdown,
            handleCloseSnack,
            handleClickSnack,
            acceptAccountArray,
            setAcceptAccountArray,
            getOrderData,
            getCommentTableListingAPI,
            getFreezeLogTableData,
            getOrderCompletedTableData,
            fetchListingDataFromApi,
            getRequistionListingDataFromApi,
            requisitionParamsData, 
            setrequisitionParamsData,
            orderRequisitionList, 
            setOrderRequisitionList,
            getBankAccountList,
            zohoBankAccountList, 
            requisitionData, 
            setRequisitionData,
            zohoBankAccountNameList,
            voucherNo, 
            setVoucherNo,
            refundRequisitionParams, setRefundRequisitionParams,
            getSettlementView,
            docTypeList,
            approvedOrderFormTableData, setApprovedOrderFormTableData,
            approvedCompanyOrderFormTableData, setApprovedCompanyOrderFormTableData,
            serviceAgreementListingTableData, setServiceAgreementListingTableData,
            subleadOrderFormListingTableData, setSubleadOrderFormListingTableData,
            companyListViewTableData, setCompanyListViewTableData,
            getPropertyFormData, setGetPropertyFormData, getPropertyFormDataApi,
            viewUpdateResale, setViewUpdateResale,    
            getUpdatePropertyDetailList, getetUpdatePropertyDetailListTableData,
            getDeveloperPropertyList,  getDeveoperPropertyListTableData,
            getAddDeveloperFormData, getAddDeveloperFormDataApi,
            viewDeveloper, setViewDeveloper,
            getEditDeveloperData, editDeveloperData,
            rentalPropertyListData, rentalPropertyListTableData,
            rentalPropertyFormData, rentalPropertyListFormDataApi,
            myRentalList, rentalListingDataFromApi,
            setGetAriaId, getAriaId,
            subPropertyType2, setSubPropertyType2,
            requestionProList,
            setRequestionPROList,
            fetchRequestionProScheduleFromApi,
            setProTaskList,
            proTaskList,
            fetchProTaskListFromApi,
            fetchTypistTaskListFromApi,
            setTypistTaskList,
            typistTaskList,
            fetchSubLeadListDataFromApi,subLeadListData,
            salesInfo, setSalesInfo,
            getSubleadList, subleadservicesList,
            getCompalintListDataApi,
            compalintListData,
            addNewLeadListing,
            getAddNewLeadViewListingApi,
            requisitionLogData,
            getRequisitionLogListDataApi,
            getOrderListgDataApi,
            orderListgData,
            getClientStatusListingDataApi,
            setClientStatusListing,
            clientStatusListing,
            getOrderNoFutureAlertsListingDataApi,
            getFreezesOrderNoFutureAlertsListingDataApi,
            orderNoFutureAlertLisitng,
            freezesOrderNoFutureAlertLisitng,
            getAdminTodayAlertData,adminTodayAlertData,
            getFreezeOrderListApi,freezeOrderList,
            rowData, setRowData,
            getCompleteOrderListApi,completeOrderList,
            getOrderLogDataApi,orderLogData,
            getProRequestionListApi,proRequestionList,
            getTypistRequestionListApi,typistRequestionList,
            getRequestionListApi,
            getAdminCompalintListDataApi,adminCompalintListData,
            getAdminCompalinFormDataApi,adminCompalinFormData,
            getCompanyIdAdminComplaintFormData, setCompanyIdAdminComplaintFormData,
            getAdminRequistionListingDataFromApi,
            getCompalintOrderListDataApi,compalinOrdertListData,
            getAddminFollowUpApi,adminFolloUpData,
            getMinlandWorkFollowDataFromApi,mainlandWorkFollowData,
            filterOrders, setFilterOrders,
            getAdminOrderListgDataApi,adminOrderListgData,
            getAdminCompletedOrderListgDataApi,adminCompletedOrderListgData, setAdminCompletedOrderListgData,
            getFreezoneWorkFollowDataFromApi,freezoneWorkFollowData,
            setFreezoneWorkFollowData,
            getOffshoreWorkFollowDataFromApi,offshoreWorkFollowData, setOffshoreWorkFollowData,
            getPROServicesWorkFollowDataFromApi,proServicesWorkFollowData,setPROServicesWorkFollowData,
            getComplianceListinggDataApi,complianceListinggData,
            getComplianceFormDataApi,complianceFormData,
            getComplianceLogDataApi,complianceLogData,
            getCompliancePartnerLogDataApi,compliancePartnerLogData,
            getComplianceVerificationPartnerDataApi,complianceVerificationPartnerData,
            getCorporateComplianceListApi,corporateComplianceList, setSearchTypeId,
            getComplianceDetailListApi,complianceDetailList,
            getMissingKYCComplianceListApi,missingKYCComplianceList,
            getNewPartnerComplianceListApi,newPartnerComplianceList,
            getNewPartnerDetailListApi,newPartnerDetailList,
            getComplianceVerificationPartnerForCompanyDataApi,complianceVerificationPartnerForCompanyData,
            getNOFDataApi, nofData,
            getComplianceLogOnCompanyDataApi,complianceLogOnCompanyData,
            getNOFDataListingApi, nofDataListing,
            getTemplateCreationListgDataApi, templateCreationList,
            getSowListingDataApi, sowList,
            templateList, getTemplateDataAPI,
            getReceivableManagerCompaniesAPI, ReceivableCompanyData,
            getReceivableVATCompaniesAPI, ReceivableVATCompanyData,
            getReceivableBIZCompaniesAPI, ReceivableBIZCompanyData,
            getNoExpiryCompaniesAPI, noExpiryCompanyData,
            getZohoTransactionDataAPI, zohoTransactionData,
            getOrderListgRequisitionDataApi, orderListgReqData,
            getOrderSOWDataAPI, OrderSOWData,
            getEditSOWDataAPI, SOWEditData,
            getViewAdminParnterDataAPI, viewAdminParnterData,
            getShareListApi, shareData,
            getInquiryPiAPI, inquiryPiData,
            getCompanyPiAPI, companyPiData,
            getRevenueProjDataApi, revenueProjData,
            getApprovedCPSubleadApi, approvedCPSubleadData,
            getViewCompanyFamilyApi, viewCompanyFamilyData,
            getApprovedCP2LeadApi, approvedCP2LeadData,
            approvedCPLeadData, getApprovedCPLeadApi,
            getViewCompanyWorkerApi, viewCompanyWorkerData,
            projectList, getProjectListDataApi,
            getVatTaxDataApi, setVatTaxData, vatTaxData,
            getOldNOFDataApi, oldNofData,
            nofOtherData,
            getProjectListDropdownapi, projectListDropdown,
            templateOperationInfo, setTemplateOperationInfo,
            getCompletedOrdersDataApi, completedOrdersData,
            isCompleteOrderBtnDisable, setIsCompleteOrderBtnDisable,
            getOrderDropDownApi, orderDropDown, getOrderDropDownForCompany,
            setAlertDate, alertDate,
            revisedProjectList, getRevisedProjectListapi,
            getAdminRequestedCompletedOrderListgDataApi, adminRequestedCompletedOrderListgData,
            FollowUpReportApi, followUpReport,
            getTypistOrderListDataApi, typisOrdertListData,
            getTypistReqListDataApi, typistReqListData,
            getNoOrdersReqListDataApi, noOrdersReqListData,
            getViewCompanyDataApi, viewCompanyData,
            getTypistDraftListDataApi, typistDraftListData,
            ordersNoLiveReqListData, getOrdersNoLiveReqListingDataApi,
            ordersOverdueReqListData, getOrdersOverdueReqListingDataApi,
            getSelfAssignedReqDataApi, selfAssignedReqData,
            getComplaintListDataApi, complaintListData,
            getCommentListDataApi, commentListData,
            getFeedbackListDataApi, feedbackListData,
            getCompanyOrdersDataApi, companyOrdersData,
            proHeadRequistionData, getProHeadRequistionDataApi,
            getReceivableProjDataApi, receivableProjData,
            tabValue, setTabValue,
            getCurrentMonthARProjectionDataApi, currentMonthARProjectionData,
            getReceivableProjHistoryDataApi, receivableProjHistoryData,
            adminOrdersWithoutFutureAlertData, getAdminOrdersWithoutFutureAlertDataApi,
            getReceivableProjectionReportCountDataApi, receivableProjectionReportCountData,
            getOrdersWithCommentsReplyDataApi, ordersWithCommentsReplyData,
            getMissingARProjectionDataApi, missingARProjectionData,
            getNewReceivableDataApi, newReceivableData,
            getHotReceivableDataApi, hotReceivableData, getOldReceivableDataApi, oldReceivableData,
            getWrittenOffReceivableDataApi, writtenOffReceivableData,
            getGeneralTaskManagerDataDataApi, generalTaskManagerData,
            getGeneralScheduleDataApi, generalScheduleData,
            getRescheduleDataListApi, rescheduleDataList,
            getRejectedOrdersDataApi, adminRejectedOrdersData,
            fetchClientResponseFromApi, clientDetail,
            getOverDueOrdersDataApi, overDueOrdersData,
            getEcsShareholderDataApi, ecsShareholderData,
            proInternalData, fetchPROInternalRequistionListData,
            start_Date, setStart_Date, end_Date, setEnd_Date,
            getEscalationLogDataApi, escalationLogData,
            getTargetAchievementDataApi, targetAchievementData,
            getTargetMastertDataApi, targetMasterData,
            onAddTarget, setOnAddTarget,
            getPerformanceTargetDataApi, performanceTargetData,
            getEmpAchievementDataApi, empAchievementData,
            getPaymentHistoryDataApi, paymentHistoryData,
            getTrainingCourseData, trainingCourseData,
            getOrderEscalationData, orderEscalationData,
            getOrderEscalationListData,
            getSupportOrdersDataApi, SupportOrdersData,
            getScheduleTrainingDataApi, scheduleTrainingData,
            getTrainingLogDataApi, trainingLogData,
            getScheduleTrainingLogDataApi, scheduleTrainingLogData,
            getReviewOnOrderDataApi, reviewOnOrderData,
            getActualDesignationPerformanceTargetDataApi,
            getPCOTargetMastertDataApi,
            getPCOAchievementDataApi,
            getAdminTargetMastertDataApi,
            getAdminPerformanceTargetDataApi,
            requistionListingDataAPi,
            pendingData,
            getFeedbackListDataApi2,
            getProjectWiseOrdersDataAPi, projectWiseOrders,
            getProjectWiseOrdersLogDataAPi, projectWiseLogOrders,
            getReviewOnOrderReportDataApi,
            getCancelledOrdersLogDataAPi, cancelledOrdersLog,
            getProjectPendingOrderDataApi, projectPendingOrderData,
            getClientReviewOnOrderDataApi, clientReviewOnOrderData,
            getCancelledOrderDataApi, cancelledOrderData,
            getJNJComplianceDataApi, jnjComplianceData,
            getShComplianceDataApi, shComplianceData,
            getTaxComplianceDataApi, taxComplianceData, 
            getBsComplianceDataApi, bsComplianceData,
            complianceBadges, setComplianceBadges,
            getFcConsultingComplianceDataApi, fcConsultingComplianceData,
            getPdOrderListDataApi, pdOrderListData,
            getDIFCComplianceDataApi, difcComplianceData,
            getECFollowUpView, followupList,
            companyFollowUpData


        }}
        >
            {children}
        </SupportDataContext.Provider>
    )
};

export default OperationProvider;
