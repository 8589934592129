import React, { useContext, useEffect } from "react";
import modalTableStyles from "../styles/ModalTableStyles";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import AddTemplateCreationForm from "./TemplateCreation/AddTemplateCreationForm";
import ViewTemplateCreation from "./TemplateCreation/ViewTemplateCreation";
import AddScopeOfWorkForm from "./TemplateCreation/AddScopeOfWorkForm";
import NOFOrderFormTable from "./NOF/NOFOrderFormTable";
import AddNOFOrderForm from "./NOF/AddNOFOrderForm";
import OperationAdminApprovalForm from "./NOF/OperationAdminApprovalForm";
import AddScopeOfWork from "./TemplateCreation/AddScopeOfWork";
import AddScopeOfWorkSubTemplateForm from "./TemplateCreation/AddScopeOfWorkSubTemplateForm";
import UpdateNOFSOW from "./NOF/UpdateNOFSOW";
import AddOrEditProjectList from "./ProjectList/AddOrEditProjectList";
import EditNOFOrderForm from "./NOF/EditNOFOrderForm";
import ViewOrderOnProjectListTable from "./ProjectList/ViewOrderOnProjectListTable";
import RevisedNOFListing from "./ProjectList/RevisedNOFListing";
import CompanyDocuments from "../Listing/CompanyDocuments";
import CourseDocsTable from "../../Support/Modals/TrainingCourse/CourseDocsTable";
import TrainingCourseLogTable from "../../Support/Modals/TrainingCourse/TrainingCourseLogTable";
import ViewAttendeesTable from "../../Support/Modals/TrainingCourse/ViewAttendeesTable";
import ScheduleTrainingLogTable from "../../Support/Modals/TrainingCourse/ScheduleTrainingLogTable";
import RequisitionLogTable from "./Requisition/RequisitionLogTable";
import ChangeOrderSequenceTable from "./NOF/ChangeOrderSequenceTable";
import ChangeOrderSequenceForm from "./NOF/ChangeOrderSequenceForm";
import ViewCompanyOrdersTable from "./ViewOrdersOnCompany/ViewCompanyOrdersTable";




const ModalTable = ({ classes, data }) => {
  const { quotationApiMsg, expiredErrorMsg, salesInfo } =
    useContext(DataContext);
  const {
    handleModalOpen,
    buttonText,
    handleModalTableClose,
    setButtonText,
    operationInfo,
    setEditModalPopper,
    handleModalTableOpen,
    getTemplateDataAPI,
    nofDataListing
  } = useContext(SupportDataContext);
  const handleModalOpenSub = (e) => {
    const buttonText = e.currentTarget.innerText;
    setEditModalPopper(false);
    handleModalOpen();
  };
  return (
    <div className={classes.TabLowerContainerModalTable}>
      <div className={classes.ButtonDivListingTab}>
        {[
          "Add-Done",
          "view-SA",
          "view-Attended",
          "Add Reschedule",
          "Add Template Creation",
          "Edit Template Creation",
          "View Template Creation",
          "Add SOW Order Template Form",
          "SOW Template Listing",
          "Add Operation Order Approval",
          "Add NOF Order Form",
          "Update NOF SOW",
          "Add SOW Order Sub Template Form",
          "Add Project",
          "Edit Project",
          "Edit NOF Order Form",
          "Revised NOF Listing",
          "Edit Order Sequence",
          
         

        ].includes(buttonText) ? null : (
          <>
            {[
           ""
             
            ].includes(buttonText) ? (
              <>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Order No:
                    {operationInfo?.Order_No || operationInfo?.order_no}
                  </span>
                </p>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Company Name:
                    {operationInfo?.Company_Name || operationInfo?.company_name}
                  </span>
                </p>
              </>
            ) : buttonText === "NOF Order Form Table" ? (
              <>
              <p
              variant="contained"
              className={classes.NewButton1}
              style={{ marginRight: "5px", marginTop: "4px" }}
            >
              <span style={{ marginTop: "4px" }}>
                Project Name:
                {operationInfo?.Company_Name || operationInfo?.Project_Name || operationInfo?.project_name}
              </span>
            </p> 
              <Button
              style={{ marginTop: "4px" }}
              variant="contained"
              className={classes.NewButton1}
              onClick={(e) => {
                getTemplateDataAPI()
                handleModalTableOpen("Add NOF Order Form");
                setButtonText("Add NOF Order Form")
                setEditModalPopper(false)
              }}
              disabled={
                nofDataListing === null || nofDataListing?.length > 0
                  ? true
                  : false
              }
            >
              <AddIcon style={{ marginLeft: "-10px" }} />
              <span style={{ marginTop: "4px" }}> Add Project & Orders</span>
            </Button>
              </>
             ) 
             : buttonText === "Update Order Sequence" ? (
              <>
              <p
              variant="contained"
              className={classes.NewButton1}
              style={{ marginRight: "5px", marginTop: "4px" }}
            >
              <span style={{ marginTop: "4px" }}>
                Project Name:
                {operationInfo?.Company_Name || operationInfo?.Project_Name || operationInfo?.project_name}
              </span>
            </p> 
              <Button
              style={{ marginTop: "4px" }}
              variant="contained"
              className={classes.NewButton1}
              onClick={(e) => {
                getTemplateDataAPI()
                handleModalTableOpen("Edit Order Sequence");
                setButtonText("Edit Order Sequence")
                setEditModalPopper(false)
              }}
            >
              <AddIcon style={{ marginLeft: "-10px" }} />
              <span style={{ marginTop: "4px" }}>Update Order Sequence</span>
            </Button>
              </>
             ) 
             :  buttonText === "View Order On Project" ? 
             <>
             <p
             variant="contained"
             className={classes.NewButton1}
             style={{ marginRight: "5px", marginTop: "4px" }}
           >
             <span style={{ marginTop: "4px" }}>
               Project Name:
               {operationInfo?.Company_Name || operationInfo?.project_name}
             </span>
           </p> 
            
             </>
           : buttonText ===  "View Company Document" || buttonText === "View Company Order" ? 
           <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Company Name: 
                    {operationInfo?.Company_Name || operationInfo?.company_name}
                  </span>
                </p>
            :
                buttonText === "View Course Documents"
                || buttonText === "Training Course Log"
                ? 
                <>
                <p
                variant="contained"
                className={classes.NewButton1}
                style={{ marginRight: "5px", marginTop: "4px" }}
              >
                <span style={{ marginTop: "4px" }}>
                Course Id: {" "}
                  {operationInfo?.traning_course_id}
                </span>
              </p> 
                </>
              : 
              buttonText === "View Training Attendees"
              || buttonText === "View Training Status Log"
                ? 
                <>
                <p
                variant="contained"
                className={classes.NewButton1}
                style={{ marginRight: "5px", marginTop: "4px" }}
              >
                <span style={{ marginTop: "4px" }}>
                Schedule Id: {" "}
                  {operationInfo?.traning_schedule_id}
                </span>
              </p> 
              </>
              : buttonText === "Requestion Log"
                ? 
                <>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Order No:
                    {operationInfo?.Order_No || operationInfo?.order_id}
                  </span>
                </p>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Company Name:
                    {operationInfo?.Company_Name || operationInfo?.company_name}
                  </span>
                </p>
                </>
              : (
              <Button
              style={{ marginTop: "4px" }}
                variant="contained"
                className={classes.NewButton}
                onClick={(e) => handleModalOpenSub(e)}
                disabled={
                  operationInfo?.Order_Status === "Order Freezed" ||
                  operationInfo?.Order_Status === "Completed" ||
                  (operationInfo?.Order_Status ===
                    "Completion Approval Pending" &&
                    buttonText === "Freeze Order Approval") ||
                  (operationInfo?.Order_Status === "Freeze Approval Pending" &&
                    buttonText === "Completion Order Approval")
                    ? true
                    : false
                }
              >
                <AddIcon style={{ marginLeft: "-10px" }} />
                <span style={{ marginTop: "4px" }}> {buttonText}</span>
              </Button>
            )}

            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#fff",
                color: "#eb6e12",
                marginLeft: "10px",
                padding: "7px 8px",
                marginTop: "4px"
              }}
              onClick={() => handleModalTableClose()}
            >
              <span style={{ marginTop: "4px", marginRight: "5px" }}>
                Close
              </span>
              <CloseIcon />
            </Button>
            {expiredErrorMsg ? (
              <div className={classes.tableMsgContaner}>
                <span className={classes.tableMsgHeader}>
                  {quotationApiMsg}
                </span>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className={classes.BorderSpacingUpperModaleTable}></div>
      {buttonText === "Add Template Creation" || buttonText === "Edit Template Creation" ? (
        <AddTemplateCreationForm />
      ) : buttonText === "View Template Creation" ? (
        <ViewTemplateCreation />
      ) : buttonText === "SOW Template Listing" ? (
        <AddScopeOfWork />
      ) : buttonText === "Add SOW Order Template Form" ? (
        <AddScopeOfWorkForm />
      ) : buttonText === "Add SOW Order Sub Template Form" ? (
        <AddScopeOfWorkSubTemplateForm />
      ) : buttonText === "Add Operation Order Approval" ? (
        <OperationAdminApprovalForm />
      ) : buttonText === "NOF Order Form Table" ? (
        <NOFOrderFormTable />
      ): buttonText === "Add NOF Order Form" ? (
        <AddNOFOrderForm />
      ) : buttonText === "Edit NOF Order Form" ? (
        <EditNOFOrderForm />
      ) : buttonText === "Update NOF SOW" ? (
        <UpdateNOFSOW />
      ) : buttonText === "Add Project" || buttonText === "Edit Project" ? (
        <AddOrEditProjectList />
      ) : buttonText === "View Order On Project" ? (
        <ViewOrderOnProjectListTable />
      ) :  buttonText === "Revised NOF Listing" ? (
        <RevisedNOFListing />
      ) :  buttonText === "View Company Document" ? (
        <CompanyDocuments />
      )  : buttonText === "View Course Documents" ? (
        <CourseDocsTable />
      ) : buttonText === "Training Course Log" ? (
        <TrainingCourseLogTable />
      ) : buttonText === "View Training Attendees" ? (
        <ViewAttendeesTable />
      ) : buttonText === "View Training Status Log" ? (
        <ScheduleTrainingLogTable />
      ) : buttonText === "Requestion Log" ? (
        <RequisitionLogTable />
      ) : buttonText === "Update Order Sequence" ? (
        <ChangeOrderSequenceTable />
      ) : buttonText === "Edit Order Sequence" ? (
        <ChangeOrderSequenceForm />
      ) : buttonText === "View Company Order" ? (
        <ViewCompanyOrdersTable />
      )
       : null}
      <div className={classes.BorderSpacingLowerModaleTable}></div>
    </div>
  );
};

export default withStyles(modalTableStyles)(ModalTable);
