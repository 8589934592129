import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload,
  FaFileMedical,
  FaEye,
  FaSync,
  FaTimes,
  FaHandshake,
  FaIdBadge,
  FaPlus,
  FaStopCircle,
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { getTotalLength } from "../../../../utils/Filters";
import PreviewIcon from '@mui/icons-material/Preview';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DownloadIcon from "@mui/icons-material/Download";
import fileDownload from 'js-file-download';
import jsPDF from "jspdf";
import { CircularProgress } from "@mui/material";
import axios from 'axios';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { useNavigate } from "react-router-dom";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import GradingIcon from '@mui/icons-material/Grading';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrdersOverdueReqListing = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    setAcceptAccountArray,
    getPropertyFormDataApi,
    message,
    severity,
    isOpen,
    setIsOpen,
    // getProjectListDataApi,
    // ordersOverdueReqListData
    getProjectListDataApi,
    ordersOverdueReqListData,
    getOrdersOverdueReqListingDataApi,
    setIsCompleteOrderBtnDisable,
    setSalesInfo,
    getOrderDropDownForCompany,
    setEditCard,
    setBusinessInfo,
    getOrderDropDownApi
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  // const [filterOrders, setFilterOrders] = useState();
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = React.useState(-1);

  useEffect(() => {
    getOrdersOverdueReqListingDataApi();
    handleListingSavedSearch();
  }, []);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <div>
          {
            params.data.Order_Status === "Admin Approval Pending" ?
              <CustomIconButton

                onClick={() => {
                  //handleModalOpen();
                  setButtonText("Initial Order Approval");
                  // scrollToTop()
                  ScrollTop();
                  setOperationInfo(params.data);
                  setIsCompleteOrderBtnDisable(false);

                }}
                title={"Initial Order Approval"}
                icon={<GradingIcon style={{ margin: "0", fontSize: "25px" }} />}
                className="btnActionAlignment"
              />
              :
              <></>
          }
          {
            params?.data?.old_or_new_order === 0 && (
              <>
                <CustomIconButton
                  title="Freeze Order Approval"
                  width={"auto"}
                  icon={
                    <>
                      <FaStopCircle style={{ margin: "0", fontSize: "19px" }} />
                    </>
                  }
                  onClick={() => {
                    handleModalTableOpen("Freeze Order Approval", params.data)
                    setButtonText("Freeze Order Approval");
                    ScrollTop();
                    setOperationInfo(params.data);
                    setIsCompleteOrderBtnDisable(false);

                  }}
                  disabled={
                    params.data.Order_Status === "Admin Approval Pending" ?
                      true : false
                  }
                />
              </>
            )}

          {upperIcons &&
            upperIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    disabled={
                      params.data.Order_Status === "Admin Approval Pending" ? true : false
                    }
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params.data);
                      setButtonText(item.btnText);
                      // scrollToTop()
                      ScrollTop();
                      setOperationInfo(params.data);
                      setIsCompleteOrderBtnDisable(false);
                    }}
                    title={item.btnText}
                    size="small"
                    style={{
                      border: "1px solid #eb6e12",
                      borderRadius: "5px",
                      fontSize: "12px",
                      marginRight: "6px",
                      color: "#fff",
                      background: "#eb6e12",
                      height: "30px",
                      marginTop: "3px",
                      width: "30px",
                    }}
                    className="btnActionAlignment"
                    icon={item.icon}
                  />
                </>
              );
            })}
          {lowerIcons &&
            lowerIcons?.map((item, i) => {
              let rowIndex = params?.node?.rowIndex;
              return (
                <>
                  <CustomIconButton
                    key={item.id}
                    onClick={() => {
                      handleModalTableOpen(item.btnText, params.data);
                      setButtonText(item.btnText);
                      ScrollTop();
                      setOperationInfo(params.data);
                      setIsCompleteOrderBtnDisable(false);
                    }}
                    title={item.btnText}
                    icon={item.icon}
                    className="btnActionAlignment"
                  />
                </>
              );
            })}
          {
            params?.data?.old_or_new_order === 1 && (
              <>
                <CustomIconButton
                  title="View NOF"
                  icon={
                    <>
                      <PreviewIcon />
                    </>
                  }
                  onClick={() => {
                    if (params?.data?.from_inquiry_or_company === "Company") {
                      setOperationInfo(params.data);
                      setSalesInfo(params?.data);
                      getOrderDropDownForCompany(params.data);
                      setButtonText("View NOF Company");
                      // setModalValue("viewforcompany");
                      // setOpenModal(true);
                      handleModalOpen();
                    } else {
                      setButtonText("View NOF Inquiry");
                      // setModalValue("viewforinquiry");
                      setSalesInfo(params?.data);
                      getOrderDropDownApi(params.data);
                      setOperationInfo(params.data);
                      // setOpenModal(true);
                      handleModalOpen();
                    }
                  }}
                />

                <CustomIconButton
                  title="Dowload NOF"
                  icon={
                    <>
                      <SimCardDownloadIcon />
                    </>
                  }
                  onClick={() => {
                    window.open(params?.data?.NOF_Download_Path, "_blank");
                  }}
                />
              </>
            )}
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"View Company Document"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          // disabled={data?.old_or_new_order === 1 ? false : true}
          //disabled={data?.Order_Status === "Pending From Accounts" || data?.Order_Status === "Underprocess" ? false : true}
          />
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Revised NOF Listing", params.data);
              setButtonText("Revised NOF Listing");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"Revised NOF Listing"}
            icon={
              <>
                < DriveFileRenameOutlineIcon />
              </>}
            className="btnActionAlignment"
            disabled={data?.Revised_NOF_Exist === "Yes" ? false : true}
          />
        </div>
      </>
    );
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString.slice(0, 10).split("-");
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };


  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "left", padding: "0", },
      width: 70,
      pinned: "left",
      filter: false,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Revised_NOF_Exist === "Yes" ? <div style={{
              background: 'blue',
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: '#fff'
            }}>{params?.data?.Serial_Number}</div> : <div style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>{params?.data?.Serial_Number}</div>}
          </>
        )
      }
    },
    {
      headerName: "Actions",
      field: "",
      filter: false,
      // checkboxSelection: true,
      cellRenderer: actionBtnRenderer,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      minWidth: 220,
      pinned: "left",
    },
    // {
    //   headerName: "Inquiry / Company",
    //   field: "from_inquiry_or_company",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
    //   width: 170,
    //   pinned: "left",
    // },
    {
      headerName: "Order No",
      field: "Order_No",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 140,
      pinned: "left",
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
    },
    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Order Sub Type",
      field: "order_sub_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 230,

    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
    },
    {
      headerName: "Old / New Order",
      field: "old_or_new_order",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            <>
              {params?.data?.old_or_new_order === 1 ? "New" : params?.data?.old_or_new_order === 0 ? "Old" : ""}<br />

            </>
          </>
        )
      }
    },
    {
      headerName: "Project Name",
      field: "project_name",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 210,
    },
    {
      headerName: "Order Sequence / No of Order",
      field: "order_sequence",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 210,
      cellRenderer: (params) => {
        return (
          <>
            <>
              <span style={{ fontWeight: "bold" }}>Order Sequence:</span> {params?.data?.order_sequence}<br />
              <span style={{ fontWeight: "bold" }}>No. of Order:</span> {params?.data?.no_of_orders}
            </>
          </>
        )
      }
    },
    // {
    //   headerName: "Point of Contact",
    //   field: "Point_of_Contact",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
    //   width: 300,
    // },
    {
      headerName: "Order Creation Date",
      field: "Order_Created_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Created_Date === "" || params?.data?.Order_Created_Date === "NA" ? "" :
              moment(params?.data?.Order_Created_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },
    {
      headerName: "Support Assign Date",
      field: "Support_Assign_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Support_Assign_Date === "" || params?.data?.Support_Assign_Date === "NA" ? "" :
              params?.data?.Support_Assign_Date
            }
          </>
        )
      }
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Start_Date === "" || params?.data?.Order_Start_Date === "NA" ? "" :
              params?.data?.Order_Start_Date
            }
          </>
        )
      }
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Expected_Complition_Date",
      filterParams: dateFilterParams,
      filter: "agDateColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 190,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Order_Expected_Complition_Date === "" || params?.data?.Order_Expected_Complition_Date === "NA" ? "" :
              moment(params?.data?.Order_Expected_Complition_Date).format("DD-MM-YYYY hh:mm:ss a")
            }
          </>
        )
      }
    },

    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Followup",
      field: "Last_Followups",
      filter: "agTextColumnFilter",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 270,
      cellRenderer: (params) => {
        params = params?.data?.Last_Followups;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Support Name",
      field: "Support_Emp_Name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
    },
    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "NA" ? (
              <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
            ) : params?.data?.Freeze_Approval}
          </>
        )
      }
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Complete Order Status Date",
      field: "Complete_Order_Status_Date_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 230,
    },
    {
      headerName: "Complete Order Status Remarks By Admin",
      field: "Complete_Order_Status_Remarks_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 230,
    },
    // {
    //   headerName: "Work in Progress",
    //   field: "Work_in_Progress",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
    //   width: 150,
    //   cellRenderer: (params) => {
    //     params = params?.data?.Work_in_Progress;
    //     return (
    //       <p
    //         style={{
    //           padding: "0 5px",
    //           margin: "0 2rem 1rem 0",
    //         }}
    //         dangerouslySetInnerHTML={{ __html: params }}
    //       />
    //     );
    //   },
    // },
    {
      headerName: "State",
      field: "State",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },

    // {
    //   headerName: "Trade Names",
    //   field: "Trade_Names",
    //   cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
    //   width: 150,
    // },
    {
      headerName: "Revised NOF",
      field: "Revised_NOF_Exist",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      hide: true
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  let filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },

  ];

  const filterSupportNames = (data) => {
    let uniqueNames = [];
    let uniqueObj = [];

    data?.forEach((item, i) => {
      if (uniqueNames.indexOf(item.Support_Emp_Name) === -1) {
        uniqueNames?.push(item?.Support_Emp_Name)
        uniqueObj.push({
          id: 2 + i,
          title: item?.Support_Emp_Name,
          value: item?.Support_Emp_Name,
          key: "Support_Emp_Name",
        });
      }
    });

    return uniqueObj;
  }

  let supportNamesFilters = filterSupportNames(ordersOverdueReqListData);
  filters = [...filters, ...supportNamesFilters]



  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleFilters = (title, value, key) => {

    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id");
  };

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>

      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={ordersOverdueReqListData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {ordersOverdueReqListData?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(ordersOverdueReqListData, val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleFilters(val.title, val.value, val.key)
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(ordersOverdueReqListData, val.Search_Name, valueKey)}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",

                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          val.Search_Name,
                          val.Search_Name,
                          valueKey
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={ordersOverdueReqListData?.length}
      />
     <span style={{ color: "red", fontSize: "13px", marginLeft: "10px" }}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>

      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={ordersOverdueReqListData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          // onSelectionChanged={onRowSelected}
          components={components}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          onPaginationChanged={onPaginationChanged}
          groupDisplayType="groupRows"
          // groupHideOpenParents={true}
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowGroupHidesColumns={true}
          rowGroupPanelShow="always"
        ></AgGridReact>
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default OrdersOverdueReqListing;
