import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import {
  getTotalLength,
  handleDefaultFilters,
  handleFilters,
  getTotalLengthForSavedFilters
} from "../../../../utils/Filters";
import { FaEye } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import { Grid, Modal } from "@material-ui/core";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import ViewOrderFormForCompany from "../../../listing/listingModals/order-form-listing/viewOrderFormForCompany";
import ViewOrderForm from "../../../listing/listingModals/order-form-listing/viewOrderForm";
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import { Formik } from "formik";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NOFListingTable = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setEditModalPopper,
    setButtonText,
    setOperationInfo,
    setSelected,
    setLoading,
    handleModalOpen,
    message,
    setMessage,
    setSeverity,
    handlePromise,
    isOpen,
    setIsOpen,
    severity,
    getNOFDataApi,
    nofData,loading
  } = useContext(SupportDataContext);
  const {
    setBusinessInfo,
    setSalesInfo,
    getOrderDropDownForCompany,
    getOrderDropDownApi,setStart_Date
  } = useContext(DataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);

  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
    const [nofFromDate, setNofFromDate] = useState("");
    const [nofToDate, setNofToDate] = useState("");

  useEffect(() => {
    getNOFDataApi();
    handleListingSavedSearch();
  }, []);

  useEffect(() => {
    if(nofFromDate || nofToDate) {
      getNOFDataApi(nofFromDate, nofToDate)
    }
  }, [nofToDate])


  const api_data = nofData?.New_Order_Form_List_On_Company?.concat(nofData?.New_Order_Form_List_On_Inquiry) || null;

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString.slice(0, 10).split("-");
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      // checkboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 70,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Actions",
      field: "Inquiry_Id",
      filter: false,
      // checkboxSelection: true,
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      minWidth: 320,
      
      pinned: "left",
      cellRenderer: (params) => {
        return (
          <>
            <CustomIconButton
             width={"auto"}
              title="View Project & Order"
              icon={
                <>
                  <CreateNewFolderIcon /> {`View Project & Order`}
                </>
              }
              onClick={() => {
                handleModalTableOpen("Add NOF Order Form",params.data );
                ScrollTop();
                setButtonText("Add NOF Order Form")
                setOperationInfo(params.data);
              }}
              // disabled={
              //   params?.data?.operation_admin_approval_status === "Approved"
              //     ? false
              //     : true
              // }
            />
              <CustomIconButton
              title="View NOF"
              width={"auto"}
              icon={
                <>
                  <FaEye />
                  {"View NOF"}
                </>
              }
              onClick={() => {
                if (params?.data?.inquiry_company === "Company") {
                  setBusinessInfo(params.data);
                  setSalesInfo(params?.data);
                  getOrderDropDownForCompany(params.data);
                  setModalValue("viewforcompany");
                  setOpenModal(true);
                } else {
                  setButtonText("Add Order Form");
                  setModalValue("viewforinquiry");
                  setSalesInfo(params?.data);
                  getOrderDropDownApi(params.data);
                  setBusinessInfo(params.data);
                  setOpenModal(true);
                }
              }}
            />

            <CustomIconButton
              title="Dowload NOF"
              width={"auto"}
              icon={
                <>
                  <DownloadIcon />
                  {"Download NOF"}
                </>
              }
              onClick={() => {
                window.open(params?.data?.downloadFilePath, "_blank");
              }}
            />
          </>
        );
      },
    },
    {
      headerName: "Project Name",
      field: "Project_Name",
       filter: 'agTextColumnFilter',
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
      width: 200,
    },
    {
      headerName: "NOF Type",
      field: "inquiry_company",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
      width: 140,
    },
    {
      headerName: "Inquiry / Company Id",
      field: "inquiry_id",
      filter: 'agTextColumnFilter',
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 140,
      filterValueGetter: (params) => {
        return `${params?.data?.inquiry_id} ${params?.data?.company_id}`; 
      },
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.inquiry_id
              ? params?.data?.inquiry_id
              : params?.data?.company_id}
          </>
        );
      },
    },
    {
      headerName: "Overlap",
      field: "overlap_company",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 110,
  },
    {
      headerName: "Client / Company Name",
      field: "company_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      filterValueGetter: (params) => {
        return `${params?.data?.company_name} ${params?.data?.client_first_name}`; 
      },
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.inquiry_id
              ? params?.data?.client_first_name + " " + params?.data?.client_last_name
              : params?.data?.company_name}
          </>
        );
      },
    },
    {
      headerName: "State",
      field: "state_name",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 140,
    },
    {
      headerName: "Formation Type",
      field: "formation_type",
      cellRenderer: "agGroupCellRenderer",
      enableRowGroup: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
    },

    {
      headerName: "Legal Type Name",
      field: "legal_type_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 170,
    },

    {
      headerName: "Sub Lead Name",
      field: "sub_Lead_Name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 170,
    },
    {
      headerName: "NOF Date",
      field: "Order_Date_Filter",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { borderBottom: "1px solid #fff",  borderRight: "1px solid #fff", overflowY: "auto"},
      width: 200,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.Order_Date_Filter).format("DD-MM-YYYY hh:mm:ss")}</>;
      },
    },
    {
      headerName: "NOF Approval Date",
      field: "Approval_Date_Filter",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: "auto" },
      width: 200,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.Approval_Date_Filter).format("DD-MM-YYYY hh:mm:ss")}</>;
      },
    },
    {
      headerName: "Project Design NOF Approval Status",
      field: "operation_admin_approval_status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 200,
    },
    {
      headerName: "NOF Order Creation Status",
      field: "operation_order_status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 200,
    },
    {
      headerName: "NOF Title",
      field: "order_title",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 300,
    },
    {
      headerName: "NOF Id",
      field: "new_order_form_id",
      filter: 'agTextColumnFilter',
      cellStyle: {borderBottom: "1px solid #fff", borderRight: "1px solid #fff" },
      width: 150,
    },
   
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    {
      id: 2,
      title: "Company",
      value: null,
      key: 'company_id',
    },
    {
      id: 3,
      title: "Inquiry",
      value: null,
      key: 'inquiry_id',
    },
    {
      id: 4,
      title: "Total NOF",
      value: null,
      key: null,
    },
    {
      id: 5,
      title: "Created Order on NOF",
      value: 'Order Created',
      key: 'operation_order_status',
    },
    {
      id: 6,
      title: "Missing Order on NOF",
      value: 'No Order Created',
      key: 'operation_order_status',
    },
  ];

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: '#eb6e12';
    box-shadow: inset '0px 0px 0px 7px #eb6e12';
    margin-top: 10%;
    `;
  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
         <div className="listing-filters" style={{ margin: "1rem" }}>
        {/* <Grid container>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
         <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
           <Form.Label
             column
             sm="5"
             style={{ fontWeight: "600", fontSize: 12 }}
           >
             Select NOF approved date:
           </Form.Label>
           <Col sm="7" style={{ padding: "0 12px 0 0" }}>
             <div className="fc-dropdown-container">
               <CustomDatePicker
                 custom={true}
                
               />
             </div>
           </Col>
         </Form.Group>
       </Grid>
       </Grid> */}
       </div>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={api_data}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {api_data?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(api_data, val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleDefaultFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.title,
                          val.value,
                          val.key
                        )
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
             {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const keyValue = val.Search_Filter?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")?.split(",")[0].replace("[", "").replace("]", "")
                    const keyss = val.Search_Filter.replace(/(.*?)=(.*?)(?:\|\||$)/g)
                    const getNewKey = keyss?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")
                    const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                    const data = JSON.parse(keyss);
                    let myValuesArr = [];
                    let myKeysArr = [];
                    // Iterate over keys and get values
                    for (const key in data) {
                      if (data.hasOwnProperty(key)) {
                        const values = data[key].values;
                        myValuesArr.push(values)
                        myKeysArr.push(key)
                      }
                    }
                    const valuesArray = [].concat(...myValuesArr);
                    let resultArray = ""
                    if (getNewKe2) {
                      const resultString = getNewKe2[1];
                      resultArray = resultString.split(',');
                    }
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLengthForSavedFilters(apiData, myValuesArr, myKeysArr)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(gridRef, setDefaultFilterValue, val.Search_Name, keyValue?.trim(), val.Search_Filter);
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                          }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
            </div>
          </div>
        </>
      ) : null}
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={api_data?.length}
      />

       <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-35px",
              }}
            >
              <div className="formLeadFlexDivModal2">
                <label htmlFor="company" className="labelFormInputModal">
                  <span>
                  Select Approval Date:
                    
                  </span>
                </label>
  
                <div className="selectContainerModal">
                 
                  <DateRangePickerComp
                        custom={true}
                        fieldHandleChange={(value, picker) => {
                          setNofFromDate(
                            moment(picker.startDate).format("YYYY-MM-DD")
                          );
                          setNofToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                          setStart_Date(moment(picker.startDate).format("YYYY-MM-DD"))
                        }}
                      />
                 
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  
            </form>
          )}
        </Formik>
        <span style={{color: "red", fontSize: "13px", marginLeft: "10px"}}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>

      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
{loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={api_data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
          groupDisplayType="groupRows"
          suppressDragLeaveHidesColumns={true}
          suppressMakeColumnVisibleAfterUnGroup={true}
          suppressRowGroupHidesColumns={true}
          rowGroupPanelShow="always"
          autoGroupColumnDef= {
            { 
             headerName: "Company Name",
             minWidth: 300,
             cellStyle: { border: "1px solid #fff", borderBottom: "2px solid #fff", fontWeight: "bold"},
             flex: 1,
             // suppresRowCount: true,
             cellRendererParams: {
              
               // suppressCount: true,
             }
           }
           }
        ></AgGridReact>
          )}
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modalValue === "viewforcompany" ? (
            <ViewOrderFormForCompany setOpenModal={setOpenModal} />
          ) : modalValue === "viewforinquiry" ? (
            <ViewOrderForm setOpenModal={setOpenModal} isSupportModule={true} />
          ) : null}
        </>
      </Modal>
    </div>
  );
};

export default NOFListingTable;
