import React, { useEffect, useCallback, useMemo, useRef, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from 'moment';
// import TodayOrdersAlert from "./TodayOrderAlert";
import { getLocalData, headers } from "../../../../services/services";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import TodayOrderAlert from "./TodayOrderAlert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { SupportDataContext } from "../../../../provider/context";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import Badge from "@mui/material/Badge";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";







const OrderCCAlerts = ({
  apiData,
  tabValue,
  tableUpperButtonArr,
  ScrollTop
}) => {

  const {
    setButtonText,
    setOperationInfo,
    handleModalTableOpen,
    rowData, setRowData
  } = useContext(SupportDataContext);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "550px", width: "100%" }), []);
  // const [rowData, setRowData] = useState();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedValue, setSavedValue] = useState(null);



  const [style, setStyle] = useState({
    height: '100%',
    width: '100%',
  });
  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
      setStyle(
        '100%', '100%'
      );
    }
  }, [rowData]);

  // const renderExpandableRow = (value) => {
  // return (
  //     <>
  //       <div
  //         style={{
  //           display:"flex",
  //           justifyContent:"center",
  //           alignItem:"center",
  //           marginLeft:"4px",
  //           width:"100%",
  //           minHeight: "40px",
  //           // maxHeight: "150px",
  //           overflowX: "scroll",
  //           // overflowY: "hidden",
  //           border: "1px solid white",
  //         }}
  //       >
  //         <TodayOrderAlert date={value.data.dueDate} height="400px"/>
  //       </div>
  //     </>
  //   );
  // };
  const [columnDefs] = useState([
    {
      headerName: "S No",
      field: "Sno",
      filter: "agNumberColumnFilter",
      cellStyle: { textAlign: "center", border: '1px solid #fff' },
      minWidth: 433,
      // maxWidth: 890,
    },
    {
      headerName: "Due Date(No of Alerts)",
      field: "Due_Date",
      filter: "agDateColumnFilter",
      cellRenderer: (params) => {
        return <>{params?.data?.Due_Date}</>
      },
      cellStyle: { textAlign: "center", border: '1px solid #fff' },
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;

          if (dateAsString == null) {
            return 0;
          }

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split("-");
          const years = dateParts[2].split("(")

          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(years[0]);
          const cellDate = new Date(year, month, day);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
      minWidth: 433,
    },
    {
      headerName: "Action",
      cellRenderer: "agGroupCellRenderer",
      field: "action",
      filter: false,
      cellRenderer: (params) => {
        return (
          <CustomIconButton
            title="view"
            icon={<RemoveRedEyeIcon />}
            onClick={() => {
              //setPrepare(true);
              handleModalTableOpen("Approve/Freeze Order", params.data?.dueDate);
              setButtonText("view");
              //handleModalOpen();
              ScrollTop();
              setOperationInfo(params.data?.dueDate);
            }}
          />
        )
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        border: '1px solid #fff',
        paddingTop: 0,
      },
      minWidth: 433,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: "agTextColumnFilter",
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
    };
  }, []);
  const emp_id = getLocalData("emp_id");
  const __token = getLocalData("token");
  const factory_id = getLocalData("factory_id");
  const userTypeInfo = localStorage.getItem("userType");

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    // https://flyingcolour.online/fcapp/docprocessing_api/ccalertlist/?emp_id=58&user_type=Admin
    fetch(
      `https://flyingcolour.online/fcapp/docprocessing_api/ccalertlist/?&emp_id=${emp_id}&&user_type=${userTypeInfo}`,
      {
        method: "GET",
        headers: headers(__token),
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {

        const mapping = data?.Data?.Alert_List.map((i) => ({
          Sno: i.Serial_Number,
          Due_Date: `${moment(i.Due_Date).format("DD-MM-YYYY")}(${i.Total_Pending_Alert})`,
          dueDate: i.Due_Date,
          action: "",
          callRecords: `${i.Total_Pending_Alert}`,
        }));
        setRowData(mapping);
      })
      .catch((error) => {
        console.error("Error from today alert:", error);
      });
  }, []);

  const isRowMaster = useCallback((dataItem, event) => {
    return dataItem ? dataItem.callRecords.length > 0 : false;
  }, []);
  const icons = useMemo(() => {
    return {
      groupExpanded:
        '<i class="fa fa-eye" aria-hidden="true" style="font-size: 22px;color:#eb6e12"/>',
      groupContracted:
        '<i class="fa fa-eye-slash" aria-hidden="true" style="font-size: 22px;color:#7a7a7a"/>',
    };
  }, []);
  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const handleFilters = (title, value, key) => {

    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };

  const getTotalLength = (value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return;
    } else if (key === null) {
      return apiData.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      return apiData.filter((val) => val[key] === value).length;
    }
  };
  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id")
  }
  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },

  ];



  return (

    <div className="listing-data-grid" style={{ height: "560px" }}>
      {
        <>
          <div style={{ display: "flex", marginLeft: "10px", marginTop: "30px", paddingBottom: "15px" }}>
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: 'Times New Roman'
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: 'Times New Roman'
              }}
            >
              {apiData?.length > 0 &&
                filters.map((val, i) => {

                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(val.value, val.key)}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: 'Times New Roman'
                      }}
                      onClick={() => handleFilters(val.title, val.value, val.key)}
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(val.Search_Name, valueKey)}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(val.Search_Name, val.Search_Name, valueKey);
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>

        </>
      }
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">

          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            icons={icons}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            masterDetail={true}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            // detailCellRenderer={renderExpandableRow}
            isRowMaster={isRowMaster}
            detailRowHeight={400}
            components={components}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};
export default OrderCCAlerts;
