import React, { useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import { Typography, Avatar } from "@mui/material";
import { DataContext, SupportDataContext } from "../../../provider/context";
import DocumentControllerTopbar from "./DocumentControllerTopbar";
import DocumentControllerListingHeaderTab from "./DocumentControllerListingHeaderTab";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const DocumentControllerListing = () => {
  const {
    setEditCard,
    employeeFirstName,
    employeeLastName,
  } = useContext(DataContext);

  const {
    open,
    handleCloseSnack,
    message,
    severity,
  } = useContext(SupportDataContext);
  useEffect(() => {
    setEditCard(false);
  }, [window.location.pathname]);
  const styles = {
    salesDashboardBoldHeading: {
      color: "white",
      paddingTop: "20px",
      fontSize: "20px",
      fontWeight: 400,
      paddingLeft: "105px",
    },
    userNameDashboard: {
      textTransform: "capitalize",
    },
    salesDashboardHeadingDiscroption: {
      color: "white",
      fontSize: "20px",
      fontWeight: 400,
      paddingLeft: "105px",
      marginBottom: "10px",
    },
  };
  const content = () => {
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseSnack}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    return (
      <div style={{ display: "block" }}>
        <div className="">
          <Typography
            variant="h4"
            component="div"
            className={styles.salesDashboardBoldHeading}
            style={{
              fontSize: "20px",
              fontWeight: 600,
              paddingLeft: "105px",
              fontFamily: "Times New Roman ",
              marginTop: "30px",
            }}
          >
            Welcome:
            <span className={styles.userNameDashboard}>
              {employeeFirstName} {employeeLastName}{" "}
            </span>
          </Typography>
          <Typography
            variant="h5"
            component="div"
            className={styles.salesDashboardHeadingDiscroption}
            style={{
              fontSize: "20px",
              fontWeight: 400,
              paddingLeft: "105px",
              fontFamily: "Times New Roman ",
              marginBottom: "20px",
            }}
          >
            Dashboard: (Document Controller)
          </Typography>

          <div style={{ position: "absolute", top: "90px", left: "20px" }}>
            <Avatar src="" sx={{ width: 65, height: 59 }} variant="square" />
          </div>
        </div>
        <DocumentControllerListingHeaderTab style={{ marginBottom: "20px" }} />
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          action={action}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  return <DocumentControllerTopbar content={content} />;
};

export default DocumentControllerListing;
