import React, { useContext } from "react";
import "./customDatePicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangeIcon from '@mui/icons-material/DateRange';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DataContext } from "../../provider/context";
import CloseIcon from '@mui/icons-material/Close';


const CustomDatePicker = (props) => {
    const { fromDate,
        setFromDate,
        toDate,
        setToDate } = useContext(DataContext);
    const range = {
        "Today": [moment(), moment()],
        "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment()
                .subtract(1, "month")
                .startOf("month"),
            moment()
                .subtract(1, "month")
                .endOf("month")
        ],
        "Last Year": [
            moment()
                .subtract(1, "year")
                .startOf("year"),
            moment()
                .subtract(1, "year")
                .endOf("year")
        ]
    };
    // const handleEvent = (event, picker) => {
    //     setFromDate(picker.startDate._d.toISOString().slice(0, 10));
    //     setToDate(picker.endDate._d.toISOString().slice(0, 10));
    // };
    const handleEventAddApply = (event, picker) => {
        // alert("123")
        setFromDate(moment(picker.startDate).format().slice(0, 10));
        setToDate(moment(picker.endDate).format().slice(0, 10));
        if(props.custom) {
            props.fieldHandleChange(event, picker)
        }

    };
    // const handleCallback = (start, end, label) => {
    //     setFromDate(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(start));
    //     setToDate(new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', }).format(end));
    // }

    const handleDeSelectDate = () => {
        props?.fieldHandleChange(null)
    }

    return (
        <div className="datePicker">
            <DateRangePicker
                ranges={range}
                showDropdowns = {true}
                // onEvent={handleEvent}
                // onCallback={handleCallback}
                onApply={handleEventAddApply}
            >
                <button className='datePicker-display-date-btn'>
                    <DateRangeIcon style={{ width: '18px', marginRight: '4px' }} />
                    {props?.showDate ? (
                          <span className='date-heading-main'> Select Date</span>
                    ) : (
                        <span className='date-heading-main'>  {moment(fromDate).format("DD-MM-YYYY")} to {moment(toDate).format("DD-MM-YYYY")}</span>
                    )}
                  
                    <KeyboardArrowDownIcon style={{ width: '18px', float: 'right' }} />
                    {!props?.showDate && (<CloseIcon  style={{ width: '18px', float: 'right' }} onClick={handleDeSelectDate} />)}
                   
                </button>
            </DateRangePicker>
        </div>
    );
}

export default CustomDatePicker;