import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext, DataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";

import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import { VpnLock } from "@material-ui/icons";
import { addDays } from "date-fns";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import NewInputText from "../../../../../reusableComponents/InputText/NewInputText";
import NewCustomTextArea from "../../../../../reusableComponents/CustomTextarea/NewCustomTextArea";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    padding: "25px",
    height: "auto",
    paddingTop: "0px",
    minHeight: "650px",
    maxHeight: "650px",
    overflow: 'auto',
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        maxHeight: "760px",
    },
};

const getStyles = (errors, fieldName, height) => {

    if (height && !getIn(errors, fieldName)) {
        return { height: height }
    }

    if (getIn(errors, fieldName) && height) {
        return {
            border: "1px solid red",
            height: height
        };
    } else if (getIn(errors, fieldName)) {
        return {
            border: "1px solid red"
        };
    }
};

const AdminTargetForm = ({ classes }) => {

    const {
        handleModaleClose,
        setLoading,
        loading,
        setMessage,
        setSeverity,
        editModalPopper,
        setIsOpen,
        operationInfo,
        salesInfo,
        rowData,
        getAdminTargetMastertDataApi,
        targetMasterData,
        onAddTarget,
        getAdminPerformanceTargetDataApi,
        performanceTargetData
    } = useContext(SupportDataContext);

    const [enableReinitialize, setEnablReinitializei] = useState(false);
    const [getAlloted_Target_Marks, setGetAlloted_Target_Marks] = useState(
      (performanceTargetData?.length !== 0 && performanceTargetData?.length !== undefined)
  ? performanceTargetData?.[0]?.targetDetails_List : editModalPopper ? rowData?.targetDetails_List
  : []);
    const [targetMonth, setTargetMonth] = useState("");
    const [targetYear, setTargetYear] = useState("");

    const fileInput = React.createRef();
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className, }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#d11616",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#d11616",
        },
    }));

    // Function to check if targetMonth and targetYear are found in the array
function isTargetMonthYearPresent(array, targetMonth, targetYear) {
  for (const item of array) {
      if (item.Target_Month === targetMonth && item.Target_Year === targetYear) {
          return true;
      }
  }
  return false;
}

// Check if March 2024 is present
const isPresent = isTargetMonthYearPresent(performanceTargetData, targetMonth, targetYear);
console.log(isPresent); // Output: true

    console.log("targetMasterData", targetMasterData);
    const targetData = 
    (performanceTargetData?.length !== 0 && performanceTargetData?.length !== undefined)
    ? performanceTargetData?.[0]?.targetDetails_List : targetMasterData;

    const targetDetailsData = targetData?.map((item) => {
        const obj = {
          description: item?.Target_Name,
          Alloted_Mark: item?.Alloted_Mark,
          target: item?.Target_Value,
          achievement: '',
          marks_obtained: '',
          comments: '',
          Target_Id: item?.Target_Id,
          Target_Type: item?.Target_Type,
          Target_Type_Id: item?.Target_Type_Id
        }
        return obj;
    });
console.log("targetDetailsData", targetDetailsData)

// Assuming there is only one object in the metrics array

const updatedDescriptionsTargets = targetDetailsData?.map(item => {
    // Check the description of the current item and update the target value accordingly
    switch (item?.description) {
       
         case "Monitor and ENsure RO's are sending Initial email / Welcome Email / WhatsApp group introductory message with in 30 mins of receiving NOF":
            return { ...item, target: 0 };
            case "Monitor and Ensure RO's are sending Project details and timelines with client within 3 hrs. of getting NOF":
              return { ...item, target: 0 };
         case "Ensure modules of all RO's are up to date. (No Backdated Alerts or Repeated Alerts, Or orders without alerts)":
             return { ...item, target: 0 };
         case "Ensure Daily updates are going to clients by RO for the ongoing work on (Email/Call/WhatsApp)":
             return { ...item, target: 0 };
             case "Ensure RO's are Freezing and Unfreezing orders as per client's response":
                return { ...item, target: 0 };
             case "Ensure all the documents are shares with the Document controller by RO's":
                return { ...item, target: 0 };
                case "Orders approval on Real time basis":
                  return { ...item, target: 0 };
               case "Orders closed without verifying the designed parameters":
                  return { ...item, target: 0 };
                  case "Daily monitor 2.5 RO's module, conducting monittoring twice a week, and compile a rough PE report.":
                    return { ...item, target: 0 };
                 case "Good quality of Email Communication by RO with Client. Review all emails received daily and assess if they meet the required standards. If any improvements are necessary, escalate them to the TL.":
                    return { ...item, target: 0 };
                    case "Complete the Monthly Evaluation of RO's and share the review with HR and Team Leader on the 1st of each month.":
                      return { ...item, target: 0 };
                      case "Ensure timely escalation to the TL if RO's are not adhering to the set parameters.":
                        return { ...item, target: 0 }; 
                        case "Daily report submission to TL":
                        return { ...item, target: 0 }; 
                        case "Inthe absence of RO's, adhere to the complete responsibility of ROs.":
                        return { ...item, target: 0 }; 
                        case "Punctuality / discipline":
                        return { ...item, target: 0 };
             

        // Add cases for other descriptions if needed
        default:
            return item; // Return the item unchanged if it doesn't match the cases
    }
});

console.log(updatedDescriptionsTargets);

const editTargetDetailsData = editModalPopper && rowData?.targetDetails_List?.map((item) => {
    const obj = {
        update_id: item?.Update_Id,
        description: item?.Target_Name,
        Alloted_Mark: item?.Alloted_Mark,
        target: item?.Target_Value,
        achievement: '',
        marks_obtained: '',
        comments: '',
        Target_Id: item?.Target_Id,
        Target_Type: item?.Target_Type,
        Target_Type_Id: item?.Target_Type_Id
    }
    return obj;
});
console.log("editTargetDetailsData", editTargetDetailsData)

   
    const monthOptions = [
        {value: "January", label: "January"},
        {value: "February", label: "February"},
        {value: "March", label: "March"},
        {value: "April", label: "April"},
        {value: "May", label: "May"},
        {value: "June", label: "June"},
        {value: "July", label: "July"},
        {value: "August", label: "August"},
        {value: "September", label: "September"},
        {value: "October", label: "October"},
        {value: "November", label: "November"},
        {value: "December", label: "December"},
    ];
    const yearOptions = [
        {value: "2024", label: "2024"},
        {value: "2025", label: "2025"},
    ];
   
    const targetPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const user_name = localStorage.getItem("Employee_First_Name");
        const factory_id = localStorage.getItem("factory_id");
        
        const url = `https://flyingcolour.online/fcapp/docprocessing_api/add_edit_adminAssistant_target/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };

        const targetDetails = values.mainTaskArray?.map((item) => {
            const obj = {
                target_id: item?.Target_Id,
                target_name: item?.description,
                alloted_mark: item?.Alloted_Mark,
                target_value: item?.target,
            }
            return obj;
        });
        let updatedDetails = targetDetails.map(obj => {
          let newObj = {};
          Object.keys(obj).forEach(key => {
            // Convert everything to string
            newObj[key] = String(obj[key]);
          });
          return newObj;
        });
        const editTargetDetails = values.mainTaskArray?.map((item) => {
            const obj = {
                update_id: item?.update_id,
                target_id: item?.Target_Id,
                target_name: item?.description,
                alloted_mark: item?.Alloted_Mark,
                target_value: item?.target,
            }
            return obj;
        });
        let editUpdatedDetails = editTargetDetails.map(obj => {
          let newObj = {};
          Object.keys(obj).forEach(key => {
            // Convert everything to string
            newObj[key] = String(obj[key]);
          });
          return newObj;
        });
        const formData = new FormData();
        formData.append("factory_id", factory_id);
        formData.append("emp_id", emp_id);
        formData.append("user_type", user_type);
        formData.append("user_name", user_name);
        formData.append("target_month", values?.target_month);
        formData.append("target_year", values?.target_year);
        formData.append("target_emp_id", operationInfo?.EmpId);
        formData.append("target_emp_designation", operationInfo?.Employee_Actual_Designation);
        formData.append("target_details", editModalPopper ? JSON.stringify(editUpdatedDetails) : JSON.stringify(updatedDetails));


        if (editModalPopper) {

            axios.put(url, formData, { headers: headers }).then((data) => {
                setLoading(false);

                if (data?.data?.message) {
                    setMessage(`${data?.data?.message}`);
                    setSeverity(500)
                }
                else {
                    setMessage(data?.data?.data?.msg);
                    setSeverity(data?.data?.data?.code)
                }
                setIsOpen(true);
                getAdminPerformanceTargetDataApi();
                handleModaleClose();

            })
        } else {
            axios.post(url, formData, { headers: headers }).then((data) => {
                setLoading(false);

                if (data?.data?.message) {
                    setMessage(`${data?.data?.message}`);
                    setSeverity(500)
                }
                else {
                    setMessage(data?.data?.data?.msg);
                    setSeverity(data?.data?.data?.code)
                }
                setIsOpen(true);
                getAdminPerformanceTargetDataApi();
                handleModaleClose();
            })
        }
    }

    useEffect(() => {
        getAdminTargetMastertDataApi();
    }, []);


  const totalAlloted = getAlloted_Target_Marks?.map((val) => Number(val?.Alloted_Mark));

    const totalAllotedMark = totalAlloted?.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
      const totalTarget = getAlloted_Target_Marks?.map((val) => Number(val?.target));

      // Calculate total of target_value
      const totalTargetValue = totalTarget?.reduce((accumulator, current) => {
        return accumulator + current;
      }, 0);
      
    
 return (
<>
<Formik
   enableReinitialize={enableReinitialize}
   initialValues={{
       target_year: editModalPopper ? rowData.Target_Year : "",
       target_month: editModalPopper ? rowData.Target_Month : "",
       // mainTaskArray:
       //     // editModalPopper ? editMainTaskArrayValue() :
       //     [{
       //         description: editModalPopper ? salesInfo.description : '',
       //         Alloted_Mark: editModalPopper ? salesInfo.Alloted_Mark : '',
       //         target: editModalPopper ? salesInfo.target : '',
       //         achievement: editModalPopper ? salesInfo.achievement : '',
       //         marks_obtained: editModalPopper ? salesInfo.marks_obtained : '',
       //         comments: editModalPopper ? salesInfo.comments : '',
       //     }],
       mainTaskArray: editModalPopper ?
            editTargetDetailsData !== undefined && editTargetDetailsData?.length !== 0
           ? editTargetDetailsData
           :
           [{
            description: '',
            Alloted_Mark: '',
            target: '',
            achievement: '',
            marks_obtained: '',
            comments: '',
           }]
         : updatedDescriptionsTargets !== undefined && updatedDescriptionsTargets?.length !== 0
           ? updatedDescriptionsTargets
           :
           [{
            description: '',
            Alloted_Mark: '',
            target: '',
            achievement: '',
            marks_obtained: '',
            comments: '',
           }],
   }}
   onSubmit={async (data) => {
       setLoading(true)
       targetPostAPI(data)
   }}

   validate={(values) => {
       setGetAlloted_Target_Marks(values?.mainTaskArray)
       console.log("mainTaskArray" ,values?.mainTaskArray)
       let errors = {};
           if (values.target_month === "") {
               errors.target_month = "Target Month is Required!!";
           }
           if (values.target_year === "") {
               errors.target_year = "Target Year is Required!!";
           }
       values?.mainTaskArray?.forEach((val) => {

           if (val.target === "") {
               errors.target = "Target Value is Required!!";
           }
           if (val.Alloted_Mark === "") {
               errors.Alloted_Mark = " Alloted Marks is Required!!";
           }
       })
       return errors;
   }}>
  {({
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
  }) => {
      return (
          <form onSubmit={(e) => { e.preventDefault() }}>
           <Box sx={style}>
               <div>
                   <div style={{ height: "36px" }}>
                       <IconButton
                           style={{ float: "right" }}
                           className={classes.quotationModalHeaderIconCancle}
                           onClick={() => {
                               handleModaleClose();
                           }}
                       >
                           <CloseIcon
                               className={classes.quotationModalHeaderIconCancleIcon}
                           />
                       </IconButton>
                   </div>
                   <div style={{ height: "610px", overflowX: "hidden", padding: "0" }}>
                       <Grid container spacing={2} columns={12}>
                           <Grid
                               item
                               xs={12}
                               style={{
                                   marginBottom: "-30px",
                                   paddingBottom: "10px",
                                   marginTop: "-45px",
                                   zIndex: 10
                               }}
                           >
                               <br />
                               <div className={classes.quotationModalHeadingContainer}>
                                   <span className={classes.quotationModalHeader}>
                                       {editModalPopper ? `Edit Admin Assistant Target Form`
                                           : `Add Admin Assistant Target Form`}
                                   </span>
                               </div>
                               <br />
                               <div className={classes.quotationModalFlexDivContaner}>
                                   <Grid container>
                                       <Grid
                                           item
                                           xs={12}
                                           md={3}
                                           className={classes.quotationInnerGridRight}
                                       >
                                           <InputText
                                               label="Employee Name"
                                               fieldName="Requistion_Id"
                                               fieldValue={operationInfo?.Emp_Name}
                                               fieldHandleChange={(event) => { }
                                                   //handleChangeInput(item?.id, event)
                                               }
                                               disableField={true}
                                               disabled={true}
                                               classvalue={"textBold"}
                                           />
                                           </Grid>
                                           <Grid
                                           item
                                           xs={12}
                                           md={3}
                                           className={classes.quotationInnerGridRight}
                                       >
                                           <InputText
                                               label="Actual Designation"
                                               fieldName="Employee_Actual_Designation"
                                               fieldValue={operationInfo?.Employee_Actual_Designation}
                                               fieldHandleChange={(event) => { }
                                                   //handleChangeInput(item?.id, event)
                                               }
                                               disableField={true}
                                               disabled={true}
                                               classvalue={"textBold"}
                                           />
                                       </Grid>
                                       <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.quotationInnerGridRight}
                      >
                        <CustomDropdown                                 
                            label="Target Month"
                            fieldOptions={monthOptions}
                            fieldValue={values?.target_month}
                            fieldHandleChange={(value) => {
                                setFieldValue(`target_month`, value.value);
                                setTargetMonth(value.value);
                            }}
                            // errorField={getStyles(errors, values?.target_month)}
                            requiredField={true}
                        />
                        </Grid>
                     <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.quotationInnerGridRight}
                      >
                         <CustomDropdown                                 
                         label="Target Year"
                          fieldOptions={yearOptions}
                          fieldValue={values?.target_year}
                          fieldHandleChange={(value) => {
                              setFieldValue(`target_year`, value.value);
                              setTargetYear(value.value)
                          }}
                        //   errorField={getStyles(errors, values?.target_year)}
                          requiredField={true}
                          />
                      </Grid>
                      </Grid>
                      <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.quotationInnerGridRight}
                        >
                      <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId={"selectsow"}
                          >
                            <Col sm="12">
                              {/* Scope of Work Component Has to  be */}
                              {values?.mainTaskArray?.length > 0 && (
                                <div
                                  className="fc-dropdown-container"
                                  style={{ padding: "10px" }}
                                >
                                  <Box
                                    component={Grid}
                                    display={{ xs: "none", md: "flex" }}
                                  >
                                    <Grid container columns={12}>
                                      <Grid item xs={12} sm={12} md={onAddTarget ? 9: 6}>
                                        <p>Description</p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={onAddTarget ? 1 : 1}>
                                        <p>Target<span style={{color: "red"}}>*</span> </p>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={onAddTarget ? 2 : 1}>
                                        <p>Allocated Marks<span style={{color: "red"}}>*</span></p>
                                      </Grid>
                                     
                                    </Grid>
                                  </Box>

                                  <>
                                    {values?.mainTaskArray?.map((val, i) => {
                                      return (
                                        <Grid container columns={12}>
                                          <Grid item xs={12} sm={12} md={onAddTarget ? 9: 6}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Description
                                              </p>
                                            </Box>

                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                             
                                          <span>{i+1}</span>
                                              <div
                                                className="fc-input-container"
                                                style={{
                                                  marginRight: 3,
                                                  marginLeft: 3,
                                                  width: "100%",
                                                }}
                                              >
                                                <input
                                                  name={"description"}
                                                  value={val?.description}
                                                  onChange={(e) => {
                                                    // setFieldValue(`mainTaskArray.${[i]}.description`, e.target.value);
                                                    e.preventDefault();
                                                  }}
                                                  type={"text"}
                                                  className={`fc-input-style`}
                                                //   disabled={true}
                                                />
                                              </div>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={onAddTarget ? 1 : 1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Target<span style={{color: "red"}}>*</span>
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"target"}
                                                  value={val?.target}
                                                  onChange={(e) => {
                                                    const {value} = e?.target;
                                                    if(value > 100 || value <= 0)
                                                    {
                                                        setFieldValue(`mainTaskArray.[${i}].target`, "");
                                                    }
                                                    else
                                                    {
                                                        setFieldValue(`mainTaskArray.[${i}].target`, value);
                                                    }
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                  disabled={true}
                                                //   disabled={
                                                //     val?.Target_Id === 120
                                                //     // || val?.Target_Id === 110
                                                //     // || val?.Target_Id === 111
                                                //     // || val?.Target_Id === 113
                                                //     // || val?.Target_Id === 114
                                                //     // || val?.Target_Id === 115
                                                //     ? true : false
                                                //   }
                                                 />
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={onAddTarget ? 2 : 1}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                              <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                            Alloted Marks<span style={{color: "red"}}>*</span>
                                              </p>
                                            </Box>
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                                  name={"Alloted_Mark"}
                                                  value={val?.Alloted_Mark}
                                                  onChange={(e) => {
                                                    const {value} = e?.target;
                                                     console.log(typeof(value))
                                                     if(value > 100 || value <= 0)
                                                     {
                                                         setFieldValue(`mainTaskArray.[${i}].Alloted_Mark`, "");
                                                     }
                                                     else
                                                     {
                                                         setFieldValue(`mainTaskArray.[${i}].Alloted_Mark`, value);
                                                     }
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                />
                                            </div>
                                          </Grid>
                                         
                                          
                                        </Grid>
                                      );
                                    })}
                                  </>
                                </div>
                              )}
                            </Col>
                       </Form.Group>   
                       <Grid container columns={12}>
                                          <Grid item xs={12} sm={12} md={onAddTarget ? 8: 5}>
                                            <Box
                                              component={Grid}
                                              display={{ md: "none" }}
                                            >
                                             
                                            </Box>

                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginLeft: "25px"
                                              }}
                                            >
                                            <p
                                                style={{
                                                  margin: 0,
                                                  marginBottom: 3,
                                                }}
                                              >
                                                Total
                                              </p>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12} sm={12} md={onAddTarget ? 2 : 1}>
                                        
                                            <div>
                                            
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={onAddTarget ? 2 : 1}>
                                           
                                            <div
                                              className="fc-dropdown-container"
                                              style={{ marginRight: 3 }}
                                            >
                                             <input
                                              placeholder="Alloted Marks Total"
                                                  name={"Alloted Marks Total"}
                                                  value={totalAllotedMark}
                                                  onChange={(e) => {
                                                    e.preventDefault()
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                  disabled={true}
                                                />
                                            </div>
                                          </Grid>
                                         
                                        </Grid>
                       </Grid>
                </div>
                <br />
                </Grid>
                </Grid>
              <br />
              <br />
              <div className={classes.quotationButtonDivModal}>
                  <BootstrapTooltip arrow={true}

                      disableFocusListener
                      title={errors.target_month === undefined && errors.target_year === undefined &&
                          errors.target === undefined && errors.Alloted_Mark === undefined
                          ? ""
                          :
                          `${errors.target_month !== undefined ? `${errors.target_month},` : ""}
                          ${errors.target_year !== undefined ? `${errors.target_year},` : ""}
                           ${errors.target !== undefined ? `${errors.target},` : ""}
                           ${errors.Alloted_Mark !== undefined ? `${errors.Alloted_Mark},` : ""}`
                      }
                  >

                      <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{ backgroundColor: "#eb6e12" }}
                          onClick={handleSubmit}
                          disabled={totalAllotedMark > 100 || totalAllotedMark < 100
                            || (isPresent && !editModalPopper) ? true : false}
                      >
                          {loading ? "Loading...." : "Submit"}
                      </Button>
                  </BootstrapTooltip>
                  <Button
                      onClick={() => {
                          handleModaleClose();
                          // handleHide()
                      }}
                      variant="contained"
                      size="small"
                      style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                      }}
                  >
                      Cancel
                  </Button>
                  <br/>
                  {
                  totalAllotedMark > 100 || totalAllotedMark < 100 ? 
                  <>
                  <span style={{color: "red",fontSize: "16px"}}>Total Alloted Marks should be 100!</span>
                  </>
                   : isPresent && !editModalPopper ? 
                   <span style={{color: "red",fontSize: "16px"}}>Target for selected Month & Year is already added!</span>
                   :
                   ""
                 }
              </div>
          </div>
      </div>
  </Box>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default withStyles(ModalPopperFormStyles)(AdminTargetForm);
