import React from 'react'
import {
    Modal,
    Box,

} from '@mui/material'
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader'

const style = {
    position: 'absolute',
    top: '50%',
    left: '48.5%',
    transform: 'translate(-50%, -50%)',
    width: '60px',
    height: '60px',
    outline: 0,
    // border: '2px solid transparent',
};

const LoaderClock = ({ loading }) => {

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: '#eb6e12';
    box-shadow: inset '0px 0px 0px 7px #eb6e12';
  `;
    return (<>
        {
            loading ? (
                <Modal
                    open={loading}
                    style={{ border: 'none' }}
                >
                    <Box sx={style}>
                        <div >
                            <ClockLoader
                                css={override}
                                size={100}
                                color={"#eb6e12"}
                                loading={loading} />
                        </div>
                    </Box>
                </Modal >


            ) :
                ''
        }
    </>)
}

export default LoaderClock