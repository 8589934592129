import React, { useContext, useMemo, useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logo from "../../../../commonComp/assets/finallogo.png";
import ReactHtmlParser from "react-html-parser";
import EditIcon from "@mui/icons-material/Edit";
import "../styles/orderAlerts.css";
import moment from "moment";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import { headers } from "../../../../services/services";
import { SupportDataContext } from "../../../../provider/context";
import LoaderClock from "../../../lodar/LoaderClock";
import { TableContainer, Paper, IconButton } from "@mui/material";


const TodayOrderAlert = ({ date, height,classes, pagination }) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: height, width: "100%" }), []);
  const [gridApi] = useState(null);
  const [prepare, setPrepare] = useState(false);
  const {
    loader,
    setButtonText,
    handleModalOpen,
    momDownloadView,
    handleChangeTab,
    handleModalTableOpen,
    setOperationInfo,
    setEditModalPopper,
    setViewFollowUp,
    getAdminTodayAlertData,
    adminTodayAlertData
  } = useContext(SupportDataContext);
  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [adminTodayAlertData]);

  const emp_id = localStorage.getItem("emp_id");
  const __token = localStorage.getItem("token");
  const userTypeInfo = localStorage.getItem("userType");
  const factory_id = localStorage.getItem("factory_id");
  const actionBtn = (value) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            marginBottom: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomIconButton
            width={"auto"}
            icon={"Follow up History"}
            onClick={() => {
              setOperationInfo(value.data);
              handleModalTableOpen();
              setButtonText("Add/View Follow-up");
              handleChangeTab(0);
            }}
          />
          {value.data?.Followup_Status === "Approved" ? (
            <>
              <CustomIconButton
                title="view"
                icon={<RemoveRedEyeIcon />}
                onClick={() => {
                  setPrepare(true);
                  //momDownloadView(value.data);
                  setButtonText("view-Attended");
                  //handleModalOpen();
                }}
              />
              {/* <CustomIconButton
                title="download"
                icon={<DownloadIcon />}
                onClick={() => {
                  //downlodBtn(value.data);
                }}
              /> */}
            </>
          ) : value?.data?.Followup_Status === "MOM Created" ? (
            <>
              <CustomIconButton
                title="edit"
                icon={<EditIcon className="dataIcons" onClick={() => {}} />}
              />
            </>
          ) : (
              value?.data?.Followup_Type?.split(",").find(
                (i) => i === "Consultation Appointment"
              )
                ? true
                : false
            ) ? (
            <CustomIconButton
              width={"auto"}
              icon={"Done"}
              onClick={() => {
                setTimeout(() => {
                  setButtonText("Add-Done");
                  handleModalOpen();
                }, 2000);
              }}
            />
          ) 
          // : value.data?.followup_status === "Done" ? (
          //   <CustomIconButton
          //     icon={"Done"}
          //     width={"auto"}
          //     disabled={true}
          //   />
          // ) 
          : (
          
            <CustomIconButton
              width={"auto"}
              icon={"Add Comment"}
              onClick={() => {
                //setEditModalPopper(true);
                setViewFollowUp(value.data)
                setOperationInfo(value.data);
                setButtonText("Add Comment");
                handleModalOpen();
              }}
              disabled={value.data?.followup_status === "Done" ? true : false}
            />
          )
          }
        </div>
      </>
    );
  };
  const [columnDefs] = useState([
    {
      headerName: "S#",
      field: "Serial_Number",
      width: 70,
      // maxWidth: 90,
      pinned: "left",
      filter:false,
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Action",
      field: "action",
      filter: false,
      width: 250,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", paddingTop: 0 },
      cellRenderer: actionBtn,
    },
     {
      headerName: "Order No",
      field: "order_no",
      filter: "agTextColumnFilter",
      width: 140,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Followup Status",
      field: "followup_status",
      //filter: "agTextColumnFilter",
      width: 170,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Commented By Admin",
      field: "comment_by_admin",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Company Name",
      field: "company_name",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Follow Up Type",
      field: "followup_type",
     // filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Priority",
      field: "Priority",
      // filter: true,
      width: 160,
      maxWidth: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Due Date",
      field: "Due_Date",
      filter: "agDateColumnFilter",
      width: 150,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.Due_Date).format("DD-MM-YYYY")}</>;
      },
    //   pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Followup Message",
      field: "followup_message",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Support Name",
      field: "support_name",
      //filter: "agTextColumnFilter",
      minWidth: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
      flex:1
    },
    {
      headerName: "Created Date",
      field: "created_date",
      filter: "agDateColumnFilter",
      minWidth: 150,
      flex:1,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.created_date).format("DD-MM-YYYY hh:mm:ss")}</>;
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      minWidth: 50,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1
    };
  }, []);
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  useEffect(() => {
   
    getAdminTodayAlertData(date)
  }, []);
  return (
    // <div className={classes.modalTableSecondContainer}>
     
     <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "550px", maxHeight: "550px", overflow: "auto" }}
        >
        <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
          ref={gridRef}
          rowData={adminTodayAlertData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{
            background: "#DEDEDE",
            color: "#000000DE",
            borderBottom: "1px solid white",
          }}
          rowHeight={90}
          enableCellChangeFlash={true}
          components={components}
          pagination={pagination ? true : false}
        ></AgGridReact>
      </div>
     </TableContainer>
  
  );
};
export default TodayOrderAlert;
