import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../provider/context";
import InputText from "../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CustomSelect } from "../../../listing/listingForm/ListingForm";

const style = { 
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "350px",
  maxHeight: "350px",

  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddNOFOrderForm = ({ classes }) => {
  const {
    handleModaleClose,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getNOFDataListingApi,
  } = useContext(SupportDataContext);

  var today = new Date();
  const currentDate = moment(today).format("YYYY-MM-DD");
  
  const [formationTypeValue, setFormationTypeValue] = useState(null);
  const [orderIdValue, setOrderIdValue] = useState(null);
  const [stateIdValue, setStateIdValue] = useState(null);
  const [licenseTypeValue, setLicenseTypeValue] = useState(null);
  const [activityTypeValue, setActivityTypeValue] = useState(null);
  const [freezoneValue, setFreezoneValue] = useState(null);
  const [offshoreValue, setOffshoreValue] = useState(null);
  const [legalTypeValue, setLegalTypeValue] = useState(null);
  const [proFormationTypeValue, setPROFormationTypeValue] = useState(null);
  const [dedfzoffTypeValue, setDedFzOffTypeValue] = useState(null);
  const [proServicesName, setProServicesName] = useState(null);
  const [sowList, setSowList] = useState([]);
  const [orderId, setOrderId] =useState('');

  const addOperationNOFOrderFormPOSTPUTApi = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    values.scopeOfWork?.map((val, i) => {
      const isValueExist = values.tagName.find((tag) => tag.id === val.Id);
      const isValueExistIndex = values.tagName.findIndex((tag) => tag.id === val.Id);
      if(isValueExist) {
        values.scopeOfWork[i] = {
          ...values.scopeOfWork[i],
          Tag_Id: isValueExist.Tag_Id
        }
      }
    })

  
    let body = {
      emp_id: Number(emp_id),
      user_type: user_type,
      company_id: Number(values?.companyName),
      order_id:orderId,
      order_name:values?.orderName,
      type_of_order:values?.orderType,
      order_sub_type:values?.orderSubType,
      support_emp_id:Number(values?.supportId),
      typist_emp_id:Number(values?.typistId),
      scope_of_work:values?.scopeOfWork,
      document_required:values?.documentsRequired,
      inquiry_or_company_order_id:operationInfo?.new_order_form_id,
      company_inquiry_flag: operationInfo?.inquiry_id ? 1 : 2,
      state_id:Number(values?.state),
      formation_type:Number(values?.formationType),
    };

    if(values.formationType === 1){
      body = {
        ...body,
        license_type_id: Number(values?.licenseType),
        activity_type: Number(values?.activityType)
      }
    }


    if(values.formationType === 2){
      body = {
        ...body,
        freezone_name_id:values?.freezoneName,
        license_type_id: Number(values?.licenseType),
        activity_type: Number(values?.activityType)
      }
    }

    if(values.formationType === 3){
      body = {
        ...body,
        offshore_name_id:values?.offShoreName,
        legal_type:values?.legalType,
        activity_type: Number(values?.activityType)
      }
    }

    if(values.formationType === 4){
      body = {
        ...body,
        formation_type_for_pro_services:values?.proServices,
        ded_fz_offshore_name_id:values?.dedFzOffshore,
        pro_services_name_id:values?.proServiceName
      }
    }

    if(operationInfo?.inquiry_id) {
      body = {
        ...body,
        inquiry_id: operationInfo?.inquiry_id
      }
    }
  

    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500);
      } else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code);
      }
      setIsOpen(true);
      // handlePromise(data);
      getNOFDataListingApi();
      handleModaleClose();
    });
  };

  const [orderFormCompanyDropdown, setOrderFormCompanyDropdown] = useState([]);
  const getOrderFormonCompanyData = async (
    orderType,
    stateId,
    formationId,
    licenseType,
    activityType,
    freezoneName,
    offShoreName,
    legalType,
    proFormationTYpe,
    dedFzOffShoreName,
    proServiceName
  ) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/orderformoncompany/?company_inquiry_flag=${
        operationInfo?.inquiry_id ? 1 : 2
      }`;
      if (orderType) {
        url = `${url}&order_type=${orderType}`;
      }
      if (stateId) {
        url = `${url}&state_id=${stateId}`;
      }
      if (formationId) {
        url = `${url}&formation_type_id=${formationId}`;
      }
      if (
        (formationId === 1 && licenseType) ||
        (formationId === 2 && licenseType)
      ) {
        url = `${url}&license_type=${licenseType}`;
      }
      if (
        (formationId === 1 && activityType) ||
        (formationId === 2 && activityType) ||
        (formationId === 3 && activityType)
      ) {
        url = `${url}&activity_type=${activityType}`;
      }
      if (formationId === 2 && freezoneName) {
        url = `${url}&freezone_name=${freezoneName}`;
      }
      if (formationId === 3 && legalType) {
        url = `${url}&legal_type=${legalType}`;
      }
      if (formationId === 3 && offShoreName) {
        url = `${url}&offshore_name=${offShoreName}`;
      }
      if (formationId === 4 && proFormationTYpe) {
        url = `${url}&pro_formation_type=${proFormationTYpe}`;
      }
      if (formationId === 4 && dedFzOffShoreName) {
        url = `${url}&ded_fz_offshore_name=${dedFzOffShoreName}`;
      }
      if (formationId === 4 && proServiceName) {
        url = `${url}&pro_services_name=${proServiceName}`;
      }

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setOrderFormCompanyDropdown(res?.data?.Data);
      setSowList(res?.data?.Data?.SOW_List);
      setOrderId(res?.data?.Data?.Order_Id)
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const states =
    orderFormCompanyDropdown.Master_State !== undefined
      ? orderFormCompanyDropdown?.Master_State.map((i) => ({
          id: i.StateIds,
          value: i.StateIds,
          label: `${i.StateName}`,
        }))
      : [];

  const companyList =
    orderFormCompanyDropdown.Company_List !== undefined
      ? orderFormCompanyDropdown?.Company_List.map((i) => ({
          id: i.Company_Id,
          value: i.Company_Id,
          label: `${i.Company_Name}`,
        }))
      : [];

  const orderTypeDropdown =
    orderFormCompanyDropdown.Order_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Type.map((i) => ({
          id: i,
          value: i,
          label: `${i}`,
        }))
      : [];

  const formationType =
    orderFormCompanyDropdown.Master_type_of_company !== undefined
      ? orderFormCompanyDropdown?.Master_type_of_company.map((i) => ({
          id: i.Formation_type_id,
          value: i.Formation_type_id,
          label: `${i.Formation_type}`,
        }))
      : [];

  const licenseTypes =
    orderFormCompanyDropdown.License_Type !== undefined
      ? orderFormCompanyDropdown?.License_Type.map((i) => ({
          id: i.Ids,
          value: i.Ids,
          label: `${i.Name}`,
        }))
      : [];
  const legalTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Legal_Type !== undefined
      ? orderFormCompanyDropdown?.Legal_Type.map((i) => ({
          id: i.Ids,
          value: i.Ids,
          label: `${i.Name}`,
        }))
      : [];
  const activityGroupTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.Activity_Group !== undefined
      ? orderFormCompanyDropdown?.Activity_Group.map((i) => ({
          id: i.Ids,
          value: i.Ids,
          label: `${i.Name}`,
        }))
      : [];
  const freezoneTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown?.Freezone_Name !== undefined
      ? orderFormCompanyDropdown?.Freezone_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];
  const offshoreTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.Offshore_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];
  const proFormationTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.PRO_Formation_Type !== undefined
      ? orderFormCompanyDropdown?.PRO_Formation_Type.map((i) => ({
          value: i,
          label: `${i}`,
        }))
      : [];
  const ded_Fz_OfforeTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.DED_FZ_Offshore_Name !== undefined
      ? orderFormCompanyDropdown?.DED_FZ_Offshore_Name.map((i) => ({
          id: i.Id,
          value: i.Id,
          label: `${i.Name}`,
        }))
      : [];
  const proServiceNames =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.PRO_Service_Name !== undefined
      ? orderFormCompanyDropdown?.PRO_Service_Name.map((i) => ({
          id: i.Id,
          value: `${i.Name}`,
          label: `${i.Name}`,
        }))
      : [];

  const orderSubTypes =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Order_Sub_Type !== undefined
      ? orderFormCompanyDropdown?.Order_Sub_Type.map((i) => ({
          id: i.Serial_Number,
          value: `${i.order_sub_type}`,
          label: `${i.order_sub_type}`,
        }))
      : [];

  const tagNames =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Tag_Name_List !== undefined
      ? orderFormCompanyDropdown?.Tag_Name_List.map((i) => ({
          id: i.Tag_Id,
          value: `${i.Tag_Id}`,
          label: `${i.Tag_Name}`,
        }))
      : [];

  const supportList =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Support_List !== undefined
      ? orderFormCompanyDropdown?.Support_List.map((i) => ({
          id: i.EmpId,
          value: `${i.EmpId}`,
          label: `${i.Emp_Name}`,
        }))
      : [];

  const typistList =
    orderFormCompanyDropdown?.length !== 0 &&
    orderFormCompanyDropdown.Typist_List !== undefined
      ? orderFormCompanyDropdown?.Typist_List.map((i) => ({
          id: i.EmpId,
          value: `${i.EmpId}`,
          label: `${i.Emp_Name}`,
        }))
      : [];

  useEffect(() => {
    getOrderFormonCompanyData(
      orderIdValue,
      stateIdValue,
      formationTypeValue,
      licenseTypeValue,
      activityTypeValue,
      freezoneValue,
      offshoreValue,
      legalTypeValue,
      proFormationTypeValue,
      dedfzoffTypeValue,
      proServicesName
    );
  }, [
    orderIdValue,
    formationTypeValue,
    stateIdValue,
    licenseTypeValue,
    activityTypeValue,
    freezoneValue,
    offshoreValue,
    legalTypeValue,
    proFormationTypeValue,
    dedfzoffTypeValue,
    proServicesName,
  ]);

  const status_option = [
    {
      id: 0,
      label: "Approved",
      value: "Approved",
    },
    {
      id: 1,
      label: "Rejected",
      value: "Rejected",
    },
  ];


  const handleTagValue = (val, tagName) => {
    const finalVal = tagName.find((tag) => tag.id === val.id);
    return finalVal?.value;

  }

  return (
    <>
      <Formik
        //enableReinitialize={true}
        initialValues={{
          orderId: orderFormCompanyDropdown?.Order_Id,
          companyName: "",
          orderName: "",
          orderType: "",
          orderSubType: "",
          state: "",
          formationType: "",
          proServices: "",
          dedFzOffshore: "",
          proServiceName:"",
          freezoneName: "",
          offShoreName: "",
          legalType: "",
          licenseType: "",
          activityType: "",
          scopeOfWork: [],
          documentsRequired: [],
          supportId: "",
          typistId: "",
          tagName:[]
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addOperationNOFOrderFormPOSTPUTApi(data);
        }}
        validate={(values) => {
          let errors = {};
          if (!values.companyName) {
            errors.companyName = "required";
          }
          if (!values.orderName) {
            errors.orderName = "required";
          }
          if (!values.orderType) {
            errors.orderType = "required";
          }
          if (!values.orderSubType) {
            errors.orderSubType = "required";
          }
          if (!values.state) {
            errors.state = "required";
          }
           if (!values.formationType) {
            errors.formationType = "required";
          }
          //Mainland
          if(values.formationType === 1) {
            if (!values.activityType) {
              errors.activityType = "required";
            }
            if (!values.licenseType) {
              errors.licenseType = "required";
            }
          }
          //Freezone
          if(values.formationType === 2) {
            if (!values.freezoneName) {
              errors.freezoneName = "required";
            }
            if (!values.activityType) {
              errors.activityType = "required";
            }
            if (!values.licenseType) {
              errors.licenseType = "required";
            }
          }
          //Offshore
          if(values.formationType === 3) {
            if (!values.offShoreName) {
              errors.offShoreName = "required";
            }
            if (!values.activityType) {
              errors.activityType = "required";
            }
            if (!values.legalType) {
              errors.legalType = "required";
            }
          }
          //PRO Services
          if(values.formationType === 4) {
            if (!values.proServices) {
              errors.proServices = "required";
            }
            if (!values.dedFzOffshore) {
              errors.dedFzOffshore = "required";
            }
            if (!values.proServiceName) {
              errors.proServiceName = "required";
            }
          }
        
          if (!values.supportId) {
            errors.supportId = "required";
          }
          if (!values.typistId) {
            errors.typistId = "required";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();

              }}
            >
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      height: "450px",
                      overflowX: "hidden",
                      padding: "0",
                    }}
                  >
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10,
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            Add NOF Order Form
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.quotationInnerGridRight}
                            >
                              <InputText
                                label="Order Id"
                                fieldName="orderId"
                                fieldValue={orderId}
                             
                                disableField={true}
                                disabled={true}
                              />
                              <CustomDropdown
                                label="Company Name"
                                fieldOptions={companyList}
                                fieldValue={values?.companyName}
                                fieldHandleChange={(value) => {
                                  //setleadCaptionData(value.value);
                                  setFieldValue("companyName", value.value);
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.companyName
                                )}
                                requiredField={true}
                              />

                              <InputText
                                label="Order Name"
                                fieldName="orderName"
                                fieldValue={values?.orderName}
                                fieldHandleChange={(event) =>
                                  setFieldValue("orderName", event.target.value)
                                }
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Order Type"
                                fieldOptions={orderTypeDropdown}
                                fieldValue={values?.orderType}
                                fieldHandleChange={(value) => {
                                  //setleadCaptionData(value.value);
                                  setOrderIdValue(value.value);
                                  setFieldValue("orderType", value.value);
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.orderType
                                )}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="Order Sub Type"
                                fieldOptions={orderSubTypes}
                                fieldValue={values?.orderSubType}
                                fieldHandleChange={(value) => {
                                  //setleadCaptionData(value.value);
                                  setFieldValue("orderSubType", value.value);
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.orderSubType
                                )}
                                requiredField={true}
                              />
                              <CustomDropdown
                                label="State"
                                fieldOptions={states}
                                fieldValue={values?.state}
                                fieldHandleChange={(value) => {
                                  //setleadCaptionData(value.value);
                                  setStateIdValue(value.value);
                                  setFieldValue("state", value.value);
                                }}
                                errorField={getStyles(errors, values?.state)}
                                 requiredField={true}
                              />
                              <CustomDropdown
                                label="Formation Type"
                                fieldOptions={formationType}
                                fieldValue={values?.formationType}
                                fieldHandleChange={(value) => {
                                  setFormationTypeValue(value.value);
                                  setFieldValue("formationType", value.value);
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.formationType
                                )}
                                requiredField={true}
                              />
                              {formationTypeValue === 3 && (
                                <>
                                  <CustomDropdown
                                    label="Offshore Name"
                                    fieldOptions={offshoreTypes}
                                    fieldValue={values?.offShoreName}
                                    fieldHandleChange={(value) => {
                                      setOffshoreValue(value.value);
                                      setFieldValue(
                                        "offShoreName",
                                        value.value
                                      );
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.offShoreName
                                    )}
                                    requiredField={true}
                                  />
                                  <CustomDropdown
                                    label="Legal Type"
                                    fieldOptions={legalTypes}
                                    fieldValue={values?.legalType}
                                    fieldHandleChange={(value) => {
                                      setLegalTypeValue(value.value);
                                      setFieldValue("legalType", value.value);
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.legalType
                                    )}
                                    requiredField={true}
                                  />
                                </>
                              )}

                              {formationTypeValue === 2 && (
                                <>
                                  <CustomDropdown
                                    label="Freezone Name"
                                    fieldOptions={freezoneTypes}
                                    fieldValue={values?.freezoneName}
                                    fieldHandleChange={(value) => {
                                      setFreezoneValue(value.value);
                                      setFieldValue(
                                        "freezoneName",
                                        value.value
                                      );
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.freezoneName
                                    )}
                                    requiredField={true}
                                  />
                                </>
                              )}

                              {formationTypeValue === 4 && (
                                <>
                                  <CustomDropdown
                                    label="Select Formation Type for PRO Services"
                                    fieldOptions={proFormationTypes}
                                    fieldValue={values?.proServices}
                                    fieldHandleChange={(value) => {
                                      setPROFormationTypeValue(value.value);
                                      setFieldValue("proServices", value.value);
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.proServices
                                    )}
                                    requiredField={true}
                                  />
                                  <CustomDropdown
                                    label="Select DED/FZ/Offshore Name"
                                    fieldOptions={ded_Fz_OfforeTypes}
                                    fieldValue={values?.dedFzOffshore}
                                    fieldHandleChange={(value) => {
                                      setDedFzOffTypeValue(value.value);
                                      setFieldValue(
                                        "dedFzOffshore",
                                        value.value
                                      );
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.dedFzOffshore
                                    )}
                                    requiredField={true}
                                  />
                                  <CustomDropdown
                                    label="Pro Service Name"
                                    fieldOptions={proServiceNames}
                                    fieldValue={values?.proServiceName}
                                    fieldHandleChange={(value) => {
                                      setProServicesName(value.value);
                                      setFieldValue("proServiceName", value.value);
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.proServiceName
                                    )}
                                    requiredField={true}
                                  />
                                </>
                              )}
                              {!(formationTypeValue === 3 ||
                                formationTypeValue === 4) && (
                                <>
                                  <CustomDropdown
                                    label="License Type"
                                    fieldOptions={licenseTypes}
                                    fieldValue={values?.licenseType}
                                    fieldHandleChange={(value) => {
                                      setLicenseTypeValue(value.value);
                                      setFieldValue("licenseType", value.value);
                                    }}
                                    errorField={getStyles(
                                      errors,
                                      values?.licenseType
                                    )}
                                     requiredField={true}
                                  />
                                </>
                              )}
                              {formationTypeValue !== 4 && (
                                <CustomDropdown
                                  label="Activity Type"
                                  fieldOptions={activityGroupTypes}
                                  fieldValue={values?.activityType}
                                  fieldHandleChange={(value) => {
                                    setActivityTypeValue(value.value);
                                    setFieldValue("activityType", value.value);
                                  }}
                                  errorField={getStyles(
                                    errors,
                                    values?.activityType
                                  )}
                                  requiredField={true}
                                />
                              )}
                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"sow"}
                              >
                                <Form.Label
                                  column
                                  sm="5"
                                  className="fc-dropdown-label"
                                >
                                  Select Scope Of Work
                                  <sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                
                                  {sowList?.length >
                                    1 && (
                                    <div className="fc-dropdown-container" style={{padding:"10px"}}>
                                          <Grid container columns={12}>
                                          <Grid item  xs={12} sm={12} md={1}>
                                      
                                    </Grid>
                                    <Grid item  xs={12} sm={12} md={4}>
                                      <p>Name</p>
                                    </Grid>
                                    <Grid item  xs={12} sm={12} md={1}>
                                      <p>Days</p>
                                    </Grid>
                                    <Grid item  xs={12} sm={12} md={2}>
                                      <p>Sequence</p>
                                    </Grid>
                                    <Grid item  xs={12} sm={12} md={4}>
                                      <p>Tag Name</p>
                                    </Grid>
                                  </Grid>
                                      {sowList?.map(
                                        (val, i) => {
                                          return (
                                            <Grid container columns={12}>
                                              <Grid item  xs={12} sm={12} md={1}>
                                              <Form.Check
                                                type="checkbox"
                                                name="scopeOfWork"
                                                value={val?.id}

                                                onClick={(e) => {
                                                  const currVal = values.scopeOfWork;
                                                  if(e.target.checked) {
                                                    let obj = {
                                                      Id: val.id,
                                                      Name:val.name,
                                                      Days:val.days,
                                                      Sequence:val.sequence
                                                    }
                                                    currVal.push(obj);
                                                  }else{
                                                    let isValueExist = currVal.findIndex((doc) => doc.id === val.id);
                                                    currVal.splice(isValueExist, 1);
                                                  }
                                               
                                                  setFieldValue("scopeOfWork", currVal)
                                                }}
                                            />
                                              </Grid>
                                              <Grid item  xs={12} sm={12} md={4}>
                                                <div className="fc-input-container" style={{marginRight:3}}>
                                                <input
                                                  name={"Name"}
                                                  value={val?.name}
                                                  onChange={(e) => {
                                                    let  fina = [...sowList];
                                                    fina[i] = {
                                                      ...fina[i],
                                                      name: e.target.value
                                                    }
                                                    setSowList(fina)
                                                  }}
                                                  type={"text"}
                                                  className={`fc-input-style`}
                                                />
                                                </div>
                                               
                                              </Grid>
                                              <Grid item  xs={12} sm={12} md={1}>
                                              <div className="fc-input-container" style={{marginRight:3}}>
                                              <input
                                                  name={"Days"}
                                                  value={val?.days}
                                                  onChange={(e) => {
                                                    let  fina = [...sowList];
                                                    fina[i] = {
                                                      ...fina[i],
                                                      days: parseInt(e.target.value)
                                                    }
                                                    setSowList(fina)
                                                  }}
                                                  type={"number"}
                                                  className={`fc-input-style`}
                                                />
                                              </div>
                                            
                                              </Grid>
                                              <Grid item  xs={12} sm={12} md={2}>
                                              <div className="fc-input-container" style={{marginRight:3}}>
                                                <input
                                                  name={"Sequence"}
                                                  value={val?.sequence}
                                                  onChange={(e) => {
                                                    let  fina = [...sowList];
                                                    fina[i] = {
                                                      ...fina[i],
                                                      sequence: parseInt(e.target.value)
                                                    }
                                                    setSowList(fina)
                                                  }}
                                                  type={"text"}
                                                  className={`fc-input-style`}
                                                />
                                                </div>
                                              </Grid>
                                              <Grid item  xs={12} sm={12} md={4}>
                                                <div
                                                  className="fc-dropdown-container"
                                                 
                                                >
                                                  <CustomSelect
                                                    value={handleTagValue(val, values.tagName)}
                                                    onChange={(value) => {
                                                      let currVal = values?.tagName;
                                                      let isValueExist = currVal.findIndex((tag) => tag.id === val.id);
                                                      let isValueExist1= currVal.find((tag) => tag.id === val.id);
                                                      
                                                      if(isValueExist1) {
                                                        currVal[isValueExist] = {
                                                          ...currVal[isValueExist],
                                                          Tag_Id: value.id,
                                                          value: value.value
                                                        }
                                                      } else {
                                                        let newObj = {
                                                          Tag_Id:value.id,
                                                          id: val.id,
                                                          value: value.value
                                                        };
                                                        currVal.push(newObj);
                                                      }
                                                     
                                                      
                                                      setFieldValue(
                                                        "tagName",
                                                        currVal
                                                      );
                                                  
                                                    }}
                                                    options={tagNames}
                                                  />
                                                </div>
                                              </Grid>
                                            </Grid>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"sow"}
                              >
                                <Form.Label
                                  column
                                  sm="5"
                                  className="fc-dropdown-label"
                                >
                                  Documents Required{" "}
                                  <sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                  {orderFormCompanyDropdown
                                    ?.Document_Required_List?.length > 0 && (
                                    <div
                                      className="fc-documents-required"
                                      style={getStyles(
                                        errors,
                                        values?.documentsRequired
                                      )}
                                    >
                                      {orderFormCompanyDropdown?.Document_Required_List?.map(
                                        (val) => {
                                          return (
                                            <Form.Check
                                              type="checkbox"
                                              style={{ width: "270px" }}
                                              label={val?.name}
                                              name="documentsRequired"
                                              value={val?.id}
                                              onClick={(e) => {
                                                const currVal = values.documentsRequired;
                                                if(e.target.checked) {
                                                  currVal.push(val);
                                                }else{
                                                  let isValueExist = currVal.findIndex((doc) => doc.id === val.id);
                                                  currVal.splice(isValueExist, 1);
                                                }

                                                setFieldValue("documentsRequired", currVal)
                                              }}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Form.Group>

                              <CustomDropdown
                                label="Support"
                                fieldOptions={supportList}
                                fieldValue={values?.supportId}
                                fieldHandleChange={(value) => {
                                  setFieldValue("supportId", value.value);
                                }}
                                errorField={getStyles(
                                  errors,
                                  values?.supportId
                                )}
                                requiredField={true}
                              />

                              <CustomDropdown
                                label="Typist"
                                fieldOptions={typistList}
                                fieldValue={values?.typistId}
                                fieldHandleChange={(value) => {
                                  setFieldValue("typistId", value.value);
                                }}
                                errorField={getStyles(errors, values?.typistId)}
                                requiredField={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>
                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: "#eb6e12" }}
                        onClick={handleSubmit}
                      >
                        {"Submit"}
                      </Button>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(AddNOFOrderForm);
