import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { BootstrapInput } from "../../../utils/BootstrapInput";

const LeadsAdvancedFilter = ({
  advancedFilterData,
  setIsDropdownClose,
  setSavedAdvancedFilter,
  savedValue,
  gridRef,
  leadSourceFilterValues,
}) => {
  const [leadFromDateValues, setLeadFromDateValues] = useState([]);
  const [leadToDateValues, setLeadToDateValues] = useState([]);
  const [leadSourceValues, setLeadSourceValues] = useState([]);
  const [receiveThroughValues, setReceiveThroughValues] = useState([]);
  const [leadCaptionValues, setLeadCaptionValues] = useState([]);
  const [conversationalStatusValues, setConversationalStatusValues] = useState(
    []
  );
  const [salesPersonValues, setSalesPersonValues] = useState([]);
  const [compaignNameValues, setCompaignNameValues] = useState([]);
  const [typeOfCompanyValues, setTypeOfCompanyValues] = useState([]);
  const [isLeadSourceValueSelected, setIsLeadSourceValueSelected] =
    useState(false);
  const [referralSourceValues, setReferralSourceValues] = useState([]);
  const [referralValue, setReferralValue] = useState([]);
  const [countryValues, setCountryValues] = useState([]);
  const handleEventAddApply = (event, picker) => {
    const fromDate = moment(picker.startDate).format().slice(0, 10);
    const toDate = moment(picker.endDate).format().slice(0, 10);
    setLeadFromDateValues(fromDate);
    setLeadToDateValues(toDate);

    gridRef.current.api.getFilterInstance("Enquiry_Date").setModel({
      type: "greaterThan",
      dateFrom: fromDate,
      dateTo: toDate,
    });
    gridRef.current.api.onFilterChanged();

    // const finalValues = apiData.filter((val) => {
    //   if (val.Enquiry_Date >= fromDate && val.Enquiry_Date <= toDate) {
    //     return val;
    //   }
    // });
    // handleFilterData(finalValues);
  };

  useEffect(() => {
    if (savedValue !== null) {
      const value = JSON.parse(savedValue);
      setLeadFromDateValues(value.leadFromDateValues);
      setLeadToDateValues(value.leadToDateValues);
      setLeadSourceValues(value.leadSourceValues);
      setReceiveThroughValues(value.receiveThroughValues);
      setLeadCaptionValues(value.leadCaptionValues);
      setConversationalStatusValues(value.conversationalStatusValues);
      setSalesPersonValues(value.salesPersonValues);
      setCompaignNameValues(value.compaignNameValues);
      setTypeOfCompanyValues(value.typeOfCompanyValues);
      setReferralSourceValues(value.referraSourceValues);
      setCountryValues(value.countryValues);
    }
  }, [savedValue]);

  const handleConversationStatusChange = (e) => {
    setConversationalStatusValues(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Conversation_Status")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Conversation_Status")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  const handleleadSourcechange = (e) => {
    setLeadSourceValues(e.target.value);
    if (e.target.value.includes("Referral")) {
      setIsLeadSourceValueSelected(true);
      return handleReferralSourceAPI();
    } else {
      setIsLeadSourceValueSelected(false);
    }
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Enquiry_Source")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Enquiry_Source")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  const handleReferralSourceAPI = async () => {
    const __token = sessionStorage.getItem("token");
    await fetch("https://flyingcolour.online/fcapp/api/dropdownleadfilters/", {
      method: "GET",
      headers: {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setReferralSourceValues(data.results?.master_referral_source);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlereceivedThroughchange = (e) => {
    setReceiveThroughValues(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Lead_Received_Through")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Lead_Received_Through")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  const handleleadCaptionChange = (e) => {
    setLeadCaptionValues(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Lead_Caption")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Lead_Caption")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  const handlesalesPersonchange = (e) => {
    setSalesPersonValues(e.target.value);

    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Consultant_Name")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api.getFilterInstance("Consultant_Name").setModel({
      type: "contains",
      filter: e.target.value.flat(),
    });
    gridRef.current.api.onFilterChanged();
  };

  const handleCompaignNamechange = (e) => {
    setCompaignNameValues(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Campaign_name")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Campaign_name")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };
  const handleTypeOfchange = (e) => {
    setTypeOfCompanyValues(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Type_of_Company")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Type_of_Company")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  const handleCountrychange = (e) => {
    setCountryValues(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Client_Country_Name")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Client_Country_Name")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  const handleReferralChange = (e) => {
    setReferralValue(e.target.value);
    if (e.target.value.length <= 0) {
      gridRef.current.api
        .getFilterInstance("Enquiry_Referral_Source")
        .setModel({ values: null });
      return gridRef.current.api.onFilterChanged();
    }

    gridRef.current.api
      .getFilterInstance("Enquiry_Referral_Source")
      .setModel({ values: e.target.value });
    gridRef.current.api.onFilterChanged();
  };

  useEffect(() => {
    let data = {
      countryValues: countryValues,
      leadSourceValues: leadSourceValues,
      receiveThroughValues: receiveThroughValues,
      leadCaptionValues: leadCaptionValues,
      conversationalStatusValues: conversationalStatusValues,
      salesPersonValues: salesPersonValues,
      compaignNameValues: compaignNameValues,
      typeOfCompanyValues: typeOfCompanyValues,
      referraSourceValues: referralValue,
      leadFromDateValues: leadFromDateValues,
      leadToDateValues: leadToDateValues,
    };

    let finalData = gridRef.current.api.getFilterModel();

    setSavedAdvancedFilter(finalData)
    if (countryValues.length > 0 || leadSourceValues.length > 0 || receiveThroughValues.length > 0 || leadCaptionValues.length > 0 || conversationalStatusValues.length > 0 || salesPersonValues.length > 0 || compaignNameValues.length > 0 || typeOfCompanyValues.length > 0) {
    } else {

return null    }
  }, [countryValues, referralValue, leadSourceValues, receiveThroughValues, leadCaptionValues, conversationalStatusValues, salesPersonValues, compaignNameValues, typeOfCompanyValues])



  return (
    <>
      <Row style={{ padding: "1rem 2rem" }}>
        <Col xs={12} md={6}>
          <Form.Group as={Row} className="mb-3" controlId="RefId">
            <Form.Label column sm="5">
              Lead Date
            </Form.Label>
            <Col sm="7">
              <DateRangePicker onApply={handleEventAddApply}>
                <input
                  type="text"
                  className="form-control"
                  value={`${leadFromDateValues} - ${leadToDateValues}`}
                />
              </DateRangePicker>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Lead Source
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={leadSourceValues}
                onChange={handleleadSourcechange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(
                  leadSourceFilterValues?.Dropdown_Lead_Source_For_Filter || []
                ).map((val) => (
                  <MenuItem key={val} value={val}>
                    <Checkbox checked={leadSourceValues.indexOf(val) > -1} />
                    <ListItemText primary={val} />
                  </MenuItem>
                ))}
              </Select>
            </Col>
          </Form.Group>

          {isLeadSourceValueSelected && (
            <Form.Group as={Row} className="mb-3" controlId="DocumentType">
              <Form.Label column sm="5">
                Referral Source
              </Form.Label>
              <Col sm="7">
                <Select
                  multiple
                  displayEmpty
                  value={referralValue}
                  onChange={handleReferralChange}
                  input={<BootstrapInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select...</em>;
                    }
                    return selected.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  style={{ fontSize: "12px", width: "100%" }}
                >
                  <MenuItem disabled value="">
                    <em>Select...</em>
                  </MenuItem>
                  {(referralSourceValues || []).map((val) => (
                    <MenuItem
                      key={val.referral_source_name}
                      value={val.referral_source_name}
                    >
                      <Checkbox
                        checked={
                          referralValue.indexOf(val.referral_source_name) > -1
                        }
                      />
                      <ListItemText primary={val.referral_source_name} />
                    </MenuItem>
                  ))}
                </Select>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Receive Through
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={receiveThroughValues}
                onChange={handlereceivedThroughchange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.lead_record_through || []).map(
                  (val) => (
                    <MenuItem
                      key={val.Lead_Received_Through}
                      value={val.Lead_Received_Through}
                    >
                      <Checkbox
                        checked={
                          receiveThroughValues.indexOf(
                            val.Lead_Received_Through
                          ) > -1
                        }
                      />
                      <ListItemText primary={val.Lead_Received_Through} />
                    </MenuItem>
                  )
                )}
              </Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Lead Caption
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={leadCaptionValues}
                onChange={handleleadCaptionChange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.lead_caption || []).map((val) => (
                  <MenuItem
                    key={val.lead_caption_name}
                    value={val.lead_caption_name}
                  >
                    <Checkbox
                      checked={
                        leadCaptionValues.indexOf(val.lead_caption_name) > -1
                      }
                    />
                    <ListItemText primary={val.lead_caption_name} />
                  </MenuItem>
                ))}
              </Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Conversation Status
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={conversationalStatusValues}
                onChange={handleConversationStatusChange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.conversation_status || []).map(
                  (val) => (
                    <MenuItem
                      key={val.Conversation_Status}
                      value={val.Conversation_Status}
                    >
                      <Checkbox
                        checked={
                          conversationalStatusValues.indexOf(
                            val.Conversation_Status
                          ) > -1
                        }
                      />
                      <ListItemText primary={val.Conversation_Status} />
                    </MenuItem>
                  )
                )}
              </Select>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Sales Person/Client Manager
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={salesPersonValues}
                onChange={handlesalesPersonchange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.lead_sales_person || []).map(
                  (val) => (
                    <MenuItem
                      key={val.Consultant_Name}
                      value={val.Consultant_Name}
                    >
                      <Checkbox
                        checked={
                          salesPersonValues.indexOf(val.Consultant_Name) > -1
                        }
                      />
                      <ListItemText primary={val.Consultant_Name} />
                    </MenuItem>
                  )
                )}
              </Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Compaign Name
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={compaignNameValues}
                onChange={handleCompaignNamechange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.campaign_name || []).map((val) => (
                  <MenuItem key={val.Campaign_name} value={val.Campaign_name}>
                    <Checkbox
                      checked={
                        compaignNameValues.indexOf(val.Campaign_name) > -1
                      }
                    />
                    <ListItemText primary={val.Campaign_name} />
                  </MenuItem>
                ))}
              </Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Type of Company
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={typeOfCompanyValues}
                onChange={handleTypeOfchange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.type_of_company || []).map(
                  (val) => (
                    <MenuItem
                      key={val.Formation_type}
                      value={val.Formation_type}
                    >
                      <Checkbox
                        checked={
                          typeOfCompanyValues.indexOf(val.Formation_type) > -1
                        }
                      />
                      <ListItemText primary={val.Formation_type} />
                    </MenuItem>
                  )
                )}
              </Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="DocumentType">
            <Form.Label column sm="5">
              Current Country Name
            </Form.Label>
            <Col sm="7">
              <Select
                multiple
                displayEmpty
                value={countryValues}
                onChange={handleCountrychange}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select...</em>;
                  }
                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
                style={{ fontSize: "12px", width: "100%" }}
              >
                <MenuItem disabled value="">
                  <em>Select...</em>
                </MenuItem>
                {(advancedFilterData?.Data?.country || []).map((val) => (
                  <MenuItem key={val.country_name} value={val.country_name}>
                    <Checkbox
                      checked={countryValues.indexOf(val.country_name) > -1}
                    />
                    <ListItemText primary={val.country_name} />
                  </MenuItem>
                ))}
              </Select>
            </Col>
          </Form.Group>

          <button
            style={{
              padding: "0.5rem",
              background: "#EB6812",
              margin: "1rem 0",
              border: "1px solid #EB6812",
              color: "#fff",
              float: "right",
            }}
            onClick={() => setIsDropdownClose(false)}
          >
            Close
          </button>
        </Col>
      </Row>
    </>
  );
};

export default LeadsAdvancedFilter;
