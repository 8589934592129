import React, { useContext, useEffect } from "react";
import modalTableStyles from "../Styles/ModalTableStyles";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import RescheduleListingTable from "./GeneralSchedule/RescheduleListingTable";
import ViewCompanyDocuments from "../Listing/ViewCompanyDocuments";
import WorkDoneListing from "./PRORequistionModals/WorkDoneListing";
import ExtendedPROHeadListing from "./PRORequistionModals/ExtendedPROHeadListing";
import LogsListing from "./PRORequistionModals/LogsListing";

const ModalTable = ({ classes, data }) => {
  const { quotationApiMsg, expiredErrorMsg, salesInfo } =
    useContext(DataContext);
  const {
    handleModalOpen,
    buttonText,
    handleModalTableClose,
    setButtonText,
    operationInfo,
    setEditModalPopper,
    freezeLogData,
    followUp,
    isCompleteOrderBtnDisable,
    requestOrderCompletedData,
    setIsCompleteOrderBtnDisable,
  } = useContext(SupportDataContext);

  // const currDueDate = followUp?.Order_Followup_List?.[followUp?.Order_Followup_List.length - 1]?.Due_Date;

  const handleModalOpenSub = (e) => {
    const buttonText = e.currentTarget.innerText;
    setEditModalPopper(false);
    handleModalOpen();
  };

  return (
    <div className={classes.TabLowerContainerModalTable}>
      <div>
        {[
          "Add-Done",
          "Reschedule Schedule Form",
          "View Document",
          "PRO Work Done",
          "Requistion Extended by PRO Head",
          "View Logs"
        ].includes(buttonText) ? null : (
          <>
            {[
              "Add / View Follow-up",
              "Add / View Comments",

              // "Requestion Log",
            ].includes(buttonText) ? (
              <>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px" }}
                >
                  <span style={{ marginTop: "2px" }}>
                    Order No:{" "}
                    {operationInfo.Order_No || operationInfo.auto_order_id}
                  </span>
                </p>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px" }}
                >
                  <span style={{ marginTop: "2px" }}>
                    Company Name:{" "}
                    {operationInfo.Company_Name || operationInfo.company_name}
                  </span>
                </p>
              </>
            ) : (
              <Button
                variant="contained"
                className={classes.NewButton}
                onClick={(e) => handleModalOpenSub(e)}
                disabled={isCompleteOrderBtnDisable ? true : false}
              >
                <AddIcon style={{ marginLeft: "-10px" }} />{" "}
                <span style={{ marginTop: "2px" }}> {buttonText}</span>
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#fff",
                color: "#eb6e12",
                marginLeft: "10px",
                padding: "7px 8px",
              }}
              onClick={() => {
                setIsCompleteOrderBtnDisable(false);
                handleModalTableClose();
              }}
            >
              <span style={{ marginTop: "2px", marginRight: "5px" }}>
                Close
              </span>
              <CloseIcon />
            </Button>
            {expiredErrorMsg ? (
              <div className={classes.tableMsgContaner}>
                <span className={classes.tableMsgHeader}>
                  {quotationApiMsg}
                </span>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className={classes.BorderSpacingUpperModaleTable}></div>
      {buttonText === "Reschedule Schedule Form" ? (
        <RescheduleListingTable />
      ) : buttonText === "View Document" ? (
        <ViewCompanyDocuments />
      ) : buttonText === "PRO Work Done" ? (
        <WorkDoneListing />
      ) :buttonText === "Requistion Extended by PRO Head" ? (
        <ExtendedPROHeadListing />
      ) : buttonText === "View Logs" ? (
        <LogsListing />
      ) : null}
      <div className={classes.BorderSpacingLowerModaleTable}></div>
    </div>
  );
};

export default withStyles(modalTableStyles)(ModalTable);
