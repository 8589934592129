import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding: "25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  overflow: "auto",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};

const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const OffshoreForm = ({ classes, getInfo }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    setIsOpen,
    editModalPopper,
    offshoreWorkFollowData,
    operationInfo,
    getOffshoreWorkFollowDataFromApi
  } = useContext(SupportDataContext);

  const [stateType, setStateType] = useState("");
  const [offshoreType, setOffshoreType] = useState("");

  const stateList = offshoreWorkFollowData?.State_List?.map((item) => {
    const obj = { value: item.StateIds, label: item.StateName };
    return obj;
  });

  const offshoreList = offshoreWorkFollowData?.Offshore_Name_list?.map((item) => {
    const obj = { value: item.Id, label: item.Name };
    return obj;
  });

  const legalList = offshoreWorkFollowData?.Legal_Type_list?.map((item) => {
    const obj = { value: item.Ids, label: item.Name };
    return obj;
  });

  const activityList = offshoreWorkFollowData?.Activity_Name_list?.map(
    (item) => {
      const obj = { value: item.Ids, label: item.Name };
      return obj;
    }
  );

  const addOffshorePostPUTAPI = async (values) => {
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    //https://flyingcolour.online/fcapp/support_api/clientcomplaintlist/
    const url = `https://flyingcolour.online/fcapp/docprocessing_api/dcoffshoreworkflow/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    let days = [],
      names = [],
      sequence = [];
    values.workflows.forEach((val) => {
      days.push(String(val?.days));
      names.push(val?.name);
      sequence.push(String(val?.sequence));
    });
    let body = {
      emp_id: emp_id,
      state_id: values?.state,
      legal_type_id: values.legal_type,
      offshore_name_id:values?.offshoreType, 
      activity_type_id: values.activity_name,
      status: Number(values.status),
    };

    if (editModalPopper) {
      body = {
        ...body,
        workflow_name: values?.workflows?.[0]?.name,
        workflow_days: Number(values?.workflows?.[0]?.days),
        workflow_sequence: Number(values?.workflows?.[0]?.sequence),
        workflow_id: operationInfo?.id,
      };

      axios.put(url, body, { headers: headers }).then((data) => {
        setLoading(false);

        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        handlePromise(data);
      });
    } else {
      body ={
        ...body,
        workflow_name: names,
        workflow_days: days,
        workflow_sequence: sequence,
      }
      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        handlePromise(data);
      });
    }
  };

  useEffect(() => {
    if (stateType || offshoreType) {
      getOffshoreWorkFollowDataFromApi('', stateType, offshoreType);
    }
  }, [stateType, offshoreType]);

  useEffect(() => {
    if (editModalPopper) {
      getOffshoreWorkFollowDataFromApi(
        operationInfo?.legal_type_id,
        operationInfo?.state_id,
        operationInfo?.offshore_name_id
      );
    } else {
      getOffshoreWorkFollowDataFromApi();
    }
  }, []);

  const handlePromise = (data) => {
    // getAddDeveloperFormDataApi();
    handleModaleClose();
    getOffshoreWorkFollowDataFromApi();
  };

  const handleWorkflows = () => {
    let finalValue = [
      {
        name: operationInfo?.workflow_name,
        days: operationInfo?.workflow_days,
        sequence: operationInfo?.workflow_sequence,
      },
    ];

    return finalValue;
  };
  return (
    <>
      <Formik
        enableReinitialize={editModalPopper ? true : false}
        initialValues={{
          state: editModalPopper ? operationInfo?.state_id : "",
          legal_type: editModalPopper ? operationInfo?.legal_type_id : "",
          offshoreType: editModalPopper ? operationInfo?.offshore_name_id : "",
          activity_name: editModalPopper ? operationInfo?.activity_type_id : "",
          workflows: editModalPopper
            ? handleWorkflows()
            : [
                {
                  name: "",
                  days: "",
                  sequence: "",
                },
              ],
          status: editModalPopper ? String(operationInfo?.status) : "",
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addOffshorePostPUTAPI(data);
        }}
        validate={(values) => {
          let errors = {};

          if (!values.state) {
            errors.state = "Required!";
          }
          if (!values.legal_type) {
            errors.legal_type = "Required!";
          }
          if (!values.activity_name) {
            errors.activity_name = "Required!";
          }
          if (!values.offshoreType) {
            errors.offshoreType = "Required!";
          }
          if (!values.status) {
            errors.status = "Required!";
          }

          values?.workflows.forEach((val, i) => {
            if (!val.name) {
              errors.name = "Required!";
            }
            if (!val.days) {
              errors.days = "Required!";
            }
            if (!val.sequence) {
              errors.sequence = "Required!";
            }
          })

          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box sx={style}>
                <div>
                  <div style={{ height: "36px" }}>
                    <IconButton
                      style={{ float: "right" }}
                      className={classes.quotationModalHeaderIconCancle}
                      onClick={() => {
                        handleModaleClose();
                      }}
                    >
                      <CloseIcon
                        className={classes.quotationModalHeaderIconCancleIcon}
                      />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      height: "480px",
                      overflowX: "hidden",
                      padding: "0",
                    }}
                  >
                    <Grid container spacing={2} columns={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: "-30px",
                          paddingBottom: "10px",
                          marginTop: "-45px",
                          zIndex: 10,
                        }}
                      >
                        <br />
                        <div className={classes.quotationModalHeadingContainer}>
                          <span className={classes.quotationModalHeader}>
                            {editModalPopper
                              ? "Edit Offshore Scope Of Work"
                              : "Add Offshore Scope Of Work"}
                          </span>
                        </div>
                        <br />
                        <div className={classes.quotationModalFlexDivContaner}>
                          <Grid container>
                            <Grid item md={12} xs={12}>
                              <CustomDropdown
                                label="State"
                                fieldOptions={stateList}
                                fieldValue={values?.state}
                                fieldHandleChange={(value) => {
                                  setFieldValue("state", value.value);
                                  setStateType(value.value);
                                }}
                                errorField={getStyles(errors, values?.state)}
                                requiredField={true}
                              />

                              <InputText
                                label="Formation Type"
                                fieldName="formation_Type"
                                fieldValue={"Offshore"}
                                disableField={true}
                                disabled={true}
                              />
                                 <CustomDropdown
                                label="Offshore Type"
                                fieldOptions={offshoreList}
                                fieldValue={values?.offshoreType}
                                fieldHandleChange={(value) => {
                                  setFieldValue("offshoreType", value.value);
                                  setOffshoreType(value.value);
                                }}
                                requiredField={true}
                                errorField={getStyles(
                                  errors,
                                  values?.offshoreType
                                )}
                              />

                              <CustomDropdown
                                label="Legal Type"
                                fieldOptions={legalList}
                                fieldValue={values?.legal_type}
                                fieldHandleChange={(value) => {
                                  setFieldValue("legal_type", value.value);
                                }}
                                requiredField={true}
                                errorField={getStyles(
                                  errors,
                                  values?.legal_type
                                )}
                              />
                              <CustomDropdown
                                label="Activity Name"
                                fieldOptions={activityList}
                                fieldValue={values?.activity_name}
                                fieldHandleChange={(value) => {
                                  setFieldValue("activity_name", value.value);
                                }}
                                requiredField={true}
                                errorField={getStyles(
                                  errors,
                                  values?.activity_name
                                )}
                              />

                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"status"}
                              >
                                <Form.Label
                                  column
                                  sm="5"
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "12px",
                                  }}
                                >
                                  Workflows <sup style={{color:'red'}}>*</sup>
                                </Form.Label>
                                <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                  <Grid container>
                                    <Grid item xs={12} md={5}>
                                      <p style={{margin:0, fontSize:"12px", fontWeight:700}}>Scope Of Work</p>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                    <p style={{margin:0, fontSize:"12px", fontWeight:700}}>Days</p>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                    <p style={{margin:0, fontSize:"12px", fontWeight:700}}>Sequence</p>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                      
                                    </Grid>
                                  </Grid>
                                  <FieldArray
                                    name="workflows"
                                    render={(arrayHelpers) => (
                                      <>
                                        {values?.workflows.length > 0 &&
                                          values?.workflows?.map((val, i) => {
                                            return (
                                              <Grid container>
                                                <Grid item xs={12} md={5}>
                                                  <input
                                                    style={{
                                                      width: "98%",
                                                      border:
                                                        "0.1px solid #d3d3d3",
                                                      padding: "4.6px 10px",
                                                    }}
                                                    placeholder="Name"
                                                    type="text"
                                                    name="Name"
                                                    value={val?.name}
                                                    onChange={(e) => {
                                                      // handleChangeInput(item?.id, event)
                                                      e.preventDefault();
                                                      const { value } =
                                                        e.target;
                                                      setFieldValue(
                                                        `workflows.[${i}].name`,
                                                        value
                                                      );
                                                    }}
                                                  />
                                                   <span style={{ color: "red" }}>
                                                      {errors.name}
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <input
                                                    style={{
                                                      width: "98%",
                                                      border:
                                                        "0.1px solid #d3d3d3",
                                                      padding: "4.6px 10px",
                                                    }}
                                                    placeholder="Days"
                                                    type="number"
                                                    name="days"
                                                    value={val?.days}
                                                    onChange={(e) => {
                                                      // handleChangeInput(item?.id, event)
                                                      e.preventDefault();
                                                      const { value } =
                                                        e.target;
                                                      setFieldValue(
                                                        `workflows.[${i}].days`,
                                                        value
                                                      );
                                                    }}
                                                  />
                                                   <span style={{ color: "red" }}>
                                                      {errors.days}
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                  <input
                                                    style={{
                                                      width: "98%",
                                                      border:
                                                        "0.1px solid #d3d3d3",
                                                      padding: "4.6px 10px",
                                                    }}
                                                    placeholder="Sequence"
                                                    type="number"
                                                    name="sequence"
                                                    value={val?.sequence}
                                                    onChange={(e) => {
                                                      // handleChangeInput(item?.id, event)
                                                      e.preventDefault();
                                                      const { value } =
                                                        e.target;
                                                      setFieldValue(
                                                        `workflows.[${i}].sequence`,
                                                        value
                                                      );
                                                    }}
                                                  />
                                                   <span style={{ color: "red" }}>
                                                      {errors.sequence}
                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                  {!editModalPopper && (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <AddCircleIcon
                                                        onClick={() => {
                                                          arrayHelpers.push({
                                                            name: "",
                                                            days: "",
                                                            sequence: "",
                                                          });
                                                        }}
                                                        className="ModalHeaderIcon"
                                                      />
                                                      {values.workflows.length >
                                                      1 ? (
                                                        <RemoveCircleOutlineIcon
                                                          onClick={() =>
                                                            //handleRemoveFieldsActivity(item.id)
                                                            arrayHelpers.remove(
                                                              i
                                                            )
                                                          }
                                                          style={{
                                                            marginBottom: "0px",
                                                            marginTop: "0px",
                                                          }}
                                                          className="ModalHeaderIcon"
                                                        />
                                                      ) : null}
                                                    </div>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            );
                                          })}
                                      </>
                                    )}
                                  />
                                </Col>
                              </Form.Group>

                              <Form.Group
                                as={Row}
                                className="mb-1"
                                controlId={"status"}
                              >
                                <Form.Label
                                  column
                                  sm="5"
                                  style={{
                                    fontWeight: "700",
                                    fontSize: "12px",
                                  }}
                                >
                                  Status? <sup style={{color:'red'}}>*</sup>
                                </Form.Label>
                                <Col
                                  sm="7"
                                  style={{
                                    padding: "0 12px 0 0",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className={
                                      classes.listingEditListingEditFormGroup
                                    }
                                  >
                                    <div>
                                      <fieldset
                                        onChange={(event) =>
                                          setFieldValue(
                                            "status",
                                            event.target.value
                                          )
                                        }
                                      >
                                        <input
                                          type="radio"
                                          checked={values?.status === "1"}
                                          name="status"
                                          value={1}
                                        />
                                        <span
                                          className={
                                            classes.genderRadioButtonLabele
                                          }
                                          style={{ marginLeft: "5px" }}
                                        >
                                          Active
                                        </span>
                                        <input
                                          type="radio"
                                          checked={values?.status === "0"}
                                          name="status"
                                          value={0}
                                          style={{ marginLeft: "5px" }}
                                        />
                                        <span
                                          className={
                                            classes.genderRadioButtonLabele
                                          }
                                          style={{ marginLeft: "5px" }}
                                        >
                                          Inactive
                                        </span>
                                      </fieldset>
                                    </div>
                                  </div>
                                </Col>
                              </Form.Group>
                            </Grid>
                          </Grid>
                        </div>
                        <br />
                      </Grid>
                    </Grid>

                    <br />
                    <br />
                    <div className={classes.quotationButtonDivModal}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: "#eb6e12" }}
                        onClick={handleSubmit}
                      >
                        {loading
                          ? "Loading...."
                          : editModalPopper
                          ? "Save Changes"
                          : "Submit"}
                      </Button>
                      <Button
                        onClick={() => {
                          handleModaleClose();
                          // handleHide()
                        }}
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#fff",
                          color: "#eb6e12",
                          marginLeft: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(OffshoreForm);
