import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Button, withStyles } from "@material-ui/core";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import { SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import axios from "axios";
import CustomPaginationTable from "../../../../reusableComponents/CustomPaginationTable/CustomPaginationTable";
import DownloadIcon from "@mui/icons-material/Download";
import modalTableStyles from "../styles/ModalTableStyles";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { Grid } from "@material-ui/core";
import InputText from "../../../../reusableComponents/InputText/InputText";
import { AgGridReact } from "ag-grid-react";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";




const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CompanyDocuments = ({ fitlerBadge, classes }) => {
  const gridRef = useRef();
  const { operationInfo, setSelected } = useContext(SupportDataContext);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [companyDocData, setCompanyDocData] = useState(null);
  const [docDropdown, setDocDropdown] = useState([])

  const todayDate = moment().format("YYYY-MM-DD");
  const [reqFromDate, setReqFromDate] = useState(todayDate);
  const [reqToDate, setReqToDate] = useState(null);
  const [docType, setDocType] = useState("");
  const [particular, setParticularValue] = useState("");
  const [showDate, setShowDate] = useState(true);

  const docTypeListDropDown = docDropdown?.Doc_Type_Master_list?.map((i) => {
    return {
      id: i.Id,
      value: i.Id,
      label: `${i.Name}`,
    };
  });

 const dateFilterParams = {
    inRangeInclusive: true,
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString.slice(0, 10).split("-");
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };
  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []); 

  const getCompanyDocApiData = async (paginationNumber) => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/DocumentOnCompany/?emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.Company_Id}&pagination_number=${paginationNumber}`;

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setCompanyDocData(res?.data?.results);
      setDocDropdown(res?.data?.results)
      gridRef.current.api.hideOverlay();
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCompanyDocApiData(currentPageIndex);
  }, [currentPageIndex]);

  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 80,
      filter: false,
    },
    {
      headerName: "Action",
      field: "Action",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 150,
      filter: false,
      cellRenderer: (params) => {
        return (
          <CustomIconButton
            title="Download Document"
            width={"auto"}
            icon={
              <>
                <DownloadIcon /> Download{" "}
              </>
            }
            onClick={() => {
              window.open(params?.data?.Document_Path, "_blank");
            }}
          />
        );
      },
    },
    {
      headerName: "Doc Type",
      field: "Doc_Type",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 150,
    },
    {
      headerName: "Particular",
      field: "particular",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 250,
      flex:1
    },
    {
      headerName: "Expiry Date",
      field: "Expiry_Date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 180,
      cellRenderer: (params)=>{
        return(
          <>
          {params?.data?.Expiry_Date === "0000-00-00" || params?.data?.Expiry_Date === "" ? "N/A":
          moment(params?.data?.Expiry_Date).format("DD-MM-YYYY")
          }
          </>
        )
      }
    },
    {
      headerName: "Doc Category",
      field: "doc_category",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Uploaded By / Date",
      field: "Uploaded_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
      cellRenderer: (params) => {
        return(
          <>
           <p style={{margin: 0, textAlign: 'center'}}> {params?.data?.Uploaded_By} </p>
          <p style={{margin: 0, textAlign: 'center'}}> {params?.data?.Uploaded_Date} </p>
         
          </>
        )
      }
    },
    {
      headerName: "Updated By / Name",
      field: "Updated_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
      cellRenderer: (params) => {
        return(
          <>
          <p style={{margin: 0, textAlign: 'center'}}> {params?.data?.Updated_By} </p>
          <p style={{margin: 0, textAlign: 'center'}}> {params?.data?.Updated_Date} </p>
          </>
        )
      }
    },
    {
      headerName: "Receipt Amount (AED)",
      field: "receipt_amount",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 200,
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);
  const handleValueBySearch = async () => {
    gridRef.current.api.showLoadingOverlay();
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    try {
      let url = `https://flyingcolour.online/fcapp/docprocessing_api/SearchDocumentOnCompany/?emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.Company_Id}`;

      if (reqFromDate && reqToDate) {
        url =
          url + `&expiry_from_date=${reqFromDate}&expiry_to_date=${reqToDate}`;
      }

      if (particular) {
        url = url + `&particular=${particular}`;
      }

      if (docType) {
        url = url + `&document_type=${docType}`;
      }

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      gridRef.current.api.hideOverlay();
      setCompanyDocData(res?.data?.results);
      //setLoading(false);
    } catch (err) {
      console.error(err);
      gridRef.current.api.hideOverlay();
    }
  };
  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
};



const [fromRows, setFromRows] = useState(0);
const [toRows, setToRows] = useState(0);
const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
}, []);

const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
};
const columnTypes = useMemo(() => {
  return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
              // provide comparator function
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                  // In the example application, dates are stored as dd/mm/yyyy
                  // We create a Date object for comparison against the filter date
                  const dateParts = cellValue.split("/");
                  const day = Number(dateParts[0]);
                  const month = Number(dateParts[1]) - 1;
                  const year = Number(dateParts[2]);
                  const cellDate = new Date(year, month, day);
                  // Now that both parameters are Date objects, we can compare
                  if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                  } else if (cellDate > filterLocalDateAtMidnight) {
                      return 1;
                  } else {
                      return 0;
                  }
              },
          },
      },
  };
}, []); 

  return (
    <div className="listing-data-grid" style={{ height: "600px"}}>
      <div
          style={{ height: "auto", background: "white", }}
        >
        <Grid container style={{ marginLeft: "10px", paddingTop: "10px" }}>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem",}}>
            <CustomDropdown
              label="Doc Type"
              fieldOptions={docTypeListDropDown}
              fieldValue={docType}
              fieldHandleChange={(value) => {
              
                setDocType(value?.value);
              }}
              isClearable={true}
            />
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <InputText
              label="Particular"
              placeholder="Enter Particular"
              fieldName="Lnquiry_Caption"
              fieldValue={particular}
              fieldHandleChange={(event) =>
                setParticularValue(event.target.value)
              }
            />
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="4"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Doc Expiry Date
              </Form.Label>
              <Col sm="8" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <CustomDatePicker
                    custom={true}
                    showDate={showDate}
                    fieldHandleChange={(value, picker) => {
                      if(value === null){
                        setReqFromDate('');
                        setReqToDate('');
                        setShowDate(true);
                        return;
                      }
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      setShowDate(false)
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
              }}
              disabled={(particular || docType || reqToDate) ? false : true}
              onClick={() => {
                handleValueBySearch();
              }}
            >
              Search
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
                marginLeft: 10
              }}
              onClick={() => {
                gridRef.current.api.showLoadingOverlay();
                setReqFromDate('');
                setReqToDate('');
                setShowDate(true);
                setParticularValue('');
                setDocType('');
                setCurrentPageIndex(0);
                getCompanyDocApiData(0);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: "540px", maxHeight: "540px", overflow: "auto",}}
        >
      <AgGridReact
       suppressExcelExport={true}
       suppressCsvExport={true}
       ref={gridRef}
       rowData={companyDocData?.Data}
       columnDefs={columnDefs}
       defaultColDef={defaultColDef}
       columnTypes={columnTypes}
       animateRows={true}
       components={components}
       onSelectionChanged={onRowSelected}
       rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
       getRowHeight={() => 90}
       pagination={true}
       detailRowAutoHeight={true}
       onPaginationChanged={onPaginationChanged}
   ></AgGridReact>
      
    </div>
    </div>
  );
};

export default withStyles(modalTableStyles)(CompanyDocuments);
