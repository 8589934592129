import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import previewFormStyles from "../../Preview/previewStyle";
import { SupportDataContext } from "../../../../../provider/context";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/styles.css";

const ViewOrderNOF = ({ classes, setOpenModal }) => {
  const { operationInfo, handleModaleClose } = useContext(SupportDataContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    p: 4,
    height: "auto",
    paddingTop: "0px",
    maxHeight: "590px",
    overflowY: "scroll",
  };

  // useEffect(() => {
  //   getProjectListDropdownapi();
  // }, [operationInfo]);

  return (
    <>
      <Formik>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <div className={classes.divModal}>
              <Box sx={style}>
                <IconButton
                  style={{ backgroundColor: "#fffffff0" }}
                  className={classes.headerIconCancle}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CloseIcon
                    className={classes.headerIcon}
                    style={{
                      marginTop: "0px",
                      marginBottom: "-45px",
                      fontSize: "25px",
                    }}
                  />
                </IconButton>

                <Grid container spacing={2} columns={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                    }}
                  >
                    <br />
                    <div className={classes.headingContainer}>
                      <span className={classes.modalHeader}>
                        {"View Order"}
                      </span>
                    </div>

                    <div className={classes.headingContainer}>
                      <span className={classes.modalHeader}>
                        {"Order Form Detail"}
                      </span>
                    </div>
                    <div
                      style={{ marginBottom: "10px" }}
                      className={classes.followUpFormModalFlexDivContaner}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Company Id
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo?.Company_Id}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Project Name
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.project_name}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order Name
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.Order_Name}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order No
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo !== undefined
                              ? operationInfo?.Order_No
                              : "undefined"}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order Id
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.Order_Id}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            State
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo !== undefined
                              ? operationInfo?.State
                              : "undefined"}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order Status
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.Order_Status}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Support Name
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo !== undefined
                              ? operationInfo?.Support_Emp_Name
                              : "undefined"}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Support Status
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.Support_Status}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Type Of Order
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo !== undefined
                              ? operationInfo?.Type_Of_Order
                              : "undefined"}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Account Status
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.Accounts_Status}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Advisor Name
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo !== undefined
                              ? operationInfo?.Advisor_Name
                              : "undefined"}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Type of company
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: operationInfo?.Formation,
                              }}
                            />
                          </Grid>
                          {operationInfo?.formation_type == 2 ? (
                            <>
                              <Grid
                                xs={4}
                                style={{ fontWeight: "600", height: "auto" }}
                                className={classes.cellData}
                              >
                                Freezone Name
                              </Grid>
                              <Grid
                                xs={4}
                                style={{ height: "auto" }}
                                className={classes.lastCellData}
                              >
                                {operationInfo !== undefined
                                  ? operationInfo?.freezone_name
                                  : "undefined"}
                              </Grid>
                            </>
                          ) : operationInfo?.formation_type == 4 ? (
                            <>
                              <Grid
                                xs={4}
                                style={{ fontWeight: "600", height: "auto" }}
                                className={classes.cellData}
                              >
                                PRO Formation Type
                              </Grid>
                              <Grid
                                xs={4}
                                style={{ height: "auto" }}
                                className={classes.lastCellData}
                              >
                                {operationInfo !== undefined
                                  ? operationInfo?.formation_type_for_pro_services
                                  : "undefined"}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                xs={4}
                                style={{ fontWeight: "600", height: "auto" }}
                                className={classes.cellData}
                              >
                                Legal Type
                              </Grid>
                              <Grid
                                xs={4}
                                style={{ height: "auto" }}
                                className={classes.lastCellData}
                              >
                                {operationInfo !== undefined
                                  ? operationInfo?.legal_type_name
                                  : "undefined"}
                              </Grid>
                            </>
                          )}
                        </Grid>
                        {operationInfo?.formation_type == 2 && (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex" }}
                            className={classes.followUpFormInnerGridLeft}
                          >
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              License Type
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.cellData}
                            >
                              {operationInfo !== undefined
                                ? operationInfo?.license_type_name
                                : "undefined"}
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              Activity Group
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.lastCellData}
                            >
                              {operationInfo?.activity_type_name}
                            </Grid>
                          </Grid>
                        )}
                        {operationInfo?.formation_type == 3 && (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex" }}
                            className={classes.followUpFormInnerGridLeft}
                          >
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              Offshore Name
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.cellData}
                            >
                              {operationInfo?.offshore_name_name}
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              Activity Group
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.lastCellData}
                            >
                              {operationInfo?.activity_type_name}
                            </Grid>
                          </Grid>
                        )}
                        {operationInfo?.formation_type == 1 && (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex" }}
                            className={classes.followUpFormInnerGridLeft}
                          >
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              License Type
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.lastCellData}
                            >
                              {operationInfo !== undefined
                                ? operationInfo?.license_type_name
                                : "undefined"}
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              Activity Group
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.lastCellData}
                            >
                              {operationInfo?.activity_type_name}
                            </Grid>
                          </Grid>
                        )}
                        {/* pro service */}
                        {operationInfo?.formation_type == 4 && (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex" }}
                            className={classes.followUpFormInnerGridLeft}
                          >
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              PRO Service
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.cellData}
                            >
                              {operationInfo?.pro_services_name_name}
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ fontWeight: "600", height: "auto" }}
                              className={classes.cellData}
                            >
                              DED/FZ/Offhsore
                            </Grid>
                            <Grid
                              xs={4}
                              style={{ height: "auto" }}
                              className={classes.lastCellData}
                            >
                              {operationInfo?.ded_fz_offshore_name_name}
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order Creation Date
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.Order_Creation_Date}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Typist Name
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo?.Typist_Name}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order Connected
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.cellData}
                          >
                            {operationInfo?.order_connected_or_not}
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ fontWeight: "600", height: "auto" }}
                            className={classes.cellData}
                          >
                            Order Sequence
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ height: "auto" }}
                            className={classes.lastCellData}
                          >
                            {operationInfo?.order_sequence}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>

                    {operationInfo?.upload_doc_details?.length > 0 && (
                      <>
                        <div className={classes.headingContainer}>
                          <span className={classes.modalHeader}>
                            {"Upload Doc. Details"}
                          </span>
                        </div>

                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className={classes.followUpFormInnerGridLeft}
                        >
                          <Grid
                            xs={4}
                            style={{
                              fontWeight: "600",
                              height: "auto",
                              textAlign: "center",
                            }}
                            className={classes.cellData}
                          >
                            Document Type
                          </Grid>
                          <Grid
                            xs={4}
                            style={{
                              fontWeight: "600",
                              height: "auto",
                              textAlign: "center",
                            }}
                            className={classes.cellData}
                          >
                            Particular
                          </Grid>
                          <Grid
                            xs={4}
                            style={{
                              fontWeight: "600",
                              height: "auto",
                              textAlign: "center",
                            }}
                            className={classes.lastCellData}
                          >
                            Documents
                          </Grid>
                        </Grid>

                        {operationInfo?.upload_doc_details?.map((i) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex" }}
                                className={classes.followUpFormInnerGridLeft}
                              >
                                <Grid
                                  xs={4}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.cellData}
                                >
                                   {
                                    i.Document_Type_Name === undefined 
                                    || i.Document_Type_Name === null
                                    || i.Document_Type_Name === "" ? "No Data"
                                    : i.Document_Type_Name 
                                    }
                                </Grid>
                                <Grid
                                  xs={4}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.cellData}
                                >
                                   {
                                    i.Particular === undefined 
                                    || i.Particular === null
                                    || i.Particular === "" ? "No Data"
                                    : i.Particular 
                                    }
                                </Grid>
                                <Grid
                                  xs={4}
                                  style={{
                                    height: "auto",
                                    textAlign: "center",
                                  }}
                                  className={classes.lastCellData}
                                >
                                   {
                                    i.uploadDoc === undefined 
                                    || i.uploadDoc === null
                                    || i.uploadDoc === "" ? "No Data"
                                    :
                                    <Button
                                    style={{
                                      marginLeft: ".4rem",
                                      textDecorationColor: "#eb6e12",
                                      background: "none",
                                      cursor: "pointer",
                                      border: "none",
                                      padding: "6px",
                                    }}
                                    onClick={() => window.open(i?.uploadDoc)}
                                   >
                                    View Document
                                  </Button>
                                  // <a
                                  //   href={i?.uploadDoc}
                                  //   target="_blank"
                                  //   rel="noreferrer"
                                  // >
                                  //   View Document
                                  // </a>
                                  }
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    )}

                    <div className={classes.headingContainer}>
                      <span className={classes.modalHeader}>
                        {"Scope of Work"}
                      </span>
                    </div>

                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex" }}
                        className={classes.followUpFormInnerGridLeft}
                      >
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.cellData}
                        >
                          Name
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.cellData}
                        >
                          Task Type
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Section
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Category
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Task Detail
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Work Type
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Days
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Amount
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Sequence
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Govt. Dept Name
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Spl. Instruction
                        </Grid>
                        <Grid
                          xs={1}
                          style={{
                            fontWeight: "600",
                            height: "auto",
                            textAlign: "center",
                          }}
                          className={classes.lastCellData}
                        >
                          Tag Name
                        </Grid>
                      </Grid>

                      {operationInfo?.scope_of_work_array?.map((i) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex" }}
                              className={classes.followUpFormInnerGridLeft}
                            >
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.cellData}
                              >
                                {i?.Name}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.cellData}
                              >
                                {i?.Task_Type}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Task_Section}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Task_Category}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Task_Detail}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Work_Type}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Days}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Task_Amount}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Sequance}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Govt_Department}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                {i?.Special_Instruction}
                              </Grid>
                              <Grid
                                xs={1}
                                style={{
                                  height: "auto",
                                  textAlign: "center",
                                }}
                                className={classes.lastCellData}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: i?.Tag_Name,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </>
                    <div
                      className={classes.headingContainer}
                      style={{ height: "10px" }}
                    >
                      <span className={classes.modalHeader}>{""}</span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(previewFormStyles)(ViewOrderNOF);
