const clientReviewFormStyles = () => ({
    clientReviewFormGroupFlexDivModal: {
      display: "flex",
      marginBottom: "6px",
    },
    clientReviewFormGroupFlexDivModalRichText: {
      marginBottom: "-10px",
    },
    clientReviewGridItemContainer: {
      paddingTop: "20px",
      marginBottom: "-30px",
    },
    clientReviewLabelFormInputModal: {
      width: "50%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    clientReviewLabelFormInputModalChanges: {
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    clientReviewLabelFormInputModalCheckBox: {
      width: "50%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    clientReviewSelectContainerModal: {
      width: "50%",
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
    },
    clientReviewSelectContainerModalCheckbox: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      alignItems: "center",
    },
    clientReviewDemoDataCheckModal: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "right",
      width: "100%",
    },
    clientReviewDemoDataCheckModalUploadFile: {
      border: "0.1px solid #d3d3d3",
      display: "flex",
      float: "right",
      padding: "1.5px 0px",
      alignItems: "center",
      width: "100%",
    },
    clientReviewListingFormInputMainModal: {
      width: "100%",
  
      border: "none",
      float: "inherit",
      padding: "4.5px 10px",
      fontFamily: " Times New Roman ",
    },
    clientReviewListingFormInputMainModalDate: {
      width: "100%",
      border: "none",
      float: "inherit",
      padding: "2.5px 4px",
      fontFamily: " Times New Roman ",
    },
    clientReviewButtonDivModal: {
      textAlign: "center",
      marginTop: "20px",
      marginBottom: "-10px",
      marginRight: "-16px",
    },
    clientReviewModalHeader: {
      color: "#fff",
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "5px",
      textTransform: "capitalize",
    },
    clientReviewModalHeaderIcon: {
      color: "#eb6e12",
      fontSize: "20px !important",
      marginTop: "5px",
      cursor: "pointer",
    },
    clientReviewModalHeaderIcon1: {
      color: "#fff",
      fontSize: "16px !important",
      marginTop: "5px",
    },
    clientReviewModalHeaderIcon11: {
      color: "#eb6e12",
      fontSize: "16px !important",
      marginTop: "26px",
    },
    RichTextEditerDiv: {
      marginTop: "5px",
    },
    clientReviewModalHeaderIconClose: {
      color: "#eb6e12",
      marginBottom: "4.5px",
    },
    clientReviewModalHeadingContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px ",
      borderTop: "1px solid #eb6e12",
      borderBottom: "1px solid #eb6e12",
      backgroundColor: "#eb6e12",
    },
    clientReviewCancleIconDiv: {
      marginLeft: "auto",
    },
    clientReviewModalFormTextArea: {
      minWidth: "131px",
      maxWidth: "131px",
      float: "inherit",
      border: "none",
      fontFamily: " Times New Roman ",
    },
    RichTextEditerDiv: {
      marginTop: "5px",
    },
  
    clientReviewSelectContainerGender: {
      fontSize: "12px",
      float: "right",
      display: "flex",
      marginTop: " 3px",
      marginRight: "66px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        marginRight: "117px",
      },
    },
    clientReviewRadioContainer: {
      display: "flex",
    },
    clientReviewGendarLabel: {
      marginTop: "2px",
    },
    FCserviceHeading: {
      float: "right",
      marginRight: "10%",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#eb6e12",
      marginTop: "3px",
    },
    clientReviewCancleButtonModel: {
      marginTop: " -15px",
      marginRight: "-25px",
      position: "relative",
      left: "43%",
      "@media screen and (min-width: 1600px) and (max-width: 3000px)": {
        left: "45%",
      },
    },
    clientReviewModalFlexDivContaner: {
      display: "-webkit-box",
      flexFlow: "row-reverse wrap",
    },
    clientReviewBorderSpacingUpperModale: {
      width: "100%",
      borderTop: "1px solid #d3d3d3 ",
      marginBottom: "10px",
    },
    clientReviewBorderSpacingLowerListing: {
      width: "100%",
      borderBottom: "5px solid #eb6e12 ",
    },
    clientReviewGapSpan: {
      fontSize: "20px",
      marginLeft: "-3px",
      marginTop: "-3px",
      marginRight: "3px",
    },
    clientReviewInnerGridRight: {
      padding: "0px 10px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
    },
    clientReviewInnerGridRightFirst: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "8px",
      paddingLeft: "9px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
    },
    clientReviewInnerGridLeft: {
      padding: "0px 10px",
      paddingTop: "3px",
    },
    clientReviewInnerGrid: {
      padding: "0px 10px",
    },
    clientReviewInnerGridContainer: {
      marginBottom: "10px",
      padding: "5px 0",
    },
    clientReviewAddRemoveBtnContainer: {
      marginLeft: "auto",
      marginRight: "-20px",
      marginTop: "-62px",
    },
    clientReviewDemoDataCheckModalUploadFileBtn: {
      padding: "6px",
      marginRight: "3px",
      backgroundColor: "#eb6e12",
      border: "1px solid #d3d3d3",
      color: "#fff",
      borderRadius: "5px",
      cursor: "pointer",
    },
    clientReviewDemoDataCheckModalUploadFileText: {
      overflow: "hidden",
      cursor: "pointer",
      maxHeight: "26px",
    },
    clientReviewDemoDataCheckModalUploadLabel: {
      width: "50%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    clientReviewModalHeaderIconCancle: {
      float: "right",
      marginRight: "-25px !important",
      marginTop: "-3px !important",
    },
  });
  export default clientReviewFormStyles;
  