import React from 'react'
import TabStyles from './styles/TabStyles';
import { withStyles } from '@material-ui/core/styles';
const BackImage = ({ classes }) => {

    return (
        <>
            <div className={classes.ButtonDivListingTab}>
            </div>
            <div className={classes.ImgDivListing}>
                <div className={classes.BorderSpacingUpper} ></div>

                <div className={classes.backImg}>
                </div>
                <div className={classes.BorderSpacingLower} ></div>
            </div>
        </>
    )
}

export default withStyles(TabStyles)(BackImage)