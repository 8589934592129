import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import modalTableStyles from "../../styles/ModalTableStyles";
import {
  SupportDataContext,
  DataContext,
} from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { CKRictTextEditer } from "../../../../listing/listingForm/RichTextEditer";

const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddScopeOfWorkSubTemplateForm = ({ classes, taskValue }) => {
  const {
    handleModalTableClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    operationInfo,
    salesInfo,
    rowData,
    templateCreationList,
    setButtonText,
    handleModalTableOpen,
    setOperationInfo,
    sowList,
  } = useContext(SupportDataContext);

  const [isTaskNameExist, setIsTaskNameExist] = useState(false);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },
  }));

  const addBorderToTables = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const tables = doc.querySelectorAll('table');
    tables.forEach((table) => {
        table.setAttribute('border', '1');
        const tds = table.querySelectorAll('td');
         tds.forEach((td) => {
            td.style.padding = '5px';
        });
    });

    return new XMLSerializer().serializeToString(doc);
};

  const addSOWSubTemplatePostAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const factory_id = localStorage.getItem("factory_id");
    // https://flyingcolour.online/fcapp/compliance/checkverificationonpartner/
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/sowforordersubtemplateview/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    const taskDetailsValue = values?.mainTaskArray?.map((val) => {
      const sowList = {
        description: addBorderToTables(val?.description),
        active_inactive: val?.status,
      };

      return sowList;
    });
    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);

    if (editModalPopper) {
      let description = [],
        status = [];
      values?.mainTaskArray?.forEach((val) => {
        description?.push(addBorderToTables(val?.description));
        status?.push(val?.status);
      });

      formData.append(
        "template_service_id",
        Number(operationInfo?.Template_Service_Id)
      );
      formData.append(
        "template_service_name",
        operationInfo?.Template_Service_Name
      );
      formData.append("remarks", values?.remarks);
      formData.append(
        "task_ids",
        Number(operationInfo?.Service_Task_Id)
      );
      formData.append("description", description?.toString());
      formData.append("active_inactive", status?.toString());
      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
       
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        setButtonText("SOW Template Listing");
      });
    } else {
      formData.append("task_list_details", JSON.stringify(taskDetailsValue));
      formData.append("template_service_id", operationInfo?.Service_Id);
      formData.append("template_service_name", operationInfo?.Service_Name);
      formData.append("remarks", values?.remarks);
     
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
       
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        setButtonText("SOW Template Listing");
      });
    }
  };


  const handleEditValue = () => {
    const finalValue = [];
    finalValue.push({
      description: operationInfo?.Task_Details,
      status: operationInfo?.active_status,
    });

    return finalValue;
  };

  const statusDropdown = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  return (
    <>
      <Formik
        initialValues={{
          mainTaskArray: editModalPopper
            ? handleEditValue()
            : [
                {
                  description: "",
                  status: 1,
                },
              ],
          remarks:editModalPopper ? operationInfo?.remarks : ''
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addSOWSubTemplatePostAPI(data);
        }}
        validate={(values) => {
          // setDisabledId(values?.mainTaskArray)
          let errors = {};
          values?.mainTaskArray?.forEach((val) => {
            if (val.description === "") {
              errors.description = "Required!!";
            }
            if (val.status === "") {
              errors.status = "Required!!";
            }
          });
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              <div className={classes.quotationButtonDivModal}>
                <BootstrapTooltip arrow={true} disableFocusListener>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: "#eb6e12" }}
                    onClick={handleSubmit}
                  >
                    {loading ? "Loading...." : "Save"}
                  </Button>
                </BootstrapTooltip>
                <Button
                  onClick={() => {
                    setButtonText("SOW Template Listing");
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>

              <div
                style={{ height: "500px", overflowX: "hidden", padding: "0" }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                      zIndex: 10,
                    }}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item md={12} xs={12}>
                          <FieldArray
                            name="mainTaskArray"
                            render={(arrayHelpers) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  //marginRight: "1rem",
                                  justifyContent: "space-between",
                                  marginBottom: "1rem",
                                  width: "100%",
                                }}
                              >
                                {values.mainTaskArray?.length > 0 &&
                                  values.mainTaskArray?.map((item, i) => {
                                    return (
                                      <>
                                        <Grid item xs={12} md={12}>
                                          <div
                                            key={i}
                                            style={
                                              i !== values.mainTaskArray.length
                                                ? {
                                                    border: "1px solid #eb6e12",
                                                    padding: "5px",
                                                    width: "100%",
                                                    paddingRight: "20px",
                                                    paddingLeft: "10px",
                                                  }
                                                : {
                                                    border: "none",
                                                    padding: "5px",
                                                    marginTop: "2px",
                                                    width: "100%",
                                                    paddingRight: "20px",
                                                  }
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                position: "relative",
                                                left: "20px",
                                                cursor: "pointer",
                                                color: "#eb6e12",
                                                margin: "3px 10px 10px 0",
                                              }}
                                            >
                                              {!editModalPopper && (
                                                <AddCircleIcon
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      description: "",
                                                      status: 1,
                                                    });
                                                  }}
                                                  style={
                                                    values.mainTaskArray
                                                      .length > 1
                                                      ? {
                                                          marginBottom: "0px",
                                                          marginTop: "0px",
                                                        }
                                                      : {
                                                          marginBottom: "0px",
                                                          marginTop: "0px",
                                                        }
                                                  }
                                                  className="ModalHeaderIcon"
                                                />
                                              )}
                                              {!editModalPopper &&
                                              values.mainTaskArray.length >
                                                1 ? (
                                                <RemoveCircleOutlineIcon
                                                  onClick={() =>
                                                    //handleRemoveFieldsActivity(item.id)
                                                    arrayHelpers.remove(i)
                                                  }
                                                  style={{
                                                    marginBottom: "0px",
                                                    marginTop: "0px",
                                                  }}
                                                  className="ModalHeaderIcon"
                                                />
                                              ) : null}
                                            </div>

                                            <Grid container>
                                              <Grid
                                                item
                                                xs={12}
                                                md={8}
                                                style={{ paddingRight: 5 }}
                                              >
                                                    <Form.Group as={Row} className="mb-2" controlId={"description"} >
                                                    <Form.Label column sm="5" style={{ fontSize: 12, fontWeight: 700 }} >
                                                      Description <sup style={{color:"red"}}>*</sup>
                                                    </Form.Label>
                                                    <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                                      <div style={getStyles(errors,item?.description)} >
                                                        <CKRictTextEditer
                                                          disabledField={false}
                                                          data={item?.description}
                                                          height="400px"
                                                          handleDataChange={(event, editor) => {
                                                            setFieldValue(`mainTaskArray.[${i}].description`, editor.getData());
                                                          }}

                                                        />
                                                      </div>
                                                    </Col>
                                                  </Form.Group>

                                               
                                              </Grid>
                                              <Grid item xs={12} md={4}>
                                                {
                                                  editModalPopper ? (
<CustomDropdown
                                                  label="Status"
                                                  fieldOptions={
                                                    statusDropdown
                                                  }
                                                  fieldValue={item?.status}
                                                  fieldHandleChange={(
                                                    value
                                                  ) => {
                                                    setFieldValue(
                                                      `mainTaskArray.[${i}].status`,
                                                      value.value
                                                    );
                                                  }}
                                                  errorField={getStyles(
                                                    errors,
                                                    values?.mainTaskArray[i]
                                                      ?.status
                                                  )}
                                                  requiredField={true}
                                                />
                                                  ) : (
                                                    <InputText
                                                      label="Status"
                                                      fieldName="status"
                                                      fieldValue={"Active"}
                                                      disableField={true}
                                                      requiredField={true}
                                                      errorField={getStyles(
                                                        errors,
                                                        values?.mainTaskArray[i]
                                                          ?.status
                                                      )}
                                                  
                                                    />
                                                  )
                                                }
                                              
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </Grid>
                                      </>
                                    );
                                  })}
                              </div>
                            )}
                          />

                        </Grid>
                                              
                      </Grid>
                      <Grid container>
                      <Grid item md={6} xs={6}>
                      <CustomTextArea
                                                  label="Remarks"
                                                  fieldName="remarks"
                                                  fieldValue={values.remarks}
                                                  fieldHandleChange={(e) => {
                                                    e.preventDefault();
                                                    const { value } = e.target;
                                                    setFieldValue(
                                                      `remarks`,
                                                      value
                                                    );
                                                  }}
                                                />
                        </Grid>  
                      </Grid>
                    </div>
                    <br />
                  </Grid>
                </Grid>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(modalTableStyles)(AddScopeOfWorkSubTemplateForm);
