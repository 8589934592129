import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../Styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModalPopper";
import {
  getTotalLength,
  getTotalLengthForSavedFilters,
  handleDefaultFilters,
  handleFilters,
} from "../../../../utils/Filters";
import { Modal } from "@mui/material";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { FaPlus, FaEdit } from "react-icons/fa";
import GeneralScheduleForm from "../Modals/GeneralSchedule/GeneralScheduleForm";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import PROInternalRequisitionForm from "../Modals/PROInternal/PROInternalRequisitionForm";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PROInternalRequistion = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    setSalesInfo,
    setIsOpen,
    isOpen,
    message,
    severity,
  } = useContext(SupportDataContext);
  const { setStart_Date, setEnd_Date } = useContext(DataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const todayDate = moment().format("YYYY-MM-DD");
  const [proName, setProName] = useState();
  const [reqFromDate, setReqFromDate] = useState(todayDate);
  const [reqToDate, setReqToDate] = useState(todayDate);
  const [proId, setProId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    handleListingSavedSearch();
    fetchListingDataFromApi(proId, reqFromDate, reqToDate);
    setStart_Date(todayDate);
    setEnd_Date(todayDate);
  }, []);

  const proNameListDropDown = apiData?.PRO_List?.map((i) => {
    return {
      id: i.EmpId,
      value: i.EmpId,
      label: `${i.Emp_Name}`,
    };
  });

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        textAlign: "left",
      },
      width: 50,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Actions",
      field: "Inquiry_Id",
      filter: false,
      // checkboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      pinned: "left",
      cellRenderer: (params) => {
        let status = params?.data?.status;
        return (
          <>
            <CustomIconButton
              onClick={() => {
                setOperationInfo(params.data);
                setModalValue("Add/Edit PRO Internal Requistion");
                setOpenModal(true);
                setIsEdit(true);
              }}
              title={"Edit"}
              width={"auto"}
              className="btnActionAlignment"
              disabled={params?.data?.req_status === "Approved" ? true : false}
              icon={
                <>
                  <FaEdit /> Edit
                </>
              }
            />
          </>
        );
      },
    },
    {
      headerName: "Requisition Type",
      field: "requisition_type",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Company Name",
      field: "companyName",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      minWidth: 300,
      cellRenderer: (params) => {
        return (
          <ol>
            {params?.data?.selected_company_list?.map((val) => {
              return <li>{val}</li>;
            })}
          </ol>
        );
      },
      flex: 1,
    },
    {
      headerName: "PRO Name",
      field: "pro_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Upload Docs",
      field: "uploadDOcs",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Button
            type="submit"
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#eb6e12",
              color: "#fff",
            }}
            onClick={(e) => {
              e.preventDefault();
              let url = params?.data?.uploaded_docs;
              window?.open(url, "_blank");
            }}
          >
            View Doc
          </Button>
        );
      },
    },
    {
      headerName: "Task Description",
      field: "task_description",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 400,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params?.data?.task_description }}
          />
        );
      },
    },
    {
      headerName: "Status",
      field: "req_status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Amount",
      field: "req_amount",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Req. Status Date",
      field: "status_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
      cellRenderer: (params) => {
        return <>{params?.data?.status_date === "" ? "No Data" : moment(params?.data?.status_date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      headerName: "Approve/Reject Date",
      field: "approved_rejected_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 190,
    },
    {
      headerName: "Approve/Reject By",
      field: "approved_rejected_by",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Approve/Reject Remarks", 
      field: "approved_rejected_remarks",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 400,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{
              __html: params?.data?.approved_rejected_remarks,
            }}
          />
        );
      },
    },
    {
      headerName: "Created By",
      field: "created_by",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Created Date",
      field: "created_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.Created_Date).format("DD-MM-YYYY")}</>;
      },
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
  ];

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.detail === "Invalid Token!") {
          localStorage.clear();
          navigate("/login");
          sessionStorage.clear();
          window.location.reload();
        }
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      <div className="listing-filters" style={{ margin: "1rem", height:"20px" }}>
        <Grid container>
          <Grid
            item
            sm={12}
            md={4}
            style={{ paddingRight: "1rem", marginBottom: "1rem" }}
          >
            <Button
              onClick={() => {
                setModalValue("Add/Edit PRO Internal Requistion");
                setOpenModal(true);
                setIsEdit(false);
              }}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
                padding: "5px",
              }}
            >
              <FaPlus style={{ marginRight: "10px" }} /> Add PRO Internal
              Requistion
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ margin: "1rem", position: "relative", top: "3.6rem" }}
        >
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}></Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="4"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Created Date
              </Form.Label>
              <Col sm="8" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <DateRangePickerComp
                    custom={true}
                    fieldHandleChange={(value, picker) => {
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      setStart_Date(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setEnd_Date(moment(picker.endDate).format("YYYY-MM-DD"));
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>

          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
              }}
              disabled={reqFromDate || proId || reqToDate ? false : true}
              onClick={() => {
                gridRef?.current?.api?.showLoadingOverlay();
                fetchListingDataFromApi(proId, reqFromDate, reqToDate);
              }}
            >
              Search
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
                marginLeft: 10,
              }}
              onClick={() => {
                setReqFromDate(todayDate);
                setReqToDate(todayDate);
                setProId(null);
                setProName(null);
                fetchListingDataFromApi(proId, reqFromDate, reqToDate);
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}></Grid>
        </Grid>
      </div>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData?.Requisition_List}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {apiData?.Requisition_List?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(
                        apiData?.Requisition_List,
                        val.value,
                        val.key
                      )}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleDefaultFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.title,
                          val.value,
                          val.key
                        )
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const keyss = val.Search_Filter.replace(
                    /(.*?)=(.*?)(?:\|\||$)/g
                  );
                  const getNewKey = keyss
                    ?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "");
                  const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                  let resultArray = "";
                  if (getNewKe2) {
                    const resultString = getNewKe2[1];
                    resultArray = resultString.split(",");
                  }
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLengthForSavedFilters(
                        apiData?.Requisition_List,
                        resultArray,
                        valueKey
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.Requisition_List?.length}
      />
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData?.Requisition_List}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modalValue === "Add/Edit PRO Internal Requistion" ? (
            <PROInternalRequisitionForm
              setOpenModal={setOpenModal}
              isEdit={isEdit}
            />
          ) : null}
        </>
      </Modal>
    </div>
  );
};

export default PROInternalRequistion;
