import React, { useContext } from "react";
import { DataContext } from "../../../../../provider/context";
import modalStyles from "./modalStyles";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, IconButton, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { RiErrorWarningFill } from "react-icons/ri";
import { MdSignalWifiConnectedNoInternet2, MdOutlineSignalWifiStatusbarConnectedNoInternet4 as SignalIssue } from "react-icons/md";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "42%",
  maxWidth: "98vw",
  bgcolor: "#fff",
  borderRadius: "4%",
  // border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  overflowY: "scroll",
  minHeight: "350px",
  maxHeight: "550px",
  overflowY: "hidden",
  '@media screen and  (max-width: 780px)': {
    width: "92%",
    maxWidth: "98vw",
    textAlign:"center"
}
};

const Error = ({ classes }) => {
  const { errorAlert, setErrorAlert, error } = useContext(DataContext);
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(false);
  const clearStorage = () => {
    setErrorAlert(false)
    localStorage.clear();
    navigate("/login");
    window.location.reload(false)
  }
  const __token = localStorage.getItem("token");
  const handleApiLogut = async() => {
    setLoader(true)
    try {
      const url = `https://flyingcolour.online/fcapp/api/logoutuser/`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const formData = new FormData();
      formData.append("token_value", __token);
      const res = await axios.post(url,
        formData,
        { headers: headers });
      if(res?.data === "User Logged out successfully"){
        setLoader(false)
        clearStorage();
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }
    }catch(err) {

    }
  }
  return (
    <Modal
      open={errorAlert}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {
          error?.split("-")[0] === "Unauthorized 401" ? null : (
            <IconButton
              className={classes.modalHeaderIconCancle}
              onClick={() => setErrorAlert(false)}
            >
              <CloseIcon className={classes.modalHeaderIconCacleIcon} />
            </IconButton>
          )
        }

        <div
          style={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            error?.split("-")[0] === "Error: Network Error"  ? (
              <MdSignalWifiConnectedNoInternet2 style={{ color: "#eb6e12", fontSize: "151px" }} />
            ) :
              ["TypeError: Failed to fetch" ,"Error: timeout exceeded"].includes(error?.split("-")[0]) ? (
                <SignalIssue style={{ color: "#eb6e12", fontSize: "151px" }} />
              ) :
                (
                  <>
                    <RiErrorWarningFill style={{ color: "#eb6e12", fontSize: "151px" }} />
                  </>
                )
          }

          <Grid
            item
            xs={12}
            style={{
              marginBottom: "0px",
              paddingBottom: "1px",
              marginTop: "5px",
              fontSize: "25px",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "600",
            }}
          >

            {
              error?.split("-")[0] === "Error: Network Error" ? "Connection Error" :
                error?.split("-")[0] === "TypeError: Failed to fetch" ? "Failed to fetch Data" :
                  error?.split("-")[0]
            }

          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "0px",
              paddingBottom: "10px",
              marginTop: "5px",
              fontFamily: "'Poppins', sans-serif",
              color: "#888888",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {error?.split("-").pop()}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "0px",
              paddingBottom: "10px",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              size="medium"
              style={{
                fontFamily: "'Poppins', sans-serif",
                marginRight: "30px",
                color: "#fff",
                background: "#eb6e12",
                padding: "5px 30px",
              }}
              onClick={() => {
                error?.split("-")[0] === "Unauthorized 401" ?
              handleApiLogut()  :
                  error?.split("-")[0] === "Error: Network Error" ? window.location.reload(false) :
                    setErrorAlert(false)
              }}
            >
              {
                error?.split("-")[0] === "Unauthorized 401" ?  loader ? "Loading....":"logout" : "ok"
              }
            </Button>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
};
export default withStyles(modalStyles)(Error);
