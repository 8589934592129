const previewFormStyles = () => ({
    divModal: {
       width:"100%",
      },
      closeModal:{
        position: "absolute",
        top: " 0%",
        right: "9%",
        '@media screen and (max-width: 720px)': {
          top: " 25%",
          right: "10%",
      },
        '@media screen and (min-width: 1400px) and (max-width: 3000px)': {
          top: " 18%",
          right: "11%",
      }
      },
     headerIconCancle: {
      backgroundColor:"#fffffff0 !important",
        float: "right",
        marginRight: "-25px !important",
        // marginTop: "-3px !important",
      },
      headerIcon: {
        color: "#eb6e12",
        fontSize: "25px !important",
        marginTop: "0px",
        cursor: "pointer",
      },headingContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px ",
        borderTop: "1px solid #eb6e12",
        borderBottom: "1px solid #eb6e12",
        backgroundColor: "#eb6e12",
      },
      modalHeader: {
        color: "#fff",
        fontSize: "18px",
        fontWeight: "bold",
        marginLeft: "5px",
        // textTransform: 'capitalize'
      },
      cellDataHead:{
        fontSize:"14px",
        fontWeight:600,
        fontFamily: "Times New Roman !important",
        borderLeft:"1px solid #c3c3c3",borderBottom:"1px solid #c3c3c3",height:"30px",padding:"6px"},
      lastCellDataHead:{
        fontSize:"14px",
        fontWeight:600,
        fontFamily: "Times New Roman !important",
        borderRight:"1px solid #c3c3c3",
        borderLeft:"1px solid #c3c3c3",
        borderBottom:"1px solid #c3c3c3",
        height:"30px",
        padding:"6px"},
      cellData:{borderLeft:"1px solid #c3c3c3",borderBottom:"1px solid #c3c3c3",height:"30px",padding:"6px"},
      lastCellData:{borderRight:"1px solid #c3c3c3",borderLeft:"1px solid #c3c3c3",borderBottom:"1px solid #c3c3c3",height:"30px",padding:"6px"},
      cellDataItem:{borderLeft:"1px solid #c3c3c3",borderBottom:"1px solid #c3c3c3",padding:"6px"},
      lastCellDataItem:{borderRight:"1px solid #c3c3c3",borderLeft:"1px solid #c3c3c3",borderBottom:"1px solid #c3c3c3",padding:"6px"},


});
export default previewFormStyles