import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";

import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../utils/Filters";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SubLeadListingTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchRequestionProScheduleFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        handleModalTableOpen,
        setButtonText,
        setSalesInfo,
        handleModalOpen,
        setOperationInfo,
        setEditModalPopper,
        setSelected,
        fetchSubLeadListDataFromApi,
        subLeadListData,
        message,
        isOpen, setIsOpen,
    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    // const [isOpen, setIsOpen] = useState(false);
    // const [message, setMessage] = useState("");
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);

    useEffect(() => {
        // fetchRequestionProScheduleFromApi();
        handleListingSavedSearch();
        fetchSubLeadListDataFromApi();
    }, [])

    console.log("subLeadListData", subLeadListData);

    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);

    var filterParams = {
        inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue.split(" ");
          if (dateAsString == null) {
            return -1;
          }
          if (dateAsString == "") {
            return -1;
          }
          const dateParts = dateAsString[0].split("-");
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      };

    const [columnDefs] = useState([
        {
            headerName: "Sr. #",
            field: "Serial_Number",
            // cellRenderer: "agGroupCellRenderer",
            filter: false,
            width: 90,
            maxWidth: 90,
            pinned: "left",
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
        },

        {
            headerName: "Parent Company",
            field: "Parent_Company",
            filter: "agTextColumnFilter",
            width: 200,
            maxWidth: 200,
            pinned: "left",
            cellStyle: { border: "1px solid #fff", textAlign: "center" },

        },
        {
            headerName: "Company Id",
            field: "Company_Id",
            filter: "agTextColumnFilter",
            width: 130,
            maxWidth: 130,
            pinned: "left",
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
            cellRenderer: (params) => {
                return (
                    <div>
                        {params?.data?.Referral_Source_Name === "CP1" ? 'FC-CP1-' + params?.data?.Company_Id
                            : params?.data?.Referral_Source_Name === "CP2" ? 'FC-CP2-' + params?.data?.Company_Id
                                :
                                params?.data?.Company_Id
                        }
                    </div>
                );
            },
        },
        {
            headerName: "Company Name",
            field: "Company_Name",
            filter: "agTextColumnFilter",
            width: 240,
            maxWidth: 300,
            pinned: "left",
            cellStyle: { border: "1px solid #fff", textAlign: "center" },

        },
        {
            headerName: "Sub Lead Status",
            field: "Status",
            filter: true,
            width: 210,
            maxWidth: 220,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.Status.charAt(0).toUpperCase()}
                        {params?.data?.Status.slice(1)}
                        <br />
                        {params?.data?.Status === "converted" && (
                            <>
                                Date & Time:{" "}
                                <span style={{ color: "#eb6e12", fontWeight: "bold" }}>
                                    {params?.data?.Converted_Data}
                                </span>
                            </>
                        )}{" "}
                    </>
                );
            },
        },

        {
            headerName: "Sub Lead Date",
            field: "Created_Date",
            filter: "agTextColumnFilter",
            minWidth: 250,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
            //cellRenderer: createbyDate,
        },

        {
            headerName: "Contacted By",
            field: "Contacted_By",
            filter: "agTextColumnFilter",
            width: 200,
            maxWidth: 200,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
        },

        {
            headerName: "CP#",
            field: "CP_No",
            filter: "true",
            width: 120,
            maxWidth: 200,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
        },
        {
            headerName: "Services",
            field: "Service_name",
            filter: true,
            width: 140,
            maxWidth: 270,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
            //   cellRenderer: (params) => {
            //     const resident = subleadservicesList !== undefined ? subleadservicesList?.find(
            //       (n) => n.Id === params?.data?.Service
            //     ) : [];
            //     return (
            //       <>
            //         {resident?.Name !== undefined
            //           ? resident?.Name
            //           : params?.data?.Service
            //         }

            //       </>)
            //   }
        },
        {
            headerName: "Service Details",
            field: "Service_Details",
            filter: true,
            width: 250,
            maxWidth: 260,
            cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: 'auto' },
        },
        {
            headerName: "Assigned To",
            field: "Assigned_To",
            filter: true,
            width: 130,
            minWidth: 130,

            cellStyle: { border: "1px solid #fff", textAlign: "center" },
        },
        {
            headerName: "Sub Lead Source",
            field: "Sub_Lead_Source",
            filter: true,
            width: 200,
            maxWidth: 200,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
        },
        {
            headerName: "Created By",
            field: "Created_By",
            // filter: "agTextColumnFilter",
            minWidth: 250,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
            //cellRenderer: createbyDate,
        },
        {
            headerName: "Created Date",
            field: "Created_Date_Time",
            filter: "agDateColumnFilter",
            minWidth: 210,
            cellStyle: { border: "1px solid #fff", textAlign: "center" },
            filterParams: filterParams,
            cellRenderer: (params) => {
                return <>{
                  params?.data?.Created_Date_Time === "0000-00-00" || params?.data?.Created_Date_Time === null
                || params?.data?.Created_Date_Time === "" || params?.data?.Created_Date_Time === undefined ? "" :
                  moment(params?.data?.Created_Date_Time).format("DD-MM-YYYY HH:mm:ss")}</>;
              },
        },
       
    ]);

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            filter: true,
            // enable floating filters by default
            floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            //flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };

    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

    const filters = [
        {
            id: 1,
            title: "Default",
            value: null,
            key: null,
        },
        {
            id: 2,
            title: "UnderProcess",
            value: "underprocess",
            key: "Status",
        },
        {
            id: 3,
            title: "Converted",
            value: "converted",
            key: "Status",
        },
        // {
        //   id: 4,
        //   title: "Freeze Approval Pending",
        //   value: "Freeze Approval Pending",
        //   key: "Order_Status",
        // },
        // {
        //   id: 5,
        //   title: "Freezed",
        //   value: "Order Freezed",
        //   key: "Order_Status",
        // },
        // {
        //   id: 6,
        //   title: "Completion Approval Pending",
        //   value: "Completion Approval Pending",
        //   key: "Order_Status",
        // },
        // {
        //   id: 7,
        //   title: "Completed",
        //   value: "Completed",
        //   key: "Order_Status",
        // },
        // {
        //   id: 7,
        //   title: "Admin Approval Pending",
        //   value: "Admin Approval Pending",
        //   key: "Order_Status",
        // },
        // {
        //   id: 7,
        //   title: "Pending From Accounts",
        //   value: "Pending From Accounts",
        //   key: "Order_Status",
        // },
        // {
        //   id: 7,
        //   title: "Overdue",
        //   value: "Overdue",
        //   key: "Order_Status",
        // },
    ];

    const handleListingSavedSearch = async () => {
        const __token = localStorage.getItem("token");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        await fetch(
            `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${__token}`,
                    "Content-Type": "application/json",
                },
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSavedSearchList(data.results?.Response);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSearch = (searchName, value, searchKey) => {
        handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey)
    }

    return (
        <div className="listing-data-grid" style={{ height: "500px" }}>
            {
                fitlerBadge ? (
                    <>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "30px", paddingBottom: "15px" }}>
                            <LeadsFilterAdvancedMain
                                tableUpperButtonArr={tableUpperButtonArr}
                                apiData={subLeadListData}
                                gridRef={gridRef}
                                tabValue={tabValue}
                                setSavedValue={setSavedValue}
                                savedValue={savedValue}
                                defaultFilterValue={defaultFilterValue}
                                handleListingSavedSearchAPI={handleListingSavedSearch}
                                handleFilter={handleSearch}
                            />
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    color: "#008000",
                                    marginLeft: "15px",
                                    fontFamily: 'Times New Roman'
                                }}
                            >
                                Filters:
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "16px",
                                    flexWrap: "wrap",
                                    color: "#008000",
                                    marginLeft: "15px",
                                    fontFamily: 'Times New Roman'
                                }}
                            >
                                {subLeadListData?.length > 0 &&
                                    filters.map((val, i) => {
                                        return (
                                            <Badge
                                                key={i}
                                                badgeContent={getTotalLength(subLeadListData, val.value, val.key)}
                                                color="warning"
                                                max={9999999}
                                                showZero
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "16px",
                                                    color: "#fff",
                                                    marginLeft: "15px",
                                                    background: "#EB6E12",
                                                    borderRadius: "20px",
                                                    padding: "5px 10px",
                                                    marginBottom: "8px",
                                                    cursor: "pointer",
                                                    fontFamily: 'Times New Roman'
                                                }}
                                                onClick={() => handleDefaultFilters(gridRef, setDefaultFilterValue, val.title, val.value, val.key)}
                                            >
                                                <span>{val.title}</span>
                                            </Badge>
                                        );
                                    })}
                                {savedSearchList?.length > 0 &&
                                    savedSearchList?.map((val, i) => {
                                        const value = val.Search_Filter?.split("{")[1];
                                        const keyValue = val.Search_Filter?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")?.split(",")[0].replace("[", "").replace("]", "")
                                        const valueKey = value
                                            ?.split(":")[0]
                                            ?.replaceAll('"', "")
                                            ?.split("_1")[0];
                                        return (
                                            <Badge
                                                key={i}
                                                color="warning"
                                                badgeContent={getTotalLength(subLeadListData, val.Search_Name, valueKey)}
                                                max={9999999}
                                                showZero
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "16px",
                                                    color: "#fff",
                                                    marginLeft: "15px",
                                                    background: "#EB6E12",
                                                    borderRadius: "20px",
                                                    padding: "5px 10px",
                                                    // marginBottom: "15px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    handleFilters(gridRef, setDefaultFilterValue, val.Search_Name, keyValue?.trim(), val.Search_Filter);
                                                    // setSavedValue(val.val.Search_Name);
                                                    setDefaultFilterValue(val.Search_Name);
                                                }}
                                            >
                                                <span>{val.Search_Name}</span>
                                            </Badge>
                                        );
                                    })}
                            </div>
                        </div>


                    </>
                ) : null
            }
            <CommonPageSelect
                search
                onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
                searchValue={searchValue}
                onPageSizeChanged={onPageSizeChanged}
                handleGotoPage={handleGotoPage}
                fromRows={fromRows}
                toRows={toRows}
                length={subLeadListData?.length}
            />

            <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >

                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={subLeadListData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    columnTypes={columnTypes}
                    animateRows={true}
                    components={components}
                    onSelectionChanged={onRowSelected}
                    rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
                    getRowHeight={() => 90}
                    pagination={true}
                    detailRowAutoHeight={true}
                    onPaginationChanged={onPaginationChanged}
                ></AgGridReact>
            </div>
            <ModalPopper />
            <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SubLeadListingTable;
