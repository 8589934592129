import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../provider/context";
import InputText from "../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  padding:"25px",
  height: "auto",
  paddingTop: "0px",
  minHeight: "350px",
  maxHeight: "350px",
 
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if(height && !getIn(errors, fieldName) ){
    return {height:height}
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height:height
    };
  } else if(getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const AddAdminCommentForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    viewRequistion,
    operationInfo,
    getAdminTodayAlertData
  } = useContext(SupportDataContext);

  var today = new Date();
  const currentDate = moment(today).format('YYYY-MM-DD')
  

 const adminCommentsPostAPI = async(values) => {
  const user_type = localStorage.getItem('user_type');
  const emp_id = localStorage.getItem("emp_id");
  const __token = localStorage.getItem("token");
  const user_name = localStorage.getItem("Employee_First_Name");
  // https://flyingcolour.online/fcapp/docprocessing_api/addfollowupcommentbyadmin/
  const url = `https://flyingcolour.online/fcapp/docprocessing_api/addfollowupcommentbyadmin/`;
  const headers = {
    Authorization: `Token ${__token}`,
    "Content-Type": "application/json",
  };

  let body = {
    emp_id: Number(emp_id),
    user_type:user_type,
    comment:values?.comments,
    followup_id: Number(operationInfo?.followup_id),
  }
 
  if (editModalPopper) {

    axios.put(url, body, { headers: headers }).then((data) => {
      setLoading(false);

      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500)
      }
      else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code)
      }
      setIsOpen(true);
      // handlePromise(data);
    })
  } else {
    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500)
      }
      else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code)
      }
      setIsOpen(true);
      // handlePromise(data);
    })
  }

 }

  return (
    <>
    <Formik     
      initialValues={{
       comments: editModalPopper? operationInfo?.comment_by_admin: '',
      }}
      onSubmit={async (data) => {
        setLoading(true)
        adminCommentsPostAPI(data);
        getAdminTodayAlertData(currentDate);
        handleModaleClose();
      }}
      validate={(values) => {
        let errors = {};
        if (!values.comments) {
          errors.approve = "required";
        }
        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={(e) => {e.preventDefault()}}>
            <Box sx={style}>
            <div>
                <div style={{ height: "36px" }}>
              <IconButton
                style={{ float: "right" }}
                className={classes.quotationModalHeaderIconCancle}
                onClick={() => {
                  handleModaleClose();
                }}
              >
                <CloseIcon
                  className={classes.quotationModalHeaderIconCancleIcon}
                />
              </IconButton>
              </div> 
              <div style={{ height: "450px", overflowX: "hidden",padding: "0" }}>
              <Grid container spacing={2} columns={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "-30px",
                    paddingBottom: "10px",
                    marginTop: "-45px",
                    zIndex:10
                  }}
                >
                  <br />
                  <div className={classes.quotationModalHeadingContainer}>
                    <span className={classes.quotationModalHeader}>
                    Comments By Admin Form {``}
                    </span>
                  </div>
                  <br />
                  <div className={classes.quotationModalFlexDivContaner}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.quotationInnerGridRight}
                      >
                           <CustomTextArea
                                label="Comments"
                                fieldName="comments"
                                fieldValue={values.comments}
                                fieldHandleChange={(e) => {
                                  e.preventDefault();
                                  const { value } = e.target;
                                 setFieldValue(
                                    `comments`,
                                    value
                                  );
                                  
                                }}
                                errorField={getStyles(errors)}
                                requiredField={true}
                               /> 
                      </Grid>
                    </Grid>
                   
                  </div>
                  <br />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.quotationButtonDivModal} >
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#eb6e12" }}
                  onClick={handleSubmit}
                >
                  {"Submit"}
                </Button>
                <Button
                  onClick={() => {
                    handleModaleClose();
                    // handleHide()
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              </div>
            
              </div>
            </Box>
           
          </form>
        );
      }}
    </Formik>
     
    </>
  );
};

export default withStyles(ModalPopperFormStyles)(AddAdminCommentForm);
