import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../listing/listingForm/ListingForm";
import InputText from "../../../../../reusableComponents/InputText/InputText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const RejectRequistion = ({ classes, setOpenModal }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    followUp,
    setMessage,
    setSeverity,
    editModalPopper,
    viewFollowup,
    operationInfo,
    getFollowUpTableAndDropdown,
    setIsOpen,
    getProHeadRequistionDataApi,
    proHeadRequistionData
  } = useContext(SupportDataContext);

  const rejectRequistionPostAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const url = `https://flyingcolour.online/fcapp/pro_head/PRORequisitionRejectByPROHead/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      requisition_id: operationInfo?.requisition_id,
      order_id: operationInfo?.order_id,
      requisition_no: operationInfo?.requisition_no,
      startdate: moment(values?.startDate)?.format('YYYY-MM-DD HH:mm:ss'),
      enddate: moment(values.endDate)?.format('YYYY-MM-DD HH:mm:ss'),
      requisition_date: operationInfo?.req_datentime,
      req_amount: operationInfo?.req_amount,
      reject_reason: values?.rejectReason
    };
    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      handleQuotationPromise(data);
    });
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getProHeadRequistionDataApi();
    setOpenModal(false);
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        startDate: new Date(operationInfo?.startdate),
        endDate: new Date(operationInfo?.enddate),
        rejectReason: "",
      }}
      onSubmit={async (data) => {
        setLoading(true);
        rejectRequistionPostAPI(data);
      }}
      validate={(values) => {
        let errors = {};

        if (!values.rejectReason) {
          errors.rejectReason = "required";
        }

        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          Reject requistion
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                            className={classes.quotationInnerGridRight}
                          >
                            <InputText
                              label="Requistion Id"
                              fieldName="requisition_no"
                              fieldValue={operationInfo?.requisition_no}
                              disableField={true}
                            />

                            <InputText
                              label="Requistion Date & Time"
                              fieldName="requisition_date"
                              fieldValue={operationInfo?.req_datentime}
                              disableField={true}
                            />

                            <InputText
                              label="Order Id"
                              fieldName="order_id"
                              fieldValue={operationInfo?.order_id}
                              disableField={true}
                            />

                            <InputText
                              label="Company Name"
                              fieldName="company_name"
                              fieldValue={operationInfo?.company_name}
                              disableField={true}
                            />

                            <InputText
                              label="Main Task Name"
                              fieldName="main_task_name"
                              fieldValue={operationInfo?.main_task_name}
                              disableField={true}
                            />

                            <InputText
                              label="Task Name"
                              fieldName="task_name"
                              fieldValue={operationInfo?.task_name}
                              disableField={true}
                            />

                            <InputText
                              label="Work Type"
                              fieldName="work_type"
                              fieldValue={operationInfo?.work_type}
                              disableField={true}
                            />

                          
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className={classes.quotationInnerGridRight}
                          > 
                            <InputText
                              label="Amount"
                              fieldName="req_amount"
                              fieldValue={operationInfo?.req_amount}
                              disableField={true}
                            />

                            <Form.Group as={Row} className="mb-1">
                              <Form.Label
                                column
                                sm="5"
                                className="fc-textarea-label"
                              >
                                PRO Date & Time
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{
                                  padding: "0 12px 0 0",
                                }}
                              >
                                <div
                                  style={{
                                    border: "0.1px solid #d3d3d3",
                                    marginBottom: 10,
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={getStyles(errors, values?.startDate)}
                                  >
                                    <DatePicker
                                      selected={values.startDate}
                                      minDate={moment().toDate()}
                                      onChange={(date) => {
                                        setFieldValue(`startDate`, date);
                                      }}
                                      showTimeInput
                                      fixedHeight
                                      dateFormat="dd-MM-yyyy h:mm aa"
                                      placeholderText="Start Date"
                                      disabled={true}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    border: "0.1px solid #d3d3d3",
                                    marginBottom: 10,
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={getStyles(errors, values?.endDate)}
                                  >
                                    <DatePicker
                                      selected={values.endDate}
                                      minDate={moment().toDate()}
                                      onChange={(date) => {
                                        setFieldValue(`endDate`, date);
                                      }}
                                      showTimeInput
                                      fixedHeight
                                      dateFormat="dd-MM-yyyy h:mm aa"
                                      placeholderText="End Date"
                                          disabled={true}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>

                            <CustomTextArea
                              label="Reject Reason"
                              fieldName="rejectReason"
                              fieldValue={values?.rejectReason}
                              fieldHandleChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue("rejectReason", value);
                              }}
                              requiredField={true}
                              errorField={getStyles(errors, values?.rejectReason)}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(RejectRequistion);
