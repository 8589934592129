import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, TextField, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import logo from "../../commonComp/assets/save.png";
import logoMobile from "../../commonComp/assets/finallogo.png";
import styles from "./styles/style";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BeachLanguageIconAccessIcon from "@mui/icons-material/Language";
import NavigationIcon from "@mui/icons-material/Navigation";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "./styles/style.css";
import { DataContext } from "../../provider/context";
import Error from "../listing/listingTable/ListingExpandableTable/modal/Error";
import CryptoJS from 'crypto-js';
import axios from "axios";



const validationSchema = yup.object({
  username: yup
    .string("Enter your userName")
    .matches(
      /^[ A-Za-z0-9_@.#$-]*$/,
      "Only alphabets are allowed for this field "
    )
    .required("username is required"),
  password: yup.string("Enter your password").required("Password is required"),
  otp: yup
    .string("Enter your OTP")
    // .min(5, 'OTP should be of minimum 6 characters')
    .required("OTP is required"),
});
const Authentication = (props) => {
  const [verification, setVerification] = useState(false);
  const { classes } = props;
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState({ otp: "", user_name: "" });
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [btnDisabledOtp, setBtnDisabledOtp] = useState(true);
  const [btnDisabledPass, setBtnDisabledPass] = useState(true);
  const [encrptedData, setEncrptedData] = useState("");
  const [decryptedData, setDecryptedData] = useState("");
  const [getData, setMyData] = useState("");
  const [encryptedPassword, setEncryptedPassword] = useState("");
  const [encryptedUsername, setEncryptedUsername] = useState("");
  const [usernameSecretKeys, setUsernameSecretKeys] = useState("");
  const [passwordSecretKeys, setPasswordSecretKeys] = useState("");
  const [oTPSecretKeys, setOtpSecretKeys] = useState("");


  const {
    setAuthToken,
    setExpirToken,
    expirToken,
    crmBlockonCompanyHistoryList,
    crmBlocked,
    userTypeInfo,
    setErrorAlert,
    setError,
  } = useContext(DataContext);

  const SECRET_KEY = '0123456789abcdef';
  // const encryptData = () => {
  //   const data = CryptoJS.AES.encrypt(
  //     JSON.stringify("abc123"), SECRET_KEY).toString();
  //   setEncrptedData(data);
  // };

  function encryptData(data, key, iv) {
    const keyHex = CryptoJS.enc.Base64.parse(key);
    const ivHex = CryptoJS.enc.Base64.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(data, keyHex, {
      iv: ivHex,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return encrypted.toString();
  }

  useEffect(() => {
    const idb = window?.indexedDB;
    const dbPromise = idb.open("inquiry-db", 2);

    dbPromise.onupgradeneeded = (event) => {
      const db = dbPromise.result;

      if (!db.objectStoreNames.contains("listing_api_res")) {
        db.createObjectStore("listing_api_res", {
          keyPath: "Serial_Number",
        });
      }
    };

    dbPromise.onsuccess = () => {
      const db = dbPromise.result;
      const tx = db.transaction('listing_api_res', 'readwrite');
      const data = tx.objectStore('listing_api_res');
      data.clear();
      tx.oncomplete = () => {
        db.close();
      }
      tx.onerror = () => {
        db.close();
      }
    };


  }, [])

  const homePush = async (token, userType, msg, crm_block, factory_id) => {
    const lowerCaseUserType = userType.toLowerCase();
    return token && msg !== "Invalid OTP" && crm_block === "Yes" && userType === "SALES_PERSON"
    ? navigate("/bizsetup-sales-company-history", {
      state: { token: token },
    })
    : token && msg !== "Invalid OTP" && userType === "SALES_PERSON" && crm_block === "No"
    ? navigate("/bizsetup-sales-listing", { state: { token: token } })
    : token && msg !== "Invalid OTP" && userType === "SALES_PERSON_AGENT" && crm_block === "No"
    ? navigate("/bizsetup-sales-agent-listing", { state: { token: token } })
    : token && userType === "RECEPTIONIST" && msg !== "Invalid OTP"
    ? navigate("/reception-dashboard", { state: { token: token } })
    : token && userType === "CLIENT_MANAGER" && msg !== "Invalid OTP"
    ? navigate("/bizsetup-clientmanager-listing", {
    state: { token: token },
    })
    : token && userType === "CLIENT_MANAGER_TAX" && msg !== "Invalid OTP"
      ? navigate("/clientmanager-tax-listing", {
        state: { token: token },
      })
    : token && userType === "CLIENT_MANAGER_SH" && factory_id == "23" && msg !== "Invalid OTP"
      ? navigate("/clientmanager-sh-listing", {
        state: { token: token },
      })
    : token && userType === "Support" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-support-listing", {
       state: { token: token },
      })
    : token && userType === "Accountant" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-accountants-listing", {
      state: { token: token },
      })
    : token && userType === "REAL_ESTATE_INVENTORY" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-realestate-listing", {
      state: { token: token },
      })
    : token && userType === "Admin" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-admin-listing", {
      state: { token: token },
      })
      : token && userType === "COMPLIANCE_OFFICER" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-compliance-listing", {
      state: { token: token },
      })
      : token &&
      userType === "CLIENT_DATA_CONTROLLER" &&
      msg !== "Invalid OTP"
      ? navigate("/bizsetup-clinet-data-controler-listing", {
      state: { token: token },
      })
      : token && userType === "Project_Designer" &&
      msg !== "Invalid OTP"
      ? navigate("/bizsetup-project-designer-listing", {
      state: { token: token },
      })
      : token &&
      userType === "RECEIVABLE_MANAGER" &&
      msg !== "Invalid OTP"
      ? navigate("/bizsetup-receivable-manager-listing", {
        state: { token: token },
      })
      : token && userType === "Typist" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-typist-listing", { state: { token: token }, })
      : token && userType === "PRO_Head" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-prohead-listing", { state: { token: token }, })
      : token && userType === "DOCUMENT_CONTROLLER" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-document-controller-listing", { state: { token: token }, })
      : token && userType === "COMMERCIAL_PARTNER_TEAM_LEADER" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-commercial-partner-team-leader-listing", { state: { token: token }, })
      : token && userType === "Operation_IRT" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-operation-irt-listing", { state: { token: token }, })
      : token && userType === "COMMERCIAL_PARTNER_COORDINATOR" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-commercial-partner-coordinator-listing", { state: { token: token }, })
      : token && lowerCaseUserType === "quality_analyst" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-quality-analyst-listing", { state: { token: token }, })
      : token && lowerCaseUserType === "prequalifyleads" && msg !== "Invalid OTP"
      ? navigate("/bizsetup-pre-qualify-listing", { state: { token: token }, })
      : token && lowerCaseUserType === "aichatboat" && msg !== "Invalid OTP"
      ? navigate("/AI-Chatbot", { state: { token: token }, })
      : null

    // token && userType === "RECEPTIONIST" ? navigate('/bizsetup-sales-dashboard') : navigate('/reception-dashboard')
  };
  const handleClicked = () => {
    !formik.errors.username && !formik.errors.password
      ? setVerification(true)
      : setVerification(false);
  };

  const handleClick = (event) => {
    handleClicked();

    if (
      otpMessage?.data?.msg ===
      "Your OTP is Expired Please Login again for new OTP."
    ) {
      handleUSerPassword();
      setVerification(false);
    } else if (
      isError?.data?.code === 200 &&
      message?.data?.msg !== "invalid users!"
    ) {
      handleVerifyOtp();
    } else {
      handleUSerPassword();
      setVerification(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: username,
      password: password,
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => { },
  });

  const servicesArr = [
    {
      icon: <NavigationIcon />,
      service: "Business Setup in UAE",
    },
    {
      icon: <AssignmentOutlinedIcon />,
      service: "Accounting & VAT Services in UAE",
    },
    {
      icon: <MapsHomeWorkIcon />,
      service: "Real Estate Brokerage ",
    },
    {
      icon: <BeachLanguageIconAccessIcon />,
      service: "Global Business Setup",
    },
  ];
  const handleUSerPassword = async () => {

    const form = {
      otp: otp,
      username: usernameSecretKeys,
      password: passwordSecretKeys,
    };

    setLoading(true);
    await fetch("https://flyingcolour.online/fcapp/api/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(form),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage(data);
        setIsError(data);
        setLoading(false);
        if (
          data?.data?.data?.token !== undefined &&
          data?.data?.data?.User_Type !== undefined &&
          data?.data?.data?.token !== null &&
          data?.data?.data?.User_Type !== null
        ) {
          localStorage.setItem("token", data?.data?.data?.token);
          localStorage.setItem("userType", data?.data?.data?.User_Type);
          localStorage.setItem("user_type", data?.data?.data?.User_Type);
          localStorage.setItem("emp_id", data?.data?.data?.Emp_Id);
          localStorage.setItem("factory_id", data?.data?.data?.factory_id);
          localStorage.setItem("crm_block", data?.data?.data?.crm_block);
          localStorage.setItem(
            "Employee_First_Name",
            data?.data?.data?.Employee_First_Name
          );
          localStorage.setItem(
            "Employee_Last_Name",
            data?.data?.data?.Employee_Last_Name
          );
          localStorage.setItem(
            "userName",
            data?.data?.data?.username
          );
          homePush(
            data?.data?.data?.token,
            data?.data?.data?.User_Type,
            data?.data?.msg,
            data?.data?.data?.crm_block,
            data?.data?.data?.factory_id
          );
        }
      })
      .catch((error) => {
        if (!error?.response) {
          return (
            setLoading(false),
            setErrorAlert(true),
            setError(
              `${error}-${"Please Check Your Network Connectivity and try again!!"}`
            )
          );
        } else {
          console.error("Error:", error);
        }
      });
  };

  const handleVerifyOtp = async (e) => {
    const otpForm = {
      otp: oTPSecretKeys,
      user_name: usernameSecretKeys,

    }
    setLoading(true);
    let response = await fetch(
      "https://flyingcolour.online/fcapp/api/login/verify/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(otpForm),
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        await setOtpMessage(data);
        if (
          data?.data?.data?.token !== undefined &&
          data?.data?.data?.User_Type !== undefined &&
          data?.data?.data?.token !== null &&
          data?.data?.data?.User_Type !== null
        ) {
          localStorage.setItem("token", data?.data?.data?.token);
          localStorage.setItem("userType", data?.data?.data?.User_Type);
          localStorage.setItem("user_type", data?.data?.data?.User_Type);
          localStorage.setItem("emp_id", data?.data?.data?.emp_id);
          localStorage.setItem("factory_id", data?.data?.data?.factory_id);
          localStorage.setItem(
            "Employee_First_Name",
            data?.data?.data?.Employee_First_Name
          );
          localStorage.setItem(
            "Employee_Last_Name",
            data?.data?.data?.Employee_Last_Name
          );
          crmBlockonCompanyHistoryList(
            data?.data?.data?.token,
            data?.data?.data?.User_Type,
            data?.data?.data?.emp_id,
            data?.data?.data?.factory_id
          );
          homePush(
            data?.data?.data?.token,
            data?.data?.data?.User_Type,
            data?.data?.msg,
            data?.data?.data?.crm_block,
            data?.data?.data?.factory_id
          );
          setLoading(false);
          setAuthToken(data?.data?.data?.token);
        }
        if (
          data?.data?.msg === "Invalid OTP" ||
          data?.data?.msg === "Something went wrong, please login again for OTP"
        ) {
          formik.setFieldValue("otp", "");
          setBtnDisabledOtp(true);
        } else if (
          data?.data?.msg ===
          "Your OTP is Expired Please Login again for new OTP."
        ) {
          formik.resetForm();
          formik.setFieldValue("otp", "");
          formik.setFieldValue("username", "");
          formik.setFieldValue("password", "");
          setVerification(false);
          setBtnDisabledOtp(true);
          alert("Your OTP is Expired Please Login again for new OTP.");
        }
      })
      .catch((error) => {
        if (!error?.response) {
          return (
            setLoading(false),
            setErrorAlert(true),
            setError(
              `${error}-${"Please Check Your Network Connectivity and try again!!"}`
            )
          );
        } else {
          console.error("Error:", error);
        }
      });
  };
  const handleResetOtpField = () => {
    // if (otpMessage?.data?.msg === 'Invalid OTP') (
    //    setOtp('')
    //     formik.setFieldValue("otp", '')
    // ) else {
    //   null
    // }
  };

  return (
    <div
      className={classes.containerLogin}
      style={{
        padding: "29px",
        overFlow: "hidden",
        backgroundColor: "#575756",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        position: " fixed",
        top: " 0px",
        left: " 0px",
        right: " 0px",
        bottom: " 0px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <Grid container className={classes.gridContsiner}>
          <Grid item xs={12} sm={12} md={6} className={classes.loginGrid}>
            <Grid container direction="column" alignItems="center">
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <img
                  src={logoMobile}
                  className={classes.loginMobileLogo}
                  alt="img"
                />
              </Box>
              <div className={classes.loginContent}>
                <Typography variant="subtitle1" className={classes.loginText}>
                  <span style={{ color: "#575756" }}> Welcome To</span>{" "}
                  Flyingcolour
                </Typography>
                <p className={classes.loginContainerHeader} variant="caption">
                  Please login to your account
                </p>
                <div className={classes.formContainer}>
                  {otpMessage?.data?.msg ? (
                    <span className={classes.apiMessage}>
                      {otpMessage?.data?.msg}{" "}
                    </span>
                  ) : isError?.data?.isError ? (
                    <span className={classes.apiMessage}>
                      {message?.data?.msg}
                    </span>
                  ) : !isError?.data?.isError ? (
                    <span className={classes.apiMessageSuccses}>
                      {/* {message?.data?.msg} */}
                    </span>
                  ) : expirToken ? (
                    <span className={classes.apiMessageSuccses}>
                      Session expired due to inactivity, Please login again
                    </span>
                  ) : null}

                  <CustomTextField
                    variant="outlined"
                    className={classes.loginInputFieldStyles}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    type="text"
                    id="username"
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) => {
                      e.preventDefault();

                      const { value } = e.target;
                      setUsername(value);

                      const key = CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(32));
                      const iv = CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(16));
                      const encryptedUser = value !== "" && encryptData(value, key, iv);
                      setEncryptedUsername(encryptedUser)
                      setUsernameSecretKeys({ data: encryptedUser, key: key, iv: iv })


                      const regex = /^[ A-Za-z0-9_@.#$-]*$/;
                      if (!value || regex.test(value.toString())) {
                        formik.setFieldValue("username", value);
                      }
                      setBtnDisabled(!value);
                    }}
                    error={
                      formik.touched.username && Boolean(formik.errors.otp)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                    InputLabelProps={{
                      style: { color: "black", fontWeight: "bold" },
                    }}
                  />
                  <CustomTextField
                    variant="outlined"
                    className={classes.loginInputFieldStyles}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    InputLabelProps={{
                      style: { color: "black", fontWeight: "bold" },
                    }}
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      setPassword(value);

                      const key = CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(32));
                      const iv = CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(16));
                      const encryptedPass = value !== "" && encryptData(value, key, iv);
                      setEncryptedPassword(encryptedPass);
                      setPasswordSecretKeys({ data: encryptedPass, key: key, iv: iv });

                      const regex = /^(?!\s)[A-Za-z0-9\s, &^%$#@!*()_+=?/]+$/;
                      if (!value || regex.test(value.toString())) {
                        formik.setFieldValue("password", value);
                      }
                      setBtnDisabledPass(!value);
                    }}

                    error={
                      formik.touched.password && Boolean(formik.errors.otp)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />

                  {
                    !isError?.data?.isError &&
                      formik.values.username &&
                      formik.values.password &&
                      verification &&
                      !loading || otpMessage?.data?.msg === "Invalid OTP" ? (
                      <CustomTextField
                        variant="outlined"
                        className={classes.loginInputFieldStyles}
                        id="otp"
                        name="otp"
                        label="OTP"
                        value={formik.values.otp}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          setOtp({ otp: value, user_name: username });

                          const key = CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(32));
                          const iv = CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(16));
                          const encryptedOTP = value !== "" && encryptData(value, key, iv);
                          setOtpSecretKeys({ data: encryptedOTP, key: key, iv: iv });

                          handleResetOtpField();
                          const regex = /^[0-9\b]+$/;

                          if (!value || regex.test(value.toString())) {
                            formik.setFieldValue("otp", value);
                          }
                          setBtnDisabledOtp(!value);
                        }}
                        InputLabelProps={{
                          style: { color: "black", fontWeight: "bold" },
                        }}
                        error={formik.touched.otp && Boolean(formik.errors.otp)}
                        helperText={formik.touched.otp && formik.errors.otp}
                      />
                    ) : null}
                </div>

                <div className={classes.formGroup}></div>

                <button
                  type="submit"
                  className={classes.button}
                  onClick={() => {
                    handleClick();
                    setOtpMessage("");
                    setVerification(true);
                    // encryptData();
                    // getEncryptedDataApi();
                    // getDecryptedData();
                  }}
                  disabled={btnDisabled || btnDisabledPass}
                >
                  {loading && !otpMessage ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </Grid>
          </Grid>
          <Box sx={{ display: { xs: "none", md: "contents" } }}>
            <Grid item xs={12} sm={6} md={6} className={classes.rightPane}>
              <div className={classes.logoImgContainer}>
                <img
                  src={logo}
                  className={classes.logoName}
                  width="150 !important"
                  height="80 !important"
                  alt=""
                />
              </div>
              <div className={classes.imageHeadline}>
                <Typography
                  variant="h1"
                  component="div"
                  className={classes.imageTag}
                >
                  Flyingcolor Consulting-360 advisory
                </Typography>
                <div>
                  <List sx={{ width: "100%", maxWidth: 360, bgcolor: "none" }}>
                    {servicesArr.map((item, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar>{item.icon}</Avatar>
                          </ListItemAvatar>
                          <CustomListItemText primary={item.service} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </Grid>
          </Box>
        </Grid>
      </form>
      <Error />
    </div>
  );
};
const CustomListItemText = withStyles({
  root: {
    "&.css-tlelie-MuiListItemText-root": {
      fontFamily: "Times New Roman ",
      fontWeight: 600,
    },
  },
})(ListItemText);

const CustomTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#575756",
    },
    "& .MuiOutlinedInput-input": {
      color: "#575756",
      backgroundColor: "#bcbcbc",
      marginTop: "-4px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#cccccc",
      },
    },
  },
})(TextField);

export default withStyles(styles)(Authentication);
