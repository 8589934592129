import React from "react";
import ReactDOM  from "react-dom";
import App from "./App";
import { createTheme, MuiThemeProvider, CssBaseline } from "@material-ui/core";
import DataProvider from "./provider/provider";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary/ErrorBoundary";
import Dialog from '@mui/material/Dialog';
import Error from "./pages/listing/listingTable/ListingExpandableTable/modal/Error";
import OperationProvider from "./provider/operationProvider";
import 'bootstrap/dist/css/bootstrap.min.css';

const theme = createTheme({
  typography: {
    fontFamily: "Times New Roman !important",
  },
  listItemText: {
    fontFamily: "Times New Roman !important",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <DataProvider>
      <OperationProvider>
      <Router basename={"/app"}>
        <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
        </ErrorBoundary>
      </Router>
      </OperationProvider>
    </DataProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
