import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import axios from "axios";
import modalTableStyles from "../../styles/ModalTableStyles";
import {
  SupportDataContext,
  DataContext,
} from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";

const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddTemplateCreationForm = ({ classes }) => {
  const {
    handleModalTableClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    getTemplateCreationListgDataApi,
    templateCreationList,
    operationInfo,
    setOperationInfo,
  } = useContext(SupportDataContext);

  const [isTemplateNameExist, setIsTemplateNameExist] = useState(false);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },
  }));
  const tagNameDropdown = templateCreationList?.Tag_Name_List?.map(
    (item, i) => {
      const obj = { value: item.Tag_Id, label: item.Tag_Name };
      return obj;
    }
  );

  const templateCategoryList = templateCreationList?.Template_Category?.map(
    (item, i) => {
      const obj = { value: item, label: item };
      return obj;
    }
  );

  const statusDropdown = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const addTemplateCreationPOSTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const user_name = localStorage.getItem("Employee_First_Name");
    // https://flyingcolour.online/fcapp/compliance/checkverificationonpartner/
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/serviceName_Master_view/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let tagValues = values?.templateTagName?.map((val) => {
      return { Id: val.value, Name: val.label };
    });

    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);
    formData.append("service_name", values?.templateName);
    formData.append("template_category", values?.templateCategory);
    formData.append("service_details", values?.templateDescription);
    formData.append("tag_array", JSON.stringify(tagValues));
    formData.append("active_inactive", values?.status);

    if (editModalPopper) {
      formData.append("service_id", operationInfo?.Service_Id);
      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        getTemplateCreationListgDataApi();
        handleModalTableClose();
      });
    } else {
      formData.append("service_id", 0);
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        getTemplateCreationListgDataApi();
        handleModalTableClose();
      });
    }
  };

  const checkIsTemplateNameExist = (value, setFieldValue) => {
    if (
      editModalPopper &&
      operationInfo?.Service_Name?.toLowerCase() === value.toLowerCase()
    ) {
      setIsTemplateNameExist(false);
      return;
    }
    if (
      templateCreationList?.Template_Service_Name_List?.some(
        (item) => item.Service_Name.toLowerCase() === value.toLowerCase()
      )
    ) {
      setFieldValue("templateName", "");
      setIsTemplateNameExist(true);
    } else {
      setIsTemplateNameExist(false);
    }
  };

  const handleTemplateTag = () => {
    let tagValues = operationInfo?.tag_array?.map((val) => {
      return { label: val.Name, value: val.Id };
    });

    return tagValues;
  };

  return (
    <>
      <Formik
        initialValues={{
          templateName: editModalPopper ? operationInfo?.Service_Name : "",
          templateCategory: editModalPopper
            ? operationInfo?.template_category
            : "",
          templateDescription: editModalPopper
            ? operationInfo?.Service_Details
            : "",
          templateTagName: editModalPopper ? handleTemplateTag() : "",
          status: 1,
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addTemplateCreationPOSTAPI(data);
        }}
        validate={(values) => {
          // setDisabledId(values?.mainTaskArray)
          let errors = {};
          if (values.templateName === "") {
            errors.templateName = "Required!!";
          }
          if (values.templateCategory === "") {
            errors.templateCategory = "Required!!";
          }
          if (values.templateTagName === "") {
            errors.templateTagName = "Required!!";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              <div className={classes.quotationButtonDivModal}>
                  <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#eb6e12",
                    fontFamily: "Times New Roman !important",
                    marginRight: 10,
                    cursor:'default'
                  }}
                >
                  
                  Template Creation
                </Button>
                <BootstrapTooltip arrow={true} disableFocusListener>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: "#eb6e12" }}
                    onClick={handleSubmit}
                  >
                    {loading ? "Loading...." : "Save"}
                  </Button>
                </BootstrapTooltip>
                <Button
                  onClick={() => {
                    handleModalTableClose();
                    // handleHide()
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div
                style={{ height: "300px", overflowX: "hidden", padding: "0" }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                      zIndex: 10,
                    }}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.quotationInnerGridRight}
                        >
                          <InputText
                            label="Template Name"
                            fieldName="templateName"
                            fieldValue={values?.templateName}
                            fieldHandleChange={(event) => {
                              setFieldValue(`templateName`, event.target.value);
                            }}
                            requiredField={true}
                            errorField={getStyles(errors, values.templateName)}
                            onBlur={() =>
                              checkIsTemplateNameExist(
                                values?.templateName,
                                setFieldValue
                              )
                            }
                            //classvalue={"textBold"}
                          />
                          {isTemplateNameExist && (
                            <Form.Group as={Row} className="mb-1">
                              <Form.Label
                                column
                                sm="5"
                                className="fc-text-label"
                              ></Form.Label>
                              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
                                <span style={{ color: "red" }}>
                                  Template Name already exists!!!
                                </span>
                              </Col>
                            </Form.Group>
                          )}

                          { editModalPopper ? (
                            <InputText
                            label="Template Category"
                            fieldName="templateCategory"
                            fieldValue={operationInfo?.template_category}
                            fieldHandleChange={(event) => {
                              //setFieldValue(`templateName`, event.target.value);
                            }}
                            requiredField={true}
                            errorField={getStyles(errors, values.templateName)}
                           disableField={true}
                          />
                          ) :
                            <CustomDropdown
                            label="Template Category"
                            fieldOptions={templateCategoryList}
                            fieldValue={values?.templateCategory}
                            fieldHandleChange={(value) => {
                              setFieldValue(`templateCategory`, value.value);
                            }}
                            errorField={getStyles(
                              errors,
                              values?.templateCategory
                            )}
                            requiredField={true}
                          />}
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                  </Grid>
                  <Grid item xs={12} md={6} style={{marginTop: 10}}>
                    <br />
                    <div className={classes.quotationModalFlexDivContaner}>
                      <CustomMultiSelectDropdown
                        label="Template Tag Name"
                        fieldOptions={tagNameDropdown}
                        fieldValue={values?.templateTagName}
                        fieldHandleChange={(value) => {
                          setFieldValue(`templateTagName`, value);
                        }}
                        errorField={getStyles(errors, values?.templateTagName)}
                        requiredField={true}
                      />

                      <CustomTextArea
                        label="Template Remarks"
                        fieldName="templateDescription"
                        fieldValue={values.templateDescription}
                        fieldHandleChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          setFieldValue(`templateDescription`, value);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(modalTableStyles)(AddTemplateCreationForm);
