import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import LoaderClock from "../../../../lodar/LoaderClock";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";


const ViewRequisitionTable = ({ classes }) => {
  const {
    requistionListing,
    loading,
    handleModalOpen,
    setViewRequistion,
    operationInfo,
    getRequistionListingDataFromApi,
    setEditModalPopper

  } = useContext(SupportDataContext);
  useEffect(() => {
    getRequistionListingDataFromApi();
  }, [operationInfo.Order_Id]);
  const gridRef = useRef();

  const [columnDefs] = useState([
    {
      headerName: "S#",
      field: "Serial_Number",
      width: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    
    {
      headerName: "Requistion Id",
      field: "requisition_auto_id",
      filter: "agTextColumnFilter",
      width: 170,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
        headerName: "Company Name",
        field: "company_name",
        filter: "agTextColumnFilter",
        width: 200,
       
        pinned: "left",
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Requistion Date",
      field: "requisition_date",
      filter: "agDateColumnFilter",
      width: 180,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      cellRenderer: (params) => {
        params = params?.data;
        return (
         moment(params?.requisition_date).format("DD-MM-YYYY")
        );
      },
    },
    {
      headerName: "Work Type",
      field: "work_type_name",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Req. Amount",
      field: "req_amt",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Account Status",
      field: "accountsStatus",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
  },
    {
      headerName: "PRO Head Status",
      field: "proHeadStatus",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
  },
 
  {
    headerName: "Support Status",
    field: "support_status",
    filter: "agTextColumnFilter",
    width: 150,
    cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
},
  
  {
      headerName: "Main Task Name",
      field: "mainTaskName",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
  },
  {
      headerName: "Task Name",
      field: "sub_task_name",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
  },
  {
    headerName: "Task Category",
    field: "task_category",
    filter: "agTextColumnFilter",
    width: 170,
    cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
  },
  {
    headerName: "Typist Name",
    field: "Typist_name",
    filter: "agTextColumnFilter",
    width: 170,
    cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
  },
  {
    headerName: "PRO Status",
    field: "proStatus",
    filter: "agTextColumnFilter",
    width: 150,
    cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
},
    {
      headerName: "Requistion Refund",
      field: "refund_req_status",
      filter: "agTextColumnFilter",
      width: 170,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Reschedule/Reassign",
      field: "reschedule_status",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto", },
    },
   
    {
      headerName: "Voucher No.",
      field: "voucher_no",
      filter: "agTextColumnFilter",
      width: 170,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
    },
    {
      headerName: "Requisition Remarks",
      field: "remarks",
      filter: "agTextColumnFilter",
      width: 300,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px", overflowY:"auto" },
    },
    {
      headerName: "Typist Done Remarks",
      field: "req_typist_done_comment",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto'},
      width: 300,
    },
    {
        headerName: "PI Remarks",
        field: "pi_remarks",
        filter: "agTextColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px", overflowY:"auto" },
    },
    {
        headerName: "Order Created Date",
        field: "order_created_date",
        filter: "agDateColumnFilter",
        width: 170,
        cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
        cellRenderer: (params) => {
          params = params?.data;
          return (
           moment(params?.order_created_date).format("DD-MM-YYYY")
          );
        },
    },
    
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 50,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <LoaderClock loading={loading} />
      <TableContainer component={Paper}>
            <div
            className="ag-theme-alpine"
            style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
          >
            <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
              ref={gridRef}
              rowData={requistionListing?.Requisition_List}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowHeight={()=>90}
              rowStyle={{
                background: "#DEDEDE",
                color: "#000000DE",
                borderBottom: "1px solid white",
              }}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
      </TableContainer>
      <Error  />
    </div>
  );
};

export default withStyles(modalTableStyles)(ViewRequisitionTable);
