import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import LoaderClock from "../../../../lodar/LoaderClock";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";


const FollowupTable = ({ classes }) => {
  const {
    //getFollowUpView,
    followUp,
    loading,
    handleModalOpen,
    setViewFollowUp,
    operationInfo,
    getFollowUpTableAndDropdown,
    setEditModalPopper,
    getAddminFollowUpApi,adminFolloUpData

  } = useContext(SupportDataContext);
  useEffect(() => {
    //getFollowUpView();
    getAddminFollowUpApi();
  }, [operationInfo.Order_Id]);
  const gridRef = useRef();

  const [columnDefs] = useState([
    {
      headerName: "S#",
      field: "Serial_Number",
      maxWidth: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },

    {
      headerName: "Followup Message",
      field: "Followup_Message",
      filter: "agTextColumnFilter",
      minWidth: 390,
      maxWidth: 450,
      flex:1,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Due Date",
      field: "Due_Date",
      filter: "agDateColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Follow Up Type",
      field: "Followup_Type",
      filter: true,
      width: 170,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Priority",
      field: "Priority",
      filter: true,
      width: 160,
      maxWidth: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Followup Status",
      field: "Followup_Status",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto" },
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      filter: "agTextColumnFilter",
      minWidth: 210,
      maxWidth: 260,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
      flex:1
    },
    {
      headerName: "Commented By Admin",
      field: "Commented_By_Admin",
      filter: "agTextColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto",height:"90px" },
    },
    {
      headerName: "Share Sent",
      field: "Share_Sent_Via",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center",overflowY:"auto"},
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      filter: "agDateColumnFilter",
      minWidth: 150,
      flex:1,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.created_date).format("DD-MM-YYYY hh:mm:ss")}</>;
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
     
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>
      <LoaderClock loading={loading} />
      <TableContainer component={Paper}>
            <div
            className="ag-theme-alpine"
            style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
          >
            <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
              ref={gridRef}
              rowData={adminFolloUpData?.Order_Followup_List}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowHeight={()=>90}
              rowStyle={{
                background: "#DEDEDE",
                color: "#000000DE",
                borderBottom: "1px solid white",
              }}
              enableCellChangeFlash={true}
            ></AgGridReact>
          </div>
      </TableContainer>
      <Error  />
    </div>
  );
};

export default withStyles(modalTableStyles)(FollowupTable);
