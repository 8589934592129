import React, { useEffect, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  useMediaQuery,
  useTheme,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import "./styles/realEstateTopbar.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ImgLogo from "../../../commonComp/assets/NewLogo.svg";
import Logo from "../../../commonComp/assets/save.png";
import { useNavigate } from "react-router-dom";
import styles from "../../authentication/styles/style";
import axios from "axios";
import { DataContext } from "../../../provider/context";
import Error from "../../listing/listingTable/ListingExpandableTable/modal/Error";
import Notify from "../../../commonComp/notification";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Dropdown from "react-bootstrap/Dropdown";
import CommentNotifications from "./CommentNotifications";



const expensionArr = [
  {
    icon: <HomeIcon style={{ color: "green" }} />,
    dis: "its my home",
    tittle: "Rentals",
  },
  {
    icon: <HomeIcon style={{ color: "red" }} />,
    dis: "Sales of the day",
    tittle: "Sales",
  },
  {
    icon: <PermContactCalendarIcon style={{ color: "gray" }} />,
    dis: "Prson Contacts",
    tittle: "Contacts ",
  },
  {
    icon: <PermIdentityIcon style={{ color: "blue" }} />,
    dis: "Leads",
    tittle: "Leads",
  },
  {
    icon: <CalendarTodayIcon style={{ color: "yellow" }} />,
    dis: "new date",
    tittle: "Calender",
  },
];

const AdminTopbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const {  setExpirToken,setErrorAlert,setError, setSupportBadges } = useContext(DataContext);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [checked, setChecked] = React.useState([]);
  const [commentNotifications, setCommentNotifications] = React.useState([]);

  const __token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  const factory_id = localStorage.getItem("factory_id");
  const emp_id = localStorage.getItem("emp_id");

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  //-------responsive---------
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("sx"));

  useEffect(() => {
    fetchBadgesCount();
    fetchNotification();
    getCommentNotificationApi();
    const interval = setInterval(() => {
      return fetchBadgesCount();
    }, 300000);
    return () => clearInterval(interval);
  }, [user_type]);

  const fetchBadgesCount = async () => {

    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/BadgeCountForAdmin/?emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      if (
        res?.data?.results === undefined && res?.data?.detail === undefined
      ) {
        return setErrorAlert(true), setError(`ERROR ${res?.data?.code}-${res?.data?.msg}${" "}${"Error is from Badge Count API"}`);
      }else  if (res?.data?.detail !== undefined && res?.data?.results === undefined ) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res?.data?.msg}${" "}${"Error is from Badge Count API"}`);
      }else { 
        return setSupportBadges(res?.data?.results?.Badge_Count?.[0]);
      }
    } catch (err) {
      if(err?.response?.status === 401) {
    
        localStorage.clear(); 
        return navigate("/login")
      }
    }
  };

  const fetchNotification = async () => {
    const userName = localStorage.getItem('userName');
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/Notification/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&read_unread_flag=2&user_name=${userName}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      if (
        res?.data?.results === undefined && res?.data?.detail === undefined
      ) {
        return setErrorAlert(true), setError(`ERROR ${res?.data?.code}-${res?.data?.msg}${" "}${"Error is from sales person notification"}`);
      }else  if (res?.data?.detail !== undefined && res?.data?.results === undefined ) {
        return setErrorAlert(true), setError(`ERROR ${"401"}-${res?.data?.msg}${" "}${"Error is from sales person notification"}`);
      }else { 
        return setChecked(res?.data?.results?.data);
      }
    } catch (err) {
      if(err?.response?.status === 401) {
        localStorage.clear(); 
        return navigate("/login")
      }
    }
  };
  const getCommentNotificationApi = async () => {
    try {
      const url = `https://flyingcolour.online/fcapp/typist_app/get_notification_comment_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });

        return setCommentNotifications(res?.data?.results?.Comment_List);
      
    } catch (err) {
      }
   }
  // setTimeout(() => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userType");
  //   localStorage.clear();
  //   navigate("/login");
  //   setExpirToken(true);
  //   // alert('error')
  // }, 360000);

  const clearStorage = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.clear();
    navigate("/login");
    // alert('error123')
  };

  const handleApiLogut = async() => {
    try {
      const url = `https://flyingcolour.online/fcapp/api/logoutuser/`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const formData = new FormData();
      formData.append("token_value", __token);
      const res = await axios.post(url,
        formData,
        { headers: headers });
      if(res?.data === "User Logged out successfully"){
        clearStorage();
        localStorage.clear();
        sessionStorage.clear();
          window.location.reload();
      }
    }catch(err) {

    }
  }

  const { classes } = props;
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      id={menuId}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const iconArr = [<HomeIcon />, <PersonIcon />, <HomeIcon />];

  return (
    <>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ backgroundColor: "#eb6e12 " }}>
            <Toolbar>
              <>{renderMobileMenu}</>
              {isMatch ? (
                <>{renderMobileMenu}</>
              ) : (
                <>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    sx={{ mr: 2 }}
                  ></IconButton>
                  <img className="logo-img" src={Logo} alt="logo" />
                  <div  className="hide-on-mobile"  style={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "9%",
                      width: "65%",
                      // height: "50px",
                      // background: "#d8d8d8",
                      borderRadius: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      // overflow: "auto"
                      // padding: "5px"
                    }}>
                  <CommentNotifications data={commentNotifications} checked={commentNotifications} fetchNotification={getCommentNotificationApi}/>
                   </div>
                  <Box sx={{ flexGrow: 1 }} />
                  <div className={classes.toolbarButtons}>
                    <IconButton
                      style={{
                        color: "#fff",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginRight: ".2rem",
                      }}
                      color="inherit"
                      size="large"
                      edge="end"
                      onClick={() => {
                        handleApiLogut();
                       
                      }}
                    >
                      <LogoutIcon />
                      <div
                        style={{
                          letterSpacing: "0.6px",
                          textDecoration: "none",
                          color: "#fff",
                          fontWeight: 700,
                          fontSize: "12px",
                          opacity: 2,
                          fontFamily: "Times New Roman ",
                        }}
                      >
                        Logout
                      </div>
                    </IconButton>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <IconButton
                          color="inherit"
                          size="large"
                          edge="end"
                          style={{
                            height: "58px",
                            padding: "8px",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            marginRight: ".1rem",

                          }}
                        >
                          <Badge
                            badgeContent={checked?.length || 0}
                            color="error"
                            style={{ height: "15px", fontSize: "10px" }}
                          >
                            <NotificationsNoneIcon />
                          </Badge>

                          <div
                            style={{
                              letterSpacing: "0.6px",
                              textDecoration: "none",
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "12px",
                              opacity: 2,
                              fontFamily: "Times New Roman ",
                            }}
                          >
                            Notifications
                          </div>
                        </IconButton>
                      </Dropdown.Toggle>
                      <Notify checked={checked} fetchNotification={fetchNotification} isCompliance={true} />
                    </Dropdown>
                    <IconButton
                      color="inherit"
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleMenuOpen}
                    >
                      <Avatar src="" />
                    </IconButton>
                  </div>
                  {renderMenu}
                </>
              )}
              <Box sx={{ display: { xs: "none", md: "none" } }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Box sx={{ display: { xs: "none", md: "none" } }} width={"100%"}>
                {iconArr.map((item, i) => {
                  return (
                    <div key={i} style={{ justifyContent: "center" }}>
                      <IconButton
                        size="small"
                        aria-haspopup="true"
                        color="inherit"
                        variant="contained"
                      >
                        {item}
                      </IconButton>
                    </div>
                  );
                })}
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{ display: { xs: "none", md: "none" } }}
          justifyContent="center"
        >
          <img src={ImgLogo} className="mobile-logo-img" alt="logoImg" />
        </Box>
        <Box
          sx={{ display: { xs: "none", md: "none" } }}
          justifyContent="center"
        >
          <div className="mobile-button">
            <div
              style={{ fontSize: "35px", backgroundColor: "rgb(255, 127, 80)" }}
            >
              Dashboard
            </div>
          </div>
        </Box>

        {expensionArr.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{ display: { xs: "none", md: "none" } }}
              justifyContent="center"
            >
              <div className="expend-panal">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ChevronRightIcon />}
                    className="expentionPanalColor"
                    style={{ backGroundColor: "blue" }}
                  >
                    {item.icon}{" "}
                    <span className="expenion-tittle">{item.tittle}</span>
                  </AccordionSummary>
                  <AccordionDetails>{item.dis}</AccordionDetails>
                </Accordion>
              </div>
            </Box>
          );
        })}
      </div>

      {props.content()}
      <Error/>
    </>
  );
};
export default withStyles(styles)(AdminTopbar);
