import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
import PreviewIcon from '@mui/icons-material/Preview';
import { Modal } from "@mui/material";
import ViewOrderForm from "../../../listing/listingModals/order-form-listing/viewOrderForm";
import EventNoteIcon from '@mui/icons-material/EventNote';
import DownloadIcon from "@mui/icons-material/Download";
import fileDownload from 'js-file-download';
import jsPDF from "jspdf";
import { CircularProgress } from "@mui/material";
import axios from 'axios';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useFormik, Formik } from "formik";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid, IconButton, Box, Button } from "@material-ui/core";
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader';
import ArticleIcon from "@mui/icons-material/Article";







const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewCompanyDetails = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop, fitlerBadge
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    setSalesInfo,
    setIsOpen,
    isOpen,
    message,
    setIsCompleteOrderBtnDisable,
    FollowUpReportApi, followUpReport,
    getViewCompanyDataApi, viewCompanyData,
    loading
  } = useContext(SupportDataContext);
  const {
    setStart_Date,
  } = useContext(DataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const todayDate = moment().format("YYYY-MM-DD");
  const [reqFromDate, setReqFromDate] = useState("");
  const [reqToDate, setReqToDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);

  
  const CompanyOptions = viewCompanyData?.Company_List_Master?.map((item) => {
    const obj = { value: item.Company_Id, label: item.Company_Name };
    return obj;
});

  useEffect(() => {
    handleListingSavedSearch();
  }, []);

  useEffect(() => {
    getViewCompanyDataApi(companyId)
  }, [companyId]);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;
      if (dateAsString == null) {
        return 0;
      }
      const dateParts = dateAsString.slice(0, 10).split("-");
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const actionBtnRenderer = (params) => {
    let data = params?.data;
    return (
      <>
        <div>
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Document", params.data);
              setButtonText("View Company Document");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"View Company Document"}
            width={"auto"}
            icon={
              <>
                < SnippetFolderIcon />
              </>}
            className="btnActionAlignment"
          />
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Company Order", params.data);
              setButtonText("View Company Order");
              // scrollToTop()
              ScrollTop();
              setOperationInfo(params.data);
              setIsCompleteOrderBtnDisable(false);
            }}
            title={"View Company Orders"}
            width={"auto"}
            icon={
              <>
                < ArticleIcon />
              </>}
            className="btnActionAlignment"
         
          />
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Sr#",
      field: "Serial_Number",
      filter: false,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      width: 80,
      pinned: "left",
    },
    {
      headerName: "Actions",
      field: "Inquiry_Id",
      filter: false,
      // checkboxSelection: true,
      cellRenderer: actionBtnRenderer,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 140,
      minWidth: 90,
      pinned: "left",
    },
    {
      headerName: "Company Id",
      field: "Company_Id",
      //filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      width: 130,
      pinned: "left",
    },

    {
      headerName: "Company Name",
      field: "Company_Name",
      filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: 'auto' },
      minWidth: 230,
      pinned: "left",
    },
    {
      headerName: "Parent Company",
      field: "Parent_Company",
      //filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 200,
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 230,
      cellRenderer: (params) => {
        return (
          <>
            {params.data?.Created_Date === "" ? "No Data" :
            moment(params.data?.Created_Date).format("DD-MM-YYYY hh:mm:ss a")}
          </>
        );
      },
    },
    {
      headerName: "Joining Date",
      field: "Joining_Date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 230,
      cellRenderer: (params) => {
        return (
          <>
            {params.data?.Joining_Date === "" ? "No Data" :
            moment(params.data?.Joining_Date).format("DD-MM-YYYY hh:mm:ss a")}
          </>
        );
      },
    },
    {
      headerName: "License Issue Date",
      field: "License_Issue_Date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 230,
      cellRenderer: (params) => {
        return (
          <>
            {params.data?.License_Issue_Date === "" ? "No Data" :
            moment(params.data?.License_Issue_Date).format("DD-MM-YYYY hh:mm:ss a")}
          </>
        );
      },
    },
    {
      headerName: "License Expiry Date",
      field: "License_Expiry_Date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 230,
      cellRenderer: (params) => {
        return (
          <>
            {params.data?.License_Expiry_Date === "" ? "No Data" :
            moment(params.data?.License_Expiry_Date).format("DD-MM-YYYY hh:mm:ss a")}
          </>
        );
      },
    },
    {
      headerName: "Overlap Company",
      field: "Overlap_Company",
     // filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 150,
    },
    {
      headerName: "State",
      field: "State_Name",
     // filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 150,
    },
    {
      headerName: "Formation Type",
      field: "Formation_Type",
     // filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 170,
    },
    {
      headerName: "Legal Type",
      field: "Legal_Type",
     // filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 170,
    },
    {
      headerName: "CM Name",
      field: "Client_Manager_Name",
      filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 170,
    },
    {
      headerName: "CM Tax Name",
      field: "CM_Tax_Name",
      filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 170,
    },
    {
      headerName: "Sales Person Name",
      field: "Sales_Person_Name",
      filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      minWidth: 170,
    },

  ];
  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      // flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };



  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
  ];

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey)
  }
  const getTotalLengthForSavedFilters = (apiData, value, key) => {
    if (apiData === null || (key === null && value === null)) {
      return apiData?.length;
    } else if (key === null) {
      return apiData?.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return apiData?.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      const filteredData = apiData.filter(function (item) {
        return value.includes(item[key]);
      });
      return filteredData?.length
    }
  };
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#eb6e12';
  box-shadow: inset '0px 0px 0px 7px #eb6e12';
  margin-top: 10%;
  `;

  return (
    <div className="listing-data-grid" style={{ height: "500px", }}>
      <div className="listing-filters" style={{ marginTop: "1rem" }}>

        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={apiData?.length}
        />
        <Formik
          enableReinitialize
          initialValues={{
            company: "",
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (data) => {
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-35px",
              }}
            >

              <Grid item sm={12} md={6} style={{ paddingRight: "1rem" }}>
                <CustomDropdown
                  label="Select Company Name to Filter"
                  fieldOptions={CompanyOptions}
                  fieldValue={companyId}
                  fieldHandleChange={(value) => {
                    setCompanyId(value.value);
                    setCompanyName(value.label);
                  }}
                />
              </Grid>
              {/* <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
          <Button
           type="submit"
           variant="contained"
           size="small"
           style={{
             backgroundColor: "#eb6e12",
             color: "#fff",
           }}
           onClick={() => {
             //fetchVisaProcessListFromApi()
           }}
      >
           Submit
         </Button>
          </Grid> */}
            </form>
          )}
        </Formik>
      </div>
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
          {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={viewCompanyData?.Company_List}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
          overlayLoadingTemplate={"Please Wait Loading Data..."}
          overlayNoRowsTemplate={"No Data...."}
        ></AgGridReact>
          )}
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>


    </div>
  );
};

export default ViewCompanyDetails;
