import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import richTextBoxStyles from './listingFormStyles/richTextBoxStyles/richTextBoxStyles'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import './listingFormStyles/richTextBoxStyles/richTextBoxStyles.css'
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const CKRictTextEditer = ({ handleDataChange, data, disabledField, dir, height, classesRichTextBox }) => {
    return (
        <div className="richTextEditorClass">
            <CKEditor
                onChange={handleDataChange}
                type='text'
                editor={ClassicEditor}
                data={`${data}`}
                disabled={disabledField}
                dir={dir}
                onReady={(editor) => {
                  
                    editor && editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "height",
                            height,
                            editor.editing.view.document.getRoot()
                        );
                    });
                }}
            />

        </div>
    )

}
export const CKRictTextEditerMediamHeight = ({ handleDataChange, data, disabledField, dir, height, classesRichTextBox }) => {

    return (
        <div className="richTextEditorClass">
            <CKEditor
                onChange={handleDataChange}
                type='text'
                editor={ClassicEditor}
                data={`${data}`}
                disabled={disabledField}
                dir={dir}
                onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                        writer.setStyle(
                            "min-height",
                            "100px",
                            editor.editing.view.document.getRoot()
                        );
                        writer.setStyle(
                            "max-height",
                            "120px",
                            editor.editing.view.document.getRoot()
                        );
                        writer.setStyle(
                            "overflow-y",
                            "scroll",
                            editor.editing.view.document.getRoot()
                        );
                    });
                }}
            />

        </div>
    )

}


const myTheme = createTheme({
    // Set up your custom MUI theme here
});


const RichTextEditer = (props) => {


    const { classes/* value, handleChangeData, handleBlur, valueText*/ } = props
    return (
        <ThemeProvider theme={myTheme}>
            <div className={classes.RichTextEditerContainer} style={{ height: props.height }}>
                <MUIRichTextEditor
                    label=""
                    type='text'
                    name='leadDescription'
                    // value={valueText}
                    // onChange={() => handleChangeData()}
                    // onBlur={handleBlur}
                    // onSave={save}
                    // inlineToolbar={true}

                    controls={["bold-text", 'italic-text', 'underLine-text', "Listcircale-text", "ListNumber-text"]}
                    customControls={[
                        {
                            name: "bold-text",
                            icon: <FormatBoldIcon className={classes.richTextBoxTopBarIcon} />,
                            type: "inline",
                            inlineStyle: {

                                fontWeight: 'bold'

                            }
                        },
                        {
                            name: "italic-text",
                            icon: <FormatItalicIcon className={classes.richTextBoxTopBarIcon} />,
                            type: "inline",
                            inlineStyle: {

                                fontStyle: 'italic',

                            }
                        },
                        {
                            name: "underLine-text",
                            icon: <FormatUnderlinedIcon className={classes.richTextBoxTopBarIcon} />,
                            type: "inline",
                            inlineStyle: {
                                textDecoration: 'underline'

                            }
                        },
                        {
                            name: "Listcircale-text",
                            icon: <FormatListBulletedIcon className={classes.richTextBoxTopBarIcon} />,
                            type: "inline",
                            inlineStyle: {
                                listStyleType: 'circle'

                            }
                        },
                        {
                            name: "ListNumber-text",
                            icon: <FormatListNumberedIcon className={classes.richTextBoxTopBarIcon} />,
                            type: "inline",
                            inlineStyle: {
                                listStyleType: 'upper-roman'

                            }
                        },
                    ]}


                >
                    {/* {valueText} */}
                </MUIRichTextEditor>
            </div>
        </ThemeProvider>
    )
}
export default withStyles(richTextBoxStyles)(RichTextEditer)