import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../Styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModalPopper";
import {
  getTotalLength,
  handleDefaultFilters,
  handleFilters,
} from "../../../../utils/Filters";
import { getLocalData } from "../../../../services/services";
import axios from "axios";
import DatePicker from "react-datepicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader'



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TypistTaskListingTable = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    setStart_Date, setEnd_Date
  } = useContext(DataContext);
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    typistTaskList,
    fetchTypistTaskListFromApi,
    loading
  } =
    useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [typistFilterListDropdown, setFilterListDropdown] = useState(null);
  const todayDate = moment().format("YYYY-MM-DD");

  const [approval, setApproval] = useState("");
  const [typistId, setTypistId] = useState(0);
  const [typistName, setTypistName] = useState();
  const [companyName, setCompanyName] = useState(0);
  const [reqFromDate, setReqFromDate] = useState(todayDate);
  const [reqToDate, setReqToDate] = useState(todayDate);

  const approvalListDropDown = typistTaskList?.Status_Details?.map((i) => {
    return {
      id: i.Value,
      value: i.Value,
      label: `${i.Name}`,
    };
  });
  const typistNameListDropDown = typistTaskList?.Typist_Name_List?.map((i) => {
    return {
      id: i.Typist_Ids,
      value: i.Typist_Names,
      label: `${i.Typist_Names}`,
    };
  });;
  const companyNameListDropDown = typistTaskList?.Company_List?.map((i) => {
    return {
      id: i.Company_Id,
      value: i.Company_Name,
      label: `${i.Company_Name}`,
    };
  });

  useEffect(() => {
    handleListingSavedSearch();
    // setToDate(moment().format().slice(0, 10));
    // setFromDate(moment().format().slice(0, 10))
  }, []);

  useEffect(() => {
    fetchTypistTaskListFromApi(typistId, reqFromDate, reqToDate);
  }, [typistId, reqFromDate, reqToDate])

  // useEffect(() => {
  //   fetchProTaskFilterFromApi();
  // }, [approval, typistId, companyName, reqFromDate, reqToDate])

  // const fetchProTaskFilterFromApi = async () => {
  //   const __token = localStorage.getItem("token");
  //   try {
  //     const url = `https://flyingcolour.online/fcapp/docprocessing_api/getTypistTaskDetails/?task_Status=${approval}&Typist_Ids=${typistId}&Company_Id=${companyName}&requisition_Date=${reqFromDate}`
  //     const headers = {
  //       Authorization: `Token ${__token}`,
  //       "Content-Type": "application/json",
  //     };
  //     const res = await axios.get(url, { headers });

  //     setFilterListDropdown(res?.data?.results);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const finalValSchedule = apiData?.Typist_Task_General_Schedule?.map((i) => {
    return {
      headerName: i.Typist_Names,
      field: i.Typist_Names,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 300,
      cellRenderer: (params) => {
        return (
          <>
            {i?.Task_Detail?.map((val) => {
              return (
                <div>
                  {/* <p style={{ margin: 0, whiteSpace: 'unset' }}>{val?.Company_Name} -  {val?.Main_Task_Name} - {val?.Sub_Task_Name}</p> */}
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Company Name:</span> {val?.Company_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Main Task Name:</span> {val?.Main_Task_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Sub Task Name: </span> {val?.Sub_Task_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Task Name:</span> {val?.Task_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Task Description:</span> {val?.Task_Descr}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Task Days: </span> {val?.Task_Days}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Task Time Frame:</span>({val?.Task_Start_Date} - {val?.Task_End_Date})</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span>Authority:</span>{val?.Authority}</p>
                </div>
              )
            })}
          </>
        )
      }
    };
  });
  const columnDefsSchedule = finalValSchedule || [];

  const finalVal = typistTaskList?.Typist_Task_List?.map((i) => {
    return {
      headerName: i.Typist_Names,
      field: i.Typist_Names,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff" },
      width: 300,
      cellRenderer: (params) => {
        return (
          <>
            {i?.Task_Detail?.map((val, index) => {
              return (
                <div>
                  <p><span style={{ fontWeight: "bold" }}>Sr. No: {index + 1} </span></p>
                  {/* <p style={{ margin: 0, whiteSpace: 'unset' }}>{val?.Company_Name} -  {val?.Main_Task_Name} - {val?.Sub_Task_Name}</p> */}
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Company Name:</span> {val?.Company_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Main Task Name:</span> {val?.Main_Task_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Sub Task Name: </span> {val?.Sub_Task_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Order Name:</span> {val?.Order_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Requisition Id:</span> {val?.Requisition_Ids}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Typiest Name: </span> {val?.Typist_Name}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Typist Status:</span>{val?.Typist_Status}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>PRO Head Status:</span>{val?.PROHead_Status}</p>
                  <p style={{ margin: 0, whiteSpace: 'unset' }}><span style={{ fontWeight: "bold" }}>Account Status:</span>{val?.Account_Status}</p>
                  <br />
                </div>
              )
            })}
          </>
        )
      }
    };
  });
  const columnDefs = finalVal || [];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      //filter: true,
      // enable floating filters by default
      //floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      flex: 1,
      autoHeight: true
    };
  }, []);


  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#eb6e12';
  box-shadow: inset '0px 0px 0px 7px #eb6e12';
  margin-top: 10%;
  `;
  return (
    <div className="listing-data-grid" style={{ height: "400px" }}>
      <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="4"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Requisition Date
              </Form.Label>
              <Col sm="8" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <DateRangePickerComp
                    custom={true}
                    fieldHandleChange={(value, picker) => {
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      setStart_Date(moment(picker.startDate).format("YYYY-MM-DD"))
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>
          {/* <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <CustomDropdown
                label="Approval"
                fieldOptions={approvalListDropDown}
                fieldValue={approval}
                fieldHandleChange={(value) => {
                  setApproval(value.value);
                }}
              />
            </Grid> */}
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
            <CustomDropdown
              label="Typist Name"
              fieldOptions={typistNameListDropDown}
              fieldValue={typistName}
              fieldHandleChange={(value) => {
                setTypistId(value.id);
                setTypistName(value.value);
              }}
            />
          </Grid>
          {/* <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
              <CustomDropdown
                label="Company Name"
                fieldOptions={companyNameListDropDown}
                fieldValue={companyName}
                fieldHandleChange={(value) => {
                  setCompanyName(value.id);
                }}
              />
            </Grid> */}
        </Grid>
      </div>

      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={typistTaskList?.Pro_Task_List?.length}
      />
      <div
        style={{ height: "800px", width: "100%" }}
        className="ag-theme-alpine"
      >
         {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={[{}]}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 700}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
          overlayLoadingTemplate={"Please Wait Loading Data..."}
          overlayNoRowsTemplate={"No Data...."}
        ></AgGridReact>
          )}
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TypistTaskListingTable;
