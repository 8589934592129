import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import {
    FaFileUpload,
    FaFileMedical,
    FaEye,
    FaSync,
    FaTimes,
    FaHandshake,
    FaIdBadge,
    FaPlus,
    FaReply,
  } from "react-icons/fa";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import { DataContext, SupportDataContext } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import ModalPopper from "../Modals/ModelPopper";
  import { Button } from "react-bootstrap";
  import Table from "react-bootstrap/Table";
  import ApprovalIcon from "@mui/icons-material/Approval";
  import GradingIcon from "@mui/icons-material/Grading";
  import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
  import { Grid } from "@material-ui/core";
  import EditIcon from "@mui/icons-material/Edit";
  import { getTotalLength } from "../../../../utils/Filters";
  import PreviewIcon from '@mui/icons-material/Preview';
  import EditNoteIcon from '@mui/icons-material/EditNote';
  


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const ProjectListTable = ({
    apiData,
    upperIcons,
    lowerIcons,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setEditModalPopper,
      handleModalOpen,
      setAcceptAccountArray,
      getPropertyFormDataApi,
      message,
      severity,
      isOpen,
      setIsOpen,
      // getProjectListDataApi,
      // projectList
      getProjectListDataApi,
      projectList,
      filterOrders,
      setFilterOrders,
    } = useContext(SupportDataContext);
  
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    // const [isOpen, setIsOpen] = useState(false);
    // const [message, setMessage] = useState();
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    // const [filterOrders, setFilterOrders] = useState();
  
    useEffect(() => {
      getProjectListDataApi();
      handleListingSavedSearch();
    }, []);
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    const actionBtnRenderer = (params) => {
      let data = params?.data;
      return (
        <>
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Update Order Sequence", params.data);
              setButtonText("Update Order Sequence");
              ScrollTop();
              setEditModalPopper(false);
              setOperationInfo(params.data);
            }}
            title={"Update Order Sequence"}
            icon={<EditNoteIcon />}
            className="btnActionAlignment"
          />    
           <CustomIconButton
            onClick={() => {
              handleModalTableOpen("View Order On Project", params.data);
              setButtonText("View Order On Project");
              ScrollTop();
              // setEditModalPopper(true);
              setOperationInfo(params.data);
            }}
            title={"View Order On Project"}
            icon={<FaEye />}
            className="btnActionAlignment"
          />
          <CustomIconButton
            onClick={() => {
              handleModalTableOpen("Edit Project", params.data);
              setButtonText("Edit Project");
              setEditModalPopper(true);
              ScrollTop();
              setOperationInfo(params.data);
            }}
            title={"Edit Project"}
            icon={<EditIcon />} 
            disabled={params?.data?.First_Order_Approved_By_Accounts === "Yes" ? true : false}
            className="btnActionAlignment"
          />
           <CustomIconButton
            onClick={() => {
              handleModalOpen("View Project Description", params.data);
              setButtonText("View Project Description");
              setEditModalPopper(true);
              setOperationInfo(params.data);
            }}
            title={"Project Description"}
            icon={<PreviewIcon />}
            className="btnActionAlignment"
          />
        </>
      );
    };
  
    const columnDefs = [
      {
        headerName: "Sr.",
        field: "Serial_Number",
        //cellRenderer: "agGroupCellRenderer",
        //checkboxSelection: true,
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          textAlign: "center",
        },
        width: 75,
        pinned: "left",
        filter: false,
      },
      {
        headerName: "Actions",
        field: "",
        filter: false,
        // checkboxSelection: true,
        cellRenderer: actionBtnRenderer,
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        minWidth: 190,
        pinned: "left",
      },
      {
        headerName: "No. of Orders",
        field: "no_of_order",
         filter: 'agTextColumnFilter',
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        width: 150,
        pinned: "left",
      },
      {
        headerName: "Company Name",
        field: "company_name",
         filter: 'agTextColumnFilter',
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: "Sub Order Template",
        field: "scope_work_template_name_for_sub_template",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY:'auto'
        },
        minWidth: 210,
        flex: 1,
      },
      {
        headerName: "Project Name",
        field: "project_name",
         filter: 'agTextColumnFilter',
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 200,
        flex: 1,
      },
      {
        headerName: "Project Status",
        field: "project_status",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 140,
        flex: 1,
      },
      {
        headerName: "Support Name",
        field: "support_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Active / InActive",
        field: "active_inactive",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Created By",
        field: "created_by_name",
         filter: 'agTextColumnFilter',
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Created Date",
        field: "created_date",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Update By",
        field: "updated_by_name",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Updated Date",
        field: "updated_date",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
        },
        minWidth: 170,
        flex: 1,
      },
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue.split(" ");
              if (dateAsString == null) {
                return 0;
              }
              const dateParts = dateAsString[0].split("-");
              const day = Number(dateParts[2]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[0]);
              const cellDate = new Date(year, month, day);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            },
          },
        },
      };
    }, []);
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const filters = [
      {
        id: 1,
        title: "Default",
        value: null,
        key: null,
      },
      // {
      //   id: 2,
      //   title: "Project Created",
      //   value: "Created",
      //   key: "project_status",
      // },
      // {
      //   id: 3,
      //   title: "Underprocess Stage 1",
      //   value: "Underprocess Stage 1",
      //   key: "project_status",
      // },
      // {
      //   id: 4,
      //   title: "Underprocess Stage 2",
      //   value: "Underprocess Stage 2",
      //   key: "project_status",
      // },

    ];
  
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const handleFilters = (title, value, key) => {
     
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.onFilterChanged();
      if (key === null) {
        gridRef.current.api.setFilterModel(null);
      } else if (key === "Enquiry_Assigned_Date") {
        gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
          type: "contains",
          dateFrom: value,
          dateTo: null,
        });
        gridRef.current.api.onFilterChanged();
      } else if (key === "}") {
      } else {
        gridRef.current.api
          .getFilterInstance(key.toString())
          .setModel({ values: [value.toString()] });
        gridRef.current.api.onFilterChanged();
      }
  
      setDefaultFilterValue(title);
    };
  
    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    const handleSearch = (searchName) => {
      handleFilters(searchName, searchName, "Inquiry_Id");
    };
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
  
        {fitlerBadge ? (
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                marginTop: "30px",
                paddingBottom: "15px",
              }}
            >
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={projectList}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                {projectList?.length > 0 &&
                  filters.map((val, i) => {
                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(projectList, val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: "Times New Roman",
                        }}
                        onClick={() =>
                          handleFilters(val.title, val.value, val.key)
                        }
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLength(projectList,val.Search_Name, valueKey)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
  
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(
                            val.Search_Name,
                            val.Search_Name,
                            valueKey
                          );
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>
          </>
        ) : null}
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={projectList?.length}
        />
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
  suppressExcelExport={true}
  suppressCsvExport={true}
            ref={gridRef}
            rowData={projectList}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            // onSelectionChanged={onRowSelected}
            components={components}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            rowHeight={90}
            pagination={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <ModalPopper />
        <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default ProjectListTable;
  