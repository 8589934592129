const listingtableStyles = (theme) => ({
    listingTableContainer: {
      backgroundColor: "#d3d3d3",
    },
    span: {
      fontFamily: "Times New Roman",
    },
    indicator: {
      backgroundColor: "#eb6e12 !important",
      top: "0px",
    },
    tabStyleListing: {
      backgroundColor: "#fff !important",
      border: "1px solid #e3e3e3 !important",
      fontSize: "11px !important",
      fontWeight: "bold !important",
      fontFamily: "Times New Roman !important",
    },
    tabStyleListingOthers: {
      backgroundColor: "#fff !important",
      borderRight: "1px solid #e3e3e3 !important",
      borderTop: "1px solid #e3e3e3 !important",
      borderBottom: "1px solid #e3e3e3 !important",
      fontSize: "11px !important",
      fontWeight: "bold !important",
      fontFamily: "Times New Roman !important",
    },
    selected: {
      backgroundColor: "#eb6e12  !important",
      color: "#fff  !important",
      borderBottom: "2px solid #fff !important",
    },
    TabLowerContainerDiv: {
      border: "1px solid #e3e3e3 !important",
      zIndex: "1",
      marginTop: "-2px",
      backgroundColor: "#fff",
    },
    tableButton: {
      fontSize: "14px",
      border: "none",
      backgroundColor: " #fff",
      padding: "0px 15px",
    },
    tableButtonContainer: {
      padding: "10px",
    },
    buttonTitle: {
      fontFamily: "Times New Roman",
    },
    sideButtonTable: {},
    sidebutton: {
      backgroundColor: "#eb6e12 !important ",
      fontFamily: "Times New Roman ",
      width: "91px",
      fontSize: "16px",
      border: "none",
      borderRadius: "3px",
      height: "25px",
      color: "#fff",
      // height: '30px'
    },
    imgIconAgreement: {
      width: "20px !important",
      height: "20px !important",
      cursor: "pointer",
      fill: "#eb6e12",
      // marginLeft: '3px',
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        marginRight: "15px",
      },
    },
    imgIcon: {
      width: "15px !important",
      height: "17px !important",
      cursor: "pointer",
      fill: "#eb6e12",
      marginLeft: "3px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        marginRight: "15px",
      },
    },
    iconImg: {
      width: "17px ",
      height: "20px ",
      cursor: "pointer",
      fill: "#fff",
      marginLeft: "3px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        marginRight: "15px",
      },
    },
    imgIconReview: {
      width: "16px !important",
      height: "16px !important",
      cursor: "pointer",
      fill: "#eb6e12",
      marginLeft: "3px",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        marginRight: "15px",
      },
    },
    imgIconEnd: {
      width: "17px !important",
      height: "17px !important",
      cursor: "pointer",
      fill: "#eb6e12",
      marginLeft: "3px",
    },
    quotatonTableButtonCreated: {
      backgroundColor: "rgb(87, 87, 86) !important",
      fontFamily: "Times New Roman !important",
      color: "#fff",
      fontSize: "10px !important",
      padding: "4px !important",
    },
    quotatonTableButton: {
      backgroundColor: "#eb6e12 !important",
      fontFamily: "Times New Roman !important",
      color: "#fff",
      fontSize: "10px !important",
      padding: "4px !important",
    },
    uploadFileBtn: {
      padding: "6px 20px",
      backgroundColor: "#eb6e12",
      border: "1px solid #d3d3d3",
      color: "#fff",
      borderRadius: "5px",
      cursor: "pointer",
      marginRight: "5px",
    },
    uploadFile: {
      border: "0.1px solid #d3d3d3",
      display: "flex",
      float: "right",
      padding: "1.5px 0px",
      alignItems: "center",
      width: "100%",
    },
  
  });
  export default listingtableStyles;
  