
const modalPopperStyles = (theme) => ({
    formGroupFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
    },
    formLeadFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
    },
    formGenderFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
      "@media screen and (max-width: 768px)": {
        flexDirection: "column",
      },
    },
    formClientFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
      "@media screen and (min-width: 600px) and (max-width: 768px)": {
        flexDirection: "column",
      },
    },
    scGroupFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    scGroupFlexDivModalFile: {
      display: "flex",
      marginBottom: "3px",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      padding: "0px 10px",
      "@media screen and (max-width: 768px)": {
        padding: "0px 0px",
         },
    },
    flexRow: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      "@media screen and (max-width: 768px)": {
     flexDirection:"column"
      },
    },
    formGroupFlexDivModalRichText: {
      marginBottom: "-10px",
      "@media screen and (max-width: 768px)": {
        width: "98%",
        marginBottom: "5px",
      },
    },
    gridItemContainer: {
      paddingTop: "20px",
      marginBottom: "-30px",
    },
    labelFormInputMulti: {
      width: "54%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
      "@media screen and (min-width: 600px) and (max-width: 768px)": {
        width: "100%",
      },
    },
    labelFormInputModal: {
      width: "50%",
      fontWeight: " 600 !important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
      "@media screen and (min-width: 600px) and (max-width: 768px)": {
        width: "100%",
      },
    },
    labelCheckInputModal: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
      "@media screen and (min-width: 600px) and (max-width: 768px)": {
        width: "100%",
      },
    },
    labelInputModal: {
      width: "100%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    containerModal: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      width: "90%",
    },
    selctModal: {
      height: "28px",
      width: "100%",
    },
    selectContainerModal: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      width: "50%",
      "@media screen and (min-width: 600px) and (max-width: 768px)": {
        width: "100%",
      },
    },
    labelFormRadioModal: {
      width: "89%",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "12px",
      position: "relative",
      top: "-1px",
    },
    selectContainerRadio: {
      fontSize: "12px",
      float: "right",
      marginRight: "2px",
      width: "100%",
    },
    demoDataCheckModal: {
      border: "0.1px solid #d3d3d3",
      display: "grid",
      float: "right",
      width: "100%",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        width: "100%",
      },
      "@media screen and (max-width: 768px)": {
        width: "100%",
      },
    },
    listingFormInputMainModal: {
      width: "100%",
      // minWidth: '131px',
      // maxWidth: '131px',
      border: "none",
      float: "inherit",
      padding: "4.5px 10px",
      fontFamily: " Times New Roman !important",
      fontSize:"13px !important"
      // '@media screen and (min-width: 1650px) and (max-width: 3000px)': {
      //     minWidth: '250px',
      //     maxWidth: '250px'
      // }
    },
    listingFormInputMainModalDate: {
      minWidth: "131px",
      maxWidth: "131px",
      border: "none",
      float: "inherit",
      padding: "2.5px 4px",
      fontFamily: " Times New Roman ",
      "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        minWidth: "250px",
        maxWidth: "250px",
      },
    },
    buttonDivModal: {
      textAlign: "center",
      marginTop: "30px",
      marginBottom: "-10px",
      marginRight: "-16px",
    },
    modalHeader: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "bold",
      marginLeft: "5px",
      textTransform: "capitalize",
    },
    modalHeaderIcon: {
      color: "#eb6e12",
      fontSize: "16px !important",
    },
    modalHeaderIconCacleIcon: {
      color: "#eb6e12",
      fontSize: "20px !important",
    },
    modalHeaderIconClose: {
      color: "#eb6e12",
      marginBottom: "4.5px",
    },
    modalHeadingContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px ",
      borderTop: "1px solid #eb6e12",
      borderBottom: "1px solid #eb6e12",
      backgroundColor: "#eb6e12",
      padding:"2px"
    },
    cancleIconDiv: {
      marginLeft: "auto",
    },
    modalFormTextArea: {
      minWidth: "131px",
      maxWidth: "131px",
      float: "inherit",
      border: "none",
      fontFamily: " Times New Roman ",
    },
    RichTextEditerDiv: {
      marginTop: "5px",
    },
  
    selectContainerGender: {
      fontSize: "12px",
      float: "right",
      display: "flex",
      marginTop: " 3px",
    },
    radioContainer: {
      display: "flex",
    },
    gendarLabel: {
      marginTop: "2px",
    },
    FCserviceHeading: {
      float: "right",
      marginRight: "10%",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#eb6e12",
      marginTop: "3px",
    },
    cancleButtonModel: {
      marginTop: " -15px",
      marginRight: "-25px",
      position: "relative",
      left: "43%",
      "@media screen and (min-width: 1600px) and (max-width: 3000px)": {
        left: "45%",
      },
    },
    modalFlexDivContaner: {
      display: "-webkit-box",
      flexFlow: "row-reverse wrap",
    },
    BorderSpacingUpperModale: {
      width: "100%",
      borderTop: "1px solid #d3d3d3 ",
      marginBottom: "10px",
    },
    BorderSpacingLowerListing: {
      width: "100%",
      borderBottom: "5px solid #eb6e12 ",
    },
    gapSpan: {
      fontSize: "20px",
      marginLeft: "-3px",
      marginTop: "-3px",
      marginRight: "3px",
    },
    innerGridRight: {
      padding: "0px 10px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
      "@media screen and (max-width: 600px)": {
        borderRight: "none",
      },
    },
    innerGridRightClient: {
      padding: "0px 10px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
      maxHeight: "260px",
      "@media screen and (min-width: 600px) and (max-width: 820px)": {
        maxHeight: "370px",
      },
      "@media screen and (max-width: 600px)": {
        borderRight: "none",
      },
    },
    innerGridPartner: {
      padding: "0px 10px",
      display:"flex",
      paddingTop: "3px",
      justifyContent:"space-between",
      width:"100% !important",
      "@media screen and (max-width: 780px)": {
        borderRight: "none",
        flexDirection:"column !important"
      }
    },
    uae:{
      paddingRight: "5px",
      marginBottom:"5px",
      "@media screen and (max-width: 780px)": {
        border: "none !important",
      }
    },
    formGroupPartner:{
      width:"100%",
      marginRight:"4px"
    },
    formGroupUAE:{
      width:"100%",
      marginRight:"4px",
      justifyContent: "flex-start",
      display: "flex",
      "@media screen and (max-width: 780px)": {
        flexDirection:"column"
      }
    },
    positionLicense:{
      border: "1px solid #d3d3d3",
      width: "100%",
      display: "flex",
      "@media screen and (max-width: 780px)": {
        flexDirection:"column",
        padding:"5px"
      }
    },
    innerGridRightDI: {
      padding: "0px 10px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
      "@media screen and (max-width: 900px)": {
        marginBottom: "5px !important",
        borderRight: "none",
      },
    },
    innerGridRightFirst: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingRight: "8px",
      paddingLeft: "9px",
      borderRight: "1px solid #d3d3d3",
      paddingTop: "3px",
    },
    innerGridLeft: {
      padding: "0px 10px",
      paddingTop: "3px",
    },
    innerGrid: {
      padding: "0px 10px",
    },
    innerGridContainer: {
      marginBottom: "10px",
      padding: "5px 0",
    },
    addRemoveBtnContainer: {
      marginLeft: "auto",
      marginRight: "-20px",
      marginTop: "-62px",
    },
    modalHeaderIconCancle: {
      float: "right",
      marginRight: "-25px !important",
    },
    SelctGroupModal: {
      width: "380px",
      marginLeft: "9px",
      "@media screen and (max-width: 900px)": {
        marginLeft: "1px",
        width: "90%",
      },
      "@media screen and (min-width: 1550px) and (max-width: 3000px)": {
        marginLeft: "9px",
        width: "320px",
      },
    },
    scQntyFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "70px",
      marginLeft: "3px",
      "@media screen and (max-width: 900px)": {
        width: "80%",
      },
      "@media screen and (min-width: 1550px) and (max-width: 3000px)": {
        width: "100px",
      },
    },
    scChargFlexDivModal: {
      display: "flex",
      marginBottom: "3px",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "90px",
      marginLeft: "3px",
      "@media screen and (max-width: 900px)": {
        width: "80%",
      },
      "@media screen and (min-width: 1550px) and (max-width: 3000px)": {
        width: "120px",
      },
    },
    scGroupWidthDivModal: {
      display: "flex",
      marginBottom: "3px",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "229px",
      "@media screen and (max-width: 900px)": {
        width: "90%",
      },
      "@media screen and (min-width: 1550px) and (max-width: 3000px)": {
        // maxWidth:"380px",
        width: "330px",
      },
    },
  
    performanceModal: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
    },
    inputModal: {
      // width: "50%",
      top: "-3px",
      left: "-11px",
      fontWeight: " 600!important",
      marginTop: "6px",
      marginBottom: 0,
      fontSize: "14px",
      position: "relative",
      "@media screen and (min-width: 600px) and (max-width: 768px)": {
        width: "100%",
      },
    },
    buttonModal: {
      textAlign: "center",
      marginLeft: "16px",
    },
    requireRow:{
      width: "99%", 
      display: "flex",
      "@media screen and (max-width: 768px)": {
      flexDirection:"column"
      },
    },
    requireSelect:{
      display: "flex",
      width: "33%",
      justifyContent: "space-between",
      "@media screen and (max-width: 768px)": {
        width:"100%"
      },
    },
    requireSelector:{
      display: "flex",
      width: "20%",
      justifyContent: "space-between",
      "@media screen and (max-width: 768px)": {
        width:"100%"
      },
    },
    scopeField:{
      display: "flex",
      justifyContent:
        "space-between",
      paddingLeft: "7px",
      "@media screen and (max-width: 768px)": {
        flexDirection:"column"
      },
    },
    descript:{
      paddingLeft: "7px",
      width: "33%",
      "@media screen and (max-width: 768px)": {
        width:"100%"
      },
    },
    uploadFile: {
      border: '0.1px solid #d3d3d3',
      display: 'flex',
      float: 'right',
      padding: '1.5px 0px',
      alignItems: 'center',
      width: '100%',
     
  },
  uploadFileText: {
    maxWidth: '170px',
    minWidth: '55px',
    overflow: 'hidden',
    cursor: 'pointer',
    maxHeight: '26px',
    fontSize:"12px !important",
    '@media screen and (min-width: 1600px) and (max-width: 3000px)': {
        maxWidth: '177px',
        minWidth: '177px',
    }
  },
   uploadFileBtn: {
      padding: '6px 20px',
      backgroundColor: '#eb6e12',
      border: '1px solid #d3d3d3',
      color: '#fff',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight:"5px"
  },
  headingForStatement:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"100%",
    height:"2rem",
    color: '#eb6e12',
    border: '1px solid #d3d3d3',
    borderRadius: '5px',
    fontFamily: " Times New Roman ",
    fontWeight: " 600 !important",
  },
  sowStyle:{
  display:"flex",
  width:"100%",
  // gridTemplateColumns: "repeat(6, 1fr)",
  // gap: "15px",
  // gridAutoRows:"minmax(auto, auto)",
  border:"1px solid #d3d3d3",
  padding:"10px",
  marginRight:"4px"
  },
  personDetail:{
    display: "flex",
    width: "99%",
    "@media screen and (min-width: 678px) and (max-width: 1000px)": {
     display:"grid",
     gridTemplateColumns: "auto auto auto",
     gap:"10px",
    },
    "@media screen and (max-width: 678px)": {
      display: "flex",
      flexDirection:"column"
    },
  }
  });
  export default modalPopperStyles;
  