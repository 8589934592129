//css will go here
import bgImg from "../../../commonComp/assets/imgBac.jpg";
const styles = (theme) => ({
  containerLogin: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 900px)": {
      display: "flex",
      overflow: "auto",
      // height: 'auto'
    },

    "@media screen  and (min-width: 1400px)": {},
    "@media (max-width: 350px)": {
      padding: "24px 29px",
      height: "auto",
    },
    "@media (max-width: 360px)": {
      padding: "24px 29px",
      // height: 'auto'
      display: "table-cell",
    },
    "@media (max-width: 380px)": {
      padding: "24px 29px",
      // height: 'auto'
      display: "grid",
    },
    "@media (max-width: 280px)": {
      padding: "24px 29px",
      height: "100vh",
    },
    // '@media (max-width: 400px)': {
    //     // padding: '24px 29px',
    //     height: '100vh'
    // },
  },
  // expenionTittle: {
  //     width: 0
  // },
  listItemText: {
    color: "white",
    fontFamily: "Times New Roman !important",
    fontSize: "14px",
    fontWeight: "600",
    textAlign: "left",
    paddingRight: "0px !important",
  },

  formGroup: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  rightPane: {
    backgroundImage: `url(${bgImg})`,
    // backgroundRepeat  : 'no-repeat',
    backgroundPosition: "center",
    // backgroundSize: 'cover',
    // opacity: 0.6
  },
  loginGrid: {
    backgroundColor: "#ffffff",
  },
  loginInputFieldStyles: {
    marginTop: "22.5px",
    fontSize: "18px",
    width: "95%",
    "@media screen and (min-width: 1350px) and (max-width: 3000px)": {
      width: "100%",
    },
  },
  loginContent: {
    padding: "60px 100px",
    background: "#ffffff",
    fontFamily: " Times New Roman",
    alignItems: "center",
    // marginTop: '20px',
    "@media (max-width: 350px)": {
      padding: "75px 26px ",
    },
    "@media screen and (max-width: 950px)": {
      marginTop: "-22px",
      // padding: '75px 65px'
    },
    // '@media screen and (max-width: 960px) and (min-width: 899px)': {
    //     // marginTop: '-22px',
    //     padding: '75px 200px'
    // },
    // // '@media screen and (max-width: 799px) and (min-width: 699px)': {
    // //     // marginTop: '-22px',
    // //     padding: '75px 150px'
    // // },
    // '@media screen and (max-width: 1400px) and (min-width: 1100px)': {
    //     // marginTop: '-22px',

    // },
    "@media (max-width: 390px)": {
      padding: "78.5px 29px ",
      maxHeight: "100vh",
    },
  },
  gridContsiner: {
    // maxWidth: '1057px'
  },
  loginText: {
    color: "#eb6e12",
    fontSize: "40px",
    fontFamily: " Times New Roman",
    lineHeight: "52px",
    fontWeight: "bold",
    "@media (max-width: 350px)": {
      fontSize: "35px",
      marginTop: "-47px",
    },
    "@media (max-width: 660px)": {
      fontSize: "35px",
    },
    "@media (min-width: 680px)": {
      fontSize: "43px",
    },
    "@media (min-width: 768px)": {
      fontSize: "40px",
    },
    "@media (min-width: 960px)": {
      fontSize: "30px",
    },
    // '@media (max-width: 1200px)': {
    //     fontSize: '33px',
    //     // marginTop: '-47px'
    // }
  },
  loginContainerHeader: {
    color: "#bcbcbc",
    fontWeight: "bold",
    fontSize: "18px",
    fontFamily: " Times New Roman",
    margin: "8px 0 20px",
    // '@media (max-width: 1200px)': {
    "@media (max-width: 400px)": {
      fontSize: "15px",
    },
  },
  emailText: {
    color: "#9B9B9B",
    fontSize: "16px",
    paddingTop: "20px",
    fontFamily: " Times New Roman",
  },
  passwordContainer: {
    margin: "15px 0",
  },
  formContainer: {
    padding: "0 12px",
    // marginTop: '42px',
    margin: "15px 0",
    "@media (max-width: 960px)": {
      marginTop: "-1px",
    },
    "@media (max-width: 340px)": {
      marginTop: "-19px",
    },
  },
  button: {
    // padding: '0 10px',
    borderRadius: "4px",
    fontSize: "22px",
    fontFamily: " Times New Roman",
    background: "#eb6e12",
    // marginTop: '47px',
    width: "90%",
    height: "50px",
    color: "#ffffff",
    cursor: "pointer",
    border: "none",
    fontWeight: "bold",
    marginLeft: "10px",
    "&:focus": {
      outline: 0,
    },
    "@media (max-width: 350px)": {
      marginTop: "2px",
    },
    "@media (max-width: 960px)": {
      marginBottom: "-8px",
    },
    "@media screen and (min-width: 960px) and (max-width:1190px)": {
      marginBottom: "-8px",
      width: "87%",
    },
    "@media (max-width: 630px)": {
      // marginBottom: '-8px',
      width: "87%",
    },
    "@media screen and (min-width: 1350px) and (max-width: 3000px)": {
      width: "94%",
    },
  },
  forgotPassText: {
    marginTop: "10px",
    fontSize: "14px",
    textAlign: "center",
    textTransform: "capitalize",
    width: "100%",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  invalid: {
    border: "1px solid #ff0000",
  },
  validText: {
    color: "red",
    fontSize: "11px",
  },
  logoName: {
    margin: 0,
    color: "#ffffff",
    float: "right",
    padding: "10px",
    fontFamily: " Times New Roman",
    cursor: "pointer",
    marginRight: "34%",
    width: "200px",
    height: "auto",
    marginTop: "38px",
    "@media (max-width: 1200px)": {
      marginRight: "30%",
    },
  },
  logoImgContainer: {
    width: "100%",
    height: "auto",
  },
  imageHeadline: {
    padding: "100px",
    color: "#ffffff",
    "@media (max-width: 1200px)": {
      padding: "75px",
    },
  },
  text: {
    fontWeight: "bold !important",
  },

  imageTag: {
    color: "#fff",
    fontSize: "24px",
    // color: "fff",
    padding: "79px 0 0 0",
    fontWeight: "bold",
    fontFamily: " Times New Roman",
    textAlign: "center",
  },
  imageTagLine: {
    fontSize: "24px",
    lineHeight: "28px",
    fontFamily: " Times New Roman",
  },
  remember: {
    fontSize: "16px",
    fontFamily: " Times New Roman",
    letterSpacing: "0",
    textTransform: "none",
    display: "inline-block",
    color: "#b1b1b1",
    // display: 'flex',
    lineHeight: "15px",
    marginTop: "5px",
  },
  rememberSpan: {
    paddingLeft: "4px",
    fontSize: "16px",
    fontFamily: " Times New Roman",
  },
  forgotPass: {
    color: "#676767",
    fontSize: "16px",
    fontFamily: " Times New Roman",
    textDecoration: "none",
    fontWeight: "bold",
  },
  policy: {
    textDecoration: "underline",
    color: "#eb6e12",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#eb6e12",
    },
  },
  policyDesc: {
    fontSize: "14px",
    fontFamily: " Times New Roman",
    letterSpacing: "0",
    textTransform: "none",
    paddingTop: "10px",
    fontWeight: "bold",
  },
  grid: {
    backgroundColor: "grey",
    // height: "50vh",
    textAlign: "center",
    fontFamily: " Times New Roman",
  },
  btn: {
    // margin: theme.spacing(3, 0, 2)
    fontFamily: " Times New Roman ",
  },

  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: " Times New Roman",
  },
  typography: {
    fontFamily: " Times New Roman !importent",
  },
  listAvatar: {
    color: "#fff",
  },
  // boldHeading: {
  //     color: 'white',
  //     paddingTop: '20px',
  //     fontSize: '20px',
  //     fontWeight: 400,
  //     paddingLeft: '105px',
  //     // fontFamily: 'Times New Roman !importent'
  // },
  // headingDiscroption: {
  //     color: 'white',
  //     fontSize: '20px',
  //     fontWeight: 400,
  //     paddingLeft: '105px'
  // },

  toolbarButtons: {
    marginLeft: "auto",
    display: "flex",
  },
  menuButtons: {
    marginRight: "auto",
  },
  tabMenu: {
    "&hover": {
      // backGroundColor: '#575756 !important'
    },
  },
  loginMobileLogo: {
    width: "165px",
    position: "relative",
    top: "15px",
    // '@media (max-width: 960px)': {
    //     display: 'flex'
    // },
    // '@media (min-width: 960px)': {
    //     display: 'none'
    // }
  },
  // coomonComponent: {
  //     display: 'flex'
  // },
  thaiTextFieldInputProps: {
    paddingTop: "5px",
  },
  //css for dashboard
  // deshboardContainer: {
  //     width: "57%",
  //     position: 'relative',
  //     top: '-20px',
  //     left: '20px',
  //     backgroundColor: '#f8f8f8',
  //     margenTop: '-20px',
  //     margenLeft: '20px',
  //     // display: 'flex'
  // },

  // listTitle: {
  //     marginLeft: '-65px'
  // },
  // dashboardGridContainor: {
  //     backgroundColor: 'WHITE',
  //     width: '100%',
  //     position: 'relative',
  //     top: '48px',
  //     left: '16px'
  // },
  // gridPossition: {
  //     marginLeft: '80px',
  //     marginTop: '18px'
  // },
  // doc: {
  //     backgroundColor: 'red'
  // },
  // expentionPanlaDate: {
  //     // width: '40%',
  //     // height: 'auto',
  //     // marginLeft: '250px',
  //     // marginBottom: '20px'
  // },
  // // expantionSummary: {

  // // }
  // dashboardDatePicker: {
  //     color: 'white'
  // },
  apiMessage: {
    color: "#f44336",
    display: "flex",
    marginBottom: "-10px",
    marginTop: "-10px",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Times New Roman!important",
    fontweight: 400,
    lineHeight: 1.66,
  },
  apiMessageSuccses: {
    color: "green",
    display: "flex",
    marginBottom: "-15px",
    marginTop: "-55px",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Times New Roman!important",
    fontweight: 400,
    lineHeight: 1.66,
  },
});

export default styles;
