import React from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import "./inputText.css";


const NewInputText = ({onKeyPress, onKeyUp, label, fieldName, classvalue, fieldValue, errorField, errors, fieldHandleChange, fieldType, disableField,  requiredField, onBlur, placeholder, maxLength }) => (
  <>
       
        <div className="fc-input-container"  style={errorField}>
            <input
                placeholder={placeholder}
                name={fieldName}
                value={fieldValue}
                onChange={fieldHandleChange}
                type={fieldType}
                disabled={disableField ? true : false}
                className={`fc-input-style ${classvalue}`}
                onBlur={onBlur}
                maxLength={maxLength ? maxLength : null}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
            />
        </div>
        
  </>
);

export default NewInputText;
