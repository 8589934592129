import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import {
  DataContext,
  SupportDataContext,
} from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import {
  getTotalLength,
  handleDefaultFilters,
  handleFilters,
} from "../../../../utils/Filters";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "react-bootstrap";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddNewLeadViewTable = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  getAddNewLeadViewListingApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    handleModalOpen,
    setEditModalPopper,
    setIsOpen, isOpen,
    message

  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);

  useEffect(() => {
    getAddNewLeadViewListingApi();
    handleListingSavedSearch();
  }, []);


  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

 
  const columnDefs = [
    {
      headerName: "S #",
      field: "Serial_Number",
      cellRenderer: "agGroupCellRenderer",
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
      width: 120,
      pinned: "left"
    },
    
    {
      headerName: "ENQ#",
      field: "Inquiry_Id",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
      cellRenderer: (params) => {
        return (
          <div>
              {params?.data?.ReferralSource === "CP1" ? params?.data?.Inquiry_Id.slice(0, 2) + '-CP1-' + params?.data?.Inquiry_Id.slice(2) 
              : params?.data?.ReferralSource === "CP2" ? params?.data?.Inquiry_Id.slice(0, 2) + '-CP2-' + params?.data?.Inquiry_Id.slice(2)  
              :
              params?.data?.Inquiry_Id
              }
          </div>
        );
      },
    },
    {
      headerName: "Lead Caption",
      field: "Lead_Caption",
      cellStyle: { border: "1px solid #fff" },
      filter: "agSetColumnFilter",
      width: 150,
    },
    {
      headerName: "Conversation Status",
      field: "Conversation_Status",
      filter: "agSetColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Conversation_Status === "Converted" ? (
              <>
                {params?.data?.Conversation_Status}
                <br />
                <div>
                  <b>{params?.data?.Converted_Date}</b>
                </div>
              </>
            ) : (
              params?.data?.Conversation_Status
            )}
          </>
        );
      },
    },
    {
      headerName: "Client Email",
      field: "Client_Email_id",
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      width: 250,
      cellRenderer: (params) => {
        const myEmail = `mailto:${params?.data?.Client_Email_id}`
        return (
          <div>
            <button
            style={{
              backgroundColor: "#E5E4E2",
              border: "none",
              color: " #0000FF",
              textDecoration: "none",
              width: "100%",
              fontSize: "14px"
            }}
            onClick={() => window.open(myEmail, "_blank")}
          >
          {params?.data?.Client_Email_id}
          </button>
          </div>
        );
      },
    },
    {
      headerName: "Client Mobile No",
      field: "Client_Mobile_No",
      cellStyle: { border: "1px solid #fff" },
      width: 170,
      cellRenderer: (params) => {
        const myMobile= params?.data?.Visitor_Phone_C_Code && params?.data?.Visitor_Phone_C_Code?.replace("+", "") 
        let VisitorPhoneNo = params?.data?.Client_Mobile_No.replace(/-/g, "");
        if (VisitorPhoneNo.startsWith("+")) {
            VisitorPhoneNo = VisitorPhoneNo.substring(1);
         }
        const myWhatsApp = `https://api.whatsapp.com/send?phone=${VisitorPhoneNo}`
        return (
          <div>
            <button
            style={{
              backgroundColor: "#E5E4E2",
              border: "none",
              color: " #0000FF",
              textDecoration: "none",
              width: "100%",
              fontSize: "14px"
            }}
            onClick={() => window.open(myWhatsApp, "_blank")}
          >
           {params?.data?.Client_Mobile_No}
          </button>
          </div>
        );
      },
    },
    {
      headerName: "Lead Date",
      field: "Enquiry_Date",
      filter: "agDateColumnFilter",
      // type: ["dateColumn", "nonEditableColumn"],
      filterParams: filterParams,
      cellRenderer: (params) => {
        return <>{moment(params?.data?.Enquiry_Date).format("DD-MM-YYYY HH:mm:ss")}</>;
      },
      cellStyle: { border: "1px solid #fff" },
      width: 150,
    },
    {
      headerName: "Assigned Date",
      field: "Enquiry_Assigned_Date",
      type: ["dateColumn", "nonEditableColumn"],
      filterParams: filterParams,
      cellRenderer: (params) => {
        return (
          <>
            {moment(params?.data?.Enquiry_Assigned_Date).format("DD-MM-YYYY HH:mm:ss")}
          </>
        );
      },

      cellStyle: { border: "1px solid #fff" },
      width: 150,
    },
    {
      headerName: "Reassigned Date",
      field: "Enquiry_Re_Assigned_Date",
      type: ["dateColumn", "nonEditableColumn"],
      // filterParams: filterParams,
      filter: true,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Enquiry_Re_Assigned_Date === ""
              ? ""
              : moment(params?.data?.["Enquiry_Re_Assigned_Date"]).format(
                "DD-MM-YYYY HH:mm:ss"
              )}
          </>
        );
      },

      cellStyle: { border: "1px solid #fff" },
      width: 170,
    },
    {
      headerName: "Enquiry Source",
      field: "Lead_Source",
      cellStyle: { border: "1px solid #fff" },
      filter: "agSetColumnFilter",
      width: 150,
    },
    {
      headerName: "Consultant Name",
      field: "Consultant_Name",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
    },
    {
      headerName: "Created By",
      field: "Created_By",
      // filter: "agTextColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      width: 150,
    },
    {
      headerName: "Lead Received Through",
      field: "Lead_Received_Through",
      filter: "agSetColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
      hide: true,
    },
    {
      headerName: "Type_of_Company",
      field: "Type_of_Company",
      filter: "agSetColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
      hide: true,
    },
    {
      headerName: "Nationality",
      field: "Nationality",
      filter: "agSetColumnFilter",
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
      hide: true,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  const renderExpandableRow = (value) => {
    const row = value?.data;
    return (
      <table>
        <tr>
          <th>Type Of Activity</th>
          <th>Type Of Company</th>
          <th>Gender</th>
          <th>Description</th>
          <th>Alternate Contact Number</th>

        </tr>
        <tr>
          <td>{row.Type_of_Activity}</td>
          <td>{row.Type_of_Company}</td>
          <td>{row.Gender}</td>
          <td><div dangerouslySetInnerHTML={{ __html: row.Description }} /></td>
          <td>{row.alternative_contact_number}</td>
        </tr>
      </table>
    );
  };

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >

              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLength(
                        apiData,
                        val.Search_Name,
                        valueKey
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.length}
      />
      
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          masterDetail={true}
          detailCellRenderer={renderExpandableRow}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddNewLeadViewTable;
