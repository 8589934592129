import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import { DataContext, SupportDataContext } from "../../../../provider/context";
  import { getLocalData } from "../../../../services/services";
  import "../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import ModalPopper from "../Modals/ModelPopper";
  import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
  import ArticleIcon from "@mui/icons-material/Article";
  import EditIcon from '@mui/icons-material/Edit';
  import HistoryIcon from "@mui/icons-material/History";
  import Col from "react-bootstrap/Col";
  import Form from "react-bootstrap/Form";
  import Row from "react-bootstrap/Row";
  import { Grid } from "@material-ui/core";
  import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
  import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
  import { Button } from "react-bootstrap";
  import { Formik, Field, getIn, FieldArray } from "formik";
  import { CustomSelect } from "../../../listing/listingForm/ListingForm";
  import axios from "axios";
  import Select from 'react-select';
  import { addDays } from "date-fns";
  import DatePicker from "react-datepicker";
  import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'





  
  
  
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const SupportOrderStatus = ({
    apiData,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge, classes
  }) => {
    const {
      setLoading,
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setViewRequistion,
      setSelected,
      setIsOpen, isOpen,
      message,
      handleModalOpen,
      getTypistRequestionListApi,
      typistRequestionList
    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    //const [isOpen, setIsOpen] = useState(false);
    //const [message, setMessage] = useState("");
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const todayDate = moment().format("YYYY-MM-DD");
    const [supportName, setSupportName] = useState(0);
    const [startDate, setStartDate] = useState();
    const [supportOrderStatusgData, setSupportOrderStatusData] = useState(null);
    
    const getStyles = (errors, fieldName, height) => {

      if (height && !getIn(errors, fieldName)) {
        return { height: height }
      }
    
      if (getIn(errors, fieldName) && height) {
        return {
          border: "1px solid silver",
          height: height
        };
      } else if (getIn(errors, fieldName)) {
        return {
          border: "1px solid silver",
        };
      }
    };

    const supportOptions = supportOrderStatusgData?.support_list?.map((item) => {
      const obj = { value: item.EmpId, label: item.Emp_Name };
      return obj;
    });
    useEffect(() => {
      getSupportOrderStatusDataApi();
    }, [])
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
   
    const documentItems = (params) => {
      let data = params?.data.Document;
  
  
      return data ? (
        <>
          <Button
            style={{
              marginLeft: ".4rem",
              textDecorationColor: "#eb6e12",
              background: "none",
              cursor: "pointer",
              border: "none",
              padding: "6px",
            }}
            onClick={() => window.open(data)}
          >
            View
          </Button>
        </>
      ) : null;
    };
   const dateFilterParams = {
    inRangeInclusive: true,
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        if (dateAsString == null) {
          return 0;
        }
        const dateParts = dateAsString.slice(0, 10).split("-");
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
  
    const columnDefs = [
      {
        headerName: "S#",
        field: "Serial_Number",
        //checkboxSelection: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 80,
        pinned: "left",
        filter: false
      },
      {
        headerName: "Support Name",
        field: "Support_Name",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto', textAlign: "center" },
        width: 210,
        pinned: "left",
      },
      {
        headerName: "New Order",
        field: "Today_New_Order",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto', textAlign: "center" },
        width: 130,
      },
      {
        headerName: "Reassign Orders",
        field: "Today_Order_Reassign",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 130,
      },
      {
        headerName: "Total Call Alerts",
        field: "Total_Call_Alerts",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto', textAlign: "center" },
        width: 130,
      },
      {
        headerName: "Total Email Alerts",
        field: "Total_Email_Alerts",
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto', textAlign: "center" },
        width: 130,
      },
      {
        headerName: "Total Whatsapp Alerts",
        field: "Total_Whatsapp_Alerts",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 160,
      },
      {
        headerName: "Total Freezed Order",
        field: "Total_Freezed_Order",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 150,
      },
      {
        headerName: "Total Future Alerts All Time",
        field: "Total_Order_Future_Alert",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center"},
        width: 150,
      },
      {
        headerName: "Total Pending Alerts All Time",
        field: "Total_Pending_Order_Alerts",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center"},
        width: 150,
      },
      {
        headerName: "Total Today Pending Alerts",
        field: "Total_Pending_Order_Alerts_Today",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        width: 170,
      },
      {
        headerName: "Total Underprocess Order",
        field: "Total_Underprocess_Order",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        minWidth: 170,
        flex: 1,
      },
      {
        headerName: "Request Completion Order Today",
        field: "Order_Complition_Request_Today",
        filter: true,
        cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
        minWidth: 170,
        flex: 1,
      },
      
    ];
    
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        // filter: true,
        // enable floating filters by default
        // floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,

      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = cellValue.split("/");
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      };
    }, []);
  
    const onRowSelected = (event) => {
      const rowData = event.api.getSelectedNodes()[0]?.data;
      setSelected([rowData]);
    };
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const getSupportOrderStatusDataApi = async () => {
        gridRef?.current?.api?.showLoadingOverlay();
      const __token = localStorage.getItem("token");
      const user_type = getLocalData("user_type");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      const statusDate = moment(startDate).format("YYYY-MM-DD")
  
      setLoading(true);
     try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/Admin_Get_Support_Status_List/?factory_id=${factory_id}&user_type=${user_type}&emp_id=${emp_id}&support_id=${supportName}&status_date=${statusDate}`
      ;
        const headers = {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        };
        const res = await axios.get(url, { headers });
        setSupportOrderStatusData(res?.data?.Data);
        setLoading(false);
        // res?.data?.Data?.support_status_list?.length < 1?
        // gridRef?.current?.api?.showNoRowsOverlay() :
      } catch (err) {
        console.error(err);
        // gridRef?.current?.api?.showNoRowsOverlay();
      }
    };
    
    const handleSearch = (searchName, value, searchKey) => {
      handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey)
    }
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
       <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
        <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            <CustomDropdown
              label="Support Name"
              fieldOptions={supportOptions}
              fieldValue={supportName}
              fieldHandleChange={(value) => {
                setSupportName(value.value);
              }}
            />
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
          <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="5"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Select Date
              </Form.Label>
              <Col sm="7" style={{ padding: "0 12px 0 0" }}>
              <div style={{ border: "0.2px solid #d3d3d3",}}  >
              <div>
                 <DatePicker
                 className='form-control form-control-solid w-250px '
                 showMonthDropdown
                 showYearDropdown
                 selected={startDate}
                 onChange={(date) => {
                   setStartDate(date);
                 }}
                 dateFormat="dd-MM-yyyy"
                maxDate={addDays(new Date(), 0)}
                 // minDate={addDays(new Date(), -7)}
                 placeholderText="Select Date"
               />
                </div>
                </div>
              </Col>
            </Form.Group>
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem", marginLeft: "30px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#eb6e12",
                fontFamily: "Times New Roman !important",
                color: "#fff",
                width: "120px",
                height: '37px',
                marginTop: '20x'
              }}
              onClick={() => {
                getSupportOrderStatusDataApi();
              }}
            disabled={
               startDate === undefined || startDate === '' 
               ||  supportName === undefined || supportName === 0
               ? true: false
            }
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </div>
  
       
  
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={supportOrderStatusgData?.support_status_list?.length}
        />
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
  
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={supportOrderStatusgData?.support_status_list}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 60}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
            overlayLoadingTemplate={"data loading..."}
            overlayNoRowsTemplate={"No Data"}
          ></AgGridReact>
          
        </div>
        <ModalPopper />
        <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default SupportOrderStatus;
  