import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import "../../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../ModelPopper";
import axios from "axios";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../../utils/Filters";
import ReplyIcon from '@mui/icons-material/Reply';
import { FaEye } from "react-icons/fa";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ViewTargetDetailsTable from "./ViewTargetDetailsTable";
import StarHalfIcon from '@mui/icons-material/StarHalf';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import logo from "../../../../../commonComp/assets/finallogo.png";
import { getLocalData, headers } from "../../../../../services/services";
import DownloadIcon from "@mui/icons-material/Download";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminTargetAchievementTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        setSalesInfo,
        setEditModalPopper,
        setButtonText,
        setSelected, setLoading,
        handleModalOpen,
        message, setMessage,
        isOpen, setIsOpen, severity,
        setRowData,
        isCompleteOrderBtnDisable,
        performanceTargetData,
        getPCOAchievementDataApi, empAchievementData,
        operationInfo,
        getAdminPerformanceTargetDataApi,
        getAdminTargetMastertDataApi

    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);

    useEffect(() => {
        // getPerformanceTargetDataApi();
        // getTargetMastertDataApi();
        getAdminPerformanceTargetDataApi();
        getAdminTargetMastertDataApi();
    }, []);

    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);
    console.log(performanceTargetData);

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
                return -1;
            }
            if (dateAsString == "") {
                return -1;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };


   const dateFilterParams = {
    inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
                return 0;
            }
            const dateParts = dateAsString.slice(0, 10).split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const renderExpandableRow = (value) => {
        setSalesInfo(value?.data);
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        marginLeft: "4px",
                        width: "99%",
                        height: "301px",
                        minHeight: "40px",
                        overflowX: "scroll",
                        overflowY: "scroll",
                        border: "1px solid white",
                    }}
                >
                    <ViewTargetDetailsTable />

                </div>
            </>
        );
    };
    const detailCellRenderer = useMemo(() => {
        return renderExpandableRow;
    }, []);
    const downlodBtn = async (data) => {
        const __token = localStorage.getItem("token");
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        const TargetEmpId = operationInfo?.EmpId;
        const EmpUserType = operationInfo?.Employee_Actual_Designation;
        const targetMonth = data?.Target_Month;
        const targetYear = data?.Target_Year;

        setLoading(true);

        await fetch(
            `https://flyingcolour.online/fcapp/docprocessing_api/get_adminAssistant_performance_target_list/?emp_id=${emp_id}&user_type=${user_type}&factory_id=${factory_id}&target_emp_id=${TargetEmpId}&target_emp_designation=${EmpUserType}&target_month=${targetMonth}&target_year=${targetYear}`,
            {
                method: "GET",
                headers: headers(__token),
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((viewData) => {
                const targetAcheiveData = viewData?.Data?.employeePerformance_Target_List;
                const totalObtained = targetAcheiveData?.[0]?.targetDetails_List?.map((val) => Number(val?.Achieve_Mark));
                // Calculate total of target_value
                for (let i = 0; i < totalObtained?.length; i++) {
                    if (totalObtained[i] === null || isNaN(totalObtained[i])) {
                        totalObtained[i] = 0;
                    }
                }
                const totalObtainedMarks = totalObtained?.reduce((accumulator, current) => {
                    return accumulator + current;
                }, 0);
                const footer1 = "";
                const footer2 = "This Report is Generated By System";
                const doc = new jsPDF();

                const addHeaders = (doc) => {
                    const pageCount = doc.internal.getNumberOfPages();
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(10);
                    for (var i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.text(
                            "Page " + String(i) + " of " + String(pageCount),
                            10,
                            15,
                            {
                                align: "left",
                            }
                        );
                        doc.addImage(logo, "PNG", 165, 5, 36, 25);
                    }
                };
                const addFooters = (doc) => {
                    const pageCount = doc.internal.getNumberOfPages();
                    doc.setFont("helvetica", "italic");
                    doc.setFontSize(8);
                    for (var i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.text(
                            footer1,
                            doc.internal.pageSize.width / 2,
                            287,
                            {
                                align: "center",
                            }
                        );
                        doc.text(
                            footer2,
                            doc.internal.pageSize.width / 2,
                            291,
                            {
                                align: "center",
                            }
                        );
                    }
                };

                doc.setFont("helvetica", "bold");
                doc.setFontSize(9);
                doc.text(
                    `Downloaded Date: ${moment(new Date()).format("DD-MM-YYYY hh:mm:ss")}`,
                    10,
                    35
                );

                doc.setFont("helvetica", "bold");
                doc.setFontSize(16);
                doc.text(`Employee Performance Report`, 68, 50);
                var nestedTableCell = {
                    content: "",
                    styles: { minCellHeight: 22 },
                };
                doc.setFont("helvetica", "bold");
                doc.setFontSize(9);
                doc.text(
                    `Last Achievment Updated Date :`,
                    15,
                    58
                );
                doc.setFont("helvetica", "bold");
                doc.setFontSize(9);
                doc.text(
                    `${moment(targetAcheiveData?.[0]?.Achievement_Date).format("DD-MM-YYYY hh:mm:ss")}`,
                    143,
                    58
                );
                doc.setFont("helvetica",);
                doc.setFontSize(9);
                doc.text(
                    `Employee Name : ${operationInfo.Emp_Name}`,
                    15,
                    66
                );
                doc.setFont("helvetica",);
                doc.setFontSize(9);
                doc.text(
                    `Employee Designation : ${targetAcheiveData?.[0]?.Target_Emp_Designation}`,
                    15,
                    74
                );
                doc.setFont("helvetica",);
                doc.setFontSize(9);
                doc.text(
                    `Traget Month : ${targetAcheiveData?.[0]?.Target_Month}`,
                    143,
                    66
                    
                );
                doc.setFont("helvetica",);
                doc.setFontSize(9);
                doc.text(
                    `Traget Year : ${targetAcheiveData?.[0]?.Target_Year}`,
                    143,
                    74
                );

                var nestedCell = {
                    content: "",
                };
            
                let columnIndexToHighlight;
                if (totalObtainedMarks >= 0 && totalObtainedMarks <= 40) columnIndexToHighlight = 0;
                else if (totalObtainedMarks >= 41 && totalObtainedMarks <= 60) columnIndexToHighlight = 1;
                else if (totalObtainedMarks >= 61 && totalObtainedMarks <= 80) columnIndexToHighlight = 2;
                else if (totalObtainedMarks >= 81 && totalObtainedMarks <= 90) columnIndexToHighlight = 3;
                else if (totalObtainedMarks >= 91 && totalObtainedMarks <= 100) columnIndexToHighlight = 4;
               
                autoTable(doc, {
                    styles: { fillColor: [235, 110, 18], halign: "center" },
                    margin: { top: 78, bottom: 0 },
                    columnStyles: { lineColor: [0, 0, 0] },
                    head: [["Target / Achievement Details"]],
                    body: [[nestedCell]],
                    didDrawCell: function(data) {
                        if (data.row.index === 0 && data.row.section === "body") {
                            const startY = data.cell.y + 0.6;
                            const tableWidth = data.cell.width;
                
                            // Add your first table
                            doc.autoTable({
                                theme: "grid",
                                headStyles: {
                                    fillColor: [239, 110, 15],
                                    halign: "center",
                                    fontStyle: "bold",
                                },
                                styles: {},
                                bodyStyles: { lineColor: [0, 0, 0] },
                                startY: startY,
                                margin: { top: 58 },
                                tableWidth: tableWidth,
                                columnStyles: { 
                                    1: {cellWidth: 80},
                                    2: {cellWidth: 19}, 
                                    3: {cellWidth: 19}, 
                                    4: {cellWidth: 45}, 
                                },
                                head: [
                                    [
                                        "Sr. No",
                                        "Description",
                                        "Target",
                                        "Target Achieved",
                                        "Comments",
                                    ],
                                ],
                                body: targetAcheiveData?.[0]?.targetDetails_List?.map((i, index) => {
                                    return [index+1, i.Target_Name, i.Target_Value, i.Achieve_Mark, i.Comments];
                                }).concat([['', { content: 'Total Achieved Marks', colSpan: 2, styles: { fontStyle: 'bold' } }, { content: totalObtainedMarks, styles: { fontStyle: 'bold' } }, '']]),
                            }); 
                
                            // Calculate startY for the second table
                            const startYSecondTable = doc.previousAutoTable.finalY + 5;
                           
                            // second table
                            doc.autoTable({
                                head: [["Poor", "Week", "Moderate", "Good", "Excellent"]],
                                body: [['0-40', '41-60', '61-80', '81-90', '91-100']],
                                startY: startYSecondTable,
                                didParseCell: function (data) {
                                    if (data.cell.section === 'head') {
                                        data.cell.styles.fillColor = [235, 110, 18]; 
                                    }
                                    else if (data.cell.section === 'body' && data.column.index === columnIndexToHighlight) {
                                        // background color to highlight
                                        data.cell.styles.fillColor = [255, 255, 0]; // yellow,
                                    }
                                }
                            });
                        }
                    },
                });
                function hexToRgb(hex) {
                    hex = hex.replace('#', '');
                    var r = parseInt(hex.substring(0, 2), 16);
                    var g = parseInt(hex.substring(2, 4), 16);
                    var b = parseInt(hex.substring(4, 6), 16);
                    return { r, g, b };
                }
                // var color = hexToRgb("#eb6e12");
                // if (totalObtainedMarks <= 40) {
                //     doc.setTextColor(color.r, color.g, color.b);
                //     doc.setFont("helvetica", "bold");
                //     doc.setFontSize(9);
                //     doc.text(
                //         `0-40 : Poor`,
                //         15,
                //         65
                //     );
             
                doc.html(ReactDOMServer.renderToStaticMarkup(), {
                    x: 14,
                    y: 398,
                    callback: () => {
                        addHeaders(doc);
                        addFooters(doc);
                        doc.save("Employee Performance.pdf");
                    },
                });
                // To handle page breaks and automatic addition of a second page if needed
                // doc.autoTableEndPosY();
                // if (doc.autoTable.previous.finalY > 280) {
                //     doc.addPage();
                // }
            });
        // setPrepare(false);
    };

    const columnDefs = [
        {
            headerName: "S #",
            field: "Serial_Number",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
            minWidth: 40,
            pinned: "left",
            filter: false
        },
        {
            headerName: "Actions",
            field: "Inquiry_Id",
            filter: false,
            // checkboxSelection: true,
            cellRenderer:
                (params) => {
                    var currentDate = new Date(params?.data?.Achievement_Date);
                    var futureDate = new Date();
                    futureDate.setDate(futureDate.getDate() + 15);
                    return (
                        <>
                            <CustomIconButton
                                title="Edit Target"
                                disabled={
                                    isCompleteOrderBtnDisable
                                        || params.data?.TargetAchieve_Flag === 1 ? true : false}
                                icon={
                                    <EditIcon
                                        onClick={() => {
                                            handleModalOpen();
                                            setEditModalPopper(true);
                                            setRowData(params.data);
                                            setButtonText("Add Admin Assistant Target");
                                            //  getAdminCompalinFormDataApi(params.data)
                                        }}
                                    />
                                }
                            />
                            <CustomIconButton
                                title="Add Achievement"
                                disabled={
                                    isCompleteOrderBtnDisable
                                        || (currentDate > futureDate) ? true : false}
                                icon={<StarHalfIcon />}
                                onClick={() => {
                                    // setEditModalPopper(true);
                                    setRowData(params.data);
                                    getPCOAchievementDataApi(params.data);
                                    setTimeout(() => {
                                        setButtonText("Add Admin Assistant Achievement");
                                        handleModalOpen();
                                    }, 3000)
                                }}
                            />
                            <CustomIconButton
                                title="View Target/Achievement Details"
                                icon={<RemoveRedEyeIcon />}
                                onClick={() => {
                                    // setEditModalPopper(true);
                                    setRowData(params.data);
                                    getPCOAchievementDataApi(params.data);
                                    setTimeout(() => {
                                        setButtonText("View Admin Assistant Achievement");
                                        handleModalOpen();
                                    }, 2000)
                                }}
                                disabled={params.data?.TargetAchieve_Flag === 0 ? true : false}
                            />
                            <CustomIconButton
                                title="Download Report"
                                icon={<DownloadIcon />}
                                onClick={(e) => {
                                    downlodBtn(params?.data)
                                }}
                            // onMouseEnter={handleClick("Download")}
                            />
                        </>
                    );
                },
            cellStyle: { border: "1px solid #fff", overflowY: 'auto' },
            minWidth: 190,
            pinned: "left",
        },
        {
            headerName: "Target Emp Designation",
            field: "Target_Emp_Designation",
            filter: "agTextColumnFilter",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            flex: 1,
        },
        {
            headerName: "Target Month",
            field: "Target_Month",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            minWidth: 200,
            flex: 1,
        },
        {
            headerName: "Target Year",
            field: "Target_Year",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            flex: 1,
        },
        {
            headerName: "Target Emp Id",
            field: "Target_Emp_Id",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            width: 150,
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            filter: true,
            // enable floating filters by default
            floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            //flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };


    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    const filters = [
        {
            id: 1,
            title: "Default",
            value: null,
            key: null,
        },
    ];

    useEffect(() => {
        const parsedSavedValue = JSON.parse(savedValue);
        if (parsedSavedValue !== null) {
            let values = Object.keys(parsedSavedValue);
            values.map((val, i) => {
                if (parsedSavedValue[val].filterType === "text") {
                    gridRef.current.api.getFilterInstance(val).setModel({
                        type: "contains",
                        filter: parsedSavedValue[val].filter,
                    });
                } else {
                    gridRef.current.api
                        .getFilterInstance(val)
                        .setModel({ values: parsedSavedValue[val].values });
                }
            });
            gridRef.current.api.onFilterChanged();
        }
    }, [savedValue]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

    const handleListingSavedSearch = async () => {
        const __token = localStorage.getItem("token");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        await fetch(
            `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${__token}`,
                    "Content-Type": "application/json",
                },
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSavedSearchList(data.results?.Response);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className="listing-data-grid" style={{ height: "650px" }}>

            <div
                style={{ height: "100%", width: "100%" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={performanceTargetData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowStyle={{
                        background: "#DEDEDE",
                        color: "#000000DE",
                        borderBottom: "1px solid white",
                    }}
                    components={components}
                    masterDetail={true}
                    animateRows={true}
                    getRowHeight={() => 90}
                    pagination={true}
                    detailRowAutoHeight={true}
                    //onPaginationChanged={onPaginationChanged}
                    detailCellRenderer={detailCellRenderer}
                ></AgGridReact>

            </div>
            <ModalPopper />
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AdminTargetAchievementTable;
