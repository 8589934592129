import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import { Button } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CommonPageSelect from "../../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../../provider/context";
import "../../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../ModelPopper";
import axios from "axios";
import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../../utils/Filters";
import ReplyIcon from '@mui/icons-material/Reply';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewReplyDetailsTable = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge
}) => {
    const {
        setEditModalPopper,
        setButtonText,
        setSelected, setLoading,
        handleModalOpen,
        message, setMessage,
        isOpen, setIsOpen, severity,
        getCommentListDataApi, commentListData, setRowData,
        salesInfo, rowData
    } = useContext(SupportDataContext);
    const containerStyle = useMemo(() => ({ width: "97%", height: "100%" }), []);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);

    useEffect(() => {
        getCommentListDataApi();
        handleListingSavedSearch();
    }, []);
    
    const components = useMemo(() => {
        return {
            agDateInput: CustomCalender,
        };
    }, []);

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
                return -1;
            }
            if (dateAsString == "") {
                return -1;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };
   const dateFilterParams = {
    inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
                return 0;
            }
            const dateParts = dateAsString.slice(0, 10).split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
    };

    const columnDefs = [
        {
            headerName: "Sr. #",
            valueGetter: "node.rowIndex + 1",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
            minWidth: 40,
            pinned: "left",
            filter: false
        },
        {
            headerName: "Reply Id",
            field: "Reply_Id",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            minWidth: 120,
            pinned: "left",
        },
        {
            headerName: "Reply Details",
            field: "Reply_Details",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
            minWidth: 200,
            cellRenderer: (params) => {
                params = params?.data?.Reply_Details;
                return (
                    <p
                        style={{
                            padding: "0 5px",
                            margin: "0 2rem 1rem 0",
                        }}
                        dangerouslySetInnerHTML={{ __html: params }}
                    />
                );
            },
        },
        {
            headerName: "Reply Datetime",
            field: "Reply_Datetime",
            filter: "agDateColumnFilter",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            minWidth: 200,
            filterParams: dateFilterParams,
            cellRenderer: (params) => {
                return <>{moment(params?.data?.Reply_Datetime).format("DD-MM-YYYY hh:mm:ss")}</>;
            },
        },
        {
            headerName: "Reply By",
            field: "Reply_By",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            filter: "agSetColumnFilter",
            minWidth: 200,
        },
        {
            headerName: "Reply By Role",
            field: "Reply_By_Role",
            cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
            filter: "agSetColumnFilter",
            minWidth: 200,
        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            // set the default column width
            width: 100,
            // make every column editable
            editable: false,
            // make every column use 'text' filter by default
            // filter: true,
            // enable floating filters by default
            // floatingFilter: true,
            // make columns resizable
            resizable: true,
            sortable: true,
            flex:1,
        };
    }, []);

    const columnTypes = useMemo(() => {
        return {
            numberColumn: { width: 130, filter: "agNumberColumnFilter" },
            medalColumn: { width: 100, columnGroupShow: "open", filter: false },
            nonEditableColumn: { editable: false },
            dateColumn: {
                // specify we want to use the date filter
                filter: "agDateColumnFilter",
                // add extra parameters for the date filter
                filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const dateParts = cellValue.split("/");
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const cellDate = new Date(year, month, day);
                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
            },
        };
    }, []);

    const onRowSelected = (event) => {
        const rowData = event.api.getSelectedNodes()[0]?.data;
        setSelected([rowData]);
    };


    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById("page-size").value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onPaginationChanged = (e) => {
        const currentPage = e.api.paginationGetCurrentPage() + 1;
        const pageSize = e.api.paginationGetPageSize();

        const to = pageSize * currentPage;
        const from = to - pageSize;

        setFromRows(from + 1);
        setToRows(to);
    };

    const handleGotoPage = (e) => {
        const value = e.target.value - 1;
        gridRef.current.api.paginationGoToPage(Number(value));
    };

    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
        setSearchVal(val);
        gridRef.current.api.setQuickFilter(val);
    };

    const filters = [
        {
            id: 1,
            title: "Default",
            value: null,
            key: null,
        },
    ];

    useEffect(() => {
        const parsedSavedValue = JSON.parse(savedValue);
        if (parsedSavedValue !== null) {
            let values = Object.keys(parsedSavedValue);
            values.map((val, i) => {
                if (parsedSavedValue[val].filterType === "text") {
                    gridRef.current.api.getFilterInstance(val).setModel({
                        type: "contains",
                        filter: parsedSavedValue[val].filter,
                    });
                } else {
                    gridRef.current.api
                        .getFilterInstance(val)
                        .setModel({ values: parsedSavedValue[val].values });
                }
            });
            gridRef.current.api.onFilterChanged();
        }
    }, [savedValue]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsOpen(false);
    };

    const handleListingSavedSearch = async () => {
        const __token = localStorage.getItem("token");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");
        await fetch(
            `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${__token}`,
                    "Content-Type": "application/json",
                },
                mode: "cors",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSavedSearchList(data.results?.Response);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div style={containerStyle}>

            <div
                style={{ height: "310px", maxHeight: "350px", overflow: "auto" }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    suppressExcelExport={true}
                    suppressCsvExport={true}
                    ref={gridRef}
                    rowData={rowData?.Reply_List}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    columnTypes={columnTypes}
                    animateRows={true}
                    components={components}
                    onSelectionChanged={onRowSelected}
                    rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
                    getRowHeight={() => 60}
                    pagination={true}
                    detailRowAutoHeight={true}
                    onPaginationChanged={onPaginationChanged}
                ></AgGridReact>
            </div>
            <ModalPopper />
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ViewReplyDetailsTable;
