import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const RequestOrderCompletedModalForm = ({ classes }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    operationInfo,
    getOrderCompletedTableData,
    fetchListingDataFromApi,
    setIsOpen
  } = useContext(SupportDataContext);

  

  const orderCompletedPostOrPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const user_name = localStorage.getItem("Employee_First_Name");
    const url = `https://flyingcolour.online/fcapp/support_api/supportcompleteorder/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      order_id: operationInfo?.Order_Id,
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      user_name: user_name,
      completed_reason: values?.remarks,
    };
    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      handleQuotationPromise(data);
    });
  };

  const handleQuotationPromise = (data) => {
    if (data.data.data.message) {
      setMessage(data.data.data.message);
      setSeverity(500);
    }
    if (data.data.data.msg) {
      setMessage(data.data.data.msg);
      setSeverity(500);
    }
    if (data.data.data.detail) {
      setMessage(data.data.data.detail);
      setSeverity(500);
    } else {
      setMessage(data.data.data.msg);
      setSeverity(data.data.data.code);
    }
    setIsOpen(true);
    getOrderCompletedTableData();
    fetchListingDataFromApi()
    handleModaleClose();
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        remarks: "",
      }}
      validate={(values) => {
        let errors = {};
        if (!values.remarks) {
          errors.remarks = "required";
        }
      }}
      onSubmit={async (data) => {
        setLoading(true);
        orderCompletedPostOrPUTAPI(data);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      handleModaleClose();
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    height: "400px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          Add Request Order Completion
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                            <InputText
                              label="Order Name"
                              fieldName="Order_Name"
                              fieldValue={operationInfo?.Order_Name}
                              disableField={true}
                            />
                            <InputText
                              label="Company Name"
                              fieldName="Company_Name"
                              fieldValue={operationInfo?.Company_Name}
                              disableField={true}
                            />
                            <CustomTextArea
                              label="Remarks"
                              fieldName="remarks"
                              fieldValue={values.remarks}
                              fieldHandleChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue(`remarks`, value);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        handleModaleClose();
                        // handleHide()
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(
  RequestOrderCompletedModalForm
);
