import React, { useContext, useEffect } from "react";
import modalTableStyles from "../Styles/ModalTableStyles";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import RescheduleListingTable from "./GeneralSchedule/RescheduleListingTable";
import ViewCompanyDocuments from "../Listing/ViewCompanyDocuments";
import WorkDoneListing from "./PRORequistionModals/WorkDoneListing";
import ExtendedPROHeadListing from "./PRORequistionModals/ExtendedPROHeadListing";
import LogsListing from "./PRORequistionModals/LogsListing";
import RefundedListing from "./PRORequistionModals/RefundedListing";
import CourseDocsTable from "../../Support/Modals/TrainingCourse/CourseDocsTable";
import TrainingCourseLogTable from "../../Support/Modals/TrainingCourse/TrainingCourseLogTable";
import ViewAttendeesTable from "../../Support/Modals/TrainingCourse/ViewAttendeesTable";
import ScheduleTrainingLogTable from "../../Support/Modals/TrainingCourse/ScheduleTrainingLogTable";




const ModalTable = ({ classes, data }) => {
  const { quotationApiMsg, expiredErrorMsg, salesInfo } =
    useContext(DataContext);
  const {
    handleModalOpen,
    buttonText,
    handleModalTableClose,
    setButtonText,
    operationInfo,
    setEditModalPopper,
    freezeLogData,
    followUp,
    isCompleteOrderBtnDisable,
    requestOrderCompletedData,
    setIsCompleteOrderBtnDisable,

  } = useContext(SupportDataContext);

  // const currDueDate = followUp?.Order_Followup_List?.[followUp?.Order_Followup_List.length - 1]?.Due_Date;

  const handleModalOpenSub = (e) => {
    const buttonText = e.currentTarget.innerText;
    setEditModalPopper(false);
    handleModalOpen();
  };

  return (
    <div className={classes.TabLowerContainerModalTable}>
      <div>
        {[
          "Add-Done",
          "Reschedule Schedule Form",
          "PRO Work Done",
          "Requistion Extended by PRO Head",
          "View Logs",
          "Refunded"
        ].includes(buttonText) ? null : (
          <>
            {[
              "Add / View Follow-up",
              "Add / View Comments",
              "View Company Order",
              "PRO Work Done",

              // "Requestion Log",
            ].includes(buttonText) ? (
              <>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Order No:{" "}
                    {operationInfo.Order_No || operationInfo.auto_order_id}
                  </span>
                </p>
                <p
                  variant="contained"
                  className={classes.NewButton1}
                  style={{ marginRight: "5px", marginTop: "4px" }}
                >
                  <span style={{ marginTop: "4px" }}>
                    Company Name:{" "}
                    {operationInfo.Company_Name || operationInfo.company_name}
                  </span>
                </p>
              </>
            ) : buttonText === "View Document" ? (
              <Button
             
                variant="contained"
                className={classes.NewButton}
                style={{ margin: 10 }}
              >
                <span style={{ marginTop: "4px" }}>Company Name:{" "}
                  {operationInfo.Company_Name || operationInfo.company_name}</span>
              </Button>
            ) :
              buttonText === "View Course Documents"
                || buttonText === "Training Course Log"
                ?
                <>
                  <Button
                  
                    variant="contained"
                    className={classes.NewButton}
                    style={{ margin: 10 }}
                  >
                    <span style={{ marginTop: "4px" }}> Course Id: {" "}
                      {operationInfo?.traning_course_id}</span>
                  </Button>
                </>
                :
                buttonText === "View Training Attendees"
                  || buttonText === "View Training Status Log"
                  ?
                  <>
                    <Button
                    
                      variant="contained"
                      className={classes.NewButton}
                      style={{ margin: 10 }}
                    >
                      <span style={{ marginTop: "4px" }}>Schedule Id: {" "}
                        {operationInfo?.traning_schedule_id}</span>
                    </Button>
                  </>
                  : 
                  buttonText ===  "View Company Order" ? null 
                  : buttonText ===  "PRO Work Done" ? (
                    <Button
                      variant="contained"
                      className={classes.NewButton}
                      onClick={(e) => handleModalOpenSub(e)}
                      disabled={
                        (Number(operationInfo?.req_amount) !== 0 
                        && operationInfo?.PROHead_Status === "Approved" 
                        && operationInfo?.Account_Status === "Approved")
                         ? false : true
                      }
                    >
                      <AddIcon style={{ marginLeft: "-10px" }} />{" "}
                      <span style={{ marginTop: "4px"}}> {buttonText}</span>
                    </Button>
                  ) : (
                    <Button
                    
                      variant="contained"
                      className={classes.NewButton}
                      onClick={(e) => handleModalOpenSub(e)}
                      disabled={isCompleteOrderBtnDisable ? true : false}
                    >
                      <AddIcon style={{ marginLeft: "-10px" }} />{" "}
                      <span style={{ marginTop: "4px"}}> {buttonText}</span>
                    </Button>
                  )
                  }
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#fff",
                color: "#eb6e12",
                marginLeft: "10px",
                padding: "7px 8px",
                marginTop: "4px"
              }}
              onClick={() => {
                setIsCompleteOrderBtnDisable(false);
                handleModalTableClose();
              }}
            >
              <span style={{ marginTop: "4px", marginRight: "5px" }}>
                Close
              </span>
              <CloseIcon />
            </Button>
            {expiredErrorMsg ? (
              <div className={classes.tableMsgContaner}>
                <span className={classes.tableMsgHeader}>
                  {quotationApiMsg}
                </span>
              </div>
            ) : null}
          </>
        )}
      </div>
      {/* <div className={classes.BorderSpacingUpperModaleTable}></div> */}
      {buttonText === "Reschedule Schedule Form" ? (
        <RescheduleListingTable />
      ) : buttonText === "View Document" ? (
        <ViewCompanyDocuments />
      ) : buttonText === "PRO Work Done" ? (
        <WorkDoneListing />
      ) : buttonText === "Requistion Extended by PRO Head" ? (
        <ExtendedPROHeadListing />
      ) : buttonText === "View Logs" ? (
        <LogsListing />
      ) : buttonText === "Refunded" ? (
        <RefundedListing />
      ) : buttonText === "View Course Documents" ? (
        <CourseDocsTable />
      ) : buttonText === "Training Course Log" ? (
        <TrainingCourseLogTable />
      ) : buttonText === "View Training Attendees" ? (
        <ViewAttendeesTable />
      ) : buttonText === "View Training Status Log" ? (
        <ScheduleTrainingLogTable />
      ) 
        : null}
      <div className={classes.BorderSpacingLowerModaleTable}></div>
    </div>
  );
};

export default withStyles(modalTableStyles)(ModalTable);
