import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../Styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModalPopper";
import {
  getTotalLength,
  handleDefaultFilters,
  handleFilters,
} from "../../../../utils/Filters";
import { getLocalData } from "../../../../services/services";
import axios from "axios";
import DatePicker from "react-datepicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import DateRangePickerComp from "../../../../reusableComponents/CustomDatePicker/DateRangePickerComp";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader';
import { FaPlus, FaEdit } from "react-icons/fa";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import GeneralScheduleForm from "../Modals/GeneralSchedule/GeneralScheduleForm";
import CompleteScheduleForm from "../Modals/GeneralSchedule/CompleteScheduleForm";
import { Modal } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PROTaskListingTable = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    setStart_Date, setEnd_Date,
    
  } = useContext(DataContext);
  
  const { handleModalTableOpen, 
    setButtonText, 
    setOperationInfo, 
    setSelected,
    fetchProTaskListFromApi,
    proTaskList,
    loading
   } =
    useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [proFilterListDropdown, setFilterListDropdown] = useState(null);
  const todayDate = moment().format("YYYY-MM-DD");
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [approval, setApproval] = useState("");
  const [proId, setProId] = useState(0);
  const [proName, setProName] = useState();
  const [companyName, setCompanyName] = useState(0);
  const [reqFromDate, setReqFromDate] = useState(todayDate);
  const [reqToDate, setReqToDate] = useState(todayDate);

  const approvalListDropDown = proTaskList?.Status_Details?.map((i) => {
    return {
      id: i.Value,
      value: i.Value,
      label: `${i.Name}`,
    };
  });
  const proNameListDropDown = proTaskList?.Pro_Name_List?.map((i) => {
    return {
      id: i.Pro_Ids,
      value: i.Pro_Names,
      label: `${i.Pro_Names}`,
    };
  });;
  const companyNameListDropDown = proTaskList?.Company_List?.map((i) => {
    return {
      id: i.Company_Id,
      value: i.Company_Name,
      label: `${i.Company_Name}`,
    };
  });
  useEffect(() => {
    handleListingSavedSearch();
    // setToDate(moment().format().slice(0, 10));
    // setFromDate(moment().format().slice(0, 10))
  }, []);

  useEffect(() => {
    fetchProTaskListFromApi(proId, reqFromDate, reqToDate);
  }, [proId, reqFromDate, reqToDate])

  // useEffect(() => {
  //   fetchProTaskFilterFromApi();
  // }, [approval, proName, companyName, reqFromDate, reqToDate])

  // const fetchProTaskFilterFromApi = async () => {
  //   const __token = localStorage.getItem("token");
  //   try {
  //     const url = `https://flyingcolour.online/fcapp/docprocessing_api/getProTaskDetails/?task_Status=${approval}&Pro_Ids=${proId}&Company_Id=${companyName}&requisition_Date=${reqFromDate}`
  //     const headers = {
  //       Authorization: `Token ${__token}`,
  //       "Content-Type": "application/json",
  //     };
  //     const res = await axios.get(url, { headers });

  //     setFilterListDropdown(res?.data?.results);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const finalValSchedule = apiData?.Pro_Task_General_Schedule?.map((i) => {
    return {
      headerName: i.Pro_Names,
      field: i.Pro_Names,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY:'auto'},
      width:300,
      cellRenderer: (params) => {
       return(
        <>
        { i?.Task_Detail?.map((val) => {
          return(
            <div>
              <p style={{margin:0, whiteSpace:'unset'}}><span>Task Name:</span> {val?.Task_Name}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span>Task Description:</span> {val?.Task_Descr}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span>Task Days: </span> {val?.Task_Days}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span>Task Time Frame:</span>({val?.Task_Start_Date} - {val?.Task_End_Date})</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span>Authority:</span>{val?.Authority}</p>
            </div>
          )
        })}
        </>
       )
      }
    };
  });
  const columnDefsSchedule = finalValSchedule || [];

  const finalVal = proTaskList?.Pro_Task_List?.map((i) => {
    return {
      headerName: i.Pro_Names,
      field: i.Pro_Names,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
      width:300,
      cellRenderer: (params) => {
       return(
        <>
        { i?.Task_Detail?.map((val, index) => {
          return(
            <div>
              <p><span style={{fontWeight: "bold"}}>Sr. No: {index + 1 } </span></p>
              <p style={{margin:0, whiteSpace:'unset'}}>{val?.Company_Name} -  {val?.Main_Task_Name} - {val?.Sub_Task_Name}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span style={{fontWeight: "bold"}}>Order Name:</span> {val?.Order_Name}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span style={{fontWeight: "bold"}}>Requisition Id:</span> {val?.Requisition_Ids}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span style={{fontWeight: "bold"}}>Typiest Name: </span> {val?.Typist_Name}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span style={{fontWeight: "bold"}}>Typist Status:</span>{val?.Typist_Status}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span style={{fontWeight: "bold"}}>PRO Head Status:</span>{val?.PROHead_Status}</p>
              <p style={{margin:0, whiteSpace:'unset'}}><span style={{fontWeight: "bold"}}>Account Status:</span>{val?.Account_Status}</p>
              <br />
            </div>
          )
        })}
        </>
       )
      }
    };
  });
  const columnDefs = finalVal || [];

  const columnDefsGeneralSchedule = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        textAlign: "left",
      },
      width: 50,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Actions",
      field: "Inquiry_Id",
      filter: false,
      // checkboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      pinned: "left",
      cellRenderer: (params) => {
        let status = params?.data?.status;
        return (
          <>
            {status === "Completed" ? null : status === "Not Started" ? (
              <CustomIconButton
                onClick={() => {
                  setOperationInfo(params.data);
                  setModalValue("Add/Edit General Schedule");
                  setOpenModal(true);
                  setIsEdit(true);
                }}
                title={"Edit"}
                width={"auto"}
                className="btnActionAlignment"
                icon={
                  <>
                    <FaEdit /> Edit
                  </>
                }
              />
            ) : status === "Under Process" || status === "Rescheduled" ? (
              <>
                <CustomIconButton
                  onClick={() => {
                    setOperationInfo(params.data);
                    handleModalTableOpen();
                    setButtonText("Reschedule Schedule Form");
                    // setModalValue("Reschedule");
                    // setOpenModal(true);
                    // setIsEdit(false);
                  }}
                  title={"Edit"}
                  width={"auto"}
                  className="btnActionAlignment"
                  icon={
                    <>
                      <ScheduleIcon style={{ fontSize: 16 }} /> Reschedule
                    </>
                  }
                />
                <CustomIconButton
                  onClick={() => {
                    setOperationInfo(params.data);
                    setModalValue("Complete Schedule");
                    setOpenModal(true);
                    setIsEdit(true);
                  }}
                  title={"Edit"}
                  width={"auto"}
                  className="btnActionAlignment"
                  icon={
                    <>
                      <TaskAltIcon style={{ fontSize: 16 }} /> Complete
                    </>
                  }
                />
              </>
            ) : (
              <>
                <CustomIconButton
                  onClick={() => {
                    setOperationInfo(params.data);
                    setModalValue("Add/Edit General Schedule");
                    setOpenModal(true);
                    setIsEdit(true);
                  }}
                  title={"Edit"}
                  width={"auto"}
                  className="btnActionAlignment"
                  icon={
                    <>
                      <FaEdit /> Edit
                    </>
                  }
                />
                <CustomIconButton
                  onClick={() => {
                    setOperationInfo(params.data);
                    handleModalTableOpen();
                    setButtonText("Reschedule Schedule Form");
                    // setModalValue("Reschedule");
                    // setOpenModal(true);
                    // setIsEdit(false);
                  }}
                  title={"Edit"}
                  width={"auto"}
                  className="btnActionAlignment"
                  icon={
                    <>
                      <ScheduleIcon style={{ fontSize: 16 }} /> Reschedule
                    </>
                  }
                />
                <CustomIconButton
                  onClick={() => {
                    setOperationInfo(params.data);
                    setModalValue("Complete Schedule");
                    setOpenModal(true);
                    setIsEdit(true);
                  }}
                  title={"Edit"}
                  width={"auto"}
                  className="btnActionAlignment"
                  icon={
                    <>
                      <TaskAltIcon style={{ fontSize: 16 }} /> Complete
                    </>
                  }
                />
              </>
            )}
          </>
        );
      },
    },
    {
      headerName: "General Schedule Id",
      field: "general_schedule_id",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
    },
    {
      headerName: "General Task Name",
      field: "general_task_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
    },

    {
      headerName: "Task Description",
      field: "task_description",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        overflowY: "auto",
      },
      width: 400,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params?.data?.task_description }}
          />
        );
      },
    },
    {
      headerName: "PRO Name",
      field: "pro_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "PRO Start Date",
      field: "pro_task_start_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agDateColumnFilter",
      width: 150,
      cellRenderer: (params) => {
        return (
            <>
                {moment(params?.data?.pro_task_start_date).format("DD-MM-YYYY")}
            </>
        );
    },
    },
    {
      headerName: "PRO End Date",
      field: "pro_task_end_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agDateColumnFilter",
      width: 150,
      cellRenderer: (params) => {
        return (
            <>
                {moment(params?.data?.pro_task_end_date).format("DD-MM-YYYY")}
            </>
        );
    },
    },
    {
      headerName: "Schedule Assigned Date",
      field: "schedule_assigned_date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agDateColumnFilter",
      width: 150,
      cellRenderer: (params) => {
        return (
            <>
                {moment(params?.data?.schedule_assigned_date).format("DD-MM-YYYY H:mm:ss")}
            </>
        );
    },
    },
    {
      headerName: "Requested By",
      field: "requested_by",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Status",
      field: "status",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Authority",
      field: "authority",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Created By",
      field: "Created_By",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Updated By",
      field: "Updated_By",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Updated Date",
      field: "Updated_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      //filter: true,
      // enable floating filters by default
     // floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      flex:1,
      autoHeight: true
    };
  }, []);


  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#eb6e12';
  box-shadow: inset '0px 0px 0px 7px #eb6e12';
  margin-top: 10%;
  `;
  

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
         
            <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
              <Form.Label
                column
                sm="4"
                style={{ fontWeight: "700", fontSize: 12 }}
              >
                Requisition Date
              </Form.Label>
              <Col sm="8" style={{ padding: "0 12px 0 0" }}>
                <div className="fc-dropdown-container">
                  <DateRangePickerComp
                    custom={true}
                    fieldHandleChange={(value, picker) => {
                      setReqFromDate(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                      setReqToDate(moment(picker.endDate).format("YYYY-MM-DD"));
                      setStart_Date(
                        moment(picker.startDate).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </div>
              </Col>
            </Form.Group>
          </Grid>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
          <CustomDropdown
              label="PRO Name"
              fieldOptions={proNameListDropDown}
              fieldValue={proName}
              fieldHandleChange={(value) => {
                setProName(value.value);
                setProId(value.id);
              }}
            />
          </Grid>
          <Grid item sm={12} md={3} style={{ paddingRight: "1rem" }}>
            {/* <CustomDropdown
              label="Company Name"
              fieldOptions={companyNameListDropDown}
              fieldValue={companyName}
              fieldHandleChange={(value) => {
                setCompanyName(value.id);
              }}
            /> */}
          </Grid>
        </Grid>
      </div>
     
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={proTaskList?.Pro_Task_List?.length}
      />
      <div
        style={{ height: "800px", width: "100%" }}
        className="ag-theme-alpine"
      >
         {loading ? (
            <>
              <div >
                <ClockLoader
                  css={override}
                  size={50}
                  color={"#eb6e12"}
                  loading={loading} />
              </div>
            </>
          ) : (
           <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
           rowData={[{}]}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 700}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
          )}
      </div>
      {!loading && (
        <>
          <div
        style={{ height: "100%", width: "100%", marginBottom: 10 }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={proTaskList?.General_Schedule_List}
          columnDefs={columnDefsGeneralSchedule}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
        </>
      )}
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modalValue === "Add/Edit General Schedule" ? (
            <GeneralScheduleForm setOpenModal={setOpenModal} isEdit={isEdit} />
          ) : modalValue === "Complete Schedule" ? (
            <CompleteScheduleForm setOpenModal={setOpenModal} />
          ) : null}
        </>
      </Modal>
    </div>
  );
};

export default PROTaskListingTable;
