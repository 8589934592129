import { useEffect, useRef, useState, useContext } from 'react'
import { DateRangePicker } from 'react-date-range'
import "./cutomDateRangeStyle.css";
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import { DataContext } from "../../provider/context";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import moment from "moment";
import { enGB, eo, ru, pt } from "date-fns/locale";
import DateRangeIcon from '@mui/icons-material/DateRange';




const CustomDateRangePicker = () => {

  const locales = { enGB, eo, ru, pt };

  const {
    start_Date, setStart_Date, end_Date, setEnd_Date
  } = useContext(DataContext);
  var currentDate = new Date();
  // date state
  const [range, setRange] = useState([
    {
      startDate: "",
      endDate: "",
      key: 'selection'
    }
  ])
  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null)

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  // Get the current date
  var currentDate = new Date();

  // Get the current year
  var currentYear = currentDate.getFullYear();

  // Get the current month
  // Note: Months are zero-indexed, so we add 1 to get the actual month
  var currentMonth = currentDate.getMonth() + 1;

  // Convert the values to strings if needed
  var yearString = currentYear
  var monthString = currentMonth

  var yearString = currentDate.toLocaleString('en-US', { year: 'numeric' });

  // Get the current month as a string
  var monthString = currentDate.toLocaleString('en-US', { month: 'long' });

  
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false)
    }
  }

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false)
    }
  }
  const handleDeSelectDate = () => {

    setRange([{ startDate: "", endDate: "", key: "selection" }]);
    // props?.fieldHandleChange(null)
  }
  return (
    <div className="calendarWrap">
      <div>
        {/* <input
        value={range[0].startDate === "" || range[0].endDate === "" ? "Please select date range" :
          `${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
        readOnly
        className="inputBox"
        onClick={ () => setOpen(open => !open) }
      /> */}

        <button className='datePicker-display-date-btn'>
          <DateRangeIcon style={{ width: '18px', marginRight: '4px' }} />
          <span className="datePicker-display-date-btn" style={{ marginLeft: "7px", cursor: "pointer" }} onClick={() => setOpen(open => !open)}>
            {range[0].startDate === "" || range[0].endDate === "" ? "Please select date range" :
              `${format(range[0].startDate, "dd-MM-yyyy")} to ${format(range[0].endDate, "dd-MM-yyyy")}`}
            <span style={{ marginLeft: "15px", marginRight: "10px", cursor: "pointer" }} onClick={() => handleDeSelectDate()}>X</span>
            {/* <button  onClick={ () => handleDeSelectDate() }>X</button> */}
          </span>

        </button>


      </div>
      <div ref={refOne} >
        {open
          &&
          <DateRangePicker
            onChange={item => {
              console.log(item.selection)
              setStart_Date(moment(item.selection.startDate).format("YYYY-MM-DD"))
              setEnd_Date(moment(item.selection.endDate).format("YYYY-MM-DD"))
              setRange([item.selection])
            }
            }
            locale={enGB}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
            rangeColors={["#eb6e12"]}
            // color={"#eb6e12"}
            showMonthAndYearPickers={true}
            preventSnapRefocus={true}
            startDatePlaceholder={"Start Date"}
            endDatePlaceholder={"End Date"}
            scroll={{ enabled: true }}
            focusSelectedMonth={true}
            shouldCloseOnSelect={false}
          />
        }

      </div>
    </div>
  )
}

export default CustomDateRangePicker