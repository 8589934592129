import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import {
    DataContext,
    SupportDataContext,
  } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import {
    getTotalLength,
    handleDefaultFilters,
    handleFilters,
  } from "../../../../utils/Filters";
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const FreezedOrderNoFutureAlertListing = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    getFreezesOrderNoFutureAlertsListingDataApi,
    ScrollTop,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setSelected,
      handleModalOpen,
      setEditModalPopper,
      setViewFollowUp,
      handleChangeTab
    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
  
    useEffect(() => {
        getFreezesOrderNoFutureAlertsListingDataApi(true);
      handleListingSavedSearch();
    }, []);
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue.split(" ");
        if (dateAsString == null) {
          return -1;
        }
        if (dateAsString == "") {
          return -1;
        }
        const dateParts = dateAsString[0].split("-");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };

    const actionBtn = (value) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
             <CustomIconButton
            width={"auto"}
            icon={"View Follow-up"}
            onClick={() => {
              setOperationInfo(value.data);
              handleModalTableOpen();
              setButtonText("Add / View Follow-up");
              handleChangeTab(0);
              ScrollTop();
              }}
            />
          </div>
        </>
      );
    };
  
    const columnDefs = [
        {
          headerName: "S #",
          field: "Serial_Number",
         // cellRenderer: "agGroupCellRenderer",
         // checkboxSelection: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff"},
          minWidth: 40,
          pinned: "left",
          filter:false
        },
        {
          headerName: "Action",
          field: "action",
          filter: false,
          minWidth: 170,
          pinned: "left",
          cellStyle: { border: "1px solid #fff", paddingTop: 0 },
          cellRenderer: actionBtn,
        },
        {
          headerName: "Order No",
          field: "Order_No",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
          pinned: "left",
        },
        {
          headerName: "Order Name",
          field: "Order_Name",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff",  overflowY:'auto'},
          width: 150,
          pinned: "left",
        },
        {
          headerName: "Order Type",
          field: "Type_Of_Order",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
          pinned: "left",
        },
        {
          headerName: "Order Status",
          field: "Order_Status",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
          pinned: "left",
        },
        {
          headerName: "Company Name",
          field: "Company_Name",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY:'auto'},
          width: 150,
          pinned: "left",
        },
        {
          headerName: "Point of Contact",
          field: "Point_of_Contact",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY:'auto'},
          width: 300,
        },
        {
          headerName: "Last Followup",
          field: "Last_Followups",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff",  overflowY:'auto'},
          width: 200,
          cellRenderer: (params) => {
            params = params?.data?.Last_Followups;
            return (
              <p
                style={{
                  padding: "0 5px",
                  margin: "0 2rem 1rem 0",
                }}
                dangerouslySetInnerHTML={{ __html: params }}
              />
            );
          },
        },
        {
          headerName: "Order Creation Date",
          field: "Order_Creation_Date",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          filter: "agSetColumnFilter",
          width: 150,
        },
        {
          headerName: "Order Assign Date",
          field: "Company_Name",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          filter: "agSetColumnFilter",
          width: 200,
        },
        {
          headerName: "Order Start Date",
          field: "Order_Start_Date",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Expected Order Completion Date",
          field: "Order_Completion_Date",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 200,
        },
        {
          headerName: "Last Requisition",
          field: "Last_Requisition",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 200,
          cellRenderer: (params) => {
            params = params?.data?.Last_Requisition;
            return (
              <p
                style={{
                  padding: "0 5px",
                  margin: "0 2rem 1rem 0",
                }}
                dangerouslySetInnerHTML={{ __html: params }}
              />
            );
          },
        },
       
        {
          headerName: "Tag Name",
          field: "Tag_Names",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 250,
          cellRenderer: (params) => {
            params = params?.data?.Tag_Names;
            return (
              <p
                style={{
                  padding: "0 5px",
                  margin: "0 2rem 1rem 0",
                }}
                dangerouslySetInnerHTML={{ __html: params }}
              />
            );
          },
        },
        {
          headerName: "Typist Name",
          field: "Typist_Name",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Support Name",
          field: "Support_Emp_Name",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Support Status",
          field: "Support_Status",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Accounts Status",
          field: "Accounts_Status",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Freez Order Status",
          field: "Freeze_Approval",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
          cellRenderer:(params) => {
            return(
              <>
              {params?.data?.Freeze_Approval !== "No Data" ? (
                <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
              ): params?.data?.Freeze_Approval}
              </>
            )
          }
        },
        {
          headerName: "Complete Order Status",
          field: "Complete_Order_Status_By_Admin",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
      //   {
      //     headerName: "License Issue/Expiry Date",
      //     field: "License_Expiry_Date",
      //     cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      //     type: ["dateColumn", "nonEditableColumn"],
      //     filterParams: filterParams,
      //     cellRenderer: (params) => {
      //       return params.data.License_Expiry_Date ? (
      //         <>{moment(params?.data?.License_Expiry_Date).format("DD-MM-YYYY")}</>
      //       ) : (
      //         <></>
      //       );
      //     },
      //     width: 200,
      //   },
        {
          headerName: "Work in Progress",
          field: "Work_in_Progress",
          filter: 'agTextColumnFilter',
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff",  overflowY:'auto'},
          width: 150,
          cellRenderer: (params) => {
              params = params?.data?.Work_in_Progress;
              return (
                <p
                  style={{
                    padding: "0 5px",
                    margin: "0 2rem 1rem 0",
                  }}
                  dangerouslySetInnerHTML={{ __html: params }}
                />
              );
            },
        },
        {
          headerName: "Sub Activities",
          field: "Sub_Activities",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "State",
          field: "State",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Formation",
          field: "Formation",
          cellRenderer: "agGroupCellRenderer",
          enableRowGroup: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
          cellRenderer: (params) => {
              params = params?.data?.Formation;
              return (
                <p
                  style={{
                    padding: "0 5px",
                    margin: "0 2rem 1rem 0",
                  }}
                  dangerouslySetInnerHTML={{ __html: params }}
                />
              );
            },
        },
       
        {
          headerName: "Advisor Name",
          field: "Advisor_Name",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Trade Names",
          field: "Trade_Names",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
        {
          headerName: "Followup Flag",
          field: "Missing_Flag",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
        },
      ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = cellValue.split("/");
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      };
    }, []);
  
    const onRowSelected = (event) => {
      const rowData = event.api.getSelectedNodes()[0]?.data;
      setSelected([rowData]);
    };
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    const handleSearch = (searchName, value, searchKey) => {
      handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
    };
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
        {fitlerBadge ? (
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                marginTop: "30px",
                paddingBottom: "15px",
              }}
            >
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={apiData}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: "Times New Roman",
                }}
              >
      
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const keyValue = val.Search_Filter?.split("{")[2]
                      ?.split(":")[1]
                      ?.replaceAll('"', "")
                      ?.split(",")[0]
                      .replace("[", "")
                      .replace("]", "");
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLength(
                          apiData,
                          val.Search_Name,
                          valueKey
                        )}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(
                            gridRef,
                            setDefaultFilterValue,
                            val.Search_Name,
                            keyValue?.trim(),
                            val.Search_Filter
                          );
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>
          </>
        ) : null}
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={apiData?.length}
        />
       <span style={{color: "red", fontSize: "13px", marginLeft: "10px"}}>Drag and drop the 'Column Name' to the drag panel and view grouped wise data</span>

        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={apiData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 90}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
            groupDisplayType="groupRows"
            // groupHideOpenParents={true}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            suppressRowGroupHidesColumns={true}
            rowGroupPanelShow="always"
          ></AgGridReact>
        </div>
        <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default FreezedOrderNoFutureAlertListing;
  