import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import { labelStyle, radioStyle } from "../../../../../utils/tableData";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
// import PendingRequistionListing from "./PendingRequistionListing";
import { VpnLock } from "@material-ui/icons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    padding: "25px",
    height: "auto",
    paddingTop: "0px",
    minHeight: "650px",
    maxHeight: "650px",
    overflow: 'auto',
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        maxHeight: "760px",
    },
};
const getStyles = (errors, fieldName, height) => {

    if (height && !getIn(errors, fieldName)) {
        return { height: height }
    }

    if (getIn(errors, fieldName) && height) {
        return {
            border: "1px solid red",
            height: height
        };
    } else if (getIn(errors, fieldName)) {
        return {
            border: "1px solid red"
        };
    }
};

const ReIssueRequisitionForm = ({ classes }) => {

    const {
        handleModaleClose,
        setLoading,
        loading,
        requistionListing,
        setMessage,
        message,
        setIsOpen,
        setSeverity,
        editModalPopper,
        viewRequistion,
        operationInfo,
        getOrderSOWDataAPI, OrderSOWData,


    } = useContext(SupportDataContext);
    const factory_id = localStorage.getItem("factory_id");
    const [mainTask, setMainTask] = useState(null);
    const [zohoBalance, setZohoBalance] = useState(null);
    const [pendingReqListing, setPendingReqListing] = useState(null);
    const [enableReinitialize, setEnablReinitializei] = useState(false);
    const [autoReqId, setAutoReqId] = useState();
    const [to_date, setTo_date] = useState('')
    const [from_date, setFrom_date] = useState('')


    const requistionPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const user_name = localStorage.getItem("Employee_First_Name");
        setLoading(true);
        const url = `https://flyingcolour.online/fcapp/support_api/reschedulereassignrequisition/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };

        const formData = new FormData();
        formData.append("factory_id", factory_id);
        formData.append("emp_id", Number(emp_id));
        formData.append("user_type", user_type);
        formData.append("user_name", user_name);
        formData.append("order_no", operationInfo?.Order_No || viewRequistion?.order_id);
        formData.append("requisition_no", viewRequistion?.requisitionNos || viewRequistion?.requisition_auto_id);
        formData.append("auto_requisition_no", autoReqId);
        formData.append("typist_id", viewRequistion?.typist_Id || Number(viewRequistion?.req_typist_id));
        formData.append("requisition_amount", viewRequistion?.req_amount || viewRequistion?.req_amt);
        formData.append("start_date", values?.start_date);
        formData.append("due_date", values?.due_date);
        formData.append("requisition_remarks", values?.requisition_remarks);

        if (editModalPopper) {

            axios.put(url, formData, { headers: headers }).then((data) => {
                setLoading(false);
                handleQuotationPromise(data);
            })
        } else {
            axios.post(url, formData, { headers: headers }).then((data) => {
                setLoading(false);
                handleQuotationPromise(data);
            })
        }

    }

    // const zohoBalanceAPI = async () => {
    //     const __token = localStorage.getItem("token");
    //     const user_type = localStorage.getItem("user_type");
    //     const emp_id = localStorage.getItem("emp_id");

    //     //setLoading(true);
    //     try {
    //         const url = `https://flyingcolour.online/fcapp/support_api/zohobalancedetailsview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.Company_Id}`;
    //         const headers = {
    //             Authorization: `Token ${__token}`,
    //             "Content-Type": "application/json",
    //         };
    //         const res = await axios.get(url, { headers });
    //         zohoBalanceRef.current = res?.data?.results?.availableZoho_Balance;
    //         setEnablReinitializei(true)
    //         let value = '';
    //         if (res?.data?.results?.availableZoho_Balance > 0) {
    //             value = `${res?.data?.results?.availableZoho_Balance}  - POSITIVE`
    //             setZohoBalance(value);
    //         } else if (res?.data?.results?.availableZoho_Balance < 0) {
    //             value = `${res?.data?.results?.availableZoho_Balance}  - NEGATIVE`
    //             setZohoBalance(value);
    //         } else {
    //             value = `Company doesn't exist on  Zoho`
    //             setZohoBalance(value);
    //         }
    //         setTimeout(() => {
    //             setEnablReinitializei(false);
    //             setLoading(false)
    //         }, 500)
    //         return value;

    //         //setLoading(false);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }

    const PendingRequistionListingAPi = async () => {
        const __token = localStorage.getItem("token");
        const user_type = localStorage.getItem("user_type");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");

        //setLoading(true);
        try {
            const url = `https://flyingcolour.online/fcapp/support_api/orderwisependingrequisitionlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo?.Order_No}`;
            const headers = {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setEnablReinitializei(true)
            setPendingReqListing(res?.data?.results);

            setTimeout(() => {
                setEnablReinitializei(false)
            }, 500)

            //setLoading(false);
        } catch (err) {
            console.error(err);
        }
    }

    const handleAutoRequistion = async (setFieldValue, i, values) => {
        const __token = localStorage.getItem("token");
        const user_type = localStorage.getItem("user_type");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");

        //setLoading(true);
        try {
            const url = `https://flyingcolour.online/fcapp/support_api/generaterequisitionno/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setAutoReqId(res?.data?.results?.Random_Requisition_No?.[0]?.Auto_Requisition_No);
            // setFieldValue(`mainTaskArray[${i}].requistionId`, res?.data?.results?.Random_Requisition_No?.[0]?.Auto_Requisition_No);


            //setLoading(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        handleAutoRequistion();
        getOrderSOWDataAPI();
    }, [])
    const handleQuotationPromise = (data) => {
        if (data?.data?.data?.message) {
            setMessage(data?.data?.data?.message);
            setSeverity(500);
        }
        if (data?.data?.data?.msg) {
            setMessage(data?.data?.data?.msg);
            setSeverity(500);
        }
        if (data?.data?.data?.detail) {
            setMessage(data?.data?.data?.detail);
            setSeverity(500);
        } else {
            setMessage(data?.data?.data?.msg);
            setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        getOrderSOWDataAPI();
        handleModaleClose();
    }
    const filterWeekends = (date) => {
        return date.getDay() !== 0;
    };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={{
                    orderId: operationInfo?.Order_No || viewRequistion?.order_id,
                    start_date: "",
                    due_date: "",
                    requisition_remarks: "",
                    main_task_days: Number(viewRequistion?.main_task_days),
                }}
                onSubmit={async (data) => {
                    setLoading(true)
                    requistionPostAPI(data)
                }}
                validate={(values) => {
                    let errors = {};

                    if (values.start_date === "") {
                        errors.start_date = "Start date required";
                    }
                    if (values.due_date === "") {
                        errors.due_date = "Due date required!!";
                    }

                    return errors;
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched,
                }) => {
                    return (
                        <form onSubmit={(e) => { e.preventDefault() }}>
                            <Box sx={style}>
                                <div>
                                    <div style={{ height: "36px" }}>
                                        <IconButton
                                            style={{ float: "right" }}
                                            className={classes.quotationModalHeaderIconCancle}
                                            onClick={() => {
                                                handleModaleClose();
                                            }}
                                        >
                                            <CloseIcon
                                                className={classes.quotationModalHeaderIconCancleIcon}
                                            />
                                        </IconButton>
                                    </div>
                                    <div style={{ height: "600px", overflowX: "hidden", padding: "0" }}>
                                        <Grid container spacing={2} columns={12}>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    marginBottom: "-30px",
                                                    paddingBottom: "10px",
                                                    marginTop: "-45px",
                                                    zIndex: 10
                                                }}
                                            >
                                                <br />
                                                <div className={classes.quotationModalHeadingContainer}>
                                                    <span className={classes.quotationModalHeader}>
                                                        Re-Issue / Reschedule Requisition
                                                    </span>
                                                </div>
                                                <br />
                                                <div className={classes.quotationModalFlexDivContaner}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                            className={classes.quotationInnerGridRight}
                                                        >
                                                            <InputText
                                                                label="Order No"
                                                                fieldName="orderId"
                                                                fieldValue={values?.orderId}
                                                                fieldHandleChange={(event) => { }
                                                                    //handleChangeInput(item?.id, event)
                                                                }
                                                                disableField={true}
                                                                disabled={true}
                                                                classvalue={"textBold"}
                                                            />

                                                            <InputText
                                                                label="Requistion No"
                                                                fieldName="requisitionNos"
                                                                fieldValue={viewRequistion?.requisitionNos || viewRequistion?.requisition_auto_id}
                                                                fieldHandleChange={(event) => { }
                                                                    //handleChangeInput(item?.id, event)
                                                                }
                                                                disableField={true}
                                                                disabled={true}
                                                                classvalue={"textBold"}
                                                            />

                                                            <InputText
                                                                label="Requistion Amount"
                                                                fieldName="task_amount"
                                                                fieldValue={viewRequistion?.req_amount || viewRequistion?.Requisition_Amount}
                                                                fieldHandleChange={(event) => { }
                                                                    //handleChangeInput(item?.id, event)
                                                                }
                                                                disableField={true}
                                                                disabled={true}
                                                                classvalue={"textBold"}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                            className={classes.quotationInnerGridRight}
                                                        >
                                                            <Form.Group as={Row} className="mb-1">
                                                                <Form.Label column sm="5" className="fc-textarea-label">
                                                                    Start Date  <span style={{ color: "red" }}>*</span>
                                                                </Form.Label>
                                                                <Col sm="7" style={{ padding: '0 14px 0 0' }} >
                                                                    <div
                                                                        style={getStyles(errors, values?.start_date)}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                border: "0.1px solid #d3d3d3",
                                                                            }}
                                                                        >
                                                                            <DatePicker
                                                                                label="Select Start Date"
                                                                                // value={from_date}
                                                                                selected={from_date}
                                                                                minDate={addDays(new Date(), 0)}
                                                                                maxDate={addDays(new Date(), 0)}
                                                                                onChange={(value) => {
                                                                                    setFrom_date(value);
                                                                                    setFieldValue(`start_date`,
                                                                                        moment(value).format("YYYY-MM-DD HH:mm:ss")
                                                                                    )
                                                                                }
                                                                                }
                                                                                dateFormat="dd-MM-yyyy"
                                                                                filterDate={filterWeekends}
                                                                                onChangeRaw={handleDateChangeRaw}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} className="mb-1" >
                                                                <Form.Label column sm="5" className="fc-textarea-label">
                                                                    Due Date <span style={{ color: "red" }}>*</span>
                                                                </Form.Label>
                                                                <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                                                    <div style={getStyles(errors, values?.due_date)}  >
                                                                        <div style={{ border: "0.1px solid #d3d3d3" }}  >
                                                                            <DatePicker
                                                                                label="Select End Date"
                                                                                // value={to_date}
                                                                                selected={to_date}
                                                                                minDate={addDays(new Date(), 0)}
                                                                                maxDate={addDays(new Date(), values?.main_task_days)}
                                                                                onChange={(value) => {
                                                                                    const Difference_In_Time = new Date(value).getTime() - new Date(values?.start_date).getTime();
                                                                                    const diffDays = Difference_In_Time / (1000 * 60 * 60 * 24);
                                                                                    setTo_date(value);
                                                                                    setFieldValue(`due_date`,
                                                                                        moment(value).format("YYYY-MM-DD HH:mm:ss"));
                                                                                    setFieldValue(
                                                                                        `duedate `,
                                                                                        Math.trunc(diffDays)
                                                                                    );
                                                                                }
                                                                                }
                                                                                dateFormat="dd-MM-yyyy"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>

                                                            <CustomTextArea
                                                                label="Requisition Remarks"
                                                                fieldName="requisition_remarks"
                                                                fieldValue={values?.requisition_remarks}
                                                                fieldHandleChange={(e) => {
                                                                    e.preventDefault();
                                                                    const { value } = e.target;
                                                                    setFieldValue(
                                                                        `requisition_remarks`,
                                                                        value
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <br />
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <br />
                                        <div className={classes.quotationButtonDivModal}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                size="small"
                                                style={{ backgroundColor: "#eb6e12" }}
                                                onClick={handleSubmit}
                                            // disabled={OrderSOWData?.error_msg === "Company doesn't exist on Zoho" ? true : false}
                                            >
                                                {loading ? "loading" : "Submit"}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    handleModaleClose();
                                                    // handleHide()
                                                }}
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    color: "#eb6e12",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                    {/* <PendingRequistionListing pendingReqListing={pendingReqListing} /> */}
                                </div>
                            </Box>

                        </form>
                    );
                }}
            </Formik>


        </>
    );
};

export default withStyles(ModalPopperFormStyles)(ReIssueRequisitionForm);
