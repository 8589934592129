import React, { useEffect, useState, useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "../../Dashboard/DatePicker";
import LeadsAdvancedFilter from "./LeadsAdvancedFilter";
import "../../CPListing/CPListing.css";
import { DataContext } from "../../../provider/context";

const LeadsFilterAdvancedMain = ({
  tableUpperButtonArr,
  classes,
  apiData,
  gridRef,
  tabValue,
  savedValue,
  setSavedValue,
  isMeetingProjection,
  defaultFilterValue,
  handleListingSavedSearchAPI,
  handleFilter
}) => {
  const {setErrorAlert, setError} = useContext(DataContext);
  const [isDropDownClose, setIsDropdownClose] = useState(false);
  const [advancedFilterData, setAdvancedFilterData] = useState({});
  const [leadSourceFilterValues, setLeadSourceFilterValues] = useState([]);
  const [savedAdvancedFilter, setSavedAdvancedFilter] = useState([]);
  const [savedSearchName, setSaveSearchName] = useState("");
  const [listSavedSearch, setListSavedSearch] = useState([]);
  const [selectedSavedValue, setSelectedValue] = useState(defaultFilterValue || "Default");

  useEffect(() => {
    if(defaultFilterValue !== null){
      setSelectedValue(defaultFilterValue)
    } else {
      setSelectedValue('Default')
    }
  }, [defaultFilterValue])

  const handleDropDownClose = () => {
    setIsDropdownClose(!isDropDownClose);
    handleDropdownAPI();
    handleDropdownLeadSourceAPI();
  };

  const handleDropdownAPI = async () => {
    const __token = localStorage.getItem("token");
    await fetch(
      "http://flyingcolour.online/fcapp/api/add_inquiry/?factory_id=1&user_type=RECEPTIONIST&lead_source_name=Google",
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setAdvancedFilterData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDropdownLeadSourceAPI = async () => {
    const __token = localStorage.getItem("token");
    await fetch("https://flyingcolour.online/fcapp/api/dropdownleadfilters/", {
      method: "GET",
      headers: {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setLeadSourceFilterValues(data?.results);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleListingSavedSearch = async () => {
    setIsDropdownClose(false);
    let finalData = gridRef.current.api.getFilterModel();
    setSavedAdvancedFilter(finalData);
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const emp_id = localStorage.getItem("emp_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setListSavedSearch(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSaveBtn = async () => {
    if (savedSearchName.length <= 0 || Object.keys(savedAdvancedFilter)?.length <= 0) {
      return setErrorAlert(true), setError(`No filters selected!`)
    }
    const emp_id = localStorage.getItem('emp_id');
    let data = {
      company_id: 1,
      searchname: savedSearchName,
      searchUrl: window.location.href,
      searchfilters: JSON.stringify(savedAdvancedFilter),
      searchby: emp_id,
      tabname: `tabName_${tabValue}`,
    };
    const __token = localStorage.getItem("token");
    await fetch("https://flyingcolour.online/fcapp/api/usersearchquery/", {
      method: "POST",
      headers: {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setSaveSearchName("");
        handleListingSavedSearch();
        handleListingSavedSearchAPI();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDeleteSavedSearch = async (id) => {
    let text = window.confirm("Do you want to delete it?");
    if (!text) {
      return;
    }

    let data = {
      id: id,
    };
    const __token = localStorage.getItem("token");
    await fetch(`https://flyingcolour.online/fcapp/api/deletesearchquery/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        handleListingSavedSearch();
        handleListingSavedSearchAPI();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDefaultSavedSearch = () => {
    setSelectedValue("Default");
    setSavedValue(null);
    gridRef.current.api.setFilterModel(null);
  };
  
  return (
    <>
      {tableUpperButtonArr === undefined ? null : 
      tableUpperButtonArr?.map((item, i) => {
        if (item.buttonName === "Advanced Search") {
          return (
            <Dropdown
              className="listing-dropdown-align"
              autoClose={false}
              onToggle={(e) => handleDropDownClose(e)}
              show={isDropDownClose}
              drop="down"
            >
              <Dropdown.Toggle id="dropdown-basic">
                Advanced Filter
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  width: "830px",
                  fontSize: "12px",
                  marginLeft: "235px",
                  marginTop: "-30px",
                }}
              >
                <LeadsAdvancedFilter
                  setIsDropdownClose={setIsDropdownClose}
                  advancedFilterData={advancedFilterData}
                  apiData={apiData}
                  filterData={apiData}
                  //handleFilterData={handleFilterData}
                  setSavedAdvancedFilter={setSavedAdvancedFilter}
                  savedValue={savedValue}
                  leadSourceFilterValues={leadSourceFilterValues}
                  gridRef={gridRef}
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        } else if (item.buttonName === "Save Search") {
          return (
            <Dropdown
              className="listing-dropdown-align"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
              }}
              onClick={() => handleListingSavedSearch()}
            >
              <Dropdown.Toggle id="dropdown-basic" style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight:"800",
                color: "#008000",
              }}>Save Search</Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  width: "300px",
                  fontSize: "12px",
                  padding: "1rem",
                  marginLeft: "235px",
                  marginTop: "-30px",
                }}
              >
                <input
                  type="text"
                  value={savedSearchName}
                  onChange={(e) => setSaveSearchName(e.target.value)}
                />
                <button
                  type="submit"
                  onClick={handleSaveBtn}
                  style={{
                    marginLeft: "0.5rem",
                    border: "none",
                    background: "#eb6e12",
                    color: "#fff",
                    padding: "5px 8px",
                  }}
                >
                  Save Search
                </button>

                <p style={{ fontSize: "16px", marginTop: "1rem" }}>
                  Saved Search
                </p>
                <p
                  onClick={(e) => handleDefaultSavedSearch()}
                  className={`${
                    selectedSavedValue === "Default"
                      ? "selectedSavedValueClass"
                      : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  Default
                </p>
                {(listSavedSearch || []).map((val) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        onClick={(e) => {
                          const keyValue = val.Search_Filter?.split("{")[2]?.split(":")[1]?.replaceAll('"', "")?.split(",")[0].replace("[", "").replace("]", "")
                          handleFilter(val.Search_Name,keyValue?.trim(), val.Search_Filter)
                        }}
                        className={`${
                          selectedSavedValue === val.Search_Name
                            ? "selectedSavedValueClass"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        {val.Search_Name}
                      </p>
                      <p
                        onClick={(e) => handleDeleteSavedSearch(val.Search_Id)}
                        style={{
                          display: "flex",
                          padding: "0 5px",
                          borderRadius: "50%",
                          background: "red",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        x
                      </p>
                    </div>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          );
        } else if (item.buttonName === "SSS") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
              }}
            >
              <p style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                margin:'0',
                fontWeight: "800",
                width:'210px',
                fontFamily:'Times New Roman'
              }}>Selected Search Criteria: &nbsp;</p>
              <div style={{ fontWeight: "800", fontFamily:'Times New Roman' }}> {selectedSavedValue}</div>
            </div>
          );
          // } else if(item.buttonName === "View Description"){
          //   return(
          //     <span onClick={handleViewDescModal} style={{display:'flex', alignItems:"center", fontSize:"16px", color:"#008000", marginLeft:"1rem", cursor:"pointer"}}>View Lead Description</span>
          //   )
        } else {
          return (
            <button className={classes.reassignListingTableTabButton} key={i}>
              {item.icon}
              <span className={classes.reassignListingTableTabButtonTitle}>
                {item.buttonName}
              </span>
            </button>
          );
        }
      })}

      {isMeetingProjection && (
        <div style={{ marginLeft: "20px" }}>
          <DatePicker />
        </div>
      )}
    </>
  );
};

export default LeadsFilterAdvancedMain;
