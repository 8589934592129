import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  CustomSelect,
  CustomMultiSelect,
} from "../../../../listing/listingForm/ListingForm";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import { CKRictTextEditer } from "../../../../listing/listingForm/RichTextEditer";
import "../../Styles/styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const GeneralScheduleForm = ({ classes, setOpenModal, isEdit }) => {
  const {
    handleModaleClose,
    setLoading,
    loading,
    followUp,
    setMessage,
    setSeverity,
    operationInfo,
    setIsOpen,
    getGeneralScheduleDataApi,
    generalScheduleData,
  } = useContext(SupportDataContext);
  const [generalScheduleDropdown, setGeneralScheduleDropdown] = useState(null);

  const GeneralTaskNameList = generalScheduleData?.Task_Detail?.map((item) => {
    const obj = {
      ...{
        id: item?.general_task_id,
        value: item.general_task_name,
        label: item.general_task_name,
      },
      ...item,
    };
    return obj;
  });

  const PRO_NameList = generalScheduleDropdown?.PRO_List?.map((item) => {
    const obj = { value: item.EmpId, label: item.Emp_Name };
    return obj;
  });

  const LocationList = generalScheduleDropdown?.Location_List?.map((item) => {
    const obj = { value: item.id, label: item.location_name };
    return obj;
  });

  const getGeneralScheduleDropdownDataApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem("user_type");
    const empId = localStorage.getItem("emp_id");
    try {
      let url = `https://flyingcolour.online/fcapp/pro_head/GeneralSchedule/?emp_id=${empId}&user_type=${user_type}`;

      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setGeneralScheduleDropdown(res?.data?.results);
      // setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getGeneralScheduleDropdownDataApi();
  }, []);

  const addGeneralTaskNamePOSTPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/pro_head/GeneralSchedule/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let body = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      general_task_id: values?.generalTaskId,
      general_task_name: values?.generalTaskName,
      task_description:  values?.taskDescription,
      pro_id:  values?.proId,
      pro_name:  values?.proName,
      no_of_days: values?.noOfDays,
      pro_task_start_date:  moment(values?.startDate)?.format('YYYY-MM-DD'),
      pro_task_end_date:  moment(values?.endDate)?.format('YYYY-MM-DD'),
      location: values?.location,
      schedule_assigned_date:  moment(values?.scheduledDate)?.format('YYYY-MM-DD'),
      authority: values?.authority,
      amount: values?.amount,
      requested_by: values?.requestedBy
    };

    if (isEdit) {
      body = {
        ...body,
        general_schedule_id: operationInfo?.general_schedule_id,
      };
      axios.put(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    } else {
      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      });
    }
  };

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getGeneralScheduleDataApi();
    setOpenModal(false);
  };

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        generalTaskId:  isEdit ? operationInfo?.general_task_id : "",
        generalTaskName: isEdit ? operationInfo?.general_task_name : "",
        taskDescription: isEdit ? operationInfo?.task_description : "",
        startDate: isEdit
          ? new Date(operationInfo?.pro_task_start_date)
          : new Date(),
        endDate: isEdit
          ? new Date(operationInfo?.pro_task_end_date)
          : new Date(),
        location: isEdit ? Number(operationInfo?.location) : "",
        proId: isEdit ? Number(operationInfo?.pro_id) : "",
        proName: isEdit ? operationInfo?.pro_name : "",
        noOfDays: isEdit ? Number(operationInfo?.no_of_days) : "",
        scheduledDate: isEdit
          ? new Date(operationInfo?.schedule_assigned_date)
          : new Date(),
        amount: isEdit ? operationInfo?.amount : "",
        authority: isEdit ? operationInfo?.authority : "",
        requestedBy: isEdit ? operationInfo?.requested_by : "",
      }}
      onSubmit={async (data) => {
        setLoading(true);
        addGeneralTaskNamePOSTPUTAPI(data);
      }}
      validate={(values) => {
        let errors = {};

        if (!values.generalTaskName) {
          errors.generalTaskName = "required";
        }

        if (!values.taskDescription) {
          errors.taskDescription = "required";
        }

        if (!values.proName) {
          errors.proName = "required";
        }
        if (!values.location) {
          errors.location = "required";
        }
        if (values.noOfDays < 0) {
          errors.noOfDays = "required";
        }
        if (!values.scheduledDate) {
          errors.scheduledDate = "required";
        }
        if (!values.startDate) {
          errors.startDate = "required";
        }
        if (!values.endDate) {
          errors.endDate = "required";
        }

        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div
                  style={{
                    padding: "0px 20px 20px 20px",
                  }}
                >
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10,
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          {isEdit ? "Edit " : "Add "} General Schedule Form
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                            className={classes.quotationInnerGridRight}
                          >
                            <CustomDropdown
                              label="General Task Name"
                              fieldOptions={GeneralTaskNameList}
                              fieldValue={values?.generalTaskName}
                              fieldHandleChange={(value) => {
                            
                                setFieldValue(`generalTaskName`, value.value);
                                setFieldValue(`taskDescription`, value.task_description);
                                setFieldValue(`startDate`, new Date(value?.pro_task_end_date));
                                setFieldValue(`endDate`, new Date(value?.pro_task_end_date));
                                setFieldValue(`location`, Number(value?.location));
                                setFieldValue(`proId`, value.pro_id);
                                setFieldValue(`proName`, value.pro_name);
                                setFieldValue(`noOfDays`, Number(value?.no_of_days));
                                setFieldValue(`scheduledDate`, new Date(value?.schedule_assigned_date));
                                setFieldValue(`amount`, value.amount);
                                setFieldValue(`authority`, value.authority);
                                  setFieldValue(`requestedBy`, value.requested_by);
                              }}
                              errorField={getStyles(
                                errors,
                                values?.generalTaskName
                              )}
                              requiredField={true}
                              isDisabled={isEdit}
                            />

                            <Form.Group as={Row} className="mb-1">
                              <Form.Label
                                column
                                sm="5"
                                className="fc-textarea-label"
                              >
                                Task Description
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{
                                  padding: "0 10px 5px 0",
                                }}
                              >
                                <div
                                  style={getStyles(
                                    errors,
                                    values?.taskDescription
                                  )}
                                >
                                  <CKRictTextEditer
                                    height={"200px"}
                                    data={values?.taskDescription}
                                    handleDataChange={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue(`taskDescription`, data);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Form.Group>

                            <CustomDropdown
                              label="PRO Name"
                              fieldOptions={PRO_NameList}
                              fieldValue={values?.proId}
                              fieldHandleChange={(value) => {
                                setFieldValue(`proName`, value.label);
                                setFieldValue(`proId`, value.value);
                              }}
                              errorField={getStyles(errors, values?.proId)}
                              requiredField={true}
                            />

<Form.Group as={Row} className="mb-1">
                              <Form.Label
                                column
                                sm="5"
                                className="fc-textarea-label"
                              >
                                Schedule Assigned Date
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{
                                  padding: "0 12px 0 0",
                                }}
                              >
                                <div
                                  style={{
                                    border: "0.1px solid #d3d3d3",
                                    width: "100%",
                                    marginBottom: 10,
                                  }}
                                >
                                  <div
                                    style={getStyles(
                                      errors,
                                      values?.scheduledDate
                                    )}
                                  >
                                    <DatePicker
                                      selected={values.scheduledDate}
                                      minDate={moment().toDate()}
                                      onChange={(date) => {
                                        setFieldValue(`scheduledDate`, date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="Scheduled Date"
                                      disabled={true}
                                      className="schedule-date-class"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>

                           
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className={classes.quotationInnerGridRight}
                          >
                            <Form.Group as={Row} className="mb-1">
                              <Form.Label
                                column
                                sm="5"
                                className="fc-textarea-label"
                              >
                                PRO Start Date
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{
                                  padding: "0 12px 0 0",
                                }}
                              >
                                <div
                                  style={{
                                    border: "0.1px solid #d3d3d3",
                                    width: "100%",
                                    marginBottom: 10,
                                    padding: "5px 0",
                                  }}
                                >
                                  <div
                                    style={getStyles(errors, values?.startDate)}
                                  >
                                    <DatePicker
                                      selected={values.startDate}
                                      minDate={moment().toDate()}
                                      onChange={(date) => {
                                        let maxDate = moment(values?.endDate)?.diff(moment(date), 'days');
                                        setFieldValue(`startDate`, date);
                                         setFieldValue(`noOfDays`, maxDate);
                                      }}
                                      fixedHeight
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="Start Date"
                                    />
                                  </div>
                                </div>

                             
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                              <Form.Label
                                column
                                sm="5"
                                className="fc-textarea-label"
                              >
                                PRO End Date
                              </Form.Label>
                              <Col
                                sm="7"
                                style={{
                                  padding: "0 12px 0 0",
                                }}
                              >
                                <div
                                  style={{
                                    border: "0.1px solid #d3d3d3",
                                    width: "100%",
                                    marginBottom: 10,
                                    padding: "5px 0",
                                  }}
                                >
                                  <div
                                    style={getStyles(errors, values?.endDate)}
                                  >
                                    <DatePicker
                                      selected={values.endDate}
                                      minDate={values.startDate}
                                      onChange={(date) => {
                                        let maxDate = moment(date)?.diff(moment(values?.startDate), 'days');
                                        setFieldValue(`endDate`, date);
                                        setFieldValue(`noOfDays`, maxDate);
                                      }}
                                      fixedHeight
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="End Date"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>

                            <InputText
                              label="No Of Days"
                              fieldName="noOfDays"
                              fieldValue={values?.noOfDays}
                              fieldHandleChange={(e) => {
                                let endDate = moment(values?.startDate)?.add(e.target.value, 'days');
                                setFieldValue(`noOfDays`, e.target.value);
                                setFieldValue(`endDate`,new Date(endDate));
                              }}
                              requiredField={true}
                              fieldType="number"
                            />
                            {values?.noOfDays < 0 && (
                                <p style={{color:'red', fontSize: 12}}>No. of days can't be less than 0.</p>
                            )}
                            <CustomDropdown
                              label="Location"
                              fieldOptions={LocationList}
                              fieldValue={values?.location}
                              fieldHandleChange={(value) => {
                                setFieldValue(`location`, value.value);
                              }}
                              errorField={getStyles(errors, values?.location)}
                              requiredField={true}
                            />
                           

                            <InputText
                              label="Authority (if any)"
                              fieldName="authority"
                              fieldValue={values?.authority}
                              fieldHandleChange={(e) => {
                                setFieldValue(`authority`, e.target.value);
                              }}
                            />

                            <InputText
                              label="Amount (AED if any)"
                              fieldName="amount"
                              fieldValue={values?.amount}
                              fieldHandleChange={(e) => {
                                if(e.target.value < 0){
                                  return ;
                                }
                                setFieldValue(`amount`, e.target.value);
                              }}
                              fieldType="number"
                            />

                            <InputText
                              label="Requested By"
                              fieldName="requestedBy"
                              fieldValue={values?.requestedBy}
                              fieldHandleChange={(e) => {
                                setFieldValue(`requestedBy`, e.target.value);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(GeneralScheduleForm);
