import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import { addDays, isBefore  } from "date-fns";
import CustomInput from "../../../../../commonComp/customInput/CustomInput";
import { labelStyle, radioStyle } from "../../../../../utils/tableData";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CustomSelect } from "../../../../listing/listingForm/ListingForm";
import { CKRictTextEditerMediamHeight } from "../../../../listing/listingForm/RichTextEditer";
import Radio from '@mui/material/Radio';
import CustomDatePicker from "../../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import RadioGroup from '@mui/material/RadioGroup';
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import PendingRequistionListing from "./PendingRequistionListing";
import { VpnLock } from "@material-ui/icons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LoaderClock from "../../../../lodar/LoaderClock";
import { css } from "@emotion/react"
import ClockLoader from 'react-spinners/ClockLoader'



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "#fff",
    border: "2px solid #eb6e12",
    boxShadow: 10,
    padding: "25px",
    height: "auto",
    paddingTop: "0px",
    minHeight: "550px",
    maxHeight: "650px",
    overflow: 'auto',
    "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
        maxHeight: "760px",
    },
};
const getStyles = (errors, fieldName, height) => {

    if (height && !getIn(errors, fieldName)) {
        return { height: height }
    }

    if (getIn(errors, fieldName) && height) {
        return {
            border: "1px solid red",
            height: height
        };
    } else if (getIn(errors, fieldName)) {
        return {
            border: "1px solid red"
        };
    }
};

const AddRequisitionModelForm = ({ classes }) => {

    const {
        handleModaleClose,
        setLoading,
        loading,
        requistionListing,
        setMessage,
        message,
        setIsOpen,
        setSeverity,
        editModalPopper,
        viewRequistion,
        operationInfo,
        getRequistionListingDataFromApi,
        getOrderSOWDataAPI, OrderSOWData,
        getEditSOWDataAPI, SOWEditData,
        // requistionListingDataAPi, pendingData

    } = useContext(SupportDataContext);
    const factory_id = localStorage.getItem("factory_id");
    const [mainTask, setMainTask] = useState(null);
    const [zohoPendingBalance, setZohoPendingBalance] = useState(null);
    const [pendingReqListing, setPendingReqListing] = useState(null);
    const [enableReinitialize, setEnablReinitializei] = useState(false);
    const [autoReqId, setAutoReqId] = useState();
    const [requistion_Type, setRequistion_Type] = useState('')
    const [to_date, setTo_date] = useState(editModalPopper ? new Date(new Date(moment(viewRequistion?.enddate)).toString()) : '')
    const [from_date, setFrom_date] = useState(editModalPopper ? new Date(new Date(moment(viewRequistion?.startdate)).toString()) : '')
    const zohoBalanceRef = useRef(null);
    const fileInput = useRef([]);
    const [pendingData, setPendingData] = useState(null);
    const [getMainTaskArr, setMainTaskArr] = useState([]);
    const [getRequisitionDate, setRequisitionDate] = useState(editModalPopper ? new Date(new Date(moment(viewRequistion?.req_urgent_date, "DD-MM-YYYYY")).toString()) : '')
    const [selectedOption, setSelectedOption] = useState(null);


    
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className, }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "#d11616",
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#d11616",
        },
    }));
   
    const getFreeZone = operationInfo?.Formation?.split("</br>")[0];

    const sow_type_dropdownForMultiple = pendingData?.Multiple_SOW_List?.map((item) => {
        const obj = {
            task_name: item.main_task_name, value: item.main_sow_task_name,
            label: item.main_sow_task_name, id: item?.main_task_id,
            days: item?.main_task_days, task_sequence: item?.main_task_sequence,
            work_type: item?.work_type_id,
            task_amount: item?.task_amount,
            task_remarks: item?.task_remarks,
            typistName: item?.Order_Typist_Id,
            work_type_name: item?.work_type,
            task_category: item?.task_category,
            task_type: item?.task_type,
            govt_department_name: item?.sow_govt_dept,
            special_instruction: item?.sow_special_instruction,
            Typist_Name: item?.Order_Typist_Name,

        };
        return obj;
    });

    const sow_type_dropdownForSingle = pendingData?.Normal_SOW_List?.map((item) => {
        const obj = {
            task_name: item.main_task_name, value: item.main_sow_task_name,
            label: item.main_sow_task_name, id: item?.main_task_id,
            days: item?.main_task_days, task_sequence: item?.main_task_sequence,
            work_type: item?.work_type_id,
            task_amount: item?.task_amount,
            task_remarks: item?.task_remarks,
            typistName: item?.Order_Typist_Id,
            work_type_name: item?.work_type,
            task_category: item?.task_category,
            task_type: item?.task_type,
            govt_department_name: item?.sow_govt_dept,
            special_instruction: item?.sow_special_instruction,
            Typist_Name: item?.Order_Typist_Name,
        };
        return obj;
    });

    const sOW_TypeMandatory = OrderSOWData?.SOWList_Details?.filter(item =>
        item.task_category === "Mandatory" &&  item.requisitionNos !== "" &&
        (item.support_status !== "Done" || item.proStatus !== "Done"
        || item.Typist_Status !== "Done"
        )
    );
const checkWorkDone = sOW_TypeMandatory?.map((val, i) => {

     if(val?.work_type === "Office Work" && val?.Typist_Status?.toLowerCase() !== "done")
    {
        return "true"
    }
    if(val?.work_type === "Pro Work" && val?.pro_head_status?.toLowerCase() !== "done")
    {
        return "true"
    }
    if(val?.is_self_assign_by_ro === "Yes" && val?.support_status?.toLowerCase() !== "done")
    {
        return "true"
    }
   else{
        return "false"
   }
});
const checkIsWorkDoneOrNot = checkWorkDone?.find(item => item === "false")

    const work_type_options = pendingData?.Work_Type_List?.map((item) => {
        const obj = { value: item.Value, label: item.Work_Type_Name };
        return obj;
    });

    const typist_name_options = pendingData?.Typiest_List?.map((item) => {
        const obj = { value: item.Emp_Id, label: item.Emp_Name, id: item?.Emp_Id, type: item?.Emp_Type };
        return obj;
    });

    const user_first_name = localStorage.getItem("Employee_First_Name");
    const user_last_name = localStorage.getItem("Employee_Last_Name");

    const requistionPostAPI = async (values) => {
        const user_type = localStorage.getItem('user_type');
        const emp_id = localStorage.getItem("emp_id");
        const __token = localStorage.getItem("token");
        const user_name = localStorage.getItem("Employee_First_Name");
        // projectdesigneraddrequisition
        const url = `https://flyingcolour.online/fcapp/support_api/projectdesigneraddrequisition/`;
        const headers = {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
        };
        let autoReqNo = [];
        let reqAmount = [];
        let clientPreferDate = [];
        let reqWorkType = [];
        let reqRemarks = [];
        let typistName = [];
        let taskName = [];
        let taskDays = [];
        let taskSeq = [];
        let reqStartDate = [];
        let reqEndDate = [];
        let files = [];
        let typistId = [];
        let selfAssi = [];
        let reqDays = [];

        let taskCategory = [];
        let taskType = [];
        let govtDepartmentName = [];
        let specialInstruction = [];
        let mainTaskIds = [];

        autoReqNo.push(values?.requistionId);
        values?.mainTaskArray?.map((value, i) => {
            reqAmount.push(value?.amount);
            reqWorkType.push(value?.typeofwork);
            typistName.push(value?.typistName);
            taskName.push(value?.taskName);
            taskDays.push(value?.taskDays);
            taskSeq.push(value?.taskSequence)
            reqStartDate.push(moment(values?.requistionType === "Multiple" ?
                moment(values?.requistionDate).format("YYYY-MM-DD HH:mm:ss") :
                value?.fromDate).format("YYYY-MM-DD HH:mm:ss"));
            reqEndDate.push(values?.requistionType === "Multiple" ?
                moment(values?.requistionDate).format("YYYY-MM-DD HH:mm:ss") :
                moment(value?.toDate).format("YYYY-MM-DD HH:mm:ss"));
            reqRemarks.push(value?.remarks);
            reqDays.push(values?.requistionType === "Multiple" ? 1 : value.reqDays);
            selfAssi.push(value?.typeofwork === 2 ? "No" : value?.typistName === 0 ? "Yes" : "No");
            clientPreferDate.push(value?.clientDate_time);
            
            taskCategory.push(value?.task_category);
            taskType.push(value.task_type);
            govtDepartmentName.push(value?.govt_department_name);
            specialInstruction.push(value?.special_instruction);
            mainTaskIds.push(Number(value?.Main_Task_Id));

            if (value?.files) {
                files.push(value?.uploadDoc);
            }

            // if (value?.typeofwork === "2") {
            //     selfAssi.push(value?.selfAssigned);
            // } else {
            //     selfAssi.push("No");
            // }

            // if (value?.typeofwork === "1") {
            //     clientPreferDate.push(value?.clientDate_time);
            // } else {
            //     clientPreferDate.push("No");
            // }

            if (i !== 0) {
                autoReqNo.push(value?.requistionId);
            }

            if (value?.selfAssigned === "Yes" || value?.typistName === 0) {
                typistId.push(0)
            } else {
                typistId.push(value?.typistName)
            }

        })
        const myRemarks = reqRemarks.map(s => `${s}`).join('` ');

        const formData = new FormData();
        formData.append("factory_id", factory_id);
        formData.append("order_no", operationInfo?.Order_No || viewRequistion?.order_id); 
        formData.append("emp_id", Number(emp_id));
        formData.append("user_type", user_type);
        formData.append("user_name", user_name);
        formData.append("auto_requisition_no", autoReqNo.toString());
        formData.append("main_task_id", editModalPopper ? Number(viewRequistion?.main_task_id) : mainTaskIds.toString());
        formData.append("main_task_days", editModalPopper ? values?.mainTaskArray[0]?.taskDays : mainTask?.days);
        formData.append("main_sow_task_name", editModalPopper ? values?.mainTaskArray[0]?.sow_mainTask : mainTask?.value);
        formData.append("zoho_balance_on_requisition_amount", pendingData?.currentAvailableZohoBalance);
        formData.append("requisition_amount", reqAmount?.toString());
        formData.append("req_company_id", operationInfo?.Company_Id || viewRequistion?.req_company_id);
        formData.append("client_prefer_datetime_location", clientPreferDate.toString());
        formData.append("requisition_work_type", reqWorkType?.toString());
        formData.append("remarks", myRemarks === undefined ? "" : myRemarks);
        formData.append("typist_emp_id", typistName?.toString());
        formData.append("task_name", taskName?.toString());
        formData.append("task_days", taskDays?.toString());
        formData.append("task_sequence", taskSeq.toString());
        formData.append("requisition_start_date", reqStartDate.toString());
        formData.append("requisition_end_date", reqEndDate?.toString());
        formData.append("is_self_assign_by_ro", selfAssi?.toString());

        formData.append("task_category", taskCategory.toString());
        formData.append("task_type", taskType.toString());
        formData.append("govt_department_name", govtDepartmentName?.toString());
        formData.append("special_instruction", specialInstruction?.toString());

        formData.append("requisition_no_days", reqDays?.toString());
        formData.append("requisition_type", values?.requistionType === "Multiple" ? "Urgent" : values?.requistionType === "Single" ? "Normal" : "");

        if (!editModalPopper) {
            formData.append("requisition_balance_approval_file", values?.mainTaskArray[0]?.uploadDoc);
        }

        if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "string") {
            formData.append("upload_doc_url", values?.mainTaskArray[0]?.uploadDoc);
        }
        if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "object") {
            formData.append("upload_doc_url", "");
        }
        if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "object") {
            formData.append("requisition_balance_approval_file", values?.mainTaskArray[0]?.uploadDoc);
        }
        if (editModalPopper && typeof (values?.mainTaskArray[0]?.uploadDoc) === "string") {
            formData.append("requisition_balance_approval_file", "");
        }
        if (values?.requistionType === "Multiple") {
            formData.append("urgent_date", moment(values?.requistionDate).format("YYYY-MM-DD"));
        }
        if (editModalPopper) {
            formData.append("update_req_id", viewRequistion?.requisition_id);
            axios.put(url, formData, { headers: headers }).then((data) => {
                setLoading(false);
                handleQuotationPromise(data);
            })
        } else {
            axios.post(url, formData, { headers: headers }).then((data) => {
                setLoading(false);
                handleQuotationPromise(data);
            })
        }

    }

    // const zohoBalanceAPI = async () => {
    //     const __token = localStorage.getItem("token");
    //     const user_type = localStorage.getItem("user_type");
    //     const emp_id = localStorage.getItem("emp_id");

    //     //setLoading(true);
    //     try {
    //         const url = `https://flyingcolour.online/fcapp/support_api/zohobalancedetailsview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&company_id=${operationInfo?.Company_Id}`;
    //         const headers = {
    //             Authorization: `Token ${__token}`,
    //             "Content-Type": "application/json",
    //         };
    //         const res = await axios.get(url, { headers });
    //         zohoBalanceRef.current = res?.data?.results?.availableZoho_Balance;
    //         setEnablReinitializei(true)
    //         let value = '';
    //         if (res?.data?.results?.availableZoho_Balance > 0) {
    //             value = `${res?.data?.results?.availableZoho_Balance}  - POSITIVE`
    //             setZohoBalance(value);
    //         } else if (res?.data?.results?.availableZoho_Balance < 0) {
    //             value = `${res?.data?.results?.availableZoho_Balance}  - NEGATIVE`
    //             setZohoBalance(value);
    //         } else {
    //             value = `Company doesn't exist on  Zoho`
    //             setZohoBalance(value);
    //         }
    //         setTimeout(() => {
    //             setEnablReinitializei(false);
    //             setLoading(false)
    //         }, 500)
    //         return value;

    //         //setLoading(false);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }

    const requistionListingDataAPi = async () => {
        const __token = localStorage.getItem("token");
        const user_type = localStorage.getItem("user_type");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");

        setLoading(true);
        try {
            const url = `https://flyingcolour.online/fcapp/support_api/orderwise_pending_sowlist/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo?.Order_No || viewRequistion?.order_id}`;
            const headers = {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setEnablReinitializei(true)
            setPendingData(res?.data?.results);

            // setTimeout(() => {
                setEnablReinitializei(false)
            // }, 500)

            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const PendingRequistionListingAPi = async () => {
        const __token = localStorage.getItem("token");
        const user_type = localStorage.getItem("user_type");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");

        // setLoading(true);
        try {
            const url = `https://flyingcolour.online/fcapp/support_api/orderwisependingrequisitionlistview/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}&order_no=${operationInfo?.Order_No || viewRequistion?.order_id}`;
            const headers = {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setEnablReinitializei(true)
            setPendingReqListing(res?.data?.results);
            setTimeout(() => {
                setEnablReinitializei(false)
            }, 500);

            // setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleAutoRequistion = async (setFieldValue, i, values) => {
        const __token = localStorage.getItem("token");
        const user_type = localStorage.getItem("user_type");
        const emp_id = localStorage.getItem("emp_id");
        const factory_id = localStorage.getItem("factory_id");

        //setLoading(true);
        try {
            const url = `https://flyingcolour.online/fcapp/support_api/generaterequisitionno/?factory_id=${factory_id}&emp_id=${emp_id}&user_type=${user_type}`;
            const headers = {
                Authorization: `Token ${__token}`,
                "Content-Type": "application/json",
            };
            const res = await axios.get(url, { headers });
            setAutoReqId(res?.data?.results?.Random_Requisition_No?.[0]?.Auto_Requisition_No);
            // setFieldValue(`mainTaskArray[${i}].requistionId`, res?.data?.results?.Random_Requisition_No?.[0]?.Auto_Requisition_No);


            //setLoading(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (editModalPopper) {
            // zohoBalanceAPI();
            // PendingRequistionListingAPi();
            // setLoading(true);
            const mainTaskFind =
                requistion_Type === "Urgent" ?
                    sow_type_dropdownForMultiple?.find((val) => val.id === viewRequistion?.req_task_to_pro)
                    :
                    requistion_Type === "Normal" ?
                        sow_type_dropdownForSingle?.find((val) => val.id === viewRequistion?.req_task_to_pro) :
                        ""
            setMainTask(mainTaskFind)
        } else {
            // zohoBalanceAPI();
            handleAutoRequistion();
            // setLoading(true)
        }
        getOrderSOWDataAPI(viewRequistion);
        PendingRequistionListingAPi();
        requistionListingDataAPi();

    }, [])
    const handleQuotationPromise = (data) => {
        if (data?.data?.message) {
            setMessage(data?.data?.message);
            setSeverity(500);
        }
        if (data?.data?.data?.msg) {
            setMessage(data?.data?.data?.msg);
            setSeverity(500);
        }
        if (data?.data?.data?.detail) {
            setMessage(data?.data?.data?.detail);
            setSeverity(500);
        } else {
            setMessage(data?.data?.data?.msg);
            setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        handleModaleClose();
        getOrderSOWDataAPI(viewRequistion);

    };

    const filterWeekends = (date) => {
        return date.getDay() !== 0 ; 
      };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
      }
      function checkDuplicateSowMainTask() {
        const sowMainTasks = new Set();
        for (const item of getMainTaskArr) {
            if (sowMainTasks.has(item.sow_mainTask)) {
                return "true"; // Found duplicate sow_mainTask
            }
            sowMainTasks.add(item.sow_mainTask);
        }
        return "false"; // No duplicate sow_mainTask found
    }
    checkDuplicateSowMainTask();
    const editingReq =  pendingReqListing?.Requisition_List?.find((item) => item.Requisition_Auto_Id === (viewRequistion?.requisitionNos || viewRequistion?.requisition_auto_id));
    const checkPendingReqAmount = editingReq === undefined ? 0 : pendingReqListing?.Pending_Requisition_Amount - editingReq?.Requisition_Amount;
    const ReqType = viewRequistion?.Requisition_Type === "Urgent" ? "Multiple" : viewRequistion?.Requisition_Type === "Normal" ? "Single" : viewRequistion?.Requisition_Type;
    const checkZohoBalance = pendingData?.currentAvailableZohoBalance - (editModalPopper ? (checkPendingReqAmount !== 0 ? checkPendingReqAmount : pendingReqListing?.Pending_Requisition_Amount): pendingReqListing?.Pending_Requisition_Amount);
    const start_date = editModalPopper && new Date(new Date(moment(viewRequistion?.startdate)).toString());
    const end_date = editModalPopper && new Date(new Date(moment(viewRequistion?.enddate)).toString());
    const reqUrgentDate = editModalPopper && new Date(new Date(moment(viewRequistion?.req_urgent_date, "DD-MM-YYYYY")).toString());

    return (
       
        <>
<Formik
    enableReinitialize={enableReinitialize}
    initialValues={{
        orderId: operationInfo?.Order_No || viewRequistion?.order_id,
        companyName: editModalPopper ? operationInfo?.Company_Name || viewRequistion?.company_name: operationInfo?.Company_Name,
        requistionId: editModalPopper ? viewRequistion?.requisitionNos || viewRequistion?.requisition_auto_id : pendingData?.Random_Requisition_No?.[0]?.Auto_Requisition_No,
        requistionDateTime: moment(new Date()).format("DD-MM-YYYY hh:mm:ss a"),
        zohoExpenses: pendingData?.currentAvailableZohoBalance === "" || pendingData?.currentAvailableZohoBalance === undefined
            ? `Company doesn't exist on  Zoho`
            : pendingData?.currentAvailableZohoBalance,
        accountPendingReqAmount: editModalPopper ? (checkPendingReqAmount !== 0 ? checkPendingReqAmount : pendingReqListing?.Pending_Requisition_Amount) : pendingReqListing?.Pending_Requisition_Amount,
        //sow_mainTask: editModalPopper ? mainTask?.value : '',
        requistionType: editModalPopper ? ReqType : '',
        requistionDate: editModalPopper ? reqUrgentDate : '',
        mainTaskArray: [{
            sow_mainTask: editModalPopper ? viewRequistion?.main_task_name || viewRequistion?.mainTaskName : '',
            taskName: editModalPopper ? viewRequistion?.sub_task_name : '',
            taskDays: editModalPopper ? viewRequistion?.main_task_days : '',
            taskSequence: editModalPopper ? viewRequistion?.main_task_sequence || viewRequistion?.sub_task_sequance: '',
            typeofwork: editModalPopper ? Number(viewRequistion?.work_type_id) : '',
            amount: editModalPopper ? viewRequistion?.req_amount: '',
            fromDate: editModalPopper ? start_date : '',
            toDate: editModalPopper ? end_date : '',
            typistName: editModalPopper ? viewRequistion?.Order_Typist_Id: '',
            clientDate_time: editModalPopper ? viewRequistion?.Client_Preffer_Datetime_Location : '',
            selfAssigned: editModalPopper ? viewRequistion?.is_self_assign_by_ro : '',
            remarks: editModalPopper ? viewRequistion?.task_remarks : '',
            reqDays: editModalPopper ? viewRequistion?.Requisition_No_Days : '',
            uploadDoc: editModalPopper ? viewRequistion?.requisition_balance_approval_file : '',
            task_category: editModalPopper ? viewRequistion?.task_category : '',
            task_type: editModalPopper ? viewRequistion?.task_type : '',
            govt_department_name: editModalPopper ? viewRequistion?.sow_govt_dept || viewRequistion?.govt_department_name: '',
            special_instruction: editModalPopper ? viewRequistion?.sow_special_instruction: '',
            Typist_Name: editModalPopper ? viewRequistion?.Order_Typist_Name : '',
            requistionId: ''
        }]
    }}
    onSubmit={async (data) => {
        setLoading(true)
        requistionPostAPI(data);
    }}
    validate={(values) => {
        setMainTaskArr(values.mainTaskArray)
        console.log(values.mainTaskArray)
        const total_amount = values?.mainTaskArray?.reduce((sum, item) => {
            return sum + Number(item.amount);
        }, 0);
        console.log("total_amount", total_amount)

        let errors = {};
        // if (!values.sow_mainTask) {
        //     errors.sow_mainTask = "required";
        // }
        
        if (values?.requistionType === "Multiple")
        {
        if (values.requistionDate === "" || values.requistionDate === undefined) {
            errors.requistionDate = "Requistion Date is Required!!";
          }
        }
        if (values.requistionType === "") {
            errors.requistionType = "Requistion Type is Required!!";
          }

        // if (checkZohoBalance < values?.mainTaskArray[0].amount && values?.mainTaskArray[0]?.uploadDoc === "" ) {
        //     errors.uploadDoc = "Upload File is Required!!";
        // }
        values?.mainTaskArray?.forEach((val) => {
           
          if (val.taskName === "") {
            errors.taskName = "Task Name is Required!!";
          }
          if (val.taskDays === "") {
            errors.taskDays = "Task Days are Required!!";
          }
          if (val.taskSequence === "") {
            errors.taskSequence = "Task Sequence is Required!!";
          }
          if (val.typeofwork === "") {
            errors.typeofwork = "Work Typs is Required!!";
          }
          if (val.amount === "" || val.amount === null || val.amount === undefined) {
            errors.amount = "Amount is Required!!";
          }
          if (values?.requistionType === "Single" && val.fromDate === "") {
            errors.fromDate = "From Date is Required!!";
          }
          if (values?.requistionType === "Single" && val.toDate === "") {
            errors.toDate = "To Date Required!!";
          }
          if (val.clientDate_time === "") {
            errors.clientDate_time = "Client Prefer DateTime is Required!!";
          }
           if ((checkZohoBalance < total_amount) && val?.uploadDoc === "" ) {
            errors.uploadDoc = "Upload File is Required!!";
        }
        //   if (val.remarks && /\s{2,}/.test(val.remarks)) {
        //     errors.remarks = "Remove extra spaces or link in the remarks and if you copied remarks from somewhere then paste that remarks in notepad first, after that copy from notepad & paste it here in remarks!";
        //   }
         
        })

        return errors;
    }}
>
    {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
    }) => {
 return (
 loading ? (
    <>
      <div >
      <LoaderClock loading={loading} />
      </div>
    </>
     ) : 
      <form onSubmit={(e) => { e.preventDefault() }}>
          <Box sx={style}>
              <div>
                  <div style={{ height: "36px" }}>
       <IconButton
           style={{ float: "right" }}
           className={classes.quotationModalHeaderIconCancle}
           onClick={() => {
               handleModaleClose();
           }}
       >
           <CloseIcon
               className={classes.quotationModalHeaderIconCancleIcon}
           />
       </IconButton>
      </div>
      <div style={{ height: "600px", overflowX: "hidden", padding: "0" }}>
       <Grid container spacing={2} columns={12}>
           <Grid
               item
               xs={12}
               style={{
                   marginBottom: "-40px",
                   paddingBottom: "10px",
                   marginTop: "-45px",
                   zIndex: 10
               }}
           >
               <br />
               <div className={classes.quotationModalHeadingContainer}>
                   <span className={classes.quotationModalHeader}>
                   {
                   editModalPopper ? "Edit Requistion" : " Add Requistion"
                   } On Order: {values?.orderId}
                   </span>
               </div>
               <br />
               <div className={classes.quotationModalFlexDivContaner}>
                   <Grid container>
                       <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                       >
                            <InputText
                               label="Company Id"
                               fieldName="Requistion_Id"
                               fieldValue={operationInfo?.Company_Id || viewRequistion?.req_company_id}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />
                           <InputText
                               label="Zoho Account Name"
                               fieldName="Requistion_Id"
                               fieldValue={pendingData?.zohoAccount_Name}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />
                           <InputText
                               label="ZOHO Govt. Expense of Client (AED)"
                               fieldName="Requistion_Id"
                               fieldValue={pendingData?.error_msg === "Company doesn't exist on Zoho" ? 
                               "Company doesn't exist on Zoho" :  pendingData?.currentAvailableZohoBalance}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />

                           <InputText
                               label="Requistion No"
                               fieldName="Requistion_Id"
                               fieldValue={
                                   editModalPopper ? viewRequistion?.requisitionNos || viewRequistion?.requisition_auto_id :
                                   pendingData?.Random_Requisition_No?.[0]?.Auto_Requisition_No}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />
                         
                       </Grid>
                       <Grid
                           item
                           xs={12}
                           md={6}
                           className={classes.quotationInnerGridRight}
                       >
                           <InputText
                               label="Company Name"
                               fieldName="Requistion_Id"
                               fieldValue={values?.companyName}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />

                           <InputText
                               label="Accounts Pending Requistion Amount(AED)"
                               fieldName="Lnquiry_Caption"
                               fieldValue={values?.accountPendingReqAmount}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />

                           <InputText
                               label="Requistion Date & Time"
                               fieldName="Requistion_Id"
                               fieldValue={values.requistionDateTime}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />
                           <InputText
                               label="RO Name"
                               fieldName="Requistion_Id"
                               fieldValue={`${user_first_name} ${user_last_name}`}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />
                            <InputText
                               label="Formation Type"
                               fieldName="Formation"
                               fieldValue={getFreeZone || viewRequistion?.formation_type_name}
                               fieldHandleChange={(event) => { }
                                   //handleChangeInput(item?.id, event)
                               }
                               disableField={true}
                               disabled={true}
                               classvalue={"textBold"}
                           />
                       </Grid>
                   </Grid>
             <Grid container>
                <Grid item xs={12} md={12} className={classes.quotationInnerGridRight}>
                <Form.Group as={Row} className="mb-1">
                  <Form.Label column sm="2" className="fc-textarea-label">
                      Requistion Type<span style={{color: "red"}}>*</span>
                  </Form.Label>
                  <Col sm="10" style={{ padding: '0 12px 0 0' }} >
                      <div
                          //style={getStyles(errors, item?.Generation_Date)}
                          style={{ width: '75%', marginLeft: '5%' }}
                      >
                          <RadioGroup
                              row
                              aria-labelledby="requistionType"
                              name="controlled-radio-buttons-group"
                              value={values.requistionType}
                              onChange={(e) => {
                                  setFieldValue(`requistionType`, e.target.value);
                                  setRequistion_Type(e.target.value);
                                  // if (e.target.value === "Multiple") {
                                  //     setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].taskDays`, 1);
                                  // }
                              }}
                          >
                              <FormControlLabel
                                  control={<Radio />}
                                  value="Single"
                                  label="Single"
                                  disabled={editModalPopper ? true : false}
                              />
                              <FormControlLabel
                                  control={<Radio />}
                                  value="Multiple"
                                  label="Multiple"
                                  disabled={editModalPopper ? true : false}
                              />
                          </RadioGroup>
                      </div>
                  </Col>
              </Form.Group>
              {(values?.requistionType === "Multiple") && (
                  <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId={"lead-generation-date"}
                  >
                      <Form.Label
                          column
                          sm="2"
                          style={{
                              fontSize: 12,
                              fontWeight: 700,
                          }}
                      >
                          Requistion Date <sup style={{ color: 'red' }}>*</sup>
                      </Form.Label>
                      <Col
                          sm="4"
                          style={{ padding: "0 12px 0 0" }}
                      >
                          <div
                          >
                              <div
                                  style={{
                                      border: "0.1px solid #d3d3d3",
                                      width: '85%', marginLeft: '13%'
                                  }}
                              >
                                  <DatePicker
                                      //disabled={fieldsEditingMain}
                                     selected={values?.requistionDate}
                                      // minDate={moment().toDate()}
                                      minDate={addDays(new Date(),  0)}
                                      maxDate={addDays(new Date(), 1)}
                                      onChange={(date) => {
                                          setFieldValue(`requistionDate`, date);
                                              const currentDate = new Date();
                                              const diffInMilliseconds = date - currentDate;
                                              const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
                                              setRequisitionDate(diffInDays)

                                      }}
                                      fixedHeight
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="Select Date"
                                  />
                              </div>
                          </div>
                      </Col>
                  </Form.Group>
              )}
                                                            

{values?.requistionType === "" ? null :
    <div className={classes.modalFlexDivContaner}>
 <Grid container xs={12} sm={12} className={classes.innerGridContainer}>
     <FieldArray
         name="mainTaskArray"
         render={(arrayHelpers) => (
             <div
                 style={{
                     display: "flex",
                     flexWrap: "wrap",
                     //marginRight: "1rem",
                     justifyContent: "space-between",
                     marginBottom: "1rem",
                     width: "100%",
                 }}
             >
                 {values.mainTaskArray.length > 0 &&
                     values.mainTaskArray.map((item, i) => {
                        const totalAmount = values?.mainTaskArray?.reduce((sum, item) => {
                            return sum + Number(item.amount);
                        }, 0);
                         return (
                             <div
                                 key={i}
                                 style={
                                     i !==
                                         values.mainTaskArray.length
                                         ? {
                                             border: "1px solid #eb6e12",
                                             padding: "5px",
                                             width: "100%",
                                             paddingRight: "20px",
                                             paddingLeft: "10px"
                                         }
                                         : {
                                             border: "none",
                                             padding: "5px",
                                             marginTop: "2px",
                                             width: "100%",
                                             paddingRight: "20px"
                                         }
                                 }
                             >
                                 <div
                                     style={{
                                         display: "flex",
                                         justifyContent: "end",
                                         position: "relative",
                                         left: "20px",
                                         cursor: 'pointer',
                                         color: '#eb6e12'
                                     }}
                                 >
                                     {!editModalPopper && values?.requistionType === "Multiple" && <AddCircleIcon
                                         onClick={() => {
                                             handleAutoRequistion()
                                             if (values?.requistionType === "Multiple") {
                                                 arrayHelpers.push({
                                                     taskName: '',
                                                     taskDays: '',
                                                     taskSequence: '',
                                                     typeofwork: '',
                                                     amount: '',
                                                     fromDate: '',
                                                     toDate: '',
                                                     typistName: '',
                                                     clientDate_time: '',
                                                     selfAssigned: '',
                                                     remarks: '',
                                                     reqDays: '',
                                                     requistionId: autoReqId

                                                 });
                                             } else { return null }
                                         }}
                                         style={
                                             values.mainTaskArray
                                                 .length > 1
                                                 ? {
                                                     marginBottom: "0px",
                                                     marginTop: "0px",
                                                 }
                                                 : {
                                                     marginBottom: "0px",
                                                     marginTop: "0px",
                                                 }
                                         }
                                         className="ModalHeaderIcon"
                                     />}
                                     {!editModalPopper && values.mainTaskArray.length >
                                         1 ? (
                                         <RemoveCircleOutlineIcon
                                             onClick={() =>
                                                 //handleRemoveFieldsActivity(item.id)
                                                 arrayHelpers.remove(i)
                                             }
                                             style={{
                                                 marginBottom: "0px",
                                                 marginTop: "0px",
                                             }}
                                             className="ModalHeaderIcon"
                                         />
                                     ) : null}
                                 </div>

                                 {(values.mainTaskArray.length > 1 && i !== 0) && (
                                     <InputText
                                         label="Requistion No."
                                         fieldName="requistionId"
                                         fieldValue={item?.requistionId}
                                         fieldHandleChange={(event) => { }
                                         }
                                         disableField={true}
                                         disabled={true}
                                         classvalue={"textBold"}
                                     />
                                 )}
                                 <Grid container>
                                     <Grid item xs={12} md={6} style={{ paddingRight: 5 }}>
                                    
                                 {editModalPopper ? (

                                     <InputText
                                         label="Main Task Name"
                                         fieldName="sow_mainTask"
                                         fieldValue={item?.sow_mainTask}
                                         fieldHandleChange={(event) => { }
                                         }
                                         disableField={true}
                                         disabled={true}
                                     //classvalue={"textBold"}
                                     />

                                 ) :
                                    
                                 <CustomDropdown
                                  label="Main Task Name"
                                     name={`sow_mainTask`}
                                     fieldValue={
                                         item?.sow_mainTask
                                     }
                                     fieldHandleChange={(
                                         value
                                     ) => {
                                         setFieldValue(`mainTaskArray[${i}].sow_mainTask`, value.value);
                                         setMainTask(value);
                                         
                                         //handleSameAsTask2(value, setFieldValue, values);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].taskName`, value?.task_name);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].taskDays`, value?.days);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].taskSequence`, value?.task_sequence);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].amount`, value?.task_amount);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].typeofwork`, value?.work_type);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].workTypeName`, value?.work_type_name);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].typistName`, value?.typistName);

                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].task_category`, value?.task_category);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].task_type`, value?.task_type);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].govt_department_name`, value?.govt_department_name);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].special_instruction`, value?.special_instruction);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].Typist_Name`, value?.Typist_Name);
                                         setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].Main_Task_Id`, value?.id);

                                         // setFieldValue(`mainTaskArray[${values.mainTaskArray.length - 1}].remarks`, value?.task_remarks);

                                     }}
                                     fieldOptions={
                                         requistion_Type === "Single" ?
                                          sow_type_dropdownForSingle
                                             : requistion_Type === "Multiple" ? 
                                                  sow_type_dropdownForMultiple
                                                 :
                                                 []
                                     }
                                     requiredField={true}
                                    //  isOptionDisabled={(option) => option?.task_category !== "Mandatory"}
                                 />
                                 }
                                  {checkDuplicateSowMainTask() === "true" ?
                                        <span style={{color: "red", fontSize: "15px"}}>Main Task should be selected one time</span>
                                        : ""
                                        }
                                 {/* <InputText
                                         label="Days"
                                         fieldName="taskDays"
                                         fieldValue={item?.taskDays}
                                         fieldHandleChange={(e) => { 
                                            setFieldValue(`mainTaskArray[${i}].taskDays`, e.target.value);
                                         }
                                         }
                                         disableField={true}
                                         disabled={true}
                                         classvalue={""}
                                         requiredField={true}
                                     />
                                  */}
                                 </Grid>
                                  <Grid item xs={12} md={6} style={{ paddingRight: 5 }}>
                                
                                  <InputText
                                         label="Task Name"
                                         placeholder={""}
                                         fieldName="taskName"
                                         fieldValue={item?.taskName}
                                         fieldHandleChange={(e) => { 
                                            setFieldValue(`mainTaskArray[${i}].taskName`, e.target.value);
                                         }
                                         }
                                         disableField={true}
                                         disabled={true}
                                         classvalue={""}
                                         requiredField={true}
                                     />
                                     
                                 </Grid>
                                     </Grid>
                                 <Grid container>
                                     <Grid item xs={12} md={6} style={{ paddingRight: 5 }}>
                                         {/* <CustomDropdown
                                             label="Work Type"
                                             fieldOptions={work_type_options}
                                             fieldValue={Number(item?.typeofwork)}
                                             fieldHandleChange={(value) => {
                                                 setFieldValue(`mainTaskArray.[${i}].typeofwork`, value.value);
                                             }}
                                             errorField={getStyles(errors, values?.mainTaskArray[i]?.typeofwork)}
                                             requiredField={true}
                                         /> */}
                                         <InputText
                                         label="Task Days"
                                         placeholder={""}
                                         fieldName="taskDays"
                                         fieldValue={item?.taskDays}
                                         fieldHandleChange={(e) => { 
                                            setFieldValue(`mainTaskArray[${i}].taskDays`, e.target.value);
                                         }
                                         }
                                         disableField={true}
                                         disabled={true}
                                         classvalue={""}
                                        />  
                                          <InputText
                                          label="Task Sequence"
                                          placeholder={""}
                                          fieldName="taskSequence"
                                          fieldValue={item?.taskSequence}
                                          fieldHandleChange={(e) => { 
                                             setFieldValue(`mainTaskArray[${i}].taskSequence`, e.target.value);
                                          }
                                          }
                                          disableField={true}
                                          disabled={true}
                                          classvalue={""}
                                        />
                                         <InputText
                                             label="Work Type"
                                             placeholder={""}
                                             fieldName="workTypeName"
                                             fieldOptions={work_type_options}
                                             fieldValue={editModalPopper ? viewRequistion?.work_type || viewRequistion?.RequisitionWorkType : item?.workTypeName}
                                             fieldHandleChange={(value) => {
                                                 setFieldValue(`mainTaskArray.[${i}].workTypeName`, value.value);
                                             }}
                                             errorField={getStyles(errors, values?.mainTaskArray[i]?.workTypeName)}
                                             requiredField={true}
                                             disableField={true}
                                         />

                                         <InputText
                                             label="Amount (AED)"
                                             placeholder={""}
                                             fieldName={item.amount}
                                             fieldValue={item?.amount}
                                             min={"1"}
                                             fieldHandleChange={(e) => {
                                                 const { value } = e.target;
                                                 const regex =  /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;
                                                 if (!value || regex.test(value.toString()))
                                                 setFieldValue(`mainTaskArray.[${i}].amount`, value);
                                             }
                                             }
                                             fieldType={"number"}
                                             errorField={getStyles(errors, values.amount)}
                                             requiredField={true }
                                            // disableField={getFreeZone !== "Free Zone" ? true: false}
                                         />

                                         {checkZohoBalance < totalAmount && (
                                             <Form.Group as={Row} className="mb-1">
                                                 <Form.Label column sm="5" className="fc-textarea-label">
                                                     Do you have management approval/transfer receipt? <span style={{ color: 'red' }}>*</span>
                                                 </Form.Label>
                                                 <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                                     <div className={classes.businessCardFormGroupModal}>
                                                         <div
                                                             className={classes.quotationFormGroupFlexDivModalVT}
                                                         >
                                                             <div
                                                                 className={classes.quotationSelectContainerModal}
                                                             >
                                                                 <div
                                                                     className={
                                                                         classes.businessCardDemoDataCheckModalUploadFile
                                                                     }
                                                                 >
                                                                     <input
                                                                         type="file"
                                                                         style={{ display: "none" }}
                                                                         onChange={(event) => {
                                                                             setFieldValue(
                                                                                 `mainTaskArray.[${i}].uploadDoc`,
                                                                                 event.currentTarget.files[0]
                                                                             );
                                                                         }}
                                                                         // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                         ref={(el) => (fileInput.current[i] = el)}
                                                                     />
                                                                     <button
                                                                         className={
                                                                             classes.businessCardDemoDataCheckModalUploadFileBtn
                                                                         }
                                                                         type="button"
                                                                         onClick={() => fileInput.current[i].click()}
                                                                       //  disabled={values?.mainTaskArray?.length > 1}
                                                                     >
                                                                         <PermMediaIcon
                                                                             style={{ marginRight: "10px" }}
                                                                         />
                                                                         Add Media
                                                                     </button>
                                                                     <small
                                                                         className={
                                                                             classes.businessCardDemoDataCheckModalUploadFileText
                                                                         }
                                                                     >
                                                                         {item.uploadDoc
                                                                             ? item?.uploadDoc?.name ||
                                                                             item?.uploadDoc?.split("/").pop()
                                                                             : "No file chosen"}
                                                                     </small>
                                                                 </div>

                                                             </div>
                                                         </div>
                                                     </div>
                                                     {values.uploadDocument
                                                         ?.length !== 0 &&
                                                         item.uploadDoc !== "" &&
                                                         typeof item.uploadDoc ===
                                                         "object" ? (
                                                         [
                                                             "png",
                                                             "jpg",
                                                             "JPEG",
                                                             "jpeg",
                                                         ].includes(
                                                             item.uploadDoc?.name
                                                                 ?.split(".")
                                                                 .pop()
                                                         ) ? (
                                                             <div
                                                                 style={{
                                                                     display: "flex",
                                                                     justifyContent:
                                                                         "center",
                                                                     marginBottom: ".2rem",
                                                                     marginTop: "5px",
                                                                 }}
                                                             >
                                                                 <img
                                                                     onClick={(e) => {
                                                                         e.preventDefault();
                                                                         fileInput.current[
                                                                             i
                                                                         ]?.current.click();
                                                                     }}
                                                                     style={{
                                                                         width: "98%",
                                                                         height: "100px",
                                                                     }}
                                                                     src={URL.createObjectURL(
                                                                         item.uploadDoc
                                                                     )}
                                                                     alt={"image"}
                                                                 />
                                                             </div>
                                                         ) : (
                                                             <div
                                                                 style={{
                                                                     display: "flex",
                                                                     justifyContent:
                                                                         "center",
                                                                     marginBottom: ".5rem",
                                                                     marginTop: ".5rem",
                                                                     alignItems: "center",
                                                                     width: "50px",
                                                                     marginRight: "2rem",
                                                                 }}
                                                             >
                                                                 {item.uploadDoc?.name
                                                                     ?.split(".")
                                                                     .pop() === "docx" ? (
                                                                     <>
                                                                         <RiFileWord2Fill
                                                                             style={{
                                                                                 color: "#2b579a",
                                                                                 fontSize: "36px",
                                                                                 marginRight:
                                                                                     ".4rem",
                                                                             }}
                                                                         />
                                                                     </>
                                                                 ) : item.uploadDoc?.name
                                                                     ?.split(".")
                                                                     .pop() === "pdf" ? (
                                                                     <BsFilePdfFill
                                                                         style={{
                                                                             color: "#ff0000",
                                                                             fontSize: "36px",
                                                                             marginRight:
                                                                                 ".4rem",
                                                                         }}
                                                                     />
                                                                 ) : (
                                                                     <CloudUploadIcon
                                                                         style={{
                                                                             color: "green",
                                                                             fontSize: "32px",
                                                                             marginRight:
                                                                                 ".4rem",
                                                                         }}
                                                                     />
                                                                 )}
                                                             </div>
                                                         )
                                                     ) : values.uploadDocument
                                                         ?.length !== 0 &&
                                                         item.uploadDoc !== "" &&
                                                         typeof item.uploadDoc ===
                                                         "string" ? (
                                                         [
                                                             "png",
                                                             "jpg",
                                                             "JPEG",
                                                             "jpeg",
                                                         ].includes(
                                                             item.uploadDoc
                                                                 ?.split(".")
                                                                 .pop()
                                                         ) ? (
                                                             <div
                                                                 style={{
                                                                     display: "flex",
                                                                     justifyContent:
                                                                         "center",
                                                                     marginBottom: ".2rem",
                                                                     marginTop: "5px",
                                                                 }}
                                                             >
                                                                 <img
                                                                     onClick={(e) => {
                                                                         e.preventDefault();
                                                                         fileInput.current[
                                                                             i
                                                                         ]?.current.click();
                                                                     }}
                                                                     style={{
                                                                         width: "98%",
                                                                         height: "100px",
                                                                     }}
                                                                     src={item.uploadDoc}
                                                                     alt={"image"}
                                                                 />
                                                             </div>
                                                         ) : (
                                                             <div
                                                                 style={{
                                                                     display: "flex",
                                                                     justifyContent:
                                                                         "center",
                                                                     marginBottom: ".5rem",
                                                                     marginTop: ".5rem",
                                                                     alignItems: "center",
                                                                     width: "50px",
                                                                     marginRight: "2rem",
                                                                 }}
                                                             >
                                                                 {item.uploadDoc
                                                                     ?.split(".")
                                                                     .pop() === "docx" ? (
                                                                     <>
                                                                         <RiFileWord2Fill
                                                                             style={{
                                                                                 color: "#2b579a",
                                                                                 fontSize: "36px",
                                                                                 marginRight:
                                                                                     ".4rem",
                                                                             }}
                                                                         />
                                                                     </>
                                                                 ) : item.uploadDoc
                                                                     ?.split(".")
                                                                     .pop() === "pdf" ? (
                                                                     <BsFilePdfFill
                                                                         style={{
                                                                             color: "#ff0000",
                                                                             fontSize: "36px",
                                                                             marginRight:
                                                                                 ".4rem",
                                                                         }}
                                                                     />
                                                                 ) : (
                                                                     <CloudUploadIcon
                                                                         style={{
                                                                             color: "green",
                                                                             fontSize: "32px",
                                                                             marginRight:
                                                                                 ".4rem",
                                                                         }}
                                                                     />
                                                                 )}
                                                             </div>
                                                         )
                                                     ) : null}
                                                 </Col>
                                             </Form.Group>
                                         )}
                                         {/* {item?.typeofwork === 2 && ( */}
                                         <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                                 Does client has preferred date time & locations?<span style={{color: "red"}}>*</span>
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                                 <div
                                                 //style={getStyles(errors, item?.Generation_Date)}
                                                 >
                                                     <RadioGroup
                                                         row
                                                         aria-labelledby="requistionType"
                                                         name="controlled-radio-buttons-group"
                                                         value={item.clientDate_time}
                                                         onChange={(e) => {
                                                             setFieldValue(`mainTaskArray.[${i}].clientDate_time`, e.target.value);
                                                         }}
                                                     >
                                                         <FormControlLabel
                                                             control={<Radio />}
                                                             label={"Yes"}
                                                             value={"Yes"}
                                                             labelPlacement="end"
                                                         />

                                                         <FormControlLabel
                                                             control={<Radio />}
                                                             label={"No"}
                                                             value={"No"}
                                                             labelPlacement="end"
                                                         />
                                                     </RadioGroup>
                                                 </div>
                                             </Col>
                                         </Form.Group>

                                         <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                                 Self Assigned?
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                                 <div
                                                 //style={getStyles(errors, item?.Generation_Date)}
                                                 >
                                                     <RadioGroup
                                                         row
                                                         aria-labelledby="requistionType"
                                                         name="controlled-radio-buttons-group"
                                                         value={
                                                            viewRequistion?.work_type_id === 2 
                                                            || item?.typeofwork === 2 ? "No"
                                                            : item?.typistName > 0 && item?.typeofwork === 1 ? "No" :
                                                            item?.typistName === 0 && item?.typeofwork === 1 ? "Yes"
                                                            :
                                                             item?.selfAssigned 
                                                            }
                                                         onChange={(e) => {
                                                             setFieldValue(`mainTaskArray.[${i}].selfAssigned`, e.target.value);
                                                         }}
                                                        >
                                                         <FormControlLabel
                                                             control={<Radio />}
                                                             label={"Yes"}
                                                             value={"Yes"}
                                                             labelPlacement="end"
                                                             disabled = {editModalPopper && viewRequistion?.work_type_id === 2 
                                                                || item?.typeofwork === 2 
                                                                || item?.Typist_Name === "Not Applicable"
                                                                || item?.typistName > 0
                                                                ? true : false}
                                                         />
                                                         <FormControlLabel
                                                             control={<Radio />}
                                                             label={"No"}
                                                             value={"No"}
                                                             labelPlacement="end"
                                                             disabled = {editModalPopper && viewRequistion?.work_type_id === 2 
                                                                || item?.typeofwork === 2 
                                                                || item?.Typist_Name === "Not Applicable"
                                                                || item?.typistName > 0
                                                                ? true : false}
                                                         />
                                                     </RadioGroup>       
                                                    </div>
                                             </Col>
                                         </Form.Group>
                                     </Grid>
                                     <Grid item xs={12} md={6}>
                                         {OrderSOWData?.SOWList_Details[0]?.Order_Typist_Name=== "Not Applicable" ? (
                                             <Form.Group as={Row} className="mb-1" controlId={"requistion-date"}>
                                             <Form.Label column sm="5" style={{ fontWeight: "700", fontSize: 12 }}>
                                               Typist Name<span style={{color: "red"}}>*</span>
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }}>
                                               <div className="fc-dropdown-container">
                                                 <CustomSelect
                                                   id="typistName"
                                                   value={Number(item?.typistName)}
                                                   onChange={(value) => {
                                                    setFieldValue(`mainTaskArray[${i}].typistName`, value.value);
                                                   }}
                                                   options={typist_name_options}
                                                 />
                                                </div>
                                             </Col>
                                           </Form.Group>
                                            //  <CustomDropdown
                                            //      label="Typist Name"
                                            //      fieldOptions={typist_name_options}
                                            //      fieldValue={Number(item?.typistName)}
                                            //      fieldHandleChange={(value) => {
                                            //          setFieldValue(`mainTaskArray[${i}].typistName`, value.value);
                                            //      }}
                                            //      errorField={getStyles(errors, item?.typistName)}
                                            //      requiredField={true}
                                            //  />
                                            ):
                                            (
                                             <InputText
                                                 label="Typist Name"
                                                 fieldName="typistName"
                                                 fieldValue={item?.sow_mainTask !== "" && OrderSOWData?.SOWList_Details[0]?.Order_Typist_Name}
                                                 fieldHandleChange={(e) => {
                                                     e.preventDefault();
                                                     const { value } = e.target;
                                                     setFieldValue(`mainTaskArray[${i}].typistName`, value.value);
                                                 }
                                                 }
                                                 disableField={true}
                                             // disabled={true}
                                             // classvalue={"textBold"}
                                             />
                                         )}
                                         { values?.requistionType === "Multiple" ?
                                             <InputText
                                             label="From Date"
                                             fieldName="fromDate"
                                             fieldValue={values?.requistionDate === "" && item?.sow_mainTask === "" ? "" : moment(values?.requistionDate).format("DD-MM-YYYY")}
                                             fieldHandleChange={(e) => {
                                                 e.preventDefault();
                                                 const { value } = e.target;
                                                 setFieldValue(`mainTaskArray[${i}].fromDate`, values?.requistionDate);
                                             }
                                             }
                                             disableField={true}
                                             requiredField={true}
                                         // classvalue={"textBold"}
                                         />
                                         :
                                           <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                            From Date<span style={{color: "red"}}>*</span>
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                                 <div style={getStyles(errors, item?.fromDate)} >
                                                     <div style={{ border: "0.1px solid #d3d3d3"}}  >
                                                            <DatePicker
                                                           //  label="Select Start Date"
                                                            // value={item?.fromDate}
                                                             selected={item?.fromDate}
                                                             minDate={addDays(new Date(), 0)}
                                                             maxDate={addDays(new Date(), Number(item?.taskDays))}
                                                             onChange={(value) => {
                                                                //  setFrom_date(value);
                                                                 if (new Date(to_date) >= new Date(value)) {
                                                                    setFieldValue(`mainTaskArray[${i}].fromDate`, value)
                                                                  } else {
                                                                    setFieldValue(`mainTaskArray[${i}].fromDate`, value)
                                                                    setFieldValue(`mainTaskArray[${i}].toDate`, "");
                                                                  }
                                                                  setFieldValue(`mainTaskArray[${i}].toDate`, "");
                                                                //  setFieldValue(`mainTaskArray[${i}].fromDate`, value)
                                                                const currentDate = new Date();
                                                                const diffInMilliseconds = value - currentDate;
                                                                const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
                                                                setFrom_date(diffInDays)
                                                             }
                                                             }
                                                            //   shouldDisableTime={() => {
                                                            //       return true;
                                                            //     }} 
                                                             dateFormat="dd-MM-yyyy"
                                                             filterDate={filterWeekends}
                                                             onChangeRaw={handleDateChangeRaw}
                                                            //  renderInput={(params) => (
                                                            //     <TextField  {...params} error={false}  inputProps={{readOnly: true}}/>
                                                            //   )}
                                                         />
                                                     </div>
                                                 </div>
                                             </Col>
                                         </Form.Group>
                                        }
                                         { values?.requistionType === "Multiple" ?
                                             <InputText
                                             label="To Date"
                                             fieldName="toDate"
                                             fieldValue={values?.requistionDate === ""  && item?.sow_mainTask === "" ? "" : moment(values?.requistionDate).format("DD-MM-YYYY")}
                                             fieldHandleChange={(e) => {
                                                 e.preventDefault();
                                                 const { value } = e.target;
                                                 setFieldValue(`mainTaskArray[${i}].toDate`, values?.requistionDate);
                                             }
                                             }
                                             disableField={true}
                                             requiredField={true}
                                         // disabled={true}
                                         // classvalue={"textBold"}
                                         />
                                         :
                                         <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                            To Date<span style={{color: "red"}}>*</span>
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                                 <div style={getStyles(errors, item?.toDate)} >
                                                     <div style={{ border: "0.1px solid #d3d3d3"}}  >
                                                  
                                                         <DatePicker
                                                           //  label="Select End Date"
                                                            // value={item?.toDate}
                                                             selected={item?.toDate}
                                                             minDate={addDays(new Date(), from_date)}
                                                             maxDate={addDays(new Date(), Number(item?.taskDays))}
                                                             onChange={(value) => {
                                                                 const Difference_In_Time = new Date(value).getTime() - new Date(item?.fromDate).getTime();
                                                                 const diffDays = Difference_In_Time / (1000 * 60 * 60 * 24);
                                                  
                                                                 setTo_date(value);
                                                                 if (new Date(item?.fromDate) <= new Date(value)) {
                                                                    setFieldValue(`mainTaskArray[${i}].toDate`, value);
                                                                  } else {
                                                                    setFieldValue(`mainTaskArray[${i}].toDate`, value);
                                                                    setFieldValue(`mainTaskArray[${i}].fromDate`, "")
                                                                  }
                                                                 setFieldValue(
                                                                     `mainTaskArray[${i}].reqDays`,
                                                                     Math.trunc(diffDays + 1)
                                                                 );
                                                             }
                                                             }
                                                            //  shouldDisableTime={() => {
                                                            //     return true;
                                                            //   }} 
                                                             dateFormat="dd-MM-yyyy"
                                                             filterDate={filterWeekends}
                                                             onChangeRaw={handleDateChangeRaw}
                                                            //  renderInput={(params) => (
                                                            //     <TextField {...params} error={false} />
                                                            //   )}
                                                            
                                                         />
                                                         
                                                     </div>
                                                 </div>
                                             </Col>
                                         </Form.Group>
                                          }
                                         <Form.Group as={Row} className="mb-1">
                                             <Form.Label column sm="5" className="fc-textarea-label">
                                             Remarks
                                             </Form.Label>
                                             <Col sm="7" style={{ padding: "0 14px 0 0" }} >
                                             <textarea
                                            // label="Remarks"
                                             name="remarks"
                                             value={item?.remarks}
                                             onPaste = {(event) => {
                                                event.preventDefault();
                                                const pastedText = (event.clipboardData || window.clipboardData).getData('text');
                                                const sanitizedValue = pastedText.replace(/[^\w\s!@#$%^&*()\-_=+[\]{};:'",.<>?/\\|`~\n\r]/g, '');
                                                const sanitizedText = sanitizedValue.replace(/\s+/g, ' ').trim();
                                                setFieldValue(`mainTaskArray[${i}].remarks`, sanitizedText);
                                            
                                             }}
                                             onChange={(e) => {
                                                 e.preventDefault();
                                                 const { value } = e.target;
                                                 const sanitizedValue = value.replace(/^[a-zA-Z0-9\s.,?!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/m, '');
                                                 const regex = /^[a-zA-Z0-9\s.,?!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/m;
                                                if (regex.test(value)) {
                                                 setFieldValue(`mainTaskArray[${i}].remarks`, value);
                                                }
                                                //  const sanitizedValue2 = sanitizedValue?.trim();
                                                //  setFieldValue(`mainTaskArray[${i}].remarks`, sanitizedValue);
                                             }}
                                             style={{width: "100%"}}
                                             rows="7"
                                         />
                                             </Col>
                                         </Form.Group>
                                        
                                     </Grid>

                                 </Grid>
                             </div>
                         );
                     })}
             </div>
            )}
        />
        </Grid>
          </div>
          }
              </Grid>
           </Grid>
               </div>
                <br />
                  </Grid>
                 </Grid>
                      <br />
                         <br />
                      <div className={classes.quotationButtonDivModal} >

                        <BootstrapTooltip arrow={true}
                        disableFocusListener
                        title={
                            errors.requistionType === undefined &&
                            errors.requistionDate === undefined &&
                            errors.taskName === undefined && errors.taskDays === undefined &&
                            errors.taskSequence === undefined && errors.uploadDoc === undefined
                            && errors.fromDate === undefined && errors.toDate === undefined
                            && errors.typeofwork === undefined && errors.amount === undefined
                            && errors.clientDate_time === undefined
                            && errors.remarks === undefined
                            ? ""
                            :
                            `
                            ${errors.requistionType !== undefined ? `${errors.requistionType},` : ""}
                             ${errors.requistionDate !== undefined ? `${errors.requistionDate},` : ""}
                            ${errors.taskName !== undefined ? `${errors.taskName},` : ""}
                            ${errors.taskDays !== undefined ? `${errors.taskDays},` : ""}
                            ${errors.taskSequence !== undefined ? `${errors.taskSequence},` : ""}${errors.uploadDoc !== undefined ? `${errors.uploadDoc},` 
                            : ""}${errors.fromDate !== undefined ? `${errors.fromDate},` : ""}${errors.toDate !== undefined ? `${errors.toDate},`
                            : ""}${errors.typeofwork !== undefined ? `${errors.typeofwork},`: ""}${errors.amount !== undefined ? `${errors.amount},`
                            : ""}
                            ${errors.clientDate_time !== undefined ? `${errors.clientDate_time},`: ""}
                            ${errors.remarks !== undefined ? `${errors.remarks},`: ""}
                            `
                                }
                            >
                          <Button
                              type="submit"
                              variant="contained"
                              size="small"
                              style={{ backgroundColor: "#eb6e12" }}
                              onClick={handleSubmit}
                              disabled={
                                pendingData?.error_msg === "Company doesn't exist on Zoho"
                                  || checkIsWorkDoneOrNot === "true" 
                                  || checkDuplicateSowMainTask() === "true" 
                                  ||  (requistion_Type === "Single" && values?.mainTaskArray?.length > 1)
                                  ? true 
                                  : false}
                          >
                              {"Submit"}
                          </Button>
                          </BootstrapTooltip>

                          <Button
                              onClick={() => {
                                  handleModaleClose();
                                  // handleHide()
                              }}
                              variant="contained"
                              size="small"
                              style={{
                                  backgroundColor: "#fff",
                                  color: "#eb6e12",
                                  marginLeft: "10px",
                              }}
                          >
                              Cancel
                          </Button> <br />
                          {checkIsWorkDoneOrNot === "true" ?
                          <span style={{color: "red", fontSize: "15px"}}>Pending task is need to be finished before adding new task</span>
                           : ""
                        }
                         {checkDuplicateSowMainTask() === "true" ?
                          <span style={{color: "red", fontSize: "15px"}}>Main Task should be selected one time</span>
                           : ""
                        }
                        { requistion_Type === "Single" && values?.mainTaskArray?.length > 1 ?
                          <span style={{color: "red", fontSize: "15px"}}>You add only one requisition on requisition type Single</span>
                           : ""
                        }
                      </div>
                     
                  </div>
                  <div style={{marginTop: "10px"}}>
                  <PendingRequistionListing pendingReqListing={pendingReqListing} />
                  </div>
              </div>
          </Box>
      </form>
         );
         }}
      </Formik>
        </>
    );
};

export default withStyles(ModalPopperFormStyles)(AddRequisitionModelForm);
