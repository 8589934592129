import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  FaFileUpload, FaFileMedical, FaEye, FaSync, FaTimes, FaHandshake, FaIdBadge, FaAdn, FaSortAmountDown, FaCheckDouble, FaReply
} from "react-icons/fa";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModelPopper";
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import ApprovalIcon from '@mui/icons-material/Approval';
import GradingIcon from '@mui/icons-material/Grading';
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import { Grid } from "@material-ui/core";
import axios from "axios";
import Select from 'react-select';
import { CustomSelect, CustomMultiSelect } from "../../../listing/listingForm/ListingForm";
import { Modal } from "@mui/material";
import SubmitModal from "../Modals/SubmitModal";






const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReAssignOrderListTable = ({
  apiData,
  upperIcons,
  lowerIcons,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop, fitlerBadge
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setEditModalPopper,
    handleModalOpen,
    setAcceptAccountArray,
    getPropertyFormDataApi,
    message, severity, setSeverity,
    setMessage, setLoading,
    isOpen, setIsOpen,
    getAdminCompalinFormDataApi, adminCompalinFormData,
    getAdminOrderListgDataApi, adminOrderListgData,
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState();
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [supportName, setSupportName] = useState();
  const [supportDetails, setSupportDetails] = useState({});
  const [filterSupportId, setFilterSupportId] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  // let getSupportId = filterSupportId?.map((value) =>
  // value?.value.toString(),
  // );
//   let opt;
//   for (let i = 0; i < getSupportId.length; i++) {

//      opt = getSupportId[i];
// }

  useEffect(() => {
    getAdminOrderListgDataApi();
    handleListingSavedSearch();
    getAdminCompalinFormDataApi();
    getBulkOrdersApi();
  }, [filterSupportId]);

  const EmployeeList = adminCompalinFormData?.employee_list?.map((item) => {
    const obj = { value: item.EmpId, label: item.Emp_Name };
    return obj;
  });

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue.split(" ");
      if (dateAsString == null) {
        return -1;
      }
      if (dateAsString == "") {
        return -1;
      }
      const dateParts = dateAsString[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const paramsExpendableRows = (params) => {
    return {
      getDetailRowData: function (params) {
        params.successCallback(params.data.callRecords);
      },
    };
  };

  const columnDefs = [
    {
      headerName: "Sr.",
      field: "Serial_Number",
      //cellRenderer: "agGroupCellRenderer",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
      width: 120,
      pinned: "left",
      filter: false
    },
    {
      headerName: "Support Name",
      field: "Support_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      pinned: "left",
    },
   
    {
      headerName: "Order No",
      field: "Order_No",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 140,
      pinned: "left",
    },
    {
      headerName: "Order Status",
      field: "Order_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      pinned: "left",
    },

    {
      headerName: "Order Type",
      field: "Type_Of_Order",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      pinned: "left",
    },
    {
      headerName: "Order Sub Type",
      field: "order_sub_type",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 170,
      // pinned: "left",
    },
    {
      headerName: "Order Name",
      field: "Order_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 230,

    },

    {
      headerName: "Company Name",
      field: "Company_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,

    },
    {
      headerName: "Point of Contact",
      field: "Point_of_Contact",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 300,
    },
    {
      headerName: "Order Creation Date",
      field: "Order_Creation_Date",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      filter: "agSetColumnFilter",
      width: 150,
    },
    {
      headerName: "Order Assign Date",
      field: "Company_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Order Start Date",
      field: "Order_Start_Date",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Expected Order Completion Date",
      field: "Order_Completion_Date",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
    },
    {
      headerName: "Last Requisition",
      field: "Last_Requisition",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Requisition;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Last Followup",
      field: "Last_Followups",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 200,
      cellRenderer: (params) => {
        params = params?.data?.Last_Followups;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Tag Name",
      field: "Tag_Names",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 250,
      cellRenderer: (params) => {
        params = params?.data?.Tag_Names;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Typist Name",
      field: "Typist_Name",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },

    {
      headerName: "Support Status",
      field: "Support_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Accounts Status",
      field: "Accounts_Status",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Freez Order Status",
      field: "Freeze_Approval",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.Freeze_Approval !== "No Data" ? (
              <>{params?.data?.Freeze_Approval}<br />
                {params?.data?.Freeze_Approval_Date}</>
            ) : params?.data?.Freeze_Approval}
          </>
        )
      }
    },
    {
      headerName: "Complete Order Status",
      field: "Complete_Order_Status_By_Admin",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Work in Progress",
      field: "Work_in_Progress",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Work_in_Progress;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },
    {
      headerName: "Sub Activities",
      field: "Sub_Activities",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "State",
      field: "State",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
    {
      headerName: "Formation",
      field: "Formation",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
      cellRenderer: (params) => {
        params = params?.data?.Formation;
        return (
          <p
            style={{
              padding: "0 5px",
              margin: "0 2rem 1rem 0",
            }}
            dangerouslySetInnerHTML={{ __html: params }}
          />
        );
      },
    },

    {
      headerName: "Trade Names",
      field: "Trade_Names",
      cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      width: 150,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue.split(" ");
            if (dateAsString == null) {
              return 0;
            }
            const dateParts = dateAsString[0].split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
      },
    };
  }, []);



  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    //   {
    //     id: 1,
    //     title: "Default",
    //     value: null,
    //     key: null,
    //   },
    //   {
    //     id: 2,
    //     title: "UnderProcess",
    //     value: "Underprocess",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 3,
    //     title: "Accounts Rejected",
    //     value: "Accounts Rejected",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 4,
    //     title: "Freeze Approval Pending",
    //     value: "Freeze Approval Pending",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 5,
    //     title: "Freezed",
    //     value: "Order Freezed",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 6,
    //     title: "Completion Approval Pending",
    //     value: "Completion Approval Pending",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 7,
    //     title: "Admin Approval Pending",
    //     value: "Admin Approval Pending",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 7,
    //     title: "Pending From Accounts",
    //     value: "Pending From Accounts",
    //     key: "Order_Status",
    //   },
    //   {
    //     id: 7,
    //     title: "Overdue",
    //     value: "Overdue",
    //     key: "Order_Status",
    //   },
  ];


  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleFilters = (title, value, key) => {
   
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.onFilterChanged();
    if (key === null) {
      gridRef.current.api.setFilterModel(null);
    } else if (key === "Enquiry_Assigned_Date") {
      gridRef.current.api.getFilterInstance("Enquiry_Assigned_Date").setModel({
        type: "contains",
        dateFrom: value,
        dateTo: null,
      });
      gridRef.current.api.onFilterChanged();
    } else if (key === "}") {
    } else {
      gridRef.current.api
        .getFilterInstance(key.toString())
        .setModel({ values: [value.toString()] });
      gridRef.current.api.onFilterChanged();
    }

    setDefaultFilterValue(title);
  };

  const getTotalLength = (value, key) => {
    if (filterOrders === null || (key === null && value === null)) {
      return;
    } else if (key === null) {
      return filterOrders.length;
    } else if (key === "Enquiry_Assigned_Date") {
      return filterOrders.filter((val) => val[key].substring(0, 10) === value)
        .length;
    } else {
      return filterOrders.filter((val) => val[key] === value).length;
    }
  };

  const handleAssignBulkOrdersApi = async (setModalLoading) => { 
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");

    // https://flyingcolour.online/fcapp/docprocessing_api/orderbulkreassigntosupport/

    const url = `https://flyingcolour.online/fcapp/docprocessing_api/orderbulkreassigntosupport/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };
    let orderIdArray = [];
    rowSelected?.map((value) => {
      orderIdArray.push(value?.Order_Id.toString());
    })

    let body = {
      assign_to: supportName,
      order_id_array: orderIdArray,
      emp_id:emp_id,
      user_type:user_type,
    }
    axios.post(url, body, { headers: headers }).then((data) => {
      setLoading(false);
      setModalLoading(false);
      setOpenModal(false);
      setRowSelected('');
      if (data?.data?.message) {
        setMessage(`${data?.data?.message}`);
        setSeverity(500)
      }
      else {
        setMessage(data?.data?.data?.msg);
        setSeverity(data?.data?.data?.code)
      }
      setIsOpen(true);
      // handlePromise(data);
    })
  }

  const getBulkOrdersApi = async () => {
    const __token = localStorage.getItem("token");
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");

    // let getSupportId = filterSupportId?.map((value) =>
    //                   value?.value.toString(),
    //                   );

    // https://flyingcolour.online/fcapp/docprocessing_api/orderbulkreassigntosupport/
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/orderbulkreassigntosupport/?support_ids=${filterSupportId?.toString()}&emp_id=${emp_id}&user_type=${user_type}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setRowSelected('');
      setFilterOrders(res?.data?.Data?.order_list);
      //setLoading(false);
    } catch (err) {
      console.error(err);
    }

  }

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName) => {
    handleFilters(searchName, searchName, "Inquiry_Id")
  }
  const rowSelectionType = "multiple"
  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedRows();
    setRowSelected(rowData);
  };

  const handleAssignSubmit = (setModalLoading) => {
    handleAssignBulkOrdersApi(setModalLoading)
  }
 
  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      <div className="listing-filters"  style={{ padding: "1rem", background: "#fff" }}>
        {/* {rowSelected == undefined || rowSelected == '' ? */}
          {/* <span style={{ color: "#eb6e12" }}>Please filter and select Order to Re-assign</span>
          : */}

          <Grid container>
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
           
              <Select
               placeholder="Select Support Name to Filter Orders"
               
               styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#eb6e12' : '#eb6e12',
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? "#fff" : state.isDisabled ? "#A9A9A9" : "black",
                  backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "#FFD580",

                  },
                }),
                singleValue: (provided, state) => {
                  const opacity = state.isDisabled ? 0.5 : 1;
                  const transition = "opacity 300ms";
                  return { ...provided, opacity, transition };
                },
              }}
                width={"100%"}
                longWidth={"100%"}
                onChange={(value) => {
                  setFilterSupportId(value.value);
                  setSupportDetails({
                    ...supportDetails,
                    supportName: value.label
                  })
                }}
                options={EmployeeList}
              />
            </Grid>
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
            
              <Select
                placeholder="Select Support Name To Assign Orders"
                options={EmployeeList}
                minMenuHeight={100}
                maxMenuHeight={150}
                menuPlacement="bottom"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? '#eb6e12' : '#eb6e12',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "#fff" : state.isDisabled ? "#A9A9A9" : "black",
                    backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#FFD580",
                    },
                  }),
                  singleValue: (provided, state) => {
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = "opacity 300ms";
                    return { ...provided, opacity, transition };
                  },
                }}
                onChange={(value) => {
                  setSupportName(value.value);
                  setSupportDetails({
                    ...supportDetails,
                    assignSupportName: value.label
                  })
                }}
                isOptionDisabled={(option) => 
                  option.value.toString() ==  filterSupportId?.toString()
                    // option.value.toString() ==  getSupportId.toString()   
                }
              />
              {/* <CustomDropdown
                label="Assign To:"
                fieldOptions={EmployeeList}
                fieldValue={supportName}
                fieldHandleChange={(value) => {
                    setSupportName(value.value);
                  // fetchListingDataFromApi();
                }}
                isOptionDisabled={(option) => option.value.toString()===support_id}
              /> */}<br/>
              
            </Grid>


            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#eb6e12",
                  fontFamily: "Times New Roman !important",
                  color: "#fff",
                  width: "120px",
                  height: '37px',
                  marginTop: '20x'
                }}
                onClick={() => {
                  setOpenModal(true);
                }}
                disabled={
                  rowSelected == undefined || rowSelected == '' ? true :  supportName == undefined || supportName == '' ? true: false
                }
              >
                Assign
              </Button>
            </Grid>


          </Grid>
      </div>
      {
        fitlerBadge ? (
          <>
            <div style={{ display: "flex", marginLeft: "10px", marginTop: "-15px", paddingBottom: "15px" }}>
              <LeadsFilterAdvancedMain
                tableUpperButtonArr={tableUpperButtonArr}
                apiData={filterOrders}
                gridRef={gridRef}
                tabValue={tabValue}
                setSavedValue={setSavedValue}
                savedValue={savedValue}
                defaultFilterValue={defaultFilterValue}
                handleListingSavedSearchAPI={handleListingSavedSearch}
                handleFilter={handleSearch}
              />
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                Filters:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                  flexWrap: "wrap",
                  color: "#008000",
                  marginLeft: "15px",
                  fontFamily: 'Times New Roman'
                }}
              >
                {filterOrders?.length > 0 &&
                  filters.map((val, i) => {

                    return (
                      <Badge
                        key={i}
                        badgeContent={getTotalLength(val.value, val.key)}
                        color="warning"
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          marginBottom: "8px",
                          cursor: "pointer",
                          fontFamily: 'Times New Roman'
                        }}
                        onClick={() => handleFilters(val.title, val.value, val.key)}
                      >
                        <span>{val.title}</span>
                      </Badge>
                    );
                  })}
                {savedSearchList?.length > 0 &&
                  savedSearchList?.map((val, i) => {
                    const value = val.Search_Filter?.split("{")[1];
                    const valueKey = value
                      ?.split(":")[0]
                      ?.replaceAll('"', "")
                      ?.split("_1")[0];
                    return (
                      <Badge
                        key={i}
                        color="warning"
                        badgeContent={getTotalLength(val.Search_Name, valueKey)}
                        max={9999999}
                        showZero
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          color: "#fff",
                          marginLeft: "15px",
                          background: "#EB6E12",
                          borderRadius: "20px",
                          padding: "5px 10px",
                          // marginBottom: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleFilters(val.Search_Name, val.Search_Name, valueKey);
                          // setSavedValue(val.val.Search_Name);
                          setDefaultFilterValue(val.Search_Name);
                        }}
                      >
                        <span>{val.Search_Name}</span>
                      </Badge>
                    );
                  })}
              </div>
            </div>

          </>

        ) : null
      }
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={filterOrders?.length}
      />
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
          ref={gridRef}
          rowData={filterOrders}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          masterDetail={true}
          //detailCellRenderer={renderExpandableRow}
          columnTypes={columnTypes}
          animateRows={true}
          onSelectionChanged={onRowSelected}
          rowSelection={rowSelectionType}
          components={components}
          detailCellRendererParams={paramsExpendableRows}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          rowHeight={90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <ModalPopper />
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose} >
        <Alert onClose={handleClose} severity={severity === 200 ? "success" : "error"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
            <SubmitModal msg={"Are you sure?"} supportDetails={supportDetails} rowSelected={rowSelected} setOpenModal={setOpenModal} handleSubmit={handleAssignSubmit} />
        </>
      </Modal>
    </div>
  );
};

export default ReAssignOrderListTable;
