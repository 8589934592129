import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import CommonPageSelect from "../../../../commonComp/commonPageSelect";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
  import { DataContext, SupportDataContext } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import Badge from "@mui/material/Badge";
  import ModalPopper from "../Modals/ModelPopper";
  import { getTotalLength, handleDefaultFilters, handleFilters } from "../../../../utils/Filters";
  import ArticleIcon from "@mui/icons-material/Article";
  import EditIcon from '@mui/icons-material/Edit';
  import HistoryIcon from "@mui/icons-material/History";
  import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Grid } from "@material-ui/core";
import CustomDropdown from "../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import { Button } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import { CustomSelect } from "../../../listing/listingForm/ListingForm";
import axios from "axios";
import Select from 'react-select';



  
  
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const ViewShareListTable = ({
    apiData,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop, fitlerBadge, classes
  }) => {
    const {
      handleModalTableOpen,
      setButtonText,
      setOperationInfo,
      setViewRequistion,
      setSelected,
      setIsOpen,isOpen,
      message,
      handleModalOpen,
      getShareListApi, shareData
    } = useContext(SupportDataContext);
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const todayDate = moment().format("YYYY-MM-DD");
    const [reqToDate, setReqToDate] = useState("");
    const [partnerId, setPartnerId] = useState("");

//   const PartnerOptions = shareData?.Partner_List?.map((item) => {
//     const obj = { value: item.Id, label: item.Name };
//     return obj;
//   });

    useEffect(() => {
      handleListingSavedSearch();
      getShareListApi();
    }, [])
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
    // const upperIcons = [
    //   {
    //     icon: <EditIcon style={{ margin: "0", fontSize: "17px" }} />,
    //     popper: "Edit Requestion",
    //     btnText: "Edit Requestion",
    //   },
    //   {
    //     icon: <HistoryIcon style={{ margin: "0", fontSize: "17px" }} />,
    //     popper: "Requestion Log",
    //     btnText: "Requestion Log",
    //   },
    //   {
    //     icon: <ArticleIcon className={classes.iconImg} style={{ margin: "0" }} />,
    //     popper: "Order Details",
    //     btnText: "Order Details",
    //   }
    // ];
  
  
    const actionBtnRenderer = (params) => {
      let data = params?.data;
      return (
        <>
          <div>
            <CustomIconButton
              title="Edit Requestion"
              disabled={
                data?.req_proHead_or_not != "accept" && data?.req_amt == 0 ||
                data?.req_acc_or_not != "accept" && data?.pro_acc_or_not != "done" 
                && data?.pro_acc_or_not != "accept" && 
                data?.req_proHead_or_not != "accept" && data?.req_amt > 0 
                || data?.req_acc_or_not == "reject" &&
                data?.pro_acc_or_not != "reject" && data?.req_proHead_or_not != "reject"
                ? false: true
              }
              icon={
                <EditIcon
                  onClick={() => {
                   // handleModalOpen();
                    handleModalTableOpen();
                    setButtonText("Edit Requestion");
                    // scrollToTop()
                    ScrollTop();
                    setOperationInfo(params.data);
                    setViewRequistion(params.data);
                    
                  }}
  
                />
              }
            /> &nbsp;
            <CustomIconButton
              title="Requestion Log"
              disabled={
                params.data?.Status === "converted" ? true : false
              }
              icon={
                <HistoryIcon
                  onClick={() => {
                    handleModalTableOpen("Requestion Log", params.data);
                    setButtonText("Requestion Log");
                    // scrollToTop()
                    ScrollTop();
                    setOperationInfo(params.data);
                    
                  }}
  
                />
              }
            /> &nbsp;
            <CustomIconButton
              title="Order Details"
              disabled={
                params.data?.Status === "converted" ? true : false
              }
              icon={
                <ArticleIcon
                  onClick={() => {
                    handleModalTableOpen();
                    setButtonText("Order Details");
                    // scrollToTop()
                    ScrollTop();
                    setOperationInfo(params.data);
                    
                  }}
  
                />
              }
            />
            {/* {
  
  
  
                upperIcons &&
                upperIcons?.map((item, i) => {
                  let rowIndex = params?.node?.rowIndex;
                  return (
                    <>
                      <CustomIconButton
                        key={item.id}
                        onClick={() => {
                          handleModalTableOpen(item.btnText, params.data);
                          setButtonText(item.btnText);
                          // scrollToTop()
                          ScrollTop();
                          setOperationInfo(params.data);
                        }}
                        title={item.btnText}
                        size="small"
                        style={{
                          border: "1px solid #eb6e12",
                          borderRadius: "5px",
                          fontSize: "12px",
                          marginRight: "6px",
                          color: "#fff",
                          background: "#eb6e12",
                          height: "30px",
                          marginTop: "3px",
                          width: "30px",
                        }}
                        className="btnActionAlignment"
                        icon={item.icon}
                      />
                    </>
                  );
                })
  
            } */}
          </div>
        </>
      );
    };
  
    const documentItems = (params) => {
        let data = params?.data.Passport;
        
        
        return data ? (
          <>
            <Button
              style={{
                marginLeft: ".4rem",
                textDecorationColor: "#eb6e12",
                background: "none",
                cursor: "pointer",
                border: "none",
                padding: "6px",
              }}
              onClick={() => window.open(data)}
            >
              View
            </Button>
          </>
        ) : null;
      };
     const dateFilterParams = {
    inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          const dateParts = dateAsString.slice(0, 10).split("-");
          const year = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const day = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      };
  
  
      const columnDefs = [
        {
          headerName: "S #",
          field: "Serial_Number",
          cellRenderer: "agGroupCellRenderer",
          //checkboxSelection: true,
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", textAlign: "center" },
          width: 80,
          pinned: "left",
          filter: false
        },
      //   {
      //     headerName: "Actions",
      //     field: "Inquiry_Id",
      //     filter: false,
      //     // checkboxSelection: true,
      //     cellRenderer: actionBtnRenderer,
      //     cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
      //     minWidth: 170,
      //     pinned: "left",
      //   },
     
      {
          headerName: "Partner Name",
          field: "Partner_Name",
          filter: "agTextColumnFilter",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          width: 210,
          pinned: "left",
        },
        {
          headerName: "Created Date",
          field: "Created_Date_New",
          filter: "agDateColumnFilter",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 180,
          pinned: "left",
          filterParams: dateFilterParams,
          cellRenderer: (params) => {
            return <>{moment(params?.data?.Created_Date_New).format("DD-MM-YYYY")}</>;
          },
        },
        {
          headerName: "Company Name",
          field: "Company_Name",
          filter: "agTextColumnFilter",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          width: 210,
        },
        {
          headerName: "No. of Share",
          field: "Nos_of_Share",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          width: 150,
        },
        {
          headerName: "Role In License",
          field: "Role_In_License",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 180,
        },
        {
          headerName: "Gender",
          field: "gender",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", },
          width: 150,
          // pinned: "left",
        },
        {
          headerName: "Email",
          field: "Email_Id",
          cellStyle: { border: "1px solid #fff", overflowY: "auto" },
          width: 250,
          cellRenderer: (params) => {
            const myEmail = `mailto:${params?.data?.Email_Id}`
            return (
              <div>
                <button
                style={{
                  backgroundColor: "#E5E4E2",
                  border: "none",
                  color: " #0000FF",
                  textDecoration: "none",
                  width: "100%",
                  fontSize: "14px"
                }}
                onClick={() => window.open(myEmail, "_blank")}
              >
              {params?.data?.Email_Id}
              </button>
              </div>
            );
          },
        },
        {
          headerName: "Mobile No",
          field: "Mobile",
          cellStyle: { border: "1px solid #fff" },
          width: 170,
          cellRenderer: (params) => {
            let VisitorPhoneNo = params?.data?.Mobile && params?.data?.Mobile?.replace(/-/g, "");
            if (VisitorPhoneNo.startsWith("+")) {
                VisitorPhoneNo = VisitorPhoneNo.substring(1);
             }
            const myWhatsApp = `https://api.whatsapp.com/send?phone=${VisitorPhoneNo}`
            return (
              <div>
                <button
                style={{
                  backgroundColor: "#E5E4E2",
                  border: "none",
                  color: " #0000FF",
                  textDecoration: "none",
                  width: "100%",
                  fontSize: "14px"
                }}
                onClick={() => window.open(myWhatsApp, "_blank")}
              >
               {params?.data?.Mobile}
              </button>
              </div>
            );
          },
        },
        {
          headerName: "DOB",
          field: "DOB",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          width: 200,
        },
    
        {
          headerName: "Partner Compliance Status",
          field: "partner_compliance_status",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          width: 180,
        },
        {
          headerName: "Worldcheck Compliance Status",
          field: "worldcheck_compliance_status",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          minWidth: 180,
          flex:1,
        },
        {
          headerName: "Passport",
          field: "worldcheck_compliance_status",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          minWidth: 130,
          flex:1,
          cellRenderer: documentItems,
        },
        {
          headerName: "Passport Expiry",
          field: "Passport_expiry",
          cellStyle: { borderBottom: "1px solid #fff", borderRight: "1px solid #fff", overflowY: 'auto' },
          minWidth: 180,
          flex:1,
        },
        
       
      ];
   
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = cellValue.split("/");
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      };
    }, []);
  
    const onRowSelected = (event) => {
      const rowData = event.api.getSelectedNodes()[0]?.data;
      setSelected([rowData]);
    };
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const handleGotoPage = (e) => {
      const value = e.target.value - 1;
      gridRef.current.api.paginationGoToPage(Number(value));
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  

    const handleListingSavedSearch = async () => {
      const __token = localStorage.getItem("token");
      const emp_id = localStorage.getItem("emp_id");
      const factory_id = localStorage.getItem("factory_id");
      await fetch(
        `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${__token}`,
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSavedSearchList(data.results?.Response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };


    const handleSearch = (searchName, value, searchKey) => {
      handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey)
    }
  
    return (
      <div className="listing-data-grid" style={{ height: "500px" }}>
      
      {/* <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
           
              <Select
               placeholder="Select Partner Name to Filter"
               
               styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#eb6e12' : '#eb6e12',
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? "#fff" : state.isDisabled ? "#A9A9A9" : "black",
                  backgroundColor: state.isSelected ? "#eb6e12" : "#fff",
                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "#FFD580",

                  },
                }),
                singleValue: (provided, state) => {
                  const opacity = state.isDisabled ? 0.5 : 1;
                  const transition = "opacity 300ms";
                  return { ...provided, opacity, transition };
                },
              }}
                width={"100%"}
                longWidth={"100%"}
                onChange={(value) => {
                  setPartnerId(value.value)
                }}
                options={PartnerOptions}
              />
            </Grid>
          
            <Grid item sm={12} md={4} style={{ paddingRight: "1rem", }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#eb6e12",
                  fontFamily: "Times New Roman !important",
                  color: "#fff",
                  width: "120px",
                  height: '37px',
                  marginTop: '20x'
                }}
                onClick={() => {
                 getShareListApi()
                }}
                // disabled={
                //    partnerId == undefined || partnerId == '' ? true: false
                // }
              >
                Filter
              </Button>
            </Grid>


         </Grid>
 </div> */}

        {
          fitlerBadge ? (
            <>
              <div style={{ display: "flex", marginLeft: "10px", marginTop: "30px", paddingBottom: "15px" }}>
                <LeadsFilterAdvancedMain
                  tableUpperButtonArr={tableUpperButtonArr}
                  apiData={apiData}
                  gridRef={gridRef}
                  tabValue={tabValue}
                  setSavedValue={setSavedValue}
                  savedValue={savedValue}
                  defaultFilterValue={defaultFilterValue}
                  handleListingSavedSearchAPI={handleListingSavedSearch}
                  handleFilter={handleSearch}
                />
              </div>
  
            </>
  
          ) : null
        }
       
        <CommonPageSelect
          search
          onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
          searchValue={searchValue}
          onPageSizeChanged={onPageSizeChanged}
          handleGotoPage={handleGotoPage}
          fromRows={fromRows}
          toRows={toRows}
          length={apiData?.length}
        />
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
  
  <AgGridReact
suppressExcelExport={true}
suppressCsvExport={true}
            ref={gridRef}
            rowData={shareData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 90}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <ModalPopper />
        <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  };
  
  export default ViewShareListTable;
  