import React, { useState, useContext, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn, FieldArray } from "formik";
import axios from "axios";
import modalTableStyles from "../../styles/ModalTableStyles";
import {
  SupportDataContext,
  DataContext,
} from "../../../../../provider/context";
import InputText from "../../../../../reusableComponents/InputText/InputText";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CustomMultiSelectDropdown from "../../../../../reusableComponents/CustomMultiSelectDropdown/CustomMultiSelectDropdown";
import { CKRictTextEditer } from "../../../../listing/listingForm/RichTextEditer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const getStyles = (errors, fieldName, height) => {
  if (height && !getIn(errors, fieldName)) {
    return { height: height };
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height,
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
};

const AddOrEditProjectList = ({ classes, showAddOrder, handleSaveProjectValue, nofSetFieldValue, nofValues }) => {
  const {
    handleModalTableClose,
    setLoading,
    loading,
    setMessage,
    setSeverity,
    editModalPopper,
    setIsOpen,
    getProjectListDataApi,
    operationInfo,
    setOperationInfo,
    setButtonText
  } = useContext(SupportDataContext);
  const [projectListDropdown, setProjectListDropdown] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [openLoading, setOpenLoading] = useState(false);
  const [
    isDefaultValueCheckedSubTemplate,
    setIsDefaultValueCheckedSubTemplate,
  ] = useState(false);
  const [selectetOrderSubTemplate, setSelectedOrderSubTemplate] = useState([]);
  const [sowSubTemplateList, setSowSubTemplateList] = useState([]);
  const [company_Name, setcompanyName] = useState("");

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#d11616",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#d11616",
    },
  }));

  const companyNameDropdown = projectListDropdown?.Company_List?.map(
    (item, i) => {
      const obj = { value: item.Company_Id, label: item.Company_Name };
      return obj;
    }
  );

  const [templateSubOrderListDropdown, setTemplateSubOrderListDropdown] =
    useState(
      projectListDropdown?.Service_Sub_Template_List?.length !== 0 &&
        projectListDropdown?.Service_Sub_Template_List !== undefined
        ? projectListDropdown?.Service_Sub_Template_List?.map((i) => ({
            id: i.Service_Id,
            value: `${i.Service_Id}`,
            label: `${i.Service_Name}`,
            details: i?.Service_Details,
            taskList: i?.Task_List,
          }))
        : []
    );

  const SupportNameDropdown = projectListDropdown?.Support_List?.map(
    (item, i) => {
      const obj = { value: item?.EmpId, label: `${item?.Emp_Name} (${Number(item?.Overdue_order_count + item?.Underprocess_order_count + (item?.Notnoted_order_count || 0))})` };
      return obj;
    }
  );

  const addProjectPOSTPUTAPI = async (values) => {
    const user_type = localStorage.getItem("user_type");
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const factory_id = localStorage.getItem("factory_id");
    const user_name = localStorage.getItem("Employee_First_Name");
    // https://flyingcolour.online/fcapp/compliance/checkverificationonpartner/
    const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectmaster/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    let selectedSubTemplate = values?.selectedSubTemplate?.map((val) => {
      return val.label;
    });

    const formData = new FormData();
    formData.append("emp_id", Number(emp_id));
    formData.append("user_type", user_type);
    formData.append("factory_id", factory_id);
    formData.append("company_id", values?.companyName);
    formData.append("company_name", company_Name);
    formData.append("support_id", values?.supportName);
    formData.append("active_inactive", values?.status);
    formData.append("order_sub_template", JSON.stringify(selectedSubTemplate));
    formData.append(
      "scope_of_work_for_sub_template",
      JSON.stringify(sowSubTemplateList)
    );

    if (editModalPopper) {
      formData.append("project_id", operationInfo?.project_id);
      axios.put(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        getProjectListDataApi();
        handleModalTableClose();
      });
    } else {
      axios.post(url, formData, { headers: headers }).then((data) => {
        setLoading(false);
        if (data?.data?.message) {
          setMessage(`${data?.data?.message}`);
          setSeverity(500);
        } else {
          setMessage(data?.data?.data?.msg);
          setSeverity(data?.data?.data?.code);
        }
        setIsOpen(true);
        getProjectListDataApi();
        handleModalTableClose();
      });
    }
  };

  const getProjectListDropdownapi = async () => {
    setOpenLoading(true);
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const user_type = localStorage.getItem("user_type");
    try {
      const url = `https://flyingcolour.online/fcapp/Operation_Order_Template/projectmaster/?user_type=${user_type}&emp_id=${emp_id}&company_id=${operationInfo?.company_id}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      setProjectListDropdown(res?.data?.Data);

      setTemplateSubOrderListDropdown(
        res?.data?.Data?.Service_Sub_Template_List?.length !== 0 &&
          res?.data?.Data?.Service_Sub_Template_List !== undefined
          ? res?.data?.Data?.Service_Sub_Template_List?.map((i) => ({
              id: i.Service_Id,
              value: `${i.Service_Id}`,
              label: `${i.Service_Name}`,
              details: i?.Service_Details,
              taskList: i?.Task_List,
            }))
          : []
      );
      setOpenLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (editModalPopper && templateSubOrderListDropdown?.length > 0) {
      setIsDefaultValueCheckedSubTemplate(true);
      selectedSubtemplateValue(operationInfo);
    }
  }, [templateSubOrderListDropdown]);

  useEffect(() => {
    getProjectListDropdownapi();
  }, []);

  const selectedSubtemplateValue = (data) => {
    setInitialLoad(true);
    let selecSubTemplates = [];
    let finalTaskList = [];
    data?.scope_of_work_for_sub_template?.forEach((item) => {
      let findValue = templateSubOrderListDropdown?.find(
        (val) => val.label === item.Sub_Template_Name
      );
      let findExistValue = selecSubTemplates.find(
        (val) => val.label === item.Sub_Template_Name
      );
      if (findExistValue) {
        finalTaskList = [...findExistValue.taskList];
      } else {
        finalTaskList = [];
      }
      if (findValue) {
        let obj = {
          Task_Details: item?.Description,
          Sub_Template_Id: item?.Sub_Template_Id,
          Sub_Template_Name: item?.Sub_Template_Name,
          Service_Task_Id: item?.Service_Task_Id,
        };
        finalTaskList.push(obj);
        findValue.taskList = finalTaskList;
        if (findExistValue === undefined) {
          selecSubTemplates.push(findValue);
        }
      }
    });

    //let filteredValue = removeDuplicateObjects(selecSubTemplates, "Service_Task_Id");
    setSowSubTemplateList(data?.scope_of_work_for_sub_template);
    setSelectedOrderSubTemplate(selecSubTemplates);
    setInitialLoad(false);
  };


  const addBorderToTables = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const tables = doc.querySelectorAll('table');
    tables.forEach((table) => {
        table.setAttribute('border', '1');
        const tds = table.querySelectorAll('td');
        tds.forEach((td) => {
            td.style.padding = '5px';
        });
    });

    return new XMLSerializer().serializeToString(doc);
};
  return (
    <>
      <Formik
        enableReinitialize={editModalPopper ? true : false}
        initialValues={{
          companyName: editModalPopper ? operationInfo?.company_id : showAddOrder ? nofValues?.companyId : "",
          companyLabel: showAddOrder ? nofValues?.companyName : "",
          selectedSubTemplate: editModalPopper ? selectetOrderSubTemplate : showAddOrder ? nofValues?.selectedSubTemplate : "",
          selectedSubTemplateId: editModalPopper
            ? selectetOrderSubTemplate
            : showAddOrder ? nofValues?.selectedSubTemplateId :"",
          scopeOfWorkSubTemplate: editModalPopper
            ? operationInfo?.scope_of_work_for_sub_template
            : showAddOrder ? nofValues?.scopeOfWorkSubTemplate : [],
          supportName: editModalPopper ? operationInfo?.support_id : showAddOrder ? nofValues?.supportId : "",
          supportLabel: showAddOrder ? nofValues?.supportName : "",
          status: "Active",
        }}
        onSubmit={async (data) => {
          setLoading(true);
          addProjectPOSTPUTAPI(data);
        }}
        validate={(values) => {
          // setDisabledId(values?.mainTaskArray)
          let errors = {};
          if (values.companyName === "") {
            errors.companyName = "Required!!";
          }
          if (values.subTemplate === "") {
            errors.subTemplate = "Required!!";
          }
          if (values.supportName === "") {
            errors.supportName = "Required!!";
          }
          if (values?.scopeOfWorkSubTemplate?.length <= 0) {
            errors.scopeOfWorkSubTemplate = "Required";
          }
          return errors;
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{ padding: 10, backgroundColor: "#fff" }}
            >
              <div className={classes.quotationButtonDivModal}>
                { showAddOrder ? (
                  <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                  <div>
                  <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#eb6e12"
                  }}
                  onClick={() => {
                    handleSaveProjectValue(values, nofSetFieldValue, sowSubTemplateList)
                  }}
                  disabled={
                    values?.companyName && values?.supportName &&  values?.scopeOfWorkSubTemplate?.length !== 0
                       ? false
                        : true
                                    }
                  >
                  Save & continue with order
                  </Button>
                    <Button
                    onClick={() => {
                      setButtonText("NOF Order Form Table");
                    }}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#fff",
                      color: "#eb6e12",
                      marginLeft: "10px",
                    }}
                  >
                    Cancel
                  </Button>
                  </div>
                  <div className="" style={{marginRight: "7.8%", fontSize: '15px'}}>
                    <p style={{margin: 0}}>Latest order on this company handled by : <span style={{fontWeight: 700}}>{projectListDropdown?.Support_Name_On_Latest_Order_On_Company}</span></p>
                    </div>
                  </div>
                ) : (
                  <>
                    <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#eb6e12",
                    fontFamily: "Times New Roman !important",
                    marginRight: 10,
                    cursor: "default",
                  }}
                >
                  Project Creation
                </Button>
                <BootstrapTooltip arrow={true} disableFocusListener>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: "#eb6e12" }}
                    onClick={handleSubmit}
                  >
                    {loading ? "Loading...." : "Save"}
                  </Button>
                </BootstrapTooltip>
                <Button
                  onClick={() => {
                    handleModalTableClose();
                    // handleHide()
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
                  </>
                )}
                
              
              </div>
              <div
                style={{ height: "400px", overflowX: "hidden", padding: "0" }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                      zIndex: 10,
                    }}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.quotationInnerGridRight}
                        >
                          <CustomDropdown
                            label="Company Name"
                            fieldName="companyName"
                            fieldOptions={companyNameDropdown}
                            fieldValue={values?.companyName}
                            fieldHandleChange={(value) => {
                              setFieldValue(`companyName`, value.value);
                              setFieldValue(`companyLabel`, value.label);
                              setcompanyName(value?.label)
                              }}
                            requiredField={true}
                            isDisabled={true}
                            errorField={getStyles(errors, values.companyName)}
                            //classvalue={"textBold"}
                          />

                          <CustomMultiSelectDropdown
                            label="Select Sub Order Template"
                            fieldOptions={templateSubOrderListDropdown}
                            fieldValue={values?.selectedSubTemplateId}
                            fieldHandleChange={(value) => {
                              if(editModalPopper) {
                                if(value.length === 0){
                                  projectListDropdown?.Service_Sub_Template_List?.map((value) => {
                                    let findValue = templateSubOrderListDropdown?.find(
                                      (val) => val.label === value.Service_Name
                                    );
                                    if(findValue){
                                      findValue.taskList = value.Task_List;
                                    }
                                  })
                                } else if(value?.length < sowSubTemplateList?.length) {
                                  value?.map((taskArray) => {
                                    
                                      let findArray = templateSubOrderListDropdown?.find(
                                        (val) => taskArray.label !== val.label
                                      );
                                      let findValue = projectListDropdown?.Service_Sub_Template_List?.find(
                                        (val) => taskArray.label !== val.Service_Name
                                      );
                                      if(findValue){
                                        findArray.taskList = findValue.Task_List;
                                      }
                                  })
                                }
                                
                              }
                              setIsDefaultValueCheckedSubTemplate(false);
                              setFieldValue("selectedSubTemplate", value);
                              setFieldValue("selectedSubTemplateId", value);
                              const currVal = [];
                              value?.map((valueArray) => {
                                valueArray?.taskList?.map((val) => {
                                  let obj = {
                                    Id: valueArray.id,
                                    Description:
                                      val.Task_Details,
                                    Sub_Template_Name:
                                    valueArray.label,
                                    Sub_Template_Id: valueArray.id,
                                    Service_Task_Id:
                                      val?.Service_Task_Id,
                                  };
                                  currVal.push(obj);
                                })
                              })

                              setSowSubTemplateList(
                                currVal
                              );
                              setFieldValue(
                                "scopeOfWorkSubTemplate",
                                currVal
                              );
                            }}
                            requiredField={true}
                            errorField={getStyles(
                              errors,
                              values?.selectedSubTemplate
                            )}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <br />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      marginBottom: "-30px",
                      paddingBottom: "10px",
                      marginTop: "-45px",
                      zIndex: 10,
                    }}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={classes.quotationModalFlexDivContaner}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className={classes.quotationInnerGridRight}
                        >
                          <CustomDropdown
                            label="Support Name"
                            fieldOptions={SupportNameDropdown}
                            fieldValue={values?.supportName}
                            fieldHandleChange={(value) => {
                              setFieldValue(`supportName`, value.value);
                              setFieldValue(`supportLabel`, value.label);
                            }}
                            errorField={getStyles(errors, values?.supportName)}
                            requiredField={true}
                          />

                          <InputText
                            label="Status"
                            fieldName="status"
                            fieldValue={values?.status}
                            fieldHandleChange={(event) => {
                              setFieldValue(`status`, event.target.value);
                            }}
                            disableField={true}
                            requiredField={true}
                            errorField={getStyles(errors, values.status)}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.quotationInnerGridRight}
                    >
                      <Form.Group as={Row} className="mb-1" controlId={"sow"}>
                        <Form.Label
                          column
                          sm="12"
                          className="fc-dropdown-label"
                        >
                          Project Description
                          <sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-1"
                        controlId={"selectsow"}
                      >
                        <Col sm="12">
                          {/* Scope of Work Component Has to  be */}
                          {values?.selectedSubTemplate?.length > 0 && (
                            <div
                              className="fc-dropdown-container"
                              style={{ padding: "10px" }}
                            >
                              {values?.selectedSubTemplate?.map(
                                (value, index) => {
                                  return (
                                    <>
                                      {value.taskList?.map((val, i) => {
                                        return (
                                          <Grid container columns={12}>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={1}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                name="scopeOfWorkSubTemplate"
                                                value={val?.id}
                                                defaultChecked={true}
                                                onClick={(e) => {
                                                  const currVal =
                                                    values.scopeOfWorkSubTemplate ||
                                                    [];
                                                  if (e.target.checked) {
                                                    let obj = {
                                                      Id: value.id,
                                                      Description:
                                                        val.Task_Details,
                                                      Sub_Template_Name:
                                                        value.label,
                                                      Sub_Template_Id: value.id,
                                                      Service_Task_Id:
                                                        val?.Service_Task_Id,
                                                    };
                                                    currVal.push(obj);
                                                  } else {
                                                    let isValueExist =
                                                      currVal.findIndex(
                                                        (doc) =>
                                                          doc.Id === val.id
                                                      );
                                                    currVal.splice(
                                                      isValueExist,
                                                      1
                                                    );
                                                  }
                                                  setSowSubTemplateList(
                                                    currVal
                                                  );
                                                  setFieldValue(
                                                    "scopeOfWorkSubTemplate",
                                                    currVal
                                                  );
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={10} sm={10} md={11}>
                                              <Box
                                                component={Grid}
                                                display={{ md: "none" }}
                                              >
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    marginBottom: 3,
                                                  }}
                                                >
                                                  Description
                                                </p>
                                              </Box>
                                              <div
                                                className="fc-input-container"
                                                style={{ marginRight: 3 }}
                                              >
                                                <div>
                                                  <CKRictTextEditer
                                                    disabledField={false}
                                                    data={val?.Task_Details}
                                                    height="400px"
                                                    handleDataChange={(
                                                      event, editor
                                                    ) => {
                                                      let finalData = addBorderToTables(editor.getData())
                                                      setFieldValue(
                                                        `selectedSubTemplate.${[
                                                          index,
                                                        ]}.taskList.${[
                                                          i,
                                                        ]}.Task_Details`,
                                                        editor.getData()
                                                      );
                                                      let fina = [
                                                        ...sowSubTemplateList,
                                                      ];
                                                      let sowIndex =
                                                        fina?.findIndex(
                                                          (item) =>
                                                            item.Service_Task_Id ===
                                                            val.Service_Task_Id
                                                        );
                                                      fina[sowIndex] = {
                                                        ...fina[sowIndex],
                                                        Description:
                                                        finalData
                                                      };
                                                      setSowSubTemplateList(
                                                        fina
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </Col>
                      </Form.Group>

                      <span style={{ color: "red" }}>
                        {errors?.scopeOfWorkSubTemplate &&
                          touched?.scopeOfWorkSubTemplate &&
                          errors?.scopeOfWorkSubTemplate}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={openLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default withStyles(modalTableStyles)(AddOrEditProjectList);
