import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import moment from "moment";
  import { AgGridReact } from "ag-grid-react";
  import "ag-grid-enterprise";
  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert from "@mui/material/Alert";
  import CustomIconButton from "../../../../reusableComponents/IconButton";
  import { Button } from "react-bootstrap";
  import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
  import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
  import { SupportDataContext } from "../../../../provider/context";
  import "../styles/ListingDataGrid.css";
  import { FaPlus, FaEdit } from "react-icons/fa";
  import CloseIcon from "@mui/icons-material/Close";
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const RevisedNOFListing = ({
    apiData,
    upperIcons,
    lowerIcon,
    tableUpperButtonArr,
    tabValue,
    fetchListingDataFromApi,
    ScrollTop,
    fitlerBadge,
  }) => {
    const {
      handleModalTableOpen,
      setEditModalPopper,
      setButtonText,
      setOperationInfo,
      operationInfo,
      setSelected,
      setLoading,
      handleModalOpen,
      message,
      setMessage,
      setSeverity,
      handlePromise,
      isOpen,
      setIsOpen,
      severity,
      getNOFDataListingApi,
      nofDataListing,
      getTemplateDataAPI,
      getRevisedProjectListapi,
      revisedProjectList,
    } = useContext(SupportDataContext);
  
    const gridRef = useRef();
    const [savedValue, setSavedValue] = useState(null);
    const [defaultFilterValue, setDefaultFilterValue] = useState(null);
    const [savedSearchList, setSavedSearchList] = useState([]);
  
    useEffect(() => {
      getRevisedProjectListapi(operationInfo?.Order_No);
    }, [operationInfo?.Order_No]);
  
    const components = useMemo(() => {
      return {
        agDateInput: CustomCalender,
      };
    }, []);
  
    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue.split(" ");
        if (dateAsString == null) {
          return -1;
        }
        if (dateAsString == "") {
          return -1;
        }
        const dateParts = dateAsString[0].split("-");
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
    const documentItems = (params) => {
      let data = params?.data.documents;
      return data ? (
        <>
          <Button
            style={{
              marginLeft: ".4rem",
              textDecorationColor: "#eb6e12",
              background: "none",
              cursor: "pointer",
              border: "none",
              padding: "6px",
            }}
            onClick={() => window.open(data)}
          >
            View Document
          </Button>
        </>
      ) : null;
    };
  
   const dateFilterParams = {
    inRangeInclusive: true,
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        if (dateAsString == null) {
          return 0;
        }
        const dateParts = dateAsString.slice(0, 10).split("-");
        const year = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    };
  
    const columnDefs = [
      {
        headerName: "S. No",
        field: "Serial_Number",
        // cellRenderer: "agGroupCellRenderer",
        // checkboxSelection: true,
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          textAlign: "center",
        },
        minWidth: 130,
        filter: false,
      },
      {
          headerName: "Order No",
          field: "order_no",
          cellStyle: {
            borderBottom: "1px solid #fff",
            borderRight: "1px solid #fff",
            overflowY: "auto",
          },
          width: 200,
        },
        {
          headerName: "Order Name",
          field: "Order_Name",
          cellStyle: {
            borderBottom: "1px solid #fff",
            borderRight: "1px solid #fff",
            overflowY: "auto",
          },
          width: 200,
        },
        {
          headerName: "Company Name",
          field: "Company_Name",
          cellStyle: {
            borderBottom: "1px solid #fff",
            borderRight: "1px solid #fff",
            overflowY: "auto",
          },
          width: 200,
        },
         {
        headerName: "Revised NOF Doc",
        field: "Upload_Document",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 200,
        cellRenderer: (params) => {
          params = params?.data?.Upload_Document;
          return (
              <a
              href={params}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          );
        },
      },
      {
        headerName: "Uploaded Date",
        field: "created_date",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 200,
        cellRenderer: (params) => {
          return (
            <>{params?.data?.created_date?.substring(0,10)}</>
          )
        }
      },
      {
        headerName: "Uploaded By",
        field: "created_By",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 200,
      },
      {
        headerName: "Remarks",
        field: "remarks",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY:'auto'
        },
        minWidth: 200,
        flex: 1
      },
      {
        headerName: "Updated By",
        field: "updated_By",
        cellStyle: {
          borderBottom: "1px solid #fff",
          borderRight: "1px solid #fff",
          overflowY: "auto",
        },
        width: 200,
      },
      {
          headerName: "Updated Date",
          field: "updated_date",
          cellStyle: {
            borderBottom: "1px solid #fff",
            borderRight: "1px solid #fff",
            overflowY: "auto",
          },
          width: 200,
        },
    ];
  
    const defaultColDef = useMemo(() => {
      return {
        // set the default column width
        width: 100,
        // make every column editable
        editable: false,
        // make every column use 'text' filter by default
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
        // make columns resizable
        resizable: true,
        sortable: true,
        //flex:1,
      };
    }, []);
  
    const columnTypes = useMemo(() => {
      return {
        numberColumn: { width: 130, filter: "agNumberColumnFilter" },
        medalColumn: { width: 100, columnGroupShow: "open", filter: false },
        nonEditableColumn: { editable: false },
        dateColumn: {
          // specify we want to use the date filter
          filter: "agDateColumnFilter",
          // add extra parameters for the date filter
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = cellValue.split("/");
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);
              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      };
    }, []);
  
    const onRowSelected = (event) => {
      const rowData = event.api.getSelectedNodes()[0]?.data;
      setSelected([rowData]);
    };
  
    const [fromRows, setFromRows] = useState(0);
    const [toRows, setToRows] = useState(0);
    const onPageSizeChanged = useCallback(() => {
      var value = document.getElementById("page-size").value;
      gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);
  
    const onPaginationChanged = (e) => {
      const currentPage = e.api.paginationGetCurrentPage() + 1;
      const pageSize = e.api.paginationGetPageSize();
  
      const to = pageSize * currentPage;
      const from = to - pageSize;
  
      setFromRows(from + 1);
      setToRows(to);
    };
  
    const [searchValue, setSearchVal] = useState("");
    const onFilterTextBoxChanged = (val) => {
      setSearchVal(val);
      gridRef.current.api.setQuickFilter(val);
    };
  
    useEffect(() => {
      const parsedSavedValue = JSON.parse(savedValue);
      if (parsedSavedValue !== null) {
        let values = Object.keys(parsedSavedValue);
        values.map((val, i) => {
          if (parsedSavedValue[val].filterType === "text") {
            gridRef.current.api.getFilterInstance(val).setModel({
              type: "contains",
              filter: parsedSavedValue[val].filter,
            });
          } else {
            gridRef.current.api
              .getFilterInstance(val)
              .setModel({ values: parsedSavedValue[val].values });
          }
        });
        gridRef.current.api.onFilterChanged();
      }
    }, [savedValue]);
  
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setIsOpen(false);
    };
  
  
    return (
      <>
      
      <div className="listing-data-grid" style={{ height: "500px" }}>
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            suppressExcelExport={true}
            suppressCsvExport={true}
            ref={gridRef}
            rowData={revisedProjectList}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnTypes={columnTypes}
            animateRows={true}
            components={components}
            onSelectionChanged={onRowSelected}
            rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
            getRowHeight={() => 90}
            pagination={true}
            detailRowAutoHeight={true}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
        </div>
        <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity === 200 ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      </>
    );
  };
  
  export default RevisedNOFListing;
  