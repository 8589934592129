import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { TableContainer, Paper, IconButton } from "@mui/material";
import { withStyles } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Error from "../../../../listing/listingTable/ListingExpandableTable/modal/Error";
import modalTableStyles from "../../styles/ModalTableStyles";
import { SupportDataContext } from "../../../../../provider/context";
import { headers } from "../../../../../services/services";
import CustomIconButton from "../../../../../reusableComponents/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Alert from 'react-bootstrap/Alert';
import {
  FaStopCircle,
} from "react-icons/fa";




const ViewCompanyWorkerTable = ({ classes }) => {
  const {
    message,
    setButtonText,
    setOperationInfo,
    handleModalOpen,
    operationInfo,
    getCompleteOrderListApi,
    completeOrderList,
    setEditModalPopper,
    getViewCompanyFamilyApi, viewCompanyFamilyData,
    setSalesInfo,
    getViewCompanyWorkerApi, viewCompanyWorkerData
  } = useContext(SupportDataContext);

  const gridRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
 
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };
  useEffect(() => {
    getViewCompanyWorkerApi();
  }, []);



  const customActionBtnRenderer = (params) => {
    return (
      <>
        <CustomIconButton
          onClick={() => {
            handleModalOpen();
            setEditModalPopper(true);
            //setButtonText("Freeze Order Approval");
            // scrollToTop()
            //ScrollTop();
            setSalesInfo(params.data);
          }}
          title={"Edit"}
          icon={<EditIcon style={{ margin: "0", fontSize: "25px" }} />}
          className="btnActionAlignment"
        />
      </>
    )
  }

  const [columnDefs] = useState([
    {
      headerName: "Sr.",
      field: "Serial_Number",
      width: 70,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Action",
      field: "action",
      filter: false,
      width: 100,
      pinned: "left",
      cellStyle: { border: "1px solid #fff", paddingTop: 0 },
      cellRenderer: customActionBtnRenderer,
    },
    {
      headerName: "Company Name",
      field: "Company_Name",
      filter: "agTextColumnFilter",
      width: 210,
      pinned: "left",
      cellStyle: { border: "1px solid #fff" },
    },
    {
        headerName: "Card Status",
        field: "CardStatus_Name",
        filter: "agTextColumnFilter",
        width: 130,
        pinned: "left",
        cellStyle: { border: "1px solid #fff" },
      },
      {
        headerName: "Employee Name",
        field: "Employee_Name",
        filter: "agTextColumnFilter",
        width: 210,
        pinned: "left",
        cellStyle: { border: "1px solid #fff" },
      },
      {
        headerName: "Employee Designation",
        field: "Employee_Designation",
        filter: "agTextColumnFilter",
        width: 180,
        cellStyle: { border: "1px solid #fff" },
      },
    {
      headerName: "Nationality",
      field: "Employee_Nationality",
      filter: "agTextColumnFilter",
      width: 130,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Email",
      field: "Employee_Email",
      filter: "agTextColumnFilter",
      width: 210,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
        headerName: "Mobile",
        field: "Employee_Mobile",
        filter: "agTextColumnFilter",
        width: 160,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
      {
        headerName: "Passport No.",
        field: "Employee_Passport",
        filter: "agTextColumnFilter",
        width: 160,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
      {
        headerName: "Passport Expiry",
        field: "Employee_Passport_Expiry_Date",
        filter: "agDateColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center" },
      },
    {
      headerName: "Visa Issue",
      field: "Employee_Visa_Issue_Date",
      filter: "agDateColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
    },
    {
      headerName: "Visa Expiry",
      field: "Employee_Visa_Expiry_Date",
      filter: "agDateColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Card Type",
      field: "Employee_Card_Type",
      filter: "agTextColumnFilter",
      width: 150,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Labour Card No.",
      field: "Employee_Labour_Card",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Labour Card Status",
      field: "Employee_Labour_Card_Status",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Labour Card Expiry",
      field: "Employee_Labour_Card_Expiry_Date",
      filter: "agDateColumnFilter",
      width: 200,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Basic Salary",
      field: "Basic_Salary",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Salary Transportation",
      field: "Sal_Transportation",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Salary Accomodation",
      field: "Sal_Accomodation",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
      headerName: "Salary Overtime",
      field: "Sal_Overtime",
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: { border: "1px solid #fff", textAlign: "center" },
    },
    {
        headerName: "Created Date",
        field: "Created_Date",
        filter: "agDateColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },
      {
        headerName: "Created By",
        field: "Created_By_Name",
        filter: "agTextColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },

      {
        headerName: "Updated Date",
        field: "Updated_Date",
        filter: "agDateColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },
      {
        headerName: "Updated By",
        field: "Updated_By_Name",
        filter: "agTextColumnFilter",
        width: 200,
        cellStyle: { border: "1px solid #fff", textAlign: "center", overflowY: "auto" },
      },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      width: 100,
      editable: false,
      floatingFilter: true,
      resizable: true,
      textAlign: "center",
      sortable: true,
    };
  }, []);

  return (
    <div className={classes.modalTableSecondContainer}>

      <TableContainer component={Paper}>
        <div
          className="ag-theme-alpine"
          style={{ height: "400px", maxHeight: "450px", overflow: "auto" }}
        >


          {/* <Alert show={show} variant="success" sx={{ width: "100%" }} onClose={() => setShow(false)} dismissible>
                 {message} 
               </Alert> */}

          <AgGridReact
            ref={gridRef}
            rowData={viewCompanyWorkerData?.Company_info_Worker_List}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowHeight={() => 90}
            rowStyle={{
              background: "#DEDEDE",
              color: "#000000DE",
              borderBottom: "1px solid white",
            }}
            enableCellChangeFlash={true}
          ></AgGridReact>
        </div>
      </TableContainer>
      <Error />
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default withStyles(modalTableStyles)(ViewCompanyWorkerTable);
