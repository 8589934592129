import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ModalPopperFormStyles from "../../styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomDropdown from "../../../../../reusableComponents/CustomDropdown/CustomDropDown";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CustomSelect, CustomMultiSelect } from "../../../../listing/listingForm/ListingForm";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if (height && !getIn(errors, fieldName)) {
    return { height: height }
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height: height
    };
  } else if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const FollowUpModalForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    followUp,
    setMessage,
    setSeverity,
    editModalPopper,
    viewFollowup,
    operationInfo,
    getFollowUpTableAndDropdown,
    setIsOpen,
  } = useContext(SupportDataContext);


  const Follow_type_dropdown = followUp?.Order_Followup_Type_List?.map((item) => {
    const obj = { value: item.Value, label: item.Followup_Type };
    return obj;
  });
  function findSelectView() {
    const vart = [];
    viewFollowup?.Followup_Type?.split(",").forEach((el) => {
      if(el) {
        vart?.push(Follow_type_dropdown?.find((item) => item.value == el));
      }
    });
    const mapArray = vart.map((i) => i);
    return mapArray;
  }
  const followUpPostAPI = async (values) => {
    const user_type = localStorage.getItem('user_type');
    const emp_id = localStorage.getItem("emp_id");
    const __token = localStorage.getItem("token");
    const url = `https://flyingcolour.online/fcapp/support_api/orderfollowupsview/`;
    const headers = {
      Authorization: `Token ${__token}`,
      "Content-Type": "application/json",
    };

    var multiFollowUpType = values?.followUpType !== "" && values?.followUpType !== undefined ? values?.followUpType?.map((o) => o.value) : [];

    let body = {
      company_id: operationInfo?.Company_Id,
      order_no: operationInfo?.Order_No,
      emp_id: emp_id,
      user_type: user_type,
      followup_type: multiFollowUpType.toString(),
      followup_msg: values.comment,
      due_date: moment(values.dueDate).format("YYYY-MM-DD"),
      share_sent_via: values.shareSent.toString()
    }
    if (editModalPopper) {
      body = {
        ...body,
        followup_id: viewFollowup?.Followup_Id
      }
      axios.put(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      })
    } else {
      axios.post(url, body, { headers: headers }).then((data) => {
        setLoading(false);
        handleQuotationPromise(data);
      })
    }

  }

  const handleQuotationPromise = (data) => {
    if (data?.data?.data?.message) {
      setMessage(data?.data?.data?.message);
      setSeverity(500);
    }
    if (data?.data?.data?.msg) {
      setMessage(data?.data?.data?.msg);
      setSeverity(500);
    }
    if (data?.data?.data?.detail) {
      setMessage(data?.data?.data?.detail);
      setSeverity(500);
    } else {
      setMessage(data?.data?.data?.msg);
      setSeverity(data?.data?.data?.code);
    }
    setIsOpen(true);
    getFollowUpTableAndDropdown();
    handleModaleClose();
  }

  return (
    <Formik
      //enableReinitialize
      initialValues={{
        followUpType: editModalPopper ?  findSelectView() : '',
        comment: editModalPopper ? '': '',
        dueDate: editModalPopper ? new Date(new Date(moment(viewFollowup?.Due_Date, "DD-MM-YYYY").format("YYYY-MM-DD")).toString()) : null,
        shareSent: editModalPopper ? viewFollowup?.Share_Sent_Via.split(',') : []
      }}
      onSubmit={async (data) => {
        setLoading(true)
        followUpPostAPI(data)
      }}
      validate={(values) => {
        let errors = {};

        if (!values.followUpType) {
          errors.followUpType = "required";
        }
        if (!values.comment) {
          errors.comment = "required";
        }
        if (!values.dueDate) {
          errors.dueDate = "required";
        }

        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <div>
                <div style={{ height: "36px" }}>
                  <IconButton
                    style={{ float: "right" }}
                    className={classes.quotationModalHeaderIconCancle}
                    onClick={() => {
                      handleModaleClose();
                    }}
                  >
                    <CloseIcon
                      className={classes.quotationModalHeaderIconCancleIcon}
                    />
                  </IconButton>
                </div>
                <div style={{ height: "400px", overflowX: "hidden", overflowY: "auto", padding: "0px 20px 20px 20px" }}>
                  <Grid container spacing={2} columns={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "-30px",
                        paddingBottom: "10px",
                        marginTop: "-45px",
                        zIndex: 10
                      }}
                    >
                      <br />
                      <div className={classes.quotationModalHeadingContainer}>
                        <span className={classes.quotationModalHeader}>
                          Add Follow Up
                        </span>
                      </div>
                      <br />
                      <div className={classes.quotationModalFlexDivContaner}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.quotationInnerGridRight}
                          >
                            <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                Follow-Up Type
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 10px 0 2px' }} >
                                <div
                                  className={classes.quotationSelectContainerModal1}
                                >
                                  <div
                                    className={classes.quotationDemoDataCheckModal1}
                                  >
                                    <CustomMultiSelect
                                      width={"100%"}
                                      longWidth={"100%"}
                                      defualtValue={editModalPopper ? findSelectView() : null}
                                      value={values?.followUpType}
                                      onChange={(value) => {
                                        
                                        setFieldValue("followUpType", value);
                                      }}
                                      options={Follow_type_dropdown}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Form.Group>
                            {/* <CustomDropdown
                            label="Follow-Up Type"
                            fieldOptions={Follow_type_dropdown}
                            fieldValue={values?.followUpType}
                            fieldHandleChange={(value) => {
                              setFieldValue("followUpType", value.value);
                            }}
                            errorField={getStyles(errors, values?.followUpType)}
                            requiredField={true}
                          /> */}

                            <CustomTextArea
                              label="Comment"
                              fieldName="comment"
                              fieldValue={values.comment}
                              fieldHandleChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue("comment", value);
                              }}
                              requiredField={true}
                              errorField={getStyles(errors, values?.comment, 80)}
                              height={78}
                            // errorField={getStyles(errors,values.fcisorfcosremarks )}
                            // disableField={fieldsEditingMain}
                            />

                            <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                Due Date
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                                <div style={{ border: "0.1px solid #d3d3d3" }}>
                                  <div style={getStyles(errors, values?.dueDate)}>
                                    <DatePicker
                                      selected={values.dueDate}
                                      minDate={moment().toDate()}
                                      onChange={(date) => {
                                        setFieldValue(
                                          `dueDate`,
                                          date
                                        );
                                      }}
                                      fixedHeight
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="Select Due Date"
                                    />
                                  </div>

                                </div>
                              </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                              <Form.Label column sm="5" className="fc-textarea-label">
                                Share/ Sent Via
                              </Form.Label>
                              <Col sm="7" style={{ padding: '0 12px 0 0' }}>
                                <div style={{ display: 'flex' }}>
                                  {followUp?.Order_Followup_Share_Sent_Via_List?.map((val) => {
                                    return (
                                      <FormControlLabel
                                        control={<Checkbox />}
                                        label={val?.Share_Sent_Name}
                                        value={val?.Value}
                                        labelPlacement="end"
                                        checked={values.shareSent.includes(val?.Value) ? true : false}
                                        onChange={(e) => {
                                          let checkboxValues = values.shareSent;
                                          if (e.target.checked) {
                                            checkboxValues.push(e.target.value)
                                          } else {
                                            const findIndex = checkboxValues.findIndex((ele) => ele === e.target.value);
                                            checkboxValues.splice(findIndex, 1)
                                          }
                                          setFieldValue("shareSent", checkboxValues);
                                        }}
                                      />
                                    )
                                  })}
                                </div>
                              </Col>
                            </Form.Group>
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <div className={classes.quotationButtonDivModal}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{ backgroundColor: "#eb6e12" }}
                    >
                      {loading ? "Loading...." : "Submit"}
                    </Button>
                    <Button
                      onClick={() => {
                        handleModaleClose();
                        // handleHide()
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        color: "#eb6e12",
                        marginLeft: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(FollowUpModalForm);
