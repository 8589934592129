import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CustomIconButton from "../../../../reusableComponents/IconButton";
import CommonPageSelect from "../../../../commonComp/commonPageSelect";
import CustomCalender from "../../../../reusableComponents/CustomCalender/CustomCalender";
import LeadsFilterAdvancedMain from "../../../ReceptionListing/AdvancedFilters/LeadsFilterAdvancedMain";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import "../Styles/ListingDataGrid.css";
import Badge from "@mui/material/Badge";
import ModalPopper from "../Modals/ModalPopper";
import {
  getTotalLength,
  getTotalLengthForSavedFilters,
  handleDefaultFilters,
  handleFilters,
} from "../../../../utils/Filters";
import PreviewIcon from "@mui/icons-material/Preview";
import { Modal } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../../../reusableComponents/CustomDatePicker/CustomDatePicker";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Grid } from "@material-ui/core";
import { FaPlus, FaEdit } from "react-icons/fa";
import GeneralTaskNameForm from "../Modals/GeneralTaskName/GeneralTaskNameForm";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GeneralTaskManagerListingTable = ({
  apiData,
  upperIcons,
  lowerIcon,
  tableUpperButtonArr,
  tabValue,
  fetchListingDataFromApi,
  ScrollTop,
  fitlerBadge,
}) => {
  const {
    handleModalTableOpen,
    setButtonText,
    setOperationInfo,
    setSelected,
    setSalesInfo,
    setIsOpen,
    isOpen,
    message,
    severity,
  } = useContext(SupportDataContext);
  const gridRef = useRef();
  const [savedValue, setSavedValue] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [message, setMessage] = useState("");
  const [defaultFilterValue, setDefaultFilterValue] = useState(null);
  const [savedSearchList, setSavedSearchList] = useState([]);
  const [modalValue, setModalValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleListingSavedSearch();
    fetchListingDataFromApi();
  }, []);

  const components = useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);

  const columnDefs = [
    {
      headerName: "S#",
      field: "Serial_Number",
      // cellRenderer: "agGroupCellRenderer",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
        textAlign: "left",
      },
      width: 70,
      pinned: "left",
      filter: false,
    },
    {
      headerName: "Actions",
      field: "Inquiry_Id",
      filter: false,
      // checkboxSelection: true,
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 120,
      pinned: "left",
      cellRenderer: (params) => {
        const date1 = moment(params?.data?.Created_Date, "YYYY-MM-DD HH:mm:ss");
        const date2 = moment(new Date(), "YYYY-MM-DD HH:mm:ss");

        const differenceInMilliseconds = date2.diff(date1);
        const isMoreThan24Hours =
          differenceInMilliseconds > 24 * 60 * 60 * 1000;

        return (
          <>
            {!isMoreThan24Hours && (
              <CustomIconButton
                onClick={() => {
                  setOperationInfo(params.data);
                  setModalValue("Add/Edit General Task Name");
                  setOpenModal(true);
                  setIsEdit(true);
                }}
                title={"Edit"}
                width={"auto"}
                className="btnActionAlignment"
                icon={
                  <>
                    <FaEdit /> Edit
                  </>
                }
              />
            )}
          </>
        );
      },
    },

    {
      headerName: "Task Name",
      field: "task_name",
      filter: "agTextColumnFilter",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "active_status_name",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Created By",
      field: "Created_By",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Created Date",
      field: "Created_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Created User Type",
      field: "created_by_user_type",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
    },
    {
      headerName: "Updated By",
      field: "Updated_By",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
    {
      headerName: "Updated Date",
      field: "Updated_Date",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      width: 150,
    },
      {
      headerName: "Updated User Type",
      field: "updated_by_user_type",
      cellStyle: {
        borderBottom: "1px solid #fff",
        borderRight: "1px solid #fff",
      },
      filter: "agSetColumnFilter",
      width: 200,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      // set the default column width
      width: 100,
      // make every column editable
      editable: false,
      // make every column use 'text' filter by default
      filter: true,
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      sortable: true,
      //flex:1,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const onRowSelected = (event) => {
    const rowData = event.api.getSelectedNodes()[0]?.data;
    setSelected([rowData]);
  };

  const [fromRows, setFromRows] = useState(0);
  const [toRows, setToRows] = useState(0);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = (e) => {
    const currentPage = e.api.paginationGetCurrentPage() + 1;
    const pageSize = e.api.paginationGetPageSize();

    const to = pageSize * currentPage;
    const from = to - pageSize;

    setFromRows(from + 1);
    setToRows(to);
  };

  const handleGotoPage = (e) => {
    const value = e.target.value - 1;
    gridRef.current.api.paginationGoToPage(Number(value));
  };

  const [searchValue, setSearchVal] = useState("");
  const onFilterTextBoxChanged = (val) => {
    setSearchVal(val);
    gridRef.current.api.setQuickFilter(val);
  };

  const filters = [
    {
      id: 1,
      title: "Default",
      value: null,
      key: null,
    },
    {
      id: 2,
      title: "Active",
      value: "Active",
      key: "active_status_name",
    },
    {
      id: 3,
      title: "Inactive",
      value: "Inactive",
      key: "active_status_name",
    },
  ];

  useEffect(() => {
    const parsedSavedValue = JSON.parse(savedValue);
    if (parsedSavedValue !== null) {
      let values = Object.keys(parsedSavedValue);
      values.map((val, i) => {
        if (parsedSavedValue[val].filterType === "text") {
          gridRef.current.api.getFilterInstance(val).setModel({
            type: "contains",
            filter: parsedSavedValue[val].filter,
          });
        } else {
          gridRef.current.api
            .getFilterInstance(val)
            .setModel({ values: parsedSavedValue[val].values });
        }
      });
      gridRef.current.api.onFilterChanged();
    }
  }, [savedValue]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const handleListingSavedSearch = async () => {
    const __token = localStorage.getItem("token");
    const emp_id = localStorage.getItem("emp_id");
    const factory_id = localStorage.getItem("factory_id");
    await fetch(
      `https://flyingcolour.online/fcapp/api/usersearchlisting/?factory_id=${factory_id}&company_id=1&searchname=&searchby=1&tabname=tabName_${tabValue}&emp_id=${emp_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.detail === "Invalid Token!") {
          localStorage.clear();
          navigate("/login");
          sessionStorage.clear();
          window.location.reload();
        }
        setSavedSearchList(data.results?.Response);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        console.error("Error:", error);
      });
  };
  const handleSearch = (searchName, value, searchKey) => {
    handleFilters(gridRef, setDefaultFilterValue, searchName, value, searchKey);
  };

  return (
    <div className="listing-data-grid" style={{ height: "500px" }}>
      <div className="listing-filters" style={{ margin: "1rem" }}>
        <Grid container>
          <Grid item sm={12} md={4} style={{ paddingRight: "1rem" }}>
            <Button
              onClick={() => {
                setModalValue("Add/Edit General Task Name");
                setOpenModal(true);
                setIsEdit(false);
              }}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#eb6e12",
                color: "#fff",
                padding: "5px",
              }}
            >
              <FaPlus style={{ marginRight: "10px" }} /> Add General Task Name
            </Button>
          </Grid>
        </Grid>
      </div>
      {fitlerBadge ? (
        <>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              marginTop: "30px",
              paddingBottom: "15px",
            }}
          >
            <LeadsFilterAdvancedMain
              tableUpperButtonArr={tableUpperButtonArr}
              apiData={apiData?.Task_Detail}
              gridRef={gridRef}
              tabValue={tabValue}
              setSavedValue={setSavedValue}
              savedValue={savedValue}
              defaultFilterValue={defaultFilterValue}
              handleListingSavedSearchAPI={handleListingSavedSearch}
              handleFilter={handleSearch}
            />
            <span
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              Filters:
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                flexWrap: "wrap",
                color: "#008000",
                marginLeft: "15px",
                fontFamily: "Times New Roman",
              }}
            >
              {apiData?.Task_Detail?.length > 0 &&
                filters.map((val, i) => {
                  return (
                    <Badge
                      key={i}
                      badgeContent={getTotalLength(
                        apiData?.Task_Detail,
                        val.value,
                        val.key
                      )}
                      color="warning"
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        marginBottom: "8px",
                        cursor: "pointer",
                        fontFamily: "Times New Roman",
                      }}
                      onClick={() =>
                        handleDefaultFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.title,
                          val.value,
                          val.key
                        )
                      }
                    >
                      <span>{val.title}</span>
                    </Badge>
                  );
                })}
              {savedSearchList?.length > 0 &&
                savedSearchList?.map((val, i) => {
                  const value = val.Search_Filter?.split("{")[1];
                  const keyValue = val.Search_Filter?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "")
                    ?.split(",")[0]
                    .replace("[", "")
                    .replace("]", "");
                  const keyss = val.Search_Filter.replace(
                    /(.*?)=(.*?)(?:\|\||$)/g
                  );
                  const getNewKey = keyss
                    ?.split("{")[2]
                    ?.split(":")[1]
                    ?.replaceAll('"', "");
                  const getNewKe2 = getNewKey?.match(/\[(.*?)\]/);
                  let resultArray = "";
                  if (getNewKe2) {
                    const resultString = getNewKe2[1];
                    resultArray = resultString.split(",");
                  }
                  const valueKey = value
                    ?.split(":")[0]
                    ?.replaceAll('"', "")
                    ?.split("_1")[0];
                  return (
                    <Badge
                      key={i}
                      color="warning"
                      badgeContent={getTotalLengthForSavedFilters(
                        apiData?.Task_Detail,
                        resultArray,
                        valueKey
                      )}
                      max={9999999}
                      showZero
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "16px",
                        color: "#fff",
                        marginLeft: "15px",
                        background: "#EB6E12",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        // marginBottom: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleFilters(
                          gridRef,
                          setDefaultFilterValue,
                          val.Search_Name,
                          keyValue?.trim(),
                          val.Search_Filter
                        );
                        // setSavedValue(val.val.Search_Name);
                        setDefaultFilterValue(val.Search_Name);
                      }}
                    >
                      <span>{val.Search_Name}</span>
                    </Badge>
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
      <p
        style={{
          fontSize: 14,
          color: "red",
          marginLeft: 10,
          marginBottom: 0,
          paddingTop: 5,
        }}
      >
        Note: Edit option will be limited 24hrs after the creation date.
      </p>
      <CommonPageSelect
        search
        onSearch={(e) => onFilterTextBoxChanged(e.target.value)}
        searchValue={searchValue}
        onPageSizeChanged={onPageSizeChanged}
        handleGotoPage={handleGotoPage}
        fromRows={fromRows}
        toRows={toRows}
        length={apiData?.Task_Detail?.length}
      />
      <div
        style={{ height: "100%", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          suppressExcelExport={true}
          suppressCsvExport={true}
          ref={gridRef}
          rowData={apiData?.Task_Detail}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          animateRows={true}
          components={components}
          onSelectionChanged={onRowSelected}
          rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
          getRowHeight={() => 90}
          pagination={true}
          detailRowAutoHeight={true}
          onPaginationChanged={onPaginationChanged}
        ></AgGridReact>
      </div>
      <Snackbar open={isOpen} autoHideDuration={30000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity === 200 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modalValue === "Add/Edit General Task Name" ? (
            <GeneralTaskNameForm setOpenModal={setOpenModal} isEdit={isEdit} />
          ) : null}
        </>
      </Modal>
    </div>
  );
};

export default GeneralTaskManagerListingTable;
