import React, { useContext, useEffect } from "react";
import modalTableStyles from "../styles/ModalTableStyles";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DataContext, SupportDataContext } from "../../../../provider/context";
import FreezeOrderLogTable from "./ApproveOrder/FreezeOrderLogTable";
import TodayOrderAlert from "../Alerts/TodayOrderAlert";
import CompleteOrderLogTable from "./ApproveOrder/CompleteOrderLogTable";
import OrderLogTable from "./OrderDetailLog/OrderLogTable";
import ViewRequisitionTable from "./Requisition/ViewRequisitionTable";
import FollowupTable from "./Followup/FollowupTable";
import ViewOrderDetail from "./OrderDetailLog/ViewOrderDetail";
import ComplaintOrderListingTable from "./Complaint/ComplaintOrderListingTable";
import NOFOrderFormTable from "./NOF/NOFOrderFormTable";
import ViewCompanyFamilyTable from "./ViewCompanyFamily/ViewCompanyFamilyTable";
import ViewCompanyWorkerTable from "./ViewCompanyWorker/ViewCompanyWorkerTable";
import ViewOrderOnProjectListTable from "./ProjectList/ViewOrderOnProjectListTable";
import CompanyDocuments from "../Listing/CompanyDocuments";
import RevisedNOFListing from "../Listing/RevisedNOFListing";
import ViewCommentTable from "./AddViewComment/ViewCommentTable";
import ViewCompanyOrdersTable from "./ViewOrdersOnCompany/ViewCompanyOrdersTable";
import CompanySharesDetailsTable from "./PartnerList/CompanySharesDetailsTable";
import OverdueReasonListingTable from "./OverdueReason/OverdueReasonListingTable";
import EscalationLogTable from "./Complaint/EscalationLogTable";
import TargetAchievementTable from "./TargetAchievement/TargetAchievementTable";
import CourseDocsTable from "../../Support/Modals/TrainingCourse/CourseDocsTable";
import TrainingCourseLogTable from "../../Support/Modals/TrainingCourse/TrainingCourseLogTable";
import ViewAttendeesTable from "../../Support/Modals/TrainingCourse/ViewAttendeesTable";
import ScheduleTrainingLogTable from "../../Support/Modals/TrainingCourse/ScheduleTrainingLogTable";
import ReviewOnOrderTable from "./ReviewOnOrder/ReviewOnOrderTable";
import PCOTargetAchievementTable from "./TargetAchievement/PCOTargetAchievementTable";
import AdminTargetAchievementTable from "./TargetAchievement/AdminTargetAchievementTable";
import RequisitionLogTable from "../Modals/Requisition/RequisitionLogTable";
import FreezeLogTable from "./FreezeLog/FreezeLogTable";





const ModalTable = ({ classes, data }) => {
  const {
    quotationApiMsg,
    expiredErrorMsg,
    salesInfo,
  } = useContext(DataContext);
  const { handleModalOpen,
    buttonText,
    handleModalTableClose,
    setButtonText,
    operationInfo,
    setEditModalPopper,
    isCompleteOrderBtnDisable,
    setIsCompleteOrderBtnDisable
  } = useContext(SupportDataContext)
  const handleModalOpenSub = (e) => {
    const buttonText = e.currentTarget.innerText;
    setEditModalPopper(false);
    handleModalOpen();
  };
  
  return (
    <div className={classes.TabLowerContainerModalTable}>
      <div className={classes.ButtonDivListingTab}>
        {[
          "Add-Done",
          "view-SA",
          "view-Attended",
          "Add Reschedule",


        ].includes(buttonText) ? null : (
          <>
            {[
              "View Requisition",
              "Add/View Follow-up",
              "Add / View Follow-up",
              "Edit/View Complaint",
              "Add/View Requisition",
              "Resolve Complaint",
              "Freeze Order Approval",
              "Completion Order Approval",
              "View / Add Family",
              "Add Worker",
              "Add NOF Order Form",
              "View Company Document",
              "View Order Log",
              "View Complaint Log",
              "View Order On Project",
              "Add Comments",
              "View Company Order",
              "Company Share Details",
              "Overdue Order Reason",
              "Add Target",
              "Add PCO Target",
              "Add Admin Assistant Target",
              "View Course Documents",
              "Training Course Log",
              "View Training Attendees",
              "View Training Status Log",
              "Review On Order",
              "Requestion Log",
              "Request Freez Order"
             

            ].includes(buttonText) ? (
              <>
                {
                  buttonText === "View / Add Family" 
                  || buttonText === "Add Worker" 
                  || buttonText === "Add NOF Order Form"
                  || buttonText === "View Order On Project"
                  || buttonText ===  "View Company Order"
                  || buttonText === "Company Share Details"
                  || buttonText === "Add Target"
                  || buttonText === "Add PCO Target"
                  || buttonText === "Add Admin Assistant Target"
                  || buttonText === "View Course Documents"
                  || buttonText === "Training Course Log"
                  || buttonText === "View Training Attendees"
                  || buttonText === "View Training Status Log"
                  
                   ? null :
                    <p
                      variant="contained"
                      className={classes.NewButton1}
                      style={{ marginRight: "5px", marginTop: "4px" }}
                    >
                      <span style={{ marginTop: "2px" }}>
                        Order No: {operationInfo?.Order_No || operationInfo?.order_no || operationInfo?.Order_Id || operationInfo.order_id}
                      </span>
                    </p>
                }

                {
                  buttonText === "View / Add Family" 
                  || buttonText === "Add Worker" 
                  || buttonText ===  "View Company Order"
                  ?
                    <>
                    <p
                      variant="contained"
                      className={classes.NewButton1}
                      style={{ marginRight: "5px", marginTop: "4px" }}
                    >
                      <span style={{ marginTop: "2px" }}>
                        Company ID: {operationInfo?.Company_Id}
                      </span>
                    </p>
                     <p
                     variant="contained"
                     className={classes.NewButton1}
                     style={{ marginRight: "5px", marginTop: "4px" }}
                   >
                     <span style={{ marginTop: "2px" }}>
                       Company Name: {operationInfo?.Company_Name || operationInfo?.company_name}
                     </span>
                   </p>
                    </>
                    :
                    buttonText === "Add NOF Order Form" ? 
                    <>
                    <p
                    variant="contained"
                    className={classes.NewButton1}
                    style={{ marginRight: "5px", marginTop: "4px" }}
                  >
                    <span style={{ marginTop: "2px" }}>
                      Project Name: {" "}
                      {operationInfo?.company_name || operationInfo?.Project_Name}
                    </span>
                  </p> 
                    </>
                     :
                     buttonText === "Company Share Details" ? 
                     <>
                     <p
                     variant="contained"
                     className={classes.NewButton1}
                     style={{ marginRight: "5px", marginTop: "4px" }}
                   >
                     <span style={{ marginTop: "2px" }}>
                       Partner Name: {" "}
                       {operationInfo?.company_name || operationInfo?.Partner_Name}
                     </span>
                   </p> 
                     </>
                     :
                     buttonText === "Add Target" 
                     || buttonText === "Add PCO Target" 
                     || buttonText === "Add Admin Assistant Target" ? 
                     <>
                     <p
                     variant="contained"
                     className={classes.NewButton1}
                     style={{ marginRight: "5px", marginTop: "4px" }}
                   >
                     <span style={{ marginTop: "2px" }}>
                       Employee Name: {" "}
                       {operationInfo?.Emp_Name}
                     </span>
                   </p> 
                     </>
                      :
                      buttonText === "View Course Documents"
                      || buttonText === "Training Course Log"
                      ? 
                      <>
                      <p
                      variant="contained"
                      className={classes.NewButton1}
                      style={{ marginRight: "5px", marginTop: "4px" }}
                    >
                      <span style={{ marginTop: "2px" }}>
                      Course Id: {" "}
                        {operationInfo?.traning_course_id}
                      </span>
                    </p> 
                      </>
                    :  buttonText === "View Training Attendees"
                    || buttonText === "View Training Status Log"
                      ? 
                      <>
                      <p
                      variant="contained"
                      className={classes.NewButton1}
                      style={{ marginRight: "5px", marginTop: "4px" }}
                    >
                      <span style={{ marginTop: "2px" }}>
                      Schedule Id: {" "}
                        {operationInfo?.traning_schedule_id}
                      </span>
                    </p> 
                      </>
                    :
                    <p
                      variant="contained"
                      className={classes.NewButton1}
                      style={{ marginRight: "5px", marginTop: "4px" }}
                    >
                      <span style={{ marginTop: "2px" }}>
                        Company Name: {operationInfo?.Company_Name || operationInfo?.company_name}
                      </span>
                    </p>
                }
           
                {buttonText === "View Order Detail"
                  || buttonText === "Edit/View Complaint"
                  || buttonText === "Resolve Complaint"
                  || buttonText === "Add/View Follow-up"
                  || buttonText === "Add / View Follow-up"
                  || buttonText === "Add/View Requisition"
                  || buttonText === "Add NOF Order Form"
                  || buttonText === "Freeze Order Approval"
                  || buttonText === "Completion Order Approval" 
                  || buttonText ===  "View Company Document"
                  || buttonText === "View Order Log"
                  || buttonText === "View Complaint Log"
                  || buttonText === "View Order On Project"
                  || buttonText ===  "View Company Order"
                  || buttonText === "Company Share Details"
                  || buttonText ===  "Overdue Order Reason"
                  || buttonText === "View Course Documents"
                  || buttonText === "Training Course Log"
                  || buttonText === "View Training Attendees"
                  || buttonText === "View Training Status Log"
                  || buttonText === "Requestion Log"
                  || buttonText === "Request Freez Order"
                  
                  ? null : (
                    <Button
                    style={{ marginTop: "4px"}}
                      variant="contained"
                      className={classes.NewButton}
                      onClick={(e) => handleModalOpenSub(e)}
                      disabled={
                        salesInfo?.Conversation_Status === "Converted" ||
                          salesInfo?.Conversation_Status === "Decline" ||
                          salesInfo?.Conversation_Status === "Not Proceed" ||
                          (salesInfo?.Alert_Done_Flag === "Yes" &&
                            buttonText === "Add Follow-up")
                            || isCompleteOrderBtnDisable
                          ? true
                          : false
                      }
                    >
                      <AddIcon style={{ marginLeft: "-10px" }} />{" "}
                      <span style={{ marginTop: "2px" }}> {buttonText}</span>
                    </Button>
                  )}
              </>
            ) : buttonText === "Revised NOF Listing" ? (
              <Button
                variant="contained"
                className={classes.NewButton}
              >
                <span style={{ marginTop: "2px" }}> Revised NOF </span>
              </Button>
            ) : buttonText === "View Order Detail" 
            || buttonText === "Edit/View Complaint" 
            || buttonText === "view"
              || buttonText === "Resolve Complaint"
              || buttonText === "View Course Documents"
              || buttonText === "Training Course Log"
              || buttonText === "View Training Attendees"
              || buttonText === "View Training Status Log"
              || buttonText === "Requestion Log"
              ? null 
              : buttonText === "Add NOF Order Form" ? (
                <Button
                style={{ marginTop: "4px"}}
                  variant="contained"
                  className={classes.NewButton}
                  onClick={(e) => handleModalOpenSub(e)}
                  disabled={
                    operationInfo?.operation_order_status === "No Order Created"
                      ? false
                      : true
                  }
                >
                  <AddIcon style={{ marginLeft: "-10px" }} />{" "}
                  <span style={{ marginTop: "2px" }}> {buttonText}</span>
                </Button>
              ) : (
                <Button
                style={{ marginTop: "4px"}}
                  variant="contained"
                  className={classes.NewButton}
                  onClick={(e) => handleModalOpenSub(e)}
                  disabled={
                    operationInfo?.Order_Status === "Order Freezed" ||
                      operationInfo?.Order_Status === "Completed" ||
                      (operationInfo?.Order_Status === "Completion Approval Pending" &&
                        buttonText === "Freeze Order Approval") ||
                      (operationInfo?.Order_Status === "Freeze Approval Pending" &&
                        buttonText === "Completion Order Approval")
                        || isCompleteOrderBtnDisable
                      ? true
                      : false
                  }
                >
                  <AddIcon style={{ marginLeft: "-10px" }} />{" "}
                  <span style={{ marginTop: "2px" }}> {buttonText}</span>
                </Button>
              )}

            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#fff",
                color: "#eb6e12",
                marginLeft: "10px",
                padding: "7px 8px",
                marginTop: "4px"
              }}
              onClick={() => {
                setIsCompleteOrderBtnDisable(false)
                handleModalTableClose()
              }}
            >
              <span style={{ marginTop: "2px", marginRight: "5px", marginTop: "4px" }}>
                Close
              </span>
              <CloseIcon />
            </Button>
            {expiredErrorMsg ? (
              <div className={classes.tableMsgContaner}>
                <span className={classes.tableMsgHeader}>
                  {quotationApiMsg}
                </span>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className={classes.BorderSpacingUpperModaleTable}></div>
      {
      buttonText === "Freeze Order Approval" ? (
       <FreezeOrderLogTable />
        ) :
       buttonText === "Completion Order Approval" ? (
         <CompleteOrderLogTable />
       ) :
       buttonText === "view" ? (
      <TodayOrderAlert date={operationInfo} />
       ) :
       buttonText === "View Order Log" ? (
      <OrderLogTable />
       )
       :
      buttonText === "Add/View Requisition" ? (
     <ViewRequisitionTable />
       ) :
      buttonText === "Add/View Follow-up" || buttonText === "Add / View Follow-up" ? (
      <FollowupTable />
       ) :
      buttonText === "View Order Detail" ? (
      <ViewOrderDetail />
       ) :
      buttonText === "Edit/View Complaint" ? (
      <ComplaintOrderListingTable />
       ) :
      buttonText === "Resolve Complaint" ? (
      <ComplaintOrderListingTable />
       ) :
      buttonText === "Add NOF Order Form" ? (
      <NOFOrderFormTable />
       ) :
      buttonText === "View / Add Family" ? (
      <ViewCompanyFamilyTable />
       ) :
      buttonText === "Add Worker" ? (
      <ViewCompanyWorkerTable />
       ) : buttonText === "View Order On Project" ? (
        <ViewOrderOnProjectListTable />
      ) : buttonText === "View Company Document" ? (
        <CompanyDocuments />
      ) : buttonText === "Revised NOF Listing" ? (
        <RevisedNOFListing />
      ) : buttonText === "Add Comments" ? (
        <ViewCommentTable />
      ) : buttonText === "View Company Order" ? (
        <ViewCompanyOrdersTable />
      ) : buttonText === "Company Share Details" ? (
        <CompanySharesDetailsTable/>
      ): buttonText === "Overdue Order Reason" ? (
        <OverdueReasonListingTable />
      ) : buttonText === "View Complaint Log" ? (
         <EscalationLogTable />
      ) : buttonText === "Add Target" ? (
        <TargetAchievementTable />
      ) : buttonText === "Add PCO Target" ? (
        <PCOTargetAchievementTable />
      ) : buttonText === "Add Admin Assistant Target" ? (
        <AdminTargetAchievementTable />
      )
      : buttonText === "View Course Documents" ? (
        <CourseDocsTable />
      ) : buttonText === "Training Course Log" ? (
        <TrainingCourseLogTable />
      ) : buttonText === "View Training Attendees" ? (
        <ViewAttendeesTable />
      ) : buttonText === "View Training Status Log" ? (
        <ScheduleTrainingLogTable />
      ) : buttonText === "Review On Order" ? (
        <ReviewOnOrderTable />
      ) : buttonText === "Requestion Log" ? (
        <RequisitionLogTable />
      ) : buttonText === "Request Freez Order" ? (
        <FreezeLogTable />
      )
       : null
      }
      <div className={classes.BorderSpacingLowerModaleTable}></div>
    </div>
  );
};

export default withStyles(modalTableStyles)(ModalTable);
