import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Grid, IconButton } from "@mui/material";
import { Formik, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import ModalPopperFormStyles from "../../Styles/ModalPopperFormStyles";
import { SupportDataContext } from "../../../../../provider/context";
import CustomTextArea from "../../../../../reusableComponents/CustomTextarea/CustomTextArea";
import moment from "moment";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BsFilePdfFill } from "react-icons/bs";
import { RiFileWord2Fill } from "react-icons/ri";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#fff",
  border: "2px solid #eb6e12",
  boxShadow: 10,
  p: 4,
  height: "auto",
  paddingTop: "0px",
  minHeight: "550px",
  maxHeight: "550px",
  "@media screen and (min-width: 1650px) and (max-width: 3000px)": {
    maxHeight: "760px",
  },
};
const getStyles = (errors, fieldName, height) => {

  if(height && !getIn(errors, fieldName) ){
    return {height:height}
  }

  if (getIn(errors, fieldName) && height) {
    return {
      border: "1px solid red",
      height:height
    };
  } else if(getIn(errors, fieldName)) {
    return {
      border: "1px solid red"
    };
  }
};

const WorkDoneForm = ({ classes }) => {

  const {
    handleModaleClose,
    setLoading,
    loading,
    setMessage,
    message,
    setSeverity,
    operationInfo,
    getOrderSOWDataAPI,
    rowData,
    setIsOpen

  } = useContext(SupportDataContext);
  const fileInput = React.createRef();


 const cchangeRequisitionStatusPostAPI = async(values) => {
  const user_type = localStorage.getItem('user_type');
  const factory_id = localStorage.getItem('factory_id');
  const emp_id = localStorage.getItem("emp_id");
  const __token = localStorage.getItem("token");
  const user_name = localStorage.getItem("Employee_First_Name");
  const url = `https://flyingcolour.online/fcapp/support_api/changerequisitionsupportstatus/`;
  const headers = {
    Authorization: `Token ${__token}`,
    "Content-Type": "application/json",
  };


  let body = {
    emp_id: Number(emp_id),
    user_type: user_type,
    factory_id: Number(factory_id),
    user_name: user_name,
    order_no: operationInfo?.Order_No || rowData?.order_id,
    requisition_no: rowData?.requisitionNos || rowData?.requisition_auto_id,
    support_remarks : values.comment,
  }

  const formData = new FormData();
  formData.append("factory_id ", Number(factory_id));
  formData.append("emp_id", Number(emp_id));
  formData.append("user_type", user_type);
  formData.append("user_name", user_name);
  formData.append("order_no", operationInfo?.Order_No || rowData?.order_id);
  formData.append("requisition_no", rowData?.requisitionNos || rowData?.requisition_auto_id);
  formData.append("support_remarks", values.comment);
  formData.append("uploaded_file_name", values.uploadDoc);

    axios.post(url, formData, { headers: headers }).then((data) => {
      setLoading(false);
      handleQuotationPromise(data);
    })
 };

 const handleQuotationPromise = (data) => {
  if (data?.data?.message) {
    setMessage(data?.data?.message);
    setSeverity(500);
  }
  if (data?.data?.data?.msg) {
    setMessage(data?.data?.data?.msg);
    setSeverity(500);
  }
  if (data?.data?.data?.detail) {
    setMessage(data?.data?.data?.detail);
    setSeverity(500);
  } else {
    setMessage(data?.data?.data?.msg);
    setSeverity(data?.data?.data?.code);
  }
  setIsOpen(true)
//   getOrderSOWDataAPI();
//   handleModaleClose();
 }
  return (
    <Formik
      //enableReinitialize
      initialValues={{
        comment: '',
        uploadDoc: ""
      }}
      onSubmit={async (data) => {
        setLoading(true)
        cchangeRequisitionStatusPostAPI(data);
        getOrderSOWDataAPI(rowData);
        handleModaleClose();
      }}
      validate={(values) => {
        let errors = {};
        if (!values.comment) {
          errors.comment = "required";
        }
        return errors;
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
            <div>
                <div style={{ height: "36px" }}>
              <IconButton
                style={{ float: "right" }}
                className={classes.quotationModalHeaderIconCancle}
                onClick={() => {
                  handleModaleClose();
                }}
              >
                <CloseIcon
                  className={classes.quotationModalHeaderIconCancleIcon}
                />
              </IconButton>
              </div> 
              <div style={{ height: "460px", overflowX: "hidden", overflowY: "auto", padding: "0px 20px 20px 20px" }}>
              <Grid container spacing={2} columns={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "-30px",
                    paddingBottom: "10px",
                    marginTop: "-45px",
                    zIndex:10
                  }}
                >
                  <br />
                  <div className={classes.quotationModalHeadingContainer}>
                    <span className={classes.quotationModalHeader}>
                     Requisition Done Form
                    </span>
                  </div>
                  <br />
                  <div className={classes.quotationModalFlexDivContaner}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className={classes.quotationInnerGridRight}
                      >
                          <Form.Group as={Row} className="mb-1">
                                                                <Form.Label column sm="5" className="fc-textarea-label">
                                                                    Upload Doc
                                                                </Form.Label>
                                                                <Col sm="7" style={{ padding: '0 12px 0 0' }} >

                                                                    <div className={classes.businessCardFormGroupModal}>
                                                                        <div
                                                                            className={classes.quotationFormGroupFlexDivModalVT}
                                                                        >
                                                                            <div
                                                                                className={classes.quotationSelectContainerModal}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        classes.businessCardDemoDataCheckModalUploadFile
                                                                                    }
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        style={{ display: "none" }}
                                                                                        onChange={(event) => {
                                                                                           
                                                                                            setFieldValue(
                                                                                                `uploadDoc`,
                                                                                                event.currentTarget.files[0]
                                                                                            );
                                                                                        }}
                                                                                        // accept=".jpg,.JPEG, .jpeg, .png,image/png, image/jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                        ref={fileInput}
                                                                                    />
                                                                                    <button
                                                                                        style={{
                                                                                            padding: '6px 20px',
                                                                                            backgroundColor: '#eb6e12',
                                                                                            border: '1px solid #d3d3d3',
                                                                                            color: '#fff',
                                                                                            borderRadius: '5px',
                                                                                            cursor: 'pointer',
                                                                                            marginRight: "5px"
                                                                                        }}
                                                                                        className={
                                                                                            classes.businessCardDemoDataCheckModalUploadFileBtn
                                                                                        }
                                                                                        type="button"
                                                                                        onClick={() => fileInput.current.click()}
                                                                                    >
                                                                                        <PermMediaIcon
                                                                                            style={{ marginRight: "10px" }}
                                                                                        />
                                                                                        Add Media
                                                                                    </button>

                                                                                    <small
                                                                                        className={
                                                                                            classes.businessCardDemoDataCheckModalUploadFileText
                                                                                        }
                                                                                    >
                                                                                        {values.uploadDoc
                                                                                            ? values?.uploadDoc?.name ||
                                                                                            values?.uploadDoc?.split("/").pop()
                                                                                            : "No file chosen"}
                                                                                    </small>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {values.uploadDocument
                                                                        ?.length !== 0 &&
                                                                        values.uploadDoc !== "" &&
                                                                        typeof values.uploadDoc ===
                                                                        "object" ? (
                                                                        [
                                                                            "png",
                                                                            "jpg",
                                                                            "JPEG",
                                                                            "jpeg",
                                                                        ].includes(
                                                                            values.uploadDoc?.name
                                                                                ?.split(".")
                                                                                .pop()
                                                                        ) ? (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent:
                                                                                        "center",
                                                                                    marginBottom: ".2rem",
                                                                                    marginTop: "5px",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        fileInput.current[
                                                                                            0
                                                                                        ]?.current.click();
                                                                                    }}
                                                                                    style={{
                                                                                        width: "98%",
                                                                                        height: "100px",
                                                                                    }}
                                                                                    src={URL.createObjectURL(
                                                                                        values.uploadDoc
                                                                                    )}
                                                                                    alt={"image"}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent:
                                                                                        "center",
                                                                                    marginBottom: ".5rem",
                                                                                    marginTop: ".5rem",
                                                                                    alignItems: "center",
                                                                                    width: "50px",
                                                                                    marginRight: "2rem",
                                                                                }}
                                                                            >
                                                                                {values.uploadDoc?.name
                                                                                    ?.split(".")
                                                                                    .pop() === "docx" ? (
                                                                                    <>
                                                                                        <RiFileWord2Fill
                                                                                            style={{
                                                                                                color: "#2b579a",
                                                                                                fontSize: "36px",
                                                                                                marginRight:
                                                                                                    ".4rem",
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                ) : values.uploadDoc?.name
                                                                                    ?.split(".")
                                                                                    .pop() === "pdf" ? (
                                                                                    <BsFilePdfFill
                                                                                        style={{
                                                                                            color: "#ff0000",
                                                                                            fontSize: "36px",
                                                                                            marginRight:
                                                                                                ".4rem",
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <CloudUploadIcon
                                                                                        style={{
                                                                                            color: "green",
                                                                                            fontSize: "32px",
                                                                                            marginRight:
                                                                                                ".4rem",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    ) : values.uploadDocument
                                                                        ?.length !== 0 &&
                                                                        values.uploadDoc !== "" &&
                                                                        typeof values.uploadDoc ===
                                                                        "string" ? (
                                                                        [
                                                                            "png",
                                                                            "jpg",
                                                                            "JPEG",
                                                                            "jpeg",
                                                                        ].includes(
                                                                            values.uploadDoc
                                                                                ?.split(".")
                                                                                .pop()
                                                                        ) ? (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent:
                                                                                        "center",
                                                                                    marginBottom: ".2rem",
                                                                                    marginTop: "5px",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        fileInput.current[
                                                                                            0
                                                                                        ]?.current.click();
                                                                                    }}
                                                                                    style={{
                                                                                        width: "98%",
                                                                                        height: "100px",
                                                                                    }}
                                                                                    src={values.uploadDoc}
                                                                                    alt={"image"}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent:
                                                                                        "center",
                                                                                    marginBottom: ".5rem",
                                                                                    marginTop: ".5rem",
                                                                                    alignItems: "center",
                                                                                    width: "50px",
                                                                                    marginRight: "2rem",
                                                                                }}
                                                                            >
                                                                                {values.uploadDoc
                                                                                    ?.split(".")
                                                                                    .pop() === "docx" ? (
                                                                                    <>
                                                                                        <RiFileWord2Fill
                                                                                            style={{
                                                                                                color: "#2b579a",
                                                                                                fontSize: "36px",
                                                                                                marginRight:
                                                                                                    ".4rem",
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                ) : values.uploadDoc
                                                                                    ?.split(".")
                                                                                    .pop() === "pdf" ? (
                                                                                    <BsFilePdfFill
                                                                                        style={{
                                                                                            color: "#ff0000",
                                                                                            fontSize: "36px",
                                                                                            marginRight:
                                                                                                ".4rem",
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <CloudUploadIcon
                                                                                        style={{
                                                                                            color: "green",
                                                                                            fontSize: "32px",
                                                                                            marginRight:
                                                                                                ".4rem",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    ) : null}
                                                                </Col>
                           </Form.Group>

                           <Form.Group as={Row} className="mb-1">
                            <Form.Label column sm="5" className="fc-textarea-label">
                            <span style={{fontSize: "12px"}}>Support Remarks </span>
                            <span style={{color: "red", fontSize: "12px"}}>*</span>
                            </Form.Label>
                            <Col sm="7" style={{ padding: '0 12px 0 0' }} >
                        <textarea
                            name="comment"
                            value={values.comment}
                            onChange={(e) => {
                                e.preventDefault();
                                const { value } = e.target;
                                setFieldValue(
                                    `comment`,
                                    value
                                );
                            }}
                            required
                            style={{height: "150px", width: "100%"}}
                                                            />
                         </Col>
                        </Form.Group>

                      </Grid>
                    </Grid>
                  </div>
                  <br />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.quotationButtonDivModal}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#eb6e12" }}
                >
                  {loading ? "Loading...." : "Submit"}
                </Button>
                <Button
                  onClick={() => {
                    handleModaleClose();
                    // handleHide()
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#fff",
                    color: "#eb6e12",
                    marginLeft: "10px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              </div>
              </div>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(ModalPopperFormStyles)(WorkDoneForm);
