import * as React from "react";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dropdown from "react-bootstrap/Dropdown";
import "./topbar.css";
import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { getLocalData } from "../services/services";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import CustomCalender from "../reusableComponents/CustomCalender/CustomCalender";
import DatePicker from "../pages/Dashboard/DatePicker";
import { DataContext } from "../provider/context";
import { useNavigate } from "react-router-dom";

export default function Notify({ checked, fetchNotification, isCompliance }) {
  const __token = localStorage.getItem("token");
  const user_type = getLocalData("user_type");
  const emp_id = getLocalData("emp_id");
  const factory_id = getLocalData("factory_id");
  const [isShowAllNotification, setIssShowAllNotification] =
    React.useState(false);
  const [notificationAllDataList, setNotificationAllDataList] =
    React.useState(false);
  const { fromDate, toDate, setError, setErrorAlert } =
    React.useContext(DataContext);
  const [isInitialRef, setInitialRef] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);
  const [viewData, setViewData] = React.useState('');
  const navigate = useNavigate();
  const gridRef = React.useRef();

  const handleNotificationDate = () => {
    gridRef?.current?.api?.getFilterInstance("Inquiry_Status_Date").setModel({
      type: "inRange",
      dateFrom: fromDate,
      dateTo: toDate,
    });
    gridRef?.current?.api.onFilterChanged();
  };

  React.useEffect(() => {
    if (isInitialRef) {
      handleNotificationDate();
    }
    setInitialRef(true);
  }, [fromDate, toDate]);

  const handleChange = async (item) => {
    let data = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      notification_id: item.notification_id,
      read_flag: 1,
    };
    await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/Notification/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
    // setChecked((prev) => [...prev.filter((i) => i !== item)]);
  };

  const components = React.useMemo(() => {
    return {
      agDateInput: CustomCalender,
    };
  }, []);
  const NotificationBox = ({ title, Msg, onClose, date }) => {
    return (
      <div className="tBody">
        <div className="toast-header">
          <h5 class="headerTxt">{title}</h5>
          <IconButton className="closeBtn" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </IconButton>
        </div>
        <div className="toast-body" style={{ marginBottom: "0.5rem" }}>
          Date & Time : {moment(date).format("DD-MM-YYYY H:mm:ss")}
        </div>
        <div className="toast-body">
          {Msg}
          <Button className="toast-button" onClick={() => {
          setViewModal(true);
          setViewData({
            title: title,
            msg: Msg
          })
        }} >View</Button>
        </div>
      </div>
    );
  };

  const NotificationBoxComplianceModule = ({ title, Msg, onClose, date }) => {
    return (
      <div className="tBody">
        <IconButton
          className="closeBtn"
          aria-label="Close"
          onClick={onClose}
          style={{ float: "right", marginRight: "5px", marginTop: "8px" }}
        >
          <span aria-hidden="true">&times;</span>
        </IconButton>
        <div className="toast-header">
          <p class="headerTxt" style={{ margin: 0 }}>
            {title}
          </p>
          <div className="toast-body">
            <p style={{ margin: 0, width: "125px" }}>
              {moment(date).format("DD-MM-YYYY H:mm:ss")}
            </p>
          </div>
        </div>

        <div className="toast-body compliance-body">
          {Msg}
         
        </div>
        <Button className="toast-button" onClick={() => {
          setViewModal(true);
          setViewData({
            title: title,
            msg: Msg
          })
        }} >View</Button>
      </div>
    );
  };

  const handleViewAllNotification = async () => {
    const userName = localStorage.getItem("userName");
    try {
      const url = `https://flyingcolour.online/fcapp/docprocessing_api/Notification/?emp_id=${emp_id}&factory_id=${factory_id}&user_type=${user_type}&read_unread_flag=3&user_name=${userName}`;
      const headers = {
        Authorization: `Token ${__token}`,
        "Content-Type": "application/json",
      };
      const res = await axios.get(url, { headers });
      if (res?.data?.results === undefined && res?.data?.detail === undefined) {
        return (
          setErrorAlert(true),
          setError(
            `ERROR ${res?.data?.code}-${
              res?.data?.msg
            }${" "}${"Error is from sales person notification"}`
          )
        );
      } else if (
        res?.data?.detail !== undefined &&
        res?.data?.results === undefined
      ) {
        return (
          setErrorAlert(true),
          setError(
            `ERROR ${"401"}-${
              res?.data?.detail
            }${" "}${"Error is from sales person notification"}`
          )
        );
      } else {
        return (
          setNotificationAllDataList(res?.data?.results?.data),
          setIssShowAllNotification(true)
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        return (
          setErrorAlert(true),
          setError(
            `${error.response?.statusText} ${error.response?.status}-${
              error.response?.data?.detail
            }${" "}${"Please Login Again!!"}`
          )
        );
      } else if (!error?.response) {
        return (
          setErrorAlert(true),
          setError(
            `${error}-${"Please Check Your Network Connectivity and try again!!"}`
          )
        );
      }
    }
  };

 const dateFilterParams = {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const onlyDate = cellValue.split(" ");
      const dateParts = onlyDate[0].split("-");
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);
      // Now that both parameters are Date objects, we can compare
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  const columnTypes = React.useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
    };
  }, []);

  const handleReadAllNotification = async () => {
    let data = {
      emp_id: emp_id,
      user_type: user_type,
      factory_id: factory_id,
      read_flag: 2,
    };
    await fetch(
      "https://flyingcolour.online/fcapp/docprocessing_api/Notification/",
      {
        method: "PUT",
        headers: {
          Authorization: `Token ${__token}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchNotification();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        console.error("Error:", error);
      });
  };

  const columnDefs = [
    {
      headerName: "S.NO",
      field: "Serial_Number",
      filter: false,
      width: 90,
      cellStyle: { border: "1px solid #fff" },
    },
    {
      headerName: "Subject",
      field: "subject",
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
    },
    {
      headerName: "Remarks",
      field: "description",
      cellStyle: { border: "1px solid #fff", overflowY: "auto" },
      flex: 1,
    },
    {
      headerName: "Action By",
      field: "notification_by",
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
    },

      {
      headerName: "Notification To",
      field: "notification_to",
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
    },
    {
      headerName: "Notification Date",
      field: "notification_date",
      cellRenderer: (params) => {
        return <>{moment(params?.data?.notification_date).format("DD-MM-YYYY hh:mm:ss")}</>;
      },
      cellStyle: { border: "1px solid #fff" },
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      flex: 1,
    },
    {
      headerName: "Action",
      field: "Inquiry_Status_Date",
      floatingFilter: false,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.read_unread_flag === "Unread" ? (
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="toast-button"
                  onClick={() => handleChange(params?.data)}
                >
                  Read
                </Button>
              </div>
            ) : (
              <>
              Read By:  {params?.data?.read_by} <br />
              Date: {params?.data?.read_date}
              
              </>
            )}
          </>
        );
      },
      cellStyle: { border: "1px solid #fff" },
      flex: 1,
    },
  ];

  const defaultColDef = React.useMemo(() => {
    return {
      width: 100,
      editable: false,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
      sortable: true,
    };
  }, []);

  return (
    <>
      <Dropdown.Menu>
        <div className="notifyHead">
          <h5 className="headTxt">Notifications</h5>
        </div>
        <div
          className="boy"
          style={{ maxHeight: "500px", overflowY: "scroll" }}
        >
          {checked?.length !== 0 ? (
            (checked || []).map((i) => (
              <>
                <Collapse in={checked} key={i}>
                  {isCompliance ? (
                    <NotificationBoxComplianceModule
                      title={i.subject}
                      Msg={i.description}
                      onClose={() => {
                        handleChange(i);
                      }}
                      date={i.notification_date}
                    />
                  ) : (
                    <NotificationBox
                      title={i.subject}
                      Msg={i.description}
                      onClose={() => {
                        handleChange(i);
                      }}
                      date={i.notification_date}
                    />
                  )}
                </Collapse>
              </>
            ))
          ) : (
            <div className="noNotification">
              No Notifications <NotificationsIcon />
            </div>
          )}
        </div>
        <div
          className=""
          style={{
            borderTop: "1px solid red",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            className="toast-button"
            onClick={() => handleViewAllNotification()}
          >
            View All
          </Button>

          <Button
            className="toast-button"
            onClick={() => handleReadAllNotification()}
          >
            Read All
          </Button>
        </div>
      </Dropdown.Menu>

      <Modal  
        show={viewModal}
        onHide={() => setViewModal(false)}
        animation={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static">

      <Modal.Header
          style={{ background: "#eb6e12", color: "white" }}
          closeButton
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {viewData?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewData?.msg}
        </Modal.Body>
      </Modal>

      <Modal
        show={isShowAllNotification}
        onHide={() => setIssShowAllNotification(false)}
        animation={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#eb6e12", color: "white" }}
          closeButton
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Notifications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "500px" }}>
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DatePicker />
            <Button
              className="toast-button"
              onClick={() => handleReadAllNotification()}
            >
              Read All
            </Button>
          </div>
          <div
            style={{ height: "93%", width: "100%" }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              suppressExcelExport={true}
              suppressCsvExport={true}
              ref={gridRef}
              rowData={notificationAllDataList}
              columnDefs={columnDefs}
              columnTypes={columnTypes}
              defaultColDef={defaultColDef}
              animateRows={true}
              components={components}
              rowStyle={{ background: "#DEDEDE", color: "#000000DE" }}
              pagination={true}
              getRowHeight={() => 90}
            ></AgGridReact>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
